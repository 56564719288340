<template>
    <CModal
        ref="modalEl"
        :header-title="__('family_family:update_family')"
        size="xs"
        @hidden="$emit('hidden')"
    >
        <FamilyForm
            ref="formEl"
            :family-model="familyModel.clone()"
            @loading="loading = $event"
            @saved="onFamilyUpdated"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="modalEl?.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loading"
                variant="primary"
                @click="formEl?.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script setup lang="ts">
    import type {PropType} from 'vue';
    import {ref} from 'vue';

    import FamilyForm from '@/modules/family/components/family/FamilyForm.vue';
    import type FamilyModel from '@/modules/family/models/FamilyModel';

    defineProps({
        familyModel: {
            type: Object as PropType<FamilyModel>,
            required: true,
        },
    });

    const emit = defineEmits<{
        (e: 'updated', value: FamilyModel): void;
        (e: 'hidden'): void;
    }>();

    defineExpose({
        show,
        hide,
    });

    const modalEl = ref<{show: () => void; hide: () => void}>();
    const formEl = ref<{save: () => void}>();
    const loading = ref(false);

    function onFamilyUpdated(familyModel: FamilyModel) {
        hide();
        emit('updated', familyModel);
    }

    function show() {
        modalEl.value?.show();
    }

    function hide() {
        modalEl.value?.hide();
    }
</script>
