import type {Moment} from 'moment';
import moment from 'moment';

export default function useSegmentSizing() {
    // Get percent of timestamp for segment on specific day depending on opening and closing hours
    function unixToPercent(timestamp: number, date: Moment, openingTime: string, closingTime: string, limit = false) {
        const openingTimestamp = date
            .clone()
            .hours(moment.duration(openingTime).hours())
            .minutes(moment.duration(openingTime).minutes())
            .seconds(moment.duration(openingTime).seconds())
            .unix();

        const closingTimestamp = date
            .clone()
            .hours(moment.duration(closingTime).hours())
            .minutes(moment.duration(closingTime).minutes())
            .seconds(moment.duration(closingTime).seconds())
            .unix();

        const percent = ((timestamp - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);

        if (limit) {
            if (percent > 100) {
                return 100;
            } else if (percent < 0) {
                return 0;
            } else {
                return percent;
            }
        }

        return percent;
    }

    return {
        unixToPercent,
    };
}
