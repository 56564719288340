<template>
    <div
        class="InvoicesListItemDetailsLineIcon"
        :class="computedClass"
    >
        <FontAwesomeIcon
            :fixed-width="true"
            :icon="icon"
        />
    </div>
</template>

<script>
    export default {
        props: {
            lineType: {
                type: String,
                default: 'other',
            },
            size: {
                type: String,
                default: 'sm',
            },
        },
        data: () => ({
            icon: 'fa-solid fa-note',
            color: 'tw-text-gray-500',
        }),
        computed: {
            computedClass() {
                let computedClass = '';

                // Colors
                computedClass += this.color;

                // Size
                computedClass += ' ' + this.getSize();

                return computedClass;
            },
        },
        watch: {
            lineType: function () {
                this.initLine();
            },
        },
        mounted() {
            this.initLine();
        },
        methods: {
            initLine() {
                switch (this.lineType) {
                    case 'cmg':
                    case 'recurrent':
                        this.color = 'tw-text-green-500';
                        this.icon = 'fa-solid fa-clock';
                        break;
                    case 'occasional':
                        this.color = 'tw-text-blue-500';
                        this.icon = 'fa-solid fa-calendar-plus';
                        break;
                    case 'adaptation':
                        this.color = 'tw-text-yellow-500';
                        this.icon = 'fa-solid fa-child-reaching';
                        break;
                    case 'absence':
                        this.color = 'tw-text-red-500';
                        this.icon = 'fa-solid fa-calendar-minus';
                        break;
                    case 'overrun':
                        this.color = 'tw-text-blue-500';
                        this.icon = 'fa-solid fa-sign-out';
                        break;
                    case 'meals':
                        this.color = 'tw-text-cyan-500';
                        this.icon = 'fa-solid fa-utensils';
                        break;
                    case 'health':
                        this.color = 'tw-text-pink-500';
                        this.icon = 'fa-solid fa-heart';
                        break;
                    default:
                        this.color = 'tw-text-gray-500';
                        this.icon = 'fa-solid fa-note';
                }
            },

            getSize() {
                switch (this.size) {
                    case 'xs':
                        return 'tw-text-sm tw-h-4 tw-w-4';
                    case 'sm':
                        return 'tw-text-sm tw-h-6 tw-w-4';
                    case 'md':
                        return 'tw-text-md tw-h-8 tw-w-8';
                    case 'lg':
                        return 'tw-text-lg tw-h-10 tw-w-10';
                    case 'xl':
                        return 'tw-text-xl tw-h-12 tw-w-12';
                    default:
                        return 'tw-text-sm tw-h-6 tw-w-4';
                }
            },
        },
    };
</script>

<style scoped>
    .InvoicesListItemDetailsLineIcon {
        @apply tw-flex tw-shrink-0 tw-items-center tw-justify-center;
    }
</style>
