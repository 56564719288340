<template>
    <div>
        <div class="tw-flex tw-items-center tw-gap-4">
            <MAvatar
                class="tw-w-24 tw-shrink-0"
                fallback-teddybear
                :url="nursery.logo_url"
            />
            <div class="tw-flex tw-flex-col tw-gap-1">
                <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                    <MHeading>
                        {{ nursery.name }}
                    </MHeading>
                    <MButton
                        v-if="can('update', 'nursery')"
                        icon-edit
                        :label="__('common:actions.update')"
                        variant="primary"
                        @click="$refs.organizationModalEditRef.$refs.modal.show()"
                    />
                </div>
                <div
                    v-if="nursery.city"
                    class="tw-text-sm tw-text-gray-500"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa-solid fa-map-marker"
                    />
                    {{ $capitalize(nursery.city) }} {{ $capitalize(nursery.state) }}
                </div>
            </div>
        </div>
        <div class="tw-my-6 tw-rounded-lg tw-bg-blue-50 tw-p-2">
            <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-3">
                <div class="tw-flex tw-min-w-0 tw-items-center tw-justify-center tw-gap-2">
                    <FontAwesomeIcon
                        v-if="nursery.places"
                        class="tw-text-primary-500"
                        fixed-width
                        icon="fa-solid fa-users"
                    />
                    <div class="tw-truncate">
                        {{ __('common:place_with_count', {count: nursery.places}) }}
                    </div>
                </div>
                <div class="tw-flex tw-min-w-0 tw-items-center tw-justify-center tw-gap-2">
                    <FontAwesomeIcon
                        v-if="nursery.phone"
                        class="tw-text-primary-500"
                        fixed-width
                        icon="fa-solid fa-phone"
                    />
                    <div class="tw-truncate">
                        {{ nursery.phone }}
                    </div>
                </div>
                <div class="tw-flex tw-min-w-0 tw-items-center tw-justify-center tw-gap-2">
                    <FontAwesomeIcon
                        v-if="nursery.email"
                        class="tw-text-primary-500"
                        fixed-width
                        icon="fa-solid fa-envelope"
                    />
                    <div class="tw-truncate">
                        {{ nursery.email }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="can('update', 'nursery')"
            class="tw-mt-4"
        >
            <div>
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <MHeading
                            class="tw-text-center"
                            level="h3"
                        >
                            {{ __('common:logo') }}
                        </MHeading>
                        <div class="tw-mt-2 tw-flex tw-flex-col tw-items-center">
                            <MAvatarEditor
                                :can-delete="!!nursery.logo_url"
                                class="tw-w-28"
                                @click="clickLogoInput()"
                                @delete="deleteLogo()"
                            >
                                <MAvatar
                                    class="tw-w-full"
                                    :url="nursery.logo_url"
                                />
                            </MAvatarEditor>
                            <small class="small">{{ __('common:click_for_update') }}</small>
                            <input
                                ref="logoInput"
                                accept="image/png, image/jpeg"
                                class="tw-hidden"
                                type="file"
                                @change="updateLogo($event)"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 tw-mt-6 sm:tw-mt-0">
                        <MHeading
                            class="tw-text-center"
                            level="h3"
                        >
                            {{ __('common:signature') }}
                        </MHeading>
                        <div class="tw-mt-2 tw-flex tw-flex-col tw-items-center">
                            <MAvatarEditor
                                :can-delete="!!nursery.signature_url"
                                class="tw-w-28"
                                @click="clickSignatureInput()"
                                @delete="deleteSignature()"
                            >
                                <MAvatar
                                    class="tw-w-full"
                                    :url="nursery.signature_url"
                                />
                            </MAvatarEditor>
                            <small class="small">{{ __('common:click_for_update') }}</small>
                            <input
                                ref="signatureInput"
                                accept="image/png, image/jpeg"
                                class="tw-hidden"
                                type="file"
                                @change="updateSignature($event)"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 tw-mt-6 sm:tw-mt-0">
                        <MHeading
                            class="tw-text-center"
                            level="h3"
                        >
                            {{ __('common:stamp') }}
                        </MHeading>
                        <div class="tw-mt-2 tw-flex tw-flex-col tw-items-center">
                            <MAvatarEditor
                                :can-delete="!!nursery.stamp_url"
                                class="tw-w-28"
                                @click="clickStampInput()"
                                @delete="deleteStamp()"
                            >
                                <MAvatar
                                    class="tw-w-full"
                                    :url="nursery.stamp_url"
                                />
                            </MAvatarEditor>
                            <small class="small">{{ __('common:click_for_update') }}</small>
                            <input
                                ref="stampInput"
                                accept="image/png, image/jpeg"
                                class="tw-hidden"
                                type="file"
                                @change="updateStamp($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OrganizationModalEdit
            ref="organizationModalEditRef"
            :nursery="nursery"
            :user="user"
        />
    </div>
</template>

<script lang="ts">
    import _forEach from 'lodash-es/forEach';
    import {defineComponent, ref} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import {upload} from '@/modules/legacy/libs/usercontent';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    import OrganizationModalEdit from './Summary/OrganizationModalEdit.vue';

    export default defineComponent({
        components: {
            OrganizationModalEdit,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const logoInput = ref<HTMLInputElement>();
            const signatureInput = ref<HTMLInputElement>();
            const stampInput = ref<HTMLInputElement>();

            const {can} = useAbility();

            function clickLogoInput() {
                logoInput.value?.click();
            }

            function updateLogo(event) {
                if (!event.target.files[0] || !validateFileMIME(event.target.files[0].type)) {
                    return;
                }

                upload(event.target.files[0]).then(response => {
                    const data = {
                        logo: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };

                    useApi()
                        .legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), data)
                        .then(response => {
                            props.nursery.logo_url = response.data.logo_url;
                            useNotification().success(__('organization:summary.logo_updated'));
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    handleError(value);
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                });
            }

            function deleteLogo() {
                useApi()
                    .legacy.delete(route('nurseries.logo.destroy', {nurseries: props.nursery.id}))
                    .then(() => {
                        props.nursery.logo_url = null;
                        useNotification().success(__('organization:summary.logo_deleted'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(value[0]);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            function clickSignatureInput() {
                signatureInput.value?.click();
            }

            function updateSignature(event) {
                if (!event.target.files[0] || !validateFileMIME(event.target.files[0].type)) {
                    return;
                }

                upload(event.target.files[0]).then(response => {
                    const data = {
                        signature: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };

                    useApi()
                        .legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), data)
                        .then(response => {
                            props.nursery.signature_url = response.data.signature_url;
                            useNotification().success(__('organization:summary.signature_updated'));
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    handleError(value);
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                });
            }

            function deleteSignature() {
                useApi()
                    .legacy.delete(route('nurseries.signature.destroy', {nurseries: props.nursery.id}))
                    .then(() => {
                        props.nursery.signature_url = null;
                        useNotification().success(__('organization:summary.signature_deleted'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(value[0]);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            function clickStampInput() {
                stampInput.value?.click();
            }

            function updateStamp(event) {
                if (!event.target.files[0] || !validateFileMIME(event.target.files[0].type)) {
                    return;
                }

                upload(event.target.files[0]).then(response => {
                    const data = {
                        stamp: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };

                    useApi()
                        .legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), data)
                        .then(response => {
                            props.nursery.stamp_url = response.data.stamp_url;
                            useNotification().success(__('organization:summary.stamp_updated'));
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    handleError(value);
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                });
            }

            function deleteStamp() {
                useApi()
                    .legacy.delete(route('nurseries.stamp.destroy', {nurseries: props.nursery.id}))
                    .then(() => {
                        props.nursery.stamp_url = null;
                        useNotification().success(__('organization:summary.stamp_deleted'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(value[0]);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            function handleError(error) {
                useNotification().error(error[0]);
            }

            function validateFileMIME(type) {
                if (!['image/png', 'image/jpeg'].includes(type)) {
                    useNotification().error(__('common:errors.incorrect_file_format'));

                    return false;
                }

                return true;
            }

            return {
                can,
                logoInput,
                signatureInput,
                stampInput,
                clickLogoInput,
                updateLogo,
                deleteLogo,
                clickSignatureInput,
                updateSignature,
                deleteSignature,
                clickStampInput,
                updateStamp,
                deleteStamp,
            };
        },
    });
</script>
