<template>
    <div
        class="CFormThreeColumns"
        :class="style"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            responsive: {type: Boolean, default: true},
        },
        setup(props) {
            const style = computed(() => {
                const output = [] as any[];

                if (props.responsive) {
                    output.push('CFormThreeColumnsResponsive');
                }

                return output;
            });

            return {
                style,
            };
        },
    });
</script>

<style scoped>
    .CFormThreeColumns {
        @apply tw-grid tw-grid-cols-3 tw-gap-4;

        > * {
            @apply tw-col-span-3;
        }

        &.CFormThreeColumnsResponsive > * {
            @apply sm:tw-col-span-1;
        }
    }
</style>
