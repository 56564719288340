<script setup lang="ts">
    import {computed, type Ref, ref, watch} from 'vue';

    import {useReportingSettingsWording} from '@/modules/meeko-family/composables/useReportingSettingsWording';
    import type {ReportingSettingEntity} from '@/modules/meeko-family/domain/entities/ReportingSettingEntity';
    import {ReportingType} from '@/modules/meeko-family/domain/values/ReportingType';

    const props = defineProps<{
        setting: ReportingSettingEntity;
        disabled: boolean;
    }>();

    const emit = defineEmits<{
        (e: 'updated', value: ReportingSettingEntity): void;
    }>();

    const {wording} = useReportingSettingsWording(props.setting.type);

    // Clone the props to local state
    const realTime = ref(props.setting.realTime);

    const markNextReportingAsVisible: Ref<boolean> = ref(props.setting.markNextReportingAsVisible);

    const markNextReportingObservationAsVisible: Ref<boolean> = ref(
        props.setting.markNextReportingObservationAsVisible
    );

    // Check if the local state has changed
    const hasMarkNextReportingAsVisibleChanged = computed(
        () => markNextReportingAsVisible.value !== props.setting.markNextReportingAsVisible
    );
    const hasMarkNextReportingObservationAsVisibleChanged = computed(
        () => markNextReportingObservationAsVisible.value !== props.setting.markNextReportingObservationAsVisible
    );

    const hasRealTimeChanged = computed(() => realTime.value !== props.setting.realTime);

    // Sync the local state with the props
    watch(
        () => props.setting,
        value => {
            if (hasRealTimeChanged.value) {
                realTime.value = value.realTime;
            }

            if (hasMarkNextReportingAsVisibleChanged.value) {
                markNextReportingAsVisible.value = value.markNextReportingAsVisible;
            }

            if (hasMarkNextReportingObservationAsVisibleChanged.value) {
                markNextReportingObservationAsVisible.value = value.markNextReportingObservationAsVisible;
            }
        }
    );

    // Watch for changes in the local state
    watch([markNextReportingAsVisible, markNextReportingObservationAsVisible, realTime], () => {
        // If the local state is different from the props, emit the updated event
        if (
            hasMarkNextReportingAsVisibleChanged.value ||
            hasMarkNextReportingObservationAsVisibleChanged.value ||
            hasRealTimeChanged.value
        ) {
            const updatedSetting = {
                ...props.setting,
                markNextReportingAsVisible: markNextReportingAsVisible.value,
                markNextReportingObservationAsVisible: markNextReportingObservationAsVisible.value,
                realTime: realTime.value,
            };

            emit('updated', updatedSetting);
        }
    });
</script>

<template>
    <MBox class="MeekoFamilyReportingCard">
        <div class="MeekoFamilyReportingCard__header">
            <CCheckbox
                v-model="markNextReportingAsVisible"
                :disabled="disabled"
            >
                <MHeading level="h3">
                    {{ wording.title }}
                </MHeading>
            </CCheckbox>
            <FontAwesomeIcon
                v-if="realTime && markNextReportingAsVisible"
                class="tw-text-2xl tw-text-success-500"
                icon="fa-solid fa-circle-check"
            />
            <FontAwesomeIcon
                v-else
                class="tw-text-2xl tw-text-gray-300"
                icon="fa-solid fa-circle-check"
            />
        </div>

        <div class="MeekoFamilyReportingCard__container">
            <div class="MeekoFamilyReportingCard__text">
                {{ wording.text }}
            </div>
            <MForm
                class="MeekoFamilyReportingCard__form"
                @submit.prevent
            >
                <MFieldset>
                    <CCheckbox
                        v-if="setting.type !== ReportingType.observations"
                        v-model="markNextReportingObservationAsVisible"
                        :disabled="disabled"
                    >
                        {{ __('reporting:share_additional_comments') }}
                    </CCheckbox>
                </MFieldset>
                <MFieldset class="tw-min-w-0">
                    <MSelectMenu
                        v-model="realTime"
                        class="tw-w-fit tw-max-w-full"
                        :search-bar="false"
                        :disabled="disabled"
                        :options="[
                            {
                                value: true,
                                text: __('reporting:shared_live'),
                            },
                            {
                                value: false,
                                text: __('reporting:shared_when_child_leaves'),
                            },
                        ]"
                        button-size="sm"
                    />
                </MFieldset>
            </MForm>
        </div>
    </MBox>
</template>

<style scoped>
    .MeekoFamilyReportingCard {
        @apply tw-flex tw-flex-col tw-gap-4 tw-p-4;
    }

    .MeekoFamilyReportingCard__header {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2;
    }

    .MeekoFamilyReportingCard__container {
        @apply tw-flex tw-h-full tw-flex-col tw-gap-4;
    }

    .MeekoFamilyReportingCard__text {
        @apply tw-text-sm tw-text-gray-500;
    }

    .MeekoFamilyReportingCard__form {
        @apply tw-mt-auto;
    }
</style>
