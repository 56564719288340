<script lang="ts" setup>
    import {useRoute, useRouter} from 'vue-router';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useModal from '@/modules/app/composables/useModal';
    import UpdateContractsModal from '@/modules/family/components/contract/legacy/UpdateContracts/UpdateContractsModal.vue';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
        pdfDocuments: {
            type: Array,
            required: true,
        },
        billingConfigs: {
            type: Array,
            required: true,
        },
        sepaMandates: {
            type: Array,
            required: true,
        },
    });

    const updateContractsModal = useModal();
    const route = useRoute();
    const router = useRouter();
</script>

<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="billing">
                {{ __('app:menu.billing_settings') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MButton @click="updateContractsModal.show">
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-regular fa-sync" />
                </template>
                {{ __('family_kid:update_contracts') }}
            </MButton>
        </template>
        <template #content>
            <CTabMenu
                class="tw-mb-4"
                variant="orange"
            >
                <CTabMenuItem
                    :active="route.name === 'settings.billings.rules'"
                    variant="orange"
                    @click="router.push({name: 'settings.billings.rules', params: {nursery: nursery.id}})"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-cogs"
                    />
                    {{ __('billing_setting:my_configurations') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="route.name === 'settings.billings.general'"
                    variant="orange"
                    @click="router.push({name: 'settings.billings.general', params: {nursery: nursery.id}})"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-wrench"
                    />
                    {{ __('common:general') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="route.name === 'settings.billings.invoices'"
                    variant="orange"
                    @click="router.push({name: 'settings.billings.invoices', params: {nursery: nursery.id}})"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-file-alt"
                    />
                    {{ __('billing_setting:invoice_template') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="route.name === 'settings.billings.sepa'"
                    variant="orange"
                    @click="router.push({name: 'settings.billings.sepa', params: {nursery: nursery.id}})"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-university"
                    />
                    {{ __('billing_payment:payment_methods.sepa') }}
                </CTabMenuItem>
            </CTabMenu>
            <MBox class="tw-p-4">
                <router-view
                    :configs="billingConfigs"
                    :nursery="nursery"
                    :pdf-documents="pdfDocuments"
                    :sepas="sepaMandates"
                />
            </MBox>
            <UpdateContractsModal
                v-if="updateContractsModal.isVisible.value"
                :modal="updateContractsModal"
                :nursery="nursery"
            />
        </template>
    </SimplePageTemplate>
</template>
