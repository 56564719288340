<template>
    <div class="tw-relative tw-mb-1 tw-h-7 tw-w-full">
        <div
            v-for="(minute, i) in enumerateMinutes"
            v-show="showHour(i)"
            :key="'min' + minute"
            class="hour tw-absolute"
            :style="{left: unixToPercent(minute.unix(), moment(), opening, closing) + '%'}"
        >
            <CBadge
                :class="[{'!tw-px-1 !tw-text-sm': small}]"
                :rounded="true"
                size="sm"
                variant="primary"
            >
                {{ formatHours(minute) }}
            </CBadge>
        </div>
    </div>
</template>

<script lang="ts">
    import type {Moment} from 'moment';
    import moment from 'moment';
    import {computed, defineComponent} from 'vue';

    import useSegmentSizing from '@/modules/planning/composables/core/useSegmentSizing';

    export default defineComponent({
        props: {
            opening: {
                type: String,
                required: true,
            },
            closing: {
                type: String,
                required: true,
            },
            simple: {
                type: Boolean,
                default: false,
            },
            small: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const {unixToPercent} = useSegmentSizing();

            const enumerateMinutes = computed(() => {
                const minutes: Moment[] = [];

                const currentDate = moment()
                    .hour(moment.duration(props.opening).hours())
                    .minute(moment.duration(props.opening).minutes())
                    .second(0)
                    .add(30, 'minutes')
                    .startOf('hours');
                // Trick to round hours (7:45 become 8.00), not a step.

                const lastDate = moment()
                    .hour(moment.duration(props.closing).hours())
                    .minute(moment.duration(props.closing).minutes())
                    .second(0)
                    .add(30, 'minutes')
                    .startOf('hours');

                // Planning 24/24
                if (lastDate.hours() === 23 && lastDate.minutes() > 0) {
                    lastDate.add(1, 'hour');
                }

                minutes.push(currentDate.clone());
                while (currentDate.add(60, 'minutes').diff(lastDate) <= 0) {
                    minutes.push(currentDate.clone());
                }

                return minutes;
            });

            function showHour(index: number) {
                if (props.simple) {
                    return index % 2 === 0;
                }

                return true;
            }

            function formatHours(min: Moment) {
                const hourFormat = props.small ? 'H' : 'HH';

                return min.minutes() === 0 ? min.format(`${hourFormat}`) + 'h' : min.format(`${hourFormat}:mm`);
            }

            return {
                enumerateMinutes,
                moment,
                showHour,
                unixToPercent,
                formatHours,
            };
        },
    });
</script>

<style scoped>
    .hour {
        &:after {
            position: absolute;
            top: 92%;
            left: 50%;
            margin-left: -5px;
            border: solid transparent;
            border-width: 5px;
            border-top-color: #ddeefb;
            content: ' ';
        }
    }
</style>
