import {ValidationError} from '@meekohq/lumos';

import type {ApiResponseOutputPort} from '@/modules/core/domain/ports/ApiResponseOutputPort';
import type {FamilyAppSettingsEntity} from '@/modules/meeko-family/domain/entities/FamilyAppSettingsEntity';
import type {FamilyAppSettingsRepositoryPort} from '@/modules/meeko-family/domain/ports/FamilyAppSettingsRepositoryPort';
import type {StoreFamilyAppSettingsInputPort} from '@/modules/meeko-family/domain/use-cases/StoreFamilyAppSettingsInputPort';

export class StoreFamilyAppSettingsInteractor implements StoreFamilyAppSettingsInputPort {
    constructor(
        private readonly repository: FamilyAppSettingsRepositoryPort,
        private readonly presenter: ApiResponseOutputPort
    ) {}

    async execute(settings: FamilyAppSettingsEntity): Promise<unknown> {
        try {
            await this.repository.storeSettings(settings);

            return this.presenter.success();
        } catch (error: Error) {
            if (error instanceof ValidationError) {
                return this.presenter.validationError(error as ValidationError);
            }

            return this.presenter.unexpectedError(error);
        }
    }
}
