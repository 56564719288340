<template>
    <div class="TransactionListHeader">
        <div class="TransactionList__header-content">
            <div
                :class="
                    showCustomerInformations ? 'TransactionList__header-name' : 'TransactionList__header-name--expanded'
                "
            >
                <MagicIndexHeaderColumnTitle :title="__('common:name')" />
            </div>
            <div
                :class="
                    showCustomerInformations
                        ? 'TransactionList__header-allocation'
                        : 'TransactionList__header-allocation--expanded'
                "
            >
                <MagicIndexHeaderColumnTitle :title="__('billing_transaction:allocation')" />
            </div>
            <div class="TransactionList__header-date">
                <MagicIndexHeaderColumnTitle :title="__('common:date')" />
            </div>
            <div class="TransactionList__header-payment-method">
                <MagicIndexHeaderColumnTitle :title="__('billing_payment:payment_method')" />
            </div>
            <div
                v-if="showCustomerInformations"
                class="TransactionList__header-customer"
            >
                <MagicIndexHeaderColumnTitle :title="__('common:customer_one')" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import MagicIndexHeaderColumnTitle from '@/modules/magic-index/components/atoms/MagicIndexHeaderColumnTitle.vue';

    export default defineComponent({
        components: {
            MagicIndexHeaderColumnTitle,
        },
        props: {
            showCustomerInformations: {
                type: Boolean,
                default: true,
            },
        },
    });
</script>

<style scoped>
    .TransactionListHeader {
        container-name: TransactionListHeader;
        container-type: inline-size;
    }

    .TransactionList__header-content {
        @apply tw-hidden;

        @container TransactionListHeader (min-width: 48rem) {
            @apply tw-mt-2 tw-grid tw-grid-cols-24 tw-gap-3;
        }
    }

    .TransactionList__header-name {
        @apply tw-col-span-7;

        @container TransactionListHeader (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionList__header-name--expanded {
        @apply tw-col-span-13;

        @container TransactionListHeader (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionList__header-allocation {
        @apply tw-hidden;

        @container TransactionListHeader (min-width: 64rem) {
            @apply tw-col-span-7 tw-block;
        }
    }

    .TransactionList__header-allocation--expanded {
        @apply tw-hidden;

        @container TransactionListHeader (min-width: 64rem) {
            @apply tw-col-span-12 tw-block;
        }
    }

    .TransactionList__header-date {
        @apply tw-col-span-4;

        @container TransactionListHeader (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionList__header-payment-method {
        @apply tw-col-span-6;

        @container TransactionListHeader (min-width: 64rem) {
            @apply tw-col-span-5;
        }
    }

    .TransactionList__header-customer {
        @apply tw-col-span-6;

        @container TransactionListHeader (min-width: 64rem) {
            @apply tw-col-span-5;
        }
    }
</style>
