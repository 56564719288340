<template>
    <MModal
        :header-title="__('family_family:filoue_export')"
        :modal="modal"
    >
        <MForm>
            <MFieldset>
                <MLabel>
                    {{ __('family_family:unique_identifier_afas_omega') }}
                    <MHelpTooltip class="tw-ml-2">
                        {{ __('family_family:unique_identifier_afas_omega_helper') }}
                    </MHelpTooltip>
                </MLabel>
                <CInput
                    v-model="idequ"
                    :placeholder="__('family_family:unique_identifier_afas_omega_example')"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    {{ __('family_family:caf_number') }}
                    <MHelpTooltip class="tw-ml-2">
                        {{ __('family_family:caf_number_helper') }}
                    </MHelpTooltip>
                </MLabel>
                <CInput
                    v-model="caf_no"
                    :placeholder="__('family_family:caf_number_example')"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('common:year_one') }}</MLabel>
                <MYearPicker v-model="date" />
            </MFieldset>
        </MForm>

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :disabled="!idequ || !caf_no"
                :label="__('common:actions.export')"
                :loading="loading"
                variant="primary"
                @click="exportFiloue"
            />
        </template>
    </MModal>
</template>

<script lang="ts">
    import {Epoch, JsonAPISchema} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import usePopover from '@/modules/app/composables/usePopover';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import Manager from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            modal: {
                type: Object as PropType<ModalType>,
                required: true,
            },
        },
        setup(props) {
            const popover = usePopover();

            const date = ref(Epoch.now());

            const idequ = ref('');
            const caf_no = ref('');
            const loading = ref(false);

            function exportFiloue() {
                loading.value = true;
                useApi()
                    .legacy.post(
                        route('caf/filoue.download'),
                        new JsonAPISchema.JsonApiDocument(
                            new JsonAPISchema.JsonApiData({
                                from: date.value.startOfYear().toISOString(),
                                to: date.value.endOfYear().startOfSecond().toISOString(),
                                organization_id: `${Manager.legacyNursery.id}`,
                                idequ: idequ.value,
                                caf_no: caf_no.value,
                            })
                        )
                    )
                    .then(() => {
                        useNotification().success(__('family_family:data_sended_successfully'));
                        loading.value = false;
                        props.modal.hide();
                    })
                    .catch(() => {
                        loading.value = false;
                    });
            }

            return {
                popover,
                date,
                idequ,
                caf_no,
                loading,
                exportFiloue,
            };
        },
    });
</script>
