<template>
    <div>
        <div :style="{transform: 'rotate(' + randomRotate() + 'deg)'}">
            <i :class="[baseClass, loaderClass]" />
        </div>
    </div>
</template>

<script lang="ts">
    import _random from 'lodash-es/random';
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            size: {type: String},
            loaderClass: {type: String},
        },
        setup(props) {
            const baseClass = computed(() => {
                const finalClass = [] as string[];

                finalClass.push('fa-duotone fa-spin fa-spinner-third tw-text-blue-400');

                switch (props.size) {
                    case 'lg':
                        finalClass.push('tw-text-3xl');
                        break;
                    default:
                        finalClass.push('tw-text-2xl');
                        break;
                    case 'sm':
                        finalClass.push('tw-text-xl');
                        break;
                }

                return finalClass;
            });

            const randomRotate = function () {
                return _random(1, 360);
            };

            return {
                baseClass,
                randomRotate,
            };
        },
    });
</script>

<style scoped>
    .pulse {
        opacity: 0.1;
        animation: fading 1.5s infinite;
    }

    @keyframes fading {
        0% {
            opacity: 0.3;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.3;
        }
    }
</style>
