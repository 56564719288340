<script setup lang="ts">
    defineProps({
        active: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['click']);
</script>

<template>
    <div
        class="MTabsItem"
        :class="{'MTabsItem--active': active}"
        @click="emit('click')"
    >
        <slot />
    </div>
</template>

<style scoped>
    .MTabsItem {
        @apply tw-font-inter;
        @apply tw-whitespace-nowrap tw-border-b-2 tw-px-1 tw-py-4 tw-font-medium;
        @apply tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700;
        @apply tw-cursor-pointer;
        @apply tw-truncate;
    }

    .MTabsItem--active {
        @apply tw-border-primary-500 tw-text-primary-600;
        @apply hover:tw-border-primary-500 hover:tw-text-primary-600;
    }
</style>
