<template>
    <CModal
        :header-title="selectedEquipment.id ? __('equipment:update_equiment') : __('equipment:add_equipment')"
        visible
        @closed="$emit('closed')"
    >
        <CInput
            v-model="selectedEquipment.name"
            :placeholder="__('equipment:equipment_name')"
        />
        <div class="tw-mt-2 tw-text-sm">
            <FontAwesomeIcon
                class="tw-mr-1 tw-text-info-500"
                icon="fa-regular fa-info-circle"
            />
            {{ __('equipment:check_equipment_explanation') }}
        </div>
        <template #footer>
            <div class="tw-flex tw-w-full tw-justify-end tw-gap-3">
                <MButton @click="$emit('closed')">
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    :disabled="loading"
                    variant="primary"
                    @click="$emit('add')"
                >
                    <template v-if="selectedEquipment.id">
                        {{ __('common:actions.update') }}
                    </template>
                    <template v-else>
                        {{ __('common:actions.add') }}
                    </template>
                </MButton>
            </div>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            selectedEquipment: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
        },
        emits: ['closed', 'add'],
    });
</script>
