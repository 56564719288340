import {app} from '@meekohq/lumos';
import {includes, isArray} from 'lodash-es';

import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type {ActionsType, SubjectsType} from '@/modules/user/utils/ActionsType';
import type {GateContract} from '@/modules/user/utils/GateContract';
import {GateContractBinding} from '@/modules/user/utils/GateContract';

export interface LegacyPermission {
    name: string;
    actions: ActionsType[];
}

export default class LegacyUserGate implements GateContract {
    private readonly _permissions: LegacyPermission[];

    constructor(permissions: LegacyPermission[] = []) {
        this._permissions = permissions;
    }

    /**
     * Get the user.
     *
     * @param user
     * @param organization
     */
    public for(user: any, organization: OrganizationModel) {
        const permissions = user?.nurseries?.find((o: any) => o.id === organization.getKey())?.permissions ?? [];

        return app(GateContractBinding, permissions);
    }

    /**
     * Determine if all of the given abilities should be granted for the current user.
     *
     * @param abilities
     * @param args
     */
    public check(abilities: ActionsType | ActionsType[], args: SubjectsType): boolean {
        for (const ability of this.wrap(abilities)) {
            // Find if given ressource exists
            const permissionForRessource = this._permissions.find(item => item.name === args);

            if (!permissionForRessource) {
                // Ressource doesn't event exist in user permissions
                return false;
            }

            // Get all actions for the given ressource
            const userActions = permissionForRessource.actions;

            // Check if selected action is in user permissions actions
            if (!includes(userActions, ability)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Determine if any one of the given abilities should be granted for the current user.
     *
     * @param abilities
     * @param args
     */
    public any(abilities: ActionsType | ActionsType[], args: SubjectsType): boolean {
        for (const ability of this.wrap(abilities)) {
            if (this.check([ability], args)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Determine if all of the given abilities should be denied for the current user.
     *
     * @param abilities
     * @param args
     */
    public none(abilities: ActionsType | ActionsType[], args: SubjectsType): boolean {
        return !this.any(abilities, args);
    }

    protected wrap(data: any) {
        return isArray(data) ? data : [data];
    }
}
