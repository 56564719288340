<template>
    <div>
        <support-meeko :ressource="selectedConfig" />
        <div class="tw-mb-8 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2">
            <div>
                <MFieldset v-if="selectedConfig.id === 0">
                    <MLabel>{{ __('common:name_colon') }}</MLabel>
                    <MInput
                        v-model="selectedConfig.name"
                        class="tw-w-64 sm:tw-w-96"
                        :placeholder="__('billing_invoice:configuration_name')"
                    />
                </MFieldset>
                <MFieldset v-else-if="nurseryConfigs.length > 1">
                    <MLabel>{{ __('billing_setting:choose_an_configuration_colon') }}</MLabel>
                    <MSelectMenu
                        v-model="selectedConfig.id"
                        :options="nurseryConfigs"
                        text-path="name"
                        value-path="id"
                        search-bar
                        @update:model-value="switchConfig"
                    />
                </MFieldset>
                <MHeading v-else>{{ __('billing_setting:configuration_colon') }} {{ selectedConfig.name }}</MHeading>
                <CCheckbox
                    v-if="nurseryConfigs.length > 1"
                    v-model="selectedConfig.default"
                    class="tw-mt-2"
                >
                    {{ __('billing_setting:apply_this_default_configuration_for_new_contracts') }}
                </CCheckbox>
            </div>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                <MButton
                    v-if="selectedConfig.id"
                    @click="duplicate"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-copy" />
                    </template>
                    {{ __('common:actions.duplicate') }}
                </MButton>
                <MTooltip
                    v-if="otherNurseriesConfigs && otherNurseriesConfigs.length"
                    :label="__('common:import_from_another_organization')"
                    placement="top-end"
                >
                    <MButton
                        v-if="otherNurseriesConfigs && otherNurseriesConfigs.length"
                        @click="importConfig"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-download" />
                        </template>
                        {{ __('common:actions.import') }}
                    </MButton>
                </MTooltip>
                <MButton
                    v-if="nurseryConfigs.length"
                    icon-plus
                    :label="__('billing_setting:new_configuration')"
                    variant="primary"
                    @click="addConfig"
                />
            </div>
        </div>

        <template v-if="!selectedConfig.empty">
            <div class="tw-flex tw-flex-col tw-gap-4">
                <BasicCard
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <PsuPriceCard
                    v-if="selectedConfig.funding === 'psu' || selectedConfig.funding === 'psu_cmg'"
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <NormalPriceCard
                    v-else
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <FeesCard
                    :config="selectedConfig"
                    :nursery="nursery"
                />
                <ExtraCard
                    :config="selectedConfig"
                    :nursery="nursery"
                />
            </div>

            <div class="tw-mt-8 tw-flex tw-flex-wrap tw-justify-between tw-gap-2">
                <div class="tw-flex tw-items-center tw-gap-2">
                    <MTooltip
                        v-if="selectedConfig.id"
                        :label="__('common:actions.delete')"
                    >
                        <MButton
                            variant="danger"
                            @click="destroyConfig"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </MButton>
                    </MTooltip>
                    <MTooltip
                        v-if="selectedConfig.id"
                        :label="__('common:remove_from_this_organization')"
                    >
                        <MButton @click="detachConfig">
                            <FontAwesomeIcon icon="fa-solid fa-link" />
                        </MButton>
                    </MTooltip>
                </div>

                <div class="tw-flex tw-items-center tw-gap-2">
                    <MButton
                        :label="__('common:actions.reset')"
                        @click="cancel"
                    />
                    <MButton
                        v-if="selectedConfig.id"
                        :label="__('common:actions.save')"
                        :loading="loading"
                        variant="primary"
                        @click="save"
                    />
                    <MButton
                        v-else
                        :label="__('billing_setting:create_this_configuration')"
                        :loading="loading"
                        variant="primary"
                        @click="storeConfig"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _orderBy from 'lodash-es/orderBy';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import Basic from '@/modules/cashier/components/settings/RulesCards/Basic.vue';
    import Extra from '@/modules/cashier/components/settings/RulesCards/Extra.vue';
    import Fees from '@/modules/cashier/components/settings/RulesCards/Fees.vue';
    import PriceNormal from '@/modules/cashier/components/settings/RulesCards/Price/Normal.vue';
    import PricePSU from '@/modules/cashier/components/settings/RulesCards/Price/PSU.vue';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            BasicCard: Basic,
            FeesCard: Fees,
            ExtraCard: Extra,
            NormalPriceCard: PriceNormal,
            PsuPriceCard: PricePSU,
        },
        mixins: [nursery],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            configs: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                nurseryConfigs: [],
                otherNurseriesConfigs: [],
                selectedConfig: _cloneDeep(defaultConfig),
                savedId: null,
                presetModeOptions: [
                    {
                        text: __('common:none'),
                        value: 'free',
                    },
                    {
                        text: 'PAJE',
                        value: 'paje',
                    },
                    {
                        text: 'PSU',
                        value: 'psu',
                    },
                ],
            };
        },
        watch: {
            'selectedConfig.funding': function () {
                if (this.selectedConfig.funding === 'psu' && !this.selectedConfig.id) {
                    this.selectedConfig.billing.cycle = 'hour';
                    this.selectedConfig.smoothing.on = 'contract';
                }
            },
            'selectedConfig.id': function (val, oldVal) {
                this.savedId = oldVal ? oldVal : null;
            },
            'selectedConfig.billing.cycle': function (val) {
                if (val === 'month') {
                    this.selectedConfig.pricing.discount.on_presence = 'none';
                }
            },
        },
        mounted() {
            this.indexConfigs();
        },
        methods: {
            indexConfigs() {
                this.nurseryConfigs = [];
                this.otherNurseriesConfigs = [];

                if (this.configs) {
                    this.configs.forEach(item => {
                        const configBelongsToNursery = this.nursery.billingConfigs.find(doc => doc.id === item.id);
                        if (configBelongsToNursery) {
                            this.nurseryConfigs.push(configBelongsToNursery);
                        } else {
                            this.otherNurseriesConfigs.push(item);
                        }
                    });
                }

                this.nurseryConfigs = _orderBy(this.nurseryConfigs, 'default', 'desc');

                this.nurseryConfigs[0]
                    ? (this.selectedConfig = _cloneDeep(this.nurseryConfigs[0]))
                    : (this.selectedConfig = _cloneDeep(defaultConfig));
            },

            storeConfig() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                const data = this.selectedConfig;
                data.nursery_id = this.nursery.id;
                useApi()
                    .legacy.post(route('billingConfigs.store'), data)
                    .then(response => {
                        this.configs.push(response.data);

                        return this.attachConfig(response.data.id);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            save() {
                useMagicModal().confirmationWithInputModal({
                    title: __('billing_setting:save_current_configuration'),
                    confirmButtonText: __('common:actions.save'),
                    placeholderText: __('billing_invoice:configuration_name'),
                    inputValue: this.selectedConfig.name,
                    onConfirm: async result => {
                        this.selectedConfig.name = result;
                        await this.updateConfig();
                    },
                });
            },

            async updateConfig() {
                const data = this.selectedConfig;
                data.nursery_id = this.nursery.id;
                if (data.default === null) {
                    data.default = undefined;
                }

                data.overruns.recurrent.amount = data.overruns.recurrent.amount ?? null;
                data.occasionals.amount = data.occasionals.amount ?? null;
                data.overruns.occasional.amount = data.overruns.occasional.amount ?? null;
                data.adaptations.amount = data.adaptations.amount ?? null;

                await useApi()
                    .legacy.put(
                        route('billingConfigs.update', {
                            billingConfig: this.selectedConfig.id,
                        }),
                        data
                    )
                    .then(response => {
                        if (this.selectedConfig.default) {
                            this.nurseryConfigs.forEach(config => {
                                set(config, 'default', false);
                            });

                            this.nursery.billingConfigs.forEach(config => {
                                set(config, 'default', false);
                            });
                        }

                        const configToUpdate = this.configs.find(item => item.id === this.selectedConfig.id);
                        if (configToUpdate) {
                            const index = this.configs.indexOf(configToUpdate);
                            set(this.configs, index, response.data);
                        }

                        const nurseryConfigToUpdate = this.nurseryConfigs.find(
                            item => item.id === this.selectedConfig.id
                        );
                        if (nurseryConfigToUpdate) {
                            const index = this.nurseryConfigs.indexOf(nurseryConfigToUpdate);
                            set(this.nurseryConfigs, index, response.data);
                        }

                        const configBelongsToNursery = this.nursery.billingConfigs.find(
                            item => item.id === this.selectedConfig.id
                        );
                        if (configBelongsToNursery) {
                            const index = this.nursery.billingConfigs.indexOf(configBelongsToNursery);
                            set(this.nursery.billingConfigs, index, response.data);
                        }

                        useNotification().success(__('billing_setting:configuration_updated_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            attachConfig(configId) {
                return useApi()
                    .legacy.post(
                        route('nurseries.billingConfigs.attach', {
                            nurseries: this.nursery.id,
                            billingConfig: configId,
                        })
                    )
                    .then(response => {
                        this.nurseryConfigs.push(response.data);
                        this.nursery.billingConfigs.push(response.data);
                        const configToRemove = this.otherNurseriesConfigs.find(item => item.id === response.data.id);
                        if (configToRemove) {
                            const index = this.otherNurseriesConfigs.indexOf(configToRemove);
                            this.otherNurseriesConfigs.splice(index, 1);
                        }
                        this.selectedConfig = _cloneDeep(response.data);
                        useNotification().success(__('billing_setting:configuration_added_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            detachConfig() {
                useMagicModal().confirmationModal({
                    title: __('billing_setting:detach_configuration_confirmation'),
                    text: __('billing_setting:configuration_will_no_longer_visible_on_this_organization'),
                    confirmButtonText: __('common:actions.detach'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.post(
                                route('nurseries.billingConfigs.detach', {
                                    nurseries: this.nursery.id,
                                    billingConfig: this.selectedConfig.id,
                                })
                            )
                            .then(response => {
                                const configToRemove = this.nurseryConfigs.find(item => item.id === response.data.id);
                                if (configToRemove) {
                                    const index = this.nurseryConfigs.indexOf(configToRemove);
                                    this.nurseryConfigs.splice(index, 1);
                                }

                                const configToRemoveFromNursery = this.nursery.billingConfigs.find(
                                    item => item.id === response.data.id
                                );
                                if (configToRemoveFromNursery) {
                                    const index = this.nursery.billingConfigs.indexOf(configToRemoveFromNursery);
                                    this.nursery.billingConfigs.splice(index, 1);
                                }

                                this.otherNurseriesConfigs.push(response.data);
                                this.nurseryConfigs[0]
                                    ? (this.selectedConfig = _cloneDeep(this.nurseryConfigs[0]))
                                    : (this.selectedConfig = _cloneDeep(defaultConfig));
                                useNotification().success(__('billing_setting:configuration_took_off_successfully'));
                            })
                            .catch(error => {
                                if (error && error.response && error.response.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            destroyConfig() {
                useMagicModal().deleteConfirmationModal({
                    title: __('billing_setting:configuration_delete_confirmation'),
                    text: __('billing_setting:configuration_name_to_delete', {name: this.selectedConfig.name}),
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('billingConfigs.destroy', {
                                    billingConfig: this.selectedConfig.id,
                                })
                            )
                            .then(() => {
                                const configToDelete = this.nurseryConfigs.find(
                                    item => item.id === this.selectedConfig.id
                                );
                                if (configToDelete) {
                                    const index = this.nurseryConfigs.indexOf(configToDelete);
                                    this.nurseryConfigs.splice(index, 1);
                                }

                                const configToDeleteFromGlobal = this.configs.find(
                                    item => item.id === this.selectedConfig.id
                                );
                                if (configToDeleteFromGlobal) {
                                    const index = this.configs.indexOf(configToDeleteFromGlobal);
                                    this.configs.splice(index, 1);
                                }

                                const configBelongsToNursery = this.nursery.billingConfigs.find(
                                    item => item.id === this.selectedConfig.id
                                );
                                if (configBelongsToNursery) {
                                    const index = this.nursery.billingConfigs.indexOf(configBelongsToNursery);
                                    this.nursery.billingConfigs.splice(index, 1);
                                }

                                useNotification().success(__('billing_setting:configuration_deleted_successfully'));
                                this.nurseryConfigs[0]
                                    ? (this.selectedConfig = _cloneDeep(this.nurseryConfigs[0]))
                                    : (this.selectedConfig = _cloneDeep(defaultConfig));
                            })
                            .catch(error => {
                                if (error && error.response && error.response.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            duplicate() {
                const newConfig = _cloneDeep(this.selectedConfig);

                useMagicModal().confirmationWithInputModal({
                    title: __('billing_setting:duplicate_configuration_confirmation'),
                    confirmButtonText: __('common:actions.duplicate'),
                    placeholderText: __('billing_invoice:configuration_name'),
                    inputValue: __('billing_setting:configuration_name_copy', {name: this.selectedConfig.name}),
                    onConfirm: result => {
                        newConfig.id = 0;
                        newConfig.name = result;
                        this.selectedConfig = _cloneDeep(newConfig);
                    },
                });
            },

            addConfig() {
                useMagicModal().confirmationWithInputModal({
                    title: __('billing_setting:new_configuration'),
                    text: __('billing_setting:what_is_the_name_of_this_configuration'),
                    confirmButtonText: __('common:actions.validate'),
                    onConfirm: result => {
                        const newConfig = _cloneDeep(defaultConfig);
                        newConfig.name = result;
                        this.selectedConfig = newConfig;
                    },
                });
            },

            importConfig() {
                const options = this.otherNurseriesConfigs.reduce((acc, item) => {
                    if (item.id) acc.push({value: item.id, text: item.name});
                    return acc;
                }, []);

                useMagicModal().confirmationWithSelectModal({
                    title: __('billing_setting:use_configuration_of_another_organization'),
                    confirmButtonText: __('common:actions.validate'),
                    options: options,
                    defaultOption: options[0].value,
                    onConfirm: async result => {
                        if (result) {
                            await this.attachConfig(result);
                        }
                    },
                });
            },

            switchConfig(val) {
                useMagicModal().confirmationModal({
                    title: __('billing_setting:update_configuration_confirmation'),
                    text: __('billing_setting:unsaved_settings_of_the_configuration_will_be_lost'),
                    type: 'warning',
                    onConfirm: async () => {
                        this.selectedConfig = _cloneDeep(this.nurseryConfigs.find(item => item.id === val));
                    },
                    onDismiss: async () => {
                        this.selectedConfig.id = this.savedId;
                    },
                });
            },

            cancel() {
                useMagicModal().confirmationModal({
                    title: __('billing_setting:reset_modifications_confirmation'),
                    text: __('billing_setting:unsaved_settings_will_be_lost'),
                    confirmButtonText: __('common:actions.reset'),
                    onConfirm: async () => {
                        if (this.selectedConfig.id) {
                            const oldConfig = this.nurseryConfigs.find(item => item.id === this.selectedConfig.id);
                            if (oldConfig) {
                                this.selectedConfig = _cloneDeep(oldConfig);
                            }
                        } else {
                            this.selectedConfig = this.nurseryConfigs.length
                                ? _cloneDeep(this.nurseryConfigs[0])
                                : _cloneDeep(defaultConfig);
                        }
                    },
                });
            },
        },
    };
</script>
