import type {LengthAwarePaginator} from '@meekohq/lumos';
import {app} from '@meekohq/lumos';
import {type InjectionKey, ref} from 'vue';

import useAuth from '@/modules/app/composables/useAuth';
import usePaginatorHelpers from '@/modules/app/composables/usePaginatorHelpers';
import {PaginatePersonalAccessTokenRequest} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenRequest';
import {PaginatePersonalAccessTokenUseCaseBinding} from '@/modules/auth/personal-access-token/application/PaginatePersonalAccessTokenUseCase';
import {ConsumerType} from '@/modules/auth/personal-access-token/domain/ConsumerType';
import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';

export const injectKey = Symbol() as InjectionKey<ReturnType<typeof usePersonalAccessTokensListState>>;

export const usePersonalAccessTokensListState = () => {
    const {user} = useAuth();

    const isFetching = ref(false);
    const paginator = ref<LengthAwarePaginator<PersonalAccessTokenModel> | undefined>(undefined);

    async function onCreated() {
        await paginate(paginator.value?.currentPage() ?? 1);
    }

    function onUpdated(item: PersonalAccessTokenModel) {
        const itemIndex = paginator.value
            ?.items()
            .all()
            .findIndex(indexed => indexed.getKey() === item.getKey());
        if (itemIndex === -1) {
            return;
        }

        paginator.value?.items().put(itemIndex, item);
    }

    async function onDeleted(item: PersonalAccessTokenModel) {
        if (!paginator.value) {
            return;
        }

        const paginatorHelpers = usePaginatorHelpers(paginator.value);

        const newPaginator = paginatorHelpers.newFilteredPaginator(a => a.getKey() === item.getKey());
        const desynchronized = paginatorHelpers.isNewPaginatorDesynchronized(newPaginator);
        paginator.value = newPaginator as LengthAwarePaginator<PersonalAccessTokenModel>;

        if (desynchronized) {
            await paginate(paginator.value.currentPage());
        }
    }

    async function paginate(newPage = 1) {
        isFetching.value = true;

        try {
            paginator.value = await app(PaginatePersonalAccessTokenUseCaseBinding).paginate(
                PaginatePersonalAccessTokenRequest.make({
                    userId: user.value?.getKey(),
                    consumerType: ConsumerType.third,
                    page: newPage,
                    perPage: 20,
                })
            );
        } finally {
            isFetching.value = false;
        }
    }

    return {
        isFetching,
        paginator,
        onCreated,
        onUpdated,
        onDeleted,
        paginate,
    };
};
