import Bugsnag from '@bugsnag/js';
import {app, type ValidationError} from '@meekohq/lumos';
import {ref} from 'vue';
import {useRouter} from 'vue-router';

import useError from '@/modules/app/composables/useError';
import __ from '@/modules/app/utils/i18n-facade';
import {KidCopyInputPortBinding} from '@/modules/family/domain/ports/KidCopyInputPort';
import useNotification from '@/modules/meeko-ui/composables/useNotification';

export function useKidCopy() {
    const isCopying = ref(false);
    const {error: notifyError} = useNotification();
    const errors = useError();
    const router = useRouter();

    async function copy(kidId: string, organizationId: string) {
        isCopying.value = true;

        errors.reset();

        const interactor = app(KidCopyInputPortBinding, {
            success(newKidId: string) {
                isCopying.value = false;
                router.push({
                    name: 'kids.show',
                    params: {
                        nursery: organizationId,
                        kid: newKidId,
                    },
                });
            },
            validationError(error: ValidationError) {
                errors.addValidationError(error);
            },
            unexpectedError(error: Error) {
                notifyError(__('common:errors.generic'));
                Bugsnag.notify(error);
            },
        });

        await interactor.execute(kidId, organizationId);

        isCopying.value = false;
    }

    return {
        isCopying,
        copy,
        errors,
    };
}
