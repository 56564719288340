<template>
    <component
        :is="as"
        :class="baseClass"
    >
        <slot />
    </component>
</template>

<script lang="ts">
    import {isNumber} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    type Gap = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
    type Distribute = 'none' | 'around' | 'between' | 'evenly' | 'start' | 'center' | 'end';
    type Align = 'none' | 'start' | 'center' | 'end' | 'baseline' | 'stretch';

    export default defineComponent({
        props: {
            distribute: {type: String as PropType<Distribute>, default: 'none'},
            align: {type: String as PropType<Align>, default: 'none'},
            gap: {type: [String, Number] as PropType<Gap>, default: '0'},
            reverse: {type: Boolean as PropType<boolean>, default: false},
            wrap: {type: Boolean as PropType<boolean>, default: false},
            as: {type: String, default: 'div'},
        },
        setup(props) {
            const {baseClass} = useStyling(props);

            return {baseClass};
        },
    });

    function useStyling(props) {
        const baseClass = computed(() => {
            const output = [] as any[];

            output.push('CHStack');

            if (props.reverse) {
                output.push('CHStack--reverse');
            }

            if (props.wrap) {
                output.push('CHStack--wrap');
            }

            output.push(distributeValues[props.distribute]);

            output.push(alignValues[props.align]);

            const gapAsNumber = isNumber(props.gap) ? props.gap : parseInt(props.gap);
            output.push(gapValues[gapAsNumber]);

            return output;
        });

        return {baseClass};
    }

    export const distributeValues = {
        none: '',
        around: 'CHStack--distribute-around',
        between: 'CHStack--distribute-between',
        evenly: 'CHStack--distribute-evenly',
        start: 'CHStack--distribute-start',
        center: 'CHStack--distribute-center',
        end: 'CHStack--distribute-end',
    };

    export const alignValues = {
        none: '',
        stretch: 'CHStack--align-stretch',
        baseline: 'CHStack--align-baseline',
        start: 'CHStack--align-start',
        center: 'CHStack--align-center',
        end: 'CHStack--align-end',
    };

    export const gapValues = {
        0: '',
        1: 'CHStack--gap-1',
        2: 'CHStack--gap-2',
        3: 'CHStack--gap-3',
        4: 'CHStack--gap-4',
        5: 'CHStack--gap-5',
        6: 'CHStack--gap-6',
        7: 'CHStack--gap-7',
        8: 'CHStack--gap-8',
    };
</script>
