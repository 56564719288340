import {Epoch} from '@meekohq/lumos';
import {isNil} from 'lodash-es';

import {BaseRule} from '@/modules/app/utils/rules/BaseRule';

export class IsDateRule extends BaseRule {
    public isValid(value: any): boolean {
        if (isNil(value)) {
            return false;
        }

        return Epoch.parse(value, 'yyyy-MM-dd').isValid;
    }
}
