<template>
    <div :class="[lightContent ? 'tw-text-white' : 'tw-text-gray-600']">
        <div
            v-for="(file, i) in files"
            :key="'attachedFile' + i"
            class="tw-flex tw-items-center tw-py-1 tw-text-sm"
        >
            <a
                class="tw-truncate tw-text-inherit"
                :href="file.file_url"
                target="_blank"
            >
                {{ file.name }}
            </a>
            <div class="tw-ml-auto">
                <div
                    v-if="file.created_at !== file.updated_at"
                    class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-font-semibold"
                >
                    <FontAwesomeIcon
                        v-if="small"
                        icon="fa-solid fa-check"
                    />
                    <template v-else>
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-check"
                        />
                        {{ __('common:saved') }}
                    </template>
                </div>
                <template v-else>
                    <MPopover
                        ref="attachedFilesPopover"
                        placement="left"
                    >
                        <MTooltip
                            v-if="small && lightContent"
                            :label="__('document:save_document')"
                        >
                            <button
                                class="zoom tw-whitespace-nowrap tw-px-2 tw-text-inherit"
                                size="sm"
                            >
                                <FontAwesomeIcon
                                    v-if="small"
                                    icon="fa-solid fa-file-download"
                                />
                            </button>
                        </MTooltip>
                        <MButton
                            v-else
                            size="sm"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-duotone fa-file-download" />
                            </template>
                            {{ __('common:actions.save') }}
                        </MButton>
                        <template #content>
                            <MForm
                                v-if="Manager.activeOrganization && Manager.activeOrganization.id"
                                class="tw-p-2"
                                @submit.prevent
                            >
                                <MFieldset>
                                    <MLabel>
                                        {{ __('document:save_to_colon') }}
                                    </MLabel>
                                    <CButtonGroup class="tw-w-full">
                                        <MButton
                                            class="tw-w-1/2"
                                            :variant="nav === 'children' ? 'primary' : 'light'"
                                            @click="nav = 'children'"
                                        >
                                            <template #left-icons>
                                                <FontAwesomeIcon icon="fa-solid fa-child-reaching" />
                                            </template>
                                            {{ __('common:kid_other') }}
                                        </MButton>
                                        <MButton
                                            class="tw-w-1/2"
                                            :variant="nav === 'members' ? 'primary' : 'light'"
                                            @click="nav = 'members'"
                                        >
                                            <template #left-icons>
                                                <FontAwesomeIcon icon="fa-solid fa-user-tie" />
                                            </template>
                                            {{ __('common:parent_other') }}
                                        </MButton>
                                    </CButtonGroup>
                                </MFieldset>
                                <MFieldset>
                                    <SelectKid
                                        v-if="nav === 'children'"
                                        v-model="selectedKid"
                                        :add-option-enabled="false"
                                        button-class="tw-w-full"
                                        :create-organization-model="Manager.activeOrganization"
                                        :extend-builder="extendKidBuilder"
                                        :fallback-text="__('document:select_kid')"
                                        :filter-organization-model="Manager.activeOrganization"
                                        :has-unselect="true"
                                        :unselect-text="__('document:unselect_kid')"
                                    />
                                    <SelectMember
                                        v-else
                                        v-model="selectedMember"
                                        button-class="tw-w-full"
                                        :create-option-enabled="false"
                                        :extend-builder="extendMemberBuilder"
                                        :fallback-text="__('document:select_member')"
                                        :has-unselect="true"
                                        :organization-model="Manager.activeOrganization"
                                        :unselect-text="__('document:unselect_member')"
                                    />
                                </MFieldset>
                                <MFieldset>
                                    <MButton
                                        class="tw-w-full"
                                        :loading="attaching"
                                        variant="primary"
                                        @click="attachFile(file, i)"
                                    >
                                        <template #left-icons>
                                            <FontAwesomeIcon icon="fa-solid fa-file-download" />
                                        </template>
                                        {{ __('document:save_document') }}
                                    </MButton>
                                </MFieldset>
                            </MForm>
                            <div
                                v-else
                                class="tw-p-4"
                            >
                                <div>{{ __('document:please_select_an_organization_before_save_document') }}</div>
                            </div>
                        </template>
                    </MPopover>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import {computed, type PropType, reactive, ref, watch} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import SelectKid from '@/modules/family/components/kid/SelectKid.vue';
    import SelectMember from '@/modules/family/components/member/SelectMember.vue';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import KidModel from '@/modules/family/models/KidModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import route from '@/modules/legacy/libs/ziggy';
    import Manager from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    const props = defineProps({
        files: {
            type: Array as PropType<
                {
                    id: string;
                    name: string;
                    file_url: string;
                    created_at: string;
                    updated_at: string;
                }[]
            >,
            default: () => reactive([]),
        },
        memberId: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        lightContent: {
            type: Boolean,
            default: false,
        },
    });

    const attachedFilesPopover = ref();
    const nav = ref('children');
    const selectedMember = ref<MemberModel | null>(null);
    const selectedKid = ref<KidModel | null>(null);
    const attaching = ref(false);

    const extendKidBuilder = computed(() => {
        return KidModel.query().whereHas(new KidModel().family(), query => {
            query.whereHas(new FamilyModel().members(), query1 => {
                query1.where('id', props.memberId);
            });
        });
    });

    const extendMemberBuilder = computed(() => {
        return MemberModel.query().whereHas(new MemberModel().families(), query => {
            query.whereHas(new FamilyModel().members(), query1 => {
                query1.where('id', props.memberId);
            });
        });
    });

    watch(nav, () => {
        selectedKid.value = null;
        selectedMember.value = null;
    });

    function attachFile(file: {id: string; updated_at: string}, i: number) {
        attaching.value = true;

        let documentable = {
            documentable_type: '',
            documentable_id: '',
        };

        if (nav.value === 'children' && selectedKid.value) {
            documentable = {
                documentable_type: 'kids',
                documentable_id: selectedKid.value.id as string,
            };
        } else if (nav.value === 'members' && selectedMember.value) {
            documentable = {
                documentable_type: 'familyMembers',
                documentable_id: selectedMember.value.id as string,
            };
        } else {
            attaching.value = false;
            useNotification().error(__('document:please_select_a_person'));

            return;
        }

        useApi()
            .legacy.post(
                route('documents.attach', {
                    document: file.id,
                }),
                documentable
            )
            .then(response => {
                if (attachedFilesPopover.value && attachedFilesPopover.value[i]) {
                    attachedFilesPopover.value[i].closePopover();
                }

                // Mise à jour de l'updated_at, car le document est considéré comme attaché sur une fiche
                // si l'updated_at a été mis à jour depuis le created_at.
                // A terme, il faudra enregistrer une colonne pour stocker cette information dans la table document
                // pour éviter ce tricks.
                file.updated_at = response.data.updated_at;
                attaching.value = false;
            })
            .catch(error => {
                attaching.value = false;
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            });
    }
</script>
