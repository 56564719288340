<script setup lang="ts">
    import type {Placement} from '@floating-ui/vue';
    import {inject, type PropType, provide, ref, toRef} from 'vue';

    import {MMenuState} from '@/modules/meeko-ui/components/Elements/MMenu/MMenuState';
    import {defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const props = defineProps({
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start',
            validator: (value: string) =>
                [
                    'top',
                    'top-start',
                    'top-end',
                    'right',
                    'right-start',
                    'right-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                ].includes(value),
        },
        arrow: {
            type: Boolean,
            default: false,
        },
    });

    const context = defineFloatingContext({
        placement: toRef(props, 'placement'),
        trigger: ref('click'),
        arrow: toRef(props, 'arrow'),
        offset: ref(props.arrow ? 11 : 5),
    });

    const {setParentContext} = context;

    setParentContext(inject<ReturnType<typeof defineFloatingContext> | undefined>('MFloatingContext', undefined));

    provide('MFloatingContext', context);

    const state = MMenuState();

    provide('MMenuState', state);
</script>

<template>
    <div class="MMenu">
        <slot />
    </div>
</template>

<style scoped>
    .MMenu {
        @apply tw-inline-flex;
    }
</style>
