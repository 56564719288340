<template>
    <CModal
        ref="modal"
        :emitter="bus"
        :header-title="__('billing_customer:add_contact')"
        visible
        @hidden="$emit('hidden')"
    >
        <ContactForm
            ref="form"
            :customer-model="customerModel"
            @loading="loader = $event"
            @saved="onSaved"
        />
        <template #footer="{closeDialog}">
            <MButton
                class="tw-ml-auto"
                @click="closeDialog"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loader"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import mitt from 'mitt';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    import ContactForm from '@/modules/cashier/components/core/contact/organisms/ContactForm.vue';
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';

    export default defineComponent({
        components: {
            ContactForm,
        },
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        emits: ['hidden', 'created'],

        setup(pros, {emit}) {
            const loader = ref(false);

            const bus = mitt<{hide: void}>();

            function onSaved(contact: ContactModel) {
                bus.emit('hide');
                emit('created', contact);
            }

            return {
                bus,
                loader,
                onSaved,
            };
        },
    });
</script>
