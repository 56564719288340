<template>
    <div class="MStepPanelHeader">
        <div class="MStepPanelHeader__content">
            <slot />
        </div>
    </div>
</template>

<style scoped>
    .MStepPanelHeader__content {
        @apply md:tw-flex;
        @apply tw-rounded-xl;
        @apply tw-divide-y tw-divide-gray-200 md:tw-divide-y-0;
        @apply tw-border tw-border-gray-200;
    }
</style>
