import {Binding} from '@meekohq/lumos';

import type {GetReportingSettingsOutputPort} from '@/modules/meeko-family/domain/use-cases/GetReportingSettingsOutputPort';

export const GetReportingSettingsInputPortBinding = new Binding<
    GetReportingSettingsInputPort,
    GetReportingSettingsOutputPort
>();

export interface GetReportingSettingsInputPort {
    execute(organizationId: string): Promise<unknown>;
}
