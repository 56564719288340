import {nextTick, ref} from 'vue';

import useRuleRef from '@/modules/calendar/composables/RuleSet/useRuleRef';
import Rule from '@/modules/calendar/utils/RuleSet/Rule';

export default function () {
    const rule = ref<Rule>();
    const ruleCompo = ref();

    const {update: updateRule} = useRuleRef(rule);

    function init(param) {
        rule.value = new Rule();

        updateRule(param);

        nextTick(() => {
            if (ruleCompo.value?.ruleDropdown) {
                ruleCompo.value.ruleDropdown.show();
            }
        });
    }

    function reset() {
        rule.value = undefined;
    }

    return {
        rule,
        ruleCompo,
        init,
        reset,
    };
}
