<template>
    <div :class="[{hover: isHover}, {line: line}]">
        <img
            v-if="icon === 'motricity'"
            :alt="__('family_kid:motricity')"
            src="@/assets/images/activities/motricity.svg"
            :width="small ? '20px' : '33px'"
        />
        <img
            v-else-if="icon === 'baby'"
            :alt="__('family_kid:baby')"
            src="@/assets/images/activities/baby.svg"
            :width="small ? '30px' : '45px'"
        />
        <img
            v-else-if="icon === 'handcraft'"
            :alt="__('family_kid:handcraft')"
            src="@/assets/images/activities/handcraft.svg"
            :width="small ? '35px' : '60px'"
        />
        <img
            v-else-if="icon === 'build'"
            :alt="__('family_kid:build')"
            src="@/assets/images/activities/build.svg"
            :width="small ? '30px' : '45px'"
        />
        <img
            v-else-if="icon === 'acting'"
            :alt="__('family_kid:acting')"
            src="@/assets/images/activities/acting.svg"
            :width="small ? '30px' : '45px'"
        />
        <img
            v-else-if="icon === 'garden'"
            :alt="__('family_kid:garden')"
            src="@/assets/images/activities/garden.svg"
            :width="small ? '30px' : '45px'"
        />
        <img
            v-else-if="icon === 'manipulation'"
            :alt="__('family_kid:manipulation')"
            src="@/assets/images/activities/manipulation.svg"
            style="padding-top: 5px"
            :width="small ? '35px' : '55px'"
        />
        <img
            v-else-if="icon === 'music'"
            :alt="__('family_kid:music')"
            src="@/assets/images/activities/music.svg"
            :width="small ? '25px' : '40px'"
        />
        <img
            v-else-if="icon === 'outside'"
            :alt="__('family_kid:outside')"
            src="@/assets/images/activities/outside.svg"
            :width="small ? '35px' : '50px'"
        />
        <img
            v-else-if="icon === 'speech'"
            :alt="__('family_kid:speech')"
            src="@/assets/images/activities/speech.svg"
            :width="small ? '30px' : '45px'"
        />
        <img
            v-else-if="icon === 'taste'"
            :alt="__('family_kid:taste')"
            src="@/assets/images/activities/taste.svg"
            :width="small ? '30px' : '45px'"
        />
        <img
            v-else-if="icon === 'activity'"
            :alt="__('common:activity_one')"
            src="@/assets/images/activities/activity.svg"
            :width="small ? '35px' : '45px'"
        />
        <img
            v-else
            :alt="__('common:other_one')"
            src="@/assets/images/activities/other.svg"
            style="margin-left: 7px"
            :width="small ? '25px' : '40px'"
        />
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: 'other',
            },
            small: {
                type: Boolean,
                default: false,
            },
            isHover: {
                type: Boolean,
                default: false,
            },
            line: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style scoped>
    div {
        img {
            margin: auto;
        }

        &.line {
            img {
                width: 30px;
                height: 30px;
            }
        }

        &.hover {
            img {
                width: 65px;
                height: 65px;
            }
        }
    }
</style>
