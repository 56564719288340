<template>
    <MModal
        :header-title="$fullName(familyMember)"
        :modal="modal"
    >
        <support-meeko
            class="tw-mb-6 tw-w-full tw-text-center"
            :ressource="familyMember"
        />
        <FamilyMemberAvatarEditor
            class="tw-mb-4"
            :family-member="familyMember"
            @delete-avatar="deleteAvatar"
            @update-avatar="updateAvatar($event)"
        />
        <FamilyMemberForm
            :errors="errors"
            :family-member="familyMember"
            :from-kid="fromKid"
            :nursery="nursery"
            :show-authorizations="showAuthorizations"
        />
        <template #footer-start>
            <MButton
                v-if="$can('delete', 'family_members_details')"
                :disabled="loading"
                variant="danger"
                @click="deleteFamilyMember"
            >
                {{ __('common:actions.delete_dots') }}
            </MButton>
            <MButton
                v-if="kid && $can('update', 'family_members_details')"
                :disabled="loading"
                @click="detachFamilyMember"
            >
                {{ __('common:actions.detach') }}
            </MButton>
        </template>
        <template #footer-end="{hide}">
            <MButton
                :disabled="loading"
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                :label="__('common:actions.save')"
                variant="primary"
                @click="updateFamilyMember"
            />
        </template>
    </MModal>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import FamilyMemberAvatarEditor from '@/modules/family/components/kid/Kid/FamilyMember/molecules/FamilyMemberAvatarEditor.vue';
    import FamilyMemberForm from '@/modules/family/components/kid/Kid/FamilyMember/molecules/FamilyMemberForm.vue';
    import useMemberRoleMapper from '@/modules/family/composables/member/useMemberRoleMapper';
    import {upload} from '@/modules/legacy/libs/usercontent';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            FamilyMemberForm,
            FamilyMemberAvatarEditor,
        },
        mixins: [nursery],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            familyMember: {
                type: Object,
                required: true,
            },
            kid: {
                type: Object,
                default: undefined,
            },
            modal: {
                type: Object,
                default: undefined,
            },
            showAuthorizations: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update', 'delete', 'detach'],
        data() {
            return {
                errors: [],
                loading: false,
            };
        },
        computed: {
            fromKid() {
                return !!this.kid;
            },
        },
        watch: {
            'familyMember.gender': function () {
                this.familyMember.role = useMemberRoleMapper().getRoleUpdatedByGender(
                    this.familyMember.gender,
                    this.familyMember.role
                );
            },
        },
        methods: {
            updateFamilyMember() {
                if (this.kid) {
                    this.loading = true;

                    useApi()
                        .legacy.put(
                            route('nurseries.kids.familyMembers.update', {
                                nurseries: this.familyMember.nursery_id,
                                kid: this.kid.id,
                                familyMember: this.familyMember.id,
                            }),
                            this.familyMember
                        )
                        .then(response => {
                            useNotification().success(__('family_member:parent.updated'));
                            this.errors = [];
                            this.$emit('update', response.data);
                            this.modal.hide();
                        })
                        .catch(error => {
                            this.errors = error.response.data.errors;
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.loading = true;

                    useApi()
                        .legacy.put(
                            route('nurseries.familyMembers.update', {
                                nurseries: this.familyMember.nursery_id,
                                familyMember: this.familyMember.id,
                            }),
                            this.familyMember
                        )
                        .then(response => {
                            useNotification().success(__('family_member:parent.updated'));
                            this.errors = [];
                            this.$emit('update', response.data);
                            this.modal.hide();
                        })
                        .catch(error => {
                            this.errors = error?.response?.data ? error.response.data.errors : [];
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            deleteFamilyMember() {
                useMagicModal().deleteConfirmationModal({
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('nurseries.familyMembers.destroy', {
                                    nurseries: this.nursery.id,
                                    familyMember: this.familyMember.id,
                                })
                            )
                            .then(() => {
                                this.$emit('delete');
                                this.errors = [];
                                this.modal.hide();
                            })
                            .catch(error => {
                                this.errors = error?.response?.data ? error.response.data.errors : [];
                            });
                    },
                });
            },
            detachFamilyMember() {
                useMagicModal().confirmationModal({
                    text: __('family_member:the_card_will_not_be_deleted_but_will_remain_linked_to_the_family'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.post(
                                route('nurseries.kids.familyMembers.detach', {
                                    nurseries: this.nursery.id,
                                    kid: this.kid.id,
                                    familyMember: this.familyMember.id,
                                })
                            )
                            .then(() => {
                                this.$emit('detach');
                                this.errors = [];
                                this.modal.hide();
                            })
                            .catch(error => {
                                this.errors = error?.response?.data ? error.response.data.errors : [];
                            });
                    },
                });
            },
            updateAvatar(event) {
                upload(event.target.files[0]).then(response => {
                    const data = {
                        avatar: {
                            key: response.key,
                            hash: response.hash,
                        },
                    };
                    useApi()
                        .legacy.put(
                            route('nurseries.familyMembers.update', {
                                nurseries: this.familyMember.nursery_id,
                                familyMember: this.familyMember.id,
                            }),
                            data
                        )
                        .then(response => {
                            this.familyMember.avatar_url = response.data.avatar_url;
                            useNotification().success(__('common:profile_picture_updated'));
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                });
            },
            deleteAvatar() {
                useApi()
                    .legacy.delete(
                        route('nurseries.familyMembers.destroyAvatar', {
                            nurseries: this.familyMember.nursery_id,
                            familyMember: this.familyMember.id,
                        })
                    )
                    .then(response => {
                        this.familyMember.avatar_url = response.data.avatar_url;
                        useNotification().success(__('common:profile_picture_deleted'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
        },
    };
</script>
