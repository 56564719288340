<script setup lang="ts">
    import {computed} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';

    const props = defineProps<{
        modelValue?: string;
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', value: string | null): void;
    }>();

    const invoiceTypeText = computed(() => {
        if (props.modelValue === InvoiceTypeValue.invoice) {
            return __('common:invoice_other');
        } else if (props.modelValue === InvoiceTypeValue.creditNote) {
            return __('common:credit_note');
        } else {
            return __('billing_invoice:invoice_and_credit_note');
        }
    });
</script>
<template>
    <MMenu>
        <MMenuButton>
            <MButton icon-menu>
                {{ invoiceTypeText }}
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem @click="emit('update:modelValue', null)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="!modelValue || typeof modelValue === 'undefined' || modelValue.length === 0"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('billing_invoice:invoice_and_credit_note') }}
            </MMenuItem>
            <MMenuItem @click="emit('update:modelValue', InvoiceTypeValue.invoice)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="modelValue && modelValue.includes('invoice')"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('common:invoice_other') }}
            </MMenuItem>
            <MMenuItem @click="emit('update:modelValue', InvoiceTypeValue.creditNote)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="modelValue && modelValue.includes('credit_note')"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('common:credit_note') }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>
