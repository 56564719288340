<template>
    <div class="EditInvoiceDetailsHeader">
        <MHeading
            v-if="displayType"
            class="EditInvoiceDetailsHeader__item EditInvoiceDetailsHeader__item-type"
            level="h4"
        >
            {{ __('common:type') }}
        </MHeading>
        <MHeading
            class="EditInvoiceDetailsHeader__item EditInvoiceDetailsHeader__item-description"
            level="h4"
        >
            {{ __('common:description') }}
        </MHeading>
        <MHeading
            v-if="withTva"
            class="EditInvoiceDetailsHeader__item EditInvoiceDetailsHeader__item-tva"
            level="h4"
        >
            {{ __('common:vat') }}
        </MHeading>
        <MHeading
            class="EditInvoiceDetailsHeader__item EditInvoiceDetailsHeader__item-price"
            level="h4"
        >
            {{ __('billing_invoice:price_with_vat') }}
        </MHeading>
        <MHeading
            class="EditInvoiceDetailsHeader__item EditInvoiceDetailsHeader__item-quantity"
            level="h4"
        >
            {{ __('billing_invoice:quantity') }}
        </MHeading>
        <MHeading
            class="EditInvoiceDetailsHeader__item EditInvoiceDetailsHeader__item-total"
            level="h4"
        >
            {{ __('billing_invoice:total_with_vat') }}
        </MHeading>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            withTva: {
                type: Boolean,
                required: true,
            },
            displayType: {
                type: Boolean,
                default: true,
            },
        },
    });
</script>

<style scoped>
    .EditInvoiceDetailsHeader {
        @apply tw-hidden tw-flex-row tw-items-center tw-gap-2 md:tw-flex;
        @apply tw-px-2;
    }

    .EditInvoiceDetailsHeader__item {
        @apply tw-truncate tw-text-gray-500;
    }

    .EditInvoiceDetailsHeader__item-type {
        @apply tw-w-12;
    }

    .EditInvoiceDetailsHeader__item-description {
        @apply tw-flex-1;
    }

    .EditInvoiceDetailsHeader__item-tva {
        @apply tw-w-20 tw-text-center;
    }

    .EditInvoiceDetailsHeader__item-price {
        @apply tw-w-28 tw-text-center;
    }

    .EditInvoiceDetailsHeader__item-quantity {
        @apply tw-w-40 tw-text-center;
    }

    .EditInvoiceDetailsHeader__item-total {
        @apply tw-w-24 tw-text-center;
    }
</style>
