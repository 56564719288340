import JobModel from '@/modules/human-resources/models/JobModel';
import type JobListContract from '@/modules/human-resources/utils/job/JobListContract';

export default class JobList implements JobListContract {
    private _jobs: JobModel[] = [];

    public get jobs(): JobModel[] {
        return this._jobs;
    }

    public clear(): this {
        this._jobs = [];

        return this;
    }

    public async loadJobs(): Promise<this> {
        this._jobs = (await JobModel.query().all()).toArray();

        return this;
    }
}
