<script setup lang="ts">
    import type {Placement} from '@floating-ui/vue';
    import {useElementHover} from '@vueuse/core';
    import {onMounted, type PropType, provide, ref, toRef, watch} from 'vue';

    import MFloating from '@/modules/meeko-ui/components/MFloating.vue';
    import {defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const props = defineProps({
        placement: {
            type: String as PropType<Placement>,
            default: 'top',
            validator: (value: string) =>
                [
                    'top',
                    'top-start',
                    'top-end',
                    'right',
                    'right-start',
                    'right-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                ].includes(value),
        },
        dontInteractWithPanel: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'Tooltip',
        },
        hoverable: {
            type: Boolean,
            default: true,
        },
    });

    const context = defineFloatingContext({
        placement: toRef(props, 'placement'),
        trigger: ref('hover'),
        arrow: ref(true),
        arrowPadding: ref(6),
        offset: ref(8),
    });

    provide('MFloatingContext', context);

    const {
        setButtonElement,
        setPanelElement,
        setArrowElement,
        panelId,
        isVisible,
        finalPlacement,
        isReady,
        middlewareData,
        setHovered,
    } = context;

    const myButton = ref();
    const myArrow = ref();
    const myPanel = ref();

    onMounted(() => {
        setButtonElement(myButton);
    });

    setPanelElement(myPanel);
    setArrowElement(myArrow);

    const isButtonHovered = useElementHover(myButton);
    watch(isButtonHovered, setHovered);

    const isPanelHovered = useElementHover(myPanel);
    watch(isPanelHovered, setHovered);
</script>

<template>
    <div
        ref="myButton"
        class="MTooltip"
    >
        <slot :context="context" />
        <MFloating>
            <section
                v-if="hoverable"
                :id="panelId"
                ref="myPanel"
                :aria-hidden="!isVisible"
                class="MTooltipPanel"
                :class="{'MTooltipPanel--prevent-interactions': dontInteractWithPanel}"
                role="tooltip"
                tabindex="-1"
            >
                <div
                    ref="myArrow"
                    class="MTooltipPanel__arrow"
                    :class="['MTooltipPanel__arrow--' + finalPlacement, {'MTooltipPanel__arrow--animated': isReady}]"
                    :style="{
                        left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
                        top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                    }"
                >
                    <svg
                        viewBox="0 0 14 6"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.7 1a3.1 3.1 0 0 1 4.6 0L14 6H0l4.7-5Z"
                            fill="currentcolor"
                        />
                    </svg>
                </div>
                <div>
                    <slot
                        :ctx="context"
                        name="content"
                    >
                        {{ label }}
                    </slot>
                </div>
            </section>
        </MFloating>
    </div>
</template>

<style scoped>
    .MTooltip {
        @apply tw-inline-flex;
    }

    .MTooltipPanel {
        @apply tw-rounded-md tw-bg-primary-600 tw-px-2 tw-py-1 tw-font-display tw-text-sm tw-font-normal tw-text-white tw-shadow-md;
        @apply print:tw-hidden;
    }

    .MTooltipPanel--prevent-interactions {
        @apply tw-pointer-events-none;
    }

    .MTooltipPanel__arrow {
        width: 14px;
        height: 14px;
        @apply tw-absolute tw-text-primary-600;
    }

    .MTooltipPanel__arrow--animated {
        transition: all 0.65s cubic-bezier(0.43, 0.33, 0.14, 1.01) 0s;
        transition-property: top, right, bottom, left;
    }

    .MTooltipPanel__arrow--top,
    .MTooltipPanel__arrow--top-start,
    .MTooltipPanel__arrow--top-end {
        bottom: -6px;
        @apply tw--rotate-180;
    }

    .MTooltipPanel__arrow--right,
    .MTooltipPanel__arrow--right-start,
    .MTooltipPanel__arrow--right-end {
        left: -6px;
        @apply tw--rotate-90;
    }

    .MTooltipPanel__arrow--bottom,
    .MTooltipPanel__arrow--bottom-start,
    .MTooltipPanel__arrow--bottom-end {
        top: -6px;
    }

    .MTooltipPanel__arrow--left,
    .MTooltipPanel__arrow--left-start,
    .MTooltipPanel__arrow--left-end {
        right: -6px;
        @apply tw-rotate-90;
    }
</style>
