import Bugsnag from '@bugsnag/js';
import type {LDClient as ILDClient} from 'launchdarkly-js-client-sdk';
import * as LDClient from 'launchdarkly-js-client-sdk';
import {ref} from 'vue';

import app from '@/config/app';
import services from '@/config/services';

let ldClient: ILDClient;

const isReady = ref(false);
const allFlags = ref({});

export function useLaunchDarkly() {
    async function initialize(user: any) {
        if (isReady.value) {
            return;
        }

        ldClient = LDClient.initialize(services.launchDarkly.key, user, {
            application: {
                id: 'manager',
                version: app.version,
            },
        });

        await ldClient.waitForInitialization();

        isReady.value = true;

        allFlags.value = ldClient.allFlags();

        // Listen for flag changes
        ldClient.on(
            'change',
            function (
                event: Record<
                    string,
                    {
                        current: boolean;
                        previous: boolean;
                    }
                >
            ) {
                for (const [flagKey, flagValue] of Object.entries(event)) {
                    allFlags.value[flagKey] = flagValue.current;
                }
            }
        );
    }

    function waitUntilReady() {
        return ldClient.waitUntilReady();
    }

    function close() {
        ldClient?.close();
        isReady.value = false;
        allFlags.value = {};
        if (services.bugsnag.enabled) {
            Bugsnag.clearFeatureFlags();
        }
    }

    return {
        isReady,
        waitUntilReady,
        initialize,
        close,
        allFlags,
        ldClient,
    };
}
