import type {App} from 'vue';

import {EventBus} from '@/eventBus';
import useAbility from '@/modules/app/composables/useAbility';
import __ from '@/modules/app/utils/i18n-facade';
import {capitalize, formatDate, fullName, refToDate, valueFromOptions} from '@/modules/legacy/filters/filters';

export default function (app: App) {
    app.config.globalProperties.__ = __;
    app.config.globalProperties.$bus = EventBus;

    app.config.globalProperties.$formatDate = formatDate;
    app.config.globalProperties.$capitalize = capitalize;
    app.config.globalProperties.$refToDate = refToDate;
    app.config.globalProperties.$fullName = fullName;
    app.config.globalProperties.$valueFromOptions = valueFromOptions;
    app.config.globalProperties.$isRole = (role, user, nursery) => {
        let isRole = false;
        if (user && user.nurseries) {
            const userNursery = user.nurseries.find(item => item.id === nursery.id);
            if (userNursery) {
                userNursery.role === role ? (isRole = true) : null;
            }

            return isRole;
        }

        return false;
    };
    app.config.globalProperties.$can = (action, subject, userPermissions) => {
        const ability = useAbility(userPermissions);

        switch (action) {
            case 'any':
                return ability.anyOfAll(subject);
            case 'all':
                return ability.all(subject);
            default:
                return ability.can(action, subject);
        }
    };
}
