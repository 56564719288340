<template>
    <div class="row">
        <div class="col-7 col-sm-6">
            {{ __('common:kids_number') }}
        </div>
        <div class="col-5 col-sm-6">
            <CBadge
                class="tw-w-full tw-p-2 tw-text-center"
                variant="cyan"
            >
                {{ __('common:kid_with_count', {count: rule.kids}) }}
            </CBadge>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            rule: {
                type: Object,
                required: true,
            },
        },
    });
</script>
