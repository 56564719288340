import {Model} from '@meekohq/lumos';

export default class SubscriptionModel extends Model {
    static readonly TOPICS = {
        CASHIER_INVOICE_SENT: 'cashier_invoice_sent',
        CASHIER_INVOICE_UNPAID: 'cashier_invoice_unpaid',
        CASHIER_INVOICE_PAID: 'cashier_invoice_paid',
        TAX_CERTIFICATE_SENT: 'tax_certificate_sent',
    };

    static readonly RESOURCE_TYPE = {
        ORDER: 'cashier/orders',
        INVOICE: 'cashier/invoices',
    };

    public type = 'cashier/notification_subscriptions';

    public attributes: {
        id: string;
        topic: string | undefined;
        tenant_id: string | undefined;
        endpoint_id: string | undefined;
        resource_type: string | undefined;
        resource_id: string | undefined;
    } = {
        id: this.uuid(),
        topic: undefined,
        tenant_id: undefined,
        endpoint_id: undefined,
        resource_type: undefined,
        resource_id: undefined,
    };
}
