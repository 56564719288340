enum InvoiceStatusValue {
    draft = 'draft',
    pending = 'pending',
    incomplete = 'incomplete',
    unpaid = 'unpaid',
    paid = 'paid',
    uncollectible = 'uncollectible',
}

export default InvoiceStatusValue;
