<script setup lang="ts">
    import {computed} from 'vue';

    const props = defineProps<{
        menu: {
            variant: string;
            icon: string;
        };
    }>();

    const menuVariantClass = computed(() => {
        return `UsersManagerPermissionsItem--variant-${props.menu.variant}`;
    });
</script>

<template>
    <div
        class="UsersManagerPermissionsItem"
        :class="menuVariantClass"
    >
        <FontAwesomeIcon
            class="UsersManagerPermissionsItem__icon"
            :icon="menu.icon"
        />
    </div>
</template>

<style scoped>
    .UsersManagerPermissionsItem {
        @apply tw-flex tw-aspect-square tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-shadow;

        &--variant-dashboard {
            @apply tw-bg-gradient-to-b tw-from-modules-dashboard-500 tw-to-modules-dashboard-600;
        }

        &--variant-planning {
            @apply tw-bg-gradient-to-b tw-from-modules-planning-500 tw-to-modules-planning-600;
        }

        &--variant-family {
            @apply tw-bg-gradient-to-b tw-from-modules-family-500 tw-to-modules-family-600;
        }

        &--variant-registration {
            @apply tw-bg-gradient-to-b tw-from-modules-registration-500 tw-to-modules-registration-600;
        }

        &--variant-billing {
            @apply tw-bg-gradient-to-b tw-from-modules-billing-500 tw-to-modules-billing-600;
        }

        &--variant-staff {
            @apply tw-bg-gradient-to-b tw-from-modules-staff-500 tw-to-modules-staff-600;
        }

        &--variant-photo {
            @apply tw-bg-gradient-to-b tw-from-modules-photo-500 tw-to-modules-photo-600;
        }

        &--variant-document {
            @apply tw-bg-gradient-to-b tw-from-modules-document-500 tw-to-modules-document-600;
        }

        &--variant-news {
            @apply tw-bg-gradient-to-b tw-from-modules-news-500 tw-to-modules-news-600;
        }

        &--variant-website {
            @apply tw-bg-gradient-to-b tw-from-modules-website-500 tw-to-modules-website-600;
        }

        &--variant-equipment {
            @apply tw-bg-gradient-to-b tw-from-modules-equipment-500 tw-to-modules-equipment-600;
        }

        &--variant-setting {
            @apply tw-bg-gradient-to-b tw-from-modules-setting-500 tw-to-modules-setting-600;
        }
    }

    .UsersManagerPermissionsItem__icon {
        @apply tw-text-sm tw-text-white;
    }
</style>
