<template>
    <div>
        <CashierOrderManager
            :company="true"
            :contract="contract"
            :legacy-organization="nursery"
            :payload="cashierPayload"
            @has-customer="show = $event"
        />

        <div v-if="show">
            <MForm class="tw-mt-6 md:tw-w-1/2">
                <MFieldset>
                    <MLabel>
                        {{ __('common:employee_name') }}
                    </MLabel>
                    <MInput
                        v-model="contract.company_employee"
                        :disabled="!$can('update', 'kids_contracts')"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        {{ __('family_contract:the_company_is_invoiced') }}
                    </MLabel>
                    <MCurrencyInput
                        v-model="contract.company_total_amount"
                        allow-undefined
                        class="tw-w-full"
                        :currency-symbol="nursery.currency"
                        :disabled="!$can('update', 'kids_contracts')"
                        :parser-options="parserOptionsCurrency"
                        placeholder="10000"
                    />
                </MFieldset>
                <CFormTwoColumns>
                    <MFieldset>
                        <MLabel>
                            {{ __('family_contract:start_schedule') }}
                        </MLabel>
                        <MDatePicker
                            v-model="contract.company_started_at"
                            class="tw-w-full"
                            :disabled="!$can('update', 'kids_contracts')"
                            format="yyyy-MM-dd"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MLabel>
                            {{ __('family_contract:end_schedule') }}
                        </MLabel>
                        <MDatePicker
                            v-model="contract.company_ended_at"
                            class="tw-w-full"
                            :disabled="!$can('update', 'kids_contracts')"
                            format="yyyy-MM-dd"
                        />
                    </MFieldset>
                </CFormTwoColumns>
                <MFieldset>
                    <MLabel>
                        {{ __('family_contract:frequency') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="contract.company_cycle"
                        :disabled="!$can('update', 'kids_contracts')"
                        :options="cycleOptions"
                    />
                </MFieldset>
            </MForm>
            <div class="tw-mt-6">
                <MHeading
                    v-if="contract.company_invoices"
                    level="h3"
                >
                    {{ __('family_contract:billing_schedule') }}
                    <span v-if="contract.company_invoices.length">({{ contract.company_invoices.length }})</span>
                </MHeading>
                <hr class="hr tw-mt-2" />
                <div class="tw-flex tw-flex-col tw-gap-8">
                    <div
                        v-for="(invoice, i) in contract.company_invoices"
                        :key="'invoice' + i"
                        class="tw-flex tw-flex-col tw-gap-2"
                    >
                        <MForm
                            class="tw-grid tw-grid-cols-2 tw-items-end tw-gap-4 lg:tw-grid-cols-6"
                            @submit.prevent
                        >
                            <MFieldset>
                                <MLabel>{{ __('common:amount_one') }}</MLabel>
                                <MCurrencyInput
                                    v-model="invoice.amount"
                                    allow-undefined
                                    class="tw-w-full"
                                    :currency-symbol="nursery.currency"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    :parser-options="parserOptionsCurrency"
                                    placeholder="10000"
                                />
                            </MFieldset>
                            <MFieldset class="lg:tw-order-last">
                                <MButton
                                    class="tw-w-full"
                                    variant="danger"
                                    icon-trash
                                    @click="removeCycle(i)"
                                >
                                    {{ __('common:actions.delete') }}
                                </MButton>
                            </MFieldset>
                            <MFieldset>
                                <MLabel>{{ __('family_contract:beginning_period') }}</MLabel>
                                <MDatePicker
                                    v-model="invoice.start_period_date"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>{{ __('family_contract:end_period') }}</MLabel>
                                <MDatePicker
                                    v-model="invoice.end_period_date"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                    :has-error="invoice.start_period_date > invoice.end_period_date"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>{{ __('family_contract:invoice_date') }}</MLabel>
                                <MDatePicker
                                    v-model="invoice.date"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>{{ __('family_contract:due_date') }}</MLabel>
                                <MDatePicker
                                    v-model="invoice.due_date"
                                    :disabled="!$can('update', 'kids_contracts')"
                                    format="yyyy-MM-dd"
                                    :has-error="invoice.date > invoice.due_date"
                                />
                            </MFieldset>
                        </MForm>
                        <div
                            v-if="
                                invoice.date > invoice.due_date || invoice.start_period_date > invoice.end_period_date
                            "
                            class="tw-flex tw-w-full tw-flex-col tw-gap-2"
                        >
                            <CFormErrorMessage v-if="invoice.date > invoice.due_date">
                                {{ __('family_contract:errors.company_invoice_due_date_after_date') }}
                            </CFormErrorMessage>
                            <CFormErrorMessage v-if="invoice.start_period_date > invoice.end_period_date">
                                {{
                                    __('family_contract:errors.company_invoice_end_period_date_after_start_period_date')
                                }}
                            </CFormErrorMessage>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="$can('update', 'kids_contracts')"
                class="tw-mt-8 tw-text-center"
            >
                <MButton
                    icon-plus
                    variant="primary"
                    @click="addCycle"
                >
                    {{ __('family_contract:add_due_date') }}
                </MButton>
            </div>
        </div>
    </div>
</template>

<script>
    import i18next from 'i18next';
    import {set} from 'lodash-es';
    import moment from 'moment';

    import {getNumericParserContractPresetCurrency} from '@/modules/core/infrastructure/NumericParserContractPresets';
    import CashierOrderManager from '@/modules/family/components/kid/CashierOrderManager.vue';
    import {useLegacyKidContractErrors} from '@/modules/family/composables/kid/useLegacyKidContractErrors.ts';
    import LegacyContractIntegrationService from '@/modules/family/utils/kid/LegacyContractIntegrationService';

    export default {
        components: {
            CashierOrderManager,
        },
        props: {
            contract: {
                type: Object,
            },
            nursery: {
                type: Object,
            },
            kid: {
                type: Object,
            },
            cashierPayload: {},
        },
        emits: ['has-errors'],
        data() {
            return {
                show: false,
                cycleOptions: [
                    {
                        text: 'Personnalisée',
                        value: null,
                    },
                    {
                        text: 'Mensuelle',
                        value: 1,
                    },
                    {
                        text: 'Trimestrielle',
                        value: 3,
                    },
                    {
                        text: 'Semestrielle',
                        value: 6,
                    },
                    {
                        text: 'Annuelle',
                        value: 12,
                    },
                ],
                parserOptionsCurrency: getNumericParserContractPresetCurrency(i18next.language),
            };
        },
        computed: {
            LegacyContractIntegration() {
                return LegacyContractIntegrationService;
            },
            hasErrors() {
                return useLegacyKidContractErrors().hasCompanyContractErrors(this.contract);
            },
        },
        watch: {
            'contract.company_cycle': function (val) {
                if (val) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'contract.company_total_amount': function () {
                if (this.contract.company_cycle) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'contract.company_started_at': function () {
                if (this.contract.company_cycle) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'contract.company_ended_at': function () {
                if (this.contract.company_cycle) {
                    this.setCycles();
                } else {
                    this.customCycle();
                }
            },
            'hasErrors': {
                handler() {
                    this.$emit('has-errors', this.hasErrors);
                },
            },
        },
        created() {
            this.parserOptionsCurrency.allowNegative = true;
        },
        mounted() {
            if (this.contract.company_employee === null) {
                const familyMember = this.kid.familyMembers.find(
                    item => item.id === this.contract.first_family_member_id
                );
                if (familyMember) {
                    this.contract.company_employee = familyMember.first_name + ' ' + familyMember.last_name;
                }
            }

            if (
                !this.contract.company_started_at &&
                this.contract.company_invoices &&
                this.contract.company_invoices.length === 0
            ) {
                set(this.contract, 'company_started_at', moment(this.contract.started_at));
            }

            if (
                !this.contract.company_ended_at &&
                this.contract.company_invoices &&
                this.contract.company_invoices.length === 0
            ) {
                set(this.contract, 'company_ended_at', moment(this.contract.ended_at));
            }
        },
        methods: {
            setCycles() {
                const cycles = [];

                // Add 1 to the diff to include the end month because it is not included by default as it is not a full month
                let months =
                    moment(this.contract.company_ended_at).diff(moment(this.contract.company_started_at), 'months') + 1;

                if (months <= 0) {
                    months = 1;
                }

                // Ceil is used to avoid having a wrong number of cycles when the contract is not a multiple of the cycle
                const n = this.contract.company_cycle ? Math.ceil(months / this.contract.company_cycle) : 1;

                for (let i = 0; i < months; i += this.contract.company_cycle) {
                    const total = this.contract.company_total_amount ? this.contract.company_total_amount / n : 0;
                    const invoice = {
                        amount: Math.round(total * 100) / 100,
                        start_period_date: moment(this.contract.company_started_at)
                            .add(i, 'month')
                            .format('YYYY-MM-DD'),
                        date: moment(this.contract.company_started_at).add(i, 'month').format('YYYY-MM-DD'),
                        due_date: moment(this.contract.company_started_at)
                            .add(i, 'month')
                            .add(1, 'month')
                            .format('YYYY-MM-DD'),
                    };
                    invoice.end_period_date = moment(invoice.start_period_date)
                        .add(this.contract.company_cycle, 'month')
                        .subtract(1, 'day')
                        .format('YYYY-MM-DD');

                    // If the end period date is after the contract end date, we set it to the contract end date
                    if (this.contract.company_ended_at < invoice.end_period_date) {
                        invoice.end_period_date = this.contract.company_ended_at;
                    }
                    cycles.push(invoice);
                }

                this.contract.company_invoices = cycles;
            },
            customCycle() {
                const invoice = {
                    amount: this.contract.company_total_amount ? this.contract.company_total_amount : 0,
                    start_period_date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    end_period_date: moment(this.contract.company_ended_at).format('YYYY-MM-DD'),
                    date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    due_date: moment(this.contract.company_started_at).add(1, 'month').format('YYYY-MM-DD'),
                };
                this.contract.company_invoices = [invoice];
            },
            addCycle() {
                const invoice = {
                    amount: 0,
                    start_period_date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    end_period_date: moment(this.contract.company_ended_at).format('YYYY-MM-DD'),
                    date: moment(this.contract.company_started_at).format('YYYY-MM-DD'),
                    due_date: moment(this.contract.company_started_at).add(1, 'month').format('YYYY-MM-DD'),
                };

                if (this.contract.company_invoices) {
                    this.contract.company_invoices.push(invoice);
                } else {
                    this.contract.company_invoices = [invoice];
                }
            },
            removeCycle(index) {
                this.contract.company_invoices.splice(index, 1);
            },
        },
    };
</script>
