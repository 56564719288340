import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

export default class EventError extends ErrorHandler {
    private dateErrors = {
        '0x3115DA45A6': 'common:errors.0x3115DA45A6',
        '0x311B92FE7B': 'common:errors.0x311B92FE7B',
        '0x3115DA57B1': 'common:errors.0x3115DA57B1',
        '0x311BFF7AFF': 'common:errors.0x311BFF7AFF',
        '0x311C3C7EEF': 'common:errors.0x311C3C7EEF',
        '0x3120D29DB0': 'common:errors.0x3120D29DB0',
        '0x3120D1BBC7': 'common:errors.0x3120D1BBC7',
    };

    protected resolver(): any {
        return {
            '/attributes/datetime_event/started_at': {
                ...this.dateErrors,
            },
            '/attributes/date_event/started_at': {
                ...this.dateErrors,
            },
            '/attributes/start_time': {
                ...this.dateErrors,
            },
            '/attributes/type_id': {
                '0x333F958240': 'common:errors.0x333F958240',
            },
        };
    }
}
