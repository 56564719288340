import type Router from 'vue-router';

import useAbility from '@/modules/app/composables/useAbility';
import useAccessRoute from '@/modules/app/composables/useAccessRoute';

export default function authorizationMiddleware(router: Router) {
    const {arePermissionsLoaded} = useAbility();
    const {checkAccessToRouteName, firstRouteNameWithGrantedAccess} = useAccessRoute(router);

    router.beforeEach(async (to, from, next) => {
        if (arePermissionsLoaded.value && !checkAccessToRouteName(to.name)) {
            return next({name: firstRouteNameWithGrantedAccess.value, params: to.params});
        }

        return next();
    });
}
