import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class AccountModel {
    public accountId: string;

    constructor(accountId) {
        this.accountId = accountId;
    }

    async hasManyOrganizations() {
        return (await OrganizationModel.query().get()).count() > 1;
    }
}
