import type {Ref} from 'vue';
import {ref} from 'vue';

import __ from '@/modules/app/utils/i18n-facade';
import type {NotificationGroup} from '@/modules/user/utils/NotificationsTypes';

export default function () {
    const defaultNotifications: Ref<NotificationGroup[]> = ref([
        {
            headerText: __('common:general'),
            isAllViaMailSelected: false,
            isAllViaBroadcastSelected: false,
            notificationsList: [
                {
                    value: 'kid_presence_unclocked',
                    icon: 'fa-solid fa-check',
                    text: __('notification:children_check_ins'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'staff_presence_unclocked',
                    icon: 'fa-solid fa-check',
                    text: __('notification:team_check_ins'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'invoice_unpaid',
                    icon: 'fa-solid fa-file-invoice',
                    text: __('common:unpaid_invoice_one'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'new_registration',
                    icon: 'fa-solid fa-pen-alt',
                    text: __('common:registration_other'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'hr_request',
                    icon: 'fa-solid fa-tasks',
                    text: __('common:teams_request'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'meeting',
                    icon: 'fa-solid fa-calendar',
                    text: __('activity:meeting_other'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'new_post',
                    icon: 'fa-solid fa-bullhorn',
                    text: __('common:news'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'new_document',
                    icon: 'fa-solid fa-regular fa-file',
                    text: __('common:document_other'),
                    viaMail: false,
                    viaBroadcast: false,
                },
            ],
        },
        {
            headerText: __('notification:group_title.meeko_family'),
            isAllViaMailSelected: false,
            isAllViaBroadcastSelected: false,
            notificationsList: [
                {
                    value: 'new_late',
                    icon: 'fa-solid fa-clock',
                    text: __('common:delay_other'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'new_absence',
                    icon: 'fa-solid fa-calendar-times',
                    text: __('common:absence_other'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'new_occasional',
                    icon: 'fa-solid fa-calendar-plus',
                    text: __('common:booking_other'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'new_message',
                    icon: 'fa-solid fa-comment',
                    text: __('common:message_other'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'new_message_manager_only',
                    icon: 'fa-solid fa-comment-exclamation',
                    text: __('common:direction_messages'),
                    viaMail: false,
                    viaBroadcast: false,
                },
            ],
        },
        {
            headerText: __('notification:group_title.task_reminder'),
            isAllViaMailSelected: false,
            isAllViaBroadcastSelected: false,
            notificationsList: [
                {
                    value: 'task_reminder',
                    icon: 'fa-solid fa-list-check',
                    text: __('notification:general_task_reminder'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'task_kid_reminder',
                    icon: 'fa-solid fa-child-reaching',
                    text: __('common:kid_page'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'task_vaccine_reminder',
                    icon: 'fa-solid fa-syringe',
                    text: __('notification:vaccine_reminder'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'task_family_member_reminder',
                    icon: 'fa-solid fa-user-tie',
                    text: __('common:parent_card'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'task_family_reminder',
                    icon: 'fa-solid fa-address-book',
                    text: __('common:family'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'task_staff_reminder',
                    icon: 'fa-solid fa-users',
                    text: __('common:team_card'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'task_registration_reminder',
                    icon: 'fa-solid fa-pen-alt',
                    text: __('common:registration_one'),
                    viaMail: false,
                    viaBroadcast: false,
                },
                {
                    value: 'task_customer_reminder',
                    icon: 'fa-solid fa-file-user',
                    text: __('common:customer_one'),
                    viaMail: false,
                    viaBroadcast: false,
                },
            ],
        },
    ]);

    return {
        defaultNotifications,
    };
}
