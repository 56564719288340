<template>
    <section class="SimplePageTemplate">
        <header class="SimplePageTemplate__header">
            <slot name="title" />
            <div class="SimplePageTemplate__actions">
                <slot name="actions" />
            </div>
        </header>
        <div class="SimplePageTemplate__content">
            <slot name="content" />
        </div>
    </section>
</template>

<script lang="ts">
    export default {
        components: {},
        props: {
            variant: {type: String, default: 'base'},
        },
        setup() {
            return {};
        },
    };
</script>

<style scoped>
    .SimplePageTemplate__header {
        @apply tw-rounded-t-inherit;
        @apply tw-flex tw-flex-col tw-justify-between tw-gap-6 md:tw-flex-row md:tw-items-center;
        @apply tw-mb-8 tw-mt-4;
        @apply print:tw-hidden;
    }

    .SimplePageTemplate__actions {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-gap-2 md:tw-justify-end;
    }
</style>
