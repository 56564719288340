import {type Model, type ModelAvailableAttributesType, type QueryBuilderOrderByDirectionType} from '@meekohq/lumos';
import {merge} from 'lodash-es';
import {type Ref} from 'vue';

import type {FilterOptions, FilterType} from '@/modules/app/composables/useMagicFilter';
import useMagicFilter, {setupFilter} from '@/modules/app/composables/useMagicFilter';
import useManager from '@/modules/app/composables/useManager';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import ArrayOfStringSerializer from '@/modules/magic-index/utils/serializers/ArrayOfStringSerializer';
import BooleanSerializer from '@/modules/magic-index/utils/serializers/BooleanSerializer';
import ModelSerializer from '@/modules/magic-index/utils/serializers/ModelSerializer';
import NumberSerializer from '@/modules/magic-index/utils/serializers/NumberSerializer';
import OrderBySerializer from '@/modules/magic-index/utils/serializers/OrderBySerializer';

type Filters = 'tenants' | 'tags' | 'withoutTag' | 'personTypes' | 'states' | 'includeArchived' | 'orderBy' | 'page';

interface OrderByFilterType {
    orderBy: ModelAvailableAttributesType<CustomerModel>;
    queryDirection: QueryBuilderOrderByDirectionType;
}

type StateFilterType = 'up-to-date' | 'pending' | 'unpaid' | 'too-much-collected';

export interface OutputType {
    tenantsFilter: FilterType<Model[]>;
    tagsFilter: FilterType<Model[]>;
    withoutTagFilter: FilterType<boolean>;
    personTypesFilter: FilterType<string[]>;
    statesFilter: FilterType<StateFilterType[]>;
    includeArchivedFilter: FilterType<boolean>;
    orderByFilter: FilterType<OrderByFilterType>;
    pageFilter: FilterType<number>;
    activeCount: Ref<number>;
    isReady: Ref<boolean>;
    reset: () => void;
    allFilters: Ref<any[]>;
}

export default function useCustomerFiltersStore(options?: Partial<Record<Filters, FilterOptions>>): OutputType {
    const {activeOrganization} = useManager();

    const defaultOptions: Record<Filters, FilterOptions> = {
        tenants: {
            defaultValue: async () => {
                await activeOrganization.value.tenants().load();

                return activeOrganization.value.tenants().value().first()
                    ? [activeOrganization.value.tenants().value().first()]
                    : [];
            },
            serializer: ModelSerializer,
        },
        tags: {
            defaultValue: [],
            serializer: ModelSerializer,
        },
        withoutTag: {
            defaultValue: false,
            serializer: BooleanSerializer,
        },
        personTypes: {
            defaultValue: [],
            serializer: ArrayOfStringSerializer,
        },
        states: {
            defaultValue: [],
            serializer: ArrayOfStringSerializer,
        },
        includeArchived: {
            defaultValue: false,
            serializer: BooleanSerializer,
        },
        orderBy: {
            defaultValue: {
                orderBy: 'name',
                queryDirection: 'asc',
            },
            serializer: OrderBySerializer,
        },
        page: {
            defaultValue: 1,
            serializer: NumberSerializer,
        },
    };

    const optionsWithDefaults = merge({}, defaultOptions, options);

    const tenantsFilter = setupFilter<Model[]>(optionsWithDefaults.tenants);
    const tagsFilter = setupFilter<Model[]>(optionsWithDefaults.tags);
    const withoutTagFilter = setupFilter<boolean>(optionsWithDefaults.withoutTag);
    const personTypesFilter = setupFilter<string[]>(optionsWithDefaults.personTypes);
    const statesFilter = setupFilter<StateFilterType[]>(optionsWithDefaults.states);
    const includeArchivedFilter = setupFilter<boolean>(optionsWithDefaults.includeArchived);
    const orderByFilter = setupFilter<OrderByFilterType>(optionsWithDefaults.orderBy);
    const pageFilter = setupFilter<number>(optionsWithDefaults.page);

    const {activeCount, isReady, reset, allFilters} = useMagicFilter(
        [
            tenantsFilter,
            tagsFilter,
            withoutTagFilter,
            personTypesFilter,
            statesFilter,
            includeArchivedFilter,
            orderByFilter,
        ],
        pageFilter
    );

    return {
        tenantsFilter,
        tagsFilter,
        withoutTagFilter,
        personTypesFilter,
        statesFilter,
        includeArchivedFilter,
        orderByFilter,
        pageFilter,
        activeCount,
        isReady,
        reset,
        allFilters,
    };
}
