<script setup lang="ts">
    import useSaveTransaction from '@/modules/cashier/composables/issuing/transaction/useSaveTransaction';
    import type {TransactionStateReturnType} from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';

    const props = defineProps<{
        transactionState: TransactionStateReturnType;
    }>();

    const emit = defineEmits<{
        (e: 'hide'): void;
    }>();

    const {hasBlockingError} = props.transactionState.transactionError;

    // Get save action
    const {save, loading: isSaving} = useSaveTransaction(props.transactionState);
</script>

<template>
    <div class="TransactionModalFooter">
        <MButton
            :label="__('common:actions.cancel')"
            :loading="isSaving"
            @click="emit('hide')"
        />
        <MButton
            :disabled="hasBlockingError"
            :label="__('common:actions.save')"
            :loading="isSaving"
            variant="primary"
            @click="save"
        />
    </div>
</template>

<style scoped>
    .TransactionModalFooter {
        @apply tw-contents;
    }
</style>
