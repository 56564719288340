<script setup lang="ts">
    import {MqlOperation} from '@meekohq/lumos';
    import {onMounted, ref, watch} from 'vue';

    const emit = defineEmits<{
        (e: 'update:modelValue', value: string): void;
    }>();

    const props = withDefaults(
        defineProps<{
            modelValue: string;
            hasError?: boolean;
            readonly?: boolean;
            maxHeight?: string;
        }>(),
        {
            hasError: false,
            readonly: false,
            maxHeight: '256px',
        }
    );
    const availableTimezones = ref<{id: string; value: string}[]>([]);
    const selected = ref<string>(props.modelValue);

    onMounted(async () => {
        const result = await new MqlOperation<{zones: string[]}>('core/timezone-list').run();
        availableTimezones.value = result.content.zones.map((timeZone: string) => ({
            id: timeZone,
            value: timeZone,
        }));
    });

    watch(
        () => props.modelValue,
        value => {
            if (value) {
                selected.value = props.modelValue;
            }
        }
    );

    watch(selected, newSelected => emit('update:modelValue', newSelected));
</script>
<template>
    <MSelectMenu
        v-model="selected"
        :fallback-text="__('components:select_timezone')"
        funnel
        :has-error="hasError"
        :max-height="maxHeight"
        :options="availableTimezones"
        text-path="value"
        value-path="id"
        search-bar
    />
</template>
