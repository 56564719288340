<template>
    <MMenu v-if="editable">
        <MMenuButton>
            <MButton
                icon-menu
                :variant="variant.name"
            >
                <FontAwesomeIcon
                    v-if="variant.icon"
                    :fixed-width="true"
                    :icon="variant.icon"
                />
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem
                :label="__('common:presence')"
                @click="switchVal('recurrent')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-green-500"
                        :fixed-width="true"
                        icon="fa-solid fa-clock"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                :label="__('common:meal')"
                @click="switchVal('meals')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-cyan-500"
                        :fixed-width="true"
                        icon="fa-solid fa-utensils"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                :label="__('common:care')"
                @click="switchVal('health')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-pink-500"
                        :fixed-width="true"
                        icon="fa-solid fa-heart"
                    />
                </template>
            </MMenuItem>
            <MMenuDivider />
            <MMenuItem
                :label="__('common:occasional')"
                @click="switchVal('occasional')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-blue-500"
                        :fixed-width="true"
                        icon="fa-solid fa-calendar-plus"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                :label="__('common:absence')"
                @click="switchVal('absence')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-red-500"
                        :fixed-width="true"
                        icon="fa-solid fa-calendar-times"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                :label="__('common:adaptation')"
                @click="switchVal('adaptation')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-yellow-500"
                        :fixed-width="true"
                        icon="fa-solid fa-child-reaching"
                    />
                </template>
            </MMenuItem>
            <MMenuItem
                :label="__('common:overrun_one')"
                @click="switchVal('overrun')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-blue-500"
                        :fixed-width="true"
                        icon="fa-solid fa-sign-out"
                    />
                </template>
            </MMenuItem>
            <MMenuDivider />
            <MMenuItem
                :label="__('common:other_one')"
                @click="switchVal('custom')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        class="tw-text-gray-500"
                        :fixed-width="true"
                        icon="fa-solid fa-note"
                    />
                </template>
            </MMenuItem>
        </MMenuItems>
    </MMenu>

    <FontAwesomeIcon
        v-else-if="variant.icon"
        :class="[variant.text, 'tw-mr-2']"
        :fixed-width="true"
        :icon="variant.icon"
    />
</template>

<script>
    export default {
        props: {
            modelValue: {},
            editable: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue', 'manualChange'],
        data() {
            return {
                variant: {},
                myVal: null,
            };
        },
        watch: {
            modelValue() {
                this.myVal = this.modelValue;
            },

            myVal(val) {
                this.setVariant(val);
                this.editable ? this.$emit('update:modelValue', val) : null;
            },
        },
        mounted() {
            this.setVariant(this.modelValue);
        },
        methods: {
            switchVal(val) {
                if (val !== this.modelValue) {
                    this.setVariant(val);
                    this.editable ? this.$emit('manualChange', val) : null;
                }
            },
            setVariant(val) {
                this.myVal = val;

                switch (val) {
                    case 'recurrent':
                        this.variant = {
                            name: 'green',
                            icon: 'fa-solid fa-clock',
                            text: 'tw-text-green-500',
                        };
                        break;
                    case 'meals':
                        this.variant = {
                            name: 'cyan',
                            icon: 'fa-solid fa-utensils',
                            text: 'tw-text-cyan-500',
                        };
                        break;
                    case 'health':
                        this.variant = {
                            name: 'pink',
                            icon: 'fa-solid fa-heart',
                            text: 'tw-text-pink-500',
                        };
                        break;
                    case 'occasional':
                        this.variant = {
                            name: 'blue',
                            icon: 'fa-solid fa-calendar-plus',
                            text: 'tw-text-blue-500',
                        };
                        break;
                    case 'absence':
                        this.variant = {
                            name: 'red',
                            icon: 'fa-solid fa-calendar-times',
                            text: 'tw-text-red-500',
                        };
                        break;
                    case 'adaptation':
                        this.variant = {
                            name: 'yellow',
                            icon: 'fa-solid fa-child-reaching',
                            text: 'tw-text-yellow-500',
                        };
                        break;
                    case 'overrun':
                        this.variant = {
                            name: 'blue',
                            icon: 'fa-solid fa-sign-out',
                            text: 'tw-text-blue-500',
                        };
                        break;
                    case 'deposit':
                        this.variant = {
                            name: 'gray',
                            icon: 'fa-solid fa-note',
                            text: 'tw-text-gray-500',
                        };
                        break;
                    case 'registration':
                        this.variant = {
                            name: 'gray',
                            icon: 'fa-solid fa-note',
                            text: 'tw-text-gray-500',
                        };
                        break;
                    case 'cmg':
                        this.variant = {
                            name: 'green',
                            icon: 'fa-solid fa-clock',
                            text: 'tw-text-green-500',
                        };
                        break;
                    default:
                        this.myVal = 'custom';
                        this.variant = {
                            name: 'gray',
                            icon: 'fa-solid fa-note',
                            text: 'tw-text-gray-500',
                        };
                        break;
                }
            },
        },
    };
</script>
