<template>
    <div class="CustomerHeroStats">
        <div class="CustomerHeroStats__container">
            <MBillingDetailItem
                color="green"
                :subtitle="
                    __('billing_core:invoice_count', {
                        count: customer.computed.invoice_count - customer.computed.draft_invoice_count,
                    })
                "
                :title="__('common:billed_other')"
                :model-value="format(customer.computed.sales_amount)"
            />
            <MBillingDetailItem
                color="green"
                :subtitle="__('billing_customer:balance_states.cashed')"
                :title="__('common:transactions')"
                :model-value="format(customer.computed.succeeded_transaction_amount)"
            />
            <MBillingDetailItem
                color="blue"
                :subtitle="__('billing_core:invoice_count', {count: customer.computed.draft_invoice_count})"
                :title="__('billing_customer:balance_states.in_coming')"
                :model-value="format(customer.computed.draft_payment_amount)"
            />
        </div>
        <div class="CustomerHeroStats__container">
            <MBillingDetailItem
                color="orange"
                :title="__('common:pending')"
                :model-value="
                    format(customer.computed.pending_payment_amount + customer.computed.upcoming_payment_amount)
                "
            />
            <MBillingDetailItem
                color="red"
                :title="__('common:unpaid_one')"
                :model-value="format(customer.computed.unpaid_payment_amount)"
            />
            <MBillingDetailItem
                v-if="customer.computed.uncollectible_payment_amount > 0"
                color="red"
                :title="__('common:uncollectible_one')"
                :model-value="format(customer.computed.uncollectible_payment_amount)"
            />
            <MBillingDetailItem
                color="gray"
                :title="__('billing_customer:balance_states.balance')"
                :model-value="format(customer.computed.balance_amount)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import MBillingDetailItem from '@/modules/meeko-ui/components/MBillingDetailItem.vue';

    export default defineComponent({
        components: {MBillingDetailItem},
        props: {
            customer: {type: Object as PropType<CustomerModel | undefined>, default: undefined},
        },
        setup(props) {
            const {format} = useFormatCurrency(props.customer?.computed.currency_iso_code);

            return {
                format,
            };
        },
    });
</script>

<style scoped>
    .CustomerHeroStats {
        @apply tw-flex tw-w-full tw-flex-col tw-space-y-2 lg:tw-flex-row lg:tw-space-x-4 lg:tw-space-y-0;
    }

    .CustomerHeroStats__container {
        @apply tw-flex tw-flex-col tw-space-y-2 tw-text-base;
    }
</style>
