<script setup lang="ts">
    import {ref} from 'vue';

    import AdviceOne from '@/assets/images/meeko-family/conseil_1.jpg';
    import AdviceTwo from '@/assets/images/meeko-family/conseil_2.jpg';
    import AdviceThree from '@/assets/images/meeko-family/conseil_3.jpg';
    import AdviceFour from '@/assets/images/meeko-family/conseil_4.jpg';
    import AdviceFive from '@/assets/images/meeko-family/conseil_5.jpg';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';

    defineProps<{
        modal: ModalType;
    }>();

    const emit = defineEmits<{
        (e: 'confirmed'): void;
    }>();

    const currentSlide = ref(0);

    const slides = ref([
        {
            title: __('reporting:advice_title_one'),
            text: __('reporting:advice_text_one'),
            image: AdviceOne,
        },
        {
            title: __('reporting:advice_title_two'),
            text: __('reporting:advice_text_two'),
            image: AdviceTwo,
        },
        {
            title: __('reporting:advice_title_three'),
            text: __('reporting:advice_text_three'),
            image: AdviceThree,
        },
        {
            title: __('reporting:advice_title_four'),
            text: __('reporting:advice_text_four'),
            image: AdviceFour,
        },
        {
            title: __('reporting:advice_title_five'),
            text: __('reporting:advice_text_five'),
            image: AdviceFive,
        },
    ]);

    const isButtonDisabled = ref(false);

    function disableButtonsTemporarily() {
        isButtonDisabled.value = true;

        setTimeout(() => {
            isButtonDisabled.value = false;
        }, 1500);
    }

    function nextSlide() {
        currentSlide.value = currentSlide.value + 1;
        disableButtonsTemporarily();
    }

    function prevSlide() {
        currentSlide.value = currentSlide.value - 1;
        disableButtonsTemporarily();
    }
</script>

<template>
    <MModal
        :header-title="__('reporting:advice_title')"
        :modal="modal"
        size="lg"
    >
        <img
            alt="Meeko"
            class="MeekoFamilyReportingModalModeration__image"
            :src="slides[currentSlide].image"
        />
        <div class="MeekoFamilyReportingModalModeration__container">
            <MHeading level="h3">
                {{ slides[currentSlide].title }}
            </MHeading>
            <div class="MeekoFamilyReportingAdvice__text">
                {{ slides[currentSlide].text }}
            </div>
        </div>
        <template #footer-start="{hide}">
            <MButton
                v-if="currentSlide > 0"
                class="MeekoFamilyReportingAdvice__button-prev"
                @click="prevSlide"
            >
                {{ __('common:actions.previous') }}
                <template #left-icons>
                    <FontAwesomeIcon icon="fas fa-arrow-left" />
                </template>
            </MButton>
            <MButton
                v-else
                @click="hide"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
        </template>
        <template #footer-end>
            <MButton
                v-if="currentSlide < slides.length - 1"
                key="next-button"
                :disabled="isButtonDisabled"
                class="MeekoFamilyReportingAdvice__button-next"
                data-action-name="nextSlide"
                variant="primary"
                @click="nextSlide"
            >
                {{ __('common:actions.next') }}
                <template #right-icons>
                    <FontAwesomeIcon icon="fas fa-arrow-right" />
                </template>
            </MButton>
            <MButton
                v-else
                key="confirm-button"
                variant="danger"
                :disabled="isButtonDisabled"
                @click="emit('confirmed')"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-lock" />
                </template>
                {{ __('reporting:access_advanced_settings') }}
            </MButton>
        </template>
    </MModal>
</template>

<style scoped>
    .MeekoFamilyReportingModalModeration__image {
        @apply tw-mb-4 tw-h-auto tw-w-full tw-rounded-xl;
    }

    .MeekoFamilyReportingModalModeration__container {
        @apply tw-flex tw-flex-col tw-gap-4;
        @apply sm:tw-h-32;
    }
</style>
