<template>
    <div class="planning tw-float-none sm:tw-float-left">
        <planning-generator
            :closing="closing"
            :day="day"
            :events="kid.events"
            inner-text
            :nursery="nursery"
            :opening="opening"
            :show-clock="false"
            :show-divider="false"
            :show-menu="showMenu"
            small
            :user="user"
        />
    </div>
</template>

<script>
    export default {
        props: ['nursery', 'kid', 'date', 'day', 'opening', 'closing', 'user', 'showMenu'],
    };
</script>

<style scoped>
    @media screen(sm) {
        .planning {
            width: 100%;
        }
    }
</style>
