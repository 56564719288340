import {MqlOperation} from '@meekohq/lumos';

import type {DetachCreditNoteToInvoiceRequest} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceRequest';
import type {DetachCreditNoteToInvoiceUseCase} from '@/modules/cashier/payment/application/DetachCreditNoteToInvoiceUseCase';
import type {SaveAllocationResult} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
import {CreditNoteAllocationErrorInterceptor} from '@/modules/cashier/payment/infrastructure/CreditNoteAllocationErrorInterceptor';

export class DetachCreditNoteToInvoiceInteractor implements DetachCreditNoteToInvoiceUseCase {
    public async detach(detachCreditNoteRequest: DetachCreditNoteToInvoiceRequest): Promise<SaveAllocationResult> {
        const operation = new MqlOperation('cashier/allocations/adjust-credit-note-amount', {
            allocation_id: detachCreditNoteRequest.allocationId,
            credit_note_id: detachCreditNoteRequest.creditNoteId,
            payment_id: detachCreditNoteRequest.paymentId,
            amount: detachCreditNoteRequest.amount,
        });

        try {
            await operation.run();

            return {
                status: 'success',
                sourceKey: 'todo',
            };
        } catch (err) {
            return {
                status: 'failed',
                sourceKey: 'todo',
                reason: CreditNoteAllocationErrorInterceptor.intercept(err as Error),
            };
        }
    }
}
