import {collect, ValidationError, ValidationErrorItem} from '@meekohq/lumos';
import {forEach} from 'lodash-es';

import {BaseError} from '@/modules/app/utils/errors/BaseError';
import __ from '@/modules/app/utils/i18n-facade';
import type {ContractAggregateError} from '@/modules/human-resources/contract/domain/errors/ContractAggregateError';

export class ContractErrorMapper {
    public static CUSTOM_ERROR_MESSAGES = {
        broked_at: {
            '0x3805E59761': () => __('hr_contract:errors.0x3805E59761'),
            '0x380603CC7E': () => __('hr_contract:errors.0x3805E59761'),
        },
        ended_at: {
            '0x3805E597A7': () => __('hr_contract:errors.0x3805E597A7'),
            '0x380603CC7E': () => __('hr_contract:errors.0x3805E597A7'),
        },
        hours_per_week: {
            '0x35C472A229': () => __('hr_contract:errors.0x35C472A229'),
        },
        trial_periods: {
            '0x3115708F32': () => __('hr_contract:errors.0x3115708F32'),
        },
    };

    public static mapFromContractAggregateError(error: ContractAggregateError): ValidationError {
        const validationErrorItems: ValidationErrorItem[] = [];
        forEach(error.getErrors(), error => {
            validationErrorItems.push(ContractErrorMapper.mapFromDomainError(error));
        });

        return new ValidationError(collect(validationErrorItems));
    }

    public static mapFromDomainError(error: Error): ValidationErrorItem {
        if (error instanceof BaseError) {
            return new ValidationErrorItem(error.message, error.code, [], {pointer: error.source});
        } else {
            return new ValidationErrorItem(__('common:errors.generic'), 'generic', [], {pointer: 'contract'});
        }
    }

    public static mapFromValidationError(error: ValidationError): ValidationError {
        const newValidationErrorItems: ValidationErrorItem[] = [];

        error.errors.each(errorItem => {
            newValidationErrorItems.push(ContractErrorMapper.mapFromValidationErrorItem(errorItem));
        });

        return new ValidationError(collect(newValidationErrorItems));
    }

    public static mapFromValidationErrorItem(errorItem: ValidationErrorItem): ValidationErrorItem {
        if (errorItem.code === '0x3115708F32') {
            return new ValidationErrorItem(errorItem.message, errorItem.code, [], {pointer: 'trial_periods'});
        } else if (errorItem.code === '0x311ABD49C9') {
            return new ValidationErrorItem(errorItem.message, errorItem.code, [], {pointer: 'contract_organizations'});
        } else {
            return new ValidationErrorItem(errorItem.message, errorItem.code, errorItem.detail, errorItem.source);
        }
    }
}
