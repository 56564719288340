<template>
    <div>
        <div
            v-if="requests.count()"
            class="list-wrapper"
        >
            <TeamsRequestsListItem
                v-for="request in requests"
                :key="request.attributes.id"
                :request="request"
                @prevent-click-outside="$emit('preventClickOutside', $event)"
            />
            <CListHasMoreTrigger @is-visible="fetchNextPage" />
        </div>
        <CCenter
            v-if="loading"
            :class="{'tw-min-h-inherit': requests.isEmpty()}"
        >
            <loader
                shadow="true"
                size="sm"
                :title="__('common:loading_dots')"
            />
        </CCenter>
        <CCenter
            v-if="!requests.count() && !loading"
            class="tw-min-h-inherit tw-p-4 tw-text-center tw-text-disabled"
        >
            {{ __('request:no_request') }}
        </CCenter>
    </div>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {debounce} from 'lodash-es';
    import {defineComponent, ref, watch} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import useManager from '@/modules/app/composables/useManager';
    import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import usePaginator from '@/modules/legacy/utils/usePaginator';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import TeamsRequestsListItem from '@/modules/request/components/Teams/TeamsRequestsListItem.vue';
    import RequestModel from '@/modules/request/models/RequestModel';
    import RequestStageModel from '@/modules/request/models/RequestStageModel';
    import RequestTypeModel from '@/modules/request/models/RequestTypeModel';

    export default defineComponent({
        components: {TeamsRequestsListItem},
        emits: ['preventClickOutside'],
        setup() {
            const loading = ref(false);
            const {activeOrganization} = useManager();
            const requests = ref(collect<RequestModel>());
            const requestStage = ref();
            const {can} = useAbility();
            const {organizationIds} = useManager();

            const debounceFetchRequests = debounce(fetchRequests, 1000, {
                leading: false,
                trailing: true,
            });

            const {paginator, currentPage, setPaginator} = usePaginator(debounceFetchRequests, false);

            const {
                selectedStaffs,
                selectedPeriods,
                selectedRequestTypes,
                selectedOrganizations,
                selectedTypeStatus,
                selectedKidsGroups,
                setSelectedTypeStatus,
                setSelectedStaffs,
                setSelectedPeriods,
                setSelectedRequestTypes,
                setSelectedOrganizations,
                setSelectedKidsGroups,
            } = useFilterSelectedElements();

            const eventRelationsToLoad = function (query: QueryBuilder<any>) {
                query
                    .with(new EventModel().staff())
                    .with(new EventModel().eventType(), query1 => {
                        query1.with(new EventTypeModel().iconModel());
                    })
                    .with(new EventModel().balanceAllocations(), query1 => {
                        query1.with(new BalanceAllocationModel().balanceType());
                    })
                    .with(new EventModel().organization())
                    .with(new EventModel().kidsGroup());
            };

            async function fetchRequests(page = 1, perPage = 20) {
                loading.value = true;

                const indexQuery = RequestModel.query();

                if (selectedTypeStatus.value.length) {
                    indexQuery.whereIn('status', selectedTypeStatus.value);
                }

                indexQuery.where(query => {
                    query.scope('inRange', [selectedPeriods.value.from, selectedPeriods.value.to]);
                });

                if (selectedOrganizations.value.length) {
                    indexQuery.whereHas(new RequestModel().organizations(), query => {
                        query.whereIn('id', selectedOrganizations.value);
                    });
                } else {
                    indexQuery.whereHas(new RequestModel().organizations(), query => {
                        query.whereIn('id', organizationIds.value);
                    });
                }

                if (selectedRequestTypes.value.length) {
                    indexQuery.whereHas(new RequestModel().requestType(), query2 => {
                        query2.whereIn('id', selectedRequestTypes.value);
                    });
                }

                if (selectedStaffs.value.length) {
                    indexQuery.where('source_type', 'hr/staffs');
                    indexQuery.whereIn('source_id', selectedStaffs.value);
                }

                if (selectedKidsGroups.value.length) {
                    indexQuery.whereHas(new RequestModel().events(), query1 => {
                        query1.whereHas(new EventModel().kidsGroup(), query2 => {
                            query2.whereIn('id', selectedKidsGroups.value);
                        });
                    });
                }

                indexQuery.where(query => {
                    query.scope('inRange', [selectedPeriods.value.from, selectedPeriods.value.to]);
                });

                if (!can('read', 'staffs_planning')) {
                    indexQuery.whereDoesntHave(new RequestModel().events());
                }

                indexQuery
                    .with(new RequestModel().requestType(), query => {
                        query.with(new RequestTypeModel().eventTypes());
                    })
                    .with(new RequestModel().events(), eventRelationsToLoad)
                    .with(new RequestModel().source())
                    .with(new RequestModel().requestStages(), q => {
                        q.with(new RequestStageModel().requestTypeStage());
                    });

                indexQuery
                    .orderBy('created_at', 'desc')
                    .paginate(perPage, page)
                    .then(response => {
                        currentPage.value = page;
                        setPaginator({
                            current_page: response.currentPage(),
                            last_page: response.lastPage(),
                        });

                        if (response.currentPage() === 1) {
                            requests.value = response.items();

                            return;
                        }

                        response.items().each(requestModel => requests.value.push(requestModel));
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            }

            function fetchNextPage() {
                if (loading.value || paginator.value?.lastPage === currentPage.value) {
                    return;
                }

                loading.value = true;
                // Updating the page triggers a watcher in the paginator to fetch the next page
                currentPage.value++;
            }

            watch(
                [
                    selectedStaffs,
                    selectedRequestTypes,
                    selectedPeriods,
                    selectedOrganizations,
                    selectedTypeStatus,
                    selectedKidsGroups,
                ],
                () => {
                    requests.value = collect<RequestModel>();
                    debounceFetchRequests();
                },
                {deep: true}
            );

            watch(
                activeOrganization,
                () => {
                    requests.value = collect<RequestModel>();
                    fetchRequests();
                },
                {deep: true, immediate: true}
            );

            return {
                selectedTypeStatus,
                loading,
                requests,
                selectedRequestTypes,
                requestStage,
                selectedKidsGroups,
                setSelectedTypeStatus,
                setSelectedStaffs,
                setSelectedPeriods,
                setSelectedRequestTypes,
                setSelectedOrganizations,
                setSelectedKidsGroups,
                fetchNextPage,
            };
        },
    });

    export function useFilterSelectedElements() {
        const selectedStaffs = ref<string[]>([]);
        const selectedPeriods = ref({from: undefined, to: undefined});
        const selectedRequestTypes = ref<string[]>([]);
        const selectedOrganizations = ref<string[]>([]);
        const selectedTypeStatus = ref<string[]>([]);
        const selectedKidsGroups = ref<string[]>([]);

        const setSelectedStaffs = function (staffs: StaffModel[]) {
            selectedStaffs.value = collect(staffs).pluck('id').all() as string[];
        };

        const setSelectedPeriods = function (periods) {
            selectedPeriods.value = {...periods};
        };

        const setSelectedRequestTypes = function (status: RequestTypeModel[]) {
            selectedRequestTypes.value = collect(status).pluck('id').all() as string[];
        };

        const setSelectedOrganizations = function (organizations: OrganizationModel[]) {
            selectedOrganizations.value = collect(organizations).pluck('id').all() as string[];
        };

        const setSelectedTypeStatus = function (typeStatus) {
            selectedTypeStatus.value = [...typeStatus];
        };

        const setSelectedKidsGroups = function (kidsGroups) {
            selectedKidsGroups.value = collect(kidsGroups).pluck('id').all() as string[];
        };

        return {
            selectedStaffs,
            selectedPeriods,
            selectedRequestTypes,
            selectedOrganizations,
            selectedTypeStatus,
            selectedKidsGroups,
            setSelectedTypeStatus,
            setSelectedStaffs,
            setSelectedPeriods,
            setSelectedRequestTypes,
            setSelectedOrganizations,
            setSelectedKidsGroups,
        };
    }
</script>

<style scoped>
    .list-wrapper {
        @apply tw-relative tw-overflow-visible;
    }
</style>
