<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-variant="buttonVariant"
        :disabled="disabled"
        :display-select-all-options="displaySelectAllOptions"
        :has-unselect="hasUnselect"
        :hide-selected-option="hideSelectedOption"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :prevent-hide-on-click="preventHideOnClick"
        :search-bar="searchBar"
        :model-value="modelValue"
        :wrapper="wrapper"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @update:model-value="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            <slot name="button-text-empty" />
        </template>

        <template #button-text="{value}">
            {{ value.attributes.name }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{
                    __('components:count_item', {
                        value: orderizeValues(values),
                        count: values.length - 1,
                    })
                }}
            </slot>
        </template>

        <!--<template v-slot:unselect-item/>-->
        <!--<template v-slot:unselect-text/>-->

        <!--<template v-slot:fallback="{searchValue}"/>-->

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-items-center tw-pr-4">
                <CBadge
                    v-if="option.attributes.address.city"
                    class="tw-mr-1 tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-text-blue-500"
                    size="sm"
                    variant="primary"
                >
                    {{ option.attributes.address.city }}
                </CBadge>
                <div
                    class="tw-truncate"
                    v-html="highlight(searchValue, option.attributes.name)"
                />
            </div>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';
    import {computed, defineComponent, type PropType, ref} from 'vue';

    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            modelValue: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            displaySelectAllOptions: {type: Boolean, default: true},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            disabled: {type: Boolean, default: false},
            searchBar: {type: Boolean, default: true},
            searchFields: {
                type: Array as PropType<string[]>,
                default: () => ['name', 'company_name'],
                required: false,
            },
            hideSelectedOption: {type: Boolean, default: false},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            onlyUserOrganizations: {type: Boolean, default: false},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            wrapper: {type: String as PropType<'MPopover2' | 'ResourceFinderWrapper'>, default: 'MPopover2'},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const {activeOrganization} = useManager();
            const onChange = function (value) {
                emit('update:modelValue', value);
            };
            // !!! END: DO NOT EDIT !!

            const queryBuilder = computed(() => {
                const builder = OrganizationModel.query().orderBy('name');

                // Search for organizations by fields specified in the searchFields props
                if (props.searchFields.length) {
                    builder.where(query => {
                        props.searchFields.forEach((field, fieldIdx) => {
                            if (fieldIdx === 0) {
                                query.whereLike(field, `*${search.value}*`);
                            } else {
                                query.orWhereLike(field, `*${search.value}*`);
                            }
                        });
                    });
                }

                // Only get organizations that are related to the current user
                if (props.onlyUserOrganizations) {
                    builder.scope('userOrganizations');
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function () {
                //
            };

            // Method to orderize values in order to display "{activeOrganization} + 2 others" in selector button
            const orderizeValues = function (organizations: OrganizationModel[]) {
                const organization = organizations.find(item => item.id === activeOrganization.value.id);
                if (organization) {
                    return organization.attributes.name;
                } else {
                    return organizations[0].attributes.name;
                }
            };

            return {
                orderizeValues,
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
