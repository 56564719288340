<script setup lang="ts">
    import {inject, useSlots} from 'vue';

    import {type defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const context = inject<ReturnType<typeof defineFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('MMenuItem must be used inside a MMenu');
    }

    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        variant: {
            type: String,
            default: 'default',
            validator: (value: string) => ['default', 'danger', 'success'].includes(value),
        },
        manual: {
            type: Boolean,
            default: false,
        },
        autoClosePopover: {
            type: Boolean,
            default: true,
        },
    });

    const emit = defineEmits(['click']);

    const {closePopover} = context;

    function onClick(event) {
        if (props.manual) {
            return;
        }

        emit('click', event);

        if (props.autoClosePopover) {
            closePopover();
        }
    }

    const slots = useSlots();

    const hasIconSlot = slots.icon !== undefined;

    defineExpose({
        closePopover,
    });
</script>

<template>
    <div
        class="MMenuItem__container"
        data-focus-children="true"
        tabindex="-1"
    >
        <button
            class="MMenuItem"
            :class="[{'MMenuItem--disabled': disabled}, `MMenuItem--${variant}`]"
            :disabled="disabled"
            @click="onClick"
        >
            <div
                v-if="hasIconSlot"
                class="MMenuItem__icon"
            >
                <slot name="icon" />
            </div>
            <slot :close="closePopover">
                {{ label }}
            </slot>
        </button>
    </div>
</template>

<style scoped>
    .MMenuItem__container {
        @apply tw-px-1.5;
    }

    .MMenuItem {
        @apply tw-flex tw-w-full tw-cursor-pointer tw-rounded-md tw-px-3 tw-py-2 tw-font-display;
        @apply tw-flex tw-items-center;
        @apply tw-text-left;

        @apply tw-transition-all tw-ease-in-out;
    }

    .MMenuItem__icon {
        @apply tw-transition-all tw-ease-in-out;
        @apply tw-mr-2 tw-flex;
    }

    .MMenuItem--default {
        @apply tw-text-gray-700;
        @apply hover:tw-bg-primary-100 hover:tw-text-primary-600;
        @apply focus-visible:tw-bg-primary-100 focus-visible:tw-text-primary-600;

        .MMenuItem__icon {
            @apply tw-text-primary-500;
        }

        &:hover,
        &:focus-visible {
            .MMenuItem__icon {
                @apply tw-text-primary-600;
            }
        }
    }

    .MMenuItem--danger {
        @apply tw-text-gray-700;
        @apply hover:tw-bg-danger-100 hover:tw-text-danger-600;
        @apply focus-visible:tw-bg-danger-100 focus-visible:tw-text-danger-600;

        .MMenuItem__icon {
            @apply tw-text-danger-500;
        }

        &:hover,
        &:focus-visible {
            .MMenuItem__icon {
                @apply tw-text-danger-600;
            }
        }
    }

    .MMenuItem--success {
        @apply tw-text-gray-700;
        @apply hover:tw-bg-success-100 hover:tw-text-success-700;
        @apply focus-visible:tw-bg-success-200 focus-visible:tw-text-success-700;

        .MMenuItem__icon {
            @apply tw-text-success-500;
        }

        &:hover,
        &:focus-visible {
            .MMenuItem__icon {
                @apply tw-text-success-700;
            }
        }
    }

    .MMenuItem--disabled {
        @apply tw-cursor-not-allowed tw-text-gray-700/40 hover:tw-text-gray-700/40;
        @apply hover:tw-bg-gray-100;

        .MMenuItem__icon {
            @apply tw-text-gray-700/40;
        }

        &:hover,
        &:focus-visible {
            .MMenuItem__icon {
                @apply tw-text-gray-700/40;
            }
        }
    }
</style>
