<template>
    <MForm @submit.prevent="save">
        <MFieldset>
            <MLabel>
                {{ __('billing_customer:entity') }}
            </MLabel>
            <CButtonGroup class="tw-w-full">
                <MButton
                    class="tw-w-full"
                    :disabled="customerModel.exists"
                    :variant="!customerModel.isCompany ? 'primary' : undefined"
                    @click="customerModel.attributes.person = 'natural'"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-user" />
                    </template>
                    {{ __('billing_customer:entity_natural') }}
                </MButton>
                <MButton
                    class="tw-w-full"
                    :disabled="customerModel.exists"
                    :variant="customerModel.isCompany ? 'primary' : undefined"
                    @click="customerModel.attributes.person = 'legal'"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-duotone fa-city" />
                    </template>
                    {{ __('billing_customer:entity_legal') }}
                </MButton>
            </CButtonGroup>
        </MFieldset>
        <MFieldset v-if="!customerModel.isCompany && !customerFamily && !familyModel">
            <MLabel>{{ __('common:family') }} *</MLabel>
            <FamilyFinder
                v-model="selectedFamily"
                button-class="tw-w-full"
                only-user-organizations
            >
                <template #button-text-empty>
                    {{ __('billing_customer:select_a_family') }}
                </template>
            </FamilyFinder>
            <CFormErrorMessageList :errors="familyPivotError.get('family_id')" />
        </MFieldset>
        <MFieldset>
            <MLabel>{{ __('billing_customer:customer_name') }} *</MLabel>
            <CInput
                ref="customerName"
                v-model="customerModel.attributes.name"
                v-focus
                :has-error="customerError.has('name')"
                :placeholder="__('billing_customer:mr_and_mrs_dupont')"
                size="lg"
            />
            <CFormErrorMessageList :errors="customerError.get('name')" />
        </MFieldset>
        <MFieldset v-if="customerModel.isCompany">
            <MLabel>{{ __('billing_customer:tva_number') }}</MLabel>
            <CInput
                v-model="customerModel.attributes.vat_number"
                :has-error="customerError.has('vat_number')"
                :placeholder="__('billing_customer:tva_country_example')"
            />
            <CFormErrorMessageList :errors="customerError.get('vat_number')" />
        </MFieldset>
        <CDisclosure
            :open="false"
            :title="__('billing_customer:accounting_information_other')"
        >
            <MFieldset>
                <MLabel>
                    {{ __('billing_customer:customer_number') }}
                    <MHelpTooltip class="tw-ml-2">
                        <div v-html="__('billing_customer:for_accounting')" />
                    </MHelpTooltip>
                </MLabel>
                <CInput
                    ref="customerName"
                    v-model="customerModel.attributes.account_number"
                    :has-error="customerError.has('account_number')"
                    :placeholder="__('billing_customer:customer_number_xxxx')"
                />
                <CFormErrorMessageList :errors="customerError.get('account_number')" />
            </MFieldset>
        </CDisclosure>
        <CFormSubmitTrigger />
    </MForm>
</template>

<script lang="ts">
    import {catcher, ValidationError} from '@meekohq/lumos';
    import mitt from 'mitt';
    import {computed, defineComponent, onMounted, onUnmounted, type PropType, reactive, ref, toRef, watch} from 'vue';

    import useError from '@/modules/app/composables/useError';
    import useModal from '@/modules/app/composables/useModal';
    import CustomerModalSameAccount from '@/modules/cashier/components/core/customer/pages/CustomerModalSameAccount.vue';
    import useSaveCustomer from '@/modules/cashier/composables/core/customer/useSaveCustomer';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import FamilyFinder from '@/modules/family/components/family/FamilyFinder.vue';
    import type FamilyModel from '@/modules/family/models/FamilyModel';

    export default defineComponent({
        components: {FamilyFinder},
        props: {
            customerModel: {
                type: Object as PropType<CustomerModel>,
                default: () => reactive(new CustomerModel()),
            },
            familyModel: {
                type: (Object as PropType<FamilyModel>) || null,
                default: null,
            },
        },
        emits: ['loading', 'saved'],
        setup: function (props, {emit}) {
            const customerError = useError();

            // Create error handler for the pivot between customer and family.
            const familyPivotError = useError();

            // Get the family of the customer
            const customerFamily = computed(() => props.customerModel?.families().value().first());

            // Init the family passed to the family selector
            const selectedFamily = ref<FamilyModel>();
            selectedFamily.value = props.familyModel
                ? (props.familyModel as FamilyModel)
                : (customerFamily.value as FamilyModel);

            const customerWithSameAccountNumber = ref<CustomerModel>();

            const isSameAccountWarningModalVisible = ref(false);

            const isConfirmedDuplicateAccount = ref(false);

            const bus = mitt<{stored: void; error: Error}>();

            const {saveCustomer, loading} = useSaveCustomer({
                bus,
                customer: toRef(props, 'customerModel'),
                customerWithSameAccountNumber,
                selectedFamily,
                isSameAccountWarningModalVisible,
                isConfirmedDuplicateAccount,
            });

            watch(loading, loading => emit('loading', loading));

            watch(
                () => props.customerModel.attributes.person,
                person => {
                    if (person === 'natural') {
                        props.customerModel.attributes.vat_number = '';
                    }
                }
            );

            onMounted(() => {
                bus.on('stored', onStored);
                bus.on('error', error => {
                    if (error instanceof ValidationError) {
                        catcher()
                            .on(ValidationError, error => {
                                if (error.model instanceof CustomerModel) {
                                    customerError.addValidationError(error);
                                } else {
                                    familyPivotError.addValidationError(error);
                                }
                            })
                            .catch(error);
                    }
                });
            });

            onUnmounted(() => {
                bus.off('stored');
                bus.off('error');
            });

            function save() {
                // Reset both error handler
                customerError.reset();
                familyPivotError.reset();
                customerWithSameAccountNumber.value = undefined;
                saveCustomer();
            }

            function onStored() {
                emit('saved', props.customerModel);
            }

            watch(isSameAccountWarningModalVisible, value => {
                if (value) {
                    sameAccountModal();
                }
            });

            function sameAccountModal() {
                useModal({
                    component: CustomerModalSameAccount,
                    props: {
                        customerWithSameAccountNumber,
                    },
                    listeners: modal => ({
                        confirmed: () => {
                            isConfirmedDuplicateAccount.value = true;
                            save();
                            isSameAccountWarningModalVisible.value = false;
                            modal.hide();
                        },
                        dismissed: () => {
                            isSameAccountWarningModalVisible.value = false;
                            modal.hide();
                        },
                    }),
                }).show();
            }

            return {
                isConfirmedDuplicateAccount,
                isSameAccountWarningModalVisible,
                customerWithSameAccountNumber,
                selectedFamily,
                customerFamily,
                customerError,
                familyPivotError,
                save,
            };
        },
    });
</script>
