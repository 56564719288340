<template>
    <div>
        <div class="tw-text-center">
            <MButton
                class="tw-mb-4 tw-text-info-500"
                variant="link"
                @click="updateFromCAF"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-sync" />
                </template>
                {{ __('billing_setting:update_with_latest_data') }}
            </MButton>
        </div>
        <div class="tw-overflow-scroll">
            <table class="tw-w-full">
                <thead>
                    <tr>
                        <th scope="col">
                            {{ __('billing_setting:slice') }}
                        </th>
                        <th scope="col">
                            {{ __('common:income') }}
                        </th>
                        <th
                            v-for="i in maxKids"
                            :key="'kid' + i"
                            scope="col"
                        >
                            {{ i > 1 ? i + ' ' + __('common:kid_one') : i + ' ' + __('common:kid_other') }}
                        </th>
                        <th scope="col">
                            {{ __('billing_setting:more_than_children_value', {value: maxKids}) }}
                            <MHelpTooltip>
                                {{ __('billing_setting:amount_of_the_bracket_will_increase') }}
                            </MHelpTooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(price, priceKey) in prices"
                        :key="'price ' + priceKey"
                    >
                        <th scope="row">
                            {{ priceKey + 1 }}
                        </th>
                        <td>
                            <MMenu class="tw-w-full">
                                <MMenuButton class="tw-w-full">
                                    <MButton
                                        class="tw-w-full tw-justify-between"
                                        icon-menu
                                        :label="priceRuleToText(price.rule)"
                                        variant="primary"
                                    />
                                </MMenuButton>
                                <MMenuItems>
                                    <MMenuItem
                                        :label="priceRuleToText('&lt;')"
                                        @click="price.rule = '<'"
                                    />
                                    <MMenuItem
                                        :label="priceRuleToText('&lt;=')"
                                        @click="price.rule = '<='"
                                    />
                                    <MMenuDivider />
                                    <MMenuItem
                                        :label="priceRuleToText('>')"
                                        @click="price.rule = '>'"
                                    />
                                    <MMenuItem
                                        :label="priceRuleToText('>=')"
                                        @click="price.rule = '>='"
                                    />
                                </MMenuItems>
                            </MMenu>
                        </td>
                        <td
                            v-for="(level, levelKey) in price.levels"
                            :key="'level' + levelKey"
                        >
                            <MInput
                                v-model="level.income"
                                :placeholder="__('common:income')"
                            />
                        </td>
                        <td class="tw-bg-white">
                            <CInputGroup>
                                <CInputAddon>
                                    <FontAwesomeIcon icon="fa-solid fa-plus" />
                                </CInputAddon>
                                <MInput
                                    v-model="price.more"
                                    :placeholder="__('common:income')"
                                />
                            </CInputGroup>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="tw-my-2 tw-flex tw-flex-row tw-items-center tw-gap-2">
            <MHeading level="h4">{{ __('billing_setting:slice') }} :</MHeading>
            <MButton
                round
                size="xs"
                variant="success"
                @click="addPrice"
            >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
            </MButton>
            <MButton
                round
                size="xs"
                variant="danger"
                @click="deletePrice"
            >
                <FontAwesomeIcon icon="fa-solid fa-times" />
            </MButton>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <MHeading level="h4">{{ __('common:kid_other') }} :</MHeading>
            <MButton
                round
                size="xs"
                variant="success"
                @click="addLevel"
            >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
            </MButton>
            <MButton
                round
                size="xs"
                variant="danger"
                @click="deleteLevel"
            >
                <FontAwesomeIcon icon="fa-solid fa-times" />
            </MButton>
        </div>
    </div>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _max from 'lodash-es/max';

    import __ from '@/modules/app/utils/i18n-facade';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';

    export default {
        props: ['prices'],
        emits: ['update:prices'],
        data() {
            return {
                income: 0,
            };
        },
        computed: {
            maxKids() {
                const maxes = [];
                _forEach(this.prices, price => {
                    maxes.push(price.levels.length);
                });

                return _max(maxes);
            },
        },
        methods: {
            addPrice() {
                const newPrice = {
                    more: 0,
                    rule: '<=',
                    levels: [],
                };

                Array(this.maxKids)
                    .fill()
                    .map(() => {
                        newPrice.levels.push({
                            income: 0,
                        });
                    });

                this.prices.push(newPrice);
            },
            deletePrice() {
                this.prices.splice(this.prices.length - 1, 1);
            },
            addLevel() {
                _forEach(this.prices, price => {
                    price.levels.push({income: 0});
                });
            },
            deleteLevel() {
                _forEach(this.prices, price => {
                    price.levels.splice(price.levels.length - 1, 1);
                });
            },
            priceRuleToText(rule) {
                if (rule === '&lt;' || rule === '<') {
                    return __('billing_setting:less_than');
                }

                if (rule === '&lt;=' || rule === '<=') {
                    return __('billing_setting:less_than_or_equal_to');
                }

                if (rule === '>') {
                    return __('billing_setting:greater_than');
                }

                if (rule === '>=') {
                    return __('billing_setting:greater_than_or_equal_to');
                }
            },
            updateFromCAF() {
                const myConfig = _cloneDeep(defaultConfig);
                this.$emit('update:prices', myConfig.pricing.discount.brackets);
            },
        },
    };
</script>

<style scoped>
    table th,
    table td {
        @apply tw-p-3;
        @apply tw-border-t tw-border-gray-200;
    }

    table thead th {
        @apply tw-border-b-2 tw-border-gray-200;
    }

    table tbody + tbody {
        @apply tw-border-t-2 tw-border-gray-200;
    }
</style>
