<template>
    <div class="tw-contents">
        <MagicIndexSearchInputFilter
            ref="searchInputFilter"
            v-model="filtersStore.searchFilter.data.value"
            :placeholder="__('common:search_dots')"
        />
        <MToggleFilter
            ref="isPendingFilter"
            v-model="filtersStore.unallocatedOnlyFilter.data.value"
            :label="__('billing_transaction:only_pending')"
        />
        <MDatePeriodFilter
            v-if="filtersStore.periodFilter.data.value"
            ref="datePeriodFilter"
            v-model="filtersStore.periodFilter.data.value"
            allow-undefined
            button-size="sm"
        />
        <AmountFilter
            ref="amountFilter"
            v-model:first-value="filtersStore.amountFilter.data.value.min"
            v-model:second-value="filtersStore.amountFilter.data.value.max"
            :currency="amountCurrency"
            :range-max="2000"
            :range-min="-2000"
            size="sm"
        />
        <MFilterSort
            ref="sortFilter"
            :filters="filtersStore.orderByFilter.data.value"
            :options="sortOptions"
        />
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import AmountFilter from '@/modules/cashier/transaction/infrastructure/components/AmountFilter.vue';
    import type {OutputType} from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
    import MagicIndexSearchInputFilter from '@/modules/magic-index/components/atoms/MagicIndexSearchInputFilter.vue';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import MFilterSort from '@/modules/meeko-ui/components/MFilterSort.vue';
    import MToggleFilter from '@/modules/meeko-ui/components/MToggleFilter.vue';

    export default defineComponent({
        components: {
            MDatePeriodFilter,
            MFilterSort,
            MToggleFilter,
            MagicIndexSearchInputFilter,
            AmountFilter,
        },
        props: {
            filtersStore: {
                type: Object as PropType<OutputType>,
                required: true,
            },
            amountCurrency: {
                type: String,
                required: true,
            },
        },
        setup() {
            const searchInputFilter = ref();
            const isPendingFilter = ref();
            const datePeriodFilter = ref();
            const amountFilter = ref();
            const sortFilter = ref();

            const sortOptions = [
                {
                    value: 'date',
                    label: __('billing_core:by_date'),
                    sortLabel: __('billing_core:order_by_date'),
                    icon: 'fa-solid fa-calendar-days',
                },
                {
                    value: 'signed_amount',
                    label: __('billing_core:by_amount'),
                    sortLabel: __('billing_core:order_by_amount'),
                    icon: 'fa-solid fa-money-bill',
                },
            ];

            return {
                sortOptions,
                searchInputFilter,
                isPendingFilter,
                datePeriodFilter,
                amountFilter,
                sortFilter,
            };
        },
    });
</script>
