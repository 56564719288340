import {Model} from '@meekohq/lumos';

import DataStore from '@/modules/legacy/store/data.store';
import RequestStageModel from '@/modules/request/models/RequestStageModel';
import RequestTypeModel from '@/modules/request/models/RequestTypeModel';
import type {Status} from '@/modules/request/utils/Status';

export default class RequestTypeStageModel extends Model {
    public type = 'request/type_stages';

    attributes: {
        id: string;
        account_id: string | undefined;
        internal_id: string | undefined;
        type_id: string | undefined;
        icon_id: string | undefined;
        color: string | undefined;
        name: string | undefined;
        description: string | undefined;
        order: string | undefined;
        type_status: Status | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        internal_id: undefined,
        type_id: undefined,
        icon_id: undefined,
        color: undefined,
        name: undefined,
        description: undefined,
        order: undefined,
        type_status: undefined,
    };

    get icon() {
        return DataStore.iconPath(`${this.attributes.icon_id}`);
    }

    requestType() {
        return this.belongsTo('requestType', RequestTypeModel, 'type_id');
    }

    requestStages() {
        return this.hasMany('requestStages', RequestStageModel, 'type_stage_id');
    }
}
