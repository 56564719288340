import type {AxiosResponse} from 'axios';

import useApi from '@/modules/app/composables/useApi';
import type ContactModel from '@/modules/cashier/models/ContactModel';
import OrderModel from '@/modules/cashier/models/OrderModel';
import type FamilyModel from '@/modules/family/models/FamilyModel';
import type MemberModel from '@/modules/family/models/MemberModel';
import route from '@/modules/legacy/libs/ziggy';

export interface PayloadType {
    companyOrderModel: OrderModel | undefined;
    familyOrderModel: OrderModel | undefined;
    familyEndpoints: (ContactModel | MemberModel)[];
    companyEndpoints: (ContactModel | MemberModel)[];
    familyModel: FamilyModel | undefined;
}

export default class LegacyContractIntegrationService {
    private readonly originalContract: any;
    private _cashierPayload: PayloadType;

    constructor(contract: any, cashierPayload: PayloadType) {
        this.originalContract = contract;
        this._cashierPayload = cashierPayload;
    }

    static async saveEndpointsAndSubscriptions(models: (ContactModel | MemberModel)[]) {
        for (const model of models) {
            const endpointModel = model.notificationEndpoint().value();

            const subscriptions = endpointModel.subscriptions().value().all();

            if (subscriptions && subscriptions.length) {
                await endpointModel.save();
                const promises = [] as any[];
                for (const subscriptionModel of subscriptions) {
                    promises.push(subscriptionModel.save());
                }
                await Promise.all(promises);
            }
        }
    }

    async postUpdate(response: AxiosResponse) {
        const contract = response.data;
        const {familyOrderModel, companyOrderModel, companyEndpoints, familyEndpoints} = this.extractContractBag();

        // Update Family OrderModel
        if (familyOrderModel) {
            if (familyOrderModel.extra.markForDelete) {
                await familyOrderModel.delete();
            } else {
                familyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
                familyOrderModel.attributes.number = `${contract.no}-${contract.id}`;

                await familyOrderModel
                    .save()
                    .then(async () => {
                        if (contract.family_order_id !== familyOrderModel.getKey()) {
                            await this.attachContractToOrder(
                                contract,
                                'family_order_id',
                                {
                                    family_order_id: familyOrderModel.getKey(),
                                    draft: contract.draft,
                                },
                                familyOrderModel
                            );
                        }

                        // After saving the order, we save the related endpoints and subscriptions
                        await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(familyEndpoints);
                    })
                    .catch(error => {
                        throw error;
                    });
            }
        }

        // Update Company OrderModel
        if (companyOrderModel) {
            if (companyOrderModel.extra.markForDelete) {
                await companyOrderModel.delete();
            } else {
                companyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
                companyOrderModel.attributes.number = contract.no + '-E' + '-' + contract.id;

                await companyOrderModel
                    .save()
                    .then(async () => {
                        if (this.originalContract.company_order_id !== companyOrderModel.getKey()) {
                            await this.attachContractToOrder(
                                contract,
                                'company_order_id',
                                {
                                    company_order_id: companyOrderModel.getKey(),
                                    draft: contract.draft,
                                },
                                companyOrderModel
                            );
                        }

                        // After saving the order, we save the related endpoints and subscriptions
                        await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(companyEndpoints);
                    })
                    .catch(error => {
                        throw error;
                    });
            }
        }
    }

    async postCreate(response: AxiosResponse) {
        const contract = response.data;
        const {familyOrderModel, companyOrderModel, companyEndpoints, familyEndpoints} = this.extractContractBag();

        // Save Family OrderModel
        if (familyOrderModel) {
            familyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
            familyOrderModel.attributes.number = `${contract.no}-${contract.id}`;

            await familyOrderModel
                .save()
                .then(async () => {
                    await this.attachContractToOrder(
                        contract,
                        'family_order_id',
                        {
                            family_order_id: familyOrderModel.getKey(),
                            draft: contract.draft,
                        },
                        familyOrderModel
                    );

                    // After saving the order, we save the related endpoints and subscriptions
                    await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(familyEndpoints);
                })
                .catch(error => {
                    throw error;
                });
        }

        // Save Company OrderModel
        if (companyOrderModel) {
            companyOrderModel.attributes.status = contract.draft ? OrderModel.DRAFT : OrderModel.PENDING;
            companyOrderModel.attributes.number = `${contract.no}-E-${contract.id}`;

            await companyOrderModel
                .save()
                .then(async () => {
                    await this.attachContractToOrder(
                        contract,
                        'company_order_id',
                        {
                            company_order_id: companyOrderModel.getKey(),
                            draft: contract.draft,
                        },
                        companyOrderModel
                    );

                    // After saving the order, we save the related endpoints and subscriptions
                    await LegacyContractIntegrationService.saveEndpointsAndSubscriptions(companyEndpoints);
                })
                .catch(error => {
                    throw error;
                });
        }
    }

    private extractContractBag() {
        return {
            familyOrderModel: this._cashierPayload.familyOrderModel,
            companyOrderModel: this._cashierPayload.companyOrderModel,
            companyEndpoints: this._cashierPayload.companyEndpoints,
            familyEndpoints: this._cashierPayload.familyEndpoints,
            familyModel: this._cashierPayload.familyModel,
        };
    }

    private async attachContractToOrder(contract, key: string, data: any = {}, orderModel: OrderModel) {
        await useApi()
            .legacy.put(
                route('kid.contracts.update', {
                    contract: contract.id,
                }),
                data
            )
            .catch(error => {
                throw new Error('Failed to attach contract to order', {cause: error});
            });

        this.originalContract[key] = orderModel.getKey();
        contract[key] = orderModel.getKey();

        return true;
    }
}
