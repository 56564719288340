<template>
    <div>
        <p
            class="tw-mb-0 tw-text-base tw-text-gray-600"
            v-html="
                __('notification:new_preregistration_for', {
                    kid: `${notification.data.kid_first_name} ${notification.data.kid_last_name}`,
                })
            "
        />
        <p
            v-show="notification.data.content"
            class="tw-mb-0 tw-mt-1 tw-text-sm tw-text-gray-800"
        >
            {{ notification.data.content }}
        </p>

        <div class="tw-mt-2">
            <RouterLink :to="to">
                <MButton @click="handlerEmit">
                    {{ __('common:see_document') }}
                </MButton>
            </RouterLink>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    import {EventBus} from '@/eventBus';
    import ManagerStore from '@/modules/legacy/store/manager.store';

    export default {
        props: {
            notification: {type: Object},
        },
        emits: ['closeModal'],
        data() {
            return {
                activeOrganization: ManagerStore.activeOrganization,
            };
        },
        computed: {
            moment() {
                return moment;
            },
            to() {
                return {
                    name: 'registrations.show',
                    params: {
                        nursery: this.getNurseryId(),
                        registration: this.notification.data.registration_id,
                    },
                };
            },
        },
        mounted() {
            //
        },
        methods: {
            handlerEmit() {
                if (!this.notification.read_at) {
                    this.notification.read_at = moment().unix();
                }

                EventBus.$emit('update:notification', this.notification);
            },
            getNurseryId() {
                if (this.notification.data.nursery_ids) {
                    return this.notification.data.nursery_ids.includes(this.activeOrganization.id)
                        ? this.activeOrganization.id
                        : this.notification.data.nursery_ids[0];
                } else {
                    return this.notification.data.nursery_id;
                }
            },
        },
    };
</script>
