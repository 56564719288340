<template>
    <div class="EditInvoiceDetails">
        <EditInvoiceDetailsHeader
            :display-type="false"
            :editor-mode="false"
            :invoice="invoice"
            :with-tva="withTva"
        />
        <div class="EditInvoiceDetails__list">
            <EditInvoiceDetailsLine
                v-for="(line, i) in invoice.lines"
                :key="i"
                :currency="invoice.currency_iso_code"
                :line="line"
                :with-tva="withTva"
            />
        </div>
        <div class="EditInvoiceDetails__total-container">
            <div class="EditInvoiceDetails__total-item">
                {{ __('billing_invoice:total_with_vat') }}
            </div>
            <div class="EditInvoiceDetails__total-item">
                {{ format(invoice.grand_total) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import EditInvoiceDetailsHeader from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceDetailsHeader.vue';
    import EditInvoiceDetailsLine from '@/modules/cashier/components/billing/invoice/molecules/EditInvoiceDetailsLine.vue';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';

    export default defineComponent({
        components: {EditInvoiceDetailsHeader, EditInvoiceDetailsLine},
        mixins: [invoice, nursery],
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            withTva: {
                type: Boolean,
                required: true,
            },
            currency: {
                type: String,
                default: undefined,
            },
        },
        setup(props) {
            const {format} = useFormatCurrency(props.currency);

            return {
                format,
            };
        },
    });
</script>

<style scoped>
    .EditInvoiceDetails__list {
        @apply tw-my-2 tw-flex tw-flex-col tw-gap-2 md:tw-gap-0;
    }

    .EditInvoiceDetails__total-container {
        @apply tw-flex tw-flex-row tw-justify-end tw-gap-2;
    }

    .EditInvoiceDetails__total-item {
        @apply tw-w-1/2 sm:tw-w-auto;
        @apply tw-rounded-lg tw-bg-blue-100 tw-px-2 tw-py-1;
        @apply tw-truncate tw-font-semibold tw-text-blue-800;
        @apply first:tw-text-center last:tw-text-right;
    }
</style>
