<template>
    <textarea
        ref="inputEl"
        class="MTextarea"
        :class="baseClass"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :value="modelValue"
        @input="onInput"
    />
</template>

<script lang="ts">
    import {computed, defineComponent, nextTick, onMounted, ref} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            preventBrowserStyling: {type: Boolean, default: false},
            modelValue: {type: String},
            placeholder: {type: String},
            size: {type: String, default: 'default'},
            hasError: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            readonly: {type: Boolean, default: false},
            autofocus: {type: Boolean, default: false},
            type: {type: String, default: 'text'},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const inputEl = ref<HTMLElement | null>(null);

            onMounted(() => {
                if (props.autofocus) {
                    nextTick(() => {
                        inputEl.value?.focus();
                    });
                }
            });

            const baseClass = computed(() => {
                const output = [] as string[];

                const states = {
                    default: 'MTextarea--light',
                    hasError: 'MTextarea--light MTextarea--has-error',
                    readonly: 'MTextarea--light MTextarea--is-readonly',
                };
                if (props.readonly) {
                    output.push(states['readonly']);
                } else {
                    output.push(states[props.hasError ? 'hasError' : 'default']);
                }

                if (props.preventBrowserStyling) {
                    output.push('MTextarea--preventBrowserStyling');
                }

                const sizes = {
                    default: 'MTextarea--base',
                    lg: 'MTextarea--lg',
                    sm: 'MTextarea--sm',
                };
                output.push(sizes[props.size]);

                return output;
            });

            function onInput(event) {
                emit('update:modelValue', event.target.value);
            }

            return {
                inputEl,
                baseClass,
                onInput,
            };
        },
    });
</script>
