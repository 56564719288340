<template>
    <MPopover
        v-if="generatedLines.length > 0 || manualLines.length > 0"
        class="InvoicesListItemDetailsPopover"
        placement="bottom"
        popover-button-class="tw-w-full"
        trigger="hover"
    >
        <MHoverable class="InvoicesListItemDetailsPopover__container">
            <div class="InvoicesListItemDetailsPopover__amount-container">
                <MTooltip
                    v-if="
                        (rateWarning(nursery, invoice) && invoice.type === 'presence') ||
                        (hoursWarning(nursery, invoice) && invoice.type === 'presence')
                    "
                >
                    <FontAwesomeIcon
                        class="tw-text-orange-500"
                        icon="fa-duotone fa-exclamation-triangle"
                    />
                    <template #content>
                        <template v-if="rateWarning(nursery, invoice) && invoice.type === 'presence'">
                            {{ __('billing_invoice:warnings.rate_warning') }}
                        </template>
                        <template v-else-if="hoursWarning(nursery, invoice) && invoice.type === 'presence'">
                            {{ __('billing_invoice:warnings.hours_warning') }}
                        </template>
                    </template>
                </MTooltip>
                <div class="InvoicesListItemDetailsPopover__amount">
                    {{ format(invoice.grand_total) }}
                </div>
            </div>
            <div class="InvoicesListItemDetailsPopover__icons-container">
                <template v-if="generatedLines.length">
                    <InvoicesListItemDetailsLineIcon
                        v-for="line in generatedLines"
                        :key="'generatedLine-' + line.id"
                        :line-type="line.type"
                    />
                </template>
                <div
                    v-if="generatedLines.length && manualLines.length"
                    class="InvoicesListItemDetailsPopover__separator"
                />
                <template v-if="manualLines.length">
                    <InvoicesListItemDetailsLineIcon
                        v-for="line in manualLines"
                        :key="'manualLines-' + line.id"
                        :line-type="line.type"
                    />
                </template>
            </div>
        </MHoverable>
        <template #content>
            <div class="InvoicesListItemDetailsPopover__popper-container">
                <!-- LINES CREATED BY CONTRACT -->
                <InvoicesListItemDetailsLine
                    v-for="line in generatedLines"
                    :key="'invoiceLineGenerated-' + line.id"
                    :currency="invoice.currency_iso_code"
                    :line="line"
                />
                <!-- LINES NOT CREATED BY CONTRACT -->
                <InvoicesListItemDetailsLine
                    v-for="line in manualLines"
                    :key="'invoiceLineManual-' + line.id"
                    :currency="invoice.currency_iso_code"
                    :line="line"
                />
            </div>
        </template>
    </MPopover>
    <div
        v-else
        class="InvoicesListItemDetailsPopover__amount-container--without-popover"
    >
        <div class="InvoicesListItemDetailsPopover__amount">
            {{ format(invoice.grand_total) }}
        </div>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import InvoicesListItemDetailsLineIcon from '@/modules/cashier/components/billing/invoice/atoms/InvoicesListItemDetailsLineIcon.vue';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    import InvoicesListItemDetailsLine from './InvoicesListItemDetailsLine.vue';

    export default defineComponent({
        components: {
            InvoicesListItemDetailsLine,
            InvoicesListItemDetailsLineIcon,
        },
        mixins: [invoice],
        props: {
            invoice: {
                type: Object as PropType<LegacyInvoiceType>,
                required: true,
            },
            nursery: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
        },
        setup(props) {
            const generatedLines = computed(() => {
                return props.invoice.lines.filter(item => item.billable_type === 'kid_contract');
            });
            const manualLines = computed(() => {
                return props.invoice.lines.filter(item => item.billable_type !== 'kid_contract');
            });

            const {format} = useFormatCurrency(props.invoice.currency_iso_code);

            return {
                format,
                generatedLines,
                manualLines,
            };
        },
    });
</script>

<style scoped>
    .InvoicesListItemDetailsPopover__container {
        @apply tw-flex tw-flex-col lg:tw-items-end;
        @apply tw-rounded tw-transition-all;
        @apply lg:tw-flex-col;
    }

    .InvoicesListItemDetailsPopover__amount-container {
        @apply tw-flex tw-flex-row tw-gap-1 lg:tw-items-center;
    }

    .InvoicesListItemDetailsPopover__amount-container--without-popover {
        @apply tw-pr-1 tw-text-end tw-font-semibold;
    }

    .InvoicesListItemDetailsPopover__amount {
        @apply tw-whitespace-nowrap tw-font-semibold tw-text-gray-800;
    }

    .InvoicesListItemDetailsPopover__icons-container {
        @apply tw-flex tw-flex-wrap tw-items-center;
    }

    .InvoicesListItemDetailsPopover__popper-container {
        @apply tw-flex tw-flex-col tw-gap-1 tw-text-sm tw-font-semibold;
    }

    .InvoicesListItemDetailsPopover__separator {
        @apply tw-mx-1 tw-h-4 tw-w-0.5 tw-shrink-0 tw-rounded-full tw-bg-gray-300;
    }
</style>
