import {Epoch} from '@meekohq/lumos';

import type AllocationModel from '@/modules/cashier/models/AllocationModel';
import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import type {SharedRemaingAmount} from '@/modules/cashier/payment/domain/SharedRemaingAmount';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

export class TransactionDebitAllocationAggregate extends AbstractAllocationAggregate<PaymentModel, TransactionModel> {
    public constructor(
        source: PaymentModel,
        destination: TransactionModel,
        sharedRemainingAmount: SharedRemaingAmount,
        allocation?: AllocationModel
    ) {
        super(source, destination, sharedRemainingAmount, allocation);
    }

    get allocatableRemainingAmount(): number {
        return this._destination.computed.remaining_to_distribute_amount;
    }

    get allocatableAmount(): number {
        return this._destination.attributes.amount ?? 0;
    }

    get allocatableDate(): Epoch {
        return this._destination.attributes.date ? Epoch.fromISOString(this._destination.attributes.date) : Epoch.now();
    }

    get allocatableReference(): string | undefined {
        return this._destination.attributes.reference ?? undefined;
    }

    get allocationMustBeIgnored(): boolean {
        return this._destination.isFailed;
    }

    public setAllocationForDeletion(): void {
        this._allocation?.markForDeletion();
    }
}
