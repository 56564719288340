import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';

import DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';
import DocumentPivot from '@/modules/document/models/DocumentPivot';
import attachResource from '@/modules/document/utils/attachResource';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';

export default class DocumentModel extends Model {
    public static attachResource = attachResource;
    public type = 'media/documents';

    public attributes: {
        id: string | undefined;
        account_id: string | undefined;
        name: string | null | undefined;
        filename: string | null | undefined;
        original_filename: string | undefined | null;
        extension: string | null | undefined;
        link: string | undefined | null;
    } = {
        id: this.uuid(),
        account_id: undefined,
        name: undefined,
        filename: undefined,
        original_filename: undefined,
        extension: undefined,
        link: undefined,
    };

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            DocumentOrganizationPivot,
            'document_id',
            'organization_id'
        );
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', DocumentOrganizationPivot, 'document_id');
    }

    resources() {
        return this.hasMany('resources', DocumentPivot, 'document_id');
    }

    staffs() {
        return this.morphedByMany('staffs', StaffModel, DocumentPivot, 'resource', 'resource_id', 'staff_id');
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }

    scopeInOrganization(query: QueryBuilder<any>, organization: OrganizationModel) {
        return query.whereHas(new DocumentModel().organizationsPivots(), query => {
            query.where('organization_id', organization.id);
        });
    }

    scopeNotInOrganization(query: QueryBuilder<any>, organization: OrganizationModel) {
        return query.whereHas(new DocumentModel().organizationsPivots(), query => {
            query.where('organization_id', '!=', organization.id);
        });
    }

    scopeMorphConstraint(query: QueryBuilder<any>, resource: {resourceId: string; resourceType: string}) {
        return query.whereHas(new DocumentModel().resources(), query => {
            query.where('documentable_id', resource.resourceId).where('documentable_type', resource.resourceType);
        });
    }
}
