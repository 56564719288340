import type {ModelCollection} from '@meekohq/lumos';

import type TicketFilterDto from '@/modules/activity/ticket/application/dto/TicketFilterDto';
import type TicketRelationOptionDto from '@/modules/activity/ticket/application/dto/TicketRelationOptionDto';
import type TicketRepositoryPort from '@/modules/activity/ticket/application/ports/TicketRepositoryPort';
import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import TicketPivot from '@/modules/activity/ticket/domain/TicketPivot';
import {TransformTicketFiltersToQueryBuilderFactory} from '@/modules/activity/ticket/infrastructure/TransformTicketFiltersToQueryBuilderFactory';

export default class TicketRepositoryAdapter implements TicketRepositoryPort {
    /**
     * Retrieves tickets based on the provided filters.
     */
    public async getByFilters(filters: TicketFilterDto): Promise<ModelCollection<TicketModel>> {
        return TransformTicketFiltersToQueryBuilderFactory.transform(filters).get();
    }

    /**
     * Loads relations for the provided collection of tickets depending on optionnal options.
     */
    public async loadTicketsRelations(
        collection: ModelCollection<TicketModel>,
        relationOptions?: TicketRelationOptionDto
    ): Promise<void> {
        await collection.load({
            parent: subQuery => {
                subQuery.with(new TicketModel().lastOccurrence());
            },
            lastOccurrence: () => {
                //
            },
            assignee: () => {
                //
            },
            reporter: () => {
                //
            },
            completedBy: () => {
                //
            },
            ticketPivots: subQuery => {
                subQuery.scope('onlyAllowed');
                if (relationOptions?.withResourceOrganizations) {
                    TicketPivot.withResourceOrganizations(subQuery);
                } else {
                    subQuery.with(new TicketPivot().resource());
                }
            },
        });
    }
}
