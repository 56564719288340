<template>
    <div class="tw-text-center print:tw-hidden">
        <MMenu>
            <MMenuButton>
                <MButton
                    icon-menu
                    variant="light"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-duotone fa-calendar-alt" />
                    </template>
                    {{ currentDisplay }}
                </MButton>
            </MMenuButton>
            <MMenuItems>
                <router-link :to="{name: 'planning.staffs.daily', params: {nursery: nursery.id}, query: $route.query}">
                    <MMenuItem>
                        {{ __('planning:display_daily') }}
                    </MMenuItem>
                </router-link>
                <router-link :to="{name: 'planning.staffs.weekly', params: {nursery: nursery.id}, query: $route.query}">
                    <MMenuItem>
                        {{ __('planning:display_weekly') }}
                    </MMenuItem>
                </router-link>
            </MMenuItems>
        </MMenu>
    </div>
</template>

<script>
    export default {
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            currentDisplay: {
                type: String,
                default: '',
            },
        },
    };
</script>
