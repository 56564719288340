<template>
    <MButton
        class="MToggleFilter"
        :label="label"
        :size="size"
        @click="toggle"
    >
        <template #left-icons>
            <FontAwesomeIcon
                v-if="modelValue"
                icon="fa-solid fa-circle-check"
            />
            <FontAwesomeIcon
                v-else
                icon="fa-regular fa-circle"
            />
        </template>
    </MButton>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            modelValue: {
                type: Boolean,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: 'sm',
            },
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            function toggle() {
                emit('update:modelValue', !props.modelValue);
            }

            return {
                toggle,
            };
        },
    });
</script>
