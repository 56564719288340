import type {Moment} from 'moment';
import moment from 'moment';

import useOrganizationOpening from '@/modules/organization/composables/useOrganizationOpening';

export default function () {
    const openingHours = useOrganizationOpening();

    function getDays(from: Moment, to: Moment): Moment[] {
        const dates: Moment[] = [];

        // Format period to have same reference
        const currDate = from.startOf('day');
        const lastDate = to.startOf('day');

        dates.push(currDate.clone());

        // Loop on dates, while diff between current date and last date is more than 1 day
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            const day = currDate.clone().day();
            // If weekend, check if organization is open on weekend (6 = saturday, 0 = sunday)
            if (day === 6 || day === 0) {
                if (openingHours.isOpenedOn(moment().day(day))) {
                    dates.push(currDate.clone());
                }
            } else {
                dates.push(currDate.clone());
            }
        }

        return dates;
    }

    function getWeeks(from: Moment, to: Moment): number[] {
        const weeks: number[] = [];

        // Format period to have same reference
        const currDate = from.startOf('day');
        const lastDate = to.startOf('day');

        weeks.push(currDate.clone().isoWeek());

        while (currDate.add(1, 'weeks').diff(lastDate) <= 0) {
            weeks.push(currDate.clone().isoWeek());
        }

        return weeks;
    }

    function getYears(from: Moment, to: Moment): number[] {
        const years: number[] = [];

        // Format period to have same reference
        const currDate = from.startOf('day');
        const lastDate = to.startOf('day');

        years.push(currDate.clone().year());

        while (currDate.add(1, 'years').startOf('year').diff(lastDate) <= 0) {
            years.push(currDate.clone().year());
        }

        return years;
    }

    return {
        getDays,
        getWeeks,
        getYears,
    };
}
