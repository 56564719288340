import type {GetOverlappingContractsUseCase} from '@/modules/human-resources/contract/application/GetOverlappingContractsUseCase';
import type {ContractRepositoryPort} from '@/modules/human-resources/contract/application/ports/ContractRepositoryPort';
import type ContractModel from '@/modules/human-resources/models/ContractModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export class GetOverlappingContractsInteractor implements GetOverlappingContractsUseCase {
    constructor(private readonly contractRepository: ContractRepositoryPort) {}

    public async getFromContract(contract: ContractModel, staff: StaffModel): Promise<ContractModel[]> {
        return await this.contractRepository.getOverlappingContractsByContract(contract, staff);
    }
}
