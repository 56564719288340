import axios from 'axios';

import app from '@/config/app';
import {Auth} from '@/facades';
import __ from '@/modules/app/utils/i18n-facade';
import useNotification from '@/modules/meeko-ui/composables/useNotification';

export default function () {
    const api = axios.create({
        baseURL: app.api_base_url,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    });

    api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error && error.code === 'ERR_NETWORK') {
                useNotification().error(__('common:errors.network'));

                return Promise.reject();
            } else {
                if (error && error.response) {
                    if (error.response.status === 403) {
                        // HTTP_FORBIDDEN
                        useNotification().error(__('common:errors.forbidden'));

                        return Promise.reject(error);
                    } else if (error.response.status === 500) {
                        // HTTP_INTERNAL_SERVER_ERROR
                        useNotification().error(__('common:errors.generic'));

                        return Promise.reject(error);
                    }
                }

                return Promise.reject(error);
            }
        }
    );

    api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error?.response?.status === 401) {
                // HTTP_UNAUTHORIZED
                Auth.logout();
            }

            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error?.response?.status === 502 || error?.response?.status === 503) {
                window.location.replace('/maintenance');
            }

            return Promise.reject(error);
        }
    );

    return api;
}
