<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="dashboard">
                {{ __('app:menu.dashboard') }}
            </MPageTitle>
        </template>
        <template #actions>
            <DashboardActions
                :selected-invoice-type="selectedInvoiceType"
                :invoice-type-options="invoiceTypeOptions"
                :from-date="fromDate"
                :to-date="toDate"
                @open-exports-modal="openExportsModal"
                @update:selected-invoice-type="selectedInvoiceType = $event"
                @update:from-date="fromDate = $event"
                @update:to-date="toDate = $event"
            />
        </template>
        <template #content>
            <div
                v-if="$can('read', 'dashboard')"
                class="tw-flex tw-flex-col tw-gap-4"
            >
                <loader
                    v-if="loading"
                    class="tw-w-full tw-text-center"
                    light="true"
                    shadow="false"
                    :title="__('common:loading_in_progress_dots')"
                />
                <template v-else>
                    <sparklines
                        v-if="allowedNurseries && allowedNurseries.length && selectedInvoiceType"
                        :from-date="fromDate.toFormat('yyyy-MM')"
                        :nurseries="allowedNurseries"
                        :nursery="selectedNursery"
                        :selected-invoice-type="selectedInvoiceType.value"
                        :to-date="toDate.toFormat('yyyy-MM')"
                        @nursery-changed="switchNursery"
                    />
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-2">
                        <div
                            v-if="canWithNursery('read', 'invoices', selectedNursery)"
                            class="tw-flex tw-flex-col tw-gap-4"
                        >
                            <RevenueModule
                                :nurseries="allowedNurseries"
                                :nursery="selectedNursery"
                            />
                            <RevenueDetailsModule :nursery="selectedNursery" />
                        </div>
                        <div class="tw-flex tw-flex-col tw-gap-4">
                            <OccupationModule
                                v-if="canWithNursery('read', 'kids_planning', selectedNursery)"
                                :nursery="selectedNursery"
                            />
                            <SupervisionModule
                                v-if="canWithNursery('read', 'staffs_planning', selectedNursery)"
                                :nursery="selectedNursery"
                            />
                            <KidPresencesModule
                                v-if="canWithNursery('read', 'kids_planning', selectedNursery)"
                                :nursery="selectedNursery"
                            />
                            <StaffPresencesModule
                                v-if="canWithNursery('read', 'staffs_planning', selectedNursery)"
                                :nursery="selectedNursery"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import DashboardActions from '@/modules/dashboard/components/DashboardActions.vue';
    import ExportsModal from '@/modules/dashboard/components/ExportsModal.vue';
    import KidPresencesModule from '@/modules/dashboard/components/KidPresences.vue';
    import OccupationModule from '@/modules/dashboard/components/Occupation.vue';
    import RevenueModule from '@/modules/dashboard/components/Revenue.vue';
    import RevenueDetailsModule from '@/modules/dashboard/components/RevenueDetails.vue';
    import Sparklines from '@/modules/dashboard/components/Sparklines.vue';
    import StaffPresencesModule from '@/modules/dashboard/components/StaffPresences.vue';
    import SupervisionModule from '@/modules/dashboard/components/Supervision.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            DashboardActions,
            SimplePageTemplate,
            Sparklines,
            RevenueModule,
            RevenueDetailsModule,
            OccupationModule,
            SupervisionModule,
            KidPresencesModule,
            StaffPresencesModule,
        },
        props: {
            nursery: {},
            user: {},
        },
        data: () => ({
            nurseries: [],
            selectedNursery: {},
            loading: false,

            fromDate: Epoch.now(),
            toDate: Epoch.now(),
            invoiceTypeOptions: [
                {
                    text: __('dashboard:invoice_and_pro_format'),
                    value: undefined,
                },
                {
                    text: __('common:invoice_other'),
                    value: 'billed',
                },
                {
                    text: __('common:draft_invoice'),
                    value: 'estimated',
                },
            ],
            selectedInvoiceType: {},
        }),
        computed: {
            allowedNurseries() {
                return this.nurseries.filter(nursery => {
                    const userNursery = this.user.nurseries.find(item => item.id === nursery.id);

                    return userNursery ? this.$can('read', 'invoices', userNursery.permissions) : false;
                });
            },
            exportsModal() {
                return useModal({
                    component: ExportsModal,
                    props: {
                        user: this.user,
                        nursery: this.nursery,
                    },
                });
            },
        },
        mounted() {
            if (this.$can('read', 'dashboard')) {
                this.getNurseries();
            }
        },
        methods: {
            getNurseries() {
                this.loading = true;
                useApi()
                    .legacy.get(route('nurseries.index'), {
                        params: {
                            resources: ['groups', 'openingHours'],
                        },
                    })
                    .then(response => {
                        this.nurseries = response.data;
                        const currentNursery = this.nurseries.find(item => item.id === this.nursery.id);
                        if (currentNursery) {
                            this.switchNursery(currentNursery);
                        }
                        this.initFilters();
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            openExportsModal() {
                this.exportsModal.show();
            },

            canWithNursery(action, ressource, nursery) {
                const userNursery = this.user.nurseries.find(item => item.id === nursery.id);

                return userNursery ? this.$can(action, ressource, userNursery.permissions) : false;
            },

            switchNursery(nursery) {
                const currentNursery = this.nurseries.find(item => item.id === nursery.id);
                if (currentNursery) {
                    this.selectedNursery = _cloneDeep(currentNursery);
                }
            },

            initFilters() {
                const savedInvoiceType = this.invoiceTypeOptions.find(
                    item => item.value === window.localStorage.getItem('sparklinesInvoiceType')
                );
                const savedFromDate = window.localStorage.getItem('sparklinesFromDate');
                const savedToDate = window.localStorage.getItem('sparklinesToDate');

                this.selectedInvoiceType = savedInvoiceType ? savedInvoiceType : this.invoiceTypeOptions[0];

                if (savedFromDate) {
                    this.fromDate = Epoch.parse(savedFromDate, 'yyyy-MM');
                } else {
                    this.fromDate = Epoch.now().startOfYear();
                }

                if (savedToDate) {
                    this.toDate = Epoch.parse(savedToDate, 'yyyy-MM');
                } else {
                    this.toDate = Epoch.now().startOfYear().addMonths(11);
                }
            },
        },
    };
</script>
