import {Model, type ModelAttributesType} from '@meekohq/lumos';

import UserModel from '@/modules/user/models/UserModel';

export default class UserSettingModel extends Model {
    public type = 'user/user_settings';

    public declare attributes: {
        id: string | undefined;
        account_id: string | undefined;
        user_id: string | undefined;
        name: string | undefined;
        value: any;
    };

    user() {
        return this.belongsTo('user', UserModel, 'user_id');
    }

    protected defaults(): ModelAttributesType {
        return {
            id: this.uuid(),
            account_id: undefined,
            user_id: undefined,
            name: undefined,
            value: null,
        };
    }
}
