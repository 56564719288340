import {ServiceProvider} from '@meekohq/lumos';

import UserModel from '@/modules/user/models/UserModel';
import UserSettingModel from '@/modules/user/models/UserSettingModel';
import {GateContractBinding} from '@/modules/user/utils/GateContract';
import LegacyUserGate from '@/modules/user/utils/LegacyUserGate';

export default class UserServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([UserModel, UserSettingModel]);
    }

    public register() {
        this.app.bind(GateContractBinding, (_, permissions) => new LegacyUserGate(permissions));
    }
}
