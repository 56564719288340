import {ForbiddenError, MqlForbiddenError} from '@meekohq/lumos';

export class SudoModeRequiredError extends Error {
    constructor() {
        super('Sudo mode required for this operation');
        this.name = this.constructor.name;
    }

    public static isSudoModeRequiredError(err: any) {
        if (!(err instanceof ForbiddenError)) {
            return false;
        }

        // Check if the error is a MQL error and if the operation result content is the expected message
        if (err.context?.mqlError instanceof MqlForbiddenError) {
            return err.context.mqlError.operation?.result?.content === 'Sudo token required to perform this action.';
        }

        return false;
    }
}
