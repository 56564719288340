<template>
    <CButtonGroup class="ParticipantInput">
        <MPopover
            ref="participantsInputPopover"
            class="tw-w-full"
            popover-button-class="tw-w-full"
            @closed="onClosed"
        >
            <MButton
                v-if="filteredParticipants.isEmpty()"
                class="tw-w-full"
                @click="addDraftParticipant"
            >
                {{ __('activity:add_participant') }}
            </MButton>
            <MButton
                v-else
                align="left"
                class="tw-w-full"
                :truncate="false"
                @click="addDraftParticipant"
            >
                <div class="tw-flex tw-flex-wrap tw-gap-1">
                    <template
                        v-for="participant in orderedParticipants"
                        :key="participant.getKey()"
                    >
                        <ParticipantItem
                            :participant="participant"
                            @remove="removeParticipant"
                        />
                    </template>
                </div>
            </MButton>
            <template #content>
                <ParticipantForm
                    v-if="draftParticipant"
                    v-model="draftParticipant"
                    :custom-select="customSelect"
                    :exclude-participants="filteredParticipants"
                    :registration="registration"
                    @cancel="removeDraftParticipant"
                    @validate="addParticipant"
                />
            </template>
        </MPopover>
        <MTooltip
            v-if="participantsWithoutEmail.isNotEmpty()"
            placement="bottom-start"
        >
            <MButton
                class="tw-h-full"
                variant="danger"
            >
                <FontAwesomeIcon icon="fa-regular fa-exclamation-triangle" />
            </MButton>
            <template #content>
                {{
                    __(
                        'activity:following_participants_not_have_email_and_therefore_not_receive_notifications_warning_colon'
                    )
                }}
                {{ participantsWithoutEmailString }}
            </template>
        </MTooltip>
    </CButtonGroup>
</template>

<script lang="ts">
    import type {ModelCollection} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import ParticipantForm from '@/modules/activity/components/ParticipantForm.vue';
    import ParticipantItem from '@/modules/activity/components/ParticipantItem.vue';
    import type MeetingModel from '@/modules/activity/models/MeetingModel';
    import MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
    import type {CustomSelectType} from '@/modules/activity/utils/CustomSelectType';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export type ParticipantType = 'custom' | 'staff' | 'config';

    export default defineComponent({
        components: {ParticipantItem, ParticipantForm},
        props: {
            modelValue: {
                type: Object as PropType<ModelCollection<MeetingParticipantModel>>,
                required: true,
            },
            meeting: {
                type: Object as PropType<MeetingModel>,
                required: true,
            },
            customSelect: {
                type: Object as PropType<CustomSelectType>,
                default: null,
            },
            registration: {
                type: RegistrationModel,
                required: true,
            },
        },
        setup(props) {
            const filteredParticipants = computed(() => {
                return props.modelValue.filter(participant => !participant.markedForDeletion);
            });

            const orderedParticipants = computed(() => {
                return filteredParticipants.value.sortBy('attributes.resource_type').sort();
            });

            const participantsInputPopover = ref();

            const draftParticipant = ref();

            function onClosed() {
                removeDraftParticipant();
            }

            const participantsWithoutEmail = computed(() =>
                orderedParticipants.value.filter(participant => participant.attributes.email === null)
            );

            const participantsWithoutEmailString = computed(() =>
                participantsWithoutEmail.value
                    .map(part => {
                        let fullname = part.attributes.first_name;

                        if (part.attributes.last_name) {
                            fullname += ` ${part.attributes.last_name}`;
                        }

                        return fullname;
                    })
                    .join(', ')
            );

            function addDraftParticipant() {
                draftParticipant.value = new MeetingParticipantModel();
            }

            function removeDraftParticipant() {
                draftParticipant.value = undefined;

                participantsInputPopover.value?.closePopover();
            }

            function addParticipant(participant: MeetingParticipantModel) {
                participant.meeting().associate(props.meeting);
                props.modelValue.push(participant);

                participantsInputPopover.value?.closePopover();
            }

            /**
             * Two kinds of remove:
             *  - If the participant was already saved, mark it for deletion. It will be deleted when the meeting is saved
             *  - If the participant was not saved, remove it from the collection, so it doesn't get saved by the meeting
             * @param participant item to remove
             */
            function removeParticipant(participant: MeetingParticipantModel) {
                if (participant.exists) {
                    participant.markForDeletion();
                } else {
                    const idx = props.modelValue.all().findIndex(item => item.getKey() === participant.getKey());
                    if (idx !== -1) {
                        props.modelValue.splice(idx, 1);
                    }
                }

                participantsInputPopover.value?.closePopover();
            }

            return {
                addDraftParticipant,
                addParticipant,
                participantsInputPopover,
                draftParticipant,
                filteredParticipants,
                onClosed,
                orderedParticipants,
                participantsWithoutEmail,
                participantsWithoutEmailString,
                removeDraftParticipant,
                removeParticipant,
            };
        },
    });
</script>

<style scoped>
    .ParticipantInput {
        @apply tw-w-full;
    }
</style>
