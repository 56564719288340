<template>
    <MagicIndex
        v-if="filtersStore.isReady.value"
        :filter-count="filtersStore.activeCount.value"
        :filters-loading="!filtersStore.isReady.value || !currencyIsoCode"
        :loading="isLoading"
        :paginator="paginator"
        show-header-on-mobile
        variant="billing"
        :with-mass-select="can('delete', 'transactions')"
        @change="setPage"
        @delete="massDeleteConfirmationModal"
        @reset-filters="filtersStore.reset"
    >
        <template #filters>
            <TransactionListFilters
                :amount-currency="currencyIsoCode"
                :filters-store="filtersStore"
            />
        </template>
        <template
            v-if="showMoreFilters"
            #more-filters
        >
            <TransactionListFiltersMore
                :filters-store="filtersStore"
                show-customers-filter
                :show-tenants-filter="showTenantsFilter"
            />
        </template>
        <template #header="{massSelect}">
            <TransactionListNavigation
                :bus="bus"
                :filters="options"
                :loading-transactions="isLoading"
                :lock-period="massSelect.isEnabled.value"
                @next-month="nextPeriod()"
                @previous-month="previousPeriod()"
            />
            <TransactionListHeader :show-customer-informations="showCustomerInformations" />
        </template>
        <template #item="{item, massSelect}">
            <TransactionListItem
                :always-show-allocations="true"
                :mass-select="massSelect"
                :organization="activeOrganization"
                :show-customer-informations="showCustomerInformations"
                :transaction="item"
                @deleted="onTransactionDeleted"
                @rejected="$emit('rejected', $event)"
                @restored="$emit('restored', $event)"
                @updated="onTransactionUpdated"
            />
        </template>
        <template #empty-list>
            {{ __('billing_transaction:no_transaction_founded_with_filters') }}
        </template>
        <template #empty-list-action>
            {{ __('billing_transaction:try_modifying_filters_or_adding_a_rule') }}
        </template>
    </MagicIndex>
</template>

<script lang="ts">
    import {computedAsync, until} from '@vueuse/core';
    import {merge} from 'lodash-es';
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import {computed, defineComponent, onUnmounted, type PropType, ref} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import useAuth from '@/modules/app/composables/useAuth';
    import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import TransactionListFilters from '@/modules/cashier/transaction/infrastructure/components/TransactionListFilters.vue';
    import TransactionListHeader from '@/modules/cashier/transaction/infrastructure/components/TransactionListHeader.vue';
    import TransactionListItem from '@/modules/cashier/transaction/infrastructure/components/TransactionListItem.vue';
    import TransactionListFiltersMore from '@/modules/cashier/transaction/infrastructure/components/TransactionListMoreFilters.vue';
    import TransactionListNavigation from '@/modules/cashier/transaction/infrastructure/components/TransactionListNavigation.vue';
    import type {
        OutputType,
        TransactionListFiltersType,
    } from '@/modules/cashier/transaction/infrastructure/components/useFiltersState';
    import useMassDeleteTransactions from '@/modules/cashier/transaction/infrastructure/components/useMassDeleteTransactions';
    import usePaginateTransactions from '@/modules/cashier/transaction/infrastructure/components/usePaginateTransactions';
    import {useDatePeriodFilterNext} from '@/modules/legacy/components/Filter/useDatePeriodFilterNext';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export interface EventType {
        refresh: void;
    }

    export default defineComponent({
        components: {
            TransactionListHeader,
            TransactionListNavigation,
            TransactionListFiltersMore,
            TransactionListFilters,
            TransactionListItem,
            MagicIndex,
        },
        props: {
            showMoreFilters: {
                type: Boolean,
                default: true,
            },
            showCustomerInformations: {
                type: Boolean,
                default: false,
            },
            defaultOptions: {
                type: Object as PropType<Partial<TransactionListFiltersType>>,
                default: undefined,
            },
            filtersStore: {
                type: Object as PropType<OutputType>,
                required: true,
            },
            emitter: {type: Object as PropType<Emitter<EventType>>, default: () => mitt()},
        },
        emits: ['rejected', 'restored', 'updated', 'deleted'],
        setup(props, {emit}) {
            const page = ref(1);

            const {error} = useNotification();
            const {activeOrganization} = useManager();
            const {user} = useAuth();
            const {can} = useAbility();

            const showTenantsFilter = computed(() => user.value.organizations().value().count() > 1);

            /**
             * The options, merged with defaults, to pass to the useGetTransactions.
             */
            const options = computed<Partial<TransactionListFiltersType>>(() => {
                const options = {
                    period: props.filtersStore.periodFilter.data.value,
                    amountMin: props.filtersStore.amountFilter.data.value.min,
                    amountMax: props.filtersStore.amountFilter.data.value.max,
                    customers: props.filtersStore.customersFilter.data.value,
                    tenants: props.filtersStore.tenantsFilter.data.value,
                    paymentMethodName: props.filtersStore.paymentMethodFilter.data.value,
                    orderBy: props.filtersStore.orderByFilter.data.value.orderBy,
                    queryDirection: props.filtersStore.orderByFilter.data.value.queryDirection,
                    hasRemainingAmount: props.filtersStore.unallocatedOnlyFilter.data.value,
                    search: props.filtersStore.searchFilter.data.value,
                    invoiceIds: [],
                    with: [
                        'tenant',
                        'customer',
                        'paymentMethod',
                        'currency',
                        'allocationsAsSource',
                        'allocationsAsDestination',
                    ],
                    page: props.filtersStore.pageFilter.data.value,
                };

                return merge({}, props.defaultOptions, options);
            });

            const {isLoading, paginator, paginate, watchOptions} = usePaginateTransactions(options);

            // Wait for the isReady to be true before starting to paginate the transactions
            until(props.filtersStore.isReady)
                .toBe(true)
                .then(() => {
                    watchOptions();
                });

            /**
             * Set the current page to the given page.
             * @param page
             */
            function setPage(value: number) {
                page.value = value;
                props.filtersStore.pageFilter.data.value = value;
            }

            /**
             * Fetch the currency code of the tenants to pass to the AmountFilter component.
             */
            const currencyIsoCode = computedAsync(async () => {
                const organizationTenant = activeOrganization.value?.tenants().value().first();

                return (await CurrencyModel.query().where('tenant_id', organizationTenant.getKey()).first()).attributes
                    .code as string;
            }, undefined);

            /**
             * Update the local transaction from the list when it is updated.
             */
            function onTransactionUpdated(transaction: TransactionModel) {
                const localTransaction = paginator.value?.items().first(item => {
                    return item.getKey() === transaction.getKey();
                });

                if (localTransaction) {
                    transaction.copyTo(localTransaction);
                }

                emit('updated', transaction);
            }

            /**
             * Remove the transaction from the list when it is deleted.
             */
            function onTransactionDeleted(transaction: TransactionModel) {
                const transactionIndex = paginator.value?.items().search(item => {
                    return item.getKey() === transaction.getKey();
                });

                // Mutate the paginator items to remove the transaction.
                paginator.value?.items().forget(transactionIndex);

                emit('deleted', transaction);
            }

            function onRefresh() {
                setPage(1);
                paginate(page.value);
            }

            const {nextPeriod, previousPeriod} = useDatePeriodFilterNext(props.filtersStore.periodFilter.data);

            /**
             * Mass select actions
             */
            const {deleteByOptions, bus} = useMassDeleteTransactions();

            function onDeleted() {
                paginate(1);
            }

            /**
             * Delete the selected transactions.
             */
            async function handleMassDeleteTransaction(massSelect: MagicMassSelectType) {
                try {
                    await deleteByOptions(options, massSelect);
                } catch (e) {
                    error(__('common:errors.generic'));
                    throw e;
                }
            }

            props.emitter.on('refresh', onRefresh);
            bus.on('deleted', onDeleted);

            onUnmounted(() => {
                props.emitter.off('refresh', onRefresh);
                bus.off('deleted', onDeleted);
            });

            function massDeleteConfirmationModal(massSelect: MagicMassSelectType) {
                useMagicModal().deleteConfirmationModal({
                    text: __('billing_transaction:are_you_sure_you_want_to_delete_count_transactions_ask', {
                        count: massSelect.selectedItemsCount.value,
                    }),
                    onConfirm: async () => {
                        await handleMassDeleteTransaction(massSelect);
                    },
                });
            }

            return {
                activeOrganization,
                bus,
                can,
                currencyIsoCode,
                handleMassDeleteTransaction,
                isLoading,
                massDeleteConfirmationModal,
                nextPeriod,
                options,
                onTransactionDeleted,
                onTransactionUpdated,
                paginator,
                previousPeriod,
                setPage,
                showTenantsFilter,
            };
        },
    });
</script>

<style scoped>
    .TransactionList__content {
        @apply tw-flex tw-flex-col tw-gap-2 tw-p-3;
    }
</style>
