<template>
    <div class="tw-rounded-lg tw-bg-white tw-p-3 tw-shadow-md">
        <div class="tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-gap-2">
            <div class="tw-flex tw-flex-wrap tw-gap-2 xl:tw-mr-auto">
                <TagsResourceFinder
                    v-model="selectedTagsModels"
                    has-unselect
                    :inject-query="tagFinderQuery"
                    multi
                    only-active-organization
                    :search-bar="false"
                    :without-tags-option-is-selected="withoutTags"
                    @without-tags-option-is-selected="withoutTags = $event"
                />
                <FilterInvoiceType v-model="selectedInvoiceType" />
                <FilterInvoiceStatus v-model="selectedStatus" />
                <FilterInvoiceOrder
                    v-model:order="order"
                    v-model:order-by="orderBy"
                    :kid-option-enabled="kidModels.length > 1"
                />
                <SelectKid
                    v-if="customer.attributes.person === 'legal'"
                    v-model="selectedKidFilter"
                    :add-option-enabled="false"
                    :create-organization-model="manager.activeOrganization"
                    :fallback-text="__('common:all_kids')"
                    :filter-organization-model="manager.activeOrganization"
                    :unselect-text="__('common:all_kids')"
                >
                    <template #unselect-item>
                        {{ __('common:all_kids') }}
                    </template>
                </SelectKid>
                <MSelectMenu
                    v-else-if="kidModels.length > 1"
                    v-model="selectedKidFilter"
                    :fallback-text="__('common:all_kids')"
                    :options="kidModels"
                    text-path="fullname"
                    :unselect-value="null"
                    value-path="id"
                >
                    <template #unselect-item>
                        {{ __('common:all_kids') }}
                    </template>
                </MSelectMenu>
            </div>
            <CustomerTabInvoicesActions
                v-if="$can('create', 'invoices') && !kidsLoading"
                :customer="customer"
                :kids="kidModels"
                :nursery="nursery"
                @init="createInvoice"
            />
        </div>

        <transition
            mode="out-in"
            name="transition-fade"
        >
            <div v-if="!loading">
                <div
                    v-if="!invoices.length"
                    class="tw-pb-2 tw-pt-3 tw-text-center"
                >
                    <h4 class="h4">
                        {{ __('billing_core:no_invoice_with_selected_filters') }}
                    </h4>
                </div>
                <div
                    v-else
                    class="tw-rounded-lg tw-border tw-border-gray-200"
                >
                    <InvoicesListItem
                        v-for="invoice in invoicesLegaciesWithModels"
                        :key="'invoice' + invoice.model.getKey()"
                        class="tw-border-b tw-border-gray-300 tw-p-3 last:tw-border-b-0 lg:tw-py-2"
                        :customer="customer"
                        :invoice="invoice.legacy"
                        :invoice-model="invoice.model"
                        :nursery="nursery"
                        show-by-list
                        @delete-invoice="deleteInvoice(invoice.legacy)"
                        @duplicate-invoice="showInvoice"
                        @show-invoice="showInvoice(invoice.legacy, $event)"
                        @update-invoices="onUpdateInvoices"
                    />
                </div>
            </div>
            <loader
                v-else
                light="true"
                shadow="false"
                size="sm"
                :title="__('common:loading_dots')"
            />
        </transition>

        <div
            v-if="total > perPage && invoices.length"
            class="tw-mt-4 tw-flex tw-justify-center"
        >
            <MPagination
                :paginator="paginator"
                @navigate="currentPage = $event"
            />
        </div>
    </div>
</template>

<script>
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _debounce from 'lodash-es/debounce';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import useModal from '@/modules/app/composables/useModal';
    import useTagLegacyModelBridge from '@/modules/app/composables/useTagLegacyModelBridge';
    import __ from '@/modules/app/utils/i18n-facade';
    import FilterInvoiceOrder from '@/modules/cashier/components/billing/invoice/FilterInvoiceOrder.vue';
    import FilterInvoiceStatus from '@/modules/cashier/components/billing/invoice/FilterInvoiceStatus.vue';
    import FilterInvoiceType from '@/modules/cashier/components/billing/invoice/FilterInvoiceType.vue';
    import InvoicesListItem from '@/modules/cashier/components/billing/invoice/molecules/InvoicesListItem.vue';
    import EditInvoice from '@/modules/cashier/components/billing/invoice/organisms/EditInvoice.vue';
    import CustomerTabInvoicesActions from '@/modules/cashier/components/core/customer/organisms/CustomerTabInvoicesActions.vue';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import SelectKid from '@/modules/family/components/kid/SelectKid.vue';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import KidModel from '@/modules/family/models/KidModel';
    import route from '@/modules/legacy/libs/ziggy';
    import {url} from '@/modules/legacy/mixins/url';
    import Manager from '@/modules/legacy/store/manager.store';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import TagModel from '@/modules/tag/models/TagModel';

    export default {
        components: {
            FilterInvoiceType,
            MPagination,
            TagsResourceFinder,
            InvoicesListItem,
            CustomerTabInvoicesActions,
            SelectKid,
            FilterInvoiceStatus,
            FilterInvoiceOrder,
        },
        mixins: [url],
        props: {
            customer: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        emits: ['refreshStats'],
        data: () => ({
            invoices: [],
            invoicesModels: [],
            currentInvoice: {},
            loading: true,
            isEditInvoiceVisible: false,
            currentPage: 1,
            total: 0,
            perPage: 20,
            orderBy: 'date',
            order: 'asc',
            selectedStatus: [],
            selectedInvoiceType: null,
            selectedKidFilter: null,
            selectedTagsModels: [],
            withoutTags: JSON.parse(localStorage.getItem('without_tags:invoices:index')) || false,
            kidModels: [],
            selectedKid: null,
            kidsLoading: false,
            invoiceType: 'presence',
            paginator: undefined,
        }),
        computed: {
            manager() {
                return Manager;
            },
            editInvoiceModal() {
                return useModal({
                    component: EditInvoice,
                    props: {
                        nursery: this.nursery,
                        customer: this.customer,
                        invoice: this.currentInvoice,
                    },
                    listeners: () => ({
                        updateInvoices: this.onUpdateInvoices,
                    }),
                });
            },
            tagFinderQuery() {
                return TagModel.query().where('type', 'invoice');
            },
            tagsFilters() {
                return {
                    selectedTagsModels: this.selectedTagsModels,
                    withoutTags: this.withoutTags,
                };
            },
            invoicesLegaciesWithModels() {
                return this.invoices.map(legacy => {
                    const model = this.invoicesModels.find(item => item.getKey() === legacy.id);

                    return {
                        legacy,
                        model,
                    };
                });
            },
        },
        watch: {
            currentPage() {
                this.getInvoices();
            },
            order() {
                this.currentPage = 1;
                this.getInvoices();
            },
            orderBy() {
                this.currentPage = 1;
                this.getInvoices();
            },
            selectedStatus: {
                handler() {
                    this.currentPage = 1;
                    this.getInvoices();
                },
                deep: true,
            },
            selectedInvoiceType() {
                this.currentPage = 1;
                this.getInvoices();
            },
            selectedKidFilter() {
                this.currentPage = 1;
                this.getInvoices();
            },
            tagsFilters: {
                handler: _debounce(function ({selectedTagsModels, withoutTags}) {
                    if (selectedTagsModels) {
                        localStorage.setItem(
                            'tags:invoices:index',
                            JSON.stringify(selectedTagsModels.map(item => item.getKey()))
                        );
                    } else {
                        localStorage.removeItem('tags:invoices:index');
                    }
                    localStorage.setItem('without_tags:invoices:index', JSON.stringify(withoutTags));

                    this.currentPage = 1;
                    this.getInvoices();
                }, 500),
                deep: true,
            },
            customer() {
                this.init();
            },
            nursery() {
                this.init();
            },
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.getSelectedTagsModels();

                if (this.customer.attributes.person !== 'legal') {
                    this.getKids();
                }
            },
            onUpdateInvoices() {
                this.getInvoices();
                this.refreshStats();
            },
            getInvoices() {
                this.loading = true;

                const tags = useTagLegacyModelBridge().tagsModelsAndWithoutTagsToLegacyFilter(
                    this.selectedTagsModels,
                    this.withoutTags
                );

                // If account is company, SelectKid returns object instead of the id
                let kidId = undefined;
                if (this.selectedKidFilter) {
                    if (typeof this.selectedKidFilter === 'string') {
                        kidId = this.selectedKidFilter;
                    } else {
                        kidId = this.selectedKidFilter.id;
                    }
                }

                useApi()
                    .legacy.get(
                        route('nurseries.invoices.index', {
                            nurseries: this.nursery.id,
                        }),
                        {
                            params: {
                                customer_id: this.customer.id,
                                kid_id: kidId,
                                with: ['lines'],
                                page: this.currentPage,
                                per_page: this.perPage,
                                order_by: this.orderBy,
                                order: this.order,
                                status: this.selectedStatus,
                                invoice_type: this.selectedInvoiceType,
                                tags,
                            },
                        }
                    )
                    .then(response => {
                        this.total = response.data.total;
                        this.paginator = new LengthAwarePaginator(
                            response.data.data,
                            parseInt(response.data.total),
                            parseInt(response.data.per_page),
                            parseInt(response.data.current_page),
                            parseInt(response.data.last_page)
                        );

                        return this.getInvoicesModels(response.data.data);
                    })
                    .then(() => {
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            async getInvoicesModels(invoices) {
                if (!invoices.length) {
                    this.invoicesModels = [];
                    this.invoices = [];

                    return;
                }

                this.invoicesModels = (
                    await InvoiceModel.query()
                        .with(new InvoiceModel().tags())
                        .with(new InvoiceModel().payments())
                        .with(new InvoiceModel().customer())
                        .with(new InvoiceModel().refunds())
                        .whereIn(
                            'id',
                            invoices.map(invoice => invoice.id)
                        )
                        .get()
                ).all();
                this.invoices = invoices;
            },

            getKids() {
                this.kidsLoading = true;

                KidModel.query()
                    .whereHas(new KidModel().family(), query => {
                        query.whereHas(new FamilyModel().customers(), query2 => {
                            query2.where('id', this.customer.id);
                        });
                    })
                    .get()
                    .then(response => {
                        this.kidModels = response.all();
                        this.selectedKid = this.kidModels[0] ? this.kidModels[0].id : null;
                        this.kidsLoading = false;
                    })
                    .finally(() => {
                        this.kidsLoading = false;
                    });
            },

            showSuccessNotification(invoiceType) {
                const messageKey =
                    invoiceType === 'credit_note'
                        ? 'billing_customer:credit_note_deleted'
                        : 'billing_customer:invoice_deleted';
                useNotification().success(__(messageKey));
            },

            deleteInvoice(invoice) {
                useApi()
                    .legacy.delete(
                        route('nurseries.invoices.destroy', {
                            nurseries: invoice.nursery_id,
                            invoice: invoice.id,
                        })
                    )
                    .then(() => {
                        this.getInvoices();

                        this.refreshStats();
                        this.showSuccessNotification(invoice.invoice_type);
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            showInvoice(invoice, scrollToPaymentAllocations = false) {
                this.currentInvoice = _cloneDeep(invoice);
                this.editInvoiceModal.modal.value.props.scrollToPaymentAllocations = scrollToPaymentAllocations;
                this.editInvoiceModal.show();
            },

            createInvoice(invoice) {
                this.currentInvoice = invoice;
                this.editInvoiceModal.modal.value.props.scrollToPaymentAllocations = false;
                this.editInvoiceModal.show();
            },

            refreshStats() {
                this.$emit('refreshStats', this.customer.id);
            },

            async getSelectedTagsModels() {
                const selectedTagIds = JSON.parse(localStorage.getItem('tags:invoices:index'));
                this.selectedTagsModels = await useTagLegacyModelBridge().getTagsModelsFromIds(selectedTagIds);
            },
        },
    };
</script>
