<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="billing">
                {{ __('app:menu.invoices') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MMenu v-if="$can('update', 'invoices')">
                <MMenuButton>
                    <MButton
                        :label="__('billing_invoice:batch_processing')"
                        variant="primary"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-copy" />
                        </template>
                    </MButton>
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :label="__('billing_invoice:lock_invoices')"
                        @click="convertInvoiceModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-lock-alt"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('billing_invoice:send_invoices')"
                        @click="sendInvoiceModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-paper-plane"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('billing_invoice:relaunch_invoices')"
                        @click="sendRemindersModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-alarm-exclamation"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuDivider />
                    <MMenuItem
                        :label="__('billing_invoice:mandates_sepa')"
                        @click="sepaInvoiceModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-university"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="$showCAF(nursery.country)"
                        :label="__('billing_invoice:caf_certificate_other')"
                        @click="sendCAFModal"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-file-check"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
            <router-link
                v-if="$can('read', 'invoices')"
                :to="{name: 'invoices.index', params: {nursery: nursery.id}, query: $route.query}"
            >
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-list" />
                    </template>
                    {{ __('common:list') }}
                </MButton>
            </router-link>
            <MSettingsButton
                v-if="$can('settings', 'invoices')"
                :to="{name: 'settings.billings.rules', params: {nursery: nursery.id}}"
                :tooltip="__('billing_invoice:invoices_settings')"
            />
        </template>
        <template #content>
            <div class="tw-mb-4 tw-flex tw-flex-wrap">
                <MMenu>
                    <MMenuButton>
                        <MButton
                            class="tw-mr-2"
                            icon-menu
                        >
                            <template
                                v-if="person === 'natural' || person === 'legal'"
                                #left-icons
                            >
                                <template v-if="person === 'natural'">
                                    <FontAwesomeIcon icon="fa-solid fa-user" />
                                </template>
                                <template v-if="person === 'legal'">
                                    <FontAwesomeIcon icon="fa-duotone fa-city fa-swap-opacity" />
                                </template>
                            </template>
                            <template v-if="person === 'natural'">
                                {{ __('billing_invoice:phyisical_entities') }}
                            </template>
                            <template v-else-if="person === 'legal'">
                                {{ __('billing_invoice:legal_entities') }}
                            </template>
                            <template v-else>
                                {{ __('billing_invoice:all_account') }}
                            </template>
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem @click="person = null">
                            <template #icon>
                                <FontAwesomeIcon icon="fa-solid fa-check-double" />
                            </template>
                            {{ __('billing_invoice:all_account') }}
                        </MMenuItem>
                        <MMenuItem @click="person = 'natural'">
                            <template #icon>
                                <FontAwesomeIcon icon="fa-solid fa-user" />
                            </template>
                            {{ __('billing_invoice:phyisical_entities') }}
                        </MMenuItem>
                        <MMenuItem @click="person = 'legal'">
                            <template #icon>
                                <FontAwesomeIcon icon="fa-duotone fa-city fa-swap-opacity" />
                            </template>
                            {{ __('billing_invoice:legal_entities') }}
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>

                <FilterInvoiceType
                    v-model="selectedType"
                    class="tw-mr-2 tw-inline-block"
                />

                <FilterInvoiceStatus v-model="selectedStatus" />
            </div>

            <MBox class="tw-p-4">
                <div>
                    <div class="row tw-items-center">
                        <div class="col-6 col-sm-4 tw-order-2 tw-text-left sm:tw-order-1">
                            <MButton
                                variant="success"
                                class="tw-capitalize"
                                @click="goPrevMonth"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                                </template>
                                {{ currentMonth.clone().subtract(1, 'months').format('MMMM Y') }}
                            </MButton>
                        </div>
                        <div class="col-12 col-sm-4 tw-order-1 tw-text-center sm:tw-order-2">
                            <MHeading class="tw-capitalize">
                                {{ currentMonth.clone().format('MMMM Y') }}
                            </MHeading>
                        </div>
                        <div class="col-6 col-sm-4 tw-order-3 tw-text-right sm:tw-order-3">
                            <MButton
                                variant="success"
                                class="tw-capitalize"
                                @click="goNextMonth"
                            >
                                {{ currentMonth.clone().add(1, 'months').format('MMMM Y') }}
                                <template #right-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                                </template>
                            </MButton>
                        </div>
                    </div>
                    <hr class="hr" />
                    <transition
                        mode="out-in"
                        name="transition-fade"
                    >
                        <div v-if="!loading">
                            <div class="tw--ml-4 tw-flex tw-flex-wrap">
                                <div
                                    v-for="customer in filteredCustomers"
                                    :key="'customer_' + customer.id"
                                    class="tw-mb-4 tw-w-full lg:tw-w-1/2"
                                >
                                    <div class="tw-flex tw-flex-col tw-gap-2 tw-pl-4">
                                        <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                                            <router-link
                                                class="tw-flex tw-items-center tw-gap-1.5 tw-text-sm tw-font-semibold tw-uppercase tw-tracking-wide tw-text-gray-500 hover:tw-text-orange-500"
                                                :to="{
                                                    name: 'cashier/customers.show.invoices',
                                                    params: {nursery: nursery.id, customer: customer.id},
                                                }"
                                            >
                                                <FontAwesomeIcon
                                                    v-if="customer.person === 'legal'"
                                                    icon="fa-duotone fa-city"
                                                    swap-opacity
                                                />
                                                <FontAwesomeIcon
                                                    v-else
                                                    icon="fa-solid fa-user"
                                                />
                                                {{ customer.name }}
                                            </router-link>
                                            <AvailableCreditAmount
                                                v-if="customer.model.availableTotalCreditAmount > 0"
                                                :customer="customer.model"
                                            />
                                        </div>
                                        <div
                                            v-if="customer.invoices.length === 0"
                                            class="tw-flex tw-cursor-pointer tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-px-3 tw-py-4 tw-shadow hover:tw-bg-gray-200"
                                        >
                                            <router-link
                                                class="tw-w-full tw-self-center tw-text-center tw-text-sm tw-text-gray-500"
                                                :to="{
                                                    name: 'cashier/customers.show.invoices',
                                                    params: {nursery: nursery.id, customer: customer.id},
                                                }"
                                            >
                                                {{ __('billing_invoice:nothing_to_invoice') }}
                                            </router-link>
                                        </div>
                                        <InvoicesListItem
                                            v-for="invoice in customer.invoices"
                                            v-else
                                            :key="'customer_' + customer.id + '_invoice_' + invoice.id"
                                            :customer="customer.model"
                                            :invoice="invoice"
                                            :invoice-model="invoice.model"
                                            :nursery="nursery"
                                            @show-invoice="showInvoice(invoice, $event)"
                                            @update-invoices="getCustomers"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="total > perPage"
                                class="tw-mt-4 tw-flex tw-justify-center"
                            >
                                <MPagination
                                    :paginator="paginator"
                                    @navigate="currentPage = $event"
                                />
                            </div>
                        </div>

                        <div
                            v-else
                            key="loader"
                        >
                            <loader custom-class="la-2x" />
                        </div>
                    </transition>
                </div>
            </MBox>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import {datadogRum} from '@datadog/browser-rum';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _debounce from 'lodash-es/debounce';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useConcurrentCallback, {QueryOverlapError} from '@/modules/app/composables/useConcurrentCallback';
    import useModal from '@/modules/app/composables/useModal';
    import useMetrics from '@/modules/app/composables/useRum';
    import ActionsCAF from '@/modules/cashier/components/billing/invoice/Actions/CAF.vue';
    import ActionsConvert from '@/modules/cashier/components/billing/invoice/Actions/Convert.vue';
    import ActionsSend from '@/modules/cashier/components/billing/invoice/Actions/Send.vue';
    import ActionsSendReminders from '@/modules/cashier/components/billing/invoice/Actions/SendReminders.vue';
    import ActionsSEPA from '@/modules/cashier/components/billing/invoice/Actions/SEPA.vue';
    import FilterInvoiceStatus from '@/modules/cashier/components/billing/invoice/FilterInvoiceStatus.vue';
    import FilterInvoiceType from '@/modules/cashier/components/billing/invoice/FilterInvoiceType.vue';
    import InvoicesListItem from '@/modules/cashier/components/billing/invoice/molecules/InvoicesListItem.vue';
    import EditInvoice from '@/modules/cashier/components/billing/invoice/organisms/EditInvoice.vue';
    import AvailableCreditAmount from '@/modules/cashier/customer/components/AvailableCreditAmount.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import route from '@/modules/legacy/libs/ziggy';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import {url} from '@/modules/legacy/mixins/url';
    import MPagination from '@/modules/meeko-ui/components/MPagination.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            AvailableCreditAmount,
            FilterInvoiceType,
            MPagination,
            SimplePageTemplate,
            InvoicesListItem,
            FilterInvoiceStatus,
        },
        mixins: [invoice, nursery, url],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            sepaMandates: {
                type: Array,
                required: false,
                default: null,
            },
        },
        data: () => ({
            loading: false,
            customers: [],
            currentInvoice: {},
            currentPage: 1,
            total: 0,
            perPage: 20,
            person: localStorage.getItem('customers:index:person'),
            selectedStatus: [],
            selectedType: null,
            currentMonth: moment(),
            paginator: undefined,
            resolveLastCallback: useConcurrentCallback().resolveLastCallback,
        }),
        computed: {
            moment() {
                return moment;
            },
            filteredCustomers() {
                return this.customers;
            },
            editInvoiceModal() {
                const customer = this.customers.find(item => item.id === this.currentInvoice.customer_id);

                return useModal({
                    component: EditInvoice,
                    props: {
                        nursery: this.nursery,
                        invoice: this.currentInvoice,
                        customer: customer.model,
                    },
                    listeners: () => ({
                        updateInvoices: this.getCustomers,
                    }),
                });
            },
        },
        watch: {
            currentPage() {
                this.pushQueryString('p', this.currentPage);
                this.getCustomers();
            },
            person(val) {
                localStorage.setItem('customers:index:person', val);
                this.currentPage = 1;
                this.getCustomers();
            },
            selectedStatus: {
                handler() {
                    this.currentPage = 1;
                    this.getCustomers();
                },
                deep: true,
            },
            selectedType() {
                this.currentPage = 1;
                this.getCustomers();
            },
            currentMonth: _debounce(function () {
                this.currentPage = 1;
                this.getCustomers();
            }, 500),
            paginator() {
                this.pushQueryString('p', this.paginator.currentPage());
            },
        },
        mounted() {
            datadogRum.startSessionReplayRecording();

            if (this.$route.query.p) {
                this.currentPage = parseInt(this.$route.query.p);
            }

            if (localStorage.getItem('customers:index:person') === 'null') {
                this.person = null;
            }

            this.selectMonth(this.$route.query.from);

            this.getCustomers();

            useMetrics().addAction('M_Invoices_Display', {value: 'column'});
        },
        methods: {
            convertInvoiceModal() {
                useModal({
                    component: ActionsConvert,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sendInvoiceModal() {
                useModal({
                    component: ActionsSend,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sepaInvoiceModal() {
                useModal({
                    component: ActionsSEPA,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                        sepas: this.sepaMandates,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sendRemindersModal() {
                useModal({
                    component: ActionsSendReminders,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            sendCAFModal() {
                useModal({
                    component: ActionsCAF,
                    props: {
                        nursery: this.nursery,
                        date: this.currentMonth,
                    },
                    listeners: modal => ({
                        updated: invoices => {
                            this.updateInvoices(invoices);
                            modal.hide();
                        },
                    }),
                }).show();
            },

            async getCustomersInvoicesModels(customers) {
                const invoices = customers.reduce((acc, customer) => acc.concat(customer.invoices), []);

                const customerIds = customers.map(customer => customer.id);

                // Get customerModel with transactions once for all invoices
                const customersModels = (
                    await CustomerModel.query()
                        .with(new CustomerModel().transactions(), query => {
                            query.where('remaining_to_distribute_amount', '>', 0);
                        })
                        .whereIn('id', customerIds)
                        .get()
                ).all();

                _forEach(customers, customer => {
                    customer.model = customersModels.find(item => item.getKey() === customer.id);
                });

                let tmpCustomers;
                if (!invoices.length) {
                    _forEach(customers, customer => {
                        customer.invoices = [];
                    });

                    tmpCustomers = customers;

                    return {
                        customersModels,
                        invoicesModels: [],
                        tmpCustomers,
                    };
                }

                const invoicesModels = (
                    await InvoiceModel.query()
                        .with(new InvoiceModel().tags())
                        .with(new InvoiceModel().payments())
                        .with(new InvoiceModel().refunds())
                        .whereIn(
                            'id',
                            invoices.map(invoice => invoice.id)
                        )
                        .get()
                ).all();

                _forEach(customers, customer => {
                    customer.invoices = customer.invoices.map(invoice => {
                        const invoiceModel = invoicesModels.find(item => item.id === invoice.id);

                        return {
                            ...invoice,
                            model: invoiceModel,
                        };
                    });
                });

                tmpCustomers = customers;

                return {
                    customersModels,
                    invoicesModels,
                    tmpCustomers,
                };
            },

            async getCustomers() {
                this.loading = true;

                try {
                    const result = await this.resolveLastCallback(async () => {
                        try {
                            const response = await useApi().legacy.get(
                                route('nurseries.customers.billing', {
                                    nurseries: this.nursery.id,
                                }),
                                {
                                    params: {
                                        person: this.person,
                                        page: this.currentPage,
                                        per_page: this.perPage,
                                        from: moment(this.currentMonth).startOf('month').format('YYYY-MM-DD'),
                                        to: moment(this.currentMonth).endOf('month').format('YYYY-MM-DD'),
                                        status: this.selectedStatus,
                                        invoice_type: this.selectedType,
                                    },
                                }
                            );
                            this.total = response.data.total;
                            this.paginator = new LengthAwarePaginator(
                                response.data.data,
                                parseInt(response.data.total),
                                parseInt(response.data.per_page),
                                parseInt(response.data.current_page),
                                parseInt(response.data.last_page)
                            );

                            const {customersModels, invoicesModels, tmpCustomers} =
                                await this.getCustomersInvoicesModels(response.data.data);

                            return {
                                customersModels,
                                invoicesModels,
                                tmpCustomers,
                            };
                        } catch (error) {
                            this.loading = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            }
                            throw error;
                        }
                    });

                    this.customersModels = result.customersModels;
                    this.invoicesModels = result.invoicesModels;
                    this.customers = result.tmpCustomers;
                    this.loading = false;
                } catch (e) {
                    if (e instanceof QueryOverlapError) {
                        // We don't do anything here, the result is outdated
                        return false;
                    }
                    throw e;
                }
            },

            showInvoice(invoice, scrollToPaymentAllocations = false) {
                this.currentInvoice = _cloneDeep(invoice);
                this.editInvoiceModal.modal.value.props.scrollToPaymentAllocations = scrollToPaymentAllocations;
                this.editInvoiceModal.show();
            },

            updateInvoices(invoices, updateAll) {
                invoices.forEach(invoice => {
                    const customer = this.customers.find(item => item.id === invoice.customer_id);

                    if (customer) {
                        const invoiceToUpdate = customer.invoices.find(item => item.id === invoice.id);

                        if (invoiceToUpdate) {
                            if (updateAll) {
                                const invoiceIndex = customer.invoices.indexOf(invoiceToUpdate);
                                if (moment(invoice.date).isSame(this.currentMonth, 'month')) {
                                    set(customer.invoices, invoiceIndex, invoice);
                                } else {
                                    customer.invoices.splice(invoiceIndex, 1);
                                }
                            } else {
                                invoiceToUpdate.no = invoice.no;
                                invoiceToUpdate.sent_at = invoice.sent_at;
                                invoiceToUpdate.dunning_dates = invoice.dunning_dates;
                                invoiceToUpdate.caf_sent_at = invoice.caf_sent_at;
                                invoiceToUpdate.sepa_done_at = invoice.sepa_done_at;
                                invoiceToUpdate.name = invoice.name;
                                invoiceToUpdate.status = invoice.status;
                            }
                        }

                        this.customers = this.customers.map(item => {
                            if (item.id === customer.id) {
                                return customer;
                            }

                            return item;
                        });
                    }
                });
            },

            selectMonth(from) {
                this.currentMonth = from ? moment.unix(from) : moment();
            },

            goNextMonth() {
                this.currentMonth = this.currentMonth.clone().add(1, 'months');
                this.pushQueryString('from', moment(this.currentMonth).startOf('month').unix());
            },
            goPrevMonth() {
                this.currentMonth = this.currentMonth.clone().subtract(1, 'months');
                this.pushQueryString('from', moment(this.currentMonth).startOf('month').unix());
            },
        },
    };
</script>
