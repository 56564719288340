<script setup lang="ts">
    defineProps<{
        newClosedPeriod: {
            started_at: string;
            ended_at: string;
            name: string;
        };
        saving: boolean;
    }>();

    const emit = defineEmits(['addClosedPeriod']);
</script>

<template>
    <MForm>
        <CFormTwoColumns>
            <MFieldset>
                <MLabel>
                    {{ __('common:beginning') }}
                </MLabel>
                <MDatePicker
                    v-model="newClosedPeriod.started_at"
                    class="tw-w-full"
                    format="yyyy-MM-dd"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    {{ __('common:end') }}
                </MLabel>
                <MDatePicker
                    v-model="newClosedPeriod.ended_at"
                    class="tw-w-full"
                    format="yyyy-MM-dd"
                />
            </MFieldset>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <MFieldset>
                <MInput
                    v-model="newClosedPeriod.name"
                    :placeholder="__('organization:closure_reason')"
                    type="text"
                />
            </MFieldset>
            <MFieldset>
                <MButton
                    class="tw-w-full"
                    icon-plus
                    :label="__('common:actions.add')"
                    :loading="saving"
                    variant="primary"
                    @click="emit('addClosedPeriod')"
                />
            </MFieldset>
        </CFormTwoColumns>
    </MForm>
</template>
