<template>
    <div>
        <config
            v-model:computed-hourly-rate="computedHourlyRate"
            :config="contract.config"
            :contract="contract"
            :nursery="nursery"
            :price-loading="loading"
            :response-config="responseConfig"
        />
        <div class="row">
            <div class="col-12 col-lg-4">
                <MBox>
                    <div class="tw-border-b tw-border-gray-200 tw-px-5 tw-py-3 tw-text-lg tw-text-info-500">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-mt-2"
                            icon="fa-solid fa-child-reaching"
                        />
                        {{ $fullName(kid) }}
                        <MButton
                            class="tw-float-right"
                            @click="kidAdvanced = !kidAdvanced"
                        >
                            {{ kidAdvanced ? __('common:simple') : __('common:advanced') }}
                        </MButton>
                    </div>
                    <div class="tw-p-4">
                        <kid-planning-summary
                            v-if="contract.plannings && contract.type !== 'occasional'"
                            :nursery="nursery"
                            :plannings="contract.plannings"
                            :weeks="contract.recurrent_weeks"
                        />
                        <div v-else-if="contract.type === 'occasional'">
                            <strong>{{ __('common:occasionnal_contract') }}</strong>
                        </div>
                        <div
                            v-if="contract.config && contract.config.smoothing.on === 'contract'"
                            class="tw-mt-2 tw-text-gray-700"
                        >
                            <div v-if="loading">
                                <FontAwesomeIcon
                                    class="tw-mr-1"
                                    icon="fa-solid fa-circle-notch"
                                    spin
                                />
                                {{ __('common:loading_dots') }}
                            </div>
                            <template v-else>
                                <template
                                    v-if="
                                        responseConfig &&
                                        responseConfig.presence &&
                                        contract.config &&
                                        contract.config.billing.cycle !== 'month'
                                    "
                                >
                                    <div v-if="responseConfig.presence.months">
                                        {{ __('common:month_with_count', {count: responseConfig.presence.months}) }}
                                    </div>
                                    <div v-if="responseConfig.presence.weeks">
                                        {{ __('common:week_with_count', {count: responseConfig.presence.weeks}) }}
                                    </div>
                                    <div
                                        v-if="
                                            contract.config.billing.cycle === 'halfday' &&
                                            responseConfig.presence.halfdays
                                        "
                                    >
                                        {{
                                            __('common:halfday_alternative_long', {
                                                count: responseConfig.presence.halfdays,
                                            })
                                        }}
                                    </div>
                                    <div v-else-if="responseConfig.presence.days">
                                        {{ __('common:day_with_count', {count: responseConfig.presence.days}) }}
                                    </div>
                                    <div v-if="responseConfig.presence.hours">
                                        {{ __('common:hour_with_count', {count: responseConfig.presence.hours}) }}
                                    </div>
                                </template>
                                <div class="tw-mt-2">
                                    {{ __('common:holiday', {count: holidays}) }}
                                </div>
                                <div>
                                    {{ __('common:closed_period', {count: closed_periods}) }}
                                </div>
                            </template>
                        </div>
                        <hr class="hr" />
                        <MForm>
                            <MFieldset
                                v-if="
                                    contract.config &&
                                    contract.config.billing.cycle !== 'month' &&
                                    contract.config.smoothing.on === 'year' &&
                                    contract.type !== 'occasional'
                                "
                            >
                                <MLabel>{{ __('billing_invoice:invoiced_quantity_per_month') }}</MLabel>
                                <CButtonGroup class="tw-w-full">
                                    <MInput
                                        v-model.number="contract.config.billing.qty"
                                        v-float
                                        :disabled="!$can('update', 'invoices')"
                                    />
                                    <MSelectMenu
                                        v-model="contract.config.billing.cycle"
                                        :disabled="!$can('update', 'invoices')"
                                        :options="cycleOptions"
                                    />
                                </CButtonGroup>
                            </MFieldset>
                            <!-- LISSAGE SUR L'ANNÉE UNIQUEMENT -->
                            <template v-if="contract.config && contract.config.smoothing.on === 'year'">
                                <!-- DEJA LISSE -->
                                <MFieldset v-if="contract.config.smoothing.smooth_on === 'none'">
                                    <MLabel>{{ __('billing_invoice:weeks_in_month') }}</MLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput
                                            v-model.number="contract.config.billing.weeks_in_month"
                                            placeholder="4"
                                        />
                                        <CInputAddon>
                                            {{ __('common:week_other') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                </MFieldset>
                                <!-- LISSAGE PRESENCE OU TARIF -->
                                <MFieldset
                                    v-if="
                                        contract.config.smoothing.smooth_on !== 'none' &&
                                        contract.config.smoothing.months !== undefined &&
                                        contract.type !== 'occasional'
                                    "
                                >
                                    <MLabel>{{ __('billing_invoice:months_in_smoothing') }}</MLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput v-model.number="contract.config.smoothing.months" />
                                        <CInputAddon>
                                            {{ __('billing_invoice:month_lower') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                    <div
                                        v-if="
                                            !contract.config.smoothing.months &&
                                            responseConfig.presence &&
                                            responseConfig.presence.months
                                        "
                                        class="tw-mt-1 tw-text-sm"
                                    >
                                        {{ __('billing_invoice:computed_months_colon') }}
                                        <strong>{{ loading ? '...' : responseConfig.presence.months }}</strong>
                                    </div>
                                </MFieldset>
                                <MFieldset
                                    v-if="
                                        contract.config.smoothing.smooth_on !== 'none' && contract.type !== 'occasional'
                                    "
                                >
                                    <MLabel>{{ __('billing_invoice:weeks_in_smoothing') }}</MLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput v-model.number="contract.config.smoothing.weeks_in_contract" />
                                        <CInputAddon>
                                            {{ __('common:week_other') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                    <div
                                        v-if="
                                            !contract.config.smoothing.weeks_in_contract &&
                                            responseConfig.presence &&
                                            responseConfig.presence.weeks
                                        "
                                        class="tw-mt-1 tw-text-sm"
                                    >
                                        {{ __('billing_invoice:computed_weeks_colon') }}
                                        <strong>{{ loading ? '...' : responseConfig.presence.weeks }}</strong>
                                    </div>
                                </MFieldset>
                                <MFieldset
                                    v-if="
                                        contract.config.smoothing.smooth_on !== 'none' &&
                                        contract.config.smoothing.weeks_in_contract !== undefined &&
                                        contract.type !== 'occasional'
                                    "
                                >
                                    <MLabel>{{ __('billing_invoice:week_to_be_deducted') }}</MLabel>
                                    <CInputGroup class="tw-w-full">
                                        <MInput v-model.number="contract.config.smoothing.closing_weeks" />
                                        <CInputAddon>
                                            {{ __('common:week_other') }}
                                        </CInputAddon>
                                    </CInputGroup>
                                    <div class="tw-mt-1 tw-text-sm">
                                        <span
                                            v-if="
                                                !contract.config.smoothing.weeks_in_contract &&
                                                responseConfig.presence &&
                                                responseConfig.presence.weeks
                                            "
                                        >
                                            {{ __('billing_invoice:total_weeks_colon') }}
                                            <strong>
                                                {{
                                                    loading
                                                        ? '...'
                                                        : responseConfig.presence.weeks -
                                                          contract.config.smoothing.closing_weeks
                                                }}
                                            </strong>
                                        </span>
                                        <span v-else>
                                            {{ __('billing_invoice:total_weeks_colon') }}
                                            <strong>
                                                {{
                                                    contract.config.smoothing.weeks_in_contract -
                                                    contract.config.smoothing.closing_weeks
                                                }}
                                            </strong>
                                        </span>
                                    </div>
                                </MFieldset>
                            </template>
                            <MFieldset>
                                <MLabel>{{ __('billing_invoice:holidays_other') }}</MLabel>
                                <CInputGroup class="tw-w-full">
                                    <MInput
                                        v-model.number="contract.vacation_days"
                                        :disabled="!$can('update', 'invoices')"
                                    />
                                    <CInputAddon>
                                        {{ __('common:day_other') }}
                                    </CInputAddon>
                                </CInputGroup>
                                <div
                                    v-if="contract.vacation_days && contract.used_vacation_days"
                                    class="tw-mt-1 tw-text-sm"
                                >
                                    <strong>
                                        {{
                                            __('billing_invoice:used_vacation_days', {
                                                count: contract.used_vacation_days,
                                            })
                                        }}
                                    </strong>
                                </div>
                            </MFieldset>
                            <template v-if="kidAdvanced">
                                <MFieldset>
                                    <MLabel>{{ __('family_kid:foods.eating_habits') }}</MLabel>
                                    <MSelectMenu
                                        v-model="kid.meal_type"
                                        :disabled="!$can('update', 'invoices')"
                                        :options="mealsOptions"
                                    />
                                </MFieldset>
                                <MFieldset>
                                    <MLabel>{{ __('family_kid:hygiene') }}</MLabel>
                                    <MSelectMenu
                                        v-model="kid.hygiene"
                                        :disabled="!$can('update', 'invoices')"
                                        :options="hygieneOptions"
                                    />
                                </MFieldset>
                            </template>
                        </MForm>
                    </div>
                </MBox>
                <MBox
                    v-if="contract.config && contract.config.deposit"
                    class="tw-mt-4"
                >
                    <div class="tw-border-b tw-border-gray-200 tw-px-5 tw-py-3 tw-text-lg tw-text-info-500">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-mt-2"
                            icon="fa-solid fa-money-check-pen"
                        />
                        {{ __('common:deposit_one') }}
                        <MButton
                            class="tw-float-right"
                            @click="depositAdvanced = !depositAdvanced"
                        >
                            {{ depositAdvanced ? __('common:simple') : __('common:advanced') }}
                        </MButton>
                    </div>
                    <div class="tw-p-4">
                        <MFieldset>
                            <MLabel>
                                {{ __('common:amount_one') }}
                            </MLabel>
                            <div class="tw-flex tw-flex-row tw-gap-2 lg:tw-flex-col xl:tw-flex-row">
                                <CButtonGroup class="tw-w-auto lg:tw-w-full xl:tw-w-auto">
                                    <MButton
                                        class="tw-w-auto lg:tw-w-1/2 xl:tw-w-auto"
                                        :variant="contract.config.deposit.type === 'month' ? 'info' : 'light'"
                                        @click="contract.config.deposit.type = 'month'"
                                    >
                                        {{ __('common:per_month_two') }}
                                    </MButton>
                                    <MButton
                                        class="tw-w-auto lg:tw-w-1/2 xl:tw-w-auto"
                                        :variant="contract.config.deposit.type === 'fixed' ? 'info' : 'light'"
                                        @click="contract.config.deposit.type = 'fixed'"
                                    >
                                        {{ __('common:phone.landline') }}
                                    </MButton>
                                </CButtonGroup>
                                <MCurrencyInput
                                    v-if="contract.config.deposit.type === 'fixed'"
                                    v-model="contract.config.deposit.amount"
                                    :currency-symbol="nursery.currency"
                                />
                                <CInputGroup v-else>
                                    <MInput v-model.number="contract.config.deposit.month_coef" />
                                    <CInputAddon>
                                        {{ __('billing_invoice:month_lower') }}
                                    </CInputAddon>
                                </CInputGroup>
                            </div>
                        </MFieldset>
                        <div
                            v-if="
                                depositAdvanced &&
                                (contract.config.deposit.amount > 0 || contract.config.deposit.month_coef > 0)
                            "
                        >
                            <MFieldset class="tw-mt-5">
                                <MLabel>
                                    {{ __('billing_invoice:deposited_the') }}
                                </MLabel>
                                <CButtonGroup
                                    class="tw-w-full"
                                    :direction="direction"
                                >
                                    <CFormDatepicker
                                        v-model="contract.config.deposit.received_at"
                                        class="tw-w-full xl:tw-w-1/2"
                                        format="YYYY-MM-DD"
                                        :search-bar="false"
                                    />
                                    <payment-methods
                                        v-model="contract.config.deposit.received_type"
                                        class="tw-w-full xl:tw-w-1/2"
                                    />
                                </CButtonGroup>
                            </MFieldset>
                            <MFieldset class="tw-mt-3">
                                <MLabel>
                                    {{ __('billing_invoice:restituted_the') }}
                                </MLabel>
                                <CButtonGroup
                                    class="tw-w-full"
                                    :direction="direction"
                                >
                                    <CFormDatepicker
                                        v-model="contract.config.deposit.refunded_at"
                                        class="tw-w-full xl:tw-w-1/2"
                                        format="YYYY-MM-DD"
                                    />
                                    <payment-methods
                                        v-model="contract.config.deposit.refunded_type"
                                        class="tw-w-full xl:tw-w-1/2"
                                    />
                                </CButtonGroup>
                            </MFieldset>
                        </div>
                    </div>
                </MBox>
                <MBox class="tw-mt-4">
                    <div class="tw-border-b tw-border-gray-200 tw-px-5 tw-py-3 tw-text-lg tw-text-info-500">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-mt-2"
                            icon="fa-solid fa-users"
                        />
                        {{ __('billing_invoice:the_family') }}
                        <MButton
                            class="tw-float-right"
                            @click="familyAdvanced = !familyAdvanced"
                        >
                            {{ familyAdvanced ? __('common:simple') : __('common:advanced') }}
                        </MButton>
                    </div>
                    <div class="tw-p-4">
                        <template v-if="showCAF()">
                            <div v-if="showIncomeBracket">
                                {{
                                    family.income_bracket !== null
                                        ? __('billing_invoice:income_bracket_with_value', {
                                              value: family.income_bracket,
                                          })
                                        : __('billing_invoice:unknow_income_bracket')
                                }}
                            </div>
                            <div
                                v-if="showCmg"
                                v-html="
                                    __('billing_invoice:cmg_with_nursery_currency', {
                                        cmg: family.cmg ? family.cmg : 0,
                                        currency: nursery.currency,
                                    })
                                "
                            />
                            <div
                                v-if="showEffortRate"
                                v-html="
                                    __('billing_invoice:effort_rate_with_rate', {
                                        effortRate: family.effort_rate ? family.effort_rate : 0,
                                    })
                                "
                            />
                            <hr class="hr" />
                        </template>
                        <MForm>
                            <MFieldset>
                                <MLabel>{{ __('billing_core:annual_resources') }}</MLabel>
                                <MInput
                                    v-model.number="contract.cafpro_resources"
                                    :disabled="!contractExists || !$can('update', 'invoices')"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>{{ __('common:dependent_children') }}</MLabel>
                                <MInput
                                    v-model.number="contract.dependent_children"
                                    :disabled="!$can('update', 'invoices')"
                                />
                            </MFieldset>
                            <MFieldset v-if="familyAdvanced">
                                <MLabel>{{ __('billing_invoice:handicapped_children') }}</MLabel>
                                <MInput
                                    v-model.number="contract.handicapped_children"
                                    :disabled="!$can('update', 'invoices')"
                                />
                            </MFieldset>
                        </MForm>
                    </div>
                </MBox>
            </div>
            <div class="col-12 col-lg-8 tw-mt-4 lg:tw-mt-0">
                <MBox>
                    <div
                        class="tw-flex tw-items-center tw-border-b tw-border-gray-200 tw-px-5 tw-py-3 tw-text-lg tw-text-info-500"
                    >
                        <div class="tw-mr-auto">
                            <FontAwesomeIcon
                                class="tw-mr-2 tw-mt-2"
                                icon="fa-solid fa-file-alt"
                            />
                            {{ __('billing_invoice:the_invoices') }}
                        </div>
                        <CTooltip v-if="showResourcesWarning">
                            <CBadge
                                class="tw-px-2 tw-py-1"
                                variant="gray"
                            >
                                <FontAwesomeIcon
                                    class="tw-mr-1"
                                    icon="fa-duotone fa-exclamation-triangle"
                                />
                                {{ __('billing_invoice:missing_resources') }}
                            </CBadge>
                            <template #content>
                                <div v-html="__('billing_invoice:missing_resources_explanation')" />
                            </template>
                        </CTooltip>
                        <template
                            v-if="
                                !contract.draft &&
                                contractExists &&
                                contract.id &&
                                !contract.broked_at &&
                                $can('create', 'invoices')
                            "
                        >
                            <MButton
                                class="tw-ml-2"
                                :disabled="updating"
                                :loading="creatingInvoices"
                                variant="orange"
                                @click="generateInvoices"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                                </template>
                                {{ __('billing_invoice:create_invoices_button') }}
                            </MButton>
                        </template>
                    </div>
                    <div>
                        <invoices
                            :invoices="invoices"
                            :loading="loading"
                            :nursery="nursery"
                        />
                    </div>
                </MBox>
            </div>
        </div>
    </div>
</template>

<script>
    import {isEmpty} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _debounce from 'lodash-es/debounce';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import {EventBus} from '@/eventBus';
    import useApi from '@/modules/app/composables/useApi';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import {useResponsiveValue} from '@/modules/app/composables/useResponsiveValue';
    import __ from '@/modules/app/utils/i18n-facade';
    import PaymentMethods from '@/modules/cashier/components/payment-method/legacy/PaymentMethods.vue';
    import KidPlanningSummary from '@/modules/legacy/components/Modules/KidPlanningSummary.vue';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    import Config from './Config.vue';
    import Invoices from './Invoices.vue';

    export default {
        components: {
            Config,
            Invoices,
            KidPlanningSummary,
            PaymentMethods,
        },
        mixins: [nursery],
        props: {
            user: {},
            nursery: {},
            contract: {},
            kid: {},
            contractExists: {
                type: Boolean,
                default: true,
            },
            updating: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            invoices: [],
            loading: false,
            isUserUpdatingValues: false,
            creatingInvoices: false,
            kidAdvanced: window.localStorage.getItem('kidEstimateAdvanced') === 'true',
            familyAdvanced: window.localStorage.getItem('familyEstimateAdvanced') === 'true',
            depositAdvanced: window.localStorage.getItem('depositEstimateAdvanced') === 'true',
            computedHourlyRate: null,
            computedMonthlyRate: null,
            holidays: null,
            closed_periods: null,
            responseConfig: {},
            family: {
                income_bracket: null,
                cmg: null,
                effort_rate: null,
            },
            mealsOptions: [
                {
                    text: __('common:meal_types.milk'),
                    value: 'milk',
                },
                {
                    text: __('common:meal_types.mixed'),
                    value: 'mixed',
                },
                {
                    text: __('common:meal_types.crushed'),
                    value: 'crushed',
                },
                {
                    text: __('common:meal_types.mix'),
                    value: 'mix',
                },
                {
                    text: __('common:meal_types.normal'),
                    value: 'normal',
                },
            ],
            hygieneOptions: [
                {
                    text: __('common:hygiene_types.diaper'),
                    value: 'baby',
                },
                {
                    text: __('common:hygiene_types.potty'),
                    value: 'potty',
                },
                {
                    text: __('common:hygiene_types.potty_and_nappy'),
                    value: 'mixed',
                },
            ],
            cycleOptions: [
                {
                    text: __('common:day_other'),
                    value: 'day',
                },
                {
                    text: __('common:halfday_other'),
                    value: 'halfday',
                },
                {
                    text: __('common:hour_other'),
                    value: 'hour',
                },
            ],
        }),
        computed: {
            direction() {
                return useResponsiveValue({xl: 'row', lg: 'column', md: 'row', base: 'column'}).value;
            },
            showIncomeBracket() {
                return this.contract.config && this.contract.config.pricing
                    ? this.contract.config.pricing.discount.on_salary
                    : false;
            },
            showEffortRate() {
                return this.contract.config ? this.contract.config.funding === 'psu' : false;
            },
            showCmg() {
                return this.contract.config ? this.contract.config.funding !== 'psu' : false;
            },
            showResourcesWarning() {
                // If ressources aren't provided
                if (!this.contract.cafpro_resources && this.contract.config) {
                    // Then show warning for specific configurations
                    return (
                        this.contract.config.funding === 'psu' ||
                        this.contract.config.funding === 'psu_cmg' ||
                        this.showIncomeBracket
                    );
                }

                return false;
            },
        },
        watch: {
            contract: {
                handler: function () {
                    this.isUserUpdatingValues = true;
                    this.loading = true;
                    this.getEstimationDebounced();
                },
                deep: true,
            },
            kid: {
                handler: function () {
                    this.isUserUpdatingValues = true;
                    this.loading = true;
                    this.getEstimationDebounced();
                },
                deep: true,
            },
            kidAdvanced(val) {
                window.localStorage.setItem('kidEstimateAdvanced', val);
            },
            familyAdvanced(val) {
                window.localStorage.setItem('familyEstimateAdvanced', val);
            },
            depositAdvanced(val) {
                window.localStorage.setItem('depositEstimateAdvanced', val);
            },
        },
        mounted() {
            this.loading = true;
            useBroadcast().sessionChannel.bind('contract-simulation', response => {
                // If the user is still updating values, we don't want to retrieve the processed data
                if (!this.isUserUpdatingValues) {
                    ProcessedData.retrieve(response.processedDataUrl, response => {
                        this.setEstimationResult(response.data);
                        this.loading = false;
                    });
                }
            });

            EventBus.$on('invoices:created', this.handleInvoicesCreated);
            EventBus.$on('invoices:creating_error', this.handleInvoicesCreatingError);

            this.getEstimation();
        },
        beforeUnmount() {
            useBroadcast().sessionChannel.unbind('contract-simulation');
            EventBus.$off('invoices:created', this.handleInvoicesCreated);
            EventBus.$off('invoices:creating_error', this.handleInvoicesCreatingError);
        },
        methods: {
            handleInvoicesCreated() {
                this.creatingInvoices = false;
            },
            handleInvoicesCreatingError() {
                this.creatingInvoices = false;
            },
            getEstimation() {
                // Prevent request if contract is null and from registration or no config exists
                if (
                    (!this.contract.id && !this.contractExists) ||
                    typeof this.contract.config == 'undefined' ||
                    this.contract.config === null
                ) {
                    return;
                }

                this.isUserUpdatingValues = false;

                // Set cafpro_resources from both parent - Only for contract
                if (this.contractExists) {
                    this.contract.cafpro_resources === null || this.contract.cafpro_resources === ''
                        ? (this.contract.cafpro_resources = this.contract.first_salary + this.contract.second_salary)
                        : null;
                }

                const data = {};
                data.contract = _cloneDeep(this.contract);
                data.config = _cloneDeep(this.contract.config);
                data.config.id = this.contract.config.id;
                data.kid = _cloneDeep(this.kid);

                if (this.contract.type !== 'recurrent') {
                    data.contract.plannings = [];
                }

                useApi()
                    .legacy.post(
                        route('nurseries.contracts.simulate', {
                            nurseries: this.nursery.id,
                        }),
                        data
                    )
                    .then(response => {
                        if (!isEmpty(response.data)) {
                            this.setEstimationResult(response.data);
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            getEstimationDebounced: _debounce(
                function () {
                    this.getEstimation();
                },
                500,
                {trailing: true, leading: false}
            ),

            generateInvoices() {
                this.creatingInvoices = true;
                EventBus.$emit('generate:invoices');
            },

            showCAF() {
                return this.$showCAF(this.nursery.country);
            },

            setEstimationResult(data) {
                this.responseConfig = data;

                // Invoices
                this.invoices = data.invoices;

                // Hourly Rate
                this.computedHourlyRate = data.price.computed_hourly_rate;
                this.computedMonthlyRate = data.price.computed_monthly_rate;

                // Pour la famille
                this.family.income_bracket = data.family.income_bracket;
                this.family.effort_rate = data.family.effort_rate;
                this.family.cmg = data.family.cmg;

                // Pour les périodes de fermetures & jours fériés
                this.holidays = data.nursery.holidays;
                this.closed_periods = data.nursery.closed_periods;
            },
        },
    };
</script>
