import {Arr, Model, type QueryBuilder} from '@meekohq/lumos';
import moment from 'moment';

import TicketPivot from '@/modules/activity/ticket/domain/TicketPivot';
import TicketTypeValue from '@/modules/activity/utils/TicketTypeValue';
import type RuleSet from '@/modules/calendar/utils/RuleSet/RuleSet';
import StaffModel from '@/modules/human-resources/models/StaffModel';

export default class TicketModel extends Model {
    public type = 'activity/tickets';

    public attributes: {
        id: string;
        account_id: string | undefined;
        parent_id: string | undefined;
        type: TicketTypeValue | undefined;
        name: string | null;
        description: string | null;
        expired_at: string | undefined;
        reporter_id: string | null;
        assignee_id: string | null;
        assigned_at: string | null;
        completed_by_id: string | null;
        completion_note: string | null;
        completed_at: string | null;
        remind_ruleset: RuleSet | null;
        remind_at: string | null;
        reminded_at: string | null;
        recurrence_ruleset: RuleSet | null;
        next_occurence_at: string | null;
        visible_on_team: boolean;
    } = {
        id: this.uuid(),
        account_id: undefined,
        parent_id: undefined,
        type: undefined,
        description: null,
        name: null,
        expired_at: undefined,
        reporter_id: null,
        assignee_id: null,
        assigned_at: null,
        completed_by_id: null,
        completion_note: null,
        completed_at: null,
        remind_ruleset: null,
        remind_at: null,
        reminded_at: null,
        recurrence_ruleset: null,
        next_occurence_at: null,
        visible_on_team: false,
    };

    get isLastOccurrence() {
        if (!this.exists) {
            return true;
        }

        if (this.attributes.parent_id) {
            return this.parent().value().lastOccurrence().value().getKey() === this.getKey();
        } else {
            return !this.lastOccurrence().value();
        }
    }

    get isCompleted() {
        return !!this.attributes.completed_at;
    }

    get isLate() {
        if (this.isCompleted || !this.attributes.expired_at) {
            return false;
        }

        return moment(this.attributes.expired_at).isBefore(moment());
    }

    public static tasksFilteredByResourcesScope(
        query: QueryBuilder<TicketModel>,
        resourceIds: string | string[],
        resourceType: string
    ) {
        query
            .whereHas(new TicketModel().ticketPivots(), pivot => {
                pivot.whereIn('resource_id', Arr.wrap(resourceIds)).where('resource_type', resourceType);
            })
            .where('type', TicketTypeValue.task);
    }

    occurrences() {
        return this.hasMany('occurrences', TicketModel, 'parent_id');
    }

    lastOccurrence() {
        return this.hasOne('lastOccurrence', TicketModel, 'parent_id').setQuery(query => {
            query.orderBy('expired_at', 'desc').limit(1);
        });
    }

    parent() {
        return this.belongsTo('parent', TicketModel, 'parent_id');
    }

    reporter() {
        return this.belongsTo('reporter', StaffModel, 'reporter_id');
    }

    assignee() {
        return this.belongsTo('assignee', StaffModel, 'assignee_id');
    }

    completedBy() {
        return this.belongsTo('completedBy', StaffModel, 'completed_by_id');
    }

    ticketPivots() {
        return this.hasMany('ticketPivots', TicketPivot, 'ticket_id');
    }
}
