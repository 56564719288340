<template>
    <MMenu v-if="balanceTypes.isNotEmpty()">
        <MMenuButton>
            <MButton icon-menu>
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-duotone fa-stopwatch" />
                </template>
                <template v-if="modelValue.length">
                    {{ __('hr_balance:filter.by_type') }}
                </template>
                <template v-else>
                    {{ __('hr_balance:filter.all') }}
                </template>
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem @click="toggleBalanceType()">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="!modelValue.length"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ __('hr_balance:filter.all') }}
            </MMenuItem>
            <MMenuDivider />
            <MMenuItem
                v-for="balanceType in balanceTypes.all()"
                :key="balanceType.attributes.id"
                :auto-close-popover="false"
                @click="toggleBalanceType(balanceType.attributes.id)"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="modelValue.find(type => type === balanceType.attributes.id)"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ balanceType.attributes.name }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        name: 'BalanceTypesFilter',
        props: {
            modelValue: {type: Array as PropType<string[]>, required: true},
            organizationsFilter: {type: Array as PropType<OrganizationModel[]>, required: true},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const balanceTypes = ref<Collection<BalanceTypeModel>>(collect());
            const organizationIds = computed(() => collect(props.organizationsFilter).pluck('id').all() as string[]);

            const fetchData = async function () {
                balanceTypes.value = await BalanceTypeModel.query()
                    .whereHas('organizations', query1 => {
                        query1.whereIn('id', organizationIds.value);
                    })
                    .get();
            };

            fetchData();

            function toggleBalanceType(balanceType: string | null = null) {
                // If select all => not filter by balance type so it's empty
                if (!balanceType) {
                    emit('update:modelValue', []);

                    return;
                }

                // If balance type already selected => remove type  else add type
                if (props.modelValue?.find(type => type === balanceType)) {
                    emit(
                        'update:modelValue',
                        props.modelValue?.filter(type => type !== balanceType)
                    );
                } else {
                    emit('update:modelValue', [...props.modelValue, balanceType]);
                }
            }

            watch(
                () => props.organizationsFilter,
                () => {
                    fetchData().then(() => {
                        balanceTypes.value.pluck('id');
                        emit(
                            'update:modelValue',
                            props.modelValue?.filter(type => balanceTypes.value.pluck('id').toArray().includes(type))
                        );
                    });
                }
            );

            return {
                balanceTypes,
                toggleBalanceType,
            };
        },
    });
</script>
