import {Model} from '@meekohq/lumos';

import CallPivot from '@/modules/activity/models/CallPivot';
import type CallDirectionValue from '@/modules/activity/utils/CallDirectionValue';
import type CallOutcomeValue from '@/modules/activity/utils/CallOutcomeValue';
import StaffModel from '@/modules/human-resources/models/StaffModel';

export default class CallModel extends Model {
    public type = 'activity/calls';

    public attributes: {
        id: string;
        account_id: string | undefined;
        staff_id: string | null;
        started_at: string | undefined;
        duration: number | null;
        direction: CallDirectionValue | undefined;
        outcome: CallOutcomeValue | undefined;
        description: string | null;
        phone: string | null;
    } = {
        id: this.uuid(),
        account_id: undefined,
        staff_id: null,
        started_at: undefined,
        duration: null,
        direction: undefined,
        outcome: undefined,
        description: null,
        phone: null,
    };

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }

    callPivots() {
        return this.hasMany('callPivots', CallPivot, 'call_id');
    }
}
