<script setup lang="ts">
    import {computed} from 'vue';

    interface Props {
        variant?: string;
        size?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        variant: 'gray',
        size: 'base',
    });

    const variantValues = {
        primary: 'MBadge--primary',
        success: 'MBadge--success',
        danger: 'MBadge--danger',
        warning: 'MBadge--warning',
        gray: 'MBadge--gray',
        black: 'MBadge--black',
        cyan: 'MBadge--cyan',
        blue: 'MBadge--blue',
        purple: 'MBadge--purple',
        pink: 'MBadge--pink',
        red: 'MBadge--red',
        orange: 'MBadge--orange',
        yellow: 'MBadge--yellow',
        green: 'MBadge--green',
    };

    const sizeValues = {
        sm: 'MBadge--small',
    };

    const baseClass = computed(() => {
        const output = [] as string[];

        output.push(sizeValues[props.size] ?? undefined);
        output.push(variantValues[props.variant] ?? undefined);

        return output;
    });
</script>

<template>
    <div
        class="MBadge"
        :class="baseClass"
    >
        <slot />
    </div>
</template>

<style scoped>
    .MBadge {
        @apply tw-inline-flex tw-items-baseline tw-justify-center tw-gap-x-1.5 tw-px-2 tw-py-1;
        @apply tw-rounded-md;
        @apply tw-font-display tw-text-sm tw-font-medium;
    }

    .MBadge--small {
        @apply tw-px-1.5 tw-py-0.5 tw-text-sm;
    }

    .MBadge--blue,
    .MBadge--primary {
        @apply tw-bg-blue-100 tw-text-blue-800;
    }

    .MBadge--red,
    .MBadge--danger {
        @apply tw-bg-red-200 tw-text-red-800;
    }

    .MBadge--orange,
    .MBadge--warning {
        @apply tw-bg-orange-200 tw-text-orange-800;
    }

    .MBadge--green,
    .MBadge--success {
        @apply tw-bg-green-300 tw-text-green-800;
    }

    .MBadge--black {
        @apply tw-bg-gray-700 tw-text-gray-200;
    }

    .MBadge--gray {
        @apply tw-bg-gray-200 tw-text-gray-700;
    }

    .MBadge--cyan {
        @apply tw-bg-cyan-200 tw-text-cyan-950;
    }

    .MBadge--purple {
        @apply tw-bg-purple-200 tw-text-purple-800;
    }

    .MBadge--pink {
        @apply tw-bg-pink-200 tw-text-pink-800;
    }

    .MBadge--yellow {
        @apply tw-bg-yellow-300 tw-text-yellow-900;
    }
</style>
