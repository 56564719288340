<script setup lang="ts">
    import {useElementHover} from '@vueuse/core';
    import {inject, ref, watch} from 'vue';

    import type {MCalendarMonthsItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarMonths';
    import {keyMCalendarMonths} from '@/modules/meeko-ui/components/MCalendar/useMCalendarMonths';

    const props = defineProps<{
        month: MCalendarMonthsItem;
    }>();

    const monthsState = inject(keyMCalendarMonths);
    if (!monthsState) {
        throw new Error('keyMCalendarMonths must be provided');
    }

    const monthElement = ref();
    const isHovered = useElementHover(monthElement);

    const {hoveredMonth, handleMonthClick} = monthsState;

    watch(isHovered, newValue => {
        if (newValue) {
            hoveredMonth.value = props.month.month;
        }
    });
</script>

<template>
    <button
        ref="monthElement"
        class="MCalendarMonthsItem"
        :class="{
            'MCalendarMonthsItem--selecting': month.isSelecting,
            'MCalendarMonthsItem--in-period': month.isInPeriod,
            'MCalendarMonthsItem--hovered': isHovered,
            'MCalendarMonthsItem--selected': month.isSelected,
        }"
        @click="handleMonthClick(month.month)"
    >
        {{ month.month.toLocaleString({month: 'long'}) }}
    </button>
</template>

<style scoped>
    .MCalendarMonthsItem {
        @apply tw-cursor-pointer tw-select-none tw-rounded tw-p-2 tw-font-display;
        @apply tw-transition-all hover:tw-bg-gray-200;
        @apply tw-text-center tw-capitalize tw-text-gray-800;
    }

    .MCalendarMonthsItem--hovered {
        @apply tw-bg-gray-200;
    }

    .MCalendarMonthsItem--in-period {
        &:not(.MCalendarMonthsItem--selecting) {
            @apply tw-bg-primary-200 tw-text-primary-900;
        }

        &.MCalendarMonthsItem--selecting {
            @apply tw-bg-gray-200;
        }
    }

    .MCalendarMonthsItem--selected {
        background-color: theme(backgroundColor.primary.500) !important;
        color: theme(textColor.white) !important;

        &.MCalendarMonthsItem--hovered {
            background-color: theme(backgroundColor.primary.600) !important;
        }
    }
</style>
