<template>
    <section class="MagicIndex">
        <slot name="filters-container">
            <MagicIndexFilters
                v-if="$slots.filters || $slots['more-filters']"
                :filter-count="filterCount"
                :loading="filtersLoading"
                :variant="variant"
                @reset="$emit('reset-filters')"
            >
                <template
                    v-if="$slots['filters']"
                    #filters
                >
                    <slot name="filters" />
                </template>
                <template
                    v-if="$slots['more-filters']"
                    #more-filters
                >
                    <slot name="more-filters" />
                </template>
            </MagicIndexFilters>
        </slot>
        <MBox :class="containerClass">
            <slot name="mass-select">
                <MagicIndexMassSelect
                    v-if="withMassSelect && massSelect.isEnabled.value"
                    :mass-select="massSelect"
                    @delete="$emit('delete', massSelect)"
                >
                    <template #actions>
                        <slot
                            :mass-select="massSelect"
                            name="mass-select-actions"
                        />
                    </template>
                </MagicIndexMassSelect>
            </slot>
            <div
                class="MagicIndex__header"
                :class="{
                    'MagicIndex__header--show-on-mobile': showHeaderOnMobile,
                }"
            >
                <slot
                    v-if="$slots['header']"
                    :has-results="paginator && paginator.items().count() > 0"
                    :loading="loading"
                    :mass-select="massSelect"
                    name="header"
                    :paginator="paginator"
                />
            </div>
            <slot
                :has-results="paginator && paginator.items().count() > 0"
                :loading="loading"
                name="content"
                :paginator="paginator"
            >
                <div class="MagicIndex__content">
                    <MagicIndexTransition>
                        <MagicIndexLoader
                            v-if="loading"
                            key="loader"
                        />
                        <ul
                            v-else-if="paginator && paginator.items().count() > 0"
                            class="MagicIndex__list"
                        >
                            <template
                                v-for="(item, index) in paginator.items()"
                                :key="keyFunction ? keyFunction(item) : index"
                            >
                                <MagicIndexItem
                                    :item="item"
                                    :mass-select="massSelect"
                                    :router-link-fn="routerLinkFn"
                                    :with-mass-select="withMassSelect"
                                >
                                    <template #item="{item}">
                                        <slot
                                            :item="item"
                                            :mass-select="massSelect"
                                            name="item"
                                        />
                                    </template>
                                </MagicIndexItem>
                            </template>
                        </ul>
                        <MagicIndexEmptyList v-else>
                            <template #empty-list>
                                <slot name="empty-list" />
                            </template>
                            <template #empty-list-action>
                                <slot name="empty-list-action" />
                            </template>
                        </MagicIndexEmptyList>
                    </MagicIndexTransition>
                    <MagicIndexPaginator
                        v-if="paginator && !loading"
                        :paginator="paginator"
                        @change="$emit('change', $event)"
                    />
                </div>
            </slot>
        </MBox>
    </section>
</template>

<script lang="ts">
    import type {LengthAwarePaginator} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {ref, toRef, watch} from 'vue';

    import useMagicMassSelect from '@/modules/app/composables/useMagicMassSelect';
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';
    import MagicIndexItem from '@/modules/magic-index/components/atoms/MagicIndexItem.vue';
    import MagicIndexLoader from '@/modules/magic-index/components/atoms/MagicIndexLoader.vue';
    import MagicIndexTransition from '@/modules/magic-index/components/atoms/MagicIndexTransition.vue';
    import MagicIndexPaginator from '@/modules/magic-index/components/molecules/MagicIndexPaginator.vue';
    import MagicIndexFilters from '@/modules/magic-index/components/organisms/MagicIndexFilters.vue';
    import MagicIndexMassSelect from '@/modules/magic-index/components/organisms/MagicIndexMassSelect.vue';

    export default {
        components: {
            MagicIndexItem,
            MagicIndexEmptyList,
            MagicIndexFilters,
            MagicIndexLoader,
            MagicIndexMassSelect,
            MagicIndexPaginator,
            MagicIndexTransition,
        },
        props: {
            loading: {type: Boolean, default: true},
            filtersLoading: {
                type: Boolean,
                default: false,
            },
            paginator: {
                type: Object as PropType<LengthAwarePaginator<any>>,
                default: undefined,
                required: false,
            },
            routerLinkFn: {type: Function, default: undefined, required: false},
            variant: {type: String, default: 'base'},
            containerClass: {type: String, default: ''},
            filterCount: {type: [String, Number], default: 0, required: false},
            withMassSelect: {type: Boolean, default: false, required: false},
            showHeaderOnMobile: {type: Boolean, default: false},
            keyFunction: {type: Function as PropType<(item: unknown) => unknown>, default: undefined, required: false},
        },
        emits: ['reset-filters', 'change', 'delete'],
        setup(props) {
            const showMore = ref(false);

            // Init mass select
            const massSelect = useMagicMassSelect(toRef(props, 'paginator'));
            // Reset mass select on loading, mass select integrity is not guaranteed
            watch(
                () => props.loading,
                v => {
                    if (v) {
                        massSelect.reset();
                    }
                }
            );

            return {
                massSelect,
                showMore,
            };
        },
    };
</script>

<style scoped>
    .MagicIndex {
        container-name: MagicIndex;
        container-type: inline-size;
    }

    .MagicIndex__content {
        @apply tw-flex tw-w-full tw-flex-col;
    }

    .MagicIndex__list {
        @apply tw-flex tw-w-full tw-flex-col;
    }

    .MagicIndex__header {
        @apply tw-flex tw-flex-col tw-gap-3 tw-rounded-t-inherit tw-border-b tw-border-gray-300 tw-bg-[#f6faff] tw-px-4 tw-py-3;
        @apply tw-hidden;

        /*C'est 50rem au lieu de 48rem pour parer son propre padding, sinon ca break pas au bon moment*/
        @container MagicIndex (min-width: 50rem) {
            @apply tw-block;
        }
    }

    .MagicIndex__header--show-on-mobile {
        @apply tw-block;
    }
</style>
