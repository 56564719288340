import app from '@/config/app';

function getUrl() {
    return `${app.api_base_url}/js/route.js`;
}

export function loadToDom(): Promise<unknown> {
    return new Promise(resolve => {
        // Prepare route.js script
        const script = document.createElement('script');
        script.src = getUrl();

        // Wait for route.js loaded
        script.addEventListener('load', resolve);

        // If route.js failed to load, load Vue anyway
        script.addEventListener('error', resolve);

        // Dynamically inject route.js script
        document.head.appendChild(script);
    });
}

export async function loadToGlobal(): Promise<void> {
    const response = await fetch(getUrl());
    const body = await response.text();

    // Transform js string object to real object
    const str = body
        .replace('var Ziggy = ', '')
        .replace('};', '}')
        .replace(/'/g, '"')
        .replace('namedRoutes', '"namedRoutes"')
        .replace('baseUrl', '"baseUrl"')
        .replace('baseProtocol', '"baseProtocol"')
        .replace('baseDomain', '"baseDomain"')
        .replace('basePort', '"basePort"')
        .replace('defaultParameters', '"defaultParameters"');

    global.Ziggy = JSON.parse(str);
}
