import {app} from '@meekohq/lumos';
import type {MaybeRef, MaybeRefOrGetter} from '@vueuse/core';
import {toValue} from '@vueuse/core';
import i18next from 'i18next';

import useManager from '@/modules/app/composables/useManager';
import type {NumericFormatterContractOptions} from '@/modules/core/infrastructure/NumericFormatterContract';
import {NumericFormatterContractBinding} from '@/modules/core/infrastructure/NumericFormatterContract';
import type {NumericParserContractOptions} from '@/modules/core/infrastructure/NumericParserContract';
import {NumericParserContractBinding} from '@/modules/core/infrastructure/NumericParserContract';
import {getNumericParserContractLocaleOptions} from '@/modules/core/infrastructure/NumericParserContractPresets';

export interface useFormatCurrencyFormatOptions {
    minFractionDigits?: number;
    maxFractionDigits?: number;
}

/**
 * The purpose of this composable is to get a specific number format from an amount. It can handle currency and country.
 * Example :
 * const {format} = useFormatCurrency('EUR', 'FR')
 * console.log(format(1125.5))  // 1 125,5 €
 */
export default function useFormatCurrency(
    currencyRef?: MaybeRefOrGetter<string | undefined>,
    countryCodeRef?: MaybeRef<string>
) {
    // Unwrap possible ref
    const currency = toValue(currencyRef);

    let countryCode = toValue(countryCodeRef) ?? i18next.language;

    const activeOrganization = useManager().activeOrganization;

    countryCode = countryCode ?? activeOrganization.value?.attributes.address.country_code;

    /**
     * Function wich handle the format of an amount.
     * You can change the format of an amount with options and locale parameter.
     *
     * Example :
     * const {format} = useFormatCurrency('FR', 'EUR')
     * format(1250.5) => 1.250,5 EUR
     */
    function format(amount: string | number, options?: useFormatCurrencyFormatOptions): string {
        let value: number;

        const formatterOptions: NumericFormatterContractOptions = {
            locale: countryCode,
        };

        if (!options?.maxFractionDigits && !options?.minFractionDigits) {
            formatterOptions.minimumFractionDigits = 2;
            formatterOptions.maximumFractionDigits = 2;
        } else {
            formatterOptions.minimumFractionDigits = options?.minFractionDigits ?? 0;
            formatterOptions.maximumFractionDigits = options?.maxFractionDigits ?? 8;
        }

        if (typeof amount === 'string') {
            const parserOptions: NumericParserContractOptions = {
                localeOptions: getNumericParserContractLocaleOptions(countryCode),
                fractionalOptions: {
                    mode: 'round',
                    minFractionalDigits: formatterOptions.minimumFractionDigits ?? 0,
                    maxFractionalDigits: formatterOptions.maximumFractionDigits ?? 8,
                },
                allowNegative: false,
            };

            value = app(NumericParserContractBinding, parserOptions).parse(amount).asNumber();
        } else {
            value = amount;
        }

        const numericFormatter = app(NumericFormatterContractBinding, formatterOptions);

        return numericFormatter.formatCurrency(value, currency ?? 'EUR');
    }

    function getSymbol(): string {
        return app(NumericFormatterContractBinding, {locale: countryCode}).getCurrencySymbol(currency ?? 'EUR');
    }

    return {
        format,
        getSymbol,
    };
}
