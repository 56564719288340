<template>
    <div>
        <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2">
            <MButton
                class="tw-mr-2"
                :icon-external-link="true"
                size="none"
                variant="link"
                @click="showArticle(1727881)"
            >
                {{ __('common:show_guide') }}
            </MButton>
            <MTooltip
                v-if="otherNurseriesSepas.length"
                :label="__('common:import_from_another_organization')"
            >
                <MButton @click="importSepa">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-download" />
                    </template>
                    {{ __('common:actions.import') }}
                </MButton>
            </MTooltip>
            <MButton
                icon-plus
                :label="__('billing_setting:add_sepa')"
                variant="primary"
                @click="sepalModal.show"
            />
            <SepaModal
                v-if="sepalModal.isVisible.value"
                :format="format"
                :loading="loading"
                :modal="sepalModal"
                :new-sepa="newSepa"
                :nursery="nursery"
                :sepas="sepas"
                @add-sepa="addSepa"
            />
        </div>

        <MEmptyIndex
            v-if="!filteredSepas.length"
            class="tw-mt-4"
            :title="__('billing_setting:you_do_not_have_sepa')"
        />
        <div
            v-else
            class="tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 lg:tw-grid-cols-3"
        >
            <MBox
                v-for="(sepa, i) in filteredSepas"
                :key="'sepa ' + i"
                class="tw-p-4"
            >
                <MForm>
                    <div v-if="sepa.editing">
                        <MFieldset>
                            <MLabel>{{ __('billing_setting:creditor_name') }}</MLabel>
                            <MInput
                                id="name"
                                v-model="sepa.name"
                                :placeholder="__('billing_setting:sepa_creditor_name')"
                                type="text"
                            />
                        </MFieldset>
                    </div>
                    <MHeading
                        v-else
                        class="tw-truncate"
                    >
                        {{ sepa.name }}
                    </MHeading>
                    <MFieldset>
                        <MLabel>{{ __('common:iban') }}</MLabel>
                        <MInput
                            v-if="sepa.editing"
                            v-model.trim="sepa.iban"
                            :formatter="format"
                            :placeholder="__('common:iban_example')"
                            type="text"
                        />
                        <CBadge v-else>
                            {{ sepa.iban }}
                        </CBadge>
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('common:bic') }}</MLabel>
                        <MInput
                            v-if="sepa.editing"
                            v-model="sepa.bic"
                            :placeholder="__('billing_setting:bic_example')"
                            type="text"
                        />
                        <CBadge v-else>
                            {{ sepa.bic }}
                        </CBadge>
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('billing_setting:creditor_identifier') }}</MLabel>
                        <MInput
                            v-if="sepa.editing"
                            v-model="sepa.ics"
                            :placeholder="__('billing_setting:rum_ics_example')"
                            type="text"
                        />
                        <CBadge v-else>
                            {{ sepa.ics }}
                        </CBadge>
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('billing_setting:payment_delay') }}</MLabel>
                        <CInputGroup v-if="sepa.editing">
                            <MInput
                                v-model.number="sepa.payment_delay"
                                placeholder="5"
                                type="number"
                            />
                            <CInputAddon>
                                {{ __('common:day', {count: sepa.payment_delay}) }}
                            </CInputAddon>
                        </CInputGroup>
                        <CBadge v-else>
                            {{ __('common:day_with_count', {count: sepa.payment_delay}) }}
                        </CBadge>
                    </MFieldset>
                </MForm>
                <div class="tw-mt-4 tw-flex tw-flex-row tw-items-center">
                    <MTooltip :label="__('common:actions.delete')">
                        <MButton
                            variant="danger"
                            @click="removeSepa(sepa)"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </MButton>
                    </MTooltip>
                    <MTooltip
                        class="tw-ml-2"
                        :label="__('common:remove_from_this_organization')"
                    >
                        <MButton @click="detachSepa(sepa)">
                            <FontAwesomeIcon icon="fa-solid fa-unlink" />
                        </MButton>
                    </MTooltip>
                    <div
                        v-if="sepa.editing"
                        class="tw-ml-auto"
                    >
                        <MTooltip :label="__('common:actions.cancel')">
                            <MButton
                                variant="light"
                                @click="cancelEditSepa(sepa)"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-times" />
                            </MButton>
                        </MTooltip>
                        <MTooltip :label="__('common:actions.save')">
                            <MButton
                                class="tw-ml-2"
                                variant="primary"
                                @click="updateSepa(sepa)"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-save" />
                            </MButton>
                        </MTooltip>
                    </div>
                    <MButton
                        v-else
                        class="tw-ml-auto"
                        variant="primary"
                        @click="editSepa(sepa)"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-pencil" />
                        </template>
                        {{ __('common:actions.update') }}
                    </MButton>
                </div>
            </MBox>
        </div>
    </div>
</template>

<script>
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _orderBy from 'lodash-es/orderBy';

    import useApi from '@/modules/app/composables/useApi';
    import useIntercom from '@/modules/app/composables/useIntercom';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import SepaModal from '@/pages/nurseries/[id]/billing/settings/SepaModal.vue';

    export default {
        components: {
            SepaModal,
            MEmptyIndex,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            sepas: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                nurserySepas: [],
                otherNurseriesSepas: [],
                newSepa: {},
                loading: false,
            };
        },
        computed: {
            filteredSepas() {
                const sepas = _orderBy(this.nurserySepas, 'name');

                return sepas.map(sepa => {
                    sepa.payment_delay = isFinite(parseInt(sepa.payment_delay)) ? parseInt(sepa.payment_delay) : 0;

                    return sepa;
                });
            },

            sepalModal() {
                return useModal();
            },
        },
        mounted() {
            this.getSepas();
        },
        methods: {
            showArticle: useIntercom().showArticle,
            getSepas() {
                this.nurserySepas = [];
                this.otherNurseriesSepas = [];

                if (this.sepas) {
                    this.sepas.forEach(sepa => {
                        set(sepa, 'editing', false);
                        const nurseryHasSepa = this.nursery.sepaMandates.find(item => item.id === sepa.id);
                        if (nurseryHasSepa) {
                            this.nurserySepas.push(sepa);
                        } else {
                            this.otherNurseriesSepas.push(sepa);
                        }
                    });
                }
            },

            addSepa() {
                this.loading = true;
                useApi()
                    .legacy.post(route('sepas.store'), this.newSepa)
                    .then(response => {
                        this.loading = false;
                        const obj = response.data;
                        obj.editing = false;
                        this.sepas.push(obj);
                        this.attachSepa(obj.id);
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            updateSepa(sepa) {
                useApi()
                    .legacy.put(route('sepas.update', {sepa: sepa.id}), sepa)
                    .then(() => {
                        const sepaToUpdate = this.sepas.find(item => item.id === sepa.id);
                        if (sepaToUpdate) {
                            const index = this.sepas.indexOf(sepaToUpdate);
                            set(this.sepas, index, sepa);
                        }
                        sepa.editing = false;
                        useNotification().success(__('billing_setting:sepa_updated_successfully'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            attachSepa(sepaID) {
                useApi()
                    .legacy.post(
                        route('nurseries.sepas.attach', {
                            nurseries: this.nursery.id,
                            sepa: sepaID,
                        })
                    )
                    .then(response => {
                        this.nurserySepas.push(response.data);
                        this.nursery.sepaMandates.push(response.data);
                        const sepaToRemove = this.otherNurseriesSepas.find(item => item.id === response.data.id);
                        if (sepaToRemove) {
                            const index = this.otherNurseriesSepas.indexOf(sepaToRemove);
                            this.otherNurseriesSepas.splice(index, 1);
                        }

                        this.newSepa = {
                            name: null,
                            iban: null,
                            bic: null,
                            ics: null,
                            payment_delay: null,
                        };
                        this.sepalModal.hide();

                        useNotification().success(__('billing_setting:sepa_added_successfully'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            detachSepa(sepa) {
                useMagicModal().confirmationModal({
                    title: __('billing_setting:detach_sepa_confirmation'),
                    text: __('billing_setting:sepa_will_no_longer_visible_on_this_organization'),
                    confirmButtonText: __('common:actions.detach'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.post(
                                route('nurseries.sepas.detach', {
                                    nurseries: this.nursery.id,
                                    sepa: sepa.id,
                                })
                            )
                            .then(response => {
                                const sepaToRemove = this.nurserySepas.find(item => item.id === response.data.id);
                                if (sepaToRemove) {
                                    const index = this.nurserySepas.indexOf(sepaToRemove);
                                    this.nurserySepas.splice(index, 1);
                                }

                                const sepaToRemoveFromNursery = this.nursery.sepaMandates.find(
                                    item => item.id === response.data.id
                                );
                                if (sepaToRemoveFromNursery) {
                                    const index = this.nursery.sepaMandates.indexOf(sepaToRemoveFromNursery);
                                    this.nursery.sepaMandates.splice(index, 1);
                                }

                                this.otherNurseriesSepas.push(response.data);
                                useNotification().success(__('billing_setting:sepa_removed_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            removeSepa(sepa) {
                useMagicModal().deleteConfirmationModal({
                    title: __('billing_setting:delete_sepa_confirmation'),
                    text: __('billing_setting:sepa_will_be_deleted_on_all_organizations'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('sepas.destroy', {
                                    sepa: sepa.id,
                                })
                            )
                            .then(() => {
                                const sepaToDelete = this.nurserySepas.find(item => item.id === sepa.id);
                                if (sepaToDelete) {
                                    const index = this.nurserySepas.indexOf(sepaToDelete);
                                    this.nurserySepas.splice(index, 1);
                                }

                                const sepaToRemoveFromNursery = this.nursery.sepaMandates.find(
                                    item => item.id === sepa.id
                                );
                                if (sepaToRemoveFromNursery) {
                                    const index = this.nursery.sepaMandates.indexOf(sepaToRemoveFromNursery);
                                    this.nursery.sepaMandates.splice(index, 1);
                                }

                                const sepaToDeleteFromGlobal = this.sepas.find(item => item.id === sepa.id);
                                if (sepaToDeleteFromGlobal) {
                                    const index = this.sepas.indexOf(sepaToDeleteFromGlobal);
                                    this.sepas.splice(index, 1);
                                }

                                useNotification().success(__('billing_setting:sepa_deleted_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            importSepa() {
                const options = this.otherNurseriesSepas.reduce((acc, item) => {
                    if (item.id) acc.push({value: item.id, text: item.name});
                    return acc;
                }, []);

                useMagicModal().confirmationWithSelectModal({
                    title: __('billing_setting:use_sepa_of_another_organization'),
                    confirmButtonText: __('common:actions.validate'),
                    options: options,
                    defaultOption: options[0].value,
                    onConfirm: async result => {
                        if (result) {
                            await this.attachSepa(result);
                        }
                    },
                });
            },

            cancelEditSepa(sepa) {
                const originalSepa = this.sepas.find(item => item.id === sepa.id);
                if (originalSepa) {
                    set(originalSepa, 'editing', false);
                    const sepaToUpdate = this.nurserySepas.find(item => item.id === sepa.id);
                    if (sepaToUpdate) {
                        const index = this.nurserySepas.indexOf(sepaToUpdate);
                        set(this.nurserySepas, index, _cloneDeep(originalSepa));
                    }
                }
            },

            format(value) {
                return value ? value.toUpperCase().trim() : null;
            },

            editSepa(sepa) {
                set(sepa, 'editing', true);
            },
        },
    };
</script>
