<template>
    <div class="tw-flex tw-min-h-screen tw-items-center tw-bg-black">
        <div class="tw-w-full">
            <div class="tw-mx-auto tw-w-full tw-px-4">
                <div class="row">
                    <div
                        class="col-12 tw-mb-12 tw-ml-auto tw-mr-auto tw-mt-12"
                        style="max-width: 430px"
                    >
                        <code
                            v-for="(line, index) in lines"
                            :key="'line' + index"
                            :class="{error: line.includes('error')}"
                        >
                            > {{ line }}
                            <br />
                        </code>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            lines: [],
        }),
        computed: {
            token() {
                return this.$route.params.token;
            },
        },
        mounted() {
            this.lines.push('Saving token to localstorage...');
            window.localStorage.setItem('support-token', this.token);
            this.lines.push('Done.');
        },
    };
</script>

<style scoped>
    code {
        color: theme(textColor.pink.500);
        @apply tw-break-words;

        &.error {
            color: #ffc000;
        }
    }
</style>
