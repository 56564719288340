import {createRouter, createWebHistory} from 'vue-router';

import useAbility from '@/modules/app/composables/useAbility';

const {can, allows} = useAbility();

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/about-tech-team',
            name: 'about-tech-team',
            component: () => import('@/pages/about-tech-team.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/login.vue'),
            meta: {requiresGuest: true},
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/pages/register.vue'),
            meta: {requiresGuest: true},
        },
        {
            path: '/password/reset',
            name: 'password.email',
            component: () => import('@/pages/password/reset/[token]/[email].vue'),
            meta: {requiresGuest: true},
        },
        {
            path: '/password/reset/:token/:email',
            name: 'password.reset',
            component: () => import('@/pages/password/reset.vue'),
            meta: {requiresGuest: true},
        },
        {
            path: '/support/login/:email',
            name: 'support-login',
            component: () => import('@/pages/support/login/[email].vue'),
        },
        {
            path: '/support/installer/:token',
            name: 'support-installer',
            component: () => import('@/pages/support/installer/[token].vue'),
        },
        {
            path: '/maintenance',
            name: 'maintenance',
            component: () => import('@/pages/maintenance.vue'),
        },
        {
            path: '/validate/email-verified',
            name: 'validate.email-verified',
            component: () => import('@/pages/validate/email-verified.vue'),
        },
        {
            path: '/validate/email-verified-failure',
            name: 'validate.email-verified-failure',
            component: () => import('@/pages/validate/email-verified-failure.vue'),
        },
        {
            path: '/',
            name: 'dashboard',
            component: () => import('@/pages/index.vue'),
            meta: {
                requiresAuth: true,
                requiresSubscribe: true,
            },
            children: [
                {
                    path: 'nurseries',
                    name: 'nurseries',
                    component: () => import('@/pages/nurseries.vue'),
                },
                {
                    path: 'nurseries/:nursery',
                    redirect: to => ({name: 'nursery', params: to.params}),
                    children: [
                        // *** Redirections ***
                        {
                            path: 'kids/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/kids', '/family/kids'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'familyMembers/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/familyMembers', '/family/members'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'families/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/families', '/family/families'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'staffs/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/staffs', '/hr/staffs'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'humanresources/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/humanresources', '/hr'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'hr/reports/register',
                            redirect: to => ({
                                path: to.path.replace('/hr/reports/register', '/hr/reports/registry'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'cashier/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/cashier', '/billing'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'billings/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/billings', '/billing'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'billings/customers/payments/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace(
                                    '/billings/customers/payments',
                                    '/billing/customers/transactions'
                                ),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'billings/payments/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/billings/payments', '/billing/transactions'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'settings/billings/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/settings/billings', '/billing/settings'),
                                query: to.query,
                            }),
                        },
                        {
                            path: 'settings/plannings/:any(.*)*',
                            redirect: to => ({
                                path: to.path.replace('/settings/plannings', '/planning/settings'),
                                query: to.query,
                            }),
                        },
                        // *** End Redirections ***
                        {
                            path: 'unauthorized',
                            name: 'unauthorized',
                        },
                        {
                            path: 'dashboard',
                            name: 'dashboard.show',
                            component: () => import('@/pages/nurseries/[id]/dashboard.vue'),
                            meta: {
                                access() {
                                    return allows('access-to-dashboard-module');
                                },
                            },
                        },
                        {
                            path: 'planning',
                            name: 'module.planning',
                            redirect: to => {
                                if (can('read', 'kids_planning')) {
                                    return {name: 'nursery', params: to.params};
                                }

                                if (can('read', 'staffs_planning')) {
                                    return {name: 'planning.staffs', params: to.params};
                                }

                                return {name: 'nursery', params: to.params};
                            },
                            meta: {
                                access() {
                                    return can('read', 'kids_planning') || can('read', 'staffs_planning');
                                },
                            },
                            children: [
                                {
                                    path: 'kids',
                                    name: 'nursery',
                                    meta: {
                                        access() {
                                            return allows('access-to-plannings-module') && can('read', 'kids_planning');
                                        },
                                    },
                                    redirect: to => {
                                        const latestKidsPlanning = window.localStorage.getItem('latestKidsPlanning');
                                        switch (latestKidsPlanning) {
                                            case 'daily':
                                                return {
                                                    name: 'planning.kids',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                            case 'weekly':
                                                return {
                                                    name: 'planning.kids.weekly',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                            case 'monthly':
                                                return {
                                                    name: 'planning.kids.monthly',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                            case 'yearly':
                                                return {
                                                    name: 'planning.kids.yearly',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                            default:
                                                return {
                                                    name: 'planning.kids',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                        }
                                    },
                                    children: [
                                        {
                                            path: 'daily',
                                            name: 'planning.kids',
                                            component: () => import('@/pages/nurseries/[id]/planning/kids/daily.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-plannings-module') &&
                                                        can('read', 'kids_planning')
                                                    );
                                                },
                                            },
                                            beforeEnter(to, from, next) {
                                                window.localStorage.setItem('latestKidsPlanning', 'daily');
                                                next();
                                            },
                                        },
                                        {
                                            path: 'weekly',
                                            name: 'planning.kids.weekly',
                                            component: () => import('@/pages/nurseries/[id]/planning/kids/weekly.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-plannings-module') &&
                                                        can('read', 'kids_planning')
                                                    );
                                                },
                                            },
                                            beforeEnter(to, from, next) {
                                                window.localStorage.setItem('latestKidsPlanning', 'weekly');
                                                next();
                                            },
                                        },
                                        {
                                            path: 'monthly/:date?',
                                            name: 'planning.kids.monthly',
                                            component: () => import('@/pages/nurseries/[id]/planning/kids/monthly.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-plannings-module') &&
                                                        can('read', 'kids_planning')
                                                    );
                                                },
                                            },
                                            beforeEnter(to, from, next) {
                                                window.localStorage.setItem('latestKidsPlanning', 'monthly');
                                                next();
                                            },
                                        },
                                        {
                                            path: 'yearly/:date?',
                                            name: 'planning.kids.yearly',
                                            component: () => import('@/pages/nurseries/[id]/planning/kids/yearly.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-plannings-module') &&
                                                        can('read', 'kids_planning')
                                                    );
                                                },
                                            },
                                            beforeEnter(to, from, next) {
                                                window.localStorage.setItem('latestKidsPlanning', 'yearly');
                                                next();
                                            },
                                        },
                                    ],
                                },
                                {
                                    path: 'staffs',
                                    name: 'planning.staffs',
                                    meta: {
                                        access() {
                                            return (
                                                allows('access-to-plannings-module') && can('read', 'staffs_planning')
                                            );
                                        },
                                    },
                                    redirect: to => {
                                        const latestStaffsPlanning =
                                            window.localStorage.getItem('latestStaffsPlanning');

                                        switch (latestStaffsPlanning) {
                                            case 'daily':
                                                return {
                                                    name: 'planning.staffs.daily',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                            case 'weekly':
                                                return {
                                                    name: 'planning.staffs.weekly',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                            default:
                                                return {
                                                    name: 'planning.staffs.daily',
                                                    params: to.params,
                                                    query: to.query,
                                                };
                                        }
                                    },
                                    children: [
                                        {
                                            path: 'daily',
                                            name: 'planning.staffs.daily',
                                            component: () => import('@/pages/nurseries/[id]/planning/staffs/daily.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-plannings-module') &&
                                                        can('read', 'staffs_planning')
                                                    );
                                                },
                                            },
                                            beforeEnter(to, from, next) {
                                                window.localStorage.setItem('latestStaffsPlanning', 'daily');
                                                next();
                                            },
                                        },
                                        {
                                            path: 'weekly',
                                            name: 'planning.staffs.weekly',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/planning/staffs/weekly.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-plannings-module') &&
                                                        can('read', 'staffs_planning')
                                                    );
                                                },
                                            },
                                            beforeEnter(to, from, next) {
                                                window.localStorage.setItem('latestStaffsPlanning', 'weekly');
                                                next();
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'family',
                            name: 'module.family',
                            redirect: to => {
                                if (allows('access-to-kids-module')) {
                                    return {name: 'kids.index', params: to.params};
                                }

                                if (allows('access-to-family-members-module')) {
                                    return {name: 'familyMembers.index', params: to.params};
                                }

                                return {name: 'kids.index', params: to.params};
                            },
                            meta: {
                                access() {
                                    return allows('access-to-kids-module') || allows('access-to-family-members-module');
                                },
                            },
                            children: [
                                {
                                    path: 'kids',
                                    children: [
                                        {
                                            path: '',
                                            name: 'kids.index',
                                            component: () => import('@/pages/nurseries/[id]/family/kids/index.vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-kids-module');
                                                },
                                            },
                                        },
                                        {
                                            path: ':kid',
                                            name: 'kids.show',
                                            component: () => import('@/pages/nurseries/[id]/family/kids/[id].vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-kids-module');
                                                },
                                                redirectOnSwap: 'kids.index',
                                            },
                                            children: [
                                                {
                                                    path: 'documents/create',
                                                    name: 'kids.show.documents.create',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/family/kids/[id]/documents/create.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return (
                                                                inherited &&
                                                                can('read', 'kids_details') &&
                                                                can('create', 'kids_documents')
                                                            );
                                                        },
                                                        redirectOnSwap: 'kids.index',
                                                    },
                                                },
                                                {
                                                    path: 'documents/:document/edit',
                                                    name: 'kids.show.documents.edit',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/family/kids/[id]/documents/[id]/edit.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return (
                                                                inherited &&
                                                                can('read', 'kids_details') &&
                                                                can('update', 'kids_documents')
                                                            );
                                                        },
                                                        redirectOnSwap: 'kids.index',
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'members',
                                    children: [
                                        {
                                            path: '',
                                            name: 'familyMembers.index',
                                            component: () => import('@/pages/nurseries/[id]/family/members/index.vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-family-members-module');
                                                },
                                            },
                                        },
                                        {
                                            path: ':familyMember',
                                            name: 'familyMembers.show',
                                            component: () => import('@/pages/nurseries/[id]/family/members/[id].vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-family-members-module');
                                                },
                                                redirectOnSwap: 'familyMembers.index',
                                            },
                                            children: [
                                                {
                                                    path: 'documents/create',
                                                    name: 'familyMembers.show.documents.create',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/family/members/[id]/documents/create.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return (
                                                                inherited &&
                                                                can('read', 'family_members_details') &&
                                                                can('create', 'family_members_documents')
                                                            );
                                                        },
                                                        redirectOnSwap: 'familyMembers.index',
                                                    },
                                                },
                                                {
                                                    path: 'documents/:document/edit',
                                                    name: 'familyMembers.show.documents.edit',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/family/members/[id]/documents/[id]/edit.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return (
                                                                inherited &&
                                                                can('read', 'family_members_details') &&
                                                                can('update', 'family_members_documents')
                                                            );
                                                        },
                                                        redirectOnSwap: 'familyMembers.index',
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'families',
                                    children: [
                                        {
                                            path: '',
                                            name: 'families.index',
                                            component: () => import('@/pages/nurseries/[id]/family/families/index.vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-families-module');
                                                },
                                            },
                                        },
                                        {
                                            path: ':family',
                                            name: 'families.show',
                                            component: () => import('@/pages/nurseries/[id]/family/families/[id].vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-families-module');
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'registrations',
                            children: [
                                {
                                    path: '',
                                    name: 'registrations.index',
                                    component: () => import('@/pages/nurseries/[id]/registrations/index.vue'),
                                    meta: {
                                        access() {
                                            return allows('access-to-registrations-module');
                                        },
                                    },
                                },
                                {
                                    path: ':registration',
                                    name: 'registrations.show',
                                    component: () => import('@/pages/nurseries/[id]/registrations/[id].vue'),
                                    meta: {
                                        access() {
                                            return allows('access-to-registrations-module');
                                        },
                                        redirectOnSwap: 'registrations.index',
                                    },
                                },
                            ],
                        },
                        {
                            path: 'billing',
                            name: 'module.billing',
                            redirect: to => {
                                if (can('read', 'invoices')) {
                                    return {name: 'billings.index', params: to.params};
                                }

                                if (can('read', 'transactions')) {
                                    return {name: 'payments.index', params: to.params};
                                }

                                return {name: 'billings.index', params: to.params};
                            },
                            meta: {
                                access() {
                                    return (
                                        allows('access-to-billings-module') &&
                                        (can('read', 'invoices') || can('read', 'transactions'))
                                    );
                                },
                            },
                            children: [
                                {
                                    path: 'invoices',
                                    beforeEnter(to, from, next) {
                                        const defaultView = window.localStorage.getItem('invoices:defaultView');
                                        // We check to.name to avoid infinite loop
                                        if (defaultView && defaultView === 'list' && to.name !== 'invoices.index') {
                                            return next({
                                                name: 'invoices.index',
                                                params: to.params,
                                                query: to.query,
                                            });
                                        } else {
                                            return next();
                                        }
                                    },
                                    children: [
                                        {
                                            path: '',
                                            name: 'billings.index',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/invoices/index.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-billings-module') && can('read', 'invoices')
                                                    );
                                                },
                                            },
                                        },
                                        {
                                            path: 'list',
                                            name: 'invoices.index',
                                            component: () => import('@/pages/nurseries/[id]/billing/invoices/list.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-billings-module') && can('read', 'invoices')
                                                    );
                                                },
                                            },
                                        },
                                    ],
                                },
                                {
                                    path: 'transactions',
                                    name: 'payments.index',
                                    component: () => import('@/pages/nurseries/[id]/billing/transactions/index.vue'),
                                    meta: {
                                        access() {
                                            return allows('access-to-billings-module') && can('read', 'transactions');
                                        },
                                    },
                                    children: [
                                        {
                                            path: 'create',
                                            name: 'payments.create',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/transactions/create.vue'),
                                            meta: {
                                                access(inherited) {
                                                    return inherited.value && can('create', 'transactions');
                                                },
                                            },
                                        },
                                        {
                                            path: ':transaction/edit',
                                            name: 'payments.edit',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/transactions/edit-[id].vue'),
                                            meta: {
                                                access(inherited) {
                                                    return inherited.value && can('update', 'transactions');
                                                },
                                            },
                                        },
                                    ],
                                },
                                {
                                    path: 'customers',
                                    children: [
                                        {
                                            path: '',
                                            name: 'cashier/customers.index',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/customers/index.vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-billings-module');
                                                },
                                            },
                                        },
                                        {
                                            path: ':customer',
                                            name: 'cashier/customers.show',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/customers/[id].vue'),
                                            meta: {
                                                access() {
                                                    return allows('access-to-billings-module');
                                                },
                                            },
                                            children: [
                                                {
                                                    path: 'summary',
                                                    name: 'cashier/customers.show.summary',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/billing/customers/[id]/summary.vue'
                                                        ),
                                                },
                                                {
                                                    path: 'invoices',
                                                    name: 'cashier/customers.show.invoices',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/billing/customers/[id]/invoices.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return inherited && can('read', 'invoices');
                                                        },
                                                        redirectOnSwap: 'cashier/customers.show',
                                                    },
                                                },
                                                {
                                                    path: 'transactions',
                                                    name: 'cashier/customers.show.payments',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/billing/customers/[id]/transactions.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return inherited && can('read', 'transactions');
                                                        },
                                                        redirectOnSwap: 'cashier/customers.show',
                                                    },
                                                    children: [
                                                        {
                                                            path: 'create',
                                                            name: 'cashier/customers.show.payments.create',
                                                            component: () =>
                                                                import(
                                                                    '@/pages/nurseries/[id]/billing/customers/[id]/transactions/create.vue'
                                                                ),
                                                            meta: {
                                                                access(inherited) {
                                                                    return inherited && can('create', 'transactions');
                                                                },
                                                            },
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'settings',
                                    name: 'settings.billings',
                                    component: () => import('@/pages/nurseries/[id]/billing/settings.vue'),
                                    meta: {
                                        access() {
                                            return allows('access-to-billings-module') && can('settings', 'invoices');
                                        },
                                    },
                                    children: [
                                        {
                                            path: 'general',
                                            name: 'settings.billings.general',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/settings/general.vue'),
                                        },
                                        {
                                            path: 'rules',
                                            name: 'settings.billings.rules',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/settings/rules.vue'),
                                        },
                                        {
                                            path: 'invoices',
                                            name: 'settings.billings.invoices',
                                            component: () =>
                                                import('@/pages/nurseries/[id]/billing/settings/invoices.vue'),
                                        },
                                        {
                                            path: 'sepa',
                                            name: 'settings.billings.sepa',
                                            component: () => import('@/pages/nurseries/[id]/billing/settings/sepa.vue'),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'hr',
                            name: 'module.hr',
                            redirect: to => {
                                if (can('read', 'staffs_details')) {
                                    return {name: 'staffs.index', params: to.params};
                                }

                                if (
                                    can('read', 'staffs_statistics') ||
                                    can('read', 'staffs_balances') ||
                                    can('read', 'staffs_contracts')
                                ) {
                                    return {name: 'humanresources/reports', params: to.params};
                                }

                                return {name: 'staffs.index', params: to.params};
                            },
                            meta: {
                                access() {
                                    return allows('access-to-hr-module');
                                },
                            },
                            children: [
                                {
                                    path: 'staffs',
                                    children: [
                                        {
                                            path: '',
                                            name: 'staffs.index',
                                            component: () => import('@/pages/nurseries/[id]/hr/staffs/index.vue'),
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-hr-module') && can('read', 'staffs_details')
                                                    );
                                                },
                                            },
                                        },
                                        {
                                            path: ':staff',
                                            name: 'staffs.show',
                                            component: () => import('@/pages/nurseries/[id]/hr/staffs/[id].vue'),
                                            redirect: {name: 'staffs.show.summary'},
                                            meta: {
                                                access() {
                                                    return (
                                                        allows('access-to-hr-module') && can('read', 'staffs_details')
                                                    );
                                                },
                                                redirectOnSwap: 'staffs.index',
                                            },
                                            children: [
                                                {
                                                    path: 'summary',
                                                    name: 'staffs.show.summary',
                                                    component: () =>
                                                        import(
                                                            '@/modules/human-resources/components/staff/Summary.vue'
                                                        ),
                                                },
                                                {
                                                    path: 'contracts',
                                                    name: 'staffs.show.contracts',
                                                    component: () =>
                                                        import(
                                                            '@/modules/human-resources/components/staff/Contracts.vue'
                                                        ),
                                                },
                                                {
                                                    path: 'biography',
                                                    name: 'staffs.show.biography',
                                                    component: () =>
                                                        import(
                                                            '@/modules/human-resources/components/staff/Biography.vue'
                                                        ),
                                                },
                                                {
                                                    path: 'documents',
                                                    name: 'staffs.show.documents',
                                                    component: () =>
                                                        import(
                                                            '@/modules/human-resources/components/staff/Documents.vue'
                                                        ),
                                                    children: [
                                                        {
                                                            path: 'create',
                                                            name: 'staffs.show.documents.create',
                                                            component: () =>
                                                                import(
                                                                    '@/pages/nurseries/[id]/hr/staffs/[id]/documents/create.vue'
                                                                ),
                                                            meta: {
                                                                access(inherited) {
                                                                    return (
                                                                        inherited && can('create', 'staffs_documents')
                                                                    );
                                                                },
                                                                redirectOnSwap: 'staffs.index',
                                                            },
                                                        },
                                                        {
                                                            path: ':document/edit',
                                                            name: 'staffs.show.documents.edit',
                                                            component: () =>
                                                                import(
                                                                    '@/pages/nurseries/[id]/hr/staffs/[id]/documents/[id]/edit.vue'
                                                                ),
                                                            meta: {
                                                                access(inherited) {
                                                                    return (
                                                                        inherited && can('update', 'staffs_documents')
                                                                    );
                                                                },
                                                                redirectOnSwap: 'staffs.index',
                                                            },
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'reports',
                                    name: 'humanresources/reports',
                                    redirect: to => {
                                        if (can('read', 'staffs_statistics')) {
                                            return {name: 'humanresources/reports.statistics', params: to.params};
                                        }

                                        if (can('read', 'staffs_balances')) {
                                            return {name: 'humanresources/reports.balances', params: to.params};
                                        }

                                        return {name: 'humanresources/reports.register', params: to.params};
                                    },
                                    component: () => import('@/pages/nurseries/[id]/hr/reports.vue'),
                                    meta: {
                                        access() {
                                            return (
                                                allows('access-to-hr-module') &&
                                                (can('read', 'staffs_statistics') ||
                                                    can('read', 'staffs_balances') ||
                                                    can('read', 'staffs_contracts'))
                                            );
                                        },
                                    },
                                    children: [
                                        {
                                            path: 'statistics',
                                            name: 'humanresources/reports.statistics',
                                            component: () => import('@/pages/nurseries/[id]/hr/reports/statistics.vue'),
                                            meta: {
                                                access(inherited) {
                                                    return inherited && can('read', 'staffs_statistics');
                                                },
                                                redirectOnSwap: 'staffs.index',
                                            },
                                        },
                                        {
                                            path: 'balances',
                                            name: 'humanresources/reports.balances',
                                            component: () => import('@/pages/nurseries/[id]/hr/reports/balances.vue'),
                                            meta: {
                                                access(inherited) {
                                                    return inherited && can('read', 'staffs_balances');
                                                },
                                                redirectOnSwap: 'staffs.index',
                                            },
                                        },
                                        {
                                            path: 'registry',
                                            name: 'humanresources/reports.register',
                                            component: () => import('@/pages/nurseries/[id]/hr/reports/registry.vue'),
                                            meta: {
                                                redirectOnSwap: 'staffs.index',
                                            },
                                        },
                                    ],
                                },
                                {
                                    path: 'settings',
                                    name: 'humanresources/settings',
                                    redirect: {name: 'humanresources/settings.common'},
                                    component: () => import('@/pages/nurseries/[id]/hr/settings.vue'),
                                    meta: {
                                        access() {
                                            return (
                                                allows('access-to-hr-module') &&
                                                (can('settings', 'staffs_details') ||
                                                    can('settings', 'staffs_planning') ||
                                                    can('settings', 'staffs_balances'))
                                            );
                                        },
                                    },
                                    children: [
                                        {
                                            path: 'common',
                                            name: 'humanresources/settings.common',
                                            redirect: to => ({
                                                name: 'humanresources/settings.common.jobs',
                                                params: to.params,
                                            }),
                                            component: () => import('@/pages/nurseries/[id]/hr/settings/common.vue'),
                                            meta: {
                                                access(inherited) {
                                                    return inherited && can('settings', 'staffs_details');
                                                },
                                            },
                                            children: [
                                                {
                                                    path: 'jobs',
                                                    name: 'humanresources/settings.common.jobs',
                                                    component: () =>
                                                        import('@/pages/nurseries/[id]/hr/settings/common/jobs.vue'),
                                                },
                                                {
                                                    path: 'teams',
                                                    name: 'humanresources/settings.common.teams',
                                                    component: () =>
                                                        import('@/pages/nurseries/[id]/hr/settings/common/teams.vue'),
                                                },
                                            ],
                                        },
                                        {
                                            path: 'calendar',
                                            name: 'humanresources/settings.calendar',
                                            redirect: to => ({
                                                name: 'humanresources/settings.calendar.eventType',
                                                params: to.params,
                                            }),
                                            component: () => import('@/pages/nurseries/[id]/hr/settings/calendar.vue'),
                                            meta: {
                                                access(inherited) {
                                                    return (
                                                        inherited &&
                                                        (can('settings', 'staffs_planning') ||
                                                            can('settings', 'staffs_balances'))
                                                    );
                                                },
                                            },
                                            children: [
                                                {
                                                    path: 'event-type',
                                                    name: 'humanresources/settings.calendar.eventType',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/hr/settings/calendar/event-type.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return inherited && can('settings', 'staffs_planning');
                                                        },
                                                    },
                                                },
                                                {
                                                    path: 'balance-type',
                                                    name: 'humanresources/settings.calendar.balanceType',
                                                    component: () =>
                                                        import(
                                                            '@/pages/nurseries/[id]/hr/settings/calendar/balance-type.vue'
                                                        ),
                                                    meta: {
                                                        access(inherited) {
                                                            return inherited && can('settings', 'staffs_balances');
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'photos',
                            name: 'photos.index',
                            component: () => import('@/pages/nurseries/[id]/photos/index.vue'),
                            meta: {
                                access() {
                                    return allows('access-to-photos-module');
                                },
                            },
                        },
                        {
                            path: 'documents',
                            name: 'documents.index',
                            component: () => import('@/pages/nurseries/[id]/documents/index.vue'),
                            meta: {
                                access() {
                                    return allows('access-to-documents-module');
                                },
                            },
                            children: [
                                {
                                    path: 'create',
                                    name: 'documents.create',
                                    component: () => import('@/pages/nurseries/[id]/documents/create.vue'),
                                    meta: {
                                        access(inherited) {
                                            return inherited && can('create', 'documents');
                                        },
                                    },
                                },
                                {
                                    path: ':document/edit',
                                    name: 'documents.edit',
                                    component: () => import('@/pages/nurseries/[id]/documents/[id]/edit.vue'),
                                    meta: {
                                        access(inherited) {
                                            return inherited && can('update', 'documents');
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            path: 'communication',
                            name: 'module.communication',
                            redirect: to => {
                                return {name: 'communication.index', params: to.params};
                            },
                            children: [
                                {
                                    path: 'news',
                                    name: 'communication.index',
                                    component: () => import('@/pages/nurseries/[id]/communication/news/index.vue'),
                                    meta: {
                                        access() {
                                            return allows('access-to-communication-module');
                                        },
                                    },
                                },
                            ],
                        },

                        {
                            path: 'website',
                            name: 'website.index',
                            component: () => import('@/pages/nurseries/[id]/website/index.vue'),
                            meta: {
                                access() {
                                    return allows('access-to-personal-website-module');
                                },
                            },
                        },
                        {
                            path: 'equipment',
                            name: 'equipment.index',
                            component: () => import('@/pages/nurseries/[id]/equipment/index.vue'),
                            meta: {
                                access() {
                                    return allows('access-to-equipments-module');
                                },
                            },
                        },
                        {
                            path: 'settings',
                            name: 'settings',
                            component: () => import('@/pages/nurseries/[id]/settings.vue'),
                            meta: {
                                access() {
                                    return allows('access-to-settings-module');
                                },
                            },
                        },
                        {
                            path: 'planning/settings',
                            name: 'settings.plannings',
                            component: () => import('@/pages/nurseries/[id]/planning/settings.vue'),
                            meta: {
                                access() {
                                    return allows('access-to-plannings-module');
                                },
                            },
                        },
                        {
                            path: 'settings/profile',
                            name: 'settings.profile',
                            component: () => import('@/pages/nurseries/[id]/settings/profile.vue'),
                        },
                        {
                            path: 'settings/subscriptions',
                            name: 'subscriptions',
                            component: () => import('@/pages/nurseries/[id]/settings/subscriptions.vue'),
                            meta: {
                                requiresSubscribe: false,
                            },
                            children: [
                                {
                                    path: 'subscribe/:plan',
                                    name: 'subscriptions.subscribe',
                                    component: () =>
                                        import('@/pages/nurseries/[id]/settings/subscriptions/subscribe/[plan].vue'),
                                },
                                {
                                    path: 'plan',
                                    name: 'subscriptions.plan',
                                    component: () => import('@/pages/nurseries/[id]/settings/subscriptions/plan.vue'),
                                },
                                {
                                    path: 'payments-methods',
                                    name: 'subscriptions.paymentsMethods',
                                    component: () =>
                                        import('@/pages/nurseries/[id]/settings/subscriptions/payments-methods.vue'),
                                },
                                {
                                    path: 'address',
                                    name: 'subscriptions.address',
                                    component: () =>
                                        import('@/pages/nurseries/[id]/settings/subscriptions/address.vue'),
                                },
                                {
                                    path: 'invoices',
                                    name: 'subscriptions.invoices',
                                    component: () =>
                                        import('@/pages/nurseries/[id]/settings/subscriptions/invoices.vue'),
                                },
                                {
                                    path: 'cancel',
                                    name: 'subscriptions.cancel',
                                    component: () => import('@/pages/nurseries/[id]/settings/subscriptions/cancel.vue'),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
});

export default router;
