<template>
    <DocumentCreateModal
        :active-organization="activeOrganization"
        :available-organizations-to-load="user.organizations()"
        :bus="bus"
        :displayed-visibilities="{
            staffs_access: false,
            parents_access: true,
            web_access: false,
        }"
        :related-resource="memberModel"
        :user="user"
        @created="documentCreated"
        @hidden="redirect"
    />
</template>

<script lang="ts">
    import mitt from 'mitt';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';
    import {useRouter} from 'vue-router';

    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import DocumentCreateModal from '@/modules/document/components/organisms/DocumentCreateModal.vue';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';

    export default defineComponent({
        components: {DocumentCreateModal},
        props: {
            memberModel: {
                type: Object as PropType<MemberModel>,
                required: true,
            },
        },
        emits: ['created'],
        setup(props, {emit}) {
            const {user} = useAuth();
            const {activeOrganization} = useManager();
            const router = useRouter();
            const bus = mitt<EventType>();

            function redirect() {
                router.push({name: 'familyMembers.show', query: {nav: 'documents'}});
            }

            function documentCreated(document: DocumentModel) {
                bus.emit('hide');
                emit('created', document);
            }

            return {
                bus,
                user,
                activeOrganization,
                documentCreated,
                redirect,
            };
        },
    });
</script>
