<template>
    <MTooltip
        :label="tooltip"
        placement="top-end"
    >
        <RouterLink :to="to">
            <MButton variant="gray">
                <FontAwesomeIcon icon="fa-solid fa-cog" />
            </MButton>
        </RouterLink>
    </MTooltip>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            tooltip: {
                type: String,
                default: () => __('common:settings'),
                required: false,
            },
            to: {
                type: Object,
                required: true,
            },
        },
    });
</script>
