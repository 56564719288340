import type {Collection} from '@meekohq/lumos';
import {QueryBuilder} from '@meekohq/lumos';

import type {HasOrganization} from '@/modules/app/composables/useSharedList';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

interface HasRelationsNameAndQuery {
    name: string;
    queryBuilder: QueryBuilder<any>;
}

export const getModelIDontHave = function (organization: OrganizationModel, modelsToIgnore: Collection<any>) {
    const modelToIgnoreIds = modelsToIgnore.keyBy('id').keys().all();
    const organizationType = new OrganizationModel().getType();

    const builder = new QueryBuilder<any>()
        .whereHas('organizations', query => {
            query.where(organizationType + '.id', organization.id);
        })
        .whereNotIn('id', modelToIgnoreIds);

    return {
        builder,
    };
};

export const attachModelToOrganization = async function <T extends HasOrganization>(
    modelsOfSelectedOrganizations: T[],
    activeOrganization: OrganizationModel,
    relation?: HasRelationsNameAndQuery
) {
    for (const model of modelsOfSelectedOrganizations) {
        if (relation) {
            const {name, queryBuilder} = relation;
            const relations = model[name]()
                .setQuery(query => query.inject(queryBuilder))
                .value();

            if (relations.isNotEmpty()) {
                await attachModelToOrganization(relations, activeOrganization);
            }
        }
        await model.organizations().attach(activeOrganization, {
            account_id: activeOrganization.attributes.account_id,
        });
    }
};
