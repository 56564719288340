import type {Router} from 'vue-router';

import useAuth from '@/modules/app/composables/useAuth';

export default function authMiddleware(router: Router) {
    const {isAuthenticated} = useAuth();

    router.beforeEach(async (to, from, next) => {
        if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated.value) {
            return next({
                name: 'login',
                query: {redirect: to.fullPath !== '/' ? to.fullPath : undefined},
            });
        }

        if (to.matched.some(record => record.meta.requiresGuest) && isAuthenticated.value) {
            return next({
                name: 'dashboard',
            });
        }

        return next();
    });
}
