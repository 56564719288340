<template>
    <div class="MHoverable">
        <slot />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style scoped>
    .MHoverable {
        @apply tw-inline-block tw-rounded tw-transition-all;
        @apply hover:tw-bg-blue-950/10 hover:tw-ring-4 hover:tw-ring-blue-950/10;
    }
</style>
