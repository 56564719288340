import type {Ref} from 'vue';
import {computed} from 'vue';

import useError from '@/modules/app/composables/useError';
import __ from '@/modules/app/utils/i18n-facade';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
import {AmountToAllocateIsGreaterThanDestinationRemainingError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanDestinationRemainingError';
import {AmountToAllocateIsGreaterThanSourceRemainingError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanSourceRemainingError';
import {AmountToAllocateIsRequired} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsRequired';
import {AmountToAllocateMustBePositiveError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateMustBePositiveError';
import {TransactionCreditAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
import {TransactionDebitAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';

export default function usePaymentSuggestionsListItem(allocation: Ref<AbstractAllocationAggregate>) {
    const errors = useError();

    const maxAllocatableAmount = computed(() => allocation.value.maxAllocatableAmount);

    function setAllocationAmount(value: number) {
        errors.reset();

        try {
            allocation.value.allocationAmount = value;
        } catch (e) {
            if (e instanceof AmountToAllocateIsRequired) {
                errors.addErrorCode('amount', AmountToAllocateIsRequired.code);

                return;
            }

            if (e instanceof AmountToAllocateIsGreaterThanDestinationRemainingError) {
                errors.addErrorCode('amount', AmountToAllocateIsGreaterThanDestinationRemainingError.code);

                return;
            }

            if (e instanceof AmountToAllocateIsGreaterThanSourceRemainingError) {
                let allocationType = '';

                if (allocation.value instanceof TransactionCreditAllocationAggregate) {
                    allocationType = __('billing_transaction:to_the_credit');
                } else if (allocation.value instanceof TransactionDebitAllocationAggregate) {
                    allocationType = __('billing_transaction:to_the_debit');
                } else if (allocation.value instanceof CreditNoteAllocationAggregate) {
                    allocationType = __('billing_transaction:to_the_credit_note');
                } else {
                    allocationType = __('billing_transaction:to_the_invoice');
                }

                errors.addErrorCode('amount', AmountToAllocateIsGreaterThanSourceRemainingError.code, {
                    name: allocationType,
                });

                return;
            }

            if (e instanceof AmountToAllocateMustBePositiveError) {
                errors.addErrorCode('amount', AmountToAllocateMustBePositiveError.code);

                return;
            }

            throw e;
        }
    }

    return {
        errors,
        maxAllocatableAmount,
        setAllocationAmount,
    };
}
