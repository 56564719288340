import moment from 'moment';
import type {Ref} from 'vue';
import {computed} from 'vue';

import {scopeComing, scopeFrom, scopeInRange, scopeUntil} from '@/modules/family/utils/kid/scopeLegacyKidContract';

export default function (contracts: Ref<any[]>, from: Ref<string>, to: Ref<string> = from, withoutDraft = true) {
    const activeContracts = computed(() => {
        let filteredContracts = contracts.value;

        if (from.value === to.value) {
            filteredContracts = scopeFrom(contracts.value, from.value);
        } else {
            filteredContracts = scopeInRange(contracts.value, from.value, to.value);
        }

        if (withoutDraft) {
            filteredContracts = filteredContracts.filter(contract => !contract.draft);
        }

        return filteredContracts;
    });

    const pastContracts = computed(() => {
        let filteredContracts = scopeUntil(contracts.value, from.value);

        if (withoutDraft) {
            filteredContracts = filteredContracts.filter(contract => !contract.draft);
        }

        return filteredContracts;
    });

    const comingContracts = computed(() => {
        let filteredContracts = scopeComing(contracts.value);

        if (withoutDraft) {
            filteredContracts = filteredContracts.filter(contract => !contract.draft);
        }

        return filteredContracts;
    });

    function contractForDate(date = from.value) {
        return contracts.value?.find(
            item =>
                item.state !== 'draft' &&
                moment(date).isBetween(item.started_at, item.broken_at || item.ended_at, 'days', '[]')
        );
    }

    return {
        activeContracts,
        pastContracts,
        comingContracts,
        contractForDate,
    };
}
