import {app, lumosBindings, type Model} from '@meekohq/lumos';

export default function () {
    const sync = function <T extends Model>(model: T) {
        app(lumosBindings.ModelCacheBinding).syncModel(model);
        app(lumosBindings.ModelCacheBinding).syncRelationships(model);
    };

    return {
        sync,
    };
}
