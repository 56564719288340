<template>
    <SimpleSubPageTemplate>
        <template #title>
            <MHeading>{{ __('common:documents_of', {name: staff.fullname}) }}</MHeading>
        </template>
        <template #actions>
            <router-link :to="{name: 'staffs.show.documents.create'}">
                <MButton
                    v-if="can('create', 'staffs_documents')"
                    icon-plus
                    :label="__('common:add_document')"
                    variant="primary"
                />
            </router-link>
        </template>
        <template #content>
            <DocumentList
                :bus="bus"
                :edit-item-fn="editItemFn"
                permission-key="staffs_documents"
                :related-resource="staff"
                :organization-to-constraint-to="staffOrganizations"
                :show-visibilities="{
                    staffs_access: false,
                    parents_access: false,
                    web_access: false,
                }"
            />
            <router-view
                :staff-model="staff"
                v-on="documentListeners"
            />
        </template>
    </SimpleSubPageTemplate>
</template>

<script lang="ts">
    import mitt from 'mitt';
    import {computed, defineComponent, onMounted, type PropType, ref} from 'vue';
    import {useRoute, useRouter} from 'vue-router';

    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import __ from '@/modules/app/utils/i18n-facade';
    import DocumentList from '@/modules/document/components/organisms/DocumentList.vue';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import Mirror from '@/modules/legacy/helpers/mirror.helper';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {SimpleSubPageTemplate, DocumentList},
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const staffCopy = ref<Mirror>();
            const saveLoading = ref(false);

            onMounted(() => {
                staffCopy.value = new Mirror(props.staff);
            });

            function save() {
                saveLoading.value = true;
                staffCopy.value?.value
                    .save(undefined, true)
                    .then(() => {
                        staffCopy.value?.commit();
                        useNotification().success(__('hr_staff:biography_successfully_updated'));
                        saveLoading.value = false;
                    })
                    .catch(() => {
                        saveLoading.value = false;
                    });
            }

            const bus = mitt();

            const router = useRouter();
            const route = useRoute();

            const {can} = useAbility();

            function editItemFn(document) {
                router.replace({
                    name: 'staffs.show.documents.edit',
                    params: {document: document.getKey()},
                });
            }

            const staffOrganizations = computed(() => {
                return props.staff
                    ?.organizationsPivots()
                    .value()
                    .map(pivot => pivot.organization().value())
                    .toArray();
            });

            const documentListeners = computed(() => {
                if (route.name === 'staffs.show.documents.create') {
                    return {
                        created: $event => bus.emit('documentCreated', $event),
                    };
                } else {
                    return {
                        updated: $event => bus.emit('documentUpdated', $event),
                        deleted: $event => bus.emit('documentDeleted', $event),
                    };
                }
            });

            return {
                can,
                bus,
                staffCopy,
                saveLoading,
                save,
                editItemFn,
                staffOrganizations,
                documentListeners,
            };
        },
    });
</script>
