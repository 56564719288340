<template>
    <CAlert
        class="tw-mb-4"
        remember-hash="september-2022-simulation-tutorial"
        :title-text="__('planning:create_simulation_with_organization_application')"
    >
        <template #icon>
            <FontAwesomeIcon
                class="tw-text-3xl tw-text-blue-500"
                icon="fa-duotone fa-info-circle"
            />
        </template>
        <CParagraph>
            <span v-html="__('planning:modal.introduction.content')" />
        </CParagraph>
        <CHStack
            align="center"
            gap="2"
        >
            <MButton
                variant="link"
                @click="showArticle(6501798)"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fa-duotone fa-external-link"
                />
                {{ __('common:show_guide') }}
            </MButton>
        </CHStack>
    </CAlert>
</template>
<script setup lang="ts">
    import useIntercom from '@/modules/app/composables/useIntercom';

    const {showArticle} = useIntercom();
</script>
