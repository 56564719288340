<template>
    <div
        class="tag-pill tw-inline-flex tw-shrink-0 tw-text-center"
        :style="getTagStyle(tag)"
    >
        <CIcon
            v-if="getIconPath"
            class="icon tw-w-full tw-justify-center tw-self-center"
            :path="getIconPath.attributes.path"
            :provider="getIconPath.attributes.provider"
        />
        <i
            v-else
            class="tw-justify-center tw-self-center"
            style="height: 10px"
        />
    </div>
</template>

<script>
    import DataStore from '@/modules/legacy/store/data.store';

    export default {
        props: {
            tag: {
                type: Object,
            },
        },

        data() {
            return {
                DataStore,
            };
        },

        computed: {
            getIconPath() {
                return DataStore.iconPath(this.tag.icon_id);
            },
        },
        methods: {
            getTagStyle(tag) {
                if (this.getIconPath) {
                    const color = tag.color ? tag.color : '#333';

                    return 'color: ' + color + '; background-color: #FFF;';
                } else {
                    const color = tag.color ? tag.color : '#FFF';

                    return 'background-color: ' + color + '; border: 2px solid white;';
                }
            },
        },
    };
</script>

<style scoped>
    .tag-pill {
        box-shadow: 0 1px 2px 1px rgba(0, 96, 198, 0.2);
        border-radius: 6px;
        width: 22px;
        height: 22px;
        font-size: 14px;

        .icon {
            text-shadow: rgba(0, 96, 198, 0.1) 1px 1px;
        }
    }
</style>
