<template>
    <div class="AbstractItemWithModal">
        <div class="AbstractItemWithModal__header-container">
            <div class="AbstractItemWithModal__header">
                <div class="AbstractItemWithModal__icon">
                    <slot name="icon" />
                </div>
                <div class="AbstractItemWithModal__title">
                    <slot name="title" />
                </div>
                <slot name="date" />
            </div>
            <div class="AbstractItemWithModal__actions">
                <slot name="actions" />
            </div>
        </div>
        <div
            v-if="$slots.content"
            class="AbstractItemWithModal__content"
        >
            <slot name="content" />
        </div>
        <slot name="modal" />
    </div>
</template>

<style scoped>
    .AbstractItemWithModal {
        @apply tw-p-3;
    }

    .AbstractItemWithModal__header-container {
        @apply tw-flex tw-justify-between tw-gap-2 sm:tw-items-center;
    }

    .AbstractItemWithModal__header {
        @apply tw-flex tw-w-full tw-flex-col tw-justify-between tw-gap-2 sm:tw-flex-row sm:tw-items-center;
    }

    .AbstractItemWithModal__title {
        @apply tw-w-full tw-min-w-0;
    }

    .AbstractItemWithModal__content {
        @apply tw-mt-2;
        @apply tw-whitespace-pre-line tw-break-words;
    }
</style>
