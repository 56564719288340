import type {Ref} from 'vue';
import {computed, watch} from 'vue';

import type ContractModel from '@/modules/human-resources/models/ContractModel';
import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';

export default function (contract: Ref<ContractModel>) {
    const trialPeriodsRelation = computed(() => contract.value.trialPeriods());

    // Service trial period functions
    const addPeriod = function (model: ContractTrialPeriodModel) {
        trialPeriodsRelation.value.value().push(model);
    };

    const deletePeriod = function (model: ContractTrialPeriodModel) {
        const deletePeriodFromCollection = function (modelToRemove: ContractTrialPeriodModel) {
            trialPeriodsRelation.value.mutate(trialPeriods =>
                trialPeriods.reject(item => item.getKey() === modelToRemove.getKey())
            );
        };

        // If model already exists, mark it for deletion and then delete from collection
        if (model.exist) {
            model.markForDeletion();
            model.on('delete', () => deletePeriodFromCollection(model));
        } else {
            deletePeriodFromCollection(model);
        }
    };

    // Refresh relation when contract changed
    watch(
        contract,
        value => {
            if (value.exist) {
                value.trialPeriods().fresh();
            }
        },
        {immediate: true}
    );

    return {addPeriod, deletePeriod, trialPeriodsRelation};
}
