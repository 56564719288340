<script lang="ts" setup>
    import {inject} from 'vue';

    import {keyMStepPanelState} from '@/modules/meeko-ui/components/MStepPanel/useMStepPanelState';

    const state = inject(keyMStepPanelState);
    if (!state) {
        throw new Error('keyMStepPanelState must be provided');
    }

    const {activeStep, firstStep, nextStep, previousStep, lastStep} = state;
</script>

<template>
    <div class="MStepPanelContent">
        <slot
            :active-step="activeStep"
            :first-step="firstStep"
            :last-step="lastStep"
            :next-step="nextStep"
            :previous-step="previousStep"
        />
    </div>
</template>

<style scoped>
    .MStepPanelContent {
        @apply tw-mt-8;
    }
</style>
