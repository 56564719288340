import {MqlOperation} from '@meekohq/lumos';

import type {ReportingSettingsNotifierPort} from '@/modules/meeko-family/domain/ports/ReportingSettingsNotifierPort';

export class ReportingSettingNotifierAdapter implements ReportingSettingsNotifierPort {
    async sendRealTimeDeactivationFeedback(feedback: string): Promise<void> {
        const operation = new MqlOperation('notification/reporting-settings/send-real-time-deactivation-feedback', {
            feedback,
        });

        await operation.run();
    }
}
