<template>
    <div class="tw-p-4 tw-text-center tw-text-xl">
        <div>
            <FontAwesomeIcon
                class="tw-text-3xl tw-text-blue-300"
                icon="fa-duotone fa-book"
            />
        </div>
        <div>{{ __('common:no_match') }}</div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>
