<template>
    <div>
        <div>
            <div class="tw-mb-6 tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 lg:tw-grid-cols-4 lg:tw-gap-8">
                <div class="tw-col-span-full tw-flex tw-flex-col tw-gap-2 lg:tw-col-span-2">
                    <MHeading class="tw-flex tw-flex-row tw-items-center">
                        <img
                            alt="Balance"
                            class="tw-mr-2"
                            :src="ScaleIcon"
                            width="30px"
                        />
                        {{ __('common:weight') }}
                    </MHeading>
                    <KidWeight :kid="kid" />
                </div>
                <div class="tw-col-span-full md:tw-col-span-1">
                    <MHeading class="tw-mb-2">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-success-500"
                            icon="fa-duotone fa-head-side-cough"
                        />
                        {{ __('family_kid:allergy_other') }}
                    </MHeading>
                    <div
                        v-if="$can('create', 'kids_health')"
                        class="tw-mb-4"
                    >
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                            <MPopover2>
                                <MPopover2ClickableReference class="tw-w-full tw-min-w-0">
                                    <MButton
                                        class="tw-w-full"
                                        align="left"
                                        icon-sort
                                    >
                                        {{ selectedAllergy ? selectedAllergy.name : __('common:search_dots') }}
                                    </MButton>
                                </MPopover2ClickableReference>
                                <MPopover2Panel v-slot="{close}">
                                    <div class="tw-flex tw-h-64 tw-flex-col tw-gap-8 tw-overflow-y-auto">
                                        <CList
                                            v-for="allergyType in allergiesTypes"
                                            :key="allergyType.id"
                                        >
                                            <CListHeader>
                                                <MHeading
                                                    level="h4"
                                                    class="tw-uppercase"
                                                >
                                                    {{ allergyType.name }}
                                                </MHeading>
                                            </CListHeader>
                                            <CListRow
                                                v-for="allergy in getAllergiesByType(allergyType.key)"
                                                :key="allergy.id"
                                                @click="selectAllergy(allergy, close)"
                                            >
                                                {{ allergy.name }}
                                            </CListRow>
                                        </CList>
                                    </div>
                                </MPopover2Panel>
                            </MPopover2>
                            <MButton
                                :loading="loadingAllergy"
                                class="tw-shrink-0"
                                icon-plus
                                :label="__('common:actions.add')"
                                variant="success"
                                @click="addAllergy(selectedAllergy.id)"
                            />
                        </div>
                    </div>
                    <ul class="tw-flex tw-flex-col tw-rounded-lg tw-border tw-border-gray-300">
                        <li
                            v-if="kid.allergies.length === 0"
                            class="tw-px-4 tw-py-2 tw-text-gray-400"
                        >
                            <em>{{ __('family_kid:empty_allergy') }}</em>
                        </li>
                        <li
                            v-for="(allergy, i) in _sortBy(kid.allergies, 'name')"
                            :key="'kidAllergy ' + i"
                            class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-300 tw-px-4 tw-py-1 tw-capitalize last-of-type:tw-border-none"
                        >
                            {{ allergy.name }}
                            <div v-if="$can('delete', 'kids_health')">
                                <MButton
                                    zoom
                                    variant="link"
                                    @click="deleteAllergy(allergy.id)"
                                >
                                    <FontAwesomeIcon
                                        class="tw-text-lg tw-text-danger-500"
                                        icon="fa-solid fa-times-circle"
                                    />
                                </MButton>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="tw-col-span-full md:tw-col-span-1">
                    <MHeading class="tw-mb-2">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-info-500"
                            icon="fa-duotone fa-face-thermometer"
                        />
                        {{ __('family_kid:childhood_disease') }}
                    </MHeading>
                    <div
                        v-if="$can('create', 'kids_health')"
                        class="tw-mb-4"
                    >
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                            <MSelectMenu
                                v-model="selectedDisease"
                                class="tw-w-full tw-min-w-0"
                                :options="_sortBy(diseases, 'name')"
                                text-path="name"
                                value-path="id"
                                list-class="tw-capitalize"
                                button-class="tw-capitalize"
                            />
                            <MButton
                                :loading="loadingDisease"
                                class="tw-shrink-0"
                                icon-plus
                                :label="__('common:actions.add')"
                                variant="success"
                                @click="addDisease(selectedDisease)"
                            />
                        </div>
                    </div>
                    <ul class="tw-flex tw-flex-col tw-rounded-lg tw-border tw-border-gray-300">
                        <li
                            v-if="kid.diseases.length === 0"
                            class="tw-px-4 tw-py-2 tw-text-gray-400"
                        >
                            <em>{{ __('family_kid:no_childhood_disease') }}</em>
                        </li>
                        <li
                            v-for="(disease, i) in _sortBy(kid.diseases, 'name')"
                            :key="'kidDisease ' + i"
                            class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-300 tw-px-4 tw-py-1 tw-capitalize last-of-type:tw-border-none"
                        >
                            {{ disease.name }}
                            <div v-if="$can('delete', 'kids_health')">
                                <MButton
                                    zoom
                                    variant="link"
                                    @click="deleteDisease(disease.id)"
                                >
                                    <FontAwesomeIcon
                                        class="tw-text-lg tw-text-danger-500"
                                        icon="fa-solid fa-times-circle"
                                    />
                                </MButton>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <TreatmentsList
                v-if="kid"
                class="tw-mb-8"
                :kid-id="kid.id"
            />

            <div class="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-2 lg:tw-gap-8">
                <div>
                    <MHeading class="tw-mb-2">
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-[#EB4591]"
                            icon="fa-duotone fa-hand-holding-heart"
                        />
                        {{ __('family_kid:individualized_welcome_project') }}
                    </MHeading>
                    <MTextarea
                        v-model="kid.chronicle_treatment"
                        :disabled="!$can('update', 'kids_health')"
                        :placeholder="__('family_kid:chronicpathology_specificlayout_etc')"
                        rows="5"
                    />
                    <div
                        v-if="$can('update', 'kids_health')"
                        class="tw-text-right"
                    >
                        <MButton
                            :loading="loadingChronicleTreatment"
                            variant="success"
                            class="tw-mt-2"
                            @click="saveChronicleTreatment()"
                        >
                            {{ __('common:actions.save') }}
                        </MButton>
                    </div>
                </div>
                <div>
                    <MHeading class="tw-mb-2">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-note"
                        />
                        {{ __('common:note_one') }}
                    </MHeading>
                    <MTextarea
                        v-model="kid.health_note"
                        :disabled="!$can('update', 'kids_health')"
                        :placeholder="__('family_kid:medicalhistory_additionalnotes_etc')"
                        rows="5"
                    />
                    <div
                        v-if="$can('update', 'kids_health')"
                        class="tw-text-right"
                    >
                        <MButton
                            :loading="loadingNote"
                            variant="success"
                            class="tw-mt-2"
                            @click="saveHealthNote()"
                        >
                            {{ __('common:actions.save') }}
                        </MButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _sortBy from 'lodash-es/sortBy';
    import moment from 'moment';

    import ScaleIcon from '@/assets/images/tablet/balance.svg';
    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import KidWeight from '@/modules/family/components/kid/Kid/KidWeight.vue';
    import TreatmentsList from '@/modules/health/components/TreatmentsList.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            TreatmentsList,
            KidWeight,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
            diseases: {
                type: Array,
                required: true,
            },
            allergies: {
                type: Array,
                required: true,
            },
        },
        data: () => ({
            allergiesTypes: [
                {
                    key: 'venom',
                    name: __('family_kid:venom'),
                },
                {
                    key: 'respiratory',
                    name: __('family_kid:respiratory'),
                },
                {
                    key: 'drug',
                    name: __('family_kid:drug'),
                },
                {
                    key: 'food',
                    name: __('family_kid:food'),
                },
            ],
            selectedAllergy: null,
            selectedDisease: null,
            ScaleIcon,
            loadingAllergy: false,
            loadingDisease: false,
            loadingChronicleTreatment: false,
            loadingNote: false,
        }),
        computed: {
            _sortBy() {
                return _sortBy;
            },
            moment() {
                return moment;
            },
            name() {
                return this.kid.first_name + ' ' + this.kid.last_name;
            },
        },
        mounted() {
            this.selectedAllergy = _head(_sortBy(this.allergies, 'name'));
            this.selectedDisease = _head(_sortBy(this.diseases, 'name')).id;
        },
        methods: {
            selectAllergy(allergy, onSelect) {
                this.selectedAllergy = allergy;
                onSelect();
            },
            addAllergy(id) {
                this.loadingAllergy = true;

                useApi()
                    .legacy.post(
                        route('kids.allergies.store', {
                            kid: this.kid.id,
                        }),
                        {allergy_id: id}
                    )
                    .then(response => {
                        if (!this.kid.allergies.find(item => item.id === response.data.id)) {
                            this.kid.allergies.push(response.data);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.loadingAllergy = false;
                    });
            },
            deleteAllergy(id) {
                useMagicModal().deleteConfirmationModal({
                    title: __('family_kid:confirm_the_withdrawal_of_this_allergy'),
                    onConfirm: async () => {
                        await useApi()
                            .legacy.delete(
                                route('kids.allergies.destroy', {
                                    kid: this.kid.id,
                                    allergy: id,
                                })
                            )
                            .then(() => {
                                this.kid.allergies = this.kid.allergies.filter(function (item) {
                                    return item.id !== id;
                                });
                            })
                            .catch(error => {
                                useNotification().error(error);
                            });
                    },
                });
            },
            getAllergiesByType(type) {
                const arr = this.allergies.filter(item => item.type === type);

                return _sortBy(arr, 'name');
            },
            addDisease(id) {
                this.loadingDisease = true;

                useApi()
                    .legacy.post(
                        route('kids.diseases.store', {
                            kid: this.kid.id,
                        }),
                        {disease_id: id}
                    )
                    .then(response => {
                        if (!this.kid.diseases.find(item => item.id === response.data.id)) {
                            this.kid.diseases.push(response.data);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.loadingDisease = false;
                    });
            },
            deleteDisease(id) {
                useMagicModal().deleteConfirmationModal({
                    title: __('family_kid:confirming_the_withdrawal_of_this_childhood_disease'),
                    onConfirm: async () => {
                        await useApi()
                            .legacy.delete(
                                route('kids.diseases.destroy', {
                                    kid: this.kid.id,
                                    disease: id,
                                })
                            )
                            .then(() => {
                                this.kid.diseases = this.kid.diseases.filter(function (item) {
                                    return item.id !== id;
                                });
                            })
                            .catch(error => {
                                useNotification().error(error);
                            });
                    },
                });
            },
            saveChronicleTreatment() {
                this.loadingChronicleTreatment = true;

                useApi()
                    .legacy.put(
                        route('kids.update', {
                            kid: this.kid.id,
                        }),
                        {chronicle_treatment: this.kid.chronicle_treatment}
                    )
                    .then(() => {
                        useNotification().success(__('family_kid:individualized_welcome_project_updated_successfully'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.loadingChronicleTreatment = false;
                    });
            },
            saveHealthNote() {
                this.loadingNote = true;

                useApi()
                    .legacy.put(
                        route('kids.update', {
                            kid: this.kid.id,
                        }),
                        {health_note: this.kid.health_note}
                    )
                    .then(() => {
                        useNotification().success(__('family_kid:childhood_disease.note_updated_successfully'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.loadingNote = false;
                    });
            },
        },
    };
</script>
