import {type App, defineAsyncComponent} from 'vue';

export default function (Vue: App) {
    // Form
    Vue.component(
        'CButtonGroup',
        defineAsyncComponent(() => import('./components/Forms/CButtonGroup.vue'))
    );
    Vue.component(
        'CFormTwoColumns',
        defineAsyncComponent(() => import('./components/Forms/CFormTwoColumns.vue'))
    );
    Vue.component(
        'CFormThreeColumns',
        defineAsyncComponent(() => import('./components/Forms/CFormThreeColumns.vue'))
    );
    Vue.component(
        'CInputGroup',
        defineAsyncComponent(() => import('./components/Forms/CInputGroup.vue'))
    );
    Vue.component(
        'CInputAddon',
        defineAsyncComponent(() => import('./components/Forms/CInputAddon.vue'))
    );
    Vue.component(
        'CInputSuggestions',
        defineAsyncComponent(() => import('./components/Forms/CInputSuggestions.vue'))
    );
    Vue.component(
        'CCheckbox',
        defineAsyncComponent(() => import('./components/Forms/CCheckbox.vue'))
    );
    Vue.component(
        'CSwitch',
        defineAsyncComponent(() => import('./components/Forms/CSwitch.vue'))
    );
    Vue.component(
        'CRadio',
        defineAsyncComponent(() => import('./components/Forms/CRadio.vue'))
    );
    Vue.component(
        'CPhoneInput',
        defineAsyncComponent(() => import('./components/Forms/CPhoneInput.vue'))
    );
    Vue.component(
        'CFormErrorMessage',
        defineAsyncComponent(() => import('./components/Forms/CFormErrorMessage.vue'))
    );
    Vue.component(
        'CFormErrorMessageList',
        defineAsyncComponent(() => import('./components/Forms/CFormErrorMessageList.vue'))
    );
    Vue.component(
        'CFormSubmitTrigger',
        defineAsyncComponent(() => import('./components/Forms/CFormSubmitTrigger.vue'))
    );
    Vue.component(
        'CFormDatepicker',
        defineAsyncComponent(() => import('./components/Forms/CFormDatepicker.vue'))
    );
    Vue.component(
        'CFileInput',
        defineAsyncComponent(() => import('./components/Forms/CFileInput.vue'))
    );

    // List
    Vue.component(
        'CList',
        defineAsyncComponent(() => import('./components/List/CList.vue'))
    );
    Vue.component(
        'CListRow',
        defineAsyncComponent(() => import('./components/List/CListRow.vue'))
    );
    Vue.component(
        'CListHeader',
        defineAsyncComponent(() => import('./components/List/CListHeader.vue'))
    );
    Vue.component(
        'CListHeaderItem',
        defineAsyncComponent(() => import('./components/List/CListHeaderItem.vue'))
    );
    Vue.component(
        'CListSection',
        defineAsyncComponent(() => import('./components/List/CListSection.vue'))
    );
    Vue.component(
        'CListCornerLoader',
        defineAsyncComponent(() => import('./components/List/ListLoader.vue'))
    );
    Vue.component(
        'CListNoResult',
        defineAsyncComponent(() => import('./components/List/CListNoResult.vue'))
    );
    Vue.component(
        'CListHasMoreTrigger',
        defineAsyncComponent(() => import('./components/List/HasMoreTrigger.vue'))
    );

    // Tab Menu
    Vue.component(
        'CTabMenu',
        defineAsyncComponent(() => import('./components/TabMenu/TabMenu.vue'))
    );
    Vue.component(
        'CTabMenuItem',
        defineAsyncComponent(() => import('./components/TabMenu/TabMenuItem.vue'))
    );

    // Tools
    Vue.component(
        'CLoader',
        defineAsyncComponent(() => import('./components/Tools/Loader.vue'))
    );
    Vue.component(
        'CPagination',
        defineAsyncComponent(() => import('./components/Tools/CPagination.vue'))
    );
    Vue.component(
        'CFinder',
        defineAsyncComponent(() => import('./components/Tools/Finder.vue'))
    );
    Vue.component(
        'CSkeleton',
        defineAsyncComponent(() => import('./components/Tools/CSkeleton.vue'))
    );
    Vue.component(
        'CIcon',
        defineAsyncComponent(() => import('./components/Tools/CIcon.vue'))
    );

    // Overlay
    Vue.component(
        'CAlert',
        defineAsyncComponent(() => import('./components/Feedback/CAlert.vue'))
    );

    // Layout
    Vue.component(
        'CVerticalNavigationWrapper',
        defineAsyncComponent(() => import('./components/Layout/VerticalNavigation/CVerticalNavigationWrapper.vue'))
    );
    Vue.component(
        'CVerticalNavigationItem',
        defineAsyncComponent(() => import('./components/Layout/VerticalNavigation/CVerticalNavigationItem.vue'))
    );
    Vue.component(
        'CStack',
        defineAsyncComponent(() => import('./components/Layout/CStack.vue'))
    );
    Vue.component(
        'CInline',
        defineAsyncComponent(() => import('./components/Layout/CInline.vue'))
    );
    Vue.component(
        'CVStack',
        defineAsyncComponent(() => import('./components/Layout/CVStack.vue'))
    );
    Vue.component(
        'CHStack',
        defineAsyncComponent(() => import('./components/Layout/CHStack.vue'))
    );
    Vue.component(
        'CCenter',
        defineAsyncComponent(() => import('./components/Layout/CCenter.vue'))
    );

    // Card
    Vue.component(
        'CCard',
        defineAsyncComponent(() => import('./components/Layout/Card/CCard.vue'))
    );
    Vue.component(
        'CCardTitle',
        defineAsyncComponent(() => import('./components/Layout/Card/CCardTitle.vue'))
    );
    Vue.component(
        'CCardHeroTitle',
        defineAsyncComponent(() => import('./components/Layout/Card/CCardHeroTitle.vue'))
    );

    // Typography
    Vue.component(
        'CText',
        defineAsyncComponent(() => import('./components/Typography/CText.vue'))
    );
    Vue.component(
        'CParagraph',
        defineAsyncComponent(() => import('./components/Typography/CParagraph.vue'))
    );
    Vue.component(
        'CLongText',
        defineAsyncComponent(() => import('./components/Text/LongText.vue'))
    );

    Vue.component(
        'CDisclosure',
        defineAsyncComponent(() => import('./components/Disclosure/CDisclosure.vue'))
    );
}
