<template>
    <CVStack gap="4">
        <CInline
            align-x="between"
            align-y="baseline"
        >
            <MHeading level="h3">
                {{ __('common:team_other') }}
            </MHeading>
            <CInline space="sm">
                <MTooltip :label="__('common:import_from_another_organization')">
                    <MButton @click="$refs.confirmModal.show()">
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-download" />
                        </template>
                        {{ __('common:actions.import') }}
                    </MButton>
                </MTooltip>
                <MButton
                    :icon-plus="true"
                    variant="primary"
                    @click="$refs.createModal.show()"
                >
                    {{ __('hr:add_team') }}
                </MButton>
            </CInline>
            <CreateTeamModal
                ref="createModal"
                @attached="addToTeams"
                @created="addToTeams"
            />
            <ConfirmModal
                ref="confirmModal"
                :orga="orga"
                :title="__('hr:import_event_type')"
                @organization-selected="importTeams($event)"
            >
                <template #label>
                    <MLabel>
                        {{ __('hr_staff:import_team_from_which_organization') }}
                    </MLabel>
                </template>
            </ConfirmModal>
        </CInline>
        <TeamList ref="list" />
    </CVStack>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';

    import ConfirmModal from '@/modules/app/components/ConfirmModal.vue';
    import TeamList from '@/modules/human-resources/components/team/List/TeamList.vue';
    import CreateTeamModal from '@/modules/human-resources/components/team/Modals/CreateTeamModal.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {CreateTeamModal, TeamList, ConfirmModal},
        setup() {
            const list = ref();
            const orga = OrganizationModel;

            function importTeams(organizationModel) {
                list.value.importAndRefreshData(organizationModel);
            }

            function addToTeams(teamModel) {
                list.value.addToResults(teamModel);
            }

            return {
                list,
                addToTeams,
                importTeams,
                orga,
            };
        },
    });
</script>
