export interface DetachCreditNoteMakeType {
    allocationId: string;
    creditNoteId: string;
    paymentId: string;
}

export class DetachCreditNoteToInvoiceRequest {
    constructor(
        public readonly allocationId: string,
        public readonly creditNoteId: string,
        public readonly paymentId: string,
        public readonly amount: number
    ) {}

    public static make(makeType: DetachCreditNoteMakeType): DetachCreditNoteToInvoiceRequest {
        return new DetachCreditNoteToInvoiceRequest(
            makeType.allocationId,
            makeType.creditNoteId,
            makeType.paymentId,
            0
        );
    }
}
