import {Model} from '@meekohq/lumos';

import StaffModel from '@/modules/human-resources/models/StaffModel';
import TeamModel from '@/modules/human-resources/models/TeamModel';

export default class TeamStaffPivot extends Model {
    public type = 'hr/teams_staffs_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        team_id: string | undefined;
        staff_id: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        team_id: undefined,
        staff_id: undefined,
    };

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }

    team() {
        return this.belongsTo('team', TeamModel, 'team_id');
    }
}
