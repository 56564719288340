import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export default function () {
    const {selectedOptions: resourceTypesToConstrainTo, waitForIsLoading: waitForIsLoadingCategories} =
        useUserFilters<string>('tasks:filter:categories', OutputType.array, []);

    const {selectedOptions: assigneesToConstrainTo, waitForIsLoading: waitForIsLoadingAssignees} = useUserFilters<
        StaffModel | {id: string; fullname: string}
    >('tasks:filter:assignees', OutputType.array, []);

    const {selectedOptions: completedFilter, waitForIsLoading: waitForIsLoadingCompleted} = useUserFilters<boolean>(
        'tasks:filter:completed',
        OutputType.value,
        false
    );

    function waitForIsLoading() {
        return Promise.all([waitForIsLoadingCategories(), waitForIsLoadingAssignees(), waitForIsLoadingCompleted()]);
    }

    return {
        waitForIsLoading,
        resourceTypesToConstrainTo,
        assigneesToConstrainTo,
        completedFilter,
    };
}
