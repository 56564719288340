<template>
    <MBox class="tw-p-4">
        <div>
            <div
                class="tw-flex tw-cursor-pointer tw-flex-wrap tw-items-center tw-justify-between tw-gap-2"
                @click="showCard = !showCard"
            >
                <div>
                    <MHeading>
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-primary-500"
                            icon="fa-solid fa-chart-bar"
                        />
                        {{ __('common:pricing_calculation_method') }}
                    </MHeading>
                    <div class="tw-mt-1 tw-text-sm tw-text-gray-500">
                        {{ __('billing_setting:psu_rate') }}
                    </div>
                </div>
                <MButton
                    icon-menu
                    @click.stop="showCard = !showCard"
                >
                    <template v-if="showCard">
                        {{ __('common:actions.close') }}
                    </template>
                    <template v-else>
                        {{ __('common:actions.open') }}
                    </template>
                </MButton>
            </div>
            <div
                v-if="showCard"
                class="tw-mb-4"
            >
                <div class="tw-mt-6">
                    <MButton
                        class="tw-text-info-500"
                        variant="link"
                        @click="$refs.psuModal.show()"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-sync" />
                        </template>
                        {{ __('billing_setting:scale_caf_to_apply') }}
                    </MButton>
                    <CModal
                        ref="psuModal"
                        :header-title="__('billing_setting:scale_caf_to_apply')"
                        size="5xl"
                        @shown="onShown()"
                    >
                        <div>
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:year_one') }}
                                </MLabel>
                                <MButton
                                    class="tw-mr-3"
                                    :variant="selectedPSUConfig.year === '2022' ? 'primary' : 'light'"
                                    @click="selectedPSUConfig.year = '2022'"
                                >
                                    2022
                                </MButton>
                                <MButton
                                    class="tw-mr-3"
                                    :variant="selectedPSUConfig.year === '2023' ? 'primary' : 'light'"
                                    @click="selectedPSUConfig.year = '2023'"
                                >
                                    2023
                                </MButton>
                                <MButton
                                    :variant="selectedPSUConfig.year === '2024' ? 'primary' : 'light'"
                                    @click="selectedPSUConfig.year = '2024'"
                                >
                                    2024
                                </MButton>
                            </MFieldset>
                            <MFieldset class="tw-mt-5">
                                <MLabel>
                                    {{ __('billing_setting:organization_type') }}
                                </MLabel>
                                <MButton
                                    class="tw-mr-3"
                                    :variant="selectedPSUConfig.organization_type === 'collectif' ? 'primary' : 'light'"
                                    @click="selectedPSUConfig.organization_type = 'collectif'"
                                >
                                    {{ __('billing_setting:collective_care') }}
                                </MButton>
                                <MButton
                                    class="tw-mr-3"
                                    :variant="selectedPSUConfig.organization_type === 'parental' ? 'primary' : 'light'"
                                    @click="selectedPSUConfig.organization_type = 'parental'"
                                >
                                    {{ __('billing_setting:parent_child_reception') }}
                                </MButton>
                            </MFieldset>
                            <div
                                v-if="selectedPSUConfig.organization_type && selectedPSUConfig.year"
                                class="tw-mt-5"
                            >
                                <taux-effort
                                    :editable="false"
                                    :taux-effort="
                                        psu[selectedPSUConfig.organization_type][selectedPSUConfig.year].effort_rate
                                            .brackets
                                    "
                                />
                            </div>
                        </div>
                        <template #footer>
                            <div class="tw-ml-auto">
                                <MButton
                                    class="tw-mr-2"
                                    @click="$refs.psuModal.hide()"
                                >
                                    {{ __('common:actions.cancel') }}
                                </MButton>
                                <MButton
                                    variant="primary"
                                    @click="updateFromCAF()"
                                >
                                    {{ __('common:actions.validate') }}
                                </MButton>
                            </div>
                        </template>
                    </CModal>
                </div>
                <div class="PSU__row">
                    <div>
                        <div>{{ __('billing_setting:minimum_monthly_resource') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:minimum_monthly_resource_explanation') }}
                        </small>
                    </div>
                    <div>
                        <MCurrencyInput
                            v-model="config.pricing.discount.effort_rate.lower_limit"
                            class="tw-w-full"
                            :currency-symbol="nursery.currency"
                        />
                    </div>
                </div>
                <div class="PSU__row">
                    <div>
                        <div>{{ __('billing_setting:maximum_monthly_resource') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:maximum_monthly_resource_exaplanation') }}
                        </small>
                    </div>
                    <div>
                        <MCurrencyInput
                            v-model="config.pricing.discount.effort_rate.upper_limit"
                            class="tw-w-full"
                            :currency-symbol="nursery.currency"
                        />
                    </div>
                </div>
                <div class="tw-mt-6">
                    <div>{{ __('billing_setting:effort_rate_to_applied') }}</div>
                    <small class="small tw-text-gray-400">
                        {{ __('billing_setting:effort_rate_to_applied_exaplanation') }}
                    </small>
                </div>
                <div class="tw-mt-6">
                    <taux-effort
                        :taux-effort="config.pricing.discount.effort_rate.brackets"
                        @update-taux="tauxUpdated"
                    />
                </div>

                <!-- PRECISION / ARRONDIS -->
                <div>
                    <MButton
                        class="tw-px-1.5"
                        :label="__('billing_setting:rounding_configure')"
                        size="sm"
                        variant="link"
                        @click="showPrecisions = !showPrecisions"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-gear" />
                        </template>
                    </MButton>
                    <div
                        v-if="showPrecisions"
                        class="tw-mt-2 tw-px-2"
                    >
                        <precisions :config-key="config.billing" />
                    </div>
                </div>

                <template v-if="config.funding === 'psu_cmg'">
                    <hr class="hr" />
                    <div class="PSU__row">
                        <div>
                            <div class="tw-flex tw-flex-wrap tw-items-baseline tw-gap-2">
                                {{ __('billing_setting:amount_cmg') }}
                                <MButton
                                    :label="showCMG ? __('common:actions.hide') : __('common:actions.show')"
                                    variant="link"
                                    @click="showCMG = !showCMG"
                                />
                            </div>
                            <small class="small tw-text-gray-400">{{ __('billing_setting:based_amout_cmg') }}</small>
                        </div>
                        <div>
                            <MButton
                                :label="__('common:income_bracket_dots')"
                                variant="info"
                                @click="incomeTableModal.show"
                            />
                        </div>
                    </div>
                    <div
                        v-if="showCMG"
                        class="tw-mt-6"
                    >
                        <cmg
                            :brackets="config.pricing.discount.brackets.length"
                            :config="config"
                            :nursery="nursery"
                        />
                    </div>
                    <MModal
                        v-if="incomeTableModal.isVisible.value"
                        :header-title="__('common:income_bracket_table')"
                        :modal="incomeTableModal"
                        size="5xl"
                    >
                        <brackets v-model:prices="config.pricing.discount.brackets" />
                        <template #footer-end="{hide}">
                            <MButton
                                :label="__('common:actions.cancel')"
                                @click="hide"
                            />
                            <MButton
                                :label="__('common:actions.validate')"
                                variant="primary"
                                @click="hide"
                            />
                        </template>
                    </MModal>
                </template>
                <CHStack
                    align="center"
                    class="tw-mt-4"
                    gap="2"
                >
                    <CText>
                        {{ __('billing_setting:annual_tax_credit') }}
                    </CText>
                    <MButton
                        :label="showCI ? __('common:actions.hide') : __('common:actions.show')"
                        variant="link"
                        @click="showCI = !showCI"
                    />
                </CHStack>
                <CStack
                    v-if="showCI"
                    :direction="direction"
                    :distribute="distribute"
                    :gap="4"
                >
                    <div class="tw-w-1/2">
                        <CText
                            font-size="sm"
                            variant="gray"
                        >
                            {{ __('billing_setting:deductible_tax_credit_amount') }}
                        </CText>
                        <CTooltip placement="bottom-start">
                            <FontAwesomeIcon
                                class="tw-ml-1 tw-text-cyan-500"
                                icon="info-circle"
                            />
                            <template #content>
                                <CText>
                                    {{ __('billing_setting:tax_credit_explanation') }}
                                </CText>
                            </template>
                        </CTooltip>
                    </div>
                    <div class="tw-w-1/2">
                        <CInputGroup class="tw-w-2/3">
                            <CInput
                                v-model.number="config.pricing.discount.tax_credit"
                                placeholder="3500"
                                type="text"
                            />
                            <CInputAddon>
                                {{ nursery.currency }}
                            </CInputAddon>
                        </CInputGroup>
                    </div>
                </CStack>
            </div>
        </div>
    </MBox>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';

    import useModal from '@/modules/app/composables/useModal';
    import {useResponsiveValue} from '@/modules/app/composables/useResponsiveValue';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';
    import psu from '@/modules/legacy/contents/psu';

    import Cmg from './Array/Cmg.vue';
    import Precisions from './Array/Precisions.vue';
    import TauxEffort from './Array/TauxEffort.vue';
    import Brackets from './Brackets.vue';

    export default {
        components: {
            'brackets': Brackets,
            'taux-effort': TauxEffort,
            'cmg': Cmg,
            Precisions,
        },
        props: {
            config: {},
            nursery: {},
        },
        data() {
            return {
                showCard: false,
                showCMG: false,
                showCI: false,
                showPrecisions: false,
                selectedPSUConfig: {year: null, organization_type: null},
                psu: psu.taux_effort,
            };
        },
        computed: {
            direction() {
                return useResponsiveValue({base: 'col', md: 'row'}).value;
            },
            distribute() {
                return useResponsiveValue({md: 'between'}).value;
            },
            incomeTableModal() {
                return useModal();
            },
        },
        methods: {
            tauxUpdated(prices) {
                this.config.pricing.discount.effort_rate.brackets = prices;
            },
            updateFromCAF() {
                if (this.psu && this.selectedPSUConfig.organization_type && this.selectedPSUConfig.year) {
                    const myConfig = _cloneDeep(defaultConfig);
                    this.config.cmg.brackets = myConfig.cmg.brackets;

                    const psu = this.psu[this.selectedPSUConfig.organization_type][this.selectedPSUConfig.year];
                    this.config.pricing.discount.effort_rate.lower_limit = psu.effort_rate.lower_limit;
                    this.config.pricing.discount.effort_rate.upper_limit = psu.effort_rate.upper_limit;
                    this.config.pricing.discount.effort_rate.brackets = psu.effort_rate.brackets;

                    this.$refs.psuModal.hide();
                }
            },
            onShown() {
                this.selectedPSUConfig = {year: null, organization_type: null};
            },
        },
    };
</script>

<style scoped>
    .PSU__row {
        @apply tw-mt-6 tw-grid tw-grid-cols-1 tw-items-center tw-gap-2 sm:tw-grid-cols-2;
    }
</style>
