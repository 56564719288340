<template>
    <form @submit.prevent="save">
        <MInputGroup
            v-model="familyClone.attributes.name"
            :errors="familyError.getErrors('name')"
            :focus="true"
            :label="__('common:last_name') + '*'"
            :placeholder="__('common:last_name')"
        />
    </form>
</template>

<script lang="ts">
    import {defineComponent, type PropType, reactive, ref} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import FamilyOrganizationPivot from '@/modules/family/models/FamilyOrganizationPivot';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {},
        props: {
            organizationModel: {type: Object as PropType<OrganizationModel>, default: undefined, required: false},
            familyModel: {
                type: Object as PropType<FamilyModel>,
                default: () => reactive(new FamilyModel()),
                required: false,
            },
            name: {type: String, default: undefined, required: false},
        },
        emits: ['loading', 'saved'],
        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const familyError = ref(new ErrorHandler());
            const familyClone = ref(props.familyModel.clone());

            if (props.name) {
                familyClone.value.attributes.name = props.name;
            }

            function save() {
                emit('loading', true);

                if (props.organizationModel) {
                    familyClone.value.attributes.account_id = props.organizationModel.attributes.account_id;
                }

                familyClone.value
                    .save()
                    .then(() => {
                        if (familyClone.value.wasRecentlyCreated) {
                            // Attach to organization
                            const pivot = new FamilyOrganizationPivot();
                            pivot.attributes.account_id = familyClone.value.attributes.account_id;
                            pivot.attributes.family_id = familyClone.value.getKey();
                            pivot.attributes.organization_id = props.organizationModel
                                ? props.organizationModel.getKey()
                                : activeOrganization.value.getKey();

                            pivot.save().then(() => {
                                emit('saved', familyClone.value);
                                emit('loading', false);
                            });
                        } else {
                            emit('saved', familyClone.value);
                            emit('loading', false);
                        }
                    })
                    .catch(error => {
                        emit('loading', false);
                        familyError.value.reset(error);
                    });
            }

            return {
                save,
                familyClone,
                familyError,
            };
        },
    });
</script>
