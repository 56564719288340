import type {ModelCollection} from '@meekohq/lumos';

import AllocationModel from '@/modules/cashier/models/AllocationModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

export async function loadTransactionsRelationships(collection: ModelCollection<TransactionModel>) {
    await collection.load({
        allocationsAsSource: query2 => {
            query2.with(new AllocationModel().destination(), query3 => {
                query3.with(new PaymentModel().invoices(), query4 => {
                    query4.with(new InvoiceModel().payments());
                });
            });
        },
        allocationsAsDestination: query2 => {
            query2.with(new AllocationModel().source(), query3 => {
                query3.with(new PaymentModel().creditNotes(), query4 => {
                    query4.with(new InvoiceModel().refunds());
                });
            });
        },
        tenant: () => {},
        customer: () => {},
        paymentMethod: () => {},
        currency: () => {},
    });
}
