<script setup lang="ts"></script>

<template>
    <div class="MagicIndexHeader">
        <slot />
    </div>
</template>

<style scoped>
    .MagicIndexHeader {
        @apply tw-hidden tw-grid-cols-24 tw-gap-3 lg:tw-grid;
    }
</style>
