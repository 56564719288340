<template>
    <WidgetPortal>
        <template #clickable="{toggle}">
            <MButton
                class="tw-relative"
                round
                zoom
                @click.stop.prevent="onClick(toggle)"
            >
                <FontAwesomeIcon
                    class="tw-text-yellow-600"
                    icon="fa-solid fa-bell"
                />
                <div
                    v-if="unread"
                    class="tw-absolute tw--right-2 tw--top-2"
                >
                    <MNotificationBadge :count="unread" />
                </div>
            </MButton>
        </template>
        <template #default="{toggle}">
            <NotificationsWidget
                class="NotificationsWidgetToggle__widget"
                :nursery="nursery"
                :user="user"
                @close="toggle"
            />
        </template>
    </WidgetPortal>
</template>

<script>
    import moment from 'moment';

    import {EventBus} from '@/eventBus';
    import WidgetPortal from '@/modules/app/components/atoms/WidgetPortal.vue';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import MNotificationBadge from '@/modules/meeko-ui/components/MNotificationBadge.vue';
    import NotificationsWidget from '@/modules/notification/components/NotificationsWidget.vue';

    export default {
        components: {NotificationsWidget, WidgetPortal, MNotificationBadge},
        props: {
            user: {
                type: Object,
            },
            nursery: {type: Object},
        },
        data: () => ({
            unread: 0,
        }),
        computed: {
            moment() {
                return moment;
            },
        },
        mounted() {
            this.getNotifications();

            EventBus.$on('create:notification', this.handleCreateNotificationEvent);
            EventBus.$on('markAllAsRead', this.handleMarkAllAsReadEvent);
        },
        beforeUnmount() {
            EventBus.$off('create:notification', this.handleCreateNotificationEvent);
            EventBus.$off('markAllAsRead', this.handleMarkAllAsReadEvent);
        },
        methods: {
            handleCreateNotificationEvent() {
                this.unread += 1;
            },
            handleMarkAllAsReadEvent() {
                this.getNotifications();
            },
            getNotifications() {
                useApi()
                    .legacy.get(route('notifications.index'), {
                        params: {
                            page: 1,
                            types: [
                                'message-request',
                                'occasional-request',
                                'absence-request',
                                'late-request',
                                'invoice-unpaid',
                                'presence-unclocked',
                                'staff-presence-unclocked',
                                'new-registration-form',
                                'new-document',
                                'new-post',
                                'throttle-login',
                                'updated-document',
                                'updated-registration-form',
                                'task-registration-reminder',
                                'task-vaccine-reminder',
                                'meeting',
                            ],
                        },
                    })
                    .then(response => {
                        this.unread = response.data.data.filter(notif => notif.read_at == null).length;
                    });
            },
            markAsRead() {
                this.unread = 0;
            },
            onClick(toggle) {
                toggle();
                this.markAsRead();
            },
        },
    };
</script>

<style scoped>
    .NotificationsWidgetToggle__widget {
        @apply tw-flex tw-w-full tw-flex-col sm:tw-w-96;
    }
</style>
