import {Binding} from '@meekohq/lumos';

import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';

export const GetAllocationsForInvoiceUseCaseBinding = new Binding<GetAllocationsForInvoiceUseCase>();

export interface GetAllocationsForInvoiceUseCase {
    fetch(payment: PaymentModel): Promise<AbstractAllocationAggregate[]>;
}
