<script setup lang="ts">
    import type {ModalType} from '@/modules/app/composables/useModal';
    import useModalManager from '@/modules/app/composables/useModalManager';
    // import {useRoute} from 'vue-router';
    // import {watch} from 'vue';

    const {stack} = useModalManager();

    function prepareListeners(item: ModalType) {
        if (item.modal.value.listeners) {
            return item.modal.value.listeners?.(item);
        }

        return {};
    }

    // const route = useRoute();
    //
    // watch(() => route.name, () => {
    //     for (const item of stack.value) {
    //         item.hide();
    //     }
    // });
</script>

<template>
    <div>
        <Teleport
            v-for="item in stack"
            :key="item.id"
            to="#overlay"
        >
            <component
                :is="item.modal.value.component"
                v-bind="item.modal.value.props"
                :modal="item"
                v-on="prepareListeners(item)"
            />
        </Teleport>
    </div>
</template>
