<template>
    <CVStack
        align="center"
        class="pattern tw-h-auto tw-min-h-screen"
        distribute="center"
    >
        <CCard class="tw-w-96 tw-items-center tw-justify-center tw-p-3">
            <template #header>
                <CCardHeroTitle class="tw-flex tw-items-center tw-justify-center">
                    {{ __('common:errors.generic') }}
                </CCardHeroTitle>
            </template>
            <div class="tw-flex tw-w-full tw-items-center">
                <CText
                    class="tw-w-72"
                    font-size="2xl"
                >
                    {{ __('user:email_not_verified_with_instruction') }}
                </CText>
                <div class="tw-w-12">
                    <FontAwesomeIcon
                        class="tw-text-red-500"
                        icon="fa-solid fa-times-circle"
                        size="2x"
                    />
                </div>
            </div>
        </CCard>
    </CVStack>
</template>
