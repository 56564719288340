<script setup lang="ts">
    import {useElementHover} from '@vueuse/core';
    import {inject, ref, watch} from 'vue';

    import type {MCalendarDaysItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarDays';
    import {keyMCalendarDays} from '@/modules/meeko-ui/components/MCalendar/useMCalendarDays';

    const props = defineProps<{
        day: MCalendarDaysItem;
    }>();

    const daysState = inject(keyMCalendarDays);
    if (!daysState) {
        throw new Error('keyMCalendarDays must be provided');
    }

    const {hoveredDay, handleDayClick} = daysState;

    const dayElement = ref();
    const isHovered = useElementHover(dayElement);

    watch(isHovered, newValue => {
        if (newValue) {
            hoveredDay.value = props.day.day;
        }
    });
</script>

<template>
    <div
        ref="dayElement"
        class="MCalendarDayItem"
        @click="handleDayClick(day.day)"
    >
        <div
            class="MCalendarDayItem__inner"
            :class="{
                'MCalendarDayItem__inner--out-of-current-month': day.isOutOfMonth,
                'MCalendarDayItem__inner--today': day.isToday,
                'MCalendarDayItem__inner--selecting': day.isSelecting,
                'MCalendarDayItem__inner--in-period': day.isInPeriod,
                'MCalendarDayItem__inner--hovered': day.isHovered,
                'MCalendarDayItem__inner--selected': day.isSelected,
            }"
        >
            {{ day.day.toFormat('dd') }}
        </div>
    </div>
</template>

<style scoped>
    .MCalendarDayItem {
        @apply tw-flex tw-aspect-square tw-w-9 tw-cursor-pointer tw-select-none tw-p-0.5;
    }

    .MCalendarDayItem__inner {
        @apply tw-relative;
        @apply tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-rounded-md;
        @apply tw-font-display tw-text-gray-800 tw-transition-all;
    }

    .MCalendarDayItem__inner--hovered {
        @apply tw-bg-gray-200;
    }

    .MCalendarDayItem__inner--out-of-current-month {
        @apply tw-text-gray-400;
    }

    .MCalendarDayItem__inner--today {
        &:before {
            @apply tw-absolute tw--right-0.5 tw--top-0.5 tw-content-[''];
            @apply tw-aspect-square tw-w-2 tw-rounded-full tw-bg-red-500;
        }
    }

    .MCalendarDayItem__inner--in-period {
        &:not(.MCalendarDayItem__inner--selecting) {
            @apply tw-bg-primary-200 tw-text-primary-900;
        }

        &.MCalendarDayItem__inner--selecting {
            @apply tw-bg-gray-200;
        }
    }

    .MCalendarDayItem__inner--selected {
        background-color: theme(backgroundColor.primary.500) !important;
        color: theme(textColor.white) !important;

        &.MCalendarDayItem__inner--hovered {
            background-color: theme(backgroundColor.primary.600) !important;
        }
    }
</style>
