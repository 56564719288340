import {Binding} from '@meekohq/lumos';

export const BroadcastContractBinding = new Binding<BroadcastContract>();

export interface BroadcastContract {
    privateChannel: any;
    sessionChannel: any;
    notificationEvent: string;

    setup(userId: string, apiToken: string);

    disconnect();
}
