import _maxBy from 'lodash-es/maxBy';
import _round from 'lodash-es/round';
import _sumBy from 'lodash-es/sumBy';
import moment, {type Moment} from 'moment';
import {computed, type Ref} from 'vue';

import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
import type {OccupationType, SummaryType} from '@/modules/planning/utils/core/LegacySummaryTypes';

export default function (occupations: Ref<OccupationType[]>, day: Ref<Moment | null>) {
    const {selectedOptions: userDaySeparator} = useUserFilters(
        'plannings:kids:display:daySeparator',
        OutputType.value,
        'day'
    );
    const {selectedOptions: halfdayTime} = useUserFilters(
        'plannings:kids:display:halfdayTime',
        OutputType.value,
        '12:30'
    );
    const {selectedOptions: userDisplayCount} = useUserFilters('plannings:kids:display:count', OutputType.value, true);
    const {selectedOptions: userDisplayRate} = useUserFilters('plannings:kids:display:rate', OutputType.value, true);
    const {selectedOptions: ratePrecision} = useUserFilters(
        'plannings:kids:display:ratePrecision',
        OutputType.value,
        0
    );

    const occupation = computed(() => {
        return occupations.value.find(o => o.date === day.value?.clone().format('YYYY-MM-DD'));
    });

    /**
     * @returns Occupation rate truncated by user's precision
     */
    function getOccupationRate() {
        if (day.value) {
            const precision = Number(ratePrecision.value) ?? 0;

            return occupation.value ? occupation.value.percent.toFixed(precision) : null;
        }

        return getAverageOccupationRate();
    }

    /**
     * @returns Average occupation rate
     */
    function getAverageOccupationRate() {
        const precision = Number(ratePrecision.value) ?? 0;
        const total = _round(_sumBy(occupations.value, 'percent') / occupations.value.length, precision);

        return total ? total.toFixed(precision) : null;
    }

    /**
     * @returns Max number of kids per day
     */
    function getFulldayCount() {
        return occupation.value ? occupation.value.kids : null;
    }

    /**
     * @param half Which half to return (first or second)
     * @returns Max number of kids for selected half
     */
    function getHalfdayCount(half: 0 | 1) {
        const halfdayDateTime = moment(`${day.value?.format('YYYY-MM-DD')} ${halfdayTime.value}`);

        const firstHalf: SummaryType[] = [];
        const secondHalf: SummaryType[] = [];

        occupation.value?.summary.forEach(summary => {
            // Le segment d'occupation commence à partir de l'heure de milieu, on l'ajoute que le soir
            if (summary.started_at >= halfdayDateTime.unix()) {
                secondHalf.push(summary);
                // Si le segment d'occupation englobe l'heure de milieu de journée, on l'ajoute matin et soir
            } else if (
                halfdayDateTime.isBetween(
                    moment.unix(summary.started_at),
                    moment.unix(summary.ended_at),
                    'minutes',
                    '[]'
                )
            ) {
                firstHalf.push(summary);
                secondHalf.push(summary);
            } else {
                firstHalf.push(summary);
            }
        });

        const maxFirstHalf = _maxBy(firstHalf, 'kids')?.kids ?? 0;
        const maxSecondHalf = _maxBy(secondHalf, 'kids')?.kids ?? 0;

        return half === 0 ? maxFirstHalf : maxSecondHalf;
    }

    return {
        userDaySeparator,
        userDisplayCount,
        userDisplayRate,
        getOccupationRate,
        getAverageOccupationRate,
        getFulldayCount,
        getHalfdayCount,
    };
}
