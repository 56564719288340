import __ from '@/modules/app/utils/i18n-facade';
import type RegistrationModel from '@/modules/registration/models/RegistrationModel';

export default function (registration: RegistrationModel) {
    function getParentAttr(parentPosition: 'first' | 'second', attribute: string) {
        return registration.attributes[`${parentPosition}_parent_${attribute}`];
    }

    function getParentFullname(parentPosition: 'first' | 'second') {
        if (getParentAttr(parentPosition, 'first_name') || getParentAttr(parentPosition, 'last_name')) {
            return `${getParentAttr(parentPosition, 'first_name')} ${getParentAttr(parentPosition, 'last_name')}`;
        } else if (getParentAttr(parentPosition, 'email')) {
            return getParentAttr(parentPosition, 'email');
        } else {
            return parentPosition == 'first'
                ? __('common:parent_with_count', {count: 1})
                : __('common:parent_with_count', {count: 2});
        }
    }

    function getFirstParentAttr(attribute: string) {
        return getParentAttr('first', attribute);
    }

    function getFirstParentFullname() {
        return getParentFullname('first');
    }

    function getSecondParentAttr(attribute: string) {
        return getParentAttr('second', attribute);
    }

    function getSecondParentFullname() {
        return getParentFullname('second');
    }

    return {
        getFirstParentAttr,
        getFirstParentFullname,
        getSecondParentAttr,
        getSecondParentFullname,
    };
}
