<template>
    <component
        :is="as"
        :aria-hidden="!!icon"
        :class="baseClass"
    >
        <slot v-if="!icon" />
    </component>
</template>

<script lang="ts">
    import {get} from 'lodash-es';
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            as: {type: String, default: 'span'},
            align: {type: String, default: ''},
            family: {type: String, default: 'base'},
            fontSize: {type: String, default: 'base'},
            fontWeight: {type: String, default: 'normal'},
            variant: {type: String, default: 'default'},
            emphase: {type: Boolean, default: false},
            uppercase: {type: Boolean, default: false},
            ellipsis: {type: Boolean, default: false},
            icon: {type: String},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as any[];

                output.push(get(variantValues, props.variant));

                if (props.icon) {
                    output.push(props.icon);
                } else {
                    output.push(get(familyValues, props.family));
                }

                if (props.emphase) {
                    output.push('CText-emphase');
                }

                if (props.uppercase) {
                    output.push('CText-uppercase');
                }

                if (props.ellipsis) {
                    output.push('CText-ellipsis');
                }

                output.push(get(alignValues, props.align));
                output.push(get(fontSizeValues, props.fontSize));
                output.push(get(fontWeightValues, props.fontWeight));

                return output;
            });

            return {
                baseClass,
            };
        },
    });

    export const familyValues = {
        base: '',
        grandhotel: 'CText--family-grandhotel',
        display: 'CText--family-display',
    };

    export const variantValues = {
        default: 'CText--variant-default',
        white: 'CText--variant-white',
        black: 'CText--variant-black',
        primary: 'CText--variant-primary',
        success: 'CText--variant-success',
        danger: 'CText--variant-danger',
        warning: 'CText--variant-warning',
        disabled: 'CText--variant-disabled',
        gray: 'CText--variant-gray',
        girl: 'CText--variant-girl',
        boy: 'CText--variant-boy',
        female: 'CText--variant-girl',
        male: 'CText--variant-boy',
        neutral: 'CText--variant-neutral',
    };

    export const alignValues = {
        left: 'CText--align-left',
        right: 'CText--align-right',
        center: 'CText--align-center',
    };

    export const fontSizeValues = {
        'xs': 'CText--size-xs',
        'sm': 'CText--size-sm',
        'base': 'CText--size-base',
        'lg': 'CText--size-lg',
        'xl': 'CText--size-xl',
        '2xl': 'CText--size-2xl',
        '3xl': 'CText--size-3xl',
        '4xl': 'CText--size-4xl',
        '5xl': 'CText--size-5xl',
    };

    export const fontWeightValues = {
        thin: 'CText--weight-thin',
        extralight: 'CText--weight-extralight',
        light: 'CText--weight-light',
        normal: 'CText--weight-normal',
        medium: 'CText--weight-medium',
        semibold: 'CText--weight-semibold',
        bold: 'CText--weight-bold',
        extrabold: 'CText--weight-extrabold',
        black: 'CText--weight-black',
    };
</script>

<style scoped>
    .CText--family-grandhotel {
        @apply tw-font-grandhotel tw-antialiased;
    }

    .CText--family-display {
        @apply tw-font-display tw-antialiased;
    }

    .CText--variant-black {
        @apply tw-text-black;
    }

    .CText--variant-white {
        @apply tw-text-white;
    }

    .CText--align-left {
        @apply tw-text-left;
    }

    .CText--align-right {
        @apply tw-text-right;
    }

    .CText--align-center {
        @apply tw-text-center;
    }

    .CText--variant-default {
        @apply tw-text-gray-800;
    }

    .CText--variant-black {
        @apply tw-text-black;
    }

    .CText--variant-white {
        @apply tw-text-white;
    }

    .CText--variant-primary {
        @apply tw-text-primary-500;
    }

    .CText--variant-success {
        @apply tw-text-green-600;
    }

    .CText--variant-danger {
        @apply tw-text-red-600;
    }

    .CText--variant-warning {
        @apply tw-text-yellow-700;
    }

    .CText--variant-disabled {
        @apply tw-text-disabled;
    }

    .CText--variant-gray {
        @apply tw-text-gray-500;
    }

    .CText-uppercase {
        @apply tw-uppercase;
    }

    .CText-strong {
        @apply tw-font-semibold;
    }

    .CText-emphase {
        @apply tw-italic;
    }

    .CText--size-xs {
        @apply tw-text-xs;
    }

    .CText--size-sm {
        @apply tw-text-sm;
    }

    .CText--size-13 {
        @apply tw-text-sm;
    }

    .CText--size-base {
        @apply tw-text-base;
    }

    .CText--size-lg {
        @apply tw-text-lg;
    }

    .CText--size-xl {
        @apply tw-text-xl;
    }

    .CText--size-2xl {
        @apply tw-text-2xl;
    }

    .CText--size-3xl {
        @apply tw-text-3xl;
    }

    .CText--size-4xl {
        @apply tw-text-4xl;
    }

    .CText--size-5xl {
        @apply tw-text-5xl;
    }

    .CText--weight-thin {
        @apply tw-font-thin;
    }

    .CText--weight-extralight {
        @apply tw-font-extralight;
    }

    .CText--weight-light {
        @apply tw-font-light;
    }

    .CText--weight-normal {
        @apply tw-font-normal;
    }

    .CText--weight-medium {
        @apply tw-font-medium;
    }

    .CText--weight-semibold {
        @apply tw-font-semibold;
    }

    .CText--weight-bold {
        @apply tw-font-bold;
    }

    .CText--weight-extrabold {
        @apply tw-font-extrabold;
    }

    .CText--weight-black {
        @apply tw-font-black;
    }

    .CText-ellipsis {
        @apply tw-whitespace-nowrap;
        @apply tw-overflow-x-hidden;
        @apply tw-overflow-ellipsis;
    }

    .CText--variant-girl {
        @apply tw-text-girl;
    }

    .CText--variant-boy {
        @apply tw-text-boy;
    }

    .CText--variant-neutral {
        @apply tw-text-teal-600;
    }
</style>
