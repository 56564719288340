<template>
    <svg
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.5 0a2 2 0 0 0-1.96 1.62l-3.5 18A2 2 0 0 0 0 20v12c0 2.19 1.81 4 4 4h28c2.19 0 4-1.81 4-4V20a2 2 0 0 0-.04-.38l-3.5-18A2 2 0 0 0 30.5 0h-25Zm1.62 4h21.76L32 20h-5.98a2 2 0 0 0-1.9 1.37l-1.2 3.58c-.2.62-.8 1.05-1.47 1.05h-6.9c-.67 0-1.26-.42-1.47-1.05l-1.2-3.58A2 2 0 0 0 9.98 20H4L7.12 4Z"
            fill="#9AADC1"
        />
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
