import {toNumber} from 'lodash-es';

type StrategyType = 'round' | 'truncate';

interface OptionsType {
    countryCode?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    strategy?: StrategyType;
    currencyCode?: string;
}

export default class NumberFormatter {
    protected _countryCode: string | undefined;
    protected _value = NaN;
    protected _minimumFractionDigits: number;
    protected _maximumFractionDigits: number | undefined;
    protected _strategy: StrategyType;
    protected _currencyCode: string | undefined;

    constructor(options?: OptionsType) {
        this._countryCode = options?.countryCode;
        this._minimumFractionDigits = options?.minimumFractionDigits ?? 0;
        this._maximumFractionDigits = options?.maximumFractionDigits ?? 20;
        this._strategy = options?.strategy ?? 'round';
        this._currencyCode = options?.currencyCode;
    }

    get asNumber() {
        return this.processValue(this._value);
    }

    get asString() {
        return this.toString(this.asNumber);
    }

    get isValid() {
        return !isNaN(this._value);
    }

    public parse(value: number | string) {
        this._value = this.sanitizeValue(value);

        return this;
    }

    protected processValue(value: number) {
        let decimalNeeded = Math.max(this._minimumFractionDigits, this.getDecimalPlaces(value));

        // If there is more decimal places than the max allowed, we set to the max value
        if (this._maximumFractionDigits && decimalNeeded > this._maximumFractionDigits) {
            decimalNeeded = this._maximumFractionDigits;
        }

        if (this._strategy === 'truncate') {
            return this.truncate(value, decimalNeeded);
        }

        return this.round(value, decimalNeeded);
    }

    protected toString(value: number) {
        const numberFormatter = new Intl.NumberFormat(this._countryCode, {
            style: this._currencyCode ? 'currency' : 'decimal',
            currency: this._currencyCode,
            minimumFractionDigits: this._minimumFractionDigits,
            maximumFractionDigits: this._maximumFractionDigits,
        });

        return numberFormatter.format(value);
    }

    protected round(value: number, precision: number) {
        // Round strategy to avoid negative number rounding issues
        // https://github.com/lodash/lodash/issues/4670
        const func = x => Math.sign(x) * Math.floor(Math.abs(x) + 0.5);

        value = +value;
        precision = precision == null ? 0 : Math.max(Math.min(precision, 292), -292);

        if (precision && isFinite(value)) {
            // Shift with exponential notation to avoid floating-point issues.
            let pair = `${value}e`.split('e');
            const roundedValue = func(+`${pair[0]}e${+pair[1] + precision}`);

            pair = `${roundedValue}e`.split('e');

            return +`${pair[0]}e${+pair[1] - precision}`;
        }

        return func(value);
    }

    protected getDecimalPlaces(value: number) {
        return (value.toString().split('.')[1] || []).length;
    }

    private truncate(value: number, decimalNeeded: number) {
        const integer = value.toString().split('.')[0] ?? value;
        let decimals = value.toString().split('.')[1] || undefined;

        if (decimals) {
            decimals = decimals.substring(0, decimalNeeded);
        }

        return Number(integer + (decimals ? '.' + decimals : ''));
    }

    private sanitizeValue(value: string | number) {
        if (typeof value === 'string') {
            value = value.replace(/\s/g, '');
            value = value.replace("'", '');
            value = value.replace(',', '.');

            const valueArray = value.split('.');
            if (valueArray.length > 1) {
                const lastBlock = valueArray[valueArray.length - 1];
                value = valueArray.slice(0, valueArray.length - 1).join('') + '.' + lastBlock;
            }

            value = value.replace(/(^,)|(,$)/g, '');
            value = value.replace(/(^\.)|(\.$)/g, '');
        }

        return toNumber(value);
    }
}
