<template>
    <div class="CTabMenuItem">
        <router-link
            v-if="to"
            :active-class="baseActiveClass"
            :class="[baseClass, 'tw-no-underline', 'tw-inline-block']"
            :to="to"
        >
            <slot />
        </router-link>
        <div
            v-else
            :class="baseClass"
        >
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            active: {type: Boolean, default: false},
            variant: {type: String, default: 'default'},
            to: {type: [String, Object], default: undefined},
        },
        setup(props) {
            const baseClass = computed(() => {
                const output = [] as string[];
                output.push('tw-flex tw-items-center');
                output.push(
                    'tw-px-2 tw-py-1 tw-m-1 tw-rounded-lg tw-cursor-pointer tw-transition tw-duration-150 tw-text-base tw-font-semibold'
                );

                const variants = {
                    default: {
                        base: 'tw-text-white',
                        active: 'tw-bg-white tw-shadow-sm !tw-text-blue-500',
                        inactive: 'tw-text-white hover:tw-shadow-sm hover:tw-bg-white hover:tw-text-blue-500',
                    },
                    orange: {
                        base: 'tw-text-white',
                        active: 'tw-bg-white tw-shadow-sm !tw-text-orange-500',
                        inactive: 'tw-text-white hover:tw-shadow-sm hover:tw-bg-white hover:tw-text-orange-500',
                    },
                    pink: {
                        base: 'tw-text-white',
                        active: 'tw-bg-white tw-shadow-sm !tw-text-pink-500',
                        inactive: 'tw-text-white hover:tw-shadow-sm hover:tw-bg-white hover:tw-text-pink-500',
                    },
                    equipment: {
                        base: 'tw-text-white',
                        active: 'tw-bg-white tw-shadow-sm !tw-text-modules-equipment-500',
                        inactive:
                            'tw-text-white hover:tw-shadow-sm hover:tw-bg-white hover:tw-text-modules-equipment-500',
                    },
                    family: {
                        base: 'tw-text-white',
                        active: 'tw-bg-white tw-shadow-sm !tw-text-purple-500',
                        inactive: 'tw-text-white hover:tw-shadow-sm hover:tw-bg-white hover:tw-text-purple-500',
                    },
                    settings: {
                        base: 'tw-text-white',
                        active: 'tw-bg-white tw-shadow-sm !tw-text-gray-500',
                        inactive: 'tw-text-white hover:tw-shadow-sm hover:tw-bg-white hover:tw-text-gray-500',
                    },
                };

                output.push(variants[props.variant]['base']);

                if (props.active && !props.to) {
                    output.push(variants[props.variant]['active']);
                } else {
                    output.push(variants[props.variant]['inactive']);
                }

                return output;
            });

            const baseActiveClass = computed(() => {
                const output = [] as string[];

                output.push('tw-bg-white tw-shadow-sm');

                const variants = {
                    default: '!tw-text-blue-500',
                    orange: '!tw-text-orange-500',
                    pink: '!tw-text-pink-500',
                    equipment: '!tw-text-modules-equipment-500',
                };
                output.push(variants[props.variant]);

                return output.join(' ');
            });

            return {
                baseClass,
                baseActiveClass,
            };
        },
    });
</script>
