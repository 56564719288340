import {
    arrow as fArrow,
    autoUpdate,
    flip,
    offset as fOffset,
    type Placement,
    shift,
    useFloating,
} from '@floating-ui/vue';
import {pull} from 'lodash-es';
import mitt from 'mitt';
import {computed, nextTick, type Ref, ref, watch} from 'vue';

import useUniqueComponentId from '@/modules/app/composables/useUniqueComponentId';

export type Trigger = 'click' | 'hover' | 'none';

interface FloatingContextOptions {
    bus: ReturnType<typeof defineFloatingContextBus>;
    placement: Ref<Placement>;
    trigger: Ref<Trigger>;
    initialFocusRefEl: Ref<any>;
    portal: Ref<boolean>;
    arrow: Ref<boolean>;
    arrowPadding: Ref<number>;
    offset: Ref<number>;
    returnFocusOnClose: Ref<boolean>;
    closeOnEscape: Ref<boolean>;
}

function getDefaultValues(): FloatingContextOptions {
    return {
        bus: defineFloatingContextBus(),
        placement: ref<Placement>('bottom-start'),
        trigger: ref<Trigger>('click'),
        initialFocusRefEl: ref(),
        portal: ref(true),
        arrow: ref(false),
        arrowPadding: ref(10),
        offset: ref(11),
        returnFocusOnClose: ref(true),
        closeOnEscape: ref(true),
    };
}

export function defineFloatingContext(opts: Partial<FloatingContextOptions> = {}) {
    const options = {...getDefaultValues(), ...opts} as FloatingContextOptions;

    const hoveringDelay = 300;

    const {
        placement,
        offset,
        arrow,
        arrowPadding,
        portal,
        trigger,
        closeOnEscape,
        initialFocusRefEl,
        returnFocusOnClose,
    } = options;

    const isInjected = ref(false);
    const isVisible = ref(false);
    const prevIsVisible = ref(isVisible.value);
    const isHovered = ref(false);
    const isReady = ref(false);

    const buttonEl = ref<HTMLElement>();
    const floatingEl = ref<HTMLElement>();
    const panelEl = ref<HTMLElement>();
    const arrowEl = ref<HTMLElement>();

    let panelElFn: undefined | (() => HTMLElement) = undefined;
    let floatingElFn: undefined | (() => HTMLElement) = undefined;
    let arrowElFn: undefined | (() => HTMLElement) = undefined;

    function setButtonElement(el: Ref<HTMLElement>) {
        buttonEl.value = el.value;
    }

    function setFloatingElement(el: Ref<HTMLElement>) {
        floatingElFn = () => el.value;
    }

    function setPanelElement(el: Ref<HTMLElement>) {
        panelElFn = () => el.value;
    }

    function setArrowElement(el: Ref<HTMLElement>) {
        arrowElFn = () => el.value;
    }

    const {
        floatingStyles,
        middlewareData,
        placement: finalPlacement,
        isPositioned,
    } = useFloating(buttonEl, floatingEl, {
        whileElementsMounted: autoUpdate,
        middleware: [
            fOffset(offset.value),
            flip({
                fallbackAxisSideDirection: 'end',
            }),
            shift(),
            fArrow({
                element: arrowEl,
                padding: arrowPadding.value,
            }),
        ],
        placement,
    });

    function togglePopover() {
        if (isVisible.value) {
            closePopover();
        } else {
            openPopover();
        }
    }

    function openPopover() {
        if (isVisible.value) {
            return;
        }

        prevIsVisible.value = isVisible.value;

        isInjected.value = true;
        // Now that Floating is injected (mounted and available), elements ref exists and can be identified
        nextTick(() => {
            // We identify the elements
            floatingEl.value = floatingElFn?.();
            panelEl.value = panelElFn?.();
            arrowEl.value = arrowElFn?.();
            if (panelEl.value) {
                parentContext?.addChildren(panelEl as Ref<HTMLElement>);
            }

            // We set floating content as visible
            isVisible.value = true;
        });
    }

    function closePopover(focusButton = false) {
        if (!isVisible.value) {
            return;
        }

        if (panelEl.value) {
            parentContext?.removeChildren(panelEl as Ref<HTMLElement>);
        }

        prevIsVisible.value = isVisible.value;
        isVisible.value = false;

        focusParent(
            buttonEl.value,
            focusButton,
            isVisible.value,
            prevIsVisible.value,
            trigger.value,
            returnFocusOnClose.value
        );
    }

    let timeout: any = undefined;

    function setHovered(hovered: boolean) {
        clearTimeout(timeout);
        if (hovered) {
            isHovered.value = true;
            timeout = setTimeout(openPopover, hoveringDelay);
        } else {
            isHovered.value = false;
            timeout = setTimeout(() => {
                if (isHovered.value === false) {
                    closePopover();
                }
            }, hoveringDelay);
        }
    }

    watch([isVisible, prevIsVisible], () => {
        if (isVisible.value && trigger.value === 'click') {
            nextTick(() => {
                setTimeout(() => {
                    if (initialFocusRefEl.value) {
                        initialFocusRefEl.value.$el.focus();
                    } else {
                        panelEl.value?.focus();
                    }
                }, 200);
            });
        }
    });

    const popoverId = useUniqueComponentId();
    const buttonId = computed(() => `popover-button-${popoverId}`);
    const panelId = computed(() => `popover-panel-${popoverId}`);

    const childrens: Ref<Ref<HTMLElement>[]> = ref([]);

    function addChildren(el: Ref<HTMLElement>) {
        childrens.value.push(el);

        // If the parent context is defined, we cascade the children
        if (parentContext) {
            parentContext.addChildren(el);
        }
    }

    function removeChildren(el: Ref<HTMLElement>) {
        pull(childrens.value, el);

        // If the parent context is defined, we cascade the children
        if (parentContext) {
            parentContext.removeChildren(el);
        }
    }

    let parentContext: ReturnType<typeof defineFloatingContext> | undefined = undefined;

    function setParentContext(ctx: ReturnType<typeof defineFloatingContext> | undefined) {
        parentContext = ctx;
    }

    options.bus.on('open', () => openPopover());
    options.bus.on('close', () => closePopover());
    options.bus.on('toggle', () => togglePopover());

    return {
        buttonId,
        panelId,
        setPanelElement,
        setButtonElement,
        setArrowElement,
        setFloatingElement,
        buttonEl,
        panelEl,
        isInjected,
        isVisible,
        openPopover,
        closePopover,
        togglePopover,
        setHovered,
        trigger,
        floatingStyles,
        middlewareData,
        closeOnEscape,
        placement,
        finalPlacement,
        portal,
        isPositioned,
        isReady,
        arrow,
        childrens,
        addChildren,
        removeChildren,
        setParentContext,
    };
}

export function focusParent(
    buttonEl: HTMLElement | undefined,
    focusButton: boolean,
    isVisible: boolean,
    prevIsVisible: boolean,
    trigger: string,
    returnFocusOnClose: boolean
) {
    if (focusButton && !isVisible && prevIsVisible && trigger === 'click' && returnFocusOnClose) {
        nextTick(() => {
            if (buttonEl && buttonEl?.children.length === 1) {
                const children = buttonEl.children[0] as HTMLElement;

                if (children.getAttribute('data-focus-children')) {
                    (children.children[0] as HTMLElement).focus();

                    return;
                }

                children.focus();
            }
        });
    }
}

export interface Event {
    open: void;
    close: void;
    toggle: void;
}

export function defineFloatingContextBus() {
    return mitt<Event>();
}
