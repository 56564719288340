<template>
    <router-link
        class="suggestion"
        :to="to"
    >
        <div class="header tw-flex tw-items-center">
            <MAvatar
                class="avatar"
                fallback-initials
                :gender="suggestion.gender"
                :name="suggestion.title"
                :url="suggestion.avatar_url"
            />
            <div
                class="title"
                v-html="highlighted(suggestion.title)"
            />
        </div>
        <div class="additionals">
            <dl
                v-if="!_isEmpty(suggestion.additionals)"
                class="additional tw-mb-0"
            >
                <template
                    v-for="(value, i) in suggestion.additionals"
                    :key="i"
                >
                    <dt>{{ translateLabel(value.label) }}</dt>
                    <dd v-html="highlighted(value.value)" />
                </template>
            </dl>
        </div>
    </router-link>
</template>

<script>
    import _isEmpty from 'lodash-es/isEmpty';

    import __ from '@/modules/app/utils/i18n-facade';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';

    export default {
        components: {},
        props: ['suggestion', 'query'],
        computed: {
            to() {
                // TODO : FIX MULTIPLE NURSERIES STAFF ROUTE
                return {
                    name: 'staffs.show',
                    params: {
                        nursery: this.suggestion.bindings.nursery_id,
                        staff: this.suggestion.bindings.staff_id,
                    },
                };
            },
            _isEmpty() {
                return _isEmpty;
            },
        },
        methods: {
            highlighted(value) {
                return useHighlighter().highlight(this.query, value);
            },
            translateLabel(label) {
                if (label === 'job') {
                    return __('hr:job_one');
                }

                if (label === 'landline') {
                    return __('common:phone.landline');
                }

                if (label === 'cellular') {
                    return __('common:phone.cellular');
                }

                return label;
            },
        },
    };
</script>
