<template>
    <CVStack gap="4">
        <CHStack
            align="center"
            class="tw-w-full"
            gap="4"
        >
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="4"
        >
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
            <CSkeletonItem class="TaxCertificates__skeleton-item" />
        </CHStack>
    </CVStack>
</template>

<style scoped>
    .TaxCertificates__skeleton-item {
        @apply tw-h-16 tw-w-full;
        @apply tw-flex tw-items-center;
        @apply tw-bg-gradient-to-br tw-from-gray-100 tw-to-blue-50;
        @apply tw-rounded-xl;
    }
</style>
