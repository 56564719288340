export enum ReportingType {
    activities = 'activities',
    diapers = 'diapers',
    drugs = 'drugs',
    meals = 'meals',
    naps = 'naps',
    observations = 'observations',
    photos = 'photos',
    presences = 'presences',
    temperatures = 'temperatures',
    weights = 'weights',
}
