<script setup lang="ts">
    import {useSlots} from 'vue';

    interface Props {
        icon?: string;
        label?: string;
    }

    withDefaults(defineProps<Props>(), {
        icon: undefined,
        label: undefined,
    });

    const slots = useSlots();

    const hasIconSlot = slots.icon !== undefined;
</script>

<template>
    <label class="MLabel">
        <span
            v-if="hasIconSlot || icon"
            class="MLabel__icon"
        >
            <slot name="icon">
                <FontAwesomeIcon
                    v-if="icon"
                    :icon="icon"
                />
            </slot>
        </span>
        <slot>
            {{ label }}
        </slot>
    </label>
</template>
