import type {SerializerType} from '../../../app/composables/useMagicFilter';

export default {
    read: async (value: string) => {
        return value;
    },
    write: (value: string) => {
        return value;
    },
} as SerializerType;
