export enum StoragePath {
    // Customers Index
    filtersCustomersIndexTenants = 'filters:customers:index:tenants',
    filtersCustomersIndexTags = 'filters:customers:index:tags',
    filtersCustomersIndexWithout = 'filters:customers:index:without-tag',
    filtersCustomersIndexPersonTypes = 'filters:customers:index:person-types',
    filtersCustomersIndexStates = 'filters:customers:index:states',
    filtersCustomersIndexIncludeArchived = 'filters:customers:index:include-archived',
    filtersCustomersIndexOrderBy = 'filters:customers:index:order-by',

    // Transactions Index
    filtersTransactionsIndexSearch = 'filters:transactions:index:search',
    filtersTransactionsIndexUnallocatedOnly = 'filters:transactions:index:unallocated-only',
    filtersTransactionsIndexPeriod = 'filters:transactions:index:period',
    filtersTransactionsIndexAmount = 'filters:transactions:index:amount',
    filtersTransactionsIndexTenants = 'filters:transactions:index:tenants',
    filtersTransactionsIndexCustomers = 'filters:transactions:index:customers',
    filtersTransactionsIndexPaymentMethod = 'filters:transactions:index:payment-method',
    filtersTransactionsIndexOrderBy = 'filters:transactions:index:order-by',

    // Kids Index
    filtersKidsIndexContractStates = 'filters:kids:index:contract-states',
    filtersKidsIndexSelectedGroup = 'filters:kids:index:group',
    filtersKidsIndexTags = 'filters:kids:index:tags',
    filtersKidsIndexWithoutTag = 'filters:kids:index:without-tag',

    // Documents Index
    filtersDocumentsIndexWithoutTag = 'filters:documents:index:without-tag',

    // Tags Legacy
    filtersDocumentsIndexTags = 'tags:documents:index',
}
