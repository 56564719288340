<template>
    <MPopover
        ref="updateSimulationListPopover"
        placement="bottom"
    >
        <MButton
            :disabled="!(simulationList && can('update', 'registrations'))"
            :zoom="true"
        >
            <FontAwesomeIcon icon="fa-duotone fa-pencil" />
        </MButton>
        <template #content>
            <div
                v-if="simulationList && can('update', 'registrations')"
                class="tw-flex tw-w-64 tw-flex-col tw-gap-4 tw-p-2"
            >
                <SimulationListForm
                    ref="form"
                    :simulation-list="editedSimulationList"
                    @loading="loading = $event"
                    @saved="updateSimulationList"
                />
                <div class="tw-flex tw-justify-end tw-gap-2">
                    <MButton @click="cancel">
                        {{ __('common:actions.cancel') }}
                    </MButton>
                    <MButton
                        :loading="loading"
                        variant="primary"
                        @click="$refs.form.save()"
                    >
                        {{ __('common:actions.save') }}
                    </MButton>
                </div>
            </div>
        </template>
    </MPopover>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {defineComponent, ref, watch} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import SimulationListForm from '@/modules/planning/components/simulation/List/SimulationListForm.vue';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';

    export default defineComponent({
        components: {SimulationListForm},
        props: {
            simulationList: {
                type: Object as PropType<SimulationListModel>,
                default: undefined,
            },
        },
        setup(props) {
            const editedSimulationList = ref() as Ref<SimulationListModel>;
            const updateSimulationListPopover = ref();
            const loading = ref(false);

            const {can} = useAbility();
            const {success} = useNotification();

            function cancel() {
                editedSimulationList.value.rollback();
                updateSimulationListPopover.value?.closePopover();
            }

            function updateSimulationList(list) {
                list.registrations().mutate(() => props.simulationList.registrations().value());
                list.copyTo(props.simulationList as SimulationListModel);
                updateSimulationListPopover.value?.closePopover();
                success(__('planning:list_edited_successfully'));
            }

            watch(
                () => props.simulationList,
                value => {
                    if (value) {
                        editedSimulationList.value = value.clone();
                    }
                },
                {immediate: true}
            );

            return {
                updateSimulationListPopover,
                editedSimulationList,
                cancel,
                updateSimulationList,
                loading,
                can,
            };
        },
    });
</script>
