<template>
    <div
        ref="segmentRef"
        :class="customClass"
        :style="customStyle"
        @click="saveEvent"
    >
        <div v-if="isOverEvent">
            <div class="tw-absolute tw--top-1.5 tw-w-full tw-text-center tw-text-sm tw-font-semibold">
                <div
                    class="tw-mx-auto tw-flex tw-h-5 tw-w-10 tw-items-center tw-rounded-lg tw-border-2 tw-border-blue-500 tw-bg-blue-400 tw-text-center tw-text-sm tw-text-white"
                >
                    <FontAwesomeIcon
                        v-if="loading"
                        class="tw-w-full"
                        icon="fa-duotone fa-spinner-third"
                        spin
                    />
                    <FontAwesomeIcon
                        v-else
                        class="tw-w-full"
                        icon="fa-solid fa-plus"
                    />
                </div>
            </div>
        </div>
        <div
            v-else
            class="tw-flex tw-h-full tw-w-full tw-items-center"
        >
            <CTooltip
                v-if="showCancelCopy"
                class="tw-absolute tw--right-2 tw--top-2"
                placement="bottom-start"
            >
                <FontAwesomeIcon
                    class="tw-text-lg tw-text-gray-900"
                    icon="fa-solid fa-times-circle"
                    @click.stop="clearCopy()"
                />
                <template #content>
                    <div class="tw-flex tw-w-24 tw-items-center tw-text-sm tw-font-semibold">
                        <div>
                            {{ __('common:actions.cancel') }}
                        </div>
                        <div
                            class="tw-ml-auto tw-rounded tw-border tw-border-gray-500 tw-px-1 tw-tracking-wide tw-text-gray-500"
                        >
                            {{ __('hr_calendar:esc') }}
                        </div>
                    </div>
                </template>
            </CTooltip>
            <div
                v-if="eventType && eventType.attributes.icon_id"
                class="tw-mr-1"
                :class="iconClass"
                :style="iconStyle"
            >
                <CIcon
                    :path="loading ? 'fa-duotone fa-spinner-third' : eventType.icon.attributes.path"
                    :provider="eventType.icon.attributes.provider"
                />
            </div>
            <FontAwesomeIcon
                v-else
                class="tw-ml-1"
                icon="fa-solid fa-plus-circle"
            />
            <div class="tw-ml-2 tw-w-full tw-truncate tw-text-sm tw-font-semibold">
                <div v-if="latestEvent">
                    {{ readableHoursFromDates(latestEvent.startedAt, latestEvent.endedAt) }}
                </div>
                <div
                    v-if="size === 'lg'"
                    class="tw--mt-1 tw-text-sm tw-opacity-90"
                >
                    <template v-if="loading">
                        {{ __('common:adding_in_progress_dots') }}
                    </template>
                    <template v-else>
                        {{ __('hr_calendar:add_event_instruction') }}
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {type Model, ValidationError} from '@meekohq/lumos';
    import interact from 'interactjs';
    import moment from 'moment';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref, toRef} from 'vue';

    import {EventBus} from '@/eventBus';
    import __ from '@/modules/app/utils/i18n-facade';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
    import {
        getClosing,
        getOpening,
        percentToUnix,
        readableHoursFromDates,
        unixToPercent,
    } from '@/modules/legacy/libs/planning/planning';
    import ManagerStore from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            staff: {
                type: Object,
            },
            day: {
                type: Object,
            },
            opening: {
                type: String,
            },
            closing: {
                type: String,
            },
            event: {
                type: Object as PropType<EventModel | TemplateEventModel | null>,
            },
            events: {
                type: Array as PropType<EventModel[] | TemplateEventModel[]>,
            },
            size: {
                type: String as PropType<'sm' | 'md' | 'lg'>,
                default: 'lg',
            },
            isTemplate: {
                type: Boolean,
                default: false,
            },
            weekTemplate: {
                type: String,
            },
            autoSave: {
                type: Boolean,
                default: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            showCancelCopy: {
                type: Boolean,
                default: true,
            },
            openEventModal: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:loading', 'pushEventTemplate', 'pushEvent'],
        setup(props, {emit}) {
            const latestEvent = ref<EventModel | TemplateEventModel>();
            const segmentRef = ref();

            const eventType = computed(() => {
                return latestEvent.value ? latestEvent.value.eventType().value() : null;
            });
            const color = computed(() => {
                return eventType.value?.attributes.color || '#49C300';
            });
            const forecast = computed(() => {
                return latestEvent.value?.attributes.forecast || true;
            });
            const {eventClass, eventStyle, iconClass, iconStyle} = useEventStyle(
                color,
                forecast,
                props.size,
                true,
                toRef(props, 'loading')
            );

            const customStyle = computed(() => {
                const left =
                    unixToPercent(latestEvent.value?.startedAt.unix() || 0, props.day, props.opening, props.closing) +
                    '%';
                const right =
                    100 -
                    unixToPercent(latestEvent.value?.endedAt.unix() || 0, props.day, props.opening, props.closing) +
                    '%';

                if (isOverEvent.value) {
                    return {
                        left,
                        right,
                    };
                }

                return {
                    ...eventStyle.value,
                    left,
                    right,
                };
            });

            const customClass = computed(() => {
                let computedClass = '';
                if (isOverEvent.value) {
                    computedClass +=
                        ' tw-absolute tw-bg-blue-500 tw-h-1.5 tw-rounded-2xl tw--top-1 tw-cursor-pointer tw-z-[3000]';
                } else {
                    computedClass = eventClass.value + ' tw-animate-pulse tw-z-40';
                    // computedClass += ' tw-absolute tw-h-full tw-rounded-2xl tw-bg-blue-200 tw-opacity-75 tw-border-2 tw-border-blue-300 tw-text-blue-700 tw-animate-pulse stripes tw-cursor-pointer';
                    // props.size !== 'lg' ? (computedClass += ' tw-text-sm tw-px-1') : (computedClass += ' tw-text-sm tw-px-2');
                }

                return computedClass;
            });

            onMounted(() => {
                initResize();

                if (!props.event) {
                    const currentDate = moment(props.day).format('MM/DD/YYYY');
                    const startTime = getOpening(ManagerStore.legacyNursery.openingHours, false, true);
                    const endTime = getClosing(ManagerStore.legacyNursery.openingHours, false, true);

                    if (props.isTemplate) {
                        latestEvent.value = new TemplateEventModel();
                        latestEvent.value.attributes.planning_template_id = props.weekTemplate;
                        latestEvent.value.attributes.day = moment(props.day).day();
                        // Force week 1 to be synchro with + action button
                        latestEvent.value.attributes.week = 1;
                    } else {
                        latestEvent.value = new EventModel();
                        latestEvent.value.attributes.staff_id = props.staff?.id;
                    }

                    latestEvent.value.attributes.account_id = ManagerStore.legacyAccount.id.toString();
                    latestEvent.value.attributes.organization_id = ManagerStore.legacyNursery.id.toString();
                    latestEvent.value.attributes.note = null;
                    latestEvent.value.attributes.forecast = true; // TODO : Get default parameters
                    latestEvent.value.attributes.supervise_kid = null;

                    latestEvent.value.startedAt = moment(`${currentDate} ${startTime}`, 'MM/DD/YYYY HH:mm');
                    latestEvent.value.endedAt = moment(`${currentDate} ${endTime}`, 'MM/DD/YYYY HH:mm');
                } else {
                    if (props.isTemplate) {
                        latestEvent.value = new TemplateEventModel();
                        latestEvent.value.attributes.planning_template_id = props.weekTemplate;
                        latestEvent.value.attributes.day = (props.event as TemplateEventModel).attributes.day;
                        latestEvent.value.attributes.week = (props.event as TemplateEventModel).attributes.week;
                    } else {
                        latestEvent.value = new EventModel();
                        latestEvent.value.attributes.staff_id = props.staff?.id;
                    }

                    latestEvent.value.attributes.account_id = props.event.attributes.account_id;
                    latestEvent.value.attributes.organization_id = props.event.attributes.organization_id;
                    latestEvent.value.attributes.note = props.event.attributes.note;
                    latestEvent.value.attributes.forecast = props.event.attributes.forecast;
                    latestEvent.value.attributes.supervise_kid = props.event.attributes.supervise_kid;

                    latestEvent.value.attributes.type_id = props.event.attributes.type_id;
                    latestEvent.value?.eventType().associate(props.event.eventType().value());

                    const day = moment(props.day).dayOfYear();
                    const year = moment(props.day).year();
                    latestEvent.value.startedAt = moment(props.event.startedAt).dayOfYear(day).year(year);
                    latestEvent.value.endedAt = moment(props.event.endedAt).dayOfYear(day).year(year);
                }
            });

            const isOverEvent = computed(() => {
                // TODO : Check others rules (presence on presence, etc)
                let isOver = false;
                props.events?.forEach((item: EventModel | TemplateEventModel) => {
                    if (latestEvent.value) {
                        const rule1 = latestEvent.value.startedAt.isBetween(
                            item.startedAt,
                            item.endedAt,
                            'minute',
                            '[)'
                        );
                        const rule2 = latestEvent.value.endedAt.isBetween(item.startedAt, item.endedAt, 'minute', '(]');
                        const rule3 = item.startedAt.isBetween(
                            latestEvent.value.startedAt,
                            latestEvent.value.endedAt,
                            'minute',
                            '[)'
                        );
                        const rule4 = item.endedAt.isBetween(
                            latestEvent.value.startedAt,
                            latestEvent.value.endedAt,
                            'minute',
                            '(]'
                        );

                        if (rule1 || rule2 || rule3 || rule4) {
                            isOver = true;
                        }
                    }
                });

                return isOver;
            });

            async function saveEvent() {
                if (props.loading === true) {
                    return;
                }

                emit('update:loading', true);

                if (!latestEvent.value?.attributes.type_id) {
                    const defaultType = await EventTypeModel.query().scope('defaultType').firstOrFail();
                    latestEvent.value?.eventType().associate(defaultType);
                    if (latestEvent.value?.attributes) {
                        (latestEvent.value as EventModel).attributes.factor = defaultType.factor;
                    }
                }

                if (latestEvent.value?.attributes && !(latestEvent.value as EventModel)?.attributes.factor) {
                    (latestEvent.value as EventModel).attributes.factor = latestEvent.value
                        ?.eventType()
                        .value().attributes.factor;
                }

                // Do not save referenceEvent on copied event
                // Improvement : associate planning below event if exist
                if ((latestEvent.value as EventModel).attributes.reference_event_id) {
                    (latestEvent.value as EventModel).referenceEvent().dissociate();
                }
                try {
                    if (props.openEventModal && isOverEvent.value) {
                        if (props.isTemplate) {
                            EventBus.$emit('calendar:staff:create:eventTemplate', latestEvent.value);
                        } else {
                            EventBus.$emit('calendar:staff:create:event', latestEvent.value);
                        }
                    } else {
                        if (props.autoSave) {
                            await (latestEvent.value as Model | undefined)?.save();
                        }

                        if (props.isTemplate) {
                            emit('pushEventTemplate', latestEvent.value);
                        } else {
                            emit('pushEvent', latestEvent.value);
                        }

                        EventBus.$emit('calendar:staff:event:copy', latestEvent.value);
                        !props.isTemplate ? EventBus.$emit('calendar:staffs:refresh') : null;
                    }
                    emit('update:loading', false);
                } catch (error) {
                    emit('update:loading', false);

                    if (error instanceof ValidationError) {
                        const validationErrorItems = error.errors.all();
                        if (
                            validationErrorItems.find(
                                error =>
                                    error.code === '0x35C2C73E0E' && error.source.pointer === 'planning_template_id'
                            )
                        ) {
                            useNotification().error(__('hr_calendar:planning_type_dosent_exists_any_more'));
                            return;
                        }
                    }

                    useNotification().error(__('hr_calendar:event_already_exists'));
                }
            }

            function clearCopy() {
                EventBus.$emit('calendar:staff:event:clearCopy');
            }

            function defaultPercent(min: number | undefined = 5) {
                const start = moment(props.day)
                    .clone()
                    .hours(moment.duration(props.opening).hours())
                    .minutes(min)
                    .seconds(0)
                    .unix();

                return unixToPercent(start, props.day, props.opening, props.closing);
            }

            const mouseVelocity = computed(() => {
                const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                const widthDifference = width / 1440 / 1.3;

                return props.size === 'lg' ? 10 / widthDifference : 2 / widthDifference;
            });

            function initResize() {
                const eventInteract = interact(segmentRef.value);

                const modifiers = [
                    interact.modifiers.snapSize({
                        targets: [
                            interact.snappers.grid({
                                width: defaultPercent() * mouseVelocity.value,
                                height: defaultPercent() * mouseVelocity.value,
                            }),
                        ],
                    }),
                ];

                eventInteract.draggable({
                    startAxis: 'x',
                    lockAxis: 'x',
                    cursorChecker() {
                        return 'pointer';
                    },
                    modifiers,
                    listeners: {
                        move(e) {
                            onResize(e, e.dx, e.dx);
                        },
                    },
                });
            }

            function onResize(e, deltaLeft: number, deltaRight: number) {
                const left = parseFloat(e.target.style.left.replace('%', ''));
                const right = parseFloat(e.target.style.right.replace('%', ''));

                let newLeft = left + deltaLeft / mouseVelocity.value;
                let newRight = right - deltaRight / mouseVelocity.value;

                Object.assign(e.target.style, {
                    left: `${newLeft}%`,
                    right: `${newRight}%`,
                });

                newLeft = percentToUnix(newLeft, props.day, props.opening, props.closing);
                newRight = percentToUnix(100 - newRight, props.day, props.opening, props.closing);

                if (latestEvent.value) {
                    latestEvent.value.startedAt = moment.unix(newLeft);
                    latestEvent.value.endedAt = moment.unix(newRight);
                }
            }

            return {
                latestEvent,
                eventType,
                customStyle,
                customClass,
                iconClass,
                iconStyle,

                isOverEvent,
                segmentRef,
                saveEvent,
                clearCopy,
                readableHoursFromDates,
            };
        },
    });
</script>
