<template>
    <CModal
        ref="modal"
        size="3xl"
        @hidden="onHidden"
        @shown="onShown"
    >
        <template #header-text>
            <template v-if="staffCopy && staffCopy.value && staffCopy.value.exist">
                {{ $fullName(staffCopy.value.attributes) }}
            </template>
            <template v-else>
                {{ __('hr_staff:add_staff') }}
            </template>
        </template>

        <div v-if="staffCopy && staffCopy.value">
            <MFieldset
                v-if="isNew"
                class="tw-mb-7 tw-w-full md:tw-w-1/2"
            >
                <MLabel class="tw-text-blue-500">
                    {{ __('hr_staff:attach_staff_from_another_organization_colon') }}
                </MLabel>
                <StaffFinder
                    :button-disabled="saveLoading || loading"
                    :has-active-contract="false"
                    :inject-query="staffsFromOtherOrganizationsQuery"
                    @update:model-value="onSelectExistingStaff"
                >
                    <template #list-header>
                        {{ __('hr_staff:other_organizations') }}
                    </template>
                </StaffFinder>
            </MFieldset>

            <div
                v-else
                class="tw-mb-6 tw-text-center"
            >
                <div class="tw-flex tw-justify-center">
                    <MAvatarEditor
                        :can-delete="!!staffCopy.value.computed.avatar_url"
                        class="tw-absolute tw-w-20"
                        @click="editAvatar()"
                        @delete="deleteAvatar()"
                    >
                        <StaffAvatar
                            class="tw-h-full tw-w-full"
                            :staff-model="staffCopy.value"
                        />
                    </MAvatarEditor>
                </div>
                <small class="small">
                    {{ __('common:edit_avatar_instruction') }}
                </small>
                <input
                    ref="fileInput"
                    accept="image/*"
                    class="tw-hidden"
                    type="file"
                    @change="updateAvatar($event)"
                />
                <CFormErrorMessageList
                    class="tw-mt-1"
                    :errors="formError.getErrors('/attributes/avatar')"
                />
            </div>

            <div
                class="tw-border-b tw-border-gray-200 tw-pb-1 tw-text-base tw-uppercase tw-tracking-wider tw-text-blue-500"
            >
                {{ __('common:general') }}
            </div>

            <div class="tw-mt-4 tw-flex">
                <MFieldset class="tw-w-1/3">
                    <MLabel>{{ __('common:first_name') }}*</MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.first_name"
                        :placeholder="__('common:first_name')"
                    />
                    <CFormErrorMessageList :errors="formError.getErrors('first_name')" />
                </MFieldset>

                <MFieldset class="tw-w-1/3 tw-pl-3">
                    <MLabel>{{ __('common:last_name') }}*</MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.last_name"
                        :placeholder="__('common:last_name')"
                    />
                    <CFormErrorMessageList :errors="formError.getErrors('last_name')" />
                </MFieldset>

                <MFieldset class="tw-w-1/3 tw-pl-3">
                    <MLabel>
                        {{ __('common:birth_name') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.maiden_name"
                        :placeholder="__('common:birth_name')"
                    />
                </MFieldset>
            </div>

            <div class="tw-mt-9 tw-flex">
                <MFieldset class="tw-w-1/2">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-solid fa-venus-mars" />
                        </template>
                        {{ __('common:gender_one') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="staffCopy.value.attributes.gender"
                        :fallback-text="__('hr_staff:select_gender_optional')"
                        :options="[
                            {value: null, text: __('common:actions.unselect')},
                            {value: 'female', text: __('common:gender.ms')},
                            {value: 'male', text: __('common:gender.mr')},
                        ]"
                    />
                </MFieldset>
                <MFieldset class="tw-w-1/2 tw-pl-3">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-birthday-cake" />
                        </template>
                        {{ __('common:birthdate') }}
                    </MLabel>
                    <CFormDatepicker
                        v-model="staffCopy.value.attributes.birthdate"
                        format="YYYY-MM-DD"
                    />
                </MFieldset>
            </div>

            <div class="tw-mb-3 tw-mt-3 tw-flex">
                <MFieldset class="tw-w-1/2">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-user-graduate" />
                        </template>
                        {{ __('hr_staff:job_to_display') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.job"
                        :placeholder="__('hr_staff:job_to_display')"
                    />
                </MFieldset>
                <MFieldset class="tw-w-1/2 tw-pl-3">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-note" />
                        </template>
                        {{ __('common:open_note') }}
                    </MLabel>
                    <MTextarea
                        v-model="staffCopy.value.attributes.note"
                        :placeholder="__('common:open_note')"
                        rows="2"
                    />
                </MFieldset>
            </div>

            <CDisclosure
                v-if="can('update', 'staffs_contracts')"
                :open="false"
                :title="__('hr_staff:legal_informations')"
            >
                <CHStack gap="3">
                    <MFieldset class="tw-flex-1">
                        <MLabel>
                            {{ __('common:nationality') }}
                        </MLabel>
                        <SelectCountry v-model="staffCopy.value.attributes.nationality" />
                    </MFieldset>
                    <MFieldset class="tw-flex-1">
                        <MLabel>
                            {{ __('common:birth_place') }}
                        </MLabel>
                        <CInput
                            v-model="staffCopy.value.attributes.birth_place"
                            :placeholder="__('common:birth_place')"
                        />
                    </MFieldset>
                    <MFieldset class="tw-flex-1">
                        <MLabel>
                            {{ __('common:social_security_number') }}
                        </MLabel>
                        <CInput
                            v-model="staffCopy.value.attributes.social_security_number"
                            :placeholder="__('common:social_security_number')"
                        />
                    </MFieldset>
                </CHStack>
            </CDisclosure>

            <div
                class="tw-mt-9 tw-border-b tw-border-gray-200 tw-pb-1 tw-text-base tw-uppercase tw-tracking-wider tw-text-blue-500"
            >
                {{ __('common:contact') }}
            </div>

            <MFieldset class="tw-mt-4 tw-w-1/2">
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-at" />
                    </template>
                    {{ __('common:email') }}
                </MLabel>
                <CInput
                    v-model="staffCopy.value.attributes.email"
                    :placeholder="__('common:email')"
                />
                <CFormErrorMessageList :errors="formError.getErrors('email')" />
            </MFieldset>

            <div class="tw-mt-3 tw-flex">
                <MFieldset class="tw-w-1/2 tw-pr-3">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-solid fa-phone" />
                        </template>
                        {{ __('common:phone.landline') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.phone"
                        :placeholder="__('common:phone.landline')"
                    />
                </MFieldset>

                <MFieldset class="tw-w-1/2 tw-pl-3">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-solid fa-mobile-alt" />
                        </template>
                        {{ __('common:phone.cellular') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.mobile_phone"
                        :placeholder="__('common:phone.cellular')"
                    />
                </MFieldset>
            </div>

            <MFieldset class="tw-mt-7">
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-map-marker-alt" />
                    </template>
                    {{ __('common:address.address') }}
                </MLabel>
                <CInput
                    v-model="staffCopy.value.attributes.address.line1"
                    class="!tw-rounded-b-none"
                    :placeholder="__('common:address.address')"
                />
                <CInput
                    v-model="staffCopy.value.attributes.address.line2"
                    class="!tw-mt-0 !tw-rounded-t-none tw-border-t-0"
                    :placeholder="__('common:address.extra_adress')"
                />
            </MFieldset>

            <div class="tw-mt-3 tw-flex">
                <MFieldset class="tw-w-1/3">
                    <MLabel>
                        {{ __('common:address.zip_code') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.address.postal_code"
                        :placeholder="__('common:address.zip_code')"
                    />
                </MFieldset>

                <MFieldset class="tw-w-1/3 tw-pl-3">
                    <MLabel>
                        {{ __('common:address.city') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.address.city"
                        :placeholder="__('common:address.city')"
                    />
                </MFieldset>

                <MFieldset class="tw-w-1/3 tw-pl-3">
                    <MLabel>
                        {{ __('common:address.country') }}
                    </MLabel>
                    <SelectCountry v-model="staffCopy.value.attributes.address.country_code" />
                </MFieldset>
            </div>

            <div
                class="tw-mt-9 tw-border-b tw-border-gray-200 tw-pb-1 tw-text-base tw-uppercase tw-tracking-wider tw-text-blue-500"
            >
                {{ __('common:settings') }}
            </div>

            <div class="tw-mt-4 tw-grid tw-gap-3 md:tw-grid-cols-3">
                <MFieldset>
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-clock" />
                        </template>
                        {{ __('common:clockin_code') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.code"
                        :placeholder="__('hr_staff:code_for_pointing_to_devices')"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-id-card" />
                        </template>
                        {{ __('hr_staff:internal_identifier') }}
                    </MLabel>
                    <CInput
                        v-model="staffCopy.value.attributes.no"
                        :placeholder="__('hr_staff:number_or_internal_identifier')"
                    />
                </MFieldset>
                <MFieldset v-if="teamsInStaffOrganizations.isNotEmpty()">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-duotone fa-users"
                            />
                        </template>
                        {{ __('common:team_other') }}
                    </MLabel>
                    <TeamFinder
                        v-model="selectedTeams"
                        button-class="tw-w-full"
                        :inject-query="getTeamsInStaffOrganizationsQuery"
                        multi
                    />
                </MFieldset>
            </div>

            <div
                class="tw-mt-9 tw-border-b tw-border-gray-200 tw-pb-1 tw-text-base tw-uppercase tw-tracking-wider tw-text-blue-500"
            >
                {{ __('common:visibility_on_applications') }}
            </div>

            <div
                v-if="organizationPivot"
                class="tw-my-4 tw-grid tw-gap-3 md:tw-grid-cols-2"
            >
                <MFieldset>
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon
                                icon="fa-duotone fa-tablet-alt"
                                swap-opacity
                            />
                        </template>
                        {{ __('common:meeko_team') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="organizationPivot.attributes.visible_on_team"
                        :options="[
                            {value: true, text: __('common:visible')},
                            {value: false, text: __('common:non_visible')},
                        ]"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-globe" />
                        </template>
                        {{ __('common:meeko_website') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="organizationPivot.attributes.visible_on_website"
                        :options="[
                            {value: true, text: __('common:visible')},
                            {value: false, text: __('common:non_visible')},
                        ]"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon
                                icon="fa-duotone fa-mobile-alt"
                                swap-opacity
                            />
                        </template>
                        {{ __('common:meeko_family') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="organizationPivot.attributes.visible_on_family"
                        :options="[
                            {value: true, text: __('common:visible')},
                            {value: false, text: __('common:non_visible')},
                        ]"
                    />
                </MFieldset>
            </div>
        </div>

        <template #footer>
            <div class="tw-flex tw-w-full">
                <MButton
                    v-if="can('delete', 'staffs_details') && !isNew"
                    variant="danger"
                    @click="remove"
                >
                    {{ __('common:actions.delete_dots') }}
                </MButton>
                <MButton
                    v-if="!isNew && organizationPivot"
                    class="tw-ml-2"
                    @click="detachStaff"
                >
                    {{ __('common:actions.detach') }}
                </MButton>

                <MButton
                    class="tw-ml-auto"
                    variant="light"
                    @click="$refs.modal.hide()"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    class="tw-ml-2"
                    :loading="saveLoading || loading"
                    variant="primary"
                    @click="staffCopy.value.exist ? save() : $emit('save', staffCopy.value)"
                >
                    {{ __('common:actions.save') }}
                </MButton>
            </div>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useManager from '@/modules/app/composables/useManager';
    import TeamFinder from '@/modules/human-resources/components/team/TeamFinder.vue';
    import useAttachTeam from '@/modules/human-resources/composables/staff/useAttachTeam';
    import useStaff from '@/modules/human-resources/composables/staff/useStaff';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import type StaffError from '@/modules/human-resources/utils/staff/StaffError';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import type Mirror from '@/modules/legacy/helpers/mirror.helper';
    import ManagerStore from '@/modules/legacy/store/manager.store';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export default defineComponent({
        components: {
            StaffAvatar,
            SelectCountry,
            TeamFinder,
            StaffFinder,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
            },
            isNew: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            error: {
                type: Object as PropType<StaffError>,
                default: null,
            },
        },
        emits: ['save', 'attach'],
        setup(props, {emit}) {
            const {can} = useAbility();
            const {organizationIds} = useManager();
            const teamsInStaffOrganizations = ref<Collection<TeamModel>>(collect());

            const organization = computed(() => {
                return ManagerStore.activeOrganization;
            });

            function onSelectExistingStaff(staff: StaffModel) {
                emit('attach', staff);
            }

            const {
                staffCopy,
                organizationPivot,
                saveLoading,
                modal,

                onShown,
                onHidden,
                save,
                remove,
                detachStaff,
                staffError,

                fileInput,
                avatarLoading,
                deleteAvatar,
                editAvatar,
                updateAvatar,
            } = useStaff(null, props.staff, null, null, true, ref({teams: []}), props.isNew);

            const staffsFromOtherOrganizationsQuery = computed(() =>
                StaffModel.query().whereDoesntHave(new StaffModel().organizations(), query => {
                    return query.where('id', organization.value.getKey());
                })
            );

            const {selectedTeams, staffTeamsRelation, addTeam, deleteTeam} = useAttachTeam(staffCopy);

            const getTeamsInStaffOrganizationsQuery = computed(() => {
                const query = TeamModel.query();

                return query.whereHas(new TeamModel().organizations(), query1 => {
                    query1.whereHas(new OrganizationModel().staffs(), query2 => {
                        query2.where('id', props.staff?.id);
                    });
                });
            });

            watch(
                staffCopy,
                async (copy: Mirror | null) => {
                    if (copy?.value.exist) {
                        teamsInStaffOrganizations.value = await TeamModel.query()
                            .whereHas(new TeamModel().organizations(), query => {
                                query.whereIn('id', organizationIds.value);
                                query.whereHas(new OrganizationModel().staffs(), query2 => {
                                    query2.where('id', copy?.value.id);
                                });
                            })
                            .orderBy('name')
                            .get();
                    }
                },
                {immediate: true}
            );

            const formError = computed(() => {
                return props.error ? props.error : staffError;
            });

            return {
                can,
                organization,
                selectedTeams,
                teamsInStaffOrganizations,
                staffTeamsRelation,
                getTeamsInStaffOrganizationsQuery,
                staffsFromOtherOrganizationsQuery,

                onSelectExistingStaff,

                staffCopy,
                organizationPivot,
                saveLoading,
                modal,
                formError,

                onShown,
                onHidden,
                save,
                remove,
                detachStaff,
                deleteAvatar,
                addTeam,
                deleteTeam,

                fileInput,
                avatarLoading,
                editAvatar,
                updateAvatar,
            };
        },
    });
</script>
