import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';

import type {HasOrganization} from '@/modules/app/composables/useSharedList';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import BalancePeriodModel from '@/modules/human-resources/models/BalancePeriodModel';
import BalanceTypeEventTypePivot from '@/modules/human-resources/models/BalanceTypeEventTypePivot';
import BalanceTypeOrganizationPivot from '@/modules/human-resources/models/BalanceTypeOrganizationPivot';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import {balanceUnitValue} from '@/modules/human-resources/utils/balance/BalanceUnitValue';
import Manager from '@/modules/legacy/store/manager.store';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class BalanceTypeModel extends Model implements HasOrganization {
    public type = 'hr/balance/types';

    public attributes: {
        id: string;
        account_id: string | undefined;
        internal_id: string | undefined;
        name: string | undefined;
        show_balance: boolean | undefined;
        unit: string | undefined;
        archived_at: string | undefined | null;
    } = {
        id: this.uuid(),
        account_id: undefined,
        internal_id: undefined,
        name: undefined,
        show_balance: true,
        unit: balanceUnitValue.day,
        archived_at: undefined,
    };

    public static activeOrganizationBalanceTypeUnarchivedScope(query: QueryBuilder<BalanceTypeModel>) {
        query.whereHas(new BalanceTypeModel().organizationsPivots(), query2 => {
            query2.where('organization_id', '=', Manager.activeOrganization.id).whereNull('archived_at');
        });
    }

    eventTypes() {
        return this.belongsToMany(
            'eventTypes',
            EventTypeModel,
            BalanceTypeEventTypePivot,
            'balance_type_id',
            'event_type_id'
        );
    }

    eventTypesPivot() {
        return this.hasMany('eventTypesPivot', BalanceTypeEventTypePivot, 'balance_type_id');
    }

    balancePeriods() {
        return this.hasMany('balancePeriods', BalancePeriodModel, 'balance_type_id');
    }

    balanceAllocations() {
        return this.hasMany('balanceAllocations', BalanceAllocationModel, 'balance_type_id');
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', BalanceTypeOrganizationPivot, 'balance_type_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            BalanceTypeOrganizationPivot,
            'balance_type_id',
            'organization_id'
        );
    }
}
