import type {Collection, ValidationError} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';
import {computed, ref} from 'vue';

import __ from '@/modules/app/utils/i18n-facade';

export interface ErrorType {
    source: string;
    code: string;
    message: string;
}

export type ErrorReturnType = ReturnType<typeof useError>;

type CustomMessages = Record<string, Record<string, (detail: any) => string>>;

export default function useError(customMessages?: CustomMessages) {
    const errors = ref<ErrorType[]>([]);

    const hasErrors = computed(() => errors.value.length > 0);

    function add(source: string, code: string, message: string) {
        if (errors.value.find(item => item.source === source && item.code === code) === undefined) {
            errors.value.push({source, code, message});
        }
    }

    function addErrorCode(source: string, code: string, detail: any = {}) {
        const customMessage = resolveCustomMessage(source, code);
        const message =
            typeof customMessage === 'function' ? customMessage(detail) : mapCodeToTranslation(code, detail);

        add(source, code, message);
    }

    function addValidationError(error: ValidationError) {
        for (const item of error.errors.toArray()) {
            const customMessage = resolveCustomMessage(item.source?.pointer, item.code);
            const message =
                typeof customMessage === 'function'
                    ? customMessage(item.detail)
                    : mapCodeToTranslation(item.code, item.detail);

            add(item.source?.pointer ?? '', item.code, message);
        }
    }

    function has(source: string): boolean {
        return filterErrorsBySource(source).length > 0;
    }

    function get(source: string): Collection<ErrorType> {
        return collect(filterErrorsBySource(source));
    }

    function getAll(): Collection<ErrorType> {
        return collect(errors.value);
    }

    function remove(source: string, code: string) {
        errors.value = errors.value.filter(item => item.source !== source || item.code !== code);
    }

    function reset() {
        errors.value = [];
    }

    function resolveCustomMessage(source?: string, code?: string) {
        if (source && code) {
            return customMessages?.[source]?.[code];
        }
    }

    function filterErrorsBySource(source: string): ErrorType[] {
        return errors.value.filter(item => item.source === source);
    }

    return {
        hasErrors,
        has,
        get,
        getAll,
        add,
        addErrorCode,
        addValidationError,
        reset,
        remove,
    };
}

function mapCodeToTranslation(code: string, detail: any): string {
    switch (code) {
        case '0x2EAA618990':
            return __('common:errors.0x2EAA618990');
        case '0x2EAA628B5E':
            return __('common:errors.0x2EAA628B5E');
        case '0x2EAA632C89':
            return __('common:errors.0x2EAA632C89');
        case '0x2EAA809FB3':
            return __('common:errors.0x2EAA809FB3');
        case '0x2EAF0958A0':
            return __('common:errors.0x2EAF0958A0');
        case '0x2EB54B8A74':
            return __('common:errors.0x2EB54B8A74');
        case '0x2EB54B8AA6':
            return __('common:errors.0x2EB54B8AA6');
        case '0x2EB54B8AA8':
            return __('common:errors.0x2EB54B8AA8');
        case '0x2EB54B8AAE':
            return __('common:errors.0x2EB54B8AAE');
        case '0x2EB54B8AB3':
            return __('common:errors.0x2EB54B8AB3');
        case '0x2EB54B8BF4':
            return __('common:errors.0x2EB54B8BF4', {maximum: detail.maximum});
        case '0x380BEACD3D':
            if (detail.maximum === 999999999.9999) {
                return __('common:errors.0x380BEACD3D_MAX');
            }

            return __('common:errors.0x380BEACD3D', {maximum: detail.maximum});
        case '0x2EC8028C6C':
            return __('common:errors.0x2EC8028C6C');
        case '0x2EDA212753':
            return __('common:errors.0x2EDA212753');
        case '0x2EDA3E9597':
            return __('common:errors.0x2EDA3E9597');
        case '0x3357D69E59':
            return __('common:errors.0x3357D69E59');
        case '0x335D90AE82':
            return __('common:errors.0x335D90AE82');
        case '0x3362CF291D':
            return __('common:errors.0x3362CF291D');
        case '0x35B7DF2E26':
            return __('common:errors.0x35B7DF2E26');
        case '0x35C472A229':
            return __('common:errors.0x35C472A229');
        case '0x2EB54B8AB4':
            return __('common:errors.0x2EB54B8AB4');
        case '0x333F958240':
            return __('common:errors.0x333F958240');
        case '0x3115DA45A6':
            return __('common:errors.0x3115DA45A6');
        case '0x311B92FE7B':
            return __('common:errors.0x311B92FE7B');
        case '0x3115DA57B1':
            return __('common:errors.0x3115DA57B1');
        case '0x311BFF7AFF':
            return __('common:errors.0x311BFF7AFF');
        case '0x311C3C7EEF':
            return __('common:errors.0x311C3C7EEF');
        case '0x3120D29DB0':
            return __('common:errors.0x3120D29DB0');
        case '0x3120D1BBC7':
            return __('common:errors.0x3120D1BBC7');
        case '0x37F933527C':
            return __('common:errors.0x37F933527C');
        case '0x3800595F12':
            return __('common:errors.0x3800595F12');
        case '0x3800595F13':
            return __('common:errors.0x3800595F13');
        case '0x3800595F14':
            return __('common:errors.0x3800595F14');
        case '0x35B80B3E83':
            return __('common:errors.0x35B80B3E83');
        case '0x35B83A627F':
            if (detail.name === 'payment') {
                return __('common:errors.0x35B83A6281');
            }

            return __('common:errors.0x35B83A627F', {
                name:
                    detail.name === 'transaction_credit'
                        ? __('billing_transaction:to_the_credit')
                        : __('billing_transaction:to_the_credit_note'),
            });
        case '0x35B83A6281':
            if (detail.name === 'payment') {
                return __('common:errors.0x35B83A6281');
            }

            return __('common:errors.0x35B83A627F', {
                name:
                    detail.name === 'transaction_debit'
                        ? __('billing_transaction:to_the_debit')
                        : __('billing_transaction:to_the_invoice'),
            });
        case '0x35B83A628F':
            return __('common:errors.0x35B83A627F', {name: detail.name});
        case '0x35B83A6282':
            return __('common:errors.0x35B83A6281');
        case '0x2EAA817032':
            return __('common:errors.0x2EAA817032');
        case '0x381D24DA3A':
            return __('common:errors.0x381D24DA3A');
        case '0x381D33AA9F':
            return __('common:errors.0x381D33AA9F');
        default:
            return __('common:errors.generic');
    }
}
