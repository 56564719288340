<template>
    <div
        v-if="can('read', 'tasks') && tasks"
        class="BadgeTasks_wrapper"
    >
        <MPopover
            v-if="hasParentComponent"
            trigger="hover"
        >
            <div class="BadgeTasks">
                <slot />
                <div
                    class="BadgeTasks__badge-container"
                    :class="badgePosition"
                >
                    <MNotificationBadge :count="tasks.count()" />
                </div>
            </div>
            <template #content>
                <TasksList :tasks="tasks" />
            </template>
        </MPopover>
        <div
            v-else
            class="BadgeTasks"
        >
            <slot />
            <MPopover
                class="BadgeTasks__badge-container"
                :class="badgePosition"
                trigger="hover"
            >
                <MNotificationBadge :count="tasks.count()" />
                <template #content>
                    <TasksList :tasks="tasks" />
                </template>
            </MPopover>
        </div>
    </div>
    <div
        v-else
        class="BadgeTasks_wrapper"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import type {ModelCollection} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted} from 'vue';

    import TasksList from '@/modules/activity/components/organisms/TasksList.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useMetrics from '@/modules/app/composables/useRum';
    import MNotificationBadge from '@/modules/meeko-ui/components/MNotificationBadge.vue';

    export default defineComponent({
        components: {TasksList, MNotificationBadge},
        props: {
            tasks: {
                type: Object as PropType<ModelCollection>,
                default: undefined,
            },
            size: {
                type: String as PropType<'sm' | 'base'>,
                default: 'base',
            },
            hasParentComponent: {
                type: Boolean,
                default: true,
            },
        },
        setup(props) {
            const {can} = useAbility();
            const {addAction} = useMetrics();

            onMounted(() => {
                const tasksCount = props.tasks?.count() ?? 0;

                if (can('read', 'tasks') && tasksCount) {
                    addAction('M_Badge_Tasks1_Display', {value: tasksCount});
                }
            });

            const badgePosition = computed(() => {
                const positions = {
                    sm: 'tw--top-1 tw--right-1',
                    base: 'tw--top-1.5 tw--right-1.5',
                };

                return positions[props.size];
            });

            return {can, badgePosition};
        },
    });
</script>

<style scoped>
    .BadgeTasks {
        @apply tw-relative;
        @apply hover:tw-cursor-pointer;
    }

    .BadgeTasks__badge-container {
        @apply tw-absolute;
    }
</style>
