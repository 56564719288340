<template>
    <div class="CustomerHeroProperties">
        <CustomerHeroPropertiesLine
            :has-value="!!customerModel.attributes.name"
            :title="__('common:name')"
            :model-value="customerModel.attributes.name"
        />
        <CustomerHeroPropertiesLine
            :has-value="!!customerModel.attributes.person"
            :title="__('billing_customer:entity')"
        >
            <CBadge>
                <template v-if="customerModel.attributes.person === 'legal'">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-city"
                    />
                    {{ __('billing_customer:entity_legal') }}
                </template>
                <template v-else>
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-user"
                    />
                    {{ __('billing_customer:entity_natural') }}
                </template>
            </CBadge>
        </CustomerHeroPropertiesLine>
        <CustomerHeroPropertiesLine
            :has-value="!!customerModel.attributes.account_number"
            :title="__('billing_customer:account_number')"
            :model-value="customerModel.attributes.account_number"
        />
        <CustomerHeroPropertiesLine
            v-if="customerModel.attributes.person === 'legal'"
            :has-value="!!customerModel.attributes.vat_number"
            :title="__('billing_customer:tva_number')"
            :model-value="customerModel.attributes.vat_number"
        />
        <CustomerHeroPropertiesLine
            v-if="familyModelCollection.length"
            :has-value="!!familyModelCollection"
            :title="__('billing_customer:linked_family')"
        >
            <ul>
                <li
                    v-for="familyModel in familyModelCollection"
                    :key="familyModel.id"
                >
                    <MPopover
                        no-padding
                        placement="right"
                        trigger="hover"
                    >
                        <RouterLink :to="{name: 'families.show', params: {family: familyModel.id}}">
                            <MButton
                                class="tw-p-0"
                                :icon-external-link="true"
                                variant="link-purple"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-duotone fa-address-book" />
                                </template>
                                {{ familyModel.attributes.name }}
                            </MButton>
                        </RouterLink>
                        <template #content>
                            <FamilyProfile :family-model="familyModel" />
                        </template>
                    </MPopover>
                </li>
            </ul>
        </CustomerHeroPropertiesLine>
    </div>
</template>

<script lang="ts">
    import {defineComponent, type PropType} from 'vue';

    import CustomerHeroPropertiesLine from '@/modules/cashier/components/core/customer/CustomerHero/molecules/CustomerHeroPropertiesLine.vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import FamilyProfile from '@/modules/family/components/family/FamilyProfile.vue';
    import type FamilyModel from '@/modules/family/models/FamilyModel';

    export default defineComponent({
        components: {CustomerHeroPropertiesLine, FamilyProfile},
        props: {
            customerModel: {
                type: Object as PropType<CustomerModel | undefined>,
                default: undefined,
            },
            familyModelCollection: {
                type: Array as PropType<FamilyModel[]>,
                default: () => [],
            },
        },
    });
</script>

<style scoped>
    .CustomerHeroProperties {
        @apply tw-flex tw-flex-col tw-gap-2;
        @apply tw-min-w-0;
    }
</style>
