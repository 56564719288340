<script setup lang="ts">
    import type {ModalType} from '@/modules/app/composables/useModal';

    const emit = defineEmits(['broke']);

    defineProps<{
        modal: ModalType;
        selectedContract: {
            broked_at: string;
        };
        loading: boolean;
    }>();
</script>

<template>
    <MModal
        :header-title="__('family_contract:break_contract')"
        :modal="modal"
    >
        <MForm>
            <MFieldset v-if="selectedContract">
                <MLabel>
                    {{ __('family_contract:broken_at') }}
                </MLabel>
                <MDatePicker
                    v-model="selectedContract.broked_at"
                    class="tw-w-full"
                    format="yyyy-MM-dd"
                />
            </MFieldset>
        </MForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.break')"
                :loading="loading"
                variant="danger"
                @click="emit('broke')"
            />
        </template>
    </MModal>
</template>
