<script setup lang="ts">
    import {computed, ref, watch} from 'vue';
    import {useRoute} from 'vue-router';

    import useUrl from '@/modules/app/composables/useUrl';
    import __ from '@/modules/app/utils/i18n-facade';
    import MeekoFamilyContact from '@/modules/meeko-family/components/MeekoFamilyContact.vue';
    import MeekoFamilyReporting from '@/modules/meeko-family/components/MeekoFamilyReporting.vue';

    const route = useRoute();
    const {pushQueryString} = useUrl();

    const tabsArray = computed(() => {
        return {
            contact: {
                label: __('reporting:coordinates'),
            },
            reporting: {
                label: __('reporting:reporting_distribution'),
            },
        };
    });

    const selectedTab = ref(route.query.tab || 'contact');

    watch(
        selectedTab,
        value => {
            pushQueryString('tab', value);
        },
        {immediate: true}
    );
</script>

<template>
    <MTabs
        :default-tab="selectedTab"
        :tabs="tabsArray"
        @selected="selectedTab = $event"
    >
        <template #tab-content="{label}">
            <div class="tw-truncate">
                {{ label }}
            </div>
        </template>
        <template #default="{activeTab}">
            <div class="tw-mt-4">
                <MeekoFamilyContact v-if="activeTab === 'contact'" />
                <MeekoFamilyReporting v-if="activeTab === 'reporting'" />
            </div>
        </template>
    </MTabs>
</template>
