<template>
    <MPopover2>
        <MPopover2ClickableReference
            v-slot="{open}"
            manual
            class="tw-relative tw-max-w-96 tw-grow"
        >
            <transition name="fade-spinner">
                <div
                    v-show="searching"
                    class="spinner tw-relative tw-z-10"
                >
                    <FontAwesomeIcon
                        icon="fa-solid fa-circle-notch"
                        spin
                    />
                </div>
            </transition>
            <form
                autocomplete="off"
                class="tw-relative tw-z-0"
                @submit.prevent
            >
                <CInput
                    v-model="query"
                    autocomplete="!off"
                    class="search !tw-border-transparent tw-shadow focus:!tw-border-primary-500"
                    :placeholder="__('common:search_dots')"
                    type="text"
                    @click="open"
                />
            </form>
        </MPopover2ClickableReference>
        <MPopover2Panel
            v-slot="{close}"
            panel-class="searchPopover"
            no-padding
            no-arrow
        >
            <div
                v-if="query"
                class="suggestions"
            >
                <template v-if="!_isEmpty(suggestions.kids) && $can('read', 'kids_details')">
                    <div class="type kids">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-child-reaching"
                        />
                        {{ __('common:kid_other') }}
                    </div>
                    <kid-suggestion
                        v-for="(suggestion, key) in suggestions.kids"
                        :key="'suggestions.kids-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click="hideResults(close)"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.customers) && allows('access-to-billings-module')">
                    <div class="type customers">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-file-user"
                        />
                        {{ __('common:customer_other') }}
                    </div>
                    <customer-suggestion
                        v-for="(suggestion, key) in suggestions.customers"
                        :key="'suggestions.customers-' + key"
                        :nursery="nursery"
                        :query="query"
                        :suggestion="suggestion"
                        @click="hideResults(close)"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.registrations) && $can('read', 'registrations')">
                    <div class="type registrations">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-pen-alt"
                        />
                        {{ __('common:registration_other') }}
                    </div>
                    <registration-suggestion
                        v-for="(suggestion, key) in suggestions.registrations"
                        :key="'suggestions.registrations-' + key"
                        :nursery="nursery"
                        :query="query"
                        :suggestion="suggestion"
                        @click="hideResults(close)"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.staffs) && $can('read', 'staffs_details')">
                    <div class="type staffs">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-users"
                        />
                        {{ __('common:team_other') }}
                    </div>
                    <staff-suggestion
                        v-for="(suggestion, key) in suggestions.staffs"
                        :key="'suggestions.staffs-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click="hideResults(close)"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.familyMembers) && $can('read', 'family_members_details')">
                    <div class="type familyMembers">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-user-tie"
                        />
                        {{ __('common:parent_other') }}
                    </div>
                    <familyMember-suggestion
                        v-for="(suggestion, key) in suggestions.familyMembers"
                        :key="'suggestions.familyMembers-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click="hideResults(close)"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.documents) && $can('read', 'documents')">
                    <div class="type documents">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-file-invoice"
                        />
                        {{ __('common:document_other') }}
                    </div>
                    <document-suggestion
                        v-for="(suggestion, key) in suggestions.documents"
                        :key="'suggestions.documents-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click="hideResults(close)"
                    />
                </template>
                <template v-if="!_isEmpty(suggestions.invoices) && $can('read', 'invoices')">
                    <div class="type invoices">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-file-invoice"
                        />
                        {{ __('common:invoice_other') }}
                    </div>
                    <invoice-suggestion
                        v-for="(suggestion, key) in suggestions.invoices"
                        :key="'suggestions.invoices-' + key"
                        :query="query"
                        :suggestion="suggestion"
                        @click="hideResults(close)"
                    />
                </template>
                <CText
                    v-if="
                        _isEmpty(suggestions.kids) &&
                        _isEmpty(suggestions.staffs) &&
                        _isEmpty(suggestions.invoices) &&
                        _isEmpty(suggestions.familyMembers) &&
                        _isEmpty(suggestions.documents) &&
                        _isEmpty(suggestions.registrations) &&
                        _isEmpty(suggestions.customers)
                    "
                    align="center"
                    as="div"
                    class="tw-p-4"
                    font-size="lg"
                    variant="disabled"
                >
                    {{ __('common:no_match') }}
                </CText>
            </div>
            <div v-else>
                <CText
                    align="center"
                    as="div"
                    class="tw-p-4"
                    font-size="lg"
                    variant="disabled"
                >
                    {{ __('common:search_kid_parent_team_invoice') }}
                </CText>
            </div>
        </MPopover2Panel>
    </MPopover2>
</template>

<script>
    import _debounce from 'lodash-es/debounce';
    import _isEmpty from 'lodash-es/isEmpty';

    import useAbility from '@/modules/app/composables/useAbility';
    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';

    import Customer from './Types/Customer.vue';
    import Document from './Types/Document.vue';
    import FamilyMember from './Types/FamilyMember.vue';
    import Invoice from './Types/Invoice.vue';
    import Kid from './Types/Kid.vue';
    import Registration from './Types/Registration.vue';
    import Staff from './Types/Staff.vue';

    export default {
        components: {
            'kid-suggestion': Kid,
            'staff-suggestion': Staff,
            'invoice-suggestion': Invoice,
            'familyMember-suggestion': FamilyMember,
            'document-suggestion': Document,
            'registration-suggestion': Registration,
            'customer-suggestion': Customer,
        },
        props: ['nursery'],
        data() {
            return {
                query: '',
                showSuggestions: false,
                suggestions: [],
                searching: false,
            };
        },
        computed: {
            allows() {
                return useAbility().allows;
            },
            _isEmpty() {
                return _isEmpty;
            },
        },
        watch: {
            query: _debounce(function () {
                if (this.query) {
                    this.search();
                }
            }, 500),
        },
        mounted() {
            const app = document.getElementById('dashboard');

            app.addEventListener('click', () => {
                this.showSuggestions = false;
            });
        },
        methods: {
            search() {
                this.searching = true;
                useApi()
                    .legacy.get(route('search'), {
                        params: {
                            nursery: this.nursery.id,
                            query: this.query,
                        },
                    })
                    .then(response => {
                        this.suggestions = response.data;
                    })
                    .finally(() => {
                        this.searching = false;
                    });
            },
            hideResults(closeFn) {
                this.showSuggestions = false;
                closeFn();
            },
        },
    };
</script>

<style>
    .searchPopover {
        @apply tw-w-full;
        width: calc(100vw - 16px);
        @media screen(sm) {
            width: 460px;
        }
    }
</style>
<style scoped>
    .suggestions {
        @apply tw-overflow-y-auto tw-overscroll-contain tw-rounded-xl;
        max-height: calc(100vh - 120px);
        -webkit-overflow-scrolling: touch;
    }

    .spinner {
        position: absolute;
        top: 4px;
        right: 10px;
        z-index: 1;
        font-size: 15px;
        @apply tw-text-gray-400;
    }

    .search {
        background: #fff
            url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1MC4zMTMgMjUwLjMxMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUwLjMxMyAyNTAuMzEzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCI+CjxnIGlkPSJTZWFyY2giPgoJPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgZD0iTTI0NC4xODYsMjE0LjYwNGwtNTQuMzc5LTU0LjM3OGMtMC4yODktMC4yODktMC42MjgtMC40OTEtMC45My0wLjc2ICAgYzEwLjctMTYuMjMxLDE2Ljk0NS0zNS42NiwxNi45NDUtNTYuNTU0QzIwNS44MjIsNDYuMDc1LDE1OS43NDcsMCwxMDIuOTExLDBTMCw0Ni4wNzUsMCwxMDIuOTExICAgYzAsNTYuODM1LDQ2LjA3NCwxMDIuOTExLDEwMi45MSwxMDIuOTExYzIwLjg5NSwwLDQwLjMyMy02LjI0NSw1Ni41NTQtMTYuOTQ1YzAuMjY5LDAuMzAxLDAuNDcsMC42NCwwLjc1OSwwLjkyOWw1NC4zOCw1NC4zOCAgIGM4LjE2OSw4LjE2OCwyMS40MTMsOC4xNjgsMjkuNTgzLDBDMjUyLjM1NCwyMzYuMDE3LDI1Mi4zNTQsMjIyLjc3MywyNDQuMTg2LDIxNC42MDR6IE0xMDIuOTExLDE3MC4xNDYgICBjLTM3LjEzNCwwLTY3LjIzNi0zMC4xMDItNjcuMjM2LTY3LjIzNWMwLTM3LjEzNCwzMC4xMDMtNjcuMjM2LDY3LjIzNi02Ny4yMzZjMzcuMTMyLDAsNjcuMjM1LDMwLjEwMyw2Ny4yMzUsNjcuMjM2ICAgQzE3MC4xNDYsMTQwLjA0NCwxNDAuMDQzLDE3MC4xNDYsMTAyLjkxMSwxNzAuMTQ2eiIgZmlsbD0iIzg3OThhYSIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=)
            no-repeat 10px 8px;
        background-size: 13px 13px;
        padding-right: 32px;
        padding-left: 32px;
    }

    .type {
        position: relative;
        z-index: 2;
        margin-top: -1px;
        padding: 12px 15px 11px;
        color: rgba(255, 255, 255, 0.9);
        font-size: 80%;
        @apply tw-font-bold;
        text-transform: uppercase;

        &.kids {
            background: #e9e0fb;
            color: #663ab7;
        }

        &.staffs {
            background: #ffeff4;
            color: #e91e63;
        }

        &.invoices {
            background: #ffe6cc;
            color: #ff5722;
        }

        &.familyMembers {
            background: #ffeffb;
            color: #b73a9c;
        }

        &.documents {
            background: #e3cdf8;
            color: #673ab7;
        }

        &.registrations {
            background: #b1f3fc;
            color: #0098ac;
        }

        &.customers {
            background: #ffe6cc;
            color: #ff7e23;
        }
    }

    .type-icon {
        position: absolute;
        top: 11px;
        left: 11px;
        font-size: 18px;
    }

    .fade-spinner-enter-active,
    .fade-spinner-leave-active {
        transition: opacity 0.2s;
    }

    .fade-spinner-enter-from, .fade-spinner-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>

<style>
    .suggestions > .suggestion {
        display: block;
        position: relative;
        margin-top: -1px;
        padding: 19px 29px 18px;
        overflow: hidden;

        &:after {
            position: absolute;
            right: 29px;
            bottom: 0;
            left: 29px;
            border-bottom: 1px solid #ecf0f4;
            content: '';
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        &:hover {
            background-color: #f6f9fc;
            text-decoration: none;

            &:after {
                display: none;
            }

            .header {
                .title {
                    @apply tw-text-primary-500;
                }
            }

            dt {
                @apply tw-text-gray-500;
            }

            dd {
                @apply tw-text-black;
            }
        }

        .avatar {
            float: left;
            margin-right: 10px;
            width: 30px;
            height: 30px;
        }

        .additionals {
            margin-top: 0.5rem;
        }

        .additional {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            dt {
                display: inline-block;
                clear: both;
                width: 25%;
                @apply tw-text-gray-500;
                float: left;
                margin-bottom: 0;
                font-weight: normal;
            }

            dd {
                display: inline;
                width: 75%;
                word-break: break-all;
                @apply tw-text-black;
                float: left;
                margin-bottom: 0;
            }
        }

        .header {
            margin: 6px 0 5px;

            .title {
                @apply tw-font-display tw-text-xl tw-text-gray-700;
                flex-shrink: 1;
                padding-right: 5px;
                line-height: 24px;
                word-break: break-word;
            }
        }
    }
</style>
