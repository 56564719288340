<script setup lang="ts">
    import {useMagicKeys} from '@vueuse/core';
    import {type PropType, toRef, watch} from 'vue';

    import MDatePickerContent from '@/modules/meeko-ui/components/MDatePicker/MDatePickerContent.vue';
    import MDatePickerInput from '@/modules/meeko-ui/components/MDatePicker/MDatePickerInput.vue';
    import {defineFloatingContextBus} from '@/modules/meeko-ui/components/MFloatingContext';
    import type {OutputValueType} from '@/modules/meeko-ui/composables/useMDatePicker';
    import useMDatePicker from '@/modules/meeko-ui/composables/useMDatePicker';

    const props = defineProps({
        modelValue: {type: [String, Object] as PropType<OutputValueType>, default: undefined},
        /**
         * @values dd/MM/yyyy, dd/MM/yyyy HH:mm, iso8601
         */
        format: {
            type: String,
            default: 'iso8601',
        },
        allowUndefined: {
            type: Boolean,
            default: false,
        },
        withTime: {
            type: Boolean,
            default: false,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    const store = useMDatePicker(
        toRef(props, 'modelValue'),
        toRef(props, 'format'),
        toRef(props, 'allowUndefined'),
        toRef(props, 'withTime')
    );

    const {isInputEditable} = store;

    const emit = defineEmits<{
        (e: 'update:modelValue', value: OutputValueType): void;
    }>();

    const bus = defineFloatingContextBus();

    defineExpose({
        show: () => bus.emit('open'),
        hide: () => bus.emit('close'),
        toggle: () => bus.emit('toggle'),
    });

    const {enter} = useMagicKeys();

    function valueChanged(value: OutputValueType, closeFn: () => void) {
        emit('update:modelValue', value);

        if (!props.withTime) {
            closeFn();
        }
    }

    watch(enter, () => bus.emit('close'));
</script>

<template>
    <MPopover2
        :bus="bus"
        class="MDatePicker"
    >
        <MPopover2ClickableReference
            v-slot="{open, close}"
            manual
            :disabled="disabled"
        >
            <MDatePickerInput
                :disabled="disabled"
                :has-error="hasError"
                :store="store"
                :with-time="withTime"
                @hide="close"
                @show="open"
            />
        </MPopover2ClickableReference>
        <MPopover2Panel
            v-slot="{close}"
            @closed="isInputEditable = false"
        >
            <MDatePickerContent
                :store="store"
                @update:model-value="valueChanged($event, close)"
            />
        </MPopover2Panel>
    </MPopover2>
</template>

<style scoped>
    .MDatePicker {
        display: inline-block;
    }
</style>
