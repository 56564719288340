<script setup lang="ts">
    import type {Placement} from '@floating-ui/vue';
    import {inject, type PropType, provide, toRef, watch} from 'vue';

    import {defineFloatingContext, type Trigger} from '@/modules/meeko-ui/components/MFloatingContext';
    import {MPopoverState} from '@/modules/meeko-ui/components/MPopoverState';

    const props = defineProps({
        trigger: {
            type: String as PropType<Trigger>,
            default: 'click',
            validator: (value: string) => ['none', 'click', 'hover'].includes(value),
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start',
            validator: (value: string) =>
                [
                    'top',
                    'top-start',
                    'top-end',
                    'right',
                    'right-start',
                    'right-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                ].includes(value),
        },
        initialFocusRef: {
            type: Object,
            default: null,
        },
        popoverButtonClass: {
            type: String,
            default: '',
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    });

    const context = defineFloatingContext({
        placement: toRef(props, 'placement'),
        trigger: toRef(props, 'trigger'),
        initialFocusRefEl: toRef(props, 'initialFocusRef'),
    });

    const {closePopover, isVisible, setParentContext} = context;

    setParentContext(inject<ReturnType<typeof defineFloatingContext> | undefined>('MFloatingContext', undefined));

    const state = MPopoverState();
    provide('MPopoverState', state);

    const emit = defineEmits(['clickOutside', 'closed']);

    defineExpose({
        closePopover,
        isVisible,
    });

    watch(isVisible, (newIsVisible, oldIsVisible) => {
        if (!newIsVisible && oldIsVisible) {
            emit('closed');
        }
    });

    provide('MFloatingContext', context);
</script>

<template>
    <div class="MPopover">
        <MPopoverButton :class="popoverButtonClass">
            <slot />
        </MPopoverButton>
        <MPopoverPanel
            :no-padding="noPadding"
            @click-outside="emit('clickOutside')"
        >
            <slot
                :close="closePopover"
                name="content"
            />
        </MPopoverPanel>
    </div>
</template>

<style scoped>
    .MPopover {
        @apply tw-inline-flex;
    }
</style>
