<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-variant="buttonVariant"
        :has-unselect="hasUnselect"
        :hide-selected-option="hideSelectedOption"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :prevent-hide-on-click="preventHideOnClick"
        :search-bar="searchBar"
        :model-value="modelValue"
        :wrapper="wrapper"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @update:model-value="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            <slot name="button-text-empty" />
        </template>

        <template #button-text="{value}">
            {{ value.fullname }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: values[0].fullname}) }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-items-center tw-pr-4">
                <div class="tw-mr-2">
                    <MemberAvatar
                        class="tw-w-6"
                        :member-model="option"
                    />
                </div>
                <div
                    class="tw-truncate"
                    v-html="highlight(searchValue, option.fullname)"
                />
            </div>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import MemberAvatar from '@/modules/app/components/atoms/avatars/MemberAvatar.vue';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import MemberModel from '@/modules/family/models/MemberModel';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';

    export default defineComponent({
        components: {MemberAvatar, ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            modelValue: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            searchBar: {type: Boolean, default: true},
            hideSelectedOption: {type: Boolean, default: false},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            onlyUserOrganizations: {type: Boolean, default: false},
            hasActiveContract: {type: Boolean, default: false},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            wrapper: {type: String as PropType<'MPopover2' | 'ResourceFinderWrapper'>, default: 'MPopover2'},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function (value) {
                emit('update:modelValue', value);
            };
            // !!! END: DO NOT EDIT !!

            const queryBuilder = computed(() => {
                const builder = MemberModel.query()
                    .orderBy('last_name')
                    .where(query => {
                        query
                            .whereLike('last_name', `*${search.value}*`)
                            .orWhereLike('first_name', `*${search.value}*`);
                    });

                // Only get members that are related to the current user organizations
                if (props.onlyUserOrganizations) {
                    builder.whereHas(new MemberModel().organizations(), query => {
                        return query.scope('userOrganizations');
                    });
                }

                if (props.hasActiveContract && !search.value) {
                    builder.whereHas(new MemberModel().kids(), query2 => query2.scope('withActiveContract'));
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function () {
                //
            };

            return {
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
