<template>
    <div
        v-if="selectedMedia"
        class="MMediaFullScreen tw-group"
    >
        <div
            v-if="selectedMedia.video"
            class="MMediaFullScreen__video-wrapper"
        >
            <PhotoVideoPlayer
                class="MMediaFullScreen__video"
                :extension-type="selectedMedia.type"
                :url="selectedMedia.photo_url"
            />
        </div>
        <div
            v-else
            class="MMediaFullScreen__img"
            :style="!selectedMedia.video ? originalImage(selectedMedia, selectedMedia.description) : null"
        />
        <div class="MMediaFullScreen__description tw-opacity-0 group-hover:tw-opacity-100">
            <span class="MMediaFullScreen__description-date">
                {{ Epoch.fromJSDate(date).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </span>
            <p v-if="selectedMedia.description">
                {{ selectedMedia.description }}
            </p>
        </div>
        <div
            class="MMediaFullScreen__close-button tw-opacity-0 group-hover:tw-opacity-100"
            @click="$emit('close', $event)"
        >
            <FontAwesomeIcon
                class="tw-text-3xl"
                icon="fa-solid fa-xmark"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {computed, defineComponent} from 'vue';

    import PhotoVideoPlayer from '@/modules/photo/components/atoms/PhotoVideoPlayer.vue';

    export default defineComponent({
        components: {
            PhotoVideoPlayer,
        },
        props: {
            selectedMedia: {
                type: Object,
                required: true,
            },
        },
        emits: ['close'],

        setup(props) {
            function originalImage(photo, description) {
                if (description) {
                    return `background: url("${photo.photo_url}") center center no-repeat; background-size: contain;`;
                }

                return `background: url("${photo.photo_url}") center center no-repeat; background-size: contain;`;
            }

            const date = computed(() => {
                return Epoch.fromTimestamp(props.selectedMedia.taken_at).toJSDate();
            });

            return {
                Epoch,
                originalImage,
                date,
            };
        },
    });
</script>

<style scoped>
    .MMediaFullScreen {
        z-index: 3000;
        @apply tw-fixed tw-inset-0 tw-bg-[#000000]/90;
    }

    .MMediaFullScreen__img {
        @apply tw-absolute tw-inset-5;
    }

    .MMediaFullScreen__video-wrapper {
        @apply tw-absolute tw-inset-5;
        @apply tw-flex tw-items-center tw-justify-center;
    }

    .MMediaFullScreen__description {
        @apply tw-absolute tw-inset-x-0 tw-bottom-0;
        @apply tw-text-white;
        @apply tw-bg-gradient-to-t tw-from-[#000000]/100;
        @apply tw-px-8 tw-py-8;
        @apply tw-transition-opacity tw-duration-300 tw-ease-in-out;
        @apply tw-flex tw-flex-col tw-gap-1;
    }

    .MMediaFullScreen__description-date {
        @apply tw-font-semibold;
    }

    .MMediaFullScreen__close-button {
        @apply tw-absolute tw-right-5 tw-top-5;
        @apply tw-rounded-full tw-bg-white/50 tw-p-2 hover:tw-bg-white;
        @apply tw-h-10 tw-w-10;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-cursor-pointer;
        @apply tw-transition-all tw-duration-300 tw-ease-in-out;
    }
</style>
