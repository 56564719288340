import type {SerializerType} from '@/modules/app/composables/useMagicFilter';

export default {
    read: async (value: string) => {
        if (value === '1') {
            return true;
        }

        return false;
    },
    write: (value: boolean) => {
        return value ? '1' : '0';
    },
} as SerializerType;
