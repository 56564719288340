<script setup lang="ts">
    import {ref} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';

    defineProps<{
        modelValue: string[];
    }>();

    const emit = defineEmits(['update:modelValue']);

    const options = ref([
        {value: 'past', text: __('news:past_news')},
        {value: 'current', text: __('news:current_news')},
        {value: 'future', text: __('news:future_news')},
    ]);
</script>

<template>
    <MSelectMenu
        multi
        :options="options"
        :model-value="modelValue"
        @update:model-value="emit('update:modelValue', $event)"
    >
        <template #fallback>
            {{ __('news:all_news') }}
        </template>
    </MSelectMenu>
</template>
