<template>
    <div class="EditInvoiceLoader">
        <div class="EditInvoiceLoader__container">
            <Loader />
        </div>
    </div>
</template>

<script setup lang="ts"></script>
<style scoped>
    .EditInvoiceLoader {
        @apply tw-rounded-lg tw-bg-white/50 tw-backdrop-blur;
        @apply tw-absolute tw-left-0 tw-top-0 tw-z-10;
        @apply tw-h-full tw-w-full;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-cursor-not-allowed tw-text-center md:tw-px-48;
    }

    .EditInvoiceLoader__container {
        @apply tw-sticky tw-bottom-0 tw-top-0 tw-cursor-default tw-px-4 tw-py-8;
    }
</style>
