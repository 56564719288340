import {Application} from '@meekohq/lumos';

import providers from '@/bootstrap/providers';
import confApp from '@/config/app';
import confHttp from '@/config/http';

export default Application.configure()
    .withConfig({
        app: confApp,
        http: confHttp,
    })
    .withProviders(providers)
    .create();
