<template>
    <div class="tw-flex tw-flex-wrap tw-gap-2">
        <TagsResourceFinder
            button-size="sm"
            has-unselect
            :inject-query="injectQuery"
            multi
            only-active-organization
            :search-bar="false"
            :model-value="selectedTags"
            :without-tags-option-is-selected="withoutTagsOptionIsSelected"
            @update:model-value="$emit('update:selectedTags', $event)"
            @without-tags-option-is-selected="$emit('update:withoutTagsOptionIsSelected', $event)"
        />
        <FilterContractStates
            size="sm"
            :model-value="contractStates"
            @update:model-value="$emit('update:contract-states', $event)"
        />
    </div>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import FilterContractStates from '@/modules/human-resources/components/contract/FilterContractStates.vue';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import type TagModel from '@/modules/tag/models/TagModel';

    export default defineComponent({
        components: {
            TagsResourceFinder,
            FilterContractStates,
        },
        props: {
            injectQuery: {type: Object as PropType<QueryBuilder<any>> | null, default: null},
            selectedTags: {type: Array as PropType<TagModel[]>, required: true},
            contractStates: {type: Array as PropType<string[]>, required: true},
            withoutTagsOptionIsSelected: {type: Boolean, required: true},
        },
        emits: ['update:selectedTags', 'update:withoutTagsOptionIsSelected', 'update:contract-states'],
    });
</script>
