<script setup lang="ts">
    import {onClickOutside} from '@vueuse/core';
    import {computed, inject, ref, watch} from 'vue';

    import MFloating from '@/modules/meeko-ui/components/MFloating.vue';
    import {type defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const context = inject<ReturnType<typeof defineFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('SegmentMenu must be used inside a Segment');
    }

    const {
        panelId,
        isVisible,
        setPanelElement,
        closeOnEscape,
        openPopover,
        closePopover,
        middlewareData,
        buttonEl,
        childrens,
    } = context;

    const myPanel = ref();

    const emit = defineEmits(['shown', 'clickOutside']);

    setPanelElement(myPanel);

    function onEsc() {
        if (closeOnEscape.value) {
            closePopover(true);
        }
    }

    const ignored = computed(() => [buttonEl, ...childrens.value]);

    onClickOutside(
        myPanel,
        () => {
            closePopover();
            emit('clickOutside');
        },
        {ignore: ignored}
    );

    watch(isVisible, (newIsVisible, oldIsVisible) => {
        if (newIsVisible && !oldIsVisible) {
            emit('shown');
        }
    });
</script>

<template>
    <MFloating>
        <section
            :id="panelId"
            ref="myPanel"
            :aria-hidden="!isVisible"
            class="SegmentMenu"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            @keydown.esc.stop="onEsc"
        >
            <slot />
        </section>
        <Teleport to="#overlay">
            <transition name="overlay">
                <div
                    v-if="isVisible && !middlewareData.hide?.referenceHidden"
                    class="SegementMenu__Overlay"
                />
            </transition>
        </Teleport>
    </MFloating>
</template>

<style scoped>
    .SegementMenu__Overlay {
        @apply tw-fixed tw-inset-0 tw-bg-gray-500/50;

        z-index: 3050;
    }

    .overlay {
        &-enter-active,
        &-leave-active {
            @apply tw-transition-all tw-ease-out;
        }

        &-enter-from,
        &-leave-to {
            @apply tw-opacity-0;
        }
    }

    .SegmentMenu {
        @apply focus-visible:tw-outline-0;
    }
</style>
