<template>
    <CButtonGroup>
        <MButton
            :variant="isUrl('https://api.meeko.app') ? 'primary' : 'light'"
            @click="setUrl('production')"
        >
            Use Production API
        </MButton>
        <MButton
            :variant="isUrl('https://staging-api.meeko.app') ? 'primary' : 'light'"
            @click="setUrl('staging')"
        >
            Use Staging API
        </MButton>
        <MButton
            :variant="isUrl('http://localhost:8080') || !storedUrl ? 'primary' : 'light'"
            @click="setUrl('local')"
        >
            Use Local API
        </MButton>
    </CButtonGroup>
</template>

<script lang="ts">
    import {useStorage} from '@vueuse/core';
    import {defineComponent, ref} from 'vue';

    import app from '@/config/app';
    import services from '@/config/services';

    export default defineComponent({
        props: {},
        setup() {
            const defaultUrl = ref(app.api_base_url);
            const storedUrl = useStorage('cto_api_base_url', null);
            const storedPusherKey = useStorage('cto_pusher_key', null);

            function setUrl(mode) {
                let url;
                let pusherKey;

                if (mode === 'production') {
                    url = app.production_api_base_url;
                    pusherKey = services.pusher.productionKey;
                }

                if (mode === 'staging') {
                    url = app.staging_api_base_url;
                    pusherKey = services.pusher.stagingKey;
                }

                if (mode === 'local') {
                    url = app.local_api_base_url;
                    pusherKey = services.pusher.localKey;
                }

                storedUrl.value = url;
                storedPusherKey.value = pusherKey;
                window.location.reload();
            }

            function isUrl(url) {
                return storedUrl.value === url;
            }

            return {
                setUrl,
                isUrl,
                defaultUrl,
                storedUrl,
            };
        },
    });
</script>

<style scoped></style>
