<template>
    <MMenuItems>
        <template
            v-for="language in availableLanguages"
            :key="language.value"
        >
            <MMenuItem @click="changeLanguage(language.value)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="authUser.attributes.locale === language.value"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                <img
                    :alt="language.text"
                    class="UserLanguageSelector__flag tw-mr-2"
                    :src="languageFlagIcons[language.value]"
                />
                <span>{{ language.text }}</span>
            </MMenuItem>
        </template>
    </MMenuItems>
</template>

<script setup lang="ts">
    import {useRouter} from 'vue-router';

    import FlagDE from '@/assets/images/flags/flag-de.svg';
    import FlagEN from '@/assets/images/flags/flag-en.svg';
    import FlagFR from '@/assets/images/flags/flag-fr.svg';
    import useAuth from '@/modules/app/composables/useAuth';
    import useLocale from '@/modules/app/composables/useLocale';

    const router = useRouter();
    const {user: authUser} = useAuth();
    const {availableLanguages} = useLocale();

    const languageFlagIcons = {
        fr: FlagFR,
        en: FlagEN,
        de: FlagDE,
    };

    async function changeLanguage(language: 'de' | 'en' | 'fr') {
        if (authUser.value.attributes.locale === language) {
            return;
        }

        authUser.value.attributes.locale = language;
        await authUser.value.save();

        router.go(0);
    }
</script>

<style scoped>
    .UserLanguageSelector__flag {
        @apply tw-h-auto tw-w-6 tw-rounded;
    }
</style>
