<template>
    <list-item
        :archived="isPivotArchived"
        :shared="isUsedInAnotherOrganization && !eventType.attributes.internal_id"
    >
        <CHStack
            v-if="loader.isDone('fetchData') && eventType"
            align-y="baseline"
        >
            <CHStack
                align="center"
                class="tw-flex tw-w-2/5 tw-items-center"
            >
                <div
                    class="tw-mr-3 tw-flex tw-h-7 tw-w-7 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full"
                    :style="getIconStyle(eventType.attributes.color)"
                >
                    <CIcon
                        v-if="icon"
                        class="icon tw-justify-center tw-self-center"
                        :path="icon.attributes.path"
                        :provider="icon.attributes.provider"
                    />
                    <template v-else />
                </div>
                <span>{{ eventType.attributes.name }}</span>
            </CHStack>
        </CHStack>
        <template #action>
            <EventTypeListItemMenu
                v-if="activePivot"
                :active-pivot="activePivot"
                class="tw-my-1"
                :event-type="eventType"
                :has-events="hasEvents"
                :is-pivot-archived="isPivotArchived"
                :is-used-in-another-organization="isUsedInAnotherOrganization"
                @deleted="$emit('deleted', $event)"
            />
        </template>
    </list-item>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, reactive, ref, toRef, watch} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import useSharedList from '@/modules/app/composables/useSharedList';
    import type IconModel from '@/modules/app/models/IconModel';
    import EventTypeListItemMenu from '@/modules/human-resources/components/event-type/List/EventTypeListItemMenu.vue';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import DataStore from '@/modules/legacy/store/data.store';

    export default defineComponent({
        components: {
            EventTypeListItemMenu,
        },
        props: {
            eventType: {
                required: true,
                type: Object as PropType<EventTypeModel>,
            },
        },
        emits: ['deleted'],
        setup(props) {
            const {activeOrganization} = useManager();
            const loader = reactive(new Loader());
            const icon = ref<IconModel>();

            const {activePivot, isPivotArchived, isUsedInAnotherOrganization} = useSharedList(
                toRef(props, 'eventType')
            );

            const getIconStyle = function (color) {
                return color ? useEventStyle(ref(color), ref(false), 'md', true).badgeStyle.value : null;
            };

            const calendar = computed(() => {
                props.eventType.calendar().value();
            });

            const hasEvents = computed(() => {
                return !!props.eventType.events().aggregateValue('count');
            });

            watch(
                props.eventType,
                (value): void => {
                    icon.value = DataStore.iconPath(value.attributes.icon_id as string);
                },
                {immediate: true}
            );

            return {
                activeOrganization,
                loader,
                icon,
                isPivotArchived,
                isUsedInAnotherOrganization,
                activePivot,
                calendar,
                getIconStyle,
                hasEvents,
            };
        },
    });
</script>
