<template>
    <router-link
        v-if="routerLinkFn"
        v-slot="{navigate}"
        custom
        :to="routerLinkFn"
    >
        <h3
            class="MListItemTitle MListItemTitle--router"
            :class="[
                {'MListItemTitle--variant-male': variant === 'male'},
                {'MListItemTitle--variant-female': variant === 'female'},
                {'MListItemTitle--variant-no-gender': variant === 'no-gender'},
                {'MListItemTitle--variant-danger': variant === 'danger'},
            ]"
            @click="navigate"
        >
            <slot>
                {{ capitalizedTitle }}
            </slot>
        </h3>
    </router-link>
    <h3
        v-else
        class="MListItemTitle"
        :class="[
            {'MListItemTitle--variant-male': variant === 'male'},
            {'MListItemTitle--variant-female': variant === 'female'},
            {'MListItemTitle--variant-no-gender': variant === 'no-gender'},
            {'MListItemTitle--variant-danger': variant === 'danger'},
        ]"
    >
        <slot>
            {{ capitalizedTitle }}
        </slot>
    </h3>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import useHumanName from '../composables/useHumanName';

    export default defineComponent({
        props: {
            routerLinkFn: {
                type: Object,
                default: undefined,
            },
            title: {
                type: String,
                default: undefined,
            },
            variant: {
                type: String as PropType<'default' | 'male' | 'female' | 'no-gender' | 'danger'>,
                default: undefined,
                required: false,
            },
        },
        setup(props) {
            const {capitalizedName} = useHumanName(props.title);

            const capitalizedTitle = computed(() => {
                if (['male', 'female', 'no-gender'].includes(props.variant)) {
                    return capitalizedName.value;
                }

                return props.title;
            });

            return {
                capitalizedTitle,
            };
        },
    });
</script>

<style scoped>
    .MListItemTitle {
        @apply tw-font-display tw-antialiased;
        @apply tw-text-lg tw-font-medium tw-leading-tight;
        @apply tw-truncate tw-text-primary-950;
    }

    .MListItemTitle--router {
        @apply hover:tw-cursor-pointer hover:tw-text-primary-500;
    }

    .MListItemTitle--variant-male {
        @apply tw-font-grandhotel tw-text-3xl tw-text-boy;
    }

    .MListItemTitle--variant-female {
        @apply tw-font-grandhotel tw-text-3xl tw-text-girl;
    }

    .MListItemTitle--variant-no-gender {
        @apply tw-font-grandhotel tw-text-3xl tw-text-teal-600;
    }

    .MListItemTitle--variant-danger {
        @apply tw-text-danger-600;
    }
</style>
