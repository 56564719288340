<script setup lang="ts">
    import {type PropType} from 'vue';

    import FilterContractStates from '@/modules/family/components/kid/Index/FilterContractStates.vue';
    import FilterGroup from '@/modules/family/components/kid/Index/FilterGroup.vue';
    import type {OutputType} from '@/modules/family/composables/kid/useKidFiltersStore';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import TagModel from '@/modules/tag/models/TagModel';

    defineProps({
        nursery: {
            type: Object as PropType<{id: string; tags: []; groups: []}>,
            required: true,
        },
        filters: {
            type: Object as PropType<OutputType>,
            required: true,
        },
    });

    const tagFinderQuery = TagModel.query().where('type', 'kid');
</script>

<template>
    <CHStack
        gap="2"
        wrap
    >
        <TagsResourceFinder
            v-model="filters.tagsFilter.data.value"
            button-size="sm"
            has-unselect
            :inject-query="tagFinderQuery"
            multi
            only-active-organization
            :search-bar="false"
            :without-tags-option-is-selected="filters.withoutTagFilter.data.value"
            @without-tags-option-is-selected="filters.withoutTagFilter.data.value = $event"
        />

        <FilterContractStates
            v-model="filters.contractStatesFilter.data.value"
            size="sm"
        />

        <FilterGroup
            v-if="nursery.groups && nursery.groups.length"
            class="tw-w-min"
            :nursery="nursery"
            size="sm"
            :model-value="filters.selectedGroupFilter.data.value"
            @update:model-value="filters.selectedGroupFilter.data.value = $event ? $event : undefined"
        />
    </CHStack>
</template>
