<script lang="ts" setup>
    import {app} from '@meekohq/lumos';
    import {useFocus} from '@vueuse/core/index';
    import i18next from 'i18next';
    import {isNil} from 'lodash-es';
    import {computed, ref, watch} from 'vue';

    import type CurrencyModel from '@/modules/cashier/models/CurrencyModel';
    import {NumericFormatterContractBinding} from '@/modules/core/infrastructure/NumericFormatterContract';
    import type {NumericParserContractOptions} from '@/modules/core/infrastructure/NumericParserContract';
    import {NumericParserContractBinding} from '@/modules/core/infrastructure/NumericParserContract';
    import {getNumericParserContractPresetCurrency} from '@/modules/core/infrastructure/NumericParserContractPresets';

    const props = withDefaults(
        defineProps<{
            modelValue?: number;
            currencyModel?: CurrencyModel;
            currencyCode?: string;
            currencySymbol?: string;
            parserOptions?: NumericParserContractOptions;
            min?: number;
            max?: number;
            variant?: string;
            placeholder?: string;
            size?: string;
            align?: 'left' | 'right';
            autofocus?: boolean;
            allowUndefined?: boolean;
            hasError?: boolean;
            readonly?: boolean;
            disabled?: boolean;
        }>(),
        {
            modelValue: undefined,
            currencyModel: undefined,
            currencyCode: undefined,
            currencySymbol: undefined,
            min: undefined,
            max: undefined,
            variant: undefined,
            parserOptions: () => getNumericParserContractPresetCurrency(i18next.language),
            placeholder: undefined,
            size: undefined,
            align: 'right',
            autofocus: false,
            allowUndefined: false,
            hasError: false,
            readonly: false,
            disabled: false,
        }
    );

    const emit = defineEmits<{
        (e: 'update:modelValue', value: number | undefined): void;
        (e: 'change', value: number | undefined): void;
    }>();

    if (!props.currencyCode && !props.currencySymbol && !props.currencyModel) {
        throw new Error('Currency code, currency symbol or currency model must be provided');
    }

    const numericParser = app(NumericParserContractBinding, props.parserOptions);
    const numericFormatter = app(NumericFormatterContractBinding, {});

    const input = ref();
    const {focused} = useFocus(input);

    const inputValue = ref(numericParser.setValue(props.modelValue ?? NaN).asString());
    const internalValue = ref(props.modelValue);

    const currencySymbol = computed(() => {
        if (props.currencySymbol) {
            return props.currencySymbol;
        }

        let currencyCode = '';
        if (props.currencyModel) {
            currencyCode = (props.currencyModel as CurrencyModel).attributes.code ?? '';
        }

        if (props.currencyCode) {
            currencyCode = props.currencyCode;
        }

        if (!props.currencyCode) {
            return '';
        }

        return numericFormatter.getCurrencySymbol(currencyCode);
    });

    function handleInput(value: string) {
        computeInternalValue(value);

        if (isNaN(internalValue.value ?? 0)) {
            return;
        }

        inputValue.value = value;
        emit('update:modelValue', internalValue.value);
    }

    function handleChange(value: string | undefined) {
        computeInternalValue(value);

        if (isNaN(internalValue.value ?? 0)) {
            return;
        }

        emit('change', internalValue.value);
    }

    function computeInternalValue(value: string | undefined) {
        const parsedValue = numericParser.parse(value).asNumber();

        if (isNaN(parsedValue)) {
            internalValue.value = NaN;

            if (value?.trim().length ?? 0) {
                return;
            }

            if (props.allowUndefined) {
                internalValue.value = undefined;
            } else {
                internalValue.value = props.min ?? 0;
            }

            return;
        }

        if (!isNil(props.max) && parsedValue > props.max) {
            internalValue.value = props.max;

            return;
        }

        if (!isNil(props.min) && parsedValue < props.min) {
            internalValue.value = props.min;

            return;
        }

        internalValue.value = parsedValue;
    }

    function setInputValue(value?: string) {
        inputValue.value = value;
        (input.value!.$el as HTMLInputElement).value = value ?? '';
    }

    watch(
        () => props.modelValue,
        newValue => {
            if (newValue === internalValue.value) {
                return;
            }

            internalValue.value = newValue;
            setInputValue(numericParser.setValue(newValue ?? NaN).asString());
        }
    );

    watch(focused, newValue => {
        if (!newValue) {
            handleChange(inputValue.value);

            const formattedValue = numericParser.setValue(internalValue.value ?? NaN).asString();
            setInputValue(formattedValue);
        }
    });
</script>

<template>
    <CInputGroup>
        <MInput
            ref="input"
            :align="align"
            :autofocus="autofocus"
            :disabled="disabled"
            :has-error="hasError"
            :placeholder="placeholder"
            :readonly="readonly"
            :size="size"
            :model-value="inputValue"
            :variant="variant"
            @update:model-value="handleInput"
        />
        <CInputAddon class="MCurrencyInputAddon">
            <slot name="addon">
                {{ currencySymbol }}
            </slot>
        </CInputAddon>
    </CInputGroup>
</template>

<style scoped>
    .MCurrencyInputAddon {
        @apply tw-shrink-0;
    }
</style>
