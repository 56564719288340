<template>
    <div class="TagsManagerList">
        <div
            v-for="(category, index) in categories"
            :key="'Category' + index"
            class="tw-flex tw-flex-col tw-gap-1"
        >
            <TagsManagerListItemHeader :category="category" />
            <TagsManagerListItem
                :category="category"
                :index="index"
                :tags-with-category="tagsWithCategory"
                @copy-tag-and-show-modal="$emit('copyTagAndShowModal', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import TagsManagerListItem from '@/modules/organization/components/TagsManager/TagsManagerListItem.vue';
    import TagsManagerListItemHeader from '@/modules/organization/components/TagsManager/TagsManagerListItemHeader.vue';

    export default defineComponent({
        components: {
            TagsManagerListItem,
            TagsManagerListItemHeader,
        },
        props: {
            categories: {
                type: Array,
                required: true,
            },
            tagsWithCategory: {
                type: Function,
                required: true,
            },
        },
        emits: ['copyTagAndShowModal'],
    });
</script>

<style scoped>
    .TagsManagerList {
        @apply tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3;
    }
</style>
