<template>
    <div class="TransactionForm">
        <MFieldset class="TransactionForm__amount-item">
            <MLabel>
                {{ __('common:amount_one') }}
            </MLabel>
            <MCurrencyInput
                v-model="transaction.attributes.amount"
                class="tw-w-full"
                :currency-code="amountCurrency"
                size="lg"
                @update:model-value="$emit('amountChanged', $event)"
            />
            <CFormErrorMessageList :errors="transactionError.get('amount')" />
        </MFieldset>
        <div
            class="TransactionForm__grid"
            :class="canSelectCustomer ? 'TransactionForm__grid--two-col' : 'TransactionForm__grid--three-col'"
        >
            <MFieldset
                v-if="canSelectCustomer"
                class="TransactionForm__item"
            >
                <MLabel>
                    {{ __('common:customer_one') }}
                </MLabel>
                <CustomerFinder
                    v-model="customer"
                    button-class="tw-w-full"
                    :inject-query="customerFinderQuery"
                />
                <CFormErrorMessageList :errors="transactionError.get('customer_id')" />
            </MFieldset>
            <MFieldset class="TransactionForm__item">
                <MLabel>
                    {{ __('billing_transaction:paid_at') }}
                </MLabel>
                <MDatePicker
                    v-model="transaction.attributes.date"
                    allow-undefined
                />
                <CFormErrorMessageList :errors="transactionError.get('date')" />
            </MFieldset>
            <MFieldset class="TransactionForm__item">
                <MLabel>
                    {{ __('billing_transaction:method') }}
                </MLabel>
                <PaymentMethodFinder
                    v-model="paymentMethod"
                    button-class="tw-w-full"
                />
                <CFormErrorMessageList :errors="transactionError.get('payment_method_id')" />
            </MFieldset>
            <MFieldset class="TransactionForm__item">
                <MLabel>
                    {{ __('billing_transaction:reference') }}
                </MLabel>
                <CInput
                    v-model="transaction.attributes.reference"
                    :placeholder="__('billing_transaction:ref_dash_xxxxx')"
                />
                <CFormErrorMessageList :errors="transactionError.get('reference')" />
            </MFieldset>
        </div>
    </div>
</template>

<script lang="ts">
    import {datadogRum} from '@datadog/browser-rum';
    import type {ValidationErrorItem} from '@meekohq/lumos';
    import {catcher, ValidationError} from '@meekohq/lumos';
    import {isNil} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed, defineComponent, watch} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import CustomerFinder from '@/modules/cashier/components/core/customer/organisms/CustomerFinder.vue';
    import PaymentMethodFinder from '@/modules/cashier/components/payment-method/PaymentMethodFinder.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import type {TransactionStateReturnType} from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {
            PaymentMethodFinder,
            CustomerFinder,
        },
        props: {
            state: {
                type: Object as PropType<TransactionStateReturnType>,
                required: true,
            },
            canSelectCustomer: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['amountChanged'],
        setup(props) {
            const {bus, transaction, customer, paymentMethod, currency, transactionError} = props.state;

            const {activeTenant} = useManager();

            const amountCurrency = computed(() => {
                return transaction.value.computed.currency_iso_code
                    ? transaction.value.computed.currency_iso_code
                    : currency.value?.attributes.code;
            });

            // Fetch only customers from the current organization
            const customerFinderQuery = CustomerModel.query().whereHas(new CustomerModel().tenant(), query => {
                query.where('id', activeTenant.value.getKey());
            });

            bus.on('reset_error', () => {
                transactionError.reset();
            });

            bus.on('error', e => {
                catcher()
                    .on(ValidationError, value => {
                        value.errors.some((item: ValidationErrorItem) => {
                            if (item.code === '0x38181318A9') {
                                useNotification().error(__('common:errors.generic'));
                                datadogRum.addAction('customer error');
                                datadogRum.addError('customer error');
                            }
                        });

                        if (
                            value.model instanceof TransactionModel &&
                            value.model.getKey() === transaction.value.getKey()
                        ) {
                            transactionError.reset();
                            transactionError.addValidationError(value);
                        }
                    })
                    .catch(e);
            });

            watch(
                () => transaction.value.attributes.amount,
                newAmount => {
                    if (isNil(newAmount) || newAmount <= 0) {
                        transactionError.addErrorCode('amount', '0x35C472A229');
                    } else {
                        transactionError.remove('amount', '0x35C472A229');
                    }
                }
            );

            return {
                transactionError,
                amountCurrency,
                customer,
                customerFinderQuery,
                paymentMethod,
                transaction,
            };
        },
    });
</script>

<style scoped>
    .TransactionForm {
        container-name: TransactionForm;
        container-type: inline-size;
    }

    .TransactionForm__amount-item {
        @apply tw-w-full sm:tw-w-full;
    }

    .TransactionForm__grid {
        @apply tw-w-full tw-items-center tw-gap-3;

        @container TransactionForm (min-width: 25rem) {
            @apply tw-grid tw-grid-cols-1;

            &--three-col {
                @apply sm:tw-grid-cols-3;
            }

            &--two-col {
                @apply sm:tw-grid-cols-2;
            }
        }
    }

    .TransactionForm__item {
        @apply tw-mt-3 tw-min-w-0;
    }
</style>
