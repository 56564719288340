<template>
    <transition
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
    >
        <div
            v-if="loading"
            class="c-list-loader"
            :class="{'tw-bottom-0': bottom, 'tw-top-0': top, 'tw-right-0': right, 'tw-left-0': left}"
            :style="'animation-duration: 0.3s; transform: rotate(' + randomRotate() + 'deg);'"
        >
            <FontAwesomeIcon
                class="c-list-loader-icon"
                icon="fa-duotone fa-spinner-third"
                spin
            />
        </div>
    </transition>
</template>

<script lang="ts">
    import {random} from 'lodash-es';
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            loading: {type: Boolean, default: false},
            right: {type: Boolean, default: false},
            bottom: {type: Boolean, default: false},
            left: {type: Boolean, default: false},
            top: {type: Boolean, default: false},
        },
        setup() {
            const randomRotate = function () {
                return random(1, 360);
            };

            return {
                randomRotate,
            };
        },
    });
</script>

<style scoped>
    .c-list-loader {
        @apply tw-absolute;
    }

    .c-list-loader-icon {
        @apply tw-text-2xl tw-text-base tw-text-blue-400;
    }
</style>
