import {escapeRegExp} from 'lodash-es';

export default function () {
    // Accent map of characters to their accent insensitive regex
    const accentsMap = {
        ae: '(ae|æ|ǽ|ǣ)',
        a: '(a|á|ă|ắ|ặ|ằ|ẳ|ẵ|ǎ|â|ấ|ậ|ầ|ẩ|ẫ|ä|ǟ|ȧ|ǡ|ạ|ȁ|à|ả|ȃ|ā|ą|ᶏ|ẚ|å|ǻ|ḁ|ⱥ|ã)',
        c: '(c|ć|č|ç|ḉ|ĉ|ɕ|ċ|ƈ|ȼ)',
        e: '(e|é|ĕ|ě|ȩ|ḝ|ê|ế|ệ|ề|ể|ễ|ḙ|ë|ė|ẹ|ȅ|è|ẻ|ȇ|ē|ḗ|ḕ|ⱸ|ę|ᶒ|ɇ|ẽ|ḛ)',
        i: '(i|í|ĭ|ǐ|î|ï|ḯ|ị|ȉ|ì|ỉ|ȋ|ī|į|ᶖ|ɨ|ĩ|ḭ)',
        n: '(n|ń|ň|ņ|ṋ|ȵ|ṅ|ṇ|ǹ|ɲ|ṉ|ƞ|ᵰ|ᶇ|ɳ|ñ)',
        o: '(o|ó|ŏ|ǒ|ô|ố|ộ|ồ|ổ|ỗ|ö|ȫ|ȯ|ȱ|ọ|ő|ȍ|ò|ỏ|ơ|ớ|ợ|ờ|ở|ỡ|ȏ|ō|ṓ|ṑ|ǫ|ǭ|ø|ǿ|õ|ṍ|ṏ|ȭ)',
        u: '(u|ú|ŭ|ǔ|û|ṷ|ü|ǘ|ǚ|ǜ|ǖ|ṳ|ụ|ű|ȕ|ù|ủ|ư|ứ|ự|ừ|ử|ữ|ȗ|ū|ṻ|ų|ᶙ|ů|ũ|ṹ|ṵ)',
    };

    // Create a regex from the accent map
    const accentsRegex = new RegExp(Object.keys(accentsMap).join('|'), 'g');

    const highlight = function (search: string, body?: string) {
        if (search === '') {
            return body;
        }

        if (!body) {
            return;
        }

        // Replace the search string with a regex that matches the search string with or without accents
        const regExWithAccents = new RegExp(
            '(' +
                escapeRegExp(search)
                    .toLowerCase()
                    .replace(accentsRegex, match => {
                        return accentsMap[match] || match;
                    }) +
                ')',
            'ig'
        );

        // Replace the search string with a span that highlights the search string
        return body.replace(regExWithAccents, '<span class="tw-rounded tw-bg-yellow-300">$1</span>');
    };

    return {
        highlight,
    };
}
