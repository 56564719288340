<template>
    <div
        ref="kidListItemEl"
        class="KidListItem"
    >
        <MagicIndexItemColumn
            :class="showGroup ? 'KidListItem__column-name' : 'KidListItem__column-name--expanded'"
            :grid-enabled="false"
        >
            <div class="tw-flex tw-min-w-0 tw-items-center tw-gap-3">
                <BadgeTasks :tasks="tasks">
                    <LegacyKidAvatar
                        class="tw-w-10"
                        :kid="kid"
                    />
                </BadgeTasks>
                <MListItemTitle
                    :title="$fullName(kid)"
                    :variant="kid.gender === 'male' ? 'male' : 'female'"
                />
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="KidListItem__column-actions"
            :grid-enabled="false"
        >
            <MTooltip>
                <RouterLink :to="{name: 'families.show', params: {nursery: nursery.id, family: kid.family_id}}">
                    <MButton variant="purple">
                        <FontAwesomeIcon icon="fa-solid fa-address-book" />
                    </MButton>
                </RouterLink>
                <template #content>
                    {{ __('common:show_family_page') }}
                </template>
            </MTooltip>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            v-if="showGroup"
            class="KidListItem__column-group"
            :container-width="width"
            :grid-title="__('common:group_one')"
            :is-empty="!getGroupById(kid.group_id)"
        >
            {{ getGroupById(kid.group_id) ? getGroupById(kid.group_id).name : null }}
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="KidListItem__column-age"
            :container-width="width"
            :grid-title="__('common:age')"
        >
            <div>
                {{ useKidAge().getByBirthdate(kid.birthdate) }}
            </div>
            <div
                v-if="kid.birthdate"
                class="tw-text-sm tw-text-gray-400"
            >
                {{ Epoch.fromISOString(kid.birthdate).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="KidListItem__column-contract"
            :container-width="width"
            :grid-title="__('common:start_contract')"
            :is-empty="!kid.contract.started_at"
        >
            <template v-if="kid.contract.started_at">
                {{ Epoch.fromISOString(kid.contract.started_at).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </template>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="KidListItem__column-contract"
            :container-width="width"
            :grid-title="__('common:end_contract')"
            :is-empty="!kid.contract.ended_at"
        >
            <template v-if="kid.contract.ended_at">
                {{ Epoch.fromISOString(kid.contract.ended_at).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </template>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            :class="getGroupById(kid.group_id) ? 'KidListItem__column-tags' : 'KidListItem__column-tags--expanded'"
            :container-width="width"
            :grid-title="__('common:tags')"
        >
            <TagsSelector
                v-if="kidModel"
                :editable="can('update', 'kids_details')"
                size="sm"
                :taggable-model="kidModel"
                taggable-type="kid"
                :tags="kidModel.tags().value().toArray()"
                @click.prevent
                @attached="kidModel.tags().value().push($event)"
                @detached="kidModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
            />
        </MagicIndexItemColumn>
    </div>
</template>

<script lang="ts">
    import {Epoch, type ModelCollection} from '@meekohq/lumos';
    import {useElementSize} from '@vueuse/core';
    import _filter from 'lodash-es/filter';
    import {defineComponent, type PropType, ref} from 'vue';

    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';
    import type KidModel from '@/modules/family/models/KidModel';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default defineComponent({
        components: {
            MagicIndexItemColumn,
            TagsSelector,
            BadgeTasks,
            LegacyKidAvatar,
            MListItemTitle,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
            kidModel: {
                type: Object as PropType<KidModel>,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            tasks: {
                type: Object as PropType<ModelCollection>,
                default: undefined,
            },
            showGroup: {
                type: Boolean,
                required: true,
            },
        },
        setup(props) {
            const {parse, fromMoment} = useEpoch();
            const kidListItemEl = ref(null);
            const {width} = useElementSize(kidListItemEl);
            const {can} = useAbility();

            function getGroupById(id) {
                return _filter(props.nursery.groups, group => {
                    return group.id === id;
                })[0];
            }

            return {
                can,
                kidListItemEl,
                width,
                Epoch,
                fromMoment,
                getGroupById,
                parse,
                useKidAge,
            };
        },
    });
</script>

<style scoped>
    .KidListItem {
        @apply tw-grid tw-grid-cols-24 tw-items-center tw-gap-3;
        container-name: KidListItem;
        container-type: inline-size;
    }

    .KidListItem__column-name {
        @apply tw-col-span-20;

        @container KidListItem (min-width: 48rem) {
            @apply tw-col-span-7;
        }

        @container KidListItem (min-width: 64rem) {
            @apply tw-col-span-8;
        }
    }

    .KidListItem__column-name--expanded {
        @apply tw-col-span-20;

        @container KidListItem (min-width: 48rem) {
            @apply tw-col-span-10;
        }

        @container KidListItem (min-width: 64rem) {
            @apply tw-col-span-11;
        }
    }

    .KidListItem__column-actions {
        @apply tw-col-span-4;
        @apply tw-order-none;
        @apply tw-text-right;

        @container KidListItem (min-width: 48rem) {
            @apply tw-order-last tw-col-span-1;
        }
    }

    .KidListItem__column-group {
        @apply tw-order-last tw-col-span-12;

        @container KidListItem (min-width: 48rem) {
            @apply tw-order-none tw-col-span-3;
        }
    }

    .KidListItem__column-age {
        @apply tw-col-span-full;

        @container KidListItem (min-width: 48rem) {
            @apply tw-col-span-3;
        }
    }

    .KidListItem__column-contract {
        @apply tw-col-span-12;

        @container KidListItem (min-width: 48rem) {
            @apply tw-col-span-3;
        }
    }

    .KidListItem__column-tags {
        @apply tw-col-span-12;

        @container KidListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }

        @container KidListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .KidListItem__column-tags--expanded {
        @apply tw-col-span-full;

        @container KidListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }

        @container KidListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }
</style>
