<template>
    <CModal
        ref="modal"
        :header-title="__('hr:add_job')"
        size="sm"
        @show="onShow"
    >
        <JobForm
            ref="form"
            :job-model="jobModel"
            @loading="loader.set($event)"
            @saved="onJobCreated"
        />
        <div v-if="isJobExistInOtherOrganization.count()">
            <div class="small tw-mt-1">
                <span v-html="__('hr:job_exist_on_another_organization', {name: jobModel.attributes.name})" />
            </div>
            <div class="tw-mt-4">
                <ul>
                    <div
                        v-for="({job, organizations}, index) in isJobExistInOtherOrganization"
                        :key="index"
                        class="tw-flex tw-flex-col tw-items-start tw-gap-2"
                    >
                        <MButton
                            variant="link"
                            @click="onAttachJobToOrga(job)"
                        >
                            {{ __('hr:add_job') }}
                            <CBadge>
                                {{ job.attributes.name }}
                            </CBadge>
                        </MButton>
                        <div>
                            <template v-if="organizations.count() > 1">
                                <span class="tw-text-base">{{ __('common:exists_on') }}</span>
                                <MTooltip placement="bottom-start">
                                    <strong class="tw-text-base">
                                        {{ __('common:organization_with_count', {count: organizations.count()}) }}
                                    </strong>
                                    <template #content>
                                        <ul>
                                            <div class="tw-mb-2 tw-text-base">
                                                {{ __('common:list_of_organizations') }} :
                                            </div>
                                            <li
                                                v-for="(organization, i) in organizations"
                                                :key="i + organization.attributes.name"
                                            >
                                                - {{ organization.attributes.name }}
                                            </li>
                                        </ul>
                                    </template>
                                </MTooltip>
                            </template>
                            <template v-else-if="organizations.count() === 1">
                                <div>
                                    <span class="tw-text-base">
                                        {{ __('common:from') }}
                                        <strong class="tw-text-base">
                                            {{ organizations.first().attributes.name }}
                                        </strong>
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <span
                                    class="tw-text-base"
                                    v-html="__('common:from_account')"
                                />
                            </template>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loader.isLoading()"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script>
    import {collect} from '@meekohq/lumos';

    import __ from '@/modules/app/utils/i18n-facade';
    import JobForm from '@/modules/human-resources/components/job/Forms/JobForm.vue';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import JobOrganizationPivot from '@/modules/human-resources/models/JobOrganizationPivot';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import Manager from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default {
        components: {
            JobForm,
        },
        emits: ['created', 'attachJobToOrga'],

        data() {
            return {
                loader: new Loader(),
                jobModel: '',
                everyJobs: collect(),
                pivots: collect(),
                organizations: collect(),
                manager: Manager,
            };
        },

        computed: {
            organizationJobs() {
                return this.pivots
                    .groupBy(item => item.attributes.organization_id)
                    .get(this.manager.activeOrganization.id, collect())
                    .map(pivot => this.everyJobs.where('id', '===', `${pivot.attributes.job_id}`).first());
            },

            isJobExistInOtherOrganization() {
                return this.everyJobs
                    .reject(item => {
                        // reject every job that is already present in active organization
                        const job = this.organizationJobs.where('id', '===', item.id).first();

                        return item === job;
                    })
                    .filter(job => {
                        // return job with same name and not archived
                        const isArchived = !!job.attributes.archived_at;

                        return job.attributes.name === this.jobModel.attributes.name && !isArchived;
                    })
                    .map(job => {
                        // return job with his organizations to pass in the template to display organization's name where the job is
                        return {
                            job,
                            organizations: this.fetchOrganization(job),
                        };
                    });
            },
        },

        mounted() {
            OrganizationModel.query()
                .all()
                .then(response => {
                    this.organizations = response;
                });
        },

        methods: {
            async onShow() {
                this.jobModel = new JobModel();
                this.jobModel.attributes.account_id = this.manager.legacyAccount.id;

                this.pivots = await JobOrganizationPivot.query().all();

                this.everyJobs = await JobModel.query().with(new JobModel().contracts()).get();
            },

            show() {
                this.$refs.modal.show();
            },

            hide() {
                this.$refs.modal.hide();
            },

            onJobCreated(jobModel) {
                this.hide();
                this.$emit('created', jobModel);
                useNotification().success(__('hr:job_created_successfully'));
            },

            onAttachJobToOrga(jobModel) {
                this.hide();
                this.$emit('attachJobToOrga', jobModel);
                useNotification().success(__('hr:job_linked_successfully'));
            },

            fetchOrganization(job) {
                const pivots = this.pivots.filter(item => item.attributes.job_id === job.id);

                return pivots.map(item => {
                    return this.organizations.first(value => value.id === item.attributes.organization_id);
                });
            },
        },
    };
</script>
