<script setup lang="ts">
    import {ref, watch} from 'vue';

    const {email, isSentToSingleEmail} = defineProps<{
        isSentToSingleEmail: boolean;
        email?: string;
    }>();

    const emit = defineEmits<{
        (e: 'sendToEachParents' | 'sendToSingleEmail'): void;
        (e: 'update:email', value: string): void;
    }>();

    const localEmail = ref(email);

    watch(localEmail, value => {
        emit('update:email', value);
    });
</script>

<template>
    <MFieldset>
        <MLabel>{{ __('common:actions.send_selection_to_colon') }}</MLabel>
        <CButtonGroup class="TaxCertificates__send-double-choice">
            <MButton
                :variant="!isSentToSingleEmail ? 'primary' : 'light'"
                @click="emit('sendToEachParents')"
            >
                {{ __('common:each_parent') }}
            </MButton>
            <MButton
                :variant="isSentToSingleEmail ? 'primary' : 'light'"
                @click="emit('sendToSingleEmail')"
            >
                {{ __('common:specific_email') }}
            </MButton>
        </CButtonGroup>
        <CInputGroup
            v-if="isSentToSingleEmail"
            class="TaxCertificates__send-by-email"
        >
            <CInputAddon>
                <FontAwesomeIcon icon="fa-solid fa-envelope" />
            </CInputAddon>
            <CInput
                v-model="localEmail"
                :placeholder="__('common:email')"
            />
        </CInputGroup>
    </MFieldset>
</template>

<style scoped>
    .TaxCertificates__send-double-choice {
        @apply tw-w-full;
    }

    .TaxCertificates__send-by-email {
        @apply tw-mt-3 tw-w-full lg:tw-w-1/3;
    }
</style>
