import {Binding} from '@meekohq/lumos';

import type {ApiResponseOutputPort} from '@/modules/core/domain/ports/ApiResponseOutputPort';

export const SendRealTimeDeactivationFeedbackInputPortBinding = new Binding<
    SendRealTimeDeactivationFeedbackInputPort,
    ApiResponseOutputPort
>();

export interface SendRealTimeDeactivationFeedbackInputPort {
    execute(feedback: string): Promise<unknown>;
}
