import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

export default class ParticipantErrorHandler extends ErrorHandler {
    protected resolver(): any {
        return {
            domain: {
                '0x335D90AE82': 'common:errors.one_field_required',
            },
        };
    }
}
