<template>
    <div class="tw-absolute tw-left-0 tw-right-0 tw-h-full tw-w-full">
        <div
            class="resizer-left zoom tw-absolute tw--left-3.5 tw-z-20 tw-hidden tw-h-full tw-w-8 tw-cursor-grab tw-items-center tw-pl-3 group-hover:tw-flex"
        >
            <div class="tw-flex tw-h-3/5 tw-w-1.5 tw-items-center tw-rounded-full tw-bg-gray-500 tw-text-center">
                <FontAwesomeIcon
                    class="tw-w-full tw-text-xl tw-text-white"
                    icon="fal fa-ellipsis-v"
                />
            </div>
        </div>
        <div
            class="resizer-right zoom tw-absolute tw--right-4 tw-z-20 tw-hidden tw-h-full tw-w-8 tw-cursor-grab tw-items-center tw-pl-3 group-hover:tw-flex"
        >
            <div class="tw-flex tw-h-3/5 tw-w-1.5 tw-items-center tw-rounded-full tw-bg-gray-500 tw-text-center">
                <FontAwesomeIcon
                    class="tw-w-full tw-text-xl tw-text-white"
                    icon="fal fa-ellipsis-v"
                />
            </div>
        </div>
    </div>
</template>
