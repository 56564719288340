import Bugsnag from '@bugsnag/js';
import type {ValidationError} from '@meekohq/lumos';
import {app} from '@meekohq/lumos';
import {ref} from 'vue';

import useError from '@/modules/app/composables/useError';
import useManager from '@/modules/app/composables/useManager';
import __ from '@/modules/app/utils/i18n-facade';
import {type FamilyAppSettingsEntity} from '@/modules/meeko-family/domain/entities/FamilyAppSettingsEntity';
import {StoreFamilyAppSettingsInputPortBinding} from '@/modules/meeko-family/domain/use-cases/StoreFamilyAppSettingsInputPort';
import useNotification from '@/modules/meeko-ui/composables/useNotification';

export function useSaveFamilyAppSettings() {
    const isSaving = ref(false);
    const {success: notifySuccess, error: notifyError} = useNotification();
    const {activeOrganization} = useManager();
    const errors = useError();

    async function save(settings: FamilyAppSettingsEntity, onSuccess?: () => void) {
        isSaving.value = true;
        errors.reset();

        const interactor = app(StoreFamilyAppSettingsInputPortBinding, {
            success() {
                notifySuccess(__('organization:settings_saved_successfully'));
                activeOrganization.value.fresh();
                onSuccess?.();
            },
            validationError(error: ValidationError) {
                errors.addValidationError(error);
            },
            unexpectedError(error: Error) {
                notifyError(__('common:errors.generic'));
                Bugsnag.notify(error);
            },
        });

        await interactor.execute(settings);

        isSaving.value = false;
    }

    return {
        isSaving,
        save,
        errors,
    };
}
