<template>
    <CModal
        v-if="editValue"
        ref="modal"
        :header-title="__('registration:planning_modification')"
        size="5xl"
    >
        <template #footer>
            <MButton
                v-if="$can('update', 'registrations')"
                class="tw-ml-auto"
                :label="__('common:actions.save')"
                variant="primary"
                @click="save"
            />
        </template>
        <MForm>
            <CFormThreeColumns>
                <MFieldset>
                    <MLabel>{{ __('common:start_contract') }}*</MLabel>
                    <MDatePicker
                        v-model="editValue.contract_started_at"
                        class="tw-w-full"
                        :disabled="!$can('update', 'registrations')"
                        format="yyyy-MM-dd"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('common:end_contract') }}*</MLabel>
                    <MDatePicker
                        v-model="editValue.contract_ended_at"
                        class="tw-w-full"
                        :disabled="!$can('update', 'registrations')"
                        format="yyyy-MM-dd"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        {{ __('registration:flexible_planning') }}
                        <MHelpTooltip class="tw-ml-2">
                            {{ __('registration:can_the_planning_be_adjusted') }}
                        </MHelpTooltip>
                    </MLabel>
                    <MSelectMenu
                        v-model="editValue.negotiable"
                        :disabled="!$can('update', 'registrations')"
                        :options="[
                            {value: 1, text: __('common:yes')},
                            {value: 0, text: __('common:no')},
                        ]"
                    />
                </MFieldset>
            </CFormThreeColumns>
        </MForm>
        <div class="tw-mt-8 tw-text-center">
            <MHeading>
                {{ __('registration:desired_planning') }}
            </MHeading>
            <div class="tw-mt-1 tw-text-sm tw-text-gray-500">
                {{ __('registration:to_modify_a_range_click_on_it') }}
            </div>
        </div>
        <ModulePlanning
            :nursery="nursery"
            :registration="editValue"
        />
    </CModal>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';

    import {nursery} from '@/modules/legacy/mixins/nursery';

    import Planning from './Planning.vue';

    export default {
        components: {
            ModulePlanning: Planning,
        },
        mixins: [nursery],
        props: {
            nursery: {},
            registration: {
                type: Object,
                required: true,
            },
            openingHours: {},
        },
        emits: ['registrationUpdated'],
        data: () => ({
            editValue: null,
        }),
        watch: {
            registration(value) {
                this.editValue = _cloneDeep(value);
            },
        },
        mounted() {
            this.editValue = _cloneDeep(this.registration);
        },
        methods: {
            save() {
                this.$emit('registrationUpdated', this.editValue);
            },
        },
    };
</script>
