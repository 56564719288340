import {Epoch, ServiceProvider} from '@meekohq/lumos';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import {capitalize} from 'lodash-es';
import moment from 'moment';

import app from '@/config/app';
import i18n from '@/config/i18n';

export default class TranslationServiceProvider extends ServiceProvider {
    public async register(): Promise<void> {
        // Register i18next backend
        i18next.use(
            resourcesToBackend(
                (language: string, namespace: string) => import(`../../../locales/${language}/${namespace}.json`)
            )
        );

        // Load LanguageDetector to detect browser language
        i18next.use(LanguageDetector);

        // Sync third party libraries with i18next language
        i18next.on('languageChanged', function (lng) {
            Epoch.setLocale(lng);

            // Set monday as the first week day by default
            moment.updateLocale(lng, {
                week: {
                    dow: 1,
                },
            });
        });

        // Initialize i18next
        await (i18next as any).init({
            fallbackLng: app.fallback_locale,
            ns: i18n.namespaces,
            defaultNS: i18n.defaultNamespace,
            interpolation: {
                escapeValue: false,
            },
            ignoreJSONStructure: true,
            detection: i18n.detection,
        });

        i18next.services.formatter?.add('capitalize', value => {
            return capitalize(value);
        });
    }
}
