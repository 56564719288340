<template>
    <MPopover
        class="KidCard"
        :class="wrapperBaseClass"
        no-padding
        popover-button-class="tw-w-full"
        trigger="hover"
    >
        <div class="KidCard__container">
            <KidAvatar
                class="KidCard__avatar"
                :kid-model="kidModel"
            />
            <div class="KidCard__sub-container">
                <div class="KidCard__name">
                    {{ kidModel.attributes.first_name }}
                </div>
                <div class="KidCard__infos-container">
                    <MBadge
                        size="sm"
                        class="tw-mr-1"
                    >
                        {{ kidModel.humanAge }}
                    </MBadge>
                    <div
                        v-if="groupName"
                        class="KidCard__group"
                    >
                        {{ groupName }}
                    </div>
                </div>
            </div>
        </div>
        <template #content>
            <KidProfile :kid-model="kidModel" />
        </template>
    </MPopover>
</template>

<script lang="ts">
    import {find} from 'lodash-es';
    import {computed, defineComponent, type PropType} from 'vue';

    import KidAvatar from '@/modules/app/components/atoms/avatars/KidAvatar.vue';
    import KidProfile from '@/modules/family/components/kid/KidProfile.vue';
    import type KidModel from '@/modules/family/models/KidModel';

    export default defineComponent({
        components: {KidAvatar, KidProfile},
        props: {
            kidModel: {type: Object as PropType<KidModel>, required: true},
            groups: {type: Array, default: () => [], required: false},
        },
        setup(props) {
            const groupName = computed(() => {
                const group = find(props.groups, (group: any) => {
                    return group.id === props.kidModel.attributes.group_id;
                }) as any;

                if (group) {
                    return group.name;
                }

                return null;
            });

            const wrapperBaseClass = computed(() => {
                const output = [] as any[];

                const variants = {
                    male: 'KidCard--boy-gradient',
                    female: 'KidCard--girl-gradient',
                };
                output.push(variants[props.kidModel.attributes.gender as string]);

                return output;
            });

            return {
                groupName,
                wrapperBaseClass,
            };
        },
    });
</script>

<style scoped>
    .KidCard {
        @apply tw-cursor-pointer tw-rounded-lg tw-shadow hover:tw-scale-105;
        @apply tw-w-full tw-transition tw-duration-150 tw-ease-out;
    }

    .KidCard__container {
        @apply tw-relative tw-flex tw-items-center tw-p-3;
    }

    .KidCard__sub-container {
        @apply tw-ml-2 tw-min-w-0;
    }

    .KidCard__infos-container {
        @apply tw-flex tw-flex-wrap tw-items-center;
    }

    .KidCard__avatar {
        @apply tw-w-10;
    }

    .KidCard__name {
        @apply tw-truncate tw-font-display tw-text-base tw-text-white;
    }

    .KidCard__group {
        @apply tw-truncate tw-text-sm tw-text-white;
    }

    .KidCard--boy-gradient {
        background-image: linear-gradient(
            to bottom right,
            theme(colors.boy.gradient.from),
            theme(colors.boy.gradient.to)
        );
    }

    .KidCard--girl-gradient {
        background-image: linear-gradient(
            to bottom right,
            theme(colors.girl.gradient.from),
            theme(colors.girl.gradient.to)
        );
    }
</style>
