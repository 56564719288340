<template>
    <div class="OpeningManager">
        <loader
            v-if="loading"
            custom-class="la-2x"
        />
        <div
            v-else
            class="OpeningManager__grid"
        >
            <div class="OpeningManager__grid-column">
                <OpeningManagerCategoryHeader
                    icon="fa-regular fa-alarm-clock"
                    :title="__('organization:openings.opening_hours')"
                />
                <OpeningManagerFormOpeningHours
                    v-if="$can('update', 'nursery')"
                    :saving="addingOpeningHour"
                    :new-opening-hour="newOpeningHour"
                    @add-opening-hour="addOpeningHour"
                />
                <div class="OpeningManager__list-container">
                    <OpeningManagerEmptyList
                        v-if="nursery.openingHours.length === 0"
                        :title="__('organization:openings.no_opening_hours')"
                    />
                    <OpeningManagerListOpeningHours
                        v-else
                        :nursery="nursery"
                        @delete-opening-hour="deleteOpeningHour"
                    />
                </div>
            </div>

            <div class="OpeningManager__grid-column">
                <OpeningManagerCategoryHeader
                    icon="fa-regular fa-calendar-exclamation"
                    :title="__('organization:openings.public_holidays')"
                />
                <OpeningManagerFormPublicHolidays
                    v-if="$can('update', 'nursery')"
                    :saving="addingHoliday"
                    :holidays="holidays"
                    @add-holiday="addHoliday"
                />
                <div class="OpeningManager__list-container">
                    <OpeningManagerEmptyList
                        v-if="nursery.holidays.length === 0"
                        :title="__('organization:openings.no_public_holidays')"
                    />
                    <OpeningManagerListPublicHolidays
                        v-else
                        :nursery="nursery"
                        @delete-holiday="deleteHoliday"
                    />
                </div>
            </div>

            <div class="OpeningManager__grid-column">
                <OpeningManagerCategoryHeader
                    :help-text="__('organization:openings.end_date_included')"
                    icon="fa-regular fa-calendar-times"
                    :title="__('organization:openings.closed_periods')"
                />
                <OpeningManagerFormClosedPeriods
                    v-if="$can('update', 'nursery')"
                    :saving="addingClosedPeriod"
                    :new-closed-period="newClosedPeriod"
                    @add-closed-period="addClosedPeriod"
                />
                <OpeningManagerPastClosedPeriods
                    v-if="pastClosedPeriod.length"
                    :past-closed-period="pastClosedPeriod"
                    @delete-closed-period="deleteClosedPeriod"
                />
                <div class="OpeningManager__list-container">
                    <OpeningManagerEmptyList
                        v-if="futureClosedPeriod.length === 0"
                        :title="__('organization:openings.no_closed_periods')"
                    />
                    <OpeningManagerListClosedPeriods
                        v-else
                        :future-closed-period="futureClosedPeriod"
                        @delete-closed-period="deleteClosedPeriod"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import {isNil, set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep.js';
    import _filter from 'lodash-es/filter';
    import _find from 'lodash-es/find';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _sortBy from 'lodash-es/sortBy';
    import moment from 'moment';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OpeningManagerCategoryHeader from '@/modules/organization/components/OpeningManager/OpeningManagerCategoryHeader.vue';
    import OpeningManagerEmptyList from '@/modules/organization/components/OpeningManager/OpeningManagerEmptyList.vue';
    import OpeningManagerFormClosedPeriods from '@/modules/organization/components/OpeningManager/OpeningManagerFormClosedPeriods.vue';
    import OpeningManagerFormOpeningHours from '@/modules/organization/components/OpeningManager/OpeningManagerFormOpeningHours.vue';
    import OpeningManagerFormPublicHolidays from '@/modules/organization/components/OpeningManager/OpeningManagerFormPublicHolidays.vue';
    import OpeningManagerListClosedPeriods from '@/modules/organization/components/OpeningManager/OpeningManagerListClosedPeriods.vue';
    import OpeningManagerListOpeningHours from '@/modules/organization/components/OpeningManager/OpeningManagerListOpeningHours.vue';
    import OpeningManagerListPublicHolidays from '@/modules/organization/components/OpeningManager/OpeningManagerListPublicHolidays.vue';
    import OpeningManagerPastClosedPeriods from '@/modules/organization/components/OpeningManager/OpeningManagerPastClosedPeriods.vue';

    export default {
        components: {
            OpeningManagerEmptyList,
            OpeningManagerCategoryHeader,
            OpeningManagerPastClosedPeriods,
            OpeningManagerFormClosedPeriods,
            OpeningManagerFormPublicHolidays,
            OpeningManagerFormOpeningHours,
            OpeningManagerListOpeningHours,
            OpeningManagerListPublicHolidays,
            OpeningManagerListClosedPeriods,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            addingClosedPeriod: false,
            addingOpeningHour: false,
            addingHoliday: false,
            loading: false,
            holidays: {
                list: [],
                selected: null,
            },
            newOpeningHour: {
                day: 'mon',
                started_at: Epoch.parse('07:00', 'HH:mm'),
                ended_at: Epoch.parse('18:00', 'HH:mm'),
            },
            newClosedPeriod: {},
            today: moment().startOf('day').unix(),
        }),
        computed: {
            pastClosedPeriod() {
                const periods = this.filterClosedPeriod(true);

                return _sortBy(periods, 'started_at');
            },

            futureClosedPeriod() {
                const periods = this.filterClosedPeriod();

                return _sortBy(periods, 'started_at');
            },
        },
        mounted() {
            this.getListHolidays();
        },
        methods: {
            filterClosedPeriod(past = false) {
                return this.nursery.closedPeriods.filter(period => {
                    if (past) {
                        return period.ended_at < this.today;
                    }

                    return period.ended_at >= this.today;
                });
            },

            addHoliday(id) {
                this.addingHoliday = true;

                useApi()
                    .legacy.post(route('nurseries.holidays.store', {nurseries: this.nursery.id}), {holiday_id: id})
                    .then(response => {
                        this.nursery.holidays.push(response.data);
                        const holiday = this.holidays.list.find(item => item.id === id);
                        const index = this.holidays.list.indexOf(holiday);
                        this.holidays.list.splice(index, 1);
                        this.holidays.selected = _head(this.holidays.list).id;
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.addingHoliday = false;
                    });
            },

            deleteHoliday(holiday, index) {
                useMagicModal().deleteConfirmationModal({
                    title: __('organization:openings.delete_public_holidays'),
                    onConfirm: async () => {
                        await useApi()
                            .legacy.delete(
                                route('nurseries.holidays.destroy', {
                                    nurseries: this.nursery.id,
                                    holiday: holiday.id,
                                })
                            )
                            .then(() => {
                                this.nursery.holidays.splice(index, 1);
                                this.holidays.list.push(holiday);
                            })
                            .catch(error => {
                                useNotification().error(error);
                            });
                    },
                });
            },

            getListHolidays() {
                useApi()
                    .legacy.get(route('holidays.index', {nurseries: this.nursery.id}))
                    .then(response => {
                        this.loading = true;
                        this.holidays.list = response.data;
                        const self = this;
                        this.holidays.list = _filter(this.holidays.list, function (item) {
                            return !_find(self.nursery.holidays, {id: item.id});
                        });
                        this.holidays.selected = _head(this.holidays.list).id;
                    })
                    .catch(error => {
                        useNotification().error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            addClosedPeriod() {
                const data = {...this.newClosedPeriod};

                if (
                    this.nursery.closedPeriods.some(closestPeriod => {
                        return (
                            Epoch.fromTimestamp(closestPeriod.started_at).toFormat('yyyy-MM-dd') === data.started_at &&
                            Epoch.fromTimestamp(closestPeriod.ended_at).toFormat('yyyy-MM-dd') === data.ended_at
                        );
                    })
                ) {
                    useNotification().error(__('organization:closest_period_already_exists'));
                    return;
                }

                this.addingClosedPeriod = true;
                data.started_at = moment(moment(data.started_at).format()).unix();

                if (isNil(data.ended_at)) {
                    data.ended_at = moment(moment(data.started_at).endOf('day').format()).unix();
                } else {
                    data.ended_at = moment(moment(data.ended_at).endOf('day').format()).unix();
                }

                useApi()
                    .legacy.post(route('nurseries.closedPeriods.index', {nurseries: this.nursery.id}), data)
                    .then(response => {
                        this.nursery.closedPeriods.push(response.data);
                        this.newClosedPeriod.name = null;
                        this.newClosedPeriod.started_at = null;
                        this.newClosedPeriod.ended_at = null;
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        }
                    })
                    .finally(() => {
                        this.addingClosedPeriod = false;
                    });
            },

            deleteClosedPeriod(id) {
                useMagicModal().deleteConfirmationModal({
                    title: __('organization:openings.delete_closing_period'),
                    onConfirm: async () => {
                        await useApi()
                            .legacy.delete(
                                route('nurseries.closedPeriods.destroy', {
                                    nurseries: this.nursery.id,
                                    closedPeriod: id,
                                })
                            )
                            .then(() => {
                                this.nursery.closedPeriods = this.nursery.closedPeriods.filter(
                                    period => period.id !== id
                                );
                            })
                            .catch(error => {
                                useNotification().error(error);
                            });
                    },
                });
            },

            addOpeningHour() {
                if (
                    this.nursery.openingHours.some(openingHour => {
                        return (
                            openingHour.day === this.newOpeningHour.day &&
                            openingHour.started_at === this.newOpeningHour.started_at.toFormat('HH:mm:ss') &&
                            openingHour.ended_at === this.newOpeningHour.ended_at.toFormat('HH:mm:ss')
                        );
                    })
                ) {
                    useNotification().error(__('organization:opening_period_already_exists'));
                    return;
                }

                this.addingOpeningHour = true;

                const copyOpeningHours = _cloneDeep(this.nursery.openingHours);

                copyOpeningHours.push({
                    day: this.newOpeningHour.day,
                    started_at: this.newOpeningHour.started_at.toFormat('HH:mm:ss'),
                    ended_at: this.newOpeningHour.ended_at.toFormat('HH:mm:ss'),
                });

                useApi()
                    .legacy.post(route('nurseries.openingHours.store', {nurseries: this.nursery.id}), {
                        opening_hours: copyOpeningHours,
                    })
                    .then(response => {
                        this.nursery.openingHours = response.data;
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.addingOpeningHour = false;
                    });
            },

            deleteOpeningHour(i) {
                useMagicModal().deleteConfirmationModal({
                    title: __('organization:openings.delete_opening_period'),
                    onConfirm: async () => {
                        this.nursery.openingHours.splice(i, 1);
                        await useApi()
                            .legacy.post(route('nurseries.openingHours.store', {nurseries: this.nursery.id}), {
                                opening_hours: this.nursery.openingHours,
                            })
                            .then(response => {
                                set(this.nursery.openingHours, response.data);
                            })
                            .catch(error => {
                                useNotification().error(error);
                            });
                    },
                });
            },
        },
    };
</script>

<style scoped>
    .OpeningManager__grid {
        @apply tw-grid tw-grid-cols-1 tw-gap-8 lg:tw-grid-cols-2 xl:tw-grid-cols-3;
    }

    .OpeningManager__grid-column {
        @apply tw-flex tw-flex-col tw-gap-4;
    }

    .OpeningManager__list-container {
        @apply tw-rounded-xl tw-border tw-border-gray-200 tw-p-2;
    }
</style>
