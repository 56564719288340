<template>
    <div
        class="card-chart"
        :class="{settings: showSettings}"
    >
        <div class="chart-title">
            <div class="tw-mb-4 tw-ml-1">
                <div class="tw-mb-4 tw-ml-1 tw-flex tw-flex-row">
                    <div class="tw-flex tw-items-center tw-text-base tw-font-semibold tw-uppercase">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-chart-bar"
                        />
                        {{ selectedDataType.text }}
                    </div>
                    <div
                        class="tw-ml-auto tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-text-sm tw-font-semibold tw-uppercase"
                        @click="showSettings = !showSettings"
                    >
                        {{ fromDate.toFormat('MMM yyyy') }} - {{ toDate.toFormat('MMM yyyy') }}
                        <FontAwesomeIcon
                            class="tw-ml-2"
                            icon="fa-solid fa-pencil"
                        />
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-4 tw-gap-2">
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedDataType.text"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-for="(item, i) in dataTypeOptions"
                                :key="'dataOption' + i"
                                :label="item.text"
                                @click="selectedDataType = item"
                            />
                        </MMenuItems>
                    </MMenu>
                    <MMenu
                        v-if="selectedDataType.value === 'invoices'"
                        class="tw-w-full"
                    >
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedInvoiceType.text"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-for="(item, i) in invoiceTypeOptions"
                                :key="'dataOption' + i"
                                :label="item.text"
                                @click="selectedInvoiceType = item"
                            />
                        </MMenuItems>
                    </MMenu>
                    <MMenu
                        v-else
                        class="tw-w-full"
                    >
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedPaymentType.name"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-for="(item, i) in paymentTypeOptions"
                                :key="'dataOption' + i"
                                :label="item.name"
                                @click="selectedPaymentType = item"
                            />
                        </MMenuItems>
                    </MMenu>
                    <MMonthPicker v-model="fromDate" />
                    <MMonthPicker v-model="toDate" />
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container">
                <div class="tw-w-full">
                    <loader
                        v-if="loading"
                        light="true"
                        shadow="false"
                        size="sm"
                    />
                    <bar-slim
                        v-else
                        ref="chart"
                        :chart-data="chartData"
                        :nursery="nursery"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _map from 'lodash-es/map';

    import useApi from '@/modules/app/composables/useApi';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import __ from '@/modules/app/utils/i18n-facade';
    import paymentMethodsOptions from '@/modules/cashier/utils/payment-method/paymentMethodsOptions';
    import BarSlim from '@/modules/legacy/components/Modules/Charts/BarSlim.vue';
    import {useLocalStorage} from '@/modules/legacy/helpers/useLocalStorage';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            BarSlim,
        },
        props: {
            nursery: {
                type: Object,
            },
            nurseries: {
                type: Array,
            },
        },
        data: () => ({
            chartData: {
                name: __('dashboard:meeko_chart'),
                categories: [],
                colors: [],
                series: [],
            },
            fromDate: Epoch.now(),
            toDate: Epoch.now(),
            dataTypeOptions: [
                {
                    text: __('common:invoice_other'),
                    value: 'invoices',
                },
                {
                    text: __('common:transactions'),
                    value: 'payments',
                },
            ],
            selectedDataType: {},
            invoiceTypeOptions: [
                {
                    text: __('common:both'),
                    value: undefined,
                },
                {
                    text: __('common:invoice_other'),
                    value: 'billed',
                },
                {
                    text: __('common:draft_invoice'),
                    value: 'estimated',
                },
            ],
            selectedInvoiceType: {},
            paymentTypeOptions: [
                {
                    name: __('common:all'),
                    value: undefined,
                },
            ].concat(paymentMethodsOptions()),
            selectedPaymentType: {},
            broadcast_id: Math.floor(100000 + Math.random() * 900000),
            loading: false,
            showSettings: false,
        }),
        watch: {
            'selectedDataType'() {
                this.selectedInvoiceType = this.invoiceTypeOptions[0];
                this.selectedPaymentType = this.paymentTypeOptions[0];
                this.getRevenues();
            },
            'selectedInvoiceType'() {
                this.getRevenues();
            },
            'selectedPaymentType'() {
                this.getRevenues();
            },
            'fromDate'(value) {
                window.localStorage.setItem('dashboard:billing:from', value.toFormat('yyyy-MM'));
                this.getRevenues();
            },
            'toDate'(value) {
                window.localStorage.setItem('dashboard:billing:to', value.toFormat('yyyy-MM'));
                this.getRevenues();
            },
            'nursery.id': {
                handler() {
                    this.refreshChartColors();
                },
                deep: true,
            },
        },
        mounted() {
            this.initFilters();

            useBroadcast().sessionChannel.bind('billing-stats-' + this.broadcast_id, response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.chartData.series = [];
                    response.data.forEach(item => {
                        const nursery = this.nurseries.find(nurs => nurs.id === item.nursery_id);
                        if (nursery) {
                            this.chartData.series.push({
                                id: nursery.id,
                                name: nursery.name,
                                currency: nursery.currency,
                                city: nursery.city,
                                data: _map(item.stats, 'amount'),
                            });
                        }
                    });
                    this.refreshChartColors();
                    this.rereshChartCategories();
                    this.loading = false;
                });
            });
        },
        beforeUnmount() {
            useBroadcast().sessionChannel.unbind('billing-stats-' + this.broadcast_id);
        },
        methods: {
            getRevenues() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                useApi()
                    .legacy.post(route('stats.sales'), {
                        from: this.fromDate.toFormat('yyyy-MM'),
                        to: this.toDate.toFormat('yyyy-MM'),
                        nurseries: _map(this.nurseries, 'id'),
                        type: this.selectedDataType.value === 'payments' ? 'charged' : this.selectedInvoiceType.value,
                        payment_type:
                            this.selectedDataType.value === 'payments' ? this.selectedPaymentType.value : undefined,
                        broadcast_id: this.broadcast_id,
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            rereshChartCategories() {
                this.chartData.categories = [];

                let monthIterator = this.fromDate;
                while (
                    monthIterator.lessThanOrEqualTo(this.toDate) ||
                    monthIterator.toFormat('M') === this.toDate.toFormat('M')
                ) {
                    this.chartData.categories.push(monthIterator.toFormat('MMM'));
                    monthIterator = monthIterator.addMonths();
                }
            },

            refreshChartColors() {
                this.chartData.colors = [];
                this.chartData.series.forEach(nursery => {
                    const color = this.nursery.id === nursery.id ? '#9076fd' : '#0396FF';
                    this.chartData.colors.push(color);
                });
                if (this.$refs.chart && this.$refs.chart.$refs.barslim) {
                    this.$refs.chart.$refs.barslim.updateOptions({colors: this.chartData.colors});
                }
            },

            initFilters() {
                this.selectedDataType = this.dataTypeOptions[0];
                const {getLocalStorage} = useLocalStorage();

                const fromTextValue = getLocalStorage('dashboard:billing:from');
                const toTextValue = getLocalStorage('dashboard:billing:to');

                if (fromTextValue) {
                    this.fromDate = Epoch.parse(fromTextValue, 'yyyy-MM');
                } else {
                    this.fromDate = Epoch.now().startOfMonth();
                }

                if (toTextValue) {
                    this.toDate = Epoch.parse(toTextValue, 'yyyy-MM');
                } else {
                    this.toDate = Epoch.now().startOfMonth().addMonths(5);
                }
            },
        },
    };
</script>
