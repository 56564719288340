<template>
    <CVStack gap="4">
        <div v-if="customer.has_subscription">
            <MHeading
                class="tw-mb-4"
                level="h1"
            >
                {{ __('subscription:your_subscription_meeko') }}
            </MHeading>
            <CVStack gap="2">
                <div>
                    {{ __('subscription:plan_type_colon') }}
                    <CBadge
                        v-if="customer.plan === 'monthly-99-2018'"
                        variant="primary"
                    >
                        {{ __('subscription:monthly_plan') }}
                    </CBadge>
                    <CBadge
                        v-else-if="customer.plan === 'yearly-1188-2018'"
                        variant="primary"
                    >
                        {{ __('subscription:yearly_plan') }}
                    </CBadge>
                </div>
                <div>
                    {{ __('subscription:state_colon') }}
                    <CBadge
                        v-if="customer.subscription_status === 'active' && !customer.on_grace_period"
                        variant="success"
                    >
                        {{ __('subscription:active') }}
                    </CBadge>
                    <template v-if="customer.subscription_status === 'active' && customer.on_grace_period">
                        <CBadge
                            class="tw-mr-1"
                            variant="danger"
                        >
                            {{ __('subscription:renewal_canceled') }}
                        </CBadge>
                        <CBadge>
                            {{
                                __('subscription:expires_on_date', {
                                    date: Epoch.fromTimestamp(customer.ends_at).toLocaleString(Epoch.presets.DATE_FULL),
                                })
                            }}
                        </CBadge>
                    </template>
                    <CBadge v-if="customer.subscription_status === 'canceled'">
                        {{ __('subscription:canceled_male') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'incomplete'"
                        variant="danger"
                    >
                        {{ __('common:unpaid_one') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'unpaid'"
                        variant="danger"
                    >
                        {{ __('common:unpaid_one') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'past_due'"
                        variant="danger"
                    >
                        {{ __('subscription:past_due') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'incomplete_expired'"
                        variant="danger"
                    >
                        {{ __('subscription:expired') }}
                    </CBadge>
                    <CBadge
                        v-if="customer.subscription_status === 'trialing'"
                        variant="success"
                    >
                        {{ __('subscription:trial_period') }}
                    </CBadge>
                </div>
            </CVStack>
        </div>
        <DefaultPlan
            v-else
            class="tw-self-center"
            :customer="customer"
            :nursery="nursery"
        />
    </CVStack>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {defineComponent} from 'vue';

    import DefaultPlan from '@/modules/subscription/components/Plan/DefaultPlan.vue';

    export default defineComponent({
        components: {
            DefaultPlan,
        },
        props: {
            customer: {type: Object, required: true},
            nursery: {type: Object, required: true},
        },
        setup() {
            return {
                Epoch,
            };
        },
    });
</script>
