import {type App, defineAsyncComponent} from 'vue';

export default function (Vue: App) {
    Vue.component(
        'FontAwesomeIcon',
        defineAsyncComponent(() => import('@/modules/app/components/atoms/FontAwesomeIcon.vue'))
    );
    Vue.component(
        'Loader',
        defineAsyncComponent(() => import('@/modules/legacy/components/Modules/Loader.vue'))
    );
    Vue.component(
        'List',
        defineAsyncComponent(() => import('@/modules/legacy/components/lists/List.vue'))
    );
    Vue.component(
        'ListItem',
        defineAsyncComponent(() => import('@/modules/legacy/components/lists/ListItem.vue'))
    );
    Vue.component(
        'ListWrapper',
        defineAsyncComponent(() => import('@/modules/legacy/components/lists/ListWrapper.vue'))
    );
    Vue.component(
        'MFormGroup',
        defineAsyncComponent(() => import('@/modules/legacy/components/OldThemes/Framework/Form/Group.vue'))
    );
    Vue.component(
        'MFormGroupInplaceInputs',
        defineAsyncComponent(
            () => import('@/modules/legacy/components/OldThemes/Framework/Form/GroupInPlaceInputs.vue')
        )
    );
    Vue.component(
        'MFormInplaceEditor',
        defineAsyncComponent(() => import('@/modules/legacy/components/OldThemes/Framework/Form/InPlaceEditor.vue'))
    );
    Vue.component(
        'MFormInput',
        defineAsyncComponent(() => import('@/modules/legacy/components/OldThemes/Framework/Form/Input.vue'))
    );
    Vue.component(
        'PlanningGenerator',
        defineAsyncComponent(() => import('@/modules/legacy/components/Modules/PlanningGenerator/Line.vue'))
    );
    Vue.component(
        'SupportMeeko',
        defineAsyncComponent(() => import('@/modules/app/components/SupportMeeko.vue'))
    );
    Vue.component(
        'Tags',
        defineAsyncComponent(() => import('@/modules/tag/components/TagsLegacy.vue'))
    );
}
