import {until} from '@vueuse/core';
import {ref} from 'vue';

import ConfirmationModal from '@/modules/app/components/organisms/ConfirmationModal.vue';
import ConfirmationWithCheckboxModal from '@/modules/app/components/organisms/ConfirmationWithCheckboxModal.vue';
import ConfirmationWithInputModal from '@/modules/app/components/organisms/ConfirmationWithInputModal.vue';
import ConfirmationWithSelectModal from '@/modules/app/components/organisms/ConfirmationWithSelectModal.vue';
import ErrorModal from '@/modules/app/components/organisms/ErrorModal.vue';
import SuccessModal from '@/modules/app/components/organisms/SuccessModal.vue';
import WarningModal from '@/modules/app/components/organisms/WarningModal.vue';
import useModal from '@/modules/app/composables/useModal';
import useModalManager from '@/modules/app/composables/useModalManager';
import __ from '@/modules/app/utils/i18n-facade';

type ModalType = 'question' | 'warning' | 'danger' | 'success';

export default function useMagicModal() {
    const {fire} = useModalManager();

    async function successModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        onConfirm?: () => Promise<void>;
    }): Promise<{
        isConfirmed: boolean;
    }> {
        const result = ref();

        const modal = useModal({
            component: SuccessModal,
            props: {
                ...option,
            },
            listeners() {
                return {
                    confirmed() {
                        result.value = true;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm();
        }

        modal.hide();
    }

    async function warningModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        onConfirm?: () => Promise<void>;
    }): Promise<{
        isConfirmed: boolean;
    }> {
        const result = ref();

        const modal = useModal({
            component: WarningModal,
            props: {
                ...option,
            },
            listeners() {
                return {
                    confirmed() {
                        result.value = true;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm();
        }

        modal.hide();
    }

    async function errorModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        onConfirm?: () => Promise<void>;
    }): Promise<{
        isConfirmed: boolean;
    }> {
        const result = ref();

        const modal = useModal({
            component: ErrorModal,
            props: {
                ...option,
            },
            listeners() {
                return {
                    confirmed() {
                        result.value = true;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm();
        }

        modal.hide();
    }

    async function confirmationModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        dismissButtonText?: string;
        confirmButtonVariant?: string;
        dismissButtonVariant?: string;
        showCancelButton?: boolean;
        type?: ModalType;
        onConfirm?: () => Promise<void>;
        onDismiss?: () => Promise<void>;
    }): Promise<{
        isConfirmed: boolean;
        isDismissed: boolean;
    }> {
        const result = ref();

        const modal = useModal({
            component: ConfirmationModal,
            props: {
                type: 'question',
                wideButtons: true,
                ...option,
            },
            listeners() {
                return {
                    confirmed() {
                        result.value = true;
                    },
                    dismissed() {
                        result.value = null;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm();
        }

        if (!result.value && option.onDismiss) {
            await option.onDismiss();
        }

        modal.hide();

        return {
            isConfirmed: result.value === true,
            isDismissed: result.value === null,
        };
    }

    async function confirmationWithInputModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        dismissButtonText?: string;
        placeholderText?: string;
        labelText?: string;
        confirmButtonVariant?: string;
        dismissButtonVariant?: string;
        type?: ModalType;
        inputValue?: string;
        onConfirm?: (value: string) => Promise<void>;
        onDismiss?: () => Promise<void>;
    }): Promise<{
        isConfirmed: boolean;
        isDismissed: boolean;
    }> {
        const result = ref();
        const inputValue = ref();

        const modal = useModal({
            component: ConfirmationWithInputModal,
            props: {
                type: 'question',
                wideButtons: true,
                ...option,
            },
            listeners() {
                return {
                    confirmed(input) {
                        result.value = true;
                        inputValue.value = input;
                    },
                    dismissed() {
                        result.value = null;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm(inputValue.value);
        }

        if (!result.value && option.onDismiss) {
            await option.onDismiss();
        }

        modal.hide();

        return {
            isConfirmed: result.value === true,
            isDismissed: result.value === null,
        };
    }

    async function confirmationWithSelectModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        dismissButtonText?: string;
        defaultOption?: string;
        options?: {value: string; text: string}[];
        labelText?: string;
        confirmButtonVariant?: string;
        dismissButtonVariant?: string;
        type?: ModalType;
        onConfirm?: (value: string) => Promise<void>;
        onDismiss?: () => Promise<void>;
    }): Promise<{
        isConfirmed: boolean;
        isDismissed: boolean;
    }> {
        const result = ref();
        const optionValue = ref();

        const modal = useModal({
            component: ConfirmationWithSelectModal,
            props: {
                type: 'question',
                wideButtons: true,
                ...option,
            },
            listeners() {
                return {
                    confirmed(option) {
                        result.value = true;
                        optionValue.value = option;
                    },
                    dismissed() {
                        result.value = null;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm(optionValue.value);
        }

        if (!result.value && option.onDismiss) {
            await option.onDismiss();
        }

        modal.hide();

        return {
            isConfirmed: result.value === true,
            isDismissed: result.value === null,
        };
    }

    async function confirmationWithCheckboxModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        dismissButtonText?: string;
        checkboxText?: string;
        confirmButtonVariant?: string;
        dismissButtonVariant?: string;
        type?: ModalType;
        onConfirm?: (checked: string) => Promise<void>;
        onDismiss?: () => Promise<void>;
    }): Promise<{
        isConfirmed: boolean;
        isDismissed: boolean;
    }> {
        const result = ref();
        const checkedValue = ref();

        const modal = useModal({
            component: ConfirmationWithCheckboxModal,
            props: {
                type: 'question',
                wideButtons: true,
                ...option,
            },
            listeners() {
                return {
                    confirmed(checked) {
                        result.value = true;
                        checkedValue.value = checked;
                    },
                    dismissed() {
                        result.value = null;
                    },
                };
            },
        });

        fire(modal);

        await until(result).toMatch(v => v !== undefined);

        if (result.value && option.onConfirm) {
            await option.onConfirm(checkedValue.value);
        }

        if (!result.value && option.onDismiss) {
            await option.onDismiss();
        }

        modal.hide();

        return {
            isConfirmed: result.value === true,
            isDismissed: result.value === null,
        };
    }

    async function deleteConfirmationModal(option: {
        title?: string;
        text?: string;
        confirmButtonText?: string;
        dismissButtonText?: string;
        onConfirm?: () => Promise<void>;
        onDismiss?: () => Promise<void>;
    }) {
        return confirmationModal({
            confirmButtonText: __('common:actions.delete'),
            confirmButtonVariant: 'danger',
            type: 'danger',
            ...option,
        });
    }

    return {
        successModal,
        warningModal,
        errorModal,
        confirmationModal,
        confirmationWithInputModal,
        confirmationWithSelectModal,
        confirmationWithCheckboxModal,
        deleteConfirmationModal,
    };
}
