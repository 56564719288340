import {Binding} from '@meekohq/lumos';

import type CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import type {TransactionFilterDto} from '@/modules/cashier/transaction/application/dto/TransactionFilterDto';

export const SumTransactionsByCurrenciesUseCaseBinding = new Binding<SumTransactionsByCurrenciesUseCase>();

export interface SumTransactionsByCurrenciesUseCase {
    sumByCurrenciesAndFilters(
        currencies: CurrencyModel[],
        filters: TransactionFilterDto
    ): Promise<
        {
            code: string;
            amount: number;
        }[]
    >;
}
