import {ServiceProvider} from '@meekohq/lumos';

import PhotoKidPivot from '@/modules/photo/models/PhotoKidPivot';
import PhotoModel from '@/modules/photo/models/PhotoModel';

export default class PhotosServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([PhotoModel, PhotoKidPivot]);
    }
}
