import type {BaseError} from '@/modules/app/utils/errors/BaseError';

export class ContractAggregateError extends Error {
    private readonly errors: BaseError[];

    constructor(errors: BaseError[] = []) {
        super('Contract aggregate error');
        this.errors = errors;
    }

    public getErrors(): BaseError[] {
        return this.errors;
    }
}
