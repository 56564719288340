import type {QueryBuilder} from '@meekohq/lumos';
import {Epoch, Model} from '@meekohq/lumos';

import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RegistrationOrganizationPivot from '@/modules/registration/models/RegistrationOrganizationPivot';
import RegistrationPlanning from '@/modules/registration/models/RegistrationPlanning';
import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';

export default class RegistrationModel extends Model {
    public type = 'registration/registrations';

    public attributes: {
        id: string;
    } & Partial<{
        account_id: string;
        uuid: string;
        contract_date_period: {
            started_at: string;
            ended_at: string;
        };
        kid_gender: string;
        kid_first_name: string | null;
        kid_last_name: string;
        kid_birthdate: string;
        kid_birthplace: string | null;
        kid_hygiene: string;
        first_parent_gender: string | null;
        first_parent_birthdate: string | null;
        first_parent_first_name: string | null;
        first_parent_last_name: string | null;
        first_parent_email: string | null;
        first_parent_role: string | null;
        first_parent_job: string | null;
        first_parent_phone: string | null;
        first_parent_mobile_phone: string | null;
        first_parent_office_phone: string | null;
        first_parent_salary: number | null;
        first_parent_csp: number;
        first_parent_alloc_no: string | null;
        first_parent_address: string | null;
        first_parent_city: string | null;
        first_parent_zip_code: string | null;
        first_parent_state: string | null;
        first_parent_country: string;
        first_parent_company_contract: boolean;
        first_parent_company_name: string | null;
        first_parent_company_address: string | null;
        first_parent_company_city: string | null;
        first_parent_company_zip_code: string | null;
        first_parent_company_country: string;
        first_parent_company_phone: string | null;
        second_parent_gender: string | null;
        second_parent_birthdate: string | null;
        second_parent_first_name: string | null;
        second_parent_last_name: string | null;
        second_parent_email: string | null;
        second_parent_role: string | null;
        second_parent_job: string | null;
        second_parent_phone: string | null;
        second_parent_mobile_phone: string | null;
        second_parent_office_phone: string | null;
        second_parent_salary: number | null;
        second_parent_csp: number;
        second_parent_alloc_no: string | null;
        second_parent_address: string | null;
        second_parent_city: string | null;
        second_parent_zip_code: string | null;
        second_parent_state: string | null;
        second_parent_country: string;
        second_parent_company_contract: boolean;
        second_parent_company_name: string | null;
        second_parent_company_address: string | null;
        second_parent_company_city: string | null;
        second_parent_company_zip_code: string | null;
        second_parent_company_country: string;
        second_parent_company_phone: string | null;
        contract_recurrent_weeks: number | null;
        contract_dependent_children: number | null;
        contract_handicapped_children: number | null;
        contract_vacation_days: number | null;
        requested_at: string;
    }> = {
        id: this.uuid(),
        uuid: this.uuid(),
    };

    get kidFullname() {
        return this.attributes.kid_first_name + ' ' + this.attributes.kid_last_name;
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            RegistrationOrganizationPivot,
            'registration_id',
            'organization_id'
        );
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', RegistrationOrganizationPivot, 'registration_id');
    }

    plannings() {
        return this.hasMany('plannings', RegistrationPlanning, 'registration_id');
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }

    scopeInRange(mainQuery: QueryBuilder<any>, dates: {from: string; to: string | undefined}) {
        mainQuery.where(query => {
            query
                .where(query2 => {
                    query2
                        .whereDate('contract_started_at', '<=', dates.from)
                        .whereDate('contract_ended_at', '>', dates.from);
                })
                .orWhere(q => {
                    q.whereDate('contract_started_at', '>=', dates.from);
                    if (dates.to) {
                        q.whereDate('contract_started_at', '<=', dates.to);
                    }
                });
        });
    }

    scopeOngoingRegistrationsByOrganizationIds(
        mainQuery: QueryBuilder<any>,
        options: {
            organizationIds: string | string[];
        }
    ) {
        return mainQuery
            .whereHas('organizationsPivots', query2 => {
                if (Array.isArray(options.organizationIds)) {
                    query2.whereIn('organization_id', options.organizationIds);
                } else {
                    query2.where('organization_id', options.organizationIds);
                }

                query2.whereNull('kid_id').where('rejected', false);
            })
            .scope('inRange', {from: new Epoch().toISOString()});
    }
}
