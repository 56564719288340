import {ValidationError} from '@meekohq/lumos';

import type {ApiResponseOutputPort} from '@/modules/core/domain/ports/ApiResponseOutputPort';
import type {ReportingSettingsNotifierPort} from '@/modules/meeko-family/domain/ports/ReportingSettingsNotifierPort';
import type {SendRealTimeDeactivationFeedbackInputPort} from '@/modules/meeko-family/domain/use-cases/SendRealTimeDeactivationFeedbackInputPort';

export class SendRealTimeDeactivationFeedbackInteractor implements SendRealTimeDeactivationFeedbackInputPort {
    constructor(
        private readonly notifier: ReportingSettingsNotifierPort,
        private readonly presenter: ApiResponseOutputPort
    ) {}

    async execute(feedback: string): Promise<unknown> {
        try {
            await this.notifier.sendRealTimeDeactivationFeedback(feedback);

            return this.presenter.success();
        } catch (error) {
            if (error instanceof ValidationError) {
                return this.presenter.validationError(error as ValidationError);
            }

            return this.presenter.unexpectedError(error);
        }
    }
}
