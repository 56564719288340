<template>
    <CModal
        ref="modal"
        :header-title="__('hr_balance:add_balance')"
        @shown="onShown"
    >
        <BalanceTypeForm
            v-if="model"
            ref="form"
            :error-form="balanceTypeFormError"
            :model="model"
            @check-existing="checkExisting = $event"
            @loading="loading = $event"
            @saved="onCreated"
        >
            <template #importItemAlert="{value}">
                <ImportItem
                    field-to-compare="name"
                    :model="balanceTypeModelConstructor"
                    :model-value="value"
                    @selected="importItem($event)"
                />
            </template>
        </BalanceTypeForm>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                :label="__('common:actions.close')"
                @click="hide()"
            />
            <MButton
                class="tw-ml-2"
                :disabled="hasErrors || checkExisting"
                :label="__('common:actions.save')"
                :loading="loading || checkExisting"
                variant="primary"
                @click="$refs.form.save()"
            />
        </template>
    </CModal>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, unref} from 'vue';

    import ImportItem from '@/modules/app/components/ImportItem.vue';
    import useError from '@/modules/app/composables/useError';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import BalanceTypeForm from '@/modules/human-resources/components/balance-type/BalanceTypeForm.vue';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import BalanceTypeOrganizationPivot from '@/modules/human-resources/models/BalanceTypeOrganizationPivot';
    import EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {ImportItem, BalanceTypeForm},
        props: {},
        emits: ['created'],
        setup(props, {emit}) {
            const loading = ref(false);
            const checkExisting = ref(false);
            const {modal, show, hide} = useLegacyModalBus();
            const {activeOrganization} = useManager();

            const balanceTypeFormError = useError();
            const hasErrors = computed(() => {
                return balanceTypeFormError.hasErrors.value;
            });

            const model = ref<BalanceTypeModel>();
            const onShown = function () {
                // Init new model
                model.value = new BalanceTypeModel();
                model.value.attributes.account_id = activeOrganization.value.attributes.account_id;
            };

            const onCreated = function (model) {
                useNotification().success(__('hr_balance:balance_type_created_successfully'));
                hide();
                emit('created', model);
            };

            const importItem = async function (model: BalanceTypeModel) {
                loading.value = true;

                // Import Balance
                const pivot = new BalanceTypeOrganizationPivot();
                pivot.attributes.account_id = model.attributes.account_id;
                pivot.balanceType().associate(model, false);
                pivot.organization().associate(activeOrganization.value, false);
                await pivot.save();
                model.organizationsPivots().value().push(pivot);

                // Get event types who doesn't have relation with active organization
                const eventTypeList = await model
                    .eventTypes()
                    .setQuery(q => q.whereDoesntHave('organizations', q1 => q1.scope('active')))
                    .load();

                // Import event types
                for (const eventTypeModel of eventTypeList) {
                    const pivot = new EventTypeOrganizationModel();
                    pivot.attributes.account_id = eventTypeModel.attributes.account_id;
                    pivot.eventType().associate(eventTypeModel, false);
                    pivot.organization().associate(activeOrganization.value, false);
                    await pivot.save();
                }

                loading.value = false;
                onCreated(model);
            };

            const balanceTypeModelConstructor = BalanceTypeModel;

            return {
                balanceTypeFormError,
                checkExisting,
                hasErrors,
                unref,
                loading,
                onCreated,
                modal,
                hide,
                show,
                model,
                onShown,
                balanceTypeModelConstructor,
                importItem,
            };
        },
    });
</script>
