import {useWindowSize} from '@vueuse/core';
import type {ComputedRef} from 'vue';
import {computed} from 'vue';

interface ResponsiveObject<T> {
    'base'?: T;
    'sm'?: T;
    'md'?: T;
    'lg'?: T;
    'xl'?: T;
    '2xl'?: T;
}

const screens = {
    'sm': 640,
    'md': 768,
    'lg': 1024,
    'xl': 1280,
    '2xl': 1536,
};

const {width} = useWindowSize();

/**
 * Get responsive value
 * @param value
 * @example useResponsiveValue({lg: 'large', base: 'small'})
 */
export function useResponsiveValue<T>(value: ResponsiveObject<T>): ComputedRef<T | undefined> {
    return computed(() => {
        if (width.value >= screens['2xl'] && value['2xl'] !== undefined) {
            return value['2xl'];
        } else if (width.value >= screens.xl && value.xl !== undefined) {
            return value.xl;
        } else if (width.value >= screens.lg && value.lg !== undefined) {
            return value.lg;
        } else if (width.value >= screens.md && value.md !== undefined) {
            return value.md;
        } else if (width.value >= screens.sm && value.sm !== undefined) {
            return value.sm;
        } else {
            return value.base;
        }
    });
}
