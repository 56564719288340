<script setup lang="ts">
    import {datadogRum} from '@datadog/browser-rum';
    import _round from 'lodash-es/round';
    import {computed, onBeforeMount, onMounted, onUnmounted, ref} from 'vue';

    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import PaymentSuggestionsList from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsList.vue';
    import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';
    import TransactionModalContent from '@/modules/cashier/transaction/infrastructure/components/TransactionModalContent.vue';
    import TransactionModalFooter from '@/modules/cashier/transaction/infrastructure/components/TransactionModalFooter.vue';
    import type {TransactionStateReturnType} from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import useTransactionState, {
        makeNewTransactionState,
    } from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

    const props = defineProps<{
        modal: ModalType;
        invoice: LegacyInvoiceType;
        payment: PaymentModel;
        customer: CustomerModel;
    }>();

    const {format} = useFormatCurrency(props.invoice.currencyIsoCode);

    const selectedTab = ref('new');

    const tabs = computed(() => {
        if (props.invoice.invoice_type === InvoiceTypeValue.creditNote) {
            return {
                new: {
                    label: __('billing_transaction:new_debit'),
                },
                transactions: {
                    label: __('billing_transaction:available_debit_with_amount'),
                    meta: {
                        amount: format(props.customer.availableTransactionDebitAmount),
                    },
                },
            };
        } else {
            return {
                new: {
                    label: __('billing_transaction:new_credit'),
                },
                transactions: {
                    label: __('billing_transaction:available_credit_with_amount'),
                    meta: {
                        amount: format(props.customer.availableTransactionCreditAmount),
                    },
                },
                credits: {
                    label: __('billing_transaction:available_credit_note_with_amount'),
                    meta: {
                        amount: format(props.customer.availableCreditNoteAmount),
                    },
                },
            };
        }
    });

    const modalTitle = computed(() => {
        if (props.invoice.invoice_type === InvoiceTypeValue.creditNote) {
            return __('billing_transaction:actions.create_debit');
        } else {
            return __('billing_transaction:actions.create_credit');
        }
    });

    // Init new transaction state
    const isLoading = ref(false);
    const transactionState: TransactionStateReturnType = useTransactionState();
    const originalCustomerIds = ref();

    onBeforeMount(() => {
        datadogRum.addAction('open modal');
        datadogRum.addAction('OnBeforeMount de InvoicePaymentSuggestionModal', {
            invoiceCustomer: props.invoice.customer_id,
            paymentCustomer: props.payment.attributes.customer_id,
            customer: props.customer.getKey(),
        });

        originalCustomerIds.value = {
            invoiceCustomer: props.invoice.customer_id,
            paymentCustomer: props.payment.attributes.customer_id,
            customer: props.customer.getKey(),
        };
    });

    onMounted(async () => {
        isLoading.value = true;
        await makeNewTransactionState(transactionState, {
            tenant_id: props.invoice.tenant_id,
            customer_id: props.customer.getKey(),
            type:
                props.invoice.invoice_type === InvoiceTypeValue.creditNote
                    ? TransactionTypeValue.debit
                    : TransactionTypeValue.credit,
            amount: _round(props.payment.computed.remaining_amount, 2),
        });
        isLoading.value = false;
    });

    onUnmounted(() => {
        datadogRum.addAction('Data du clone', {
            invoiceCustomer: originalCustomerIds.value.invoiceCustomer,
            paymentCustomer: originalCustomerIds.value.paymentCustomer,
            customer: originalCustomerIds.value.customer,
        });

        datadogRum.addAction('close modal');
    });

    transactionState.bus.on('saved', () => {
        onSaved();
    });

    const emit = defineEmits<{
        (e: 'created'): void;
    }>();

    function onSaved() {
        emit('created');
        props.modal.hide();
    }
</script>

<template>
    <MModal
        :header-title="modalTitle"
        :loading="isLoading"
        :modal="modal"
        size="4xl"
    >
        <MTabs
            :tabs="tabs"
            @selected="selectedTab = $event"
        >
            <template #tab-content="{label, meta, tabKey}">
                <div class="tw-truncate">
                    {{ label }}
                </div>
                <MBadge
                    v-if="meta && meta.amount"
                    size="sm"
                    :variant="selectedTab === tabKey ? 'primary' : 'gray'"
                >
                    {{ meta.amount }}
                </MBadge>
            </template>
            <template #default="{activeTab}">
                <div class="tw-mt-4">
                    <div v-if="activeTab === 'new'">
                        <TransactionModalContent
                            :can-select-customer="false"
                            :customer="customer"
                            :invoice="invoice"
                            :payment="payment"
                            :transaction-state="transactionState"
                        />
                    </div>
                    <PaymentSuggestionsList
                        v-if="activeTab === 'transactions'"
                        :payment="payment"
                        suggestion-type="transaction"
                        @saved="onSaved"
                    />
                    <PaymentSuggestionsList
                        v-if="activeTab === 'credits'"
                        :payment="payment"
                        suggestion-type="credit_note"
                        @saved="onSaved"
                    />
                </div>
            </template>
        </MTabs>
        <template
            v-if="selectedTab === 'new'"
            #footer-end="{hide}"
        >
            <TransactionModalFooter
                :transaction-state="transactionState"
                @hide="hide"
            />
        </template>
    </MModal>
</template>
