import {Binding} from '@meekohq/lumos';

export interface NumericParserContractOptions {
    localeOptions: NumericParserContractLocaleOptions;
    fractionalOptions?: NumericParserContratFractionalOptions;
    allowNegative: boolean;
}

export interface NumericParserContractLocaleOptions {
    thousandsSeparator?: string;
    fractionalSeparator: string;
    fractionalSeparatorAdditional?: string;
}

export interface NumericParserContratFractionalOptions {
    mode: 'round' | 'truncate';
    minFractionalDigits: number;
    maxFractionalDigits: number;
}

export const NumericParserContractBinding = new Binding<NumericParserContract, NumericParserContractOptions>();

export interface NumericParserContract {
    /**
     * Parses the string and returns class instance to allow chaining with asNumber() | asString().
     * @param value
     */
    parse(value?: string): this;

    setValue(value: number): this;

    asNumber(): number;

    asString(): string | undefined;
}
