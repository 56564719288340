import {app, collect, lumosBindings, type Model, type ModelConstructorType, type QueryBuilder} from '@meekohq/lumos';
import _groupBy from 'lodash-es/groupBy';

import type {SerializerType} from '../../../app/composables/useMagicFilter';

interface SerializedModel {
    type: string;
    id: string;
}

export default {
    read: async (value: string) => {
        const models: SerializedModel[] = JSON.parse(value);

        const modelTypes = _groupBy(models, i => i.type);
        let newmodels: Model[] = [];

        for (const type in modelTypes) {
            const modelConstructor = getModelConstructorByType(type) as any | Model;
            const builder = modelConstructor.query() as unknown as QueryBuilder<any>;

            const ids = collect(modelTypes[type]).pluck('id').toArray() as string[];
            const results = await builder.whereIn(builder.model.getKeyName(), ids).get();

            newmodels = newmodels.concat(results.toArray());
        }

        return newmodels;
    },
    write: (models: Model[]) => {
        const array = models.map(model => ({
            type: model.type,
            id: model.getKey(),
        }));

        return JSON.stringify(array);
    },
} as SerializerType;

/**
 * Get a model constructor by model type.
 *
 * @param type
 */
function getModelConstructorByType(type: string): ModelConstructorType {
    const types: Record<string, ModelConstructorType> = {};

    const models = app(lumosBindings.ModelCollectionBinding);
    models.forEach(model => {
        types[(new model() as Model).getType()] = model;
    });

    return types[type];
}
