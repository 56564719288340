import {ServiceProvider} from '@meekohq/lumos';

import DocTemplateModel from '@/modules/organization/models/DocTemplateModel';
import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import OrganizationTenantPivot from '@/modules/organization/models/OrganizationTenantPivot';
import OrganizationUserPivot from '@/modules/organization/models/OrganizationUserPivot';
import LegacyNurseryOpening from '@/modules/organization/utils/LegacyNurseryOpening';
import {OrganizationOpeningContractBinding} from '@/modules/organization/utils/OrganizationOpeningContract';

export default class OrganizationServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            KidsGroupModel,
            OrganizationModel,
            OrganizationTenantPivot,
            DocTemplateModel,
            OrganizationUserPivot,
        ]);
    }

    public register() {
        this.app.bind(OrganizationOpeningContractBinding, () => new LegacyNurseryOpening());
    }
}
