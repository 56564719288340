<script setup lang="ts">
    import {Epoch, EpochDuration} from '@meekohq/lumos';
    import {computed} from 'vue';

    const props = defineProps<{
        nursery: {
            openingHours: {day: string}[];
            morning_end_at: string | null;
            afternoon_start_at: string | null;
        };
        plannings: {
            accountid: string;
            day: string;
            end_time: string;
            id: string;
            note: string | null;
            start_time: string;
            type: string;
            week: number;
        }[];
        weeks: number;
    }>();

    const filteredPlannings = computed(() => {
        return props.plannings.filter(item => item.type === 'recurrent');
    });

    function getWeekHours(week: number): string {
        let minutes = 0;

        filteredPlannings.value.forEach(planning => {
            if (planning.week === week) {
                const end = Epoch.parse(planning.end_time, 'HH:mm:ss');
                const start = Epoch.parse(planning.start_time, 'HH:mm:ss');

                minutes += start.difference(end, 'minutes').as('minute');
            }
        });

        return EpochDuration.fromMinutes(minutes).shiftTo('hours', 'minutes').toFormat('hhHmm');
    }

    function getPresences(week: number): {name: string; class: string | undefined}[] {
        const presences = [
            {
                name: 'Lun',
                class: getDayPresence(week, 'mon'),
            },
            {
                name: 'Mar',
                class: getDayPresence(week, 'tue'),
            },
            {
                name: 'Mer',
                class: getDayPresence(week, 'wed'),
            },
            {
                name: 'Jeu',
                class: getDayPresence(week, 'thu'),
            },
            {
                name: 'Ven',
                class: getDayPresence(week, 'fri'),
            },
        ];

        if (props.nursery.openingHours.some(item => item.day === 'sat')) {
            presences.push({
                name: 'Sam',
                class: getDayPresence(week, 'sat'),
            });
        }

        if (props.nursery.openingHours.some(item => item.day === 'sun')) {
            presences.push({
                name: 'Dim',
                class: getDayPresence(week, 'sun'),
            });
        }

        return presences;
    }

    function getDayPresence(week: number, day: string): string | undefined {
        const plannings = filteredPlannings.value.filter(item => item.week === week && item.day === day);

        let am = false;
        let pm = false;
        plannings.forEach(planning => {
            if (planning.start_time === '00:00:00' && planning.end_time === '00:00:00') {
                return;
            }

            const start = Epoch.parse(planning.start_time, 'HH:mm:ss');
            const end = Epoch.parse(planning.end_time, 'HH:mm:ss');

            const morningEndAt = props.nursery.morning_end_at
                ? Epoch.parse(props.nursery.morning_end_at, 'HH:mm:ss')
                : Epoch.parse('12:00:00', 'HH:mm:ss');

            const afternoonStartAt = props.nursery.afternoon_start_at
                ? Epoch.parse(props.nursery.afternoon_start_at, 'HH:mm:ss')
                : Epoch.parse('12:00:00', 'HH:mm:ss');

            if (start.lessThan(afternoonStartAt) && end.greaterThan(morningEndAt)) {
                am = true;
                pm = true;
            } else if (start.lessThanOrEqualTo(morningEndAt) && end.lessThanOrEqualTo(morningEndAt)) {
                am = true;
            } else if (start.greaterThanOrEqualTo(afternoonStartAt) && end.greaterThanOrEqualTo(afternoonStartAt)) {
                pm = true;
            } else {
                return;
            }
        });

        if (am && pm) {
            return 'full';
        } else if (am && !pm) {
            return 'half-left';
        } else if (!am && pm) {
            return 'half-right';
        } else {
            return undefined;
        }
    }
</script>

<template>
    <div
        v-if="weeks > 1"
        class="tw-text-base tw-text-gray-600"
    >
        <div
            v-for="(week, w) in weeks"
            :key="'week' + w"
            :class="{'tw-mt-2': w > 0}"
        >
            <div class="tw-mb-1">
                {{ __('common:week') }} {{ week }} -
                <strong>{{ getWeekHours(week) }}</strong>
            </div>
            <div
                v-for="(day, d) in getPresences(week)"
                :key="d"
                class="tw-mx-2 tw-inline-block"
            >
                <div
                    class="presence-indicator tw-rounded-full"
                    :class="day.class"
                />
                <div class="day-name">
                    {{ day.name }}
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="tw-text-base tw-text-gray-600"
    >
        <div class="tw-mb-1">
            {{ __('common:standard_week') }} -
            <strong>{{ getWeekHours(1) }}</strong>
        </div>
        <div>
            <div
                v-for="(day, i) in getPresences(1)"
                :key="i"
                class="tw-mx-2 tw-inline-block"
            >
                <div
                    class="presence-indicator tw-rounded-full"
                    :class="day.class"
                />
                <div class="day-name">
                    {{ day.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .presence-indicator {
        background: #d8d8d8;
        width: 25px;
        height: 25px;

        &.full {
            @apply tw-bg-blue-500;
        }

        &.half-left {
            background: linear-gradient(90deg, theme(colors.blue.500) 50%, #d8d8d8 50%);
        }

        &.half-right {
            background: linear-gradient(-90deg, theme(colors.blue.500) 50%, #d8d8d8 50%);
        }
    }

    .day-name {
        font-size: 13px;
    }
</style>
