<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';
    import usePrint from '@/modules/app/composables/usePrint';
    import StaffPlanningSubscriberModal from '@/modules/human-resources/components/calendar/StaffPlanningSubscriberModal.vue';

    withDefaults(
        defineProps<{
            nursery: object;
            currentDisplay: string;
        }>(),
        {
            currentDisplay: '',
        }
    );

    defineEmits(['manageTemplates', 'useTemplates']);

    function printPage() {
        usePrint().print();
    }

    const {can} = useAbility();
</script>

<template>
    <div class="tw-contents">
        <MMenu
            v-if="can('create', 'staffs_planning') && can('update', 'staffs_planning')"
            placement="bottom-end"
        >
            <MMenuButton>
                <MButton>
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-duotone fa-calendar-alt" />
                    </template>
                    {{ __('planning:manage_planning') }}
                </MButton>
                <MMenuItems>
                    <MMenuItem
                        :label="__('planning:manage_typical_week')"
                        @click="$emit('manageTemplates')"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-duotone fa-calendar-day"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('planning:apply_typical_week')"
                        @click="$emit('useTemplates')"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-duotone fa-copy"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenuButton>
        </MMenu>
        <MTooltip
            :label="__('planning:print_or_share')"
            placement="top-end"
        >
            <MMenu placement="bottom-end">
                <MMenuButton>
                    <MButton variant="gray">
                        <FontAwesomeIcon icon="fa-solid fa-external-link" />
                    </MButton>
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :label="__('planning:print_planning')"
                        @click="printPage"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-print"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('planning:planning_subscription')"
                        @click="$refs.modal.show()"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-calendar-alt"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </MTooltip>
        <MSettingsButton
            :to="{name: 'settings.plannings', params: {nursery: nursery.id}}"
            :tooltip="__('planning:planning_settings')"
        />
        <StaffPlanningSubscriberModal ref="modal" />
    </div>
</template>
