<template>
    <div>
        <div
            v-for="(week, i) in registration.contract_recurrent_weeks"
            :key="'week' + i"
            class="tw-mb-6"
        >
            <h5
                v-if="registration.contract_recurrent_weeks > 1"
                class="h5 tw-mb-0 tw-mt-4"
            >
                {{ __('planning:reference_week_number', {week: week}) }}
                <span class="small tw-text-gray-400">
                    -
                    {{ __('common:starting_from') }}
                    {{
                        parse(registration.contract_started_at, 'yyyy-MM-dd')
                            .addWeeks(i)
                            .startOfWeek()
                            .toLocaleString(Epoch.presets.DATE_SHORT)
                    }}
                </span>
            </h5>
            <hr class="hr" />
            <div class="tw-mb-2 tw-table tw-w-full">
                <div
                    class="tw-hidden sm:tw-block"
                    style="padding-left: 85px; padding-right: 65px"
                >
                    <hours
                        :closing="closing"
                        :opening="opening"
                    />
                </div>
            </div>
            <div
                v-for="(day, key) in epochDaysBetweenDates(
                    weekStart.addWeeks(week).toISOString(),
                    weekEnd.addWeeks(week).toISOString()
                )"
                :key="'day' + key"
                class="tw-mb-2 tw-table tw-w-full"
            >
                <div
                    class="day tw-float-none tw-mb-1 tw-font-display tw-capitalize sm:tw-float-left sm:tw-mb-0 sm:tw-pr-6 sm:tw-text-right"
                >
                    {{ day.weekdayLong }}
                </div>
                <div class="planning tw-float-none sm:tw-float-left sm:tw-pr-4">
                    <planning-generator
                        :closing="closing"
                        :day="day"
                        :events="events"
                        inner-text
                        :nursery="nursery"
                        :opening="opening"
                        :show-clock="false"
                        :show-menu="$can('update', 'registrations')"
                        :user="user"
                    />
                </div>
                <div
                    v-if="!disabled && $can('update', 'registrations')"
                    class="add tw-float-none sm:tw-float-right sm:tw-pr-4"
                >
                    <MButton
                        variant="primary"
                        @click="addPlage(week, day)"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                    </MButton>
                </div>
            </div>
        </div>
        <div
            v-if="!disabled && $can('update', 'registrations')"
            class="tw-text-center"
        >
            <MButton
                :label="__('common:add_week')"
                icon-plus
                variant="primary"
                @click="registration.contract_recurrent_weeks++"
            />
            <MButton
                v-if="registration && registration.contract_recurrent_weeks > 1"
                icon-trash
                class="tw-ml-2"
                :label="__('common:remove_week')"
                variant="danger"
                @click="removeWeek"
            />
        </div>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _concat from 'lodash-es/concat';
    import _filter from 'lodash-es/filter';
    import _forEach from 'lodash-es/forEach';

    import {EventBus} from '@/eventBus';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useModal from '@/modules/app/composables/useModal';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import {getPlanningEventsFromKidContractOrRegistration} from '@/modules/legacy/libs/Planning';
    import {epochDaysBetweenDates} from '@/modules/legacy/libs/planning/planning';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import EditPlanning from '@/modules/planning/components/Modal/EditPlanning.vue';
    import NewPlanning from '@/modules/planning/components/Modal/NewPlanning.vue';

    export default {
        components: {
            hours: Hours,
        },
        mixins: [nursery],
        props: ['nursery', 'registration', 'disabled', 'user'],
        data: () => ({
            Epoch,
            planningOnEdition: {},
            addOnWeek: 1,
            addOnDay: '',
            events: [],
            epochDaysBetweenDates,
        }),
        computed: {
            newModal() {
                return useModal();
            },
            editModal() {
                return useModal();
            },
            opening() {
                return this.$getOpening(this.nursery.openingHours, false, false, this.events);
            },
            closing() {
                return this.$getClosing(this.nursery.openingHours, false, false, this.events);
            },
            exactOpening() {
                return this.$getOpening(this.nursery.openingHours, true, true, this.events);
            },
            exactClosing() {
                return this.$getClosing(this.nursery.openingHours, true, true, this.events);
            },
            weekStart() {
                return Epoch.now().startOfWeek().subWeeks(1);
            },
            weekEnd() {
                return this.weekStart.endOfWeek();
            },
        },
        watch: {
            'registration.plannings': {
                handler() {
                    this.computeEvents();
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {
            EventBus.$on('clicked:event', this.handleClickedEvent);
            this.computeEvents();
        },
        beforeUnmount() {
            EventBus.$off('clicked:event', this.handleClickedEvent);
        },
        methods: {
            handleClickedEvent(segment) {
                if (this.disabled) {
                    return;
                }

                this.planningOnEdition = _filter(this.registration.plannings, planning => {
                    return planning.id === segment.id;
                })[0];

                const editPlanningModal = useModal({
                    component: EditPlanning,
                    props: {
                        planning: this.planningOnEdition,
                        planningModel: this.registration,
                    },
                });
                editPlanningModal.show();
            },
            parse: useEpoch().parse,
            computeEvents() {
                let events = [];
                if (this.registration) {
                    // Create a copy of the registration to simulate the started date of the contract.
                    // Because getPlanningEventsFromKidContractOrRegistration needed it to compute the events.
                    // This function calculate week from the contract start date.
                    const registrationForEvents = _cloneDeep(this.registration);
                    registrationForEvents.contract_started_at = Epoch.now().toFormat('yyyy-MM-dd');

                    for (let week = 1; week <= registrationForEvents.contract_recurrent_weeks; week++) {
                        _forEach(
                            epochDaysBetweenDates(
                                this.weekStart.addWeeks(week).toISOString(),
                                this.weekEnd.addWeeks(week).toISOString()
                            ),
                            day => {
                                events = _concat(
                                    events,
                                    getPlanningEventsFromKidContractOrRegistration(
                                        day.toISOString(),
                                        null,
                                        registrationForEvents,
                                        {
                                            onlyHours: true,
                                        }
                                    )
                                );
                            }
                        );
                    }
                }

                this.events = events;
            },
            removeWeek() {
                this.registration.contract_recurrent_weeks--; // Ne pas inverser les deux lignes, car le watcher sur plannings recompute les events.
                this.registration.plannings = this.registration.plannings.filter(
                    planning => planning.week <= this.registration.contract_recurrent_weeks
                );
            },
            addPlage(week, day) {
                this.addOnWeek = week;
                this.addOnDay = day.weekday;

                // We use the last event to copy its start and end time, otherwise we use the organization opening and
                // closing hours.

                const opening = this.events?.length
                    ? this.events[this.events.length - 1].start_time
                    : this.exactOpening;
                const closing = this.events?.length ? this.events[this.events.length - 1].end_time : this.exactClosing;

                const newPlanningModal = useModal({
                    component: NewPlanning,
                    props: {
                        closing,
                        planningModel: this.registration,
                        day: this.addOnDay,
                        opening,
                        type: 'recurrent',
                        week: this.addOnWeek,
                    },
                });
                newPlanningModal.show();
            },
        },
    };
</script>

<style scoped>
    @media screen(sm) {
        .day {
            width: 100px;
        }

        .planning {
            width: calc(100% - 100px - 35px);
        }

        .add {
            width: 35px;
        }
    }
</style>
