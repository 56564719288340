<template>
    <div>
        <p
            class="tw-mb-0 tw-text-base tw-text-gray-600"
            v-html="
                __('notification:invoice_with_name_unpaid', {
                    name: `${notification.data.no} (${notification.data.kid_first_name} ${notification.data.kid_last_name})`,
                })
            "
        />
        <p
            v-show="notification.data.amount"
            class="tw-mb-0 tw-mt-1 tw-text-sm tw-text-gray-800"
        >
            {{ __('notification:amount_of_invoice_colon') }}
            <span class="tw-font-semibold tw-text-blue-500">{{ notification.data.amount }}</span>
        </p>

        <div
            v-if="notification.data.nursery_id"
            class="tw-mt-2"
        >
            <RouterLink
                :to="{
                    name: 'kids.show',
                    params: {nursery: notification.data.nursery_id, kid: notification.data.kid_id},
                }"
            >
                <MButton @click="handleEmit">
                    {{ __('common:show_kid_page') }}
                </MButton>
            </RouterLink>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    import {EventBus} from '@/eventBus';

    export default {
        props: {
            notification: {type: Object},
        },
        emits: ['closeModal'],
        computed: {
            moment() {
                return moment;
            },
        },
        mounted() {
            //
        },
        methods: {
            handleEmit() {
                this.$emit('closeModal');

                if (!this.notification.read_at) {
                    this.notification.read_at = moment().unix();
                }

                EventBus.$emit('update:notification', this.notification);
            },
        },
    };
</script>
