import {cloneDeep, head, last} from 'lodash-es';
import type {Moment} from 'moment';
import moment from 'moment';
import type {Ref} from 'vue';
import {computed, ref} from 'vue';

interface DatePickerParams {
    time: boolean;
    hoursStep: number;
    minutesStep: number;
}

/**
 * Provide the date picker elements
 *
 * @param {Ref<Moment>} date
 * @param {DatePickerParams} params
 */
export default function (date: Ref<Moment>, params: DatePickerParams) {
    const dropdown = ref(null);

    const view = ref('month');
    const weeksPerMonth = ref(6);

    /**
     * Get days of month.
     */
    const daysInMonths = computed(() => {
        const collection = [] as any[];

        const firstDayOfMonth = cloneDeep(date.value).startOf('month');
        const firstDayOfFirstWeek = cloneDeep(firstDayOfMonth).startOf('week');

        // Set time of first day of month and first day of first week with time of date
        if (params.time) {
            firstDayOfMonth.hour(date.value.hour()).minute(date.value.minute());
            firstDayOfFirstWeek.hour(date.value.hour()).minute(date.value.minute());
        }

        // Add before days of month to fill first week
        const beforeDayIterator = cloneDeep(firstDayOfFirstWeek);
        while (!beforeDayIterator.isSame(date.value, 'month')) {
            collection.push(cloneDeep(beforeDayIterator));
            beforeDayIterator.add(1, 'days');
        }

        // Add days of month
        const dayIterator = cloneDeep(firstDayOfMonth);
        while (dayIterator.isSame(firstDayOfMonth, 'month')) {
            collection.push(cloneDeep(dayIterator));
            dayIterator.add(1, 'days');
        }

        // Add after days of month to fill last week
        const afterDayIterator = last(cloneDeep(collection));
        while (collection.length < weeksPerMonth.value * 7) {
            afterDayIterator.add(1, 'days');
            collection.push(cloneDeep(afterDayIterator));
        }

        return collection;
    });

    const monthsInYear = moment.months();

    /**
     * Get weeks numbers of month.
     */
    const weekNumbers = computed(() => {
        const collection = [] as number[];

        const weekIterator = head(cloneDeep(daysInMonths.value));
        while (weekIterator.week() !== last(daysInMonths.value).week()) {
            collection.push(weekIterator.week());
            weekIterator.add(1, 'weeks');
        }

        collection.push(weekIterator.week());
        weekIterator.add(1, 'weeks');

        return collection;
    });

    const currentMonthName = computed(() => date.value.format('MMMM'));

    const countDayInMonth = computed(() => date.value.daysInMonth());

    const weekDays = computed(() => {
        return moment.weekdaysMin(true);
    });

    /**
     * Get hours of day.
     */
    const hours = computed(() => {
        const collection = [] as Moment[];

        const hourIterator = cloneDeep(date.value).startOf('day');
        while (hourIterator.isSame(date.value, 'day')) {
            collection.push(cloneDeep(hourIterator));
            hourIterator.add(params.hoursStep, 'hours');
        }

        return collection;
    });

    /**
     * Get minutes of hour.
     */
    const minutes = computed(() => {
        const collection = [] as Moment[];

        const minuteIterator = cloneDeep(date.value).startOf('hour');
        while (minuteIterator.isSame(date.value, 'hour')) {
            collection.push(cloneDeep(minuteIterator));
            minuteIterator.add(params.minutesStep, 'minutes');
        }

        return collection;
    });

    /**
     * Go to previous month or year.
     */
    function previous() {
        date.value = cloneDeep(date.value).subtract(1, view.value === 'year' ? 'years' : 'months');
    }

    /**
     * Go to next month or year.
     */
    function next() {
        date.value = cloneDeep(date.value).add(1, view.value === 'year' ? 'years' : 'months');
    }

    return {
        dropdown,
        view,
        weeksPerMonth,
        daysInMonths,
        weekNumbers,
        monthsInYear,
        currentMonthName,
        countDayInMonth,
        weekDays,
        hours,
        minutes,
        previous,
        next,
    };
}
