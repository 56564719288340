<script setup lang="ts"></script>

<template>
    <div class="CopyKidList">
        <MHeading level="h4">
            {{ __('family_kid:only_the_following_data_will_be_copied_to_the_new_organization_colon') }}
        </MHeading>
        <div class="CopyKidList__grid">
            <div class="CopyKidList__container">
                <MHeading level="h4">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-child-reaching"
                    />
                    {{ __('family_kid:kid_sheet') }}
                </MHeading>
                <ul class="CopyKidList__list">
                    <li>{{ __('family_kid:fields_filled') }}</li>
                    <li>{{ __('family_kid:the_profile_photo') }}</li>
                    <li>{{ __('family_kid:health_data') }}</li>
                    <li>{{ __('family_kid:feed_data') }}</li>
                    <li>{{ __('family_kid:the_documents') }}</li>
                </ul>
            </div>
            <div class="CopyKidList__container">
                <MHeading level="h4">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-user-tie"
                    />
                    {{ __('family_kid:parents_sheet') }}
                </MHeading>
                <ul class="CopyKidList__list">
                    <li>{{ __('family_kid:fields_filled') }}</li>
                    <li>{{ __('family_kid:the_profile_photo') }}</li>
                    <li>{{ __('family_kid:relationships_with_child') }}</li>
                    <li>{{ __('family_kid:meeko_family_access') }}</li>
                    <li>{{ __('family_kid:the_documents') }}</li>
                </ul>
            </div>
            <div class="CopyKidList__container">
                <MHeading level="h4">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-handshake"
                    />
                    {{ __('family_kid:the_contracts') }}
                </MHeading>
                <ul class="CopyKidList__list">
                    <li>{{ __('family_kid:fields_filled') }}</li>
                    <li>{{ __('family_kid:recurrent_schedules') }}</li>
                </ul>
            </div>
            <div class="CopyKidList__container">
                <MHeading level="h4">
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-address-book"
                    />
                    {{ __('family_kid:family_sheet') }}
                </MHeading>
                <ul class="CopyKidList__list">
                    <li>{{ __('family_kid:family_name') }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .CopyKidList {
        @apply tw-flex tw-flex-col tw-gap-4;
        @apply tw-rounded-lg tw-bg-gray-50 tw-p-4;
    }

    .CopyKidList__grid {
        @apply tw-grid tw-grid-cols-1 tw-gap-8 sm:tw-grid-cols-2;
    }

    .CopyKidList__container {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .CopyKidList__list {
        @apply tw-flex tw-list-inside tw-list-disc tw-flex-col tw-gap-1;
    }
</style>
