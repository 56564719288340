import type {Collection, Epoch, MqlUnprocessableEntityError} from '@meekohq/lumos';
import {app} from '@meekohq/lumos';
import mitt from 'mitt';
import {ref} from 'vue';

import {RejectAndRestoreTransactionsUseCaseBinding} from '@/modules/cashier/transaction/application/use-cases/RejectAndRestoreTransactionsUseCase';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

export interface TransactionRejectEmitter {
    error: Error | MqlUnprocessableEntityError;
    restored: void;
    rejected: void;
}

/**
 * useTransactionReject composable.
 */
export default function useRejectRestoreTransaction() {
    const loading = ref(false);
    const bus = mitt<TransactionRejectEmitter>();

    /**
     * Reject transactions.
     * @return {Promise<void>}
     */
    async function reject(transactions: Collection<TransactionModel>, failureDate: Epoch): Promise<void> {
        loading.value = true;

        try {
            await app(RejectAndRestoreTransactionsUseCaseBinding).rejectTransactionsAtDate(transactions, failureDate);

            bus.emit('rejected');
        } catch (error: any) {
            bus.emit('error', error);
        } finally {
            loading.value = false;
        }
    }

    /**
     * Restore transactions.
     * @return {Promise<void>}
     */
    async function restore(transactions: Collection<TransactionModel>): Promise<void> {
        loading.value = true;

        try {
            await app(RejectAndRestoreTransactionsUseCaseBinding).restoreTransactions(transactions);

            bus.emit('restored');
        } catch (error: any) {
            bus.emit('error', error);
        } finally {
            loading.value = false;
        }
    }

    return {
        bus,
        loading,
        reject,
        restore,
    };
}
