<template>
    <CCenter class="tw-relative tw-h-9 tw-w-9 tw-rounded-full tw-bg-yellow-300">
        <font-awesome-icon
            class="tw-text-yellow-700"
            icon="fa-solid fa-memo-pad"
        />
        <div class="tw-absolute tw--right-1 tw--top-1 tw-flex tw-text-yellow-600">
            <font-awesome-icon icon="fa-solid fa-circle-plus" />
        </div>
    </CCenter>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>

<style scoped></style>
