import moment from 'moment/moment';
import {computed, onMounted} from 'vue';

import type MeetingModel from '@/modules/activity/models/MeetingModel';
import MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
import MeetingStateValue from '@/modules/activity/utils/MeetingStateValue';
import useAuth from '@/modules/app/composables/useAuth';
import useManager from '@/modules/app/composables/useManager';
import __ from '@/modules/app/utils/i18n-facade';
import StaffModel from '@/modules/human-resources/models/StaffModel';

export default function (meeting: MeetingModel) {
    const {activeOrganization} = useManager();
    const {user} = useAuth();

    const suggestedLocations = computed(() => {
        const locations: {text: string; value: string}[] = [];

        if (activeOrganization.value.addressLine) {
            locations.push({
                text: __('common:organization_address'),
                value: activeOrganization.value.addressLine,
            });
        }

        return {
            icon: 'fa-solid fa-address-book',
            options: locations,
        };
    });

    onMounted(() => {
        if (!meeting.exists) {
            meeting.attributes.state = MeetingStateValue.scheduled;

            const nextHalfTime = moment().add(30 - (moment().minute() % 30), 'm');
            meeting.attributes.datetime_period.started_at = nextHalfTime.format();
            meeting.attributes.datetime_period.ended_at = nextHalfTime.add(1, 'hour').format();

            if (user.value.attributes.email) {
                associateStaff(user.value.attributes.email);
            }
        }
    });

    async function associateStaff(email: string) {
        const staff = await StaffModel.query().where('email', email).first();

        if (staff) {
            const participant = new MeetingParticipantModel();
            participant.meeting().associate(meeting);
            participant.attributes.account_id = staff.attributes.account_id;
            participant.attributes.first_name = staff.attributes.first_name ?? null;
            participant.attributes.last_name = staff.attributes.last_name ?? null;
            participant.attributes.email = staff.attributes.email ?? null;
            participant.resource().associate(staff);
            meeting.participants().value().push(participant);
        }
    }

    return {
        suggestedLocations,
    };
}
