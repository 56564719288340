import {datadogRum} from '@datadog/browser-rum';
import {app} from '@meekohq/lumos';
import {ref} from 'vue';

import __ from '@/modules/app/utils/i18n-facade';
import type {SaveAllocationResultFailed} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
import {SaveAllocationAggregateUseCaseBinding} from '@/modules/cashier/payment/application/SaveAllocationAggregateUseCase';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import useNotification from '@/modules/meeko-ui/composables/useNotification';

export default function useSaveAllocations() {
    const isSaving = ref(false);
    const errors = ref<SaveAllocationResultFailed[]>();

    async function save(allocationAggregate: AbstractAllocationAggregate) {
        if (!allocationAggregate) {
            throw new Error('allocationAggregates is not set.');
        }

        isSaving.value = true;

        try {
            const result = await app(SaveAllocationAggregateUseCaseBinding).save(allocationAggregate.copy());

            if (result.hasError) {
                errors.value = result.errors;
                isSaving.value = false;

                allocationAggregate.allocationAmount = 0;
                useNotification().error(__('common:errors.generic'));
                datadogRum.addAction('customer error');
                datadogRum.addError('customer error');

                return false;
            }

            isSaving.value = false;

            return true;
        } catch (e) {
            isSaving.value = false;
            useNotification().error(__('common:errors.generic'));

            throw e;
        }
    }

    async function saveAll(allocationAggregates: AbstractAllocationAggregate[]) {
        if (!allocationAggregates) {
            throw new Error('allocationAggregates is not set.');
        }

        isSaving.value = true;
        // Clone the object to avoid modifying the original object (saving order is changed in the repository)
        const allocationAggregatesClone = allocationAggregates.map((allocationAggregate: AbstractAllocationAggregate) =>
            allocationAggregate.copy()
        );

        try {
            const result = await app(SaveAllocationAggregateUseCaseBinding).saveAll(allocationAggregatesClone);

            if (result.hasError) {
                errors.value = result.errors;
                isSaving.value = false;

                return false;
            }

            isSaving.value = false;

            return true;
        } catch (e) {
            isSaving.value = false;
            useNotification().error(__('common:errors.generic'));

            throw e;
        }
    }

    return {
        errors,
        isSaving,
        save,
        saveAll,
    };
}
