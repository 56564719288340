<template>
    <MForm>
        <CFormTwoColumns v-if="allFamilyMembers.length">
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-users" />
                    </template>
                    {{ __('common:parent') }}
                </MLabel>
                <MemberFinder
                    button-class="tw-w-full"
                    class="tw-block"
                    :has-unselect="true"
                    :inject-query="memberFinderQueryBuilder"
                    :model-value="selectedFamilyMember"
                    @update:model-value="$emit('selectExistingMember', $event)"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-handshake" />
                    </template>
                    {{ __('family_member:kinship') }}
                </MLabel>
                <MSelectMenu
                    v-model="newFamilyMember.role"
                    :options="relationshipOptions"
                    text-path="text"
                    value-path="value"
                />
            </MFieldset>
        </CFormTwoColumns>

        <CFormTwoColumns>
            <MFieldset>
                <MemberPermissionsInput v-model="newFamilyMember.permissions" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-hands-helping" />
                    </template>
                    {{ __('family_member:pick_up_child') }}
                </MLabel>
                <MSelectMenu
                    v-model="newFamilyMember.can_pickup_kid"
                    :options="[
                        {value: 1, text: __('common:yes')},
                        {value: 0, text: __('common:no')},
                    ]"
                    text-path="text"
                    value-path="value"
                />
            </MFieldset>
        </CFormTwoColumns>
    </MForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, toRef} from 'vue';

    import MemberFinder from '@/modules/family/components/member/MemberFinder.vue';
    import MemberPermissionsInput from '@/modules/family/components/member/MemberPermissionsInput.vue';
    import {useMemberRoleTranslations} from '@/modules/family/composables/member/useMemberRoleTranslations';
    import MemberModel from '@/modules/family/models/MemberModel';

    export default defineComponent({
        components: {MemberFinder, MemberPermissionsInput},
        props: {
            newFamilyMember: {
                type: Object,
                required: true,
            },
            allFamilyMembers: {
                type: Array,
                required: true,
            },
            selectedFamilyMember: {
                type: Object as PropType<MemberModel>,
                default: null,
            },
            kidFamilyMemberIds: {
                type: Array as PropType<string[]>,
                required: true,
            },
        },
        emits: ['selectExistingMember'],
        setup(props) {
            const gender = toRef(props.newFamilyMember, 'gender');

            const memberFinderQueryBuilder = computed(() => {
                return MemberModel.query()
                    .whereHas(new MemberModel().organizations(), query => query.scope('active'))
                    .whereNotIn('id', props.kidFamilyMemberIds);
            });

            const {getRoleOptionsByGender} = useMemberRoleTranslations();

            const relationshipOptions = computed(() => {
                return getRoleOptionsByGender(gender.value);
            });

            return {
                relationshipOptions,
                memberFinderQueryBuilder,
            };
        },
    });
</script>
