import {Binding} from '@meekohq/lumos';

import type {SaveAllocationAggregateResponseModel} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';

export const SaveAllocationAggregateUseCaseBinding = new Binding<SaveAllocationAggregateUseCase>();

export interface SaveAllocationAggregateUseCase {
    saveAll(allocationAggregates: AbstractAllocationAggregate[]): Promise<SaveAllocationAggregateResponseModel>;

    save(allocationAggregate: AbstractAllocationAggregate): Promise<SaveAllocationAggregateResponseModel>;
}
