import type {Collection, CustomObject} from '@meekohq/lumos';
import {collect, Epoch, MqlOperation} from '@meekohq/lumos';
import type {Ref} from 'vue';
import {computed, ref} from 'vue';

import __ from '@/modules/app/utils/i18n-facade';
import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import type {PeriodFilterType} from '@/modules/legacy/components/Filter/PeriodFilterType';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import type DocTemplateModel from '@/modules/organization/models/DocTemplateModel';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

export interface SendTaxCertificatesParameters {
    period: Ref<PeriodFilterType>;
    docTemplate: Ref<DocTemplateModel>;
    selectedCustomers: Ref<Collection<CustomerModel>>;
    organization: Ref<OrganizationModel>;
    email: Ref<string>;
}

/**
 * Composable to send tax certificates
 *
 * @param customers
 * @param parameters
 */
export default function (customers: Ref<Collection<CustomerModel>>, parameters: SendTaxCertificatesParameters) {
    const {error: toastError, success: toastSuccess} = useNotification();
    const isSending = ref(false);

    // Email options
    const isSentToSingleEmail = ref(false);

    const periodErrors = computed(() => {
        if (
            Epoch.fromISOString(parameters.period.value.from as string).greaterThan(
                Epoch.fromISOString(parameters.period.value.to as string)
            )
        ) {
            return collect(__('common:errors.0x2EDA212753'));
        }

        return collect();
    });

    /**
     * Format doc params of all selected kids
     */
    const docs = computed(() => {
        return parameters.selectedCustomers.value
            .filter(
                customer =>
                    customer.extra.selected && (!isSentToSingleEmail.value ? customer.extra.sentTo.length > 0 : true)
            )
            .flatMap(customerModel => {
                const selectedKidsOfCustomer = getSelectedKidsOfCustomer(customerModel.getKey());
                const kidDocs = selectedKidsOfCustomer.map(kid => generateDocForKid(kid, customerModel));

                // If no kids, format doc params without kid for company customer
                if (kidDocs.isEmpty()) {
                    return generateDocForCustomer(customerModel);
                } else {
                    return kidDocs.all();
                }
            })
            .all();
    });

    /**
     * Get all selected kids of customer
     *
     * @param customerId
     */
    function getSelectedKidsOfCustomer(customerId: string) {
        return customers.value
            .filter(customer => customer.getKey() === customerId)
            .flatMap(customerModel => {
                // First we filter families with selected kids
                return customerModel
                    .families()
                    .value()
                    .filter(family => {
                        return family.kids().value().where('extra.selected', '===', true).isNotEmpty();
                    })
                    .flatMap(family => {
                        // Then we return selected kids of family
                        return family
                            .kids()
                            .value()
                            .where('extra.selected', '===', true)
                            .map(kid => {
                                return kid;
                            });
                    });
            });
    }

    /**
     * Generate doc params for kid
     *
     * @param kid
     * @param kidCustomer
     */
    function generateDocForKid(kid, kidCustomer) {
        const data: Record<string, string | string[]> = {
            doc_template_id: parameters.docTemplate.value.getKey(),
            from: Epoch.fromISOString(parameters.period.value.from as string).toFormat('yyyy-MM-dd'),
            to: Epoch.fromISOString(parameters.period.value.to as string).toFormat('yyyy-MM-dd'),
            customer_id: kidCustomer.getKey(),
            organization_id: kidCustomer.tenant().value().organizations().value().first().getKey(),
            kid_id: kid.getKey(),
        };

        // If we send email to each kid's contact and family member, we add kid's emails
        if (!isSentToSingleEmail.value) {
            data.sent_to = collect(kidCustomer.extra.sentTo).pluck('email').toArray();
        }

        return data;
    }

    /**
     * Generate doc params for company customer
     *
     * @param customer
     */
    function generateDocForCustomer(customer: CustomerModel) {
        const data: Record<string, string | string[]> = {
            doc_template_id: parameters.docTemplate.value.getKey(),
            from: Epoch.fromISOString(parameters.period.value.from as string).toFormat('yyyy-MM-dd'),
            to: Epoch.fromISOString(parameters.period.value.to as string).toFormat('yyyy-MM-dd'),
            customer_id: customer.getKey(),
            organization_id: parameters.organization.value.getKey(),
        };

        if (!isSentToSingleEmail.value) {
            data.sent_to = collect(customer.extra.sentTo).pluck('email').toArray();
        }

        return data;
    }

    /**
     * Send tax certificates
     *
     * @param callback
     */
    function sendCertificates(callback: () => void) {
        isSending.value = true;

        // Check if sent to single email is true and email is filled
        if (isSentToSingleEmail.value && !parameters.email.value) {
            toastError(__('billing_core:email_required'));
            isSending.value = false;

            return;
        }

        // Email content translations
        const filesNumber = isSentToSingleEmail.value ? 2 : 1;
        const mailSubject = __('common:tax_certificate', {count: filesNumber}) || '';
        const mailContent =
            __('billing_core:tax_certificate_email_content', {
                count: filesNumber,
                startDate: Epoch.fromJSDate(new Date(parameters.period.value.from as string)).toLocaleString(
                    Epoch.presets.DATE_SHORT
                ),
                endDate: Epoch.fromJSDate(new Date(parameters.period.value.to as string)).toLocaleString(
                    Epoch.presets.DATE_SHORT
                ),
            }) || '';

        // Generate operation params
        const params: Record<string, string | string[] | any[]> = {
            docs: docs.value,
            organization_id: parameters.organization.value.getKey(),
            mail_subject: mailSubject,
            mail_greeting: mailSubject,
            mail_content: mailContent,
        };

        // If sent to single email is true, we add it to params as sent_to
        if (isSentToSingleEmail.value) {
            params.sent_to = [parameters.email.value];
        }

        // Send operation
        new MqlOperation<CustomObject>('doc_template/notify', params)
            .run()
            .then(() => {
                toastSuccess(__('billing_core:email_sent'));
            })
            .catch(() => {
                toastError(__('common:errors.generic'));
            })
            .finally(() => {
                isSending.value = false;
                callback();
            });
    }

    return {
        periodErrors,
        sendCertificates,
        isSending,
        isSentToSingleEmail,
    };
}
