import {forEach} from 'lodash-es';
import moment from 'moment';
import type {Ref} from 'vue';

import type {LegacyKidContractType} from '@/modules/family/utils/contract/LegacyKidContractType';
import {getKidContractOfTheDay} from '@/modules/legacy/libs/Planning';

export interface LegacyKid {
    contracts: LegacyKidContractType[];
    familyMembers: LegacyKidFamilyMember[];
}

export interface LegacyKidFamilyMember {
    id: string;
}

export default function useLegacyKidContract(kid: Ref<LegacyKid>) {
    /**
     * Get the active contract of the kid.
     * @returns {LegacyKidContractType|null}
     */
    function getActiveContract(): LegacyKidContractType | null {
        return getKidContractOfTheDay(kid.value.contracts, moment());
    }

    /**
     * Get the family member ids of the kid's for document generation.
     * @returns {string[]}
     */
    function getKidDocumentFamilyMemberIds(): string[] {
        const activeContract = getActiveContract();

        const familyMembers: string[] = [];

        // Add the family members of the active contract.
        if (activeContract) {
            if (activeContract.first_family_member_id) {
                familyMembers.push(activeContract.first_family_member_id.toString());
            }

            if (activeContract.second_family_member_id) {
                familyMembers.push(activeContract.second_family_member_id.toString());
            }
        }

        // If there is only one family member, add the family members of the kid.
        if (familyMembers.length < 2 && kid.value.familyMembers.length > familyMembers.length) {
            forEach(kid.value.familyMembers, familyMember => {
                if (!familyMembers.includes(familyMember.id)) {
                    familyMembers.push(familyMember.id.toString());
                }
            });
        }

        return familyMembers;
    }

    return {
        getKidDocumentFamilyMemberIds,
    };
}
