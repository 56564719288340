<template>
    <div class="MTabs">
        <div class="MTabs__container">
            <nav class="MTabs__nav">
                <MTabsItem
                    v-for="(tab, tabKey) in tabs"
                    :key="'tab-' + tabKey"
                    :active="tabKey === selectedTab"
                    @click="selectTab(tabKey)"
                >
                    <div class="tw-flex tw-items-center tw-gap-2">
                        <slot
                            v-if="tab.meta?.icon"
                            :icon="tab.meta.icon"
                            name="tab-icon"
                        >
                            <FontAwesomeIcon :icon="tab.meta.icon" />
                        </slot>
                        <slot
                            :label="tab.label"
                            :meta="tab.meta"
                            name="tab-content"
                            :tab-key="tabKey"
                        >
                            <div class="tw-truncate">
                                {{ tab.label }}
                            </div>
                        </slot>
                    </div>
                </MTabsItem>
            </nav>
        </div>
        <MMenu class="MTabs__menu">
            <MMenuButton class="tw-w-full">
                <MButton
                    class="tw-w-full tw-justify-between"
                    size="lg"
                    variant="primary-alt"
                >
                    <div class="tw-flex tw-items-center tw-gap-2">
                        <slot
                            v-if="tabs[selectedTab].meta?.icon"
                            :icon="tabs[selectedTab].meta.icon"
                            name="tab-icon"
                        >
                            <FontAwesomeIcon
                                fixed-width
                                :icon="tabs[selectedTab].meta.icon"
                            />
                        </slot>
                        <slot
                            :label="tabs[selectedTab].label"
                            :meta="tabs[selectedTab].meta"
                            name="tab-content"
                            :tab-key="selectedTab"
                        >
                            <div class="tw-truncate">
                                {{ tabs[selectedTab].label }}
                            </div>
                        </slot>
                    </div>
                    <template #right-icons>
                        <FontAwesomeIcon icon="fa-solid fa-bars" />
                    </template>
                </MButton>
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    v-for="(tab, tabKey) in tabs"
                    :key="'tab-' + tabKey"
                    :active="tabKey === selectedTab"
                    @click="selectTab(tabKey)"
                >
                    <template
                        v-if="tab.meta?.icon"
                        #icon
                    >
                        <slot
                            :icon="tab.meta.icon"
                            name="tab-icon"
                        >
                            <FontAwesomeIcon
                                fixed-width
                                :icon="tab.meta.icon"
                            />
                        </slot>
                    </template>
                    <div class="tw-flex tw-items-center tw-gap-2">
                        <slot
                            :label="tab.label"
                            :meta="tab.meta"
                            name="tab-content"
                            :tab-key="tabKey"
                        >
                            {{ tab.label }}
                        </slot>
                    </div>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
        <slot :active-tab="selectedTab" />
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';

    const props = withDefaults(
        defineProps<{
            tabs: Record<
                string,
                {
                    label: string;
                    meta: {
                        icon?: string;
                    };
                }
            >;
            defaultTab?: string;
        }>(),
        {
            defaultTab: undefined,
        }
    );

    const emit = defineEmits<{
        (e: 'selected', tab: string): void;
    }>();

    const selectedTab = ref(props.defaultTab ?? Object.keys(props.tabs)[0]);

    function selectTab(tab: string) {
        selectedTab.value = tab;
        emit('selected', tab);
    }
</script>

<style scoped>
    .MTabs__container {
        @apply tw-border-b tw-border-gray-200;
        @apply tw-hidden md:tw-block;
    }

    .MTabs__nav {
        @apply tw--mb-px tw-flex tw-gap-x-8;
    }

    .MTabs__menu {
        @apply tw-block tw-w-full md:tw-hidden;
    }
</style>
