import type {Epoch} from '@meekohq/lumos';
import {isNil} from 'lodash-es';
import type {Ref} from 'vue';
import {computed, inject} from 'vue';

import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
import type {
    DateTimeModifiers,
    DateTimeModifierSingle,
    DateTimeModifiersPeriod,
} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
import useDateTimeModifiers from '@/modules/meeko-ui/composables/useDateTimeModifiers';

export interface MCalendarSelectionItem {
    // Is the day equal to the modified hovered date.
    isHovered: boolean;
    // When selecting, the day is between the first and last selecting day
    isSelecting: boolean;
    // The day is selected
    isSelected: boolean;
    // Is the start of the period (selected or selecting)
    isPeriodStart: boolean;
    // Is the end of the period (selected or selecting)
    isPeriodEnd: boolean;
    // The day is in the period (selected or selecting)
    isInPeriod: boolean;
}

/**
 * This composable provides the logic to handle the selection of dates in the calendar.
 * It is used by the days, months and years composable.
 * It provides the logic to handle the selection of dates, the hover effect and the selection of periods.
 *
 * # Glossary:
 * - **Selected date**: A selected date can be the first and last date or a period, or the from in single mode.
 * - **Selecting date**: Only in period mode, a selecting date is a date between the first and the last selecting date
 * - **Period**: A range of dates that are selected or selecting by the user
 * - **Infinite period**: A period that has no end date or no start date
 *
 *
 * @param hoveredDate The date that is currently hovered by the user
 * @param unit The unit the calendar is currently working on (day, month or year)
 * @param modifiers The modifiers to apply to the dates
 */
export default function useMCalendarSelection(
    hoveredDate: Ref<Epoch | undefined>,
    unit: 'day' | 'month' | 'year',
    modifiers: DateTimeModifiers | undefined
) {
    const state = inject(keyMCalendarState)!;
    if (!state) {
        throw new Error('keyMCalendarState must be provided');
    }

    const {isPeriod, internalValue, setDate, allowUndefined, mode} = state;

    /**
     * The date that is currently hovered by the user modified by the modifiers.
     * We want to apply a modifier to the hoveredDate to the user can see the effect of the modifier with the hover effect.
     * For example, if the calendar has a modifier "startOfWeek" and the user pass the mouse hover a wenesday, the monday will be hovered.
     */
    const modifiedHoveredDate = computed(() => {
        if (!modifiers) {
            return hoveredDate.value;
        }

        if (isPeriod) {
            const periodModifiers = modifiers as DateTimeModifiersPeriod;
            const {modifySingle: modifyFrom} = useDateTimeModifiers(periodModifiers.from);
            const {modifySingle: modifyTo} = useDateTimeModifiers(periodModifiers.to);

            // If period is infinite, apply the from modifier to the hovered date since the period will be reseted on click
            if (!internalValue.value.from || !internalValue.value.to) {
                return modifyFrom(hoveredDate.value);
            }

            // If from and to date are the same
            if (
                modifyFrom(internalValue.value.from)!.hasSame(modifyFrom(internalValue.value.to), unit) ||
                modifyTo(internalValue.value.from)!.hasSame(modifyTo(internalValue.value.to), unit)
            ) {
                // If the hovered date is before the from date, apply the from modifier since the period will be reseted on click
                if (hoveredDate.value?.lessThan(internalValue.value.from)) {
                    return modifyFrom(hoveredDate.value);
                }

                // Apply the to modifier, a period will be created
                return modifyTo(hoveredDate.value);
            }

            return modifyFrom(hoveredDate.value);
        }

        // If in single mode directly apply the modifier on the hovered date
        return useDateTimeModifiers(modifiers as DateTimeModifierSingle).modifySingle(hoveredDate.value);
    });

    /**
     * The first day of the period (from date)
     */
    const firstPeriodDay = computed(() => {
        return internalValue.value.from;
    });

    /**
     * The last day of the period (to date or from if undefined)
     */
    const lastPeriodDay = computed(() => {
        if (!internalValue.value.to) {
            return firstPeriodDay.value;
        }

        return internalValue.value.to;
    });

    /**
     * Returns true if firstSelectingDate or lastSelectingDate can be defined.
     */
    const canSelectDate = computed(() => {
        // If the hovered date is not defined, we are not selecting
        if (isNil(modifiedHoveredDate.value)) {
            return false;
        }

        if (!isPeriod) {
            return true;
        }

        const periodModifiers = modifiers as DateTimeModifiersPeriod;
        const {modifySingle: modifyFrom} = useDateTimeModifiers(periodModifiers?.from);
        const {modifySingle: modifyTo} = useDateTimeModifiers(periodModifiers?.to);

        // If the period is already defined, we are not selecting
        return !(
            internalValue.value.from &&
            internalValue.value.to &&
            !modifyFrom(internalValue.value.from)!.hasSame(modifyFrom(internalValue.value.to), unit) &&
            !modifyTo(internalValue.value.from)!.hasSame(modifyTo(internalValue.value.to), unit)
        );
    });

    /**
     * The first selecting date is the beginning of the selection. It should be the from date if following conditions are meet.
     */
    const firstSelectingDate = computed(() => {
        if (!canSelectDate.value) {
            return undefined;
        }

        // If the hovered date is before the from date, we are not selecting
        if (modifiedHoveredDate.value!.lessThan(internalValue.value.from)) {
            return undefined;
        }

        return internalValue.value.from;
    });

    /**
     * The last selecting date is the end of the selection. It should be the modified hovered date if following conditions are meet.
     */
    const lastSelectingDate = computed(() => {
        if (!canSelectDate.value) {
            return undefined;
        }

        // If the hovered date is before the to date, we are not selecting
        if (modifiedHoveredDate.value!.lessThan(internalValue.value.to)) {
            return undefined;
        }

        return modifiedHoveredDate.value;
    });

    function isDatePeriodStart(date: Epoch) {
        return !!firstPeriodDay.value?.hasSame(date, unit);
    }

    function isDatePeriodEnd(date: Epoch) {
        return !!lastPeriodDay.value?.hasSame(date, unit);
    }

    function isDateSelectingStart(date: Epoch) {
        return !!firstSelectingDate.value?.hasSame(date, unit);
    }

    function isDateSelectingEnd(date: Epoch) {
        return !!lastSelectingDate.value?.hasSame(date, unit);
    }

    function isDateSelected(date: Epoch) {
        const isPeriodStart = isDatePeriodStart(date);
        const isPeriodEnd = isDatePeriodEnd(date);
        const isSelectingStart = isDateSelectingStart(date);
        const isSelectingEnd = isDateSelectingEnd(date);

        if (isPeriod) {
            // If neither from nor to are defined, no date is selected
            if (!internalValue.value.from && !internalValue.value.to) {
                return false;
            }

            // The date is selected if it is the start or the end of the period or if it is the start or the end of the selecting
            return isPeriodStart || isPeriodEnd || isSelectingStart || isSelectingEnd;
        } else {
            return !!internalValue.value.from?.hasSame(date, unit);
        }
    }

    function isDateSelecting(date: Epoch) {
        // If not in period mode, the date cannot be selecting
        if (!isPeriod) {
            return false;
        }

        // The date is selecting if it is between the first and last selecting date
        if (firstSelectingDate.value && lastSelectingDate.value) {
            return date.between(firstSelectingDate.value.startOfDay(), lastSelectingDate.value.endOfDay(), true);
        }

        return false;
    }

    function isDateInPeriod(date: Epoch) {
        if (!isPeriod) {
            return false;
        }

        // If date is selecting, it is in period
        if (isDateSelecting(date)) {
            return true;
        }

        if (!firstSelectingDate.value || !lastSelectingDate.value) {
            // If no to date is defined, the period is infinite and the date is in period if it is after the first period day
            if (isNil(internalValue.value.to)) {
                return date.greaterThan(firstPeriodDay.value?.startOfDay());
            }

            // If no from date is defined, the period is infinite and the date is in period if it is before the last period day
            if (isNil(internalValue.value.from)) {
                return date.lessThan(lastPeriodDay.value?.endOfDay());
            }

            // If first period day and last period day are defined and the date is between them, the date is in period
            if (firstPeriodDay.value && lastPeriodDay.value) {
                return date.between(firstPeriodDay.value.startOfDay(), lastPeriodDay.value.endOfDay(), true);
            }
        }

        return false;
    }

    function isDateHovered(date: Epoch) {
        return !!modifiedHoveredDate.value?.hasSame(date, unit);
    }

    function handleDateClick(date: Epoch) {
        let dateAtStartOfUnit = date.startOf(unit);
        let dateAtEndOfUnit = date.endOf(unit);

        // When we are in dateTime mode we don't want to override the previous times of the dates.
        if (mode === 'dateTime') {
            dateAtStartOfUnit = date.set({
                hour: internalValue.value.from?.hour,
                minute: internalValue.value.from?.minute,
                second: internalValue.value.from?.second,
            });

            dateAtEndOfUnit = date.set({
                hour: internalValue.value.to?.hour,
                minute: internalValue.value.to?.minute,
                second: internalValue.value.to?.second,
            });
        }

        if (!isPeriod) {
            handleDateClickSingle(date, dateAtStartOfUnit);

            return;
        }

        handleDateClickPeriod(date, dateAtStartOfUnit, dateAtEndOfUnit);
    }

    function handleDateClickSingle(date: Epoch, dateAtStartOfUnit: Epoch) {
        const {modifySingle} = useDateTimeModifiers(modifiers as DateTimeModifierSingle);

        // Date is already selected and the user clicks on the same date, remove it if allow undefined
        if (allowUndefined && internalValue.value.from?.hasSame(modifySingle(date), unit)) {
            setDate(undefined);

            return;
        }

        setDate(modifySingle(dateAtStartOfUnit));
    }

    function handleDateClickPeriod(date: Epoch, dateAtStartOfUnit: Epoch, dateAtEndOfUnit: Epoch) {
        const periodModifiers = modifiers as DateTimeModifiersPeriod;

        const {modifySingle: modifyFrom} = useDateTimeModifiers(periodModifiers?.from);
        const {modifySingle: modifyTo} = useDateTimeModifiers(periodModifiers?.to);

        // The period is infinite. Set from and to date to the clicked date
        if (!internalValue.value.from || !internalValue.value.to) {
            setDate({from: modifyFrom(dateAtStartOfUnit), to: modifyTo(dateAtEndOfUnit)});

            return;
        }

        // From date is the same as to date
        if (
            modifyFrom(internalValue.value.from)!.hasSame(modifyFrom(internalValue.value.to), unit) ||
            modifyTo(internalValue.value.from)!.hasSame(modifyTo(internalValue.value.to), unit)
        ) {
            // If the clicked date is after the from date, set it as to date
            if (modifyTo(date)?.greaterThan(internalValue.value.from)) {
                setDate({from: internalValue.value.from, to: modifyTo(dateAtEndOfUnit)});

                return;
            }

            // If the clicked date is the same as the from date and allow undefined is true, remove the period
            if (modifyFrom(date)?.hasSame(internalValue.value.from, unit) && allowUndefined) {
                setDate({from: undefined, to: undefined});

                return;
            }

            // Set from and to date to the clicked date
            setDate({from: modifyFrom(dateAtStartOfUnit), to: modifyTo(dateAtEndOfUnit)});

            return;
        }

        // From date is clicked, remove it
        if (internalValue.value.from?.hasSame(modifyFrom(date), unit)) {
            setDate({from: modifyFrom(internalValue.value.to.startOf(unit)), to: modifyTo(internalValue.value.to)});

            return;
        }

        // To date is clicked, remove it
        if (internalValue.value.to?.hasSame(modifyTo(date), unit)) {
            setDate({from: modifyFrom(internalValue.value.from), to: modifyTo(internalValue.value.from.endOf(unit))});

            return;
        }

        // Set from and to date to the clicked date
        setDate({from: modifyFrom(dateAtStartOfUnit), to: modifyTo(dateAtEndOfUnit)});
    }

    return {
        modifiedHoveredDate,

        firstPeriodDay,
        lastPeriodDay,
        firstSelectingDate,
        lastSelectingDate,
        isDateHovered,
        isDatePeriodStart,
        isDatePeriodEnd,
        isDateSelectingStart,
        isDateSelectingEnd,
        isDateSelected,
        isDateSelecting,
        isDateInPeriod,

        handleDateClick,
    };
}
