<template>
    <component
        :is="containerElement"
        arrow
        class="tw-inline-block"
        placement="top"
    >
        <MMenuButton
            ref="downloadButton"
            class="tw-w-full"
        >
            <component
                :is="as"
                v-bind="dynamicTemplateProps"
                @click.stop="saveBeforeDownloadTemplate"
            >
                <template
                    v-if="as === 'CButton' && !buttonWithoutText"
                    #left-icons
                >
                    <FontAwesomeIcon icon="fa-solid fa-download" />
                </template>
                <template v-if="as === 'CButton' && buttonWithoutText">
                    <div>
                        <FontAwesomeIcon
                            class="tw-text-blue-500"
                            icon="fa-solid fa-download"
                        />
                    </div>
                </template>
                <template
                    v-if="as === 'MMenuItem'"
                    #icon
                >
                    <FontAwesomeIcon
                        v-if="downloading"
                        icon="fa-solid fa-circle-notch"
                        spin
                    />
                    <FontAwesomeIcon
                        v-else
                        icon="fa-solid fa-download"
                    />
                </template>
                <template v-if="downloading">
                    {{ !buttonWithoutText ? __('common:downloading_dots') : '' }}
                </template>
                <template v-else-if="loading">
                    {{ !buttonWithoutText ? __('common:loading_dots') : '' }}
                </template>
                <template v-else>
                    {{ !buttonWithoutText ? __('common:actions.download') : '' }}
                </template>
            </component>
        </MMenuButton>

        <MMenuItems>
            <template v-if="loading">
                <MMenuItem :label="__('common:loading_in_progress_dots')">
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-circle-notch"
                            spin
                        />
                    </template>
                </MMenuItem>
            </template>
            <template v-else>
                <div
                    v-if="filteredDocTemplates.length > 0"
                    class="tw-max-h-60 tw-overflow-y-auto"
                >
                    <MMenuItem
                        v-for="(template, i) in filteredDocTemplates"
                        :key="'template-' + i"
                        :label="template.name"
                        @click.prevent.stop="download(template)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-download"
                            />
                        </template>
                    </MMenuItem>
                </div>
                <router-link
                    v-else
                    :to="{name: 'settings', query: {nav: 'documents'}}"
                    @click="showArticle(1860558)"
                >
                    <MMenuItem
                        :label="__('organization:create_a_new_document_template_dots')"
                        manual
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-external-link"
                            />
                        </template>
                    </MMenuItem>
                </router-link>
            </template>
        </MMenuItems>
    </component>
</template>

<script lang="ts">
    import type {CustomObject} from '@meekohq/lumos';
    import {MqlOperation} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _sortBy from 'lodash-es/sortBy';
    import moment from 'moment';
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, nextTick, ref, toRef} from 'vue';
    import {useRouter} from 'vue-router';

    import useDownload from '@/modules/app/composables/useDownload';
    import useIntercom from '@/modules/app/composables/useIntercom';
    import __ from '@/modules/app/utils/i18n-facade';
    import type {LegacyKid} from '@/modules/family/composables/kid/useLegacyKidContract';
    import useLegacyKidContract from '@/modules/family/composables/kid/useLegacyKidContract';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            as: {
                type: String as PropType<'CButton' | 'MMenuItem'>,
                default: 'CButton',
            },
            item: {
                type: Object,
                required: true,
            },
            additionalItems: {
                type: Array as PropType<{name: string; value: string | number}[]>,
                default: null,
            },
            nursery: {
                type: Object,
                required: true,
            },
            docType: {
                type: String as PropType<
                    | 'contract'
                    | 'registration'
                    | 'kid'
                    | 'family_member'
                    | 'staff'
                    | 'transaction'
                    | 'tax-certificate'
                    | 'sepa_mandate'
                    | 'staff_contract'
                >,
                required: true,
            },
            fromDate: {
                type: String,
                default: null,
            },
            toDate: {
                type: String,
                default: null,
            },
            sm: {
                type: Boolean,
                default: false,
            },
            variant: {
                type: String,
                default: 'info',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            waitForCallback: {
                type: Boolean,
                default: false,
            },
            customClass: {
                type: String,
                default: '',
            },
            buttonWithoutText: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['downloaded', 'saveBeforeDownloadTemplate'],
        setup(props, {emit}) {
            const {error: toastError} = useNotification();
            const router = useRouter();
            const {downloadFromBase64} = useDownload();

            const {showArticle} = useIntercom();

            const downloading = ref(false);
            const downloadButton = ref();

            const containerElement = computed(() => {
                return props.as === 'MMenuItem' ? 'MSubMenu' : 'MMenu';
            });

            const filteredDocTemplates = computed(() => {
                // Filter registration as a contract type
                const docType = props.docType === 'registration' ? 'contract' : props.docType;

                const clonedTemplates = _cloneDeep(props.nursery.docTemplates);

                return _sortBy(
                    clonedTemplates.filter(item => item.type === docType),
                    'name'
                );
            });

            const dynamicTemplateProps = computed(() => {
                if (props.as === 'MMenuItem') {
                    return {
                        disabled: props.disabled || downloading.value,
                        variant: 'default',
                        autoClosePopover: false,
                    };
                } else {
                    return {
                        class: props.customClass,
                        disabled: props.disabled,
                        loading: props.loading || downloading.value,
                        size: props.sm ? 'base' : null,
                        variant: props.variant,
                    };
                }
            });

            function download(template) {
                downloadButton.value?.closePopover();

                downloading.value = true;

                // Generate docs params for the operation depending on the doc template type
                const params: Record<string, number | string | string[]> = {
                    doc_template_id: template.id,
                };

                switch (props.docType) {
                    case 'contract': {
                        const contractConfigId = props.item.config ? props.item.config.id : '';
                        params.contract_id = props.item.id.toString();
                        params.config_id = contractConfigId.toString();

                        if (props.item.kid_id) {
                            params.kid_id = props.item.kid_id.toString();
                        }

                        if (props.item.first_family_member_id || props.item.second_family_member_id) {
                            params.family_member_ids = [];

                            if (props.item.first_family_member_id) {
                                params.family_member_ids.push(props.item.first_family_member_id.toString());
                            }

                            if (props.item.second_family_member_id) {
                                params.family_member_ids.push(props.item.second_family_member_id.toString());
                            }
                        }
                        break;
                    }
                    case 'staff_contract': {
                        params.staff_id = props.item.staff.id.toString();
                        params.staff_contract_id = props.item.contract.id.toString();
                        break;
                    }
                    case 'registration': {
                        const regisrationConfigId = props.item.config ? props.item.config.id : '';
                        params.registration_id = props.item.id.toString();
                        params.config_id = regisrationConfigId.toString();
                        break;
                    }
                    case 'kid':
                        params.kid_id = props.item.id.toString();
                        params.family_member_ids = [];

                        // eslint-disable-next-line no-case-declarations
                        const {getKidDocumentFamilyMemberIds} = useLegacyKidContract(
                            toRef(props, 'item') as Ref<LegacyKid>
                        );
                        params.family_member_ids = getKidDocumentFamilyMemberIds();
                        break;
                    case 'staff':
                        params.staff_id = props.item.id.toString();
                        break;
                    case 'family_member':
                        params.family_member_id = props.item.id.toString();
                        break;
                    case 'transaction':
                        params.transaction_id = props.item.getKey();
                        break;
                    case 'sepa_mandate':
                        params.sepa_mandate_id = props.item.id.toString();
                        break;
                    case 'tax-certificate':
                        params.customer_id = props.item.id.toString();
                        if (props.fromDate) {
                            params.from = moment(props.fromDate).format('Y-MM-DD');
                        }
                        if (props.fromDate) {
                            params.to = moment(props.toDate).format('Y-MM-DD');
                        }
                        break;
                }

                if (props.additionalItems) {
                    props.additionalItems.forEach(additionalItem => {
                        params[additionalItem.name] = additionalItem.value.toString();
                    });
                }

                params.organization_id = props.nursery.id.toString();

                new MqlOperation<CustomObject>('doc_template/download', {
                    docs: [params],
                })
                    .run()
                    .then(async result => {
                        // Generate fake link to download
                        downloadFromBase64(
                            result.content.data.file,
                            result.content.data.name + '.' + result.content.data.extension,
                            'application/' + result.content.data.extension
                        );
                    })
                    .then(() => {
                        emit('downloaded');
                    })
                    .catch(() => {
                        toastError(__('common:errors.generic'));
                    })
                    .finally(() => {
                        downloading.value = false;
                    });
            }

            function saveBeforeDownloadTemplate() {
                if (props.waitForCallback) {
                    emit('saveBeforeDownloadTemplate', {
                        done() {
                            if (filteredDocTemplates.value.length === 1) {
                                const template = filteredDocTemplates.value[0];
                                download(template);
                            } else {
                                downloadButton.value?.openPopover();
                            }
                        },
                    });
                } else {
                    emit('saveBeforeDownloadTemplate');
                    nextTick(() => {
                        if (filteredDocTemplates.value.length === 1) {
                            const template = filteredDocTemplates.value[0];
                            download(template);
                        } else {
                            downloadButton.value?.openPopover();
                        }
                    });
                }
            }

            function createTemplate() {
                router?.push({name: 'settings', params: {nursery: props.nursery.id}});
            }

            return {
                containerElement,
                createTemplate,
                download,
                downloadButton,
                downloading,
                filteredDocTemplates,
                saveBeforeDownloadTemplate,
                dynamicTemplateProps,
                showArticle,
            };
        },
    });
</script>
