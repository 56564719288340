<script setup lang="ts">
    import {useElementHover, useFocusWithin} from '@vueuse/core';
    import {inject, onMounted, ref, watch} from 'vue';

    import type {MMenuState} from '@/modules/meeko-ui/components/Elements/MMenu/MMenuState';
    import {type defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const context = inject<ReturnType<typeof defineFloatingContext>>('MFloatingContext');
    const state = inject<ReturnType<typeof MMenuState>>('MMenuState');

    if (!context) {
        throw new Error('MMenuButton must be used inside a MMenu');
    }

    if (!state) {
        throw new Error('MMenuButton must be used inside a MMenu');
    }

    const {
        togglePopover,
        setHovered,
        isVisible,
        trigger,
        buttonId,
        panelId,
        setButtonElement,
        openPopover,
        closePopover,
    } = context;

    const {hasOverlay} = state;

    const myButton = ref();

    onMounted(() => {
        setButtonElement(myButton);
    });

    if (trigger.value === 'hover') {
        const isHovered = useElementHover(myButton);
        watch(isHovered, setHovered);

        const {focused} = useFocusWithin(myButton);
        watch(focused, () => {
            if (focused.value) {
                openPopover();
            } else {
                closePopover();
            }
        });
    }

    function onClick() {
        if (trigger.value === 'click') {
            togglePopover();
        }
    }

    function onEsc() {
        if (trigger.value === 'hover') {
            closePopover();
        }
    }

    function onSpaceOrEnter() {
        if (trigger.value === 'hover') {
            openPopover();
        }
    }

    defineExpose({
        openPopover,
        closePopover,
    });
</script>

<template>
    <div
        :id="buttonId"
        ref="myButton"
        :aria-controls="panelId"
        :aria-expanded="isVisible"
        aria-haspopup="dialog"
        :style="{
            zIndex: hasOverlay && isVisible ? 3060 : null,
        }"
        tabindex="-1"
        @click="onClick"
        @keydown.enter="onSpaceOrEnter"
        @keydown.esc="onEsc"
        @keydown.space="onSpaceOrEnter"
    >
        <slot :toggle="togglePopover" />
    </div>
</template>
