<template>
    <div>
        <SelectResource
            ref="SelectResource"
            :builder="builder"
            :button-class="buttonClass"
            :disabled="disabled"
            :has-unselect="hasUnselect"
            :hide-on-click="!checkable"
            :model="organizationModel"
            :unselect-value="unselectValue"
            :model-value="modelValue"
            @fallback="showModal"
            @update:model-value="onModelSelected"
            @search="onSearchValue"
        >
            <template #trigger="{toggle}">
                <slot
                    name="trigger"
                    :toggle="toggle"
                    :value="modelValue"
                />
            </template>
            <template #button>
                <div
                    v-if="checkedOrganizations.count()"
                    class="tw-flex tw-w-auto tw-items-center"
                >
                    {{ __('common:organization_with_count', {count: checkedOrganizations.count()}) }}
                </div>
                <template v-else>
                    <slot name="unselectedValue">
                        {{ __('hr_contract:select_organizations') }}
                    </slot>
                </template>
            </template>

            <template
                v-if="createOptionEnabled"
                #fallback="{searchValue}"
            >
                <template>
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />
                    {{ __('common:actions.add') }} {{ searchValue }}
                </template>
            </template>

            <template
                v-if="modelValue && hasUnselect"
                #unselect-item
            >
                <slot name="unselect-item" />
            </template>
            <template #default="{option, searchValue}">
                <div
                    v-if="staffInOrganization(option)"
                    class="tw-flex tw-items-center tw-gap-2"
                >
                    <CCheckbox
                        v-if="canUncheck || !isChecked(option)"
                        :model-value="isChecked(option)"
                        @click.stop="isChecked(option) ? $emit('delete', option) : $emit('add', option)"
                    />
                    <CCheckbox
                        v-else
                        :model-value="isChecked(option)"
                        @click.stop.self.prevent
                    />
                    <CBadge
                        v-if="option.attributes.city"
                        class="tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-text-blue-500"
                        size="sm"
                        variant="primary"
                    >
                        {{ option.attributes.city }}
                    </CBadge>
                    <div
                        class="tw-truncate"
                        v-html="TextHighlighter.highlight(searchValue, option.attributes.name)"
                    />
                </div>
                <div
                    v-else
                    class="tw-flex tw-items-center tw-gap-2 tw-truncate tw-opacity-50"
                >
                    <CCheckbox
                        :model-value="isChecked(option)"
                        @click.self.prevent="$refs.confirmModal.show(option)"
                    />
                    <CBadge
                        v-if="option.attributes.city"
                        class="tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-text-blue-500"
                        size="sm"
                        variant="primary"
                    >
                        {{ option.attributes.city }}
                    </CBadge>
                    <MLabel class="tw-truncate">
                        <span v-html="TextHighlighter.highlight(searchValue, option.attributes.name)" />
                    </MLabel>
                </div>
            </template>
        </SelectResource>
        <ConfirmImportStaffModal
            ref="confirmModal"
            :staff="staff"
            @confirm="$emit('addOrganizationOnStaffAndContract', $event)"
        >
            <template #content="{organization}">
                <CVStack
                    v-if="isChecked(organization)"
                    gap="4"
                >
                    <CVStack gap="1">
                        <CText>
                            {{ __('hr_contract:contract_already_attached_on_organization') }}
                            <strong>"{{ organization.attributes.name }}"</strong>
                            .
                        </CText>
                        <CText>
                            {{ __('hr_contract:however_staff_member') }}
                            <strong>"{{ staff.attributes.first_name }}"</strong>
                            {{ __('hr_contract:not_attached_on_organization') }}
                        </CText>
                    </CVStack>
                    <CText font-size="lg">
                        {{ __('hr_contract:import_staff_on_organization_confirmation') }}
                        <strong>"{{ organization.attributes.name }}"</strong>
                        ?
                    </CText>
                </CVStack>
                <CVStack
                    v-else
                    gap="4"
                >
                    <CVStack gap="1">
                        <CText>
                            {{ __('hr_contract:for_attach_contract') }}
                            <strong>"{{ organization.attributes.name }}"</strong>
                            .
                        </CText>
                        <CText>
                            {{ __('hr_contract:we_must_link') }}
                            <strong>"{{ staff.attributes.first_name }}"</strong>
                            {{ __('hr_contract:to_this_one') }}
                        </CText>
                    </CVStack>
                    <CText font-size="lg">
                        {{ __('hr_contract:import_staff_on_organization_confirmation') }}
                        <strong>"{{ organization.attributes.name }}"</strong>
                        ?
                    </CText>
                </CVStack>
            </template>
        </ConfirmImportStaffModal>
    </div>
</template>

<script>
    import {collect} from '@meekohq/lumos';

    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import ConfirmImportStaffModal from '@/modules/human-resources/components/contract/Modals/ConfirmImportStaffModal.vue';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import Manager from '@/modules/legacy/store/manager.store';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default {
        components: {ConfirmImportStaffModal, SelectResource},
        props: {
            unselectValue: {
                default: undefined,
            },
            modelValue: {
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            customModal: {
                type: Boolean,
                default: false,
            },
            createOptionEnabled: {
                type: Boolean,
                default: false,
            },
            hasUnselect: {
                type: Boolean,
                default: false,
            },
            checkable: {
                type: Boolean,
                default: false,
            },
            organizations: {
                type: Object,
                default: null,
            },
            staffOrganizations: {
                type: Object,
                default: null,
            },
            buttonClass: {},
            staff: {
                type: StaffModel,
            },
        },

        emits: ['update:modelValue', 'hideModal', 'showModal', 'addOrganizationOnStaffAndContract', 'add', 'delete'],

        data() {
            return {
                icon: null,
                organizationModel: OrganizationModel,
                loading: false,
                TextHighlighter: new TextHighlighter(),
                searchValue: '',
                Manager,
            };
        },

        computed: {
            userNuseriesIds() {
                return collect(useAuth().legacyUser.value.nurseries).keyBy('id').keys().all();
            },

            checkedOrganizations() {
                return this.organizations.filter(organization => !organization.pivot().markedForDeletion);
            },

            canUncheck() {
                return this.checkedOrganizations.count() > 1;
            },

            builder() {
                return OrganizationModel.query()
                    .whereIn('id', this.userNuseriesIds)
                    .orderBy('name')
                    .whereLike('name', `*${this.searchValue}*`);
            },

            defaultModal: {
                cache: false,
                get() {
                    return this.$refs.defaultModal;
                },
            },

            finder: {
                cache: false,
                get() {
                    return this.$refs.finder;
                },
            },

            SelectResource: {
                cache: false,
                get() {
                    return this.$refs.SelectResource;
                },
            },
        },

        methods: {
            staffInOrganization(organization) {
                return this.staffOrganizations.contains(item => item.attributes.organization_id === organization.id);
            },

            isChecked(organization) {
                return this.checkedOrganizations.contains(item => item.id === organization.id);
            },

            onSearchValue(event) {
                this.searchValue = event;
            },

            onModelCreated(model) {
                this.hideModal();
                this.onModelSelected(model);
            },

            onModelSelected(model) {
                this.$emit('update:modelValue', model);
            },

            hideModal() {
                if (this.customModal) {
                    this.$emit('hideModal');
                } else {
                    this.defaultModal.hide();
                }
            },

            showModal() {
                if (this.customModal) {
                    this.$emit('showModal');
                } else {
                    this.defaultModal.show();
                }
                this.SelectResource.hide();
            },
        },
    };
</script>
