<template>
    <div>
        <div class="tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-border-b tw-border-gray-200 tw-pb-4">
            <MDatePeriodFilter v-model="periodFilter" />
            <EventTypeFinder
                v-if="eventTypes"
                v-model="eventTypes"
                multi
                @update:model-value="onEventTypeClicked"
            />
            <OrganizationFinder
                v-if="hasManyOrganizationsInAccount"
                v-model="selectedOrganizations"
                :inject-query="organizationFinderQueryBuilder"
                multi
                multi-minimum="1"
            />
            <FilterEvents
                v-model:event-filters="eventFilters"
                local-storage-key="reportEvents"
            />
            <MToggleFilter
                v-model="getEventsWithoutOrganizations"
                :label="__('hr:include_events_without_organization')"
                size="base"
            />
        </div>

        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div
            v-else
            class="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-3"
        >
            <MBox
                v-if="!staff"
                class="tw-bg-blue-50 tw-p-4 lg:tw-col-span-1"
            >
                <StaffsList
                    v-model:selected-contracts="selectedContracts"
                    v-model:selected-staff="selectedStaff"
                    v-model:selected-tags="selectedTags"
                    v-model:selected-teams="selectedTeams"
                    v-model:without-tags="withoutTags"
                    :organizations="selectedOrganizations"
                    :staff-events="staffEvents"
                    :staffs="filteredStaffs"
                />
                <CPagination
                    v-if="paginator"
                    class="tw-mt-2 tw-text-center"
                    :paginator="paginator"
                    @navigate="$page => getStaffs($page)"
                />
            </MBox>

            <div :class="staff ? 'lg:tw-col-span-3' : 'lg:tw-col-span-2'">
                <loader
                    v-if="loadingEvent"
                    light="true"
                    shadow="false"
                    size="sm"
                />
                <template v-else>
                    <div
                        v-if="selectedStaff"
                        class="tw-flex tw-flex-col tw-gap-4 md:tw-flex-row"
                    >
                        <div class="tw-flex tw-w-full tw-flex-col tw-gap-4 md:tw-w-7/12">
                            <StaffDetails
                                :from="periodFilter.from"
                                :staff="selectedStaff"
                                :staff-statistics="staffStatistics"
                                :to="periodFilter.to"
                            />
                            <EventChartByTypes
                                v-if="staffEvents"
                                :events="staffEvents"
                            />
                        </div>
                        <div class="tw-w-full md:tw-w-5/12">
                            <EventsByDay
                                :events="staffEvents"
                                :from="periodFilter.from"
                                :to="periodFilter.to"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {ModelCollection} from '@meekohq/lumos';
    import moment from 'moment';
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';

    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import FilterEvents from '@/modules/human-resources/components/calendar/FilterEvents.vue';
    import EventTypeFinder from '@/modules/human-resources/components/event-type/EventTypeFinder.vue';
    import EventChartByTypes from '@/modules/human-resources/components/statistics/EventChartByTypes.vue';
    import EventsByDay from '@/modules/human-resources/components/statistics/EventsByDay.vue';
    import StaffDetails from '@/modules/human-resources/components/statistics/StaffDetails.vue';
    import StaffsList from '@/modules/human-resources/components/statistics/StaffsList.vue';
    import useStaff from '@/modules/human-resources/composables/staff/useStaff';
    import useStaffsStatistics from '@/modules/human-resources/composables/statistics/useStaffsStatistics';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import MToggleFilter from '@/modules/meeko-ui/components/MToggleFilter.vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {
            MToggleFilter,
            EventChartByTypes,
            EventTypeFinder,
            EventsByDay,
            FilterEvents,
            MDatePeriodFilter,
            OrganizationFinder,
            StaffDetails,
            StaffsList,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: false,
            },
        },
        setup(props) {
            const loadingEvent = ref(false);
            const periodFilter = ref({
                from: localStorage.getItem('staffs:statistics:from') || moment().startOf('month').toISOString(true),
                to: localStorage.getItem('staffs:statistics:to') || moment().endOf('month').toISOString(true),
            });
            const organizationFinderQueryBuilder = computed(() => OrganizationModel.query().scope('userOrganizations'));

            const {selectedOptions: selectedTeams} = useUserFilters('staffs:statictics:teams', OutputType.array, []);

            const {
                filteredStaffs,
                selectedStaff,
                loading,
                selectedTags,
                selectedContracts,
                selectedOrganizations,
                selectedOrganizationsIds,
                initFilters,
                paginator,
                withoutTags,
                getStaffs,
                getEventsWithoutOrganizations,
            } = useStaff(null, props.staff, null, periodFilter, false, ref({teams: selectedTeams as Ref<TeamModel[]>}));

            const staffStatistics = useStaffsStatistics(selectedStaff);
            const {
                eventFilters,
                staffEvents,
                calendars,
                hasManyOrganizationsInAccount,

                totalEvents,
                totalEventsForCalendar,

                eventTypes,
                getStaffEvents,
                filteringStaffEvents,
                onEventTypeClicked,
            } = staffStatistics;

            onMounted(() => {
                initFilters();
            });

            watch([selectedTeams, getEventsWithoutOrganizations], () => {
                getStaffs(1);
            });

            watch(
                [selectedStaff, eventTypes, eventFilters],
                () => {
                    if (selectedStaff.value && periodFilter.value.from && periodFilter.value.to) {
                        loadingEvent.value = true;
                        staffEvents.value = new ModelCollection([]);
                        getStaffEvents(periodFilter, selectedOrganizationsIds, getEventsWithoutOrganizations).then(
                            events => {
                                staffEvents.value = filteringStaffEvents(events);
                                loadingEvent.value = false;
                            }
                        );
                    }
                },
                {deep: true}
            );

            return {
                moment,
                periodFilter,
                hasManyOrganizationsInAccount,
                getEventsWithoutOrganizations,

                filteredStaffs,
                selectedTags,
                selectedContracts,
                selectedTeams,
                selectedOrganizations,
                organizationFinderQueryBuilder,
                getStaffs,
                paginator,

                selectedStaff,
                staffStatistics,
                withoutTags,
                loading,
                loadingEvent,

                staffEvents,
                eventFilters,
                calendars,

                totalEvents,
                totalEventsForCalendar,

                eventTypes,
                onEventTypeClicked,
            };
        },
    });
</script>
