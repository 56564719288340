<template>
    <div>
        <DocTemplatesManagerHeader
            :nurseries-templates="nurseryTemplates"
            :other-nurseries-templates="otherNurseriesTemplates"
            :selected-template="selectedTemplate"
            @edit-template="selectedTemplate = $event"
            @import-template="importTemplate"
            @new-template="docTemplatesManagerModalCreate().show()"
        />
        <hr class="tw-mb-4 tw-mt-5" />
        <DocTemplatesManagerEditorHeader
            v-if="selectedTemplate.id || selectedTemplate.id === null"
            :loading="editorLoading"
            :selected-template="selectedTemplate"
            :template-exists="templateExists"
            :uploading="uploading"
            :saving="saving"
            @detach-template="detachTemplate"
            @duplicate-template="docTemplatesManagerModalDuplicate().show()"
            @remove-template="removeTemplate"
            @save-template="saveTemplate"
            @update-destination="updateDestination"
            @upload-file="uploadFile"
        />
        <DocTemplatesManagerWordFile
            v-if="selectedTemplate.format === 'word'"
            v-model="file"
            class="tw-mt-4"
            :selected-template="selectedTemplate"
        />
        <HtmlEditor
            v-else-if="selectedTemplate.format === 'html'"
            ref="editor"
            :disabled="!$can('update', 'doc_templates')"
            :editor-variables="editorVariables"
            :loading="editorLoading"
            :model-value="selectedTemplate.content"
            @update:model-value="setDecodedContent"
        />
        <MEmptyIndex v-else />
    </div>
</template>

<script>
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _sortBy from 'lodash-es/sortBy';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import HtmlEditor from '@/modules/legacy/components/Modules/HtmlEditor.vue';
    import {showCAF} from '@/modules/legacy/libs/organization';
    import {upload} from '@/modules/legacy/libs/usercontent';
    import route from '@/modules/legacy/libs/ziggy';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import DocTemplatesManagerEditorHeader from '@/modules/organization/components/DocTemplatesManager/DocTemplatesManagerEditorHeader.vue';
    import DocTemplatesManagerHeader from '@/modules/organization/components/DocTemplatesManager/DocTemplatesManagerHeader.vue';
    import DocTemplatesManagerModalCreate from '@/modules/organization/components/DocTemplatesManager/DocTemplatesManagerModalCreate.vue';
    import DocTemplatesManagerModalDuplicate from '@/modules/organization/components/DocTemplatesManager/DocTemplatesManagerModalDuplicate.vue';
    import DocTemplatesManagerWordFile from '@/modules/organization/components/DocTemplatesManager/DocTemplatesManagerWordFile.vue';
    import DocTemplatesVariablesByType from '@/modules/organization/utils/DocTemplatesVariablesByType';

    export default {
        components: {
            DocTemplatesManagerEditorHeader,
            DocTemplatesManagerHeader,
            DocTemplatesManagerWordFile,
            MEmptyIndex,
            HtmlEditor,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            docTemplates: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                nurseryTemplates: [],
                otherNurseriesTemplates: [],
                selectedTemplate: {},
                decodedContent: null,
                file: null,
                saving: false,
                uploading: false,
                editorLoading: false,
                editorVariables: [
                    'nursery_variables',
                    'kid_variables',
                    'contract_variables',
                    'staff_contract_variables',
                    'billing_variables',
                    'caf_variables',
                    'deposit_variables',
                    'transaction_variables',
                    'company_variables',
                    'family1_variables',
                    'family2_variables',
                    'sepa_mandate_variables',
                    'other_variables',
                ],
            };
        },
        computed: {
            templateExists() {
                return this.selectedTemplate && this.selectedTemplate.id !== null;
            },
            sortedTemplates() {
                return _sortBy(this.nurseryTemplates, 'name');
            },
        },
        watch: {
            'selectedTemplate.id': function (val) {
                const template = this.nurseryTemplates.find(item => item.id === val);
                if (template) {
                    this.switchTemplate(template);
                }
            },
        },
        mounted() {
            this.getTemplates();
        },
        methods: {
            docTemplatesManagerModalCreate() {
                return useModal({
                    component: DocTemplatesManagerModalCreate,
                    listeners: modal => ({
                        saved: async newTemplate => {
                            await this.switchTemplate(newTemplate);
                            modal.hide();
                        },
                    }),
                });
            },

            docTemplatesManagerModalDuplicate() {
                return useModal({
                    component: DocTemplatesManagerModalDuplicate,
                    props: {
                        selectedTemplate: this.selectedTemplate,
                    },
                    listeners: modal => ({
                        duplicated: async newTemplate => {
                            await this.switchTemplate(newTemplate);
                            await this.saveTemplate();
                            modal.hide();
                        },
                    }),
                });
            },

            // Implement decodedContent variable on each input event.
            setDecodedContent(content) {
                this.decodedContent = content;
            },

            getTemplates() {
                this.nurseryTemplates = [];
                this.otherNurseriesTemplates = [];

                this.docTemplates.forEach(item => {
                    const template = _cloneDeep(item);
                    const nurseryHasTemplate = this.nursery.docTemplates.find(doc => doc.id === item.id);
                    if (nurseryHasTemplate) {
                        this.nurseryTemplates.push(template);
                    } else {
                        this.otherNurseriesTemplates.push(template);
                    }
                });
                this.nurseryTemplates[0] ? this.switchTemplate(this.nurseryTemplates[0]) : this.switchTemplate(null);
            },

            async uploadFile() {
                if (!this.file) {
                    await this.saveTemplate();

                    return;
                }

                this.uploading = true;
                await upload(this.file)
                    .then(async response => {
                        this.selectedTemplate.file = {
                            key: response.key,
                            hash: response.hash,
                            name: this.file.name,
                        };

                        await this.saveTemplate();
                    })
                    .finally(() => {
                        this.uploading = false;
                    });
            },

            async saveTemplate() {
                this.saving = true;
                // Replace content by decoded content.
                this.selectedTemplate.content = this.decodedContent;
                const data = _cloneDeep(this.selectedTemplate);
                if (data.format === 'html') {
                    if (data.content) {
                        // Replace Tailwind variables added after a copy / paste
                        data.content = data.content.replace(/--tw-[\S]+:[^;]*;/g, '');
                    }
                    // Encode to base64
                    data.content = window.btoa(unescape(encodeURIComponent(data.content)));
                }

                if (data.id) {
                    await useApi()
                        .legacy.put(
                            route('docTemplates.update', {
                                nurseries: this.nursery.id,
                                docTemplate: data.id,
                            }),
                            data
                        )
                        .then(response => {
                            const nurseryTemplate = this.docTemplates.find(item => item.id === response.data.id);
                            if (nurseryTemplate) {
                                const index = this.docTemplates.indexOf(nurseryTemplate);
                                set(this.docTemplates, index, response.data);
                            }

                            const templateFromList = this.nurseryTemplates.find(item => item.id === response.data.id);
                            if (templateFromList) {
                                const index = this.nurseryTemplates.indexOf(templateFromList);
                                set(this.nurseryTemplates, index, response.data);
                            }

                            const templateFromNursery = this.nursery.docTemplates.find(
                                item => item.id === response.data.id
                            );
                            if (templateFromNursery) {
                                const index = this.nursery.docTemplates.indexOf(templateFromNursery);
                                set(this.nursery.docTemplates, index, response.data);
                            }

                            this.selectedTemplate = response.data;
                            useNotification().success(__('organization:document_template_updated'));
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        })
                        .finally(() => {
                            this.saving = false;
                        });
                } else {
                    await useApi()
                        .legacy.post(route('docTemplates.store', {nurseries: this.nursery.id}), data)
                        .then(response => {
                            this.docTemplates.push(_cloneDeep(response.data));
                            this.selectedTemplate.id = response.data.id;
                            this.attachTemplate(response.data);
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        })
                        .finally(() => {
                            this.saving = false;
                        });
                }
            },

            attachTemplate(template) {
                useApi()
                    .legacy.post(
                        route('nurseries.docTemplates.attach', {
                            nurseries: this.nursery.id,
                            docTemplate: template.id,
                        })
                    )
                    .then(response => {
                        this.nursery.docTemplates.push(response.data);

                        this.nurseryTemplates.push(response.data);

                        const docToDelete = this.otherNurseriesTemplates.find(item => item.id === template.id);
                        if (docToDelete) {
                            const index = this.otherNurseriesTemplates.indexOf(docToDelete);
                            this.otherNurseriesTemplates.splice(index, 1);
                        }

                        this.switchTemplate(template);
                        useNotification().success(__('organization:document_template_created'));
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            detachTemplate() {
                useMagicModal().confirmationModal({
                    title: __('organization:detach_document_template'),
                    text: __('organization:detach_document_template_ask'),
                    confirmButtonText: __('common:actions.detach'),
                    onConfirm: async () => {
                        if (this.selectedTemplate.id) {
                            await useApi()
                                .legacy.post(
                                    route('nurseries.docTemplates.detach', {
                                        nurseries: this.nursery.id,
                                        docTemplate: this.selectedTemplate.id,
                                    })
                                )
                                .then(response => {
                                    this.removeTemplateFromUI(this.selectedTemplate);
                                    useNotification().success(__('organization:document_template_detached'));
                                    this.otherNurseriesTemplates.push(response.data);
                                })
                                .catch(error => {
                                    if (error?.response?.status === 422) {
                                        _forEach(error.response.data.errors, function (value) {
                                            useNotification().error(_head(value));
                                        });
                                    } else {
                                        useNotification().error(error);
                                    }
                                });
                        } else {
                            await this.removeTemplateFromUI(this.selectedTemplate);
                            useNotification().success(__('organization:document_template_detached'));
                        }
                    },
                });
            },

            removeTemplate() {
                useMagicModal().deleteConfirmationModal({
                    title: __('organization:delete_document_template_ask'),
                    text: __('organization:document_template_will_be_deleted_on_all_organizations'),
                    onConfirm: async () => {
                        await useApi()
                            .legacy.delete(
                                route('docTemplates.destroy', {
                                    docTemplate: this.selectedTemplate.id,
                                })
                            )
                            .then(() => {
                                const docToDeleteFromGlobal = this.docTemplates.find(
                                    item => item.id === this.selectedTemplate.id
                                );
                                if (docToDeleteFromGlobal) {
                                    const index = this.docTemplates.indexOf(docToDeleteFromGlobal);
                                    this.docTemplates.splice(index, 1);
                                }
                                this.removeTemplateFromUI(this.selectedTemplate);
                                useNotification().success(__('organization:document_template_deleted'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            removeTemplateFromUI(selectedTemplate) {
                const template = this.nurseryTemplates.find(item => item.id === selectedTemplate.id);
                if (template && typeof template !== 'undefined') {
                    const index = this.nurseryTemplates.indexOf(template);
                    this.nurseryTemplates.splice(index, 1);
                    this.nurseryTemplates[0]
                        ? this.switchTemplate(this.nurseryTemplates[0])
                        : this.switchTemplate(null);

                    const nurseryTemplate = this.nursery.docTemplates.find(item => item.id === template.id);
                    if (nurseryTemplate) {
                        const index = this.nursery.docTemplates.indexOf(nurseryTemplate);
                        this.nursery.docTemplates.splice(index, 1);
                    }
                } else {
                    this.nurseryTemplates[0]
                        ? this.switchTemplate(this.nurseryTemplates[0])
                        : this.switchTemplate(null);
                }
            },

            importTemplate() {
                const options = this.otherNurseriesTemplates.reduce((acc, item) => {
                    if (item.id) acc.push({value: item.id, text: item.name});
                    return acc;
                }, []);

                useMagicModal().confirmationWithSelectModal({
                    title: __('organization:import_document_template_ask'),
                    options: options,
                    defaultOption: options[0].value,
                    onConfirm: async result => {
                        if (result) {
                            const template = this.otherNurseriesTemplates.find(item => item.id === result);

                            if (template) {
                                await this.attachTemplate(template);
                            }
                        }
                    },
                });
            },

            async switchTemplate(template) {
                this.editorLoading = true;
                if (template) {
                    this.editorVariables = this.getVariablesForDocumentType(template.type);
                    this.selectedTemplate = _cloneDeep(template);
                } else {
                    this.editorVariables = this.getVariablesForDocumentType();
                    this.selectedTemplate = {};
                }

                // On force le reload du HtmlEditor pour forcer le rendu
                // On attend bien que le HtmlEditor soit chargé avant de continuer
                await new Promise(resolve => {
                    setTimeout(() => {
                        this.editorLoading = false;
                        resolve();
                    }, 400);
                });
            },

            getVariablesForDocumentType(docType) {
                let documentVariables = [];
                const docTypeVariables = DocTemplatesVariablesByType().find(item => item.type === docType);
                const docTypeDefaultVariables = DocTemplatesVariablesByType().find(item => item.type === 'default');

                if (docTypeVariables) {
                    documentVariables = docTypeVariables.variables;
                } else {
                    documentVariables = docTypeDefaultVariables.variables;
                }

                if (!showCAF()) {
                    documentVariables = documentVariables.filter(item => item !== 'caf_variables');
                }

                return documentVariables;
            },

            updateDestination(destination) {
                this.selectedTemplate.type = destination;
                this.switchTemplate(this.selectedTemplate);
            },
        },
    };
</script>

<style scoped>
    html,
    body,
    #editor {
        margin: 0;
        height: 100%;
        color: #333;
        font-family: 'Helvetica Neue', Arial, sans-serif;
    }

    code {
        color: #f66;
    }
</style>
