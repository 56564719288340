<script setup lang="ts">
    import {computed, inject, nextTick, ref} from 'vue';

    import {type defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const context = inject<ReturnType<typeof defineFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('MFloating must be used inside a MPopover');
    }

    const {
        panelId,
        isVisible,
        floatingStyles,
        setFloatingElement,
        isInjected,
        portal: usePortal,
        isPositioned,
        middlewareData,
        isReady,
        finalPlacement,
    } = context;

    const myFloatingEl = ref();

    setFloatingElement(myFloatingEl);

    function onAfterEnter() {
        nextTick(() => {
            isReady.value = true;
        });
    }

    function onAfterLeave() {
        isInjected.value = false;
        isReady.value = false;
    }

    const computedClass = computed(() => {
        const output = [] as string[];

        output.push(placementValues[finalPlacement.value]);

        return output;
    });

    const placementValues = {
        'top-start': 'tw-origin-bottom-left',
        'top': 'tw-origin-bottom',
        'top-end': 'tw-origin-bottom-right',
        'bottom-start': 'tw-origin-top-left',
        'bottom': 'tw-origin-top',
        'bottom-end': 'tw-origin-top-right',
        'left-start': 'tw-origin-top-right',
        'left': 'tw-origin-right',
        'left-end': 'tw-origin-bottom-right',
        'right-start': 'tw-origin-top-left',
        'right': 'tw-origin-left',
        'right-end': 'tw-origin-bottom-left',
    };
</script>

<template>
    <Teleport
        v-if="isInjected"
        :disabled="!usePortal"
        to="#overlay"
        :class="{'tw-contents': !usePortal}"
    >
        <div
            ref="myFloatingEl"
            class="MFloating"
            :class="{'MFloating--animated': isReady}"
            :style="{
                ...floatingStyles,
                visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible',
            }"
        >
            <transition
                name="floating"
                @after-enter="onAfterEnter"
                @after-leave="onAfterLeave"
            >
                <div
                    v-show="isVisible && isPositioned"
                    :class="[computedClass]"
                >
                    <slot />
                </div>
            </transition>
        </div>
    </Teleport>
</template>

<style scoped>
    .MFloating {
        z-index: 3060;
    }

    .MFloating--animated {
        transition: transform 0.65s cubic-bezier(0.43, 0.33, 0.14, 1.01) 0s;
    }

    .floating-enter-active {
        transition:
            opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
            transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
    }

    .floating-enter-from {
        transform: scale(0.85);
        opacity: 0;
    }

    .floating-enter-to,
    .floating-leave-from {
        transform: scale(1);
        opacity: 1;
    }

    .floating-leave-active {
        transition:
            opacity 0.2s ease-in,
            transform 0.2s ease-in;
    }

    .floating-leave-to {
        transform: scale(0.95);
        opacity: 0;
    }

    .ContextualPopover-animate {
        transform: scale(0.85);
        opacity: 0;
        transition:
            opacity 0.25s cubic-bezier(0, 1, 0.4, 1),
            transform 0.25s cubic-bezier(0.18, 1.25, 0.4, 1);
    }

    .ContextualPopover-animate-exiting {
        transform: scale(0.95);
        transition:
            opacity 0.2s ease-in,
            transform 0.2s ease-in;
    }

    .ContextualPopover-animate-entered,
    .ContextualPopover-animate-entering,
    .ContextualPopover-noAnimation {
        transform: scale(1);
        opacity: 1;
    }
</style>
