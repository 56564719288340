<template>
    <CInline
        align-y="center"
        space="md"
    >
        <div
            class="tw-relative tw-cursor-pointer"
            :class="wrapperBaseClass"
            @click.prevent="click"
        >
            <input
                :id="id"
                :checked="modelValue"
                class="tw-hidden"
                type="checkbox"
            />
            <div
                class="tw-rounded-full tw-bg-gray-200 tw-shadow-inner"
                :class="lineBaseClass"
            />
            <div
                class="tw-absolute tw-inset-y-0 tw-left-0 tw--m-1 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow-sm tw-transition tw-duration-200 focus:tw-ring"
                :class="dotBaseClass"
                tabindex="0"
                @keypress.space="click"
            >
                <FontAwesomeIcon
                    v-if="icon"
                    :icon="'fa-solid ' + iconBaseClass"
                />
            </div>
        </div>
        <MLabel :for="id">
            <slot />
        </MLabel>
    </CInline>
</template>

<script lang="ts">
    import {Str} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            modelValue: {type: Boolean as PropType<boolean>, default: false},
            halfChecked: {type: Boolean, default: false, required: false},
            disabled: {type: Boolean, default: false, required: false},
            icon: {type: Boolean, default: true, required: false},
            size: {type: String, default: 'default', required: false},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const id = Str.uuid();
            const wrapperBaseClass = computed(() => {
                const output = [] as string[];

                if (props.disabled) {
                    output.push('tw-opacity-50');
                }

                return output;
            });

            const lineBaseClass = computed(() => {
                const output = [] as string[];

                const sizes = {
                    default: 'tw-h-4 tw-w-10',
                    lg: 'tw-h-6 tw-w-12',
                };
                output.push(sizes[props.size]);

                return output;
            });

            const dotBaseClass = computed(() => {
                const output = [] as string[];

                if (props.modelValue) {
                    output.push('tw-translate-x-full !tw-bg-blue-500');
                }

                const sizes = {
                    default: 'tw-w-6 tw-h-6',
                    lg: 'tw-w-8 tw-h-8',
                };
                output.push(sizes[props.size]);

                return output;
            });

            const iconBaseClass = computed(() => {
                const output = [] as string[];

                const sizes = {
                    default: {
                        checked: 'fa-check tw-text-white tw-text-sm',
                        halfchecked: 'fa-minus tw-text-white tw-text-sm',
                        unchecked: 'fa-times tw-text-gray-500 tw-text-sm',
                    },
                    lg: {
                        checked: 'fa-check tw-text-white tw-text-sm',
                        halfchecked: 'fa-minus tw-text-white tw-text-sm',
                        unchecked: 'fa-times tw-text-gray-500 tw-text-base',
                    },
                };
                output.push(
                    sizes[props.size][props.halfChecked ? 'halfchecked' : props.modelValue ? 'checked' : 'unchecked']
                );

                return output;
            });

            const click = function () {
                if (!props.disabled) {
                    emit('update:modelValue', !props.modelValue);
                }
            };

            return {
                wrapperBaseClass,
                lineBaseClass,
                dotBaseClass,
                iconBaseClass,
                id,
                click,
            };
        },
    });
</script>

<style scoped></style>
