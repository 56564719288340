<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed, toRef} from 'vue';

    import useHumanizePeriod from '@/modules/app/composables/useHumanizePeriod';
    import __ from '@/modules/app/utils/i18n-facade';
    import MDatePickerContent from '@/modules/meeko-ui/components/MDatePicker/MDatePickerContent.vue';
    import MDatePickerInput from '@/modules/meeko-ui/components/MDatePicker/MDatePickerInput.vue';
    import type {OutputValueType} from '@/modules/meeko-ui/composables/useMDatePicker';
    import useMDatePicker from '@/modules/meeko-ui/composables/useMDatePicker';

    const props = defineProps({
        modelValue: {type: [String, Object] as PropType<OutputValueType>, default: undefined},
        /**
         * @values dd/MM/yyyy, dd/MM/yyyy HH:mm, iso8601
         */
        format: {
            type: String,
            default: 'iso8601',
        },
        allowUndefined: {
            type: Boolean,
            default: false,
        },
        withTime: {
            type: Boolean,
            default: false,
        },
        buttonSize: {
            type: String,
            default: 'base',
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        buttonClass: {
            type: String,
            default: '',
        },
    });

    const store = useMDatePicker(
        toRef(props, 'modelValue'),
        toRef(props, 'format'),
        toRef(props, 'allowUndefined'),
        toRef(props, 'withTime'),
        {
            from: epoch => epoch?.startOfDay(),
            to: epoch => epoch?.endOfDay(),
        }
    );

    const {isInputEditable, dates} = store;

    const emit = defineEmits<{
        (e: 'update:modelValue', value: OutputValueType): void;
    }>();

    const dateAsJSDate = computed(() => {
        return {from: dates.value.from?.toJSDate(), to: dates.value.to?.toJSDate()};
    });

    const {toHuman} = useHumanizePeriod(dateAsJSDate, __('components:select_period') ?? '');
</script>

<template>
    <MPopover2 class="MDatePeriodFilter">
        <MPopover2ClickableReference>
            <MButton
                :class="buttonClass"
                icon-menu
                class="tw-w-full"
                :size="buttonSize"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-duotone fa-calendar-range" />
                </template>
                {{ toHuman }}
            </MButton>
        </MPopover2ClickableReference>
        <MPopover2Panel
            v-slot="{close}"
            @closed="isInputEditable = false"
        >
            <div class="tw-flex tw-flex-col tw-gap-4">
                <MDatePickerInput
                    :has-error="hasError"
                    :store="store"
                    @hide="close"
                />
                <MDatePickerContent
                    :allow-undefined="allowUndefined"
                    :store="store"
                    @update:model-value="emit('update:modelValue', $event)"
                />
            </div>
        </MPopover2Panel>
    </MPopover2>
</template>

<style scoped>
    .MDatePeriodFilter {
        display: inline-block;
    }
</style>
