<template>
    <div class="About__container">
        <div class="About__content">
            <h1 class="About__title">
                <span class="About__meeko-logo">meeko</span>
                Tech Team
            </h1>
            <div class="About__team">
                <div
                    v-for="(staff, i) in sortedTeam"
                    :key="i"
                    class="About__staff"
                >
                    <div class="About__emoji">
                        <span>{{ staff.emoji }}</span>
                    </div>
                    <div>
                        <span class="About__name">{{ staff.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';

    const team = ref([
        {name: 'Damien', emoji: '🦁'},
        {name: 'Justine', emoji: '👀'},
        {name: 'Alexandre', emoji: '😁'},
        {name: 'Romain', emoji: '🫡'},
        {name: 'Pierre', emoji: '🙈'},
    ]);

    const sortedTeam = ref(team.value.sort((a, b) => a.name.localeCompare(b.name)));
</script>

<style scoped>
    .About__container {
        @apply tw-flex tw-h-screen tw-justify-center;

        &::before {
            z-index: -1;
            content: '';
            @apply tw-fixed tw-inset-0 tw-bg-[#282b37];
        }
    }

    .About__content {
        @apply tw-m-auto tw-py-4;
    }

    .About__title {
        @apply tw-mb-4 tw-font-display tw-text-3xl tw-font-normal tw-text-white;
    }

    .About__meeko-logo {
        @apply tw-pr-2 tw-font-grandhotel tw-text-[36px];
    }

    .About__team {
        @apply tw-flex tw-flex-col tw-gap-4 tw-font-display tw-text-lg;
    }

    .About__staff {
        @apply tw-flex tw-w-96 tw-items-center tw-gap-4 tw-p-4;
        @apply tw-rounded-xl tw-bg-white tw-shadow-md tw-ring-1 tw-ring-black/10;
    }

    .About__name {
        @apply tw-block tw-text-lg tw-font-medium;
    }

    .About__role {
        @apply tw-block tw-text-sm tw-text-gray-500;
    }

    .About__emoji {
        @apply tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-lg tw-bg-gray-300;
        @apply tw-text-4xl tw-font-semibold tw-text-gray-700;
    }
</style>
