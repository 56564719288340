import {Model} from '@meekohq/lumos';

import RuleModel from '@/modules/calendar/models/RuleModel';

export default class RulesetModel extends Model {
    public type = 'calendar/rulesets';

    public attributes: {
        id: string;
        account_id: string | undefined;
        name: string | undefined;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        name: undefined,
        created_at: undefined,
        updated_at: undefined,
    };

    rules() {
        return this.hasMany('rules', RuleModel, 'ruleset_id');
    }
}
