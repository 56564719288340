import {ServiceProvider} from '@meekohq/lumos';

import {AxiosApiBinding} from '@/modules/app/utils/AxiosApiBinding';
import {AxiosLegacyApiBinding} from '@/modules/app/utils/AxiosLegacyApiBinding';
import {BroadcastContractBinding} from '@/modules/app/utils/BroadcastContract';
import PusherBroadcast from '@/modules/app/utils/PusherBroadcast';

export default class BroadcastServiceProvider extends ServiceProvider {
    public register() {
        this.app.singleton(BroadcastContractBinding, app => {
            const broadcast = new PusherBroadcast();

            broadcast.setAxiosSessionId(app.make(AxiosApiBinding));
            broadcast.setAxiosSessionId(app.make(AxiosLegacyApiBinding));

            return broadcast;
        });
    }
}
