import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {GetAllocationsForInvoiceUseCase} from '@/modules/cashier/payment/application/GetAllocationsForInvoiceUseCase';
import type {InvoiceAllocationAggregatePort} from '@/modules/cashier/payment/application/ports/InvoiceAllocationAggregatePort';
import type {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
import type {TransactionCreditAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';

export class GetAllocationsForInvoiceInteractor implements GetAllocationsForInvoiceUseCase {
    private readonly _invoiceAllocationsAggregateRepository: InvoiceAllocationAggregatePort;

    constructor(invoiceAllocationsAggregateRepository: InvoiceAllocationAggregatePort) {
        this._invoiceAllocationsAggregateRepository = invoiceAllocationsAggregateRepository;
    }

    public async fetch(
        payment: PaymentModel
    ): Promise<(CreditNoteAllocationAggregate | TransactionCreditAllocationAggregate)[]> {
        return this._invoiceAllocationsAggregateRepository.getAllocations(payment);
    }
}
