import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';

import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import Manager from '@/modules/legacy/store/manager.store';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class EventTypeOrganizationModel extends Model {
    public type = 'hr/calendar/event_types_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        event_type_id: string | undefined;
        organization_id: string | undefined;
        archived_at: string | null;
    } = {
        id: this.uuid(),
        account_id: undefined,
        event_type_id: undefined,
        organization_id: undefined,
        archived_at: null,
    };

    eventType() {
        return this.belongsTo('eventType', EventTypeModel, 'event_type_id');
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }

    scopeActive(query: QueryBuilder<any>) {
        query.where('organization_id', Manager.activeOrganization.id);
    }
}
