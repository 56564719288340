import {join, split} from 'lodash-es';

import type {SerializerType} from '@/modules/app/composables/useMagicFilter';

export default {
    read: async (value: string) => {
        return split(value, ',');
    },
    write: (value: string[]) => {
        return join(value, ',');
    },
} as SerializerType;
