<template>
    <div class="MStepPanelDivider">
        <svg
            class="MStepPanelDivider__arrow"
            fill="none"
            preserveAspectRatio="none"
            viewBox="0 0 22 80"
        >
            <path
                d="M0 -2L20 40L0 82"
                stroke="currentcolor"
                stroke-linejoin="round"
                vector-effect="non-scaling-stroke"
            />
        </svg>
    </div>
</template>

<style scoped>
    .MStepPanelDivider {
        @apply tw-absolute tw-right-0 tw-top-0 tw-hidden tw-h-full tw-w-5 md:tw-block;
    }

    .MStepPanelDivider__arrow {
        @apply tw-h-full tw-w-full tw-text-gray-200;
    }
</style>
