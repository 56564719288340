<template>
    <CModal
        ref="modal"
        :header-title="__('family_kid:add_kid')"
        size="md"
        :visible="visible"
        @hidden="$emit('hidden')"
    >
        <CreateKidForm
            ref="form"
            :family-model="familyModel"
            :organization-model="organizationModel"
            @created="onKidCreated"
            @loading="loading = $event"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="modal?.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loading"
                variant="primary"
                @click="form?.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script setup lang="ts">
    import type {PropType} from 'vue';
    import {ref} from 'vue';

    import CreateKidForm from '@/modules/family/components/kid/CreateKidForm.vue';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import type KidModel from '@/modules/family/models/KidModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    defineProps({
        organizationModel: {type: Object as PropType<OrganizationModel>, required: true},
        familyModel: {type: Object as PropType<FamilyModel>, default: undefined},
        visible: {type: Boolean, default: false},
    });

    const emit = defineEmits<{
        (e: 'created', value: KidModel): void;
        (e: 'hidden'): void;
    }>();

    defineExpose({
        show,
        hide,
    });

    const modal = ref<{show: () => void; hide: () => void}>();
    const form = ref<{save: () => void}>();
    const loading = ref(false);

    function onKidCreated(kidModel: KidModel) {
        hide();

        emit('created', kidModel);
    }

    function show() {
        modal.value?.show();
    }

    function hide() {
        modal.value?.hide();
    }
</script>
