import {ValidationError} from '@meekohq/lumos';

import type {KidCopyInputPort} from '@/modules/family/domain/ports/KidCopyInputPort';
import type {KidCopyOutputPort} from '@/modules/family/domain/ports/KidCopyOutputPort';
import type {KidCopyRepositoryPort} from '@/modules/family/domain/ports/KidCopyRepositoryPort';

export class KidCopyInteractor implements KidCopyInputPort {
    constructor(
        private readonly repository: KidCopyRepositoryPort,
        private readonly presenter: KidCopyOutputPort
    ) {}

    async execute(kidId: string, organizationId: string): Promise<unknown> {
        try {
            const newKidId = await this.repository.copyKid(kidId, organizationId);
            return this.presenter.success(newKidId);
        } catch (error) {
            if (error instanceof ValidationError) {
                return this.presenter.validationError(error as ValidationError);
            }

            return this.presenter.unexpectedError(error);
        }
    }
}
