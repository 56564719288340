<template>
    <div class="month tw-mx-4 tw-mb-4">
        <span
            class="tw-text-2xl"
            :class="{'tw-ml-[30px]': withWeekNumber && withWeekNumber !== false}"
        >
            {{ monthReadable }}
        </span>
        <div class="tw-flex">
            <div v-if="withWeekNumber">
                <template
                    v-for="(week, i) in weekNumbers"
                    :key="'week' + i"
                >
                    <div class="week-number tw-flex tw-items-center tw-justify-center tw-text-sm">
                        <span class="tw-capitalize tw-text-gray-400">{{ week }}</span>
                    </div>
                </template>
            </div>
            <div>
                <div class="tw-flex">
                    <template
                        v-for="(day, i) in weekDays"
                        :key="'dayA' + i"
                    >
                        <div class="week-day tw-flex tw-items-center tw-justify-center tw-text-base">
                            <span class="tw-capitalize tw-text-gray-400">{{ day[0] }}</span>
                        </div>
                    </template>
                </div>
                <div class="days tw-flex tw-flex-wrap tw-rounded-lg">
                    <template
                        v-for="day in offsetBefore"
                        :key="day.format('YYYY-MM-DD')"
                    >
                        <div class="day out-of-bounds">
                            <div class="day-item tw-flex tw-items-center tw-justify-center" />
                        </div>
                    </template>
                    <template
                        v-for="day in days"
                        :key="day.format('YYYY-MM-DD')"
                    >
                        <div
                            :id="day.format('YYYY-MM-DD')"
                            class="day"
                            :class="computedClass(filteredRules(day))"
                            @click="handleDayClicked(day)"
                        >
                            <MTooltip
                                v-if="filteredRules(day).length"
                                class="day-item tw-flex tw-items-center tw-justify-center"
                            >
                                <span class="tw-text-sm">{{ day.format('D') }}</span>
                                <template #content>
                                    <div v-html="tooltip(filteredRules(day))" />
                                </template>
                            </MTooltip>
                            <div
                                v-else
                                class="day-item tw-flex tw-items-center tw-justify-center"
                            >
                                <span class="tw-text-sm">{{ day.format('D') }}</span>
                            </div>
                        </div>
                    </template>
                    <template
                        v-for="day in offsetAfter"
                        :key="day.format('YYYY-MM-DD')"
                    >
                        <div class="day out-of-bounds">
                            <div class="day-item tw-flex tw-items-center tw-justify-center" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import _isArray from 'lodash-es/isArray';
    import moment from 'moment';
    import {extendMoment} from 'moment-range';

    export default {
        props: ['rules', 'withWeekNumber', 'enableClick', 'month', 'equalMonths'],
        emits: ['dayClicked'],
        computed: {
            monthReadable() {
                return this.month.format('MMMM');
            },
            days() {
                const start = this.month.clone().startOf('month');
                const end = this.month.clone().endOf('month');
                const range = extendMoment(moment).range(start, end);

                return Array.from(range.by('day'));
            },
            offsetBefore() {
                const startOfMonth = this.month.clone().startOf('month');
                const startOfWeek = startOfMonth.clone().startOf('week');

                if (startOfWeek.isSame(startOfMonth, 'day')) {
                    return [];
                }

                const range = extendMoment(moment).range(startOfWeek, startOfMonth.subtract(1, 'days'));

                return Array.from(range.by('day'));
            },
            offsetAfter() {
                const endOfMonth = this.month.clone().endOf('month');
                let endOfWeek = endOfMonth.clone().endOf('week');

                if (this.equalMonths) {
                    const offset = 42 - this.offsetBefore.length - this.days.length;
                    endOfWeek = endOfMonth.clone().add(offset, 'days');
                }

                if (endOfWeek.isSame(endOfMonth, 'day')) {
                    return [];
                }

                const range = extendMoment(moment).range(endOfMonth.add(1, 'days'), endOfWeek);

                return Array.from(range.by('day'));
            },
            weekDays() {
                const range = extendMoment(moment).range(moment().startOf('week'), moment().endOf('week'));
                const days = Array.from(range.by('day'));

                return days.map(m => m.format('dd'));
            },
            weekNumbers() {
                const startWeek = this.month.clone().startOf('month').startOf('week');
                const endWeek = this.month.clone().endOf('month').add(this.offsetAfter.length, 'days');

                const range = extendMoment(moment).range(startWeek, endWeek);
                const weeks = Array.from(range.by('week'));

                return weeks.map(m => m.format('w'));
            },
        },
        methods: {
            handleDayClicked(day) {
                this.$emit('dayClicked', day);
            },
            filteredRules(day) {
                return this.rules.filter(rule => {
                    return rule.day.format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
                });
            },
            tooltip(rules) {
                const tooltips = [];
                _forEach(rules, rule => {
                    tooltips.push(rule.tooltip);
                });

                return this.implode('<br>', tooltips);
            },
            implode(delimiter, collection) {
                const glue = delimiter || '';

                if (!_isArray(collection)) {
                    return false;
                }

                if (collection.length === 1) {
                    return collection[0];
                }

                let returnString = '';
                let i = 0;
                const len = collection.length;
                for (; i < len; i++) {
                    if (i !== 0) {
                        returnString += glue;
                    }
                    returnString += collection[i];
                }

                return returnString;
            },
            computedClass(rules) {
                let output = '';

                if (!rules.length) {
                    output += ' out-of-bounds';
                } else {
                    if (this.enableClick) {
                        output += ' clickable';
                    }
                    _forEach(rules, rule => {
                        output += ' ' + rule.class;
                    });
                }

                return output;
            },
        },
    };
</script>

<style scoped>
    .month {
        width: 240px;
    }

    .ml-30 {
        margin-left: 30px;
    }

    .week-day {
        width: calc(100% / 7);
        height: 30px;
    }

    .week-number {
        width: 30px;
        height: 30px;

        &:first-of-type {
            margin-top: 30px;
        }
    }

    .days {
        .offset-day {
            width: calc(100% / 7);
            height: 30px;
        }
    }

    .day {
        z-index: 0;
        cursor: default;
        background-color: #c1e1ff;
        width: calc(100% / 7);
        height: 30px;

        &:hover {
            z-index: 1;
        }

        &:nth-child(1) {
            border-top-left-radius: 4px;
        }

        &:nth-child(7) {
            border-top-right-radius: 4px;
        }

        &:nth-last-child(7) {
            border-bottom-left-radius: 4px;
        }

        &:nth-last-child(1) {
            border-bottom-right-radius: 4px;
        }
    }

    .day-item {
        width: 100%;
        height: 100%;
    }

    .clickable {
        cursor: pointer;

        .day-item {
            transform: scale(1);
            transition: transform 100ms ease-in-out;

            &:hover {
                transform: scale(1.2);
                border-radius: 4px;
                background-color: #007bff !important;
                color: white !important;
            }
        }

        &:hover {
            opacity: 1 !important;
        }
    }

    .out-of-bounds {
        opacity: 0.5;
        background-color: #e2f2ff;
        color: #7791a7;
    }

    .bg-success-day {
        background-color: #05d80d8a;
    }

    .bg-secondary-day {
        background-color: #e83e8cb5;
    }
</style>
