<template>
    <CTabMenu variant="pink">
        <CTabMenuItem
            :to="{name: 'humanresources/settings.common'}"
            variant="pink"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-cog"
            />
            {{ __('common:general') }}
        </CTabMenuItem>
        <CTabMenuItem
            :to="{name: 'humanresources/settings.calendar'}"
            variant="pink"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-regular fa-calendar-alt"
            />
            {{ __('common:calendar') }}
        </CTabMenuItem>
    </CTabMenu>
</template>
