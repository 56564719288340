<template>
    <MForm>
        <MHeading
            class="tw-border-b tw-border-gray-200 tw-uppercase"
            level="h4"
            variant="blue"
        >
            {{ __('common:general') }}
        </MHeading>
        <CFormThreeColumns>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-venus-mars" />
                    </template>
                    {{ __('common:gender_one') }}
                </MLabel>
                <MSelectMenu
                    v-model="familyMember.gender"
                    :options="[
                        {gender: 'male', description: __('common:gender.mr')},
                        {gender: 'female', description: __('common:gender.ms')},
                    ]"
                    text-path="description"
                    value-path="gender"
                />
                <CFormErrorMessageList :errors="collect(errors.gender)" />
            </MFieldset>
            <MFieldset v-if="fromKid">
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-handshake" />
                    </template>
                    {{ __('family_member:kinship') }}
                </MLabel>
                <MSelectMenu
                    v-model="familyMember.role"
                    :options="relationshipOptions"
                    text-path="text"
                    value-path="value"
                />
                <CFormErrorMessageList :errors="collect(errors.role)" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                    </template>
                    {{ __('common:job') }}
                </MLabel>
                <MInput
                    v-model="familyMember.job"
                    :placeholder="__('common:job')"
                />
            </MFieldset>
            <CFormErrorMessageList :errors="collect(errors.job)" />
        </CFormThreeColumns>
        <CFormThreeColumns>
            <MFieldset>
                <MLabel>
                    {{ __('common:first_name') }}
                </MLabel>
                <MInput
                    v-model="familyMember.first_name"
                    :placeholder="__('common:first_name')"
                />
                <CFormErrorMessageList :errors="collect(errors.first_name)" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    {{ __('common:last_name') }}
                </MLabel>
                <MInput
                    v-model="familyMember.last_name"
                    :placeholder="__('common:last_name')"
                />
                <CFormErrorMessageList :errors="collect(errors.last_name)" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-birthday-cake" />
                    </template>
                    {{ __('common:birthdate') }}
                </MLabel>
                <CFormDatepicker
                    v-model="familyMember.birthdate"
                    format="YYYY-MM-DD"
                />
                <CFormErrorMessageList :errors="collect(errors.birthdate)" />
            </MFieldset>
        </CFormThreeColumns>
        <CFormTwoColumns v-if="showCAF(nursery.country)">
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-circle" />
                    </template>
                    {{ __('family_member:beneficiary_number_short') }}
                </MLabel>
                <MInput
                    v-model="familyMember.allocator_no"
                    :placeholder="__('family_member:beneficiary_number_short')"
                />
                <CFormErrorMessageList :errors="collect(errors.allocator_no)" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-house-user" />
                    </template>
                    {{ __('family_member:insurance_systems') }}
                </MLabel>
                <MSelectMenu
                    v-model="familyMember.insurance_code"
                    :options="insuranceCodeOptions"
                    text-path="text"
                    value-path="value"
                />
                <CFormErrorMessageList :errors="collect(errors.insurance_code)" />
            </MFieldset>
        </CFormTwoColumns>
        <MHeading
            class="tw-mt-4 tw-border-b tw-border-gray-200 tw-uppercase"
            level="h4"
            variant="blue"
        >
            {{ __('common:contact') }}
        </MHeading>
        <MFieldset>
            <MLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-solid fa-at" />
                </template>
                {{ __('common:email_address') }}
            </MLabel>
            <MInput
                v-model="familyMember.email"
                :placeholder="__('common:email')"
            />
            <CFormErrorMessageList :errors="collect(errors.email)" />
        </MFieldset>
        <CFormThreeColumns>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-phone" />
                    </template>
                    {{ __('common:phone.landline') }}
                </MLabel>
                <MInput
                    v-model="familyMember.phone"
                    :placeholder="__('common:phone.landline')"
                />
                <CFormErrorMessageList :errors="collect(errors.phone)" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-mobile-alt" />
                    </template>
                    {{ __('common:phone.cellular') }}
                </MLabel>
                <MInput
                    v-model="familyMember.mobile_phone"
                    :placeholder="__('common:phone.cellular')"
                />
                <CFormErrorMessageList :errors="collect(errors.mobile_phone)" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-duotone fa-phone-office" />
                    </template>
                    {{ __('common:phone.work') }}
                </MLabel>
                <MInput
                    v-model="familyMember.office_phone"
                    :placeholder="__('common:phone.work')"
                />
                <CFormErrorMessageList :errors="collect(errors.office_phone)" />
            </MFieldset>
        </CFormThreeColumns>
        <MFieldset>
            <MLabel>
                {{ __('common:address.address') }}
            </MLabel>
            <MInput
                v-model="familyMember.line_1"
                :placeholder="__('common:address.address')"
            />
            <CFormErrorMessageList :errors="collect(errors.line_1)" />
        </MFieldset>
        <CFormThreeColumns>
            <MFieldset>
                <MLabel>
                    {{ __('common:address.city') }}
                </MLabel>
                <MInput
                    v-model="familyMember.city"
                    :placeholder="__('common:address.city')"
                />
                <CFormErrorMessageList :errors="collect(errors.city)" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    {{ __('common:address.zip_code') }}
                </MLabel>
                <MInput
                    v-model="familyMember.postal_code"
                    :placeholder="__('common:address.zip_code')"
                />
                <CFormErrorMessageList :errors="collect(errors.postal_code)" />
            </MFieldset>
            <MFieldset class="tw-min-w-0">
                <MLabel>
                    {{ __('common:address.country') }}
                </MLabel>
                <SelectCountry v-model="familyMember.country_code" />
                <CFormErrorMessageList :errors="collect(errors.country_code)" />
            </MFieldset>
        </CFormThreeColumns>
        <template v-if="showAuthorizations">
            <MHeading
                class="tw-mt-4 tw-border-b tw-border-gray-200 tw-uppercase"
                level="h4"
                variant="blue"
            >
                {{ __('common:authorizations') }}
            </MHeading>
            <CFormTwoColumns>
                <MFieldset>
                    <MemberPermissionsInput v-model="familyMember.permissions" />
                    <CFormErrorMessageList :errors="collect(errors.permissions)" />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-solid fa-hands-helping" />
                        </template>
                        {{ __('family_member:pick_up_child') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="familyMember.can_pickup_kid"
                        :options="[
                            {value: 1, text: __('common:yes')},
                            {value: 0, text: __('common:no')},
                        ]"
                        text-path="text"
                        value-path="value"
                    />
                    <CFormErrorMessageList :errors="collect(errors.can_pickup_kid)" />
                </MFieldset>
            </CFormTwoColumns>
        </template>
        <MFieldset class="tw-mt-4">
            <MLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-solid fa-pencil" />
                </template>
                {{ __('common:note_one') }}
            </MLabel>
            <MTextarea
                v-model="familyMember.note"
                :placeholder="__('common:note_one')"
                rows="2"
            />
            <CFormErrorMessageList :errors="collect(errors.note)" />
        </MFieldset>
    </MForm>
</template>

<script lang="ts">
    import {collect} from '@meekohq/lumos';
    import {computed, defineComponent, toRef} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import MemberPermissionsInput from '@/modules/family/components/member/MemberPermissionsInput.vue';
    import {useMemberRoleTranslations} from '@/modules/family/composables/member/useMemberRoleTranslations';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import {showCAF} from '@/modules/legacy/libs/organization';

    export default defineComponent({
        components: {MemberPermissionsInput, SelectCountry},
        props: {
            familyMember: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
            fromKid: {
                type: Boolean,
                required: true,
            },
            // Allows to hide form's authorisations section
            showAuthorizations: {
                type: Boolean,
                default: false,
            },
            errors: {
                type: Array,
                required: true,
            },
        },

        setup(props) {
            const gender = toRef(props.familyMember, 'gender');

            const {getRoleOptionsByGender} = useMemberRoleTranslations();

            const relationshipOptions = computed(() => {
                return getRoleOptionsByGender(gender.value);
            });

            const insuranceCodeOptions = [
                {
                    value: null,
                    text: __('family_member:insurance_systems.undefined'),
                },
                {
                    value: 1,
                    text: __('family_member:insurance_systems.general_system'),
                },
                {
                    value: 2,
                    text: __('family_member:insurance_systems.agricultural_system'),
                },
                {
                    value: 3,
                    text: __('family_member:insurance_systems.rsi'),
                },
                {
                    value: 4,
                    text: __('family_member:insurance_systems.sncf'),
                },
                {
                    value: 5,
                    text: __('family_member:insurance_systems.ratp'),
                },
                {
                    value: 8,
                    text: __('family_member:insurance_systems.military_system'),
                },
                {
                    value: 17,
                    text: __('family_member:insurance_systems.french_abroad_system'),
                },
                {
                    value: 91,
                    text: __('family_member:insurance_systems.mgen'),
                },
                {
                    value: 95,
                    text: __('family_member:insurance_systems.mnh'),
                },
                {
                    value: 99,
                    text: __('family_member:insurance_systems.other_systems'),
                },
            ];

            const dynamicComponent = computed(() => {
                if (showCAF(props.nursery.country)) {
                    return 'CFormThreeColumns';
                }

                return 'CFormTwoColumns';
            });

            return {
                dynamicComponent,
                relationshipOptions,
                insuranceCodeOptions,
            };
        },
        methods: {showCAF, collect},
    });
</script>
