<template>
    <CTabMenu
        class="tw-mb-2"
        variant="pink"
    >
        <CTabMenuItem
            v-if="$can('read', 'staffs_statistics')"
            :to="{name: 'humanresources/reports.statistics'}"
            variant="pink"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-file-chart-line"
            />
            {{ __('common:statistic_other') }}
        </CTabMenuItem>
        <CTabMenuItem
            v-if="$can('read', 'staffs_balances')"
            :to="{name: 'humanresources/reports.balances'}"
            variant="pink"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-stopwatch"
            />
            {{ __('common:balances') }}
        </CTabMenuItem>
        <CTabMenuItem
            v-if="$can('read', 'staffs_details') && $can('read', 'staffs_contracts')"
            :to="{name: 'humanresources/reports.register'}"
            variant="pink"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-ballot"
            />
            {{ __('hr:staff_registry') }}
        </CTabMenuItem>
    </CTabMenu>
</template>
