<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';

    import useAbility from '@/modules/app/composables/useAbility';
    import __ from '@/modules/app/utils/i18n-facade';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import type {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';
    import {useDetachCreditNote} from '@/modules/cashier/payment/infrastructure/components/useDetachCreditNote';
    import InvoiceTypeValue from '@/modules/cashier/utils/billing/invoice/InvoiceTypeValue';
    import MProgressBar from '@/modules/meeko-ui/components/MProgressBar.vue';

    const props = defineProps<{
        allocation: InvoiceAllocationAggregate;
        invoice: InvoiceModel;
        editingAllocations: boolean;
    }>();
    const emit = defineEmits<{(e: 'detached'): void}>();

    const {can} = useAbility();
    const {format} = useFormatCurrency(props.allocation.currencyIsoCode);

    async function onDetachButtonClicked() {
        const hasBeenDetached = await useDetachCreditNote(InvoiceTypeValue.invoice).detachCreditNote(
            props.allocation.allocation!.getKey(),
            props.allocation.sourceKey,
            props.allocation.destinationKey
        );

        if (hasBeenDetached) {
            emit('detached');
        }
    }
</script>

<template>
    <div class="PaymentAllocationsListItemInvoice">
        <div class="PaymentAllocationsListItemInvoice__column-header">
            <FontAwesomeIcon
                class="tw-text-3xl tw-text-green-500"
                icon="fa-solid fa-file-import"
            />
            <div class="tw-min-w-0">
                <MHeading
                    class="tw-truncate"
                    level="h4"
                >
                    {{ __('common:invoice_one') }}
                </MHeading>
                <MHeading>
                    {{ format(invoice.computed.unsigned_grand_total) }}
                </MHeading>
            </div>
        </div>
        <div class="PaymentAllocationsListItemInvoice__column-actions">
            <MMenu>
                <MMenuButton>
                    <MTooltip
                        :hoverable="editingAllocations"
                        :label="__('billing_transaction:save_breakdowns_first')"
                    >
                        <MButton
                            :disabled="editingAllocations"
                            icon-ellipsis
                            variant="ghost"
                        />
                    </MTooltip>
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        v-if="can('delete', 'transactions')"
                        :label="__('common:actions.detach_dots')"
                        variant="danger"
                        @click="onDetachButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon icon="fa-solid fa-link-slash" />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </div>
        <div class="PaymentAllocationsListItemInvoice__column-progress-bar">
            <div class="PaymentAllocationsListItemInvoice__progress-bar-label">
                {{ __('billing_transaction:remaining_amount_to_collect', {value: format(invoice.remaingAmount)}) }}
            </div>
            <MProgressBar
                :remaining="invoice.remaingAmount"
                :total="invoice.computed.unsigned_grand_total"
            />
        </div>
        <div class="PaymentAllocationsListItemInvoice__column-informations">
            <div class="PaymentAllocationsListItemInvoice__information">
                <FontAwesomeIcon
                    fixed-width
                    icon="fa-duotone fa-calendar"
                />
                {{ allocation.allocatableDate.toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
            <div class="PaymentAllocationsListItemInvoice__information">
                <FontAwesomeIcon
                    fixed-width
                    icon="fa-duotone fa-receipt"
                />
                {{ invoice.attributes.no }}
            </div>
        </div>
    </div>
</template>

<style scoped>
    .PaymentAllocationsListItemInvoice {
        @apply tw-grid tw-grid-cols-20 tw-items-center tw-gap-3 md:tw-grid-cols-24;
    }

    .PaymentAllocationsListItemInvoice__column-header {
        @apply tw-col-span-16 md:tw-col-span-9;
        @apply tw-flex tw-items-center tw-gap-3;
    }

    .PaymentAllocationsListItemInvoice__column-actions {
        @apply tw-col-span-4 md:tw-col-span-2;
        @apply md:tw-order-last;
        @apply tw-text-end;
    }

    .PaymentAllocationsListItemInvoice__column-progress-bar {
        @apply tw-col-span-10 md:tw-col-span-7;
    }

    .PaymentAllocationsListItemInvoice__progress-bar-label {
        @apply tw-mb-0.5 tw-text-sm tw-text-gray-500;
    }

    .PaymentAllocationsListItemInvoice__column-informations {
        @apply tw-col-span-10 md:tw-col-span-6;
    }

    .PaymentAllocationsListItemInvoice__information {
        @apply tw-truncate tw-text-sm tw-text-gray-500;
    }
</style>
