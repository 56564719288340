import type {ReportingSettingRepositoryPort} from '@/modules/meeko-family/domain/ports/ReportingSettingRepositoryPort';
import type {GetReportingSettingsInputPort} from '@/modules/meeko-family/domain/use-cases/GetReportingSettingsInputPort';
import type {GetReportingSettingsOutputPort} from '@/modules/meeko-family/domain/use-cases/GetReportingSettingsOutputPort';

export class GetReportingSettingsInteractor implements GetReportingSettingsInputPort {
    constructor(
        private readonly repository: ReportingSettingRepositoryPort,
        private readonly presenter: GetReportingSettingsOutputPort
    ) {}

    async execute(organizationId: string): Promise<unknown> {
        try {
            const settings = await this.repository.getReportingSettings(organizationId);

            return this.presenter.success(settings);
        } catch (error) {
            return this.presenter.error(error);
        }
    }
}
