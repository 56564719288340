import __ from '@/modules/app/utils/i18n-facade';
import {
    defaultCompanySocialCertificate,
    defaultContract,
    defaultEstimate,
    defaultFamilyMemberCard,
    defaultFormalNotice,
    defaultKidCard,
    defaultPackContract,
    defaultPackEstimate,
    defaultSepaMandate,
    defaultStaffCard,
    defaultTaxCertificate,
    defaultTransactionReceipt,
} from '@/modules/legacy/contents/defaultDocuments';

export interface MeekoTemplate {
    id: number | null;
    textId: string;
    name: string;
    selectorName: string;
    content: string | null;
    type: string;
    format: string;
}

export default function (): MeekoTemplate[] {
    const kidNameVariable = ' ${nom-de-l-enfant}';

    return [
        {
            id: 0,
            textId: 'word',
            name: __('common:document_one'),
            selectorName: __('organization:word_file_with_extension'),
            content: null,
            type: 'contract',
            format: 'word',
        },
        {
            id: 0,
            textId: 'new',
            name: __('organization:new_model_dots'),
            selectorName: __('organization:blank_model'),
            content: 'Tm91dmVhdSBtb2TDqGxl4oCm',
            type: 'contract',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-contract-pack',
            name: __('organization:host_contract') + kidNameVariable,
            selectorName: __('organization:template.meeko_contract_pack'),
            content: defaultPackContract,
            type: 'contract',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-contract-hour',
            name: __('organization:host_contract') + kidNameVariable,
            selectorName: __('organization:template.meeko_contract_hour'),
            content: defaultContract,
            type: 'contract',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-estimate-pack',
            name: __('common:quote') + kidNameVariable,
            selectorName: __('organization:template.meeko_estimate_pack'),
            content: defaultPackEstimate,
            type: 'contract',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-estimate-hour',
            name: __('common:quote') + kidNameVariable,
            selectorName: __('organization:template.meeko_estimate_hour'),
            content: defaultEstimate,
            type: 'contract',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-transaction-receipt',
            name: __('organization:transaction_receipt'),
            selectorName: __('organization:template.meeko_payment_receipt'),
            content: defaultTransactionReceipt,
            type: 'transaction',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-tax-certificate',
            name: __('common:tax_certificate_one') + kidNameVariable,
            selectorName: __('organization:template.meeko_tax_certificate'),
            content: defaultTaxCertificate,
            type: 'tax-certificate',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-company-social-certificate',
            name: __('organization:company_social_certificate'),
            selectorName: __('organization:template.meeko_company_social_certificate'),
            content: defaultCompanySocialCertificate,
            type: 'tax-certificate',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-formal-notice',
            name: __('organization:formal_notice') + kidNameVariable,
            selectorName: __('organization:template.meeko_formal_notice'),
            content: defaultFormalNotice,
            type: 'contract',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-kid-card',
            name: __('common:kid_page'),
            selectorName: __('organization:template.meeko_kid_card'),
            content: defaultKidCard,
            type: 'kid',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-staff-card',
            name: __('common:team_card'),
            selectorName: __('organization:template.meeko_staff_card'),
            content: defaultStaffCard,
            type: 'staff',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-family-member-card',
            name: __('common:parent_card'),
            selectorName: __('organization:template.meeko_family_member_card'),
            content: defaultFamilyMemberCard,
            type: 'family_member',
            format: 'html',
        },
        {
            id: 0,
            textId: 'meeko-sepa-mandate',
            name: __('common:sepa_mandate_one'),
            selectorName: __('organization:template.meeko_sepa_mandate'),
            content: defaultSepaMandate,
            type: 'sepa_mandate',
            format: 'html',
        },
    ];
}
