import type {ContractRepositoryPort} from '@/modules/human-resources/contract/application/ports/ContractRepositoryPort';
import {SaveContractResult} from '@/modules/human-resources/contract/application/SaveContractResult';
import type {SaveContractUseCase} from '@/modules/human-resources/contract/application/SaveContractUseCase';
import {ContractAggregate} from '@/modules/human-resources/contract/domain/ContractAggregate';
import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import type ContractModel from '@/modules/human-resources/models/ContractModel';
import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export class SaveContractInteractor implements SaveContractUseCase {
    constructor(private readonly contractRepository: ContractRepositoryPort) {}

    async save(
        staff: StaffModel,
        contract: ContractModel,
        trialPeriods: ContractTrialPeriodModel[] = [],
        balanceAllocations: BalanceAllocationModel[] = []
    ): Promise<SaveContractResult> {
        const contractAggregate = new ContractAggregate(staff, contract, trialPeriods, balanceAllocations);

        const results = await this.contractRepository.save(contractAggregate);

        return new SaveContractResult(results);
    }
}
