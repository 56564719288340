<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('common:my_organizations') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MButton
                v-if="user.is_owner"
                icon-plus
                :label="__('organization:add_organization')"
                variant="primary"
                @click="createOrganizationModal.show"
            />
        </template>
        <template #content>
            <MHeading
                class="tw-mb-4"
                level="h3"
            >
                {{ __('organization:to_start_select_one_organization_colon') }}
            </MHeading>
            <transition
                mode="out-in"
                name="fade"
            >
                <template v-if="!loader">
                    <div class="tw-flex tw-flex-col tw-gap-4">
                        <div
                            v-for="(nursery, i) in filtered"
                            :key="'nursery' + i"
                        >
                            <NurseriesItem :item="nursery" />
                        </div>
                        <MEmptyIndex
                            v-if="!filtered.length && user.is_owner"
                            :show-text="false"
                            :title="__('organization:empty_organization_message')"
                        />
                        <MEmptyIndex
                            v-else-if="!filtered.length && !user.is_owner"
                            :title="__('organization:no_access_title')"
                            :text="__('organization:no_access_text')"
                        />
                    </div>
                </template>
                <div
                    v-else
                    key="loader"
                >
                    <loader custom-class="la-2x" />
                </div>
            </transition>
            <AddOrganizationModal
                v-if="createOrganizationModal.isVisible.value"
                :modal="createOrganizationModal"
                @store:nursery="pushNursery"
            />
            <NurseryWizard
                ref="nurseryWizard"
                :user="user"
                @store-nursery="pushNursery"
            />
        </template>
    </SimplePageTemplate>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _sortBy from 'lodash-es/sortBy';
    import {nextTick} from 'vue';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import NurseryWizard from '@/modules/legacy/components/OldThemes/Wizards/SimpleNursery/Wizard.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import AddOrganizationModal from '@/modules/organization/components/AddOrganizationModal.vue';
    import NurseriesItem from '@/pages/NurseriesItem.vue';

    export default {
        components: {
            NurseriesItem,
            MEmptyIndex,
            SimplePageTemplate,
            AddOrganizationModal,
            NurseryWizard,
        },
        props: {
            user: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loader: false,
                nurseries: [],
            };
        },
        computed: {
            filtered() {
                return _sortBy(this.nurseries, 'name');
            },
            createOrganizationModal() {
                return useModal();
            },
        },
        mounted() {
            this.getNurseries().then(nurseries => {
                if (nurseries.length < 1) {
                    this.$refs.nurseryWizard.show();
                }

                nextTick(() => {
                    if (this.$route.query.welcome === 'true') {
                        useMagicModal().successModal({
                            title: __('app:welcome'),
                            text: __('app:welcome.details'),
                            confirmButtonText: __('common:lets_go'),
                        });
                    }
                });
            });
        },
        methods: {
            getNurseries() {
                return new Promise((resolve, reject) => {
                    this.loader = true;
                    useApi()
                        .legacy.get(route('nurseries.index'))
                        .then(response => {
                            this.nurseries = response.data;
                            this.loader = false;
                            resolve(response.data);
                        })
                        .catch(error => {
                            this.loader = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                            reject();
                        });
                });
            },
            pushNursery(e) {
                this.nurseries.push(e);
                this.user.nurseries.push(e);
            },
        },
    };
</script>
