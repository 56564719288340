<template>
    <div class="PlanningSettingsDisplayPlannings">
        <MHeading>
            <FontAwesomeIcon
                class="tw-mr-1 tw-text-blue-500"
                icon="fa-solid fa-calendar-alt"
            />
            {{ __('planning:the_plannings') }}
        </MHeading>
        <MForm>
            <MFieldset>
                <MLabel>{{ __('planning:display_team_bar_on_child_schedule_colon') }}</MLabel>
                <CButtonGroup>
                    <MButton
                        :label="__('common:yes')"
                        :variant="displaySupervision ? 'primary' : 'light'"
                        @click="displaySupervision = true"
                    />
                    <MButton
                        :label="__('common:no')"
                        :variant="!displaySupervision ? 'primary' : 'light'"
                        @click="displaySupervision = false"
                    />
                </CButtonGroup>
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('planning:display_on_weekdays_colon') }}</MLabel>
                <div class="tw-flex tw-flex-col tw-gap-2 sm:tw-flex-row">
                    <CCheckbox v-model="displayCount">
                        {{ __('common:kids_number') }}
                    </CCheckbox>
                    <CCheckbox v-model="displayRate">
                        {{ __('planning:occupation_rate') }}
                    </CCheckbox>
                </div>
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('planning:display_total_kid_number_colon') }}</MLabel>
                <HalfDayFilter
                    v-if="displayCount"
                    :day-separator="daySeparator"
                    :halfday-time="halfdayTime"
                    @update:day-separator="daySeparator = $event"
                    @update:halfday-time="halfdayTime = $event"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('planning:occupation_rate_decimals_colon') }}</MLabel>
                <CButtonGroup>
                    <MButton
                        class="tw-px-4"
                        label="0"
                        :variant="ratePrecision === 0 ? 'primary' : 'light'"
                        @click="ratePrecision = 0"
                    />
                    <MButton
                        class="tw-px-4"
                        label="1"
                        :variant="ratePrecision === 1 ? 'primary' : 'light'"
                        @click="ratePrecision = 1"
                    />
                    <MButton
                        class="tw-px-4"
                        label="2"
                        :variant="ratePrecision === 2 ? 'primary' : 'light'"
                        @click="ratePrecision = 2"
                    />
                </CButtonGroup>
            </MFieldset>
            <MFieldset>
                <div class="tw-flex tw-flex-row tw-gap-4">
                    <MFieldset>
                        <MLabel>{{ __('planning:minimal_occupation_rate') }}</MLabel>
                        <CInputGroup>
                            <CInput
                                v-model.number="activeOrganization.attributes.occ_min_rate"
                                :disabled="!can('update', 'nursery')"
                                placeholder="70"
                            />
                            <CInputAddon>%</CInputAddon>
                        </CInputGroup>
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('planning:maximal_occupation_rate') }}</MLabel>
                        <CInputGroup>
                            <CInput
                                v-model.number="activeOrganization.attributes.occ_max_rate"
                                :disabled="!can('update', 'nursery')"
                                placeholder="115"
                            />
                            <CInputAddon>%</CInputAddon>
                        </CInputGroup>
                    </MFieldset>
                </div>
            </MFieldset>
        </MForm>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import useManager from '@/modules/app/composables/useManager';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import HalfDayFilter from '@/modules/planning/components/simulation/Filter/HalfDayFilter.vue';

    export default defineComponent({
        components: {
            HalfDayFilter,
        },
        props: {
            saveUserPreferences: {
                type: Function,
                required: true,
            },
        },
        setup(props) {
            const {activeOrganization} = useManager();
            const {can} = useAbility();

            const {selectedOptions: daySeparator} = useUserFilters(
                'plannings:kids:display:daySeparator',
                OutputType.value,
                'day'
            );
            const {selectedOptions: halfdayTime} = useUserFilters(
                'plannings:kids:display:halfdayTime',
                OutputType.value,
                '12:30'
            );
            const {selectedOptions: displayCount} = useUserFilters(
                'plannings:kids:display:count',
                OutputType.value,
                true
            );
            const {selectedOptions: displayRate} = useUserFilters(
                'plannings:kids:display:rate',
                OutputType.value,
                true
            );
            const {selectedOptions: ratePrecision} = useUserFilters(
                'plannings:kids:display:ratePrecision',
                OutputType.value,
                0
            );

            watch(
                () => [
                    daySeparator.value,
                    halfdayTime.value,
                    displayCount.value,
                    displayRate.value,
                    ratePrecision.value,
                ],
                () => {
                    props.saveUserPreferences();
                }
            );

            // Keep legacy user preference for staffSummary
            const legacyKeyShowStaffSummary = 'showStaffSummary';
            const displaySupervision = ref(window.localStorage.getItem(legacyKeyShowStaffSummary) === 'true');
            watch(
                () => displaySupervision.value,
                val => {
                    if (val) {
                        window.localStorage.setItem(legacyKeyShowStaffSummary, 'true');
                    } else {
                        window.localStorage.removeItem(legacyKeyShowStaffSummary);
                    }
                    props.saveUserPreferences();
                }
            );

            const loading = ref(false);

            return {
                activeOrganization,
                can,
                daySeparator,
                halfdayTime,
                displayCount,
                displayRate,
                ratePrecision,
                displaySupervision,
                loading,
            };
        },
    });
</script>

<style scoped>
    .PlanningSettingsDisplayPlannings {
        @apply tw-flex tw-flex-col tw-gap-4;
    }
</style>
