import type {QueryBuilder} from '@meekohq/lumos';
import moment from 'moment';

import {scopeComing, scopeFrom, scopeInRange, scopeUntil} from '@/modules/human-resources/utils/contract/scopeContract';

/**
 * @returns Active contract.
 */
export function scopeActive(
    query: QueryBuilder<any>,
    args: {
        from: string;
        to: string | null;
    }
): QueryBuilder<any> {
    const from = args ? args.from : moment().format('YYYY-MM-DD');
    const to = args ? args.to || null : null;
    if (to) {
        query = scopeInRange(query, from, to);
    } else {
        query = scopeFrom(query, from);
    }

    return query;
}

export function scopeWithoutDraft(query: QueryBuilder<any>): QueryBuilder<any> {
    return query.where('draft', 0);
}

export function scopeWithActiveContract(query: QueryBuilder<any>, withoutDraft = true) {
    return query.whereHas('contracts', contractQuery => {
        contractQuery = scopeActive(contractQuery, {from: moment().toISOString(), to: null});

        if (withoutDraft) {
            contractQuery = scopeWithoutDraft(contractQuery);
        }

        return contractQuery;
    });
}

export function scopeWithPastContractUntil(query: QueryBuilder<any>, until: string, withoutDraft = true) {
    return query.whereHas('contracts', contractQuery => {
        contractQuery = scopeUntil(contractQuery, until);

        if (withoutDraft) {
            contractQuery = scopeWithoutDraft(contractQuery);
        }

        return contractQuery;
    });
}

export function scopeWithComingContract(query: QueryBuilder<any>, withoutDraft = true) {
    return query.whereHas('contracts', contractQuery => {
        contractQuery = scopeComing(contractQuery);

        if (withoutDraft) {
            contractQuery = scopeWithoutDraft(contractQuery);
        }

        return contractQuery;
    });
}
