<script lang="ts" setup>
    import {JsonAPISchema} from '@meekohq/lumos';
    import type {PropType, Ref} from 'vue';
    import {computed, onBeforeMount, ref} from 'vue';
    import {useRouter} from 'vue-router';

    import useApi from '@/modules/app/composables/useApi';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import SelectCustomer from '@/modules/cashier/components/core/customer/organisms/SelectCustomer.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import TenantModel from '@/modules/cashier/models/TenantModel';
    import route from '@/modules/legacy/libs/ziggy';

    const props = defineProps({
        modal: {type: Object as PropType<ModalType>, required: true},
        customerModel: {type: Object as PropType<CustomerModel>, required: true},
    });

    const tenantModel = ref(new TenantModel()) as Ref<TenantModel>;
    const customerModelToKeep = ref<CustomerModel>();
    const customerModelToDelete = ref<CustomerModel>(props.customerModel);

    const isTenantLoading = ref(false);
    const isMerging = ref(false);

    const router = useRouter();

    const customerBuilder = computed(() => {
        if (customerModelToDelete.value) {
            return CustomerModel.query().where('id', '<>', customerModelToDelete.value.id as string);
        }

        return CustomerModel.query();
    });

    function fetchDefaultTenant() {
        isTenantLoading.value = true;
        TenantModel.query()
            .find(props.customerModel.attributes.tenant_id)
            .then(response => {
                customerModelToKeep.value = undefined;
                tenantModel.value = response as TenantModel;
                isTenantLoading.value = false;
            });
    }

    function switchCustomers() {
        customerModelToDelete.value = (customerModelToDelete.value as CustomerModel).clone();
        customerModelToKeep.value = (customerModelToKeep.value as CustomerModel).clone();
    }

    function mergeCustomers() {
        isMerging.value = true;
        useApi()
            .legacy.post(
                route('cashier/customers.merge'),
                new JsonAPISchema.JsonApiDocument(
                    new JsonAPISchema.JsonApiData({
                        customer_source_id: (customerModelToDelete.value as CustomerModel).getKey(),
                        customer_destination_id: (customerModelToKeep.value as CustomerModel).getKey(),
                    })
                )
            )
            .then(() => {
                router.push({
                    name: 'cashier/customers.show.summary',
                    params: {customer: (customerModelToKeep.value as CustomerModel).getKey() as string},
                });
                isMerging.value = false;
                props.modal.hide();
            })
            .catch(() => {
                isMerging.value = false;
            });
    }

    onBeforeMount(() => {
        fetchDefaultTenant();
    });
</script>
<template>
    <MModal
        :header-title="__('billing_customer:merge_customer_account')"
        :modal="modal"
        size="3xl"
    >
        <div class="tw-flex">
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('billing_customer:what_ll_be_removed_colon')"
                />
                <div
                    v-if="customerModelToDelete"
                    class="tw-flex tw-h-20 tw-flex-wrap tw-items-center tw-rounded tw-border tw-border-gray-300 tw-bg-gray-50 tw-p-3"
                >
                    <div class="tw-w-full tw-truncate tw-text-2xl">
                        {{ customerModelToDelete.attributes.name }}
                    </div>
                    <div
                        v-if="customerModelToDelete.attributes.account_number"
                        class="tw-text-gray-600"
                    >
                        {{ __('common:number_short', {value: customerModelToDelete.attributes.account_number}) }}
                    </div>
                </div>
            </div>
            <div class="tw-mt-5 tw-flex tw-w-2/12 tw-flex-wrap tw-justify-center">
                <FontAwesomeIcon
                    class="tw-self-center tw-text-5xl tw-text-gray-600"
                    icon="fa-duotone fa-angle-double-right"
                />
                <a
                    v-if="customerModelToKeep"
                    class="tw-block tw-w-full tw-cursor-pointer tw-self-end tw-text-center tw-text-sm !tw-text-blue-500"
                    @click="switchCustomers"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-retweet"
                    />
                    {{ __('common:actions.reverse') }}
                </a>
            </div>
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('billing_customer:what_ll_be_retained_colon')"
                />
                <div
                    class="tw-flex tw-h-20 tw-flex-wrap tw-items-center tw-rounded tw-border tw-border-gray-300 tw-p-3"
                >
                    <SelectCustomer
                        v-if="!isTenantLoading"
                        v-model="customerModelToKeep"
                        :create-option-enabled="false"
                        :extend-builder="customerBuilder"
                        :tenant-model="tenantModel"
                    />
                </div>
            </div>
        </div>

        <div class="tw-mt-8 tw-rounded tw-bg-gray-50 tw-p-2 tw-text-sm">
            <div>{{ __('billing_customer:following_data_ll_be_sent_to_the_retained_account_colon') }}</div>
            <ul class="tw-mb-0 tw-mt-2">
                <li>- {{ __('billing_customer:billing_addresse_other') }}</li>
                <li>- {{ __('billing_customer:saving_contact_other') }}</li>
                <li>- {{ __('billing_customer:invoice_and_payments') }}</li>
            </ul>
        </div>

        <div
            class="tw-mt-3"
            v-html="__('common:irreversible_action_warning')"
        />

        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="modal.hide"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :disabled="!customerModelToKeep"
                :loading="isMerging"
                variant="primary"
                @click="mergeCustomers"
            >
                {{ __('common:actions.merge') }}
            </MButton>
        </template>
    </MModal>
</template>
