<template>
    <MMenu>
        <MMenuButton>
            <MButton icon-ellipsis />
        </MMenuButton>
        <MMenuItems>
            <MMenuItem
                :label="__('family_family:merge_family_dots')"
                @click="mergeModal.show"
            >
                <template #icon>
                    <FontAwesomeIcon
                        fixed-width
                        icon="fa-solid fa-compress-alt"
                    />
                </template>
            </MMenuItem>
            <MTooltip
                class="tw-w-full"
                :hoverable="familyHasKids"
                :label="__('family_family:cannot_delete_family')"
                placement="bottom-start"
            >
                <MMenuItem
                    class="tw-w-full"
                    :disabled="familyHasKids"
                    :label="__('family_family:delete_family_dots')"
                    variant="danger"
                    @click="onFamilyDeleted"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MTooltip>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import {computed, defineComponent, type PropType} from 'vue';

    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import MergeFamilyModal from '@/modules/family/components/family/MergeFamilyModal.vue';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: {
            familyModel: {type: Object as PropType<FamilyModel>, required: true},
        },
        emits: ['merged', 'deleted'],
        setup(props, {emit}) {
            const mergeModal = useModal({
                component: MergeFamilyModal,
                props: {
                    familyModel: computed(() => props.familyModel.clone()),
                },
                listeners: () => ({
                    merged: (family: FamilyModel) => emit('merged', family),
                }),
            });

            const onFamilyDeleted = async function () {
                const result = await useMagicModal().deleteConfirmationModal({
                    text: __('family_family:delete_family_warning'),
                });

                if (result.isConfirmed) {
                    await props.familyModel.delete();
                    useNotification().success(__('family_family:family_deleted_successfully'));
                    emit('deleted', props.familyModel);
                }
            };

            const familyHasKids = computed(() => props.familyModel.kids().value().isNotEmpty());

            return {
                mergeModal,
                onFamilyDeleted,
                familyHasKids,
            };
        },
    });
</script>
