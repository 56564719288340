<template>
    <div
        class="MagicIndexMassSelectWrapper"
        :style="{top: topBarSize + 'px'}"
    >
        <MBox class="MagicIndexMassSelect">
            <div class="tw-flex tw-items-center tw-gap-4">
                <div class="tw-flex tw-items-center tw-gap-2">
                    <CCheckbox
                        :half-checked="massSelect.selectedItemsCount.value < massSelect.itemsOnPage.value"
                        :model-value="massSelect.selectedItemsCount.value >= massSelect.itemsOnPage.value"
                        @update:model-value="onChange"
                    />
                    <div class="tw-font-display tw-font-medium tw-text-primary-900">
                        {{ __('common:selected_elements_count', {count: massSelect.selectedItemsCount.value}) }}
                    </div>
                </div>
                <MButton
                    v-if="
                        massSelect.totalItemsCount.value > massSelect.selectedItemsCount.value &&
                        massSelect.selectedItemsCount.value >= massSelect.itemsOnPage.value
                    "
                    size="sm"
                    variant="link"
                    @click="massSelect.selectAll"
                >
                    {{ __('common:select_all_items', {totalCount: massSelect.totalItemsCount.value}) }}
                </MButton>
            </div>
            <div class="tw-flex tw-gap-6">
                <slot name="actions">
                    <MButton
                        class="tw-text-red-500"
                        size="sm"
                        variant="link"
                        @click="$emit('delete')"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon
                                class="tw-text-red-500"
                                icon="fa-solid fa-trash"
                            />
                        </template>
                        {{ __('common:actions.delete') }} ({{ massSelect.selectedItemsCount.value }})
                    </MButton>
                </slot>
            </div>
        </MBox>
    </div>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
    import useUI from '@/modules/app/composables/useUI';

    export default defineComponent({
        props: {
            massSelect: {type: Object as PropType<MagicMassSelectType>, required: true},
        },
        emits: ['delete'],
        setup(props) {
            const {topBarSize} = useUI();

            function onChange() {
                if (props.massSelect.selectedItemsCount.value < props.massSelect.itemsOnPage.value) {
                    props.massSelect.selectCurrentPage();
                } else if (props.massSelect.selectedItemsCount.value >= props.massSelect.itemsOnPage.value) {
                    props.massSelect.reset();
                }
            }

            return {
                topBarSize,
                onChange,
            };
        },
    });
</script>

<style scoped>
    .MagicIndexMassSelectWrapper {
        @apply tw-p-3;
        /*@apply tw-border-b tw-border-gray-200;*/
        @apply tw-sticky tw-z-10;
    }

    .MagicIndexMassSelect {
        @apply tw-flex tw-gap-4 tw-p-3;
    }

    .MagicIndexMassSelect__text-row {
        @apply tw-flex tw-flex-wrap tw-items-center tw-gap-1;
    }
</style>
