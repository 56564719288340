<template>
    <div class="AuthCardTemplate">
        <h1 class="AuthCardTemplate__title">meeko</h1>
        <div class="AuthCardTemplate__container">
            <div class="AuthCardTemplate__card">
                <slot name="card" />
            </div>
        </div>
        <div
            v-if="showMeekoLogo === true"
            class="AuthCardTemplate__meeko-logo"
        >
            <img
                alt="Meeko"
                class="animated slideInUp MeekoAvatar"
                src="../../assets/images/Supercoon.png"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            showMeekoLogo: {type: Boolean, required: false, default: true},
        },
        setup() {
            return {};
        },
    });
</script>

<style scoped>
    .AuthCardTemplate {
        @apply tw-h-auto tw-min-h-screen;
        @apply tw-flex tw-flex-col tw-items-center tw-justify-center;
        @apply tw-relative;
        @apply tw-overflow-hidden;
        @apply tw-text-center;

        &::before {
            display: block;
            position: absolute;
            top: -50%;
            right: 0;
            bottom: -50%;
            left: 0;
            transform: rotate(-8deg);
            opacity: 0.1;
            z-index: -1;
            background: url('@/assets/images/pattern.png') 0 0 repeat;
            background-size: 652px 700px;
            content: '';
        }
    }

    .AuthCardTemplate__container {
        @apply tw-z-10;
        @apply tw-w-full md:tw-w-[800px];
        @apply tw-p-4;
    }

    .AuthCardTemplate__card {
        @apply tw-rounded-xl tw-bg-white tw-shadow-md;
        @apply tw-flex tw-flex-col tw-items-start tw-justify-center md:tw-flex-row;
        @apply tw-w-full;
    }

    .AuthCardTemplate__title {
        @apply tw-font-grandhotel tw-antialiased;
        @apply tw-text-[110px] tw-leading-[3.75rem];
        @apply tw-text-primary-500;
        @apply tw-mb-10;
        text-shadow: rgba(0, 96, 198, 0.3) 0 2px 3px;
    }

    .AuthCardTemplate__meeko-logo {
        @apply tw-absolute;
        @apply tw--bottom-14 tw-right-0 tw--rotate-6;
        @apply tw-z-0;
        @apply tw-w-52 md:tw-w-72 lg:tw-w-80;
    }
</style>
