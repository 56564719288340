import type {QueryBuilder} from '@meekohq/lumos';
import {ref} from 'vue';

// Create a shared boolean value to export it in every component which need to know which list is showed.
const showEmployeesList = ref(true);
export default function () {
    // Create a constraint to get allowed organizations wich has contracts.
    function addConstraint(builder: QueryBuilder<any>) {
        builder.where(query1 => {
            query1
                .whereDoesntHave('organizations')
                .orWhereHas('organizations', query2 => query2.scope('userOrganizations'))
                .whereHas('contracts');
        });
    }

    return {
        showEmployeesList,
        addConstraint,
    };
}
