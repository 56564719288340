<template>
    <ConfirmModal
        ref="confirmModal"
        :orga="orga"
        :title="__('hr_balance:import_balance_type_from_another_organization')"
        @organization-selected="importBalanceTypes($event)"
    />
</template>
<script lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {defineComponent, ref} from 'vue';

    import ConfirmModal from '@/modules/app/components/ConfirmModal.vue';
    import {attachModelToOrganization, getModelIDontHave} from '@/modules/app/composables/useSharedImportModel';
    import __ from '@/modules/app/utils/i18n-facade';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import Manager from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {
            ConfirmModal,
        },
        emits: ['loading', 'imported'],
        setup(props, {emit}) {
            const confirmModal = ref();
            const orga = ref();
            const balanceTypes = ref(collect<BalanceTypeModel>());
            const eventTypes = ref(collect<EventTypeModel>());

            const show = async function () {
                orga.value = await OrganizationModel.query().find(Manager.activeOrganization.id);
                balanceTypes.value = await BalanceTypeModel.query()
                    .whereHas('organizations', query => query.scope('active'))
                    .get();
                eventTypes.value = await EventTypeModel.query()
                    .whereHas('organizations', query => query.scope('active'))
                    .get();
                confirmModal.value.show();
            };

            const importBalanceTypes = async function (organizationModelSelected: OrganizationModel) {
                if (organizationModelSelected) {
                    try {
                        const {builder: balanceTypeBuilder} = getModelIDontHave(
                            organizationModelSelected,
                            balanceTypes.value as Collection<BalanceTypeModel>
                        );
                        const {builder: eventTypeBuilder} = getModelIDontHave(
                            organizationModelSelected,
                            eventTypes.value as Collection<EventTypeModel>
                        );

                        const balanceTypesOfSelectedOrganization = await BalanceTypeModel.query()
                            .inject(balanceTypeBuilder)
                            .with(new BalanceTypeModel().eventTypes(), query => query.inject(eventTypeBuilder))
                            .get();

                        emit('loading', true);
                        await attachModelToOrganization(balanceTypesOfSelectedOrganization.all(), orga.value, {
                            name: 'eventTypes',
                            queryBuilder: eventTypeBuilder,
                        });
                        emit('loading', false);

                        emit('imported');
                        useNotification().success(__('hr_balance:balance_type_imported_successfully'));
                    } catch (e) {
                        emit('loading', false);
                        useNotification().error(__('hr_balance:balance_type_imported_error'));
                    }
                }
            };

            return {
                confirmModal,
                orga,
                importBalanceTypes,
                show,
                balanceTypes,
            };
        },
    });
</script>
