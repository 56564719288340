<template>
    <MModal
        :header-title="__('family_member:new_parent')"
        :modal="modal"
    >
        <MTabs
            default-tab="FamilyMemberForm"
            :tabs="tabsArray"
            @selected="selectedTab"
        >
            <template #default="{activeTab}">
                <div class="tw-mt-4">
                    <FamilyMemberForm
                        v-if="activeTab === 'FamilyMemberForm'"
                        :errors="errors"
                        :family-member="newFamilyMember"
                        :from-kid="fromKid"
                        :nursery="nursery"
                        :show-authorizations="true"
                    />
                    <AttachFamilyMemberForm
                        v-if="activeTab === 'AttachFamilyMemberForm'"
                        :all-family-members="allFamilyMembers"
                        :kid-family-member-ids="kidFamilyMemberIds"
                        :new-family-member="newFamilyMember"
                        :selected-family-member="selectedFamilyMember"
                        @select-existing-member="selectExistingMember($event)"
                    />
                </div>
            </template>
        </MTabs>
        <template #footer-end="{hide}">
            <MButton
                :disabled="loading"
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                :label="__('common:actions.add')"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>

<script>
    import {collect} from '@meekohq/lumos';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _pullAllBy from 'lodash-es/pullAllBy';
    import _sortBy from 'lodash-es/sortBy';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import AttachFamilyMemberForm from '@/modules/family/components/kid/Kid/FamilyMember/molecules/AttachFamilyMemberForm.vue';
    import FamilyMemberForm from '@/modules/family/components/kid/Kid/FamilyMember/molecules/FamilyMemberForm.vue';
    import useMemberRoleMapper from '@/modules/family/composables/member/useMemberRoleMapper';
    import KidMemberPivot from '@/modules/family/models/KidMemberPivot';
    import MemberRoleValue from '@/modules/family/utils/member/MemberRoleValue';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            AttachFamilyMemberForm,
            FamilyMemberForm,
        },
        mixins: [nursery],
        props: {
            nursery: {},
            kid: {},
            contactFamilyMembers: {
                type: Array,
            },
            modal: {
                type: Object,
                required: true,
            },
        },
        emits: ['add'],
        data: () => ({
            loading: false,
            errors: [],
            allFamilyMembers: [],
            newFamilyMember: {
                gender: 'female',
                role: 'mother',
                allocator_no: null,
                can_pickup_kid: 1,
                permissions: [],
                billing: 0,
                family_member_id: null,
                first_name: null,
                last_name: null,
                birthdate: null,
                email: null,
                mobile: null,
                insurance_code: null,
                note: null,
            },
            selectedFamilyMember: null,
            familyMemberpermissions: [],
            tabsArray: {
                FamilyMemberForm: {
                    label: __('family_member:new_parent'),
                },
                AttachFamilyMemberForm: {
                    label: __('family_member:add_existing_parent'),
                },
            },
        }),
        computed: {
            fromKid() {
                return !!this.kid;
            },
            kidFamilyMemberIds() {
                const familyMembersContactIds = collect(this.contactFamilyMembers).pluck('id').all();
                const familyMembersIds = collect(this.kid.familyMembers).pluck('id').all();

                return [...familyMembersContactIds, ...familyMembersIds];
            },
        },
        watch: {
            'newFamilyMember.family_member_id': function () {
                // Need to wait for the next tick for the role MSelectMenu to be updated with the new options
                this.$nextTick(() => {
                    this.newFamilyMember.role = useMemberRoleMapper().getRoleUpdatedByGender(
                        this.newFamilyMember.gender,
                        this.newFamilyMember.role
                    );
                });
            },
            'newFamilyMember.gender': function () {
                this.newFamilyMember.role = useMemberRoleMapper().getRoleUpdatedByGender(
                    this.newFamilyMember.gender,
                    this.newFamilyMember.role
                );
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.getInitData();
            },
            getInitData() {
                this.newFamilyMember.gender = 'female';
                this.newFamilyMember.role = MemberRoleValue.mother;
                this.newFamilyMember.can_pickup_kid = 1;
                this.newFamilyMember.permissions = [];
                this.newFamilyMember.family_member_id = null;
                this.newFamilyMember.first_name = null;
                this.newFamilyMember.last_name = null;
                this.newFamilyMember.birthdate = null;
                this.newFamilyMember.country_code = this.nursery.country;
                this.newFamilyMember.email = null;
                this.newFamilyMember.mobile = null;
                this.newFamilyMember.note = null;
                this.newFamilyMember.allocator_no = null;
            },
            selectedTab(tab) {
                if (tab === 'AttachFamilyMemberForm') {
                    this.getAllFamilyMembers();
                }
            },
            getAllFamilyMembers() {
                useApi()
                    .legacy.get(
                        route('nurseries.familyMembers.index', {
                            nurseries: this.nursery.id,
                        }),
                        {
                            params: {
                                per_page: 1000,
                            },
                        }
                    )
                    .then(response => {
                        const diffFamilyMembers = _pullAllBy(response.data.data, this.familyMembers, 'id');
                        this.allFamilyMembers = _sortBy(diffFamilyMembers, 'last_name');
                    });
            },
            resetSelectedFamilyMember() {
                this.newFamilyMember.family_member_id = null;
                this.selectedFamilyMember = null;
                this.getInitData();
            },
            selectExistingMember(member) {
                if (member) {
                    this.selectedFamilyMember = member;
                    this.newFamilyMember.gender = member.attributes.gender;
                    this.newFamilyMember.family_member_id = member.getKey();
                } else {
                    this.resetSelectedFamilyMember();
                }
            },
            updateSelectedMemberToLegacyFormat(pivot) {
                const isParent =
                    pivot.attributes.role === MemberRoleValue.mother ||
                    pivot.attributes.role === MemberRoleValue.father;

                const {address, ...attributes} = this.selectedFamilyMember.attributes;

                return {
                    ...attributes,
                    ...address,
                    isParent,
                    can_pickup_kid: pivot.attributes.can_pickup_kid,
                    permissions: pivot.attributes.permissions,
                    role: pivot.attributes.role,
                };
            },
            async save() {
                const newFamilyMember = this.newFamilyMember;
                if (newFamilyMember.family_member_id) {
                    this.loading = true;

                    try {
                        const pivot = new KidMemberPivot();
                        pivot.attributes.account_id = this.kid.account_id.toString();
                        pivot.attributes.family_member_id = this.selectedFamilyMember.id;
                        pivot.attributes.kid_id = this.kid.id.toString();
                        pivot.attributes.role = this.newFamilyMember.role;
                        pivot.attributes.permissions = this.newFamilyMember.permissions;
                        pivot.attributes.can_pickup_kid = this.newFamilyMember.can_pickup_kid;
                        await pivot.save();

                        const legacyMember = this.updateSelectedMemberToLegacyFormat(pivot);
                        this.$emit('add', legacyMember);
                        useNotification().success(__('family_member:family_member_added'));
                    } catch (error) {
                        useNotification().error(__('family_member:errors.add_family_member'));
                    }
                    this.modal.hide();
                    this.loading = false;
                } else {
                    this.loading = true;

                    useApi()
                        .legacy.post(
                            route('nurseries.kids.familyMembers.store', {
                                nurseries: this.nursery.id,
                                kid: this.kid.id,
                            }),
                            this.newFamilyMember
                        )
                        .then(response => {
                            this.$emit('add', response.data);
                            useNotification().success(__('family_member:family_member_added'));
                            this.modal.hide();
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
        },
    };
</script>
