import {shallowRef, triggerRef} from 'vue';

import type {ModalType} from '@/modules/app/composables/useModal';

const stack = shallowRef<ModalType[]>([]);

export default function useModalManager() {
    function fire(modal: ModalType, callback?: () => void) {
        modal.bus.on('mounted', () => {
            modal.bus.on('hidden', () => kill(modal));
            modal.bus.emit('show');
            modal.bus.on('shown', () => {
                if (typeof callback === 'function') {
                    callback();
                }
            });
        });

        stack.value.push(modal);
        triggerRef(stack);
    }

    function kill(modal: ModalType) {
        stack.value.splice(stack.value.indexOf(modal), 1);
        triggerRef(stack);
    }

    return {
        stack,
        fire,
        kill,
    };
}
