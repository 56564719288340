<template>
    <CVStack gap="4">
        <CHStack
            align="baseline"
            distribute="between"
        >
            <MHeading level="h3">
                <slot name="title">
                    {{ title }}
                </slot>
            </MHeading>
            <CHStack gap="2">
                <slot name="action" />
            </CHStack>
        </CHStack>
        <slot />
    </CVStack>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            title: {type: String, required: true},
        },
        setup() {
            return {};
        },
    });
</script>
