<template>
    <div>
        <MForm>
            <CFormThreeColumns>
                <MFieldset>
                    <MLabel>{{ __('common:see_contract_between_colon') }}</MLabel>
                    <MDatePicker
                        v-model="fromDate"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('common:and_between_colon') }}</MLabel>
                    <MDatePicker
                        v-model="toDate"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                </MFieldset>
                <MFieldset class="tw-min-w-0">
                    <MLabel>{{ __('common:contract_using_colon') }}</MLabel>
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full tw-justify-between"
                                :icon-menu="true"
                                :label="selectedConfig.name"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem @click="switchConfig(null)">
                                {{ __('common:any_configuration') }}
                            </MMenuItem>
                            <MMenuDivider />
                            <MMenuItem
                                v-for="(config, i) in configs"
                                :key="'config' + i"
                                @click="switchConfig(config.id)"
                            >
                                {{ config.name }}
                            </MMenuItem>
                        </MMenuItems>
                    </MMenu>
                </MFieldset>
            </CFormThreeColumns>
        </MForm>
        <hr class="hr" />
        <loader v-if="loading" />
        <div v-else-if="filteredContracts.length">
            <div class="tw-mb-4 tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2">
                <MButton @click="toggleAll(true)">
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-primary-500"
                            icon="fa-solid fa-check-circle"
                        />
                    </template>
                    {{ __('common:actions.select_all') }}
                </MButton>
                <MButton @click="toggleAll(false)">
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-danger-500"
                            icon="fa-solid fa-times-circle"
                        />
                    </template>
                    {{ __('common:actions.unselect_all') }}
                </MButton>
            </div>
            <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 md:tw-grid-cols-4">
                <div
                    v-for="(contract, i) in filteredContracts"
                    :key="'contracts' + i"
                    @click="toggleContract(contract)"
                >
                    <div
                        class="tw-cursor-pointer tw-rounded-lg tw-border tw-p-2 tw-transition-all tw-ease-in-out"
                        :class="contract.selected ? 'tw-bg-primary-500' : 'tw-bg-white'"
                    >
                        <div class="tw-mb-2 tw-flex tw-flex-row tw-items-center tw-gap-1">
                            <div class="tw-flex tw-w-4 tw-items-center tw-justify-center">
                                <FontAwesomeIcon
                                    :class="contract.selected ? 'tw-text-white' : 'tw-text-primary-500'"
                                    :icon="
                                        contract.selected ? 'fa-solid fa-check-circle' : 'fa-regular fa-file-contract'
                                    "
                                />
                            </div>
                            <MHeading
                                class="tw-truncate"
                                level="h4"
                                :variant="contract.selected ? 'white' : 'primary'"
                            >
                                {{ $capitalize(contract.kid_name) }}
                            </MHeading>
                        </div>
                        <div :class="contract.selected ? 'tw-text-white' : 'tw-text-gray-500'">
                            {{
                                __('common:date_format.human_from_to', {
                                    from: epoch
                                        .fromISOString(contract.started_at)
                                        .toLocaleString(epoch.presets.DATE_SHORT),
                                    to: epoch.fromISOString(contract.ended_at).toLocaleString(epoch.presets.DATE_SHORT),
                                })
                            }}
                        </div>
                        <CBadge
                            class="tw-mt-0.5"
                            variant="blue"
                        >
                            {{ contract.no }}
                        </CBadge>
                    </div>
                </div>
            </div>
        </div>
        <MEmptyIndex v-else />
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _debounce from 'lodash-es/debounce';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _orderBy from 'lodash-es/orderBy';
    import moment from 'moment';

    import useApi from '@/modules/app/composables/useApi';
    import route from '@/modules/legacy/libs/ziggy';
    import MEmptyIndex from '@/modules/meeko-ui/components/MEmptyIndex.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {MEmptyIndex},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            configs: {
                type: Array,
                required: true,
            },
            selectedContracts: {
                type: Array,
                required: true,
            },
        },
        emits: ['configChanged'],
        data: () => ({
            selectedConfig: {},
            fromDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            toDate: moment().add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD'),
            contracts: [],
            loading: false,
            epoch: Epoch,
        }),
        computed: {
            filteredContracts() {
                return _orderBy(this.contracts, 'kid_name');
            },
        },
        watch: {
            'selectedConfig.id': {
                handler: _debounce(function () {
                    this.$emit('configChanged', this.selectedConfig.id);
                    this.getContracts();
                }, 500),
                deep: true,
            },
            'fromDate': _debounce(function () {
                this.getContracts();
            }, 500),
            'toDate': _debounce(function () {
                this.getContracts();
            }, 500),
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.switchConfig(null);
            },

            getContracts() {
                if (this.loading) {
                    return;
                }
                this.loading = true;

                useApi()
                    .legacy.get(route('kid.contracts.index', {nursery_id: this.nursery.id}), {
                        params: {
                            per_page: 200,
                            from: this.fromDate,
                            to: this.toDate,
                            original_config_id: this.selectedConfig.id,
                            broken: 0,
                            draft: 0,
                            has_order: 1,
                        },
                    })
                    .then(response => {
                        this.contracts = response.data.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            switchConfig(id) {
                if (id === null) {
                    this.selectedConfig = {
                        id: null,
                        name: "N'importe quelle configuration",
                    };
                } else {
                    const newConfig = this.configs.find(item => item.id === id);
                    if (newConfig) {
                        this.selectedConfig = _cloneDeep(newConfig);
                    }
                }
            },

            toggleAll(selected) {
                this.contracts.forEach(contract => {
                    set(contract, 'selected', selected);
                    selected ? this.addToSelectedContracts(contract) : this.removeFromSelectedContracts(contract);
                });
            },

            toggleContract(contract) {
                if (contract.selected) {
                    set(contract, 'selected', false);
                    this.removeFromSelectedContracts(contract);
                } else {
                    set(contract, 'selected', true);
                    this.addToSelectedContracts(contract);
                }
            },

            addToSelectedContracts(contract) {
                const alreadySelected = this.selectedContracts.find(item => item === contract.id);
                !alreadySelected ? this.selectedContracts.push(contract.id) : null;
            },

            removeFromSelectedContracts(contract) {
                const contractToRemove = this.selectedContracts.find(item => item === contract.id);
                if (contractToRemove) {
                    const index = this.selectedContracts.indexOf(contractToRemove);
                    this.selectedContracts.splice(index, 1);
                }
            },
        },
    };
</script>
