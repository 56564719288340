<script setup lang="ts">
    import {useElementSize} from '@vueuse/core';
    import {ref} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';
    import UsersManagerPermissionsItem from '@/modules/organization/components/UsersManager/UsersManagerPermissionsItem.vue';

    defineProps({
        userItem: {
            type: Object,
            required: true,
        },
        userMenus: {
            type: Function,
            required: true,
        },
    });

    defineEmits(['editUser', 'openPermissions']);

    const {can} = useAbility();
    const usersManagerListItem = ref(null);
    const {width} = useElementSize(usersManagerListItem);
</script>

<template>
    <div
        ref="usersManagerListItem"
        class="UsersManagerListItem"
    >
        <MagicIndexItemColumn
            class="UsersManagerListItem__column-name"
            :grid-enabled="false"
        >
            <MListItemTitle
                class="tw-truncate"
                :title="$fullName(userItem)"
            />
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="UsersManagerListItem__column-actions"
            :grid-enabled="false"
        >
            <MMenu v-if="can('update', 'users')">
                <MMenuButton>
                    <MButton
                        icon-ellipsis
                        variant="ghost"
                    />
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :label="__('common:actions.update')"
                        @click="$emit('editUser', userItem)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-pencil"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="!userItem.is_owner"
                        :label="__('user:manage_permissions_dots')"
                        @click="$emit('openPermissions', userItem)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-user-lock"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="UsersManagerListItem__column-permissions"
            :container-width="width"
            :grid-title="__('common:permissions')"
        >
            <div class="UsersManagerListItem__permissions">
                <CBadge
                    v-if="userItem.is_owner"
                    variant="green"
                >
                    {{ __('user:admin_full_permissions') }}
                </CBadge>
                <CBadge
                    v-else-if="userMenus(userItem).length === 0"
                    variant="gray"
                >
                    {{ __('user:no_access_to_the_current_organization') }}
                </CBadge>
                <div
                    v-else
                    class="tw-flex tw-flex-wrap tw-gap-2"
                >
                    <MTooltip
                        v-for="(menu, index) in userMenus(userItem)"
                        :key="'menu-permission' + index"
                        :label="menu.displayName"
                    >
                        <UsersManagerPermissionsItem :menu="menu" />
                    </MTooltip>
                </div>
            </div>
        </MagicIndexItemColumn>
    </div>
</template>

<style scoped>
    .UsersManagerListItem {
        @apply tw-grid tw-grid-cols-24 tw-items-center tw-gap-3;
        container-name: UsersManagerListItem;
        container-type: inline-size;
    }

    .UsersManagerListItem__column-name {
        @apply tw-col-span-20;

        @container UsersManagerListItem (min-width: 48rem) {
            @apply tw-col-span-12;
        }
    }

    .UsersManagerListItem__column-actions {
        @apply tw-col-span-4;
        @apply tw-order-none;
        @apply tw-text-right;

        @container UsersManagerListItem (min-width: 48rem) {
            @apply tw-order-last tw-col-span-1;
        }
    }

    .UsersManagerListItem__column-permissions {
        @apply tw-order-last tw-col-span-full;

        @container UsersManagerListItem (min-width: 48rem) {
            @apply tw-order-none tw-col-span-11;
        }
    }
</style>
