<script setup lang="ts">
    import type {PropType} from 'vue';

    import DeviceReportingMandatoryFor from '@/modules/organization/utils/DeviceReportingMandatoryFor';

    const props = defineProps({
        modelValue: {
            type: Array as PropType<DeviceReportingMandatoryFor[]>,
            required: true,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    function deviceHasReportingMandatoryAuthorSetting(setting: DeviceReportingMandatoryFor) {
        return props.modelValue.includes(setting);
    }

    function toggleDeviceReportingMandatoryAuthorSetting(setting: DeviceReportingMandatoryFor, checked: boolean) {
        if (checked && !deviceHasReportingMandatoryAuthorSetting(setting)) {
            emit('update:modelValue', [...props.modelValue, setting]);
        } else if (!checked && deviceHasReportingMandatoryAuthorSetting(setting)) {
            emit(
                'update:modelValue',
                props.modelValue.filter(item => item !== setting)
            );
        } else {
            throw new Error('Toggle device reporting mandatory author setting error');
        }
    }
</script>

<template>
    <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
        <div>
            <MLabel>{{ __('common:kid_other') }}</MLabel>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.kidAttendances)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.kidAttendances, $event)
                    "
                >
                    {{ __('common:clocking_in_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.meals)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.meals, $event)
                    "
                >
                    {{ __('common:meal') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.activities)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.activities, $event)
                    "
                >
                    {{ __('common:activity_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.naps)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.naps, $event)
                    "
                >
                    {{ __('common:naps') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.weights)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.weights, $event)
                    "
                >
                    {{ __('common:weight_measurement') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.drugs)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.drugs, $event)
                    "
                >
                    {{ __('common:drug_intake') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.temperatures)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.temperatures, $event)
                    "
                >
                    {{ __('common:temperature_measurement') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.diapers)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.diapers, $event)
                    "
                >
                    {{ __('common:diaper') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.photos)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.photos, $event)
                    "
                >
                    {{ __('common:photos') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.observations)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.observations, $event)
                    "
                >
                    {{ __('common:observation_other') }}
                </CCheckbox>
            </MFieldset>
        </div>
        <div>
            <MLabel>{{ __('common:team_other') }}</MLabel>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.rooms)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.rooms, $event)
                    "
                >
                    {{ __('common:room_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.equipment)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.equipment, $event)
                    "
                >
                    {{ __('common:equipment_one') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.staffNotes)"
                    @update:model-value="
                        toggleDeviceReportingMandatoryAuthorSetting(DeviceReportingMandatoryFor.staffNotes, $event)
                    "
                >
                    {{ __('organization:teams_notes') }}
                </CCheckbox>
            </MFieldset>
        </div>
    </div>
</template>
