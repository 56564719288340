<script setup lang="ts">
    import {provide} from 'vue';

    const props = defineProps({
        context: {
            type: Object,
            default: undefined,
        },
    });

    if (props.context) {
        provide('MFloatingContext', props.context);
    }
</script>

<template>
    <div class="MFloatingContainer">
        <slot />
    </div>
</template>

<style scoped>
    .MFloatingContainer {
        @apply tw-contents;
    }
</style>
