<script setup lang="ts">
    import {now} from '@meekohq/lumos';
    import {ref, watch} from 'vue';

    import useEpoch from '@/modules/app/composables/useEpoch';
    import useError from '@/modules/app/composables/useError';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

    const props = defineProps<{
        modal: ModalType;
        transaction: TransactionModel;
        isLoading: boolean;
    }>();

    const emit = defineEmits<{
        (e: 'confirmed', value: string): void;
        (e: 'dismissed'): void;
    }>();

    const failureDate = ref<string>(now().toISOString());
    const errors = useError();
    const {fromISOString} = useEpoch();

    watch(failureDate, value => {
        const transactionDate = fromISOString(props.transaction.attributes.date as string);
        const safeFailureDate = value ? fromISOString(value as string) : undefined;

        // We check the failure date set to the end of the day to avoid any timezone issue
        if (!safeFailureDate || transactionDate.greaterThanOrEqualTo(safeFailureDate.endOfDay())) {
            errors.add(
                'failureDate',
                'failure-date-must-be-future',
                __('billing_transaction:errors.failure_date_cannot_be_before_transaction_date')
            );
        } else {
            errors.remove('failureDate', 'failure-date-must-be-future');
        }
    });

    function onConfirm() {
        emit('confirmed', failureDate.value);
    }
</script>

<template>
    <MModal
        hide-header-close
        :modal="modal"
        size="md"
    >
        <FontAwesomeIcon
            class="ConfirmationModal__icon tw-bg-danger-100 tw-text-3xl tw-text-danger-500"
            icon="fal fa-xmark"
        />
        <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
            <h3 class="tw-font-display tw-text-xl tw-leading-6">
                {{ __('common:please_confirm') }}
            </h3>
            <div class="tw-mt-2">
                <p class="tw-text-gray-500">
                    {{
                        __('billing_transaction:ask_reject_transaction', {
                            context: transaction.isCredit ? 'credit' : 'debit',
                        })
                    }}
                </p>
            </div>
            <MForm
                class="tw-mt-4"
                @submit.prevent
            >
                <MFieldset>
                    <MLabel>
                        {{ __('billing_transaction:failed_at') }}
                    </MLabel>
                    <CFormDatepicker
                        v-model="failureDate"
                        :has-error="errors.has('failureDate')"
                    />
                    <CFormErrorMessageList :errors="errors.get('failureDate')" />
                </MFieldset>
            </MForm>
        </div>

        <template #footer>
            <div class="tw-grid tw-w-full tw-gap-3 sm:tw-grid-cols-2">
                <MButton
                    class="tw-w-full"
                    :loading="isLoading"
                    size="lg"
                    @click="emit('dismissed')"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    class="tw-w-full"
                    :disabled="errors.hasErrors.value"
                    :loading="isLoading"
                    size="lg"
                    variant="danger"
                    @click="onConfirm"
                >
                    {{ __('common:actions.reject') }}
                </MButton>
            </div>
        </template>
    </MModal>
</template>

<style scoped>
    .ConfirmationModal__icon {
        @apply tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full;
    }
</style>
