<template>
    <input
        ref="input"
        v-model="editValue"
        :class="computedClass"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        type="text"
    />
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _join from 'lodash-es/join';

    export default {
        props: {
            modelValue: {
                default: null,
            },
            placeholder: {
                type: String,
                required: false,
            },
            size: {
                type: String,
                default: null,
                required: false,
            },
            readonly: {
                type: Boolean,
                default: false,
                required: false,
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false,
            },
            focus: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        emits: ['update:modelValue'],
        data: () => ({
            editValue: null,
        }),
        computed: {
            computedClass() {
                const array = [];

                array.push('CFormControl');

                switch (this.size) {
                    case 'sm':
                        array.push('CFormControl--sm');
                        break;
                    case 'lg':
                        array.push('CFormControl-lg');
                        break;
                }

                return _join(array, ' ');
            },
        },
        watch: {
            modelValue(value) {
                this.editValue = _cloneDeep(value);
            },
        },
        mounted() {
            this.editValue = _cloneDeep(this.modelValue);

            if (this.focus) {
                this.$refs.input.focus();
            }
        },
        methods: {
            onChange() {
                // this.$emit('update:modelValue', event.target.value);
            },
            validate() {
                this.$emit('update:modelValue', this.editValue);
            },
            undo() {
                this.editValue = _cloneDeep(this.modelValue);
            },
        },
    };
</script>

<style scoped>
    .CFormControl {
        @apply tw-w-full tw-appearance-none tw-rounded-lg tw-border tw-border-gray-300 tw-text-gray-800 tw-shadow;
        @apply tw-px-3 tw-py-2;

        &:not([readonly]):not([disabled]):focus {
            @apply tw-border-blue-500 tw-outline-none tw-ring;
        }

        &:read-only {
            @apply tw-bg-gray-200 tw-text-gray-600;
        }

        &:disabled {
            @apply tw-cursor-default tw-bg-gray-200 tw-text-gray-600;
        }

        &.CFormControl--sm {
            @apply tw-px-2 tw-py-1 tw-text-sm;
        }

        &.CFormControl--lg {
            @apply tw-text-lg;
        }

        &.is-invalid {
            @apply tw-border-red-500;

            &:not([readonly]):not([disabled]):focus {
                @apply tw-border-red-500 tw-ring tw-ring-red-500 tw-ring-opacity-50;
            }
        }
    }
</style>
