<template>
    <div class="CInputGroup">
        <slot />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {
            //
        },
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style>
    .CInputGroup {
        @apply tw-inline-flex;

        > .CInput,
        > .CInputAddon {
            @apply tw-ml-px;
            border-radius: 0 !important;

            &:focus {
                z-index: 10;
            }
        }

        > :first-child {
            &.CInput,
            &.CInputAddon {
                border-top-left-radius: theme(borderRadius.md) !important;
                border-bottom-left-radius: theme(borderRadius.md) !important;
            }
        }

        > :last-child {
            &.CInput,
            &.CInputAddon {
                border-top-right-radius: theme(borderRadius.md) !important;
                border-bottom-right-radius: theme(borderRadius.md) !important;
            }
        }
    }
</style>
