<template>
    <div>
        <p class="tw-mb-0 tw-text-base tw-text-gray-600">
            <span v-html="__('notification:preregistration_file_uptaded_for_children')" />
        </p>
        <p
            v-show="notification.data.content"
            class="tw-mb-0 tw-mt-1 tw-text-sm tw-text-gray-800"
        >
            {{ notification.data.content }}
        </p>

        <div class="tw-mt-2">
            <RouterLink :to="to">
                <MButton @click="handlerEmit">
                    {{ __('common:see_document') }}
                </MButton>
            </RouterLink>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    import {EventBus} from '@/eventBus';

    export default {
        props: {
            notification: {type: Object},
        },
        emits: ['closeModal'],
        computed: {
            moment() {
                return moment;
            },
            to() {
                return {
                    name: 'registrations.show',
                    params: {
                        nursery: this.notification.data.nursery_id,
                        registration: this.notification.data.registration_id,
                    },
                };
            },
        },
        mounted() {
            //
        },
        methods: {
            handlerEmit() {
                if (!this.notification.read_at) {
                    this.notification.read_at = moment().unix();
                }

                EventBus.$emit('update:notification', this.notification);
            },
        },
    };
</script>
