<template>
    <MForm
        class="ResetForm"
        @submit.prevent="resetPassword"
    >
        <transition name="fade">
            <div
                v-if="hasError"
                class="ResetForm__error"
            >
                <ul>
                    <template
                        v-for="error in errors"
                        :key="error.value"
                    >
                        <li>
                            {{ _head(error) }}
                        </li>
                    </template>
                </ul>
            </div>
        </transition>
        <MFieldset class="ResetForm__text-container--is-bordered">
            {{ __('auth:enter_a_new_password_for_colon') }}
        </MFieldset>
        <MFieldset class="ResetForm__input-container--is-bordered">
            <FontAwesomeIcon
                class="ResetForm__input-icon"
                icon="fa-regular fa-lock"
            />
            <input
                v-model="password"
                autofocus
                class="ResetForm__input"
                :placeholder="__('auth:new_password')"
                required
                type="password"
            />
        </MFieldset>
        <MFieldset class="ResetForm__input-container--is-bordered">
            <FontAwesomeIcon
                class="ResetForm__input-icon"
                icon="fa-regular fa-check"
            />
            <input
                v-model="password_confirmation"
                class="ResetForm__input"
                :placeholder="__('auth:confirm_password')"
                required
                type="password"
            />
        </MFieldset>
        <MFieldset class="ResetForm__password-strength-meter-container">
            {{ __('auth:password_requirements_colon') }}
            <PasswordStrengthMeter
                v-model:valid="strengthPassword"
                class="ResetForm__password-strength-meter"
                :form-sent="formSent"
                :password="password"
            />
        </MFieldset>
        <MFieldset>
            <AuthButton
                :is-loading="isLoading"
                :label="__('common:actions.reset')"
                @click="resetPassword"
            />
        </MFieldset>
    </MForm>
</template>

<script>
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import AuthButton from '@/modules/auth/components/atoms/AuthButton.vue';
    import PasswordStrengthMeter from '@/modules/coherence-ui/components/PasswordStrengthMeter/PasswordStrengthMeter.vue';
    import route from '@/modules/legacy/libs/ziggy';

    export default {
        components: {PasswordStrengthMeter, AuthButton},
        data: () => ({
            isLoading: false,
            password: '',
            password_confirmation: '',
            errors: [],
            strengthPassword: false,
            formSent: false,
        }),
        computed: {
            hasError() {
                return Object.keys(this.errors).length > 0;
            },
            _head() {
                return _head;
            },
            email() {
                return this.$route.params.email;
            },
        },
        methods: {
            resetPassword() {
                this.formSent = true;
                this.isLoading = true;

                const data = {
                    email: this.email,
                    token: this.$route.params.token,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                };

                useApi()
                    .legacy.post(route('password.reset'), data)
                    .then(() => {
                        this.errors = [];
                        this.isLoading = false;

                        useMagicModal().successModal({
                            title: __('auth:password_reset'),
                            text: __('auth:password_has_been_reset'),
                            confirmButtonText: __('common:understand'),
                            onConfirm: async () => {
                                await this.$router.push({name: 'login'});
                            },
                        });
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            this.errors = error.response.data.errors;
                        }

                        this.isLoading = false;
                    });
            },
        },
    };
</script>

<style scoped>
    .ResetForm {
        @apply tw-gap-0 tw-overflow-hidden tw-rounded-xl tw-bg-white tw-shadow-lg;
    }

    .ResetForm__error {
        @apply tw-rounded-lg tw-bg-red-100 tw-shadow-sm;
        @apply tw-m-4 tw-px-4 tw-py-2;
        @apply tw-text-left tw-font-semibold tw-text-red-500;
    }

    .ResetForm__text-container--is-bordered {
        @apply tw-text-left;
        @apply tw-px-4 tw-py-2;
        @apply tw-border-b-2 tw-border-dashed tw-border-primary-200;
    }

    .ResetForm__input-container--is-bordered {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-px-4;
        @apply tw-border-b-2 tw-border-dashed tw-border-primary-200;
    }

    .ResetForm__password-strength-meter-container {
        @apply tw-text-left;
        @apply tw-px-4 tw-py-2;
    }

    .ResetForm__password-strength-meter {
        @apply tw-pt-2;
    }

    .ResetForm__input-icon {
        @apply tw-px-2 tw-text-2xl tw-text-primary-500;
    }

    .ResetForm__input {
        @apply tw-h-16 tw-w-full;
        @apply tw-tracking-tight tw-outline-none;
        @apply tw-px-4;
        @apply tw-text-2xl tw-text-primary-500 tw-antialiased;

        &::placeholder {
            @apply tw-font-grandhotel tw-text-4xl;
            @apply tw-text-primary-300;
        }
    }
</style>
