<template>
    <div class="EditInvoiceFooter">
        {{ invoice.footer ? invoice.footer : nursery.invoice_footer }}
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {
            invoice: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
    });
</script>

<style scoped>
    .EditInvoiceFooter {
        @apply tw-w-full tw-whitespace-pre-line tw-text-center tw-text-sm;
    }
</style>
