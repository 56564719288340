import {ServiceProvider} from '@meekohq/lumos';

import CallModel from '@/modules/activity/models/CallModel';
import CallPivot from '@/modules/activity/models/CallPivot';
import MeetingModel from '@/modules/activity/models/MeetingModel';
import MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
import MeetingPivot from '@/modules/activity/models/MeetingPivot';
import {GetTaskService} from '@/modules/activity/ticket/application/GetTaskService';
import {GetTasksUseCaseBinding} from '@/modules/activity/ticket/application/use-cases/GetTasksUseCase';
import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import TicketPivot from '@/modules/activity/ticket/domain/TicketPivot';
import TicketRepositoryAdapter from '@/modules/activity/ticket/infrastructure/TicketRepositoryAdapter';

export default class ActivityServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            CallModel,
            CallPivot,
            TicketModel,
            TicketPivot,
            MeetingModel,
            MeetingPivot,
            MeetingParticipantModel,
        ]);
    }

    public register() {
        this.app.bind(GetTasksUseCaseBinding, () => {
            return new GetTaskService(new TicketRepositoryAdapter());
        });
    }
}
