import type {HasMany, QueryBuilder} from '@meekohq/lumos';
import {collect, Epoch} from '@meekohq/lumos';
import {ref} from 'vue';

import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
import TicketTypeValue from '@/modules/activity/utils/TicketTypeValue';
import useManager from '@/modules/app/composables/useManager';

export default function (pivotConstraint: (query: QueryBuilder<any>, relation: HasMany<any, any>) => void) {
    const notes = ref(collect<TicketModel>());

    const {activeOrganization} = useManager();

    const newNote = function () {
        const note = new TicketModel();
        note.attributes.type = TicketTypeValue.note;
        note.attributes.account_id = activeOrganization.value.attributes.account_id;
        note.extra.draftDate = Epoch.now();

        notes.value.push(note);
    };

    const removeNote = function (deletedNote: TicketModel) {
        notes.value = notes.value.reject(note => note.id === deletedNote.id);
    };

    const getNotes = async function () {
        notes.value = await TicketModel.query(query => pivotConstraint(query, new TicketModel().ticketPivots()))
            .where('type', TicketTypeValue.note)
            .with(new TicketModel().ticketPivots())
            .with(new TicketModel().reporter())
            .get();
    };

    return {
        getNotes,
        newNote,
        notes,
        removeNote,
    };
}
