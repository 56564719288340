<template>
    <div>
        <p class="tw-mb-0 tw-text-base tw-text-gray-600">
            <span
                v-html="
                    __('request:absence_of_with_name', {
                        name: `${notification.data.kid_first_name} ${notification.data.kid_last_name}`,
                    })
                "
            />
        </p>
        <p class="tw-mb-0 tw-mt-1 tw-text-sm tw-text-red-500">
            <template v-if="sameDate">
                {{
                    __('common:date_format.human_from_date_from_time_to_time', {
                        date: Epoch.fromTimestamp(notification.data.started_at).toLocaleString(
                            Epoch.presets.DATE_SHORT
                        ),
                        fromTime: Epoch.fromTimestamp(notification.data.started_at).toLocaleString(
                            Epoch.presets.TIME_SIMPLE
                        ),
                        toTime: Epoch.fromTimestamp(notification.data.ended_at).toLocaleString(
                            Epoch.presets.TIME_SIMPLE
                        ),
                    })
                }}
                <br />
                {{ notification.data.content }}
            </template>
            <template v-else>
                <span
                    v-html="
                        __('common:date_format.human_from_to_with_time_and_line_break', {
                            from: Epoch.fromTimestamp(notification.data.started_at).toLocaleString(
                                Epoch.presets.DATE_SHORT
                            ),
                            fromTime: Epoch.fromTimestamp(notification.data.started_at).toLocaleString(
                                Epoch.presets.TIME_SIMPLE
                            ),
                            to: Epoch.fromTimestamp(notification.data.ended_at).toLocaleString(
                                Epoch.presets.DATE_SHORT
                            ),
                            toTime: Epoch.fromTimestamp(notification.data.ended_at).toLocaleString(
                                Epoch.presets.TIME_SIMPLE
                            ),
                        })
                    "
                />
                <br />
                {{ notification.data.content }}
            </template>
        </p>

        <MMenu class="tw-mt-1">
            <MMenuButton>
                <MButton
                    icon-menu
                    :variant="getVariantBtn(notification.state)"
                >
                    <template #left-icons>
                        <FontAwesomeIcon
                            v-if="notification.state === 'accepted'"
                            :fixed-width="true"
                            icon="fa-solid fa-check-circle"
                        />
                        <FontAwesomeIcon
                            v-else
                            :fixed-width="true"
                            icon="fa-solid fa-question-circle"
                        />
                    </template>
                    <template v-if="notification.state === 'accepted'">
                        {{ __('request:validated_absence') }}
                    </template>
                    <template v-else>
                        {{ __('request:pended_absence') }}
                    </template>
                </MButton>
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('request:validate_absence')"
                    @click="accept"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-check-circle"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('common:put_on_hold')"
                    @click="updateState('pending')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-question-circle"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </div>
</template>

<script>
    import {Epoch, Lang} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';

    import {EventBus} from '@/eventBus';
    import useNotificationStore from '@/modules/app/composables/useNotificationStore';
    import useMetrics from '@/modules/app/composables/useRum';
    import KidModel from '@/modules/family/models/KidModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import useEditEventModal from '@/modules/planning/composables/core/useEditEventModal';

    export default {
        props: {
            notification: {type: Object},
        },
        data() {
            return {
                kidId: null,
                organizationId: null,
            };
        },
        computed: {
            Epoch() {
                return Epoch;
            },
            sameDate() {
                return Epoch.fromTimestamp(this.notification.data.started_at).hasSame(
                    Epoch.fromTimestamp(this.notification.data.ended_at),
                    'day'
                );
            },
            notificationBus() {
                return useNotificationStore();
            },
            metrics() {
                return useMetrics();
            },
        },
        async mounted() {
            this.kidId = this.notification.data.kid_id;
            this.organizationId = this.notification.data.nursery_id;

            // Replace legacy id by new uuid
            if (Lang.isNumeric(this.kidId) || Lang.isNumeric(this.organizationId)) {
                const kid = await KidModel.query().where('legacy_id', this.kidId).first();
                const organization = await OrganizationModel.query().where('legacy_id', this.organizationId).first();

                if (kid || organization) {
                    this.kidId = kid.getKey();
                    this.organizationId = organization.getKey();

                    // Add metric to track legacy id usage
                    this.metrics.addAction('M_Notification_Message_Legacy_Id');
                }
            }
        },
        methods: {
            accept() {
                const event = {};
                event.started_at = Epoch.fromTimestamp(this.notification.data.started_at).toISOString();
                event.ended_at = Epoch.fromTimestamp(this.notification.data.ended_at).toISOString();
                event.kid_id = this.notification.data.kid_id;
                event.nursery_id = this.notification.data.nursery_id;
                event.type = 'absence';
                event.note = this.notification.data.content;
                event.contract_hourly_rate = this.notification.data.hourly_rate;
                event.hourly_rate = this.notification.data.hourly_rate;
                event.isNew = true;
                event.rangeAbsence = true;
                event.unit = 'day';
                event.notification = this.notification;

                event.notification = _cloneDeep(this.notification);
                event.notification.state = 'accepted';

                useEditEventModal().show(event);

                if (!this.notification.read_at) {
                    this.notification.read_at = Epoch.now().toTimestamp();
                }
            },

            updateState(state) {
                const myNotif = _cloneDeep(this.notification);
                myNotif.state = state;

                if (myNotif.referenceable) {
                    myNotif.referenceable.state = state;
                }

                EventBus.$emit('updateMessageNotification', myNotif);
                EventBus.$emit('refreshChatMessage', myNotif);
            },

            getVariantBtn(state) {
                if (state === 'accepted') {
                    return 'success';
                } else if (state === 'rejected') {
                    return 'danger';
                }

                return 'primary';
            },
        },
    };
</script>
