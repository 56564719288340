import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';

interface UpdatePersonalAccessTokenNameRequestMakeType {
    model: PersonalAccessTokenModel;
    name: string;
    sudoToken: string;
}

export class UpdatePersonalAccessTokenNameRequest {
    constructor(
        public readonly model: PersonalAccessTokenModel,
        public readonly name: string,
        public readonly sudoToken: string
    ) {}

    public static make(makeType: UpdatePersonalAccessTokenNameRequestMakeType): UpdatePersonalAccessTokenNameRequest {
        return new UpdatePersonalAccessTokenNameRequest(makeType.model, makeType.name, makeType.sudoToken);
    }
}
