<template>
    <MBox class="tw-px-2 tw-py-3 tw-pr-4">
        <div class="tw-flex tw-items-start">
            <div class="tw-w-10 tw-min-w-10 tw-text-center tw-text-xl tw-text-gray-600">
                <div
                    class="tw-m-auto tw-mt-1 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-bg-blue-200"
                >
                    <IconUser class="tw-mt-2 tw-h-6 tw-w-6 tw-fill-current tw-text-blue-500" />
                </div>
            </div>
            <m-form-inplace-editor
                ref="part1"
                :force-edit="
                    hasError(errors, [
                        'second_parent_first_name',
                        'second_parent_last_name',
                        'second_parent_alloc_no',
                        'second_parent_gender',
                    ])
                "
                :input-refs="['second_parent_first_name', 'second_parent_last_name', 'second_parent_alloc_no']"
                :no-edit="!$can('update', 'registrations')"
                :no-edit-btn="!$can('update', 'registrations')"
                @editing="getFocus"
                @keyup.enter="$refs.part1.save()"
                @canceled="$emit('canceled')"
                @save="updateRegistration"
            >
                <div class="tw-flex tw-w-full tw-flex-col tw-leading-tight">
                    <span class="tw-text-lg tw-font-semibold">
                        <span
                            class="tw-mr-1 tw-break-words"
                            :class="{'tw-text-gray-600': !registration.second_parent_first_name}"
                        >
                            {{ registration.second_parent_first_name || __('common:first_name') }}
                        </span>
                        <span
                            class="tw-break-words"
                            :class="{'tw-text-gray-600': !registration.second_parent_last_name}"
                        >
                            {{ registration.second_parent_last_name || __('common:last_name') }}
                        </span>
                    </span>
                    <span class="tw-text-sm tw-font-semibold tw-text-gray-600">
                        {{ registration.second_parent_alloc_no || __('family_member:beneficiary_number') }}
                    </span>
                </div>
                <template #edition>
                    <m-form-group-inplace-inputs>
                        <MLabel class="tw-mb-1">
                            {{ __('family_member:kinship') }}
                        </MLabel>
                        <MSelectMenu
                            v-model="registration.second_parent_role"
                            class="tw-w-full"
                            :has-error="hasError(errors, ['second_parent_gender'])"
                            :options="[
                                {value: 'mother', text: __('common:relationship.parent_female')},
                                {value: 'father', text: __('common:relationship.parent_male')},
                                {value: 'brother', text: __('common:relationship.sibling_male')},
                                {value: 'sister', text: __('common:relationship.sibling_female')},
                                {value: 'uncle', text: __('common:relationship.pibling_male')},
                                {value: 'aunt', text: __('common:relationship.pibling_female')},
                                {value: 'stepmother', text: __('common:relationship.parent_in_law_female')},
                                {value: 'stepfather', text: __('common:relationship.parent_in_law_male')},
                                {value: 'grandparent', text: __('common:relationship.grandparent')},
                                {value: 'legal_custodian_male', text: __('common:relationship.legal_custodian_male')},
                                {
                                    value: 'legal_custodian_female',
                                    text: __('common:relationship.legal_custodian_female'),
                                },
                                {value: 'other', text: __('common:other_one')},
                            ]"
                        />
                        <MLabel class="tw-mb-1 tw-mt-2">
                            {{ __('common:first_name') }}
                        </MLabel>
                        <m-form-input
                            ref="second_parent_first_name"
                            v-model="registration.second_parent_first_name"
                            :class="{'is-invalid': hasError(errors, ['second_parent_first_name'])}"
                            size="sm"
                        />
                        <MLabel class="tw-mb-1 tw-mt-2">
                            {{ __('common:last_name') }}
                        </MLabel>
                        <m-form-input
                            ref="second_parent_last_name"
                            v-model="registration.second_parent_last_name"
                            :class="{'is-invalid': hasError(errors, ['second_parent_last_name'])}"
                            size="sm"
                        />
                        <MLabel class="tw-mb-1 tw-mt-2">
                            {{ __('family_member:beneficiary_number') }}
                        </MLabel>
                        <m-form-input
                            ref="second_parent_alloc_no"
                            v-model="registration.second_parent_alloc_no"
                            :class="{'is-invalid': hasError(errors, ['second_parent_alloc_no'])}"
                            size="sm"
                        />
                    </m-form-group-inplace-inputs>
                </template>
            </m-form-inplace-editor>
        </div>
        <div class="tw-mt-3 tw-flex tw-items-center">
            <div class="tw-flex tw-w-10 tw-min-w-10 tw-justify-center">
                <IconCoins class="tw-h-6 tw-w-6 tw-fill-current tw-text-yellow-500" />
            </div>
            <div class="tw-flex tw-w-full tw-flex-col tw-leading-tight">
                <m-form-inplace-editor
                    ref="part2"
                    :force-edit="hasError(errors, ['second_parent_salary'])"
                    :input-refs="['second_parent_salary']"
                    :no-edit="!$can('update', 'registrations')"
                    :no-edit-btn="!$can('update', 'registrations')"
                    @editing="$refs.second_parent_salary ? $refs.second_parent_salary.$el.focus() : null"
                    @keyup.enter="$refs.part2.save()"
                    @canceled="$emit('canceled')"
                    @save="updateRegistration"
                >
                    <span :class="{'tw-text-gray-600': !registration.second_parent_salary}">
                        {{
                            registration.second_parent_salary
                                ? __('common:annual_income_per_year', {value: registration.second_parent_salary})
                                : __('common:annual_income')
                        }}
                    </span>
                    <template #edition>
                        <m-form-input
                            ref="second_parent_salary"
                            v-model="registration.second_parent_salary"
                            size="sm"
                        />
                    </template>
                </m-form-inplace-editor>
            </div>
        </div>
        <div class="tw-mt-3 tw-flex tw-items-start">
            <div class="tw-flex tw-w-10 tw-min-w-10 tw-justify-center">
                <IconPhone class="tw-h-5 tw-w-5 tw-fill-current tw-text-green-500" />
            </div>
            <div class="tw-flex tw-w-full tw-flex-col tw-leading-tight">
                <m-form-inplace-editor
                    ref="part3"
                    :force-edit="
                        hasError(errors, [
                            'second_parent_phone',
                            'second_parent_mobile_phone',
                            'second_parent_office_phone',
                        ])
                    "
                    :input-refs="['second_parent_phone', 'second_parent_mobile_phone', 'second_parent_office_phone']"
                    :no-edit="!$can('update', 'registrations')"
                    :no-edit-btn="!$can('update', 'registrations')"
                    @keyup.enter="$refs.part3.save()"
                    @canceled="$emit('canceled')"
                    @save="updateRegistration"
                >
                    <div :class="{'tw-text-gray-600': !registration.second_parent_phone}">
                        {{ registration.second_parent_phone || __('common:phone.general') }}
                    </div>
                    <div v-if="registration.second_parent_mobile_phone">
                        {{ registration.second_parent_mobile_phone }}
                    </div>
                    <div v-if="registration.second_parent_office_phone">
                        {{ registration.second_parent_office_phone }}
                    </div>
                    <template #edition>
                        <m-form-group-inplace-inputs>
                            <MLabel class="tw-mb-1">
                                {{ __('common:phone.landline') }}
                            </MLabel>
                            <m-form-input
                                ref="second_parent_phone"
                                v-model="registration.second_parent_phone"
                                :class="{'is-invalid': hasError(errors, ['second_parent_phone'])}"
                                size="sm"
                            />
                            <MLabel class="tw-mb-1 tw-mt-2">
                                {{ __('common:phone.cellular') }}
                            </MLabel>
                            <m-form-input
                                ref="second_parent_mobile_phone"
                                v-model="registration.second_parent_mobile_phone"
                                :class="{'is-invalid': hasError(errors, ['second_parent_mobile_phone'])}"
                                size="sm"
                            />
                            <MLabel class="tw-mb-1 tw-mt-2">
                                {{ __('common:phone.work') }}
                            </MLabel>
                            <m-form-input
                                ref="second_parent_office_phone"
                                v-model="registration.second_parent_office_phone"
                                :class="{'is-invalid': hasError(errors, ['second_parent_office_phone'])}"
                                size="sm"
                            />
                        </m-form-group-inplace-inputs>
                    </template>
                </m-form-inplace-editor>
            </div>
        </div>
        <div class="tw-mt-3 tw-flex tw-items-center">
            <div class="tw-flex tw-w-10 tw-min-w-10 tw-justify-center">
                <IconEmail class="tw-h-6 tw-w-6 tw-fill-current tw-text-blue-500" />
            </div>
            <div class="tw-flex tw-w-full tw-flex-col tw-leading-tight">
                <m-form-inplace-editor
                    ref="part4"
                    :force-edit="hasError(errors, ['second_parent_email'])"
                    :input-refs="['second_parent_email']"
                    :no-edit="!$can('update', 'registrations')"
                    :no-edit-btn="!$can('update', 'registrations')"
                    show-copy-btn
                    :value-to-copy="registration.second_parent_email"
                    @keyup.enter="$refs.part4.save()"
                    @canceled="$emit('canceled')"
                    @save="updateRegistration"
                >
                    <span
                        class="parent-email"
                        :class="{'tw-text-gray-600': !registration.second_parent_email}"
                    >
                        {{ registration.second_parent_email || __('common:email') }}
                    </span>
                    <template #edition>
                        <m-form-input
                            ref="second_parent_email"
                            v-model="registration.second_parent_email"
                            :class="{'is-invalid': hasError(errors, ['second_parent_email'])}"
                            size="sm"
                        />
                    </template>
                </m-form-inplace-editor>
            </div>
        </div>
        <div class="tw-mt-3 tw-flex tw-items-start">
            <div class="tw-flex tw-w-10 tw-min-w-10 tw-justify-center">
                <IconMap class="tw-h-6 tw-w-6" />
            </div>
            <div class="tw-flex tw-w-full tw-flex-col tw-leading-tight">
                <m-form-inplace-editor
                    ref="part5"
                    :force-edit="
                        hasError(errors, [
                            'second_parent_address',
                            'second_parent_city',
                            'second_parent_zipcode',
                            'second_parent_country',
                        ])
                    "
                    :input-refs="['second_parent_address', 'second_parent_city', 'second_parent_zipcode']"
                    :no-edit="!$can('update', 'registrations')"
                    :no-edit-btn="!$can('update', 'registrations')"
                    @keyup.enter="$refs.part5.save()"
                    @canceled="$emit('canceled')"
                    @save="updateRegistration"
                >
                    <span>
                        <span :class="{'tw-text-gray-600': !registration.second_parent_address}">
                            {{ registration.second_parent_address || __('common:address.address') }}
                        </span>
                        <br />
                        <span
                            class="tw-mr-1"
                            :class="{'tw-text-gray-600': !registration.second_parent_zipcode}"
                        >
                            {{ registration.second_parent_zipcode || __('common:address.zip_code') + ' -' }}
                        </span>
                        <span :class="{'tw-text-gray-600': !registration.second_parent_city}">
                            {{ registration.second_parent_city || __('common:address.city') }}
                        </span>
                        <br />
                        <span :class="{'tw-text-gray-600': !registration.second_parent_country}">
                            {{ registration.second_parent_country || __('common:address.country') }}
                        </span>
                    </span>
                    <template #edition>
                        <div class="tw-rounded-lg tw-border tw-border-dashed tw-border-gray-300 tw-p-2">
                            <m-form-group class="tw-mb-2">
                                <MLabel>
                                    {{ __('common:address.address') }}
                                </MLabel>
                                <m-form-input
                                    ref="second_parent_address"
                                    v-model="registration.second_parent_address"
                                    :class="{'is-invalid': hasError(errors, ['second_parent_address'])}"
                                    size="sm"
                                />
                            </m-form-group>
                            <m-form-group class="tw-mb-2">
                                <MLabel>
                                    {{ __('common:address.zip_code') }}
                                </MLabel>
                                <m-form-input
                                    ref="second_parent_zipcode"
                                    v-model="registration.second_parent_zipcode"
                                    :class="{'is-invalid': hasError(errors, ['second_parent_zipcode'])}"
                                    size="sm"
                                />
                            </m-form-group>
                            <m-form-group class="tw-mb-2">
                                <MLabel>
                                    {{ __('common:address.city') }}
                                </MLabel>
                                <m-form-input
                                    ref="second_parent_city"
                                    v-model="registration.second_parent_city"
                                    :class="{'is-invalid': hasError(errors, ['second_parent_city'])}"
                                    size="sm"
                                />
                            </m-form-group>
                            <m-form-group class="tw-mb-0">
                                <MLabel>
                                    {{ __('common:address.country') }}
                                </MLabel>
                                <SelectCountry
                                    v-model="registration.second_parent_country"
                                    :has-error="!!hasError(errors, ['second_parent_country'])"
                                />
                            </m-form-group>
                        </div>
                    </template>
                </m-form-inplace-editor>
            </div>
        </div>
        <transition name="more">
            <div
                v-if="more"
                ref="more"
            >
                <div class="tw-mt-3 tw-flex tw-items-start">
                    <div class="tw-flex tw-w-10 tw-min-w-10 tw-justify-center">
                        <IconCompany class="tw-h-6 tw-w-6" />
                    </div>
                    <m-form-inplace-editor
                        ref="company"
                        :force-edit="
                            hasError(errors, [
                                'second_parent_job',
                                'second_parent_company_name',
                                'second_parent_company_address',
                                'second_parent_company_city',
                                'second_parent_company_zipcode',
                                'second_parent_company_country',
                                'second_parent_company_contract',
                            ])
                        "
                        :input-refs="[
                            'second_parent_job',
                            'second_parent_company_name',
                            'second_parent_company_address',
                            'second_parent_company_city',
                            'second_parent_company_zipcode',
                        ]"
                        :no-edit="!$can('update', 'registrations')"
                        :no-edit-btn="!$can('update', 'registrations')"
                        @keyup.enter="$refs.company.save()"
                        @canceled="$emit('canceled')"
                        @save="updateRegistration"
                    >
                        <div class="tw-flex tw-w-full tw-flex-col tw-leading-tight">
                            <span
                                class="tw-font-semibold"
                                :class="{'tw-text-gray-600': !registration.second_parent_job}"
                            >
                                {{ registration.second_parent_job || __('common:job') }}
                            </span>
                            <span
                                class="tw-text-sm tw-font-semibold"
                                :class="{'tw-text-gray-600': !registration.second_parent_company_name}"
                            >
                                {{ registration.second_parent_company_name || __('common:company_name') }}
                            </span>
                            <span
                                class="tw-text-sm tw-font-semibold tw-text-gray-600"
                                :class="{'tw-text-gray-600': !registration.second_parent_company_address}"
                            >
                                {{ registration.second_parent_company_address || __('common:address.address') }}
                            </span>
                            <span
                                class="tw-text-sm tw-font-semibold tw-text-gray-600"
                                :class="{'tw-text-gray-600': !registration.second_parent_company_city}"
                            >
                                {{ registration.second_parent_company_city || __('common:address.city') }}
                            </span>
                            <span
                                class="tw-text-sm tw-font-semibold tw-text-gray-600"
                                :class="{'tw-text-gray-600': !registration.second_parent_company_zipcode}"
                            >
                                {{ registration.second_parent_company_zipcode || __('common:address.zip_code') }}
                            </span>
                            <span
                                class="tw-text-sm tw-font-semibold tw-text-gray-600"
                                :class="{'tw-text-gray-600': !registration.second_parent_company_country}"
                            >
                                {{ registration.second_parent_company_country || __('common:address.country') }}
                            </span>
                        </div>
                        <template #edition>
                            <div class="tw-rounded-lg tw-border tw-border-dashed tw-border-gray-300 tw-p-2">
                                <m-form-group class="tw-mb-2">
                                    <MLabel>
                                        {{ __('common:company_contract') }}
                                    </MLabel>
                                    <MSelectMenu
                                        v-model="registration.second_parent_company_contract"
                                        class="tw-w-full"
                                        :has-error="hasError(errors, ['second_parent_company_contract'])"
                                        :options="[
                                            {value: true, text: __('common:yes')},
                                            {value: false, text: __('common:no')},
                                        ]"
                                    />
                                    <MLabel>
                                        {{ __('common:company_one') }}
                                    </MLabel>
                                    <m-form-input
                                        ref="second_parent_company_name"
                                        v-model="registration.second_parent_company_name"
                                        :class="{'is-invalid': hasError(errors, ['second_parent_company_name'])}"
                                        size="sm"
                                    />
                                    <MLabel>
                                        {{ __('common:job') }}
                                    </MLabel>
                                    <m-form-input
                                        ref="second_parent_job"
                                        v-model="registration.second_parent_job"
                                        :class="{'is-invalid': hasError(errors, ['second_parent_job'])}"
                                        size="sm"
                                    />
                                    <MLabel>
                                        {{ __('common:address.address') }}
                                    </MLabel>
                                    <m-form-input
                                        ref="second_parent_company_address"
                                        v-model="registration.second_parent_company_address"
                                        :class="{'is-invalid': hasError(errors, ['second_parent_company_address'])}"
                                        size="sm"
                                    />
                                </m-form-group>
                                <m-form-group class="tw-mb-2">
                                    <MLabel>
                                        {{ __('common:address.city') }}
                                    </MLabel>
                                    <m-form-input
                                        ref="second_parent_company_city"
                                        v-model="registration.second_parent_company_city"
                                        :class="{'is-invalid': hasError(errors, ['second_parent_company_city'])}"
                                        size="sm"
                                    />
                                </m-form-group>
                                <m-form-group class="tw-mb-2">
                                    <MLabel>
                                        {{ __('common:address.zip_code') }}
                                    </MLabel>
                                    <m-form-input
                                        ref="second_parent_company_zipcode"
                                        v-model="registration.second_parent_company_zipcode"
                                        :class="{'is-invalid': hasError(errors, ['second_parent_company_zipcode'])}"
                                        size="sm"
                                    />
                                </m-form-group>
                                <m-form-group class="tw-mb-0">
                                    <MLabel>
                                        {{ __('common:address.country') }}
                                    </MLabel>
                                    <SelectCountry
                                        v-model="registration.second_parent_company_country"
                                        :has-error="!!hasError(errors, ['second_parent_company_country'])"
                                    />
                                </m-form-group>
                            </div>
                        </template>
                    </m-form-inplace-editor>
                </div>
            </div>
        </transition>
        <div class="tw--mx-1 tw--mb-2 tw--mr-3">
            <button
                class="tw-mt-2 tw-w-full tw-cursor-pointer tw-rounded-full tw-bg-gray-200 tw-py-1 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-600 hover:tw-bg-gray-300 hover:tw-text-gray-800"
                @click="more = !more"
            >
                {{ __('common:more_informations') }}
                <FontAwesomeIcon
                    class="more-icon tw-ml-1"
                    :class="{'fa-rotate-180': more}"
                    icon="fa-solid fa-caret-down"
                />
            </button>
        </div>
    </MBox>
</template>

<script>
    import {intersection, keys} from 'lodash-es';

    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import IconCoins from '@/modules/registration/assets/icons8-coins.vue';
    import IconCompany from '@/modules/registration/assets/icons8-company.vue';
    import IconEmail from '@/modules/registration/assets/icons8-email.vue';
    import IconMap from '@/modules/registration/assets/icons8-map_marker.vue';
    import IconPhone from '@/modules/registration/assets/icons8-phone.vue';
    import IconUser from '@/modules/registration/assets/icons8-user.vue';

    export default {
        name: 'ParentCard',
        components: {
            SelectCountry,
            IconUser,
            IconPhone,
            IconCoins,
            IconEmail,
            IconMap,
            IconCompany,
        },
        props: {
            errors: {
                type: Object,
                required: false,
            },
            registration: {
                type: Object,
                required: true,
            },
            type: {
                type: String,
                default: 'second_parent',
            },
        },
        emits: ['save', 'canceled'],
        data: () => ({
            more: false,
        }),
        methods: {
            getFocus() {
                this.$refs.second_parent_first_name.$el.focus();
            },
            hasError(errors, inputs) {
                return !!intersection(keys(errors), inputs).length;
            },
            updateRegistration() {
                this.$emit('save', this.registration);
            },
        },
    };
</script>

<style scoped>
    .more-icon {
        transition: transform 200ms ease-out;
    }

    .parent-email {
        display: block;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
