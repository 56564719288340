<template>
    <div>
        <div class="tw-mb-2">
            {{ __('subscription:contact_support_to_cancel') }}
        </div>
        <div>
            {{ __('subscription:subscription_will_continue') }}
        </div>
    </div>
</template>
