<template>
    <c-modal
        :id="modalId"
        ref="modal"
        :header-title="newPost?.id ? __('news:edit_news') : __('news:publish_news')"
        size="3xl"
        @show="onShow"
    >
        <MForm>
            <CFormTwoColumns>
                <MFieldset>
                    <MLabel>{{ __('news:visible_from') }}</MLabel>
                    <MDatePicker
                        v-model="newPost.posted_at"
                        class="tw-w-full"
                        format="yyyy-MM-dd HH:mm"
                        with-time
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('common:end_until') }}</MLabel>
                    <MDatePicker
                        v-model="newPost.expire_at"
                        class="tw-w-full"
                        format="yyyy-MM-dd HH:mm"
                        with-time
                    />
                    <div class="small tw-mt-2 tw-text-gray-400">
                        {{ __('news:let_empty_date') }}
                    </div>
                </MFieldset>
            </CFormTwoColumns>
            <MFieldset>
                <MLabel>{{ __('news:visibility') }}</MLabel>
                <CCheckbox v-model="newPost.staffs_access">
                    {{ __('common:for_team') }}
                </CCheckbox>
                <CCheckbox v-model="newPost.parents_access">
                    {{ __('common:for_parents') }}
                </CCheckbox>
                <CCheckbox v-model="newPost.web_access">
                    {{ __('news:for_website') }}
                </CCheckbox>
                <div v-if="availableOrganizations.length > 1">
                    <hr class="hr tw-mb-2 tw-mt-3" />
                    <MFieldset class="sm:tw-w-1/2">
                        <MLabel>{{ __('common:for_organizations_colon') }}</MLabel>
                        <OrganizationFinder
                            v-model="selectedOrganizations"
                            :inject-query="organizationFinderQuery"
                            multi
                            :multi-minimum="1"
                        />
                    </MFieldset>
                </div>
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('common:title') }}</MLabel>
                <MInput
                    v-model="newPost.title"
                    :placeholder="__('common:title')"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('common:message_one') }}</MLabel>
                <CAlert
                    class="tw-mb-3"
                    remember-hash="htmleditor-tips-posts"
                    size="lg"
                    :title-text="__('news:how_to_enrich_your_news')"
                >
                    <template #icon>
                        <div
                            class="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-300"
                        >
                            <FontAwesomeIcon
                                class="tw-text-lg tw-text-blue-700"
                                icon="fa-solid fa-info"
                            />
                        </div>
                    </template>
                    <div class="tw-mt-3 tw-px-1 tw-text-sm">
                        <div>
                            <FontAwesomeIcon
                                class="tw-mr-1"
                                icon="fa-regular fa-image"
                            />
                            {{ __('news:add_image') }}
                        </div>
                        <div class="tw-mt-1">
                            <FontAwesomeIcon
                                class="tw-mr-1"
                                icon="fa-regular fa-link"
                            />
                            {{ __('news:create_link') }}
                        </div>
                        <div class="tw-mt-2">
                            <code
                                class="tw-mr-1 tw-rounded tw-border tw-border-gray-400 tw-bg-gray-50 tw-p-1 tw-font-bold tw-text-black"
                            >
                                {{ __('news:code_snippet') }}
                            </code>
                            {{ __('news:line_break') }}
                        </div>
                    </div>
                </CAlert>
                <HtmlEditor
                    :id="newPost.id ? 'html-editor' + newPost.id : 'html-editor-new'"
                    v-model="newPost.content"
                    :placeholder="__('common:message_one')"
                    :rows="10"
                />
            </MFieldset>
        </MForm>
        <div
            v-if="!newPost.staffs_access && !newPost.parents_access && !newPost.web_access"
            class="tw-mt-1 tw-text-right tw-text-sm tw-text-gray-500"
        >
            <FontAwesomeIcon
                icon="fa-duotone fa-exclamation-triangle"
                class="tw-mr-1"
            />
            {{ __('news:invisible_post_alert') }}
        </div>
        <template #footer>
            <div class="tw-ml-auto">
                <MButton
                    v-if="newPost.id"
                    class="tw-mr-2"
                    :label="__('common:actions.cancel')"
                    @click="$refs.modal.hide()"
                />
                <MButton
                    :label="newPost.id === null ? __('news:actions.publish_post') : __('news:actions.edit_post')"
                    variant="primary"
                    @click="store"
                />
            </div>
        </template>
    </c-modal>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    import HtmlEditor from './HtmlEditor.vue';

    export default {
        components: {
            OrganizationFinder,
            HtmlEditor,
        },
        props: ['nursery', 'user', 'modalId', 'currentPost'],
        emits: ['postStored', 'postEdited'],
        data: () => ({
            newPost: {},
            userOrganizations: useAuth().user.value.organizations().value(),
            availableOrganizations: [],
            selectedOrganizations: [],
        }),
        computed: {
            organizationFinderQuery() {
                return OrganizationModel.query().whereIn(
                    'id',
                    this.availableOrganizations.map(item => item.id)
                );
            },
        },
        watch: {
            currentPost(val) {
                this.newPost = val;
                this.newPost.posted_at = Epoch.fromTimestamp(val.posted_at).toFormat('yyyy-MM-dd HH:mm');

                if (this.newPost.expire_at) {
                    this.newPost.expire_at = Epoch.fromTimestamp(val.expire_at).toFormat('yyyy-MM-dd HH:mm');
                }
            },
        },
        methods: {
            store() {
                const data = _cloneDeep(this.newPost);
                data.content = window.btoa(unescape(encodeURIComponent(data.content)));
                data.posted_at = data.posted_at ? Epoch.parse(data.posted_at, 'yyyy-MM-dd HH:mm').toTimestamp() : null;
                data.expire_at = data.expire_at ? Epoch.parse(data.expire_at, 'yyyy-MM-dd HH:mm').toTimestamp() : null;
                data.nursery_id = this.nursery.id;
                data.nurseries = [];

                this.selectedOrganizations.forEach(item => {
                    data.nurseries.push({
                        id: item.getKey(),
                        pivot: {
                            staffs_access: data.staffs_access,
                            web_access: data.web_access,
                            parents_access: data.parents_access,
                        },
                    });
                });

                if (data.id === null) {
                    useApi()
                        .legacy.post(route('posts.store'), data)
                        .then(response => {
                            useNotification().success(__('news:news_published_successfully'));
                            this.$emit('postStored', response.data);
                            this.hideModal();
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                } else {
                    useApi()
                        .legacy.put(
                            route('posts.update', {
                                post: data.id,
                            }),
                            data
                        )
                        .then(response => {
                            useNotification().success(__('news:news_edited_successfully'));
                            this.$emit('postEdited', response.data);
                            this.hideModal();
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },

            async onShow() {
                this.availableOrganizations = _cloneDeep(this.user.nurseries).filter(nursery => {
                    return this.$can('create', 'posts', nursery.permissions);
                });

                const {activeOrganization} = useManager();

                if (this.newPost.id) {
                    this.selectedOrganizations = (
                        await OrganizationModel.query()
                            .whereIn(
                                'id',
                                this.newPost.nurseries.map(item => item.id)
                            )
                            .get()
                    ).toArray();
                } else {
                    this.selectedOrganizations = [activeOrganization.value];
                }
            },

            hideModal() {
                this.$refs.modal.hide();
            },
        },
    };
</script>
