import {lumosBindings, lumosVersion, ServiceProvider} from '@meekohq/lumos';

import app from '@/config/app';
import {AxiosApiBinding} from '@/modules/app/utils/AxiosApiBinding';
import {AxiosLegacyApiBinding} from '@/modules/app/utils/AxiosLegacyApiBinding';
import createLegacyAxiosInstance from '@/modules/app/utils/createLegacyAxiosInstance';
import setupAxiosInstance from '@/modules/app/utils/setupAxiosInstance';

export default class HttpServiceProvider extends ServiceProvider {
    public boot() {
        this.app.make(lumosBindings.SetupMqlBinding).interceptors.document.request.use(document => {
            document.meta = {
                app: 'Manager',
                app_version: app.version,
                client: 'Lumos/' + lumosVersion,
            };
        });

        this.app.make(lumosBindings.SetupMqlBinding).interceptors.operation.request.use(operation => {
            operation.meta = {
                source_url: window.location.href,
            };
        });
    }

    public register() {
        this.app.singleton(AxiosApiBinding, app1 => {
            // Retrieve repository axios instance from Lumos
            const api = app1.make(lumosBindings.MqlAxiosBinding);

            // Setup instance with base url, interceptors, etc.
            setupAxiosInstance(api);

            return api;
        });

        this.app.singleton(AxiosLegacyApiBinding, () => {
            return createLegacyAxiosInstance();
        });
    }
}
