<template>
    <section class="SimpleFullPageTemplate">
        <header class="SimpleFullPageTemplate__header">
            <slot name="title" />
            <div class="SimpleFullPageTemplate__actions">
                <slot name="actions" />
            </div>
        </header>
        <div class="SimpleFullPageTemplate__content">
            <slot name="content" />
        </div>
    </section>
</template>

<script lang="ts">
    export default {
        components: {},
        props: {
            variant: {type: String, default: 'base'},
        },
        setup() {
            return {};
        },
    };
</script>

<style scoped>
    .SimpleFullPageTemplate {
        @apply tw-flex tw-flex-1 tw-flex-col;
    }

    .SimpleFullPageTemplate__header {
        @apply tw-rounded-t-inherit;
        @apply tw-flex tw-flex-col tw-justify-between tw-gap-6 md:tw-flex-row;
        @apply tw-mb-8 tw-mt-4;
    }

    .SimpleFullPageTemplate__actions {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-gap-2 md:tw-justify-end;
    }

    .SimpleFullPageTemplate__content {
        @apply tw-flex tw-flex-1 tw-flex-col;
    }
</style>
