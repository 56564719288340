<template>
    <div class="TasksListItem">
        <div class="TasksListItem__task-container">
            <MPopover
                :trigger="resources.length > 1 ? 'hover' : 'none'"
                placement="bottom"
            >
                <div class="TasksListItem__resource-container">
                    <div
                        v-for="(resource, i) in resources.slice(0, 1)"
                        :key="'resource' + i"
                        class="TasksListItem__resource"
                    >
                        <FontAwesomeIcon
                            :class="resource.iconColor"
                            :icon="resource.icon"
                        />
                    </div>
                    <div
                        v-if="resources.length > 1"
                        class="TasksListItem__badge"
                    >
                        +{{ resources.length - 1 }}
                    </div>
                </div>
                <template #content>
                    <div class="TasksListItem__popper">
                        <div
                            v-for="(resource, i) in resources"
                            :key="'resource' + i"
                            class="TasksListItem__resource TasksListItem__resource--is-stackable"
                        >
                            <FontAwesomeIcon
                                :class="resource.iconColor"
                                :icon="resource.icon"
                            />
                        </div>
                    </div>
                </template>
            </MPopover>
            <MTooltip class="TasksListItem__task-name-container">
                <CText>
                    {{ name }}
                </CText>
                <template #content>
                    {{ name }}
                </template>
            </MTooltip>
        </div>
        <MTooltip class="TasksListItem__date-container">
            <CText
                font-size="sm"
                font-weight="semibold"
                :variant="isLate ? 'danger' : 'gray'"
            >
                {{
                    useDuration().toHuman({
                        dateFrom: Epoch.now(),
                        dateTo: Epoch.fromISOString(date),
                        options: ({
                            autoSuffix: true,
                        }),
                    })
                }}
            </CText>
            <template #content>
                <div class="TasksListItem__date-tooltip">
                    {{ Epoch.fromISOString(date).toLocaleString(Epoch.presets.DATETIME_SHORT) }}
                </div>
            </template>
        </MTooltip>
    </div>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import useDuration from '@/modules/app/composables/useDuration';
    import type {ResourceInterface} from '@/modules/app/composables/useResource';

    export default defineComponent({
        props: {
            resources: {
                type: Array as PropType<ResourceInterface[]>,
                required: true,
            },
            date: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            isLate: {
                type: Boolean,
                required: true,
            },
        },
        setup() {
            return {
                Epoch,
            };
        },
        methods: {useDuration},
    });
</script>

<style scoped>
    .TasksListItem {
        @apply tw-flex tw-items-center tw-justify-between tw-gap-6 tw-border-b tw-border-gray-200 tw-pb-2;
    }

    .TasksListItem__task-container {
        @apply tw-flex tw-items-center tw-gap-5;
    }

    .TasksListItem__resource-container {
        @apply tw-relative;
    }

    .TasksListItem__resource {
        @apply tw-rounded-full;
        @apply tw-bg-white;
        @apply tw-border tw-border-gray-100;
        @apply tw-h-7 tw-w-7;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-text-sm;
    }

    .TasksListItem__resource--is-stackable {
        @apply tw--ml-1;
    }

    .TasksListItem__badge {
        @apply tw-absolute;
        @apply tw--right-2.5 tw--top-1.5;
        @apply tw-h-5 tw-w-5;
        @apply tw-rounded-full;
        @apply tw-bg-gray-100;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-text-xs;
    }

    .TasksListItem__task-name-container {
        @apply tw-flex tw-max-w-40 tw-items-center tw-truncate;
    }

    .TasksListItem__popper {
        @apply tw-p-1 tw-pl-2;
        @apply tw-flex tw-flex-row tw-items-center tw-justify-start;
    }

    .TasksListItem__date-container {
        @apply tw-flex tw-max-w-36 tw-items-center tw-truncate hover:tw-cursor-help;
    }

    .TasksListItem__date-tooltip {
        @apply tw-whitespace-nowrap;
    }
</style>
