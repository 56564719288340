export default {
    id: 0,
    name: 'Configuration par défaut',
    funding: 'paje', // none, psu, paje, psu_cmg
    default: false,

    billing: {
        package: false, // afficher en forfait
        cycle: 'day', // day, halfday, hour
        charged_at: 'end', // start, end
        qty: 0, // La quantité à facturer
        due_at: 10, // delai de paiement
        hours_in_day: 10, // nombre d'heure dans une journée
        weeks_in_month: 4, // nombre de semaine dans un mois
        charge_closing: false, // Facturer ou pas les fermetures de la structure
        charge_holidays: false, // Facturer ou pas les jours fériés
        line_name: 'Frais de garde (contrat)', // afficher en forfait
        company_footer: null, // Pied de page de facture entreprise
        company_conditions: null, // Conditions et réglement sur les factures entreprises
        show_kid_name_on_company_invoice: true, // Afficher le nom de l'enfant sur les factures entreprises
        show_employee_name_on_company_invoice: true, // Afficher le nom de l'employé sur les factures entreprises
        planning: {
            smoothing: {
                active: false, // Arondir les heures
                brackets: {
                    charged_from: [{value: 30}, {value: 60}],
                    charged_hours: [{value: 30}, {value: 60}],
                },
                sum_start_and_end: false, // on sommes le debut et la fin des arrondis
                between_opening: true, // Arrondis sur les heures d'ouvertures
            },
        },
    },

    smoothing: {
        on: 'year', // year, contract, none
        smooth_on: 'qty', // On peux lisser sur les quantités ou sur le tarif (qty,price,none)
        weeks_in_contract: 52, // Nombre de semaine dans le contrat
        closing_weeks: 5, // Nombre de semaine de fermeture
        months: 12, // Nombre de mois pour le lissage sur l'année
        deduct_vacations: false, // Déduire les vacances dans le lissage
    },

    fees: [],

    overruns: {
        occasional: {
            charged: false, // Facturé les dépassement
            amount: null, // Facturé par tranche de 60 minutes
            charge_on: 'current_period',
            brackets: {
                charged_from: [{value: 15}, {value: 60}, {value: 120}, {value: 120}],
                charged_hours: [{value: 0}, {value: 1}, {value: 2}, {value: 3}],
            }, // Facturer par tranche de ..
        },
        recurrent: {
            charged: false, // Facturé les dépassement
            amount: null, // Facturé par tranche de 60 minutes
            charge_on: 'current_period',
            brackets: {
                charged_from: [{value: 15}, {value: 60}, {value: 120}, {value: 120}],
                charged_hours: [{value: 0}, {value: 1}, {value: 2}, {value: 3}],
            }, // Facturer par tranche de ..
        },
        adaptation: {
            charged: false, // Facturé les dépassement
            amount: null, // Facturé par tranche de 60 minutes
            brackets: {
                charged_from: [{value: 15}, {value: 60}, {value: 120}, {value: 120}],
                charged_hours: [{value: 0}, {value: 1}, {value: 2}, {value: 3}],
            }, // Facturer par tranche de ..
        },
    },

    occasionals: {
        per: 'hour',
        amount: null,
        charge_on: 'current_period',
    },

    adaptations: {
        per: 'hour',
        amount: null,
        deduct_planning_on: 'current_period', // current_period, smoothing, none
        charge_on: 'current_period', // current_period, smoothing
    },

    deposit: {
        type: 'month', // fixed, month
        amount: 0,
        month_coef: 1, // Permet de facturer X mois
        auto_charge: false, // Encaisser sur la première facture
        refund: false, // Restituer sur la dernière facture
    },

    registration: {
        amount: 0,
        auto_charge: true,
    },

    contract: {
        vacation: 0,
    },

    cmg_certif_id: null,
    cmg: {
        caf_mandate: 'none', // none, individual, collective
        detail_cmg: true,
        brackets: [
            [{value: 967.81}, {value: 483.91}],
            [{value: 834.28}, {value: 417.15}],
            [{value: 700.8}, {value: 350.4}],
        ],
        brackets_increased: [
            [{value: 1258.16}, {value: 629.09}],
            [{value: 1084.57}, {value: 542.3}],
            [{value: 911.04}, {value: 455.52}],
        ],
    },

    pricing: {
        amount: null,
        increase: 0, // Majoration du prix
        discount: {
            on_salary: false,
            on_presence: 'none', // day, halfday, hour
            effort_rate: {
                brackets: [
                    {value: 0.0619}, // 2024
                    {value: 0.0516},
                    {value: 0.0413},
                    {value: 0.031},
                    {value: 0.031},
                    {value: 0.031},
                    {value: 0.031},
                    {value: 0.0206},
                ],
                lower_limit: 765.77,
                upper_limit: 7000.0,
                disabled_child_coef: 2,
            }, // Tableau taux d'effort pour PSU
            // brackets: [{
            //     more: 2917,
            //     rule: '<=',
            //     levels: [{income: 20550}, {income: 23467}, {income: 26384}],
            // }, {
            //     more: 6482,
            //     rule: '<=',
            //     levels: [{income: 45666}, {income: 52148}, {income: 58630}],
            // }, {
            //     more: 6482,
            //     rule: '>',
            //     levels: [{income: 45666}, {income: 52148}, {income: 58630}],
            // }], // Tableau tranche de revenus 2018
            brackets: [
                {
                    more: 3237,
                    rule: '<=',
                    levels: [{income: 22809}, {income: 26046}, {income: 29283}],
                },
                {
                    more: 7195,
                    rule: '<=',
                    levels: [{income: 50686}, {income: 57881}, {income: 65076}],
                },
                {
                    more: 7195,
                    rule: '>',
                    levels: [{income: 50686}, {income: 57881}, {income: 65076}],
                },
            ], // Tableau tranche de revenus 2024
            prices: {
                unit: 'none',
                brackets: [[{value: 9}]],
            }, // Tableau dégresif (tableau de prix dégressif en fonction des presences et des tranches de revenus)
            tax_credit: 3500,
        },
        constraints: {
            max_price: 10,
        },
    },

    absences: {
        charge_on: 'current_period',
    },
};
