<script setup lang="ts">
    defineProps({
        canDelete: {
            type: Boolean,
            default: false,
            required: false,
        },
    });

    const emit = defineEmits(['delete']);
</script>

<template>
    <div class="MAvatarEditor">
        <slot />
        <div class="MAvatarEditor__overlay tw-group">
            <FontAwesomeIcon
                class="MAvatarEditor__overlay-icon"
                icon="fa-solid fa-pencil"
            />
        </div>
        <div
            v-if="canDelete"
            class="MAvatarEditor__delete-button"
            @click.stop="emit('delete')"
        >
            <FontAwesomeIcon
                class="MAvatarEditor__delete-button-icon"
                icon="fa-solid fa-times"
            />
        </div>
    </div>
</template>

<style scoped>
    .MAvatarEditor {
        @apply tw-relative tw-aspect-square;
    }

    .MAvatarEditor__overlay {
        @apply tw-absolute tw-inset-0;
        @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-center;
        @apply tw-transition-all hover:tw-bg-primary-500/60;
        @apply tw-rounded-[25%];
    }

    .MAvatarEditor__overlay-icon {
        @apply tw-text-xl tw-text-white tw-opacity-0;
        @apply tw-transition-all group-hover:tw-opacity-100;
    }

    .MAvatarEditor__delete-button {
        @apply tw-absolute tw--right-1 tw--top-1 tw-cursor-pointer;
        @apply tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-500;
        @apply tw-transition-all hover:tw-scale-110;
    }

    .MAvatarEditor__delete-button-icon {
        @apply tw-text-sm tw-text-white;
    }
</style>
