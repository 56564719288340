import {collect} from '@meekohq/lumos';
import moment from 'moment';

import type {OpeningHourValue} from '@/modules/human-resources/utils/calendar/OpeningHourValue';
import type SplittableEvent from '@/modules/human-resources/utils/calendar/SplittableEvent';
import {HourValue} from '@/modules/legacy/libs/periodSplitter/HourValue';
import SliceEvent from '@/modules/legacy/libs/periodSplitter/SliceEvent';

// Moment equivalent opening hour weekday
enum weekdays {
    mon = 1,
    tue = 2,
    wed = 3,
    thu = 4,
    fri = 5,
    sat = 6,
    sun = 7,
}

export type FilterCallbackType = (event: SplittableEvent) => boolean;

export default class ConstrainEventsInOpeningHours {
    protected openingHours: OpeningHourValue[] = [];
    protected timezone: string | null = null;

    public constructor(openingHours: OpeningHourValue[] = [], timezone: string | null = null) {
        this.openingHours = openingHours;
        this.timezone = timezone;
    }

    public setFilterCallback(filterCallback: FilterCallbackType) {
        this._filterCallback = filterCallback;

        return this;
    }

    public getEvents(events: SplittableEvent | SplittableEvent[]): SplittableEvent[] {
        const constrainEvents = Array.isArray(events) ? events : [events];

        if (this.openingHours.length) {
            let eventsInOpeningHours: SplittableEvent[] = [];

            // Keep absence events
            const keepedEvents = collect(constrainEvents)
                .filter(item => !this._filterCallback(item))
                .toArray();

            // Slice by opening hours the filter events only
            const constrainedEventDate = collect(constrainEvents)
                .filter(item => this._filterCallback(item))
                .toArray();

            for (const openingHour of this.openingHours) {
                eventsInOpeningHours = eventsInOpeningHours.concat(
                    this.constrainSplittableEventsInOpeningHour(constrainedEventDate as SplittableEvent[], openingHour)
                );
            }

            return eventsInOpeningHours.concat(keepedEvents as SplittableEvent[]);
        }

        return constrainEvents;
    }

    protected _filterCallback: FilterCallbackType = () => true;

    protected constrainSplittableEventsInOpeningHour(events: SplittableEvent[], openingHour: OpeningHourValue) {
        const eventsMatching = collect(events).filter(
            item => moment.unix(item.startedAt).isoWeekday() === weekdays[openingHour.getDay()]
        );

        if (eventsMatching.isEmpty()) {
            return [];
        }

        return new SliceEvent(
            eventsMatching.toArray() as SplittableEvent[],
            this.mapOpeningHourToHourValue(openingHour).from,
            this.mapOpeningHourToHourValue(openingHour).to
        ).slice();
    }

    protected mapOpeningHourToHourValue(value: OpeningHourValue) {
        return {
            from: new HourValue(
                value.getOpeningHour(),
                value.getOpeningMinute(),
                value.getOpeningSecond(),
                this.timezone
            ),
            to: new HourValue(
                value.getClosingHour(),
                value.getClosingMinute(),
                value.getClosingSecond(),
                this.timezone
            ),
        };
    }
}
