<script setup lang="ts">
    import useModal from '@/modules/app/composables/useModal';
    import MeekoFamilyReportingModalModeration from '@/modules/meeko-family/components/MeekoFamilyReportingModalModeration.vue';
    import MeekoFamilyReportingTestimonial from '@/modules/meeko-family/components/MeekoFamilyReportingTestimonial.vue';

    defineProps<{
        confirmedAccess: boolean;
    }>();

    const emit = defineEmits(['confirmed']);

    async function showModerationModal() {
        useModal({
            component: MeekoFamilyReportingModalModeration,
            listeners: modal => ({
                confirmed: () => {
                    emit('confirmed');
                    modal.hide();
                },
            }),
        }).show();
    }
</script>

<template>
    <div class="MeekoFamilyReportingHeader">
        <MHeading
            level="h3"
            class="tw-shrink-0"
        >
            <div v-html="__('reporting:reporting_distribution_text')" />
        </MHeading>
        <MBox class="MeekoFamilyReportingHeader__testimonial">
            <MeekoFamilyReportingTestimonial />
        </MBox>
        <MButton
            v-if="!confirmedAccess"
            class="sm:tw-w-fit"
            variant="danger"
            @click="showModerationModal"
        >
            <template #left-icons>
                <FontAwesomeIcon icon="fa-solid fa-lock" />
            </template>
            {{ __('reporting:access_advanced_reporting_settings') }}
        </MButton>
    </div>
</template>

<style scoped>
    .MeekoFamilyReportingHeader {
        @apply tw-flex tw-flex-col tw-gap-4;
    }

    .MeekoFamilyReportingHeader__testimonial {
        @apply tw-p-4 2xl:tw-col-span-2 2xl:tw-col-start-4;
    }
</style>
