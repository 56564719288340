import {Model, ServiceProvider} from '@meekohq/lumos';

import IconModel from '@/modules/app/models/IconModel';
import {loadToDom, loadToGlobal} from '@/modules/app/utils/loadZiggyRoute';

export default class AppServiceProvider extends ServiceProvider {
    public async boot() {
        // Wait for route file loaded
        if (this.app.isTest) {
            await loadToGlobal();
        } else {
            await loadToDom();
        }

        Model.preventLazyLoading(this.app.isDevelopment);

        this.loadModelsFrom([IconModel]);
    }

    public register() {
        //
    }
}
