<template>
    <CModal
        :header-title="__('common:actions.add_task')"
        :hide-footer="true"
        visible
        @hidden="$emit('hidden')"
    >
        <TaskForm
            :task="taskModel"
            @saved="saved($event)"
            @undo="$emit('undo')"
        />
    </CModal>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';

    import TaskForm from '@/modules/activity/components/TaskForm.vue';
    import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
    import TicketTypeValue from '@/modules/activity/utils/TicketTypeValue';

    export default defineComponent({
        components: {TaskForm},
        props: {},
        emits: ['hidden', 'undo', 'saved'],
        setup(props, {emit}) {
            const taskModel = ref(new TicketModel());
            taskModel.value.attributes.type = TicketTypeValue.task;

            function saved(task) {
                emit('saved', task);
            }

            return {
                taskModel,
                saved,
            };
        },
    });
</script>
