<template>
    <div class="tw-text-center print:tw-hidden">
        <MMenu>
            <MMenuButton>
                <MButton
                    icon-menu
                    variant="light"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-duotone fa-calendar-alt" />
                    </template>
                    {{ currentDisplay }}
                </MButton>
            </MMenuButton>
            <MMenuItems>
                <router-link :to="{name: 'planning.kids', params: {nursery: nursery.id}, query: $route.query}">
                    <MMenuItem>
                        {{ __('planning:display_daily') }}
                    </MMenuItem>
                </router-link>
                <router-link :to="{name: 'planning.kids.weekly', params: {nursery: nursery.id}, query: $route.query}">
                    <MMenuItem>
                        {{ __('planning:display_weekly') }}
                    </MMenuItem>
                </router-link>
                <router-link
                    :to="{
                        name: 'planning.kids.monthly',
                        params: {nursery: nursery.id, date: date.clone().format('DD/MM/Y')},
                        query: $route.query,
                    }"
                >
                    <MMenuItem>
                        {{ __('planning:display_monthly') }}
                    </MMenuItem>
                </router-link>
                <router-link :to="{name: 'planning.kids.yearly', params: {nursery: nursery.id}, query: $route.query}">
                    <MMenuItem>
                        {{ __('planning:display_yearly') }}
                    </MMenuItem>
                </router-link>
            </MMenuItems>
        </MMenu>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            nursery: {},
            date: {},
            currentDisplay: {},
        },
    };
</script>
