import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

export default class StaffError extends ErrorHandler {
    protected resolver(): any {
        return {
            first_name: {
                '0x2EAA809FB3': 'hr_staff:errors.first_name.required',
                '0x2EAA628B5E': 'hr_staff:errors.first_name.filled',
                '0x2EAA618990': 'hr_staff:errors.first_name.string',
            },
            last_name: {
                '0x2EAA809FB3': 'hr_staff:errors.last_name.required',
                '0x2EAA628B5E': 'hr_staff:errors.last_name.filled',
                '0x2EAA618990': 'hr_staff:errors.last_name.string',
            },
            email: {
                '0x2EAA817032': 'hr_staff:errors.email.format',
            },
        };
    }
}
