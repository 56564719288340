export class HourValue {
    protected hour: number;
    protected minute: number;
    protected second: number;
    protected timezone: string | null = null;

    public constructor(hour = 0, minute = 0, second = 0, timezone: string | null = null) {
        if (!this.validate(hour, minute, second)) {
            throw new Error('Bad format');
        }
        this.hour = hour;
        this.minute = minute;
        this.second = second;
        this.timezone = timezone;
    }

    public validate(hour: number, minute: number, second: number): boolean {
        let valid = true;

        if (hour < 0 || hour > 24 || minute < 0 || minute > 59 || second < 0 || second > 59) {
            valid = false;
        }

        return valid;
    }

    public getValue(): string {
        return `${this.hour}:${this.minute}:${this.second}`;
    }

    public getHour(): number {
        return this.hour;
    }

    public getMinute(): number {
        return this.minute;
    }

    public getSecond(): number {
        return this.second;
    }

    public getTimezone(): string | null {
        return this.timezone;
    }

    public toObject() {
        return {
            hour: this.hour,
            minute: this.minute,
            second: this.second,
        };
    }
}
