import _includes from 'lodash-es/includes';
import moment from 'moment';

export const invoice = {
    methods: {
        rateWarning(nursery, invoice) {
            // Returns warning if rate is greater than the limit
            const total = this.getRate(nursery, invoice);

            return total > 0 && total > nursery.hour_price_limit;
        },

        hoursWarning(nursery, invoice) {
            // Returns warning if total hours is greater than the limit
            const total: any = this.getTotalHours(
                invoice.hours_in_day ? invoice.hours_in_day : nursery.hours_in_day,
                invoice.lines
            );

            return total > 0 && total < nursery.min_hours_in_month;
        },

        getRate(nursery, invoice) {
            if (!invoice.lines) {
                return 0;
            }

            let rate: any = 0;

            const typesToInclude = ['recurrent', 'occasional', 'absence', 'adaptation', 'overrun', 'cmg'];
            nursery.incl_meals_in_price ? typesToInclude.push('meals') : null;
            nursery.incl_health_in_price ? typesToInclude.push('health') : null;
            const hours_in_day = invoice.hours_in_day ? invoice.hours_in_day : nursery.hours_in_day;
            const totalHours: any = this.getTotalHours(hours_in_day, invoice.lines);
            let total = 0;

            invoice.lines.forEach(line => {
                // De not count free absence (included in contract)
                if (line.type === 'absence' && line.price === 0) {
                    return null;
                }
                // Only lines that should be included in rate
                else if (_includes(typesToInclude, line.type)) {
                    total += line.qty * line.price;
                }
            });

            rate = totalHours && totalHours > 0 ? total / totalHours : null;

            return Math.round(rate * 100) / 100;
        },

        getTotalHours(hoursInDay, lines) {
            const presencesTypes = ['recurrent', 'occasional', 'absence', 'adaptation', 'overrun'];
            let totalHours: any = 0;

            lines.forEach(line => {
                let lineHours = 0;

                // De not count free absence (included in contract)
                if (line.type === 'absence' && line.price === 0) {
                    return null;
                }

                // Increase total hours in month following type
                else if (_includes(presencesTypes, line.type)) {
                    switch (line.unit) {
                        case 'day':
                            lineHours += line.qty * hoursInDay;
                            break;
                        case 'halfday':
                            lineHours += line.qty * (hoursInDay / 2);
                            break;
                        case 'hour':
                            lineHours += line.qty;
                            break;
                        case 'pack':
                            lineHours += line.hours ? line.hours : 0;
                            break;
                        case 'unit':
                            lineHours += line.hours ? line.hours : 0;
                            break;
                        default:
                            lineHours += line.qty * hoursInDay;
                            break;
                    }

                    // If total line is negative, we need to substracte the hours of the line
                    // Else, we need to add the hours of the line
                    if (line.qty * line.price < 0) {
                        totalHours -= Math.abs(lineHours);
                    } else {
                        totalHours += Math.abs(lineHours);
                    }
                }
            });

            return parseFloat(totalHours).toFixed(2);
        },

        // Anomalies
        hasAnomaly(invoice) {
            return this.getExtra(invoice) || this.getDeduction(invoice) || this.getAdaptation(invoice);
        },
        getExtra(invoice) {
            let extra = 0;
            invoice.lines.forEach(line => {
                if (line.billable_type !== 'kid_contract' && line.total > 0 && line.type !== 'adaptation') {
                    extra += line.total;
                }
            });

            return Math.round(extra * 100) / 100;
        },
        getDeduction(invoice) {
            let deduction = 0;
            invoice.lines.forEach(line => {
                if (line.billable_type !== 'kid_contract' && line.total < 0) {
                    deduction += line.total;
                }
            });

            return Math.round(deduction * 100) / 100;
        },
        getAdaptation(invoice) {
            let total = 0;
            invoice.lines.forEach(line => {
                if (line.type === 'adaptation') {
                    total += line.total;
                }
            });

            return Math.round(total * 100) / 100;
        },

        // Invoice display
        changeDueAt(nursery, invoice) {
            const due_at = nursery.invoice_due_at;
            const date = invoice.date;
            if (moment(date).isValid()) {
                invoice.due_date =
                    due_at > 0
                        ? moment(date).add(due_at, 'day').format('YYYY-MM-DD')
                        : moment(date).add(1, 'month').format('YYYY-MM-DD');
            }
        },
    },
};
