<template>
    <div
        ref="transactionListItemEl"
        class="TransactionListItem"
    >
        <MagicIndexItemColumn
            :class="
                showCustomerInformations
                    ? 'TransactionListItem__column-amount'
                    : 'TransactionListItem__column-amount--expanded'
            "
            :grid-enabled="false"
        >
            <TransactionListItemAmount :transaction="transaction" />
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="TransactionListItem__column-actions"
            :grid-enabled="false"
        >
            <MMenu>
                <MMenuButton ref="actionsDropdown">
                    <MButton
                        icon-ellipsis
                        variant="ghost"
                    />
                </MMenuButton>
                <MMenuItems>
                    <DownloadTemplate
                        as="MMenuItem"
                        class="tw-w-full"
                        custom-class="tw-w-full"
                        doc-type="transaction"
                        :item="transaction"
                        :nursery="legacyNursery"
                        :user="user"
                        @downloaded="onDownloaded"
                    />
                    <MMenuItem
                        v-if="can('update', 'transactions')"
                        ref="editButtonRef"
                        :label="__('common:actions.update')"
                        @click="onEditButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-pen"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('update', 'transactions') && !transaction.isFailed"
                        ref="rejectButtonRef"
                        :label="__('common:actions.reject')"
                        variant="danger"
                        @click="onRejectButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-reply"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('update', 'transactions') && transaction.isFailed"
                        ref="restoreButtonRef"
                        :label="__('billing_transaction:actions.dont_reject')"
                        @click="onRestoreButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-check"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="can('delete', 'transactions')"
                        ref="deleteButtonRef"
                        :label="__('common:actions.delete_dots')"
                        variant="danger"
                        @click="onDeleteButtonClicked"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            v-if="alwaysShowAllocations || allocations.length > 1"
            :class="
                showCustomerInformations
                    ? 'TransactionListItem__column-allocations'
                    : 'TransactionListItem__column-allocations--expanded'
            "
            :container-width="width"
            :grid-title="__('billing_transaction:allocation')"
        >
            <div class="TransactionListItem__allocations">
                <TransactionListItemAllocationsProgressBar
                    v-if="transaction.computed.remaining_to_distribute_amount > 0"
                    :transaction="transaction"
                />
                <div class="tw-flex tw-flex-wrap tw-items-baseline tw-gap-2">
                    <InvoiceMiniature
                        v-for="allocation in allocations"
                        :key="allocation.attributes.id"
                        :allocation="allocation"
                        :customer="transaction.customer().value()"
                        :invoice="allocation.extra.invoice"
                    >
                        <TransactionListItemInvoiceDropdownItem :allocation="allocation" />
                    </InvoiceMiniature>
                </div>
            </div>
        </MagicIndexItemColumn>
        <MagicIndexItemColumn
            class="TransactionListItem__column-date"
            :container-width="width"
            :grid-title="__('common:date')"
        >
            <div class="tw-font-medium">
                {{ Epoch.fromISOString(transaction.attributes.date).toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
        </MagicIndexItemColumn>
        <div class="TransactionListItem__column-details">
            <MagicIndexItemColumn
                v-if="paymentMethodName"
                class="TransactionListItem__column-payment-method"
                :container-width="width"
                :grid-title="__('billing_payment:payment_method')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fa-duotone fa-money-check-dollar"
                    />
                    {{ paymentMethodName }}
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                v-if="transaction.attributes.reference"
                class="TransactionListItem__column-reference"
                :container-width="width"
                :grid-title="__('billing_transaction:reference')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fa-duotone fa-receipt"
                    />
                    <MClamp>
                        {{ transaction.attributes.reference }}
                    </MClamp>
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                v-if="transaction.attributes.description"
                class="TransactionListItem__column-description"
                :container-width="width"
                :grid-title="__('common:description')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fa-duotone fa-pencil"
                    />
                    {{ transaction.attributes.description }}
                </div>
            </MagicIndexItemColumn>
        </div>
        <div
            v-if="showCustomerInformations"
            class="TransactionListItem__column-informations"
        >
            <MagicIndexItemColumn
                class="TransactionListItem__column-customer"
                :container-width="width"
                :grid-title="__('common:customer_one')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fa-duotone fa-file-user"
                    />
                    <RouterLink
                        v-if="transaction.customer().value()"
                        :to="{
                            name: 'cashier/customers.show.payments',
                            params: {customer: transaction.customer().value().getKey()},
                        }"
                    >
                        <MButton
                            class="tw-p-0 tw-font-normal"
                            :label="transaction.customer().value().attributes.name"
                            variant="link"
                        />
                    </RouterLink>
                </div>
            </MagicIndexItemColumn>
            <MagicIndexItemColumn
                v-if="transaction.tenant().value() && hasManyOrganizations"
                class="TransactionListItem__column-tenant"
                :container-width="width"
                :grid-title="__('common:organization_one')"
            >
                <div class="TransactionListItem__row">
                    <FontAwesomeIcon
                        class="TransactionListItem__icon"
                        fixed-width
                        icon="fa-duotone fa-house-blank"
                    />
                    {{ hasManyOrganizations ? transaction.tenant().value().attributes.name : undefined }}
                </div>
            </MagicIndexItemColumn>
        </div>
    </div>
</template>

<script lang="ts">
    import {collect, Epoch, MqlUnprocessableEntityError} from '@meekohq/lumos';
    import {useElementSize} from '@vueuse/core';
    import type {ComponentPublicInstance, PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import useAuth from '@/modules/app/composables/useAuth';
    import type {MagicMassSelectType} from '@/modules/app/composables/useMagicMassSelect';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useManager from '@/modules/app/composables/useManager';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import InvoiceMiniature from '@/modules/cashier/payment/infrastructure/components/InvoiceMiniature/InvoiceMiniature.vue';
    import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
    import TransactionStatusValue from '@/modules/cashier/transaction/domain/TransactionStatusValue';
    import TransactionListItemAllocationsProgressBar from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemAllocationsProgressBar.vue';
    import TransactionListItemAmount from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemAmount.vue';
    import TransactionListItemInvoiceDropdownItem from '@/modules/cashier/transaction/infrastructure/components/TransactionListItemInvoiceDropdownItem.vue';
    import TransactionModalEdit from '@/modules/cashier/transaction/infrastructure/components/TransactionModalEdit.vue';
    import TransactionModalReject from '@/modules/cashier/transaction/infrastructure/components/TransactionModalReject.vue';
    import useRejectRestoreTransaction from '@/modules/cashier/transaction/infrastructure/components/useRejectRestoreTransaction';
    import {mapPaymentToAllocation} from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import MagicIndexItemColumn from '@/modules/magic-index/components/atoms/MagicIndexItemColumn.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {
            TransactionListItemInvoiceDropdownItem,
            InvoiceMiniature,
            TransactionListItemAllocationsProgressBar,
            MagicIndexItemColumn,
            TransactionListItemAmount,
            DownloadTemplate,
        },
        props: {
            transaction: {
                type: Object as PropType<TransactionModel>,
                required: true,
            },
            organization: {
                type: Object as PropType<OrganizationModel>,
                required: true,
            },
            payment: {
                type: Object as PropType<PaymentModel>,
                required: false,
                default: undefined,
            },
            massSelect: {
                type: Object as PropType<MagicMassSelectType>,
                default: undefined,
                required: false,
            },
            showCustomerInformations: {
                type: Boolean,
                default: false,
            },
            alwaysShowAllocations: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['restored', 'rejected', 'updated', 'deleted'],
        setup(props, {emit}) {
            const user = useAuth().user;
            const {legacyNursery, hasManyOrganizations} = useManager();

            const isRejecting = ref(false);

            const actionsDropdown = ref();
            const statusReference = ref();

            const editButtonRef = ref<ComponentPublicInstance>();
            const deleteButtonRef = ref<ComponentPublicInstance>();
            const rejectButtonRef = ref<ComponentPublicInstance>();
            const restoreButtonRef = ref<ComponentPublicInstance>();

            const {can} = useAbility();

            const allocations = computed(() => {
                const data = collect();

                // Transaction can have multiple allocation on same payment
                props.transaction.allocations.each(allocation => {
                    data.push(mapPaymentToAllocation(allocation));
                });

                return data;
            });

            const canOpenActions = computed(() => {
                return !props.massSelect || !props.massSelect.isEnabled.value;
            });

            const transactions = computed(() => collect(props.transaction));

            const {bus, reject, restore, loading} = useRejectRestoreTransaction();

            bus.on('restored', () => emit('restored', props.transaction));
            bus.on('rejected', () => emit('rejected', props.transaction));
            bus.on('error', error => {
                // TODO: Remplacer par une erreur métier et non MQL
                if (error instanceof MqlUnprocessableEntityError && error.operation.result.status === 422) {
                    useNotification().error(
                        __('billing_transaction:errors.restore_transaction', {
                            context: props.transaction.isCredit ? 'credit' : 'debit',
                        })
                    );
                } else {
                    useNotification().error(__('common:errors.generic'));
                }
            });

            const editModal = useModal({
                component: TransactionModalEdit,
                props: {
                    payment: props.payment,
                    transactionId: props.transaction.getKey(),
                },
                listeners: modal => ({
                    updated(event) {
                        emit('updated', event);
                        modal.hide();
                    },
                }),
            });

            function onEditButtonClicked() {
                editModal.show();
            }

            async function onDeleteButtonClicked() {
                await useMagicModal().deleteConfirmationModal({
                    text: __('billing_transaction:delete_transaction_ask'),
                    onConfirm: async () => {
                        loading.value = true;
                        await props.transaction.delete();
                        emit('deleted', props.transaction);
                        loading.value = false;
                    },
                });
            }

            async function onRestoreButtonClicked() {
                await useMagicModal().confirmationModal({
                    text: __('billing_transaction:ask_restore_transaction', {
                        context: props.transaction.isCredit ? 'credit' : 'debit',
                    }),
                    onConfirm: () => {
                        restore(transactions.value);
                        transactions.value.each(transaction => {
                            transaction.attributes.status = TransactionStatusValue.succeeded;
                        });
                    },
                });
            }

            function handleReject(failureDate: string) {
                reject(transactions.value, Epoch.fromISOString(failureDate));
                transactions.value.each(transaction => {
                    transaction.attributes.status = TransactionStatusValue.failed;
                });
            }

            function onRejectButtonClicked() {
                useModal({
                    component: TransactionModalReject,
                    props: {
                        transaction: props.transaction,
                        isLoading: isRejecting,
                    },
                    listeners: modal => ({
                        confirmed: (value: string) => {
                            isRejecting.value = true;
                            handleReject(value);
                            isRejecting.value = false;
                            modal.hide();
                        },
                        dismissed: () => {
                            modal.hide();
                        },
                    }),
                }).show();
            }

            function onDownloaded() {
                actionsDropdown.value?.closePopover();
            }

            const paymentMethodName = computed(() => props.transaction.paymentMethod().value()?.attributes.name);

            const transactionListItemEl = ref(null);
            const {width} = useElementSize(transactionListItemEl);

            return {
                width,
                transactionListItemEl,
                paymentMethodName,
                actionsDropdown,
                editButtonRef,
                deleteButtonRef,
                rejectButtonRef,
                restoreButtonRef,
                allocations,
                legacyNursery,
                user,
                loading,
                hasManyOrganizations,
                can,
                canOpenActions,
                reject,
                restore,
                Epoch,
                onEditButtonClicked,
                onDeleteButtonClicked,
                onRestoreButtonClicked,
                onRejectButtonClicked,
                onDownloaded,
                statusReference,
                handleReject,
            };
        },
    });
</script>

<style scoped>
    .TransactionListItem {
        @apply tw-grid tw-grid-cols-24 tw-items-center tw-gap-3;
        container-name: TransactionListItem;
        container-type: inline-size;
    }

    .TransactionListItem__column-amount {
        @apply tw-col-span-20;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-7;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionListItem__column-amount--expanded {
        @apply tw-col-span-20;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-13;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionListItem__column-allocations {
        @apply tw-col-span-full;
        @apply tw-order-none;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-order-last;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-order-none tw-col-span-7;
        }
    }

    .TransactionListItem__column-allocations--expanded {
        @apply tw-col-span-full;
        @apply tw-order-none;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-order-last;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-order-none tw-col-span-12;
        }
    }

    .TransactionListItem__allocations {
        @apply tw-flex tw-flex-1 tw-flex-col tw-gap-2;
        @apply tw-border-dashed;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-flex-none tw-border-t tw-pt-2;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-flex-none tw-border-0 tw-pt-0;
        }
    }

    .TransactionListItem__column-date {
        @apply tw-col-span-full;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-4;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-3;
        }
    }

    .TransactionListItem__column-details {
        @apply tw-grid tw-grid-cols-2 tw-gap-2;
        @apply tw-col-span-full;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-6 tw-gap-0.5;
        }
        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-5;
        }
    }

    .TransactionListItem__column-payment-method {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-reference {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-description {
        @apply tw-col-span-full;
    }

    .TransactionListItem__column-informations {
        @apply tw-grid tw-grid-cols-2 tw-gap-2;
        @apply tw-col-span-full;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-6 tw-gap-0.5;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-col-span-5;
        }
    }

    .TransactionListItem__column-customer {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-tenant {
        @apply tw-col-span-1;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-col-span-full;
        }
    }

    .TransactionListItem__column-actions {
        @apply tw-col-span-4;
        @apply tw-order-none;
        @apply tw-text-right;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-order-5 tw-col-span-1;
        }

        @container TransactionListItem (min-width: 64rem) {
            @apply tw-order-last;
        }
    }

    .TransactionListItem__icon {
        @apply tw-hidden tw-text-gray-500;

        @container TransactionListItem (min-width: 48rem) {
            @apply tw-block;
        }
    }

    .TransactionListItem__row {
        @apply tw-flex tw-items-baseline tw-gap-2;
    }
</style>
