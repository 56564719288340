<template>
    <MModal
        :modal="modal"
        size="7xl"
    >
        <template #header>
            <div class="ContractEditModal__header">
                {{ __('family_contract:contract_number', {number: contract.no}) }}
                <support-meeko :ressource="contract" />
            </div>
        </template>

        <MTabs
            default-tab="terms"
            :tabs="tabsArray"
        >
            <template #tab-content="{label, meta}">
                <div class="tw-truncate">
                    {{ label }}
                </div>
                <MAlertBadge :visible="!!meta.hasError" />
            </template>
            <template #default="{activeTab}">
                <div class="tw-mt-4">
                    <ModuleTerms
                        v-if="activeTab === 'terms'"
                        :contract="contract"
                        :disabled="disabled"
                        :kid="kid"
                        :organization="nursery"
                    />
                    <ModulePlanning
                        v-if="activeTab === 'planning'"
                        :contract="contract"
                        :disabled="disabled"
                        :nursery="nursery"
                    />
                    <ModuleAdaptation
                        v-if="activeTab === 'adaptation' && hasAdaptation"
                        :contract="contract"
                        :nursery="nursery"
                    />
                    <ModuleRessources
                        v-if="activeTab === 'ressources'"
                        :contract="contract"
                        :kid="kid"
                        :organization="nursery"
                    />
                    <ModuleBilling
                        v-show="activeTab === 'billing'"
                        :cashier-payload="cashierPayload"
                        :contract="contract"
                        :kid="kid"
                        :nursery="nursery"
                        :updating="updating"
                        :user="user"
                        :is-active="activeTab === 'billing'"
                    />
                    <ModuleCompany
                        v-if="activeTab === 'company'"
                        :cashier-payload="cashierPayload"
                        :contract="contract"
                        :kid="kid"
                        :nursery="nursery"
                        @has-errors="errors.company = $event"
                    />
                    <ModuleSepa
                        v-if="activeTab === 'sepa'"
                        :contract="contract"
                        :kid="kid"
                        :nursery="nursery"
                        :user="user"
                        @save-before-download-template="saveBeforeDownloadTemplate"
                    />
                </div>
            </template>
        </MTabs>
        <template #footer-start>
            <DownloadTemplate
                v-if="contract.config && $can('read', 'invoices')"
                class="tw-mr-auto"
                :disabled="updating"
                doc-type="contract"
                :item="contract"
                :loading="loading"
                :nursery="nursery"
                :user="user"
                wait-for-callback
                @save-before-download-template="saveBeforeDownloadTemplate"
            />
        </template>
        <template #footer-end="{hide}">
            <MButton
                variant="light"
                @click="hide"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <MButton
                v-if="contract.draft"
                :disabled="hasErrors"
                :loading="updating || isGeneratingInvoices"
                @click="saveAsDraft"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-file-pen" />
                </template>
                {{ __('family_contract:save_in_draft') }}
            </MButton>
            <MButton
                v-if="contract.draft"
                :disabled="hasErrors"
                :loading="updating || isGeneratingInvoices"
                variant="primary"
                @click="warnSave"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-check" />
                </template>
                {{ __('family_contract:confirm_contract_dots') }}
            </MButton>
            <MButton
                v-else
                :disabled="hasErrors"
                :loading="updating || isGeneratingInvoices"
                variant="primary"
                @click="save(0)"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-save" />
                </template>
                {{ __('family_contract:save_contract') }}
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';

    import {EventBus} from '@/eventBus';
    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useLegacyKidContractErrors} from '@/modules/family/composables/kid/useLegacyKidContractErrors.ts';
    import LegacyContractIntegrationService from '@/modules/family/utils/kid/LegacyContractIntegrationService';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import MAlertBadge from '@/modules/meeko-ui/components/MAlertBadge.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    import Adaptation from './Modal/Adaptation.vue';
    import Company from './Modal/Company.vue';
    import Tarifs from './Modal/Facturation.vue';
    import Modalites from './Modal/Modalites.vue';
    import Planning from './Modal/Planning.vue';
    import Ressources from './Modal/Ressources.vue';
    import Sepa from './Modal/Sepa.vue';

    export default {
        components: {
            MAlertBadge,
            ModuleTerms: Modalites,
            ModulePlanning: Planning,
            ModuleAdaptation: Adaptation,
            ModuleRessources: Ressources,
            ModuleBilling: Tarifs,
            ModuleSepa: Sepa,
            ModuleCompany: Company,
            DownloadTemplate,
        },
        props: ['kid', 'nursery', 'contract', 'disabled', 'user', 'modal'],
        emits: ['closed'],
        data() {
            return {
                loading: false,
                updating: false,
                isGeneratingInvoices: false,
                cashierPayload: {
                    companyOrderModel: undefined,
                    familyOrderModel: undefined,
                    familyEndpoints: [],
                    companyEndpoints: [],
                    familyModel: undefined,
                },
                errors: {
                    company: false,
                },
            };
        },
        computed: {
            tabsArray() {
                const tabs = {
                    terms: {
                        label: __('common:terms'),
                        meta: {
                            icon: 'fa-solid fa-pencil',
                        },
                    },
                };

                if (this.contract.type === 'recurrent') {
                    tabs.planning = {
                        label: __('common:planning_one'),
                        meta: {
                            icon: 'fa-solid fa-calendar',
                        },
                    };

                    if (this.hasAdaptation) {
                        tabs.adaptation = {
                            label: __('common:adaptation_other'),
                            meta: {
                                icon: 'fa-solid fa-users',
                            },
                        };
                    }
                }

                if (this.$can('read', 'kids_contracts')) {
                    tabs.ressources = {
                        label: __('common:resources'),
                        meta: {
                            icon: 'fa-solid fa-suitcase',
                        },
                    };
                }

                if (this.$can('read', 'invoices')) {
                    tabs.billing = {
                        label: __('common:billing'),
                        meta: {
                            icon: 'fa-solid fa-wallet',
                        },
                    };

                    if (this.contract.company) {
                        tabs.company = {
                            label: __('common:company_one'),
                            meta: {
                                icon: 'fa-solid fa-city',
                                hasError: this.errors.company,
                            },
                        };
                    }

                    tabs.sepa = {
                        label: __('common:sepa_direct_debit'),
                        meta: {
                            icon: 'fa-solid fa-university',
                        },
                    };
                }

                return tabs;
            },
            moment() {
                return moment;
            },
            hasAdaptation() {
                return (
                    this.contract.plannings && this.contract.plannings.filter(item => item.type === 'adaptation').length
                );
            },
            hasErrors() {
                return this.errors.company;
            },
        },
        mounted() {
            this.cashierPayload.companyOrderModel = undefined;
            this.cashierPayload.familyOrderModel = undefined;
            this.cashierPayload.familyModel = undefined;
            this.cashierPayload.familyEndpoints = [];
            this.cashierPayload.companyEndpoints = [];

            // Check contract errors on modal open
            this.errors = useLegacyKidContractErrors().getLegacyContractErrors(this.contract);

            EventBus.$on('generate:invoices', this.handleGenerateInvoices);
        },
        beforeUnmount() {
            EventBus.$off('generate:invoices', this.handleGenerateInvoices);
        },
        methods: {
            handleGenerateInvoices() {
                this.generateInvoices();
            },
            warnSave() {
                useMagicModal().confirmationModal({
                    title: __('family_contract:validate_contract_ask'),
                    text: __('family_contract:validate_contract_warning'),
                    type: 'warning',
                    onConfirm: async () => {
                        this.contract.draft = 0;
                        this.save(error => {
                            if (!error) {
                                useNotification().success(__('family_contract:contract_now_validated'));
                                this.modal.hide();
                                this.$emit('closed', this.kid.contracts[this.contract.index]);
                            } else {
                                this.contract.draft = 1;
                            }
                        });
                    },
                });
            },

            /**
             * Save contract and related models
             * @param callback
             */
            save(callback) {
                this.updating = true;

                // Save sepa if newly created
                if (
                    this.contract.temporaryFamilySepa &&
                    this.contract.temporaryFamilySepa.id === null &&
                    this.contract.temporaryCompanySepa &&
                    this.contract.temporaryCompanySepa.id === null
                ) {
                    this.saveSepa(error => {
                        if (error) {
                            this.updating = false;
                        } else {
                            this.saveSepa(
                                error => {
                                    if (error) {
                                        this.updating = false;
                                    } else {
                                        this.save();
                                    }
                                },
                                this.contract.temporaryCompanySepa,
                                true
                            );
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryFamilySepa && this.contract.temporaryFamilySepa.id === null) {
                    this.saveSepa(error => {
                        if (error) {
                            this.updating = false;
                        } else {
                            this.save();
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryCompanySepa && this.contract.temporaryCompanySepa.id === null) {
                    this.saveSepa(
                        error => {
                            if (error) {
                                this.updating = false;
                            } else {
                                this.save();
                            }
                        },
                        this.contract.temporaryCompanySepa,
                        true
                    );
                } else {
                    const data = _cloneDeep(this.contract);

                    if (data.broked_at === undefined) {
                        data.broked_at = null;
                    }

                    if (data.company_cycle === null || data.company_cycle === 0) {
                        data.company_cycle = 1;
                    }

                    if (this.contract.type !== 'recurrent') {
                        data.plannings = [];
                    }

                    if (data.mode === 'manuel') {
                        data.cafpro_resources = null;
                    }

                    // Save billing config then update kid contract
                    this.saveBillingConfig(error => {
                        if (error) {
                            this.updating = false;
                            callback(error);
                        } else {
                            useApi()
                                .legacy.put(
                                    route('kid.contracts.update', {
                                        contract: this.contract.id,
                                    }),
                                    data
                                )
                                .then(async response => {
                                    const contractExtension = new LegacyContractIntegrationService(
                                        this.contract,
                                        this.cashierPayload
                                    );
                                    await contractExtension.postUpdate(response);

                                    this.contract.billing_config_id = response.data.billing_config_id;
                                    set(this.kid.contracts, this.contract.index, response.data);

                                    await useApi().legacy.put(route('kids.update', {kid: this.kid.id}), {
                                        meal_type: this.kid.meal_type,
                                        hygiene: this.kid.hygiene,
                                    });

                                    if (typeof callback === 'function') {
                                        callback();
                                    } else {
                                        useNotification().success(__('family_contract:contract_saved'));
                                        this.modal.hide();
                                        this.$emit('closed', this.kid.contracts[this.contract.index]);
                                    }

                                    this.updating = false;
                                })
                                .catch(error => {
                                    this.updating = false;
                                    if (error?.response?.status === 422) {
                                        _forEach(error.response.data.errors, value => {
                                            useNotification().error(_head(value));
                                        });
                                    } else {
                                        useNotification().error(__('common:errors.generic'));
                                    }
                                    callback(error);
                                });
                        }
                    });
                }
            },

            saveBillingConfig(callback) {
                if (this.contract.billing_config_id && this.contract.config) {
                    useApi()
                        .legacy.put(
                            route('billingConfigs.update', {
                                billingConfig: this.contract.billing_config_id,
                            }),
                            this.contract.config
                        )
                        .then(response => {
                            this.contract.config = response.data;
                            if (typeof callback === 'function') {
                                callback();
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(__('common:errors.generic'));
                            }
                            callback(error);
                        });
                } else {
                    const data = _cloneDeep(this.contract.config);
                    data.contract_id = this.contract.id;
                    useApi()
                        .legacy.post(route('billingConfigs.store'), data)
                        .then(response => {
                            set(this.contract, 'config', response.data);
                            if (typeof callback === 'function') {
                                callback();
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            }

                            callback(error);
                        });
                }
            },

            /*
                Save the contract before executing the callback and download the pdf
             */
            saveBeforeDownloadTemplate(callback) {
                this.loading = true;
                useNotification().info(__('family_contract:saving_contract_before_download_dots'));
                this.save(() => {
                    this.loading = false;
                    useNotification().success(__('family_contract:contract_saved_successfully'));

                    // The callback must return an object with a done method to conform to this signature
                    return callback.done(true);
                });
            },

            saveAsDraft() {
                this.save(error => {
                    if (!error) {
                        useNotification().success(__('family_contract:contract_save_in_draft_successfully'));
                        this.modal.hide();
                        this.$emit('closed', this.kid.contracts[this.contract.index]);
                    }
                });
            },

            saveSepa(callback, sepa, company = false) {
                useApi()
                    .legacy.post(route('sepas.store'), sepa)
                    .then(response => {
                        if (company) {
                            this.contract.company_sepa_mandate_id = response.data.id;
                            this.contract.temporaryCompanySepa = null;
                        } else {
                            this.contract.sepa_mandate_id = response.data.id;
                            this.contract.temporaryFamilySepa = null;
                        }

                        useApi()
                            .legacy.post(
                                route('nurseries.sepas.attach', {
                                    nurseries: this.nursery.id,
                                    sepa: response.data.id,
                                })
                            )
                            .then(() => {
                                callback();
                            })
                            .catch(error => {
                                this.updating = false;
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(__('common:errors.generic'));
                                }
                            });
                    })
                    .catch(error => {
                        this.updating = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(__('common:errors.generic'));
                        }
                        callback(error);
                    });
            },

            generateInvoices() {
                this.save(error => {
                    if (!error) {
                        this.isGeneratingInvoices = true;
                        useNotification().info(__('family_contract:saving_contract_before_creating_invoices_dots'));
                        useApi()
                            .legacy.post(route('kid.contracts.generateInvoices'), {
                                contracts: [this.contract.id],
                            })
                            .then(() => {
                                useNotification().success(__('family_contract:invoices_created_successfully'));
                                EventBus.$emit('invoices:created');
                                this.isGeneratingInvoices = false;
                            })
                            .catch(error => {
                                EventBus.$emit('invoices:creating_error');
                                this.isGeneratingInvoices = false;
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(__('common:errors.generic'));
                                }
                            });
                    } else {
                        EventBus.$emit('invoices:creating_error');
                        this.isGeneratingInvoices = false;
                    }
                });
            },

            getDatesBetween(startDate, endDate) {
                const dates = [];

                const currDate = moment(startDate).startOf('day');
                const lastDate = moment(endDate).startOf('day');

                dates.push(currDate.clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    dates.push(currDate.clone().toDate());
                }

                return dates;
            },
        },
    };
</script>

<style scoped>
    .ContractEditModal__header {
        @apply tw-font-display tw-text-xl tw-font-normal tw-text-primary-950;
    }
</style>
