<template>
    <CButtonGroup>
        <MPopover
            ref="rulePopover"
            @click-outside="resetChanges"
        >
            <MButton :disabled="disabled">
                {{ ruleDetails }}
            </MButton>
            <template #content>
                <div class="tw-max-h-96 tw-overflow-y-auto tw-p-px tw-pr-2">
                    <MFieldset class="tw-mb-4">
                        <MLabel>{{ __('common:frequency') }}</MLabel>
                        <Frequency
                            v-model="ruleCopy.freq"
                            @update:model-value="ruleCopy.reset()"
                        />
                    </MFieldset>
                    <component
                        :is="ruleCopy.freq"
                        :rule="ruleCopy"
                    />
                    <MFieldset class="tw-mt-8">
                        <MLabel>{{ __('common:end_repeat') }}</MLabel>
                        <End :rule="ruleCopy" />
                    </MFieldset>
                    <CHStack
                        class="tw-mt-8"
                        distribute="end"
                    >
                        <MButton @click="cancel">
                            {{ __('common:actions.cancel') }}
                        </MButton>
                        <MButton
                            class="tw-ml-2"
                            variant="primary"
                            @click="validate"
                        >
                            {{ __('common:actions.validate') }}
                        </MButton>
                    </CHStack>
                </div>
            </template>
        </MPopover>
        <MButton
            :disabled="disabled"
            @click="remove"
        >
            <MIconDelete />
        </MButton>
    </CButtonGroup>
</template>

<script lang="ts">
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import Daily from '@/modules/calendar/components/RuleSet/Rule/Daily.vue';
    import End from '@/modules/calendar/components/RuleSet/Rule/End.vue';
    import Frequency from '@/modules/calendar/components/RuleSet/Rule/Frequency.vue';
    import Monthly from '@/modules/calendar/components/RuleSet/Rule/Monthly.vue';
    import Weekly from '@/modules/calendar/components/RuleSet/Rule/Weekly.vue';
    import Yearly from '@/modules/calendar/components/RuleSet/Rule/Yearly.vue';
    import useRuleSetHumanDetails from '@/modules/calendar/composables/RuleSet/useRuleSetHumanDetails';
    import Rule from '@/modules/calendar/utils/RuleSet/Rule';

    export default defineComponent({
        components: {
            Frequency,
            End,
            day: Daily,
            week: Weekly,
            month: Monthly,
            year: Yearly,
        },
        props: {
            rule: {
                type: Object as PropType<Rule>,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['validate', 'cancel', 'remove'],
        setup(props, {emit}) {
            const ruleCopy: Ref<Rule> = ref(props.rule ? Rule.fromJSON(props.rule) : new Rule());
            const rulePopover = ref();

            const {getRecurrenceRuleDetails} = useRuleSetHumanDetails();

            // Translation rule details
            const ruleDetails = computed(() => {
                return getRecurrenceRuleDetails(ruleCopy.value);
            });

            // Validation of cnange
            function validate() {
                emit('validate', ruleCopy.value);
                rulePopover.value?.closePopover();
            }

            function resetChanges() {
                ruleCopy.value = Rule.fromJSON(props.rule);
            }

            function cancel() {
                resetChanges();
                emit('cancel', props.rule);
                rulePopover.value?.closePopover();
            }

            function remove() {
                emit('remove', props.rule);
                rulePopover.value?.closePopover();
            }

            watch(
                () => props.rule,
                () => {
                    resetChanges();
                },
                {deep: true}
            );

            return {
                cancel,
                resetChanges,
                remove,
                ruleCopy,
                ruleDetails,
                rulePopover,
                validate,
            };
        },
    });
</script>
