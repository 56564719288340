<script setup lang="ts">
    import {inject, type PropType, provide} from 'vue';

    import {defineFloatingContext, defineFloatingContextBus} from '@/modules/meeko-ui/components/MFloatingContext';

    const props = defineProps({
        bus: {
            type: Object as PropType<ReturnType<typeof defineFloatingContextBus>>,
            required: false,
            default: () => defineFloatingContextBus(),
        },
    });

    const context = defineFloatingContext({
        bus: props.bus,
    });

    const {setParentContext} = context;

    setParentContext(inject<ReturnType<typeof defineFloatingContext> | undefined>('MFloatingContext', undefined));

    provide('MFloatingContext', context);
</script>

<template>
    <div class="MPopover2">
        <slot />
    </div>
</template>
