import GenderValue from '@/modules/family/utils/member/GenderValue';
import MemberRoleValue from '@/modules/family/utils/member/MemberRoleValue';

/**
 * Manage the role of a member.
 */
export default function () {
    /**
     * Get role of family member depending on gender
     * @param {GenderValue} gender
     * @param {MemberRoleValue} role
     * @return {MemberRoleValue}
     */
    function getRoleUpdatedByGender(gender: GenderValue, role: MemberRoleValue) {
        let newRole: MemberRoleValue;

        switch (role) {
            case MemberRoleValue.father:
            case MemberRoleValue.mother:
                newRole = gender === GenderValue.female ? MemberRoleValue.mother : MemberRoleValue.father;
                break;

            case MemberRoleValue.brother:
            case MemberRoleValue.sister:
                newRole = gender === GenderValue.female ? MemberRoleValue.sister : MemberRoleValue.brother;
                break;

            case MemberRoleValue.uncle:
            case MemberRoleValue.aunt:
                newRole = gender === GenderValue.female ? MemberRoleValue.aunt : MemberRoleValue.uncle;
                break;

            case MemberRoleValue.stepmother:
            case MemberRoleValue.stepfather:
                newRole = gender === GenderValue.female ? MemberRoleValue.stepmother : MemberRoleValue.stepfather;
                break;

            case MemberRoleValue.legal_custodian_female:
            case MemberRoleValue.legal_custodian_male:
                newRole =
                    gender === GenderValue.female
                        ? MemberRoleValue.legal_custodian_female
                        : MemberRoleValue.legal_custodian_male;
                break;

            case undefined:
                newRole = gender === GenderValue.female ? MemberRoleValue.mother : MemberRoleValue.father;
                break;

            default:
                newRole = role;
                break;
        }

        return newRole;
    }

    return {
        getRoleUpdatedByGender,
    };
}
