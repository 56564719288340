<template>
    <CHStack class="tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-p-2">
        <CVStack
            class="tw-w-3/5"
            gap="2"
        >
            <CSkeletonItem class="tw-h-5 tw-w-2/4" />
            <CSkeletonItem class="tw-h-10 tw-w-1/3" />
            <CSkeletonItem class="tw-h-5 tw-w-3/4" />
        </CVStack>
        <CVStack
            class="tw-w-2/5 tw-overflow-hidden"
            distribute="center"
            gap="2"
        >
            <CSkeletonItem class="tw-h-3 tw-w-2/3" />
            <CHStack gap="2">
                <CSkeletonItem class="tw-h-5 tw-w-5 tw-shrink-0 tw-rounded-full" />
                <CSkeletonItem class="tw-h-5 tw-w-5 tw-shrink-0 tw-rounded-full" />
                <CSkeletonItem class="tw-h-5 tw-w-5 tw-shrink-0 tw-rounded-full" />
                <CSkeletonItem class="tw-h-5 tw-w-5 tw-shrink-0 tw-rounded-full" />
                <CSkeletonItem class="tw-h-5 tw-w-5 tw-shrink-0 tw-rounded-full" />
            </CHStack>
        </CVStack>
    </CHStack>
</template>
