<script setup lang="ts">
    import {ref} from 'vue';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import PersonalWebsiteEdit from '@/modules/personal-website/PersonalWebsiteEdit.vue';
    import PersonalWebsiteIframe from '@/modules/personal-website/PersonalWebsiteIframe.vue';
    import PersonalWebsiteLink from '@/modules/personal-website/PersonalWebsiteLink.vue';

    const props = defineProps({
        nursery: {
            type: Object,
            required: true,
        },
    });

    const website = ref(props.nursery.websites[0]);
</script>

<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="website">
                {{ __('common:website') }}
            </MPageTitle>
        </template>
        <template #content>
            <MBox class="WebsiteIndex__content">
                <div
                    v-if="website"
                    class="WebsiteIndex__sub-container"
                >
                    <PersonalWebsiteEdit :website="website" />
                    <PersonalWebsiteLink :website="website" />
                    <PersonalWebsiteIframe :website="website" />
                </div>
                <MHeading
                    v-else
                    class="WebsiteIndex__no-website"
                >
                    {{ __('personal_website:no_website') }}
                </MHeading>
            </MBox>
        </template>
    </SimplePageTemplate>
</template>

<style scoped>
    .WebsiteIndex__content {
        @apply tw-p-4;
    }

    .WebsiteIndex__sub-container {
        @apply tw-flex tw-w-fit tw-flex-col tw-gap-8;
    }

    .WebsiteIndex__no-website {
        @apply tw-p-12 tw-text-center;
    }
</style>
