import {Model} from '@meekohq/lumos';

import StaffModel from '@/modules/human-resources/models/StaffModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class StaffsOrganizationsPivot extends Model {
    public type = 'hr/staffs_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        staff_id: string | undefined;
        organization_id: string | undefined;
        visible_on_website: boolean | undefined;
        visible_on_family: boolean | undefined;
        visible_on_team: boolean | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        staff_id: undefined,
        organization_id: undefined,
        visible_on_website: undefined,
        visible_on_family: undefined,
        visible_on_team: undefined,
    };

    staff() {
        return this.belongsTo('staff', StaffModel, 'staff_id');
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }
}
