import {Epoch} from '@meekohq/lumos';

import useManager from '@/modules/app/composables/useManager';

const {activeOrganization} = useManager();

const countryCode = activeOrganization.value?.attributes.address.country_code ?? 'FR';

export default {
    id: null,

    plannings: [],
    contract_started_at: Epoch.now().toFormat('yyyy-MM-dd'),
    contract_ended_at: null,
    contract_recurrent_weeks: 1,
    contract_dependent_children: 1,

    kid_first_name: null,
    kid_last_name: null,
    kid_gender: 'male',
    kid_birthdate: null,
    kid_birthplace: null,

    first_parent_gender: 'female',
    first_parent_role: 'mother',
    first_parent_first_name: null,
    first_parent_last_name: null,
    first_parent_email: null,
    first_parent_birthdate: null,
    first_parent_job: null,
    first_parent_address: null,
    first_parent_city: null,
    first_parent_state: null,
    first_parent_country: countryCode,
    first_parent_zipcode: null,
    first_parent_phone: null,
    first_parent_mobile_phone: null,
    first_parent_office_phone: null,
    first_parent_salary: null,

    first_parent_company_contract: 0,
    first_parent_company_name: null,
    first_parent_company_city: null,
    first_parent_company_country: countryCode,
    first_parent_company_zipcode: null,
    first_parent_company_address: null,
    first_parent_company_email: null,
    first_parent_company_phone: null,

    second_parent_gender: 'male',
    second_parent_role: 'father',
    second_parent_first_name: null,
    second_parent_last_name: null,
    second_parent_email: null,
    second_parent_birthdate: null,
    second_parent_job: null,
    second_parent_address: null,
    second_parent_city: null,
    second_parent_state: null,
    second_parent_country: countryCode,
    second_parent_zipcode: null,
    second_parent_phone: null,
    second_parent_mobile_phone: null,
    second_parent_office_phone: null,
    second_parent_salary: null,

    second_parent_company_contract: 0,
    second_parent_company_name: null,
    second_parent_company_city: null,
    second_parent_company_country: countryCode,
    second_parent_company_zipcode: null,
    second_parent_company_address: null,
    second_parent_company_email: null,
    second_parent_company_phone: null,

    negotiable: 1,
    rejected: false,
    requested_at: null,
    note: null,
    nurseries: [],
};
