<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('app:menu.kids_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <top-actions
                :current-display="__('common:weekly')"
                :date="date"
                :nursery="nursery"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                class="print:tw-hidden"
                :current-display="__('common:weekly')"
                :date="date"
                :nursery="nursery"
                print
            />
            <MBox class="tw-mt-4 tw-p-5">
                <div>
                    <div
                        class="tw-mb-6 tw-flex tw-flex-wrap tw-items-start tw-justify-between sm:tw-flex-nowrap print:tw-hidden"
                    >
                        <MButton
                            class="tw-order-2 tw-shrink-0 sm:tw-order-1"
                            variant="success"
                            @click="
                                date = date.clone().startOf('week').subtract(1, 'weeks');
                                pushQueryDate(date, 'week');
                            "
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                            </template>
                            {{ __('common:longweek_with_number', {week: fromMoment(date).subWeeks(1).weekNumber}) }}
                        </MButton>
                        <div class="tw-order-1 tw-flex tw-basis-full tw-flex-col tw-items-center sm:tw-order-2">
                            <MHeading class="tw-flex tw-gap-2">
                                {{ __('common:longweek_with_number', {week: fromMoment(date).weekNumber}) }}
                                <OccupationBadge
                                    :enable-count="false"
                                    :loading="occupationLoading || loading"
                                    :occupations="occupations"
                                />
                            </MHeading>
                            <div class="tw-mb-1 tw-text-gray-400">
                                {{ fromMoment(date).startOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }} -
                                {{ fromMoment(date).endOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }}
                            </div>
                        </div>
                        <MButton
                            class="tw-order-2 tw-shrink-0 sm:tw-order-3"
                            variant="success"
                            @click="
                                date = date.clone().startOf('week').add(1, 'weeks');
                                pushQueryDate(date, 'week');
                            "
                        >
                            <template #right-icons>
                                <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                            </template>
                            {{ __('common:longweek_with_number', {week: fromMoment(date).addWeeks(1).weekNumber}) }}
                        </MButton>
                    </div>
                    <h6
                        class="h6 tw-mb-12 tw-hidden tw-w-full tw-justify-center tw-pb-6 tw-text-center tw-text-primary-500 print:tw-flex"
                        style="margin-top: -20px"
                    >
                        {{
                            __('common:date_format.human_from_to', {
                                from: fromMoment(date).startOfWeek().toLocaleString(Epoch.presets.DATE_SHORT),
                                to: fromMoment(toDay).endOfWeek().toLocaleString(Epoch.presets.DATE_SHORT),
                            })
                        }}
                        - {{ __('common:longweek_with_number', {week: fromMoment(date).weekNumber}) }}
                    </h6>
                    <div>
                        <loader
                            v-if="loading"
                            light="true"
                            shadow="false"
                        />
                        <div
                            v-else
                            id="context-parent"
                            class="tw-mt-12 tw-flex"
                        >
                            <div class="first-col-day tw-p-0">
                                <div>
                                    <div
                                        class="header-row tw-mb-2 tw-table tw-w-full tw-px-1"
                                        :class="{big: showStaffSummary}"
                                    >
                                        <div
                                            class="kid tw-float-none tw-mb-0 tw-mt-0 sm:tw-float-left sm:tw-mt-4 sm:tw-pr-4"
                                        >
                                            <MMenu class="tw-w-full">
                                                <MMenuButton class="tw-w-full">
                                                    <MButton
                                                        class="tw-w-full"
                                                        icon-menu
                                                        :label="
                                                            currentGroup.id ? currentGroup.name : __('common:all_kids')
                                                        "
                                                        size="sm"
                                                        variant="primary"
                                                    />
                                                </MMenuButton>
                                                <MMenuItems>
                                                    <MMenuItem
                                                        :label="__('common:all_kids')"
                                                        @click="switchGroup()"
                                                    >
                                                        <template #icon>
                                                            <FontAwesomeIcon
                                                                v-if="!currentGroup.id"
                                                                fixed-width
                                                                icon="fa-solid fa-circle-check"
                                                            />
                                                            <FontAwesomeIcon
                                                                v-else
                                                                fixed-width
                                                                icon="fa-regular fa-circle"
                                                            />
                                                        </template>
                                                    </MMenuItem>
                                                    <MMenuDivider />
                                                    <MMenuItem
                                                        v-for="(group, i) in nursery.groups"
                                                        :key="'group' + i"
                                                        :label="group.name"
                                                        @click="switchGroup(group)"
                                                    >
                                                        <template #icon>
                                                            <FontAwesomeIcon
                                                                v-if="currentGroup.id === group.id"
                                                                fixed-width
                                                                icon="fa-solid fa-circle-check"
                                                            />
                                                            <FontAwesomeIcon
                                                                v-else
                                                                fixed-width
                                                                icon="fa-regular fa-circle"
                                                            />
                                                        </template>
                                                    </MMenuItem>
                                                    <MMenuDivider />
                                                    <tags
                                                        v-model:selected-tags="selectedTags"
                                                        class="tw-px-3 tw-py-2"
                                                        filter
                                                        taggable-type="kid"
                                                        :tags="nursery.tags"
                                                    />
                                                </MMenuItems>
                                            </MMenu>
                                        </div>
                                    </div>

                                    <div
                                        v-show="!loading"
                                        style="margin: 0 -0.3rem"
                                    >
                                        <div
                                            v-for="(kid, i) in kids"
                                            :key="'kid' + i"
                                            class="line tw-table tw-h-12 tw-w-full"
                                        >
                                            <div
                                                class="kid tw-relative tw-float-none tw-mb-0 tw-flex tw-h-full tw-items-center tw-font-display sm:tw-float-left"
                                            >
                                                <BadgeTasks
                                                    size="sm"
                                                    :tasks="tasksByKidId[kid.id]"
                                                >
                                                    <LegacyKidAvatar
                                                        class="tw-w-6"
                                                        :kid="kid"
                                                    />
                                                </BadgeTasks>
                                                <div class="name tw-ml-1 tw-font-grandhotel tw-capitalize">
                                                    <router-link
                                                        v-if="$can('read', 'kids_details')"
                                                        :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                                        :to="{
                                                            name: 'kids.show',
                                                            params: {nursery: nursery.id, kid: kid.id},
                                                        }"
                                                    >
                                                        {{ $fullName(kid) }}
                                                    </router-link>
                                                    <span
                                                        v-else
                                                        :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                                    >
                                                        {{ $fullName(kid) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-for="(dayOfWeek, dayIndex) in enumerateDaysBetweenDates"
                                :key="'currentDay' + dayIndex"
                                class="col-day tw-relative tw-grow"
                            >
                                <div>
                                    <div
                                        class="header-row tw-mb-2 tw-table tw-w-full"
                                        :class="{big: showStaffSummary}"
                                    >
                                        <router-link
                                            class="day-text"
                                            :to="{
                                                name: 'planning.kids',
                                                params: {nursery: nursery.id},
                                                query: {date: dayOfWeek.clone().unix()},
                                            }"
                                        >
                                            <h6 class="h6 tw-font-normal tw-text-gray-500">
                                                <div
                                                    class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center lg:tw-flex-row print:tw-hidden"
                                                    :class="{
                                                        'tw-font-semibold tw-text-blue-500': moment(dayOfWeek).isSame(
                                                            date,
                                                            'day'
                                                        ),
                                                    }"
                                                >
                                                    <div>
                                                        {{ $capitalize(fromMoment(dayOfWeek).toFormat('cccc')) }}
                                                    </div>
                                                    <div class="tw-mx-1 tw-hidden lg:tw-block">-</div>
                                                    <div>
                                                        {{ $capitalize(fromMoment(dayOfWeek).toFormat('dd/MM')) }}
                                                    </div>
                                                </div>
                                                <div class="tw-hidden tw-w-full print:tw-block">
                                                    {{ $capitalize(fromMoment(dayOfWeek).toFormat('cccc - dd/MM')) }}
                                                </div>
                                                <OccupationBadge
                                                    class="tw-mt-2"
                                                    :day="dayOfWeek"
                                                    :enable-count="false"
                                                    :loading="occupationLoading"
                                                    :occupations="occupations"
                                                />
                                            </h6>
                                        </router-link>
                                        <div class="planning tw-hidden lg:tw-block">
                                            <div
                                                class="planning summary-bar"
                                                style="margin: 20px -0.8rem 0"
                                            >
                                                <summary-bar
                                                    :class="{'tw-mb-2': showStaffSummary}"
                                                    :closing="closing"
                                                    :day="dayOfWeek"
                                                    :nursery="nursery"
                                                    :opening="opening"
                                                    small
                                                    :summary="weekOccupationRateBar(dayOfWeek)"
                                                />
                                                <summary-bar-staff
                                                    v-if="showStaffSummary"
                                                    :closing="closing"
                                                    :day="dayOfWeek"
                                                    :nursery="nursery"
                                                    :opening="opening"
                                                    small
                                                    :summary="weekOccupationRateBarStaff(dayOfWeek)"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div style="margin: 0 -1.25rem">
                                        <div
                                            v-for="(kid, i) in kids"
                                            :key="'kid' + i"
                                            class="line tw-table tw-h-12 tw-w-full"
                                        >
                                            <div class="tw-pt-3">
                                                <planning
                                                    :closing="closing"
                                                    :date="date"
                                                    :day="dayOfWeek"
                                                    :kid="kid"
                                                    :nursery="nursery"
                                                    :opening="opening"
                                                    :show-menu="$can('update', 'kids_planning')"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <context-menu
                                id="contextMenu"
                                :event="event"
                                :nursery="nursery"
                                :visible="toggleMenu"
                            />
                        </div>
                    </div>
                    <h1
                        v-if="!loading && !Object.keys(kids).length"
                        class="h1 tw-text-center tw-font-grandhotel"
                    >
                        {{ __('common:no_kids') }}
                    </h1>
                </div>
            </MBox>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import {collect, Epoch} from '@meekohq/lumos';
    import {useIntervalFn} from '@vueuse/core';
    import _concat from 'lodash-es/concat';
    import _debounce from 'lodash-es/debounce';
    import _filter from 'lodash-es/filter';
    import _find from 'lodash-es/find';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _map from 'lodash-es/map';
    import moment from 'moment';

    import {EventBus} from '@/eventBus';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import __ from '@/modules/app/utils/i18n-facade';
    import KidModel from '@/modules/family/models/KidModel';
    import ContextMenu from '@/modules/legacy/components/Modules/PlanningGenerator/ContextMenu.vue';
    import SummaryBarStaff from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import SummaryBar from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import {
        getOverrunEvents,
        getPlanningEventsFromKidContracts,
        getPresenceEvents,
    } from '@/modules/legacy/libs/Planning';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import {url} from '@/modules/legacy/mixins/url';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import getClosestOpeningDay from '@/modules/organization/utils/getClosestOpeningDay';
    import OccupationBadge from '@/modules/planning/components/core/OccupationBadge.vue';
    import TopActions from '@/modules/planning/components/kid/TopActions.vue';
    import TopActionsSwitcher from '@/modules/planning/components/kid/TopActionsSwitcher.vue';
    import Planning from '@/modules/planning/components/kid/WeeklyPlanning.vue';

    export default {
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            LegacyKidAvatar,
            'summary-bar': SummaryBar,
            'planning': Planning,
            'top-actions': TopActions,
            ContextMenu,
            SummaryBarStaff,
            OccupationBadge,
            BadgeTasks,
        },
        mixins: [nursery, url],
        props: ['nursery', 'user'],
        data: () => ({
            loading: false,
            occupationLoading: false,
            date: moment(),
            kids: [],
            currentGroup: {},
            occupations: [],
            supervision: [],
            event: {},
            toggleMenu: false,
            showStaffSummary: window.localStorage.getItem('showStaffSummary') === 'true',
            planningDisplayPreference: window.localStorage.getItem('planningDisplayPreference'),
            selectedTags: JSON.parse(localStorage.getItem('tags:kids:planning')),
            useGetTasks: undefined,
            tasks: undefined,
            tasksByKidId: collect(),
            Epoch,
        }),
        computed: {
            moment() {
                return moment;
            },
            fromDay() {
                return this.date.clone().startOf('week');
            },
            toDay() {
                return this.date.clone().endOf('week');
            },
            opening() {
                let hours = this.supervision[0] ? this.supervision[0].summary : [];
                this.occupations[0] ? (hours = hours.concat(this.occupations[0].summary)) : null;

                return this.$getOpening(this.nursery.openingHours, true, false, hours);
            },
            closing() {
                let hours = this.supervision[0] ? this.supervision[0].summary : [];
                this.occupations[0] ? (hours = hours.concat(this.occupations[0].summary)) : null;

                return this.$getClosing(this.nursery.openingHours, true, false, hours);
            },
            enumerateDaysBetweenDates() {
                const dates = [];

                const currDate = moment(this.fromDay).startOf('day');
                const lastDate = moment(this.toDay).startOf('day');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
        },
        watch: {
            'date': function (val, old) {
                if (val.unix() !== old.unix()) {
                    this.loading = true;
                    this.debounceDate();
                }
            },
            'fromDay': function (val, oldVal) {
                if (val.format('YYYY-MM-DD') !== oldVal.format('YYYY-MM-DD')) {
                    this.occupationLoading = true;
                    this.debounceFromDay(val, oldVal);
                }
            },
            'nursery.id': function () {
                this.fetchNursery();
            },
            'selectedTags': {
                handler: _debounce(function (val) {
                    if (val) {
                        localStorage.setItem('tags:kids:planning', JSON.stringify(val));
                    } else {
                        localStorage.removeItem('tags:kids:planning');
                    }

                    this.getKids();
                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                }, 500),
                deep: true,
            },
            '$route.query.date': function (date) {
                this.date = date ? moment.unix(date) : moment();
            },
            'kids': {
                handler: async function (kids) {
                    this.tasks = await this.useGetTasks.getTasks(kids.map(kid => kid.id));
                },
                deep: true,
            },
            'tasks': {
                handler: function () {
                    this.tasksByKidId = this.useGetTasks.groupTasks();
                },
                deep: true,
            },
        },
        created() {
            this.useGetTasks = useGetTasks({
                constrainToResourceModel: KidModel,
            });
        },
        beforeMount() {
            this.date = this.$route.query.date ? moment.unix(this.$route.query.date) : getClosestOpeningDay();
            this.fetchAll();
        },
        mounted() {
            if (this.selectedTags) {
                const existingTags = this.selectedTags.filter(tag => this.nursery.tags.find(item => item.id === tag));
                if (existingTags.length !== this.selectedTags.length) {
                    this.selectedTags = existingTags;
                }
            }

            useIntervalFn(() => {
                // Update events with no end each minute to display the current time
                this.kids.forEach(kid => {
                    if (kid.presences.filter(event => !event.ended_at).length > 0) {
                        kid.events = this.getEvents(kid);
                    }
                });
            }, 1000 * 60);

            useBroadcast().sessionChannel.bind('occupation', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.occupations = response.data;
                    this.occupationLoading = false;
                });
            });

            useBroadcast().sessionChannel.bind('kid_plannings', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.kids = _map(response.data, kid => {
                        _map(kid.presences, presence => {
                            presence.type = 'presence';
                            presence.started_at = presence.droped_at;
                            presence.ended_at = presence.picked_up_at;
                            presence.nursery_id = this.nursery.id;

                            return presence;
                        });

                        _map(kid.absences, absence => {
                            absence.type = 'absence';
                            absence.event_type = 'absence';
                            absence.nursery_id = this.nursery.id;

                            return absence;
                        });

                        kid.events = this.getEvents(kid);

                        return kid;
                    });
                    this.loading = false;
                });
            });

            if (this.showStaffSummary) {
                useBroadcast().sessionChannel.bind('supervision', response => {
                    ProcessedData.retrieve(response.processedDataUrl, response => {
                        this.supervision = response.data;
                        this.occupationLoading = false;
                    });
                });
            }

            this.fetchNursery();
        },
        beforeUnmount() {
            EventBus.$off('clicked:event', this.handleEventClicked);
            EventBus.$off('refresh:kids', this.handleRefreshKidsEvent);

            EventBus.$off('created:absence', this.handleAbsenceCreatedEvent);
            EventBus.$off('updated:absence', this.handleAbsenceUpdatedEvent);
            EventBus.$off('deleted:absence', this.handleAbsenceDeletedEvent);

            EventBus.$off('created:presence', this.handlePresenceCreatedEvent);
            EventBus.$off('updated:presence', this.handlePresenceUpdatedEvent);
            EventBus.$off('deleted:presence', this.handlePresenceDeletedEvent);

            EventBus.$off('created:planning', this.handlePlanningCreatedEvent);
            EventBus.$off('updated:planning', this.handlePlanningUpdatedEvent);
            EventBus.$off('deleted:planning', this.handlePlanningDeletedEvent);

            useBroadcast().sessionChannel.unbind('occupation');
            useBroadcast().sessionChannel.unbind('kid_plannings');
            this.showStaffSummary ? useBroadcast().sessionChannel.unbind('supervision') : null;
        },
        methods: {
            handleEventClicked(event) {
                this.event = event;
                this.event.start_time = moment.unix(this.event.started_at).format('HH:mm:ss');
                this.event.end_time = moment.unix(this.event.ended_at).format('HH:mm:ss');

                this.toggleMenu = true;
                setTimeout(() => {
                    this.toggleMenu = false;
                }, 200);
            },
            handleRefreshKidsEvent() {
                this.getKids();
            },
            handleAbsenceCreatedEvent(absences) {
                if (!absences.length) {
                    absences = [absences];
                }

                absences.forEach(absence => {
                    const kid = this.kids.find(item => item.id === absence.kid_id);
                    if (kid) {
                        absence.type = 'absence';
                        absence.event_type = 'absence';
                        absence.nursery_id = this.nursery.id;
                        kid.absences.push(absence);
                        kid.events = this.getEvents(kid);
                    }
                });

                this.getOccupation();
                this.showStaffSummary ? this.getSupervision() : null;
            },
            handleAbsenceUpdatedEvent(absence) {
                const kid = this.kids.find(item => item.id === absence.kid_id);
                if (kid && kid.absences.length) {
                    const abs = kid.absences.find(item => item.id === absence.id);
                    if (abs) {
                        abs.started_at = absence.started_at;
                        abs.ended_at = absence.ended_at;
                        abs.vacation = absence.vacation;
                        abs.billed = absence.billed;
                        abs.note = absence.note;
                        abs.unit = absence.unit;
                        abs.hours = absence.hours;
                        abs.hourly_rate = absence.hourly_rate;
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                }
            },
            handleAbsenceDeletedEvent(absence) {
                const kid = this.kids.find(item => item.id === absence.kid_id);
                if (kid && kid.absences.length) {
                    const abs = kid.absences.find(item => item.id === absence.id);
                    if (abs) {
                        const i = kid.absences.indexOf(abs);
                        kid.absences.splice(i, 1);
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                }
            },
            handlePresenceCreatedEvent(presence) {
                const kid = this.kids.find(item => item.id === presence.kid_id);
                if (kid) {
                    presence.started_at = presence.droped_at;
                    presence.ended_at = presence.picked_up_at;
                    presence.type = 'presence';
                    presence.nursery_id = this.nursery.id;
                    kid.presences.push(presence);
                    kid.events = this.getEvents(kid);
                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                }
            },
            handlePresenceUpdatedEvent(presence) {
                const kid = this.kids.find(item => item.id === presence.kid_id);
                if (kid && kid.presences.length) {
                    const pre = kid.presences.find(item => item.id === presence.id);
                    if (pre) {
                        pre.droped_at = presence.droped_at;
                        pre.picked_up_at = presence.picked_up_at;
                        pre.started_at = presence.droped_at;
                        pre.ended_at = presence.picked_up_at;
                        pre.drop_note = presence.drop_note;
                        pre.pick_up_note = presence.pick_up_note;

                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                }
            },
            handlePresenceDeletedEvent(presence) {
                const kid = this.kids.find(item => item.id === presence.kid_id);
                if (kid && kid.presences.length) {
                    const pre = kid.presences.find(item => item.id === presence.id);
                    if (pre) {
                        const i = kid.presences.indexOf(pre);
                        kid.presences.splice(i, 1);
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                }
            },
            handlePlanningCreatedEvent(planning) {
                const kid = this.kids.find(item => item.id === planning.kid_id);
                if (kid) {
                    const contract = kid.contracts.find(item => item.id === planning.contract_id);
                    if (contract) {
                        planning.nursery_id = this.nursery.id;
                        contract.plannings.push(planning);
                        kid.events = this.getEvents(kid);
                        this.getOccupation();
                        this.showStaffSummary ? this.getSupervision() : null;
                    }
                } else {
                    this.fetchAll();
                }
            },
            handlePlanningUpdatedEvent(planning) {
                const kid = this.kids.find(item => item.id === planning.kid_id);
                if (kid) {
                    const contract = kid.contracts.find(item => item.id === planning.contract_id);
                    if (contract && contract.plannings.length) {
                        const plan = contract.plannings.find(item => item.id === planning.id);
                        if (plan) {
                            plan.week = planning.week;
                            plan.day = planning.day;
                            plan.start_time = planning.start_time;
                            plan.end_time = planning.end_time;
                            plan.note = planning.note;
                            plan.unit = planning.unit;
                            plan.hours = planning.hours;
                            plan.hourly_rate = planning.hourly_rate;
                        }
                    }

                    kid.events = this.getEvents(kid);

                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                }
            },
            handlePlanningDeletedEvent(planning) {
                const kid = this.kids.find(item => item.id === planning.kid_id);
                if (kid) {
                    const contract = kid.contracts.find(item => item.id === planning.contract_id);
                    if (contract && contract.plannings.length) {
                        const plan = contract.plannings.find(item => item.id === planning.id);
                        if (plan) {
                            const i = contract.plannings.indexOf(plan);
                            contract.plannings.splice(i, 1);
                        }
                    }
                    kid.absences = kid.absences.filter(item => item.planning_id !== planning.id);
                    kid.events = this.getEvents(kid);

                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                }
            },
            fromMoment: useEpoch().fromMoment,
            debounceDate: _debounce(function () {
                this.fetchAll();
            }, 1000),
            debounceFromDay: _debounce(function () {
                if (this.nursery.places && this.nursery.openingHours.length) {
                    this.getOccupation();
                    this.showStaffSummary ? this.getSupervision() : null;
                }
            }, 1000),
            fetchNursery() {
                this.occupationLoading = false;

                if (this.$route.params.date) {
                    this.date = moment(this.$route.params.date, 'DD/MM/Y');
                }

                this.currentGroup = {
                    id: null,
                    name: __('common:all_kids'),
                };

                EventBus.$on('clicked:event', this.handleEventClicked);
                EventBus.$on('refresh:kids', this.handleRefreshKidsEvent);

                EventBus.$on('created:absence', this.handleAbsenceCreatedEvent);
                EventBus.$on('updated:absence', this.handleAbsenceUpdatedEvent);
                EventBus.$on('deleted:absence', this.handleAbsenceDeletedEvent);

                EventBus.$on('created:presence', this.handlePresenceCreatedEvent);
                EventBus.$on('updated:presence', this.handlePresenceUpdatedEvent);
                EventBus.$on('deleted:presence', this.handlePresenceDeletedEvent);

                EventBus.$on('created:planning', this.handlePlanningCreatedEvent);
                EventBus.$on('updated:planning', this.handlePlanningUpdatedEvent);
                EventBus.$on('deleted:planning', this.handlePlanningDeletedEvent);
            },
            getKids() {
                this.loading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                if (this.currentGroup) {
                    useApi()
                        .legacy.get(route('nurseries.planning.kids', {nurseries: this.nursery.id}), {
                            params: {
                                from: this.fromDay.unix(),
                                to: this.toDay.unix(),
                                group_id: this.currentGroup.id ? this.currentGroup.id : null,
                                tags,
                                order_by: window.localStorage.getItem('display:orderBy')
                                    ? window.localStorage.getItem('display:orderBy')
                                    : 'first_name',
                            },
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, function (value) {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },
            getOccupation() {
                this.occupationLoading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi()
                    .legacy.get(route('nurseries.occupation', {nurseries: this.nursery.id}), {
                        params: {
                            from: this.fromDay.unix(),
                            to: this.toDay.unix(),
                            group_id: this.currentGroup.id ? this.currentGroup.id : null,
                            interval: 15,
                            tags,
                        },
                    })
                    .catch(error => {
                        this.occupationLoading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            getSupervision() {
                this.occupationLoading = true;

                let tags = undefined;
                if (this.selectedTags) {
                    tags = this.selectedTags.length ? this.selectedTags : '';
                }

                useApi()
                    .legacy.get(route('nurseries.supervision', {nurseries: this.nursery.id}), {
                        params: {
                            from: this.fromDay.unix(),
                            to: this.toDay.unix(),
                            group_id: this.currentGroup.id ? this.currentGroup.id : null,
                            interval: 15,
                            tags,
                        },
                    })
                    .catch(error => {
                        this.occupationLoading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            switchGroup(group) {
                if (!group) {
                    this.currentGroup = {
                        id: null,
                        name: __('common:all_kids'),
                    };
                } else {
                    this.currentGroup = group;
                }
                this.fetchAll();
            },
            getEvents(kid) {
                const kidPlannings = this.computeKidPlanning(kid);
                const kidPresences = getPresenceEvents(kid.presences, kidPlannings);
                const kidOverruns = getOverrunEvents(kidPresences, kidPlannings);

                return _concat(kidPlannings, kidPresences, kid.absences, kidOverruns);
            },
            getOccupationRate(day) {
                let occupation = null;

                if (this.occupations.length) {
                    occupation = this.occupations.find(o => {
                        return o.date === day.clone().format('YYYY-MM-DD');
                    });
                }

                return occupation ? occupation.percent.toFixed(1) : null;
            },
            weekOccupationRateBar(date) {
                const filter = _filter(this.occupations, occupation => {
                    return occupation.date === moment(date).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            weekOccupationRateBarStaff(date) {
                const filter = _filter(this.supervision, supervision => {
                    return supervision.date === moment(date).format('YYYY-MM-DD');
                });

                if (filter.length) {
                    return _head(filter).summary;
                }

                return [];
            },
            computeKidPlanning(kid) {
                let output = [];
                _forEach(this.enumerateDaysBetweenDates, day => {
                    output = _concat(output, getPlanningEventsFromKidContracts(kid.contracts, day));
                });

                return output;
            },
            fetchAll() {
                this.getKids();
                this.getOccupation();

                if (this.showStaffSummary) {
                    this.getSupervision();
                }
            },
        },
    };
</script>

<style scoped>
    @media screen(sm) {
        .kid {
            width: 120px;
        }

        .first-col-day {
            max-width: 120px;

            .planning {
                width: calc(100% - 170px - 35px);
            }

            .summary-bar {
                margin-left: 170px;
            }
        }
    }

    .selected-day {
        display: none;
    }

    @media print {
        @page {
            size: auto;
            max-width: 100%;
            max-height: 100%;
        }

        body {
            writing-mode: tb-rl; /*landscape*/
        }
    }

    .name {
        position: relative;
        width: 110px;

        overflow: hidden;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .col-day:not(.first-col-day) {
        opacity: 0.8;
        transition: flex-grow 0.2s ease-in-out;
        padding: 0 20px;
        min-width: 100px;

        &:hover {
            flex-grow: 10;
            opacity: 1;
        }
    }

    .header-row {
        height: 40px;

        &.big {
            height: 70px;
        }
    }

    .line {
        padding: 0 0.5rem;

        &:nth-child(even) {
            @apply tw-bg-primary-50;
        }
    }

    .day-text {
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;
        text-align: center;
    }

    .day {
        display: inline-block;
        padding: 1.25rem 0;
        width: 140px;
        @apply tw-bg-gray-200;
        transform: scale(1);
        transition: all 200ms ease-in;
        cursor: pointer;
        margin: 0 5px;

        h1,
        h5 {
            transition: color 200ms;
            @apply tw-text-gray-600;
            font-weight: 400;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            @apply tw-bg-gray-300;

            h1,
            h5 {
                @apply tw-text-gray-700;
            }
        }

        &.active {
            @apply tw-text-primary-500;
            @apply tw-bg-primary-500;

            transform: scale(1.05);
            box-shadow:
                0 0 0 1px theme(colors.primary.500),
                0 1.5px 1px 0 rgba(67, 69, 139, 0.15),
                0 2px 5px 0 rgba(50, 50, 93, 0.1),
                0 1px 2px 0 rgba(0, 0, 0, 0.08),
                0 0 0 0 transparent;

            h1,
            h5 {
                color: white;
            }
        }

        @media (max-width: theme(screens.sm)) {
            h1 {
                font-size: 2rem;
            }

            h5 {
                font-size: 1rem;
            }
        }
    }
</style>
