<template>
    <SelectResource
        ref="SelectResource"
        :builder="builder"
        :button-class="buttonClass"
        :has-unselect="hasUnselect"
        :model="KidsGroupModel"
        :unselect-value="unselectValue"
        :model-value="modelValue"
        @update:model-value="onModelSelected"
        @search="onSearchValue"
    >
        <template #trigger="{toggle}">
            <slot
                name="trigger"
                :toggle="toggle"
                :value="modelValue"
            />
        </template>
        <template #button>
            <template v-if="modelValue && modelValue.attributes.name">
                {{ modelValue.attributes.name }}
            </template>
            <template v-else>
                <slot name="unselectedValue">
                    {{ __('common:search_group') }}
                </slot>
            </template>
        </template>

        <template
            v-if="hasUnselect"
            #unselect-item
        >
            <slot name="unselect-item">
                <FontAwesomeIcon
                    class="tw--ml-1 tw-mr-1"
                    icon="fa-duotone fa-unlink"
                />
                {{ __('common:none') }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <div class="tw-flex tw-pr-4">
                <div
                    class="tw-truncate"
                    v-html="textHighlighter.highlight(searchValue, option.attributes.name)"
                />
            </div>
        </template>
    </SelectResource>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';

    export default defineComponent({
        components: {
            SelectResource,
        },
        props: {
            modelValue: {
                type: Object as PropType<KidsGroupModel>,
            },
            unselectValue: {},
            hasUnselect: {
                type: Boolean,
                default: true,
            },
            buttonClass: {
                type: String,
            },
            organizationId: {
                type: String,
                required: true,
            },
        },
        emits: ['change'],
        setup(props, {emit}) {
            const searchValue = ref('');
            const textHighlighter = new TextHighlighter();

            const builder = computed(() => {
                const query = KidsGroupModel.query();
                query.where('nursery_id', '=', props.organizationId);

                return query;
            });

            function onModelSelected(model: KidsGroupModel) {
                emit('change', model);
            }

            function onSearchValue(val: string) {
                searchValue.value = val;
            }

            return {
                searchValue,
                textHighlighter,
                builder,
                KidsGroupModel,

                onModelSelected,
                onSearchValue,
            };
        },
    });
</script>
