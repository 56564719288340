import type {
    NumericParserContractLocaleOptions,
    NumericParserContractOptions,
} from '@/modules/core/infrastructure/NumericParserContract';

const numericParserLocaleMapping: Record<string, NumericParserContractLocaleOptions> = {
    fr: {
        fractionalSeparator: ',',
        fractionalSeparatorAdditional: '.',
    },
    en: {
        fractionalSeparator: '.',
        thousandsSeparator: ',',
    },
    de: {
        fractionalSeparator: ',',
        fractionalSeparatorAdditional: '.',
    },
};

export function getNumericParserContractLocaleOptions(locale?: string): NumericParserContractLocaleOptions {
    return numericParserLocaleMapping[locale ?? 'fr'];
}

export function getNumericParserContractPresetInteger(locale?: string): NumericParserContractOptions {
    return {
        localeOptions: getNumericParserContractLocaleOptions(locale),
        allowNegative: true,
    };
}

export function getNumericParserContractPresetFloatRoundFourDigits(locale?: string): NumericParserContractOptions {
    return {
        localeOptions: getNumericParserContractLocaleOptions(locale),
        fractionalOptions: {
            mode: 'round',
            minFractionalDigits: 0,
            maxFractionalDigits: 4,
        },
        allowNegative: true,
    };
}

export function getNumericParserContractPresetCurrency(locale?: string): NumericParserContractOptions {
    return {
        localeOptions: getNumericParserContractLocaleOptions(locale),
        fractionalOptions: {
            mode: 'round',
            minFractionalDigits: 2,
            maxFractionalDigits: 2,
        },
        allowNegative: false,
    };
}

export function getNumericParserContractPresetCurrencyPerHour(locale?: string): NumericParserContractOptions {
    return {
        localeOptions: getNumericParserContractLocaleOptions(locale),
        fractionalOptions: {
            mode: 'round',
            minFractionalDigits: 2,
            maxFractionalDigits: 8,
        },
        allowNegative: false,
    };
}
