<script setup lang="ts">
    import {computed} from 'vue';

    import useAuth from '@/modules/app/composables/useAuth';
    import __ from '@/modules/app/utils/i18n-facade';

    defineProps({
        website: {
            type: Object,
            required: true,
        },
    });

    const temporaryToken = computed(() => {
        const {legacyToken} = useAuth();

        return legacyToken.value;
    });
</script>

<template>
    <div class="PersonalWebsiteEdit">
        <MHeading>{{ __('personal_website:view_and_edit_website') }}</MHeading>
        <div class="PersonalWebsiteEdit__subtitle">
            {{ __('personal_website:create_modify_and_delete_elements_on_your_site') }}
        </div>
        <a
            :href="'https://' + website.slug + '.meeko.site/login/' + temporaryToken"
            target="_blank"
        >
            <MButton
                class="PersonalWebsiteEdit__button"
                icon-edit
                :label="__('personal_website:edit_website')"
                variant="primary"
            />
        </a>
    </div>
</template>

<style scoped>
    .PersonalWebsiteEdit__subtitle {
        @apply tw-mt-1 tw-text-gray-500;
    }

    .PersonalWebsiteEdit__button {
        @apply tw-mt-4 tw-w-full md:tw-w-auto;
    }
</style>
