export abstract class BaseError extends Error {
    public readonly message: string;
    public readonly source?: string;

    public abstract readonly code: string;

    protected constructor(message = 'An error occurred.', source?: string) {
        super(message);

        this.message = message;
        this.source = source;
    }
}
