enum CurrencySymbolToIsoCode {
    '€' = 'EUR',
    '$' = 'USD',
    '£' = 'GBP',
    '₪' = 'ILS',
    'XOF' = 'XOF',
    'XAF' = 'XAF',
    'KMF' = 'KMF',
    'CHF' = 'CHF',
    'XPF' = 'XPF',
    'MUR' = 'MUR',
    'MAD' = 'MAD',
}

export default CurrencySymbolToIsoCode;
