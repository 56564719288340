import type {Model} from '@meekohq/lumos';
import {Epoch} from '@meekohq/lumos';
import {isNil} from 'lodash-es';
import _cloneDeep from 'lodash-es/cloneDeep';
import _round from 'lodash-es/round';

import AllocationModel from '@/modules/cashier/models/AllocationModel';
import {AmountToAllocateIsGreaterThanDestinationRemainingError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanDestinationRemainingError';
import {AmountToAllocateIsGreaterThanSourceRemainingError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanSourceRemainingError';
import {AmountToAllocateIsRequired} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsRequired';
import {AmountToAllocateMustBePositiveError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateMustBePositiveError';
import type {SharedRemaingAmount} from '@/modules/cashier/payment/domain/SharedRemaingAmount';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

export abstract class AbstractAllocationAggregate<S extends Model = Model, D extends Model = Model> {
    protected readonly _source: S;

    protected readonly _destination: D;

    protected readonly _sharedRemainingAmount: SharedRemaingAmount;

    protected _allocation: AllocationModel | undefined;

    protected _originalAllocationAmount = 0;

    protected _amount: number | undefined;

    protected constructor(
        source: S,
        destination: D,
        sharedRemainingAmount: SharedRemaingAmount,
        allocation?: AllocationModel
    ) {
        this._source = source;
        this._destination = destination;
        this._sharedRemainingAmount = sharedRemainingAmount;
        this.allocation = allocation;
        this.originalAllocationAmount = this._allocation?.attributes.amount ?? 0;
        this._amount = this._allocation?.attributes.amount ?? 0;
    }

    get amount(): number | undefined {
        return this._amount;
    }

    get sourceKey(): string {
        return this._source.getKey();
    }

    get destinationKey(): string {
        return this._destination.getKey();
    }

    get source(): S {
        return this._source.clone();
    }

    get destination(): D {
        return this._destination.clone();
    }

    get allocation(): AllocationModel | undefined {
        return this._allocation?.clone();
    }

    get allocationAmount(): number {
        return this._allocation?.attributes.amount ?? 0;
    }

    get currencyIsoCode(): string {
        return this._destination.computed.currency_iso_code;
    }

    get isAllocated(): boolean {
        return !isNil(this._allocation?.attributes.amount) && this._allocation!.attributes.amount > 0;
    }

    get originalAllocationAmount(): number {
        return this._originalAllocationAmount;
    }

    get sharedRemainingAmount(): number {
        return this._sharedRemainingAmount.remainingAmount;
    }

    get remainingAmount(): number {
        return _round(this.allocatableRemainingAmount + this.originalAllocationAmount, 2);
    }

    get maxAllocatableAmount(): number {
        if (this.allocationMustBeIgnored) {
            return this.remainingAmount;
        }

        const sharedAllocatableAmount = _round(this._sharedRemainingAmount.remainingAmount + (this.amount ?? 0), 2);

        return Math.min(this.remainingAmount, sharedAllocatableAmount);
    }

    get isAllocatable() {
        if (
            (this._source instanceof TransactionModel && this._source.isFailed) ||
            (this._destination instanceof TransactionModel && this._destination.isFailed)
        ) {
            return false;
        }

        return this.allocatableRemainingAmount > 0;
    }

    abstract get allocatableRemainingAmount(): number;

    abstract get allocatableDate(): Epoch;

    abstract get allocatableReference(): string | undefined;

    abstract get allocatableAmount(): number;

    abstract get allocationMustBeIgnored(): boolean;

    set allocation(value: AllocationModel | undefined) {
        this._allocation = value;
        this.originalAllocationAmount = value?.exists ? value.attributes.amount! : 0;
    }

    set originalAllocationAmount(value: number) {
        this._originalAllocationAmount = value;
    }

    set allocationAmount(value: number) {
        if (!this._allocation) {
            this.initAllocation();
        }

        const oldAmount = this._amount;
        this._amount = value;

        const diff = _round((this._amount ?? 0) - (oldAmount ?? 0), 2);
        if (!this.allocationMustBeIgnored) {
            this._sharedRemainingAmount.adjustRemainingAmount(diff);
        }

        if (isNil(value)) {
            throw new AmountToAllocateIsRequired();
        }

        if (value < 0) {
            throw new AmountToAllocateMustBePositiveError();
        }

        if (value > this.remainingAmount) {
            throw new AmountToAllocateIsGreaterThanSourceRemainingError();
        }

        if (this.sharedRemainingAmount < 0) {
            throw new AmountToAllocateIsGreaterThanDestinationRemainingError();
        }

        this._allocation!.attributes.amount = value;
    }

    public resetAllocationAmount() {
        const oldAmount = this._amount;
        this._allocation!.attributes.amount = this.originalAllocationAmount;
        this._amount = this.originalAllocationAmount;
        this._sharedRemainingAmount.adjustRemainingAmount(_round(this.originalAllocationAmount - (oldAmount ?? 0), 2));
    }

    public copy() {
        return _cloneDeep(this);
    }

    protected initAllocation() {
        if (this._allocation) {
            throw new Error('Allocation already set');
        }

        this.allocation = this.prepareAllocation();
    }

    protected prepareAllocation() {
        const allocationModel = new AllocationModel();
        allocationModel.attributes.tenant_id = this._destination.attributes.tenant_id;
        allocationModel.attributes.customer_id = this._destination.attributes.customer_id;
        allocationModel.attributes.currency_id = this._destination.attributes.currency_id;
        allocationModel.attributes.date = Epoch.now().toISOString();

        allocationModel.source().associate(this._source);
        allocationModel.destination().associate(this._destination);

        return allocationModel;
    }
}
