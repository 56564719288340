<template>
    <MForm @submit.prevent="save">
        <div class="tw--mx-2 tw-flex">
            <MFieldset class="tw-w-2/4 tw-px-2">
                <MLabel>{{ __('common:first_name') }}</MLabel>
                <CInput
                    v-model="contactModel.attributes.first_name"
                    :has-error="contactError.hasError('first_name')"
                    :placeholder="__('common:first_name')"
                />
                <CFormErrorMessageList :errors="contactError.getErrors('first_name')" />
            </MFieldset>
            <MFieldset class="tw-w-2/4 tw-px-2">
                <MLabel>{{ __('common:last_name') }}</MLabel>
                <CInput
                    v-model="contactModel.attributes.last_name"
                    :has-error="contactError.hasError('last_name')"
                    :placeholder="__('common:last_name')"
                />
                <CFormErrorMessageList :errors="contactError.getErrors('last_name')" />
            </MFieldset>
        </div>
        <div class="tw--mx-2 tw-flex">
            <MFieldset class="tw-w-1/2 tw-px-2">
                <MLabel>{{ __('billing_core:department') }}</MLabel>
                <CInput
                    v-model="contactModel.attributes.service"
                    :has-error="contactError.hasError('service')"
                    :placeholder="__('common:accounting')"
                />
                <CFormErrorMessageList :errors="contactError.getErrors('service')" />
            </MFieldset>
            <MFieldset class="tw-w-1/2 tw-px-2">
                <MLabel>{{ __('billing_core:position') }}</MLabel>
                <CInput
                    v-model="contactModel.attributes.position"
                    :has-error="contactError.hasError('position')"
                    :placeholder="__('billing_core:director_female')"
                />
                <CFormErrorMessageList :errors="contactError.getErrors('position')" />
            </MFieldset>
        </div>
        <div class="tw--mx-2 tw--mb-4 tw-flex">
            <MFieldset class="tw-w-1/2 tw-px-2">
                <MLabel>{{ __('common:email_address') }}</MLabel>
                <CInput
                    v-model="contactModel.attributes.email"
                    :has-error="contactError.hasError('email')"
                    :placeholder="__('common:email_example')"
                />
                <CFormErrorMessageList :errors="contactError.getErrors('email')" />
            </MFieldset>
            <MFieldset class="tw-w-1/2 tw-px-2">
                <MLabel>{{ __('common:phone.general') }}</MLabel>
                <CInput
                    v-model="contactModel.attributes.phone"
                    :has-error="contactError.hasError('phone')"
                    :placeholder="__('common:phone.example.general')"
                />
                <CFormErrorMessageList :errors="contactError.getErrors('phone')" />
            </MFieldset>
        </div>
        <CFormErrorMessageList
            v-if="emptyError"
            :errors="collect(__('billing_core:errors.require_one_of_this'))"
        />
        <CFormSubmitTrigger />
    </MForm>
</template>

<script lang="ts">
    import {collect} from '@meekohq/lumos';
    import {defineComponent, type PropType, reactive, ref} from 'vue';

    import ContactModel from '@/modules/cashier/models/ContactModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

    export default defineComponent({
        props: {
            customerModel: {
                type: Object as PropType<CustomerModel>,
                default: undefined,
            },
            contactModel: {
                type: Object as PropType<ContactModel>,
                required: false,
                default: () => reactive(new ContactModel()),
            },
        },
        emits: ['loading', 'saved'],
        setup(props, {emit}) {
            const contactError = ref(new ErrorHandler());
            const emptyError = ref(false);

            function save() {
                emit('loading', true);

                // If all fields are empty, we don't save the contact
                if (
                    !props.contactModel.attributes.first_name &&
                    !props.contactModel.attributes.last_name &&
                    !props.contactModel.attributes.email
                ) {
                    emptyError.value = true;
                    emit('loading', false);

                    return;
                }

                // If we have a customer model, we set the customer_id and tenant_id for the contact
                if (props.customerModel) {
                    props.contactModel.attributes.customer_id = props.customerModel.getKey();
                    props.contactModel.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                }

                props.contactModel
                    .save()
                    .then(() => {
                        emit('loading', false);
                        contactError.value.reset();
                        emptyError.value = false;
                        emit('saved', props.contactModel);
                    })
                    .catch(response => {
                        emit('loading', false);
                        contactError.value.reset(response);
                    });
            }

            return {
                contactError,
                emptyError,
                collect,
                save,
            };
        },
    });
</script>
