<template>
    <div class="EquipmentItem">
        <div class="EquipmentItem__header">
            <div class="EquipmentItem__icon-container">
                <FontAwesomeIcon icon="fal fa-temperature-frigid" />
            </div>
            <MListItemTitle
                class="tw-truncate"
                :title="equipment.name"
            />
        </div>
        <MMenu v-if="can('update', 'equipments') || (equipment.id && can('delete', 'equipments'))">
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    v-if="can('update', 'equipments')"
                    :label="__('common:actions.update')"
                    @click="$emit('edit')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    v-if="equipment.id && can('delete', 'equipments')"
                    :label="__('common:actions.delete_dots')"
                    variant="danger"
                    @click="$emit('delete')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import MListItemTitle from '@/modules/meeko-ui/components/MListItemTitle.vue';

    export default defineComponent({
        components: {
            MListItemTitle,
        },
        props: {
            equipment: {
                type: Object,
                required: true,
            },
        },
        emits: ['delete', 'edit'],
        setup() {
            const {can} = useAbility();

            return {
                can,
            };
        },
    });
</script>

<style>
    .EquipmentItem {
        @apply tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-6;
    }

    .EquipmentItem__header {
        @apply tw-flex tw-items-center tw-gap-2 tw-truncate;
    }

    .EquipmentItem__icon-container {
        @apply tw-h-12 tw-w-12;
        @apply tw-rounded-full tw-bg-blue-100 tw-text-2xl;
        @apply tw-flex tw-shrink-0 tw-items-center tw-justify-center;
    }
</style>
