<template>
    <CVStack
        v-if="overlappingContracts.length > 0"
        gap="2"
    >
        <CFormErrorMessage variant="danger">
            {{ __('hr_contract:errors.save_contract') }}
        </CFormErrorMessage>
        <CText variant="danger">
            {{ __('hr_contract:contract_already_exists_in_selected_organizations') }}
        </CText>

        <ul class="discList tw-ml-5">
            <li
                v-for="contract in overlappingContracts"
                :key="contract.id"
            >
                <CText
                    v-if="contract.attributes.ended_at || contract.attributes.broked_at"
                    variant="danger"
                >
                    <span
                        v-html="
                            __('hr_contract:contract_from_start', {
                                started_at: parse(contract.attributes.started_at, 'y-MM-dd').toLocaleString(
                                    Epoch.presets.DATE_SHORT
                                ),
                                ended_at: parse(
                                    contract.attributes.broked_at || contract.attributes.ended_at,
                                    'y-MM-dd'
                                ).toLocaleString(Epoch.presets.DATE_SHORT),
                            })
                        "
                    />
                </CText>
                <CText
                    v-else
                    variant="danger"
                >
                    <span
                        v-html="
                            __('hr_contract:permanent_contract_that_start_at', {
                                started_at: parse(contract.attributes.started_at, 'y-MM-dd').toLocaleString(
                                    Epoch.presets.DATE_SHORT
                                ),
                            })
                        "
                    />
                </CText>
                <CText variant="danger">
                    {{ __('common:on') }}
                </CText>
                <MTooltip placement="bottom-start">
                    <CText
                        class="tw-lowercase"
                        variant="danger"
                    >
                        <span
                            v-html="
                                __('common:organization_with_count', {count: contract.organizations().value().count()})
                            "
                        />
                    </CText>
                    <template #content>
                        <CVStack
                            class="tw-flex-1"
                            gap="2"
                        >
                            <CText
                                font-size="lg"
                                variant="white"
                            >
                                {{ __('common:organizations_list_colon') }}
                            </CText>
                            <CVStack gap="2">
                                <CHStack
                                    v-for="organization in contract.organizations().value()"
                                    :key="organization.getKey()"
                                    class="tw-flex-1"
                                >
                                    <CText variant="white">
                                        {{ organization.attributes.name }}
                                    </CText>
                                </CHStack>
                            </CVStack>
                        </CVStack>
                    </template>
                </MTooltip>
            </li>
        </ul>
    </CVStack>
</template>

<script lang="ts">
    import {app, Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, onMounted, ref} from 'vue';

    import useEpoch from '@/modules/app/composables/useEpoch';
    import {GetOverlappingContractsUseCaseBinding} from '@/modules/human-resources/contract/application/GetOverlappingContractsUseCase';
    import type ContractModel from '@/modules/human-resources/models/ContractModel';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';

    export default defineComponent({
        props: {
            contractToCheck: {
                type: Object as PropType<ContractModel>,
                required: true,
            },
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
        },
        setup(props) {
            const {parse} = useEpoch();

            const overlappingContracts = ref<ContractModel[]>([]);

            // Load overlapping contracts
            onMounted(async () => {
                overlappingContracts.value = await app(GetOverlappingContractsUseCaseBinding).getFromContract(
                    props.contractToCheck,
                    props.staff
                );
            });

            return {
                overlappingContracts,
                parse,
                Epoch,
            };
        },
    });
</script>

<style scoped>
    .discList {
        list-style: disc !important;
    }
</style>
