export enum DevicePolicy {
    kidAttendances = 'kid_attendances',
    kidKiosk = 'kid_kiosk',
    meals = 'meals',
    activities = 'activities',
    naps = 'naps',
    health = 'health',
    diapers = 'diapers',
    photos = 'photos',
    observations = 'observations',
    staffAttendances = 'staff_attendances',
    staffKiosk = 'staff_kiosk',
    rooms = 'rooms',
    equipment = 'equipment',
    news = 'news',
    documents = 'documents',
    staffNotes = 'staff_notes',
}
