import {Model} from '@meekohq/lumos';

export default class SimulationListPivot extends Model {
    public type = 'planning/simulation_lists_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        list_id: string | undefined;
        resource_id: string | undefined;
        resource_type: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        list_id: undefined,
        resource_id: undefined,
        resource_type: undefined,
    };
}
