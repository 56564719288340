<script setup lang="ts">
    import useAbility from '@/modules/app/composables/useAbility';
    import useIntercom from '@/modules/app/composables/useIntercom';

    const emit = defineEmits(['update:modelValue']);

    defineProps({
        selectedTemplate: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: File,
            default: null,
        },
    });

    const {can} = useAbility();

    const {showArticle} = useIntercom();
</script>

<template>
    <MBox class="DocTemplatesManagerWordFile">
        <div class="DocTemplatesManagerWordFile__form-container">
            <MForm class="DocTemplatesManagerWordFile__form">
                <MFieldset
                    v-if="selectedTemplate.filename && selectedTemplate.file_url"
                    class="tw-mb-8"
                >
                    <MLabel>
                        {{ __('organization:current_file_name_colon') }}
                    </MLabel>
                    <CButtonGroup class="tw-w-full">
                        <MInput
                            :disabled="true"
                            :model-value="selectedTemplate.filename"
                        />
                        <a
                            id="downloadWordFile"
                            :href="selectedTemplate.file_url"
                            target="_blank"
                        >
                            <MButton class="tw-shrink-0">
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-download" />
                                </template>
                                {{ __('common:actions.download') }}
                            </MButton>
                        </a>
                    </CButtonGroup>
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        {{ __('document:select_new_file') }}
                    </MLabel>
                    <CFileInput
                        class="tw-w-full"
                        :disabled="!can('update', 'doc_templates')"
                        :max-size="104857600"
                        :model-value="modelValue"
                        @update:model-value="emit('update:modelValue', $event)"
                    />
                    <div class="DocTemplatesManagerWordFile__info">
                        <FontAwesomeIcon icon="fa-duotone fa-circle-info" />
                        {{ __('organization:only_files_in_docx_format_are_accepted') }}
                    </div>
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        {{ __('organization:to_fill_word_file_colon') }}
                    </MLabel>
                    <MButton
                        class="tw-w-full"
                        icon-external-link
                        :label="__('organization:following_guide')"
                        @click="showArticle(2543786)"
                    />
                </MFieldset>
            </MForm>
        </div>
    </MBox>
</template>

<style scoped>
    .DocTemplatesManagerWordFile {
        @apply tw-p-4;
    }

    .DocTemplatesManagerWordFile__form-container {
        @apply tw-flex tw-flex-col tw-items-center;
    }

    .DocTemplatesManagerWordFile__form {
        @apply tw-w-full sm:tw-w-auto;
    }

    .DocTemplatesManagerWordFile__info {
        @apply tw-mt-2 tw-flex tw-flex-row tw-items-baseline tw-gap-1 tw-text-sm tw-text-gray-500;
    }
</style>
