import type {ModelCollection} from '@meekohq/lumos';
import {Binding} from '@meekohq/lumos';

import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';

export const GetAllocationsForCreditNoteUseCaseBinding = new Binding<GetAllocationsForCreditNoteUseCase>();

export interface GetAllocationsForCreditNoteUseCase {
    fetch(creditNoteId: string, payments: ModelCollection<PaymentModel>): Promise<AbstractAllocationAggregate[]>;
}
