import {forEach} from 'lodash-es';

import invoiceConfig from '@/config/invoice';
import {InvoiceLinePriceExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceLinePriceExceedsLimitError';
import {InvoiceTotalExceedsLimitError} from '@/modules/cashier/invoice/domain/errors/InvoiceTotalExceedsLimitError';
import {InvoiceAmountService} from '@/modules/cashier/invoice/domain/InvoiceAmountService';
import type {LegacyInvoiceLineType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceLineType';
import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

export class InvoiceAggregate {
    private _total: number;
    private _invoice: LegacyInvoiceType;

    private _amountService: InvoiceAmountService;

    public constructor(invoice: LegacyInvoiceType, country?: string) {
        this._amountService = new InvoiceAmountService(country ?? 'FR');

        this.invoice = invoice;
    }

    public get total(): number {
        return this._total;
    }

    public get invoice(): LegacyInvoiceType {
        return this._invoice;
    }

    public set invoice(invoice: LegacyInvoiceType) {
        forEach(invoice.lines, (line: LegacyInvoiceLineType) => {
            if (line.price < invoiceConfig.minLinePriceAllowed || line.price > invoiceConfig.maxLinePriceAllowed) {
                throw new InvoiceLinePriceExceedsLimitError();
            }
        });

        const invoiceTotal = this.calculateTotal(invoice);

        if (invoiceTotal < invoiceConfig.minTotalAllowed || invoiceTotal > invoiceConfig.maxTotalAllowed) {
            throw new InvoiceTotalExceedsLimitError();
        }

        this._invoice = invoice;
        this._total = invoiceTotal;
    }

    private calculateTotal(invoice: LegacyInvoiceType): number {
        return this._amountService.sumInvoiceLines(invoice);
    }
}
