<script setup lang="ts">
    import type {LengthAwarePaginator} from '@meekohq/lumos';
    import {filter, orderBy} from 'lodash-es';
    import {computed, ref, watch} from 'vue';
    import {useRoute} from 'vue-router';

    import __ from '@/modules/app/utils/i18n-facade';
    import ContactListItem from '@/modules/cashier/components/core/contact/molecules/ContactListItem.vue';
    import ContactModel from '@/modules/cashier/models/ContactModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';

    defineExpose({
        addToResult,
    });

    const props = defineProps<{
        customerModel: CustomerModel;
    }>();

    const emit = defineEmits<{
        (event: 'deleted' | 'updated', contactModel: ContactModel): void;
    }>();

    const results = ref<ContactModel[]>([]);
    const paginator = ref<LengthAwarePaginator<ContactModel>>();
    const route = useRoute();

    const resultsOrdered = computed(() => {
        return orderBy(results.value, 'id');
    });

    function addToResult(contactModel: ContactModel) {
        results.value.unshift(contactModel);
    }

    watch(
        () => props.customerModel,
        () => {
            fetchResults(1);
        }
    );

    fetchResults(Number(route?.query.page) || 1);

    function onContactDeleted(contactModel: ContactModel) {
        results.value = filter(results.value, function (c: ContactModel) {
            return c.getKey() !== contactModel.getKey();
        }) as ContactModel[];

        emit('deleted', contactModel);
    }

    function onContactUpdated(contactModel: ContactModel) {
        const index = results.value.findIndex(c => c.getKey() === contactModel.getKey());
        results.value[index] = contactModel;

        emit('updated', contactModel);
    }

    function fetchResults(page = 1, perPage = 15): void {
        ContactModel.query()
            .where('customer_id', props.customerModel.getKey())
            .paginate(perPage, page)
            .then(response => {
                paginator.value = response;
                results.value = response.items().all();
            });
    }
</script>

<template>
    <CList
        v-if="results.length"
        :striped="true"
    >
        <ContactListItem
            v-for="contactModel in resultsOrdered"
            :key="contactModel.id"
            :contact-model="contactModel"
            @deleted="onContactDeleted"
            @updated="onContactUpdated"
        />
    </CList>
    <div v-else>
        <CAlert
            class="tw-mb-4"
            remember-hash="update-june-2020-cashier-contact-explanation"
            :title-text="__('billing_core:contact_persons')"
        >
            <template #icon>
                <FontAwesomeIcon
                    class="tw-text-3xl tw-text-blue-500"
                    icon="fa-duotone fa-info-circle"
                />
            </template>
            <p v-html="__('billing_core:this_is_where_you_can_add_additional_billing_contacts')" />
        </CAlert>
        <div class="tw-p-4 tw-text-center tw-text-disabled">
            {{ __('billing_core:no_contact_saved') }}
        </div>
    </div>
</template>
