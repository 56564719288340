<template>
    <div class="tw-contents">
        <TenantFinder
            v-if="showTenantsFilter"
            v-model="filters.tenantsFilter.data.value"
            button-size="sm"
            has-unselect
            multi
            only-user-organizations
        />
        <CustomersEntityFilter
            :model-value="filters.personTypesFilter.data.value"
            @value:updated="filters.personTypesFilter.data.value = $event"
        />
        <MSelectMenu
            v-model="filters.statesFilter.data.value"
            size="sm"
            :fallback-text="__('billing_customer:status_other')"
            multi
            :options="stateOptions"
        />
        <MToggleFilter
            v-model="filters.includeArchivedFilter.data.value"
            :label="__('billing_customer:include_archived')"
        />
        <TagsResourceFinder
            v-model="filters.tagsFilter.data.value"
            button-size="sm"
            has-unselect
            :inject-query="tagFinderQuery"
            multi
            only-active-organization
            :search-bar="false"
            :without-tags-option-is-selected="filters.withoutTagFilter.data.value"
            @without-tags-option-is-selected="filters.withoutTagFilter.data.value = $event"
        />
        <MFilterSort
            :filters="filters.orderByFilter.data.value"
            :options="sortOptions"
        />
    </div>
</template>
<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import CustomersEntityFilter from '@/modules/cashier/components/core/customer/organisms/CustomersEntityFilter.vue';
    import TenantFinder from '@/modules/cashier/components/tax/tax-certificate/organisms/TenantFinder.vue';
    import type {OutputType} from '@/modules/cashier/composables/core/customer/useCustomerFiltersStore';
    import MFilterSort from '@/modules/meeko-ui/components/MFilterSort.vue';
    import MToggleFilter from '@/modules/meeko-ui/components/MToggleFilter.vue';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import TagModel from '@/modules/tag/models/TagModel';

    export default defineComponent({
        components: {
            TenantFinder,
            CustomersEntityFilter,
            MFilterSort,
            MToggleFilter,
            TagsResourceFinder,
        },
        props: {
            filters: {
                type: Object as PropType<OutputType>,
                required: true,
            },
            showTenantsFilter: {
                type: Boolean,
                default: false,
            },
        },
        setup() {
            const tagFinderQuery = TagModel.query().where('type', 'customer');

            const sortOptions = [
                {
                    value: 'name',
                    label: __('billing_customer:by_name'),
                    sortLabel: __('billing_customer:order_by_name'),
                    icon: 'fa-solid fa-user',
                },
                {
                    value: 'unpaid_payment_amount',
                    label: __('billing_customer:by_unpaid_amount'),
                    sortLabel: __('billing_customer:order_by_unpaid_amount'),
                    icon: 'fa-solid fa-money-bill',
                },
                {
                    value: 'first_unpaid_payment_at',
                    label: __('billing_customer:by_first_unpaid_at'),
                    sortLabel: __('billing_customer:order_by_first_unpaid_at'),
                    icon: 'fa-solid fa-alarm-exclamation',
                },
                {
                    value: 'balance_amount_updated_at',
                    label: __('billing_customer:by_last_activity'),
                    sortLabel: __('billing_customer:order_by_last_activity'),
                    icon: 'fa-solid fa-clock-rotate-left',
                },
            ];

            const stateOptions = [
                {
                    value: 'up-to-date',
                    text: __('billing_customer:state_up_to_date'),
                },
                {
                    value: 'pending',
                    text: __('billing_customer:state_pending'),
                },
                {
                    value: 'unpaid',
                    text: __('billing_customer:state_unpaid'),
                },
                {
                    value: 'too-much-collected',
                    text: __('billing_customer:state_too_much_collected'),
                },
            ];

            return {
                sortOptions,
                stateOptions,
                tagFinderQuery,
            };
        },
    });
</script>
