import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';

export interface DeletePersonalAccessTokenRequestMakeType {
    model: PersonalAccessTokenModel;
    sudoToken: string;
}

export class DeletePersonalAccessTokenRequest {
    constructor(
        public readonly model: PersonalAccessTokenModel,
        public readonly sudoToken: string
    ) {}

    public static make(makeType: DeletePersonalAccessTokenRequestMakeType): DeletePersonalAccessTokenRequest {
        return new DeletePersonalAccessTokenRequest(makeType.model, makeType.sudoToken);
    }
}
