<template>
    <CCard>
        <template #header>
            <CCardTitle>
                <FontAwesomeIcon
                    class="tw-mr-2 tw-text-gray-600"
                    icon="fa-solid fa-credit-card"
                />
                {{ __('subscription:save_card') }}
            </CCardTitle>
        </template>
        <CAlert
            v-if="stripe.error"
            class="tw-mb-4"
            :close-button="false"
            variant="danger"
        >
            {{ stripe.error }}
        </CAlert>
        <MForm
            id="card-form"
            @submit.prevent="createCard"
        >
            <CFormTwoColumns>
                <MFieldset>
                    <MLabel>{{ __('common:full_name') }}</MLabel>
                    <CInput
                        v-model="stripe.name"
                        :placeholder="__('common:full_name')"
                        required
                        size="lg"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('subscription:debit_card') }}</MLabel>
                    <div ref="elementRef" />
                </MFieldset>
            </CFormTwoColumns>
        </MForm>
        <template #footer>
            <MButton
                class="tw-w-full"
                form="card-form"
                :loading="loader.isLoading()"
                size="lg"
                type="submit"
                variant="primary"
            >
                {{ __('subscription:save_the_card') }}
            </MButton>
        </template>
    </CCard>
</template>

<script lang="ts">
    import {useElementVisibility, watchOnce} from '@vueuse/core';
    import {defineComponent, reactive, ref} from 'vue';

    import services from '@/config/services';
    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        props: ['customer', 'email'],
        emits: ['created'],
        setup(props, {emit}) {
            // @ts-ignore
            const StripeJS = window.Stripe;
            const stripe = reactive({
                name: '',
                error: '',
                client: StripeJS(services.stripe.key) as any,
                element: {} as any,
            });
            const loader = reactive(new Loader());

            const elementRef = ref(null);
            const targetIsVisible = useElementVisibility(elementRef);
            watchOnce(targetIsVisible, () => {
                initStripe();
            });

            const createCard = function () {
                loader.start();
                useApi()
                    .legacy.post(
                        route('customers.sources.createSetupIntent', {
                            customer: props.customer.id,
                        }),
                        {}
                    )
                    .then(response => {
                        stripe.client
                            .confirmCardSetup(response.data.client_secret, {
                                payment_method: {
                                    card: stripe.element,
                                    billing_details: {
                                        name: stripe.name,
                                        address: {
                                            line1: props.customer.address,
                                            city: props.customer.city,
                                            postal_code: props.customer.zipcode,
                                            country: props.customer.country,
                                        },
                                        email: props.email,
                                    },
                                },
                            })
                            .then(result => {
                                loader.stop();
                                // Handle result.error or result.setupIntent
                                if (result.error) {
                                    stripe.error = result.error.message;
                                } else {
                                    initStripe();
                                    stripe.error = '';
                                    emit('created');
                                    useNotification().success(__('subscription:card_saved'));

                                    useApi().legacy.post(
                                        route('customers.sources.setDefault', {
                                            customer: props.customer.id,
                                        }),
                                        {
                                            id: result.setupIntent.payment_method,
                                        }
                                    );
                                }
                            });
                    });
            };

            const initStripe = function () {
                const elements = stripe.client.elements();

                const style = {
                    base: {
                        'color': '#212529',
                        'lineHeight': '27px',
                        'fontSmoothing': 'antialiased',
                        'fontSize': '16px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#dc3545',
                        iconColor: '#dc3545',
                    },
                };

                stripe.element = elements.create('card', {
                    style,
                });

                stripe.element.mount(elementRef.value);

                stripe.element.on('change', event => {
                    if (event.error) {
                        stripe.error = event.error.message;
                    } else {
                        stripe.error = '';
                    }
                });
            };

            return {
                stripe,
                loader,
                elementRef,
                createCard,
            };
        },
    });
</script>
