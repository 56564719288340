<template>
    <MagicIndexFilters :variant="variant">
        <template #filters>
            <TagsResourceFinder
                button-size="sm"
                has-unselect
                :inject-query="tagFinderQuery"
                multi
                only-active-organization
                :search-bar="false"
                :model-value="selectedTagsModels"
                :without-tags-option-is-selected="withoutTags"
                @update:model-value="$emit('update:selectedTagsModels', $event)"
                @without-tags-option-is-selected="$emit('update:withoutTags', $event)"
            />
            <MDatePeriodFilter
                allow-undefined
                button-size="sm"
                :model-value="dateFilter"
                @update:model-value="$emit('update:dateFilter', $event)"
            />
            <div v-if="nursery.groups && nursery.groups.length">
                <KidGroupFinder
                    button-class="tw-h-6 tw-text-sm"
                    :fall-back-text="__('photo:filter_by_group')"
                    has-unselect
                    :inject-query="groupFinderQuery"
                    only-active-organization
                    :model-value="selectedGroup"
                    @update:model-value="selectGroup"
                />
            </div>
            <div>
                <KidFinder
                    button-class="tw-h-6 tw-text-sm"
                    :fall-back-text="__('photo:filter_by_kid')"
                    has-unselect
                    :inject-query="kidFinderQuery"
                    :model-value="selectedKid"
                    @update:model-value="$emit('update:selectedKid', $event)"
                />
            </div>
        </template>
    </MagicIndexFilters>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import KidFinder from '@/modules/family/components/kid/KidFinder.vue';
    import KidModel from '@/modules/family/models/KidModel';
    import MagicIndexFilters from '@/modules/magic-index/components/organisms/MagicIndexFilters.vue';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import KidGroupFinder from '@/modules/organization/components/KidGroupFinder.vue';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import TagModel from '@/modules/tag/models/TagModel';

    export default defineComponent({
        components: {MDatePeriodFilter, TagsResourceFinder, KidGroupFinder, KidFinder, MagicIndexFilters},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            selectedKid: {
                type: Object as PropType<KidModel>,
                default: undefined,
            },
            selectedGroup: {
                type: Object as PropType<KidsGroupModel>,
                default: undefined,
            },
            kids: {
                type: Array,
                required: true,
            },
            selectedTagsModels: {
                type: Array as PropType<TagModel[]>,
                required: true,
            },
            withoutTags: {
                type: Boolean,
                required: true,
            },
            dateFilter: {
                type: Object as PropType<{from?: string; to?: string}>,
                required: true,
            },
            variant: {
                type: String,
                default: 'base',
            },
        },
        emits: [
            'update:selectedTagsModels',
            'update:withoutTags',
            'update:dateFilter',
            'update:selectedKid',
            'update:selectedGroup',
        ],

        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const tagFinderQuery = TagModel.query().where('type', 'photo');

            const kidFinderQuery = computed(() => {
                const query = KidModel.query().whereHas(new KidModel().organizations(), query2 => {
                    query2.where('id', activeOrganization.value.getKey());
                });

                if (props.selectedGroup) {
                    query.whereHas(new KidModel().group(), query2 => {
                        query2.where('id', props.selectedGroup?.getKey());
                    });
                }

                return query;
            });

            const groupFinderQuery = computed(() =>
                KidsGroupModel.query().whereHas(new KidsGroupModel().organization(), query => {
                    query.where('id', activeOrganization.value.getKey());

                    return query;
                })
            );

            function selectGroup(groupModel: KidsGroupModel | null) {
                emit('update:selectedGroup', groupModel);
                emit('update:selectedKid', undefined);
            }

            return {
                tagFinderQuery,
                kidFinderQuery,
                groupFinderQuery,
                selectGroup,
            };
        },
    });
</script>

<style>
    .PhotoFilters__list {
        @apply tw-cursor-pointer tw-p-2;
    }
</style>
