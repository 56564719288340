<script setup lang="ts">
    import {inject, onMounted, ref} from 'vue';

    import {type defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false,
        },
        manual: {
            type: Boolean,
            default: false,
        },
    });

    const context = inject<ReturnType<typeof defineFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('MPopoverButton must be used inside a MPopover');
    }

    const {togglePopover, isVisible, trigger, buttonId, panelId, setButtonElement, openPopover, closePopover} = context;

    const myButton = ref();

    trigger.value = props.manual ? 'none' : 'click';

    onMounted(() => {
        setButtonElement(myButton);
    });

    const emit = defineEmits(['click', 'active']);

    function onClick() {
        if (props.disabled || props.manual) {
            return;
        }

        emit('click');
        togglePopover();
    }
</script>

<template>
    <div
        :id="buttonId"
        ref="myButton"
        :aria-controls="panelId"
        :aria-expanded="isVisible"
        aria-haspopup="dialog"
        @click="onClick"
    >
        <slot
            :open="openPopover"
            :close="closePopover"
            :toggle="togglePopover"
        />
    </div>
</template>
