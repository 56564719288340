<script setup lang="ts">
    import {useElementHover} from '@vueuse/core';
    import {inject, ref, watch} from 'vue';

    import type {MCalendarYearsItem} from '@/modules/meeko-ui/components/MCalendar/useMCalendarYears';
    import {keyMCalendarYears} from '@/modules/meeko-ui/components/MCalendar/useMCalendarYears';

    const props = defineProps<{
        year: MCalendarYearsItem;
    }>();

    const yearsState = inject(keyMCalendarYears);
    if (!yearsState) {
        throw new Error('keyMCalendarYears must be provided');
    }

    const yearElement = ref();
    const isHovered = useElementHover(yearElement);

    const {hoveredYear, handleYearClick} = yearsState;

    watch(isHovered, newValue => {
        if (newValue) {
            hoveredYear.value = props.year.year;
        }
    });
</script>

<template>
    <button
        ref="yearElement"
        class="MCalendarYearsItem"
        :class="{
            'MCalendarYearsItem--selecting': year.isSelecting,
            'MCalendarYearsItem--in-period': year.isInPeriod,
            'MCalendarYearsItem--hovered': isHovered,
            'MCalendarYearsItem--selected': year.isSelected,
        }"
        @click="handleYearClick(year.year)"
    >
        {{ year.year.year }}
    </button>
</template>

<style scoped>
    .MCalendarYearsItem {
        @apply tw-cursor-pointer tw-select-none tw-rounded tw-p-2;
        @apply tw-transition-all hover:tw-bg-gray-200;
        @apply tw-text-center tw-font-display tw-capitalize tw-text-gray-800;
    }

    .MCalendarYearsItem--hovered {
        @apply tw-bg-gray-200;
    }

    .MCalendarYearsItem--in-period {
        &:not(.MCalendarYearsItem--selecting) {
            @apply tw-bg-primary-200 tw-text-primary-900;
        }

        &.MCalendarYearsItem--selecting {
            @apply tw-bg-gray-200;
        }
    }

    .MCalendarYearsItem--selected {
        background-color: theme(backgroundColor.primary.500) !important;
        color: theme(textColor.white) !important;

        &.MCalendarYearsItem--hovered {
            background-color: theme(backgroundColor.primary.600) !important;
        }
    }
</style>
