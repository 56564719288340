<script setup lang="ts">
    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import {useFamilyAppSettingsState} from '@/modules/meeko-family/composables/useFamilyAppSettingsState';
    import {useSaveFamilyAppSettings} from '@/modules/meeko-family/composables/useSaveFamilyAppSettings';

    const {familyAppSettings, updated, resetOriginalSettings} = useFamilyAppSettingsState();

    const {isSaving, save: saveSettings, errors} = useSaveFamilyAppSettings();

    async function save() {
        await saveSettings(familyAppSettings.value, () => {
            resetOriginalSettings();
        });
    }
</script>

<template>
    <SimpleSubPageTemplate>
        <template #content>
            <MBox class="MeekoFamilyContact">
                <div class="MeekoFamilyContact__subtitle">
                    {{ __('reporting:coordinates_subtitle') }}
                </div>
                <MForm>
                    <MInputGroup
                        v-model="familyAppSettings.phone"
                        :errors="errors.get('second_phone')"
                        :label="__('common:phone_number')"
                        :placeholder="__('common:phone_number')"
                        type="tel"
                    />
                    <MInputGroup
                        v-model="familyAppSettings.email"
                        :errors="errors.get('second_email')"
                        :label="__('common:email')"
                        :placeholder="__('common:email_example')"
                        type="email"
                    />
                    <MInputGroup
                        v-model="familyAppSettings.website"
                        :errors="errors.get('website')"
                        :label="__('news:current_website_colon')"
                        :placeholder="__('common:website')"
                        type="url"
                    />
                    <CCheckbox v-model="familyAppSettings.displayStaffLastName">
                        {{ __('news:display_last_names') }}
                    </CCheckbox>
                    <MFieldset class="tw-flex tw-justify-end">
                        <MButton
                            :disabled="!updated"
                            :loading="isSaving"
                            variant="primary"
                            @click="save"
                        >
                            {{ __('reporting:apply_changes') }}
                        </MButton>
                    </MFieldset>
                </MForm>
            </MBox>
        </template>
    </SimpleSubPageTemplate>
</template>

<style scoped>
    .MeekoFamilyContact {
        @apply tw-flex tw-flex-col tw-gap-4 tw-p-4;
        @apply tw-w-full md:tw-w-2/3 lg:tw-w-1/2 xl:tw-w-1/3;
    }

    .MeekoFamilyContact__subtitle {
        @apply tw-text-sm tw-text-gray-500;
    }
</style>
