<template>
    <loader
        v-if="loading"
        light="true"
        shadow="false"
        size="sm"
        :title="__('common:loading_dots')"
    />
    <CVStack
        v-else
        gap="1"
    >
        <CListHeader>
            <div class="tw-w-2/4">
                <CListHeaderItem>{{ __('common:name') }}</CListHeaderItem>
            </div>
            <div class="tw-w-1/4" />
            <div class="tw-w-1/4" />
        </CListHeader>
        <CList>
            <JobsListItem
                v-for="jobModel in jobsModelCollectionFiltered"
                :key="jobModel.id"
                :job-model="jobModel"
                :pivots="pivotsModelCollection"
                @remove-job-in-array="removeJobInArray"
                @remove-pivot-in-array="removePivotInArray"
            />
        </CList>
    </CVStack>
</template>

<script>
    import {collect} from '@meekohq/lumos';
    import {forEach, head, isNull} from 'lodash-es';

    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import JobsListItem from '@/modules/human-resources/components/job/List/JobsListItem.vue';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import JobsOrganizationsPivot from '@/modules/human-resources/models/JobOrganizationPivot';
    import Manager from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default {
        components: {JobsListItem},

        data() {
            return {
                loading: false,
                jobsModelCollection: collect(),
                pivotsModelCollection: collect(),
                organizations: collect(),
                manager: Manager,
            };
        },

        computed: {
            pivotsModelCollectionFiltered() {
                return this.pivotsModelCollection
                    .groupBy(item => item.attributes.organization_id)
                    .get(this.manager.activeOrganization.id, collect());
            },

            jobsModelCollectionFiltered() {
                return this.pivotsModelCollectionFiltered
                    .map(pivot => this.jobsModelCollection.where('id', '===', `${pivot.attributes.job_id}`).first())
                    .sortBy('attributes.name');
            },
        },

        watch: {
            'manager.activeOrganization': {
                immediate: true,
                handler() {
                    return this.fetchData();
                },
            },
        },

        methods: {
            async fetchData() {
                this.loading = true;
                this.jobsModelCollection = await JobModel.query().with(new JobModel().contracts()).all();

                JobsOrganizationsPivot.query()
                    .all()
                    .then(response => (this.pivotsModelCollection = response));

                OrganizationModel.query()
                    .all()
                    .then(response => (this.organizations = response));
                this.loading = false;
            },

            addToResult(jobModel) {
                const jobModelOrganizationsPivot = jobModel.organizationsPivots().value().all();

                jobModelOrganizationsPivot.forEach(pivot => {
                    const isPivotDontExistInPivotArray = !this.pivotsModelCollection.contains('id', pivot.id);
                    const isJobDontExistInJobArray = !this.jobsModelCollection.contains('id', pivot.attributes.job_id);

                    if (isPivotDontExistInPivotArray) {
                        this.pivotsModelCollection.push(pivot);
                    }
                    if (isJobDontExistInJobArray) {
                        this.jobsModelCollection.push(jobModel);
                    }
                });
            },

            removeJobInArray(jobModel) {
                this.jobsModelCollection = this.jobsModelCollection.reject(item => item.id === jobModel.id);
                this.pivotsModelCollection = this.pivotsModelCollection.reject(
                    item => item.attributes.job_id === jobModel.id
                );
            },

            removePivotInArray(pivot) {
                this.pivotsModelCollection = this.pivotsModelCollection.reject(item => item.id === pivot.id);
            },

            async attachJobToOrga(jobModelToAttach) {
                await jobModelToAttach.attachToOrga(this.manager.activeOrganization);
                this.addToResult(jobModelToAttach);
            },

            async importJobs() {
                const organizations = this.organizations;

                // Array of Organization's name
                const organizationsName = organizations
                    .reject(organization => organization.id === this.manager.activeOrganization.id)
                    .map(item => ({value: item.getKey(), text: item.attributes.name}))
                    .all();

                await useMagicModal().confirmationWithSelectModal({
                    title: __('hr:which_organization_for_import_jobs'),
                    confirmButtonText: __('common:actions.validate'),
                    options: organizationsName,
                    defaultOption: organizationsName[0].value,
                    onConfirm: async result => {
                        if (result) {
                            const organizationModel = organizations.where('attributes.id', '===', result).first();

                            try {
                                // fetch every JobsOrganizationsPivot of the "organizationModel"
                                const pivots = await JobsOrganizationsPivot.query()
                                    .where('organization_id', organizationModel.id)
                                    .get();

                                pivots
                                    .reject(item => {
                                        // reject every JobModel that is already present in "jobsModelCollectionFiltered"
                                        const findJobInOrganization = this.jobsModelCollectionFiltered
                                            .where('id', '===', `${item.attributes.job_id}`)
                                            .first();

                                        return item.attributes.job_id === findJobInOrganization?.id;
                                    })
                                    .each(async jobPivot => {
                                        // try to find the "job" thanks to "jobPivot.attributes.job_id" and verify if this "job" has an "archived_at" variable equal to null
                                        const job = this.jobsModelCollection
                                            .where('id', jobPivot.attributes.job_id)
                                            .first();

                                        if (!job || !isNull(job.attributes.archived_at)) {
                                            return;
                                        }

                                        const pivot = await job.attachToOrga(this.manager.activeOrganization);
                                        this.pivotsModelCollection.push(pivot);
                                    });

                                useNotification().success(__('hr:import_jobs_success'));
                            } catch (e) {
                                this.errorResponse(e);
                            }
                        }
                    },
                });
            },

            errorResponse(error) {
                if (error && error.response && error.response.status === 422) {
                    forEach(error.response.data.errors, value => {
                        useNotification().error(head(value));
                    });
                } else {
                    useNotification().error(error);
                }
            },
        },
    };
</script>
