<script lang="ts" setup>
    import {Epoch} from '@meekohq/lumos';
    import _debounce from 'lodash-es/debounce';
    import {ref, watch} from 'vue';

    const props = defineProps<{
        daySeparator: 'day' | 'halfday';
        halfdayTime: string;
    }>();

    const emit = defineEmits<{
        (e: 'update:halfdayTime', value: string): void;
        (e: 'update:daySeparator', value: 'day' | 'halfday'): void;
    }>();

    const halfdayTimeEpoch = ref(Epoch.parse(props.halfdayTime, 'HH:mm'));

    const debounceUpdateHalfdayTime = _debounce(
        (time: string) => {
            emit('update:halfdayTime', time);
        },
        1000,
        {trailing: true}
    );

    function updateDaySeparator(value: 'day' | 'halfday') {
        emit('update:daySeparator', value);
    }

    watch(halfdayTimeEpoch, value => debounceUpdateHalfdayTime(value.toFormat('HH:mm')));
</script>

<template>
    <div>
        <CButtonGroup>
            <MButton
                :variant="daySeparator === 'day' ? 'primary' : 'light'"
                @click="updateDaySeparator('day')"
            >
                {{ __('common:day_female_one') }}
            </MButton>
            <MPopover>
                <MButton
                    :variant="daySeparator === 'halfday' ? 'primary' : 'light'"
                    @click="updateDaySeparator('halfday')"
                >
                    {{ __('common:halfday_one') }}
                </MButton>
                <template #content>
                    <MForm>
                        <MFieldset>
                            <MLabel>
                                {{ __('planning:halfday_time') }}
                                <MHelpTooltip class="tw-ml-2">
                                    {{ __('planning:halfday_time_tips') }}
                                </MHelpTooltip>
                            </MLabel>
                            <MTimePicker
                                v-model="halfdayTimeEpoch"
                                class="tw-w-full"
                            />
                        </MFieldset>
                    </MForm>
                </template>
            </MPopover>
        </CButtonGroup>
    </div>
</template>
