<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="staff">
                {{ __('app:menu.hr_settings') }}
            </MPageTitle>
        </template>
        <template #content>
            <SettingsMenu class="tw-mb-3" />
            <router-view />
        </template>
    </SimplePageTemplate>
</template>

<script>
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import SettingsMenu from '@/modules/human-resources/components/settings/Menu.vue';

    export default {
        components: {SimplePageTemplate, SettingsMenu},
        data() {
            return {};
        },
    };
</script>

<style scoped>
    .CCard {
        border-color: #e91e63;
    }

    .iconContainer {
        i,
        svg {
            color: #e91e63;
        }
    }

    .CCardHeroTitle {
        color: #e91e63;
    }
</style>
