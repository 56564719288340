<template>
    <CAlert :title-text="__('billing_core:calculating_the_tax_certificate')">
        <template #icon>
            <FontAwesomeIcon
                class="tw-text-3xl tw-text-blue-500"
                icon="fa-duotone fa-info-circle"
            />
        </template>
        <CText>
            <span
                v-html="__('billing_core:certificate_is_calculated_from_payment_date_added_not_invoice_with_example')"
            />
        </CText>
    </CAlert>
</template>

<style scoped>
    .TaxCertificates__alert-button {
        @apply tw-mt-3 lg:tw-w-1/4;
    }
</style>
