import {ServiceProvider} from '@meekohq/lumos';

import {NumericFormatter} from '@/modules/core/infrastructure/NumericFormatter';
import {NumericFormatterContractBinding} from '@/modules/core/infrastructure/NumericFormatterContract';
import {NumericParser} from '@/modules/core/infrastructure/NumericParser';
import {NumericParserContractBinding} from '@/modules/core/infrastructure/NumericParserContract';

export default class CoreServiceProvider extends ServiceProvider {
    public register() {
        this.app.bind(NumericFormatterContractBinding, (_, options) => new NumericFormatter(options));
        this.app.bind(NumericParserContractBinding, (_, options) => new NumericParser(options));
    }
}
