export default function () {
    function print() {
        //Print using browser API if supported, else use window.print(), using window.print() doesn't work on safari
        const isPrinted = document.execCommand('print');
        if (!isPrinted) {
            window.print();
        }
    }

    return {
        print,
    };
}
