import {toBool} from '@/modules/app/utils/booleanEnv';

export default {
    pusher: {
        enabled: toBool(import.meta.env.VITE_PUSHER_ENABLED, true),
        key: window.localStorage.getItem('cto_pusher_key') ?? import.meta.env.VITE_PUSHER_KEY,
        productionKey: import.meta.env.VITE_PUSHER_KEY_PRODUCTION,
        stagingKey: import.meta.env.VITE_PUSHER_KEY_STAGING,
        localKey: import.meta.env.VITE_PUSHER_KEY_LOCAL,
    },
    stripe: {
        key: import.meta.env.VITE_STRIPE_KEY,
    },
    intercom: {
        enabled: toBool(import.meta.env.VITE_INTERCOM_ENABLED, true),
        appId: import.meta.env.VITE_INTERCOM_APP_ID,
    },
    launchDarkly: {
        enabled: toBool(import.meta.env.VITE_LAUNCH_DARKLY_ENABLED, false),
        key: import.meta.env.VITE_LAUNCH_DARKLY_KEY,
    },
    bugsnag: {
        enabled: toBool(import.meta.env.VITE_BUGSNAG_ENABLED, false),
        key: import.meta.env.VITE_BUGSNAG_API_KEY,
    },
    datadog: {
        enabled: toBool(import.meta.env.VITE_DATADOG_ENABLED, false),
        appId: import.meta.env.VITE_DATADOG_APP_ID,
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    },
};
