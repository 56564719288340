import {Model} from '@meekohq/lumos';

import TagModel from '@/modules/tag/models/TagModel';

export default class TagsOrganizationsPivot extends Model {
    public type = 'tags/tags_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        tag_id: string | undefined;
        organization_id: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        tag_id: undefined,
        organization_id: undefined,
    };

    tag() {
        return this.belongsTo('tag', TagModel, 'tag_id');
    }
}
