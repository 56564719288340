<template>
    <CModal
        :emitter="emitter"
        :header-title="__('billing_customer:update_customer_account')"
        visible
        @closed="$emit('closed')"
    >
        <CustomerForm
            ref="form"
            :customer-model="customerModelClone"
            @loading="isLoadingToggle($event)"
            @saved="onCustomerSaved"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="emitter.emit('hide')"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="isLoading"
                variant="primary"
                @click="$refs.form.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {useToggle} from '@vueuse/core';
    import mitt from 'mitt';
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';

    import CustomerForm from '@/modules/cashier/components/core/customer/organisms/CustomerForm.vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';

    export default defineComponent({
        components: {
            CustomerForm,
        },
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        emits: ['closed', 'updated'],
        setup(props, {emit}) {
            const [isLoading, isLoadingToggle] = useToggle();

            const customerModelClone = ref<CustomerModel>();

            watch(
                () => props.customerModel,
                () => {
                    customerModelClone.value = props.customerModel.clone();
                },
                {immediate: true}
            );

            const emitter = mitt<EventType>();

            function onCustomerSaved(model: CustomerModel) {
                model.copyTo(props.customerModel);

                emit('updated', model);
                emitter.emit('close');
            }

            return {
                isLoading,
                isLoadingToggle,
                customerModelClone,
                onCustomerSaved,
                emitter,
            };
        },
    });
</script>
