import {Binding} from '@meekohq/lumos';

import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
import type {TransactionCreditAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
import type {TransactionDebitAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';

export const GetAllocationsSuggestionsForPaymentUseCaseBinding =
    new Binding<GetAllocationsSuggestionsForPaymentUseCase>();

export interface GetAllocationsSuggestionsForPaymentUseCase {
    fetchDebitTransaction(payment: PaymentModel): Promise<TransactionDebitAllocationAggregate[]>;

    fetchCreditTransaction(payment: PaymentModel): Promise<TransactionCreditAllocationAggregate[]>;

    fetchCreditNote(payment: PaymentModel): Promise<CreditNoteAllocationAggregate[]>;
}
