<template>
    <div class="ParticipantForm">
        <!-- Staff selector -->
        <StaffFinder
            v-model="selectedStaff"
            button-class="tw-w-full"
            :inject-query="staffFinderQuery"
            :registration="registration"
            @update:model-value="validate('staff')"
        >
            <template #button-text-empty>
                {{ __('activity:select_staff') }}
            </template>
        </StaffFinder>
        <!-- Custom selector -->
        <MSelectMenu
            v-model="custom"
            :fallback-text="customSelectLabel"
            :options="customSelectOptions"
            @update:model-value="validate('custom')"
        />
        <!-- Participant form -->
        <CDisclosure
            :open="false"
            size="sm"
            :title="__('activity:add_participant')"
        >
            <MForm>
                <CFormTwoColumns>
                    <MFieldset>
                        <MLabel>{{ __('common:first_name') }}</MLabel>
                        <CInput
                            v-model="custom.firstname"
                            :placeholder="__('common:first_name')"
                            type="string"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('common:last_name') }}</MLabel>
                        <CInput
                            v-model="custom.lastname"
                            :placeholder="__('common:last_name')"
                            type="string"
                        />
                    </MFieldset>
                    <CFormErrorMessageList
                        v-if="participantErrors.hasError('domain')"
                        :errors="participantErrors.getErrors('domain')"
                    />
                </CFormTwoColumns>
                <MFieldset>
                    <MLabel>{{ __('common:email') }}</MLabel>
                    <CInput
                        v-model="custom.email"
                        :placeholder="__('common:email')"
                        type="email"
                    />
                    <CFormErrorMessageList
                        v-if="participantErrors.hasError('email')"
                        :errors="participantErrors.getErrors('email')"
                    />
                </MFieldset>
                <MFieldset class="tw-flex tw-justify-end tw-gap-2">
                    <MButton @click="cancel">
                        {{ __('common:actions.cancel') }}
                    </MButton>
                    <MButton
                        variant="primary"
                        @click="validate('custom')"
                    >
                        {{ __('common:actions.validate') }}
                    </MButton>
                </MFieldset>
            </MForm>
        </CDisclosure>
    </div>
</template>

<script lang="ts">
    import type {ModelCollection} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, ref, toRef} from 'vue';

    import useParticipantCustomSelector from '@/modules/activity/composables/useParticipantCustomSelector';
    import useParticipantStaffSelector from '@/modules/activity/composables/useParticipantStaffSelector';
    import type MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
    import type {CustomOptionType} from '@/modules/activity/utils/CustomOptionType';
    import type {CustomSelectType} from '@/modules/activity/utils/CustomSelectType';
    import ParticipantErrorHandler from '@/modules/activity/utils/ParticipantErrorHandler';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import Validator from '@/modules/legacy/helpers/validator.helper';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export default defineComponent({
        components: {StaffFinder},
        props: {
            modelValue: {
                type: Object as PropType<MeetingParticipantModel>,
                required: true,
            },
            customSelect: {
                type: Object as PropType<CustomSelectType>,
                default: null,
            },
            excludeParticipants: {
                type: Object as PropType<ModelCollection<MeetingParticipantModel>>,
                default: null,
            },
            registration: {type: RegistrationModel, default: undefined},
        },
        emits: ['validate', 'cancel'],
        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            const {selectedStaff, staffFinderQuery} = useParticipantStaffSelector(toRef(props, 'excludeParticipants'));

            const {customSelectLabel, customSelectOptions} = useParticipantCustomSelector(
                props.customSelect,
                toRef(props, 'excludeParticipants')
            );

            const custom = ref<CustomOptionType>({
                firstname: null,
                lastname: null,
                email: null,
                resource_type: null,
                resource_id: null,
            });

            function fillParticipantWithStaff(staff: StaffModel) {
                selectedStaff.value = staff;

                props.modelValue.resource().associate(staff);
                props.modelValue.attributes.account_id = activeOrganization.value.attributes.account_id;
                props.modelValue.attributes.first_name = staff.attributes.first_name || null;
                props.modelValue.attributes.last_name = staff.attributes.last_name || null;
                props.modelValue.attributes.email = staff.attributes.email || null;
            }

            function fillParticipantWithCustom(custom: CustomOptionType) {
                props.modelValue.attributes.account_id = activeOrganization.value.attributes.account_id;
                props.modelValue.attributes.first_name = custom.firstname;
                props.modelValue.attributes.last_name = custom.lastname;
                props.modelValue.attributes.email = custom.email;
                props.modelValue.attributes.resource_id = custom.resource_id;
                props.modelValue.attributes.resource_type = custom.resource_type;
            }

            const participantErrors = ref(new ParticipantErrorHandler());

            function prevalidate(participant: MeetingParticipantModel): boolean {
                const errors: {message: string; code: string; detail: any; source: {pointer: string}}[] = [];

                // At least one field must be filled
                if (
                    !participant.attributes.first_name &&
                    !participant.attributes.last_name &&
                    !participant.attributes.email
                ) {
                    errors.push({
                        message: __('common:error.required'),
                        code: '0x335D90AE82',
                        detail: {},
                        source: {pointer: 'domain'},
                    });
                }

                if (participant.attributes.email && !Validator.isValidEmail(participant.attributes.email)) {
                    errors.push({
                        message: __('common:error.email.format'),
                        code: '0x2EAA817032',
                        detail: {},
                        source: {pointer: 'email'},
                    });
                }

                if (errors.length) {
                    participantErrors.value.reset({response: {data: {errors}}});

                    return false;
                }

                return true;
            }

            function validate(type: 'staff' | 'custom') {
                // Fill participant with selected item or custom form data
                if (type === 'staff' && selectedStaff.value) {
                    fillParticipantWithStaff(selectedStaff.value);
                } else {
                    fillParticipantWithCustom(custom.value);
                }

                // Validate format of data
                if (!prevalidate(props.modelValue)) {
                    return;
                }

                emit('validate', props.modelValue);
            }

            function cancel() {
                emit('cancel');
            }

            return {
                cancel,
                custom,
                customSelectLabel,
                customSelectOptions,
                fillParticipantWithStaff,
                fillParticipantWithCustom,
                participantErrors,
                selectedStaff,
                staffFinderQuery,
                validate,
            };
        },
    });
</script>

<style scoped>
    .ParticipantForm {
        @apply tw-flex tw-w-80 tw-flex-col tw-gap-2;
    }
</style>
