export default {
    taux_effort: {
        collectif: {
            2020: {
                effort_rate: {
                    brackets: [
                        {value: 0.061},
                        {value: 0.0508},
                        {value: 0.0406},
                        {value: 0.0305},
                        {value: 0.0305},
                        {value: 0.0305},
                        {value: 0.0305},
                        {value: 0.0203},
                    ],
                    lower_limit: 705.27,
                    upper_limit: 5600.0,
                    disabled_child_coef: 2,
                },
            },
            2021: {
                effort_rate: {
                    brackets: [
                        {value: 0.0615},
                        {value: 0.0512},
                        {value: 0.041},
                        {value: 0.0307},
                        {value: 0.0307},
                        {value: 0.0307},
                        {value: 0.0307},
                        {value: 0.0205},
                    ],
                    lower_limit: 711.62,
                    upper_limit: 5800.0,
                    disabled_child_coef: 2,
                },
            },
            2022: {
                effort_rate: {
                    brackets: [
                        {value: 0.0619},
                        {value: 0.0516},
                        {value: 0.0413},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.0206},
                    ],
                    lower_limit: 712.33,
                    upper_limit: 6000.0,
                    disabled_child_coef: 2,
                },
            },
            2023: {
                effort_rate: {
                    brackets: [
                        {value: 0.0619},
                        {value: 0.0516},
                        {value: 0.0413},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.0206},
                    ],
                    lower_limit: 754.16,
                    upper_limit: 6000.0,
                    disabled_child_coef: 2,
                },
            },
            2024: {
                effort_rate: {
                    brackets: [
                        {value: 0.0619},
                        {value: 0.0516},
                        {value: 0.0413},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.0206},
                    ],
                    lower_limit: 765.77,
                    upper_limit: 7000.0,
                    disabled_child_coef: 2,
                },
            },
        },
        parental: {
            2020: {
                effort_rate: {
                    brackets: [
                        {value: 0.0508},
                        {value: 0.0406},
                        {value: 0.0305},
                        {value: 0.0305},
                        {value: 0.0305},
                        {value: 0.0203},
                        {value: 0.0203},
                        {value: 0.0203},
                    ],
                    lower_limit: 705.27,
                    upper_limit: 5600.0,
                    disabled_child_coef: 2,
                },
            },
            2021: {
                effort_rate: {
                    brackets: [
                        {value: 0.0512},
                        {value: 0.041},
                        {value: 0.0307},
                        {value: 0.0307},
                        {value: 0.0307},
                        {value: 0.0205},
                        {value: 0.0205},
                        {value: 0.0205},
                    ],
                    lower_limit: 711.62,
                    upper_limit: 5800.0,
                    disabled_child_coef: 2,
                },
            },
            2022: {
                effort_rate: {
                    brackets: [
                        {value: 0.0516},
                        {value: 0.0413},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.0206},
                        {value: 0.0206},
                        {value: 0.0206},
                    ],
                    lower_limit: 712.33,
                    upper_limit: 6000.0,
                    disabled_child_coef: 2,
                },
            },
            2023: {
                effort_rate: {
                    brackets: [
                        {value: 0.0516},
                        {value: 0.0413},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.0206},
                        {value: 0.0206},
                        {value: 0.0206},
                    ],
                    lower_limit: 754.16,
                    upper_limit: 6000.0,
                    disabled_child_coef: 2,
                },
            },
            2024: {
                effort_rate: {
                    brackets: [
                        {value: 0.0516},
                        {value: 0.0413},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.031},
                        {value: 0.0206},
                        {value: 0.0206},
                        {value: 0.0206},
                    ],
                    lower_limit: 765.77,
                    upper_limit: 7000.0,
                    disabled_child_coef: 2,
                },
            },
        },
    },
};
