import type {Collection} from '@meekohq/lumos';
import {isNil} from 'lodash-es';
import _round from 'lodash-es/round';
import type {ComputedRef, Ref} from 'vue';
import {computed} from 'vue';

import type {ErrorReturnType} from '@/modules/app/composables/useError';
import useError from '@/modules/app/composables/useError';
import type AllocationModel from '@/modules/cashier/models/AllocationModel';
import type TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';

export type TransactionErrorReturnType = ErrorReturnType & CustomTransactionErrorType;

interface CustomTransactionErrorType {
    sumOtherAllocations: ComputedRef<number>;
    isOverAllocated: ComputedRef<boolean>;
    isInsufficientlyAllocated: ComputedRef<boolean>;
    hasInvalidAmount: ComputedRef<boolean>;
    hasBlockingError: ComputedRef<boolean>;
}

export default function useTransactionError(
    transaction: Ref<TransactionModel>,
    allocations?: Ref<Collection<AllocationModel>>,
    invoiceAllocation?: Ref<AllocationModel | undefined>
): TransactionErrorReturnType {
    const error = useError();

    const sumOtherAllocations = computed(() => {
        if (!allocations) {
            return 0;
        }

        return allocations.value
            .filter(allocation => !allocation.markedForDeletion)
            .sum(allocation => allocation.attributes.amount ?? 0);
    });

    const isOverAllocated = computed(() => {
        if (!allocations || !invoiceAllocation) {
            return false;
        }

        if (
            !transaction.value.isFailed &&
            !isNil(transaction.value.attributes.amount) &&
            (sumOtherAllocations.value || invoiceAllocation.value)
        ) {
            const totalAllocated = sumOtherAllocations.value + (invoiceAllocation.value?.attributes.amount ?? 0);

            return transaction.value.attributes.amount > _round(totalAllocated, 2);
        }

        return false;
    });

    const isInsufficientlyAllocated = computed(() => {
        if (!allocations || !invoiceAllocation) {
            return false;
        }

        if (!isNil(transaction.value.attributes.amount) && (sumOtherAllocations.value || invoiceAllocation.value)) {
            const totalAllocated = sumOtherAllocations.value + (invoiceAllocation.value?.attributes.amount ?? 0);

            return transaction.value.attributes.amount < _round(totalAllocated, 2);
        }

        return false;
    });

    const hasInvalidAmount = computed(() => {
        if (!transaction) {
            return false;
        }

        return isNil(transaction.value.attributes.amount) || transaction.value.attributes.amount <= 0;
    });

    const hasBlockingError = computed(() => {
        return hasInvalidAmount.value || isInsufficientlyAllocated.value;
    });

    return {
        ...error,
        sumOtherAllocations,
        isOverAllocated,
        isInsufficientlyAllocated,
        hasInvalidAmount,
        hasBlockingError,
    };
}
