<template>
    <CHStack align="center">
        <CText>{{ __('common:each') }}</CText>
        <CInput
            v-model="rule.interval"
            class="tw-mx-2 tw-w-16"
            min="1"
            type="number"
        />
        <CText>{{ __('common:interval.frequency.day') }}</CText>
    </CHStack>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import type Rule from '@/modules/calendar/utils/RuleSet/Rule';

    export default defineComponent({
        props: {
            rule: {
                type: Object as PropType<Rule>,
                required: true,
            },
        },
        setup(props) {
            if (!props.rule.interval) {
                props.rule.interval = 1;
            }
        },
    });
</script>
