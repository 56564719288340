import {Binding} from '@meekohq/lumos';

import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
import type {LegacyPermission} from '@/modules/user/utils/LegacyUserGate';

export const GateContractBinding = new Binding<GateContract, LegacyPermission[]>();

export interface GateContract {
    /**
     * Get a guard instance for the given user.
     *
     * @param user
     */
    for(user: any, organization: OrganizationModel): GateContract;

    /**
     * Determine if all of the given abilities should be granted for the current user.
     *
     * @param abilities
     * @param args
     */
    check(abilities: any | any[], args: any): boolean;

    /**
     * Determine if any one of the given abilities should be granted for the current user.
     *
     * @param abilities
     * @param args
     */
    any(abilities: any | any[], args: any): boolean;

    /**
     * Determine if all of the given abilities should be denied for the current user.
     *
     * @param abilities
     * @param args
     */
    none(abilities: any | any[], args: any): boolean;
}
