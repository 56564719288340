import {toast} from 'vue3-toastify';

export default function useNotification() {
    return {
        show: (content: string) => {
            toast(content, {
                autoClose: 4000,
                type: 'default',
                transition: 'zoom',
            });
        },
        success: (text: string) => {
            toast(text, {
                autoClose: 4000,
                type: 'success',
                transition: 'zoom',
            });
        },
        info: (text: string) => {
            toast(text, {
                autoClose: 4000,
                type: 'info',
                transition: 'zoom',
            });
        },
        error: (text: string) => {
            toast(text, {
                autoClose: 4000,
                type: 'error',
                transition: 'zoom',
            });
        },
    };
}
