<template>
    <div>
        <div
            v-if="displayPreference === 'month'"
            class="tw-flex tw-flex-wrap tw-justify-between"
        >
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="tw-capitalize print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().subtract(1, 'month');
                        getCalendar();
                    "
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-angle-left"
                    />
                    {{ fromMoment(date).subMonths(1).monthLong }}
                </MButton>
            </div>
            <div class="tw-order-2 tw-mt-2 tw-w-full tw-text-center md:tw-order-1 md:tw-mt-0 md:tw-w-1/2 xl:tw-w-1/3">
                <MHeading class="tw-capitalize">
                    {{ fromMoment(date).toFormat('MMMM yyyy') }}
                </MHeading>
                <div class="tw-mt-1">
                    <EventTotals
                        :contract="
                            staff.activeContract(date.format())
                                ? `${staff.activeContract(date.format()).attributes.hours_per_week}h`
                                : '0h'
                        "
                        :forecast="totalPlanned(date.clone().startOf('month'), date.clone().endOf('month'))"
                        :real="totalAchieved(date.clone().startOf('month'), date.clone().endOf('month'))"
                    />
                </div>
            </div>
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="tw-capitalize print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().add(1, 'month');
                        getCalendar();
                    "
                >
                    {{ fromMoment(date).addMonths(1).monthLong }}
                    <FontAwesomeIcon
                        class="tw-ml-1"
                        icon="fa-solid fa-angle-right"
                    />
                </MButton>
            </div>
        </div>
        <div
            v-else
            class="tw-flex tw-flex-wrap tw-justify-between"
        >
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().subtract(1, 'weeks');
                        getCalendar();
                    "
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-angle-left"
                    />
                    {{ __('common:longweek_with_number', {week: fromMoment(date).subWeeks(1).toFormat('W')}) }}
                </MButton>
            </div>
            <div class="tw-order-2 tw-mt-2 tw-w-full tw-text-center md:tw-order-1 md:tw-mt-0 md:tw-w-1/2 xl:tw-w-1/3">
                <MHeading>
                    {{ __('common:longweek_with_number', {week: from.week()}) }}
                </MHeading>
                <div class="tw-mt-1">
                    <EventTotals
                        :contract="
                            staff.activeContract(date.format())
                                ? `${staff.activeContract(date.format()).attributes.hours_per_week}h`
                                : '0h'
                        "
                        :forecast="totalPlanned(from, to)"
                        :real="totalAchieved(from, to)"
                    />
                </div>
            </div>
            <div class="tw-order-1 tw-w-auto">
                <MButton
                    class="print:!tw-hidden"
                    variant="success"
                    @click="
                        date = date.clone().add(1, 'weeks');
                        getCalendar();
                    "
                >
                    {{ __('common:longweek_with_number', {week: fromMoment(date).addWeeks(1).toFormat('W')}) }}
                    <FontAwesomeIcon
                        class="tw-ml-1"
                        icon="fa-solid fa-angle-right"
                    />
                </MButton>
            </div>
        </div>

        <div class="tw-my-4 tw-flex tw-flex-wrap tw-justify-between tw-gap-2 print:tw-hidden">
            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <FilterEvents v-model:event-filters="eventFilters" />
                <CButtonGroup>
                    <MButton
                        :variant="displayPreference === 'week' ? 'primary' : 'light'"
                        @click="displayPreference = 'week'"
                    >
                        {{ __('common:week') }}
                    </MButton>
                    <MButton
                        :variant="displayPreference === 'month' ? 'primary' : 'light'"
                        @click="displayPreference = 'month'"
                    >
                        {{ __('common:month') }}
                    </MButton>
                </CButtonGroup>
            </div>
            <div class="tw-flex tw-flex-wrap tw-gap-2">
                <MMenu
                    v-if="$can('create', 'staffs_planning') && $can('update', 'staffs_planning')"
                    placement="bottom-end"
                >
                    <MMenuButton>
                        <MButton>
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-duotone fa-calendar-alt" />
                            </template>
                            {{ __('planning:manage_planning') }}
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            :label="__('planning:manage_typical_week')"
                            @click="showManageTemplatesModal = true"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-duotone fa-calendar-day"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            :label="__('planning:apply_typical_week')"
                            @click="showUseTemplateModal = true"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-duotone fa-copy"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            v-if="$can('delete', 'staffs_planning')"
                            :label="__('planning:delete_events_dots')"
                            variant="danger"
                            @click="$refs.massDeleteEventsModal.show()"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-duotone fa-calendar-times"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
                <MassDeleteEventsModal
                    ref="massDeleteEventsModal"
                    :from="from"
                    :staff="staff"
                    :to="to"
                    @deleted="getCalendar"
                />
                <MButton
                    v-if="$can('read', 'staffs_statistics')"
                    @click="$refs.staffStatisticsModal.$refs.modal.show()"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-duotone fa-swap-opacity fa-file-chart-line" />
                    </template>
                    {{ __('common:statistic_other') }}
                </MButton>
                <MMenu>
                    <MMenuButton>
                        <MTooltip
                            :label="__('planning:print_or_share')"
                            placement="top-end"
                        >
                            <MButton variant="gray">
                                <FontAwesomeIcon icon="fa-solid fa-external-link" />
                            </MButton>
                        </MTooltip>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            :label="__('planning:print_planning')"
                            @click="printPlanning"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-print"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            :label="__('planning:planning_subscription')"
                            @click="$refs.modalplanning.show()"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-calendar-alt"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
                <StaffPlanningSubscriberModal
                    ref="modalplanning"
                    :staff-model="staff"
                />
            </div>
        </div>

        <loader
            v-if="loading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div
            v-for="(week, w) in weeksToDisplay"
            v-else
            :key="'week' + w"
            style="page-break-inside: avoid"
        >
            <div
                v-if="w > 0"
                class="tw-mx-5 tw-mb-5 tw-border-b tw-border-gray-200"
            />

            <div
                class="tw-mb-2 tw-hidden tw-w-full sm:tw-block print:tw-block"
                :style="
                    fullDayEvents().count()
                        ? 'padding-left: 105px; padding-right: 55px'
                        : 'padding-left: 85px; padding-right: 55px'
                "
            >
                <Hours
                    :closing="closing()"
                    :opening="opening()"
                />
            </div>
            <div v-show="!loading">
                <div
                    v-for="(day, i) in enumerateDaysBetweenDates.filter(item => item.week() === week)"
                    :key="`day_${i}week_${w}`"
                >
                    <div
                        class="tw-mb-2 tw-flex tw-w-full tw-flex-wrap tw-items-center sm:tw-flex-nowrap print:tw-flex-nowrap"
                    >
                        <div class="tw-w-full sm:tw-w-28 print:tw-w-28">
                            <MPopover
                                placement="top"
                                trigger="hover"
                            >
                                <MHoverable class="tw-cursor-default tw-font-semibold tw-capitalize">
                                    {{ day.format('ddd DD/MM') }}
                                </MHoverable>
                                <template #content>
                                    <EventTotals
                                        :forecast="totalPlanned(day.clone().startOf('day'), day.clone().endOf('day'))"
                                        :real="totalAchieved(day.clone().startOf('day'), day.clone().endOf('day'))"
                                    />
                                </template>
                            </MPopover>
                        </div>

                        <div class="tw-w-11/12 sm:tw-w-full print:tw-w-full">
                            <PlanningGenerator
                                :closing="closing()"
                                :day="day"
                                :events="filteredEvents.all()"
                                :full-day-events="fullDayEvents().all()"
                                :opening="opening()"
                                show-note
                                :staff="staff"
                                @add="newEvent(day, staff, true, true)"
                                @push-event="events.push($event)"
                            />
                        </div>
                        <div class="tw-w-1/12 tw-pl-1 sm:tw-ml-2 sm:tw-w-8 print:tw-ml-2 print:tw-w-8">
                            <MButton
                                v-if="$can('create', 'staffs_planning')"
                                class="print:!tw-hidden"
                                variant="primary"
                                @click="newEvent(day, staff, true)"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-plus" />
                            </MButton>
                        </div>
                    </div>

                    <div
                        v-if="
                            numberOfWeeks > 1 &&
                            i + 1 === enumerateDaysBetweenDates.filter(item => item.week() === week).length
                        "
                        class="tw-mb-6 tw-ml-4 tw-w-full tw-pt-3"
                    >
                        <div class="tw-flex tw-items-center">
                            <div class="tw-mr-2 tw-text-base tw-font-semibold tw-text-gray-500">
                                {{ __('common:total') }} - {{ __('common:shortweek_with_number', {week: week}) }}
                            </div>
                            <EventTotals
                                :forecast="totalPlanned(day.clone().startOf('week'), day.clone().endOf('week'))"
                                :real="totalAchieved(day.clone().startOf('week'), day.clone().endOf('week'))"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ManageTemplatesModal
            v-if="showManageTemplatesModal"
            :current-week="weeksToDisplay.values().next().value"
            :default-plannings="events"
            :staff="staff"
            @hidden="showManageTemplatesModal = false"
        />

        <UseTemplateModal
            v-if="showUseTemplateModal"
            :staff="staff"
            @hidden="showUseTemplateModal = false"
            @update:plannings="getCalendar()"
        />

        <StaffStatisticsModal
            ref="staffStatisticsModal"
            :staff="staff"
        />

        <EventModal
            v-if="selectedEvent"
            ref="eventModal"
            :event="selectedEvent"
            @add="onEventAdded"
            @delete="onEventDeleted"
            @hidden="selectedEvent = null"
        />
    </div>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import moment from 'moment';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';

    import useEpoch from '@/modules/app/composables/useEpoch';
    import useMetrics from '@/modules/app/composables/useRum';
    import ManageTemplatesModal from '@/modules/human-resources/components/calendar/CalendarTemplate/Daily/ManageTemplates.vue';
    import UseTemplateModal from '@/modules/human-resources/components/calendar/CalendarTemplate/Daily/UseTemplate.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import FilterEvents from '@/modules/human-resources/components/calendar/FilterEvents.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import StaffPlanningSubscriberModal from '@/modules/human-resources/components/calendar/StaffPlanningSubscriberModal.vue';
    import MassDeleteEventsModal from '@/modules/human-resources/components/planning/MassDeleteEventsModal.vue';
    import StaffStatisticsModal from '@/modules/human-resources/components/statistics/StaffStatisticsModal.vue';
    import useCalendar from '@/modules/human-resources/composables/calendar/useCalendar';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';

    export default defineComponent({
        components: {
            MassDeleteEventsModal,
            PlanningGenerator: Line,
            Hours,
            FilterEvents,
            EventModal,
            EventTotals,

            ManageTemplatesModal,
            UseTemplateModal,
            StaffStatisticsModal,
            StaffPlanningSubscriberModal,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
        },
        setup(props) {
            const {parse, fromMoment} = useEpoch();
            const date = ref(moment());
            const {addAction} = useMetrics();
            const from = computed(() => {
                if (displayPreference.value === 'month') {
                    return date.value.clone().startOf('month');
                }

                return date.value.clone().startOf('week');
            });
            const to = computed(() => {
                if (displayPreference.value === 'month') {
                    return date.value.clone().endOf('month');
                }

                return date.value.clone().endOf('week');
            });
            const numberOfWeeks = computed(() => {
                return to.value.diff(from.value, 'weeks');
            });

            const showManageTemplatesModal = ref(false);
            const showUseTemplateModal = ref(false);

            const displayPreference = ref<'week' | 'month'>('week');
            watch(displayPreference, () => {
                window.localStorage.setItem('staffs:show:planning:displayRange', displayPreference.value);
                getCalendar();
            });

            const tempStaff = computed(() => {
                const staff = new StaffModel();
                staff.id = props.staff.id;

                return staff;
            });

            const {
                enumerateDaysBetweenDates,
                weeksToDisplay,
                opening,
                closing,

                filteredEvents,
                fullDayEvents,
                eventFilters,
                loading,
                getCalendar,

                events,
                selectedEvent,
                totalPlanned,
                totalAchieved,

                newEvent,
                addEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                printPlanning,
            } = useCalendar(from, to, tempStaff.value);

            onMounted(() => {
                const localDisplayPreference = window.localStorage.getItem('staffs:show:planning:displayRange');
                if (localDisplayPreference === 'week' || localDisplayPreference === 'month') {
                    displayPreference.value = localDisplayPreference;
                }

                addAction('M_HR_Staff_Summary_Display', {value: displayPreference.value === 'week' ? 'week' : 'month'});

                getCalendar();
            });

            return {
                moment,
                date,
                from,
                to,
                numberOfWeeks,
                displayPreference,

                opening,
                closing,
                enumerateDaysBetweenDates,
                weeksToDisplay,

                filteredEvents,
                fullDayEvents,
                fromMoment,
                eventFilters,
                Epoch,
                loading,
                getCalendar,
                parse,
                printPlanning,

                events,
                selectedEvent,
                totalPlanned,
                totalAchieved,

                newEvent,
                addEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                showManageTemplatesModal,
                showUseTemplateModal,
            };
        },
    });
</script>
