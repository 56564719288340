<template>
    <MMenu class="tw-inline-block">
        <MMenuButton>
            <MButton icon-menu>
                <template #left-icons>
                    <template v-if="order_by === 'date'">
                        <FontAwesomeIcon
                            :icon="defaultOrder === 'asc' ? 'fa-solid fa-sort-alpha-down' : 'fa-solid fa-sort-alpha-up'"
                        />
                    </template>
                    <template v-else-if="order_by === 'kid_name'">
                        <FontAwesomeIcon
                            :icon="defaultOrder === 'asc' ? 'fa-solid fa-sort-alpha-down' : 'fa-solid fa-sort-alpha-up'"
                        />
                    </template>
                    <template v-else-if="order_by === 'no'">
                        <FontAwesomeIcon
                            :icon="defaultOrder === 'asc' ? 'fa-solid fa-sort-alpha-down' : 'fa-solid fa-sort-alpha-up'"
                        />
                    </template>
                </template>
                <template v-if="order_by === 'date'">
                    {{ __('billing_core:by_date') }}
                </template>
                <template v-else-if="order_by === 'kid_name'">
                    {{ __('billing_core:by_kid') }}
                </template>
                <template v-else-if="order_by === 'no'">
                    {{ __('billing_invoice:by_number') }}
                </template>
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem @click="defaultOrder = 'asc'">
                <template #icon>
                    <FontAwesomeIcon icon="fa-solid fa-sort-alpha-down" />
                </template>
                {{ __('common:ascending') }}
            </MMenuItem>
            <MMenuItem @click="defaultOrder = 'desc'">
                <template #icon>
                    <FontAwesomeIcon icon="fa-solid fa-sort-alpha-up" />
                </template>
                {{ __('common:descending') }}
            </MMenuItem>
            <MMenuDivider />
            <MMenuItem @click="order_by = 'date'">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="order_by === 'date'"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('billing_core:order_by_date') }}
            </MMenuItem>
            <MMenuItem @click="order_by = 'no'">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="order_by === 'no'"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('billing_invoice:order_by_number') }}
            </MMenuItem>
            <MMenuItem
                v-if="kidOptionEnabled"
                @click="order_by = 'kid_name'"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="order_by === 'kid_name'"
                        fixed-width
                        icon="fa-solid fa-circle-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-circle"
                    />
                </template>
                {{ __('billing_core:order_by_kid') }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>

<script>
    export default {
        props: {
            orderBy: {
                type: String,
                required: true,
                default: 'no',
            },
            order: {
                type: String,
                required: true,
                default: 'asc',
            },
            kidOptionEnabled: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['update:orderBy', 'update:order'],
        data: () => ({
            order_by: 'no',
            defaultOrder: 'asc',
        }),
        watch: {
            order_by(val) {
                this.$emit('update:orderBy', val);
                window.localStorage.setItem('filter:invoice:orderBy', val);
            },
            defaultOrder(val) {
                this.$emit('update:order', val);
                window.localStorage.setItem('filter:invoice:order', val);
            },
        },
        mounted() {
            this.order_by = this.orderBy;
            this.defaultOrder = this.order;
        },
    };
</script>
