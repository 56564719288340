<template>
    <MForm>
        <MFieldset>
            <CHStack gap="2">
                <MSelectMenu
                    v-model="selectedTreatmentInternalId"
                    class="tw-flex-1"
                    :fallback-text="__('health:select_vaccine')"
                    :options="sortedTreatments"
                    text-path="name"
                    value-path="internal_id"
                />
                <MButton
                    icon-plus
                    :label="__('common:actions.add')"
                    variant="success"
                    @click="addTreatment"
                />
            </CHStack>
        </MFieldset>
    </MForm>
</template>

<script lang="ts">
    import type {ModelCollection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {filter, head, sortBy} from 'lodash-es';
    import moment from 'moment';
    import {computed, defineComponent, type PropType, reactive, type Ref, ref} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import type KidModel from '@/modules/family/models/KidModel';
    import MedicalTreatmentModel from '@/modules/health/models/MedicalTreatmentModel';
    import MedicalTreatmentType from '@/modules/health/models/MedicalTreatmentType';
    import treatmentsSource from '@/modules/health/static/treatments_source.json';

    export default defineComponent({
        components: {},
        props: {
            treatments: {
                type: Object as PropType<ModelCollection<MedicalTreatmentModel>>,
                required: true,
                default: () => reactive(collect<MedicalTreatmentModel>()),
            },
            kid: {
                type: Object as PropType<KidModel>,
                required: true,
            },
        },
        emits: ['add'],
        setup(props, {emit}) {
            const {activeOrganization} = useManager();

            // Treatments not already used in the list.
            const treatmentsAvailable = computed(() =>
                filter(treatmentsSource, value => {
                    return !props.treatments.first(item => item.attributes.internal_id === value.internal_id);
                })
            );

            // Treatment sorted by name.
            const sortedTreatments = computed(() => {
                return sortBy(treatmentsAvailable.value, 'name');
            });

            // Set default treatment.
            const selectedTreatmentInternalId: Ref<string | undefined> = ref(head(sortedTreatments.value)?.internal_id);

            /**
             * Emit a new treatment to the treatments list.
             */
            function addTreatment() {
                const treatmentModel = prepareTreatmentModelFromId(selectedTreatmentInternalId.value as string);

                if (treatmentModel) {
                    emit('add', treatmentModel);
                    selectedTreatmentInternalId.value = undefined;
                }
            }

            /**
             * Create TreatmentModel from selectedTreatmentId.
             *
             * @param id
             */
            function prepareTreatmentModelFromId(id: string) {
                const treatment = treatmentsSource.find(treatment => treatment.internal_id === id);

                if (treatment) {
                    const treatmentModel = new MedicalTreatmentModel();
                    treatmentModel.attributes.account_id = activeOrganization.value.attributes.account_id;
                    treatmentModel.attributes.name = treatment.name;
                    treatmentModel.attributes.internal_id = treatment.internal_id;
                    treatmentModel.attributes.type = MedicalTreatmentType.vaccine;
                    treatmentModel.attributes.started_at = props.kid?.attributes.birthdate
                        ? moment(props.kid.attributes.birthdate).toISOString()
                        : moment().toISOString();
                    treatmentModel.kid().associate(props.kid);

                    return treatmentModel;
                }

                return null;
            }

            return {
                selectedTreatmentInternalId,
                sortedTreatments,
                addTreatment,
            };
        },
    });
</script>
