import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

export default class TaskErrorHandler extends ErrorHandler {
    protected resolver(): any {
        return {
            reporter_id: {
                '0x3599F1FE54':
                    'activity:the_staff_record_must_be_associated_with_a_user_account_sharing_the_same_email_address',
            },
        };
    }
}
