<template>
    <CModal
        id="saveTemplateModal"
        ref="modal"
        :header-title="__('planning:manage_typical_week')"
        size="5xl"
        :visible="true"
        @hidden="$emit('hidden')"
        @shown="onShown"
    >
        <loader
            v-if="weekTemplatesLoading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <template
            v-else-if="
                (selectedWeekTemplate && selectedWeekTemplate.attributes.name) ||
                filteredWeekTemplates.filter(item => item.attributes.template_id === null).length
            "
        >
            <div class="tw-mb-7 tw-flex tw-flex-wrap">
                <div class="tw-mb-3 tw-w-full sm:tw-mb-0 sm:tw-ml-3 sm:!tw-w-1/2">
                    <MFieldset>
                        <MLabel>{{ __('hr_calendar:update_typical_week') }}</MLabel>
                        <CInline>
                            <div class="tw-mr-3 tw-h-full tw-w-1/2">
                                <MMenu class="tw-w-full">
                                    <MMenuButton class="tw-w-full">
                                        <MButton
                                            class="tw-w-full"
                                            icon-menu
                                        >
                                            {{ selectedWeekTemplate.attributes.name }}
                                        </MButton>
                                    </MMenuButton>
                                    <MMenuItems>
                                        <MMenuItem
                                            v-for="(weekTemplate, i) in filteredWeekTemplates.filter(
                                                item => item.attributes.template_id === null
                                            )"
                                            :key="'weekTemplate' + i"
                                            @click="selectedWeekTemplate = weekTemplate"
                                        >
                                            <template #icon>
                                                <FontAwesomeIcon
                                                    v-if="selectedWeekTemplate.id === weekTemplate.id"
                                                    fixed-width
                                                    icon="fa-solid fa-circle-check"
                                                />
                                                <FontAwesomeIcon
                                                    v-else
                                                    fixed-width
                                                    icon="fa-regular fa-circle"
                                                />
                                            </template>
                                            {{ weekTemplate.attributes.name }}
                                        </MMenuItem>
                                    </MMenuItems>
                                </MMenu>
                            </div>
                            <CTooltip>
                                <MButton
                                    ref="updateWeekNameBtn"
                                    variant="primary"
                                    @click="saveWeekTemplate"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-pencil-alt" />
                                </MButton>
                                <template #content>
                                    {{ __('hr_calendar:update_name_of_week') }}
                                </template>
                            </CTooltip>

                            <CTooltip>
                                <MButton
                                    class="tw-ml-2"
                                    variant="danger"
                                    @click="removeWeekTemplate"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                                </MButton>
                                <template #content>
                                    {{ __('hr_calendar:delete_typical_week') }}
                                </template>
                            </CTooltip>
                        </CInline>
                    </MFieldset>
                </div>

                <div class="tw-mt-3 sm:tw-ml-auto sm:tw-mt-0 sm:tw-self-end">
                    <MButton
                        variant="primary"
                        @click="newWeekTemplate"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-plus"
                        />
                        {{ __('hr_calendar:new_week') }}
                    </MButton>
                </div>
            </div>

            <loader
                v-if="calendarLoading"
                light="true"
                shadow="false"
                size="sm"
                :title="__('common:loading_dots')"
            />
            <div
                v-else
                class="tw-border-t tw-border-gray-200 tw-pt-3"
            >
                <div class="tw-mb-2 tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2">
                    <CTooltip class="tw-w-full sm:tw-w-min">
                        <MButton
                            class="tw-w-full"
                            size="sm"
                            variant="gray"
                            @click="fillPlanningsWithCurrentWeek"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-calendar-plus" />
                            </template>
                            {{ __('hr_calendar:use_week_with_count', {count: currentWeek}) }}
                        </MButton>
                        <template #content>
                            {{ __('hr_calendar:fill_week_type_by_copying_weeK_with_week', {week: currentWeek}) }}
                        </template>
                    </CTooltip>
                    <MButton
                        class="tw-w-full sm:tw-w-min"
                        size="sm"
                        variant="danger"
                        @click="resetEvents"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                        </template>
                        {{ __('common:actions.delete_all_dots') }}
                    </MButton>
                </div>

                <div class="tw-mb-4">
                    <CText
                        v-if="eventLoading"
                        variant="gray"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-circle-notch"
                            spin
                        />
                        {{ __('common:saving_dots') }}
                    </CText>
                    <CText
                        v-else
                        variant="success"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-check"
                        />
                        {{ __('hr_calendar:week_saved_successfully') }}
                    </CText>
                </div>

                <div
                    class="tw-mb-2 tw-hidden tw-w-full sm:tw-block"
                    style="padding-left: 44px; padding-right: 55px"
                >
                    <Hours
                        :closing="closing"
                        :opening="opening"
                    />
                </div>

                <div
                    v-for="(day, i) in daysOfWeek"
                    :key="'day_save_week' + i"
                    class="tw-mb-2 tw-flex tw-w-full tw-flex-wrap tw-items-center sm:tw-flex-nowrap"
                >
                    <div class="tw-w-full sm:tw-w-16">
                        <CTooltip>
                            <MButton
                                class="tw-capitalize"
                                variant="hover"
                            >
                                {{ fromMoment(day).weekdayShort }}
                            </MButton>
                            <template #content>
                                <div class="tw-min-w-36 tw-text-center tw-text-base tw-text-gray-600">
                                    {{ __('common:total') }}
                                    <CBadge
                                        class="tw-mr-1"
                                        size="sm"
                                        variant="cyan"
                                    >
                                        {{ totalPlannings(day) }}
                                    </CBadge>
                                </div>
                            </template>
                        </CTooltip>
                    </div>

                    <div class="tw-w-11/12 sm:tw-w-full">
                        <PlanningGenerator
                            :closing="closing"
                            create-with-modal
                            :day="day"
                            is-template
                            :opening="opening"
                            :show-clock="false"
                            show-note
                            :template-events="events"
                            :week-template="selectedWeekTemplate.id"
                            @push-event-template="selectedWeekTemplate.planningEvents().value().all().push($event)"
                        />
                    </div>
                    <div class="tw-w-1/12 tw-pl-2 sm:tw-ml-2 sm:tw-w-8">
                        <MButton
                            :round="true"
                            size="sm"
                            variant="success"
                            @click="newEvent(day)"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </MButton>
                    </div>
                </div>

                <CCenter class="tw-mt-3">
                    <EventTotals
                        :contract="
                            staff.activeContract() ? `${staff.activeContract().attributes.hours_per_week}h` : '0h'
                        "
                        :forecast="totalWeekPlannings"
                        :real="null"
                    />
                </CCenter>
            </div>

            <EventModal
                v-if="selectedEvent"
                ref="modalEditEvent"
                :event="selectedEvent"
                is-template
                @add="onEventAdded"
                @delete="onEventDeleted"
                @hidden="selectedEvent = null"
            />
        </template>
        <div
            v-else
            class="tw-rounded-md tw-bg-blue-100 tw-p-5 tw-text-center tw-text-blue-500"
        >
            <div class="tw-mb-3">
                {{ __('hr_calendar:no_week_templates') }}
            </div>
            <MButton
                variant="primary"
                @click="newWeekTemplate"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa-solid fa-plus"
                />
                {{ __('hr_calendar:new_week') }}
            </MButton>
        </div>

        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, ref, toRef, watch} from 'vue';

    import useEpoch from '@/modules/app/composables/useEpoch';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import useWeekTemplate from '@/modules/human-resources/composables/calendar/useWeekTemplate';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';

    export default defineComponent({
        components: {
            Hours,
            PlanningGenerator: Line,
            EventModal,
            EventTotals,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
            defaultPlannings: {
                type: Array as PropType<EventModel[]>,
                required: true,
            },
            currentWeek: {
                type: Number,
                required: true,
            },
        },
        emits: ['hidden'],
        setup(props) {
            const {fromMoment} = useEpoch();
            const {
                modal,
                onShown,

                opening,
                closing,
                daysOfWeek,
                calendarLoading,

                weekTemplates,
                filteredWeekTemplates,
                selectedWeekTemplate,
                weekTemplatesLoading,
                getWeekTemplates,
                newWeekTemplate,
                saveWeekTemplate,
                removeWeekTemplate,
                fillPlanningsWithCurrentWeek,

                events,
                totalWeekPlannings,
                totalPlannings,
                resetEvents,

                selectedEvent,
                modalEditEvent,
                newEvent,
                onEventAdded,
                onEventDeleted,
            } = useWeekTemplate(props.staff, toRef(props, 'defaultPlannings'), toRef(props, 'currentWeek'));

            const eventLoading = ref(false);
            watch(
                events,
                () => {
                    eventLoading.value = true;
                    setTimeout(() => {
                        eventLoading.value = false;
                    }, 300);
                },
                {deep: true}
            );

            return {
                Epoch,
                fromMoment,
                modal,
                onShown,

                opening,
                closing,
                daysOfWeek,
                calendarLoading,

                weekTemplates,
                filteredWeekTemplates,
                selectedWeekTemplate,
                weekTemplatesLoading,
                getWeekTemplates,
                newWeekTemplate,
                saveWeekTemplate,
                removeWeekTemplate,
                fillPlanningsWithCurrentWeek,

                events,
                eventLoading,
                totalWeekPlannings,
                totalPlannings,
                resetEvents,

                selectedEvent,
                modalEditEvent,
                newEvent,
                onEventAdded,
                onEventDeleted,
            };
        },
    });
</script>
