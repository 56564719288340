import type {FamilyAppSettingsEntity} from '@/modules/meeko-family/domain/entities/FamilyAppSettingsEntity';
import type {FamilyAppSettingsRepositoryPort} from '@/modules/meeko-family/domain/ports/FamilyAppSettingsRepositoryPort';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export class FamilyAppSettingsRepositoryAdapter implements FamilyAppSettingsRepositoryPort {
    async storeSettings(settings: FamilyAppSettingsEntity): Promise<void> {
        const organization = await OrganizationModel.find(settings.organizationId);
        organization.attributes.second_phone = settings.phone;
        organization.attributes.second_email = settings.email;
        organization.attributes.website = settings.website;
        organization.attributes.display_staff_last_name_on_family = settings.displayStaffLastName;

        await organization.save();
    }
}
