<script setup lang="ts">
    import useIntercom from '@/modules/app/composables/useIntercom';
    import type {ModalType} from '@/modules/app/composables/useModal';

    const props = defineProps<{
        modal: ModalType;
        acceptedInOtherNurseries: {name: string; url: string}[];
        loading: boolean;
    }>();

    const emit = defineEmits<{
        (e: 'onConfirm'): void;
    }>();

    function helpButtonClicked() {
        props.modal.hide();
        useIntercom().showArticle(10085761);
    }
</script>

<template>
    <MModal :modal="modal">
        <FontAwesomeIcon
            class="AcceptedInOtherNurseriesModal__icon"
            icon="fa-light fa-question"
        />
        <div class="AcceptedInOtherNurseriesModal__container">
            <h3 class="AcceptedInOtherNurseriesModal__title">
                {{ __('registration:accept_the_folder_or_copy_the_existing_one') }}
            </h3>
            <div>
                <div class="AcceptedInOtherNurseriesModal__text">
                    {{
                        __('registration:folder_already_exists_on_the_organization', {
                            count: acceptedInOtherNurseries.length,
                        })
                    }}
                </div>
                <ul class="AcceptedInOtherNurseriesModal__list">
                    <li
                        v-for="nursery in acceptedInOtherNurseries"
                        :key="nursery.url"
                    >
                        {{ nursery.name }}
                    </li>
                </ul>
            </div>
            <div class="AcceptedInOtherNurseriesModal__text">
                {{ __('registration:you_can_instead_copy_this_folder_to_a_new_organization') }}
            </div>
            <MButton
                class="tw-mb-4"
                variant="link"
                icon-external-link
                @click="helpButtonClicked"
            >
                {{ __('registration:copy_a_folder_to_another_organization') }}
            </MButton>
        </div>
        <template #footer="{hide}">
            <div class="AcceptedInOtherNurseriesModal__footer">
                <MButton
                    class="tw-w-full"
                    size="lg"
                    @click="hide"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    variant="primary"
                    class="'tw-w-full"
                    :loading="loading"
                    size="lg"
                    @click="emit('onConfirm')"
                >
                    {{ __('registration:accept_again') }}
                </MButton>
            </div>
        </template>
    </MModal>
</template>

<style scoped>
    .AcceptedInOtherNurseriesModal__icon {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-h-12 tw-w-12;
        @apply tw-mx-auto tw-rounded-full tw-bg-info-100;
        @apply tw-text-3xl tw-text-info-500;
    }

    .AcceptedInOtherNurseriesModal__container {
        @apply tw-mt-3 tw-flex tw-flex-col tw-gap-2 tw-text-center sm:tw-mt-5;
    }

    .AcceptedInOtherNurseriesModal__title {
        @apply tw-font-display tw-text-xl tw-leading-6;
    }

    .AcceptedInOtherNurseriesModal__text {
        @apply tw-text-gray-500;
    }

    .AcceptedInOtherNurseriesModal__list {
        @apply tw-list-inside tw-list-disc;
    }

    .AcceptedInOtherNurseriesModal__footer {
        @apply tw-grid tw-w-full tw-gap-3 sm:tw-grid-cols-2;
    }
</style>
