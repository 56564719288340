<template>
    <div
        tabindex="-1"
        @keydown.down.prevent="selectNextOption"
        @keydown.enter.prevent="keyboardSelectOption(true)"
        @keydown.space="keyboardSelectOption(false)"
        @keydown.up.prevent="selectPreviousOption"
    >
        <CCard
            ref="card"
            body-size="xs"
            :border="false"
            class="sm:sm tw-max-w-xs md:tw-max-w-md lg:tw-max-w-lg"
            header-size="xs"
        >
            <template
                v-if="searchBar || (multi && options.length)"
                #header
            >
                <CVStack gap="2">
                    <CInput
                        v-if="searchBar"
                        ref="searchInput"
                        v-model="searchValue"
                        :placeholder="__('common:search_dots')"
                    />
                    <CList>
                        <CListRow
                            v-if="multi && options.length && displaySelectAllOptions"
                            clickable
                            :size="rowSize"
                            @click="selectAllOptions(modelValue.length < options.length)"
                        >
                            <CHStack gap="2">
                                <CCheckbox
                                    class="tw-pointer-events-none"
                                    :half-checked="modelValue.length > 0 && modelValue.length < options.length"
                                    :model-value="areAllValueChecked && !isLoading"
                                />
                                <template v-if="areAllValueChecked && !isLoading">
                                    {{ __('common:actions.unselect_all') }}
                                </template>
                                <template v-else>
                                    {{ __('components:select_all') }}
                                </template>
                            </CHStack>
                        </CListRow>
                    </CList>
                </CVStack>
            </template>
            <div
                v-if="$slots['list-header']"
                class="tw-mb-2"
            >
                <slot name="list-header" />
            </div>
            <CList
                v-if="
                    filteredOptions.length ||
                    ($slots.fallback && !hideFallbackIfNoResult) ||
                    ($slots.bestMatch && bestMatch)
                "
                ref="list"
                :class="[listClass, 'tw-overflow-y-auto', 'tw-min-h-6']"
                :has-more="hasMoreResult"
                :striped="true"
                :style="{'max-height': maxHeight}"
                @want-more="onWantMore"
            >
                <CListRow
                    v-if="hasUnselect"
                    :ref="el => setElementRef(el, 'unselect')"
                    :clickable="true"
                    :force-hover="focusedOption === 'unselect'"
                    :size="rowSize"
                    @click="selectUnselect"
                    @mousemove="focusedOption = 'unselect'"
                >
                    <slot name="unselect-item">
                        <slot name="unselect-text">
                            {{ __('common:actions.unselect') }}
                        </slot>
                    </slot>
                </CListRow>
                <CListRow
                    v-if="$slots.bestMatch && bestMatch"
                    :ref="el => setElementRef(el, 'bestMatch')"
                    :clickable="true"
                    :force-hover="focusedOption === 'bestMatch'"
                    :size="rowSize"
                    @click="selectBestMatch"
                    @mousemove="focusedOption = 'bestMatch'"
                >
                    <slot
                        name="bestMatch"
                        :search-value="searchValue"
                    />
                </CListRow>
                <CListRow
                    v-if="$slots.fallback"
                    :ref="el => setElementRef(el, 'fallback')"
                    :clickable="true"
                    :force-hover="focusedOption === 'fallback'"
                    :size="rowSize"
                    @click="selectFallback()"
                    @mousemove="focusedOption = 'fallback'"
                >
                    <slot
                        name="fallback"
                        :search-value="searchValue"
                    />
                </CListRow>
                <CListRow
                    v-for="option in filteredOptions"
                    :key="uniquify(option)"
                    :ref="el => setElementRef(el, uniquify(option))"
                    :clickable="true"
                    :force-hover="!disabled && isOptionFocused(option)"
                    :size="rowSize"
                    :variant="disabled ? 'disabled' : 'default'"
                    @click="selectOption(option)"
                    @mousemove="focusedOption = option"
                >
                    <CHStack gap="2">
                        <CCheckbox
                            v-if="multi"
                            :disabled="isCheckboxLocked && isOptionSelected(option)"
                            :model-value="isOptionSelected(option)"
                        />
                        <div class="tw-w-full tw-truncate">
                            <slot
                                :option="option"
                                :search-value="searchValue"
                            >
                                <template v-if="!isObject(option)">
                                    {{ option }}
                                </template>
                            </slot>
                        </div>
                    </CHStack>
                </CListRow>
            </CList>
            <slot
                v-else
                name="empty-result"
            >
                <!--  <CListNoResult/>    -->
            </slot>
            <CListCornerLoader
                :bottom="true"
                class="tw-mb-2 tw-mr-2"
                :loading="isLoading"
                :right="true"
            />
        </CCard>
    </div>
</template>

<script lang="ts">
    import {collect} from '@meekohq/lumos';
    import {whenever} from '@vueuse/core';
    import {useFocus} from '@vueuse/core/index';
    import {stringify} from 'flatted';
    import {clone, findIndex, get, head, isArray, isEqual, isObject, omit} from 'lodash-es';
    import sha1 from 'sha1';
    import {computed, defineComponent, onBeforeUpdate, onMounted, type PropType, reactive, ref, watch} from 'vue';

    import Scroller from '@/modules/legacy/helpers/scroller.helper';

    type OptionType = unknown;

    export default defineComponent({
        components: {},
        props: {
            modelValue: {
                type: [Array, Object, Boolean, String, Number] as PropType<any>,
                default: undefined,
                required: false,
            },
            options: {type: Array, default: () => reactive([]), required: false},
            hasUnselect: {type: Boolean, default: false},
            unselectValue: {type: undefined, default: undefined, required: false},
            searchBar: {type: Boolean, default: true},
            funnel: {type: Boolean, default: false},
            displaySelectAllOptions: {type: Boolean, default: true},
            hasMoreResult: {type: Boolean, default: false},
            isLoading: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            disabled: {type: Boolean, default: false},
            searchOn: {type: Array as PropType<string[]>, default: () => reactive([])},
            listClass: {type: [String, Array], default: '', required: false},
            rowSize: {type: String, default: 'default', required: false},
            maxHeight: {type: String, default: '256px', required: false},
            refPath: {type: [String, Array] as PropType<any>, default: undefined, required: false},
            hideSelectedOption: {type: Boolean, default: false},
            hideFallbackIfNoResult: {type: Boolean, default: false},
            bestMatch: {
                type: [Array, Object, Boolean, String, Number] as PropType<any>,
                default: undefined,
                required: false,
            },
        },
        emits: ['update:modelValue', 'search', 'wantMore', 'fallback', 'bestMatch'],
        setup(props, {emit, slots}) {
            const searchValue = ref('');
            const searchInput = ref();
            const focusedOption = ref();

            const elementsRef = ref<Record<string, Element>>({});

            onBeforeUpdate(() => {
                elementsRef.value = {};
            });

            const {focused: inputFocused} = useFocus(searchInput);

            whenever(
                () => !props.isLoading,
                () => {
                    inputFocused.value = true;
                }
            );

            function setElementRef(el: Element, key: string) {
                if (el) {
                    elementsRef.value[key] = el;
                }
            }

            const specialOptions = computed(() => {
                const options = [] as string[];

                if (slots.unselect) {
                    options.push('unselect');
                }

                if (slots.bestMatch) {
                    options.push('bestMatch');
                }

                if (slots.fallback) {
                    options.push('fallback');
                }

                return options;
            });

            const areAllValueChecked = computed(() => {
                if (Array.isArray(props.modelValue)) {
                    return props.modelValue.length === props.options.length;
                } else {
                    return false;
                }
            });

            function normalizeString(text: string): string {
                return String(text)
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/\p{Diacritic}/gu, '');
            }

            function searchValueMatchWithOption(option: OptionType) {
                let match = false;

                if (props.searchOn.length) {
                    for (const property of props.searchOn) {
                        const search = normalizeString(get(option, property)).includes(
                            normalizeString(searchValue.value)
                        );
                        if (search) {
                            match = true;
                            break;
                        }
                    }

                    return match;
                }

                return true;
            }

            const filteredOptions = computed(() => {
                let options = props.options;

                // Reduce options with search value
                if (props.funnel && props.searchOn.length) {
                    options = options.filter(option => searchValueMatchWithOption(option));
                }

                return options;
            });

            const isMultiValue = computed(() => isArray(props.modelValue) && props.multi);

            /**
             * Return true if the minimum number of selected options is not satisfied
             */
            const isCheckboxLocked = computed(() => {
                return (
                    props.disabled ||
                    (props.multi && isMultiValue.value && (props.modelValue as any[]).length <= props.multiMinimum)
                );
            });

            function selectOption(option: OptionType) {
                if (props.multi) {
                    let value: any[] = clone(props.modelValue) as any[];
                    if (isMultiValue.value) {
                        // Value is an array
                        if (isOptionSelected(option)) {
                            if (!isCheckboxLocked.value) {
                                value = value.filter(item => uniquify(item) !== uniquify(option));
                            }
                        } else {
                            value.push(option);
                        }
                    } else {
                        // Value is null or undefined, and multi mode is activated
                        value = [option];
                    }

                    emit('update:modelValue', value);
                } else {
                    if (!props.disabled) {
                        if (props.hideSelectedOption) {
                            const index = props.options.indexOf(option);
                            props.options.splice(index, 1);
                        }

                        // Single mode, we return option directly
                        emit('update:modelValue', option);
                    }
                }
            }

            function selectAllOptions(checked: boolean) {
                if (checked) {
                    emit('update:modelValue', props.options);
                } else {
                    selectUnselect();
                }
            }

            function selectFallback() {
                emit('fallback');
            }

            function selectBestMatch() {
                emit('bestMatch', props.bestMatch);
            }

            function selectUnselect() {
                let unselectValue: unknown;

                // If multiMinimum is set, we take the first N values
                if (props.multiMinimum > 0) {
                    unselectValue = collect(props.modelValue).take(Number(props.multiMinimum)).toArray();
                } else {
                    // If unselectValue is undefined and we are in multi-mode, we emit an empty array
                    unselectValue = props.unselectValue === undefined && isMultiValue.value ? [] : props.unselectValue;
                }

                emit('update:modelValue', unselectValue);
            }

            function isOptionSelected(option: OptionType) {
                if (isMultiValue.value) {
                    return (props.modelValue as any[]).filter(item => uniquify(item) === uniquify(option)).length > 0;
                }

                return isEqual(props.modelValue, option);
            }

            function onWantMore() {
                emit('wantMore');
            }

            // ***
            // NAVIGATION
            // ***

            function keyboardSelectOption(force = false) {
                if (searchValue.value !== '' && !force) {
                    return;
                }

                if (focusedOption.value === 'fallback') {
                    selectFallback();
                } else if (focusedOption.value === 'bestMatch') {
                    selectBestMatch();
                } else if (focusedOption.value === 'unselect') {
                    selectUnselect();
                } else {
                    selectOption(focusedOption.value);
                }
            }

            function selectNextOption() {
                let navigationMenu;
                if (props.multi) {
                    const values = isMultiValue.value ? (props.modelValue as any[]) : [props.modelValue];
                    navigationMenu = values.concat(specialOptions.value).concat(filteredOptions.value);
                } else {
                    navigationMenu = (specialOptions.value as any[]).concat(filteredOptions.value);
                }
                const index = findIndex(navigationMenu, o => isEqual(o, focusedOption.value));
                if (index + 1 < navigationMenu.length) {
                    focusedOption.value = navigationMenu[index + 1];
                    scrollToOption(focusedOption.value);
                }
            }

            function selectPreviousOption() {
                let navigationMenu;
                if (props.multi) {
                    const values = isMultiValue.value ? (props.modelValue as any[]) : [props.modelValue];
                    navigationMenu = values.concat(specialOptions.value).concat(filteredOptions.value);
                } else {
                    navigationMenu = (specialOptions.value as any[]).concat(filteredOptions.value);
                }
                const index = findIndex(navigationMenu, o => isEqual(o, focusedOption.value));
                focusedOption.value = navigationMenu[index - 1] || navigationMenu[0];
                scrollToOption(focusedOption.value);
            }

            function selectMoreAccurateResult(navigateToOption = true) {
                if (props.modelValue === props.unselectValue && slots.unselect && searchValue.value === '') {
                    focusedOption.value = 'unselect';
                } else if (searchValue.value === '' && props.modelValue) {
                    focusedOption.value = head(filteredOptions.value);
                } else {
                    const options = filteredOptions.value.filter(option => searchValueMatchWithOption(option));

                    if (slots.bestMatch && searchValue.value !== '') {
                        focusedOption.value = 'bestMatch';
                    } else {
                        focusedOption.value = head(options) || 'fallback';
                    }
                }
                if (navigateToOption) {
                    scrollToOption(focusedOption.value);
                }
            }

            /**
             * Transform each option as an unique string
             *
             * @param option
             */
            function uniquify(option: OptionType = {}): string {
                if (props.refPath) {
                    return `${props.refPath}-${get(option, props.refPath as any)}`;
                }

                return sha1(stringify(option));
            }

            function isOptionFocused(option: OptionType) {
                if (props.refPath) {
                    return get(focusedOption.value, props.refPath as any) === get(option, props.refPath as any);
                }

                return isEqual(focusedOption.value, option);
            }

            function scrollToOption(option: OptionType) {
                if (option === 'fallback' || option === 'bestMatch' || option === 'unselect') {
                    Scroller.scrollToRef(elementsRef.value[option]);
                } else {
                    Scroller.scrollToRef(elementsRef.value[uniquify(option)]);
                }
            }

            watch(
                () => props.options,
                () => {
                    selectMoreAccurateResult();
                }
            );

            watch(searchValue, value => {
                selectMoreAccurateResult();

                emit('search', value);
            });

            onMounted(() => {
                selectMoreAccurateResult(false);
            });

            return {
                searchInput,
                keyboardSelectOption,
                selectAllOptions,
                areAllValueChecked,
                selectPreviousOption,
                selectNextOption,
                isOptionFocused,
                focusedOption,
                selectOption,
                searchValue,
                filteredOptions,
                uniquify,
                selectFallback,
                selectBestMatch,
                selectUnselect,
                isOptionSelected,
                onWantMore,
                specialOptions,
                omit,
                get,
                isObject,
                isMultiValue,
                isCheckboxLocked,
                setElementRef,
            };
        },
    });
</script>
