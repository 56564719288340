import {Epoch} from '@meekohq/lumos';
import type {Ref} from 'vue';
import {computed, ref, watch} from 'vue';

export default function useMDatePickertTime(userValue: Ref<Epoch>) {
    const internalValue = ref(Epoch.fromJSDate(userValue.value.toJSDate())) as Ref<Epoch>;

    const hour = computed(() => internalValue.value.hour);
    const minute = computed(() => internalValue.value.minute);
    const second = computed(() => internalValue.value.second);

    function setHour(value: number) {
        internalValue.value = internalValue.value.setHour(value);
    }

    function addHours(values: number) {
        internalValue.value = internalValue.value.addHours(values);
    }

    function subHours(values: number) {
        internalValue.value = internalValue.value.subHours(values);
    }

    function setMinute(value: number) {
        internalValue.value = internalValue.value.setMinute(value);
    }

    function addMinutes(values: number) {
        internalValue.value = internalValue.value.addMinutes(values);
    }

    function subMinutes(values: number) {
        internalValue.value = internalValue.value.subMinutes(values);
    }

    watch(userValue, newValue => {
        if (newValue.toTimestamp() !== internalValue.value.toTimestamp()) {
            internalValue.value = Epoch.fromJSDate(newValue.toJSDate());
        }
    });

    return {
        internalValue,

        hour,
        setHour,
        addHours,
        subHours,
        minute,
        setMinute,
        addMinutes,
        subMinutes,
        second,
    };
}
