<template>
    <div>
        <div class="tw-flex tw-flex-wrap">
            <KidsPlaningFilters
                class="tw-w-full"
                :contract-states="contractStates"
                :filter-query="filterQuery"
                :nursery="nursery"
                :search="search"
                :selected-group="selectedGroup"
                show-search
                @contract:updated="contractStates = $event"
                @filter-query:updated="filterQuery = $event"
                @group:updated="selectedGroup = $event"
                @search:updated="search = $event"
                @tags:updated="selectedTags = $event"
            />
        </div>

        <div
            v-if="loading"
            class="tw-mt-3 tw-flex tw-flex-wrap"
        >
            <CSkeleton
                class="tw-w-full"
                :count="6"
                layout="grid"
                size="lg"
            />
        </div>
        <div
            v-else-if="!kids.count()"
            class="tw-mt-3"
        >
            <CText
                as="div"
                class="tw-rounded-lg tw-bg-blue-100 tw-p-5 tw-text-center tw-text-blue-600"
            >
                {{ __('family_kid:list.no_kid_found') }}
            </CText>
        </div>
        <div
            v-else
            class="tw-mt-3 tw-flex tw-flex-wrap"
        >
            <div
                v-for="(kid, i) in kids"
                :key="'kid' + i"
                class="tw-w-1/2 md:tw-w-1/4 lg:tw-w-1/6"
            >
                <div
                    class="zoom-small tw-mb-3 tw-mr-3 tw-cursor-pointer tw-rounded-lg tw-border tw-p-2 hover:tw-shadow-md"
                    :class="kid.extra.selected ? 'tw-border-blue-500 tw-bg-blue-500' : 'tw-border-gray-200'"
                    @click="selectKid(kid)"
                >
                    <div class="tw-flex tw-items-center tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                        <KidAvatar
                            class="tw-mr-2 tw-w-10"
                            :kid-model="kid"
                        />
                        <div class="">
                            <CText
                                as="div"
                                :class="{'tw-text-white': kid.extra.selected}"
                                font-weight="semibold"
                            >
                                {{ $capitalize(kid.fullname) }}
                            </CText>
                            <CText
                                as="div"
                                :class="kid.extra.selected ? 'tw-text-white' : 'tw-text-gray-600'"
                            >
                                {{ kid.humanAge }}
                            </CText>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CPagination
            v-if="paginator && lastPage > 1"
            class="tw-mt-4 tw-text-center"
            :paginator="paginator"
            @navigate="$page => getKids($page)"
        />
    </div>
</template>

<script lang="ts">
    import {Collection, JsonAPIPaginator} from '@meekohq/lumos';
    import {set} from 'lodash-es';
    import {computed, defineComponent, nextTick, ref, watch} from 'vue';

    import KidAvatar from '@/modules/app/components/atoms/avatars/KidAvatar.vue';
    import useManager from '@/modules/app/composables/useManager';
    import KidsPlaningFilters from '@/modules/family/components/kid/Index/KidsPlaningFilters.vue';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import KidModel from '@/modules/family/models/KidModel';

    export default defineComponent({
        components: {
            KidAvatar,
            KidsPlaningFilters,
        },
        props: {
            multiple: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['change'],

        setup(props, {emit}) {
            const {activeOrganization: organization} = useManager();
            const {legacyNursery: nursery} = useManager();

            const kids = ref<Collection<KidModel>>(new Collection());
            const selectedKids = computed(() => {
                return kids.value?.filter(kid => kid.extra.selected);
            });
            watch(
                () => selectedKids.value,
                val => {
                    emit('change', val);
                }
            );

            const loading = ref(false);

            const selectedTags = ref(null);
            const contractStates = ref(['current']);
            const selectedGroup = ref(null);
            const search = ref('');

            const paginator = ref<JsonAPIPaginator | null>(null);
            const currentPage = ref(1);
            const lastPage = computed(() => paginator?.value?.lastPage ?? 0);

            const filterQuery = ref(KidModel.query());
            watch(
                () => filterQuery.value,
                () => {
                    currentPage.value = 1;
                    nextTick(() => getKids());
                },
                {immediate: true}
            );

            function getKids(page = 1) {
                if (loading.value) {
                    return;
                }
                loading.value = true;

                const mainQuery = filterQuery.value.whereHas(new KidModel().family(), query => {
                    query.whereHas(new FamilyModel().organizations(), query1 => {
                        query1.where('id', organization.value.id);
                    });
                });

                mainQuery
                    .paginate(24, page)
                    .then(response => {
                        paginator.value = new JsonAPIPaginator({
                            current_page: response.currentPage(),
                            last_page: response.lastPage(),
                        });

                        kids.value = response.items();
                        kids.value.each(kid => {
                            set(kid.extra, 'selected', false);
                        });
                        loading.value = false;
                    })
                    .catch(() => {
                        loading.value = false;
                    });
            }

            function selectKid(kid: KidModel) {
                if (props.multiple) {
                    kid.extra.selected = !kid.extra.selected;
                } else {
                    kids.value?.each(item => {
                        item.extra.selected = !kid.extra.selected && item.id === kid.id;
                    });
                }
            }

            return {
                nursery,
                kids,
                selectedKids,
                loading,
                selectedTags,
                contractStates,
                selectedGroup,
                search,
                filterQuery,

                paginator,
                currentPage,
                lastPage,

                getKids,
                selectKid,
            };
        },
    });
</script>
