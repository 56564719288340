<script setup lang="ts">
    defineProps<{
        holidays: {
            list: {id: number; name: string}[];
            selected: number | null;
        };
        saving: boolean;
    }>();

    const emit = defineEmits(['addHoliday']);
</script>

<template>
    <MForm>
        <CFormTwoColumns>
            <MFieldset class="tw-min-w-0">
                <MSelectMenu
                    v-model="holidays.selected"
                    :options="holidays.list"
                    search-bar
                    text-path="name"
                    value-path="id"
                />
            </MFieldset>
            <MFieldset>
                <MButton
                    class="tw-w-full"
                    icon-plus
                    :label="__('common:actions.add')"
                    :loading="saving"
                    variant="primary"
                    @click="emit('addHoliday', holidays.selected)"
                />
            </MFieldset>
        </CFormTwoColumns>
    </MForm>
</template>
