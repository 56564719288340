import type {Collection} from '@meekohq/lumos';
import {collect} from '@meekohq/lumos';

import type ISplittable from '@/modules/legacy/libs/periodSplitter/ISplittable';

/**
 * Filter a list of events to find events that overlap with currentEvent
 * @param currentEvent
 * @param events
 */
export default function <TSplittable extends ISplittable>(
    currentEvent: TSplittable,
    events: Collection<TSplittable>
): TSplittable[] {
    /* Reject current item to exclude it as it will overlap with itself */
    const remainingEvents = events.reject(item => item === currentEvent);

    const startedAt = remainingEvents.whereBetween('startedAt', [currentEvent.startedAt, currentEvent.endedAt]);

    const endedAt = remainingEvents.whereBetween('endedAt', [currentEvent.startedAt, currentEvent.endedAt]);

    const both = remainingEvents
        .where('startedAt', '<=', currentEvent.startedAt)
        .where('endedAt', '>=', currentEvent.endedAt);

    return collect(([] as TSplittable[]).concat(startedAt.all(), endedAt.all(), both.all()))
        .unique()
        .toArray();
}
