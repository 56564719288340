<template>
    <AuthCardTemplate>
        <template #card>
            <div class="RegisterPage__text-container">
                <h2 class="RegisterPage__title">
                    {{ __('auth:ready_to_start_ask') }}
                </h2>
                <p>
                    <span class="RegisterPage__subtitle--is-bold">{{ __('auth:without_papers') }}</span>
                    ,
                    {{ __('auth:meeko_accompagnies_you') }}
                </p>
                <ul class="RegisterPage__list">
                    <template
                        v-for="list in listItem"
                        :key="list.id"
                    >
                        <li class="RegisterPage__list-item">
                            <FontAwesomeIcon
                                class="RegisterPage__list-item-icon"
                                icon="fa-regular fa-check-circle"
                            />
                            {{ list.text }}
                        </li>
                    </template>
                </ul>
            </div>
            <div class="RegisterPage__form-container">
                <RegisterForm />
            </div>
        </template>
    </AuthCardTemplate>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import RegisterForm from '@/modules/auth/components/organisms/RegisterForm.vue';
    import AuthCardTemplate from '@/modules/auth/components/templates/AuthCardTemplate.vue';

    export default defineComponent({
        components: {RegisterForm, AuthCardTemplate},
        props: {},
        setup() {
            const listItem = [
                {
                    id: 0,
                    text: __('auth:schedule_management'),
                },
                {
                    id: 1,
                    text: __('auth:automatic_invoicing'),
                },
                {
                    id: 2,
                    text: __('auth:pre_registration_file'),
                },
                {
                    id: 3,
                    text: __('auth:daily_follow_up'),
                },
                {
                    id: 4,
                    text: __('auth:family_portal'),
                },
                {
                    id: 5,
                    text: __('common:website'),
                },
            ];

            return {listItem};
        },
    });
</script>

<style scoped>
    .RegisterPage__text-container {
        @apply tw-text-center md:tw-text-left;
        @apply tw-w-full md:tw-w-2/5;
        @apply tw-space-y-2;
        @apply tw-p-6;
    }

    .RegisterPage__form-container {
        @apply tw-border-gray-100 md:tw-border-l;
        @apply tw-w-full md:tw-w-3/5;
        @apply tw-p-6;
    }

    .RegisterPage__title {
        @apply tw-font-grandhotel tw-antialiased;
        @apply tw-text-4xl;
        @apply tw-text-primary-500;
    }

    .RegisterPage__subtitle--is-bold {
        @apply tw-font-semibold;
    }

    .RegisterPage__list {
        @apply tw-leading-10;
        @apply tw-hidden md:tw-block;
    }

    .RegisterPage__list-item {
        @apply tw-flex tw-items-center;
    }

    .RegisterPage__list-item-icon {
        @apply tw-mr-1 tw-text-2xl tw-text-green-500;
    }
</style>
