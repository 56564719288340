<script setup lang="ts">
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';
    import {ref} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import type {LegacyOrganizationType} from '@/modules/organization/utils/LegacyOrganizationType';

    import SelectInvoices from './SelectInvoices.vue';

    const props = defineProps<{
        nursery: LegacyOrganizationType;
        date: moment;
        modal: any;
    }>();

    const emit = defineEmits<{
        (e: 'updated', value: any): void;
    }>();

    const selectedInvoices = ref([]);
    const loading = ref(false);

    function send() {
        if (loading.value) {
            return;
        }

        loading.value = true;

        useApi()
            .legacy.post(
                route('nurseries.invoices.sendreminderbymail', {
                    nurseries: props.nursery.id,
                }),
                {
                    invoices: selectedInvoices.value.map(invoice => invoice.id),
                }
            )
            .then(response => {
                response.data.forEach(invoice => {
                    invoice.dunning_dates.push(moment().format());
                });

                emit('updated', response.data);

                useNotification().success(
                    __('billing_invoice:invoices_sended_again_successfully', {
                        count: selectedInvoices.value.length,
                    })
                );
                loading.value = false;
            })
            .catch(error => {
                loading.value = false;
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value));
                    });
                } else {
                    useNotification().error(__('common:errors.generic'));
                }
            });
    }
</script>

<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('billing_invoice:relaunch_invoices') }}
            -
            <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
        </template>

        <select-invoices
            v-model:selected-invoices="selectedInvoices"
            action="sendReminders"
            :date="date"
            :nursery="nursery"
        />

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                variant="primary"
                @click="send"
            >
                {{ __('billing_invoice:send_relaunch') }}
                -
                <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
            </MButton>
        </template>
    </MModal>
</template>
