<template>
    <MBox class="WidgetCore">
        <slot />
    </MBox>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style scoped>
    .WidgetCore {
        @apply tw-relative;
        max-height: inherit;
    }
</style>
