import {Model} from '@meekohq/lumos';

import TemplateOrganizationPivotModel from '@/modules/human-resources/models/TemplateOrganizationPivotModel';
import TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class TemplateModel extends Model {
    public type = 'hr/calendar/template/templates';

    public attributes: {
        id: string;
        account_id: string | undefined;
        name: string | undefined;
        description: string | undefined;
        archived_at: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        name: undefined,
        description: undefined,
        archived_at: undefined,
    };

    planningTemplates() {
        return this.hasMany('planningTemplates', TemplatePlanningModel, 'template_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            TemplateOrganizationPivotModel,
            'template_id',
            'organization_id'
        );
    }

    organizationsPivots() {
        return this.hasMany('organizationsPivots', TemplateOrganizationPivotModel, 'template_id');
    }
}
