import {lumosBindings, ServiceProvider} from '@meekohq/lumos';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import {set, unset} from 'lodash-es';
import {createApp} from 'vue';
import Vue3Toastify from 'vue3-toastify';

import AppVue from '@/App.vue';
import authorizationMiddleware from '@/modules/app/middleware/authorizationMiddleware';
import migrateToUuidMiddleware from '@/modules/app/middleware/migrateToUuidMiddleware';
import registerGlobalComponents from '@/modules/app/registerComponents';
import '@/modules/legacy/filters/filters';
import registerDirectives from '@/modules/app/registerDirectives';
import registerGlobalProperties from '@/modules/app/registerGlobalProperties';
import authMiddleware from '@/modules/auth/middleware/authMiddleware';
import registerCoherenceComponents from '@/modules/coherence-ui/registerComponents';
import registerMeekoUIComponents from '@/modules/meeko-ui/registerComponents';
import subscribeToMeekoMiddleware from '@/modules/subscription/middleware/subscribeToMeekoMiddleware';
import router from '@/router';

export default class VueServiceProvider extends ServiceProvider {
    public boot() {
        const app = createApp(AppVue);

        authMiddleware(router);
        migrateToUuidMiddleware(router);
        subscribeToMeekoMiddleware(router);
        authorizationMiddleware(router);
        app.use(Vue3Toastify, {
            dangerouslyHTMLString: true,
        });
        app.use(I18NextVue, {i18next});
        app.use(router);

        registerGlobalProperties(app);
        registerGlobalComponents(app);
        registerDirectives(app);
        registerMeekoUIComponents(app);
        registerCoherenceComponents(app);

        if (!this.app.isTest) {
            app.mount('#app');
        }
    }

    public register(): void {
        this.app.singleton(lumosBindings.ReactivitySetBinding, () => {
            return set;
        });

        this.app.singleton(lumosBindings.ReactivityDeleteBinding, () => {
            return unset;
        });

        // this.app.singleton(lumosBindings.ReactiveBinding, () => {
        //     return reactive;
        // });

        // this.app.singleton(lumosBindings.IsReactiveBinding, () => {
        //     return isReactive;
        // });
    }
}
