<template>
    <CModal
        ref="modal"
        :header-title="
            __('hr_contract:warning_member_not_in_organization', {organization: organization.attributes.name})
        "
        size="3xl"
    >
        <slot
            name="content"
            :organization="organization"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click.stop="hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                variant="primary"
                @click.stop="onSave()"
            >
                {{ __('common:actions.import') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {},
        props: {
            staff: {type: Object as PropType<StaffModel>, required: true},
        },
        emits: ['confirm'],
        setup: function (props, {emit}) {
            const {modal, hide} = useLegacyModalBus();
            const organization = ref(new OrganizationModel());

            const show = function (selectedOrganization: OrganizationModel) {
                modal.value?.show();
                organization.value = selectedOrganization;
            };
            const onSave = function () {
                hide();
                emit('confirm', organization.value);
            };

            return {
                organization,
                modal,
                onSave,
                show,
                hide,
            };
        },
    });
</script>
