<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';

    import useAbility from '@/modules/app/composables/useAbility';

    defineProps<{
        selectedInvoiceType: {text: string; value: string};
        invoiceTypeOptions: {text: string; value: string}[];
        fromDate: Epoch;
        toDate: Epoch;
    }>();

    const emit = defineEmits<{
        (e: 'openExportsModal'): void;
        (e: 'update:selected-invoice-type', value: {text: string; value: string}): void;
        (e: 'update:from-date' | 'update:to-date', value: Epoch): void;
    }>();

    const {can} = useAbility();
</script>

<template>
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2">
        <MPopover v-if="can('read', 'dashboard')">
            <MButton :label="__('dashboard:choose_filters')">
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-filter" />
                </template>
            </MButton>
            <template #content>
                <div class="tw-flex tw-min-w-48 tw-flex-col tw-gap-2">
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full tw-justify-between"
                                icon-menu
                                :label="selectedInvoiceType.text"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem
                                v-for="(item, i) in invoiceTypeOptions"
                                :key="'dataOption' + i"
                                @click="emit('update:selected-invoice-type', item)"
                            >
                                <template #icon>
                                    <FontAwesomeIcon
                                        v-if="selectedInvoiceType.value === item.value"
                                        fixed-width
                                        icon="fa-solid fa-circle-check"
                                    />
                                    <FontAwesomeIcon
                                        v-else
                                        fixed-width
                                        icon="fa-regular fa-circle"
                                    />
                                </template>
                                {{ item.text }}
                            </MMenuItem>
                        </MMenuItems>
                    </MMenu>
                    <MMonthPicker
                        :model-value="fromDate"
                        @update:model-value="emit('update:from-date', $event)"
                    />
                    <MMonthPicker
                        :model-value="toDate"
                        @update:model-value="emit('update:to-date', $event)"
                    />
                </div>
            </template>
        </MPopover>
        <MButton
            v-if="can('read', 'export')"
            :label="__('common:actions.export')"
            @click="emit('openExportsModal')"
        >
            <template #left-icons>
                <FontAwesomeIcon icon="fa-solid fa-download" />
            </template>
        </MButton>
    </div>
</template>
