<template>
    <MForm>
        <MFieldset>
            <MLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-duotone fa-address-book" />
                </template>
                {{ __('common:family') }}
            </MLabel>
            <select-family
                v-model="localFamilyModel"
                button-class="tw-w-full"
                :create-option-enabled="false"
                :disabled="familyModel !== undefined"
                :organization-model="organizationModel"
                @update:model-value="onFamilyChange"
            />
        </MFieldset>
        <CFormTwoColumns>
            <MFieldset>
                <MLabel>{{ __('common:first_name') }}*</MLabel>
                <CInput
                    ref="first_name"
                    v-model="localMemberModel.attributes.first_name"
                    v-focus
                    :has-error="memberError.hasError('first_name')"
                    :placeholder="__('common:first_name')"
                />
                <CFormErrorMessageList
                    v-if="memberError.hasError('first_name')"
                    :errors="memberError.getErrors('first_name')"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('common:last_name') }}*</MLabel>
                <CInput
                    v-model="localMemberModel.attributes.last_name"
                    :has-error="memberError.hasError('last_name')"
                    :placeholder="__('common:last_name')"
                />
                <CFormErrorMessageList
                    v-if="memberError.hasError('last_name')"
                    :errors="memberError.getErrors('last_name')"
                />
            </MFieldset>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-venus-mars" />
                    </template>
                    {{ __('common:gender_one') }}
                </MLabel>
                <MSelectMenu
                    v-model="localMemberModel.attributes.gender"
                    :has-error="memberError.hasError('gender')"
                    :options="[
                        {value: 'male', text: __('common:gender.mr')},
                        {value: 'female', text: __('common:gender.ms')},
                    ]"
                />
                <CFormErrorMessageList :errors="memberError.getErrors('gender')" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-birthday-cake" />
                    </template>
                    {{ __('common:birthdate') }}*
                </MLabel>
                <CFormDatepicker
                    v-model="localMemberModel.attributes.birthdate"
                    format="YYYY-MM-DD"
                    :has-error="memberError.hasError('birthdate')"
                />
                <CFormErrorMessageList :errors="memberError.getErrors('birthdate')" />
            </MFieldset>
        </CFormTwoColumns>
        <MFieldset>
            <MLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                </template>
                {{ __('common:job') }}
            </MLabel>
            <CInput
                v-model="localMemberModel.attributes.job"
                :has-error="memberError.hasError('job')"
                :placeholder="__('common:job')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('job')" />
        </MFieldset>
        <MFieldset>
            <MLabel>
                <template #icon>
                    <CafIcon class="inline-block tw-h-4 tw-w-4 tw-fill-current tw-text-blue-500" />
                </template>
                {{ __('family_member:beneficiary_number') }}
            </MLabel>
            <CInput
                v-model="localMemberModel.attributes.allocator_no"
                :has-error="memberError.hasError('allocator_no')"
                :placeholder="__('family_member:beneficiary_number_short')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('allocator_no')" />
        </MFieldset>
        <MFieldset>
            <MLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-regular fa-at" />
                </template>
                {{ __('common:email_address') }}
            </MLabel>
            <CInput
                v-model="localMemberModel.attributes.email"
                :has-error="memberError.hasError('email')"
                :placeholder="__('common:email_example')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('email')" />
        </MFieldset>
        <CDisclosure
            class="tw-mb-2"
            :open="false"
            size="sm"
            :title="__('common:phone.general')"
        >
            <MForm>
                <MFieldset>
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-solid fa-phone" />
                        </template>
                        {{ __('common:phone.general') }}
                    </MLabel>
                    <CInput
                        v-model="localMemberModel.attributes.phone"
                        :has-error="memberError.hasError('phone')"
                        :placeholder="__('common:phone.example.general')"
                    />
                    <CFormErrorMessageList :errors="memberError.getErrors('phone')" />
                </MFieldset>
                <CFormTwoColumns>
                    <MFieldset>
                        <MLabel>
                            <template #icon>
                                <FontAwesomeIcon icon="fa-solid fa-mobile-alt" />
                            </template>
                            {{ __('common:phone.cellular') }}
                        </MLabel>
                        <CInput
                            v-model="localMemberModel.attributes.mobile_phone"
                            :has-error="memberError.hasError('mobile_phone')"
                            :placeholder="__('common:phone.example.cellular')"
                        />
                        <CFormErrorMessageList :errors="memberError.getErrors('mobile_phone')" />
                    </MFieldset>
                    <MFieldset custom-class="tw-mb-0">
                        <MLabel>
                            <template #icon>
                                <FontAwesomeIcon icon="fa-solid fa-phone-office" />
                            </template>
                            {{ __('common:phone.work') }}
                        </MLabel>
                        <CInput
                            v-model="localMemberModel.attributes.office_phone"
                            :has-error="memberError.hasError('office_phone')"
                            :placeholder="__('common:phone.example.general')"
                        />
                        <CFormErrorMessageList :errors="memberError.getErrors('office_phone')" />
                    </MFieldset>
                </CFormTwoColumns>
            </MForm>
        </CDisclosure>
        <CDisclosure
            class="tw-mb-4"
            :open="false"
            size="sm"
            :title="__('common:address.address')"
            :visible="memberError.hasError('address')"
        >
            <AddressFields
                address-path="address"
                :model="localMemberModel"
                :model-error="memberError"
                pointer-path="/data/attributes/address"
            />
        </CDisclosure>
        <MFieldset>
            <MLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-duotone fa-envelope-open-text" />
                </template>
                {{ __('common:send_account_bills_by_email') }}
            </MLabel>
            <CButtonGroup class="tw-w-full">
                <MButton
                    class="tw-w-full"
                    :variant="!memberWillReceiveBillingEmail ? 'primary' : undefined"
                    @click="memberWillReceiveBillingEmail = false"
                >
                    {{ __('common:no') }}
                </MButton>
                <MButton
                    class="tw-w-full"
                    :variant="memberWillReceiveBillingEmail ? 'primary' : undefined"
                    @click="memberWillReceiveBillingEmail = true"
                >
                    {{ __('common:yes') }}
                </MButton>
            </CButtonGroup>
        </MFieldset>
        <MFieldset>
            <MLabel>
                <template #icon>
                    <FontAwesomeIcon icon="fa-duotone fa-note" />
                </template>
                {{ __('common:note_one') }}
            </MLabel>
            <MTextarea
                v-model="localMemberModel.attributes.note"
                :has-error="memberError.hasError('note')"
                :placeholder="__('common:note_one')"
            />
            <CFormErrorMessageList :errors="memberError.getErrors('note')" />
        </MFieldset>
        <CFormSubmitTrigger />
    </MForm>
</template>

<script setup lang="ts">
    import type {PropType} from 'vue';
    import {onBeforeMount, ref} from 'vue';

    import AddressFields from '@/modules/cashier/components/core/address/molecules/AddressFields.vue';
    import SelectFamily from '@/modules/family/components/family/SelectFamily.vue';
    import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import MemberOrganizationPivot from '@/modules/family/models/MemberOrganizationPivot';
    import MemberError from '@/modules/family/utils/member/memberError';
    import CafIcon from '@/modules/legacy/components/icons/CafIcon.vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    defineExpose({save});

    const props = defineProps({
        organizationModel: {
            type: Object as PropType<OrganizationModel>,
            required: true,
        },
        memberModel: {
            type: Object as PropType<MemberModel>,
            default: undefined,
        },
        familyModel: {
            type: Object as PropType<FamilyModel>,
            default: undefined,
        },
    });

    const emit = defineEmits(['loading', 'saved']);

    const localMemberModel = ref(new MemberModel());
    const localFamilyModel = ref<FamilyModel>();
    const memberError = ref(new MemberError());
    const memberWillReceiveBillingEmail = ref(false);

    onBeforeMount(() => {
        localFamilyModel.value = props.familyModel;
        localMemberModel.value.attributes.last_name = props.familyModel.attributes.name;
        localMemberModel.value.attributes.nursery_id = props.organizationModel.id;
        localMemberModel.value.attributes.address.country_code =
            props.organizationModel.attributes.address.country_code ?? 'FR';
    });

    function onFamilyChange(familyModel: FamilyModel | undefined) {
        if (familyModel) {
            localMemberModel.value.attributes.last_name = familyModel.attributes.name;
        }
    }

    function save() {
        emit('loading', true);
        localMemberModel.value
            .save()
            .then(() => {
                if (localMemberModel.value.wasRecentlyCreated) {
                    const promises = [] as Promise<any>[];

                    if (props.familyModel) {
                        // Attach to family
                        const familyPivot = new FamilyMemberPivot();
                        familyPivot.attributes.account_id = localMemberModel.value.attributes.account_id;
                        familyPivot.attributes.member_id = localMemberModel.value.id;
                        familyPivot.attributes.family_id = props.familyModel.id;
                        promises.push(familyPivot.save());
                    }

                    // Attach to organization
                    const pivot = new MemberOrganizationPivot();
                    pivot.attributes.account_id = localMemberModel.value.attributes.account_id;
                    pivot.attributes.member_id = localMemberModel.value.id;
                    pivot.attributes.organization_id = props.organizationModel.id;
                    promises.push(pivot.save());

                    Promise.all(promises).then(() => {
                        emit('saved', localMemberModel.value);
                        emit('loading', false);
                    });
                } else {
                    emit('saved', localMemberModel.value);
                    emit('loading', false);
                }
            })
            .catch(error => {
                emit('loading', false);
                memberError.value.reset(error);
            });
    }
</script>
