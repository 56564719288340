<script lang="ts" setup>
    import type {PropType} from 'vue';
    import {computed} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import {showCAF} from '@/modules/legacy/libs/organization';

    const props = defineProps({
        invoice: {
            type: Object as PropType<LegacyInvoiceType>,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
        subTotal: {
            type: Number,
            required: true,
        },
        nursery: {
            type: Object,
            required: true,
        },
        editingAllocations: {
            type: Boolean,
            required: true,
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['unlockInvoice', 'uncollectibleInvoice']);
    const {can} = useAbility();

    const showUncollectibleButton = computed(() => {
        return !props.editable && props.invoice.invoice_type === 'invoice';
    });

    const invoiceExists = computed(() => {
        return props.invoice.id;
    });

    const showCaf = computed(() => {
        return showCAF(props.nursery.country) && props.invoice.type === 'presence' && invoiceExists.value;
    });
</script>

<template>
    <div class="EditInvoiceHeaderActions">
        <MButton
            v-if="!editable && can('update', 'invoices')"
            :disabled="editingAllocations"
            icon-edit
            :label="__('billing_invoice:edit', {context: subTotal < 0 ? 'credit_note' : 'invoice'})"
            :loading="isProcessing"
            @click="emit('unlockInvoice')"
        />
        <MMenu v-if="invoiceExists">
            <MMenuButton>
                <MButton
                    :disabled="isProcessing"
                    icon-ellipsis
                />
            </MMenuButton>
            <MMenuItems>
                <a :href="invoice.download_url">
                    <MMenuItem :label="__('common:actions.download_pdf')">
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-download"
                            />
                        </template>
                    </MMenuItem>
                </a>
                <a
                    v-if="showCaf"
                    :href="invoice.download_caf_url"
                >
                    <MMenuItem :label="__('billing_invoice:download_caf_certificate')">
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-download"
                            />
                        </template>
                    </MMenuItem>
                </a>
                <MMenuItem
                    v-if="showUncollectibleButton"
                    :label="__('billing_invoice:uncollectible_invoice')"
                    variant="danger"
                    @click="emit('uncollectibleInvoice')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-reply"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </div>
</template>

<style scoped>
    .EditInvoiceHeaderActions {
        @apply tw-flex tw-gap-2;
    }
</style>
