import type {QueryBuilder} from '@meekohq/lumos';
import {Model} from '@meekohq/lumos';

import AddressModel from '@/modules/cashier/models/AddressModel';
import ContactModel from '@/modules/cashier/models/ContactModel';
import OrderModel from '@/modules/cashier/models/OrderModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import TransactionStatusValue from '@/modules/cashier/transaction/domain/TransactionStatusValue';
import TransactionTypeValue from '@/modules/cashier/transaction/domain/TransactionTypeValue';
import FamilyCustomerPivot from '@/modules/family/models/FamilyCustomerPivot';
import FamilyModel from '@/modules/family/models/FamilyModel';
import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';

export default class CustomerModel extends Model {
    public type = 'cashier/customers';

    public attributes: {
        id: string;
        name: string | undefined;
        person: string | undefined;
        tenant_id: string | undefined;
        account_number: string | undefined;
        vat_number: string | undefined;
        default_billing_address_id: string | undefined;
        default_shipping_address_id: string | undefined;
        archived_at: string | null;
    } = {
        id: this.uuid(),
        name: undefined,
        person: undefined,
        tenant_id: undefined,
        account_number: undefined,
        vat_number: undefined,
        default_billing_address_id: undefined,
        default_shipping_address_id: undefined,
        archived_at: null,
    };

    public computed: {
        balance_amount: number;
        invoice_amount: number;
        credit_note_amount: number;
        sales_amount: number;
        invoice_count: number;
        credit_note_count: number;
        draft_invoice_count: number;
        pending_invoice_count: number;
        unpaid_invoice_count: number;
        uncollectible_invoice_count: number;
        incomplete_invoice_count: number;
        succeeded_transaction_amount: number;
        succeeded_transaction_count: number;
        pending_transaction_amount: number;
        pending_transaction_count: number;
        failed_transaction_amount: number;
        failed_transaction_count: number;
        draft_payment_amount: number;
        pending_payment_amount: number;
        upcoming_payment_amount: number;
        unpaid_payment_amount: number;
        uncollectible_payment_amount: number;
        incomplete_payment_amount: number;
        unpaid_payment_draft_amount: number;
        upcoming_payment_draft_amount: number;
        draft_payment_refund_amount: number;
        pending_payment_refund_amount: number;
        upcoming_payment_refund_amount: number;
        unpaid_payment_refund_amount: number;
        incomplete_payment_refund_amount: number;
        currency_iso_code: string;
        first_unpaid_payment_at: string | null;
        last_unpaid_payment_at: string | null;
        balance_amount_updated_at: string;
    } = {
        balance_amount: 0,
        invoice_amount: 0,
        credit_note_amount: 0,
        sales_amount: 0,
        invoice_count: 0,
        credit_note_count: 0,
        draft_invoice_count: 0,
        pending_invoice_count: 0,
        unpaid_invoice_count: 0,
        uncollectible_invoice_count: 0,
        incomplete_invoice_count: 0,
        succeeded_transaction_amount: 0,
        succeeded_transaction_count: 0,
        pending_transaction_amount: 0,
        pending_transaction_count: 0,
        failed_transaction_amount: 0,
        failed_transaction_count: 0,
        draft_payment_amount: 0,
        pending_payment_amount: 0,
        upcoming_payment_amount: 0,
        unpaid_payment_amount: 0,
        uncollectible_payment_amount: 0,
        incomplete_payment_amount: 0,
        unpaid_payment_draft_amount: 0,
        upcoming_payment_draft_amount: 0,
        draft_payment_refund_amount: 0,
        pending_payment_refund_amount: 0,
        upcoming_payment_refund_amount: 0,
        unpaid_payment_refund_amount: 0,
        incomplete_payment_refund_amount: 0,
        currency_iso_code: '',
        first_unpaid_payment_at: null,
        last_unpaid_payment_at: null,
        balance_amount_updated_at: '',
    };

    get isCompany() {
        return this.attributes.person === 'legal';
    }

    get isArchived() {
        return this.attributes.archived_at !== null;
    }

    // We use unpaid_payment_amount instead of unpaid_invoice_count
    // because this does not include the draft invoices
    get isUnpaid() {
        return this.computed.unpaid_payment_amount > 0;
    }

    // We use pending_payment_amount instead of pending_invoice_count
    // because this does not include the draft invoices
    get isPending() {
        return this.computed.pending_payment_amount > 0 || this.computed.upcoming_payment_amount > 0;
    }

    get availableCreditNoteAmount() {
        return (
            this.computed.pending_payment_refund_amount +
            this.computed.upcoming_payment_refund_amount +
            this.computed.unpaid_payment_refund_amount
        );
    }

    get availableTransactionCreditAmount() {
        return (
            this.transactions()
                .value()
                .where('attributes.type', TransactionTypeValue.credit)
                .where('attributes.status', TransactionStatusValue.succeeded)
                .where('computed.remaining_to_distribute_amount', '>', 0)
                .sum(t => t.computed.remaining_to_distribute_amount ?? 0) ?? 0
        );
    }

    get availableTransactionDebitAmount() {
        return (
            this.transactions()
                .value()
                .where('attributes.type', TransactionTypeValue.debit)
                .where('attributes.status', TransactionStatusValue.succeeded)
                .where('computed.remaining_to_distribute_amount', '>', 0)
                .sum(t => t.computed.remaining_to_distribute_amount ?? 0) ?? 0
        );
    }

    get availableTotalCreditAmount() {
        return this.availableTransactionCreditAmount + this.availableCreditNoteAmount;
    }

    families() {
        return this.belongsToMany('families', FamilyModel, FamilyCustomerPivot, 'customer_id', 'family_id');
    }

    tenant() {
        return this.belongsTo('tenant', TenantModel, 'tenant_id');
    }

    orders() {
        return this.hasMany('orders', OrderModel, 'customer_id');
    }

    addresses() {
        return this.hasMany('addresses', AddressModel, 'customer_id');
    }

    contacts() {
        return this.hasMany('contacts', ContactModel, 'customer_id');
    }

    tags() {
        return this.morphToMany('tags', TagModel, TagPivot, 'resource', undefined, 'tag_id');
    }

    transactions() {
        return this.hasMany('transactions', TransactionModel, 'customer_id');
    }

    scopeWithActiveContract(mainQuery: QueryBuilder<any>) {
        return mainQuery.whereHas(new CustomerModel().families(), query2 => {
            query2.whereHas(new FamilyModel().kids(), query3 => query3.scope('withActiveContract'));
        });
    }
}
