export enum AvailableOperatingCountries {
    democraticRepublicOfTheCongo = 'CD',
    congo = 'CG',
    france = 'FR',
    switzerland = 'CH',
    belgium = 'BE',
    luxembourg = 'LU',
    djibouti = 'DJ',
    mauritius = 'MU',
    israel = 'IL',
    reunion = 'RE',
    morocco = 'MA',
    martinique = 'MQ',
    monaco = 'MC',
    newCaledonia = 'NC',
    guadeloupe = 'GP',
    frenchGuiana = 'GF',
    saintMartin = 'MF',
    senegal = 'SN',
}
