import {ServiceProvider} from '@meekohq/lumos';

import DocumentModel from '@/modules/document/models/DocumentModel';
import DocumentOrganizationPivot from '@/modules/document/models/DocumentOrganizationPivot';
import DocumentPivot from '@/modules/document/models/DocumentPivot';

export default class DocumentsServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([DocumentModel, DocumentPivot, DocumentOrganizationPivot]);
    }
}
