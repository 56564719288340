<script setup lang="ts">
    import {ref} from 'vue';

    import type {ModalType} from '@/modules/app/composables/useModal';
    import useMetrics from '@/modules/app/composables/useRum';
    import CopyKidForm from '@/modules/family/components/kid/Kid/CopyKidForm.vue';
    import CopyKidList from '@/modules/family/components/kid/Kid/CopyKidList.vue';
    import {useKidCopy} from '@/modules/family/composables/kid/useKidCopy';
    import type KidModel from '@/modules/family/models/KidModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    const props = defineProps<{
        modal: ModalType;
        kidModel: KidModel;
    }>();

    const {addAction} = useMetrics();

    const {errors, isCopying, copy} = useKidCopy();

    const selectedOrganization = ref<OrganizationModel>();

    async function onCopyKidButtonClicked() {
        if (!selectedOrganization.value) {
            return;
        }

        await copy(props.kidModel.getKey(), selectedOrganization.value.getKey());

        addAction('Taux d’utilisation de la copie d’un dossier', {
            account_id: selectedOrganization.value.attributes.account_id,
            organization_id: selectedOrganization.value.getKey(),
        });

        props.modal.hide();
    }
</script>

<template>
    <MModal
        :modal="modal"
        :header-title="__('family_kid:copy_to_another_organization')"
        size="3xl"
    >
        <CopyKidForm
            v-model="selectedOrganization"
            :errors="errors"
            :kid-model="kidModel"
        />
        <CopyKidList class="tw-mt-6" />
        <template #footer-end="{hide}">
            <MButton @click="hide">
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                variant="primary"
                :disabled="!selectedOrganization"
                :loading="isCopying"
                @click="onCopyKidButtonClicked"
            >
                {{ __('family_kid:confirm_copy') }}
            </MButton>
        </template>
    </MModal>
</template>
