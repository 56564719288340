<template>
    <div>
        <p class="tw-mb-0 tw-text-base tw-text-gray-600">
            <span class="tw-font-semibold">{{ notification.data.title }}</span>
            .
        </p>
        <p class="tw-mb-0 tw-mt-1 tw-text-sm">
            {{ notification.data.content }}
        </p>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            notification: {type: Object},
        },
        computed: {
            moment() {
                return moment;
            },
        },
    };
</script>
