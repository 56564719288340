<template>
    <CCard
        v-if="customerModel"
        body-size="xs"
        :border="false"
        :shadow="true"
    >
        <template #header>
            <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
                <CCardTitle>
                    <FontAwesomeIcon
                        class="tw-mr-2 tw-text-blue-500"
                        icon="fa-duotone fa-address-book"
                    />
                    {{ __('billing_core:contact_persons') }}
                </CCardTitle>
                <MButton
                    :icon-plus="true"
                    @click="showCreateContactModal = true"
                >
                    {{ __('common:actions.add') }}
                </MButton>
                <CreateContactModal
                    v-if="showCreateContactModal"
                    ref="modal"
                    :customer-model="customerModel"
                    @created="onContactCreated"
                    @hidden="showCreateContactModal = false"
                />
            </div>
        </template>
        <ContactList
            ref="list"
            :customer-model="customerModel"
            @deleted="onContactDeleted"
            @updated="onContactUpdated"
        />
    </CCard>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    import ContactList from '@/modules/cashier/components/core/contact/organisms/ContactList.vue';
    import CreateContactModal from '@/modules/cashier/components/core/contact/organisms/CreateContactModal.vue';
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';

    export default defineComponent({
        components: {ContactList, CreateContactModal},
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
        },
        emits: ['created', 'deleted', 'updated'],
        setup(props, {emit}) {
            const list = ref(null);
            const showCreateContactModal = ref(false);

            function onContactCreated(contactModel: ContactModel) {
                // @ts-ignore
                list.value.addToResult(contactModel);
                emit('created');
            }

            function onContactDeleted(contactModel: ContactModel) {
                emit('deleted', contactModel);
            }

            function onContactUpdated(contactModel: ContactModel) {
                emit('updated', contactModel);
            }

            return {
                list,
                onContactCreated,
                onContactDeleted,
                onContactUpdated,
                showCreateContactModal,
            };
        },
    });
</script>
