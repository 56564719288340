<template>
    <div
        class="item"
        :class="[{unread: !notification.read_at}, {'tw-cursor-pointer hover:!tw-bg-blue-100': resourceUrl !== null}]"
        @click.self="handleNotificationClick()"
    >
        <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
            <p
                v-if="haveManyNurseries && nursery"
                class="small tw-mb-0 tw-mr-12 tw-text-gray-400"
            >
                {{ nursery.name }} - {{ nursery.city }}
            </p>
            <slot
                v-else
                name="tag"
            >
                <span
                    v-if="$getNotificationCharacteristics(notification.data.notification_type)"
                    class="tw-rounded-lg tw-font-medium"
                    :style="$getNotificationCharacteristics(notification.data.notification_type).style"
                >
                    <i
                        class="tw-mr-1"
                        :class="$getNotificationCharacteristics(notification.data.notification_type).icon"
                    />
                    {{ $getNotificationCharacteristics(notification.data.notification_type).title }}
                </span>
            </slot>
            <div class="tw-whitespace-nowrap">
                <span
                    v-if="showDate"
                    class="small tw-text-gray-400"
                >
                    {{ moment.unix(notification.created_at).fromNow() }}
                </span>
                <MMenu>
                    <MMenuButton>
                        <MButton
                            icon-ellipsis
                            size="sm"
                            variant="ghost"
                        />
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            v-if="!notification.read_at"
                            :label="__('notification:mark_as_read')"
                            @click="updateNotification(moment().unix())"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-eye"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            v-if="notification.read_at"
                            :label="__('notification:mark_as_unread')"
                            @click="updateNotification(null)"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-eye-slash"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuItem
                            :label="__('common:actions.delete')"
                            variant="danger"
                            @click="deleteNotification"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    fixed-width
                                    icon="fa-solid fa-trash"
                                />
                            </template>
                        </MMenuItem>
                        <MMenuDivider
                            v-if="
                                notification.data.nursery_id &&
                                (notification.data.family_member_id || notification.data.kid_id)
                            "
                        />
                        <router-link
                            v-if="notification.data.family_member_id && notification.data.nursery_id"
                            :to="{
                                name: 'familyMembers.show',
                                params: {
                                    nursery: notification.data.nursery_id,
                                    familyMember: notification.data.family_member_id,
                                },
                            }"
                        >
                            <MMenuItem :label="__('notification:see_conversation')">
                                <template #icon>
                                    <FontAwesomeIcon
                                        fixed-width
                                        icon="fa-solid fa-comments"
                                    />
                                </template>
                            </MMenuItem>
                        </router-link>
                        <router-link
                            v-if="notification.data.kid_id && notification.data.nursery_id"
                            :to="{
                                name: 'kids.show',
                                params: {nursery: notification.data.nursery_id, kid: notification.data.kid_id},
                            }"
                        >
                            <MMenuItem :label="__('notification:see_kid')">
                                <template #icon>
                                    <FontAwesomeIcon
                                        fixed-width
                                        icon="fa-solid fa-child-reaching"
                                    />
                                </template>
                            </MMenuItem>
                        </router-link>
                    </MMenuItems>
                </MMenu>
                <span
                    v-if="showHideButton"
                    class="small tw-cursor-pointer tw-text-gray-400"
                    @click.stop="hideNotification"
                >
                    <FontAwesomeIcon icon="fa-solid fa-times-circle" />
                </span>
            </div>
        </div>

        <slot
            v-if="haveManyNurseries && nursery"
            name="tag"
        >
            <span
                v-if="$getNotificationCharacteristics(notification.data.notification_type)"
                class="tw-rounded-full tw-font-semibold"
                :style="$getNotificationCharacteristics(notification.data.notification_type).style"
            >
                <i
                    class="tw-mr-1"
                    :class="$getNotificationCharacteristics(notification.data.notification_type).icon"
                />
                {{ $getNotificationCharacteristics(notification.data.notification_type).title }}
            </span>
        </slot>

        <slot name="content">
            <component
                :is="$getNotificationCharacteristics(notification.data.notification_type).component"
                :class="{'tw-mt-2': haveManyNurseries}"
                :notification="notification"
            />
        </slot>
    </div>
</template>

<script lang="ts">
    import {Lang} from '@meekohq/lumos';
    import moment from 'moment';
    import {computed, defineComponent, onMounted, ref, toRef} from 'vue';
    import {useRouter} from 'vue-router';

    import useResource from '@/modules/app/composables/useResource';
    import useMetrics from '@/modules/app/composables/useRum';
    import {notification} from '@/modules/legacy/mixins/notification';
    import AbsenceRequest from '@/modules/notification/components/Types/AbsenceRequest.vue';
    import InvoiceUnpaid from '@/modules/notification/components/Types/InvoiceUnpaid.vue';
    import LateRequest from '@/modules/notification/components/Types/LateRequest.vue';
    import Meeting from '@/modules/notification/components/Types/Meeting.vue';
    import MessageRequest from '@/modules/notification/components/Types/MessageRequest.vue';
    import NewDocument from '@/modules/notification/components/Types/NewDocument.vue';
    import NewPost from '@/modules/notification/components/Types/NewPost.vue';
    import NewRegistrationForm from '@/modules/notification/components/Types/NewRegistrationForm.vue';
    import OccasionalRequest from '@/modules/notification/components/Types/OccasionalRequest.vue';
    import PresenceUnclocked from '@/modules/notification/components/Types/PresenceUnclocked.vue';
    import StaffPresenceUnclocked from '@/modules/notification/components/Types/StaffPresenceUnclocked.vue';
    import TaskReminder from '@/modules/notification/components/Types/TaskReminder.vue';
    import ThrottleLogin from '@/modules/notification/components/Types/ThrottleLogin.vue';
    import UpdatedRegistrationForm from '@/modules/notification/components/Types/UpdatedRegistrationForm.vue';
    import useMarkAsReadNotification from '@/modules/notification/composables/useMarkAsReadNotification';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {
            MessageRequest,
            AbsenceRequest,
            OccasionalRequest,
            LateRequest,
            NewRegistrationForm,
            UpdatedRegistrationForm,
            PresenceUnclocked,
            StaffPresenceUnclocked,
            InvoiceUnpaid,
            NewPost,
            NewDocument,
            ThrottleLogin,
            TaskReminder,
            Meeting,
        },
        mixins: [notification],
        props: {
            notification: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            showDate: {
                type: Boolean,
                default: true,
            },
            showHideButton: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['updateNotification', 'deleteNotification', 'hideNotification'],
        setup(props, {emit}) {
            const nursery = ref<any>(null);

            const {addAction} = useMetrics();

            const {init, computedResources} = useResource(toRef(props.notification.data, 'resources'));
            const resourceUrl = computed(() => {
                if (props.notification.data.resources && props.notification.data.resources.length) {
                    return computedResources.value[0].url;
                }

                return null;
            });

            const {markAsRead} = useMarkAsReadNotification();

            const router = useRouter();

            onMounted(async () => {
                await init();
                await getNursery();
            });

            function handleNotificationClick() {
                if (resourceUrl.value && computedResources.value.length < 2) {
                    if (!props.notification.read_at) {
                        markAsRead(props.notification);
                    }
                    router.push(resourceUrl.value).catch(() => {
                        return;
                    });
                }
            }

            const haveManyNurseries = computed(() => {
                if (props.user && props.user.nurseries) {
                    return props.user.nurseries.length > 1;
                }

                return false;
            });

            function updateNotification(readAt) {
                props.notification.read_at = readAt;
                emit('updateNotification', props.notification);
            }

            function deleteNotification() {
                emit('deleteNotification', props.notification);
            }

            function hideNotification() {
                emit('hideNotification', props.notification);
            }

            async function getNursery() {
                if (
                    props.user &&
                    props.notification &&
                    (props.notification.data.nursery_id || props.notification.data.nursery_ids)
                ) {
                    // Replace legacy id by new uuid if needed
                    if (
                        (props.notification.data.nursery_id && Lang.isNumeric(props.notification.data.nursery_id)) ||
                        props.notification.data.nursery_ids?.filter(id => Lang.isNumeric(id)).length
                    ) {
                        // Get organizations from legacy ids
                        const query = OrganizationModel.query();

                        if (props.notification.data.nursery_id) {
                            query.where('legacy_id', props.notification.data.nursery_id);
                        } else {
                            const numericIds = props.notification.data.nursery_ids.filter(id => Lang.isNumeric(id));
                            const uuidIds = props.notification.data.nursery_ids.filter(id => !Lang.isNumeric(id));
                            query.whereIn('legacy_id', numericIds).orWhereIn('id', uuidIds);
                        }

                        const organizations = await query.get();

                        // Replace legacy id by new uuid
                        if (props.notification.data.nursery_id) {
                            props.notification.data.nursery_id = organizations.first().getKey();
                        } else {
                            props.notification.data.nursery_ids = organizations.pluck('attributes.id').all();
                        }

                        // Add metric to track legacy id usage
                        addAction('M_Notification_Legacy_Id', {
                            nursery_id: props.notification.data.nursery_id,
                            nursery_ids: props.notification.data.nursery_ids,
                        });
                    }

                    // Get nursery depending on notification
                    nursery.value = props.user.nurseries.find(nursery =>
                        props.notification.data.nursery_id
                            ? nursery.id === props.notification.data.nursery_id
                            : props.notification.data.nursery_ids.includes(nursery.id)
                    );
                }
            }

            return {
                moment,
                nursery,
                resourceUrl,
                haveManyNurseries,
                handleNotificationClick,
                updateNotification,
                deleteNotification,
                hideNotification,
                getNursery,
            };
        },
    });
</script>

<style scoped>
    .item {
        border-bottom: 1px solid #d8dee4;

        &:first-of-type {
            border-top: 1px solid #d8dee4;
        }

        &:last-of-type {
            border-bottom: 0;
        }

        &.unread {
            background-color: rgba(233, 244, 255, 0.7);
        }
    }
</style>
