<script setup lang="ts">
    import mitt from 'mitt';
    import {computed} from 'vue';
    import {useRoute, useRouter} from 'vue-router';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import type {BusType} from '@/modules/document/components/organisms/DocumentList.vue';
    import DocumentList from '@/modules/document/components/organisms/DocumentList.vue';
    import type DocumentModel from '@/modules/document/models/DocumentModel';

    const bus = mitt<BusType>();
    const router = useRouter();
    const route = useRoute();

    const {can} = useAbility();

    function editItemFn(document: DocumentModel) {
        router?.push({name: 'documents.edit', params: {document: document.getKey()}});
    }

    const documentListeners = computed(() => {
        if (route.name === 'documents.create') {
            return {
                created: $event => bus.emit('documentCreated', $event),
            };
        } else {
            return {
                updated: $event => bus.emit('documentUpdated', $event),
                deleted: $event => bus.emit('documentDeleted', $event),
            };
        }
    });
</script>

<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="family">
                {{ __('app:pages.documents') }}
            </MPageTitle>
        </template>
        <template
            v-if="can('create', 'documents')"
            #actions
        >
            <RouterLink :to="{name: 'documents.create'}">
                <MButton
                    :icon-plus="true"
                    variant="primary"
                >
                    {{ __('common:add_document') }}
                </MButton>
            </RouterLink>
        </template>
        <template #content>
            <DocumentList
                :bus="bus"
                :edit-item-fn="editItemFn"
                permission-key="documents"
                show-organizations
                :show-visibilities="{
                    staffs_access: true,
                    parents_access: true,
                    web_access: true,
                }"
            />
            <router-view v-on="documentListeners" />
        </template>
    </SimplePageTemplate>
</template>
