<script setup lang="ts">
    import {type Collection, MqlOperation} from '@meekohq/lumos';
    import moment from 'moment';
    import {ref} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import type {LegacyOrganizationType} from '@/modules/organization/utils/LegacyOrganizationType';

    import SelectInvoices from './SelectInvoices.vue';

    defineProps<{
        nursery: LegacyOrganizationType;
        date: moment;
        modal: any;
    }>();

    const emit = defineEmits<{
        (e: 'updated', value: Collection): void;
    }>();

    const selectedInvoices = ref([]);
    const loading = ref(false);

    async function convert() {
        if (loading.value || !selectedInvoices.value.length) {
            return;
        }

        loading.value = true;

        try {
            const response = await new MqlOperation('cashier/lock_invoices', {
                invoice_ids: selectedInvoices.value.map(invoice => invoice.id),
            }).run();

            emit('updated', response.content.data);

            useNotification().success(
                __('billing_invoice:invoices_converted_successfully', {count: selectedInvoices.value.length})
            );
            loading.value = false;
        } catch (error) {
            useNotification().error(__('common:errors.generic'));
            loading.value = false;
            throw error;
        }
    }
</script>

<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('billing_invoice:lock_invoices') }}
            -
            <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
        </template>

        <SelectInvoices
            v-model:selected-invoices="selectedInvoices"
            action="convert"
            :date="date"
            :nursery="nursery"
        />

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                variant="primary"
                @click="convert"
            >
                {{ __('common:actions.lock') }}
                -
                <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
            </MButton>
        </template>
    </MModal>
</template>
