<script setup lang="ts">
    import {cloneDeep} from 'lodash-es';
    import type {PropType} from 'vue';
    import {ref} from 'vue';

    import useError from '@/modules/app/composables/useError';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import type {DocTemplatesDestination} from '@/modules/organization/utils/DocTemplatesDestinations';
    import DocTemplatesDestinations from '@/modules/organization/utils/DocTemplatesDestinations';
    import type {MeekoTemplate} from '@/modules/organization/utils/MeekoTemplates';

    const props = defineProps({
        modal: {
            type: Object as PropType<ModalType>,
            required: true,
        },
        selectedTemplate: {
            type: Object as PropType<MeekoTemplate>,
            required: true,
        },
    });

    const emit = defineEmits(['duplicated']);

    const errorHandler = useError();

    const docTemplatesDestinations = DocTemplatesDestinations();
    const docTemplateDestination = ref(
        DocTemplatesDestinations().find(
            destination => destination.type === props.selectedTemplate.type
        ) as DocTemplatesDestination
    );
    const docTemplateName = ref(`${props.selectedTemplate?.name} ${__('organization:copy_suffix')}`);

    function selectDocTemplateDestination(templateType: DocTemplatesDestination) {
        useMagicModal().confirmationModal({
            text: __('organization:doc_templates_destination_change_confirmation', {type: templateType.name}),
            onConfirm: async () => {
                docTemplateDestination.value = templateType;
            },
        });
    }

    function duplicateTemplate() {
        const selectedTemplateCopy = cloneDeep(props.selectedTemplate);

        errorHandler.reset();

        if (!docTemplateName.value) {
            errorHandler.addErrorCode('name', '0x2EAA809FB3');

            return;
        }

        selectedTemplateCopy.id = 0;
        selectedTemplateCopy.name = docTemplateName.value;
        selectedTemplateCopy.type = docTemplateDestination.value.type;
        emit('duplicated', selectedTemplateCopy);
    }
</script>

<template>
    <MModal
        :header-title="__('organization:duplicate_doc_template')"
        :modal="modal"
    >
        <MForm>
            <MInputGroup
                v-model="docTemplateName"
                class="DocTemplatesManagerModalEdit__form-group"
                :errors="errorHandler.get('name')"
                :label="__('common:name_colon')"
                :placeholder="__('organization:template_name')"
            />
            <MFieldset class="DocTemplatesManagerModalEdit__form-group">
                <MLabel>{{ __('organization:use_template_for_colon') }}</MLabel>
                <MMenu class="tw-w-full">
                    <MMenuButton class="tw-w-full">
                        <MButton
                            class="tw-w-full tw-justify-between"
                            icon-menu
                        >
                            <template v-if="docTemplateDestination">
                                <FontAwesomeIcon
                                    class="tw-mr-1 tw-text-primary-500"
                                    :fixed-width="true"
                                    :icon="docTemplateDestination.icon"
                                />
                                {{ docTemplateDestination.name }}
                            </template>
                            <template v-else>
                                {{ __('organization:select_from_list_dots') }}
                            </template>
                        </MButton>
                    </MMenuButton>
                    <MMenuItems>
                        <MMenuItem
                            v-for="destination in docTemplatesDestinations"
                            :key="destination.type"
                            :label="destination.name"
                            @click="selectDocTemplateDestination(destination)"
                        >
                            <template #icon>
                                <FontAwesomeIcon
                                    :fixed-width="true"
                                    :icon="destination.icon"
                                />
                            </template>
                        </MMenuItem>
                    </MMenuItems>
                </MMenu>
            </MFieldset>
        </MForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                variant="primary"
                @click="duplicateTemplate"
            />
        </template>
    </MModal>
</template>

<style scoped>
    .DocTemplatesManagerModalEdit__form-group {
        @apply tw-min-w-0;
    }
</style>
