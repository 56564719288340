<template>
    <CModal
        ref="modal"
        :header-title="__('common:actions.delete_of_all_organizations')"
        :shared="shared"
        @shown="onShown"
    >
        <p class="tw-mb-0">
            <span v-html="__('hr:delete_of_all_organizations_warning', {name: model.attributes.name})" />
        </p>
        <p class="tw-mt-2">
            {{ __('common:irreversible_action') }}
        </p>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
            <CTooltip v-if="!canRemoveModel">
                <MButton
                    class="tw-ml-2"
                    :disabled="!canRemoveModel"
                    variant="danger"
                >
                    {{ __('common:actions.delete') }}
                </MButton>
                <template #content>
                    {{ __('common:deleting_unable') }}
                </template>
            </CTooltip>
            <MButton
                v-else
                class="tw-ml-2"
                variant="danger"
                @click="removeEventType"
            >
                {{ __('common:actions.delete') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {isEmpty} from 'lodash-es';
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {},
        props: {
            model: {type: Object as PropType<EventTypeModel>, required: true},
            shared: {type: Boolean},
        },
        emits: ['deleted'],
        setup(props, {emit}) {
            const canRemoveModel = ref(false);
            const {modal, show, hide} = useLegacyModalBus();

            const onShown = async function () {
                const activeEventType = EventTypeModel.query().where('id', '=', props.model.id).whereHas('events');

                if (props.model.attributes.internal_id) {
                    canRemoveModel.value = false;
                } else {
                    canRemoveModel.value = isEmpty(activeEventType.all());
                }
            };

            const removeEventType = async function () {
                await props.model.delete();
                hide();
                emit('deleted', props.model);
                useNotification().success(__('hr:event_type_deleted_successfully'));
            };

            return {
                modal,
                show,
                hide,
                canRemoveModel,
                onShown,
                removeEventType,
            };
        },
    });
</script>
