<template>
    <div class="tw-block tw-w-full tw-overflow-x-auto">
        <table class="tw-mb-4 tw-w-full">
            <thead>
                <tr>
                    <th scope="col">
                        {{ __('common:date_format.human_to_time') }}
                    </th>
                    <th
                        v-for="(hour, key) in overruns.brackets.charged_from"
                        :key="'brackets' + key"
                        scope="col"
                        style="min-width: 110px"
                    >
                        <CInputGroup class="tw-w-full tw-font-normal">
                            <CInputAddon>
                                {{ key === overruns.brackets.charged_from.length - 1 ? '>' : '≤' }}
                            </CInputAddon>
                            <MInput
                                v-model.number="hour.value"
                                class="tw-min-w-12"
                                placeholder="15"
                            />
                            <CInputAddon>
                                {{ __('common:minimum_short') }}
                            </CInputAddon>
                        </CInputGroup>
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-text-right"
                        scope="col"
                    >
                        <MButton
                            class="tw-mr-1"
                            variant="success"
                            @click="addHour"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-plus-circle" />
                        </MButton>
                        <MButton
                            v-if="overruns.brackets.charged_from.length > 2"
                            variant="danger"
                            @click="subHour"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-times-circle" />
                        </MButton>
                    </th>
                </tr>
            </thead>
            <tbody>
                <th scope="row">
                    {{ __('billing_setting:bill') }}
                </th>
                <th
                    v-for="(hour, i) in overruns.brackets.charged_hours"
                    :key="'hour ' + i"
                    style="min-width: 110px"
                >
                    <CInputGroup class="tw-w-full tw-font-normal">
                        <MInput
                            v-model.number="hour.value"
                            v-float
                            class="tw-min-w-12"
                            placeholder="9"
                        />
                        <CInputAddon>
                            {{ __('common:hour_short') }}
                        </CInputAddon>
                    </CInputGroup>
                </th>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['overruns', 'nursery'],
        computed: {},
        mounted() {},
        methods: {
            addHour() {
                this.overruns.brackets.charged_from.push({value: null});
                this.overruns.brackets.charged_hours.push({value: 10});
            },
            subHour() {
                this.overruns.brackets.charged_from.splice(this.overruns.brackets.charged_from.length - 1, 1);
                this.overruns.brackets.charged_hours.splice(this.overruns.brackets.charged_hours.length - 1, 1);
            },
        },
    };
</script>

<style scoped>
    table th,
    table td {
        @apply tw-p-1;
        @apply tw-border-t tw-border-gray-200;
    }

    table thead th {
        @apply tw-border-b-2 tw-border-gray-200;
    }

    table tbody + tbody {
        @apply tw-border-t-2 tw-border-gray-200;
    }
</style>
