import {MqlTransaction} from '@meekohq/lumos';

import type CustomerModel from '@/modules/cashier/models/CustomerModel';
import FamilyCustomerPivot from '@/modules/family/models/FamilyCustomerPivot';
import type FamilyModel from '@/modules/family/models/FamilyModel';

export default class StoreCustomerFamilyAction {
    store(
        family: FamilyModel | undefined,
        customer: CustomerModel,
        mqlRunner: MqlTransaction = new MqlTransaction()
    ): Promise<FamilyCustomerPivot> {
        const pivot = new FamilyCustomerPivot();
        pivot.attributes.account_id = family?.attributes.account_id;
        pivot.attributes.family_id = family?.getKey();
        pivot.attributes.customer_id = customer?.getKey();

        // Save pivot through an operation of the mqlRunner transaction.
        return pivot.save({mqlRunner});
    }
}
