<template>
    <CModal
        ref="modal"
        :emitter="emitter"
        :header-title="__('billing_core:add_billing_address')"
        size="sm"
        visible
        @hidden="$emit('hidden')"
    >
        <AddressForm
            ref="addressForm"
            :address-model="addressModel"
            :company="isCompany"
            @loading="loading = $event"
            @saved="onAddressCreated"
        />
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="emitter.emit('hide')"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loading"
                variant="primary"
                @click="$refs.addressForm.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import AddressForm from '@/modules/cashier/components/core/address/organisms/AddressForm.vue';
    import AddressModel from '@/modules/cashier/models/AddressModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type {EventType} from '@/modules/meeko-ui/utils/ModalEventType';

    export default defineComponent({
        components: {
            AddressForm,
        },
        props: {
            customerModel: {type: Object as PropType<CustomerModel>, required: true},
            emitter: {type: Object as PropType<Emitter<EventType>>, default: () => mitt()},
        },
        emits: ['hidden', 'created'],
        setup(props, {emit}) {
            const loading = ref(false);
            const addressModel = ref(new AddressModel());
            const {activeOrganization} = useManager();

            const isCompany = computed(() => {
                if (props.customerModel) {
                    return props.customerModel.attributes.person === 'legal';
                }

                return false;
            });

            function onAddressCreated(addressModel: AddressModel) {
                props.emitter.emit('hide');

                emit('created', addressModel);
            }

            watch(
                () => props.customerModel.attributes.person,
                val => {
                    if (val === 'legal') {
                        addressModel.value.attributes.label = __('billing_customer:head_office');
                    } else {
                        addressModel.value.attributes.label = __('billing_customer:home_address');
                    }
                }
            );

            function preset() {
                if (props.customerModel) {
                    if (props.customerModel.attributes.person === 'natural') {
                        addressModel.value.attributes.label = __('billing_customer:home_address');
                    } else {
                        addressModel.value.attributes.label = __('billing_customer:head_office');
                    }
                    addressModel.value.attributes.name = props.customerModel.attributes.name;
                    addressModel.value.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                    addressModel.value.attributes.customer_id = props.customerModel.getKey();
                }
                addressModel.value.attributes.address.country_code =
                    activeOrganization.value?.attributes.address.country_code ?? 'FR';
                addressModel.value.attributes.type = 'billing';
            }

            preset();

            return {
                loading,
                addressModel,
                isCompany,
                onAddressCreated,
            };
        },
    });
</script>
