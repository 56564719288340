<template>
    <CButtonGroup>
        <MPopover2>
            <MPopover2ClickableReference>
                <MButton :icon-menu="true">
                    {{ __('request:filter_with_count', {value: countEnabledFilters}) }}
                </MButton>
            </MPopover2ClickableReference>
            <MPopover2Panel>
                <MForm class="tw-min-w-96">
                    <CFormTwoColumns>
                        <MFieldset v-if="hasManyOrganizations">
                            <MLabel>
                                {{ __('request:by_organization_colon') }}
                            </MLabel>
                            <OrganizationFinder
                                v-model="selectedOrganizations"
                                button-class="tw-w-full"
                                multi
                            />
                        </MFieldset>
                        <MFieldset>
                            <MLabel>
                                {{ __('request:by_child_group_other_colon') }}
                            </MLabel>
                            <KidGroupFinder
                                v-model="kidsGroup"
                                button-class="tw-w-full"
                                :inject-query="kidGroupQueryBuilder"
                                multi
                                :show-organization="true"
                            />
                        </MFieldset>
                    </CFormTwoColumns>
                    <MFieldset>
                        <MLabel>
                            {{ __('request:by_request_date_colon') }}
                        </MLabel>
                        <MDatePeriodFilter
                            ref="filter"
                            v-model="periodFilter"
                        />
                    </MFieldset>
                    <CFormTwoColumns>
                        <MFieldset>
                            <MLabel>
                                {{ __('request:by_request_type_colon') }}
                            </MLabel>
                            <RequestTypesFinder
                                v-model="requestTypes"
                                button-class="tw-w-full"
                                multi
                            />
                        </MFieldset>
                        <MFieldset>
                            <MLabel>
                                {{ __('request:by_person_concerned_colon') }}
                            </MLabel>
                            <StaffFinder
                                v-model="selectedStaffs"
                                only-user-organizations
                                button-class="tw-w-full"
                                multi
                            />
                        </MFieldset>
                    </CFormTwoColumns>
                    <MButton
                        class="tw-flex-1"
                        variant="primary"
                        @click="reset"
                    >
                        {{ __('common:actions.reset') }}
                    </MButton>
                </MForm>
            </MPopover2Panel>
        </MPopover2>
        <MButton
            class="tw-w-10"
            :variant="selectedStatus.contains(Status.rejected) ? 'primary' : 'light'"
            @click="toggleStatus(Status.rejected)"
        >
            <FontAwesomeIcon
                :class="{'tw-text-red-500': !selectedStatus.contains(Status.rejected)}"
                icon="fa-solid fa-ban"
            />
        </MButton>
        <MButton
            class="tw-w-10"
            :variant="selectedStatus.contains(Status.pending) ? 'primary' : 'light'"
            @click="toggleStatus(Status.pending)"
        >
            <FontAwesomeIcon
                :class="{'tw-text-yellow-600': !selectedStatus.contains(Status.pending)}"
                icon="fa-solid fa-hourglass-half"
            />
        </MButton>
        <MButton
            class="tw-w-10"
            :variant="selectedStatus.contains(Status.done) ? 'primary' : 'light'"
            @click="toggleStatus(Status.done)"
        >
            <FontAwesomeIcon
                :class="{'tw-text-green-500': !selectedStatus.contains(Status.done)}"
                icon="fa-solid fa-check"
            />
        </MButton>
    </CButtonGroup>
</template>

<script lang="ts">
    import {collect} from '@meekohq/lumos';
    import {computed, defineComponent, ref, watch} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import KidGroupFinder from '@/modules/organization/components/KidGroupFinder.vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import RequestTypesFinder from '@/modules/request/components/RequestTypesFinder.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';
    import {Status} from '@/modules/request/utils/Status';

    export default defineComponent({
        components: {MDatePeriodFilter, KidGroupFinder, RequestTypesFinder, StaffFinder, OrganizationFinder},
        props: {},
        emits: [
            'selected-type-status',
            'selected-staffs',
            'selected-organizations',
            'selected-periods',
            'selected-request-types',
            'selected-kids-groups',
        ],
        setup(props, {emit}) {
            const filter = ref();
            const selectedOrganizations = ref([]);
            const selectedStaffs = ref([]);
            const requestTypes = ref([]);
            const kidsGroup = ref([]);
            const typeStatus = ref([]);

            const periodFilter = ref({
                from: null,
                to: null,
            });

            const selectedStatus = ref(collect<string>([]));

            const toggleStatus = function (status) {
                if (selectedStatus.value.contains(status)) {
                    selectedStatus.value = collect<string>([]);
                } else {
                    selectedStatus.value = collect([status]);
                }

                emit('selected-type-status', selectedStatus.value.toArray());
            };

            const kidGroupQueryBuilder = computed(() => {
                const query = KidsGroupModel.query();

                if (selectedOrganizations.value.length) {
                    query.whereHas(new KidsGroupModel().organization(), query =>
                        query.whereIn('id', collect(selectedOrganizations.value).pluck('id').all() as string[])
                    );
                }

                return query;
            });

            watch(selectedStaffs, value => {
                emit('selected-staffs', value);
            });

            watch(selectedOrganizations, value => {
                // Filter only if there are selected organization
                if (value.length) {
                    // Filtering selected kids group who are only in selected organization
                    kidsGroup.value = kidsGroup.value.filter((group: KidsGroupModel) => {
                        return value.find(
                            (organization: OrganizationModel) => organization.id === group.attributes.organization_id
                        );
                    });
                }
                emit('selected-organizations', value);
            });

            watch(
                periodFilter,
                value => {
                    emit('selected-periods', value);
                },
                {deep: true}
            );

            watch(requestTypes, value => {
                emit('selected-request-types', value);
            });

            watch(kidsGroup, value => {
                emit('selected-kids-groups', value);
            });

            const reset = function () {
                selectedOrganizations.value = [];
                selectedStaffs.value = [];
                requestTypes.value = [];
                kidsGroup.value = [];
                periodFilter.value = {from: undefined, to: undefined};
            };

            const countEnabledFilters = computed(() => {
                let count = 1;

                if (selectedStaffs.value.length) {
                    count++;
                }

                if (requestTypes.value.length) {
                    count++;
                }

                if (selectedStatus.value.toArray().length) {
                    count++;
                }

                if (kidsGroup.value.length) {
                    count++;
                }

                return count;
            });

            const {hasManyOrganizations} = useManager();

            return {
                filter,
                selectedOrganizations,
                periodFilter,
                selectedStaffs,
                requestTypes,
                typeStatus,
                kidsGroup,
                reset,
                countEnabledFilters,
                hasManyOrganizations,
                toggleStatus,
                selectedStatus,
                Status,
                kidGroupQueryBuilder,
            };
        },
    });
</script>
