import {computed} from 'vue';
import type {RouteLocationNormalized, RouteRecordNameGeneric, RouteRecordRaw} from 'vue-router';

export type AccessCallbackType = (inherited: {value: boolean; route?: RouteRecordRaw}) => boolean;
interface InheritedAccessType {
    value: boolean;
    route: RouteRecordRaw;
}

export default function useAccessRoute(router: any) {
    const enableDebug = false;

    const defaultUnauthorizedRouteName = 'unauthorized';

    async function authorize(route: RouteLocationNormalized) {
        if (!checkAccessToRouteName(route.name)) {
            try {
                await router.push({name: firstRouteNameWithGrantedAccess.value, params: route.params});
            } catch {
                // If the navigation was aborted by a navigation guard, we don't want to throw the error
            }
        }
    }

    function checkAccessToRouteName(name?: RouteRecordNameGeneric | null) {
        // If no name is provided, we can't check the access so we assume it's granted
        if (!name) {
            return true;
        }

        const matchingRoute = searchForRouteName(router.options.routes, name);

        // If no route is found, we assume it's granted
        if (!matchingRoute) {
            return true;
        }

        const {route, inheritedAccess} = matchingRoute;

        // Firstly we check if the route is protected by an access
        if (route.meta?.access) {
            const value = route.meta?.access({value: inheritedAccess?.value ?? true, route: inheritedAccess?.route});

            debug(`ROUTE [${matchingRoute.route.name}] -> ACCESS -> ${value}`);

            return value;
        }

        // Then we use the herited access if there is one
        if (inheritedAccess) {
            debug(
                `ROUTE [${matchingRoute.route.name}] -> INHERIT ACCESS FROM [${inheritedAccess.route.name ?? inheritedAccess.route.path}] -> ${inheritedAccess.value}`
            );

            return inheritedAccess.value;
        }

        debug(`ROUTE [${matchingRoute.route.name}] -> UNDEFINED ACCESS -> true`);

        // If there is no access available, we assume it's granted
        return true;
    }

    function searchForRouteName(
        routes: RouteRecordRaw[],
        name: RouteRecordNameGeneric,
        inheritedAccess?: InheritedAccessType
    ):
        | {
              route: RouteRecordRaw;
              inheritedAccess?: InheritedAccessType;
          }
        | undefined {
        for (const route of routes) {
            if (route.children) {
                let child: {route: RouteRecordRaw; inheritedAccess?: InheritedAccessType} | undefined;

                if (route.meta?.access) {
                    // New access available, it become the new herited access
                    const inherited = route.meta.access({
                        value: inheritedAccess?.value ?? true,
                        route: inheritedAccess?.route,
                    });

                    child = searchForRouteName(route.children, name, {value: inherited, route});
                } else {
                    child = searchForRouteName(route.children, name, inheritedAccess);
                }

                if (child) {
                    return child;
                }
            }

            if (route.name === name) {
                return {route, inheritedAccess};
            }
        }

        return undefined;
    }

    function scanRoutes(routes: RouteRecordRaw[]): RouteRecordRaw | undefined {
        for (const route of routes) {
            if (route.children) {
                const child = scanRoutes(route.children);

                if (child) {
                    return child;
                }
            }

            if (
                route.name !== defaultUnauthorizedRouteName &&
                route.meta?.access &&
                route.meta?.access({value: true})
            ) {
                return route;
            }
        }

        return undefined;
    }

    const firstRouteNameWithGrantedAccess = computed(() => {
        return scanRoutes(router.options.routes ?? [])?.name ?? defaultUnauthorizedRouteName;
    });

    function debug(...args: any[]) {
        if (enableDebug) {
            console.log('[useAccessRoute]', ...args);
        }
    }

    return {
        authorize,
        checkAccessToRouteName,
        firstRouteNameWithGrantedAccess,
    };
}
