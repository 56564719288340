<template>
    <div class="SimulationList">
        <CVStack
            class="SimulationList__container"
            gap="4"
        >
            <div class="SimulationList__header">
                <CText
                    class="tw-mb-2 xl:tw-mb-0"
                    font-weight="semibold"
                    uppercase
                >
                    {{ __('planning:application_selected') }}
                </CText>
                <div>
                    <MButton
                        :disabled="!can('update', 'registrations')"
                        variant="primary"
                        @click="isAllRegistrationsPaneOpened = !isAllRegistrationsPaneOpened"
                    >
                        {{ __('planning:add_application') }}
                    </MButton>
                </div>
            </div>
            <SimulationListResources
                :registrations="simulationList.registrations().value()"
                @detach="removeRegistration($event)"
            />
        </CVStack>
        <div
            v-if="isAllRegistrationsPaneOpened"
            class="SimulationList__registration-pane"
        >
            <div class="SimulationList__registration-pane-container">
                <div class="SimulationList__registration-pane-header">
                    <CText font-weight="semibold">
                        {{ __('planning:add_application') }}
                    </CText>
                    <button
                        class="SimulationList__registration-pane-close-button"
                        type="button"
                        variant="transculcent"
                        @click="isAllRegistrationsPaneOpened = false"
                    >
                        <FontAwesomeIcon icon="fa-regular fa-times" />
                    </button>
                </div>
                <SimulationListResources
                    attach-to-simulation
                    :loading="loading"
                    :registrations="resourcesNotRelatedToSimulationList"
                    @attach="addRegistration($event)"
                >
                    <template #filters>
                        <RegistrationFilters
                            storage-key-prefix="simulation-registrations"
                            @updated="filtersChanged"
                        />
                    </template>
                </SimulationListResources>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {collect} from '@meekohq/lumos';
    import _debounce from 'lodash-es/debounce';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import SimulationListResources from '@/modules/planning/components/simulation/List/SimulationListResources.vue';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';
    import SimulationListPivot from '@/modules/planning/models/SimulationListPivot';
    import RegistrationFilters from '@/modules/registration/components/organisms/RegistrationFilters.vue';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export default defineComponent({
        components: {
            SimulationListResources,
            RegistrationFilters,
        },
        props: {
            simulationList: {
                type: Object as PropType<SimulationListModel>,
                required: true,
            },
        },
        setup(props) {
            const resources = ref(collect<RegistrationModel>());

            const simulationListRegistrationsIds = computed(() => {
                return props.simulationList.registrations().value().pluck('attributes.id');
            });

            const resourcesNotRelatedToSimulationList = computed(() => {
                return resources.value.reject(resource => {
                    return simulationListRegistrationsIds.value.contains(resource.getKey());
                });
            });

            const {can} = useAbility();

            const isAllRegistrationsPaneOpened = ref(false);

            const loading = ref(false);

            const filtersQuery = ref(RegistrationModel.query());

            function filtersChanged(query) {
                loading.value = true;
                filtersQuery.value = query;
                getResources();
            }

            const getResources = _debounce(async () => {
                resources.value = await filtersQuery.value
                    .clone()
                    .whereNotIn('id', simulationListRegistrationsIds.value.toArray())
                    .with(new RegistrationModel().plannings())
                    .with(new RegistrationModel().tags())
                    .get();
                loading.value = false;
            }, 1000);

            /**
             * Create a new SimulationListPivot and preprend the registration into the registration's
             * relationships of the simulationList prop
             *
             * @param registrationWithCallback
             */
            function addRegistration(registrationWithCallback: {
                registration: RegistrationModel;
                stopLoading: CallableFunction;
            }) {
                const pivot = new SimulationListPivot();

                pivot.attributes.account_id = props.simulationList.attributes.account_id;
                pivot.attributes.list_id = props.simulationList.getKey();
                pivot.attributes.resource_id = registrationWithCallback.registration.getKey();
                pivot.attributes.resource_type = new RegistrationModel().type;

                pivot.save().then(pivot => {
                    registrationWithCallback.registration.relationships.pivot = pivot;
                    props.simulationList
                        .registrations()
                        .mutate(registrations => registrations.prepend(registrationWithCallback.registration));

                    registrationWithCallback.stopLoading();
                });
            }

            /**
             * Retrieve the related registration in the simulationList relationship and delete it.
             * Filter the registration's relationships to remove the detached registration.
             *
             * @param registrationWithCallback
             */
            function removeRegistration(registrationWithCallback: {
                registration: RegistrationModel;
                stopLoading: CallableFunction;
            }) {
                const registrationToDetach = props.simulationList
                    .registrations()
                    .value()
                    .first(
                        associatedRegistration =>
                            associatedRegistration.getKey() === registrationWithCallback.registration.getKey()
                    );

                registrationToDetach?.relationships.pivot.delete().then(() => {
                    props.simulationList?.registrations().mutate(registrations => {
                        return registrations.reject(registrationToCheck => {
                            return registrationToCheck.getKey() === registrationWithCallback.registration.getKey();
                        });
                    });

                    // Prepend the detached registration in the resource's relationships if the resource was not present when fetched
                    if (
                        !resources.value.contains(
                            resource => resource.getKey() === registrationWithCallback.registration.getKey()
                        )
                    ) {
                        resources.value.prepend(registrationWithCallback.registration);
                    }

                    registrationWithCallback.stopLoading();
                });
            }

            watch(
                () => props.simulationList,
                () => {
                    getResources();
                }
            );

            return {
                isAllRegistrationsPaneOpened,
                can,
                loading,
                resources,
                simulationListRegistrationsIds,
                resourcesNotRelatedToSimulationList,
                addRegistration,
                removeRegistration,
                getResources,
                filtersChanged,
            };
        },
    });
</script>

<style scoped>
    .SimulationList {
        @apply tw-relative tw-z-20;
    }

    .SimulationList__container {
        @apply tw-rounded-lg tw-border tw-border-blue-200 tw-bg-blue-100 tw-p-4 tw-shadow-sm;
    }

    .SimulationList__header {
        @apply tw-flex tw-flex-col tw-items-center tw-justify-items-center xl:tw-flex-row xl:tw-justify-between;
    }

    .SimulationList__registration-pane {
        @apply lg:tw-absolute lg:tw-left-full lg:tw-top-0 lg:tw-z-10;
        @apply tw-mt-2 lg:tw-ml-2 lg:tw-mt-4;
        @apply tw-rounded-lg tw-bg-white tw-shadow-md tw-ring-1 tw-ring-black/20;
    }

    .SimulationList__registration-pane-container {
        @apply tw-flex tw-flex-col tw-gap-8 tw-p-4;
        @apply tw-overflow-auto;
        @apply tw-max-h-[40rem] xl:tw-max-h-[50rem];
    }

    .SimulationList__registration-pane-header {
        @apply tw-flex tw-w-full tw-items-center tw-justify-between;
    }

    .SimulationList__registration-pane-close-button {
        @apply tw-h-7 tw-w-7;
        @apply tw-rounded-lg tw-text-xl tw-text-gray-300 hover:tw-bg-gray-200 hover:tw-text-gray-500;
        @apply tw-transition-all tw-duration-100;
    }
</style>
