import type ISplittable from '@/modules/legacy/libs/periodSplitter/ISplittable';

export default class OverrunEvent implements ISplittable {
    public startedAt: number;
    public endedAt: number;
    public sources: any[];
    public position: 'before' | 'after' | undefined = undefined;

    public constructor(startedAt: number, endedAt: number, sources: any[] = []) {
        this.startedAt = startedAt;
        this.endedAt = endedAt;
        this.sources = sources;
    }
}
