<template>
    <MPopover ref="selectIconPopover">
        <MButton
            :disabled="disabled"
            icon-sort
        >
            <CIcon
                v-if="iconModel"
                :path="iconModel.attributes.path"
                :provider="iconModel.attributes.provider"
            />
            <template v-else>
                {{ __('components:without_icone') }}
            </template>
        </MButton>
        <template #content>
            <div class="tw-max-h-80 tw-max-w-xs tw-overflow-y-scroll tw-pr-2">
                <div
                    class="tw-flex tw-cursor-pointer tw-items-center tw-text-base"
                    @click="changeIcon(null)"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2 tw-text-2xl"
                        icon="fa-solid fa-square"
                    />
                    <span class="tw-text-gray-800">{{ __('components:without_icone') }}</span>
                </div>
                <hr class="hr tw-mb-2 tw-mt-3" />
                <div
                    v-for="(category, index) in categories"
                    :key="'cat' + index"
                >
                    <div class="tw-mb-2 tw-mt-4 tw-text-gray-500">
                        <template v-if="index === 'people'">
                            {{ __('common:people') }}
                        </template>
                        <template v-else-if="index === 'communication'">
                            {{ __('common:communication') }}
                        </template>
                        <template v-else-if="index === 'files'">
                            {{ __('common:files') }}
                        </template>
                        <template v-else-if="index === 'objects'">
                            {{ __('common:objects') }}
                        </template>
                        <template v-else-if="index === 'others'">
                            {{ __('common:other_other') }}
                        </template>
                    </div>
                    <div class="tw-grid tw-grid-flow-row tw-grid-cols-12 tw-gap-4">
                        <div
                            v-for="(icon, i) in category"
                            :key="'cat' + index + 'icon' + i"
                            class="tw-col-span-2 tw-cursor-pointer tw-text-center tw-text-2xl"
                            @click="changeIcon(icon)"
                        >
                            <CIcon
                                :path="icon.attributes.path"
                                :provider="icon.attributes.provider"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </MPopover>
</template>

<script>
    import {groupBy} from 'lodash-es';

    import DataStore from '@/modules/legacy/store/data.store';

    export default {
        props: {
            iconId: {
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['updateIcon'],

        data() {
            return {
                icons: null,
                categories: [],
                iconModel: '',
            };
        },

        watch: {
            iconId(value) {
                if (value) {
                    this.iconModel = DataStore.iconPath(value);
                }
            },
        },

        mounted() {
            this.icons = DataStore.store.data.icons;
            this.categories = groupBy(this.icons, icon => icon.attributes.category);

            if (this.iconId) {
                this.iconModel = DataStore.iconPath(this.iconId);
            }
        },

        methods: {
            changeIcon(icon) {
                this.iconModel = icon;
                this.$refs.selectIconPopover.closePopover();
                this.$emit('updateIcon', icon ? icon.id : null);
            },
        },
    };
</script>
