<template>
    <MForm>
        <MFieldset>
            <MLabel>{{ __('common:comment') }}</MLabel>
            <MTextarea
                v-model="note.attributes.description"
                autofocus
            />
            <CFormErrorMessageList
                v-if="noteErrors.hasError('description')"
                :errors="noteErrors.getErrors('description')"
            />
        </MFieldset>
        <MFieldset>
            <MLabel>{{ __('activity:reporter') }}</MLabel>
            <StaffFinder
                button-class="tw-w-full"
                :registration="registration"
                :model-value="note.reporter().value()"
                @update:model-value="note.reporter().associate($event)"
            />
            <CFormErrorMessageList
                v-if="noteErrors.hasError('reporter_id')"
                :errors="noteErrors.getErrors('reporter_id')"
            />
        </MFieldset>
        <CHStack
            distribute="end"
            gap="2"
        >
            <MButton
                :label="__('common:actions.cancel')"
                @click="undo"
            />
            <MButton
                :disabled="disableSaveButton"
                :label="__('common:actions.save')"
                :loading="loading"
                variant="primary"
                @click="save"
            />
        </CHStack>
    </MForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, onMounted, ref} from 'vue';

    import TicketModel from '@/modules/activity/ticket/domain/TicketModel';
    import useAuth from '@/modules/app/composables/useAuth';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export interface Events {
        save: undefined;
    }

    export default defineComponent({
        components: {StaffFinder},
        props: {
            note: {
                type: Object as PropType<TicketModel>,
                required: true,
            },
            registration: {
                type: RegistrationModel,
                default: undefined,
            },
            disableSaveButton: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['saved', 'undo'],
        setup(props, {emit}) {
            const noteErrors = ref(new ErrorHandler());
            const loading = ref(false);
            const {user} = useAuth();

            onMounted(() => {
                const isNoteHasntReporter = !props.note.exist && !props.note.reporter().value();

                if (isNoteHasntReporter && user.value.attributes.email) {
                    associateReporter(user.value.attributes.email);
                }
            });

            async function associateReporter(email: string) {
                const staff = await StaffModel.query().where('email', email).first();
                if (staff) {
                    props.note.reporter().associate(staff);
                }
            }

            async function save() {
                loading.value = true;

                try {
                    await props.note.save();
                    noteErrors.value.reset();

                    emit('saved', props.note);
                } catch (e) {
                    noteErrors.value.reset(e);
                } finally {
                    loading.value = false;
                }
            }

            const undo = function () {
                emit('undo');
            };

            return {
                loading,
                noteErrors,
                save,
                undo,
            };
        },
    });
</script>
