import {replace} from 'lodash-es';

export function clickableUrl(value: string, shortenUrl = true): string {
    let output = value;

    const regex = new RegExp('(https?:\\/\\/\\S+)', 'gmi');

    let m;
    while ((m = regex.exec(value)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
            if (groupIndex === 0) {
                let url = match;

                // Shorten url
                if (shortenUrl && match.length > 35) {
                    url = `${match.substring(0, 20)}[...]${match.slice(-10)}`;
                }

                // Make url clickable
                url = `<a href="${match}" class="tw-text-primary-500" target="_blank" onclick="event.stopPropagation();">${url}</a>`;

                output = replace(output, match, url);
            }
        });
    }

    return output;
}
