<template>
    <MModal
        :header-title="__('registration:add_registration')"
        :modal="modal"
        size="6xl"
    >
        <MStepPanel
            ref="stepper"
            @has-next-step="hasNextStep = $event"
            @has-previous-step="hasPreviousStep = $event"
        >
            <MStepPanelHeader>
                <MStepPanelHeaderItem
                    :is-completed="isStepOneCompleted"
                    :step="1"
                    :title="__('common:the_kid')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-child-reaching" />
                    </template>
                </MStepPanelHeaderItem>
                <MStepPanelHeaderItem
                    :is-completed="isStepTwoCompleted"
                    :step="2"
                    :title="__('common:the_contract_one')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-file" />
                    </template>
                </MStepPanelHeaderItem>
                <MStepPanelHeaderItem
                    :is-completed="isStepThreeCompleted"
                    :step="3"
                    :title="__('common:the_parents')"
                >
                    <template #icon>
                        <FontAwesomeIcon icon="fa-solid fa-users" />
                    </template>
                </MStepPanelHeaderItem>
            </MStepPanelHeader>
            <MStepPanelContent v-slot="{activeStep}">
                <MForm v-if="activeStep === 1">
                    <CFormThreeColumns>
                        <MFieldset>
                            <MLabel>{{ __('common:first_name') }}</MLabel>
                            <MInput
                                id="kid_first_name"
                                v-model="registration.kid_first_name"
                                :placeholder="__('common:first_name')"
                            />
                        </MFieldset>
                        <MFieldset>
                            <MLabel>{{ __('common:last_name') }}*</MLabel>
                            <MInput
                                id="kid_last_name"
                                v-model="registration.kid_last_name"
                                :placeholder="__('common:last_name')"
                            />
                        </MFieldset>
                        <MFieldset>
                            <MLabel>
                                <template #icon>
                                    <FontAwesomeIcon icon="fa-solid fa-venus-mars" />
                                </template>
                                {{ __('common:gender_one') }}
                            </MLabel>
                            <MSelectMenu
                                v-model="registration.kid_gender"
                                :label="__('common:gender_one')"
                                :options="[
                                    {value: 'male', text: __('common:gender.boy')},
                                    {value: 'female', text: __('common:gender.girl')},
                                    {value: '', text: __('common:gender.unknow')},
                                ]"
                            />
                        </MFieldset>
                    </CFormThreeColumns>
                    <CFormTwoColumns>
                        <MFieldset>
                            <MLabel>
                                <template #icon>
                                    <FontAwesomeIcon icon="fa-solid fa-birthday-cake" />
                                </template>
                                {{ __('common:date_of_birth_or_estimate') }}*
                            </MLabel>
                            <MDatePicker
                                v-model="registration.kid_birthdate"
                                format="yyyy-MM-dd"
                            />
                        </MFieldset>
                        <MFieldset>
                            <MLabel>
                                <template #icon>
                                    <FontAwesomeIcon icon="fa-solid fa-map-marker" />
                                </template>
                                {{ __('common:birth_place') }}
                            </MLabel>
                            <MInput
                                id="kid_birthplace"
                                v-model="registration.kid_birthplace"
                                :placeholder="__('common:birth_place')"
                            />
                        </MFieldset>
                    </CFormTwoColumns>
                </MForm>

                <div v-else-if="activeStep === 2">
                    <MForm>
                        <CFormThreeColumns>
                            <MFieldset>
                                <MLabel>{{ __('common:start_contract') }}*</MLabel>
                                <MDatePicker
                                    v-model="registration.contract_started_at"
                                    format="yyyy-MM-dd"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>{{ __('common:end_contract') }}*</MLabel>
                                <MDatePicker
                                    v-model="registration.contract_ended_at"
                                    format="yyyy-MM-dd"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>
                                    {{ __('registration:flexible_planning') }}
                                    <MHelpTooltip class="tw-ml-2">
                                        {{ __('registration:can_the_planning_be_adjusted') }}
                                    </MHelpTooltip>
                                </MLabel>
                                <MSelectMenu
                                    v-model="registration.negotiable"
                                    :options="[
                                        {value: 1, text: __('common:yes')},
                                        {value: 0, text: __('common:no')},
                                    ]"
                                />
                            </MFieldset>
                        </CFormThreeColumns>
                    </MForm>
                    <div class="tw-mt-8 tw-text-center">
                        <MHeading>
                            {{ __('registration:desired_planning') }}
                        </MHeading>
                        <div class="tw-mt-1 tw-text-sm tw-text-gray-500">
                            {{ __('registration:to_modify_a_range_click_on_it') }}
                        </div>
                    </div>
                    <ModulePlanning
                        v-if="registration"
                        :nursery="nursery"
                        :registration="registration"
                    />
                </div>

                <div v-else-if="activeStep === 3">
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-gap-y-8 lg:tw-grid-cols-2">
                        <div class="tw-flex tw-flex-col tw-gap-4">
                            <MHeading level="h3">
                                {{ __('common:first_parent') }}
                            </MHeading>
                            <MSelectMenu
                                v-model="registration.first_parent_role"
                                :options="ParentRoleOptions"
                            />
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-full">
                                    <MLabel>{{ __('common:first_name') }}*</MLabel>
                                    <MInput
                                        id="first_parent_first_name"
                                        v-model="registration.first_parent_first_name"
                                        :placeholder="__('common:first_name')"
                                    />
                                </MFieldset>
                                <MFieldset class="tw-basis-full">
                                    <MLabel>{{ __('common:last_name') }}*</MLabel>
                                    <MInput
                                        id="first_parent_last_name"
                                        v-model="registration.first_parent_last_name"
                                        :placeholder="__('common:last_name')"
                                    />
                                </MFieldset>
                            </div>
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-full">
                                    <MLabel>{{ __('common:email') }}*</MLabel>
                                    <MInput
                                        id="first_parent_email"
                                        v-model="registration.first_parent_email"
                                        :placeholder="__('common:email')"
                                    />
                                </MFieldset>
                                <MFieldset
                                    v-if="showCAF()"
                                    class="tw-basis-full"
                                >
                                    <MLabel>
                                        {{ __('family_member:beneficiary_number_short') }}
                                    </MLabel>
                                    <MInput
                                        id="first_parent_alloc_no"
                                        v-model="registration.first_parent_alloc_no"
                                        :placeholder="__('family_member:beneficiary_number_short')"
                                    />
                                </MFieldset>
                            </div>
                            <hr />
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:address.address') }}
                                </MLabel>
                                <MInput
                                    id="first_parent_address"
                                    v-model="registration.first_parent_address"
                                    :placeholder="__('common:address.address')"
                                />
                            </MFieldset>
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-1/3">
                                    <MLabel>
                                        {{ __('common:address.zip_code') }}
                                    </MLabel>
                                    <MInput
                                        id="first_parent_zipcode"
                                        v-model="registration.first_parent_zipcode"
                                        :placeholder="__('common:address.zip_code')"
                                    />
                                </MFieldset>
                                <MFieldset class="tw-basis-2/3">
                                    <MLabel>
                                        {{ __('common:address.city') }}
                                    </MLabel>
                                    <MInput
                                        id="first_parent_city"
                                        v-model="registration.first_parent_city"
                                        :placeholder="__('common:address.city')"
                                    />
                                </MFieldset>
                            </div>
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-full">
                                    <MLabel>{{ __('common:address.country') }}</MLabel>
                                    <SelectCountry v-model="registration.first_parent_country" />
                                </MFieldset>
                            </div>
                            <hr />
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-full">
                                    <MLabel>
                                        {{ __('common:phone.landline') }}
                                    </MLabel>
                                    <MInput
                                        id="first_parent_phone"
                                        v-model="registration.first_parent_phone"
                                        :placeholder="__('common:phone.landline')"
                                    />
                                </MFieldset>
                                <MFieldset class="tw-basis-full">
                                    <MLabel>
                                        {{ __('common:phone.cellular') }}
                                    </MLabel>
                                    <MInput
                                        id="first_parent_mobile_phone"
                                        v-model="registration.first_parent_mobile_phone"
                                        :placeholder="__('common:phone.cellular')"
                                    />
                                </MFieldset>
                            </div>
                            <hr />
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:annual_income_for_the_year', {year: salaryDate}) }}
                                </MLabel>
                                <MInput
                                    id="first_parent_salary"
                                    v-model.number="registration.first_parent_salary"
                                    :placeholder="__('common:annual_income')"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:job') }}
                                </MLabel>
                                <MInput
                                    id="first_parent_job"
                                    v-model="registration.first_parent_job"
                                    :placeholder="__('common:job')"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:company_name') }}
                                </MLabel>
                                <MInput
                                    id="first_parent_company_name"
                                    v-model="registration.first_parent_company_name"
                                    :placeholder="__('common:name')"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:company_contract') }}
                                    <MHelpTooltip class="tw-ml-2">
                                        {{ __('registration:company_can_finance_nursery_place') }}
                                    </MHelpTooltip>
                                </MLabel>
                                <MSelectMenu
                                    v-model="registration.first_parent_company_contract"
                                    :options="[
                                        {value: 1, text: __('common:yes')},
                                        {value: 0, text: __('common:no')},
                                    ]"
                                />
                            </MFieldset>
                            <template v-if="registration.first_parent_company_contract">
                                <MFieldset>
                                    <MLabel>
                                        {{ __('common:company_address') }}
                                    </MLabel>
                                    <MInput
                                        id="first_parent_company_address"
                                        v-model="registration.first_parent_company_address"
                                        :placeholder="__('common:address.address')"
                                    />
                                </MFieldset>
                                <div class="tw-flex tw-gap-4">
                                    <MFieldset class="tw-basis-1/3">
                                        <MLabel>
                                            {{ __('common:address.zip_code') }}
                                        </MLabel>
                                        <MInput
                                            id="first_parent_company_zipcode"
                                            v-model="registration.first_parent_company_zipcode"
                                            :placeholder="__('common:address.zip_code')"
                                        />
                                    </MFieldset>
                                    <MFieldset class="tw-basis-2/3">
                                        <MLabel>
                                            {{ __('common:address.city') }}
                                        </MLabel>
                                        <MInput
                                            id="first_parent_company_city"
                                            v-model="registration.first_parent_company_city"
                                            :placeholder="__('common:address.city')"
                                        />
                                    </MFieldset>
                                </div>
                                <MFieldset>
                                    <MLabel>{{ __('common:address.country') }}</MLabel>
                                    <SelectCountry v-model="registration.first_parent_company_country" />
                                </MFieldset>
                                <div class="tw-flex tw-gap-4">
                                    <MFieldset class="tw-basis-full">
                                        <MLabel>
                                            {{ __('common:phone.landline') }}
                                        </MLabel>
                                        <MInput
                                            id="first_parent_company_phone"
                                            v-model="registration.first_parent_company_phone"
                                            :placeholder="__('common:phone.landline')"
                                        />
                                    </MFieldset>
                                    <MFieldset class="tw-basis-full">
                                        <MLabel>
                                            {{ __('common:email') }}
                                        </MLabel>
                                        <MInput
                                            id="first_parent_company_email"
                                            v-model="registration.first_parent_company_email"
                                            :placeholder="__('common:email')"
                                        />
                                    </MFieldset>
                                </div>
                            </template>
                        </div>
                        <div class="tw-flex tw-flex-col tw-gap-4">
                            <MHeading level="h3">
                                {{ __('common:second_parent') }}
                            </MHeading>
                            <MSelectMenu
                                v-model="registration.second_parent_role"
                                :options="ParentRoleOptions"
                            />
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-full">
                                    <MLabel>
                                        {{ __('common:first_name') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_first_name"
                                        v-model="registration.second_parent_first_name"
                                        :placeholder="__('common:first_name')"
                                    />
                                </MFieldset>
                                <MFieldset class="tw-basis-full">
                                    <MLabel>
                                        {{ __('common:last_name') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_last_name"
                                        v-model="registration.second_parent_last_name"
                                        :placeholder="__('common:last_name')"
                                    />
                                </MFieldset>
                            </div>
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-full">
                                    <MLabel>
                                        {{ __('common:email') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_email"
                                        v-model="registration.second_parent_email"
                                        :placeholder="__('common:email')"
                                    />
                                </MFieldset>
                                <MFieldset
                                    v-if="showCAF()"
                                    class="tw-basis-full"
                                >
                                    <MLabel>
                                        {{ __('family_member:beneficiary_number_short') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_alloc_no"
                                        v-model="registration.second_parent_alloc_no"
                                        :placeholder="__('family_member:beneficiary_number_short')"
                                    />
                                </MFieldset>
                            </div>
                            <hr />
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:address.address') }} -
                                    <MButton
                                        class="tw-ml-0.5 tw-p-0"
                                        variant="link"
                                        @click="copyAddress"
                                    >
                                        {{ __('registration:copy_first_parent_address') }}
                                    </MButton>
                                </MLabel>
                                <MInput
                                    id="second_parent_address"
                                    v-model="registration.second_parent_address"
                                    :placeholder="__('common:address.address')"
                                />
                            </MFieldset>
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-1/3">
                                    <MLabel>
                                        {{ __('common:address.zip_code') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_zipcode"
                                        v-model="registration.second_parent_zipcode"
                                        :placeholder="__('common:address.zip_code')"
                                    />
                                </MFieldset>
                                <MFieldset class="tw-basis-2/3">
                                    <MLabel>
                                        {{ __('common:address.city') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_city"
                                        v-model="registration.second_parent_city"
                                        :placeholder="__('common:address.city')"
                                    />
                                </MFieldset>
                            </div>
                            <MFieldset>
                                <MLabel>{{ __('common:address.country') }}</MLabel>
                                <SelectCountry v-model="registration.second_parent_country" />
                            </MFieldset>
                            <hr />
                            <div class="tw-flex tw-gap-4">
                                <MFieldset class="tw-basis-full">
                                    <MLabel>
                                        {{ __('common:phone.landline') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_phone"
                                        v-model="registration.second_parent_phone"
                                        :placeholder="__('common:phone.landline')"
                                    />
                                </MFieldset>
                                <MFieldset class="tw-basis-full">
                                    <MLabel>
                                        {{ __('common:phone.cellular') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_mobile_phone"
                                        v-model="registration.second_parent_mobile_phone"
                                        :placeholder="__('common:phone.cellular')"
                                    />
                                </MFieldset>
                            </div>
                            <hr />
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:annual_income_for_the_year', {year: salaryDate}) }}
                                </MLabel>
                                <MInput
                                    id="second_parent_salary"
                                    v-model.number="registration.second_parent_salary"
                                    :placeholder="__('common:annual_income')"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:job') }}
                                </MLabel>
                                <MInput
                                    id="second_parent_job"
                                    v-model="registration.second_parent_job"
                                    :placeholder="__('common:job')"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:company_name') }}
                                </MLabel>
                                <MInput
                                    id="second_parent_company_name"
                                    v-model="registration.second_parent_company_name"
                                    :placeholder="__('common:name')"
                                />
                            </MFieldset>
                            <MFieldset>
                                <MLabel>
                                    {{ __('common:company_contract') }}
                                    <MHelpTooltip class="tw-ml-2">
                                        {{ __('registration:company_can_finance_nursery_place') }}
                                    </MHelpTooltip>
                                </MLabel>
                                <MSelectMenu
                                    v-model="registration.second_parent_company_contract"
                                    :options="[
                                        {value: 1, text: __('common:yes')},
                                        {value: 0, text: __('common:no')},
                                    ]"
                                />
                            </MFieldset>
                            <template v-if="registration.second_parent_company_contract">
                                <MFieldset>
                                    <MLabel>
                                        {{ __('common:company_address') }}
                                    </MLabel>
                                    <MInput
                                        id="second_parent_company_address"
                                        v-model="registration.second_parent_company_address"
                                        :placeholder="__('common:address.address')"
                                    />
                                </MFieldset>
                                <div class="tw-flex tw-gap-4">
                                    <MFieldset class="tw-basis-1/3">
                                        <MLabel>
                                            {{ __('common:address.zip_code') }}
                                        </MLabel>
                                        <MInput
                                            id="second_parent_company_zipcode"
                                            v-model="registration.second_parent_company_zipcode"
                                            :placeholder="__('common:address.zip_code')"
                                        />
                                    </MFieldset>
                                    <MFieldset class="tw-basis-2/3">
                                        <MLabel>
                                            {{ __('common:address.city') }}
                                        </MLabel>
                                        <MInput
                                            id="second_parent_company_city"
                                            v-model="registration.second_parent_company_city"
                                            :placeholder="__('common:address.city')"
                                        />
                                    </MFieldset>
                                </div>
                                <MFieldset>
                                    <MLabel>{{ __('common:address.country') }}</MLabel>
                                    <SelectCountry v-model="registration.second_parent_company_country" />
                                </MFieldset>
                                <div class="tw-flex tw-gap-4">
                                    <MFieldset class="tw-basis-full">
                                        <MLabel>
                                            {{ __('common:phone.landline') }}
                                        </MLabel>
                                        <MInput
                                            id="second_parent_company_phone"
                                            v-model="registration.second_parent_company_phone"
                                            :placeholder="__('common:phone.landline')"
                                        />
                                    </MFieldset>
                                    <MFieldset class="tw-basis-full">
                                        <MLabel>
                                            {{ __('common:email') }}
                                        </MLabel>
                                        <MInput
                                            id="second_parent_company_email"
                                            v-model="registration.second_parent_company_email"
                                            :placeholder="__('common:email')"
                                        />
                                    </MFieldset>
                                </div>
                            </template>
                        </div>
                    </div>
                    <MFieldset class="tw-mt-4 tw-w-1/2 sm:tw-w-1/4">
                        <MLabel>
                            {{ __('common:dependent_children') }}
                        </MLabel>
                        <MInput
                            id="contract_dependent_children"
                            v-model="registration.contract_dependent_children"
                            :placeholder="__('registration:one')"
                            type="number"
                        />
                    </MFieldset>
                </div>
            </MStepPanelContent>
        </MStepPanel>

        <template #footer-start>
            <MButton
                v-if="hasPreviousStep"
                variant="primary"
                @click="$refs.stepper.previousStep()"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </template>
                {{ __('common:actions.previous') }}
            </MButton>
        </template>

        <template #footer-end>
            <MButton
                class="tw-mr-2"
                @click="cancelRegistration"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <template v-if="hasNextStep">
                <MButton
                    variant="primary"
                    @click="$refs.stepper.nextStep()"
                >
                    <template #right-icons>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                    </template>
                    {{ __('common:actions.next') }}
                </MButton>
            </template>
            <template v-else>
                <MButton
                    :loading="loading"
                    variant="primary"
                    @click="save()"
                >
                    {{ __('common:actions.save') }}
                </MButton>
            </template>
        </template>
    </MModal>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import _clone from 'lodash-es/clone';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import {reactive} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import SelectCountry from '@/modules/legacy/components/Modules/SelectCountry.vue';
    import defaultRegistration from '@/modules/legacy/contents/defaultRegistration';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    import Planning from './Planning/Planning.vue';

    export default {
        components: {
            SelectCountry,
            ModulePlanning: Planning,
        },
        mixins: [nursery],
        props: {
            modal: {
                type: Object,
                required: true,
            },
            nursery: {},
            registration: {
                type: Object,
                default() {
                    return reactive(_cloneDeep(defaultRegistration));
                },
            },
        },
        emits: ['registrationCreated'],
        data() {
            return {
                loading: false,
                hasPreviousStep: false,
                hasNextStep: false,
            };
        },
        computed: {
            registrationExists() {
                return this.registration && this.registration.id !== null;
            },
            salaryDate() {
                if (this.registration.contract_started_at) {
                    return Epoch.fromISOString(this.registration.contract_started_at).subYears(2).year;
                }

                return '';
            },
            isStepOneCompleted() {
                return !!this.registration.kid_last_name && !!this.registration.kid_birthdate;
            },
            isStepTwoCompleted() {
                return !!this.registration.contract_started_at && !!this.registration.contract_ended_at;
            },
            isStepThreeCompleted() {
                return (
                    !!this.registration.first_parent_first_name &&
                    !!this.registration.first_parent_last_name &&
                    !!this.registration.first_parent_email
                );
            },
            ParentRoleOptions() {
                return [
                    {value: 'mother', text: __('common:relationship.parent_female')},
                    {value: 'father', text: __('common:relationship.parent_male')},
                    {value: 'brother', text: __('common:relationship.sibling_male')},
                    {value: 'sister', text: __('common:relationship.sibling_female')},
                    {value: 'uncle', text: __('common:relationship.pibling_male')},
                    {value: 'aunt', text: __('common:relationship.pibling_female')},
                    {value: 'stepmother', text: __('common:relationship.parent_in_law_female')},
                    {value: 'stepfather', text: __('common:relationship.parent_in_law_male')},
                    {value: 'grandparent', text: __('common:relationship.grandparent')},
                    {value: 'legal_custodian_male', text: __('common:relationship.legal_custodian_male')},
                    {value: 'legal_custodian_female', text: __('common:relationship.legal_custodian_female')},
                    {value: 'other', text: __('common:other_one')},
                ];
            },
        },
        watch: {
            'registration.first_parent_role': function (val) {
                this.setParentGenderFromRole(val, 'first_parent_gender');
            },
            'registration.second_parent_role': function (val) {
                this.setParentGenderFromRole(val, 'second_parent_gender');
            },
            'registration.id': function (val) {
                if (val === null) {
                    this.$refs.wizard.navigateToTab(0);
                }
            },
        },
        methods: {
            save() {
                const data = _clone(this.registration);

                this.loading = true;

                if (!data.requested_at) {
                    data.requested_at = Epoch.now().toFormat('yyyy-MM-dd');
                }

                this.store(data, registration => {
                    this.registration.id = registration.id;
                    this.attach(registration => {
                        this.$emit('registrationCreated', registration);
                        useNotification().success(__('registration:add_success'));
                        this.modal.hide();
                    });
                });
            },

            store(data, callback) {
                useApi()
                    .legacy.post(route('registrations.store'), data)
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            attach(callback) {
                useApi()
                    .legacy.post(
                        route('nurseries.registrations.attach', {
                            nurseries: this.nursery.id,
                            registration: this.registration.id,
                        })
                    )
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            copyAddress() {
                this.registration.second_parent_address = this.registration.first_parent_address;
                this.registration.second_parent_city = this.registration.first_parent_city;
                this.registration.second_parent_country = this.registration.first_parent_country;
                this.registration.second_parent_zipcode = this.registration.first_parent_zipcode;
                this.registration.second_parent_phone = this.registration.first_parent_phone;
            },

            cancelRegistration() {
                this.modal.hide();
            },

            showCAF() {
                return this.$showCAF(this.nursery.country);
            },

            setParentGenderFromRole(role, registrationParentGenderkey) {
                const females = ['mother', 'sister', 'aunt', 'stepmother', 'legal_custodian_female'];

                if (females.indexOf(role) >= 0) {
                    this.registration[registrationParentGenderkey] = 'female';
                } else {
                    this.registration[registrationParentGenderkey] = 'male';
                }
            },
        },
    };
</script>
