<template>
    <CHStack>
        <MSelectMenu
            v-model="selectedEnd"
            class="tw-mr-4"
            :fallback-text="options[0].text"
            :options="options"
        />
        <CFormDatepicker
            v-if="selectedEnd === 'until'"
            v-model="until"
        />
        <template v-if="selectedEnd === 'count'">
            <CInput
                v-model="count"
                class="tw-mr-2 tw-w-16"
                min="1"
                type="number"
            />
            <CText class="tw-self-center">
                {{ __('common:end.count') }}
            </CText>
        </template>
    </CHStack>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import type Rule from '@/modules/calendar/utils/RuleSet/Rule';

    export default defineComponent({
        props: {
            rule: {
                type: Object as PropType<Rule>,
                required: true,
            },
        },
        setup(props) {
            const getRuleEndType = () => {
                let type = 'none';
                if (props.rule.until) {
                    type = 'until';
                }
                if (props.rule.count) {
                    type = 'count';
                }

                return type;
            };

            const selectedEnd = ref(getRuleEndType());
            const until = ref(props.rule.until);
            const count = ref(props.rule.count || 1);

            const options = [
                {value: 'none', text: __('common:end.never')},
                {value: 'count', text: __('common:after')},
                {value: 'until', text: __('common:end_until')},
            ];

            watch(selectedEnd, () => {
                if (selectedEnd.value === 'none') {
                    props.rule.count = undefined;
                    props.rule.until = undefined;
                } else if (selectedEnd.value === 'count') {
                    props.rule.count = count.value;
                    props.rule.until = undefined;
                } else {
                    props.rule.count = undefined;
                    props.rule.until = until.value;
                }
            });

            watch(count, () => {
                props.rule.count = count.value;
            });

            watch(until, () => {
                if (until.value) {
                    props.rule.until = Epoch.fromISOString(until.value).toISOString();
                }
            });

            return {
                count,
                options,
                selectedEnd,
                until,
            };
        },
    });
</script>
