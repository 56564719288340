import {type Model, ModelCollection, type QueryBuilder, QueryBuilderDateTime} from '@meekohq/lumos';
import {debounce} from 'lodash-es';
import type {Ref} from 'vue';
import {reactive, ref, watch} from 'vue';

import ContactModel from '@/modules/cashier/models/ContactModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import EndpointModel from '@/modules/cashier/models/EndpointModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import CustomerPersonValue from '@/modules/cashier/utils/core/customer/CustomerPersonValue';
import FamilyModel from '@/modules/family/models/FamilyModel';
import KidModel from '@/modules/family/models/KidModel';
import MemberModel from '@/modules/family/models/MemberModel';

export interface CustomerListFilters {
    period: Ref<{from: string; to: string}>;
    tenants: Ref<TenantModel[]>;
    customerPerson: Ref<CustomerPersonValue>;
}

/**
 * Composable to manage the list of customers to send tax certificates
 *
 * @param {CustomerListFilters} filters
 */
export default function (filters: CustomerListFilters) {
    const customers = ref(new ModelCollection()) as Ref<ModelCollection<CustomerModel>>;
    const isLoading = ref(true);

    function constrainTransactionBetweenDates<T extends Model>(query: QueryBuilder<T>) {
        query.whereBetween('date', [
            new QueryBuilderDateTime(filters.period.value.from),
            new QueryBuilderDateTime(filters.period.value.to),
        ]);
    }

    /**
     * Constrains the query to get the customers that have invoices with transactions in the selected period
     *
     * @param {QueryBuilder} query
     */
    function transactionsDateContrainsFromInvoices<T extends Model>(query: QueryBuilder<T>) {
        query.whereHas(new InvoiceModel().payments(), query2 => {
            query2.whereHas(new PaymentModel().credits(), query3 => {
                constrainTransactionBetweenDates(query3);
            });
        });

        return query;
    }

    /**
     * Constrains the query to get the customers that have families with kids with invoices with payments in the selected period
     *
     * @param {QueryBuilder} query
     * @param {string} method
     */
    function familyAndKidInvoicesConstrains<T extends Model>(query: QueryBuilder<T>, method: 'whereHas' | 'with') {
        return query[method](new CustomerModel().families(), query1 => {
            query1.whereHas(new FamilyModel().kids(), query2 => {
                query2.whereHas(new KidModel().invoices(), query3 => {
                    transactionsDateContrainsFromInvoices(query3);
                });
            });

            if (method === 'with') {
                query1.with(new FamilyModel().members(), query2 => {
                    query2.whereHas(new MemberModel().notificationEndpoint(), query3 => {
                        query3.whereHas(new EndpointModel().subscriptions(), query4 => {
                            query4.where('topic', '=', SubscriptionModel.TOPICS.TAX_CERTIFICATE_SENT);
                        });
                    });
                });

                query1.with(new FamilyModel().kids(), query2 => {
                    query2.whereHas(new KidModel().invoices(), query3 => {
                        transactionsDateContrainsFromInvoices(query3);
                    });
                });
            }
        });
    }

    /**
     * Get customers list
     */
    async function getCustomers() {
        isLoading.value = true;

        // Filter query results
        let mainQuery = CustomerModel.query()
            .whereHas(new CustomerModel().tenant(), query => {
                query.whereIn(
                    'id',
                    filters.tenants.value.map(tenant => tenant.getKey())
                );
            })
            .whereHas(new CustomerModel().transactions(), query => {
                constrainTransactionBetweenDates(query);
            });

        if (filters.customerPerson.value === CustomerPersonValue.legal) {
            mainQuery = mainQuery.where('person', '=', CustomerPersonValue.legal);
        } else {
            mainQuery = mainQuery.where('person', '=', CustomerPersonValue.natural);
            mainQuery = familyAndKidInvoicesConstrains(mainQuery, 'whereHas');
            mainQuery = familyAndKidInvoicesConstrains(mainQuery, 'with');
        }

        // Get customer contacts
        mainQuery.with(new CustomerModel().contacts(), query => {
            query.whereHas(new ContactModel().notificationEndpoint(), query1 => {
                query1.whereHas(new EndpointModel().subscriptions(), query2 => {
                    query2.where('topic', '=', SubscriptionModel.TOPICS.TAX_CERTIFICATE_SENT);
                });
            });
        });

        // Get customer tenants and organizations
        mainQuery.with(new CustomerModel().tenant(), query => {
            query.whereIn(
                'id',
                filters.tenants.value.map(tenant => tenant.getKey())
            );
            query.with(new TenantModel().organizations());
        });

        mainQuery = mainQuery.orderBy('name', 'asc');

        customers.value = await mainQuery.get();

        isLoading.value = false;
    }

    /**
     * Debounced version of getCustomers
     */
    const debounceGetCustomers = debounce(getCustomers, 1000);

    /**
     * Initialize the watchers
     */
    function initWatchers() {
        watch(
            reactive({...filters}),
            async () => {
                await debounceGetCustomers();
            },
            {deep: true}
        );
    }

    return {
        customers,
        getCustomers,
        debounceGetCustomers,
        isLoading,
        initWatchers,
    };
}
