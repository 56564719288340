<script setup lang="ts">
    import {computed, onMounted, ref} from 'vue';

    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useModal from '@/modules/app/composables/useModal';
    import useMetrics from '@/modules/app/composables/useRum';
    import MeekoFamilyReportingCard from '@/modules/meeko-family/components/MeekoFamilyReportingCard.vue';
    import MeekoFamilyReportingHeader from '@/modules/meeko-family/components/MeekoFamilyReportingHeader.vue';
    import MeekoFamilyReportingModalFeedback from '@/modules/meeko-family/components/MeekoFamilyReportingModalFeedback.vue';
    import {useReportingSettingNotification} from '@/modules/meeko-family/composables/useReportingSettingNotification';
    import {useReportingSettingsState} from '@/modules/meeko-family/composables/useReportingSettingsState';
    import {useSaveReportingSettings} from '@/modules/meeko-family/composables/useSaveReportingSettings';
    import type {ReportingSettingEntity} from '@/modules/meeko-family/domain/entities/ReportingSettingEntity';

    const isAccessGranted = ref(false);

    const {activeOrganization} = useManager();

    // Fetching
    const {setSettingByType, fetch, isFetching, reportingSettings, originalSettings, resetOriginalSettings, updated} =
        useReportingSettingsState();

    // Feedback
    const {sendRealTimeDeactivationFeedback, isSending} = useReportingSettingNotification();

    // Saving
    const {save, isSaving: isReportingsSaving} = useSaveReportingSettings();

    const isSaving = computed(() => isReportingsSaving.value || isSending.value);

    // Metrics
    const {addAction} = useMetrics();

    const disabledRealTimeReportings = computed(() =>
        reportingSettings.value.filter((setting, index) => {
            return originalSettings.value[index].realTime && !setting.realTime;
        })
    );

    const enabledRealTimeReportings = computed(() =>
        reportingSettings.value.filter((setting, index) => {
            return !originalSettings.value[index].realTime && setting.realTime;
        })
    );

    const disabledVisibilityReportings = computed(() =>
        reportingSettings.value.filter((setting, index) => {
            return originalSettings.value[index].markNextReportingAsVisible && !setting.markNextReportingAsVisible;
        })
    );

    const enabledVisibilityReportings = computed(() =>
        reportingSettings.value.filter((setting, index) => {
            return !originalSettings.value[index].markNextReportingAsVisible && setting.markNextReportingAsVisible;
        })
    );

    function reportRealTimeChanges() {
        disabledRealTimeReportings.value.forEach(setting => {
            addAction('save-real-time', {
                action: 'disable',
                type: setting.type,
            });
        });

        enabledRealTimeReportings.value.forEach(setting => {
            addAction('save-real-time', {
                action: 'enable',
                type: setting.type,
            });
        });
    }

    function reportVisibilityChanges() {
        disabledVisibilityReportings.value.forEach(setting => {
            addAction('save-visibility', {
                action: 'disable',
                type: setting.type,
            });
        });

        enabledVisibilityReportings.value.forEach(setting => {
            addAction('save-visibilty', {
                action: 'enable',
                type: setting.type,
            });
        });
    }

    function onUpdated(setting: ReportingSettingEntity) {
        setSettingByType(setting.type, setting);
    }

    async function saveSettings() {
        await save(activeOrganization.value.getKey(), reportingSettings.value, () => {
            reportRealTimeChanges();
            reportVisibilityChanges();
            resetOriginalSettings();
        });
    }

    async function showFeedbackModal() {
        useModal({
            component: MeekoFamilyReportingModalFeedback,
            props: {
                isLoading: isSaving,
            },
            listeners: modal => ({
                confirmed: async (feedback: string) => {
                    await sendRealTimeDeactivationFeedback(feedback);
                    await saveSettings();
                    modal.hide();
                },
            }),
        }).show();
    }

    onMounted(async () => {
        await fetch(activeOrganization.value.getKey());
    });
</script>

<template>
    <SimpleSubPageTemplate class="MeekoFamilyReporting">
        <template #content>
            <MeekoFamilyReportingHeader
                class="MeekoFamilyReporting__header"
                :confirmed-access="isAccessGranted"
                @confirmed="isAccessGranted = true"
            />
            <template v-if="isAccessGranted">
                <loader v-if="isFetching" />
                <div
                    v-else
                    class="MeekoFamilyReporting__container"
                >
                    <div class="MeekoFamilyReporting__grid">
                        <template
                            v-for="(setting, index) in reportingSettings"
                            :key="'reporting-setting-' + index"
                        >
                            <MeekoFamilyReportingCard
                                :disabled="isSaving"
                                :setting="setting"
                                @updated="onUpdated($event)"
                            />
                        </template>
                    </div>
                    <MButton
                        class="MeekoFamilyReporting__button"
                        :disabled="!updated"
                        :loading="isSaving"
                        size="lg"
                        variant="primary"
                        @click="showFeedbackModal"
                    >
                        {{ __('reporting:apply_changes') }}
                    </MButton>
                </div>
            </template>
        </template>
    </SimpleSubPageTemplate>
</template>

<style scoped>
    .MeekoFamilyReporting__header {
        @apply tw-mb-8 2xl:tw-w-4/5 2xl:tw-pr-1;
    }

    .MeekoFamilyReporting__container {
        @apply tw-flex tw-w-full tw-flex-col tw-gap-4;
    }

    .MeekoFamilyReporting__grid {
        @apply tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 2xl:tw-grid-cols-5;
    }

    .MeekoFamilyReporting__button {
        @apply lg:tw-ml-auto xl:tw-mt-auto;
    }
</style>
