<template>
    <div class="CustomerHeroHeader">
        <div class="CustomerHeroHeader__actions-container">
            <MHeading
                level="h4"
                variant="billing"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fa-duotone fa-file-user"
                />
                {{ __('common:customer_one') }}
            </MHeading>
            <div
                v-if="customer && !loading"
                class="tw-flex tw-gap-2"
            >
                <MPopover ref="taxCertificatePopover">
                    <MButton :label="__('common:tax_certificate_one')" />
                    <template #content>
                        <TaxCertificate
                            :customer-model="customer"
                            :nursery="legacyNursery"
                            :user="legacyUser"
                            @downloaded="onTaxCertificateDownloaded"
                        />
                    </template>
                </MPopover>
                <ActionButton
                    :customer-model="customer"
                    @deleted="
                        $router.push({name: 'cashier/customers.index', params: {nursery: activeOrganization.getKey()}})
                    "
                />
            </div>
        </div>
        <div class="CustomerHeroHeader__name-container">
            <template v-if="customer && !loading">
                <MHeading class="tw-truncate">
                    {{ customer.attributes.name }}
                </MHeading>
                <CustomerBillingStatus :customer="customer" />
                <TagsSelector
                    class="tw-ml-auto"
                    :taggable-model="customer"
                    taggable-type="customer"
                    :tags="customer.tags().value().toArray()"
                    @attached="customer.tags().value().push($event)"
                    @detached="customer.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
                />
            </template>
            <template v-else>
                <CSkeletonItem class="tw-h-6 tw-w-56" />
            </template>
        </div>
        <CustomerModalUpdate
            v-if="isModalVisible"
            :customer-model="customer"
            @closed="toggleModal(false)"
            @updated="$emit('updated')"
        />
    </div>
</template>

<script lang="ts">
    import {useToggle} from '@vueuse/core';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import CustomerBillingStatus from '@/modules/cashier/components/core/customer/atoms/CustomerBillingStatus.vue';
    import ActionButton from '@/modules/cashier/components/core/customer/CustomerHero/molecules/ActionButton.vue';
    import TaxCertificate from '@/modules/cashier/components/core/customer/CustomerHero/molecules/TaxCertificate.vue';
    import CustomerModalUpdate from '@/modules/cashier/components/core/customer/pages/CustomerModalUpdate.vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default defineComponent({
        components: {
            TagsSelector,
            TaxCertificate,
            CustomerBillingStatus,
            ActionButton,
            CustomerModalUpdate,
        },
        props: {
            customer: {type: Object as PropType<CustomerModel | undefined>, default: undefined},
            loading: {type: Boolean, default: false, required: true},
            legacyNursery: {type: Object, required: true},
            legacyUser: {type: Object, required: true},
        },
        emits: ['updated'],
        setup() {
            const {activeOrganization} = useManager();
            const [isModalVisible, toggleModal] = useToggle(false);
            const taxCertificatePopover = ref();

            const activeOrganizationTags = computed(() => activeOrganization.value.tags().value());

            function onTaxCertificateDownloaded() {
                taxCertificatePopover.value?.closePopover();
            }

            return {
                activeOrganization,
                activeOrganizationTags,
                isModalVisible,
                toggleModal,
                taxCertificatePopover,
                onTaxCertificateDownloaded,
            };
        },
    });
</script>

<style scoped>
    .CustomerHeroHeader {
        @apply tw-flex tw-flex-col tw-gap-4;
    }

    .CustomerHeroHeader__actions-container {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2;
    }

    .CustomerHeroHeader__name-container {
        @apply tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-between tw-gap-6 sm:tw-justify-start;
    }
</style>
