<template>
    <div
        v-if="computedResources.length > 0"
        class="tw-flex tw-flex-wrap tw-gap-3"
    >
        <ResourceBadge
            v-for="(resource, i) in computedResources"
            :key="'resource' + i"
            :is-link="resourcesAreLinks"
            :resource="resource"
            :show-delete="showDelete"
            @delete="$emit('delete', resource.source.model)"
            @resource-clicked="$emit('resourceClicked', $event)"
        />
    </div>
</template>
<script lang="ts">
    import type {Model} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import {defineComponent, onMounted, type PropType, type Ref, ref, watch} from 'vue';

    import ResourceBadge from '@/modules/app/components/resource/molecules/ResourceBadge.vue';
    import useResource from '@/modules/app/composables/useResource';

    export default defineComponent({
        components: {ResourceBadge},
        props: {
            resources: {
                type: Array as PropType<Model[]>,
                required: true,
            },
            showDelete: {
                type: Boolean,
                default: false,
            },
            resourcesAreLinks: {
                type: Boolean,
                default: false,
            },
            groupResources: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['delete', 'resourceClicked'],
        setup(props) {
            const resourcesCopy = ref(_cloneDeep(props.resources)) as Ref<Model[]>;

            watch(
                () => props.resources,
                () => {
                    resourcesCopy.value = _cloneDeep(props.resources);
                },
                {deep: true}
            );

            const {computedResources, init} = useResource(resourcesCopy, props.groupResources);

            onMounted(async () => {
                await init();
            });

            return {
                computedResources,
            };
        },
    });
</script>
