<template>
    <div class="AbstractItem">
        <div class="AbstractItem__header-container">
            <div class="AbstractItem__header">
                <div class="AbstractItem__icon">
                    <slot name="icon" />
                </div>
                <div class="AbstractItem__title">
                    <slot name="title" />
                </div>
                <slot name="date" />
            </div>
            <div class="AbstractItem__actions">
                <slot name="actions" />
            </div>
        </div>
        <div
            v-if="$slots.content"
            class="AbstractItem__content"
        >
            <slot name="content" />
        </div>
    </div>
</template>

<style scoped>
    .AbstractItem {
        @apply tw-block tw-rounded-xl tw-bg-white tw-shadow-md;
        @apply tw-transition tw-ease-in-out;
        @apply tw-p-3;
    }

    .AbstractItem__header-container {
        @apply tw-flex tw-justify-between tw-gap-2 sm:tw-items-center;
    }

    .AbstractItem__header {
        @apply tw-flex tw-w-full tw-flex-col tw-justify-between tw-gap-2 sm:tw-flex-row sm:tw-items-center;
    }

    .AbstractItem__title {
        @apply tw-w-full tw-min-w-0;
    }

    .AbstractItem__content {
        @apply tw-mt-2;
        @apply tw-whitespace-pre-line tw-break-words;
    }
</style>
