<template>
    <div class="PhotoList">
        <div class="PhotoList__grid-wrapper">
            <div class="PhotoList__grid">
                <template
                    v-for="(item, index) in paginator.items()"
                    :key="index"
                >
                    <div>
                        <slot
                            :index="index"
                            :item="item"
                            name="item"
                        />
                    </div>
                </template>
                <div
                    v-if="hasMore"
                    ref="target"
                    class="PhotoList__loader"
                >
                    <RingLoaderIcon />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {LengthAwarePaginator} from '@meekohq/lumos';
    import {useElementVisibility, useIntersectionObserver, useIntervalFn} from '@vueuse/core';
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';

    import RingLoaderIcon from '@/modules/app/components/atoms/RingLoaderIcon.vue';

    export default defineComponent({
        components: {RingLoaderIcon},
        props: {
            hasMore: {
                type: Boolean,
                default: false,
                required: false,
            },
            loading: {
                type: Boolean,
                default: false,
                required: false,
            },
            paginator: {
                type: Object as PropType<LengthAwarePaginator<unknown>>,
                default: undefined,
                required: false,
            },
        },
        emits: ['load-more'],
        setup(props, {emit}) {
            const target = ref(null);
            const targetIsVisible = useElementVisibility(target);

            useIntersectionObserver(target, ([{isIntersecting}]) => {
                targetIsVisible.value = isIntersecting;
            });

            // Emit the load-more event each second
            const {pause, resume} = useIntervalFn(() => {
                emit('load-more');
            }, 500);

            // Pause the interval when emit is not needed
            watch(
                [() => props.loading, () => props.hasMore, targetIsVisible],
                values => {
                    const [isLoading, hasMore, isVisible] = values;
                    if (!isLoading && hasMore && isVisible) {
                        resume();
                    } else {
                        pause();
                    }
                },
                {immediate: true}
            );

            return {
                target,
                targetIsVisible,
            };
        },
    });
</script>

<style scoped>
    .PhotoList {
        @apply tw-relative tw-w-full tw-flex-1;
    }

    .PhotoList__loader {
        @apply tw-flex tw-aspect-square tw-items-center tw-justify-center;

        svg {
            @apply tw-h-12 tw-w-12 tw-stroke-gray-200;
        }
    }

    .PhotoList__grid-wrapper {
        @apply tw-absolute tw-inset-0 tw-overflow-auto;
    }

    .PhotoList__grid {
        @apply tw-grid tw-grid-cols-3 sm:tw-grid-cols-4 md:tw-grid-cols-5 lg:tw-grid-cols-6 xl:tw-grid-cols-8 2xl:tw-grid-cols-10;
        @apply tw-gap-0.5;
    }
</style>
