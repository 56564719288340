<template>
    <MBox class="tw-p-4">
        <div>
            <div
                class="tw-flex tw-cursor-pointer tw-flex-wrap tw-items-center tw-justify-between tw-gap-2"
                @click="showCard = !showCard"
            >
                <div>
                    <MHeading>
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-primary-500"
                            icon="fa-solid fa-sliders-v"
                        />
                        {{ __('family_contract:basic_settings') }}
                    </MHeading>
                    <div class="tw-mt-1 tw-text-sm tw-text-gray-500">
                        <span v-if="showCAF">{{ $valueFromOptions(config.funding, presetModeOptions) }} /</span>
                        {{ $valueFromOptions(config.billing.cycle, cycleOptions) }} /
                        {{ $valueFromOptions(config.smoothing.on, smoothingOptions) }} /
                        {{ $valueFromOptions(config.billing.charged_at, momentOptions) }}
                    </div>
                </div>
                <MButton
                    icon-menu
                    @click.stop="showCard = !showCard"
                >
                    <template v-if="showCard">
                        {{ __('common:actions.close') }}
                    </template>
                    <template v-else>
                        {{ __('common:actions.open') }}
                    </template>
                </MButton>
            </div>

            <div v-if="showCard">
                <template v-if="showCAF">
                    <div class="Basic__row tw-mt-12">
                        <div>
                            <div>{{ __('billing_setting:do_you_have_financing_mode') }}</div>
                            <small class="small tw-text-gray-400">{{ __('billing_setting:paje_psu_operating') }}</small>
                        </div>
                        <div>
                            <MSelectMenu
                                v-model="config.funding"
                                :options="presetModeOptions"
                                text-path="text"
                                value-path="value"
                            />
                        </div>
                    </div>
                    <hr class="hr" />
                </template>

                <div class="Basic__row tw-mt-4">
                    <div>
                        <div>{{ __('billing_setting:you_want_to_invoice_by') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:hours_halfday_day_month') }}
                        </small>
                    </div>
                    <div>
                        <MSelectMenu
                            v-model="config.billing.cycle"
                            class="tw-w-full"
                            :options="cycleOptions"
                        />
                    </div>
                </div>

                <div
                    v-if="config.billing.cycle !== 'month'"
                    class="Basic__row tw-mt-6"
                >
                    <div>
                        <div>{{ __('billing_setting:hours_number_in_a_day') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:number_of_hours_to_invoice_in_a_day') }}
                        </small>
                    </div>
                    <div>
                        <CInputGroup>
                            <CInput
                                v-model.number="config.billing.hours_in_day"
                                type="number"
                            />
                            <CInputAddon class="tw-lowercase">
                                {{ __('common:hour', {count: config.billing?.hours_in_day ?? 0}) }}
                            </CInputAddon>
                        </CInputGroup>
                    </div>
                </div>

                <template v-if="config.billing.cycle !== 'month'">
                    <template
                        v-if="
                            (config.funding === 'psu' || config.funding === 'psu_cmg') &&
                            config.billing.cycle === 'hour'
                        "
                    >
                        <div class="Basic__row tw-mt-6">
                            <div>
                                <div>{{ __('billing_setting:ask_round_the_scheduled_contracts_schedule') }}</div>
                                <small class="small tw-block tw-text-gray-400">
                                    {{ __('billing_setting:round_the_scheduled_contracts_schedule_example_one') }}
                                </small>
                                <small class="small tw-block tw-text-gray-400">
                                    {{ __('billing_setting:round_the_scheduled_contracts_schedule_example_two') }}
                                </small>
                            </div>
                            <div>
                                <MButtonSelect
                                    v-model="config.billing.planning.smoothing.active"
                                    :options="booleanOptions"
                                />
                            </div>
                        </div>
                        <div
                            v-if="config.billing.planning.smoothing.active"
                            class="Basic__row tw-mt-4"
                        >
                            <smooth
                                :brackets="config.billing.planning.smoothing.brackets"
                                :nursery="nursery"
                            />
                        </div>
                        <div
                            v-if="config.billing.planning.smoothing.active"
                            class="Basic__row tw-mt-4"
                        >
                            <div>
                                <div>
                                    {{
                                        __('billing_setting:do_you_want_combine_departure_and_arrival_before_rounding')
                                    }}
                                </div>
                                <small class="small tw-text-gray-400">
                                    {{
                                        __('billing_setting:else_the_departure_and_arrival_will_be_rounded_separately')
                                    }}
                                </small>
                            </div>
                            <div>
                                <MButtonSelect
                                    v-model="config.billing.planning.smoothing.sum_start_and_end"
                                    :options="booleanOptions"
                                />
                            </div>
                        </div>
                        <CStack
                            v-if="
                                !config.billing.planning.smoothing.sum_start_and_end &&
                                config.billing.planning.smoothing.active
                            "
                            align="start"
                            class="Basic__container"
                            :direction="direction1"
                        >
                            <CVStack
                                class="Basic__radio-label"
                                gap="1"
                            >
                                <CText variant="black">
                                    {{ __('billing_setting:limit_to_opening_hours') }}
                                </CText>
                                <CText
                                    font-size="sm"
                                    variant="gray"
                                >
                                    {{ __('billing_setting:limit_to_opening_hours_example') }}
                                </CText>
                            </CVStack>
                            <div class="Basic__radio-wrapper">
                                <MButtonSelect
                                    v-model="config.billing.planning.smoothing.between_opening"
                                    :options="booleanOptions"
                                />
                            </div>
                        </CStack>
                    </template>
                    <hr class="hr" />
                    <div class="Basic__row tw-mt-4">
                        <div>
                            <div>{{ __('billing_setting:smoothing_the_contract') }}</div>
                            <small class="small tw-text-gray-400">
                                {{ __('billing_setting:so_that_the_family_pays_same_amount_every_month') }}
                            </small>
                        </div>
                        <div>
                            <MSelectMenu
                                v-model="config.smoothing.on"
                                :options="smoothingOptions"
                                sub-text-path="subtext"
                                text-path="text"
                                value-path="value"
                            />
                        </div>
                    </div>

                    <template v-if="config.smoothing.on === 'contract' || config.smoothing.on === 'none'">
                        <div class="Basic__row tw-mt-6">
                            <div>
                                <div>{{ __('billing_setting:do_you_want_to_invoice_public_holidays') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:public_holiday_are_to_be_defined_in_settings') }}
                                </small>
                            </div>
                            <div>
                                <MButtonSelect
                                    v-model="config.billing.charge_holidays"
                                    :options="booleanOptions"
                                />
                            </div>
                        </div>
                        <div class="Basic__row tw-mt-6">
                            <div>
                                <div>{{ __('billing_setting:do_you_want_to_invoice_nursery_closures') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:periods_are_to_be_defined_in_settings') }}
                                </small>
                            </div>
                            <div>
                                <MButtonSelect
                                    v-model="config.billing.charge_closing"
                                    :options="booleanOptions"
                                />
                            </div>
                        </div>
                        <div
                            v-if="config.smoothing.on === 'contract'"
                            class="Basic__row tw-mt-6"
                        >
                            <div>
                                <div>{{ __('billing_setting:do_you_want_to_deduct_holidays_in_the_smoothing') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:days_number_will_be_defined_into_contract') }}
                                </small>
                            </div>
                            <div>
                                <MButtonSelect
                                    v-model="config.smoothing.deduct_vacations"
                                    :options="booleanOptions"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-if="config.smoothing.on === 'year'">
                        <div class="Basic__row tw-mt-6">
                            <div>
                                <div>{{ __('billing_setting:do_you_want_smoothing_to_be_done_on') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:the_hours_or_hourly_rate') }}
                                </small>
                            </div>
                            <div>
                                <MSelectMenu
                                    v-model="config.smoothing.smooth_on"
                                    :options="smoothingOnOptions"
                                    sub-text-path="subtext"
                                    text-path="text"
                                    value-path="value"
                                />
                            </div>
                        </div>

                        <div
                            v-if="config.smoothing.smooth_on === 'none'"
                            class="Basic__row tw-mt-6"
                        >
                            <div>
                                <div>{{ __('billing_setting:how_many_weeks_in_a_month') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:weeks_in_a_month_example') }}
                                </small>
                            </div>
                            <div>
                                <CInputGroup>
                                    <CInput
                                        v-model.number="config.billing.weeks_in_month"
                                        placeholder="4"
                                        type="number"
                                    />
                                    <CInputAddon class="tw-lowercase">
                                        <template
                                            v-if="config.billing.weeks_in_month && config.billing.weeks_in_month > 1"
                                        >
                                            {{ __('common:week_other') }}
                                        </template>
                                        <template v-else>
                                            {{ __('common:week_one') }}
                                        </template>
                                    </CInputAddon>
                                </CInputGroup>
                            </div>
                        </div>

                        <div
                            v-if="
                                config.smoothing.smooth_on !== 'none' &&
                                config.smoothing.weeks_in_contract !== undefined
                            "
                            class="Basic__row tw-mt-6"
                        >
                            <div>
                                <div>{{ __('billing_setting:for_how_many_weeks_is_the_contract_computed') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:keep_empty_to_have_number_of_weeks_automatically') }}
                                </small>
                            </div>
                            <div>
                                <CInputGroup>
                                    <CInput
                                        v-model.number="config.smoothing.weeks_in_contract"
                                        type="number"
                                    />
                                    <CInputAddon class="tw-lowercase">
                                        <template
                                            v-if="
                                                config.smoothing.weeks_in_contract &&
                                                config.smoothing.weeks_in_contract > 1
                                            "
                                        >
                                            {{ __('common:week_other') }}
                                        </template>
                                        <template v-else>
                                            {{ __('common:week_one') }}
                                        </template>
                                    </CInputAddon>
                                </CInputGroup>
                                <div
                                    v-if="
                                        !config.smoothing.weeks_in_contract &&
                                        responseConfig &&
                                        responseConfig.presence &&
                                        responseConfig.presence.weeks
                                    "
                                    class="small tw-mt-1"
                                >
                                    {{ __('billing_invoice:computed_weeks_colon') }}
                                    <strong>{{ loading ? '...' : responseConfig.presence.weeks }}</strong>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="config.smoothing.smooth_on !== 'none'"
                            class="Basic__row tw-mt-6"
                        >
                            <div>
                                <div>{{ __('billing_setting:how_many_weeks_of_closures_do_you_deduct') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:weeks_of_closures_deducted_example') }}
                                </small>
                            </div>
                            <div>
                                <CInputGroup>
                                    <CInput
                                        v-model.number="config.smoothing.closing_weeks"
                                        type="number"
                                    />
                                    <CInputAddon class="tw-lowercase">
                                        <template
                                            v-if="config.smoothing.closing_weeks && config.smoothing.closing_weeks > 1"
                                        >
                                            {{ __('common:week_other') }}
                                        </template>
                                        <template v-else>
                                            {{ __('common:week_one') }}
                                        </template>
                                    </CInputAddon>
                                </CInputGroup>
                            </div>
                        </div>
                        <div
                            v-if="config.smoothing.smooth_on !== 'none' && config.smoothing.months !== undefined"
                            class="Basic__row tw-mt-6"
                        >
                            <div>
                                <div>{{ __('billing_setting:how_many_months_is_the_smoothing_done') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:keep_empty_to_have_number_of_month_automatically') }}
                                </small>
                            </div>
                            <div>
                                <CInputGroup>
                                    <CInput
                                        v-model.number="config.smoothing.months"
                                        type="number"
                                    />
                                    <CInputAddon class="tw-lowercase">
                                        {{ __('common:month') }}
                                    </CInputAddon>
                                </CInputGroup>
                                <div
                                    v-if="
                                        !config.smoothing.months &&
                                        responseConfig &&
                                        responseConfig.presence &&
                                        responseConfig.presence.months
                                    "
                                    class="small tw-mt-1"
                                >
                                    {{ __('billing_invoice:computed_months_colon') }}
                                    <strong>{{ loading ? '...' : responseConfig.presence.months }}</strong>
                                </div>
                                <div class="small tw-mt-1">
                                    <span
                                        v-if="
                                            !config.smoothing.weeks_in_contract &&
                                            responseConfig &&
                                            responseConfig.presence &&
                                            responseConfig.presence.weeks
                                        "
                                        v-html="
                                            __('billing_setting:smoothing_will_be_done_over_count_weeks', {
                                                count: responseConfig.presence.weeks - config.smoothing.closing_weeks,
                                            })
                                        "
                                    />

                                    <span
                                        v-else-if="!config.smoothing.weeks_in_contract"
                                        v-html="__('billing_setting:total_number_of_weeks_will_be_based_on_contract')"
                                    />
                                    <span
                                        v-else
                                        v-html="
                                            __('billing_setting:smoothing_will_be_done_over_count_weeks', {
                                                count:
                                                    config.smoothing.weeks_in_contract - config.smoothing.closing_weeks,
                                            })
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <hr class="hr" />

                <div class="Basic__row tw-mt-6">
                    <div>
                        <div>
                            {{ __('billing_setting:desired_text_on_the_invoice_line') }}
                            <MHelpTooltip>
                                {{ __('billing_setting:qty_word_will_be_automatically_replaced_by_billed_quantity') }}
                            </MHelpTooltip>
                        </div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:desired_text_on_the_invoice_line_example') }}
                        </small>
                    </div>
                    <div>
                        <MInput v-model="config.billing.line_name" />
                    </div>
                </div>
                <div class="Basic__row tw-mt-6">
                    <div>
                        <div>{{ __('billing_setting:ask_display_details_into_invoice') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:otherwise_fixed_amount_package_will_be_displayed') }}
                        </small>
                    </div>
                    <div>
                        <MButtonSelect
                            v-model="config.billing.package"
                            :options="invBooleanOptions"
                        />
                    </div>
                </div>

                <div
                    v-if="config.funding === 'psu_cmg'"
                    class="Basic__row tw-mt-6"
                >
                    <div>
                        <div>{{ __('billing_setting:detail_cmg_amount_on_invoice') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:one_line_will_be_add_for_detail_cmg_amount') }}
                        </small>
                    </div>
                    <div>
                        <MButtonSelect
                            v-model="config.cmg.detail_cmg"
                            :options="booleanOptions"
                        />
                    </div>
                </div>
                <div class="Basic__row tw-mt-6">
                    <div>
                        <div>{{ __('billing_setting:when_do_you_want_to_invoice') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:choose_the_end_of_month_to_invoice_in_arrears') }}
                        </small>
                    </div>
                    <div>
                        <MSelectMenu
                            v-model="config.billing.charged_at"
                            :options="momentOptions"
                            sub-text-path="subtext"
                            text-path="text"
                            value-path="value"
                        />
                    </div>
                </div>
                <div>
                    <CText
                        as="button"
                        font-size="sm"
                        font-weight="medium"
                        variant="primary"
                        @click="showCustomChargedAt = !showCustomChargedAt"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-gear"
                        />
                        {{ __('common:customize') }}
                    </CText>
                    <div
                        v-if="showCustomChargedAt"
                        class="tw-mt-1"
                    >
                        <div class="Basic__row">
                            <div>
                                <div>{{ __('billing_setting:define_an_invoice_issuance_day') }}</div>
                                <small class="small tw-text-gray-400">
                                    {{ __('billing_setting:example_the_third_day_of_month') }}
                                </small>
                            </div>
                            <div>
                                <CInputGroup>
                                    <CInput
                                        v-model.number="config.billing.charged_at_by_month_day"
                                        type="number"
                                    />
                                    <CInputAddon class="tw-shrink-0 tw-lowercase">
                                        {{ __('billing_setting:day_of_month') }}
                                    </CInputAddon>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Basic__row tw-mt-6">
                    <div>
                        <div>{{ __('billing_setting:invoice_payable_within') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:for_example_thirty_days_from_issuance_date') }}
                        </small>
                    </div>
                    <div>
                        <CInputGroup>
                            <CInput
                                v-model.number="config.billing.due_at"
                                type="number"
                            />
                            <CInputAddon class="tw-lowercase">
                                <template v-if="config.billing.due_at && config.billing.due_at > 1">
                                    {{ __('common:day_other') }}
                                </template>
                                <template v-else>
                                    {{ __('common:day_one') }}
                                </template>
                            </CInputAddon>
                        </CInputGroup>
                    </div>
                </div>

                <CVStack gap="6">
                    <CVStack gap="2">
                        <CStack
                            align="start"
                            class="Basic__container"
                            :direction="direction2"
                            :gap="1"
                        >
                            <CVStack
                                class="Basic__label"
                                gap="1"
                            >
                                <CVStack gap="1">
                                    <CText variant="black">
                                        {{ __('billing_setting:conditions_on_invoice') }}
                                    </CText>
                                    <CText
                                        font-size="sm"
                                        variant="gray"
                                    >
                                        {{ __('billing_setting:conditions_explanation') }}
                                    </CText>
                                </CVStack>
                            </CVStack>
                            <div class="Basic__textarea-wrapper">
                                <MTextarea
                                    v-model="config.billing.conditions"
                                    :placeholder="nursery.invoice_conditions"
                                />
                            </div>
                        </CStack>
                    </CVStack>
                    <CVStack gap="2">
                        <CStack
                            align="start"
                            :direction="direction2"
                            :gap="1"
                        >
                            <CVStack
                                class="Basic__label"
                                gap="1"
                            >
                                <CVStack gap="1">
                                    <CText variant="black">
                                        {{ __('billing_setting:footer.invoices') }}
                                    </CText>
                                    <CText
                                        font-size="sm"
                                        variant="gray"
                                    >
                                        {{ __('billing_setting:footer.legal_notices') }}
                                    </CText>
                                </CVStack>
                            </CVStack>
                            <div class="Basic__textarea-wrapper">
                                <MTextarea
                                    v-model="config.billing.footer"
                                    :placeholder="nursery.invoice_footer"
                                />
                            </div>
                        </CStack>
                    </CVStack>
                </CVStack>
                <div class="Basic__row tw-mt-6 tw-border-t tw-pt-4">
                    <div>
                        <div class="tw-flex tw-flex-wrap tw-items-baseline tw-gap-2">
                            {{ __('billing_setting:company_billing') }}
                            <MButton
                                :label="
                                    showCompanyInvoiceSettings ? __('common:actions.hide') : __('common:actions.show')
                                "
                                variant="link"
                                @click="showCompanyInvoiceSettings = !showCompanyInvoiceSettings"
                            />
                        </div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:customize_business_invoices') }}
                        </small>
                    </div>
                </div>
                <div
                    v-if="showCompanyInvoiceSettings"
                    class="Basic__row tw-mt-6"
                >
                    <div>
                        <div>{{ __('billing_setting:ask_display_kid_name_on_invocie') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:kid_name_will_be_displayed_on_invoice') }}
                        </small>
                    </div>
                    <div>
                        <MButtonSelect
                            v-model="config.billing.show_kid_name_on_company_invoice"
                            :options="booleanOptions"
                        />
                    </div>
                </div>
                <div
                    v-if="showCompanyInvoiceSettings"
                    class="Basic__row tw-mt-6"
                >
                    <div>
                        <div>{{ __('billing_setting:ask_display_employee_name_on_invoice') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:employee_name_will_be_displayed_on_invoice') }}
                        </small>
                    </div>
                    <div>
                        <MButtonSelect
                            v-model="config.billing.show_employee_name_on_company_invoice"
                            :options="booleanOptions"
                        />
                    </div>
                </div>
                <MFieldset v-if="showCompanyInvoiceSettings">
                    <CStack
                        align="start"
                        class="Basic__container"
                        :direction="direction2"
                        :gap="1"
                    >
                        <CVStack
                            class="Basic__label"
                            gap="1"
                        >
                            <CVStack gap="1">
                                <CText variant="black">
                                    {{ __('billing_setting:conditions_on_company_invoice') }}
                                </CText>
                                <CText
                                    font-size="sm"
                                    variant="gray"
                                >
                                    {{ __('billing_setting:conditions_explanation') }}
                                </CText>
                            </CVStack>
                        </CVStack>
                        <div class="Basic__textarea-wrapper">
                            <MTextarea
                                v-model="config.billing.company_conditions"
                                :placeholder="nursery.invoice_conditions"
                            />
                        </div>
                    </CStack>
                </MFieldset>
                <MFieldset v-if="showCompanyInvoiceSettings">
                    <CStack
                        align="start"
                        class="Basic__container"
                        :direction="direction2"
                        :gap="1"
                    >
                        <CVStack
                            class="Basic__label"
                            gap="1"
                        >
                            <CVStack gap="1">
                                <CText variant="black">
                                    {{ __('billing_setting:footer.company_invoice') }}
                                </CText>
                                <CText
                                    font-size="sm"
                                    variant="gray"
                                >
                                    {{ __('billing_setting:footer.legal_notices') }}
                                </CText>
                            </CVStack>
                        </CVStack>
                        <div class="Basic__textarea-wrapper">
                            <MTextarea
                                v-model="config.billing.company_footer"
                                :placeholder="nursery.invoice_footer"
                            />
                        </div>
                    </CStack>
                </MFieldset>
                <div
                    v-if="showCAF && (config.funding === 'psu_cmg' || config.funding === 'paje')"
                    class="Basic__row tw-mb-2 tw-mt-4 tw-border-t tw-pt-4"
                >
                    <div>
                        <div class="tw-flex tw-flex-wrap tw-items-baseline tw-gap-2">
                            {{ __('billing_setting:paje_certificate_template') }}
                            <MButton
                                :label="showCMGCertif ? __('common:actions.hide') : __('common:actions.show')"
                                variant="link"
                                @click="showCMGCertif = !showCMGCertif"
                            />
                        </div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:for_cafs_that-have_not_yet_transitioned_to_digital_processing') }}
                        </small>
                    </div>
                    <div v-if="pdfDocuments.length && showCMGCertif">
                        <MSelectMenu
                            v-model="config.cmg_certif_id"
                            :options="pdfDocuments"
                            text-path="name"
                            value-path="id"
                        />
                    </div>
                </div>
                <div
                    v-if="showCAF && (config.funding === 'psu_cmg' || config.funding === 'paje') && showCMGCertif"
                    class="Basic__row tw-mt-6"
                >
                    <div>
                        <div>{{ __('billing_setting:do_you_have_mandate_caf') }}</div>
                        <small class="small tw-text-gray-400">
                            {{ __('billing_setting:one_box_wil_be_added_to_paje_certificate') }}
                        </small>
                    </div>
                    <div>
                        <MButtonSelect
                            v-model="config.cmg.caf_mandate"
                            :options="cafMandateOptions"
                        />
                    </div>
                </div>
            </div>
        </div>
    </MBox>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import {useResponsiveValue} from '@/modules/app/composables/useResponsiveValue.ts';
    import __ from '@/modules/app/utils/i18n-facade';
    import {showCAF as organizationShowCaf} from '@/modules/legacy/libs/organization';
    import route from '@/modules/legacy/libs/ziggy';

    import Smooth from './Price/Array/Smooth.vue';

    export default defineComponent({
        components: {
            smooth: Smooth,
        },
        props: {
            config: {
                type: Object,
                required: true,
            },
            responseConfig: {
                type: Object,
                default: null,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            nursery: {
                type: Object as PropType<{country: string}>,
                required: true,
            },
        },
        setup(props) {
            const showCard = ref(false);

            const showCustomChargedAt = ref(false);

            const showCMGCertif = ref(false);

            const showCompanyBillingConditions = ref(false);

            const showCompanyBillingFooter = ref(false);

            const showCompanyInvoiceSettings = ref(false);

            const pdfDocuments = ref([]);

            const cafMandateOptions = [
                {
                    text: __('common:no'),
                    value: 'none',
                },
                {
                    text: __('common:individual'),
                    value: 'individual',
                },
                {
                    text: __('common:collective'),
                    value: 'collective',
                },
            ];

            const presetModeOptions = [
                {
                    text: 'PAJE (CMG)',
                    value: 'paje',
                },
                {
                    text: 'PSU',
                    value: 'psu',
                },
                {
                    text: 'PSU + CMG',
                    value: 'psu_cmg',
                },
                {
                    text: __('common:none'),
                    value: 'none',
                },
            ];

            const momentOptions = [
                {
                    text: __('billing_setting:end_month'),
                    subtext: __('billing_setting:end_month_subtext'),
                    value: 'end',
                },
                {
                    text: __('billing_setting:start_month'),
                    subtext: __('billing_setting:start_month_subtext'),
                    value: 'start',
                },
            ];

            const booleanOptions = [
                {
                    text: __('common:yes'),
                    value: true,
                },
                {
                    text: __('common:no'),
                    value: false,
                },
            ];

            const invBooleanOptions = [
                {
                    text: __('common:yes'),
                    value: false,
                },
                {
                    text: __('common:no'),
                    value: true,
                },
            ];

            const smoothingOnOptions = [
                {
                    text: __('billing_setting:the_presence'),
                    subtext: __('billing_setting:the_presence_subtext'),
                    value: 'qty',
                },
                {
                    text: __('billing_setting:already_smoothed'),
                    subtext: __('billing_setting:already_smoothed_subtext'),
                    value: 'none',
                },
                {
                    text: __('billing_setting:the_price'),
                    subtext: __('billing_setting:the_price_subtext'),
                    value: 'price',
                },
            ];

            const smoothingOptions = [
                {
                    text: __('billing_setting:over_year'),
                    subtext: __('billing_setting:over_year_subtext'),
                    value: 'year',
                },
                {
                    text: __('billing_setting:over_duration_contract'),
                    subtext: __('billing_setting:over_duration_contract_subtext'),
                    value: 'contract',
                },
                {
                    text: __('billing_setting:no_smooth'),
                    subtext: __('billing_setting:no_smooth_subtext'),
                    value: 'none',
                },
            ];

            onMounted(() => {
                fetchPdfDocuments();
            });

            const showCAF = computed(() => {
                if (props.nursery) {
                    return organizationShowCaf(props.nursery.country);
                }

                return false;
            });

            const cycleOptions = computed(() => {
                const options = [
                    {
                        text: __('common:hour_other'),
                        value: 'hour',
                    },
                    {
                        text: __('common:halfday_other'),
                        value: 'halfday',
                    },
                    {
                        text: __('common:day_female_one'),
                        value: 'day',
                    },
                ];

                if (props.config.funding === 'none' || !showCAF.value) {
                    options.push({
                        text: __('common:month'),
                        value: 'month',
                    });
                }

                return options;
            });

            function fetchPdfDocuments() {
                useApi()
                    .legacy.get(route('pdfDocuments.index'))
                    .then(response => {
                        pdfDocuments.value = response.data;
                    });
            }

            const direction1 = useResponsiveValue({base: 'col', sm: 'row'});
            const direction2 = useResponsiveValue({base: 'col', md: 'row'});

            return {
                showCard,
                showCustomChargedAt,
                showCMGCertif,
                showCompanyBillingConditions,
                showCompanyBillingFooter,
                showCompanyInvoiceSettings,
                pdfDocuments,
                cafMandateOptions,
                presetModeOptions,
                cycleOptions,
                momentOptions,
                booleanOptions,
                invBooleanOptions,
                smoothingOnOptions,
                smoothingOptions,
                showCAF,
                direction1,
                direction2,
            };
        },
    });
</script>

<style scoped>
    .Basic__container {
        @apply tw-mt-7;
    }

    .Basic__label {
        @apply md:tw-w-1/2;
    }

    .Basic__radio-label {
        @apply sm:tw-w-1/2;
    }

    .Basic__textarea-wrapper {
        @apply md:tw-w-1/3;
        @apply md:tw-ml-0.5;
        @apply md:tw-pr-1.5;
    }

    .Basic__radio-wrapper {
        @apply sm:tw-self-center;
        /*Les classes ci-dessous sont utilisées pour se rapprocher au mieux des classes bootstrap des autres container des compo radio-group.*/
        @apply sm:tw-px-1;
        @apply tw-mt-2;
        @apply sm:tw-mt-0;
    }

    .Basic__row {
        @apply tw-grid tw-grid-cols-1 tw-items-center tw-gap-2 sm:tw-grid-cols-2;
    }
</style>
