<script setup lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {type Ref, ref, watch} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import TeamListItemMenu from '@/modules/human-resources/components/team/List/TeamListItemMenu.vue';
    import DeleteTeamModal from '@/modules/human-resources/components/team/Modals/DeleteTeamModal.vue';
    import TeamModel from '@/modules/human-resources/models/TeamModel';
    import type TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
    import type TeamStaffPivot from '@/modules/human-resources/models/TeamStaffPivot';
    import Loader from '@/modules/legacy/helpers/loader.helper';

    const {teamModel} = defineProps<{
        teamModel: TeamModel;
    }>();

    const emit = defineEmits<{
        (e: 'onRemoveTeamModel' | 'ownerRemoveTeamModel', teamModel: TeamModel): void;
    }>();

    const loader = ref<Loader>(new Loader());
    const isOnlyInOrganization: Ref<boolean> = ref(false);
    const pivot = ref<TeamOrganizationPivot>();
    const getStaffInTeam: Ref<number> = ref(0);
    const {activeOrganization} = useManager();

    function removeTeam(model) {
        emit('ownerRemoveTeamModel', model);
    }

    const isArchived = ref<boolean>(false);

    function updateIsArchived(value: boolean) {
        isArchived.value = value;
    }

    watch(
        () => teamModel,
        async value => {
            loader.value.start();
            const pivots: Collection<TeamOrganizationPivot> = value.organizationsPivots().value();
            isOnlyInOrganization.value = pivots.count() === 1;

            pivot.value = value
                .organizationsPivots()
                .value()
                .where('attributes.organization_id', activeOrganization.value.id)
                .first();

            updateIsArchived(!!pivot.value?.attributes.archived_at);
            const fetchStaffs: TeamStaffPivot[] = (await value.staffsPivots().load()).all();
            getStaffInTeam.value = fetchStaffs.length;

            loader.value.stop();
        },
        {immediate: true}
    );
</script>

<template>
    <CListRow
        v-if="pivot && loader.isDone()"
        :class="{'progress-bar-striped': isArchived}"
        :variant="isArchived ? 'disabled' : 'default'"
    >
        <CInline
            v-if="teamModel"
            align-y="baseline"
        >
            <div class="tw-flex tw-w-3/12 tw-items-center">
                <span>{{ teamModel.attributes.name }}</span>
            </div>
            <div class="tw-flex tw-w-3/12 tw-items-center">
                <span v-if="getStaffInTeam">{{ __('hr:member', {count: getStaffInTeam}) }}</span>
            </div>
            <div class="tw-w-1/12" />
            <div class="tw-flex tw-w-5/12 tw-items-center tw-justify-end">
                <CBadge
                    v-if="!isOnlyInOrganization"
                    class="tw-mr-2"
                    variant="blue"
                >
                    <span>{{ __('hr:shared_team') }}</span>
                </CBadge>
                <MBadge
                    v-if="isArchived"
                    variant="warning"
                >
                    {{ __('common:archived') }}
                </MBadge>
                <TeamListItemMenu
                    :is-only-in-organization="isOnlyInOrganization"
                    :pivot="pivot"
                    :team-model="teamModel"
                    @owner-delete="$refs.deleteModal.show()"
                    @team-model-to-delete="$emit('onRemoveTeamModel', $event)"
                    @pivot-archive-at-updated="updateIsArchived"
                />
                <DeleteTeamModal
                    ref="deleteModal"
                    :team-model="teamModel"
                    @remove-team="removeTeam"
                />
            </div>
        </CInline>
    </CListRow>
</template>
