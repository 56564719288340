<template>
    <router-link
        class="suggestion"
        :to="to"
    >
        <div class="header tw-flex tw-items-center">
            <LegacyInvoiceAvatar
                class="avatar"
                :status="suggestion.status"
            />
            <div
                class="title"
                v-html="highlighted(suggestion.title)"
            />
        </div>
        <div class="additionals">
            <dl class="additional tw-mb-0">
                <template
                    v-for="(value, i) in suggestion.additionals"
                    :key="i"
                >
                    <dt>{{ translateLabel(value.label) }}</dt>
                    <dd v-html="highlighted(value.value)" />
                </template>
            </dl>
        </div>
    </router-link>
</template>

<script>
    import moment from 'moment';

    import LegacyInvoiceAvatar from '@/modules/app/components/atoms/avatars/LegacyInvoiceAvatar.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';

    export default {
        components: {LegacyInvoiceAvatar},
        props: ['suggestion', 'query'],
        computed: {
            to() {
                if (this.suggestion.bindings.customer_id) {
                    return {
                        name: 'cashier/customers.show.invoices',
                        params: {
                            nursery: this.suggestion.bindings.nursery_id,
                            customer: this.suggestion.bindings.customer_id,
                        },
                    };
                } else {
                    const date = this.suggestion.additionals.find(item => item.label === 'Date');
                    const from = date ? moment(date.value, 'DD/MM/YYYY').startOf('month').unix() : null;
                    const to = date ? moment(date.value, 'DD/MM/YYYY').endOf('month').unix() : null;
                    const query = from && to ? {from, to} : undefined;

                    return {
                        name: 'invoices.index',
                        params: {
                            nursery: this.suggestion.bindings.nursery_id,
                        },
                        query,
                    };
                }
            },
        },
        methods: {
            translateLabel(label) {
                if (label === 'name') {
                    return __('common:name');
                }

                if (label === 'date') {
                    return __('common:date');
                }

                if (label === 'amount') {
                    return __('common:amount_one');
                }

                return label;
            },
            highlighted(value) {
                return useHighlighter().highlight(this.query, value);
            },
        },
    };
</script>
