import {Model} from '@meekohq/lumos';
import {cloneDeep, isEqual, set} from 'lodash-es';

import useCache from '@/modules/app/composables/useCache';
import CopyObject from '@/modules/legacy/helpers/copy-object.helper';

export default class Mirror {
    public value: any = {};
    public original: any = {};

    constructor(object: object = {}) {
        this.value = cloneDeep(object);
        this.original = object;
    }

    isClean(): boolean {
        return isEqual(this.original, this.value);
    }

    isDirty(): boolean {
        return !this.isClean();
    }

    rollback(): void {
        CopyObject.copy(this.original, this.value);
    }

    commit(): void {
        CopyObject.copy(this.value, this.original);
        if (this.original instanceof Model) {
            const {sync} = useCache();
            sync(this.original as Model);
        }
    }

    commit2(): void {
        CopyObject.copy(this.value.attributes, this.original.attributes);
        set(this.original, 'exist', this.value.exist);
        set(this.original, 'wasRecentlyCreated', this.value.wasRecentlyCreated);
        set(this.original, 'relationships', this.value.relationships);
        if (this.original instanceof Model) {
            const {sync} = useCache();
            sync(this.original as Model);
        }
    }
}
