<template>
    <div class="PlanningSettingsAllGroups">
        <div class="PlanningSettingsAllGroups__header">
            <MHeading>
                {{ __('common:all_groups') }}
            </MHeading>
            <MButton
                v-if="$can('create', 'groups')"
                icon-plus
                :label="__('planning:add_group')"
                variant="primary"
                @click="addGroup"
            />
        </div>
        <loader
            v-if="loading"
            light="true"
            shadow="false"
        />
        <template v-else>
            <MagicIndexEmptyList v-if="!groups.length">
                <template #empty-list>
                    {{ __('planning:no_group') }}
                </template>
            </MagicIndexEmptyList>
            <div
                v-else
                class="PlanningSettingsAllGroups__grid"
            >
                <MBox
                    v-for="(group, i) in groups"
                    :key="i"
                    class="PlanningSettingsAllGroups__card"
                    :class="{'PlanningSettingsAllGroups__card--editing': group.editing}"
                >
                    <PlanningSettingsGroupCard
                        v-if="!group.editing"
                        :group="group"
                        :job-models="jobModels"
                        @edit-rule="group.editing = true"
                    />
                    <PlanningSettingsGroupForm
                        v-else
                        :group="group"
                        :index="i"
                        :my-nursery="myNursery"
                        :nursery="nursery"
                        @cancel-edit-group="cancelEditGroup(group)"
                        @delete-group="deleteGroup(group.id, i)"
                        @save-group="saveGroup(group)"
                    />
                </MBox>
            </div>
        </template>
    </div>
</template>

<script>
    import {collect} from '@meekohq/lumos';
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import JobModel from '@/modules/human-resources/models/JobModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import route from '@/modules/legacy/libs/ziggy';
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import PlanningSettingsGroupCard from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsGroupCard.vue';
    import PlanningSettingsGroupForm from '@/modules/planning/components/settings/PlanningSettingsGroup/molecules/PlanningSettingsGroupForm.vue';

    export default {
        components: {
            PlanningSettingsGroupCard,
            PlanningSettingsGroupForm,
            MagicIndexEmptyList,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                groups: [],
                savedGroups: [],
                myNursery: {},
                jobModels: collect(),
                loader: new Loader(),
            };
        },
        async mounted() {
            this.getGroups();
            this.myNursery = _cloneDeep(this.nursery);
            this.loader.start('fetchJobModels');
            this.jobModels = await JobModel.query().get();
            this.loader.stop('fetchJobModels');
        },

        methods: {
            getGroups() {
                this.loading = true;
                useApi()
                    .legacy.get(route('groups.index'), {
                        params: {
                            nursery_id: this.nursery.id,
                        },
                    })
                    .then(response => {
                        this.loading = false;
                        this.nursery.groups = response.data;
                        this.initGroups();
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            saveGroup(group) {
                if (group.rules?.length === 0) {
                    group.rules = null;
                }

                useApi()
                    .legacy.put(
                        route('groups.update', {
                            group: group.id,
                        }),
                        group
                    )
                    .then(response => {
                        const groupToUpdate = this.nursery.groups.find(item => item.id === group.id);
                        if (groupToUpdate) {
                            const index = this.nursery.groups.indexOf(groupToUpdate);
                            set(this.nursery.groups, index, response.data);
                            this.initGroups();
                        }
                        group.editing = false;
                        useNotification().success(__('common:parameters_updated_successfully'));
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            addGroup() {
                useMagicModal().confirmationWithInputModal({
                    title: __('planning:add_new_group'),
                    confirmButtonText: __('common:actions.add'),
                    placeholderText: __('planning:group_name'),
                    onConfirm: result => {
                        useApi()
                            .legacy.post(route('groups.store'), {
                                name: result,
                                nursery_id: this.nursery.id,
                                places: 10,
                                rules: null,
                            })
                            .then(response => {
                                this.nursery.groups.push(response.data);
                                set(response.data, 'editing', true);
                                this.initGroups();
                                useNotification().success(__('planning:group_added'));
                            })
                            .catch(error => {
                                if (error.response && error.response.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            deleteGroup(id, index) {
                useMagicModal().deleteConfirmationModal({
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('groups.destroy', {
                                    group: id,
                                })
                            )
                            .then(() => {
                                this.nursery.groups.splice(index, 1);
                                this.initGroups();
                                useNotification().success(__('planning:group_deleted'));
                            })
                            .catch(error => {
                                if (error.response && error.response.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            cancelEditGroup(group) {
                const originalGroup = this.savedGroups.find(item => item.id === group.id);
                if (originalGroup) {
                    set(originalGroup, 'editing', false);
                    const groupToUpdate = this.groups.find(item => item.id === group.id);
                    if (groupToUpdate) {
                        const index = this.groups.indexOf(groupToUpdate);
                        set(this.groups, index, _cloneDeep(originalGroup));
                    }
                }
            },

            initGroups() {
                this.groups = _cloneDeep(this.nursery.groups);
                this.formatGroups();
                this.savedGroups = _cloneDeep(this.groups);
            },

            formatGroups() {
                this.groups.forEach(group => {
                    set(group, 'editing', false);
                    if (!group.rules) {
                        set(group, 'rules', []);
                    } else {
                        group.rules.forEach(rule => {
                            if (!rule.staffs) {
                                set(rule, 'staffs', []);
                            }
                        });
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .PlanningSettingsAllGroups__header {
        @apply tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2;
    }

    .PlanningSettingsAllGroups__grid {
        @apply tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4 xl:tw-grid-cols-2 2xl:tw-grid-cols-3;
    }

    .PlanningSettingsAllGroups__card {
        @apply tw-p-4;
    }

    .PlanningSettingsAllGroups__card--editing {
        @apply tw-bg-gray-100;
    }
</style>
