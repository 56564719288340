<template>
    <CModal
        ref="modal"
        :header-title="__('photo:add_media')"
        size="4xl"
        @shown="onShown"
    >
        <MButton
            class="tw-w-full"
            variant="primary"
            @click="browseFiles"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-solid fa-upload"
            />
            {{ __('photo:add_from_my_library') }}
        </MButton>
        <input
            id="browseFile"
            accept="image/*, video/*"
            class="tw-hidden"
            multiple
            type="file"
            @change="fileSelected"
        />

        <div class="PhotoAddModal__grid">
            <div
                v-for="(photo, i) in photos"
                :key="'attachedFile' + i"
                class="PhotoAddModal__item"
            >
                <div class="PhotoAddModal__container">
                    <div
                        v-if="photo.video"
                        class="PhotoAddModal__media--is-video"
                    >
                        <PhotoVideoPlayer
                            class="PhotoAddModal__video-player"
                            :extension-type="photo.type"
                            :url="photo.photo_url"
                        />
                        <MButton
                            class="PhotoAddModal__delete-media-button"
                            @click="removeFile(photo)"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </MButton>
                    </div>
                    <div
                        v-else
                        class="PhotoAddModal__media--is-photo"
                        :style="{'background-image': `url('${photo.photo_url}')`}"
                    >
                        <MButton
                            class="PhotoAddModal__delete-media-button"
                            @click="removeFile(photo)"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </MButton>
                    </div>
                    <div
                        v-if="photo.uploading"
                        class="PhotoAddModal__media--is-uploading"
                    >
                        <FontAwesomeIcon
                            class="AddMedia__cloud-icon animated rubberBand infinite slow"
                            icon="fa-duotone fa-cloud-upload-alt"
                        />
                    </div>
                    <div
                        v-else-if="photo.uploaded"
                        class="PhotoAddModal__media--is-uploaded"
                    >
                        <FontAwesomeIcon
                            class="AddMedia__check-icon"
                            icon="fa-duotone fa-check-circle"
                        />
                    </div>
                    <div
                        v-else
                        class="PhotoAddModal__informations"
                    >
                        <div class="PhotoAddModal__filename">
                            {{ photo.filename + ' - ' + filesize(photo.file.size) }}
                        </div>
                        <MFieldset>
                            <MLabel>
                                {{ __('common:date') }}
                            </MLabel>
                            <CFormDatepicker
                                v-model="photo.taken_at"
                                :time="true"
                            />
                        </MFieldset>
                        <MFieldset>
                            <MLabel>
                                {{ __('common:description') }}
                            </MLabel>
                            <MTextarea v-model="photo.description" />
                        </MFieldset>
                        <MFieldset v-if="!photo.video">
                            <CCheckbox v-model="photo.website">
                                {{ __('photo:available_in_library_of_website') }}
                            </CCheckbox>
                        </MFieldset>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="PhotoAddModal__footer">
                <MButton
                    class="tw-mr-2"
                    @click="$refs.modal.hide()"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    :disabled="loading"
                    variant="primary"
                    @click="storePhotos"
                >
                    <template v-if="loading">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-circle-notch"
                            spin
                        />
                        {{ __('common:loading_dots') }}
                    </template>
                    <template v-else>
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-save"
                        />
                        {{ __('common:actions.add') }}
                    </template>
                </MButton>
            </div>
        </template>
    </CModal>
</template>

<script>
    import {filesize} from 'filesize';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';

    import useApi from '@/modules/app/composables/useApi';
    import {upload} from '@/modules/legacy/libs/usercontent';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import PhotoVideoPlayer from '@/modules/photo/components/atoms/PhotoVideoPlayer.vue';

    export default {
        components: {
            PhotoVideoPlayer,
        },
        props: {
            nursery: {},
        },
        emits: ['photo-created', 'refresh'],
        data: () => ({
            photos: [],
        }),
        computed: {
            moment() {
                return moment;
            },
            filesize() {
                return filesize;
            },
            loading() {
                return this.photos.some(photo => {
                    return photo.uploading;
                });
            },
            processing() {
                return this.photos.some(photo => {
                    return photo.uploading || !photo.uploaded;
                });
            },
        },
        methods: {
            storePhotos() {
                this.photos.forEach(photo => {
                    photo.uploading = true;
                    upload(photo.file)
                        .then(response => {
                            const extension = photo.filename ? photo.filename.split('.').pop().toLowerCase() : '';
                            const data = {
                                description: photo.description,
                                taken_at: moment(photo.taken_at).unix(),
                                website: photo.website,
                                video: photo.video,
                                extension: photo.extension,
                                file: {
                                    key: response.key,
                                    hash: response.hash,
                                    name: photo.filename,
                                    extension,
                                },
                            };

                            useApi()
                                .legacy.post(
                                    route('nurseries.photos.store', {
                                        nurseries: this.nursery.id,
                                    }),
                                    data
                                )
                                .then(response => {
                                    photo.uploading = false;
                                    photo.uploaded = true;

                                    this.$emit('photo-created', response.data);

                                    if (!this.processing) {
                                        this.$emit('refresh');
                                        this.$refs.modal.hide();
                                    }
                                })
                                .catch(error => {
                                    photo.uploading = false;
                                    if (error && error.response && error.response.status === 422) {
                                        _forEach(error.response.data.errors, value => {
                                            useNotification().error(_head(value));
                                        });
                                    } else {
                                        useNotification().error(error);
                                    }
                                });
                        })
                        .catch(() => {
                            photo.uploading = false;
                        });
                });
            },

            fileSelected() {
                const files = document.getElementById('browseFile').files;
                const videoExtensions = ['m4v', 'avi', 'mpg', 'mp4', 'mov'];
                for (const item of files) {
                    if (item.size > 100 * 1024 * 1024) {
                        useNotification().error(`Le fichier ${item.name} est trop volumineux (max 100mb).`);
                    } else {
                        const extension = item.name ? item.name.split('.').pop().toLowerCase() : 'jpeg';
                        const reader = new FileReader();
                        reader.onload = e => {
                            const photo = {
                                id: null,
                                filename: item.name ? item.name : 'Photo',
                                taken_at: item.lastModified
                                    ? moment(item.lastModified).format('DDMMYYYYHHmm')
                                    : moment().format(),
                                description: null,
                                photo_url: e.target.result,
                                thumbnail_url: e.target.result,
                                kids: [],
                                website: 0,
                                file: item,
                                extension,
                                video: videoExtensions.includes(extension),
                                uploading: false,
                                uploaded: false,
                            };
                            this.photos.push(photo);
                        };
                        reader.readAsDataURL(item);
                    }
                }
            },

            removeFile(file) {
                const index = this.photos.indexOf(file);
                this.photos.splice(index, 1);
            },

            browseFiles() {
                document.getElementById('browseFile').click();
            },

            onShown() {
                if (document.getElementById('browseFile')) {
                    document.getElementById('browseFile').value = '';
                }
                this.photos = [];
            },
        },
    };
</script>

<style scoped>
    .PhotoAddModal__grid {
        @apply tw-mt-5 tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 md:tw-grid-cols-3;
    }

    .PhotoAddModal__item {
        @apply tw-mb-6 tw-min-h-56;
    }

    .PhotoAddModal__container {
        @apply tw-rounded-lg tw-border tw-border-gray-300;
    }

    .PhotoAddModal__video-player {
        @apply tw-h-48 tw-w-full tw-rounded-t-lg;
    }

    .PhotoAddModal__media--is-video {
        @apply tw-relative tw-rounded-t-lg tw-border-b tw-border-gray-500 tw-bg-cover tw-bg-center;
    }

    .PhotoAddModal__media--is-photo {
        @apply tw-relative tw-h-48 tw-w-full tw-rounded-t-lg tw-border-b tw-border-gray-500 tw-bg-cover tw-bg-center;
    }

    .PhotoAddModal__media--is-uploading {
        @apply tw-flex tw-items-center tw-bg-gray-50 tw-text-center;
    }

    .PhotoAddModal__media--is-uploaded {
        @apply tw-flex tw-items-center tw-bg-gray-50 tw-text-center;
    }

    .PhotoAddModal__delete-media-button {
        @apply tw-absolute tw-right-1 tw-top-1 tw-cursor-pointer tw-text-red-500 hover:tw-scale-110;
    }

    .PhotoAddModal__informations {
        @apply tw-flex tw-flex-col tw-gap-3 tw-p-2;
    }

    .PhotoAddModal__filename {
        @apply tw-break-words tw-italic tw-text-gray-700;
    }

    .AddMedia__cloud-icon {
        @apply tw-w-full tw-py-6 tw-text-5xl tw-text-blue-500;
    }

    .AddMedia__check-icon {
        @apply tw-w-full tw-py-6 tw-text-5xl tw-text-green-500;
    }

    .PhotoAddModal__footer {
        @apply tw-ml-auto;
    }
</style>
