<template>
    <ResourceFinder
        ref="resourceFinderRef"
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-disabled="buttonDisabled"
        :button-size="buttonSize"
        :button-variant="buttonVariant"
        :has-unselect="hasUnselect"
        :hide-selected-option="hideSelectedOption"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :options-callback="staffFinderOptionsCallback"
        :prevent-hide-on-click="preventHideOnClick"
        :model-value="modelValue"
        :wrapper="wrapper"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @update:model-value="onChange"
        @search="search = $event"
    >
        <template #trigger="{toggle}">
            <slot
                name="trigger"
                :toggle="toggle"
                :value="modelValue"
            />
        </template>
        <template #button-text-empty>
            <slot name="button-text-empty" />
        </template>

        <template #list-header>
            <div
                v-if="$slots['list-header']"
                class="tw-rounded-lg tw-bg-gray-100 tw-px-3 tw-py-2"
            >
                <MHeading level="h4">
                    <slot name="list-header" />
                </MHeading>
            </div>
            <div
                v-else-if="!registration && hasActiveContract"
                class="tw-rounded-lg tw-bg-gray-100 tw-px-3 tw-py-2"
            >
                <MHeading level="h4">
                    <template v-if="search">
                        {{ __('hr_staff:everybody') }}
                    </template>
                    <template v-else>
                        {{ __('hr_staff:contract_in_this_organization') }}
                    </template>
                </MHeading>
            </div>
        </template>

        <template #button-text="{value}">
            {{ value.fullname }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: values[0].fullname}) }}
            </slot>
        </template>

        <template
            v-if="$slots['fallback']"
            #fallback
        >
            <slot name="fallback" />
        </template>

        <template #default="{option, searchValue}">
            <slot
                :option="option"
                :search-value="searchValue"
            >
                <div class="tw-flex tw-items-center tw-pr-4">
                    <div
                        v-if="option instanceof staffModelClass"
                        class="tw-mr-2"
                    >
                        <StaffAvatar
                            class="tw-w-6"
                            :staff-model="option"
                        />
                    </div>
                    <div
                        class="tw-truncate"
                        v-html="highlight(searchValue, option.fullname)"
                    />
                    <CText
                        v-if="userStaff && option.id === userStaff.getKey()"
                        class="tw-ml-1"
                        font-weight="bold"
                    >
                        ({{ __('common:myself') }})
                    </CText>
                </div>
            </slot>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import moment from 'moment';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useManager from '@/modules/app/composables/useManager';
    import ContractModel from '@/modules/human-resources/models/ContractModel';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import type {StaffFinderOptionType} from '@/modules/request/components/Teams/StaffFinderOptionType';

    export default defineComponent({
        components: {StaffAvatar, ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            modelValue: {
                type: [Object, Array] as PropType<Model | Model[] | StaffFinderOptionType | StaffFinderOptionType[]>,
            },
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            optionsCallback: {
                type: Function as PropType<(models: Model[] | StaffFinderOptionType[]) => any[]>,
                default: undefined,
                required: false,
            },
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            wrapperClass: {},
            buttonClass: {},
            buttonSize: {},
            buttonVariant: {},
            onlyUserOrganizations: {type: Boolean, default: false},
            allowedOrganizations: {type: Array as PropType<OrganizationModel[]>, default: undefined},
            hasActiveContract: {type: Boolean, default: true},
            excludedIds: {
                type: Array as PropType<string[]>,
            },
            hideSelectedOption: {type: Boolean, default: false},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            withRelatedUserCount: {type: Boolean as PropType<boolean>, default: false},
            userStaff: {type: Object as PropType<StaffModel>, default: undefined},
            buttonDisabled: {type: Boolean as PropType<boolean>, default: false},
            wrapper: {type: String as PropType<'MPopover2' | 'ResourceFinderWrapper'>, default: 'MPopover2'},
            registration: {type: RegistrationModel, default: undefined},
        },
        emits: ['update:modelValue', 'fallback'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function (value) {
                emit('update:modelValue', value);
            };
            // !!! END: DO NOT EDIT !!
            const resourceFinderRef = ref();
            const {activeOrganization} = useManager();

            // *** Query filters ***
            const applySearchFilter = (builder: QueryBuilder<StaffModel>) => {
                builder.where(query => {
                    query.whereLike('last_name', `*${search.value}*`);
                    query.orWhereLike('first_name', `*${search.value}*`);
                });
            };

            const applyRegistrationFilter = (builder: QueryBuilder<StaffModel>) => {
                const from = moment().startOf('day').format('YYYY-MM-DD');
                const organizationIds = props.registration
                    .organizations()
                    .value()
                    .map(org => org.getKey());
                builder.whereHas(new StaffModel().contracts(), query => {
                    return query
                        .tap(query1 => {
                            new ContractModel().scopeActive(query1, [from, null, organizationIds]);
                        })
                        .orWhere(query1 =>
                            query1.tap(query2 => {
                                new ContractModel().scopeComing(query2, from);
                            })
                        );
                });
            };

            const applyActiveContractFilter = (builder: QueryBuilder<StaffModel>) => {
                const from = moment().startOf('day').format('YYYY-MM-DD');
                builder.whereHas(new StaffModel().contracts(), query => {
                    return query
                        .tap(query1 => {
                            new ContractModel().scopeActive(query1, [
                                from,
                                null,
                                [activeOrganization.value.attributes.id],
                            ]);
                        })
                        .orWhere(query1 =>
                            query1.tap(query2 => {
                                new ContractModel().scopeComing(query2, from);
                            })
                        );
                });
            };

            const applyMutuallyExclusiveFilters = (builder: QueryBuilder<StaffModel>) => {
                if (search.value) {
                    applySearchFilter(builder);

                    return;
                }

                if (props.registration) {
                    applyRegistrationFilter(builder);

                    return;
                }

                if (props.hasActiveContract) {
                    applyActiveContractFilter(builder);
                }
            };

            const applyExclusionFilter = (builder: QueryBuilder<StaffModel>) => {
                if (props.excludedIds?.length) {
                    builder.whereNotIn('id', props.excludedIds);
                }
            };

            const applyUserOrganizationsFilter = (builder: QueryBuilder<StaffModel>) => {
                if (props.onlyUserOrganizations) {
                    builder.whereHas(new StaffModel().organizations(), query => {
                        return query.tap(query1 => {
                            OrganizationModel.belongingToAuthUserScope(query1);
                        });
                    });
                }
            };

            const applyAllowedOrganizationsFilter = (builder: QueryBuilder<StaffModel>) => {
                if (props.allowedOrganizations) {
                    builder.whereHas(new StaffModel().organizations(), query => {
                        return query.whereIn(
                            'id',
                            props.allowedOrganizations.map(org => org.getKey())
                        );
                    });
                }
            };

            const applyRelatedUserCount = (builder: QueryBuilder<StaffModel>) => {
                if (props.withRelatedUserCount) {
                    builder.withCount('user');
                }
            };

            const applyUserStaffFilter = (builder: QueryBuilder<StaffModel>) => {
                if (props.userStaff) {
                    builder.where('id', '!=', props.userStaff.getKey());
                }
            };

            const applyInjectQueryFilter = (builder: QueryBuilder<StaffModel>) => {
                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }
            };

            const queryBuilder = computed(() => {
                const builder = StaffModel.query().orderBy('last_name');

                applyMutuallyExclusiveFilters(builder);

                applyExclusionFilter(builder);
                applyUserOrganizationsFilter(builder);
                applyAllowedOrganizationsFilter(builder);
                applyRelatedUserCount(builder);
                applyUserStaffFilter(builder);
                applyInjectQueryFilter(builder);

                return builder;
            });

            const staffModelClass = computed(() => {
                return StaffModel;
            });

            const onFallback = function () {
                emit('fallback');
            };

            /**
             * Callback to add the user staff to the options and forward the optionsCallback
             * function to the ResourceFinder component
             * @param models
             */
            function staffFinderOptionsCallback(models: any[]) {
                if (props.userStaff) {
                    models.unshift(props.userStaff);
                }

                return props.optionsCallback ? props.optionsCallback(models) : models;
            }

            return {
                resourceFinderRef,
                search,
                onChange,
                onFallback,
                queryBuilder,
                staffModelClass,
                staffFinderOptionsCallback,
                ...useHighlighter(),
            };
        },
    });
</script>
