<template>
    <transition
        appear
        name="bounce"
    >
        <div
            v-if="visible"
            class="MNotificationBadge"
        >
            {{ count }}
        </div>
    </transition>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from 'vue';

    export default defineComponent({
        props: {
            count: {
                type: Number,
                required: true,
            },
            animationRandomDelay: {
                type: Number,
                default: 300,
            },
        },
        setup(props) {
            const visible = ref(false);

            // Unused, can be reactivated if needed
            const tasksCount = computed(() => {
                return props.count > 99 ? '99+' : props.count;
            });

            onMounted(() => {
                if (props.animationRandomDelay) {
                    setTimeout(
                        () => {
                            visible.value = true;
                        },
                        Math.floor(Math.random() * props.animationRandomDelay)
                    );
                } else {
                    visible.value = true;
                }
            });

            return {
                tasksCount,
                visible,
            };
        },
    });
</script>

<style scoped>
    .MNotificationBadge {
        @apply tw-min-w-4 tw-px-1;
        @apply tw-rounded-full tw-bg-red-500 tw-shadow-md;
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-py-px tw-font-display tw-text-xs tw-font-normal tw-text-white;
    }

    .bounce-enter-active {
        animation: bounce ease-in-out 500ms;
    }

    @keyframes bounce {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
