import {Binding} from '@meekohq/lumos';

import type {ApiResponseOutputPort} from '@/modules/core/domain/ports/ApiResponseOutputPort';
import type {ReportingSettingEntity} from '@/modules/meeko-family/domain/entities/ReportingSettingEntity';

export const StoreReportingSettingsInputPortBinding = new Binding<
    StoreReportingSettingsInputPort,
    ApiResponseOutputPort
>();
export interface StoreReportingSettingsInputPort {
    execute(organizationId: string, settings: ReportingSettingEntity[]): Promise<unknown>;
}
