<script setup lang="ts">
    import {uniq} from 'lodash-es';
    import {computed, onBeforeMount, ref} from 'vue';

    import useError from '@/modules/app/composables/useError';
    import KidCard from '@/modules/family/components/kid/KidCard.vue';
    import KidModel from '@/modules/family/models/KidModel';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    const props = defineProps<{
        modelValue?: OrganizationModel;
        kidModel: KidModel;
        errors: ReturnType<useError>;
    }>();

    const emit = defineEmits<{
        (e: 'update:model-value', value: OrganizationModel): void;
    }>();

    const excludedOrganizationsIds = ref<string[]>([]);

    const organizationQuery = computed(() => {
        return OrganizationModel.query().whereNotIn('id', excludedOrganizationsIds.value);
    });

    onBeforeMount(async () => {
        await fillExcludedOrganizations();
    });

    async function fillExcludedOrganizations() {
        const kidModel = await KidModel.query()
            .with(new KidModel().originalKid(), query => {
                query.with(new KidModel().transferredKids());
            })
            .with(new KidModel().transferredKids())
            .find(props.kidModel.getKey());

        const originalKid = kidModel.originalKid().value();

        const otherOrganizations = [kidModel.attributes.organization_id as string];

        kidModel
            .transferredKids()
            .value()
            .each((kid: KidModel) => {
                otherOrganizations.push(kid.attributes.organization_id as string);
            });

        if (originalKid) {
            otherOrganizations.push(originalKid.attributes.organization_id as string);

            originalKid
                .transferredKids()
                .value()
                .each((kid: KidModel) => {
                    otherOrganizations.push(kid.attributes.organization_id as string);
                });
        }

        excludedOrganizationsIds.value = uniq(otherOrganizations);
    }
</script>

<template>
    <div class="CopyKidForm">
        <div class="CopyKidForm__container">
            <MLabel class="CopyKidForm__label">
                {{ __('family_kid:child_to_copy_colon') }}
            </MLabel>
            <KidCard :kid-model="kidModel" />
            <CFormErrorMessageList :errors="errors.get('kid_id')" />
        </div>
        <div class="CopyKidForm__icon-container">
            <FontAwesomeIcon
                class="CopyKidForm__icon"
                icon="fa-duotone fa-copy"
            />
        </div>
        <div class="CopyKidForm__container">
            <MLabel class="CopyKidForm__label">
                {{ __('family_kid:organization_where_the_child_will_have_a_copy_colon') }}
            </MLabel>
            <div class="CopyKidForm__card">
                <OrganizationFinder
                    :model-value="modelValue"
                    :inject-query="organizationQuery"
                    class="tw-w-full"
                    button-class="tw-w-full"
                    @update:model-value="emit('update:model-value', $event)"
                />
                <CFormErrorMessageList :errors="errors.get('organization_id')" />
            </div>
        </div>
    </div>
</template>

<style scoped>
    .CopyKidForm {
        @apply tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row;
    }

    .CopyKidForm__container {
        @apply tw-w-full sm:tw-w-5/12;
    }

    .CopyKidForm__label {
        @apply tw-mb-2;
    }

    .CopyKidForm__icon-container {
        @apply tw-flex tw-w-full tw-flex-wrap tw-justify-center sm:tw-mt-5 sm:tw-w-2/12;
    }

    .CopyKidForm__icon {
        @apply tw-self-center tw-text-5xl tw-text-primary-950;
    }

    .CopyKidForm__card {
        @apply tw-flex tw-items-center;
        @apply tw-h-16 tw-rounded tw-p-3;
        @apply tw-border tw-border-gray-300;
    }
</style>
