import {merge} from 'lodash-es';
import type {ComputedRef, Ref} from 'vue';

import type {FilterOptions, FilterType} from '@/modules/app/composables/useMagicFilter';
import useMagicFilter, {setupFilter} from '@/modules/app/composables/useMagicFilter';
import BooleanSerializer from '@/modules/magic-index/utils/serializers/BooleanSerializer';
import ModelSerializer from '@/modules/magic-index/utils/serializers/ModelSerializer';
import NumberSerializer from '@/modules/magic-index/utils/serializers/NumberSerializer';
import {StoragePath} from '@/modules/magic-index/utils/serializers/StoragePath';
import type TagModel from '@/modules/tag/models/TagModel';

type Filters = 'search' | 'tags' | 'withoutTag' | 'page';

export interface OutputType {
    searchFilter: FilterType<string>;
    tagsFilter: FilterType<TagModel[]>;
    withoutTagFilter: FilterType<boolean>;
    pageFilter: FilterType<number>;
    activeCount: Ref<number>;
    isReady: ComputedRef<boolean>;
    reset: () => void;
    allFilters: Ref<any[]>;
}

export default function useDocumentsFiltersStore(options: Record<Filters, FilterOptions>): OutputType {
    const defaultOptions: Record<Filters, FilterOptions> = {
        search: {
            defaultValue: '',
            debounceDelay: 500,
        },
        tags: {
            defaultValue: [],
            serializer: ModelSerializer,
            storagePath: StoragePath.filtersDocumentsIndexTags,
        },
        withoutTag: {
            defaultValue: false,
            serializer: BooleanSerializer,
            storagePath: StoragePath.filtersDocumentsIndexWithoutTag,
        },
        page: {
            defaultValue: 1,
            serializer: NumberSerializer,
            queryString: 'p',
        },
    };

    const optionsWithDefaults = merge({}, defaultOptions, options);

    const searchFilter = setupFilter<string>(optionsWithDefaults.search);
    const tagsFilter = setupFilter<TagModel[]>(optionsWithDefaults.tags);
    const withoutTagFilter = setupFilter<boolean>(optionsWithDefaults.withoutTag);
    const pageFilter = setupFilter<number>(optionsWithDefaults.page);

    const {activeCount, isReady, reset, allFilters} = useMagicFilter(
        [searchFilter, tagsFilter, withoutTagFilter],
        pageFilter
    );

    return {
        searchFilter,
        tagsFilter,
        withoutTagFilter,
        pageFilter,
        activeCount,
        isReady,
        reset,
        allFilters,
    };
}
