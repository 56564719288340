import {MqlOperation} from '@meekohq/lumos';

import type {KidCopyRepositoryPort} from '@/modules/family/domain/ports/KidCopyRepositoryPort';

export class KidCopyRepositoryAdapter implements KidCopyRepositoryPort {
    async copyKid(kidId: string, organizationId: string): Promise<string> {
        const operation = new MqlOperation('family/kids/copy', {
            kid_id: kidId,
            destination_organization_id: organizationId,
        });

        const response = await operation.run();

        return response.content.kid_id;
    }
}
