import __ from '@/modules/app/utils/i18n-facade';
import GenderValue from '@/modules/family/utils/member/GenderValue';
import type MemberRoleValue from '@/modules/family/utils/member/MemberRoleValue';

export function useMemberRoleTranslations() {
    function getRoleOptionsByGender(familyMemberGender: GenderValue) {
        if (familyMemberGender === GenderValue.female) {
            return [
                {text: __('common:relationship.parent_female'), value: 'mother'},
                {text: __('common:relationship.sibling_female'), value: 'sister'},
                {text: __('common:relationship.pibling_female'), value: 'aunt'},
                {text: __('common:relationship.grandparent'), value: 'grandparent'},
                {text: __('common:relationship.nanny'), value: 'nanny'},
                {text: __('common:relationship.parent_in_law_female'), value: 'stepmother'},
                {text: __('common:relationship.cousin_female'), value: 'cousin'},
                {text: __('common:relationship.legal_custodian_female'), value: 'legal_custodian_female'},
                {text: __('common:other_one'), value: 'other'},
            ];
        } else {
            return [
                {text: __('common:relationship.parent_male'), value: 'father'},
                {text: __('common:relationship.sibling_male'), value: 'brother'},
                {text: __('common:relationship.pibling_male'), value: 'uncle'},
                {text: __('common:relationship.grandparent'), value: 'grandparent'},
                {text: __('common:relationship.nanny'), value: 'nanny'},
                {text: __('common:relationship.parent_in_law_male'), value: 'stepfather'},
                {text: __('common:relationship.cousin_male'), value: 'cousin'},
                {text: __('common:relationship.legal_custodian_male'), value: 'legal_custodian_male'},
                {text: __('common:other_one'), value: 'other'},
            ];
        }
    }

    function getTranslationByRoleAndGender(role?: MemberRoleValue, gender?: GenderValue) {
        if (!role || !gender) {
            return __('common:other_one');
        }

        switch (role) {
            case 'legal_custodian_female':
            case 'legal_custodian_male':
                return __('common:relationship.legal_custodian', {context: gender});
            case 'mother':
            case 'father':
                return __('common:relationship.parent', {context: gender});
            case 'sister':
            case 'brother':
                return __('common:relationship.sibling', {context: gender});
            case 'aunt':
            case 'uncle':
                return __('common:relationship.pibling', {context: gender});
            case 'grandparent':
                return __('common:relationship.grandparent');
            case 'nanny':
                return __('common:relationship.nanny');
            case 'stepmother':
            case 'stepfather':
                return __('common:relationship.parent_in_law', {context: gender});
            case 'cousin':
                return __('common:relationship.cousin', {context: gender});
            default:
                return __('common:other_one');
        }
    }

    return {getRoleOptionsByGender, getTranslationByRoleAndGender};
}
