<template>
    <div>
        <div>
            <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                <LegacyMemberAvatar
                    class="tw-w-16"
                    :member="familyMember"
                />
                <div class="tw-flex tw-flex-col tw-gap-2">
                    <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                        <CText
                            class="tw-font-grandhotel !tw-leading-none"
                            font-size="4xl"
                            :variant="familyMember.gender === 'male' ? 'boy' : 'girl'"
                        >
                            {{ $fullName(familyMember) }}
                        </CText>
                        <MButton
                            v-if="$can('update', 'family_members_details')"
                            :label="__('common:actions.update')"
                            variant="primary-alt"
                            @click="showEditFamilyMemberModal"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-pencil" />
                            </template>
                        </MButton>
                    </div>
                    <CText
                        font-size="sm"
                        variant="gray"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1 tw-text-primary-500"
                            icon="fa-solid fa-map-marker"
                        />
                        {{ familyMember.city }}
                    </CText>
                </div>
            </div>
        </div>
        <MInformationsLine
            :informations="[
                {
                    icon: 'fa-solid fa-phone',
                    text: familyMember.mobile_phone ? familyMember.mobile_phone : familyMember.phone,
                },
                {
                    icon: 'fa-solid fa-at',
                    text: familyMember.email,
                },
            ]"
            class="tw-my-6"
        />
        <module-kid
            v-if="$can('read', 'kids_details')"
            :family-member="familyMember"
            :nursery="nursery"
        />
    </div>
</template>

<script>
    import moment from 'moment';

    import LegacyMemberAvatar from '@/modules/app/components/atoms/avatars/LegacyMemberAvatar.vue';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import EditFamilyMemberModal from '@/modules/family/components/kid/Kid/FamilyMember/organisms/EditFamilyMemberModal.vue';
    import MInformationsLine from '@/modules/meeko-ui/components/MInformationsLine.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    import Kids from './Kids.vue';

    export default {
        components: {
            MInformationsLine,
            LegacyMemberAvatar,
            'module-kid': Kids,
        },
        props: ['familyMember', 'nursery'],
        emits: ['update:familyMember'],
        data: () => ({
            editedFamilyMember: {},
            loader: false,
        }),
        computed: {
            moment() {
                return moment;
            },
        },
        watch: {
            'familyMember.id': function () {
                this.editedFamilyMember = Object.assign({}, this.familyMember);
            },
        },
        mounted() {
            this.editedFamilyMember = Object.assign({}, this.familyMember);
        },
        methods: {
            showEditFamilyMemberModal() {
                const editFamilyMemberModal = useModal({
                    component: EditFamilyMemberModal,
                    props: {
                        nursery: this.nursery,
                        familyMember: this.editedFamilyMember,
                    },
                    listeners: modal => ({
                        update: familyMember => {
                            this.update(familyMember);
                            modal.hide();
                        },
                        delete: () => {
                            this.remove();
                            modal.hide();
                        },
                        detach: () => {
                            this.detachFamilyMember();
                            modal.hide();
                        },
                    }),
                });
                editFamilyMemberModal.show();
            },

            formatDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },
            getAge(birthdate) {
                const age = this.moment().diff(this.moment(birthdate), 'years');
                if (age <= 1) {
                    return this.moment().diff(this.moment(birthdate), 'months') + ' mois';
                }

                return age + ' ans';
            },
            update(familyMember) {
                this.$emit('update:familyMember', familyMember);
            },
            remove() {
                useNotification().success(__('family_member:parent_deleted_successfully'));
                this.$router.push({name: 'familyMembers.index', params: {nursery: this.nursery.id}});
            },
        },
    };
</script>
