<template>
    <label
        class="tw-m-0 tw-text-sm tw-font-semibold tw-uppercase tw-leading-5"
        :rounded="true"
        size="sm"
    >
        <slot />
    </label>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>

<style scoped></style>
