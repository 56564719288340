<template>
    <MagicIndex
        v-if="storeIsReady"
        :filter-count="activeCount"
        :loading="isLoading"
        :paginator="paginator"
        variant="family"
        @reset-filters="resetFilters"
    >
        <template #filters>
            <MagicIndexSearchInputFilter
                v-model="search.data.value"
                :placeholder="__('document:search_document_dots')"
            />
            <TagsResourceFinder
                v-model="tagsFilter.data.value"
                button-size="sm"
                has-unselect
                :inject-query="tagFinderQuery"
                multi
                only-active-organization
                :search-bar="false"
                :without-tags-option-is-selected="withoutTagFilter.data.value"
                @without-tags-option-is-selected="withoutTagFilter.data.value = $event"
            />
        </template>
        <template #content="{hasResults}">
            <div class="DocumentList__content">
                <transition
                    mode="out-in"
                    name="transition-fade"
                >
                    <MagicIndexLoader
                        v-if="isLoading"
                        key="loader"
                    />
                    <div
                        v-else-if="hasResults"
                        class="DocumentList__list"
                    >
                        <template
                            v-for="(item, index) in paginator?.items()"
                            :key="index"
                        >
                            <DocumentItem
                                :document="item"
                                :organization="activeOrganization"
                                :permission-key="permissionKey"
                                :show-organizations="showOrganizations"
                                :show-visibilities="showVisibilities"
                                :user="user"
                                @update="editItemFn($event)"
                            />
                        </template>
                    </div>
                    <MagicIndexEmptyList v-else>
                        <template #empty-list>
                            {{ __('common:empty_result') }}
                        </template>
                        <template #empty-list-action>
                            {{ __('common:try_modifying_filters_or_adding_data') }}
                        </template>
                    </MagicIndexEmptyList>
                </transition>
                <MagicIndexPaginator
                    v-if="paginator"
                    :paginator="paginator"
                    @change="updatePagination($event)"
                />
            </div>
        </template>
    </MagicIndex>
</template>

<script lang="ts">
    import type {Model} from '@meekohq/lumos';
    import {collect, type Collection} from '@meekohq/lumos';
    import {until} from '@vueuse/core/index';
    import type {Emitter} from 'mitt';
    import mitt from 'mitt';
    import {defineComponent, onBeforeMount, type PropType, type Ref, ref, watch} from 'vue';
    import {useRoute} from 'vue-router';

    import useAbility from '@/modules/app/composables/useAbility';
    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import DocumentItem from '@/modules/document/components/molecules/DocumentListItem.vue';
    import useDocumentsFiltersStore from '@/modules/document/composables/useDocumentsFiltersStore';
    import useFetchDocumentAction from '@/modules/document/composables/useFetchDocumentAction';
    import type DocumentModel from '@/modules/document/models/DocumentModel';
    import type {VisibilitiesType} from '@/modules/document/stores/useDocumentStore';
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';
    import MagicIndexLoader from '@/modules/magic-index/components/atoms/MagicIndexLoader.vue';
    import MagicIndexSearchInputFilter from '@/modules/magic-index/components/atoms/MagicIndexSearchInputFilter.vue';
    import MagicIndexPaginator from '@/modules/magic-index/components/molecules/MagicIndexPaginator.vue';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import TagModel from '@/modules/tag/models/TagModel';

    export interface BusType {
        documentCreated: DocumentModel;
        documentUpdated: DocumentModel;
        documentDeleted: DocumentModel;
    }

    export default defineComponent({
        components: {
            TagsResourceFinder,
            MagicIndexSearchInputFilter,
            MagicIndexPaginator,
            MagicIndexLoader,
            MagicIndexEmptyList,
            MagicIndex,
            DocumentItem,
        },
        props: {
            relatedResource: {
                type: Object as PropType<Model>,
                default: undefined,
            },
            permissionKey: {
                type: String,
                default: 'documents',
            },
            queryStringParam: {
                type: String,
                default: 'p',
            },
            bus: {
                type: Object as PropType<Emitter<BusType>>,
                default: () => mitt(),
            },
            editItemFn: {
                type: Function,
                required: true,
            },
            showVisibilities: {
                type: Object as PropType<VisibilitiesType>,
                required: false,
                default: () => ({
                    staffs_access: false,
                    parents_access: false,
                    web_access: false,
                }),
            },
            showOrganizations: {
                type: Boolean,
                default: false,
            },
            organizationToConstraintTo: {
                type: Array,
                default: () => [useManager().activeOrganization.value],
            },
        },
        setup(props) {
            const {user} = useAuth();
            const {activeOrganization} = useManager();

            const route = useRoute();

            const {can} = useAbility();

            const filtersStore = useDocumentsFiltersStore({
                search: {},
                tags: {},
                withoutTag: {},
                page: {},
            });

            const {
                searchFilter: search,
                reset: resetFilters,
                tagsFilter,
                withoutTagFilter,
                activeCount,
                pageFilter,
            } = filtersStore;

            const tagFinderQuery = TagModel.query().where('type', 'document');

            const showModal = ref(false);

            const documentableTypeToConstrainTo = ref(props.relatedResource?.getType());
            const documentableIdToConstrainTo = ref(props.relatedResource?.getKey());

            const {debounceGetDocuments, paginator, isLoading, initWatch, updatePagination} = useFetchDocumentAction(
                {
                    organizationsToConstrainTo: ref(collect(props.organizationToConstraintTo)) as Ref<
                        Collection<OrganizationModel>
                    >,
                    documentableTypeToConstrainTo,
                    documentableIdToConstrainTo,
                    search: search.data,
                    tagsToConstrainTo: tagsFilter.data as Ref<TagModel[]>,
                    withoutTag: withoutTagFilter.data,
                    permissionKey: props.permissionKey,
                },
                pageFilter.data
            );

            // If the related resource changes, update the documentableTypeToConstrainTo and documentableIdToConstrainTo.
            // This will trigger a new fetch of documents.
            watch(
                () => props.relatedResource,
                () => {
                    documentableTypeToConstrainTo.value = props.relatedResource?.getType();
                    documentableIdToConstrainTo.value = props.relatedResource?.getKey();
                }
            );

            until(filtersStore.isReady)
                .toBe(true)
                .then(async () => {
                    initWatch();

                    await debounceGetDocuments();
                });

            onBeforeMount(async () => {
                isLoading.value = true;
            });

            props.bus.on('documentCreated', async (document: DocumentModel) => {
                const documentIsInOrganization = document
                    .organizations()
                    .value()
                    .contains(organization => {
                        return organization.getKey() === activeOrganization.value.getKey();
                    });

                if (!documentIsInOrganization) {
                    return;
                }

                // If the current page is not the first page or in the current organization, then we need to fetch the documents again.
                if (paginator.value?.currentPage() !== 1) {
                    await debounceGetDocuments();
                } else {
                    // We add the document to the paginator items.
                    paginator.value?.items().prepend(document);

                    // If the number of items is greater than the per page, then we need to remove the last item.
                    if (paginator.value?.items().count() > paginator.value?.perPage()) {
                        paginator.value?.items().pop();
                    }
                }
            });

            props.bus.on('documentUpdated', async (document: DocumentModel) => {
                const documentIsInOrganization = document
                    .organizations()
                    .value()
                    .contains(organization => {
                        return organization.getKey() === activeOrganization.value.getKey();
                    });

                if (!documentIsInOrganization) {
                    const documentIndex = paginator.value?.items().search((item: DocumentModel) => {
                        return item.getKey() === document.getKey();
                    });

                    // Mutate the paginator items to remove the document.
                    paginator.value?.items().forget(documentIndex);
                }

                const localDocument = paginator.value?.items().first(item => {
                    return item.getKey() === document.getKey();
                });

                if (localDocument) {
                    // We refresh the tags before copying the updated document so as not to lose the tags
                    await document.tags().fresh();
                    document.copyTo(localDocument);
                }
            });

            props.bus.on('documentDeleted', async (document: DocumentModel) => {
                const documentIndex = paginator.value?.items().search((item: DocumentModel) => {
                    return item.getKey() === document.getKey();
                });

                // Mutate the paginator items to remove the document.
                paginator.value?.items().forget(documentIndex);
            });

            return {
                user,
                route,
                activeOrganization,
                search,
                tagsFilter,
                withoutTagFilter,
                tagFinderQuery,
                activeCount,
                paginator,
                isLoading,
                showModal,
                storeIsReady: filtersStore.isReady,
                can,
                updatePagination,
                resetFilters,
            };
        },
    });
</script>

<style scoped>
    .DocumentList__content {
        @apply tw-flex tw-w-full tw-flex-col tw-gap-2 tw-p-4;
    }

    .DocumentList__list {
        @apply tw-w-full;
        @apply tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4;
        @apply tw-gap-4;
    }
</style>
