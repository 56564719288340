<script setup lang="ts">
    import type {PropType} from 'vue';

    import {DevicePolicy} from '@/modules/organization/utils/DevicePolicy';

    const props = defineProps({
        modelValue: {
            type: Array as PropType<DevicePolicy[]>,
            required: true,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    function deviceHasPolicy(policy: DevicePolicy) {
        return props.modelValue.includes(policy);
    }

    function toggleDevicePolicy(policy: DevicePolicy, checked: boolean) {
        if (checked && !deviceHasPolicy(policy)) {
            emit('update:modelValue', [...props.modelValue, policy]);
        } else if (!checked && deviceHasPolicy(policy)) {
            emit(
                'update:modelValue',
                props.modelValue.filter(item => item !== policy)
            );
        } else {
            throw new Error('Toggle device policy error');
        }
    }
</script>

<template>
    <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
        <div>
            <MLabel>{{ __('common:kid_other') }}</MLabel>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.kidAttendances)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.kidAttendances, $event)"
                >
                    {{ __('common:clocking_in_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset class="tw-ml-6">
                <MTooltip
                    :label="__('organization:code_required_to_clock_children')"
                    placement="right"
                >
                    <CCheckbox
                        :model-value="deviceHasPolicy(DevicePolicy.kidKiosk)"
                        @update:model-value="toggleDevicePolicy(DevicePolicy.kidKiosk, $event)"
                    >
                        {{ __('organization:ask_parent_code') }}
                    </CCheckbox>
                </MTooltip>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.meals)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.meals, $event)"
                >
                    {{ __('common:meal') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.activities)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.activities, $event)"
                >
                    {{ __('common:activity_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.naps)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.naps, $event)"
                >
                    {{ __('common:naps') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.health)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.health, $event)"
                >
                    {{ __('common:health') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.diapers)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.diapers, $event)"
                >
                    {{ __('common:diaper') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.photos)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.photos, $event)"
                >
                    {{ __('common:photos') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.observations)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.observations, $event)"
                >
                    {{ __('common:observation_other') }}
                </CCheckbox>
            </MFieldset>
        </div>
        <div>
            <MLabel>{{ __('common:team_other') }}</MLabel>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.staffAttendances)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.staffAttendances, $event)"
                >
                    {{ __('common:clocking_in_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.staffKiosk)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.staffKiosk, $event)"
                >
                    {{ __('organization:locked_clocking_in') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.rooms)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.rooms, $event)"
                >
                    {{ __('common:room_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.equipment)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.equipment, $event)"
                >
                    {{ __('common:equipment_one') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.news)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.news, $event)"
                >
                    {{ __('common:news') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.documents)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.documents, $event)"
                >
                    {{ __('common:document_other') }}
                </CCheckbox>
            </MFieldset>
            <MFieldset>
                <CCheckbox
                    :model-value="deviceHasPolicy(DevicePolicy.staffNotes)"
                    @update:model-value="toggleDevicePolicy(DevicePolicy.staffNotes, $event)"
                >
                    {{ __('organization:teams_notes') }}
                </CCheckbox>
            </MFieldset>
        </div>
    </div>
</template>
