import {JsonAPIPaginator} from '@meekohq/lumos';
import {getCurrentInstance, ref, watch} from 'vue';

export default function (fetchData: Function | null, saveCurrentPage = true) {
    const vm = getCurrentInstance()?.proxy;

    const paginator = ref<JsonAPIPaginator | null>(null);
    const currentPage = ref(Number(vm?.$route.query.page) || 1);

    watch(
        () => vm?.$route.query.page,
        val => {
            if (saveCurrentPage) {
                currentPage.value = Number(val) || 1;
            }
        }
    );

    watch(currentPage, val => {
        if (saveCurrentPage && vm?.$route.query.page !== `${val}`) {
            vm?.$router.push({query: {page: `${val}`}});
        }
        fetchData ? fetchData(val) : null;
    });

    function setPaginator(meta) {
        paginator.value = new JsonAPIPaginator(meta);
    }

    return {
        paginator,
        currentPage,
        setPaginator,
    };
}
