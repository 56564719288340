<template>
    <MForm @submit.prevent="save">
        <MFieldset>
            <MLabel>{{ __('common:name') }}</MLabel>
            <CInput
                v-model="simulationList.attributes.name"
                :placeholder="__('planning:back_to_nursery_with_years', {currentYear, nextYear})"
            />
            <CFormErrorMessageList :errors="simulationListErrors.get('name')" />
        </MFieldset>
        <MFieldset>
            <MLabel>{{ __('planning:period') }}</MLabel>
            <CFormTwoColumns>
                <MFieldset>
                    <CFormDatepicker v-model="simulationList.attributes.period.started_at" />
                </MFieldset>
                <MFieldset>
                    <CFormDatepicker v-model="simulationList.attributes.period.ended_at" />
                </MFieldset>
            </CFormTwoColumns>
            <CFormErrorMessageList :errors="simulationListErrors.get('period.started_at')" />
            <CFormErrorMessageList :errors="simulationListErrors.get('period.ended_at')" />
        </MFieldset>
    </MForm>
</template>

<script lang="ts">
    import {ValidationError} from '@meekohq/lumos';
    import moment from 'moment/moment';
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import useError from '@/modules/app/composables/useError';
    import __ from '@/modules/app/utils/i18n-facade';
    import type SimulationListModel from '@/modules/planning/models/SimulationListModel';

    export default defineComponent({
        components: {},
        props: {
            simulationList: {
                type: Object as PropType<SimulationListModel>,
                required: true,
            },
        },
        emits: ['loading', 'saved'],
        setup(props, {emit}) {
            const simulationListErrors = useError({
                'period.ended_at': {
                    '0x2EDA21274D': () => __('planning:errors.list_dates_inversed'),
                },
            });

            const currentYear = moment().year();
            const nextYear = moment().add(1, 'year').year();

            async function save() {
                try {
                    emit('loading', true);
                    simulationListErrors.reset();

                    const list = await props.simulationList.save();

                    emit('saved', list);
                } catch (e) {
                    if (e instanceof ValidationError) {
                        simulationListErrors.addValidationError(e);
                    }
                } finally {
                    emit('loading', false);
                }
            }

            return {
                simulationListErrors,
                save,
                currentYear,
                nextYear,
            };
        },
    });
</script>
