<template>
    <CModal
        ref="modal"
        size="6xl"
        :visible="true"
        @hidden="onHidden()"
    >
        <template #header>
            <CText
                font-size="lg"
                font-weight="semibold"
            >
                <FontAwesomeIcon
                    v-if="!kid && selectedKid"
                    class="tw-mr-2 tw-cursor-pointer tw-text-blue-500"
                    icon="fa-solid fa-arrow-left"
                    @click="selectedKid = null"
                />
                {{
                    selectedKid
                        ? `${__('planning:add_multiple_plannings')} - ${$fullName(selectedKid)}`
                        : __('planning:add_multiple_plannings')
                }}
            </CText>
        </template>
        <div v-if="!selectedKid">
            <div
                v-if="kidLoading"
                class="tw-rounded-lg tw-bg-blue-100 tw-p-5 tw-text-center tw-text-blue-600"
            >
                <FontAwesomeIcon
                    class="tw-mr-2 tw-text-lg"
                    icon="fa-solid fa-spinner"
                    spin
                />
                {{ __('common:loading_dots') }}
            </div>
            <SelectKidGrid
                v-else
                @change="onKidChanged($event)"
            />
        </div>
        <div v-else>
            <div class="tw-flex tw-flex-wrap">
                <div class="tw-mb-2 tw-w-full lg:tw-w-1/3 lg:tw-pr-2">
                    <div class="tw-flex-1 tw-rounded-lg tw-border tw-border-gray-200 tw-p-3 tw-shadow-sm">
                        <MDatePeriodFilter v-model="periodFilter" />

                        <CText
                            v-if="!activeContracts.length"
                            as="div"
                            class="tw-mt-4"
                        >
                            {{ __('planning:no_contract_found_over_period') }}
                        </CText>
                        <div
                            v-else
                            class="tw-mt-4"
                        >
                            <div
                                v-for="(contract, i) in activeContracts"
                                :key="'contract' + i"
                                class="tw-mt-4"
                            >
                                <CText
                                    as="div"
                                    class="tw-tracking-wider tw-text-gray-700"
                                    font-size="sm"
                                    font-weight="semibold"
                                    uppercase
                                >
                                    {{ getContractTitle(contract) }}
                                </CText>
                                <CText
                                    v-if="contract.config && contract.config.billing.qty"
                                    as="div"
                                    class="tw-mt-1 tw-text-gray-600"
                                >
                                    {{ __('planning:billing_quantity_per_month_colon') }}
                                    <CBadge
                                        size="sm"
                                        variant="primary"
                                    >
                                        <template v-if="contract.config.billing.cycle === 'halfday'">
                                            {{
                                                __('common:halfday_alternative_long', {
                                                    count: contract.config.billing.qty,
                                                })
                                            }}
                                        </template>
                                        <template v-else-if="contract.config.billing.cycle === 'day'">
                                            {{ __('common:day_with_count', {count: contract.config.billing.qty}) }}
                                        </template>
                                        <template v-else-if="contract.config.billing.cycle === 'hour'">
                                            {{ __('common:hour_with_count', {count: contract.config.billing.qty}) }}
                                        </template>
                                        <template v-else>
                                            {{ __('common:month_with_count', {count: contract.config.billing.qty}) }}
                                        </template>
                                    </CBadge>
                                </CText>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tw-mb-2 tw-w-full lg:tw-w-2/3 lg:tw-pl-2">
                    <AddMultiplePlanningsTotals
                        v-model:display-preference="displayPreference"
                        class="tw-flex-1 tw-rounded-lg tw-border tw-border-gray-200 tw-p-3 tw-shadow-sm"
                        :kid="selectedKid"
                        :period-filter="periodFilter"
                        :plannings="plannings"
                    />
                </div>
            </div>

            <div
                v-if="plannings.length"
                class="tw-mt-4 tw-hidden tw-pl-2 md:tw-flex"
            >
                <div class="tw-w-1/6">
                    <CText font-weight="semibold">
                        {{ __('common:type') }}
                    </CText>
                </div>
                <div class="tw-w-1/6">
                    <CText font-weight="semibold">
                        {{ __('common:day_one') }}
                    </CText>
                </div>
                <div class="tw-w-1/6">
                    <CText font-weight="semibold">
                        {{ __('common:beginning') }}
                    </CText>
                </div>
                <div class="tw-w-1/6">
                    <CText font-weight="semibold">
                        {{ __('common:end') }}
                    </CText>
                </div>
                <div class="tw-w-1/6">
                    <CText font-weight="semibold">
                        {{ __('common:billing') }}
                    </CText>
                </div>
            </div>

            <div class="tw-mt-2">
                <AddMultiplePlanningListItem
                    v-for="planning in plannings"
                    :key="'newPlanning' + planning.id"
                    class="tw-mb-4"
                    :planning="planning"
                    @change="
                        ($event1, $event2) => {
                            initConfigForPlanning($event1, $event2);
                        }
                    "
                    @remove="removePlanning(planning)"
                />

                <MButton
                    class="tw-mt-2 tw-w-full"
                    variant="light"
                    @click="addPlanning()"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-plus"
                    />
                    {{ __('planning:add_day') }}
                </MButton>
            </div>
        </div>

        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                v-if="selectedKid"
                class="tw-ml-3"
                :loading="loading"
                variant="primary"
                @click="saveAll"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {Epoch, Str} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment/moment';
    import {computed, defineComponent, nextTick, ref, watch} from 'vue';

    import {EventBus} from '@/eventBus';
    import useApi from '@/modules/app/composables/useApi';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import SelectKidGrid from '@/modules/family/components/kid/SelectKidGrid.vue';
    import useScopeLegacyKidContract from '@/modules/family/composables/kid/useScopeLegacyKidContract';
    import AddMultiplePlanningListItem from '@/modules/legacy/components/Modules/PlanningSummary/AddMultiplePlanningListItem.vue';
    import AddMultiplePlanningsTotals from '@/modules/legacy/components/Modules/PlanningSummary/AddMultiplePlanningsTotals.vue';
    import {useLocalStorage} from '@/modules/legacy/helpers/useLocalStorage';
    import {getClosing, getOpening} from '@/modules/legacy/libs/planning/planning';
    import route from '@/modules/legacy/libs/ziggy';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';

    export default defineComponent({
        components: {
            AddMultiplePlanningListItem,
            AddMultiplePlanningsTotals,
            MDatePeriodFilter,
            SelectKidGrid,
        },
        props: {
            kid: {
                type: Object,
                default: null,
            },
        },
        emits: ['hidden'],
        setup(props, {emit}) {
            const {legacyNursery: organization} = useManager();

            const selectedKid = ref(props.kid);
            const plannings = ref<any[]>([]);
            const loading = ref(false);

            const {getLocalStorage, setLocalStorage, removeLocalStorage} = useLocalStorage();

            const fromLocalStorage = 'kid:plannings:multiplePlanningsModal:from';
            const toLocalStorage = 'kid:plannings:multiplePlanningsModal:to';

            const periodFilter = ref({
                from: getLocalStorage(fromLocalStorage) || moment().startOf('month').toISOString(true),
                to: getLocalStorage(toLocalStorage) || moment().endOf('month').toISOString(true),
            });

            watch(
                periodFilter,
                val => {
                    if (val?.from) {
                        setLocalStorage(fromLocalStorage, val.from);
                        if (!plannings.value.length) {
                            nextTick(() => addPlanning());
                        } else if (plannings.value.length === 1) {
                            plannings.value[0].started_at = val.from;
                        }
                    } else {
                        removeLocalStorage(fromLocalStorage);
                    }

                    if (val?.to) {
                        setLocalStorage(toLocalStorage, val.to);
                    } else {
                        removeLocalStorage(toLocalStorage);
                    }
                },
                {immediate: true}
            );

            const displayPreferenceString = 'kid:plannings:multiplePlanningsModal:displayPreference';
            const displayPreference = ref(getLocalStorage(displayPreferenceString) || 'hour');
            watch(displayPreference, val => {
                if (val) {
                    setLocalStorage(displayPreferenceString, val);
                }
            });

            watch(
                () => selectedKid.value,
                () => {
                    plannings.value = [];
                    addPlanning();
                }
            );

            const allContracts = computed(() => selectedKid.value?.contracts || []);
            const from = computed(() => periodFilter.value.from);
            const to = computed(() => periodFilter.value.to);
            const {activeContracts, contractForDate} = useScopeLegacyKidContract(allContracts, from, to);
            watch(activeContracts, val => {
                // Do not automatically set display preference from contract when user has already localstorage value
                if (!getLocalStorage(displayPreferenceString) && val && val[0] && val[0].config) {
                    displayPreference.value = val[0].config.billing.cycle;
                }
            });

            function initConfigForPlanning(planning, previousPlanning) {
                const contract = contractForDate(planning.started_at);

                if (contract) {
                    // Recalculate pricing when newPlanning has a different contract than previous one.
                    const isSameContract =
                        previousPlanning && contractForDate(previousPlanning.started_at)?.id === contract?.id;
                    const isSameType = previousPlanning && previousPlanning.type === planning.type;

                    if (!isSameContract || !isSameType) {
                        switch (planning.type) {
                            case 'occasional':
                                planning.unit = contract.config.occasionals.per;
                                planning.hourly_rate = contract.config.occasionals.amount || contract.hourly_rate;
                                planning.hours_in_day = contract.config.billing.hours_in_day;
                                break;
                            case 'adaptation':
                                planning.unit = contract.config.adaptations.per;
                                planning.hourly_rate = contract.config.adaptations.amount || contract.hourly_rate;
                                planning.hours_in_day = contract.config.billing.hours_in_day;
                                break;
                            default:
                        }
                    }
                }
            }

            function addPlanning() {
                if (!selectedKid.value) {
                    return;
                }

                let startedAt = moment(periodFilter.value.from).format();
                if (
                    moment().startOf('month').isSame(from.value, 'day') &&
                    moment().endOf('month').isSame(to.value, 'day')
                ) {
                    // Set first planning to today when dates are current month
                    startedAt = moment().format();
                }

                let newPlanning = {
                    id: Str.uuid(),
                    type: 'occasional',
                    started_at: startedAt,
                    ended_at: null,
                    start_time: Epoch.parse(getOpening(organization.value.openingHours, false, true), 'HH:mm:ss'),
                    end_time: Epoch.parse(getClosing(organization.value.openingHours, false, true), 'HH:mm:ss'),
                    note: null,
                    kid_id: selectedKid.value.id,
                    nursery_id: organization.value.id,
                    hourly_rate: 0,
                    unit: 'free',
                    hours: 0,
                    vacation: false,
                    hours_in_day: 0,
                    loading: false,
                    success: false,
                    error: null,
                };

                if (plannings.value.length) {
                    const previousPlanning = plannings.value[plannings.value.length - 1];
                    newPlanning = _cloneDeep(previousPlanning);
                    newPlanning.id = Str.uuid();
                    newPlanning.started_at = moment(newPlanning.started_at).add(1, 'day').format();
                    newPlanning.loading = false;
                    newPlanning.success = false;
                    newPlanning.error = null;

                    initConfigForPlanning(newPlanning, previousPlanning);
                } else {
                    initConfigForPlanning(newPlanning, null);
                }

                plannings.value.push(newPlanning);
            }

            function removePlanning(planning) {
                plannings.value.splice(plannings.value.indexOf(planning), 1);
            }

            async function saveAll() {
                loading.value = true;
                const planningsToSave = plannings.value.filter(item => !item.success && !item.loading);
                for (const planning of planningsToSave) {
                    try {
                        await savePlanning(planning);
                    } catch (error) {
                        console.log('Error - ' + error);
                    }
                }
                loading.value = false;
            }

            async function savePlanning(planning) {
                planning.error = null;
                planning.loading = true;

                const data = _cloneDeep(planning);
                data.started_at = moment(
                    `${planning.started_at} ${planning.start_time.toFormat('HH:mm:ss')}`,
                    'YYYY-MM-DD HH:mm:ss'
                ).unix();
                data.ended_at = moment(
                    `${planning.started_at} ${planning.end_time.toFormat('HH:mm:ss')}`,
                    'YYYY-MM-DD HH:mm:ss'
                ).unix();

                const apiURL = data.type === 'absence' ? route('kid.absences.store') : route('kid.plannings.store');

                // @ts-ignore
                useApi()
                    .legacy.post(apiURL, data)
                    .then(response => {
                        planning.loading = false;
                        planning.success = true;
                        data.type === 'absence'
                            ? EventBus.$emit('created:absence', response.data)
                            : EventBus.$emit('created:planning', response.data);
                    })
                    .catch(error => {
                        planning.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            planning.error = '';
                            _forEach(error.response.data.errors, value => {
                                planning.error += _head(value) + ' ';
                            });
                        } else {
                            planning.error = error;
                        }
                    });
            }

            const kidLoading = ref(false);

            async function onKidChanged(event) {
                if (event.count()) {
                    kidLoading.value = true;
                    try {
                        const apiURL = route('kids.show', {kid: event.first().id});
                        // @ts-ignore
                        selectedKid.value = await (await useApi().legacy.get(apiURL)).data;
                        kidLoading.value = false;
                    } catch (error) {
                        console.log('Error - ' + error);
                        kidLoading.value = false;
                    }
                }
            }

            function getContractTitle(contract) {
                const fromContract = Epoch.parse(contract.started_at, 'yyyy-MM-dd').toLocaleString(
                    Epoch.presets.DATE_SHORT
                );
                const toContract = Epoch.parse(contract.broken_at || contract.ended_at, 'yyyy-MM-dd').toLocaleString(
                    Epoch.presets.DATE_SHORT
                );

                return __('planning:contract_no_from_to', {
                    no: contract.no,
                    from: fromContract,
                    to: toContract,
                });
            }

            function onHidden() {
                emit('hidden');
            }

            return {
                moment,

                selectedKid,
                plannings,
                loading,
                periodFilter,
                displayPreference,
                activeContracts,

                initConfigForPlanning,
                addPlanning,
                removePlanning,
                saveAll,

                kidLoading,
                onKidChanged,
                getContractTitle,
                onHidden,
            };
        },
    });
</script>
