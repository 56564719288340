<template>
    <div
        class="tw-relative tw-flex tw-flex-col tw-space-y-1 tw-rounded-md"
        @mousemove.self="mousemoveThrottled"
    >
        <slot />
        <CListHasMoreTrigger
            v-if="hasMore"
            class="CListHasMoreTrigger tw-h-0"
            @is-visible="wantMore"
        />
    </div>
</template>

<script lang="ts">
    import _throttle from 'lodash-es/throttle';
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            hasMore: {type: Boolean, default: false},
        },
        emits: ['mousemove', 'wantMore'],
        setup(props, {emit}) {
            const mousemove = function (e) {
                emit('mousemove', e);
            };

            const wantMore = function (e) {
                emit('wantMore', e);
            };

            const mousemoveThrottled = _throttle(mousemove, 100, {trailing: false});

            return {
                wantMore,
                mousemoveThrottled,
            };
        },
    });
</script>

<style scoped></style>
