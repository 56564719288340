<script setup lang="ts">
    import {inject} from 'vue';

    import {type defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';
    import type {MPopoverState} from '@/modules/meeko-ui/components/MPopoverState';

    const context = inject<ReturnType<typeof defineFloatingContext>>('MFloatingContext');
    const state = inject<ReturnType<typeof MPopoverState>>('MPopoverState');

    if (!context) {
        throw new Error('MPopoverOverlay must be used inside a MPopover');
    }

    if (!state) {
        throw new Error('MPopoverOverlay must be used inside a MPopover');
    }

    const {isVisible, middlewareData} = context;
    const {hasOverlay} = state;

    hasOverlay.value = true;
</script>

<template>
    <Teleport to="#overlay">
        <transition name="overlay">
            <div
                v-if="isVisible && !middlewareData.hide?.referenceHidden"
                class="MPopoverOverlay"
            />
        </transition>
    </Teleport>
</template>

<style scoped>
    .MPopoverOverlay {
        @apply tw-fixed tw-inset-0 tw-bg-gray-500/75;

        z-index: 3050;
    }

    .overlay {
        &-enter-active,
        &-leave-active {
            @apply tw-transition-all tw-ease-out;
        }

        &-enter-from,
        &-leave-to {
            @apply tw-opacity-0;
        }
    }
</style>
