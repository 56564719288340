<template>
    <CListRow :variant="archived ? 'archived' : 'default'">
        <CHStack
            align="center"
            distribute="between"
            gap="2"
        >
            <div class="tw-flex-1">
                <slot />
            </div>
            <CHStack
                align="center"
                class="tw-w-24"
                distribute="end"
                gap="1"
            >
                <CTooltip
                    v-if="shared"
                    placement="bottom-start"
                >
                    <CCenter class="tw-h-6 tw-w-6 tw-rounded-md tw-bg-purple-400/25 tw-text-purple-600">
                        <FontAwesomeIcon icon="fa-duotone fa-link" />
                    </CCenter>
                    <template #content>
                        <CText>
                            {{ __('app:share_with_other_organization') }}
                        </CText>
                    </template>
                </CTooltip>
                <CTooltip
                    v-if="archived"
                    placement="bottom-start"
                >
                    <CCenter class="tw-h-6 tw-w-6 tw-rounded-md tw-bg-orange-400/25 tw-text-orange-600">
                        <FontAwesomeIcon icon="fa-solid fa-archive" />
                    </CCenter>
                    <template #content>
                        <CText>
                            {{ __('common:archived') }}
                        </CText>
                    </template>
                </CTooltip>
                <slot name="action" />
            </CHStack>
        </CHStack>
    </CListRow>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            shared: {type: Boolean, default: false},
            archived: {type: Boolean, default: false},
        },
        setup() {
            return {};
        },
    });
</script>
