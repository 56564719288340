import _has from 'lodash-es/has';
import {nextTick} from 'vue';

export default class Scroller {
    public static scrollToRef(ref: Element, option: object = {block: 'nearest'}) {
        let el;

        if (_has(ref, '$el')) {
            el = ref['$el'];
        } else if (_has(ref, 0)) {
            el = ref[0]['$el'];
        }

        if (el) {
            nextTick(() => {
                el.scrollIntoView(option);
            });
        }
    }
}
