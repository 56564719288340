<template>
    <CParagraph
        margin="0"
        size="sm"
    >
        <CText variant="danger">
            <FontAwesomeIcon
                fixed-width
                icon="fa-duotone fa-exclamation-triangle"
            />
            <slot />
        </CText>
    </CParagraph>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {},
        setup() {
            return {};
        },
    });
</script>
