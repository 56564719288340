import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';

export class EventFactory {
    public static duplicateEvent(event: TemplateEventModel, planning: TemplatePlanningModel): TemplateEventModel {
        // Duplicate the event
        const eventClone = event.clone();
        eventClone.attributes.id = new TemplateEventModel().uuid();
        eventClone.attributes.planning_template_id = planning.getKey();
        eventClone.exists = false;

        return eventClone;
    }
}
