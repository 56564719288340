<template>
    <AuthTemplate show-login-buttons>
        <template #title>
            {{ __('auth:login_to_my_space') }}
        </template>
        <template #form>
            <LoginForm />
        </template>
        <template #loginButtons>
            <RouterLink :to="{name: 'password.email'}">
                <MButton
                    class="LoginPage__forgot-password-button"
                    :label="__('auth:forgot_password_ask')"
                    size="lg"
                    variant="link"
                />
            </RouterLink>
            <RouterLink :to="{name: 'register'}">
                <MButton
                    class="LoginPage__register-button"
                    size="lg"
                >
                    {{ __('auth:register') }}
                    <span class="LoginPage__register-button-text">meeko</span>
                </MButton>
            </RouterLink>
            <ApiUrlSwitcher
                v-if="isPreview"
                class="tw-my-4"
            />
        </template>
    </AuthTemplate>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    import app from '@/config/app';
    import ApiUrlSwitcher from '@/modules/app/components/molecules/ApiUrlSwitcher.vue';
    import LoginForm from '@/modules/auth/components/organisms/LoginForm.vue';
    import AuthTemplate from '@/modules/auth/components/templates/AuthTemplate.vue';

    export default defineComponent({
        components: {LoginForm, AuthTemplate, ApiUrlSwitcher},
        props: {},
        setup() {
            const isPreview = computed(() => app.env === 'preview');

            return {
                isPreview,
            };
        },
    });
</script>

<style scoped>
    .LoginPage__forgot-password-button {
        @apply tw-w-full;
    }

    .LoginPage__register-button {
        @apply tw-mt-6 tw-w-full;
    }

    .LoginPage__register-button-text {
        @apply tw-font-grandhotel tw-antialiased;
        @apply tw-text-3xl tw-font-normal;
        @apply tw-text-primary-500;
    }
</style>
