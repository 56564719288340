enum DeviceReportingMandatoryFor {
    kidAttendances = 'kid_attendances',
    meals = 'meals',
    activities = 'activities',
    naps = 'naps',
    health = 'health',
    weights = 'weights',
    drugs = 'drugs',
    temperatures = 'temperatures',
    diapers = 'diapers',
    photos = 'photos',
    observations = 'observations',
    rooms = 'rooms',
    equipment = 'equipment',
    staffNotes = 'staff_notes',
}

export default DeviceReportingMandatoryFor;
