import {collect} from '@meekohq/lumos';
import {isNil} from 'lodash-es';

import type {BaseError} from '@/modules/app/utils/errors/BaseError';
import {DateFormatError} from '@/modules/app/utils/errors/DateFormatError';
import {IsZeroError} from '@/modules/app/utils/errors/IsZeroError';
import {MissingError} from '@/modules/app/utils/errors/MissingError';
import {IsDateRule} from '@/modules/app/utils/rules/IsDateRule';
import {IsNotZeroRule} from '@/modules/app/utils/rules/IsNotZeroRule';
import {IsRequiredRule} from '@/modules/app/utils/rules/IsRequiredRule';
import {ContractAggregateError} from '@/modules/human-resources/contract/domain/errors/ContractAggregateError';
import {ContractBrokedAtAfterStartedAtError} from '@/modules/human-resources/contract/domain/errors/ContractBrokedAtAfterStartedAtError';
import {ContractEndedAtAfterStartedAtError} from '@/modules/human-resources/contract/domain/errors/ContractEndedAtAfterStartedAtError';
import {TrialPeriodEndedAtAfterContractStartedAtError} from '@/modules/human-resources/contract/domain/errors/TrialPeriodEndedAtAfterContractStartedAtError';
import {ContractBrokedAtAfterStartedAtRule} from '@/modules/human-resources/contract/domain/rules/ContractBrokedAtAfterStartedAtRule';
import {ContractEndedAtAfterStartedAtRule} from '@/modules/human-resources/contract/domain/rules/ContractEndedAtAfterStartedAtRule';
import {TrialPeriodEndedBetweenContractDatesRule} from '@/modules/human-resources/contract/domain/rules/TrialPeriodEndedBetweenContractDatesRule';
import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import type ContractModel from '@/modules/human-resources/models/ContractModel';
import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export class ContractAggregate {
    private readonly _staff: StaffModel;
    private readonly _contract: ContractModel;
    private readonly _trialPeriods: ContractTrialPeriodModel[];
    private readonly _balanceAllocations: BalanceAllocationModel[];

    constructor(
        staff: StaffModel,
        contract: ContractModel,
        trialPeriods: ContractTrialPeriodModel[],
        balanceAllocations: BalanceAllocationModel[]
    ) {
        this.validate(contract, trialPeriods);

        this._staff = staff;
        this._contract = contract;
        this._trialPeriods = trialPeriods;
        this._balanceAllocations = balanceAllocations;
    }

    get staff() {
        return this._staff;
    }

    get contract() {
        return this._contract;
    }

    get staffOrganizationsPivots() {
        return this._staff.organizationsPivots().value().toArray();
    }

    get organizations() {
        return this._contract.organizations().value().toArray();
    }

    get trialPeriods() {
        return this._trialPeriods;
    }

    get balanceAllocations() {
        return this._balanceAllocations;
    }

    private validate(contract: ContractModel, trialPeriods: ContractTrialPeriodModel[]) {
        const errors: BaseError[] = [];

        if (!new IsRequiredRule().isValid(contract.attributes.hours_per_week)) {
            errors.push(new MissingError('hours_per_week'));
        }

        if (
            !isNil(contract.attributes.hours_per_week) &&
            !new IsNotZeroRule().isValid(contract.attributes.hours_per_week)
        ) {
            errors.push(new IsZeroError('hours_per_week'));
        }

        if (!new IsRequiredRule().isValid(contract.attributes.started_at)) {
            errors.push(new MissingError('started_at'));
        }

        if (contract.attributes.started_at && !new IsDateRule().isValid(contract.attributes.started_at)) {
            errors.push(new DateFormatError('started_at'));
        }

        if (contract.attributes.ended_at && !new IsDateRule().isValid(contract.attributes.ended_at)) {
            errors.push(new DateFormatError('ended_at'));
        }

        if (contract.attributes.broked_at && !new IsDateRule().isValid(contract.attributes.broked_at)) {
            errors.push(new DateFormatError('broked_at'));
        }

        if (!new ContractEndedAtAfterStartedAtRule().isValid(contract)) {
            errors.push(new ContractEndedAtAfterStartedAtError('ended_at'));
        }

        if (!new ContractBrokedAtAfterStartedAtRule().isValid(contract)) {
            errors.push(new ContractBrokedAtAfterStartedAtError('broked_at'));
        }

        const trialPeriodErrors = this.validateTrialPeriods(contract, trialPeriods);

        if (trialPeriodErrors.length > 0) {
            errors.push(...trialPeriodErrors);
        }

        if (errors.length > 0) {
            throw new ContractAggregateError(errors);
        }
    }

    private validateTrialPeriods(contract: ContractModel, trialPeriods: ContractTrialPeriodModel[]) {
        const errors: BaseError[] = [];

        collect(trialPeriods).each(trialPeriod => {
            if (!new IsRequiredRule().isValid(trialPeriod.attributes.ended_at)) {
                errors.push(new MissingError('trial_periods'));
            }

            if (trialPeriod.attributes.ended_at && !new IsDateRule().isValid(trialPeriod.attributes.ended_at)) {
                errors.push(new DateFormatError('trial_periods'));
            }

            if (!new TrialPeriodEndedBetweenContractDatesRule().isValid(trialPeriod, contract)) {
                errors.push(new TrialPeriodEndedAtAfterContractStartedAtError('trial_periods'));
            }
        });

        // Filter duplicate errors
        return collect(errors).unique().toArray();
    }
}
