<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';

    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type AllocationModel from '@/modules/cashier/models/AllocationModel';

    const props = defineProps<{
        allocation: AllocationModel;
    }>();

    const {format} = useFormatCurrency(props.allocation.extra.payment.computed.currency_iso_code);
</script>

<template>
    <div class="InvoiceMiniaturePaymentList">
        <div
            v-for="payment in allocation.extra.invoicePayments"
            :key="payment.getKey()"
            class="InvoiceMiniaturePaymentList__item"
            :class="{
                'InvoiceMiniaturePaymentList__item--highlighted':
                    payment.getKey() === allocation.extra.payment.getKey(),
            }"
        >
            <FontAwesomeIcon
                v-if="payment.getKey() === allocation.extra.payment.getKey()"
                icon="fa-solid fa-arrow-right"
            />
            {{
                __('billing_transaction:payment_of_date_for_amount', {
                    date: Epoch.fromISOString(payment.attributes.date).toLocaleString(Epoch.presets.DATE_SHORT),
                    amount: format(payment.attributes.amount),
                })
            }}
        </div>
    </div>
</template>

<style scoped>
    .InvoiceMiniaturePaymentList {
        @apply tw-flex tw-flex-col tw-gap-1;
    }

    .InvoiceMiniaturePaymentList__item {
        @apply tw-flex tw-items-baseline tw-gap-2;
        @apply tw-text-gray-500;
    }

    .InvoiceMiniaturePaymentList__item--highlighted {
        @apply tw-rounded-lg tw-bg-primary-50 tw-px-1.5 tw-py-0.5 tw-font-semibold tw-text-primary-500;
    }
</style>
