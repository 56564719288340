<template>
    <MBox class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <MHeading level="h3">{{ __('planning:rule') }} {{ index + 1 }}</MHeading>
            <MTooltip>
                <MButton
                    variant="ghost"
                    @click="removeRule(group, index)"
                >
                    <template #left-icons>
                        <FontAwesomeIcon
                            class="tw-text-red-500"
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MButton>
                <template #content>
                    {{ __('planning:delete_rule') }}
                </template>
            </MTooltip>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <div class="tw-flex-1">
                {{ __('common:kids_number') }}
            </div>
            <CInputGroup>
                <CInputAddon>
                    <template v-if="rule.kids < 1">
                        {{ __('common:for') }}
                    </template>
                    <template v-else>
                        {{ __('common:date_format.human_to_time') }}
                    </template>
                </CInputAddon>
                <MInput
                    v-model.number="rule.kids"
                    class="tw-w-16"
                    type="number"
                />
                <CInputAddon class="tw-lowercase">
                    {{ __('common:kid', {count: rule.kids}) }}
                </CInputAddon>
            </CInputGroup>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-between tw-gap-2 sm:tw-flex-row">
            <div class="tw-shrink-0">
                {{ __('planning:required_staffs') }}
            </div>
            <div>
                <div
                    v-for="(staff, i) in rule.staffs"
                    :key="'staff' + i"
                    class="tw-mb-2"
                >
                    <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                        <MInput
                            v-model="staff.staffs"
                            class="tw-w-16"
                            type="number"
                        />
                        <SettingsGroupsJob
                            v-model="staff.job_id"
                            button-class="tw-w-28"
                        />
                        <MTooltip>
                            <MButton
                                variant="ghost"
                                @click="removeStaffFromRule(rule, i)"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon
                                        class="tw-text-black"
                                        icon="fa-regular fa-times"
                                    />
                                </template>
                            </MButton>
                            <template #content>
                                {{ __('common:actions.remove') }}
                            </template>
                        </MTooltip>
                    </div>
                </div>
                <MButton
                    class="tw-w-full tw-rounded-lg tw-p-1"
                    icon-plus
                    :label="__('planning:add_staffs')"
                    size="sm"
                    variant="dashed-light"
                    @click="addStaffInRule(rule)"
                />
            </div>
        </div>
    </MBox>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import SettingsGroupsJob from '@/modules/planning/components/settings/PlanningSettingsGroup/atoms/PlanningSettingsJobSelector.vue';

    export default defineComponent({
        components: {SettingsGroupsJob},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            myNursery: {
                type: Object,
                required: true,
            },
            jobTitles: {
                type: Object,
                default: undefined,
            },
            index: {
                type: Number,
                required: true,
            },
            rule: {
                type: Object,
                required: true,
            },
            group: {
                type: Object,
                default: null,
            },
        },

        setup(props) {
            function removeStaffFromRule(rule, index) {
                rule.staffs.splice(index, 1);
            }

            function addStaffInRule(rule) {
                rule.staffs.push({
                    job_id: null,
                    staffs: 1,
                });
            }

            function removeRule(group, index) {
                if (group) {
                    group.rules.splice(index, 1);
                } else {
                    props.myNursery.supervision_rules.splice(index, 1);
                }
            }

            return {
                removeStaffFromRule,
                addStaffInRule,
                removeRule,
            };
        },
    });
</script>
