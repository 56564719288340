<template>
    <loader v-if="loading" />
    <SimplePageTemplate v-else-if="staff">
        <template #title>
            <MPageTitle
                class="tw-capitalize"
                variant="staff"
            >
                {{ $fullName(staff.attributes) }}
                <template v-if="staff.attributes.job">&nbsp;-&nbsp;{{ $capitalize(staff.attributes.job) }}</template>
            </MPageTitle>
        </template>
        <template #actions>
            <TagsSelector
                :taggable-model="staff"
                taggable-type="staff"
                :tags="staff.tags().value().toArray()"
                @attached="staff.tags().value().push($event)"
                @detached="staff.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
            />
            <DownloadTemplate
                :additional-items="additionalItems"
                doc-type="staff"
                :item="staff"
                :nursery="nursery"
                sm
                :user="user"
                variant="light"
            />
        </template>
        <template #content>
            <div class="tw-hidden tw-w-full print:!tw-block">
                <h3 class="h3 tw-w-full tw-justify-items-center tw-text-center tw-font-grandhotel tw-text-blue-800">
                    {{ $fullName(staff.attributes) }}
                    <span
                        v-if="nursery"
                        class="tw-font-display tw-text-lg"
                    >
                        {{ `- ${nursery.name} ${nursery.city}` }}
                    </span>
                </h3>
                <hr class="hr tw-hidden print:!tw-block" />
            </div>
            <CTabMenu
                class="print:!tw-hidden"
                variant="pink"
            >
                <CTabMenuItem
                    :to="{
                        name: 'staffs.show.summary',
                        params: {nursery: nursery.id, staff: staff.id},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-user"
                    />
                    {{ __('common:summary') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'staffs_contracts')"
                    :to="{
                        name: 'staffs.show.contracts',
                        params: {nursery: nursery.id, staff: staff.id},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-handshake"
                    />
                    {{ __('common:contract_other') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :to="{
                        name: 'staffs.show.biography',
                        params: {nursery: nursery.id, staff: staff.id},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-book"
                    />
                    {{ __('common:biography') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'staffs_documents')"
                    :to="{
                        name: 'staffs.show.documents',
                        params: {nursery: nursery.id, staff: staff.id},
                    }"
                    variant="pink"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-file-alt"
                    />
                    {{ __('common:document_other') }}
                </CTabMenuItem>
            </CTabMenu>
            <router-view
                :staff="staff"
                :nursery="nursery"
                @saved="getStaff(staff.getKey())"
            />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {computed, defineComponent, ref, watch} from 'vue';
    import {useRoute} from 'vue-router';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useStaff from '@/modules/human-resources/composables/staff/useStaff';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default defineComponent({
        components: {
            SimplePageTemplate,
            TagsSelector,
            DownloadTemplate,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        setup() {
            const selectedTab = ref<'summary' | 'contracts' | 'documents' | 'biography'>('summary');

            const {can} = useAbility();
            const route = useRoute();

            const {selectedStaff: staff, loading, getStaff} = useStaff();
            watch(
                () => route.params.staff,
                val => {
                    getStaff(val);
                },
                {immediate: true}
            );

            // Additional items for download template
            const additionalItems = computed(() => {
                const arr: any[] = [];
                const activeContract = staff.value?.activeContract(undefined, Epoch.now().toISOString());
                if (activeContract) {
                    arr.push({
                        name: 'staff_contract_id',
                        value: activeContract.getKey(),
                    });
                }

                return arr;
            });

            return {
                selectedTab,
                staff,
                loading,
                additionalItems,
                can,
                getStaff,
            };
        },
    });
</script>
