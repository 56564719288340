<template>
    <div
        class="loader tw-flex tw-items-center tw-justify-center"
        style="padding: 20px 0"
    >
        <div
            class="circle tw-flex tw-items-center"
            :class="computedSize"
        >
            <FontAwesomeIcon
                class="tw-text-primary-500"
                icon="fa-regular fa-sync"
                spin
            />
            <span class="title">{{ title }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['size', 'light', 'title', 'shadow'],
        computed: {
            computedSize() {
                let output = '';
                if (this.size === 'sm') {
                    output += ' sm';
                }

                if (this.shadow !== 'false') {
                    output += ' card-shadow-md';
                }

                if (this.light === 'true') {
                    output += ' light';
                }

                if (this.title) {
                    output += ' withTitle';
                }

                return output;
            },
        },
    };
</script>

<style scoped>
    .circle {
        border-radius: 100px;
        background: #ffffff;
        padding: 20px;
        text-align: center;

        &.light {
            background-color: #eef3f9;
        }

        i,
        svg {
            font-size: 2em;
        }

        &.withTitle {
            .title {
                margin-left: 15px;
                font-weight: 600;
                font-size: 18px;
            }
        }

        &.sm {
            padding: 18px;

            i,
            svg {
                font-size: 1.5em;
            }

            &.withTitle {
                .title {
                    font-size: 16px;
                }
            }
        }
    }
</style>
