import {useToggle} from '@vueuse/core';
import {cloneDeep} from 'lodash-es';
import type {Moment} from 'moment';
import moment from 'moment';
import type {Ref} from 'vue';
import {onMounted, ref, watch} from 'vue';

interface DatePickerProps {
    time: Ref<boolean>;
    format: Ref<string>;
    modifier: Ref<string>;
    minDate: Ref<string | undefined>;
    maxDate: Ref<string | undefined>;
    syncDateByMinOrMax: Ref<boolean>;
}

/**
 * Manage the date picker state
 *
 * @param {string | undefined} value
 * @param {DatePickerProps} props
 * @param {(event: string, ...args: any[]) => void} emit
 * @param {() => void} scrollToRef
 * @param {string|undefined|null} emptyValue
 */
export default function (
    value: Ref<undefined | string>,
    props: DatePickerProps,
    emit: (event: 'update:modelValue', ...args: any[]) => void,
    scrollToRef: () => void,
    emptyValue: undefined | null
) {
    const date = ref<Moment>(moment());
    const minDate = ref<Moment | undefined>(props.minDate.value ? moment(props.minDate.value) : undefined);
    const maxDate = ref<Moment | undefined>(props.maxDate.value ? moment(props.maxDate.value) : undefined);
    const textDate = ref<string | undefined>(undefined);
    const [isEditing, setIsEditing] = useToggle(false);

    /**
     * Parse date from input depending on format
     * @param input
     */
    function parseDate(input) {
        if (input) {
            const formats = [moment.ISO_8601, 'DDMMYYYY', 'DDMMYY', 'DDMMYYYYHHmm', 'DDMMYYHHmm'];

            let parsedDate;

            if (props.format.value === 'timestamp') {
                parsedDate = moment.unix(input);
            } else {
                parsedDate = moment(input, formats);
            }

            if (parsedDate.isValid()) {
                // If date is before min date, set min date only if we don't sync date by min or max
                // If date is after max date, set max date only if we don't sync date by min or max
                if (!props.syncDateByMinOrMax.value && minDate.value && parsedDate.isBefore(minDate.value)) {
                    date.value = minDate.value;
                } else if (!props.syncDateByMinOrMax.value && maxDate.value && parsedDate.isAfter(maxDate.value)) {
                    date.value = maxDate.value;
                } else {
                    date.value = parsedDate;
                }
            } else {
                emit('update:modelValue', emptyValue);
            }
        } else {
            emit('update:modelValue', emptyValue);
        }
    }

    /**
     * Set date to now
     */
    function setNow() {
        date.value = moment();
    }

    /**
     * Select date
     * @param {Moment} selectedDate
     * @param {() => void} callback
     */
    function selectDate(selectedDate: Moment, callback?: () => void) {
        date.value = cloneDeep(selectedDate);
        if (callback) {
            callback();
        }
    }

    onMounted(() => {
        parseDate(value?.value);
    });

    watch(value, newValue => {
        if (newValue) {
            parseDate(newValue);
        } else {
            textDate.value = undefined;
            emit('update:modelValue', value?.value);
        }
    });

    watch(date, () => {
        if (props.time.value) {
            scrollToRef();
            textDate.value = date.value.format('DD/MM/YYYY HH:mm');
        } else {
            switch (props.modifier.value) {
                case 'endOfDay':
                    date.value = date.value.endOf('day');
                    break;
                case 'startOfDay':
                default:
                    date.value = date.value.startOf('day');
                    break;
            }

            textDate.value = date.value.format('DD/MM/YYYY');
        }

        switch (props.format.value) {
            case 'iso8601':
                emit('update:modelValue', date.value.toISOString(true));
                break;
            case 'timestamp':
                emit('update:modelValue', date.value.unix());
                break;
            default:
                emit('update:modelValue', date.value.format(props.format.value));
        }
    });

    /**
     * Watch min date update
     */
    watch(props.minDate, newValue => {
        if (newValue) {
            minDate.value = moment(newValue);

            // If date is before min date, set date to min date if we sync date by min or max
            if (props.syncDateByMinOrMax.value && date.value.isBefore(minDate.value)) {
                date.value = minDate.value;
            }
        } else {
            minDate.value = undefined;
        }
    });

    /**
     * Watch max date update
     */
    watch(props.maxDate, newValue => {
        if (newValue) {
            maxDate.value = moment(newValue);

            // If date is after max date, set date to max date if we sync date by min or max
            if (props.syncDateByMinOrMax.value && date.value.isAfter(maxDate.value)) {
                date.value = maxDate.value;
            }
        } else {
            maxDate.value = undefined;
        }
    });

    return {
        date,
        textDate,
        isEditing,
        setIsEditing,
        parseDate,
        setNow,
        selectDate,
    };
}
