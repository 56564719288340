<script setup lang="ts">
    import type {Epoch} from '@meekohq/lumos';
    import {EpochInfo} from '@meekohq/lumos';
    import {computed} from 'vue';

    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    defineProps<{
        newOpeningHour: {
            day: string;
            started_at: Epoch;
            ended_at: Epoch;
        };
        saving: boolean;
    }>();

    const emit = defineEmits(['addOpeningHour']);

    const {startOfMinute} = useDateTimeModifiersPresets();

    const weekDaysLong = computed(() => {
        return EpochInfo.weekdays();
    });
</script>

<template>
    <MForm>
        <MFieldset>
            <MSelectMenu
                v-model="newOpeningHour.day"
                :options="[
                    {
                        value: 'mon',
                        text: weekDaysLong[0],
                    },
                    {
                        value: 'tue',
                        text: weekDaysLong[1],
                    },
                    {
                        value: 'wed',
                        text: weekDaysLong[2],
                    },
                    {
                        value: 'thu',
                        text: weekDaysLong[3],
                    },
                    {
                        value: 'fri',
                        text: weekDaysLong[4],
                    },
                    {
                        value: 'sat',
                        text: weekDaysLong[5],
                    },
                    {
                        value: 'sun',
                        text: weekDaysLong[6],
                    },
                ]"
                text-path="text"
                value-path="value"
            />
        </MFieldset>
        <CFormThreeColumns>
            <MFieldset class="tw-min-w-0">
                <MTimePicker
                    v-model="newOpeningHour.started_at"
                    :modifier="startOfMinute"
                />
            </MFieldset>
            <MFieldset class="tw-min-w-0">
                <MTimePicker
                    v-model="newOpeningHour.ended_at"
                    :modifier="startOfMinute"
                />
            </MFieldset>
            <MFieldset>
                <MButton
                    class="tw-w-full"
                    icon-plus
                    :loading="saving"
                    :label="__('common:actions.add')"
                    variant="primary"
                    @click="emit('addOpeningHour')"
                />
            </MFieldset>
        </CFormThreeColumns>
    </MForm>
</template>
