<template>
    <svg
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            fill="none"
            fill-rule="evenodd"
        >
            <path
                d="m8.01 26.12 2.15-12.3a1 1 0 0 1 .99-.82h13.2a1 1 0 0 1 .98.83l2.1 12.3a1 1 0 0 1-.98 1.17H9a1 1 0 0 1-.99-1.18Z"
                fill="#2B92FF"
            />
            <path
                d="M7.81 20.8 10.31 6h14.88l2.65 14.38a1 1 0 0 1-.96 1.18l-18.06.41a1 1 0 0 1-1-1.16Z"
                fill="#2B92FF"
            />
            <path
                d="M8 24h21v1H8zm0-2h21v1H8zm0-2h21v1H8z"
                fill="#FFF"
            />
            <path
                d="M5.5 0a2 2 0 0 0-1.96 1.62l-3.5 18A2 2 0 0 0 0 20v12c0 2.19 1.81 4 4 4h28c2.19 0 4-1.81 4-4V20a2 2 0 0 0-.04-.38l-3.5-18A2 2 0 0 0 30.5 0h-25Zm1.62 4h21.76L32 20h-5.98a2 2 0 0 0-1.9 1.37l-1.2 3.58c-.2.62-.8 1.05-1.47 1.05h-6.9c-.67 0-1.26-.42-1.47-1.05l-1.2-3.58A2 2 0 0 0 9.98 20H4L7.12 4Z"
                fill="#9AADC1"
                fill-rule="nonzero"
            />
        </g>
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
