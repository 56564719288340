<script setup lang="ts">
    import type {Epoch} from '@meekohq/lumos';
    import {useElementHover, whenever} from '@vueuse/core/index';
    import type {PropType} from 'vue';
    import {computed, ref} from 'vue';

    import MDatePickerControl from '@/modules/meeko-ui/components/MDatePicker/MDatePickerControl.vue';
    import MDatePickerDay from '@/modules/meeko-ui/components/MDatePicker/MDatePickerDay.vue';
    import MDatePickerMonthPicker from '@/modules/meeko-ui/components/MDatePicker/MDatePickerMonthPicker.vue';
    import MDatePickerYearPicker from '@/modules/meeko-ui/components/MDatePicker/MDatePickerYearPicker.vue';
    import type {MDatePickerStoreType} from '@/modules/meeko-ui/composables/useMDatePicker';

    const props = defineProps({
        store: {
            type: Object as PropType<MDatePickerStoreType>,
            required: true,
        },
        month: {
            type: Object as PropType<Epoch>,
            required: true,
        },
    });

    const emit = defineEmits<{
        (e: 'navigate', value: Epoch): void;
    }>();

    const {onDaySelected, hoveredDay} = props.store;
    const daysElement = ref();
    const isHovered = useElementHover(daysElement);
    const monthSelection = ref(false);
    const yearSelection = ref(false);

    /**
     * Get days to display in calendar.
     */
    const daysInMonths = computed(() => {
        const days = [] as Epoch[];
        const daysToDisplay = 6 * 7;

        const startOfCalendarMonth = props.month.startOfMonth().startOfWeek();
        const endOfCalendarMonth = props.month.endOfMonth().endOfWeek();

        // Add before days of month to fill first week
        let dayIterator = startOfCalendarMonth;
        while (dayIterator.between(startOfCalendarMonth, endOfCalendarMonth) || days.length < daysToDisplay) {
            days.push(dayIterator);
            dayIterator = dayIterator.addDays();
        }

        return days;
    });

    const weekDays = computed(() => {
        return daysInMonths.value.slice(0, 7).map(day => day.toLocaleString({weekday: 'short'}).slice(0, 3));
    });

    whenever(
        () => !isHovered.value,
        () => (hoveredDay.value = undefined)
    );

    function swapMonth(date: Epoch) {
        emit('navigate', date.startOfMonth());
        monthSelection.value = false;
    }

    function swapYear(date: Epoch) {
        emit('navigate', date.startOfMonth());
        yearSelection.value = false;
    }

    function toggleMonth() {
        if (monthSelection.value) {
            monthSelection.value = false;
        } else {
            monthSelection.value = true;
            yearSelection.value = false;
        }
    }

    function toggleYear() {
        if (yearSelection.value) {
            yearSelection.value = false;
        } else {
            yearSelection.value = true;
            monthSelection.value = false;
        }
    }
</script>

<template>
    <div class="MDatePickerCalendar">
        <template v-if="monthSelection">
            <MDatePickerControl
                hide-arrows
                :month="month"
                @toggle-month="toggleMonth"
                @toggle-year="toggleYear"
            />
            <MDatePickerMonthPicker
                :month="month.month"
                @selected="swapMonth(month.setMonth($event))"
            />
        </template>
        <template v-else-if="yearSelection">
            <MDatePickerControl
                hide-arrows
                :month="month"
                @toggle-month="toggleMonth"
                @toggle-year="toggleYear"
            />
            <MDatePickerYearPicker
                :year="month.year"
                @selected="swapYear(month.setYear($event))"
            />
        </template>
        <template v-else>
            <MDatePickerControl
                :month="month"
                @next="swapMonth(month.addMonths())"
                @previous="swapMonth(month.subMonths())"
                @toggle-month="toggleMonth"
                @toggle-year="toggleYear"
            />
            <div class="MDatePickerCalendar__grid">
                <div
                    v-for="day in weekDays"
                    :key="day"
                    class="MDatePickerWeekDays__weekday"
                >
                    {{ day }}
                </div>
            </div>
            <div
                ref="daysElement"
                class="MDatePickerCalendar__grid"
            >
                <MDatePickerDay
                    v-for="day in daysInMonths"
                    :key="day.toISOString()"
                    :day="day"
                    :month="month"
                    :store="store"
                    @click="onDaySelected"
                />
            </div>
        </template>
    </div>
</template>

<style scoped>
    .MDatePickerCalendar {
        @apply tw-flex tw-w-max tw-shrink-0 tw-flex-col tw-justify-start;
    }

    .MDatePickerCalendar__grid {
        @apply tw-grid tw-grid-flow-row tw-grid-cols-7;
    }

    .MDatePickerWeekDays__weekday {
        @apply tw-flex tw-justify-center tw-py-2 tw-font-display tw-text-sm tw-capitalize tw-text-gray-500;
    }
</style>
