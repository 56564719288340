import {Lang} from '@meekohq/lumos';
import type {Router} from 'vue-router';

import useMetrics from '@/modules/app/composables/useRum';
import FamilyModel from '@/modules/family/models/FamilyModel';
import KidModel from '@/modules/family/models/KidModel';
import MemberModel from '@/modules/family/models/MemberModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RegistrationModel from '@/modules/registration/models/RegistrationModel';

/**
 * Middleware to resolve legacy ids to uuids
 */
export default function migrateToUuidMiddleware(router: Router) {
    const {addAction} = useMetrics();

    /**
     * Check if the given id is a legacy id
     *
     * @param id
     */
    function isLegacyId(id: string) {
        return Lang.isNumeric(id);
    }

    /**
     * Resolve legacy ids to uuids
     *
     * @param params
     */
    async function resolveParams(params) {
        let needRedirection = false;
        let legacyId;

        // List of available mappings where we want to replace legacy ids by uuids
        const availableMappings = {
            nursery: {
                id: undefined,
                paramName: 'nursery',
                model: OrganizationModel,
            },
            kid: {
                id: undefined,
                paramName: 'kid',
                model: KidModel,
            },
            familyMember: {
                id: undefined,
                paramName: 'familyMember',
                model: MemberModel,
            },
            family: {
                id: undefined,
                paramName: 'family',
                model: FamilyModel,
            },
            registration: {
                id: undefined,
                paramName: 'registration',
                model: RegistrationModel,
            },
            staff: {
                id: undefined,
                paramName: 'staff',
                model: StaffModel,
            },
        };

        // Filter the params to keep only the ones that are legacy ids then map them to the available mappings data structure
        const paramsToUpdate = Object.keys(params)
            .filter(
                paramName =>
                    typeof params[paramName] === 'string' &&
                    isLegacyId(params[paramName]) &&
                    !!availableMappings[paramName]
            )
            .map(paramName => {
                return {...availableMappings[paramName], id: `${params[paramName]}`};
            });

        // If there are params to update, we need to redirect to the new url
        if (paramsToUpdate.length) {
            needRedirection = true;
        }

        // Update the params with the uuids and keep the legacy id for the metric
        for (const param of paramsToUpdate) {
            const model = await param.model.query().where('legacy_id', param.id).first();
            if (!model) {
                throw new Error('Could not find model the with legacy id ' + param.id);
            }
            params[param.paramName] = model.getKey();
            legacyId = param.id;
        }

        return {
            legacyId,
            needRedirection,
            params,
        };
    }

    router.beforeEach((to, from, next) => {
        resolveParams(to.params)
            .then(({legacyId, needRedirection, params}) => {
                if (needRedirection) {
                    //Increment the metric for legacy id redirection
                    addAction('A_Legacy_ID_Compat', {legacy_id: legacyId, scope: to.path});

                    // If the params have been updated, redirect to the new url
                    return next({name: to.name as string, params, query: to.query});
                }

                return next();
            })
            .catch(() => {
                next({name: 'nurseries'});
            });
    });
}
