<template>
    <div class="tw-rounded-lg tw-border tw-border-gray-300 tw-p-3">
        <div class="tw-flex tw-justify-end">
            <template v-if="modelValue.editing">
                <MTooltip :label="__('common:actions.cancel')">
                    <MButton
                        class="tw-mr-2 tw-border tw-border-gray-300"
                        :round="true"
                        variant="ghost"
                        @click="cancelEdit"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-times" />
                    </MButton>
                </MTooltip>
                <MTooltip :label="__('common:actions.save')">
                    <MButton
                        :round="true"
                        variant="primary"
                        @click="confirmEditSepa"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-save" />
                    </MButton>
                </MTooltip>
            </template>
            <template v-else-if="$can('update', 'kids_contracts')">
                <MTooltip
                    v-if="modelValue && modelValue.id"
                    :label="__('common:actions.download')"
                >
                    <DownloadTemplate
                        :additional-items="additionalItemsForDownload"
                        button-without-text
                        doc-type="sepa_mandate"
                        :item="modelValue"
                        :nursery="manager.legacyNursery"
                        :user="user"
                        variant="light"
                        wait-for-callback
                        @save-before-download-template="$emit('saveBeforeDownloadTemplate', $event)"
                    />
                </MTooltip>
                <MTooltip :label="__('common:actions.update')">
                    <MButton
                        class="tw-ml-2"
                        :round="true"
                        variant="primary"
                        @click="startEditSepa"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-pencil" />
                    </MButton>
                </MTooltip>
            </template>
        </div>
        <div class="tw-mt-1">
            <div>
                <MLabel>
                    {{ __('common:last_name') }}
                </MLabel>
                <CInput
                    v-if="modelValue.editing"
                    v-model="modelValue.name"
                    :placeholder="__('common:last_name')"
                    type="text"
                />
                <CBadge v-else>
                    {{ modelValue.name }}
                </CBadge>
            </div>
            <div class="tw-mt-3">
                <MLabel>{{ __('common:iban') }}</MLabel>
                <CInput
                    v-if="modelValue.editing"
                    v-model.trim="modelValue.iban"
                    _placeholder="__('common:iban_example')"
                    type="text"
                />
                <CBadge v-else>
                    {{ modelValue.iban }}
                </CBadge>
            </div>
            <div class="tw-mt-3">
                <MLabel>{{ __('common:bic') }}</MLabel>
                <CInput
                    v-if="modelValue.editing"
                    v-model="modelValue.bic"
                    :placeholder="__('billing_setting:bic_example')"
                    type="text"
                />
                <CBadge v-else>
                    {{ modelValue.bic }}
                </CBadge>
            </div>
            <div class="tw-mt-3 tw-flex">
                <div class="tw-mr-2 tw-w-1/2">
                    <MLabel>
                        {{ __('billing_core:sepa_mandate_unique_reference') }}
                        <MTooltip placement="bottom-start">
                            <FontAwesomeIcon
                                class="tw-ml-1 tw-text-cyan-500"
                                icon="fa-solid fa-info-circle"
                            />
                            <template #content>
                                {{ __('family_contract:unique_mandate_reference') }}
                            </template>
                        </MTooltip>
                    </MLabel>
                    <CInput
                        v-if="modelValue.editing"
                        v-model="modelValue.rum"
                        :placeholder="__('family_contract:unique_mandate_reference')"
                        type="text"
                    />
                    <CBadge v-else>
                        {{ modelValue.rum }}
                    </CBadge>
                </div>
                <div class="tw-w-1/2">
                    <MLabel>
                        {{ __('family_contract:signed_on') }}
                    </MLabel>
                    <MDatePicker
                        v-if="modelValue.editing"
                        v-model="modelValue.signed_at"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                    <CBadge v-else>
                        {{ $formatDate(modelValue.signed_at, 'DD/MM/YYYY') }}
                    </CBadge>
                </div>
            </div>
        </div>
        <div
            v-if="modelValue.editing && modelValue.id"
            class="tw-mt-4 tw-flex"
        >
            <MButton
                class="tw-mr-2 tw-text-red-500"
                @click="removeSepa"
            >
                {{ __('common:actions.delete') }}
            </MButton>
            <MButton
                class="tw-border tw-border-gray-900"
                @click="detachSepa"
            >
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fa-solid fa-link"
                />
                {{ __('common:actions.remove') }}
            </MButton>
        </div>
    </div>
</template>

<script lang="ts">
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import {computed, defineComponent, type PropType, ref} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import Manager from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        components: {DownloadTemplate},
        props: {
            modelValue: {
                type: Object as PropType<{id: string; editing: boolean}>,
                required: true,
            },
            user: {
                type: Object as PropType<any>,
                required: true,
            },
            contract: {
                type: Object as PropType<{first_family_member_id: string; second_family_member_id: string}>,
                required: true,
            },
            billingAddressId: {
                type: String,
                default: () => null,
            },
        },
        emits: ['saveBeforeDownloadTemplate', 'update:modelValue'],
        setup(props, {emit}) {
            const manager = Manager;
            const savedValue = ref({});

            function onSepaEdited(sepa: object | null) {
                emit('update:modelValue', sepa);
            }

            /*
                Format the parents data to pass variables to the DownloadTemplate component
             */
            const contractParentsIds = computed(() => {
                const contractParentsIds: {}[] = [];

                if (props.contract.first_family_member_id) {
                    contractParentsIds.push({
                        name: 'family_member_ids[]',
                        value: props.contract.first_family_member_id,
                    });
                }

                if (props.contract.second_family_member_id) {
                    contractParentsIds.push({
                        name: 'family_member_ids[]',
                        value: props.contract.second_family_member_id,
                    });
                }

                return contractParentsIds;
            });

            /*
                Aggregate all additional data to be passed to the DownloadTemplate component
             */
            const additionalItemsForDownload = computed(() => {
                if (props.billingAddressId) {
                    return [
                        {
                            name: 'customer_address_id',
                            value: props.billingAddressId,
                        },
                        ...contractParentsIds.value,
                    ];
                } else {
                    return contractParentsIds.value;
                }
            });

            function updateSepa() {
                useApi()
                    .legacy.put(route('sepas.update', {sepa: props.modelValue.id}), props.modelValue)
                    .then(() => {
                        savedValue.value = _cloneDeep(props.modelValue);
                        set(props.modelValue, 'editing', false);
                        useNotification().success(__('billing_setting:sepa_updated_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value) as string);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            }

            function detachSepa() {
                onSepaEdited(null);
            }

            function removeSepa() {
                useMagicModal().deleteConfirmationModal({
                    title: __('billing_setting:delete_sepa_confirmation'),
                    text: __('family_contract:sepa_will_be_deleted_on_all_contracts'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('sepas.destroy', {
                                    sepa: props.modelValue.id,
                                })
                            )
                            .then(() => {
                                detachSepa();
                                useNotification().success(__('billing_setting:sepa_deleted_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value) as string);
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            }

            function confirmEditSepa() {
                if (props.modelValue.id) {
                    updateSepa();
                } else {
                    set(props.modelValue, 'editing', false);
                    savedValue.value = _cloneDeep(props.modelValue);
                    onSepaEdited(props.modelValue);
                }
            }

            function startEditSepa() {
                savedValue.value = _cloneDeep(props.modelValue);
                set(props.modelValue, 'editing', true);
            }

            function cancelEdit() {
                if (props.modelValue.id) {
                    onSepaEdited(_cloneDeep(savedValue.value));
                    set(props.modelValue, 'editing', false);
                } else {
                    onSepaEdited(null);
                }
            }

            return {
                additionalItemsForDownload,
                detachSepa,
                removeSepa,
                confirmEditSepa,
                startEditSepa,
                cancelEdit,
                manager,
            };
        },
    });
</script>
