<template>
    <CInline space="sm">
        <CInline v-if="!isContractsExist">
            <CBadge>
                {{ jobModel.attributes.created_at }}
                {{ __('common:unused') }}
            </CBadge>
        </CInline>
        <CInline space="sm">
            <MBadge
                v-if="jobModel.attributes.archived_at"
                variant="warning"
            >
                {{ __('common:archived') }}
            </MBadge>

            <CTooltip
                v-if="isJobExistInOtherOrganization"
                class="tw-flex tw-items-center tw-justify-center"
            >
                <FontAwesomeIcon
                    class="tw-text-gray-700"
                    icon="fa-solid fa-link"
                />
                <template #content>
                    {{ __('hr:present_in_mutliple_organizations') }}
                </template>
            </CTooltip>
            <slot name="update" />
        </CInline>
    </CInline>
</template>

<script>
    import {isEmpty} from 'lodash-es';

    import Manager from '@/modules/legacy/store/manager.store';

    export default {
        props: {
            jobModel: {
                required: true,
            },
            jobPivotInOtherOrganization: {
                required: true,
            },
        },

        data() {
            return {
                Manager,
            };
        },

        computed: {
            isContractsExist() {
                return !isEmpty(this.jobModel?.relationships?.contracts);
            },

            isJobExistInOtherOrganization() {
                return !isEmpty(this.jobPivotInOtherOrganization.all());
            },
        },
    };
</script>

<style scoped></style>
