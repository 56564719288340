<template>
    <div
        v-if="registration.id && !loading"
        class="tw-mt-7"
    >
        <div class="tw-flex tw-flex-col lg:tw-flex-row">
            <div class="tw-flex tw-w-full tw-flex-col tw-gap-3 lg:tw-w-64">
                <MBox class="tw-p-5">
                    <m-form-inplace-editor
                        ref="kid"
                        :force-edit="
                            hasError(errors, ['kid_first_name', 'kid_last_name', 'kid_birthdate', 'kid_gender'])
                        "
                        :input-refs="['kid_first_name', 'kid_last_name']"
                        no-border
                        :no-edit="!$can('update', 'registrations')"
                        :no-edit-btn="!$can('update', 'registrations')"
                        no-padding
                        @keyup.enter="$refs.kid.save()"
                        @canceled="resetRegistration"
                        @save="saveRegistration"
                    >
                        <h1
                            class="h1 tw-mx-5 tw-text-center tw-font-grandhotel tw-text-4xl tw-text-blue-500"
                            :class="{'tw-text-girl': registration.kid_gender === 'female'}"
                        >
                            <span
                                class="tw-mr-2 tw-break-words"
                                :class="{'tw-text-gray-300': !registration.kid_first_name}"
                            >
                                {{
                                    $capitalize(
                                        registration.kid_first_name
                                            ? registration.kid_first_name
                                            : __('common:first_name')
                                    )
                                }}
                            </span>
                            <span
                                class="tw-break-words"
                                :class="{'tw-text-gray-600': !registration.kid_last_name}"
                            >
                                {{
                                    $capitalize(
                                        registration.kid_last_name ? registration.kid_last_name : __('common:last_name')
                                    )
                                }}
                            </span>
                        </h1>
                        <p class="tw-mb-0 tw-text-center tw-font-display">
                            <span v-if="isKidBorn">
                                {{
                                    registration.kid_gender === 'male'
                                        ? __('common:born_on_male')
                                        : __('common:born_on_female')
                                }}
                                {{ kidBirthdateFormatted }}
                            </span>
                            <span v-else>
                                {{ __('common:will_be_born_on', {date: kidBirthdateFormatted}) }}
                            </span>
                        </p>
                        <p class="tw-mb-0 tw-text-center tw-font-display">
                            {{ humanKidAge }}
                        </p>
                        <template #edition>
                            <m-form-group-inplace-inputs>
                                <MLabel class="tw-mb-1">
                                    {{ __('common:gender_one') }}
                                </MLabel>
                                <MSelectMenu
                                    v-model="registration.kid_gender"
                                    class="tw-w-full"
                                    :has-error="hasError(errors, ['kid_gender'])"
                                    :options="[
                                        {value: 'male', text: __('common:gender.boy')},
                                        {value: 'female', text: __('common:gender.girl')},
                                        {value: null, text: __('common:gender.unknow')},
                                    ]"
                                />
                                <MLabel class="tw-mb-1 tw-mt-2">
                                    {{ __('common:first_name') }}
                                </MLabel>
                                <m-form-input
                                    ref="kid_first_name"
                                    v-model="registration.kid_first_name"
                                    :class="{'is-invalid': hasError(errors, ['kid_first_name'])}"
                                    size="sm"
                                    focus
                                />
                                <MLabel class="tw-mb-1 tw-mt-2">
                                    {{ __('common:last_name') }}
                                </MLabel>
                                <m-form-input
                                    ref="kid_last_name"
                                    v-model="registration.kid_last_name"
                                    :class="{'is-invalid': hasError(errors, ['kid_last_name'])}"
                                    size="sm"
                                />
                                <MLabel class="tw-mb-1 tw-mt-2">
                                    {{ __('common:birthdate') }}
                                </MLabel>
                                <MDatePicker
                                    v-model="registration.kid_birthdate"
                                    class="tw-w-full"
                                    format="yyyy-MM-dd"
                                />
                            </m-form-group-inplace-inputs>
                        </template>
                    </m-form-inplace-editor>
                    <div class="tw--mx-5 tw-my-3 tw-h-px tw-bg-gray-300" />
                    <div
                        class="tw--mx-5 tw--my-3 tw-cursor-pointer tw-text-center hover:tw-bg-gray-50"
                        @click="editPlanning"
                    >
                        <kid-planning-summary
                            :nursery="nursery"
                            :plannings="registration.plannings"
                            :weeks="registration.contract_recurrent_weeks"
                        />
                        <div class="tw-my-3 tw-mb-0 tw-h-px tw-border-b tw-border-dashed" />
                        <div class="tw-p-2">
                            <div class="tw-flex tw-items-end tw-justify-center">
                                <div class="tw-text-sm tw-font-semibold">
                                    {{ contractStartedAtFormatted }}
                                </div>
                                <div class="tw-mx-3 tw-flex tw-w-16 tw-flex-col tw-items-center">
                                    <span class="tw-text-sm tw-font-semibold tw-text-blue-500">
                                        {{ contractDurationInMonths }}
                                        {{ __('common:month') }}
                                    </span>
                                    <div class="tw-relative tw-flex tw-h-5 tw-w-full tw-items-center">
                                        <div class="h-arrow tw-grow tw-rounded-full tw-bg-blue-300" />
                                        <FontAwesomeIcon
                                            class="tw-absolute tw-text-blue-300"
                                            icon="fa-regular fa-angle-right"
                                            style="right: -2px; font-size: 26px"
                                        />
                                    </div>
                                </div>
                                <div class="tw-text-sm tw-font-semibold">
                                    {{ contractEndedAtFormatted }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tw--mx-5 tw-my-3 tw-h-px tw-bg-gray-300" />
                    <m-form-inplace-editor
                        ref="requested"
                        :force-edit="hasError(errors, ['requested_at'])"
                        :no-edit="!$can('update', 'registrations')"
                        :no-edit-btn="!$can('update', 'registrations')"
                        @canceled="resetRegistration"
                        @save="saveRegistration"
                    >
                        <p class="tw-mb-0 tw-text-center">
                            {{ __('registration:requested_at') }}
                            <span class="tw-font-semibold">{{ contractRequestAtFormatted }}</span>
                        </p>
                        <template #edition>
                            <MDatePicker
                                v-model="registration.requested_at"
                                class="tw-w-full"
                                format="yyyy-MM-dd"
                            />
                        </template>
                    </m-form-inplace-editor>
                    <div
                        v-if="$can('delete', 'registrations')"
                        class="-tw-mx-4 tw--mb-4 tw-mt-3"
                    >
                        <button
                            class="tw-w-full tw-rounded-full tw-border-red-500 tw-bg-red-100 tw-py-1 tw-text-sm tw-font-semibold tw-text-red-500 hover:tw-bg-red-500 hover:tw-text-white"
                            @click="remove"
                        >
                            {{ __('registration:delete_registration') }}
                        </button>
                    </div>
                </MBox>
                <div class="tw-flex tw-flex-wrap tw-gap-3">
                    <div class="tw-grow">
                        <first-parent
                            :errors="errors"
                            :registration="registration"
                            @canceled="resetRegistration"
                            @save="setAndSaveRegistration"
                        />
                    </div>
                    <div class="tw-grow">
                        <second-parent
                            :errors="errors"
                            :registration="registration"
                            @canceled="resetRegistration"
                            @save="setAndSaveRegistration"
                        />
                    </div>
                </div>
            </div>
            <div class="main tw-mt-4 tw-w-full lg:tw-ml-10 lg:tw-mt-0">
                <MBox class="tw-flex tw-h-60 tw-flex-col tw-gap-6 tw-overflow-y-auto tw-p-5 sm:tw-h-40">
                    <div
                        v-for="(nurseryRegistration, key) in sortedNurseries"
                        :key="'sortedNursery' + key"
                    >
                        <deposited-in-nursery
                            :id="nurseryRegistration.id"
                            :accepted="nurseryRegistration.accepted"
                            :city="nurseryRegistration.city"
                            :declined="nurseryRegistration.declined"
                            :deposited="nurseryRegistration.deposited"
                            :kid_id="nurseryRegistration.kid_id"
                            :name="nurseryRegistration.name"
                            :permissions="nurseryRegistration.permissions"
                            :registration="registration"
                            :accepted-in-nurseries="acceptedInNurseries"
                            @estimate-invoice="estimateInvoice"
                            @refresh-nurseries="refreshNurseries"
                        />
                    </div>
                </MBox>
                <div class="tw-my-8 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2">
                    <MHeading>
                        {{ __('registration:registration_follow_up') }}
                    </MHeading>
                    <div class="tw-flex tw-items-center tw-gap-2">
                        <a
                            v-if="nursery.websites[0]"
                            :href="
                                'https://' + nursery.websites[0].slug + '.meeko.site/registration/' + registration.uuid
                            "
                            target="_blank"
                        >
                            <MTooltip :label="__('registration:link_to_modify_folder')">
                                <MButton>
                                    {{ __('registration:parent_link') }}
                                    <template #right-icons>
                                        <FontAwesomeIcon icon="fa-solid fa-share-square" />
                                    </template>
                                </MButton>
                            </MTooltip>
                        </a>
                        <TagsSelector
                            v-if="registrationModel"
                            :editable="$can('update', 'registrations')"
                            :taggable-model="registrationModel"
                            taggable-type="registration"
                            :tags="registrationModel.tags().value().toArray()"
                            @attached="registrationModel.tags().value().push($event)"
                            @detached="
                                registrationModel.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))
                            "
                        />
                    </div>
                    <div class="section-title" />
                </div>
                <activity-timeline :registration-model="registrationModel" />
            </div>
        </div>

        <edit-plannings
            ref="planning"
            :nursery="nursery"
            :opening-hours="nursery.openingHours"
            :registration="registration"
            @registration-updated="setAndSaveRegistration"
        />
        <estimate-invoice
            ref="estimateInvoice"
            :contract="contract"
            :kid="kid"
            :nursery="selectedNursery"
            :registration="registration"
            :user="user"
            @registration-updated="setRegistration"
        />
    </div>
    <div
        v-else
        key="loader"
    >
        <loader custom-class="la-2x" />
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import {intersection, keys, set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _find from 'lodash-es/find';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _reverse from 'lodash-es/reverse';
    import _sortBy from 'lodash-es/sortBy';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import useKidAge from '@/modules/family/composables/kid/useKidAge';
    import KidPlanningSummary from '@/modules/legacy/components/Modules/KidPlanningSummary.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import {url} from '@/modules/legacy/mixins/url';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import ActivityTimeline from '@/modules/registration/components/ActivityTimeline.vue';
    import EstimateInvoice from '@/modules/registration/components/Estimate/Modal.vue';
    import DepositedInNursery from '@/modules/registration/components/Partials/DepositedInNursery.vue';
    import ParentCard1 from '@/modules/registration/components/Partials/ParentCard.vue';
    import ParentCard2 from '@/modules/registration/components/Partials/ParentCard2.vue';
    import EditPlannings from '@/modules/registration/components/Planning/EditPlannings.vue';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default {
        components: {
            TagsSelector,
            ActivityTimeline,
            'first-parent': ParentCard1,
            'second-parent': ParentCard2,
            'deposited-in-nursery': DepositedInNursery,
            'edit-plannings': EditPlannings,
            'estimate-invoice': EstimateInvoice,
            'kid-planning-summary': KidPlanningSummary,
        },
        mixins: [url],
        props: ['user', 'nursery'],
        data: () => ({
            originalRegistration: {},
            registration: {},
            registrationModel: {},
            contract: {},
            kid: {},
            errors: {},
            loading: false,
            saveLoading: false,
            selectedNursery: null,
        }),
        computed: {
            sortedNurseries() {
                const nurseriesWithPermission = [];
                this.user.nurseries.forEach(nursery => {
                    if (this.$can('update', 'registrations', nursery.permissions)) {
                        const accountNursery = this.nurseries.find(item => item.id === nursery.id);
                        if (accountNursery) {
                            set(accountNursery, 'permissions', nursery.permissions);
                            nurseriesWithPermission.push(accountNursery);
                        }
                    }
                });

                return _reverse(_sortBy(nurseriesWithPermission, ['deposited', 'accepted']));
            },
            acceptedInNurseries() {
                return this.sortedNurseries.filter(nursery => nursery.accepted);
            },
            nurseries() {
                const output = [];

                _forEach(this.user.nurseries, nursery => {
                    const registrationNursery = _find(this.registration.nurseries, n => {
                        return n.id === nursery.id;
                    });

                    const tmp = {};
                    tmp.id = nursery.id;
                    tmp.name = nursery.name;
                    tmp.city = nursery.city;
                    tmp.deposited = false;
                    tmp.declined = false;
                    if (registrationNursery) {
                        tmp.deposited = true;
                        tmp.accepted = registrationNursery.kid_id !== null;
                        tmp.kid_id = registrationNursery.kid_id;
                        tmp.declined = registrationNursery.rejected === true;
                    }
                    output.push(tmp);
                });

                return output;
            },
            contractDurationInMonths() {
                const diff = Epoch.fromISOString(this.contract.started_at).difference(
                    Epoch.fromISOString(this.contract.ended_at),
                    'months'
                ).months;
                const diffToInt = Math.trunc(diff);

                // If the diff has floating value, we round up otherwise we round down
                return diff > diffToInt ? diffToInt + 1 : diffToInt;
            },
            willBeBornOnStart() {
                return Epoch.parse(this.kid.birthdate, 'yyyy-MM-dd').lessThan(
                    Epoch.parse(this.contract.started_at, 'yyyy-MM-dd')
                );
            },
            isKidBorn() {
                return Epoch.parse(this.kid.birthdate, 'yyyy-MM-dd').lessThan(Epoch.now());
            },
            contractStartedAtFormatted() {
                return Epoch.parse(this.contract.started_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT);
            },
            contractEndedAtFormatted() {
                return Epoch.parse(this.contract.ended_at, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT);
            },
            contractRequestAtFormatted() {
                return Epoch.parse(this.registration.requested_at, 'yyyy-MM-dd').toLocaleString(
                    Epoch.presets.DATE_SHORT
                );
            },
            kidBirthdateFormatted() {
                return Epoch.parse(this.kid.birthdate, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT);
            },
            humanKidAge() {
                if (this.willBeBornOnStart) {
                    return __(
                        this.registration.kid_gender === 'male'
                            ? 'common:welcome_at_age_male'
                            : 'common:welcome_at_age_female',
                        {
                            value: useKidAge().getByBirthdate(this.kid.birthdate, this.contract.started_at),
                        }
                    );
                }
                return __('family_kid:not_born_yet_on_start');
            },
        },
        watch: {
            '$route.params.registration': function () {
                this.init();
            },
            'registration': function (registration) {
                this.contract = {
                    id: registration.id,
                    billing_config_id: registration.billing_config_id,
                    started_at: registration.contract_started_at,
                    ended_at: registration.contract_ended_at,
                    recurrent_weeks: registration.contract_recurrent_weeks,
                    contract_recurrent_weeks: registration.contract_recurrent_weeks,
                    dependent_children: registration.contract_dependent_children,
                    handicapped_children: registration.contract_handicapped_children,
                    vacation_days: registration.contract_vacation_days,
                    plannings: registration.plannings,
                    type: 'recurrent',
                    cafpro_resources: registration.first_parent_salary + registration.second_parent_salary,
                    config: registration.config,
                };
                this.kid = {
                    id: 0,
                    first_name: registration.kid_first_name ? registration.kid_first_name : '',
                    last_name: registration.kid_last_name ? registration.kid_last_name : '',
                    hygiene: registration.kid_hygiene ? registration.kid_hygiene : 'baby',
                    meal_type: registration.kid_meal_type ? registration.kid_meal_type : 'milk',
                    birthdate: registration.kid_birthdate,
                };
            },
            'registration.first_parent_role': function (val) {
                this.setParentGenderFromRole(val, 'first_parent_gender');
            },
            'registration.second_parent_role': function (val) {
                this.setParentGenderFromRole(val, 'second_parent_gender');
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            useKidAge,
            hasError(errors, inputs) {
                return !!intersection(keys(errors), inputs).length;
            },
            init() {
                this.getRegistration();
            },
            userCanShowNursery(nursery_id) {
                return !!this.user.nurseries.find(item => item.id === nursery_id);
            },
            getRegistration() {
                this.loading = true;
                useApi()
                    .legacy.get(
                        route('registrations.show', {
                            registration: this.$route.params.registration,
                        })
                    )
                    .then(response => {
                        this.registration = response.data;
                        this.originalRegistration = _cloneDeep(response.data);

                        return this.getRegistrationModel(response.data.id);
                    })
                    .then(() => {
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            this.$router.push({name: 'registrations.index', params: {nursery: this.nursery.id}});
                        }
                    });
            },
            async getRegistrationModel(id) {
                this.registrationModel = await RegistrationModel.query()
                    .with(new RegistrationModel().organizations())
                    .with(new RegistrationModel().tags())
                    .find(id);
            },
            remove() {
                useMagicModal().deleteConfirmationModal({
                    title: __('registration:delete_registration'),
                    text: __('registration:registration_will_be_removed_in_all_organizations'),
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('registrations.destroy', {
                                    registration: this.registration.id,
                                })
                            )
                            .then(() => {
                                useNotification().success(__('registration:preregistration_deleted_successfully'));
                                this.$router.push({name: 'registrations.index', params: {nursery: this.nursery.id}});
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, function (value) {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },
            setAndSaveRegistration(registration) {
                this.registration = registration;
                this.saveRegistration();
            },
            setRegistration(registration) {
                this.registration = registration;
                this.$refs.planning.$refs.modal.hide();
            },
            refreshNurseries(nurseries) {
                this.registration.nurseries = nurseries;
            },
            checkPlaces() {
                this.$refs.checkPlaces.$refs.modal.show();
            },
            estimateInvoice(nurseryRegistration) {
                this.selectedNursery = nurseryRegistration;
                this.$refs.estimateInvoice.$refs.modal.show();
            },
            editPlanning() {
                this.$refs.planning.$refs.modal.show();
            },
            saveRegistration() {
                if (this.saveLoading) {
                    return;
                }

                this.saveLoading = true;
                const data = Object.assign({}, this.registration);

                if (!data.requested_at) {
                    data.requested_at = Epoch.now().toFormat('yyyy-MM-dd');
                }

                // Set dates to null if undefined because api will ignore them if undefined
                data.contract_started_at = data.contract_started_at || null;
                data.contract_ended_at = data.contract_ended_at || null;
                data.kid_birthdate = data.kid_birthdate || null;

                useApi()
                    .legacy.put(route('registrations.update', this.registration.id), data)
                    .then(async response => {
                        this.registration = response.data;
                        this.originalRegistration = _cloneDeep(response.data);
                        this.errors = {};
                        this.$refs.planning.$refs.modal.hide();

                        await this.getRegistrationModel(response.data.id);
                        useNotification().success(__('registration:registration_updated_successfully'));
                        setTimeout(() => {
                            this.saveLoading = false;
                        }, 1500);
                    })
                    .catch(error => {
                        this.saveLoading = false;
                        if (error && error.response && error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            setParentGenderFromRole(role, registrationParentGenderkey) {
                const females = ['mother', 'sister', 'aunt', 'stepmother', 'legal_custodian_female'];
                if (females.indexOf(role) >= 0) {
                    this.registration[registrationParentGenderkey] = 'female';
                } else {
                    this.registration[registrationParentGenderkey] = 'male';
                }
            },
            resetRegistration() {
                this.registration = _cloneDeep(this.originalRegistration);
            },
        },
    };
</script>

<style scoped>
    .h-arrow {
        height: 3px;
    }

    .section-title {
        @apply tw-h-1 tw-w-full tw-rounded-full tw-bg-blue-700 tw-opacity-25;
    }
</style>
