<script setup lang="ts">
    import {computed} from 'vue';

    import InvoiceStatusBadge from '@/modules/cashier/components/billing/invoice/atoms/InvoiceStatusBadge.vue';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

    const props = defineProps<{
        invoice: InvoiceModel | LegacyInvoiceType;
    }>();

    const no = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.no;
        }

        return props.invoice.no;
    });
</script>

<template>
    <div class="InvoiceMiniatureHeader">
        <MHeading level="h2">
            {{ no }}
        </MHeading>
        <InvoiceStatusBadge :invoice="invoice" />
    </div>
</template>

<style scoped>
    .InvoiceMiniatureHeader {
        @apply tw-flex tw-items-center tw-justify-between tw-gap-4;
    }
</style>
