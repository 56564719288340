<template>
    <div class="EditInvoiceInformations">
        <EditInvoiceInformationsAttributes
            class="EditInvoiceInformations__attributes-container"
            :editable="editable"
            :invoice="invoice"
            :nursery="nursery"
        />

        <div class="EditInvoiceInformations__address-container">
            <SelectAddress
                v-if="isNew && customer && can('update', 'invoices')"
                button-class="tw-mb-2"
                :customer-model="customer"
                @update:model-value="onAddressChanged"
            />
            <MTextarea
                v-if="editable && can('update', 'invoices')"
                v-model="invoice.recipient"
                class="tw-text-right"
                :placeholder="__('billing_invoice:name_address_etc')"
                rows="4"
            />
            <div
                v-else
                class="tw-whitespace-pre-line tw-break-words tw-text-right"
            >
                {{ invoice.recipient }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import EditInvoiceInformationsAttributes from '@/modules/cashier/components/billing/invoice/organisms/EditInvoiceInformationsAttributes.vue';
    import SelectAddress from '@/modules/cashier/components/core/address/organisms/SelectAddress.vue';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';

    export default defineComponent({
        components: {
            EditInvoiceInformationsAttributes,
            SelectAddress,
        },
        mixins: [invoice, nursery],
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            invoice: {
                type: Object,
                required: true,
            },
            isNew: {
                type: Boolean,
                default: false,
            },
            editable: {
                type: Boolean,
                required: true,
            },
            customer: {
                type: Object,
                default: null,
            },
        },
        setup(props) {
            const {can} = useAbility();

            function onAddressChanged(addressModel) {
                if (!addressModel) {
                    props.invoice.customer_address_id = null;
                    return;
                }

                props.invoice.customer_address_id = addressModel.id;

                const address = addressModel.attributes.address;

                props.invoice.recipient = '';
                props.invoice.recipient += addressModel.attributes.name;
                props.invoice.recipient += '\n';
                props.invoice.recipient += address.line1;
                if (address.line2) {
                    props.invoice.recipient += '\n' + address.line2;
                }
                props.invoice.recipient += '\n';
                props.invoice.recipient += address.postal_code + ' ' + address.city;
                props.invoice.recipient += '\n';
                props.invoice.recipient += address.state
                    ? address.state + ', ' + address.country_code
                    : address.country_code;
            }

            return {
                can,
                onAddressChanged,
            };
        },
    });
</script>

<style scoped>
    .EditInvoiceInformations {
        @apply tw-flex tw-w-full tw-flex-col tw-gap-4 md:tw-flex-row md:tw-items-center md:tw-justify-between;
        @apply tw-mt-10;
    }

    .EditInvoiceInformations__attributes-container {
        @apply tw-w-full md:tw-w-1/2;
    }

    .EditInvoiceInformations__address-container {
        @apply tw-rounded-lg tw-bg-blue-50 tw-p-2;
        @apply tw-w-full md:tw-w-1/3;
    }
</style>
