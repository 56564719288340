<template>
    <div class="MPagination">
        <div
            class="MPagination__item MPagination__navigate-wrapper"
            :class="{'MPagination__navigate-wrapper--disabled': isSamePage(1)}"
            @click="isSamePage(1) ? undefined : navigate(currentPage - 1)"
        >
            <div class="MPagination__navigate">
                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                <span>{{ __('common:actions.previous') }}</span>
            </div>
        </div>
        <div
            v-for="page in leftPages"
            :key="'l' + page"
            class="MPagination__item MPagination__button"
            :class="{'MPagination__button--active': isSamePage(page)}"
            @click="navigate(page)"
        >
            {{ page }}
        </div>
        <div
            v-if="midRange.left > leftRange.right"
            class="MPagination__item MPagination__gap"
        >
            …
        </div>
        <template v-for="page in midPages">
            <div
                v-if="true || (page > leftRange.right && page < rightRange.left)"
                :key="'m' + page"
                class="MPagination__item MPagination__button"
                :class="{'MPagination__button--active': isSamePage(page)}"
                @click="navigate(page)"
            >
                {{ page }}
            </div>
        </template>
        <div
            v-if="midRange.right < rightRange.left && rightRange.left > leftRange.right"
            class="MPagination__item MPagination__gap"
        >
            …
        </div>
        <template
            v-for="page in rightPages"
            :key="'r' + page"
        >
            <div
                class="MPagination__item MPagination__button"
                :class="{'MPagination__button--active': isSamePage(page)}"
                @click="navigate(page)"
            >
                {{ page }}
            </div>
        </template>
        <div
            class="MPagination__item MPagination__navigate-wrapper"
            :class="{'MPagination__navigate-wrapper--disabled': isSamePage(lastPage)}"
            :disabled="isSamePage(lastPage)"
            @click="isSamePage(lastPage) ? undefined : navigate(currentPage + 1)"
        >
            <div class="MPagination__navigate">
                <span>{{ __('common:actions.next') }}</span>
                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import type {JsonAPIPaginator} from '@meekohq/lumos';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import {round} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            paginator: {
                type: Object as PropType<JsonAPIPaginator | LengthAwarePaginator<any>>,
                required: true,
            },
            limit: {type: Number, default: 4},
        },
        emits: ['navigate'],
        setup(props, {emit}) {
            const selectedPage = ref();

            watch(
                () => props.paginator,
                () => {
                    selectedPage.value = undefined;
                },
                {deep: true}
            );

            const leftRange = computed(() => {
                if (lastPage.value <= 8) {
                    return {
                        left: 1,
                        right: lastPage.value,
                    };
                }

                if (currentPage.value <= 6) {
                    const right = currentPage.value + round(props.limit / 2);

                    return {
                        left: 1,
                        right: right > 5 ? right : 5,
                    };
                }

                return {
                    left: 1,
                    right: 2,
                };
            });

            const midRange = computed(() => {
                const left = currentPage.value - round(props.limit / 2);
                const right = currentPage.value + round(props.limit / 2);

                return {
                    left,
                    right,
                };
            });

            const rightRange = computed(() => {
                if (currentPage.value > lastPage.value - 6) {
                    const left = currentPage.value + round(props.limit / 2);

                    return {
                        left: left < lastPage.value - 4 ? left : lastPage.value - 4,
                        right: lastPage.value,
                    };
                }

                return {
                    left: lastPage.value - 1,
                    right: lastPage.value,
                };
            });

            const leftPages = computed(() => {
                const pages = [] as number[];

                for (let page = leftRange.value.left; page <= leftRange.value.right; page++) {
                    pages.push(page);
                }

                return pages;
            });

            const midPages = computed(() => {
                const pages: any[] = [];

                for (let page = midRange.value.left; page <= midRange.value.right; page++) {
                    if (page > leftRange.value.right && page < rightRange.value.left) {
                        pages.push(page);
                    }
                }

                return pages;
            });

            const rightPages = computed(() => {
                const pages: any[] = [];

                for (let page = rightRange.value.left; page <= rightRange.value.right; page++) {
                    if (page > leftRange.value.right) {
                        pages.push(page);
                    }
                }

                return pages;
            });

            const currentPage = computed(() => {
                if (props.paginator instanceof LengthAwarePaginator) {
                    return selectedPage.value ?? props.paginator.currentPage();
                } else {
                    return selectedPage.value ?? props.paginator.currentPage;
                }
            });

            const lastPage = computed(() => {
                if (props.paginator instanceof LengthAwarePaginator) {
                    return props.paginator.lastPage();
                } else {
                    return props.paginator.lastPage;
                }
            });

            const navigate = function (page) {
                if (selectedPage.value === page) {
                    return;
                }

                selectedPage.value = page;
                emit('navigate', page);
            };

            function isSamePage(page) {
                if (selectedPage.value) {
                    return selectedPage.value === page;
                }

                return currentPage.value === page;
            }

            return {
                leftRange,
                midRange,
                rightRange,
                leftPages,
                rightPages,
                navigate,
                midPages,
                currentPage,
                lastPage,
                selectedPage,
                isSamePage,
            };
        },
    });
</script>

<style scoped>
    .MPagination {
        @apply tw-flex tw-items-center;
        @apply md:tw-gap-1;
    }

    .MPagination__item {
        @apply tw-flex tw-items-center tw-justify-center;
        @apply tw-h-[32px] tw-cursor-pointer tw-select-none tw-rounded-md tw-px-2 tw-py-1;
        @apply tw-min-w-[32px] sm:tw-px-3;
    }

    .MPagination__navigate-wrapper {
        @apply tw-font-medium tw-text-blue-500;
        @apply tw-transition-all hover:tw-bg-blue-100;

        span {
            @apply tw-hidden md:tw-block;
        }
    }

    .MPagination__navigate-wrapper--disabled {
        @apply tw-cursor-default tw-text-gray-400;
        @apply hover:tw-bg-transparent;
    }

    .MPagination__navigate {
        @apply tw-flex tw-items-baseline tw-gap-1;

        i {
            @apply tw-text-sm;
        }
    }

    .MPagination__gap {
        @apply tw-cursor-default tw-text-gray-400;
        @apply tw-font-display;
    }

    .MPagination__button {
        @apply tw-cursor-pointer;
        @apply tw-text-black;
        @apply tw-transition-all hover:tw-bg-blue-100 hover:tw-text-blue-600;
        @apply tw-font-display;
    }

    .MPagination__button--active {
        @apply tw-bg-blue-500 tw-text-white hover:tw-bg-blue-500 hover:tw-text-white;
    }
</style>
