import {escapeRegExp} from 'lodash-es';

export default class TextHighlighter {
    highlight(search, body) {
        if (search === '') {
            return body;
        }

        if (!body) {
            return;
        }

        const regEx = new RegExp('(' + escapeRegExp(search) + ')', 'ig');

        // @ts-ignore
        return body.replace(regEx, '<span class="tw-rounded tw-bg-yellow-300">$1</span>');
    }
}
