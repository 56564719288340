import {ServiceProvider} from '@meekohq/lumos';

import {ApplyTemplateInteractor} from '@/modules/human-resources/apply-template/application/ApplyTemplateInteractor';
import {ApplyTemplateUseCaseBinding} from '@/modules/human-resources/apply-template/application/use-cases/ApplyTemplateUseCase';
import {ApplyTemplateRepositoryAdapter} from '@/modules/human-resources/apply-template/infrastructure/ApplyTemplateRepositoryAdapter';
import {GetOverlappingContractsInteractor} from '@/modules/human-resources/contract/application/GetOverlappingContractsInteractor';
import {GetOverlappingContractsUseCaseBinding} from '@/modules/human-resources/contract/application/GetOverlappingContractsUseCase';
import {SaveContractInteractor} from '@/modules/human-resources/contract/application/SaveContractInteractor';
import {SaveContractUseCaseBinding} from '@/modules/human-resources/contract/application/SaveContractUseCase';
import ContractRepositoryAdapter from '@/modules/human-resources/contract/infrastructure/ContractRepositoryAdapter';
import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import BalancePeriodModel from '@/modules/human-resources/models/BalancePeriodModel';
import BalanceTypeEventTypePivot from '@/modules/human-resources/models/BalanceTypeEventTypePivot';
import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
import BalanceTypeOrganizationPivot from '@/modules/human-resources/models/BalanceTypeOrganizationPivot';
import CalendarModel from '@/modules/human-resources/models/CalendarModel';
import ContractModel from '@/modules/human-resources/models/ContractModel';
import ContractOrganizationPivot from '@/modules/human-resources/models/ContractOrganizationPivot';
import ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import ContractTypeModel from '@/modules/human-resources/models/ContractTypeModel';
import ContractTypeOrganizationPivot from '@/modules/human-resources/models/ContractTypeOrganizationPivot';
import EventModel from '@/modules/human-resources/models/EventModel';
import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
import EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
import JobsModel from '@/modules/human-resources/models/JobModel';
import JobsOrganizationsPivot from '@/modules/human-resources/models/JobOrganizationPivot';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import StaffsOrganizationsPivot from '@/modules/human-resources/models/StaffsOrganizationsPivot';
import TeamModel from '@/modules/human-resources/models/TeamModel';
import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
import TeamStaffPivot from '@/modules/human-resources/models/TeamStaffPivot';
import TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import TemplateModel from '@/modules/human-resources/models/TemplateModel';
import TemplateOrganizationPivotModel from '@/modules/human-resources/models/TemplateOrganizationPivotModel';
import TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel';
import {TemplateRepositoryAdapter} from '@/modules/human-resources/template/infrastructure/TemplateRepositoryAdapter';
import JobList from '@/modules/human-resources/utils/job/JobList';
import {JobListContractBinding} from '@/modules/human-resources/utils/job/JobListContract';

export default class HumanResourcesServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            StaffModel,
            StaffsOrganizationsPivot,

            JobsModel,
            JobsOrganizationsPivot,
            ContractModel,
            ContractTrialPeriodModel,
            ContractOrganizationPivot,
            ContractTypeModel,
            ContractTypeOrganizationPivot,
            CalendarModel,

            EventModel,
            EventTypeModel,
            EventTypeOrganizationModel,

            TemplateModel,
            TemplatePlanningModel,
            TemplateEventModel,
            TemplateOrganizationPivotModel,

            TeamModel,
            TeamStaffPivot,
            TeamOrganizationPivot,

            BalanceTypeModel,
            BalanceTypeEventTypePivot,
            BalancePeriodModel,
            BalanceAllocationModel,
            BalanceTypeOrganizationPivot,
        ]);
    }

    public register() {
        this.app.singleton(JobListContractBinding, () => new JobList());

        this.app.bind(ApplyTemplateUseCaseBinding, () => {
            return new ApplyTemplateInteractor(new ApplyTemplateRepositoryAdapter(), new TemplateRepositoryAdapter());
        });

        this.app.bind(SaveContractUseCaseBinding, () => {
            return new SaveContractInteractor(new ContractRepositoryAdapter());
        });

        this.app.bind(GetOverlappingContractsUseCaseBinding, () => {
            return new GetOverlappingContractsInteractor(new ContractRepositoryAdapter());
        });
    }
}
