import moment from 'moment';

export default function () {
    const formatDate = function (date: string | undefined, format = 'DD/MM/YYYY') {
        return date ? moment(date).locale('fr').format(format) : undefined;
    };

    return {
        formatDate,
    };
}
