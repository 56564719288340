import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

export default class KidError extends ErrorHandler {
    protected resolver(): any {
        return {
            family_id: {
                '0x2EAA809FB3': 'family_family:errors.must_select_family',
            },
        };
    }
}
