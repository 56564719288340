<template>
    <CListRow>
        <CInline align-y="baseline">
            <div class="tw-w-2/4">
                {{ jobModel.attributes.name }}
            </div>
            <div class="tw-w-1/4" />
            <div class="tw-flex tw-w-1/4">
                <JobsListItemMenu
                    class="tw--my-1 tw-ml-auto"
                    :job-model="jobModel"
                    :job-pivot-in-other-organization="isJobExistInOtherOrganization"
                >
                    <template #update>
                        <MButton
                            class="tw--my-1 tw-ml-auto"
                            :icon-edit="true"
                            :job-model="jobModel"
                            variant="link"
                            :zoom="true"
                            @click="$refs.updateModal.show()"
                        />
                    </template>
                </JobsListItemMenu>
                <UpdateJobModal
                    ref="updateModal"
                    :is-contracts-exist="isContractsExist"
                    :job-model="jobModel"
                    @job-deleted="$emit('removeJobInArray', $event)"
                    @pivot-deleted="$emit('removePivotInArray', $event)"
                />
            </div>
        </CInline>
    </CListRow>
</template>

<script>
    import {isEmpty} from 'lodash-es';

    import JobsListItemMenu from '@/modules/human-resources/components/job/List/JobsListItemMenu.vue';
    import UpdateJobModal from '@/modules/human-resources/components/job/Modals/UpdateJobModal.vue';
    import Manager from '@/modules/legacy/store/manager.store';

    export default {
        components: {JobsListItemMenu, UpdateJobModal},

        props: {
            jobModel: {
                required: true,
            },
            pivots: {
                required: true,
            },
        },
        emits: ['removeJobInArray', 'removePivotInArray'],

        data() {
            return {
                Manager,
            };
        },

        computed: {
            isContractExistInOrganization() {
                return !!this.jobModel.relationships.contracts
                    .all()
                    .find(item => item.attributes.organization_id === Manager.activeOrganization.id);
            },

            isContractsExist() {
                return !isEmpty(this.jobModel?.relationships?.contracts);
            },

            isContractExistInOtherOrganization() {
                return this.jobModel.relationships.contracts
                    .all()
                    .filter(item => item.attributes.organization_id !== Manager.activeOrganization.id);
            },

            isJobExistInOtherOrganization() {
                return this.pivots
                    .reject(item => item.attributes.organization_id === Manager.activeOrganization.id)
                    .filter(pivot => pivot.attributes.job_id === this.jobModel.id);
            },
        },
    };
</script>
