import type {NotifiableError} from '@bugsnag/js';
import Bugsnag from '@bugsnag/js';
import {ServiceProvider} from '@meekohq/lumos';

import {KidCopyRepositoryAdapter} from '@/modules/family/adapters/KidCopyRepositoryAdapter';
import {KidCopyInputPortBinding} from '@/modules/family/domain/ports/KidCopyInputPort';
import {KidCopyInteractor} from '@/modules/family/domain/use-cases/KidCopyInteractor';
import FamilyCustomerPivot from '@/modules/family/models/FamilyCustomerPivot';
import FamilyMemberPivot from '@/modules/family/models/FamilyMemberPivot';
import FamilyModel from '@/modules/family/models/FamilyModel';
import FamilyOrganizationPivot from '@/modules/family/models/FamilyOrganizationPivot';
import KidMemberPivot from '@/modules/family/models/KidMemberPivot';
import KidModel from '@/modules/family/models/KidModel';
import MemberModel from '@/modules/family/models/MemberModel';
import MemberOrganizationPivot from '@/modules/family/models/MemberOrganizationPivot';
import MigrateKidIndexFiltersStorage from '@/modules/family/utils/kid/MigrateKidIndexFiltersStorage';

export default class FamilyServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([
            KidModel,
            KidMemberPivot,

            FamilyModel,
            FamilyMemberPivot,
            FamilyCustomerPivot,
            FamilyOrganizationPivot,

            MemberModel,
            MemberOrganizationPivot,
        ]);

        try {
            const migrateKidIndexFiltersStorage = new MigrateKidIndexFiltersStorage();
            migrateKidIndexFiltersStorage.migrateContracts();
            migrateKidIndexFiltersStorage.migrateGroups();
            migrateKidIndexFiltersStorage.migrateTags();
        } catch (e) {
            Bugsnag.notify(e as NotifiableError);
        }
    }

    public register() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.app.bind(KidCopyInputPortBinding, (app, presenter) => {
            return new KidCopyInteractor(new KidCopyRepositoryAdapter(), presenter);
        });
    }
}
