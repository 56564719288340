<template>
    <div
        class="CFormDatePicker"
        :class="[dropdownClasses]"
    >
        <MPopover2>
            <MPopover2ClickableReference
                v-slot="{open, close}"
                ref="myButton"
                :disabled="disabled"
            >
                <CInput
                    v-if="isEditing"
                    v-model="textDate"
                    :disabled="disabled"
                    :has-error="hasError"
                    :placeholder="time ? 'jj/mm/aaaa --:--' : 'jj/mm/aaaa'"
                    :readonly="disabled"
                    type="input"
                    @change="parseOnBlur"
                    @keydown.enter.prevent="open()"
                    @keydown.space.prevent="preventSpace($event, open)"
                    @keydown.tab="close()"
                />
                <CHStack
                    v-else
                    align="center"
                    class="CInput CInput--light"
                    gap="2"
                >
                    <CText :variant="modelValue && !disabled ? '' : 'disabled'">
                        {{ modelValue ? date.format('DD/MM/YYYY') : 'jj/mm/aaaa' }}
                    </CText>
                    <CBadge
                        v-if="time"
                        class="tw-leading-normal"
                        size="sm"
                        :variant="modelValue && !disabled ? 'blue' : 'gray'"
                    >
                        {{ modelValue ? date.format('HH:mm') : '--:--' }}
                    </CBadge>
                    <FontAwesomeIcon
                        v-if="showClearValueButton && modelValue"
                        class="CFormDatePicker__reset-icon"
                        icon="fa-solid fa-xmark"
                        @click.stop="$emit('update:modelValue', emptyValueIsUndefined ? undefined : null)"
                    />
                </CHStack>
            </MPopover2ClickableReference>
            <MPopover2Panel
                v-slot="{close}"
                @opened="onShown"
            >
                <div class="tw-flex tw-justify-between">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex tw-text-lg tw-text-gray-500">
                            <div
                                class="tw-cursor-pointer tw-px-1 tw-text-base hover:tw-text-blue-500"
                                @click="previous"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                            </div>
                            <div
                                class="tw-cursor-pointer tw-px-1 tw-text-base hover:tw-text-blue-500"
                                @click="next"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </div>
                        </div>
                        <div
                            v-if="view === 'month'"
                            class="tw-cursor-pointer tw-rounded tw-px-1 hover:tw-bg-blue-200 hover:tw-text-blue-600"
                            @click="view = 'year'"
                        >
                            <span class="tw-mr-1 tw-text-xl tw-font-semibold tw-capitalize tw-text-black">
                                {{ currentMonthName }}
                            </span>
                            <span class="tw-ml-1 tw-text-xl tw-text-gray-700">{{ date.format('Y') }}</span>
                        </div>
                        <div
                            v-else
                            class="tw-px-1 tw-text-xl tw-font-semibold tw-text-black"
                        >
                            {{ date.format('Y') }}
                        </div>
                    </div>
                    <MButton
                        size="sm"
                        variant="link"
                        @click="setNow()"
                    >
                        Aujourd'hui
                    </MButton>
                </div>
                <div
                    v-if="view === 'year'"
                    class="tw-mt-2 tw-grid tw-grid-flow-row tw-grid-cols-3 tw-grid-rows-3 tw-gap-1"
                >
                    <div
                        v-for="(month, index) in monthsInYear"
                        :key="index"
                        :class="getMonthClasses(index)"
                        @click="selectDate(date.clone().month(index), () => (view = 'month'))"
                    >
                        <span class="tw-text-base tw-capitalize">{{ month }}</span>
                    </div>
                </div>

                <div
                    v-if="view === 'month'"
                    class="tw-mt-2"
                >
                    <div
                        class="tw-flex"
                        style="height: 11rem"
                    >
                        <div>
                            <div class="tw-flex tw-h-full tw-items-end">
                                <div>
                                    <div class="tw-mr-2 tw-grid tw-w-4 tw-grid-flow-row tw-grid-rows-7 tw-gap-1">
                                        <div
                                            class="tw-h-5"
                                            :style="{'margin-bottom': '3px'}"
                                        />
                                        <div
                                            v-for="(number, i) in weekNumbers"
                                            :key="i"
                                            class="tw-h-5 tw-text-right tw-text-sm tw-leading-4 tw-text-gray-500"
                                        >
                                            {{ number }}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="tw-grid tw-grid-flow-row tw-grid-cols-7 tw-gap-1">
                                        <div
                                            v-for="(day, i) in weekDays"
                                            :key="i"
                                            class="tw-h-5 tw-text-center tw-text-sm tw-font-semibold tw-capitalize"
                                        >
                                            {{ day }}
                                        </div>
                                    </div>
                                    <div class="tw-mt-2 tw-grid tw-grid-flow-row tw-grid-cols-7 tw-gap-1">
                                        <div
                                            v-for="(day, i) in daysInMonths"
                                            :key="i"
                                            :class="getDayClasses(day)"
                                            @click="selectDate(day, () => hide(close))"
                                        >
                                            <span class="tw-text-base">{{ day.format('D') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="time"
                            class="tw-ml-4 tw-flex tw-space-x-1"
                        >
                            <CList
                                class="tw-h-full tw-overflow-auto"
                                :striped="true"
                            >
                                <CListRow
                                    v-for="hour in hours"
                                    :key="'h' + hour.hour()"
                                    :ref="el => setHourRef(el, 'h' + hour.hour())"
                                    :active="date.hour() === hour.hour()"
                                    size="sm"
                                    @click="selectDate(hour)"
                                >
                                    <div class="tw-px-2 tw-text-center">
                                        {{ hour.format('HH') }}
                                    </div>
                                </CListRow>
                            </CList>
                            <CList
                                class="tw-h-full tw-overflow-auto"
                                :striped="true"
                            >
                                <CListRow
                                    v-for="minute in minutes"
                                    :key="'m' + minute.minute()"
                                    :ref="el => setMinuteRef(el, 'm' + minute.minute())"
                                    :active="date.minute() === minute.minute()"
                                    size="sm"
                                    @click="selectDate(minute)"
                                >
                                    <div class="tw-px-2 tw-text-center">
                                        {{ minute.format('mm') }}
                                    </div>
                                </CListRow>
                            </CList>
                        </div>
                    </div>
                </div>
            </MPopover2Panel>
        </MPopover2>
    </div>
</template>

<script lang="ts">
    import {onClickOutside} from '@vueuse/core';
    import {defineComponent, nextTick, onBeforeUpdate, ref, toRef, toRefs, watch} from 'vue';

    import useDatePickerProvider from '@/modules/coherence-ui/hooks/useDatePickerProvider';
    import useDatePickerState from '@/modules/coherence-ui/hooks/useDatePickerState';
    import useDatePickerStyle from '@/modules/coherence-ui/hooks/useDatePickerStyle';
    import Scroller from '@/modules/legacy/helpers/scroller.helper';

    export default defineComponent({
        props: {
            modelValue: {
                type: String,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            time: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false,
            },
            dropdownClass: {
                type: String,
                default: '',
            },
            hoursStep: {
                type: Number,
                default: 1,
            },
            minutesStep: {
                type: Number,
                default: 1,
            },
            format: {
                type: String,
                default: 'iso8601',
            },
            modifier: {
                type: String,
                default: 'startOfDay',
            },
            minDate: {
                type: String,
                default: undefined,
            },
            maxDate: {
                type: String,
                default: undefined,
            },
            syncDateByMinOrMax: {
                type: Boolean,
                default: false,
            },
            emptyValueIsUndefined: {
                type: Boolean,
                default: false,
            },
            showClearValueButton: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const propsValue = toRef(props, 'modelValue');
            const propsRefs = toRefs(props);

            // If the prop emptyValueIsUndefined is true, the empty value will be undefined instead of null
            const emptyValue = props.emptyValueIsUndefined ? undefined : null;

            const hourElements = ref<Record<string, Element>>({});
            const minuteElements = ref<Record<string, Element>>({});

            onBeforeUpdate(() => {
                hourElements.value = {};
                minuteElements.value = {};
            });

            function setHourRef(el: Element, key: string) {
                if (el) {
                    hourElements.value[key] = el;
                }
            }

            function setMinuteRef(el: Element, key: string) {
                if (el) {
                    minuteElements.value[key] = el;
                }
            }

            const {date, textDate, isEditing, setIsEditing, parseDate, setNow, selectDate} = useDatePickerState(
                propsValue,
                propsRefs,
                emit,
                scrollToRef,
                emptyValue
            );

            const {
                view,
                weeksPerMonth,
                daysInMonths,
                weekNumbers,
                monthsInYear,
                currentMonthName,
                countDayInMonth,
                weekDays,
                hours,
                minutes,
                previous,
                next,
            } = useDatePickerProvider(date, {
                time: props.time,
                hoursStep: props.hoursStep,
                minutesStep: props.minutesStep,
            });

            const myButton = ref();

            onClickOutside(myButton, () => setIsEditing(false));

            const {dropdownClasses, getDayClasses, getMonthClasses} = useDatePickerStyle(
                date,
                toRef(props, 'minDate'),
                toRef(props, 'maxDate'),
                toRef(props, 'syncDateByMinOrMax'),
                {dropdownClass: props.dropdownClass}
            );

            function onShown() {
                parseDate(propsValue.value);
                scrollToRef();
                setIsEditing(true);
            }

            function hide(closeFn: () => void) {
                setIsEditing(false);
                closeFn();
            }

            function parseOnBlur(event) {
                parseDate(event.target.value);
                setIsEditing(false);
            }

            function preventSpace(event, openFn: () => void) {
                parseDate(event.target.value);
                openFn();
            }

            function scrollToRef() {
                Scroller.scrollToRef(hourElements.value['h' + date.value.hour()], {block: 'center'});
                Scroller.scrollToRef(minuteElements.value['m' + date.value.minute()], {block: 'center'});
            }

            watch(
                () => view.value,
                () => {
                    nextTick(() => {
                        scrollToRef();
                    });
                }
            );

            return {
                myButton,

                date,
                isEditing,
                setIsEditing,
                parseDate,
                setNow,
                selectDate,

                view,
                weeksPerMonth,
                textDate,
                daysInMonths,
                weekNumbers,
                monthsInYear,
                currentMonthName,
                countDayInMonth,
                weekDays,
                hours,
                minutes,
                previous,
                next,

                dropdownClasses,
                getDayClasses,
                getMonthClasses,

                hide,
                onShown,
                parseOnBlur,
                preventSpace,

                hourElements,
                minuteElements,
                setHourRef,
                setMinuteRef,
            };
        },
    });
</script>

<style scoped>
    .CFormDatePicker {
        display: block;
    }

    .CInput {
        @apply tw-h-[28px] tw-px-3;
    }

    .CFormDatePicker__reset-icon {
        @apply tw--mr-1.5 tw-ml-auto;
        @apply tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded tw-text-sm tw-text-gray-400;
        @apply tw-transition-all hover:tw-cursor-pointer hover:tw-bg-gray-200 hover:tw-text-gray-500;
    }
</style>
