import {ServiceProvider} from '@meekohq/lumos';

import MedicalActionModel from '@/modules/health/models/MedicalActionModel';
import MedicalTreatmentModel from '@/modules/health/models/MedicalTreatmentModel';

export default class HealthServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([MedicalTreatmentModel, MedicalActionModel]);
    }
}
