<script setup lang="ts">
    import {ref} from 'vue';

    import MCalendar from '@/modules/meeko-ui/components/MCalendar/MCalendar.vue';
    import type {MCalendarPreset} from '@/modules/meeko-ui/components/MCalendar/useMCalendarPresets';
    import MCalendarInput from '@/modules/meeko-ui/components/MCalendarInput/MCalendarInput.vue';
    import type {DateTimeModifierSingle} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import type {DateTimeSingle} from '@/modules/meeko-ui/types/DateTime';

    const props = withDefaults(
        defineProps<{
            modelValue: DateTimeSingle;
            modifier?: DateTimeModifierSingle;
            presets?: MCalendarPreset[];
            overridePresets?: boolean;
            hidePresets?: boolean;
            hideOnClick?: boolean;
            disabled?: boolean;
            allowUndefined?: boolean;
        }>(),
        {
            modifier: undefined,
            presets: undefined,
            overridePresets: false,
            hidePresets: false,
            hideOnClick: true,
            disabled: false,
            allowUndefined: false,
        }
    );

    const emit = defineEmits<{
        (e: 'update:modelValue', value: DateTimeSingle): void;
    }>();

    const inputEl = ref<InstanceType<typeof MCalendarInput>>();

    function handleFocusChange(value: boolean, openFn: () => void, closeFn: () => void) {
        if (value) {
            openFn();

            return;
        }

        closeFn();
    }

    function handleCalendarInput(value: DateTimeSingle, closeFn: () => void) {
        emit('update:modelValue', value);

        if (props.hideOnClick) {
            closeFn();
        }
    }
</script>

<template>
    <MPopover2 class="MMonthPicker">
        <MPopover2ClickableReference
            v-slot="{open, close}"
            :disabled="disabled"
            manual
        >
            <MCalendarInput
                ref="inputEl"
                :allow-undefined="allowUndefined"
                :disabled="disabled"
                mode="month"
                :modifiers="modifier"
                :model-value="modelValue"
                @focus="handleFocusChange($event, open, close)"
                @update:model-value="emit('update:modelValue', $event)"
                @click="open"
            />
        </MPopover2ClickableReference>
        <MPopover2Panel
            v-slot="{close}"
            @closed="inputEl?.resetFocus()"
        >
            <MCalendar
                :allow-undefined="allowUndefined"
                :hide-presets="hidePresets"
                mode="month"
                :modifiers="modifier"
                :override-presets="overridePresets"
                :presets="presets"
                :model-value="modelValue"
                @update:model-value="handleCalendarInput($event, close)"
            />
        </MPopover2Panel>
    </MPopover2>
</template>

<style scoped>
    .MMonthPicker {
        display: inline-block;
    }
</style>
