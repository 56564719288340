<template>
    <router-link
        class="suggestion"
        :to="to"
    >
        <CHStack
            align="center"
            class="header tw-flex tw-items-center"
        >
            <CustomerAvatar
                class="avatar"
                :customer-model="customerModel"
            />
            <CVStack distribute="center">
                <div
                    class="title"
                    v-html="highlighted(suggestion.title)"
                />
                <CText
                    font-size="sm"
                    variant="gray"
                >
                    <span v-html="highlighted(suggestion.account_number)" />
                </CText>
            </CVStack>
        </CHStack>
        <div class="additionals">
            <dl class="additional tw-mb-0">
                <template
                    v-for="(value, i) in suggestion.additionals"
                    :key="i"
                >
                    <dt>{{ value.label }}</dt>
                    <dd v-html="highlighted(value.value)" />
                </template>
            </dl>
            <div class="tw-mt-3 tw-text-sm">
                <router-link
                    class="tw-text-gray-600 hover:tw-text-orange-500"
                    :to="{
                        name: 'cashier/customers.show.summary',
                        params: {
                            customer: suggestion.bindings.customer_id,
                            nursery: suggestion.bindings.nursery_id,
                        },
                    }"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-tachometer"
                    />
                    {{ __('common:summary') }}
                </router-link>
                <router-link
                    class="tw-ml-3 tw-text-gray-600 hover:tw-text-orange-500"
                    :to="{
                        name: 'cashier/customers.show.invoices',
                        params: {
                            customer: suggestion.bindings.customer_id,
                            nursery: suggestion.bindings.nursery_id,
                        },
                    }"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-file-invoice"
                    />
                    {{ __('common:invoice_other') }}
                </router-link>
                <router-link
                    class="tw-ml-3 tw-text-gray-600 hover:tw-text-orange-500"
                    :to="{
                        name: 'cashier/customers.show.payments',
                        params: {
                            customer: suggestion.bindings.customer_id,
                            nursery: suggestion.bindings.nursery_id,
                        },
                    }"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-money-check-edit-alt"
                    />
                    {{ __('common:payment_other') }}
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>
    import CustomerAvatar from '@/modules/app/components/atoms/avatars/CustomerAvatar.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';

    export default {
        components: {CustomerAvatar},
        props: ['suggestion', 'query'],
        computed: {
            to() {
                return {
                    name: 'cashier/customers.show.summary',
                    params: {
                        customer: this.suggestion.bindings.customer_id,
                        nursery: this.suggestion.bindings.nursery_id,
                    },
                };
            },
            customerModel() {
                const fakeCustomer = new CustomerModel();
                fakeCustomer.attributes.person = this.suggestion.person;

                return fakeCustomer;
            },
        },
        methods: {
            highlighted(value) {
                return useHighlighter().highlight(this.query, value);
            },
        },
    };
</script>
