<template>
    <div>
        <div
            v-if="staff.balanceAllocations().value().isNotEmpty()"
            class="tw-rounded-lg tw-bg-blue-100 tw-px-4 tw-py-1 tw-text-blue-900"
        >
            <div
                v-for="(allocation, index) in orderedAllocations"
                :key="allocation.attributes.id"
            >
                <div
                    v-if="index"
                    class="tw-my-2 tw-w-full tw-rounded-full tw-bg-blue-300/50"
                    style="height: 1px"
                />
                <CHStack
                    align="center"
                    class="tw-my-2"
                    gap="1"
                >
                    <div class="tw-flex tw-w-5/12">
                        <MTooltip
                            v-if="allocation.attributes.confirmed_at"
                            placement="bottom-start"
                        >
                            <CCheckbox
                                :model-value="!!allocation.attributes.confirmed_at"
                                @click="confirmedAllocation(allocation)"
                            >
                                {{
                                    allocationPeriodLabel(
                                        allocation.attributes.source_type === contractType
                                            ? allocation.computed.created_at
                                            : allocation.source().value().startedAtRaw,
                                        allocation.source().value().endedAtRaw,
                                        allocation.source().value().isFullDay
                                    )
                                }}
                            </CCheckbox>
                            <template #content>
                                {{
                                    __('hr_balance:verified_at', {
                                        date: fromISOString(allocation.attributes.confirmed_at).toLocaleString(
                                            Epoch.presets.DATETIME_SHORT
                                        ),
                                    })
                                }}
                            </template>
                        </MTooltip>
                        <div
                            v-else
                            class="tw-inline-block"
                        >
                            <CCheckbox
                                class="tw-font-semibold"
                                :model-value="!!allocation.attributes.confirmed_at"
                                @click="confirmedAllocation(allocation)"
                            >
                                {{
                                    allocationPeriodLabel(
                                        allocation.attributes.source_type === contractType
                                            ? allocation.computed.created_at
                                            : allocation.source().value().startedAtRaw,
                                        allocation.source().value().endedAtRaw,
                                        allocation.source().value().isFullDay
                                    )
                                }}
                            </CCheckbox>
                        </div>
                        <CText
                            v-if="allocation.attributes.source_type === contractType"
                            class="tw-pl-2"
                            font-weight="semibold"
                        >
                            {{
                                __('common:contract_of_the', {
                                    date: allocationPeriodLabel(
                                        allocation.source().value().attributes.started_at,
                                        null,
                                        allocation.source().value().isFullDay
                                    ),
                                })
                            }}
                        </CText>
                    </div>
                    <div class="tw-w-1/3">
                        <div class="balance-details tw-flex">
                            <div class="tw-bg-blue-400 tw-px-2 tw-py-0.5 tw-font-semibold tw-text-white">
                                {{
                                    __('hr_balance:used', {
                                        context: allocation.balanceType().value().attributes.unit,
                                        count: allocation.roundedValue,
                                    })
                                }}
                            </div>
                            <div class="tw-bg-blue-300/75 tw-px-2 tw-py-0.5 tw-font-semibold tw-text-blue-700">
                                {{ allocation.balanceType().value().attributes.name }}
                            </div>
                        </div>
                    </div>
                    <div class="tw-w-1/4">
                        <CText v-if="allocation.attributes.source_type === eventType">
                            {{ allocation.source().value().organization().value().attributes.name }}
                        </CText>
                        <CText
                            v-if="
                                allocation.attributes.source_type === contractType &&
                                allocation.source().value().organizations().value().count() === 1
                            "
                        >
                            {{ allocation.source().value().organizations().value().first().attributes.name }}
                        </CText>

                        <MTooltip
                            v-if="
                                allocation.attributes.source_type === contractType &&
                                allocation.source().value().organizations().value().count() > 1
                            "
                            placement="bottom-start"
                        >
                            {{ __('hr_balance:many_organizations') }}
                            <template #content>
                                <div
                                    v-for="orga in allocation.source().value().organizations().value().all()"
                                    :key="orga.attributes.id"
                                >
                                    {{ orga.attributes.name }}
                                </div>
                            </template>
                        </MTooltip>
                    </div>
                    <div class="tw-mr-3 tw-w-1/12 tw-text-right">
                        <MTooltip
                            v-if="allocation.source().value().exist && $can('update', 'staffs_planning')"
                            placement="bottom-start"
                        >
                            <MButton
                                variant="link"
                                @click="showSourceDetails(allocation.source().value())"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-search" />
                            </MButton>
                            <template #content>
                                {{ __('hr_balance:display_corresponding_event') }}
                            </template>
                        </MTooltip>
                    </div>
                </CHStack>
            </div>
        </div>
        <div
            v-else
            class="tw-rounded-lg tw-bg-blue-100 tw-p-4 tw-text-blue-900"
        >
            <CText>{{ __('common:no_data_to_display') }}</CText>
        </div>
    </div>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import moment from 'moment/moment';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import useEpoch from '@/modules/app/composables/useEpoch';
    import __ from '@/modules/app/utils/i18n-facade';
    import useAllocationPeriodDate from '@/modules/human-resources/components/balance/List/useAllocationPeriodDate';
    import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
    import ContractModel from '@/modules/human-resources/models/ContractModel';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default defineComponent({
        name: 'StaffAllocationsSummary',
        props: {
            staff: {type: Object as PropType<StaffModel>, required: true},
        },
        emits: ['showEvent'],
        setup(props, {emit}) {
            const {fromISOString} = useEpoch();
            const contractType = new ContractModel().type;
            const eventType = new EventModel().type;

            const orderedAllocations = computed(() =>
                props.staff
                    .balanceAllocations()
                    .value()
                    .sort((allocation1, allocation2) => {
                        const aStartedAt = allocation1.source().value().startedAtRaw;
                        const bStartedAt = allocation2.source().value().startedAtRaw;

                        return moment(aStartedAt).diff(moment(bStartedAt), 'hours');
                    })
            );

            function confirmedAllocation(allocation: BalanceAllocationModel) {
                if (allocation.attributes.confirmed_at) {
                    allocation.attributes.confirmed_at = null;
                } else {
                    allocation.attributes.confirmed_at = moment().toISOString(true);
                }

                allocation.save().then(() => {
                    useNotification().success(__('hr_balance:balance_updated_successfully'));
                });
            }

            // Generate period od dates formated for allocations
            const {allocationPeriodLabel} = useAllocationPeriodDate();

            function showSourceDetails(source: EventModel | ContractModel) {
                // Show event modal only if source is an event
                if (source instanceof EventModel) {
                    emit('showEvent', source);
                }
            }

            return {
                allocationPeriodLabel,
                confirmedAllocation,
                contractType,
                eventType,
                Epoch,
                fromISOString,
                moment,
                orderedAllocations,
                showSourceDetails,
            };
        },
    });
</script>

<style scoped>
    .balance-details {
        > div:first-child {
            @apply tw-rounded-l-md;
        }

        > div:last-child {
            @apply tw-rounded-r-md;
        }
    }
</style>
