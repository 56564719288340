<script setup lang="ts">
    import {computed, onMounted, ref} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import type {LegacyKidContractType} from '@/modules/family/utils/contract/LegacyKidContractType';
    import type {LegacyKidType} from '@/modules/family/utils/kid/LegacyKidType';
    import type {LegacyMemberType} from '@/modules/family/utils/member/LegacyMemberType';
    import {showCAF} from '@/modules/legacy/libs/organization';
    import type {LegacyOrganizationType} from '@/modules/organization/utils/LegacyOrganizationType';

    const props = defineProps<{
        organization: LegacyOrganizationType;
        kid: LegacyKidType;
        contract: LegacyKidContractType;
    }>();

    onMounted(() => {
        showDetail.value = props.contract.cafpro_resources === null;
    });

    const {can} = useAbility();
    const showDetail = ref(false);

    const firstParent = computed(() => {
        return props.kid.familyMembers.find(
            (item: LegacyMemberType) => item.id === props.contract.first_family_member_id
        );
    });

    const secondParent = computed(() => {
        return props.kid.familyMembers.find(
            (item: LegacyMemberType) => item.id === props.contract.second_family_member_id
        );
    });

    const showCaf = computed(() => {
        return showCAF(props.organization.country);
    });

    function getFamilyMemberNameById(id: string) {
        const familyMember = props.kid.familyMembers.find((item: LegacyMemberType) => item.id === id);

        return familyMember ? familyMember.first_name + ' ' + familyMember.last_name : null;
    }
</script>

<template>
    <MForm>
        <MFieldset
            v-if="showCaf"
            class="tw-rounded-md tw-bg-gray-50 tw-p-2"
        >
            <a
                href="https://partenaires.caf.fr/portal/auth/login"
                target="_blank"
            >
                <MButton
                    variant="link"
                    icon-external-link
                >
                    {{ __('family_contract:consult_benefits_folder') }}
                </MButton>
            </a>
            <div
                v-if="firstParent && firstParent.allocator_no"
                class="tw-mt-1 tw-text-base tw-capitalize tw-text-gray-700"
            >
                {{ $fullName(firstParent) }} {{ ' - ' + firstParent.allocator_no }}
            </div>
            <div
                v-if="secondParent && secondParent.allocator_no"
                class="tw-text-base tw-capitalize tw-text-gray-700"
            >
                {{ $fullName(secondParent) }} {{ ' - ' + secondParent.allocator_no }}
            </div>
        </MFieldset>
        <CFormTwoColumns>
            <MFieldset>
                <MLabel>{{ __('billing_core:annual_resources') }}</MLabel>
                <MInput
                    v-model.number="contract.cafpro_resources"
                    :disabled="!can('update', 'kids_contracts')"
                    :placeholder="__('billing_core:annual_resources')"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('common:year_one') }}</MLabel>
                <MInput
                    v-model.number="contract.resources_year"
                    :disabled="!can('update', 'kids_contracts')"
                    :placeholder="__('family_contract:resources_year')"
                />
            </MFieldset>
        </CFormTwoColumns>
        <CFormTwoColumns>
            <MFieldset>
                <MLabel>{{ __('common:dependent_children') }}</MLabel>
                <MInput
                    v-model.number="contract.dependent_children"
                    :disabled="!can('update', 'kids_contracts')"
                    :placeholder="__('family_contract:number_of_dependent_children')"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('family_contract:children_with_disabilities') }}</MLabel>
                <MInput
                    v-model.number="contract.handicapped_children"
                    :disabled="!can('update', 'kids_contracts')"
                    :placeholder="__('family_contract:number_of_children_with_disabilities')"
                />
            </MFieldset>
        </CFormTwoColumns>
        <MFieldset>
            <CDisclosure
                :open="showDetail"
                :title="__('common:view_detail')"
            >
                <CFormTwoColumns>
                    <MFieldset>
                        <MHeading class="tw-mb-2">
                            <template v-if="!contract.first_family_member_id">
                                {{ __('common:first_parent') }}
                            </template>
                            <template v-else>
                                {{ getFamilyMemberNameById(contract.first_family_member_id) }}
                            </template>
                        </MHeading>
                        <MLabel>{{ __('family_contract:annual_earning') }}</MLabel>
                        <MInput
                            v-model.number="contract.first_salary"
                            :disabled="!can('update', 'kids_contracts')"
                            :placeholder="__('family_contract:annual_earning')"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MHeading class="tw-mb-2">
                            <template v-if="!contract.second_family_member_id">
                                {{ __('common:second_parent') }}
                            </template>
                            <template v-else>
                                {{ getFamilyMemberNameById(contract.second_family_member_id) }}
                            </template>
                        </MHeading>
                        <MLabel>{{ __('family_contract:annual_earning') }}</MLabel>
                        <MInput
                            v-model.number="contract.second_salary"
                            :disabled="!can('update', 'kids_contracts')"
                            :placeholder="__('family_contract:annual_earning')"
                        />
                    </MFieldset>
                </CFormTwoColumns>
            </CDisclosure>
        </MFieldset>
    </MForm>
</template>
