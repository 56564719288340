<template>
    <div class="Sparklines">
        <template
            v-for="(nursery, i) in orderedNurseries"
            :key="'nursery' + i"
        >
            <div
                class="Sparklines__container"
                :class="[
                    {'Sparklines__container--selected': nursery.selected && nurseries.length > 1},
                    {'tw-cursor-pointer': nurseries.length > 1},
                ]"
                @click="selectNursery(nursery)"
            >
                <div class="Sparklines__header">
                    <MHeading
                        variant="white"
                        level="h4"
                        class="tw-truncate"
                    >
                        {{ nursery.name + ' - ' + nursery.city }}
                    </MHeading>
                    <MHeading
                        variant="white"
                        level="h3"
                    >
                        {{ nursery.average + ' ' + nursery.currency }}
                    </MHeading>
                </div>
                <loader
                    v-if="loading"
                    class="tw-p-0"
                    shadow="false"
                    size="sm"
                />
                <sparkline-chart
                    v-else
                    :chart-data="chartData"
                    :height="50"
                    :nursery="nursery"
                    :series="nursery.series"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import {set} from 'lodash-es';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _map from 'lodash-es/map';
    import _orderBy from 'lodash-es/orderBy';
    import _round from 'lodash-es/round';
    import _sumBy from 'lodash-es/sumBy';
    import moment from 'moment';

    import useApi from '@/modules/app/composables/useApi';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import SparklineChart from '@/modules/legacy/components/Modules/Charts/Sparkline.vue';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            SparklineChart,
        },
        props: {
            nursery: {
                type: Object,
            },
            nurseries: {
                type: Array,
            },
            selectedInvoiceType: {},
            fromDate: {},
            toDate: {},
        },
        emits: ['nurseryChanged'],
        data: () => ({
            chartData: {
                categories: [],
            },
            broadcast_id: Math.floor(100000 + Math.random() * 900000),
            loading: false,
        }),
        computed: {
            orderedNurseries() {
                return _orderBy(this.nurseries, 'name');
            },
        },
        watch: {
            selectedInvoiceType(val) {
                window.localStorage.setItem('sparklinesInvoiceType', val);
                this.getRevenues();
            },
            fromDate(val) {
                window.localStorage.setItem('sparklinesFromDate', val);
                this.getRevenues();
            },
            toDate(val) {
                window.localStorage.setItem('sparklinesToDate', val);
                this.getRevenues();
            },
        },
        mounted() {
            this.nurseries.forEach(nursery => {
                set(nursery, 'selected', false);
                set(nursery, 'average', 0);
                set(nursery, 'series', []);
            });
            const myNursery = this.nurseries.find(item => item.id === this.nursery.id);
            this.nurseries.length ? this.selectNursery(myNursery ? myNursery : this.nurseries[0]) : null;

            useBroadcast().sessionChannel.bind('billing-stats-' + this.broadcast_id, response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    response.data.forEach(item => {
                        const nursery = this.nurseries.find(nurs => nurs.id === item.nursery_id);
                        if (nursery) {
                            nursery.average = _round(_sumBy(item.stats, 'amount'), 2);
                            nursery.series = [
                                {
                                    name: '',
                                    data: _map(item.stats, 'amount'),
                                },
                            ];
                        }
                    });
                    this.rereshChartCategories();
                    this.loading = false;
                });
            });

            this.getRevenues();
        },
        beforeUnmount() {
            useBroadcast().sessionChannel.unbind('billing-stats-' + this.broadcast_id);
        },
        methods: {
            getRevenues() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                useApi()
                    .legacy.post(route('stats.sales'), {
                        from: this.fromDate,
                        to: this.toDate,
                        nurseries: _map(this.nurseries, 'id'),
                        type: this.selectedInvoiceType,
                        broadcast_id: this.broadcast_id,
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            rereshChartCategories() {
                this.chartData.categories = [];
                const dateStart = moment(this.fromDate);
                const dateEnd = moment(this.toDate);
                while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
                    this.chartData.categories.push(dateStart.format('MMMM YYYY'));
                    dateStart.add(1, 'month');
                }
            },

            selectNursery(nursery) {
                this.nurseries.forEach(item => {
                    item.selected = false;
                });
                nursery.selected = true;
                this.$emit('nurseryChanged', nursery);
            },
        },
    };
</script>

<style scoped>
    .Sparklines {
        @apply tw-flex tw-w-full tw-gap-4 tw-overflow-x-auto;
    }

    .Sparklines__container {
        @apply tw-w-full tw-min-w-64 tw-rounded-xl tw-px-px tw-py-4;
        background: linear-gradient(135deg, #6dc2ff 10%, #0396ff 100%);
    }

    .Sparklines__header {
        @apply tw-flex tw-flex-col tw-gap-1;
        @apply tw-min-w-0 tw-px-4;
    }

    .Sparklines__container--selected {
        background: linear-gradient(135deg, #9076fd 10%, #d187ff 100%);
    }
</style>
