<template>
    <div
        :class="[wrapperClass]"
        class="ResourceFinder"
    >
        <CFinder
            v-if="computedWrapper === 'ResourceFinderWrapper'"
            ref="finder"
            :class="['tw-min-w-56']"
            :best-match="bestMatch"
            :disabled="disabled"
            :display-select-all-options="displaySelectAllOptions"
            :has-more-result="hasMore && !loading"
            :has-unselect="hasUnselect"
            :hide-fallback-if-no-result="hideFallbackIfNoResult"
            :hide-selected-option="hideSelectedOption"
            :is-loading="loading"
            :multi="multi"
            :multi-minimum="multiMinimum"
            :options="prepareOptions"
            ref-path="id"
            :search-bar="searchBar"
            :unselect-value="unselectValue"
            :model-value="modelValue"
            @best-match="$emit('bestMatch', $event)"
            @fallback="$emit('fallback')"
            @update:model-value="onChange"
            @search="updateSearchValue"
            @want-more="fetchNextModels"
        >
            <template
                v-if="$slots['list-header']"
                #list-header
            >
                <slot name="list-header" />
            </template>

            <template
                v-if="$slots.bestMatch"
                #bestMatch="{searchValue}"
            >
                <slot
                    :best-match="bestMatch"
                    name="bestMatch"
                    :search-value="searchValue"
                />
            </template>
            <template
                v-if="$slots.fallback"
                #fallback="{searchValue}"
            >
                <slot
                    name="fallback"
                    :search-value="searchValue"
                />
            </template>

            <template
                v-if="$slots['unselect-item']"
                #unselect-item
            >
                <slot name="unselect-item" />
            </template>

            <template
                v-if="$slots['unselect-text']"
                #unselect-text
            >
                <slot name="unselect-text" />
            </template>

            <template #default="{option, searchValue}">
                <slot
                    :option="option"
                    :search-value="searchValue"
                />
            </template>

            <template
                v-if="$slots['empty-result']"
                #empty-result
            >
                <slot name="empty-result" />
            </template>
        </CFinder>
        <MPopover2
            v-else
            :bus="bus"
        >
            <MPopover2ClickableReference manual>
                <slot
                    :is-array="Array.isArray(modelValue)"
                    name="trigger"
                    :toggle="toggle"
                    :value="modelValue"
                >
                    <MButton
                        align="left"
                        :class="buttonClass"
                        :disabled="buttonDisabled"
                        :icon-sort="true"
                        class="tw-w-full"
                        :size="buttonSize"
                        :variant="buttonVariant"
                        @click="toggle"
                    >
                        <template v-if="multi">
                            <slot
                                v-if="modelValue.length"
                                :lang-var="__('components:count_item', {count: modelValue.length})"
                                name="button-text-multi"
                                :values="modelValue"
                            >
                                VALUE
                            </slot>
                            <slot
                                v-else
                                name="button-text-empty"
                            >
                                {{ fallBackText }}
                            </slot>
                        </template>
                        <template v-else>
                            <slot
                                v-if="modelValue"
                                name="button-text"
                                :value="modelValue"
                            >
                                VALUE
                            </slot>
                            <slot
                                v-else
                                name="button-text-empty"
                            >
                                {{ fallBackText }}
                            </slot>
                        </template>
                    </MButton>
                </slot>
            </MPopover2ClickableReference>
            <MPopover2Panel
                panel-class="tw-min-w-56"
                no-padding
                @opened="onShown"
            >
                <CFinder
                    ref="finder"
                    :best-match="bestMatch"
                    :disabled="disabled"
                    :display-select-all-options="displaySelectAllOptions"
                    :has-more-result="hasMore && !loading"
                    :has-unselect="hasUnselect"
                    :hide-fallback-if-no-result="hideFallbackIfNoResult"
                    :hide-selected-option="hideSelectedOption"
                    :is-loading="loading"
                    :multi="multi"
                    :multi-minimum="multiMinimum"
                    :options="prepareOptions"
                    ref-path="id"
                    :search-bar="searchBar"
                    :unselect-value="unselectValue"
                    :model-value="modelValue"
                    @best-match="$emit('bestMatch', $event)"
                    @fallback="$emit('fallback')"
                    @update:model-value="onChange"
                    @keydown.tab="hide"
                    @search="updateSearchValue"
                    @want-more="fetchNextModels"
                >
                    <template
                        v-if="$slots['list-header']"
                        #list-header
                    >
                        <slot name="list-header" />
                    </template>

                    <template
                        v-if="$slots.bestMatch"
                        #bestMatch="{searchValue}"
                    >
                        <slot
                            :best-match="bestMatch"
                            name="bestMatch"
                            :search-value="searchValue"
                        />
                    </template>
                    <template
                        v-if="$slots.fallback"
                        #fallback="{searchValue}"
                    >
                        <slot
                            name="fallback"
                            :search-value="searchValue"
                        />
                    </template>

                    <template
                        v-if="$slots['unselect-item']"
                        #unselect-item
                    >
                        <slot name="unselect-item" />
                    </template>

                    <template
                        v-if="$slots['unselect-text']"
                        #unselect-text
                    >
                        <slot name="unselect-text" />
                    </template>

                    <template #default="{option, searchValue}">
                        <slot
                            :option="option"
                            :search-value="searchValue"
                        />
                    </template>

                    <template
                        v-if="$slots['empty-result']"
                        #empty-result
                    >
                        <slot name="empty-result" />
                    </template>
                </CFinder>
            </MPopover2Panel>
        </MPopover2>
    </div>
</template>

<script lang="ts">
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import {debounce, isArray} from 'lodash-es';
    import {computed, defineComponent, onMounted, type PropType, ref} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import {defineFloatingContextBus} from '@/modules/meeko-ui/components/MFloatingContext';

    export default defineComponent({
        props: {
            modelValue: {},
            builder: {type: Object as PropType<QueryBuilder<any>>, required: true},
            queryParams: {type: Object as PropType<Record<string, unknown>>, default: undefined, required: false},
            bestMatchBuilder: {type: Object as PropType<QueryBuilder<any>>, required: false},
            optionsCallback: {
                type: Function as PropType<(models: Model[]) => any[]>,
                default: undefined,
                required: false,
            },
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            disabled: {type: Boolean, default: false},
            hasUnselect: {type: Boolean, default: false},
            fallBackText: {type: String, default: () => __('common:actions.select')},
            searchBar: {type: Boolean, default: true},
            hideSelectedOption: {type: Boolean, default: false},
            displaySelectAllOptions: {type: Boolean, default: true},
            hideFallbackIfNoResult: {type: Boolean, default: false},
            unselectValue: {},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            buttonSize: {},
            preventHideOnClick: {type: Boolean as PropType<boolean>, default: false},
            stopPropagation: {type: Boolean, default: false},
            preventDefault: {type: Boolean, default: false},
            buttonDisabled: {type: Boolean as PropType<boolean>, default: false},
            wrapper: {
                type: String as PropType<'MPopover2' | 'ResourceFinderWrapper'>,
                default: 'MPopover2',
            },
        },
        emits: ['update:modelValue', 'search', 'bestMatch', 'fallback'],
        setup(props, {emit}) {
            const finderRef = ref();

            const models = ref<Model[]>([]);
            const bestMatch = ref();
            const currentPage = ref(1);
            const hasMore = ref(true);
            const loading = ref(false);

            const computedWrapper = computed(() => {
                if (props.wrapper === 'ResourceFinderWrapper') {
                    return 'ResourceFinderWrapper';
                } else {
                    return 'MPopover2';
                }
            });

            const prepareOptions = computed(() => {
                if (props.optionsCallback) {
                    return props.optionsCallback(models.value as any);
                }

                return models.value;
            });

            function toggle() {
                bus.emit('toggle');
            }

            function updateSearchValue(value) {
                emit('search', value);
                fetchModelsDebounced();
            }

            function fetchModels(page = 1): void {
                loading.value = true;

                if (bestMatch.value) {
                    props.builder.where('id', '!=', bestMatch.value.id);
                }

                props.builder.paginate(100, page, true, {params: props.queryParams}).then(paginator => {
                    loading.value = false;

                    currentPage.value = paginator.currentPage();
                    hasMore.value = paginator.hasMorePages();

                    if (paginator.onFirstPage()) {
                        models.value = paginator.items().all();
                    } else {
                        models.value = models.value.concat(paginator.items().all());
                    }
                });
            }

            const fetchModelsDebounced = debounce(fetchModels, 300);

            function fetchNextModels() {
                fetchModels(currentPage.value + 1);
            }

            function onChange(values: any | any[]) {
                if (!props.multi) {
                    bus.emit('close');
                }

                emit('update:modelValue', values);
            }

            function hide() {
                bus.emit('close');
            }

            function show() {
                bus.emit('open');
            }

            function onShown() {
                emit('search', '');
                if (props.bestMatchBuilder) {
                    props.bestMatchBuilder.first().then(value => {
                        bestMatch.value = value;
                        fetchModels();
                    });
                } else {
                    fetchModels();
                }
            }

            onMounted(() => {
                if (props.wrapper === 'ResourceFinderWrapper') {
                    onShown();
                }
            });

            const bus = defineFloatingContextBus();

            return {
                bus,
                finderRef,
                toggle,
                hide,
                show,
                hasMore,
                prepareOptions,
                onChange,
                updateSearchValue,
                fetchNextModels,
                loading,
                isArray,
                onShown,
                bestMatch,
                computedWrapper,
            };
        },
    });
</script>

<style scoped>
    .ResourceFinder {
        display: inline-block;
    }
</style>
