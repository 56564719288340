<script setup lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {computed, toRef} from 'vue';

    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import UpdateAddressModal from '@/modules/cashier/components/core/address/organisms/UpdateAddressModal.vue';
    import type AddressModel from '@/modules/cashier/models/AddressModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type OrderModel from '@/modules/cashier/models/OrderModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    const props = defineProps<{
        customerModel: CustomerModel;
        addressModel: AddressModel;
    }>();

    const emit = defineEmits<{
        (e: 'deleted', value: AddressModel): void;
    }>();

    const customerModel = toRef(props, 'customerModel');
    const addressModel = toRef(props, 'addressModel');

    const orders = computed((): Collection<OrderModel> => {
        return props.addressModel.orders().value();
    });

    function setDefaultBillingAddress() {
        customerModel.value.attributes.default_billing_address_id = addressModel.value.id;
        customerModel.value.save();
    }

    async function tryToDelete() {
        if (orders.value.isEmpty()) {
            await useMagicModal().deleteConfirmationModal({
                text: __('billing_core:delete_address_warning'),
                onConfirm: async () => {
                    await props.addressModel.delete().then(() => {
                        useNotification().success(__('billing_core:address_deleted_successfully'));
                        emit('deleted', addressModel.value);
                    });
                },
            });
        }
    }

    const editModal = useModal({
        component: UpdateAddressModal,
        props: {
            addressModel: computed(() => addressModel.value.clone()),
        },
        listeners: () => ({
            update: (newAddressModel: AddressModel) => {
                newAddressModel.copyTo(props.addressModel);
            },
        }),
    });
</script>
<template>
    <div>
        <MMenu>
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    size="sm"
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('billing_core:set_as_default')"
                    @click="setDefaultBillingAddress"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-check"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('billing_setting:update_address')"
                    @click="editModal.show"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :disabled="orders.isNotEmpty()"
                    :label="__('billing_core:delete_address_dots')"
                    variant="danger"
                    @click="tryToDelete"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </div>
</template>
