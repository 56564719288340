<template>
    <div
        v-if="loading"
        class="tw-flex tw-flex-col tw-gap-2"
    >
        <MSkeleton class="tw-h-7 tw-w-36 !tw-bg-white/50"></MSkeleton>
        <MSkeleton class="tw-h-7 tw-w-36 !tw-bg-white/50"></MSkeleton>
    </div>
    <div
        v-else
        class="tw--mt-2"
    >
        <MButton
            v-for="(requestType, i) in filteredRequestTypes"
            :key="'requestTypes' + i"
            align="left"
            class="tw-mt-2 tw-w-full !tw-rounded-full"
            variant="light"
            @click="createRequestStage(requestType)"
        >
            <CIcon
                :path="requestType.icon.attributes.path"
                :provider="requestType.icon.attributes.provider"
                :style="{color: requestType.attributes.color}"
            />
            {{ requestType.attributes.name }}
        </MButton>
    </div>
</template>

<script lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref} from 'vue';

    import useRequestsStage from '@/modules/request/components/composables/useRequestsStage';
    import type RequestModel from '@/modules/request/models/RequestModel';
    import RequestTypeStageModel from '@/modules/request/models/RequestTypeStageModel';

    export default defineComponent({
        props: {
            request: {
                type: Object as PropType<RequestModel>,
                required: true,
            },
        },

        setup(props, context) {
            const requestTypes = ref<Collection<RequestTypeStageModel>>(collect());
            const loading = ref(false);

            const {createRequestStage} = useRequestsStage(props.request, context);

            onMounted(() => {
                getRequestTypesStages();
            });

            const filteredRequestTypes = computed(() => {
                const requestType = props.request.requestType().value();

                return requestTypes.value.filter(
                    item => requestType.attributes.default_request_type_stage_id !== item.id
                );
            });

            async function getRequestTypesStages() {
                try {
                    loading.value = true;
                    requestTypes.value = collect<RequestTypeStageModel>();

                    const requestType = props.request.requestType().value();
                    const query = RequestTypeStageModel.query()
                        .orderBy('name')
                        .whereHas('requestType', query2 => {
                            if (requestType) {
                                query2.where('id', requestType.getKey());
                            }
                        });
                    requestTypes.value = await query.get();
                    loading.value = false;
                } catch (e) {
                    loading.value = false;
                }
            }

            return {
                createRequestStage,
                filteredRequestTypes,
                loading,
            };
        },
    });
</script>
