<template>
    <list-wrapper :title="__('common:balances')">
        <template #action>
            <MTooltip :label="__('common:import_from_another_organization')">
                <MButton @click="$refs.importModal.show()">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-download" />
                    </template>
                    {{ __('common:actions.import') }}
                </MButton>
            </MTooltip>
            <MButton
                :icon-plus="true"
                :label="__('common:actions.new')"
                variant="primary"
                @click="$refs.createModal.show()"
            />
            <ImportBalanceTypeModal
                ref="importModal"
                @imported="$refs.list.fetchData()"
                @loading="$refs.list.loading = $event"
            />
            <CreateBalanceTypeModal
                ref="createModal"
                @created="$refs.list.addToResult($event)"
            />
        </template>
        <BalanceTypeList ref="list" />
    </list-wrapper>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import BalanceTypeList from '@/modules/human-resources/components/balance-type/List/BalanceTypeList.vue';
    import CreateBalanceTypeModal from '@/modules/human-resources/components/balance-type/Modals/CreateBalanceTypeModal.vue';
    import ImportBalanceTypeModal from '@/modules/human-resources/components/balance-type/Modals/ImportBalanceTypeModal.vue';

    export default defineComponent({
        components: {
            ImportBalanceTypeModal,
            CreateBalanceTypeModal,
            BalanceTypeList,
        },
        props: {},
        setup() {
            return {};
        },
    });
</script>
