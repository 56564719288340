<template>
    <planning-generator
        :closing="closing"
        :day="day"
        :events="data"
        inner-text
        :nursery="legacyNursery"
        :opening="opening"
        :show-clock="false"
        :show-divider="false"
        :show-menu="false"
        small
        :user="legacyUser"
    />
</template>

<script lang="ts">
    import type {Moment} from 'moment';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import usePlanningOpening from '@/modules/planning/composables/core/usePlanningOpening';
    import useRegistrationPlanning from '@/modules/planning/composables/core/useRegistrationPlanning';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export default defineComponent({
        props: {
            day: {
                type: Object as PropType<Moment>,
                required: true,
            },
            registration: {
                type: Object as PropType<RegistrationModel>,
                required: true,
            },
        },
        setup(props) {
            const {legacyNursery} = useManager();
            const {legacyUser} = useAuth();
            const {getOpening, getClosing} = usePlanningOpening();
            const {generatePlanning} = useRegistrationPlanning();

            const computedPlanning = computed(() => {
                return generatePlanning(props.registration, props.day);
            });

            const data = computed(() => {
                return computedPlanning.value;
            });

            const opening = computed(() => {
                return getOpening(legacyNursery.value.openingHours, computedPlanning.value, false, false);
            });

            const closing = computed(() => {
                return getClosing(legacyNursery.value.openingHours, computedPlanning.value, false, false);
            });

            return {
                closing,
                data,
                legacyNursery,
                legacyUser,
                opening,
            };
        },
    });
</script>
