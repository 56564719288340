import {collect, type Collection, Epoch, type QueryBuilder} from '@meekohq/lumos';
import _concat from 'lodash-es/concat';
import _debounce from 'lodash-es/debounce';
import _filter from 'lodash-es/filter';
import _forEach from 'lodash-es/forEach';
import _head from 'lodash-es/head';
import type {Moment} from 'moment';
import moment from 'moment';
import type {Ref} from 'vue';
import {computed, onBeforeMount, onMounted, onUnmounted, ref, watch} from 'vue';
import {useRouter} from 'vue-router';

import {EventBus} from '@/eventBus';
import useAbility from '@/modules/app/composables/useAbility';
import useApi from '@/modules/app/composables/useApi';
import useAuth from '@/modules/app/composables/useAuth';
import useBroadcast from '@/modules/app/composables/useBroadcast';
import usePrint from '@/modules/app/composables/usePrint';
import useEvent from '@/modules/human-resources/composables/calendar/useEvent';
import {filterEvents} from '@/modules/human-resources/composables/calendar/useEventsFilter';
import ContractModel from '@/modules/human-resources/models/ContractModel';
import EventModel from '@/modules/human-resources/models/EventModel';
import StaffModel from '@/modules/human-resources/models/StaffModel';
import type TeamModel from '@/modules/human-resources/models/TeamModel';
import {EventStatus, eventStatusPending} from '@/modules/human-resources/utils/calendar/Values/EventStatus';
import {daysBetweenDates, epochDaysBetweenDates, getClosing, getOpening} from '@/modules/legacy/libs/planning/planning';
import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
import route from '@/modules/legacy/libs/ziggy';
import ManagerStore from '@/modules/legacy/store/manager.store';
import useNotification from '@/modules/meeko-ui/composables/useNotification';
import getClosestOpeningDay from '@/modules/organization/utils/getClosestOpeningDay';

interface CalendarFilters {
    teams: TeamModel[];
    planningType: 'present' | 'active';
    group: string | null;
    includeOtherOrganizationsStaffs: boolean;
}

const defaultFilters = ref<CalendarFilters>({
    teams: [],
    planningType: 'present',
    group: null,
    includeOtherOrganizationsStaffs: true,
});

export default function (
    fromDate: Ref<Moment | null> = ref(null),
    toDate: Ref<Moment | null> = ref(null),
    staff: StaffModel | null = null,
    withOccupation = false,
    withSupervision = false,
    saveDate = false,
    filters = defaultFilters,
    staffListTimeGranularity: 'day' | 'week' = 'day'
) {
    const organization = computed(() => ManagerStore.legacyNursery);
    const {legacyUser: user} = useAuth();
    const {currentRoute, push} = useRouter();
    const {can} = useAbility();

    const date = ref(moment());
    watch(date, val => {
        const queryDate = currentRoute.value.query.date ? moment.unix(Number(currentRoute.value.query.date)) : null;
        // Check if the selected date differs from the query date to decide on pushing the new date to the URL query parameters
        const hasDiff = val.diff(queryDate) !== 0;
        const shoudPushDateToQuery = saveDate && (!queryDate || hasDiff);

        if (shoudPushDateToQuery) {
            push({
                query: {
                    date: `${val.unix()}`,
                },
            });
        }

        getCalendar();
    });

    function changePlanningType(type: 'present' | 'active') {
        if (filters.value.group === null) {
            filters.value.planningType = type;
        }
    }

    const from = computed(() => {
        return fromDate.value ? fromDate.value : date.value.clone().startOf('week');
    });
    const to = computed(() => (toDate.value ? toDate.value : date.value.clone().endOf('week')));

    const staffs: Ref<StaffModel[]> = ref([]);

    const {
        events,
        selectedEvent,
        getEvents,
        totalPlanned,
        totalAchieved,

        newEvent,
        addEvent,
        onEventAdded,
        onEventDeleted,
        modal: eventModal,
    } = useEvent(staff, staffs);

    function eventsForCurrentDay(selectedDate) {
        if (staff) {
            return filteredEvents.value.all();
        } else {
            let arr: EventModel[] = [];
            staffs.value.forEach(item => {
                let dayEvents = item.events().value().all();
                if (selectedDate) {
                    dayEvents = dayEvents.filter(ev =>
                        moment(selectedDate).isBetween(ev.startedAt, ev.endedAt, 'day', '[]')
                    );
                }
                arr = _concat(arr, dayEvents);
            });

            return arr;
        }
    }

    function opening(selectedDate: Moment | string = date.value, exactHours = false) {
        const hoursFromEvents = eventsForCurrentDay(selectedDate)
            .filter(item => item.attributes.date_event === null)
            .map(item => moment(item.startedAt).format('HH:mm:ss'));

        return getOpening(ManagerStore.legacyNursery.openingHours, true, exactHours, hoursFromEvents);
    }

    function closing(selectedDate: Moment | string = date.value, exactHours = false) {
        const hoursFromEvents = eventsForCurrentDay(selectedDate)
            .filter(
                item =>
                    item.endedAt !== null &&
                    item.attributes.date_event === null &&
                    item.attributes.datetime_event?.ended_at !== null
            )
            .map(item => moment(item.endedAt).format('HH:mm:ss'));

        return getClosing(ManagerStore.legacyNursery.openingHours, true, exactHours, hoursFromEvents);
    }

    const enumerateDaysBetweenDates = computed(() => {
        return daysBetweenDates(from.value, to.value, organization.value.openingHours);
    });

    const epochEnumerateDaysBetweenDates = computed(() => {
        return epochDaysBetweenDates(from.value.toISOString(), to.value.toISOString());
    });

    const weeksToDisplay = computed(() => {
        return new Set(enumerateDaysBetweenDates.value.map(item => item.week()));
    });

    const eventFilters = ref<string[]>(
        JSON.parse(localStorage.getItem('calendar:staffs:filter:events') as string) || []
    );
    const loading = ref(false);

    onBeforeMount(() => {
        date.value = currentRoute.value.query.date
            ? moment.unix(Number(currentRoute.value.query.date))
            : (getClosestOpeningDay() as Moment);
    });

    onMounted(() => {
        if (withOccupation) {
            useBroadcast().sessionChannel.bind('occupation', response => {
                ProcessedData.retrieve(response.processedDataUrl, response1 => {
                    occupations.value = response1.data;
                    occupationLoading.value = false;
                });
            });
            getOccupation();
        }

        if (withSupervision) {
            useBroadcast().sessionChannel.bind('supervision', response => {
                ProcessedData.retrieve(response.processedDataUrl, response1 => {
                    supervision.value = response1.data;
                    supervisionLoading.value = false;
                });
            });
            getSupervision();
        }

        EventBus.$on('calendar:staffs:refresh', handleRefreshStaffsEvent);
    });

    onUnmounted(() => {
        EventBus.$off('calendar:staffs:refresh', handleRefreshStaffsEvent);
        if (withOccupation) {
            useBroadcast().sessionChannel.unbind('occupation');
        }

        if (withSupervision) {
            useBroadcast().sessionChannel.unbind('supervision');
        }
    });

    const filteredEvents = computed(() => {
        return filterEvents(collect(events.value as EventModel[]), eventFilters) as Collection<EventModel>;
    });

    function handleRefreshStaffsEvent(fetchEvents = false) {
        if (withSupervision) {
            getSupervision();
        }

        if (fetchEvents) {
            getCalendar();
        }
    }

    function fullDayEvents(selectedDate: any = null) {
        return collect(eventsForCurrentDay(selectedDate).filter(event => event.attributes.date_event !== null));
    }

    watch(
        () => from.value,
        (val, oldVal) => {
            if (val?.format('YYYY-MM-DD') !== oldVal?.format('YYYY-MM-DD')) {
                if (withOccupation) {
                    getOccupation();
                }

                if (withSupervision) {
                    getSupervision();
                }
            }
        }
    );

    watch(
        () => filters.value,
        () => {
            getOccupation();
            getSupervision();
            getStaffs();
        },
        {deep: true}
    );

    function getCalendar() {
        loading.value = true;
        debounceCalendar();
    }

    const debounceCalendar = _debounce(() => {
        if (staff) {
            getEvents(from.value.format('YYYY-MM-DD HH:mm:ss'), to.value.format('YYYY-MM-DD HH:mm:ss')).then(() => {
                loading.value = false;
            });
        } else {
            getStaffs();
        }
    }, 1000);

    // useCurrentDate to getStaffs from one date only (not range)
    function getStaffs() {
        staffs.value = [];

        const fromFormat =
            staffListTimeGranularity === 'day'
                ? date.value.startOf('day').format('YYYY-MM-DD HH:mm:ss')
                : from.value.format('YYYY-MM-DD HH:mm:ss');

        const toFormat =
            staffListTimeGranularity === 'day'
                ? date.value.endOf('day').format('YYYY-MM-DD HH:mm:ss')
                : to.value.format('YYYY-MM-DD HH:mm:ss');

        const orderDisplayPreferences = window.localStorage.getItem('display:orderBy');

        const indexQuery = StaffModel.query().with(new StaffModel().events(), query => {
            query
                .orderBy('started_at')
                .with(new EventModel().staff())
                .with(new EventModel().eventType())
                .with(new EventModel().kidsGroup());

            query.scope('inRange', [from.value.format('YYYY-MM-DD HH:mm:ss'), to.value.format('YYYY-MM-DD HH:mm:ss')]);
            if (filters.value.group !== null) {
                query.where('group_id', '=', `${filters.value.group}`);
            }

            // Only with authorized events status
            if (can('read', 'hr_request')) {
                query.whereIn('status', eventStatusPending.concat(EventStatus.validated));
            } else {
                query.where('status', EventStatus.validated);
            }
        });

        const constrainToActiveStaffs = (query: QueryBuilder<StaffModel>) => {
            query.where(query2 => {
                query2.whereHas(new StaffModel().events(), query3 =>
                    query3.scope('inRange', [fromFormat, toFormat]).where('organization_id', organization.value.id)
                );
                query2.orWhereHas(new StaffModel().contracts(), query3 =>
                    query3.scope('active', [fromFormat, toFormat, [organization.value.id]])
                );
            });
        };

        const constrainToStaffsWithoutContractsInOtherOrganizations = (query: QueryBuilder<StaffModel>) => {
            query.whereHas(new StaffModel().contracts(), query2 =>
                query2.whereDoesntHave(new ContractModel().organizations(), query3 =>
                    query3.where('id', '!=', organization.value.id)
                )
            );
        };

        const constrainToPresentStaffs = (query: QueryBuilder<StaffModel>) => {
            query.whereHas(new StaffModel().events(), query2 =>
                query2.scope('inRange', [fromFormat, toFormat]).where('organization_id', organization.value.id)
            );
        };

        const constrainToPresentStaffsOnlyInOrganization = (query: QueryBuilder<StaffModel>) => {
            query.whereDoesntHave(new StaffModel().events(), query2 =>
                query2.scope('inRange', [fromFormat, toFormat]).where('organization_id', '!=', organization.value.id)
            );
        };

        indexQuery.where(query => {
            if (filters.value.planningType === 'active') {
                constrainToActiveStaffs(query);

                if (!filters.value.includeOtherOrganizationsStaffs) {
                    constrainToStaffsWithoutContractsInOtherOrganizations(query);
                }
            } else {
                constrainToPresentStaffs(query);

                if (!filters.value.includeOtherOrganizationsStaffs) {
                    constrainToPresentStaffsOnlyInOrganization(query);
                }
            }
        });

        indexQuery.orderBy(orderDisplayPreferences ?? 'first_name');

        if (filters.value.group !== null) {
            indexQuery.whereHas(new StaffModel().events(), query => {
                query.scope('inRange', [fromFormat, toFormat]).where('group_id', '=', `${filters.value.group}`);
            });
        }

        if (filters.value.teams.length) {
            indexQuery.whereHas(new StaffModel().teams(), query => {
                query.whereIn('id', collect(filters.value.teams).pluck('id').all() as string[]);
            });
        }

        indexQuery
            .get(200)
            .then(response => {
                staffs.value = response.all();
                loading.value = false;
            })
            .catch(() => {
                loading.value = false;
            });
    }

    const occupations = ref([]);
    const supervision = ref([]);
    const occupationLoading = ref(false);
    const supervisionLoading = ref(false);

    function weekOccupationRateBar(selectedDate) {
        const filter = _filter(
            occupations.value,
            (occupation: any) => occupation.date === moment(selectedDate).format('YYYY-MM-DD')
        );

        if (filter.length) {
            return _head(filter).summary;
        }

        return [];
    }

    function weekOccupationRateBarStaff(selectedDate) {
        const filter = _filter(
            supervision.value,
            (value: any) => value.date === moment(selectedDate).format('YYYY-MM-DD')
        );

        if (filter.length) {
            return _head(filter).summary;
        }

        return [];
    }

    function getSupervision() {
        supervisionLoading.value = true;
        debounceSupervision();
    }

    const debounceSupervision = _debounce(() => {
        useApi()
            .legacy.get(
                route('nurseries.supervision', {
                    nurseries: organization.value.id,
                }),
                {
                    params: {
                        from: from.value.unix(),
                        to: to.value.unix(),
                        group_id: filters.value.group ? filters.value.group : null,
                        interval: 5,
                    },
                }
            )
            .catch(error => {
                supervisionLoading.value = false;
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            });
    }, 1000);

    function getOccupation() {
        occupationLoading.value = true;
        debounceOccupation();
    }

    const debounceOccupation = _debounce(() => {
        useApi()
            .legacy.get(route('nurseries.occupation', {nurseries: organization.value.id}), {
                params: {
                    from: from.value.unix(),
                    to: to.value.unix(),
                    group_id: filters.value.group ? filters.value.group : null,
                    interval: 5,
                },
            })
            .catch(error => {
                occupationLoading.value = false;
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            });
    }, 1000);

    function getOccupationRate(day) {
        const occupation: any = occupations.value.find(
            (o: any) => o.date === Epoch.fromISOString(day).toFormat('yyyy-MM-dd')
        );

        return occupation ? occupation.percent.toFixed(1) : null;
    }

    function printPlanning() {
        usePrint().print();
    }

    return {
        user,
        organization,

        changePlanningType,
        date,
        enumerateDaysBetweenDates,
        epochEnumerateDaysBetweenDates,
        weeksToDisplay,
        opening,
        closing,

        eventFilters,
        loading,
        filteredEvents,
        fullDayEvents,
        getCalendar,

        staffs,

        occupations,
        supervision,
        occupationLoading,
        supervisionLoading,
        weekOccupationRateBar,
        weekOccupationRateBarStaff,

        events,
        selectedEvent,
        getEvents,
        totalPlanned,
        totalAchieved,

        newEvent,
        addEvent,
        onEventAdded,
        onEventDeleted,
        eventModal,

        getOccupationRate,

        printPlanning,
    };
}
