<template>
    <CModal
        id="saveTemplateModal"
        ref="modal"
        :header-title="__('planning:manage_typical_week')"
        size="7xl"
        prevent-escape
        :visible="true"
        @hidden="$emit('hidden')"
        @shown="onShown"
    >
        <loader
            v-if="weekTemplatesLoading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <template v-else-if="selectedTemplate || filteredTemplates.length">
            <div class="tw-mb-7 tw-flex tw-flex-wrap">
                <div class="tw-mb-3 tw-w-full sm:tw-mb-0 sm:tw-ml-3 sm:!tw-w-1/2">
                    <MFieldset>
                        <MLabel>{{ __('hr_calendar:update_typical_week') }}</MLabel>
                        <CInline>
                            <div class="tw-mr-3 tw-h-full tw-w-1/2">
                                <MMenu class="tw-w-full">
                                    <MMenuButton class="tw-w-full">
                                        <MButton
                                            class="tw-w-full"
                                            icon-menu
                                        >
                                            {{ selectedTemplate.attributes.name }}
                                        </MButton>
                                    </MMenuButton>
                                    <MMenuItems>
                                        <MMenuItem
                                            v-for="(weekTemplate, i) in filteredTemplates"
                                            :key="'weekTemplate' + i"
                                            @click="selectedTemplate = weekTemplate"
                                        >
                                            <template #icon>
                                                <FontAwesomeIcon
                                                    v-if="selectedTemplate.id === weekTemplate.id"
                                                    fixed-width
                                                    icon="fa-solid fa-circle-check"
                                                />
                                                <FontAwesomeIcon
                                                    v-else
                                                    fixed-width
                                                    icon="fa-regular fa-circle"
                                                />
                                            </template>
                                            {{ weekTemplate.attributes.name }}
                                        </MMenuItem>
                                    </MMenuItems>
                                </MMenu>
                            </div>
                            <CTooltip>
                                <MButton
                                    ref="updateWeekNameBtn"
                                    variant="primary"
                                    @click="saveWeekTemplate"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-pencil-alt" />
                                </MButton>
                                <template #content>
                                    {{ __('hr_calendar:update_name_of_week') }}
                                </template>
                            </CTooltip>

                            <CTooltip>
                                <MButton
                                    class="tw-ml-2"
                                    variant="danger"
                                    @click="removeWeekTemplate"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                                </MButton>
                                <template #content>
                                    {{ __('hr_calendar:delete_typical_week') }}
                                </template>
                            </CTooltip>
                        </CInline>
                    </MFieldset>
                </div>

                <div class="tw-mt-3 sm:tw-ml-auto sm:tw-mt-0 sm:tw-self-end">
                    <MButton
                        variant="primary"
                        @click="newWeekTemplate"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-plus"
                        />
                        {{ __('hr_calendar:new_week') }}
                    </MButton>
                </div>
            </div>

            <loader
                v-if="calendarLoading"
                light="true"
                shadow="false"
                size="sm"
                :title="__('common:loading_dots')"
            />
            <div
                v-else
                class="tw-border-t tw-border-gray-200 tw-pt-3"
            >
                <div class="tw-mb-2 tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                    <div class="tw-flex-1">
                        <OrganizationFinder
                            v-model="selectedTemplateOrganizations"
                            button-class="sm:tw-w-min tw-w-full"
                            :disabled="isLoadingAttachOrganizations"
                            multi
                            multi-minimum="1"
                            only-user-organizations
                            @update:model-value="debouncedAttachOrganizationsToTemplate"
                        />
                    </div>
                    <CTooltip class="tw-w-full sm:tw-w-min">
                        <MButton
                            class="tw-w-full"
                            size="sm"
                            variant="gray"
                            @click="fillEventsWithCurrentWeek"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-calendar-plus" />
                            </template>
                            {{ __('hr_calendar:use_template_number_weeks', {number: currentWeek}) }}
                        </MButton>
                        <template #content>
                            {{ __('hr_calendar:add_week_in_copying_week_template', {number: currentWeek}) }}
                        </template>
                    </CTooltip>
                    <MButton
                        class="tw-w-full sm:tw-w-min"
                        size="sm"
                        variant="danger"
                        @click="resetEvents(null)"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                        </template>
                        {{ __('common:actions.delete_all_dots') }}
                    </MButton>
                </div>
                <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2">
                    <CText
                        v-if="eventLoading"
                        as="div"
                        variant="gray"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-circle-notch"
                            spin
                        />
                        {{ __('common:saving_dots') }}
                    </CText>
                    <CText
                        v-else
                        as="div"
                        variant="success"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-solid fa-check"
                        />
                        {{ __('hr_calendar:week_saved_successfully') }}
                    </CText>
                    <CText
                        as="div"
                        font-size="sm"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-1"
                            icon="fa-duotone fa-info-circle"
                        />
                        {{ __('hr_calendar:can_copy_planning') }}
                        <span
                            class="tw-rounded tw-border tw-border-gray-500 tw-px-1 tw-text-xs tw-font-semibold tw-uppercase"
                        >
                            {{ __('hr_calendar:shortcut_alt_click') }}
                        </span>
                    </CText>
                </div>
                <div class="tw-mt-4 tw-flex">
                    <div class="tw-mt-4 tw-w-24">
                        <div class="tw-h-9" />
                        <div
                            v-for="(planningTemplate, i) in planningsTemplates"
                            :key="'planning_template_staff' + i"
                            class="tw-mb-1 tw-flex tw-h-9 tw-w-full tw-items-center"
                        >
                            <StaffFinder
                                :allowed-organizations="selectedTemplateOrganizations"
                                class="tw-w-full"
                                :excluded-ids="
                                    planningTemplate.staff().value()
                                        ? [planningTemplate.staff().value().getKey()]
                                        : null
                                "
                                @fallback="switchStaffPlanning(planningTemplate)"
                                @update:model-value="switchStaffPlanning(planningTemplate, $event)"
                            >
                                <template #fallback>
                                    {{ __('hr_calendar:not_allocated') }}
                                </template>
                                <template #trigger="{toggle}">
                                    <MPopover
                                        class="tw-w-full"
                                        placement="top-start"
                                        popover-button-class="tw-h-full tw-w-full tw-rounded hover:tw-bg-gray-50 tw-border tw-border-dashed tw-border-transparent hover:tw-border-blue-200 tw-cursor-pointer tw-p-1"
                                        trigger="hover"
                                        @click="toggle"
                                    >
                                        <div
                                            v-if="planningTemplate.staff().value()"
                                            class="tw-flex tw-h-full tw-items-center"
                                        >
                                            <StaffAvatar
                                                class="tw-mr-1 tw-w-6 tw-shrink-0"
                                                :staff-model="planningTemplate.staff().value()"
                                            />
                                            <div
                                                class="tw-truncate tw-text-base"
                                                :class="planningTemplate.staff().value().genderColor"
                                            >
                                                {{ planningTemplate.staff().value().fullname }}
                                            </div>
                                        </div>
                                        <div
                                            v-else
                                            class="tw-flex tw-h-full tw-items-center"
                                        >
                                            <div
                                                class="tw-mr-1 tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-rounded-full tw-bg-gray-300"
                                            >
                                                <FontAwesomeIcon
                                                    class="tw-w-full tw-text-center tw-text-sm tw-text-gray-500"
                                                    icon="fa-solid fa-question"
                                                />
                                            </div>
                                            <div class="tw-truncate tw-text-base tw-text-gray-600">N/A</div>
                                        </div>
                                        <template #content>
                                            <div class="tw-capitalize">
                                                <div
                                                    v-if="planningTemplate.staff().value()"
                                                    :class="planningTemplate.staff().value().genderColor"
                                                >
                                                    {{ planningTemplate.staff().value().fullname }}
                                                </div>
                                                <div
                                                    v-else
                                                    class="tw-text-gray-600"
                                                >
                                                    {{ __('hr_calendar:not_allocated') }}
                                                </div>
                                            </div>
                                            <div class="tw-my-2">
                                                <EventTotals
                                                    :contract="
                                                        planningTemplate.staff().value() &&
                                                        planningTemplate.staff().value().activeContract()
                                                            ? `${planningTemplate.staff().value().activeContract().attributes.hours_per_week}h`
                                                            : null
                                                    "
                                                    :forecast="totalHours(planningTemplate)"
                                                    :real="null"
                                                />
                                            </div>
                                            <div class="tw-mt-1 tw-flex tw-border-t tw-border-gray-200 tw-pt-2">
                                                <MButton
                                                    class="tw-mr-2"
                                                    size="xs"
                                                    variant="link"
                                                    @click.stop="toggle"
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-1"
                                                        icon="fa-solid fa-retweet"
                                                    />
                                                    {{ __('common:actions.change') }}
                                                </MButton>
                                                <MButton
                                                    size="xs"
                                                    variant="link-danger"
                                                    @click.stop="confirmRemovePlanningTemplate(planningTemplate)"
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-1"
                                                        icon="fa-solid fa-trash-alt"
                                                    />
                                                    {{ __('common:actions.delete_dots') }}
                                                </MButton>
                                            </div>
                                        </template>
                                    </MPopover>
                                </template>
                            </StaffFinder>
                        </div>
                    </div>

                    <div class="tw-flex tw-w-full tw-justify-between tw-overflow-x-scroll">
                        <div
                            v-for="(dayOfWeek, dayIndex) in daysOfWeek"
                            :key="'currentDay' + dayIndex"
                            class="showDayHoursTrigger tw-ml-3 tw-w-full tw-min-w-48"
                        >
                            <div class="tw-relative tw-h-5">
                                <div class="showDay tw-w-full tw-text-center tw-capitalize tw-text-blue-500">
                                    {{ dayOfWeek.format('dddd') }}
                                </div>
                            </div>

                            <div class="tw--ml-2 tw-mt-1 tw-h-7 tw-pr-3.5">
                                <Hours
                                    class="showDayHours"
                                    :closing="closing(dayOfWeek, true)"
                                    grid-on-hover
                                    :opening="opening(dayOfWeek, true)"
                                    simple
                                    small
                                />
                            </div>

                            <div
                                v-for="(planningTemplate, i) in planningsTemplates"
                                :key="'planning_template' + i"
                                class="tw-mb-1 tw-flex tw-h-9 tw-items-center"
                            >
                                <PlanningGenerator
                                    always-show-ghost
                                    :closing="closing(dayOfWeek, true)"
                                    create-with-modal
                                    :day="dayOfWeek"
                                    is-template
                                    :opening="opening(dayOfWeek, true)"
                                    :show-clock="false"
                                    :show-divider="false"
                                    show-note
                                    size="md"
                                    :template-events="eventsForCurrentDay(dayOfWeek, planningTemplate)"
                                    :week-template="planningTemplate.id"
                                    @push-event-template="planningTemplate.planningEvents().value().all().push($event)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <StaffFinder
                    :allowed-organizations="selectedTemplateOrganizations"
                    class="tw-w-full"
                    :excluded-ids="staffFinderExceptionIds"
                    @fallback="addPlanningTemplate"
                    @update:model-value="addPlanningTemplate"
                >
                    <template #trigger="{toggle}">
                        <CVStack
                            align="center"
                            class="tw-group tw-cursor-pointer tw-rounded-xl tw-border-2 tw-border-dashed tw-border-gray-300 tw-py-1 tw-transition-all tw-duration-300 hover:tw-border-blue-500 hover:tw-bg-blue-100"
                            distribute="center"
                            @click="toggle"
                        >
                            <CText
                                class="tw-text-gray-700 group-hover:tw-text-black"
                                font-weight="semibold"
                            >
                                <template v-if="planningTemplateLoading">
                                    <FontAwesomeIcon
                                        class="tw-mr-2 tw-text-blue-500"
                                        icon="fa-solid fa-circle-notch"
                                        spin
                                    />
                                    {{ __('common:adding_in_progress_dots') }}
                                </template>
                                <template v-else>
                                    <FontAwesomeIcon
                                        class="tw-mr-2 tw-text-blue-500"
                                        icon="fa-duotone fa-plus-circle"
                                    />
                                    {{ __('common:actions.add_line') }}
                                </template>
                            </CText>
                        </CVStack>
                    </template>
                    <template #fallback>
                        {{ __('hr_calendar:not_allocated') }}
                    </template>
                </StaffFinder>
            </div>

            <EventModal
                v-if="selectedEvent"
                ref="modalEditEvent"
                :event="selectedEvent"
                is-template
                @add="onEventAdded"
                @delete="onEventDeleted"
                @hidden="selectedEvent = null"
            />
        </template>
        <div
            v-else
            class="tw-rounded-md tw-bg-blue-100 tw-p-5 tw-text-center tw-text-blue-500"
        >
            <div class="tw-mb-3">
                <MButton
                    :icon-external-link="true"
                    size="none"
                    variant="link"
                    @click="showArticle(5538054)"
                >
                    {{ __('common:show_guide') }}
                </MButton>
            </div>
            <MButton
                variant="primary"
                @click="newWeekTemplate"
            >
                <FontAwesomeIcon
                    class="tw-mr-2"
                    icon="fa-solid fa-plus"
                />
                {{ __('hr_calendar:new_week_dots') }}
            </MButton>
        </div>

        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$refs.modal.hide()"
            >
                {{ __('common:actions.close') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, toRef, watch} from 'vue';

    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import useIntercom from '@/modules/app/composables/useIntercom';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import useGlobalWeekTemplate from '@/modules/human-resources/composables/calendar/useGlobalWeekTemplate';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export default defineComponent({
        components: {
            StaffAvatar,
            OrganizationFinder,
            Hours,
            PlanningGenerator: Line,
            EventModal,
            EventTotals,
            StaffFinder,
        },
        props: {
            staffsFromCurrentWeek: {
                type: Array as PropType<StaffModel[]>,
                required: true,
            },
            currentWeek: {
                type: Number,
                required: true,
            },
        },
        emits: ['hidden'],
        setup(props) {
            const {showArticle} = useIntercom();

            const {
                modal,
                onShown,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                staffFinderExceptionIds,
                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,
                newWeekTemplate,
                selectedTemplateOrganizations,
                isLoadingAttachOrganizations,
                debouncedAttachOrganizationsToTemplate,
                saveWeekTemplate,
                removeWeekTemplate,
                fillEventsWithCurrentWeek,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventDeleted,
            } = useGlobalWeekTemplate(toRef(props, 'staffsFromCurrentWeek'));
            const eventLoading = ref(false);

            watch(
                () => selectedTemplate.value?.planningTemplates().value(),
                () => {
                    eventLoading.value = true;
                    setTimeout(() => {
                        eventLoading.value = false;
                    }, 300);
                },
                {
                    deep: true,
                }
            );

            return {
                showArticle,
                eventLoading,
                staffFinderExceptionIds,
                modal,
                onShown,
                selectedTemplateOrganizations,
                isLoadingAttachOrganizations,
                debouncedAttachOrganizationsToTemplate,

                calendarLoading,
                opening,
                closing,
                daysOfWeek,

                filteredTemplates,
                selectedTemplate,
                weekTemplatesLoading,
                newWeekTemplate,
                saveWeekTemplate,
                removeWeekTemplate,
                fillEventsWithCurrentWeek,

                planningsTemplates,
                planningTemplateLoading,
                addPlanningTemplate,
                switchStaffPlanning,
                confirmRemovePlanningTemplate,

                totalHours,
                eventsForCurrentDay,
                resetEvents,
                deleteEvents,
                selectedEvent,
                modalEditEvent,
                onEventAdded,
                onEventDeleted,
            };
        },
    });
</script>

<style scoped>
    .showDayHours {
        @apply tw-opacity-0 tw-transition-all tw-duration-300 tw-ease-in-out;
    }

    .showDay {
        @apply tw-absolute tw-top-5 tw-transition-all tw-ease-in-out;
    }

    .showDayHoursTrigger:hover {
        .showDayHours {
            @apply tw-opacity-100;
        }

        .showDay {
            @apply tw-top-0;
        }
    }
</style>
