import type {ModelCollection} from '@meekohq/lumos';
import {Binding} from '@meekohq/lumos';

import type TicketFilterDto from '@/modules/activity/ticket/application/dto/TicketFilterDto';
import type TicketRelationOptionDto from '@/modules/activity/ticket/application/dto/TicketRelationOptionDto';
import type TicketModel from '@/modules/activity/ticket/domain/TicketModel';

export const GetTasksUseCaseBinding = new Binding<GetTasksUseCase>();

export interface GetTasksUseCase {
    /**
     * Retrieves tasks based on the provided filters and load relations depending on optionnal options.
     */
    getTasksByFilters(
        filters: TicketFilterDto,
        relationOptions?: TicketRelationOptionDto
    ): Promise<ModelCollection<TicketModel>>;
}
