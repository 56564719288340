enum FrequencyValue {
    year = 'year',
    month = 'month',
    week = 'week',
    day = 'day',
    hour = 'hour',
    minute = 'minute',
    second = 'second',
    once = 'once',
}

export default FrequencyValue;
