<script setup lang="ts">
    import type {PropType} from 'vue';
    import {ref} from 'vue';

    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';

    defineProps({
        modal: {type: Object as PropType<ModalType>, required: true},
        title: {type: String, default: undefined},
        text: {type: String, default: undefined},
        type: {type: String, default: 'question'},
        confirmButtonText: {type: String, default: undefined},
        confirmButtonVariant: {type: String, default: 'primary'},
        dismissButtonText: {type: String, default: undefined},
        checkboxText: {type: String, default: undefined},
        dismissButtonVariant: {type: String, default: 'light'},
        wideButtons: {type: Boolean, default: false},
    });

    const emit = defineEmits<{
        (e: 'confirmed', value: boolean): void;
        (e: 'dismissed'): void;
    }>();

    const loading = ref(false);

    function onConfirm() {
        loading.value = true;
        emit('confirmed', checkedValue.value);
    }

    const checkedValue = ref(false);
</script>

<template>
    <CModal
        hide-header-close
        :modal="modal"
        size="md"
    >
        <FontAwesomeIcon
            v-if="type === 'question'"
            class="ConfirmationModal__icon tw-bg-info-100 tw-text-3xl tw-text-info-500"
            icon="fal fa-question"
        />
        <FontAwesomeIcon
            v-if="type === 'warning'"
            class="ConfirmationModal__icon tw-bg-warning-100 tw-text-3xl tw-text-warning-500"
            icon="fal fa-exclamation"
        />
        <FontAwesomeIcon
            v-if="type === 'danger'"
            class="ConfirmationModal__icon tw-bg-danger-100 tw-text-3xl tw-text-danger-500"
            icon="fal fa-xmark"
        />
        <FontAwesomeIcon
            v-if="type === 'success'"
            class="ConfirmationModal__icon tw-bg-success-100 tw-text-3xl tw-text-success-600"
            icon="fal fa-check"
        />
        <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
            <h3 class="tw-font-display tw-text-xl tw-leading-6">
                {{ title ?? __('common:please_confirm') }}
            </h3>
            <div
                v-if="text"
                class="tw-mt-2"
            >
                <p
                    class="tw-text-gray-500"
                    v-html="text"
                />
            </div>
            <MFieldset class="tw-mt-4">
                <CCheckbox
                    v-model="checkedValue"
                    size="lg"
                >
                    {{ checkboxText }}
                </CCheckbox>
            </MFieldset>
        </div>

        <template #footer>
            <div
                :class="{
                    'tw-grid tw-w-full tw-gap-3 sm:tw-grid-cols-2': wideButtons,
                    'tw-flex tw-w-full tw-justify-end tw-gap-3': !wideButtons,
                }"
            >
                <MButton
                    :class="{
                        'tw-w-full': wideButtons,
                    }"
                    size="lg"
                    :variant="dismissButtonVariant"
                    @click="emit('dismissed')"
                >
                    <span v-html="dismissButtonText ?? __('common:actions.cancel')" />
                </MButton>
                <MButton
                    :class="{
                        'tw-w-full': wideButtons,
                    }"
                    :loading="loading"
                    size="lg"
                    :variant="confirmButtonVariant"
                    @click="onConfirm"
                >
                    <span v-html="confirmButtonText ?? __('common:actions.confirm')" />
                </MButton>
            </div>
        </template>
    </CModal>
</template>

<style scoped>
    .ConfirmationModal__icon {
        @apply tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full;
    }
</style>
