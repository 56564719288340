<template>
    <div class="TasksList">
        <div class="TasksList__title">
            {{ __('activity:tasks_to_be_completed') }}
        </div>
        <div
            v-if="loading"
            class="tw-space-y-3"
        >
            <div class="tw-flex tw-w-full tw-flex-row">
                <CSkeletonItem class="tw-mr-4 tw-h-6 tw-w-20" />
                <CSkeletonItem class="tw--ml-1 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw--ml-1 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw--ml-1 tw-mr-3 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw-h-6 tw-w-56" />
            </div>
            <div class="tw-flex tw-w-full tw-flex-row">
                <CSkeletonItem class="tw-mr-4 tw-h-6 tw-w-20" />
                <CSkeletonItem class="tw--ml-1 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw--ml-1 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw--ml-1 tw-mr-3 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw-h-6 tw-w-56" />
            </div>
            <div class="tw-flex tw-w-full tw-flex-row">
                <CSkeletonItem class="tw-mr-4 tw-h-6 tw-w-20" />
                <CSkeletonItem class="tw--ml-1 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw--ml-1 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw--ml-1 tw-mr-3 tw-h-6 tw-w-6 tw-rounded-full" />
                <CSkeletonItem class="tw-h-6 tw-w-56" />
            </div>
        </div>
        <TasksListItem
            v-for="task in tasksWithResources"
            v-else
            :key="task.getKey()"
            :date="task.attributes.expired_at"
            :is-late="task.isLate"
            :name="task.attributes.name"
            :resources="task.extra.resources"
        />
    </div>
</template>

<script lang="ts">
    import type {ModelCollection} from '@meekohq/lumos';
    import {set} from 'lodash-es';
    import moment from 'moment';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref} from 'vue';

    import TasksListItem from '@/modules/activity/components/molecules/TasksListItem.vue';
    import useResource from '@/modules/app/composables/useResource';
    import useMetrics from '@/modules/app/composables/useRum';

    export default defineComponent({
        components: {TasksListItem},
        props: {
            tasks: {
                type: Object as PropType<ModelCollection>,
                required: true,
            },
        },
        setup(props) {
            const loading = ref(false);
            const {addAction} = useMetrics();

            onMounted(() => {
                addAction('M_Badge_Tasks1_Hover', {value: props.tasks.count()});
            });

            const tasksWithResources = computed(() => {
                return props.tasks.map(task => {
                    const taskResources = task
                        .ticketPivots()
                        .value()
                        .map(pivot => pivot.resource().value())
                        .all();

                    set(task.extra, 'resources', useResource(ref(taskResources), true).computedResources.value);

                    return task;
                });
            });

            return {
                loading,
                moment,
                tasksWithResources,
            };
        },
    });
</script>

<style scoped>
    .TasksList {
        @apply tw-space-y-2 tw-p-3;
        @apply tw-max-h-64 tw-min-w-64 tw-max-w-96;
        @apply tw-overflow-auto;
    }

    .TasksList__title {
        @apply tw-uppercase tw-tracking-wide;
        @apply tw-text-sm tw-font-semibold;
        @apply tw-text-gray-500;
        @apply tw-mb-4;
    }
</style>
