<template>
    <div class="EditInvoiceDetailsLine">
        <div class="EditInvoiceDetailsLine__item-description">
            <div class="tw-float-left">
                <invoiceType v-model="line.type" />
            </div>
            {{ line.name }}
        </div>
        <MListItemValue
            v-if="withTva"
            class="EditInvoiceDetailsLine__item EditInvoiceDetailsLine__item-tva"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('common:vat') }}
            </template>
            <template #default>{{ line.tva }}%</template>
        </MListItemValue>
        <MListItemValue
            class="EditInvoiceDetailsLine__item EditInvoiceDetailsLine__item-price"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('billing_invoice:price_with_vat') }}
            </template>
            <template #default>
                {{ formattedLinePrice }}
            </template>
        </MListItemValue>
        <MListItemValue
            class="EditInvoiceDetailsLine__item EditInvoiceDetailsLine__item-quantity"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('billing_invoice:quantity') }}
            </template>
            <template #default>
                {{ formattedLineQuantity }}
            </template>
        </MListItemValue>
        <MListItemValue
            class="EditInvoiceDetailsLine__item EditInvoiceDetailsLine__item-total"
            hide-title-on-desktop
            hide-title-on-tablet
        >
            <template #title>
                {{ __('billing_invoice:total_with_vat') }}
            </template>
            <template #default>
                {{ format(line.total) }}
            </template>
        </MListItemValue>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    import InvoiceType from '@/modules/cashier/components/billing/invoice/InvoiceType.vue';
    import humanizeQuantity from '@/modules/cashier/composables/billing/invoice/humanizeQuantity';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import {invoice} from '@/modules/legacy/mixins/invoice';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import MListItemValue from '@/modules/meeko-ui/components/MListItemValue.vue';

    export default defineComponent({
        components: {MListItemValue, InvoiceType},
        mixins: [invoice, nursery],
        props: {
            line: {
                type: Object,
                required: true,
            },
            withTva: {
                type: Boolean,
                required: true,
            },
            currency: {
                type: String,
                required: true,
            },
        },
        setup(props) {
            const {format} = useFormatCurrency(props.currency);

            const formattedLinePrice = computed(() => {
                return format(props.line.price, {minFractionDigits: 2});
            });

            const formattedLineQuantity = computed(() => {
                return humanizeQuantity(props.line.qty, props.line.unit);
            });

            return {
                format,
                formattedLinePrice,
                formattedLineQuantity,
            };
        },
    });
</script>

<style scoped>
    .EditInvoiceDetailsLine {
        @apply tw-relative tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2 md:tw-flex-nowrap;
        @apply odd:tw-bg-blue-50 even:tw-bg-blue-50 md:even:tw-bg-transparent;
        @apply tw-rounded-lg;
        @apply tw-p-2;
    }

    .EditInvoiceDetailsLine__item {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between;
        @apply tw-text-right tw-text-gray-800;
    }

    .EditInvoiceDetailsLine__item-tva {
        @apply tw-w-full md:tw-w-20;
    }

    .EditInvoiceDetailsLine__item-price {
        @apply tw-w-full md:tw-w-28;
    }

    .EditInvoiceDetailsLine__item-quantity {
        @apply tw-w-full md:tw-w-40;
    }

    .EditInvoiceDetailsLine__item-total {
        @apply tw-w-full md:tw-w-24;
    }

    .EditInvoiceDetailsLine__item-description {
        @apply tw-text-gray-800;
        @apply tw-w-full md:tw-w-24 md:tw-flex-1;
    }
</style>
