<template>
    <div class="tw-rounded-lg tw-border tw-border-dashed tw-border-gray-300 tw-p-2">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'GroupInPlaceInputs',
    };
</script>
