import {Epoch} from '@meekohq/lumos';
import type {Ref} from 'vue';
import {computed} from 'vue';

import type {PeriodFilterType} from '@/modules/legacy/components/Filter/PeriodFilterType';

/**
 * This composable is paired with the DatePeriodFilter component.
 * It provides computed values for the period filter and methods to update the period filter and
 * get and set the query params in the URL.
 * @param period
 */
export function useDatePeriodFilterNext(period: Ref<PeriodFilterType>) {
    const diffInDays = computed(() => {
        if (period.value.from && period.value.to) {
            return Epoch.fromJSDate(new Date(period.value.from)).differenceInDays(
                Epoch.fromJSDate(new Date(period.value.to))
            );
        }

        return 0;
    });

    const diffInMonths = computed(() => {
        if (period.value.from && period.value.to) {
            return (
                Epoch.fromJSDate(new Date(period.value.from)).differenceInMonths(
                    Epoch.fromJSDate(new Date(period.value.to))
                ) + 1
            );
        }

        return 0;
    });

    /**
     * Update period filter value and querystring to previous or next period based on moveForward parameter
     * if current period covers full months, then move to previous/next full months period length
     * otherwise move to the previous/next period based on diffInDays value
     */
    function nextPeriod(backward = false) {
        // Type guard
        if (!period.value.from || !period.value.to) {
            return;
        }

        const fromEpoch = Epoch.fromISOString(period.value.from);
        const toEpoch = Epoch.fromISOString(period.value.to);

        const isFullMonthPeriod = fromEpoch.equalTo(fromEpoch.startOfMonth()) && toEpoch.equalTo(toEpoch.endOfMonth());

        if (period.value) {
            if (isFullMonthPeriod) {
                const methodName = backward ? 'subMonths' : 'addMonths';

                period.value = {
                    from: fromEpoch[methodName](diffInMonths.value).startOfMonth().toISOString(),
                    to: toEpoch[methodName](diffInMonths.value).endOfMonth().toISOString(),
                };
            } else {
                const methodName = backward ? 'subDays' : 'addDays';

                period.value = {
                    from: fromEpoch[methodName](diffInDays.value).toISOString(),
                    to: toEpoch[methodName](diffInDays.value).toISOString(),
                };
            }
        }
    }

    function previousPeriod() {
        return nextPeriod(true);
    }

    return {
        nextPeriod,
        previousPeriod,
    };
}
