<template>
    <div
        v-if="!loading"
        class="SimulationListAction"
    >
        <div class="SimulationListAction__container">
            <SimulationListFinder
                v-model="selectedSimulationList"
                :inject-query="query"
                :search-bar="false"
            >
                <template #button-text-empty>
                    {{ __('planning:select_an_list') }}
                </template>
            </SimulationListFinder>
            <div class="SimulationListAction__actions-container">
                <UpdateSimulationList :simulation-list="selectedSimulationList" />
                <DeleteSimulationList
                    :model="selectedSimulationList"
                    @deleted="deletedSimulationList()"
                />
            </div>
        </div>
        <CreateSimulationList
            class="SimulationListAction__add-list-button"
            @created="createdSimulationList($event)"
        />
    </div>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {defineComponent, onMounted, ref, watch} from 'vue';

    import CreateSimulationList from '@/modules/planning/components/simulation/List/CreateSimulationList.vue';
    import DeleteSimulationList from '@/modules/planning/components/simulation/List/DeleteSimulationList.vue';
    import SimulationListFinder from '@/modules/planning/components/simulation/List/SimulationListFinder.vue';
    import UpdateSimulationList from '@/modules/planning/components/simulation/List/UpdateSimulationList.vue';
    import SimulationListModel from '@/modules/planning/models/SimulationListModel';

    export default defineComponent({
        components: {CreateSimulationList, DeleteSimulationList, SimulationListFinder, UpdateSimulationList},
        props: {
            query: {
                type: Object as PropType<QueryBuilder<any>>,
                required: true,
            },
        },
        emits: ['created', 'deleted', 'changed'],
        setup(props, {emit}) {
            const selectedSimulationList = ref<SimulationListModel>();
            const loading = ref(false);

            onMounted(() => getFirstSimulationList());

            async function getFirstSimulationList() {
                loading.value = true;
                selectedSimulationList.value = await SimulationListModel.query().inject(props.query).first();
                loading.value = false;
            }

            function createdSimulationList(simulationList: SimulationListModel) {
                emit('created');
                selectedSimulationList.value = simulationList;
            }

            function deletedSimulationList() {
                emit('deleted');
                getFirstSimulationList();
            }

            watch(
                () => selectedSimulationList.value,
                value => emit('changed', value)
            );

            return {
                loading,
                selectedSimulationList,
                getFirstSimulationList,
                createdSimulationList,
                deletedSimulationList,
            };
        },
    });
</script>

<style scoped>
    .SimulationListAction {
        @apply tw-flex tw-flex-wrap tw-justify-between;
    }

    .SimulationListAction__container {
        @apply tw-flex tw-flex-row;
        @apply tw-justify-between sm:tw-justify-start lg:tw-justify-between;
        @apply tw-w-full sm:tw-w-auto lg:tw-w-full;
        @apply tw-space-x-2 tw-pb-4;
    }

    .SimulationListAction__actions-container {
        @apply tw-flex tw-flex-row tw-space-x-2;
    }

    .SimulationListAction__add-list-button {
        @apply tw-w-full sm:tw-w-auto lg:tw-w-full;
    }
</style>
