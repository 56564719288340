<script setup lang="ts">
    import {computed, onMounted} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import NumberFormatter from '@/modules/app/utils/NumberFormatter';
    import useTransactionAllocationsState from '@/modules/cashier/composables/issuing/transaction/useTransactionAllocationsState';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import InvoiceMiniatureContent from '@/modules/cashier/payment/infrastructure/components/InvoiceMiniature/InvoiceMiniatureContent.vue';
    import TransactionModalForm from '@/modules/cashier/transaction/infrastructure/components/TransactionModalForm.vue';
    import type {TransactionStateReturnType} from '@/modules/cashier/transaction/infrastructure/components/useTransactionState';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

    const props = withDefaults(
        defineProps<{
            canSelectCustomer: boolean;
            invoice?: LegacyInvoiceType;
            payment?: PaymentModel;
            transactionState: TransactionStateReturnType;
            customer?: CustomerModel;
        }>(),
        {
            invoice: undefined,
            payment: undefined,
            customer: undefined,
        }
    );

    const {activeOrganization} = useManager();

    const {transaction, sumOtherAllocations, currency} = props.transactionState;

    const {isOverAllocated, isInsufficientlyAllocated} = props.transactionState.transactionError;

    const formatter = new NumberFormatter({
        countryCode: activeOrganization.value.attributes.address.country_code,
        currencyCode: currency.value?.attributes.code,
    });

    const goingToBeDeleteInvoiceAllocation = computed(() => {
        return (
            !!transaction.value.attributes.amount && transaction.value.attributes.amount === sumOtherAllocations.value
        );
    });

    const invoiceType = computed(() => {
        if (props.invoice) {
            return props.invoice.invoice_type;
        }

        return transaction.value.isCredit ? 'invoice' : 'credit_note';
    });

    const {prefillInvoiceAllocation, transactionRemainingAmount} = useTransactionAllocationsState(
        props.transactionState,
        props.payment
    );

    // Prefill invoice allocation if the transaction is not yet created and the payment has a remaining amount
    if (!transaction.value.exists) {
        onMounted(async () => {
            if (props.payment && props.payment.computed.remaining_amount > 0) {
                await prefillInvoiceAllocation();
            }
        });
    }

    const withMiniature = computed(() => {
        return props.invoice && props.customer;
    });
</script>

<template>
    <div :class="{'TransactionModalContent__with-miniature': withMiniature}">
        <MForm :class="{'TransactionModalContent__content-left': withMiniature}">
            <TransactionModalForm
                :can-select-customer="canSelectCustomer"
                :state="transactionState"
            />
            <MFieldset>
                <div
                    v-if="isOverAllocated"
                    class="tw-text-gray-500"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-info-square"
                    />
                    {{
                        __('billing_transaction:warning.amount_is_overed', {
                            context: invoiceType,
                            amount: formatter.parse(transactionRemainingAmount).asString,
                        })
                    }}
                </div>
                <div
                    v-if="goingToBeDeleteInvoiceAllocation && payment"
                    class="tw-text-gray-500"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-info-square"
                    />
                    {{
                        __('billing_transaction:warning.allocation_going_to_be_delete', {
                            amount: formatter.parse(sumOtherAllocations).asString,
                        })
                    }}
                </div>
                <CFormErrorMessage v-if="isInsufficientlyAllocated">
                    {{
                        __(
                            'billing_transaction:errors.the_amount_must_be_greater_or_equals_thant_sum_other_allocations',
                            {
                                context: transaction.attributes.type,
                                amount: formatter.parse(sumOtherAllocations).asString,
                            }
                        )
                    }}
                </CFormErrorMessage>
            </MFieldset>
        </MForm>
        <template v-if="invoice && customer">
            <MBox class="TransactionModalContent__content-right">
                <InvoiceMiniatureContent
                    :customer="customer"
                    :invoice="invoice"
                />
            </MBox>
        </template>
    </div>
</template>

<style scoped>
    .TransactionModalContent__with-miniature {
        @apply tw-flex tw-flex-col-reverse tw-gap-5 sm:tw-flex-row;
    }

    .TransactionModalContent__content-left {
        @apply tw-w-full sm:tw-flex-1;
    }

    .TransactionModalContent__content-right {
        @apply tw-p-4;
        @apply tw-w-full sm:tw-w-80;
    }
</style>
