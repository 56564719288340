<template>
    <div>
        <CButtonGroup>
            <MButton
                v-if="firstLast"
                :disabled="isSamePage(1)"
                @click="navigate(1)"
            >
                <FontAwesomeIcon icon="fa-solid fa-chevron-double-left" />
            </MButton>
            <MButton
                :disabled="isSamePage(1)"
                @click="navigate(currentPage - 1)"
            >
                <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
            </MButton>
            <MButton
                v-for="page in pages"
                :key="page"
                :variant="isSamePage(page) ? 'primary' : 'light'"
                @click="navigate(page)"
            >
                {{ page }}
            </MButton>
            <MButton
                :disabled="isSamePage(lastPage)"
                @click="navigate(currentPage + 1)"
            >
                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
            </MButton>
            <MButton
                v-if="firstLast"
                :disabled="isSamePage(lastPage)"
                @click="navigate(lastPage)"
            >
                <FontAwesomeIcon icon="fa-solid fa-chevron-double-right" />
            </MButton>
        </CButtonGroup>
    </div>
</template>

<script lang="ts">
    import type {JsonAPIPaginator} from '@meekohq/lumos';
    import {LengthAwarePaginator} from '@meekohq/lumos';
    import {round} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            paginator: {
                type: Object as PropType<JsonAPIPaginator | LengthAwarePaginator<any>>,
                required: true,
            },
            limit: {type: Number, default: 4},
            firstLast: {type: Boolean, default: true},
        },
        emits: ['navigate'],
        setup(props, {emit}) {
            const selectedPage = ref();

            watch(
                () => props.paginator,
                () => {
                    selectedPage.value = undefined;
                },
                {deep: true}
            );

            const pages = computed(() => {
                const pages = [] as number[];
                let left = currentPage.value - round(props.limit / 2);
                let right = currentPage.value + round(props.limit / 2);

                if (left < 1) {
                    left = 1;
                }

                if (right > lastPage.value) {
                    right = lastPage.value;
                }

                for (let page = left; page <= right; page++) {
                    pages.push(page);
                }

                return pages;
            });

            const currentPage = computed(() => {
                if (props.paginator instanceof LengthAwarePaginator) {
                    return props.paginator.currentPage();
                } else {
                    return props.paginator.currentPage;
                }
            });

            const lastPage = computed(() => {
                if (props.paginator instanceof LengthAwarePaginator) {
                    return props.paginator.lastPage();
                } else {
                    return props.paginator.lastPage;
                }
            });

            const navigate = function (page) {
                if (selectedPage.value === page) {
                    return;
                }

                selectedPage.value = page;
                emit('navigate', page);
            };

            function isSamePage(page) {
                if (selectedPage.value) {
                    return selectedPage.value === page;
                }

                return currentPage.value === page;
            }

            return {
                navigate,
                pages,
                currentPage,
                lastPage,
                selectedPage,
                isSamePage,
            };
        },
    });
</script>
