<template>
    <MPopover
        v-if="eventType"
        :trigger="!!event ? 'hover' : 'none'"
        placement="top"
    >
        <div
            :class="badgeClass"
            :style="badgeStyle"
        >
            <div
                v-if="eventType.attributes.icon_id"
                :class="iconClass"
                :style="iconStyle"
            >
                <CIcon
                    :path="eventType.icon.attributes.path"
                    :provider="eventType.icon.attributes.provider"
                />
            </div>
            <div class="tw-z-10 tw-mx-2">
                <div class="tw-text-sm tw-font-semibold">
                    {{ displaySum ? totalEvents : hours }}
                </div>
                <div
                    v-if="size !== 'sm'"
                    class="tw--mt-1 tw-text-sm tw-font-semibold tw-opacity-80"
                >
                    {{ eventType.attributes.name }}
                </div>
            </div>
        </div>
        <template #content>
            <div
                v-if="event"
                class="tw-flex tw-min-w-36 tw-items-center"
            >
                <div
                    v-if="eventType.attributes.icon_id"
                    class="tw-mr-3 tw-shadow"
                    :class="iconClass"
                    :style="iconStyle"
                >
                    <CIcon
                        :path="eventType.icon.attributes.path"
                        :provider="eventType.icon.attributes.provider"
                    />
                </div>
                <div class="tw-mr-3 tw-truncate">
                    <div class="tw-mb-1 tw-text-sm tw-font-semibold">
                        {{ hours }}
                        <CBadge
                            v-if="event.attributes.datetime_event"
                            class="tw-ml-1 !tw-text-sm"
                            variant="cyan"
                        >
                            {{ event.readableHours }}
                        </CBadge>
                    </div>

                    <div class="tw-text-sm tw-opacity-90">
                        <template v-if="event.organization().value()">
                            {{ event.organization().value().attributes.name }} -
                            {{ event.organization().value().attributes.city }}
                        </template>
                        <template v-else>
                            {{ __('common:organization_zero') }}
                        </template>
                    </div>

                    <!-- TODO : Use With Group when fetching events -->
                    <!-- <div class="tw-opacity-90 tw-text-sm">
                        <template v-if="nurseryName && eventGroup">
                            {{ nurseryName }} • {{ eventGroup }}
                        </template>
                        <template v-else>
                            {{ nurseryName ? nurseryName : eventGroup }}
                        </template>
                    </div> -->

                    <div class="tw-text-sm tw-opacity-90">
                        {{
                            event.attributes.note
                                ? eventType.attributes.name + ' • ' + event.attributes.note
                                : eventType.attributes.name
                        }}
                    </div>
                </div>
            </div>
        </template>
    </MPopover>
</template>

<script lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import moment from 'moment';
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import CalculateEventTime from '@/modules/human-resources/utils/statistics/CalculateEventTime';
    import {minutesToHours, readableHoursFromDates} from '@/modules/legacy/libs/planning/planning';

    export default defineComponent({
        components: {},
        props: {
            event: {
                type: Object as PropType<EventModel>,
            },
            events: {
                type: Object as PropType<Collection<EventModel>>,
            },
            type: {
                type: String,
            },
            displaySum: {
                type: Boolean,
                default: false,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String as PropType<'sm' | 'md'>,
                default: 'md',
            },
            showReal: {
                type: Boolean,
                default: true,
            },
        },
        setup(props) {
            const hours = computed(() => {
                if (props.event?.attributes.datetime_event) {
                    const start = moment(props.event?.startedAt);
                    const end = moment(props.event?.endedAt);

                    return readableHoursFromDates(start, end);
                } else {
                    return __('hr_calendar:full_day_one');
                }
            });

            const totalEvents = computed(() => {
                const {real: realTime, forecast: forecastTime} = new CalculateEventTime(
                    filteredEvents.value?.all() || []
                ).getEventsTime();

                if (props.showReal) {
                    return minutesToHours(realTime);
                } else {
                    return minutesToHours(forecastTime);
                }
            });

            const filteredEvents = computed(() => {
                return props.events?.filter(item => item.attributes.type_id === props.type);
            });

            const eventType = computed(() => {
                if (props.events) {
                    return filteredEvents.value?.first().eventType().value();
                }

                return props.event?.eventType().value();
            });

            const color = computed(() => {
                return eventType.value!.attributes.color!;
            });
            const forecast = computed(() => {
                return props.event?.attributes.forecast;
            });

            const {badgeClass, badgeStyle, iconClass, iconStyle} = useEventStyle(
                color,
                forecast,
                props.size,
                props.editable
            );

            return {
                hours,
                totalEvents,
                eventType,

                badgeClass,
                badgeStyle,
                iconClass,
                iconStyle,
            };
        },
    });
</script>
