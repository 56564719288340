<template>
    <svg
        class="tw-fill-current"
        viewBox="0 0 160 160"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="M2.944 21.9006c-3.9253 4.1028-3.9253 10.7547 0 14.8574l35.1066 36.695-.0003 76.0412c0 5.8022 4.5002 10.5058 10.0514 10.5058 5.5512 0 10.0514-4.7036 10.0514-10.5058v-36.108c.0866-2.301 1.1288-3.4515 3.1264-3.4515 1.9976 0 3.0173 1.2205 3.0592 3.6617l-.0001 35.8978c0 5.8022 4.5001 10.5058 10.0513 10.5058 5.5513 0 10.0514-4.7036 10.0514-10.5058V74.6497l35.6147-37.226c3.9253-4.1028 3.9253-10.7547 0-14.8575s-10.2895-4.1028-14.2148 0c-9.5146 10.11-18.1051 19.1714-25.7713 27.1841-5.341 5.5824-12.179 6.7203-18.5699 6.7203s-12.1535-1.138-17.4945-6.7203c-7.7932-8.1455-16.742-17.4288-26.8467-27.8497-3.9253-4.1028-10.2895-4.1028-14.2148 0zM61.5 0C48.4363 0 37.8462 10.5345 37.8462 23.5294c0 12.995 10.5901 23.5294 23.6538 23.5294s23.6538-10.5345 23.6538-23.5294C85.1538 10.5344 74.5637 0 61.5 0z"
            />
            <path
                d="M138.0526 59C139.6804 59 141 60.6338 141 62.6491V78h15.3509C158.3662 78 160 79.3196 160 80.9474v8.1052C160 90.6804 158.3662 92 156.3509 92H141v15.3509c0 2.0153-1.3196 3.6491-2.9474 3.6491h-8.1052c-1.6278 0-2.9474-1.6338-2.9474-3.6491V92h-15.3509C109.6338 92 108 90.6804 108 89.0526v-8.1052C108 79.3196 109.6338 78 111.6491 78H127V62.6491C127 60.6338 128.3196 59 129.9474 59h8.1052z"
            />
        </g>
    </svg>
</template>
