import type {
    NumericFormatterContract,
    NumericFormatterContractOptions,
} from '@/modules/core/infrastructure/NumericFormatterContract';

export class NumericFormatter implements NumericFormatterContract {
    constructor(private readonly options: NumericFormatterContractOptions) {}

    public getCurrencySymbol(currencyCode: string): string {
        return Intl.NumberFormat(this.options.locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
            .format(1)
            .replace(/\d/g, '')
            .trim();
    }

    public formatNumber(value: number): string {
        return Intl.NumberFormat(this.options.locale, {
            maximumFractionDigits: this.options?.maximumFractionDigits,
            minimumFractionDigits: this.options?.minimumFractionDigits,
        }).format(value);
    }

    public formatCurrency(value: number, currency: string): string {
        return Intl.NumberFormat(this.options.locale, {
            style: 'currency',
            currency,
            maximumFractionDigits: this.options?.maximumFractionDigits,
            minimumFractionDigits: this.options?.minimumFractionDigits,
        }).format(value);
    }
}
