<template>
    <div>
        <transition
            mode="out-in"
            name="fade"
        >
            <div v-if="loader.loaded >= loader.attempted">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <MHeading class="tw-mb-4 sm:tw-mb-6">
                            {{ __('organization:devices_connected_to_meeko_team') }}
                        </MHeading>
                    </div>
                    <div class="col-12 col-sm-8 tw-self-center sm:tw-text-right">
                        <MButton
                            class="tw-mb-6"
                            variant="cyan"
                            @click="simulateTablet"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-tablet-alt" />
                            </template>
                            {{ __('organization:simulate_device') }}
                        </MButton>
                        <MButton
                            v-if="$can('create', 'devices')"
                            class="tw-mb-6 tw-ml-2"
                            variant="primary"
                            icon-plus
                            @click="saveTablet"
                        >
                            {{ __('organization:register_device') }}
                        </MButton>
                    </div>
                </div>
                <div class="row">
                    <div
                        v-for="(device, i) in orderedDevices"
                        :key="'device' + i"
                        class="col-12 col-lg-4"
                    >
                        <MBox class="tw-mb-4 tw-p-4">
                            <div>
                                <div>
                                    <h4
                                        class="h4 tw-cursor-pointer tw-font-grandhotel"
                                        @click="editDevice(device)"
                                    >
                                        <FontAwesomeIcon
                                            class="tw-mr-2 tw-text-primary-500"
                                            icon="fa-solid fa-tablet-alt"
                                        />
                                        {{ $capitalize(device.device_name) }}
                                    </h4>
                                    <support-meeko :ressource="device" />
                                    <div class="group lg:tw-inline-block lg:tw-text-center">
                                        <MBadge
                                            v-if="device.device_version === device.latest_version"
                                            variant="success"
                                        >
                                            <FontAwesomeIcon icon="fa-solid fa-check" />
                                            {{ __('organization:application_up_to_date') }}
                                        </MBadge>
                                        <MBadge
                                            v-else-if="device.device_version >= device.supported_version"
                                            variant="warning"
                                        >
                                            <FontAwesomeIcon icon="fa-solid fa-info-circle" />
                                            {{ __('organization:update_available') }}
                                        </MBadge>
                                        <MBadge
                                            v-else
                                            variant="danger"
                                        >
                                            <FontAwesomeIcon icon="fa-solid fa-exclamation-triangle" />
                                            {{ __('organization:update_required') }}
                                        </MBadge>
                                    </div>
                                </div>
                                <hr class="hr" />
                                <MButton
                                    class="tw-mb-2 tw-w-full"
                                    icon-menu
                                    :label="__('organization:notifications_received')"
                                    variant="light"
                                    @click="device.showNotif = !device.showNotif"
                                />
                                <div
                                    v-if="device.showNotif"
                                    class="small tw-mb-2 tw-pl-2"
                                >
                                    <p class="tw-my-1">
                                        {{ __('organization:the_device_receives_colon') }}
                                    </p>
                                    <div class="row ellipsis">
                                        <div class="col-6 tw-mt-1">
                                            <div class="small tw-mb-1 tw-font-semibold tw-uppercase">
                                                {{ __('common:parent_other') }}
                                            </div>
                                            <ul class="list-unstyled">
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'new_message')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'new_message')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:message_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'new_late')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'new_late')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:delay_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'new_absence')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'new_absence')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:absence_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'new_occasional')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'new_occasional')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:booking_other') }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-6 tw-mt-1">
                                            <div class="small tw-mb-1 tw-font-semibold tw-uppercase">
                                                {{ __('common:direction') }}
                                            </div>
                                            <ul class="list-unstyled">
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'new_document')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'new_document')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:document_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'new_post')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'new_post')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:news') }}
                                                </li>
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'new_registration')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'new_registration')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:registration_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        notifIncluded(device, 'kid_presence_unclocked')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            notifIncluded(device, 'kid_presence_unclocked')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('organization:missing_clocking_in') }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul class="list-unstyled" />
                                </div>
                                <MButton
                                    class="tw-mb-2 tw-w-full"
                                    icon-menu
                                    :label="__('organization:accessible_menus')"
                                    variant="light"
                                    @click="device.showCredentials = !device.showCredentials"
                                />
                                <div
                                    v-if="device.showCredentials"
                                    class="small tw-mb-2 tw-pl-2"
                                >
                                    <p class="tw-my-1">
                                        {{ __('organization:menu_configuration_other_colon') }}
                                    </p>
                                    <div class="row tw-truncate">
                                        <div class="col-6 tw-mt-1">
                                            <div class="small tw-mb-1 tw-font-semibold tw-uppercase">
                                                {{ __('common:kid_one') }}
                                            </div>
                                            <ul class="list-unstyled">
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'kid_attendances')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'kid_attendances')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:clocking_in_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'kid_kiosk')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'kid_kiosk')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('organization:clocking_in_parent') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'meals')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'meals') ? 'fa-eye' : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:meal') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'activities')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'activities')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:activity_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'naps')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'naps') ? 'fa-eye' : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:naps') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'health')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'health') ? 'fa-eye' : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:health') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'diapers')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'diapers')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:diaper') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'photos')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'photos') ? 'fa-eye' : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:photos') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'observations')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'observations')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:observation_other') }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-6 tw-mt-1">
                                            <div class="small tw-mb-1 tw-font-semibold tw-uppercase">
                                                {{ __('common:team_one') }}
                                            </div>
                                            <ul class="list-unstyled">
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'staff_attendances')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'staff_attendances')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:clocking_in_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'staff_kiosk')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'staff_kiosk')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('organization:locked_clocking_in') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'rooms')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'rooms') ? 'fa-eye' : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:room_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'equipment')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'equipment')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:equipment_one') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'news')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'news') ? 'fa-eye' : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:news') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'documents')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'documents')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('common:document_other') }}
                                                </li>
                                                <li
                                                    :class="
                                                        policyIncluded(device, 'staff_notes')
                                                            ? 'tw-text-primary-500'
                                                            : 'tw-text-gray-400'
                                                    "
                                                >
                                                    <FontAwesomeIcon
                                                        class="tw-mr-2"
                                                        :class="
                                                            policyIncluded(device, 'staff_notes')
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        "
                                                        fixed-width
                                                        icon="fa-solid"
                                                        style="margin-top: 2px"
                                                    />
                                                    {{ __('organization:teams_notes') }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <hr
                                    v-if="$can('update', 'devices') || $can('create', 'devices')"
                                    class="hr"
                                />

                                <MButton
                                    v-if="$can('create', 'devices')"
                                    class="tw-mb-2 tw-w-full"
                                    variant="success"
                                    @click="regenerateCode(device)"
                                >
                                    {{ __('organization:regenerate_code') }}
                                    <template #right-icons>
                                        <FontAwesomeIcon icon="fa-solid fa-sync" />
                                    </template>
                                </MButton>
                                <MButton
                                    v-if="$can('update', 'devices')"
                                    class="tw-w-full"
                                    variant="primary"
                                    @click="editDevice(device)"
                                >
                                    {{ __('organization:modify_device') }}
                                    <template #right-icons>
                                        <FontAwesomeIcon
                                            fixed-width
                                            icon="fa-solid fa-wrench"
                                        />
                                    </template>
                                </MButton>
                            </div>
                        </MBox>
                    </div>
                </div>
                <h1
                    v-if="!Object.keys(devices).length"
                    class="h1 tw-text-center tw-font-grandhotel"
                >
                    {{ __('organization:no_devices_connected') }}
                </h1>
            </div>
            <div
                v-else
                key="loader"
            >
                <loader custom-class="la-2x" />
            </div>
        </transition>
    </div>
</template>

<script>
    import {MqlOperation, MqlUnauthorizedError} from '@meekohq/lumos';
    import {set} from 'lodash-es';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _includes from 'lodash-es/includes';
    import _sortBy from 'lodash-es/sortBy';
    import moment from 'moment';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import UserModel from '@/modules/user/models/UserModel';

    import EditDeviceModal from './Devices/EditDeviceModal.vue';

    export default {
        props: ['nursery'],
        data: () => ({
            loader: {
                loaded: 0,
                attempted: 1,
            },
            devices: [],
            device_code: null,
            selectedUser: {},
        }),
        computed: {
            moment() {
                return moment;
            },
            orderedDevices() {
                return _sortBy(this.devices, 'device_name');
            },
        },
        mounted() {
            this.getDevices();
        },
        methods: {
            getDevices() {
                useApi()
                    .legacy.get(route('nurseries.devices.index', {nurseries: this.nursery.id}))
                    .then(response => {
                        this.loader.loaded++;
                        this.devices = response.data;
                        this.devices.forEach(device => {
                            set(device, 'showNotif', false);
                            set(device, 'showCredentials', false);
                            set(device, 'showReportingAuthorMandatory', false);
                        });
                    })
                    .catch(error => {
                        useNotification().error(error);
                    });
            },

            async editDevice(device) {
                this.selectedUser = await UserModel.query().find(device.id);

                useModal({
                    component: EditDeviceModal,
                    props: {
                        device,
                    },
                    listeners: modal => ({
                        update: async (device, organizationIds) => {
                            // Mirorring the device changes to the user
                            this.selectedUser.attributes.device_name = device.device_name;
                            this.selectedUser.attributes.device_policies = device.device_policies;
                            this.selectedUser.attributes.via_broadcast = device.via_broadcast;
                            this.selectedUser.attributes.device_reporting_author_mandatory_for =
                                device.device_reporting_author_mandatory_for;

                            // Save the user
                            try {
                                await this.selectedUser.save();

                                await new MqlOperation('device/sync_organizations', {
                                    device_id: device.id,
                                    organization_ids: organizationIds,
                                }).run();

                                this.getDevices();

                                useNotification().success(__('organization:device_updated_successfully'));

                                modal.hide();
                            } catch (error) {
                                if (error instanceof MqlUnauthorizedError) {
                                    useNotification().error(__('common:errors.unauthorized_to_perform_action'));
                                } else if (error.response?.status === 422) {
                                    _forEach(error.response.data.errors, function (value) {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(__('common:errors.generic'));
                                }
                            }
                        },
                        delete: device => {
                            this.deleteDevice(device);
                            modal.hide();
                        },
                    }),
                }).show();
            },
            deleteDevice(editingDevice) {
                const deviceIndex = this.devices.findIndex(device => device.id === editingDevice.id);

                useMagicModal().deleteConfirmationModal({
                    onConfirm: async () => {
                        useApi()
                            .legacy.delete(
                                route('nurseries.devices.destroy', {
                                    nurseries: this.nursery.id,
                                    device: editingDevice.id,
                                })
                            )
                            .then(() => {
                                this.devices.splice(deviceIndex, 1);
                                useNotification().success(__('organization:device_deleted_successfully'));
                            })
                            .catch(error => {
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, function (value) {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                            });
                    },
                });
            },

            saveTablet() {
                useApi()
                    .legacy.get(route('nurseries.devices.code', {nurseries: this.nursery.id}))
                    .then(response => {
                        this.device_code = response.data.code;

                        useMagicModal().confirmationModal({
                            title: __('organization:code_with_code', {code: this.device_code}),
                            text: __('organization:identification_code_to_enter'),
                            type: 'success',
                            showCancelButton: false,
                            confirmButtonText: __('organization:code_valid_for'),
                            onConfirm: async () => {
                                await this.getDevices();
                            },
                        });
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            regenerateCode(device) {
                useApi()
                    .legacy.get(
                        route('nurseries.devices.code.regenerate', {
                            nurseries: this.nursery.id,
                            device: device.id,
                        })
                    )
                    .then(response => {
                        this.device_code = response.data.code;
                        useMagicModal().confirmationModal({
                            title: __('organization:code_with_code', {code: this.device_code}),
                            text: __('organization:identification_code_to_enter'),
                            type: 'success',
                            showCancelButton: false,
                            confirmButtonText: __('organization:code_valid_for'),
                            onConfirm: async () => {
                                await this.getDevices();
                            },
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            simulateTablet() {
                window.open('https://team.meeko.app', 'Meeko', 'menubar=0,resizable=0,width=768,height=1024');
            },

            notifIncluded(device, notif) {
                return _includes(device.via_broadcast, notif);
            },

            policyIncluded(device, policy) {
                return _includes(device.device_policies, policy);
            },
        },
    };
</script>
