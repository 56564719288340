<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="family">
                {{ __('app:menu.members') }}
            </MPageTitle>
        </template>
        <template #content>
            <MagicIndex
                :loading="loading"
                :paginator="paginator"
                :router-link-fn="memberRouterLinkCallback"
                variant="family"
                @change="getFamilyMembers($event)"
            >
                <template #filters>
                    <Filters
                        v-model:contract-states="contractStates"
                        v-model:selected-tags="selectedTags"
                        v-model:without-tags-option-is-selected="withoutTagsOptionIsSelected"
                        :inject-query="filterTagsListQuery"
                    />
                </template>
                <template #header>
                    <FamilyMembersListHeader />
                </template>
                <template #item="{item: familyMember}">
                    <FamilyMembersListItem
                        :family-member="familyMember"
                        :paginator="paginator"
                        :tasks-by-member-id="tasksByMemberId"
                    />
                </template>
                <template #empty-list>
                    {{ __('common:empty_result') }}
                </template>
                <template #empty-list-action>
                    {{ __('common:try_modifying_filters_or_adding_data') }}
                </template>
            </MagicIndex>
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {Epoch, type LengthAwarePaginator} from '@meekohq/lumos';
    import _debounce from 'lodash-es/debounce';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import {useRoute, useRouter} from 'vue-router';

    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import Filters from '@/modules/family/components/member/FamilyMembersFilters.vue';
    import MemberModel from '@/modules/family/models/MemberModel';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import TagModel from '@/modules/tag/models/TagModel';
    import FamilyMembersListHeader from '@/pages/nurseries/[id]/family/members/FamilyMembersListHeader.vue';
    import FamilyMembersListItem from '@/pages/nurseries/[id]/family/members/FamilyMembersListItem.vue';

    export default defineComponent({
        name: 'FamilyIndex',
        components: {
            FamilyMembersListItem,
            FamilyMembersListHeader,
            SimplePageTemplate,
            MagicIndex,
            Filters,
        },
        setup() {
            const loading = ref(true);
            const {activeOrganization} = useManager();
            const activeOrganizationTags = computed(() => activeOrganization.value.tags().value());
            const {selectedOptions: selectedTags} = useUserFilters('tags:familyMembers:index', OutputType.array, []);
            const {selectedOptions: contractStates} = useUserFilters(
                'contracts:familyMembers:index',
                OutputType.array,
                []
            );
            const {selectedOptions: withoutTagsOptionIsSelected} = useUserFilters(
                'tags:familyMembers:without-tag',
                OutputType.value,
                false
            );
            const filterTagsListQuery = TagModel.query().where('type', 'family_member');
            const paginator = ref<LengthAwarePaginator<MemberModel> | null>(null);
            const currentPage = ref(1);
            const router = useRouter();
            const isFirstLoad = ref(true);

            onMounted(async () => {
                const route = useRoute();

                if (route?.query.page) {
                    const page = parseInt(route.query.page as string);
                    currentPage.value = page;
                    paginator.value?.setCurrentPage(page);
                }

                await activeOrganization.value.tags().load();
            });

            watch(
                [selectedTags, contractStates],
                _debounce(() => {
                    //if we are on first page load selected tags changes are triggered, by checking the isFirstLoad value
                    //we don't override the currentPage value set by query params in onMounted hook
                    if (!isFirstLoad.value) {
                        paginator.value?.setCurrentPage(1);
                        currentPage.value = 1;
                    } else {
                        isFirstLoad.value = false;
                    }

                    getFamilyMembers();
                }, 500),
                {immediate: true}
            );

            watch(currentPage, value => {
                router?.replace({
                    name: 'familyMembers.index',
                    query: {page: `${value}`},
                });
            });

            async function getFamilyMembers(page: number = currentPage.value || 1) {
                loading.value = true;

                const memberQuery = MemberModel.query().where('nursery_id', activeOrganization.value.id);

                addFamilyMembersRequestTagsConstraint(memberQuery);

                addFamilyMembersRequestContractsContraint(memberQuery);

                memberQuery
                    .with(new MemberModel().tags())
                    .orderBy(window.localStorage.getItem('display:orderBy') || 'first_name');

                memberQuery
                    .paginate(20, page)
                    .then(async queryPaginator => {
                        paginator.value = queryPaginator;
                        currentPage.value = queryPaginator.currentPage();

                        tasks.value = await getTasksRelatedToMembers(
                            queryPaginator
                                .items()
                                .map(memberModel => memberModel.getKey())
                                .all()
                        );
                    })
                    .finally(() => (loading.value = false));
            }

            function addFamilyMembersRequestTagsConstraint(familyMembersQuery) {
                if (selectedTags.value?.length) {
                    const tags = selectedTags.value as TagModel[];
                    const tagIds: string[] = tags.map(selectedTag => selectedTag.getKey());
                    familyMembersQuery.whereHas(new MemberModel().tagsPivots(), q => {
                        q.whereIn('tag_id', tagIds);
                    });
                }

                if (withoutTagsOptionIsSelected.value === true) {
                    familyMembersQuery.whereDoesntHave(new MemberModel().tagsPivots());
                }
            }

            function addFamilyMembersRequestContractsContraint(familyMembersQuery) {
                if (contractStates.value?.length) {
                    const contractStatesValue = contractStates.value as string[];
                    const withoutDrafts = !contractStatesValue.includes('including_draft');

                    //Object keys are possible contract filter values. Oject values are matching kid contract scopes
                    const contractStateMappedToScopeFunctions = {
                        coming: query => query.scope('withComingContract', withoutDrafts),
                        current: query => query.scope('withActiveContract', withoutDrafts),
                        past: query =>
                            query.scope('withPastContractUntil', Epoch.now().subDays(1).toISOString(), withoutDrafts),
                    };

                    const contractStateMappedToScope = contractStates.value
                        //filter states to remove including_draft as it's not a possible scope in the mapped array
                        .filter(contractState => contractState !== 'including_draft')
                        //map selected contract filters to matching scope functions in contractStateMappedToScopeFunctions object
                        .map(contractState => contractStateMappedToScopeFunctions[contractState]) as Function[];

                    //add scope constraint on contract related to the family members kids
                    familyMembersQuery.whereHas(new MemberModel().kids(), query1 => {
                        query1.where(query2 => {
                            //loop through the mapped scopes
                            contractStateMappedToScope.forEach((scope: Function, index: number) => {
                                if (index === 0) {
                                    query2.where(subKidQueryWhere => {
                                        scope(subKidQueryWhere);
                                    });
                                } else {
                                    query2.orWhere(subKidQueryWhere => {
                                        scope(subKidQueryWhere);
                                    });
                                }
                            });
                        });
                    });
                }
            }

            function memberRouterLinkCallback(familyMember: MemberModel) {
                return {
                    name: 'familyMembers.show',
                    params: {nursery: activeOrganization.value.attributes.id, familyMember: familyMember.attributes.id},
                };
            }

            const {
                tasks,
                tasksGroupByModelId: tasksByMemberId,
                getTasks: getTasksRelatedToMembers,
            } = useGetTasks({
                constrainToResourceModel: MemberModel,
            });

            return {
                loading,
                activeOrganization,
                activeOrganizationTags,
                selectedTags,
                tasksByMemberId,
                contractStates,
                withoutTagsOptionIsSelected,
                filterTagsListQuery,
                getFamilyMembers,
                paginator,
                memberRouterLinkCallback,
            };
        },
    });
</script>
