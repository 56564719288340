import {Epoch, Lang} from '@meekohq/lumos';
import type {ComputedRef, Ref} from 'vue';
import {computed, ref, watch} from 'vue';

const locales = {
    fr: {
        datePlaceholder: 'jj/mm/aaaa',
        dateFormats: ['dd/MM/yyyy', 'ddMMyyyy'],
        datetimePlaceholder: 'jj/mm/aaaa hh:mm',
        datetimeFormats: ["dd/MM/yyyy HH'h'mm", 'dd/MM/yyyy HH:mm', 'dd/MM/yyyy HHmm'],
        timePlaceholder: 'hh:mm',
        timeFormats: ["HH'h'mm", 'HH:mm', 'HHmm'],
    },
};

export type MDatePickerStoreType = ReturnType<typeof useMDatePicker>;

export type OutputValueType = string | undefined | {from?: string; to?: string};
interface PeriodType {
    from?: Epoch;
    to?: Epoch;
}
type ModifierType = (date?: Epoch) => Epoch | undefined;

function useMDatePicker(
    userValue: Ref<OutputValueType>,
    format: Ref<string>,
    allowUndefined: Ref<boolean>,
    withTime: Ref<boolean>,
    modifiers: {from?: ModifierType; to?: ModifierType} = {}
) {
    const dates = ref({from: undefined, to: undefined}) as Ref<PeriodType>;
    const hoveredDay = ref<Epoch>();
    const isPeriod = computed(() => Lang.isObject(userValue.value));

    const inputs = ref<{from?: string; to?: string}>({
        from: undefined,
        to: undefined,
    });

    const isInputEditable = ref(false);

    // Set calendar date the userValue to open the right month on the calendar
    let parsedUserValue: Epoch | undefined;
    if (isPeriod.value) {
        parsedUserValue = parseStringToEpoch((userValue.value as {from?: string; to?: string}).from);
    } else {
        parsedUserValue = parseStringToEpoch(userValue.value as string);
    }
    const calendarDate = ref(parsedUserValue ?? Epoch.now()) as Ref<Epoch>;

    const times = ref<{
        fromHour?: number;
        fromMinute?: number;
        toHour?: number;
        toMinute?: number;
    }>({
        fromHour: undefined,
        fromMinute: undefined,
        toHour: undefined,
        toMinute: undefined,
    });

    /**
     * Format date
     * @param value
     */
    function toDateFormat(value?: Epoch) {
        if (!value) {
            return undefined;
        }

        let locale = Epoch.getLocale();

        if (!locales[Epoch.getLocale()]) {
            locale = 'fr';
        }

        return value.toFormat(locales[locale].dateFormats[0]);
    }

    /**
     * Format time
     * @param value
     */
    function toTimeFormat(value?: Epoch) {
        if (!value) {
            return undefined;
        }

        let locale = Epoch.getLocale();

        if (!locales[Epoch.getLocale()]) {
            locale = 'fr';
        }

        return value.toFormat(locales[locale].timeFormats[0]);
    }

    /**
     * Formats for inputs
     */
    const formats = computed(() => {
        let locale = Epoch.getLocale();

        if (!locales[Epoch.getLocale()]) {
            locale = 'fr';
        }

        if (withTime.value) {
            return locales[locale].datetimeFormats;
        }

        return locales[locale].dateFormats;
    });

    /**
     * Placeholders for inputs
     */
    const placeholders = computed(() => {
        return {
            auto: withTime.value ? locales['fr'].datetimePlaceholder : locales['fr'].datePlaceholder,
            date: locales['fr'].datePlaceholder,
            time: locales['fr'].timePlaceholder,
        };
    });

    /**
     * Virtual hovered day, reflect date after modifiers
     */
    const virtualHoveredDay = computed(() => {
        if (isPeriod.value) {
            if (dates.value.from && !dates.value.to) {
                return modify('to', hoveredDay.value);
            } else {
                return modify('from', hoveredDay.value);
            }
        } else {
            return modify('from', hoveredDay.value);
        }
    });

    /**
     * Output formatted dates
     */
    const outputDates: ComputedRef<OutputValueType> = computed(() => {
        if (isPeriod.value) {
            return {
                from: dates.value.from ? parseEpochToString(dates.value.from) : undefined,
                to: dates.value.to ? parseEpochToString(dates.value.to) : undefined,
            };
        }

        return dates.value.from ? parseEpochToString(dates.value.from) : undefined;
    });

    /**
     * Parse date with format form props
     * @param value
     */
    function parseStringToEpoch(value?: string): Epoch | undefined {
        if (!value) {
            return;
        }

        if (format.value === 'iso8601') {
            return Epoch.fromISOString(value);
        }

        const epoch = Epoch.parse(value, format.value);

        if (withTime.value) {
            return epoch;
        }

        return epoch;
    }

    /**
     * Parse date with format form props
     * @param value
     */
    function parseEpochToString(value?: Epoch): string | undefined {
        if (!value) {
            return;
        }

        if (format.value === 'iso8601') {
            return value.toISOString();
        }

        return value.toFormat(format.value);
    }

    /**
     * Parse date from input.
     * @param value
     */
    function parseUserInputToEpoch(value?: string): Epoch | undefined {
        if (!value) {
            return undefined;
        }

        const clonedFormats = [...formats.value];

        while (clonedFormats.length > 0) {
            const date = Epoch.parse(value, clonedFormats.shift() as string);

            if (date.isValid) {
                return date;
            }
        }

        return undefined;
    }

    /**
     * Parse date to user input.
     * @param value
     */
    function parseEpochToUserInput(value?: Epoch): string | undefined {
        if (!value) {
            return undefined;
        }

        return value.toFormat(formats.value[0]);
    }

    /**
     * Set dates
     * @param from
     * @param to
     */
    function setDate(from?: Epoch, to?: Epoch) {
        if (from) {
            setFromDate(parseStringToEpoch(parseEpochToString(from)));
        } else {
            setFromDate(from);
        }

        if (to) {
            setToDate(parseStringToEpoch(parseEpochToString(to)));
        } else {
            setToDate(to);
        }
    }

    /**
     * Set dates.from
     * @param value
     */
    function setFromDate(value?: Epoch) {
        if (!value) {
            dates.value.from = undefined;

            return;
        }

        // If to is set and from is greater than to, replace to with from
        if (dates.value.to && value.greaterThan(dates.value.to)) {
            const to = modify('to', value);
            dates.value.to = to;
        }

        times.value.fromHour = isNaN(value!.hour) ? 0 : value!.hour;
        times.value.fromMinute = isNaN(value!.minute) ? 0 : value!.minute;

        value = modify('from', value);

        if (value?.isValid) {
            dates.value.from = value;
        }
    }

    /**
     * Set dates.to
     * @param value
     */
    function setToDate(value?: Epoch) {
        if (!value) {
            dates.value.to = undefined;

            return;
        }

        // If from is set and to is less than from, replace from with to
        if (dates.value.from && value.lessThan(dates.value.from)) {
            const from = modify('from', value);
            dates.value.from = from;
        }

        times.value.toHour = isNaN(value.hour) ? 0 : value.hour;
        times.value.toMinute = isNaN(value.minute) ? 0 : value.minute;

        value = modify('to', value);

        if (value?.isValid) {
            dates.value.to = value;
        }
    }

    /**
     * Check if dates are equal
     * @param from
     * @param to
     */
    function equalToDates(from?: Epoch, to?: Epoch) {
        if (!dates.value.from && !dates.value.to && !from && !to) {
            return true;
        }

        // From is required in all button for MDatePicker Period and Simple modes.
        if (!dates.value.from || !from) {
            return false;
        }

        // To can be undefined with MDatePicker "simple" mode
        return dates.value.from.equalTo(from) && ((!to && !dates.value.to) || dates.value.to?.equalTo(to));
    }

    /**
     * On day selected
     * @param day
     */
    function onDaySelected(day: Epoch) {
        const fromDay = day.setHour(times.value.fromHour ?? day.hour).setMinute(times.value.fromMinute ?? day.minute);
        const toDay = day.setHour(times.value.toHour ?? day.hour).setMinute(times.value.toMinute ?? day.minute);

        if (isPeriod.value) {
            if (dates.value.from && !dates.value.to) {
                setToDate(toDay);
            } else if (dates.value.to && !dates.value.from) {
                setFromDate(fromDay);
            } else {
                setFromDate(fromDay);
                setToDate();
            }
        } else {
            setFromDate(fromDay);
            setToDate();
        }
    }

    /**
     * On from input changed
     * @param value
     */
    function onFromInputChanged(value: string) {
        inputs.value.from = value;

        if (!value) {
            dates.value.from = undefined;

            return;
        }

        const parsed = parseUserInputToEpoch(value);
        if (parsed) {
            setFromDate(parsed);
        }
    }

    /**
     * On to input changed
     * @param value
     */
    function onToInputChanged(value: string) {
        inputs.value.to = value;

        if (!value) {
            dates.value.to = undefined;

            return;
        }

        const parsed = parseUserInputToEpoch(value);
        if (parsed) {
            setToDate(parsed);
        }
    }

    /**
     * Modify date with modifier
     * @param name
     * @param value
     */
    function modify(name: 'from' | 'to', value?: Epoch) {
        const cb = modifiers[name];

        return cb ? cb(value) : value;
    }

    function updateInputs(value: PeriodType) {
        if (value.from) {
            if (value.from.isValid) {
                const newFromInputValue = parseEpochToUserInput(value.from);
                if (newFromInputValue !== inputs.value.from) {
                    inputs.value.from = newFromInputValue;
                }
            }
        } else {
            inputs.value.from = undefined;
        }

        if (value.to) {
            if (value.to.isValid) {
                const newToInputValue = parseEpochToUserInput(value.to);
                if (newToInputValue !== inputs.value.to) {
                    inputs.value.to = newToInputValue;
                }
            }
        } else {
            inputs.value.to = undefined;
        }
    }

    /**
     * Set inputs from props
     */
    watch(
        userValue,
        value => {
            if (isPeriod.value && Lang.isObject(value)) {
                setFromDate(parseStringToEpoch(value.from));
                setToDate(parseStringToEpoch(value.to));
            } else {
                setFromDate(parseStringToEpoch(value as string));
                setToDate();
            }
        },
        {deep: true, immediate: true}
    );

    /**
     * Update inputs if dates are changed
     */
    watch(
        dates,
        value => {
            updateInputs(value);
        },
        {immediate: true, deep: true}
    );

    /**
     * Update calendar date if dates are changed
     */
    watch(
        dates,
        () => {
            const leftStart = calendarDate.value.startOfMonth().startOfWeek();
            const leftEnd = calendarDate.value.startOfMonth().startOfWeek().addDays(41).endOfDay();

            if (isPeriod.value) {
                // From and to unavailable in calendar
                if (
                    dates.value.from &&
                    !dates.value.from.between(leftStart, leftEnd) &&
                    dates.value.to &&
                    !dates.value.to.between(leftStart, leftEnd)
                ) {
                    calendarDate.value = dates.value.from.startOfMonth();
                }

                // From and to available in same month
                if (dates.value.from && dates.value.to && dates.value.from.hasSame(dates.value.to, 'month')) {
                    calendarDate.value = dates.value.from.startOfMonth();
                }
            } else {
                // From unavailable in calendar
                if (dates.value.from && !dates.value.from.between(leftStart, leftEnd)) {
                    calendarDate.value = dates.value.from.startOfMonth();
                }
            }
        },
        {deep: true}
    );

    return {
        allowUndefined,
        calendarDate,
        dates,
        equalToDates,
        formats,
        hoveredDay,
        inputs,
        isInputEditable,
        isPeriod,
        onDaySelected,
        onFromInputChanged,
        onToInputChanged,
        outputDates,
        placeholders,
        setDate,
        setFromDate,
        setToDate,
        times,
        toDateFormat,
        toTimeFormat,
        userValue,
        virtualHoveredDay,
        withTime,
    };
}

export default useMDatePicker;
