<template>
    <i
        aria-hidden="true"
        :class="baseClass"
    />
</template>

<script lang="ts">
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        props: {
            icon: {
                type: [Object, Array, String],
                required: true,
            },
            spin: {type: Boolean, default: false},
            fixedWidth: {type: Boolean, default: false},
            swapOpacity: {type: Boolean, default: false},
            size: {
                type: String,
                default: null,
                validator: value =>
                    [
                        '2xs',
                        'xs',
                        'sm',
                        'lg',
                        'xl',
                        '2xl',
                        '1x',
                        '2x',
                        '3x',
                        '4x',
                        '5x',
                        '6x',
                        '7x',
                        '8x',
                        '9x',
                        '10x',
                    ].indexOf(value as string) > -1,
            },
        },
        setup(props) {
            const baseClass = computed(() => {
                const output: unknown[] = [];

                output.push(props.icon);

                if (props.spin) {
                    output.push('fa-spin');
                }

                if (props.fixedWidth) {
                    output.push('fa-fw');
                }

                if (props.swapOpacity) {
                    output.push('fa-swap-opacity');
                }

                if (props.size) {
                    output.push(`fa-${props.size}`);
                }

                return output;
            });

            return {
                baseClass,
            };
        },
    });
</script>

<style scoped></style>
