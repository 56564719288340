import {Epoch} from '@meekohq/lumos';
import {isNil} from 'lodash-es';

import {BaseRule} from '@/modules/app/utils/rules/BaseRule';
import type ContractModel from '@/modules/human-resources/models/ContractModel';

export class ContractBrokedAtAfterStartedAtRule extends BaseRule {
    isValid(contract: ContractModel): boolean {
        if (isNil(contract.attributes.started_at) || isNil(contract.attributes.broked_at)) {
            return true;
        }

        return Epoch.parse(contract.attributes.broked_at, 'yyyy-MM-dd').greaterThanOrEqualTo(
            Epoch.parse(contract.attributes.started_at, 'yyyy-MM-dd')
        );
    }
}
