<template>
    <MMenu>
        <MMenuButton>
            <MButton icon-menu>
                {{ invoiceStatusText }}
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <MMenuItem @click="updateInvoiceStatus(null)">
                <template #icon>
                    <FontAwesomeIcon
                        v-if="!modelValue || typeof modelValue === 'undefined' || modelValue.length === 0"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ __('billing_invoice:all_states') }}
            </MMenuItem>
            <MMenuDivider />
            <template v-if="modelValue && Array.isArray(modelValue)">
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateInvoiceStatus('draft')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="modelValue && modelValue.includes('draft')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('billing_invoice:status.proforma') }}
                </MMenuItem>
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateInvoiceStatus('pending')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="modelValue && modelValue.includes('pending')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('billing_invoice:status.pending') }}
                </MMenuItem>
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateInvoiceStatus('incomplete')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="modelValue && modelValue.includes('incomplete')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('billing_invoice:status.incomplete') }}
                </MMenuItem>
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateInvoiceStatus('paid')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="modelValue && modelValue.includes('paid')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('billing_invoice:status.paid') }}
                </MMenuItem>
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateInvoiceStatus('unpaid')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="modelValue && modelValue.includes('unpaid')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('billing_invoice:status.unpaid_invoice') }}
                </MMenuItem>
                <MMenuItem
                    :auto-close-popover="false"
                    @click="updateInvoiceStatus('uncollectible')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            v-if="modelValue && modelValue.includes('uncollectible')"
                            fixed-width
                            icon="fa-solid fa-square-check"
                        />
                        <FontAwesomeIcon
                            v-else
                            fixed-width
                            icon="fa-regular fa-square"
                        />
                    </template>
                    {{ __('billing_invoice:status.uncollectible') }}
                </MMenuItem>
            </template>
        </MMenuItems>
    </MMenu>
</template>

<script>
    import _isEqual from 'lodash-es/isEqual';

    import __ from '@/modules/app/utils/i18n-facade';
    import InvoiceStatusValue from '@/modules/cashier/utils/billing/invoice/InvoiceStatusValue';

    export default {
        props: {
            modelValue: {},
        },
        emits: ['update:modelValue'],
        computed: {
            invoiceStatusText() {
                let txt = __('billing_invoice:states');
                if (!this.modelValue || this.modelValue.length === 0) {
                    txt = __('billing_invoice:all_states');
                } else if (this.modelValue.length === 1) {
                    if (this.modelValue.includes(InvoiceStatusValue.draft)) {
                        txt = __('billing_invoice:status.proforma');
                    }

                    if (this.modelValue.includes(InvoiceStatusValue.pending)) {
                        txt = __('billing_invoice:status.pending');
                    }

                    if (this.modelValue.includes(InvoiceStatusValue.incomplete)) {
                        txt = __('billing_invoice:status.incomplete');
                    }

                    if (this.modelValue.includes(InvoiceStatusValue.paid)) {
                        txt = __('billing_invoice:status.paid');
                    }

                    if (this.modelValue.includes(InvoiceStatusValue.unpaid)) {
                        txt = __('billing_invoice:status.unpaid_invoice');
                    }

                    if (this.modelValue.includes(InvoiceStatusValue.uncollectible)) {
                        txt = __('billing_invoice:status.uncollectible');
                    }
                }

                return txt;
            },
        },
        mounted() {
            if (!Array.isArray(this.modelValue)) {
                this.$emit('update:modelValue', []);
            }
        },
        methods: {
            updateInvoiceStatus(val) {
                let states = this.modelValue ? this.modelValue.slice() : [];

                if (!val) {
                    states = [];
                } else if (states.includes(val)) {
                    states = states.filter(e => e !== val);
                } else {
                    states.push(val);

                    const allStates = [
                        InvoiceStatusValue.draft,
                        InvoiceStatusValue.pending,
                        InvoiceStatusValue.incomplete,
                        InvoiceStatusValue.paid,
                        InvoiceStatusValue.unpaid,
                        InvoiceStatusValue.uncollectible,
                    ];

                    if (
                        _isEqual(
                            allStates.sort((a, b) => a.localeCompare(b)),
                            states.sort((a, b) => a.localeCompare(b))
                        )
                    ) {
                        states = [];
                    }
                }

                this.$emit('update:modelValue', states);
            },
        },
    };
</script>
