<script lang="ts" setup>
    import {type Collection} from '@meekohq/lumos';
    import {type PropType} from 'vue';

    import type {ErrorType} from '@/modules/app/composables/useError';

    defineProps({
        icon: {type: String, default: undefined},
        label: {type: String, default: undefined},
        placeholder: {type: String, default: undefined},
        modelValue: {type: [String, Number], default: undefined},
        focus: {type: Boolean, default: false},
        errors: {type: Object as PropType<Collection<string | ErrorType> | null>, default: undefined},
        type: {type: String, default: undefined},
    });

    const emit = defineEmits(['update:modelValue']);
</script>

<template>
    <MFieldset>
        <MLabel :icon="icon">
            {{ label }}
        </MLabel>
        <MInput
            v-focus="focus"
            :has-error="errors?.isNotEmpty()"
            :placeholder="placeholder"
            :type="type"
            :model-value="modelValue"
            @update:model-value="emit('update:modelValue', $event)"
        />
        <CFormErrorMessageList
            v-if="errors"
            :errors="errors"
        />
    </MFieldset>
</template>
