import useApi from '@/modules/app/composables/useApi';

export function retrieve(url, callback, errorCallback) {
    useApi()
        .legacy.get(url)
        .then(response => {
            if (typeof callback === 'function') {
                callback(response);
            }
        })
        .catch(error => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
}
