<template>
    <MPopover>
        <MButton icon-menu>
            <template #left-icons>
                <FontAwesomeIcon icon="fa-duotone fa-file-download" />
            </template>
            {{ __('billing_customer:accounting_export') }}
        </MButton>
        <template #content>
            <div class="tw-flex tw-flex-col tw-gap-4">
                <MDatePeriodFilter
                    v-model="periodFilter"
                    button-class="tw-w-full"
                />
                <OrganizationFinder
                    v-model="selectedOrganizations"
                    button-class="tw-w-full"
                    :inject-query="organizationFinderQuery"
                    multi
                    :multi-minimum="1"
                />
                <MSelectMenu
                    v-model="exportTypes"
                    multi
                    :options="exportTypesOptions"
                />
                <MButton
                    class="tw-w-full"
                    :disabled="!selectedOrganizations.length"
                    :loading="loading"
                    variant="primary"
                    @click="accountingExport()"
                >
                    {{ __('common:actions.export') }}
                </MButton>
            </div>
        </template>
    </MPopover>
</template>

<script lang="ts">
    import type {CustomObject} from '@meekohq/lumos';
    import {MqlOperation, Str} from '@meekohq/lumos';
    import moment from 'moment';
    import {computed, defineComponent, ref} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import __ from '@/modules/app/utils/i18n-facade';
    import TenantModel from '@/modules/cashier/models/TenantModel';
    import MDatePeriodFilter from '@/modules/meeko-ui/components/MDatePeriodFilter.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {
            MDatePeriodFilter,
            OrganizationFinder,
        },
        setup() {
            const loading = ref(false);
            const {error: toastError} = useNotification();

            const {selectedOptions: from} = useUserFilters(
                'billing:account:export:from',
                OutputType.value,
                moment().startOf('month').toISOString(true)
            );
            const {selectedOptions: to} = useUserFilters(
                'billing:account:export:to',
                OutputType.value,
                moment().endOf('month').toISOString(true)
            );

            const periodFilter = ref({
                from: from.value,
                to: to.value,
            });

            const {selectedOptions: exportTypes} = useUserFilters('billing:account:export:types', OutputType.array, [
                'invoices',
                'transactions',
                'deposits',
            ]);

            const exportTypesOptions = [
                {value: 'invoices', text: __('common:invoice_other')},
                {value: 'transactions', text: __('common:transactions')},
                {value: 'deposits', text: __('common:deposit_other')},
            ];

            const {activeOrganization} = useManager();
            const selectedOrganizations = ref([activeOrganization.value]);

            const organizationFinderQuery = computed(() => OrganizationModel.query().scope('userOrganizations'));

            async function accountingExport() {
                loading.value = true;

                try {
                    const tenantsModels = await getTenantsFromSelectedOrganizations();
                    const tenantsIds: string[] = tenantsModels.pluck('id').toArray();

                    new MqlOperation<CustomObject>('cashier/accouting_export', {
                        // Hack to remove timezone from date because we use a plain date (will be fixed in the future)
                        from: moment.utc(moment(periodFilter.value.from).format('YYYY-MM-DDTHH:mm:ss')).unix(),
                        to: moment.utc(moment(periodFilter.value.to).format('YYYY-MM-DDTHH:mm:ss')).unix(),
                        tenant_ids: tenantsIds,
                        types: exportTypes.value,
                    })
                        .run()
                        .then(async result => {
                            // Generate fake link to download
                            const link = document.createElement('a');
                            link.download = Str.uuid() + '.csv';
                            link.target = '_blank';
                            link.href = 'data:application/csv;base64,' + result.content.data.csv;
                            link.dispatchEvent(
                                new MouseEvent('click', {bubbles: true, cancelable: true, view: window})
                            );
                        })
                        .catch(() => {
                            toastError(__('common:errors.generic'));
                        });

                    loading.value = false;
                } catch (error) {
                    loading.value = false;
                }
            }

            async function getTenantsFromSelectedOrganizations() {
                const organizationsIds: string[] = selectedOrganizations.value.map(item => item.getKey());

                return await TenantModel.query()
                    .whereHas(new TenantModel().organizations(), query => {
                        query.whereIn('id', organizationsIds);
                    })
                    .get();
            }

            return {
                accountingExport,
                exportTypes,
                exportTypesOptions,
                loading,
                organizationFinderQuery,
                periodFilter,
                selectedOrganizations,
            };
        },
    });
</script>
