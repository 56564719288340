import {collect} from '@meekohq/lumos';
import type {Moment} from 'moment';
import moment from 'moment';

import type EventModel from '@/modules/human-resources/models/EventModel';
import OverrunEvent from '@/modules/human-resources/utils/planning/OverrunEvent';
import SliceEvent from '@/modules/legacy/libs/periodSplitter/SliceEvent';
import type {ISplittedEvent} from '@/modules/legacy/libs/periodSplitter/SplitEvent';
import SplitEvent from '@/modules/legacy/libs/periodSplitter/SplitEvent';

export default class OverrunsFinder {
    private readonly event: EventModel;
    private readonly eventsToCompare: EventModel[];

    constructor(event: EventModel, eventsToCompare: EventModel[] = []) {
        this.event = event;
        this.eventsToCompare = eventsToCompare;
    }

    public getOverruns() {
        const splittableEvents = this.mapEventModelsToSplittableEvents();

        const constrainedEvents = this.constrainEventsInPeriod(
            splittableEvents.toArray(),
            moment(this.event.startedAt),
            moment(this.event.endedAt)
        );

        const splittedEvents = this.splitEventsWithStrategy(constrainedEvents);

        // Keep only events related to baseEvent
        return splittedEvents.filter(e => e.sources[0] === this.event);
    }

    protected mapEventModelsToSplittableEvents() {
        return collect(this.eventsToCompare).map(event => {
            return new OverrunEvent(moment(event.startedAtRaw).unix(), moment(event.endedAtRaw).unix(), [event]);
        });
    }

    protected splitEventsWithStrategy(events: OverrunEvent[]): OverrunEvent[] {
        return new SplitEvent(events).setStrategyCallback(splitStrategyCallback).split();
    }

    protected constrainEventsInPeriod(events: OverrunEvent[], from: Moment, to: Moment) {
        return new SliceEvent(events, from.unix(), to.unix()).slice();
    }
}

const splitStrategyCallback = (
    originalEvent: OverrunEvent,
    overlappingEvent: OverrunEvent | null,
    splittedEvents: ISplittedEvent<OverrunEvent>
): OverrunEvent[] => {
    const originalSource = originalEvent.sources as EventModel[];

    if (overlappingEvent) {
        const overlappingSource = overlappingEvent.sources as EventModel[];

        if (originalSource[0].attributes.forecast === false && overlappingSource[0].attributes.forecast === true) {
            const eventsToPush: OverrunEvent[] = [];

            // If before event which not same than source event
            if (
                splittedEvents.beforeEvent &&
                (splittedEvents.beforeEvent.endedAt !== originalEvent.endedAt ||
                    splittedEvents.beforeEvent.startedAt !== originalEvent.startedAt)
            ) {
                // Il y'a un dépassement avant
                splittedEvents.beforeEvent.position = 'before';
                eventsToPush.push(splittedEvents.beforeEvent);
            }

            // If after event which not same than source event
            if (
                splittedEvents.afterEvent &&
                (splittedEvents.afterEvent.endedAt !== originalEvent.endedAt ||
                    splittedEvents.afterEvent.startedAt !== originalEvent.startedAt)
            ) {
                // Il y'a un dépassement après
                splittedEvents.afterEvent.position = 'after';
                eventsToPush.push(splittedEvents.afterEvent);
            }

            return eventsToPush;
        }
    }

    return [];
};
