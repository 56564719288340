import useModal from '@/modules/app/composables/useModal';
import SelectImageModal from '@/modules/news/components/SelectImageModal.vue';
import __ from '@/modules/app/utils/i18n-facade';

(function ($) {
    'use strict';

    $.extend(true, $.trumbowyg, {
        plugins: {
            insertImage: {
                init: function (trumbowyg) {
                    const btnDef = {
                        title: __('news:insert_image_link'),
                        ico: 'link',
                    };

                    trumbowyg.addBtnDef('insertImage', btnDef);
                },
            },
            selectImage: {
                init: function (trumbowyg) {
                    const btnDef = {
                        title: __('news:insert_image'),
                        ico: 'insertImage',
                        fn: function () {
                            trumbowyg.saveRange();

                            useModal({
                                component: SelectImageModal,
                                listeners: modal => ({
                                    'select': photo => {
                                        if (photo.video) {
                                            trumbowyg.execCmd(
                                                'insertHTML',
                                                `<video src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="video" width="100%" height="auto" preload="metadata" controls><source src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="video"/></video>`
                                            );
                                        } else {
                                            trumbowyg.execCmd(
                                                'insertHTML',
                                                `<img src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="photo" width="250px"/>`
                                            );
                                        }

                                        modal.hide();
                                    },
                                    'new-photos': photos => {
                                        photos.forEach(photo => {
                                            if (photo.video) {
                                                trumbowyg.execCmd(
                                                    'insertHTML',
                                                    `<video src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="video" width="100%" height="auto" preload="metadata" controls><source src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="video"/></video>`
                                                );
                                            } else {
                                                trumbowyg.execCmd(
                                                    'insertHTML',
                                                    `<img src="${photo.photo_url}" meeko-resource-id="${photo.id}" meeko-resource-type="photo" width="250px"/>`
                                                );
                                            }
                                        });

                                        modal.hide();
                                    },
                                }),
                            }).show();
                        },
                    };

                    trumbowyg.addBtnDef('selectImage', btnDef);
                },
            },
        },
    });
})(jQuery);
