import {Epoch} from '@meekohq/lumos';

export type DateTimeFormatModes = 'time' | 'date' | 'month' | 'year';

const formats: Record<string, Record<DateTimeFormatModes, string>> = {
    fr: {
        time: 'HH:mm',
        date: 'dd/MM/yyyy',
        month: 'MM/yyyy',
        year: 'yyyy',
    },
    en: {
        time: 'HH:mm',
        date: 'MM/dd/yyyy',
        month: 'MM/yyyy',
        year: 'yyyy',
    },
    de: {
        time: 'HH:mm',
        date: 'dd.MM.yyyy',
        month: 'MM.yyyy',
        year: 'yyyy',
    },
} as const;

export default function useLocalDateTimeFormats() {
    const fallback = 'fr';

    function getLocalFormats() {
        let localFormats = formats[Epoch.getLocale()];

        if (!localFormats) {
            localFormats = formats[fallback];
        }

        return localFormats;
    }

    return {
        getLocalFormats,
    };
}
