<template>
    <CSkeleton
        :count="3"
        layout="custom"
    >
        <div class="tw-my-6 tw-flex tw-items-center tw-p-3">
            <div class="tw-w-3/6">
                <CVStack gap="4">
                    <CHStack
                        align="center"
                        gap="2"
                    >
                        <div class="tw-h-7 tw-w-7 tw-rounded-lg tw-bg-gray-200" />
                        <div class="tw-h-3 tw-w-36 tw-rounded-full tw-bg-gray-200" />
                    </CHStack>
                    <CHStack gap="2">
                        <div class="tw-h-6 tw-w-32 tw-rounded-lg tw-bg-gray-200" />
                        <div class="tw-h-6 tw-w-32 tw-rounded-lg tw-bg-gray-200" />
                    </CHStack>
                </CVStack>
            </div>
            <div class="tw-ml-auto tw-flex">
                <CHStack
                    align="center"
                    gap="2"
                >
                    <div class="tw-h-4 tw-w-24 tw-rounded-full tw-bg-gray-200" />
                    <div class="tw-h-7 tw-w-8 tw-rounded-lg tw-bg-gray-200" />
                </CHStack>
            </div>
        </div>
    </CSkeleton>
</template>
