<script setup lang="ts">
    import {Epoch, Lang} from '@meekohq/lumos';
    import {useFocus} from '@vueuse/core/index';
    import {isNil} from 'lodash-es';
    import {provide, ref, toRef, watch} from 'vue';

    import type {MCalendarMode, UseMCalendar} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';
    import MCalendarInputField from '@/modules/meeko-ui/components/MCalendarInput/MCalendarInputField.vue';
    import useMCalendarInputState, {
        keyUseMCalendarInputState,
    } from '@/modules/meeko-ui/components/MCalendarInput/useMCalendarInputState';
    import type {DateTimeModifiers} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import type {DateTimeValue} from '@/modules/meeko-ui/types/DateTime';

    const props = withDefaults(
        defineProps<{
            modelValue: DateTimeValue;
            mode: MCalendarMode;
            modifiers?: DateTimeModifiers;
            disabled?: boolean;
            hasError?: boolean;
            allowUndefined?: boolean;
            isPeriod?: boolean;
        }>(),
        {
            modifiers: undefined,
            disabled: false,
            hasError: false,
            allowUndefined: false,
            isPeriod: false,
        }
    );

    const emit = defineEmits<{
        (e: 'focus', value: boolean): void;
        (e: 'update:modelValue', value: DateTimeValue): void;
    }>();

    validateProps(props.modelValue);

    const state = useMCalendarInputState(
        {
            isPeriod: props.isPeriod,
            value: toRef(props, 'modelValue'),
        } as UseMCalendar,
        props.allowUndefined,
        props.mode,
        props.modifiers
    );
    provide(keyUseMCalendarInputState, state);

    const {internalValue, focusScope, toggleFocusScope, setDates} = state;

    const mainDivEl = ref<HTMLDivElement>();
    const {focused: mainDivFocused} = useFocus(mainDivEl);

    function validateProps(value: unknown) {
        if (props.isPeriod) {
            validatePeriodModeProps(value);

            return;
        }

        validateSingleModeProps(value);
    }

    function validateSingleModeProps(value: unknown) {
        if (!props.allowUndefined && isNil(value)) {
            throw new Error('value cannot be undefined when allowUndefined is false');
        }

        if (!isNil(value) && !(value instanceof Epoch)) {
            throw new Error('value must be an Epoch instance');
        }
    }

    function validatePeriodModeProps(value: unknown) {
        if (isNil(value)) {
            throw new Error('value cannot be undefined in period mode');
        }

        if (value instanceof Epoch || !Lang.isObject(value)) {
            throw new Error('value must be an object with from and to properties in period mode');
        }

        if ('from' in value && !isNil(value.from) && !(value.from instanceof Epoch)) {
            throw new Error('value.from must be an Epoch instance');
        }

        if ('to' in value && !isNil(value.to) && !(value.to instanceof Epoch)) {
            throw new Error('value.to must be an Epoch instance');
        }
    }

    function resetFocus() {
        focusScope.value = undefined;
    }

    watch(() => props.modelValue, validateProps, {deep: true});

    watch(mainDivFocused, newValue => {
        if (newValue && !props.disabled) {
            toggleFocusScope('from', true);
            emit('focus', true);
        }
    });

    watch(focusScope, newValue => {
        if (!newValue) {
            emit('focus', false);
        }
    });

    watch(
        internalValue,
        newValue => {
            if (props.isPeriod) {
                emit('update:modelValue', {
                    from: newValue.from,
                    to: newValue.to,
                });
            } else {
                emit('update:modelValue', newValue.from);
            }
        },
        {deep: true}
    );

    defineExpose({
        resetFocus,
    });
</script>

<template>
    <div
        ref="mainDivEl"
        class="MCalendarInput CInput CInput--light CInput--base"
        :class="{
            'CInput--has-error': hasError,
            'MCalendarInput--disabled': disabled,
        }"
        :tabindex="!disabled ? 0 : undefined"
    >
        <MCalendarInputField
            :disabled="disabled"
            :focus="focusScope === 'from'"
            :tab-index="1"
            :model-value="internalValue.from"
            @focus="toggleFocusScope('from', $event)"
            @update:model-value="setDates({from: $event, to: internalValue.to})"
        />
        <FontAwesomeIcon
            v-if="isPeriod"
            class="MCalendarInput__arrow"
            icon="fa-regular fa-arrow-right"
        />
        <MCalendarInputField
            v-if="isPeriod"
            :disabled="disabled"
            :focus="focusScope === 'to'"
            :tab-index="2"
            :model-value="internalValue.to"
            @focus="toggleFocusScope('to', $event)"
            @update:model-value="setDates({from: internalValue.from, to: $event})"
        />
    </div>
</template>

<style scoped>
    .MCalendarInput {
        @apply tw-flex tw-items-baseline tw-gap-2;
    }

    .MCalendarInput--disabled {
        cursor: not-allowed !important;
        background-color: theme(backgroundColor.gray.50) !important;
        color: theme(textColor.gray.600) !important;
    }

    .MCalendarInput__arrow {
        @apply tw-text-gray-400;
    }
</style>
