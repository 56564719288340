<script setup lang="ts">
    import {trim} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed} from 'vue';

    const props = defineProps({
        member: {
            type: Object as PropType<any>,
            required: true,
        },
    });

    const fullname = computed(() => {
        let value = props.member.first_name ?? '';

        if (props.member.last_name) {
            value += ' ' + props.member.last_name;
        }

        return trim(value);
    });
</script>

<template>
    <MAvatar
        fallback-initials
        :gender="member.gender"
        :name="fullname"
        :url="member.avatar_url"
    />
</template>
