<script setup lang="ts">
    import type {PropType} from 'vue';

    type ValueType = boolean | string | number;

    defineProps({
        options: {
            type: Array as PropType<{text: string; value: ValueType}[]>,
            default: undefined,
        },
        modelValue: {
            type: [Boolean, String, Number] as PropType<ValueType>,
            default: undefined,
        },
        selectedVariant: {
            type: String,
            default: 'primary',
        },
    });

    const emit = defineEmits<{
        (e: 'update:modelValue', value: boolean | string | number): void;
    }>();
</script>

<template>
    <div class="MButtonSelect">
        <MButton
            v-for="(option, index) in options"
            :key="`${index}_${option.value}`"
            :label="option.text"
            :truncate="false"
            :variant="option.value === modelValue ? selectedVariant : 'light'"
            @click="emit('update:modelValue', option.value)"
        />
    </div>
</template>

<style scoped>
    .MButtonSelect {
        @apply tw-inline-flex;
    }

    .MButtonSelect .CButton {
        @apply tw-ml-px tw-whitespace-normal tw-rounded-none;
    }

    .MButtonSelect .CButton:focus {
        @apply tw-z-10;
    }

    .MButtonSelect .CButton:first-child {
        @apply tw-rounded-l-lg;
    }

    .MButtonSelect .CButton:last-child {
        @apply tw-rounded-r-lg;
    }
</style>
