<script setup lang="ts">
    import {computed, ref, watch} from 'vue';
    import {useRoute} from 'vue-router';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import type {LegacyKidType} from '@/modules/family/utils/kid/LegacyKidType';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    const props = defineProps<{
        kid: LegacyKidType;
        foods: {id: string; name: string}[];
    }>();

    const kidRoute = useRoute();

    const search = ref<string>('');
    const kidFoods = ref<{id: string; name: string}[]>([]);
    const activeFoodId = ref<string | null>(null);
    const loadingList = ref(false);

    function getKidsFoods() {
        loadingList.value = true;
        useApi()
            .legacy.get(
                route('kids.foods.index', {
                    kid: props.kid.id,
                })
            )
            .then(response => {
                kidFoods.value = response.data;
            })
            .catch(error => {
                handleError(error);
            })
            .finally(() => {
                loadingList.value = false;
            });
    }

    function toggleFood(food: {id: string; name: string}) {
        if (hasFood(food)) {
            removeFood(food);
        } else {
            addFood(food);
        }
    }

    function handleError(error: any) {
        if (error?.response && error.response.status === 422) {
            Object.values(error.response.data.errors).forEach((value: any) => {
                useNotification().error(value[0]);
            });
        } else {
            useNotification().error(error);
        }
    }

    function hasFood(food: {id: string; name: string}) {
        return kidFoods.value.some(item => item.id === food.id);
    }

    function addFood(food: {id: string; name: string}) {
        activeFoodId.value = food.id;

        useApi()
            .legacy.post(
                route('kids.foods.store', {
                    kid: props.kid.id,
                    food_id: food.id,
                })
            )
            .then(() => {
                kidFoods.value.push(food);
                useNotification().success(
                    __('family_kid:foods.added_successfully', {
                        kid_name: props.kid.first_name,
                        food_name: __(`foods:${food.name}`),
                    })
                );
            })
            .catch(handleError)
            .finally(() => {
                activeFoodId.value = null;
            });
    }

    function removeFood(food: {id: string; name: string}) {
        activeFoodId.value = food.id;

        useApi()
            .legacy.delete(
                route('kids.foods.destroy', {
                    kid: props.kid.id,
                    food: food.id,
                })
            )
            .then(() => {
                const index = kidFoods.value.findIndex(item => item.id === food.id);
                if (index !== -1) kidFoods.value.splice(index, 1);
                useNotification().info(__('family_kid:food_has_been_removed'));
            })
            .catch(handleError)
            .finally(() => {
                activeFoodId.value = null;
            });
    }

    const filteredFoods = computed(() => {
        const filtered = props.foods.filter(food => {
            const foodName = __(`foods:${food.name}`)
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            const searchNormalized = search.value
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');

            return foodName.includes(searchNormalized);
        });

        return filtered.sort((a, b) => __(`foods:${a.name}`).localeCompare(__(`foods:${b.name}`)));
    });

    watch(
        () => kidRoute.params.kid,
        () => {
            getKidsFoods();
        },
        {immediate: true}
    );
</script>

<template>
    <loader
        v-if="loadingList || !foods"
        class="tw-p-4"
        light="true"
        shadow="false"
        size="sm"
        :title="__('family_kid:loading_food_colon_other_dots')"
    />
    <div v-else>
        <div class="KidFoodList">
            <MHeading>
                <FontAwesomeIcon
                    class="tw-mr-2 tw-text-info-500"
                    icon="fa-solid fa-utensils"
                />
                {{ __('family_kid:foods.introductions') }}
            </MHeading>
            <MInput
                v-model="search"
                :placeholder="__('family_kid:food_search_dots')"
            />
            <div class="KidFoodList__container">
                <div class="KidFoodList__grid">
                    <MButton
                        v-for="(food, i) in filteredFoods"
                        :key="'food' + i"
                        variant="link"
                        align="left"
                        class="KidFoodList__item"
                        :class="{'KidFoodList__item--selected': hasFood(food)}"
                        :loading="activeFoodId === food.id"
                        :disabled="activeFoodId !== null"
                        @click="toggleFood(food)"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon :icon="'fd-' + food.name" />
                        </template>
                        {{ __(`foods:${food.name}`) }}
                    </MButton>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .KidFoodList {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .KidFoodList__container {
        @apply tw-mt-2 tw-h-full tw-rounded-lg tw-bg-gray-50 tw-p-4;
    }

    .KidFoodList__grid {
        @apply tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3 lg:tw-grid-cols-4;
    }

    .KidFoodList__item {
        @apply tw-w-fit tw-p-0 tw-text-gray-500;
    }

    .KidFoodList__item--selected {
        @apply tw-text-info-500;
    }
</style>
