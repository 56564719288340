<template>
    <div
        class="clock tw-rounded-b-lg"
        :style="{left: unixToPercent(now) + '%'}"
    />
</template>

<script>
    import {useIntervalFn} from '@vueuse/core';
    import moment from 'moment';

    export default {
        props: ['day', 'opening', 'closing'],
        data: () => ({
            now: moment().unix(),
        }),
        mounted() {
            useIntervalFn(() => {
                this.now = moment().unix();
            }, 1000 * 60);
        },
        methods: {
            unixToPercent(unix) {
                const openingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.opening).hours())
                    .minutes(moment.duration(this.opening).minutes())
                    .seconds(moment.duration(this.opening).seconds())
                    .unix();

                const closingTimestamp = this.day
                    .clone()
                    .hours(moment.duration(this.closing).hours())
                    .minutes(moment.duration(this.closing).minutes())
                    .seconds(moment.duration(this.closing).seconds())
                    .unix();

                return ((unix - openingTimestamp) * 100) / (closingTimestamp - openingTimestamp);
            },
        },
    };
</script>

<style scoped>
    @media print {
        .clock {
            display: none;
        }
    }

    .clock {
        position: absolute;
        top: -10%;
        z-index: 21;
        margin-left: -1px;
        background-color: red;
        width: 2px;
        height: 120%;
        pointer-events: none;

        &:before {
            position: absolute;
            top: -4px;
            left: -3px;
            border-radius: 100px;
            background-color: red;
            width: 8px;
            height: 8px;
            content: '';
        }
    }
</style>
