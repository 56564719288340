<script lang="ts" setup>
    import {inject} from 'vue';

    import {keyMCalendarPresets} from '@/modules/meeko-ui/components/MCalendar/useMCalendarPresets';
    import {keyMCalendarState} from '@/modules/meeko-ui/components/MCalendar/useMCalendarState';

    const state = inject(keyMCalendarState);
    if (!state) {
        throw new Error('keyMCalendarState must be provided');
    }

    const presetsState = inject(keyMCalendarPresets);
    if (!presetsState) {
        throw new Error('keyMCalendarPresets must be provided');
    }

    const {mode} = state;

    const {presets, isPresetEnabled, activatePreset} = presetsState;
</script>

<template>
    <div
        class="MCalendarPresets"
        :class="{
            'MCalendarPresets-time-mode': mode === 'time',
        }"
    >
        <button
            v-for="preset in presets"
            :key="preset.label"
            class="MCalendarPresets__preset-button"
            :class="{
                'MCalendarPresets__preset-button--active': isPresetEnabled(preset),
            }"
            @click="activatePreset(preset)"
        >
            {{ preset.label }}
        </button>
    </div>
</template>

<style scoped>
    .MCalendarPresets {
        @apply tw-flex tw-h-[280px] tw-min-w-24 tw-flex-col tw-gap-1 tw-overflow-y-auto;
    }

    .MCalendarPresets-time-mode {
        height: 140px !important;
    }

    .MCalendarPresets__preset-button {
        @apply tw-cursor-pointer tw-select-none tw-rounded tw-p-2 tw-py-1.5 tw-font-display tw-text-sm tw-text-gray-500;
        @apply tw-text-left tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600;
    }

    .MCalendarPresets__preset-button--active {
        @apply tw-bg-primary-100 tw-text-primary-600;
    }
</style>
