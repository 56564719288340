<script setup lang="ts">
    import {onMounted, watch} from 'vue';

    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
    import PaymentSuggestionsListItem from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsListItem.vue';
    import {usePaymentSuggestionsState} from '@/modules/cashier/payment/infrastructure/components/usePaymentSuggestionsState';
    import useSaveAllocations from '@/modules/cashier/payment/infrastructure/components/useSaveAllocations';

    const props = defineProps<{payment: PaymentModel; suggestionType: 'transaction' | 'credit_note'}>();

    const emit = defineEmits<{
        (e: 'saved'): void;
    }>();

    const {isFetching, fetch, allocations} = usePaymentSuggestionsState(props.payment);

    const {save, isSaving} = useSaveAllocations();

    onMounted(() => {
        fetch(props.suggestionType);
    });

    watch(
        () => props.payment,
        () => {
            fetch(props.suggestionType);
        }
    );

    watch(
        () => props.suggestionType,
        () => {
            fetch(props.suggestionType);
        }
    );

    async function onAddedAllocation(allocationAggregate: AbstractAllocationAggregate) {
        if (await save(allocationAggregate)) {
            emit('saved');
        }
    }
</script>

<template>
    <div class="PaymentSuggestionsList">
        <loader v-if="isFetching" />
        <MHeading
            v-else-if="allocations.length === 0"
            class="PaymentSuggestionsList__empty"
        >
            <template v-if="suggestionType === 'transaction' && payment.isRefund">
                {{ __('billing_transaction:no_refundable_suggestions_available_transaction_debit') }}
            </template>
            <template v-else-if="suggestionType === 'transaction' && !payment.isRefund">
                {{ __('billing_transaction:no_refundable_suggestions_available_transaction_credit') }}
            </template>
            <template v-else>
                {{ __('billing_transaction:no_refundable_suggestions_available_credit_note') }}
            </template>
        </MHeading>
        <div
            v-else
            class="PaymentSuggestionsList__container"
        >
            <template
                v-for="allocation in allocations"
                :key="allocation.allocation?.getKey()"
            >
                <PaymentSuggestionsListItem
                    :allocation="allocation"
                    class="PaymentSuggestionsList__item"
                    :saving="isSaving"
                    @add-allocation="onAddedAllocation"
                />
            </template>
            <div class="PaymentSuggestionsList__info">
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fa-duotone fa-info-square"
                />
                {{ __('billing_transaction:can_change_allocation_after_selection') }}
            </div>
        </div>
    </div>
</template>
<style scoped>
    .PaymentSuggestionsList__info {
        @apply tw-text-gray-500;
    }

    .PaymentSuggestionsList__container {
        @apply tw-flex tw-flex-col tw-gap-2;
    }

    .PaymentSuggestionsList__empty {
        @apply tw-text-center;
    }

    .PaymentSuggestionsList__item {
        @apply tw-border-b tw-border-gray-200 tw-bg-white tw-py-4;
    }

    .PaymentSuggestionsList__item:nth-last-child(1 of .PaymentSuggestionsList__item) {
        @apply tw-border-b-0;
    }
</style>
