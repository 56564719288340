<script setup lang="ts">
    import {computed, watch} from 'vue';

    import {EventBus} from '@/eventBus';
    import useAbility from '@/modules/app/composables/useAbility';
    import __ from '@/modules/app/utils/i18n-facade';
    import {useMemberRoleTranslations} from '@/modules/family/composables/member/useMemberRoleTranslations';
    import type {LegacyKidContractType} from '@/modules/family/utils/contract/LegacyKidContractType';
    import type {LegacyKidType} from '@/modules/family/utils/kid/LegacyKidType';
    import type {LegacyMemberType} from '@/modules/family/utils/member/LegacyMemberType';
    import type {LegacyOrganizationType} from '@/modules/organization/utils/LegacyOrganizationType';

    const props = withDefaults(
        defineProps<{
            contract: LegacyKidContractType;
            kid: LegacyKidType;
            disabled?: boolean;
            organization: LegacyOrganizationType;
        }>(),
        {
            disabled: false,
        }
    );

    const {can} = useAbility();
    const {getTranslationByRoleAndGender} = useMemberRoleTranslations();

    watch(
        () => [props.contract.first_family_member_id, props.contract.second_family_member_id],
        () => {
            EventBus.$emit('contract:parent:change');
        }
    );

    watch(
        () => props.contract,
        () => {
            if (props.organization.docTemplates.length && !props.contract.contract_template_id) {
                props.contract.contract_template_id = props.organization.docTemplates[0].id;
            }
        },
        {deep: true}
    );

    const familyMemberOptions = computed(() => {
        return [
            {value: null, text: __('common:none')},
            ...props.kid.familyMembers.map((familyMember: LegacyMemberType) => ({
                value: familyMember.id,
                text: `${familyMember.first_name} ${familyMember.last_name} (${getTranslationByRoleAndGender(familyMember.role, familyMember.gender)})`,
            })),
        ];
    });
</script>

<template>
    <MForm>
        <CFormTwoColumns>
            <MForm>
                <MFieldset>
                    <MLabel>{{ __('common:beginning') }}*</MLabel>
                    <MDatePicker
                        v-model="contract.started_at"
                        :disabled="disabled || !can('update', 'kids_contracts')"
                        format="yyyy-MM-dd"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('common:end') }}*</MLabel>
                    <MDatePicker
                        v-model="contract.ended_at"
                        :disabled="disabled || !can('update', 'kids_contracts')"
                        format="yyyy-MM-dd"
                    />
                </MFieldset>
                <MFieldset v-if="contract.broked_at">
                    <MLabel>
                        {{ __('common:broken') }}
                    </MLabel>
                    <MDatePicker
                        v-model="contract.broked_at"
                        format="yyyy-MM-dd"
                    />
                    <div class="tw-mt-1 tw-text-sm tw-text-gray-500">
                        <FontAwesomeIcon
                            class="tw-mr-0.5"
                            icon="fa-duotone fa-info-circle"
                        />
                        {{ __('family_contract:let_empty_to_cancel_the_break') }}
                    </div>
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        {{ __('common:contract_type') }}
                    </MLabel>
                    <MButton
                        v-if="disabled"
                        disabled
                        variant="primary"
                    >
                        <template v-if="contract.type === 'occasional'">
                            {{ __('family_contract:occasional_contract') }}
                        </template>
                        <template v-else>
                            {{ __('family_contract:regular_contract') }}
                        </template>
                    </MButton>
                    <MButtonSelect
                        v-else
                        v-model="contract.type"
                        :options="[
                            {text: __('common:recurrent'), value: 'recurrent'},
                            {text: __('common:occasional_other'), value: 'occasional'},
                        ]"
                    />
                </MFieldset>
                <MFieldset>
                    <CCheckbox
                        v-model="contract.company"
                        :disabled="!can('update', 'kids_contracts')"
                    >
                        {{ __('common:company_contract') }}
                        <MHelpTooltip class="tw-ml-2">
                            <div class="tw-mb-2">
                                {{ __('family_contract:company_contract_tooltip_title') }}
                            </div>
                            <div v-html="__('family_contract:company_contract_advantages_list')" />
                        </MHelpTooltip>
                    </CCheckbox>
                </MFieldset>
            </MForm>
            <MForm>
                <MFieldset>
                    <MLabel icon="fa-solid fa-user">
                        {{ __('common:first_parent') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="contract.first_family_member_id"
                        :disabled="!can('update', 'kids_contracts')"
                        :options="familyMemberOptions"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel icon="fa-solid fa-user">
                        {{ __('common:second_parent') }}
                    </MLabel>
                    <MSelectMenu
                        v-model="contract.second_family_member_id"
                        :disabled="!can('update', 'kids_contracts')"
                        :options="familyMemberOptions"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        {{ __('common:note_one') }}
                    </MLabel>
                    <MTextarea
                        v-model="contract.note"
                        :disabled="!can('update', 'kids_contracts')"
                        :placeholder="__('common:note_one')"
                        rows="2"
                    />
                </MFieldset>
            </MForm>
        </CFormTwoColumns>
    </MForm>
</template>
