<template>
    <div class="Activties">
        <MBox
            v-for="(category, index) in sortedActivitiesCategories"
            :key="index"
            class="tw-p-4"
        >
            <div class="Activties__header">
                <img
                    :alt="__('common:activity_one')"
                    :src="icons[category.icon]"
                    width="25px"
                />
                <MHeading level="h3">
                    {{ category.name }}
                </MHeading>
            </div>
            <CButtonGroup
                v-if="$can('update', 'activities')"
                class="tw-mb-2 tw-w-full"
            >
                <MInput
                    v-model="category.newActivity"
                    :placeholder="__('activity:add_activity')"
                    type="text"
                />
                <MButton
                    variant="primary"
                    @click="addActivity(category, category.newActivity)"
                >
                    <div class="tw-flex tw-items-center tw-justify-center tw-px-1">
                        <FontAwesomeIcon icon="fa-solid fa-plus-circle" />
                    </div>
                </MButton>
            </CButtonGroup>
            <CList>
                <CListRow
                    v-if="category.activities.length === 0"
                    :hover="false"
                >
                    {{ __('activity:empty_activity') }}
                </CListRow>
                <CListRow
                    v-for="(activity, i) in orderActivities(category.activities)"
                    v-else
                    :key="i"
                    class="Activties__list-item"
                    :hover="false"
                >
                    {{ activity.name }}
                    <MButton
                        v-if="$can('delete', 'activities')"
                        variant="link-danger"
                        @click="deleteActivity(category, activity)"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-times" />
                    </MButton>
                </CListRow>
            </CList>
        </MBox>
    </div>
</template>

<script setup lang="ts">
    import {cloneDeep} from 'lodash-es';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _sortBy from 'lodash-es/sortBy';
    import {computed} from 'vue';

    import ActingImage from '@/assets/images/activities/acting.svg';
    import BabyImage from '@/assets/images/activities/baby.svg';
    import BuildImage from '@/assets/images/activities/build.svg';
    import GardenImage from '@/assets/images/activities/garden.svg';
    import HandcraftImage from '@/assets/images/activities/handcraft.svg';
    import ManipulationImage from '@/assets/images/activities/manipulation.svg';
    import MotricityImage from '@/assets/images/activities/motricity.svg';
    import MusicImage from '@/assets/images/activities/music.svg';
    import OtherImage from '@/assets/images/activities/other.svg';
    import OutsideImage from '@/assets/images/activities/outside.svg';
    import SpeechImage from '@/assets/images/activities/speech.svg';
    import TasteImage from '@/assets/images/activities/taste.svg';
    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    const props = defineProps({
        nursery: {
            type: Object,
            required: true,
        },
    });

    const icons = {
        taste: TasteImage,
        baby: BabyImage,
        music: MusicImage,
        build: BuildImage,
        handcraft: HandcraftImage,
        manipulation: ManipulationImage,
        motricity: MotricityImage,
        acting: ActingImage,
        speech: SpeechImage,
        garden: GardenImage,
        outside: OutsideImage,
        other: OtherImage,
    };

    const sortedActivitiesCategories = computed(() => {
        const activitiesWithoutOther = props.nursery.custom_activities
            .filter(activityCategory => activityCategory.icon !== 'other')
            .sort((a, b) => a.name.localeCompare(b.name));

        return [
            ...activitiesWithoutOther,
            ...props.nursery.custom_activities.filter(activity => activity.icon === 'other'),
        ];
    });

    function addActivity(category, activity) {
        const customActivities = cloneDeep(props.nursery.custom_activities);

        customActivities.forEach(customActivity => {
            if (customActivity.name === category.name) {
                customActivity.activities.push({name: activity});
            }
        });

        useApi()
            .legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), {custom_activities: customActivities})
            .then(response => {
                category.newActivity = null;
                props.nursery.custom_activities = response.data.custom_activities;
                useNotification().success(__('activity:activity_created_successfully'));
            })
            .catch(error => {
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, function (value) {
                        useNotification().error(_head(value) as string);
                    });
                } else {
                    useNotification().error(error);
                }
            });
    }

    function deleteActivity(category, activity) {
        useMagicModal().deleteConfirmationModal({
            text: __('activity:delete_activity_ask'),
            onConfirm: async () => {
                const index = category.activities.indexOf(activity);
                category.activities.splice(index, 1);
                const data = {
                    custom_activities: props.nursery.custom_activities,
                };

                await useApi()
                    .legacy.put(route('nurseries.update', {nurseries: props.nursery.id}), data)
                    .then(() => {
                        useNotification().success(__('activity:activity_deleted_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value) as string);
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
        });
    }

    function orderActivities(activities) {
        return _sortBy(activities, 'name');
    }
</script>

<style scoped>
    .Activties {
        @apply tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4;
    }

    .Activties__header {
        @apply tw-mb-2 tw-flex tw-flex-row tw-items-center tw-gap-2;
    }

    .Activties__list-item {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between;
    }
</style>
