<template>
    <CModal
        ref="modal"
        :header-title="__('common:tax_certificate_other')"
        size="5xl"
        visible
        @hidden="onHidden"
    >
        <TaxCertificatesEmptyList v-if="!hasDocTemplate" />
        <div
            v-else
            class="TaxCertificates__container"
        >
            <TaxCertificatesIntroductionMessage />
            <TaxCertificatesFilters
                :customer-person-options="customerPersonSelectorOptions"
                :filters="filters"
                :show-tenants-filter="showTenantsFilter"
            >
                <CFormErrorMessageList
                    v-if="periodErrors.isNotEmpty()"
                    class="tw-basis-full tw-pt-4"
                    :errors="periodErrors"
                />
            </TaxCertificatesFilters>
            <TaxCertificatesToggleSelector
                @select-all="toggleAll(true)"
                @unselect-all="toggleAll(false)"
            />
            <TaxCertificatesList
                :customers="customers"
                :loading="isFiltersLoading || isListLoading"
                :toggle-kid-fn="toggleKid"
            />
            <TaxCertificatesSendToSelector
                :email="email"
                :is-sent-to-single-email="isSentToSingleEmail"
                @send-to-each-parents="isSentToSingleEmail = false"
                @send-to-single-email="isSentToSingleEmail = true"
                @update:email="email = $event"
            />
        </div>
        <template #footer>
            <MButton
                class="TaxCertificates__cancel-button"
                variant="light"
                @click="$emit('hide')"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                class="TaxCertificates__send-button"
                :disabled="selectedCustomers.count() === 0 && !filters.docTemplate"
                :loading="isSending"
                variant="primary"
                @click="sendCertificates(() => $emit('hide'))"
            >
                {{ __('common:actions.send') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeMount, reactive, ref} from 'vue';

    import useAuth from '@/modules/app/composables/useAuth';
    import useManager from '@/modules/app/composables/useManager';
    import TaxCertificatesEmptyList from '@/modules/cashier/components/tax/tax-certificate/molecules/TaxCertificatesEmptyList.vue';
    import TaxCertificatesIntroductionMessage from '@/modules/cashier/components/tax/tax-certificate/molecules/TaxCertificatesIntroductionMessage.vue';
    import TaxCertificatesFilters from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesFilters.vue';
    import TaxCertificatesList from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesList.vue';
    import TaxCertificatesSendToSelector from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesSendToSelector.vue';
    import TaxCertificatesToggleSelector from '@/modules/cashier/components/tax/tax-certificate/organisms/TaxCertificatesToggleSelector.vue';
    import useCustomersFilters from '@/modules/cashier/composables/tax/tax-certificate/useCustomersFilters';
    import useCustomersList from '@/modules/cashier/composables/tax/tax-certificate/useCustomersList';
    import useCustomersSelector from '@/modules/cashier/composables/tax/tax-certificate/useCustomersSelector';
    import useSendTaxCertificates from '@/modules/cashier/composables/tax/tax-certificate/useSendTaxCertificates';
    import DocTemplateTypeValues from '@/modules/organization/utils/Values/DocTemplateTypeValues';

    export default defineComponent({
        components: {
            TaxCertificatesList,
            TaxCertificatesSendToSelector,
            TaxCertificatesToggleSelector,
            TaxCertificatesFilters,
            TaxCertificatesIntroductionMessage,
            TaxCertificatesEmptyList,
        },
        emits: ['hide'],
        setup(props, {emit}) {
            const {legacyNursery, activeOrganization} = useManager();
            const {user} = useAuth();

            /**
             * Email to send tax certificates
             */
            const email = ref(user.value?.attributes.email || '');

            /**
             * Check if nursery has tax certificate template
             */
            const hasDocTemplate =
                legacyNursery.value?.docTemplates.filter(item => item.type === DocTemplateTypeValues.taxCertificate)
                    .length > 0;

            const showTenantsFilter = computed(() => user.value.organizations().value().count() > 1);

            const {
                customerPersonSelectorOptions,
                filters,
                waitForFiltersToBeLoaded,
                isLoading: isFiltersLoading,
                initFiltersValues,
                initWatchers: initFiltersWatchers,
            } = useCustomersFilters();

            const {
                customers,
                isLoading: isListLoading,
                initWatchers: initListWatchers,
                debounceGetCustomers,
            } = useCustomersList(filters);

            const {selectedCustomers, toggleAll, toggleKid} = useCustomersSelector(customers);

            const {isSentToSingleEmail, periodErrors, sendCertificates, isSending} = useSendTaxCertificates(customers, {
                ...filters,
                selectedCustomers,
                organization: activeOrganization,
                email,
            });

            onBeforeMount(async () => {
                // Await initial filters loading
                await waitForFiltersToBeLoaded();
                // Await initial orgnaization and doc template filters loading
                await initFiltersValues();
                // Await second filters loading to not trigger twice reload of customers list
                await waitForFiltersToBeLoaded();
                initFiltersWatchers();
                await debounceGetCustomers();
                initListWatchers();
            });

            function onHidden() {
                emit('hide');
            }

            // In Vue's template, refs contained in the "filters" object are not automatically unwrapped.
            // To solve this issue, we use "reactive" to unwrap the refs in the template.
            const reactiveFilters = reactive({...filters});

            return {
                isListLoading,
                isSending,
                isFiltersLoading,

                isSentToSingleEmail,
                email,
                customers,
                selectedCustomers,
                periodErrors,

                hasDocTemplate,
                customerPersonSelectorOptions,
                filters: reactiveFilters,
                showTenantsFilter,

                onHidden,
                sendCertificates,
                toggleKid,
                toggleAll,
            };
        },
    });
</script>

<style scoped>
    .TaxCertificates__container {
        @apply tw-flex tw-flex-col tw-gap-5;
    }

    .TaxCertificates__cancel-button {
        @apply tw-ml-auto;
    }

    .TaxCertificates__send-button {
        @apply tw-ml-2;
    }
</style>
