<script setup lang="ts">
    import {reactive, ref} from 'vue';
    import {useRoute} from 'vue-router';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useUrl from '@/modules/app/composables/useUrl';
    import ActivitiesManager from '@/modules/organization/components/Activities.vue';
    import DevicesManager from '@/modules/organization/components/Devices.vue';
    import DocTemplatesManager from '@/modules/organization/components/DocTemplatesManager.vue';
    import MeekoFamilyManager from '@/modules/organization/components/MeekoFamilyManager.vue';
    import OpeningManager from '@/modules/organization/components/OpeningManager.vue';
    import Summary from '@/modules/organization/components/Summary.vue';
    import TagsManager from '@/modules/organization/components/TagsManager.vue';
    import UsersManager from '@/modules/organization/components/UsersManager.vue';

    defineProps({
        nursery: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        docTemplates: {
            type: Array,
            default: () => reactive([]),
        },
    });

    const route = useRoute();
    const selectedTab = ref(route.query.nav ?? 'summary');
    const {pushQueryString} = useUrl();
    const {can} = useAbility();

    function selectTab(tab: string) {
        selectedTab.value = tab;
        pushQueryString('nav', tab);
    }
</script>

<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="settings">
                {{ __('app:menu.settings') }}
            </MPageTitle>
        </template>
        <template #actions>
            <RouterLink :to="{name: 'nurseries'}">
                <MButton
                    :label="__('common:my_organizations')"
                    variant="primary"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-home" />
                    </template>
                </MButton>
            </RouterLink>
        </template>
        <template #content>
            <CTabMenu
                class="tw-mb-4"
                variant="settings"
            >
                <CTabMenuItem
                    :active="selectedTab === 'summary'"
                    variant="settings"
                    @click="selectTab('summary')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-cog"
                    />
                    {{ __('common:organization_one') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'nursery')"
                    :active="selectedTab === 'opening'"
                    variant="settings"
                    @click="selectTab('opening')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-alarm-clock"
                    />
                    {{ __('common:openings') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'devices')"
                    :active="selectedTab === 'devices'"
                    variant="settings"
                    @click="selectTab('devices')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-tablet-alt"
                    />
                    {{ __('common:meeko_team') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('settings', 'posts')"
                    :active="selectedTab === 'meeko_family'"
                    variant="settings"
                    @click="selectTab('meeko_family')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fas fa-mobile-alt"
                    />
                    {{ __('common:meeko_family') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'doc_templates')"
                    :active="selectedTab === 'documents'"
                    variant="settings"
                    @click="selectTab('documents')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-file-invoice"
                    />
                    {{ __('organization:document_template') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'tags')"
                    :active="selectedTab === 'tags'"
                    variant="settings"
                    @click="selectTab('tags')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-tags"
                    />
                    {{ __('common:tags') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'activities')"
                    :active="selectedTab === 'activities'"
                    variant="settings"
                    @click="selectTab('activities')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-puzzle-piece"
                    />
                    {{ __('common:activity_other') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="can('read', 'users')"
                    :active="selectedTab === 'users'"
                    variant="settings"
                    @click="selectTab('users')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-user-cog"
                    />
                    {{ __('common:user_other') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="user.is_owner"
                    :active="selectedTab === 'subscriptions'"
                    :to="{name: 'subscriptions.plan'}"
                    variant="settings"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-shopping-cart"
                    />
                    {{ __('common:subscription') }}
                </CTabMenuItem>
            </CTabMenu>
            <MeekoFamilyManager v-if="selectedTab === 'meeko_family'" />
            <UsersManager
                v-else-if="selectedTab === 'users'"
                :nursery="nursery"
                :user="user"
            />
            <MBox
                v-else
                class="tw-p-4"
            >
                <Summary
                    v-if="selectedTab === 'summary'"
                    :nursery="nursery"
                    :user="user"
                />
                <DevicesManager
                    v-if="selectedTab === 'devices'"
                    :nursery="nursery"
                />
                <OpeningManager
                    v-if="selectedTab === 'opening'"
                    :nursery="nursery"
                />
                <DocTemplatesManager
                    v-if="selectedTab === 'documents'"
                    :doc-templates="docTemplates"
                    :nursery="nursery"
                />
                <TagsManager
                    v-if="selectedTab === 'tags'"
                    :nurseries="user.nurseries"
                    :nursery="nursery"
                />
                <ActivitiesManager
                    v-if="selectedTab === 'activities'"
                    :nursery="nursery"
                />
            </MBox>
        </template>
    </SimplePageTemplate>
</template>
