import {ServiceProvider} from '@meekohq/lumos';

import TagModel from '@/modules/tag/models/TagModel';
import TagPivot from '@/modules/tag/models/TagPivot';
import TagsOrganizationsPivot from '@/modules/tag/models/TagsOrganizationsPivot';

export default class TagServiceProvider extends ServiceProvider {
    public boot() {
        this.loadModelsFrom([TagModel, TagsOrganizationsPivot, TagPivot]);
    }
}
