<template>
    <div>
        <h2
            class="h2 tw-hidden tw-text-center tw-font-grandhotel tw-capitalize print:!tw-block"
            :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
        >
            {{ $fullName(kid) }}
        </h2>
        <div class="row print:tw-hidden">
            <div class="col-12 col-lg-6 tw-mb-6 lg:tw-mb-0">
                <LegacyKidAvatar
                    class="tw-float-left tw-mr-2 tw-w-16"
                    :kid="kid"
                />
                <div>
                    <h2
                        class="h2 tw-mr-4 tw-inline-block tw-font-grandhotel tw-capitalize"
                        :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                    >
                        {{ $fullName(kid) }}
                    </h2>
                    <MButton
                        v-if="$can('update', 'kids_details')"
                        :label="__('common:actions.update')"
                        variant="primary"
                        @click="editKidModal.show"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-pencil" />
                        </template>
                    </MButton>
                </div>
                <div>
                    <p class="tw-mb-0 tw-inline-block tw-text-gray-400">
                        <span v-if="kid.birthdate">
                            <template v-if="parse(kid.birthdate, 'yyyy-MM-dd').lessThanOrEqualTo(Epoch.now())">
                                {{ __('common:born_on', {context: kid.gender}) }}
                            </template>
                            <template v-else>
                                {{ __('common:scheduled_for_female') }}
                            </template>

                            {{ parse(kid.birthdate, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT) }}
                        </span>
                        <span v-else>
                            {{ __('family_kid:not_born_yet') }}
                        </span>
                        -
                        <span v-if="kid.group_id">{{ kid.group.name }}</span>
                        <span v-else>{{ __('common:no_group') }}</span>
                    </p>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <MTextarea
                    v-model="kid.note"
                    class="tw-resize-none"
                    :placeholder="__('common:note_one')"
                    readonly
                    rows="3"
                />
            </div>
        </div>

        <MInformationsLine class="tw-my-6 print:tw-hidden">
            <template #informations>
                <div class="tw-truncate md:tw-text-center">
                    <FontAwesomeIcon
                        fixed-width
                        class="tw-mr-1 tw-text-primary-500"
                        icon="fa-solid fa-tint"
                    />
                    <template v-if="kid.hygiene === 'baby'">
                        {{ __('common:hygiene_types.diaper') }}
                    </template>
                    <template v-else-if="kid.hygiene === 'mixed'">
                        {{ __('common:learning') }}
                    </template>
                    <template v-else>
                        {{ __('family_kid:potty') }}
                    </template>
                </div>

                <div class="tw-truncate md:tw-text-center">
                    <MTooltip
                        :label="__('family_kid:last_value_added')"
                        class="tw-cursor-pointer"
                    >
                        <div @click="$emit('weightClicked')">
                            <FontAwesomeIcon
                                fixed-width
                                class="tw-mr-1 tw-text-primary-500"
                                icon="fa-solid fa-weight"
                            />
                            {{ __('family_kid:weight_value', {weight: kid.kilogram}) }}
                        </div>
                    </MTooltip>
                </div>
            </template>
        </MInformationsLine>

        <div
            v-if="$can('read', 'kids_planning')"
            class="row"
        >
            <div class="col-12">
                <planning
                    :kid="kid"
                    :nursery="nursery"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import {defineComponent, toRef} from 'vue';

    import NoAvatarImage from '@/assets/images/no-avatar.png';
    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useModal from '@/modules/app/composables/useModal';
    import EditKidModal from '@/modules/family/components/kid/Kid/Summary/EditKidModal/organisms/EditKidModal.vue';
    import MInformationsLine from '@/modules/meeko-ui/components/MInformationsLine.vue';

    import Planning from './Summary/Planning/Show.vue';

    export default defineComponent({
        components: {
            MInformationsLine,
            LegacyKidAvatar,
            planning: Planning,
        },
        props: {
            kid: {
                type: Object,
                required: true,
            },
            nursery: {
                type: Object,
                required: true,
            },
        },
        emits: ['weightClicked'],
        setup(props) {
            const {parse} = useEpoch();

            const editKidModal = useModal({
                component: EditKidModal,
                props: {
                    kid: toRef(props, 'kid'),
                    nursery: toRef(props, 'nursery'),
                },
            });

            return {
                Epoch,
                parse,
                NoAvatarImage,
                editKidModal,
            };
        },
    });
</script>
