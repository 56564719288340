<template>
    <InvoicesList
        :customer="customer"
        :user="user"
    />
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import InvoicesList from '@/modules/subscription/components/Invoices/InvoicesList.vue';

    export default defineComponent({
        components: {
            InvoicesList,
        },
        props: {
            customer: {required: true},
            user: {required: true},
        },
        setup() {
            //
        },
    });
</script>
