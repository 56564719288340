import {Binding} from '@meekohq/lumos';

import type {ApiResponseOutputPort} from '@/modules/core/domain/ports/ApiResponseOutputPort';
import type {FamilyAppSettingsEntity} from '@/modules/meeko-family/domain/entities/FamilyAppSettingsEntity';

export const StoreFamilyAppSettingsInputPortBinding = new Binding<
    StoreFamilyAppSettingsInputPort,
    ApiResponseOutputPort
>();

export interface StoreFamilyAppSettingsInputPort {
    execute(settings: FamilyAppSettingsEntity): Promise<unknown>;
}
