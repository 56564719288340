import {Model} from '@meekohq/lumos';

import RequestTypeModel from '@/modules/request/models/RequestTypeModel';
import UserModel from '@/modules/user/models/UserModel';

export default class RequestTypeUserPivotModel extends Model {
    public type = 'request/types_users_pivot';

    attributes: {
        id: string;
        account_id: string | undefined;
        request_type_id: string | undefined;
        user_id: string | undefined;
        can_request: boolean | undefined;
        can_response: boolean | undefined;
        can_show: boolean | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        request_type_id: undefined,
        user_id: undefined,
        can_request: undefined,
        can_response: undefined,
        can_show: undefined,
    };

    requestType() {
        return this.belongsTo('requestType', RequestTypeModel, 'request_type_id');
    }

    user() {
        return this.belongsTo('user', UserModel, 'user_id');
    }
}
