import {Model, type QueryBuilder, QueryBuilderDateTime} from '@meekohq/lumos';
import type {Moment} from 'moment/moment';

import EventModel from '@/modules/human-resources/models/EventModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';
import RequestOrganizationPivotModel from '@/modules/request/models/RequestOrganizationPivotModel';
import RequestStageModel from '@/modules/request/models/RequestStageModel';
import RequestTypeModel from '@/modules/request/models/RequestTypeModel';
import type {Status} from '@/modules/request/utils/Status';

export default class RequestModel extends Model {
    public type = 'request/requests';

    attributes: {
        id: string;
        account_id: string | undefined;
        request_type_id: string | undefined;
        source_id: string | undefined;
        source_type: string | undefined;
        resource_id: string | undefined;
        resource_type: string | undefined;
        description: string | undefined;
        status: Status | undefined;
        created_at: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        request_type_id: undefined,
        source_id: undefined,
        source_type: undefined,
        resource_id: undefined,
        resource_type: undefined,
        description: undefined,
        status: undefined,
        created_at: undefined,
    };

    requestType() {
        return this.belongsTo('requestType', RequestTypeModel, 'request_type_id');
    }

    source() {
        return this.morphTo<StaffModel>('source');
    }

    requestStages() {
        return this.hasMany('requestStages', RequestStageModel, 'request_id');
    }

    events() {
        return this.hasMany('events', EventModel, 'request_id');
    }

    organizations() {
        return this.belongsToMany(
            'organizations',
            OrganizationModel,
            RequestOrganizationPivotModel,
            'request_id',
            'organization_id'
        );
    }

    scopeInRange(
        mainQuery: QueryBuilder<any>,
        range: [from: undefined | string | Moment, to: undefined | string | Moment]
    ) {
        const from = range[0];
        const to = range[1];

        if (from && to) {
            mainQuery.where(query => {
                query.where(query1 => {
                    query1.whereBetween('created_at', [new QueryBuilderDateTime(from), new QueryBuilderDateTime(to)]);
                });
            });
        }
    }
}
