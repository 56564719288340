<template>
    <CListRow>
        <CInline align-y="center">
            <template v-if="contactModel.fullname">
                {{ contactModel.fullname }}
                <div
                    v-if="contactModel.job"
                    class="tw-ml-2 tw-text-sm tw-text-gray-700"
                >
                    {{ contactModel.job }}
                </div>
                <div
                    v-else-if="contactModel.attributes.email || contactModel.attributes.phone"
                    class="tw-ml-2 tw-text-sm tw-text-gray-700"
                >
                    {{ trim(join([contactModel.attributes.email, contactModel.attributes.phone], ' / '), ' / ') }}
                </div>
            </template>
            <template v-else-if="contactModel.job">
                {{ contactModel.job }}
                <div
                    v-if="contactModel.attributes.email || contactModel.attributes.phone"
                    class="tw-ml-2 tw-text-sm tw-text-gray-700"
                >
                    {{ trim(join([contactModel.attributes.email, contactModel.attributes.phone], ' / '), ' / ') }}
                </div>
            </template>
            <template v-else>
                {{ trim(join([contactModel.attributes.email, contactModel.attributes.phone], ' / '), ' / ') }}
            </template>
            <div class="tw--my-1 tw-ml-auto">
                <ContactListItemAction
                    :contact-model="contactModel"
                    @deleted="onContactDeleted"
                    @updated="onContactUpdated"
                />
            </div>
        </CInline>
    </CListRow>
</template>

<script setup lang="ts">
    import {join, trim} from 'lodash-es';

    import ContactListItemAction from '@/modules/cashier/components/core/contact/molecules/ContactListItemAction.vue';
    import type ContactModel from '@/modules/cashier/models/ContactModel';

    const {contactModel} = defineProps<{
        contactModel: ContactModel;
    }>();

    const emit = defineEmits<{
        (event: 'deleted' | 'updated', contactModel: ContactModel): void;
    }>();

    function onContactDeleted(contactModel: ContactModel) {
        emit('deleted', contactModel);
    }

    function onContactUpdated(contactModel: ContactModel) {
        emit('updated', contactModel);
    }
</script>
