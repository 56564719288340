import type {Ref} from 'vue';

import Rule from '@/modules/calendar/utils/RuleSet/Rule';
import type RuleInterface from '@/modules/calendar/utils/RuleSet/RuleInterface';

export default function (rule: Ref<Rule | undefined>) {
    function update(changes: RuleInterface) {
        if (!rule.value) {
            return;
        }

        const ruleCopy = rule.value.copy();

        Object.keys(changes).forEach(change => {
            ruleCopy[change] = changes[change];
        });

        rule.value = ruleCopy;
    }

    function reset(changes: RuleInterface) {
        rule.value = Rule.fromJSON(changes);
    }

    return {
        update,
        reset,
    };
}
