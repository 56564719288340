enum MemberRoleValue {
    mother = 'mother',
    father = 'father',
    brother = 'brother',
    sister = 'sister',
    nanny = 'nanny',
    cousin = 'cousin',
    uncle = 'uncle',
    aunt = 'aunt',
    grandparent = 'grandparent',
    stepmother = 'stepmother',
    stepfather = 'stepfather',
    'legal_custodian_male' = 'legal_custodian_male',
    'legal_custodian_female' = 'legal_custodian_female',
    other = 'other',
}

export default MemberRoleValue;
