<script setup lang="ts">
    import type {PropType} from 'vue';
    import {computed} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import type {DocTemplatesDestination} from '@/modules/organization/utils/DocTemplatesDestinations';
    import DocTemplatesDestinations from '@/modules/organization/utils/DocTemplatesDestinations';

    const props = defineProps({
        selectedTemplate: {
            type: Object as PropType<{id: string | null}>,
            required: true,
        },
        nurseriesTemplates: {
            type: Array as PropType<{type: string}[]>,
            required: true,
        },
        otherNurseriesTemplates: {
            type: Array,
            required: true,
        },
    });

    const emit = defineEmits(['importTemplate', 'newTemplate', 'editTemplate']);
    const {can} = useAbility();
    const docTemplatesDestinations = DocTemplatesDestinations();

    const sortedTemplatesWithSubTitle = computed(() => {
        return props.nurseriesTemplates.map(template => {
            const docTemplateDestination = getDocTemplateDestinationByValue(template.type);

            return {
                ...template,
                subTitle: `<div><i class="${docTemplateDestination?.icon} tw-mr-1 tw-text-primary-500"></i>${docTemplateDestination?.name}</div>`,
            };
        });
    });

    function getDocTemplateDestinationByValue(docTemplateValue: string): DocTemplatesDestination | undefined {
        return docTemplatesDestinations.find(destination => destination.type === docTemplateValue);
    }

    function selectTemplate(id: string) {
        emit(
            'editTemplate',
            sortedTemplatesWithSubTitle.value.find(template => template.id === id)
        );
    }
</script>

<template>
    <div class="DocTemplatesManagerHeader">
        <MFieldset class="tw-min-w-0">
            <MLabel>{{ __('organization:template_under_edition') }}</MLabel>
            <MTooltip
                :hoverable="!sortedTemplatesWithSubTitle.length"
                :label="__('organization:no_document_template')"
                placement="right"
            >
                <MSelectMenu
                    class="tw-w-full md:tw-w-60"
                    :disabled="!sortedTemplatesWithSubTitle.length"
                    :options="sortedTemplatesWithSubTitle"
                    sub-text-path="subTitle"
                    text-path="name"
                    search-bar
                    funnel
                    :model-value="selectedTemplate.id"
                    value-path="id"
                    @update:model-value="selectTemplate($event)"
                />
            </MTooltip>
        </MFieldset>
        <div class="DocTemplatesManagerHeader__actions">
            <MTooltip
                v-if="otherNurseriesTemplates && otherNurseriesTemplates.length && can('create', 'doc_templates')"
                :label="__('common:import_from_another_organization')"
                placement="top-end"
            >
                <MButton @click="emit('importTemplate')">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-download" />
                    </template>
                    {{ __('common:actions.import') }}
                </MButton>
            </MTooltip>
            <MButton
                v-if="can('create', 'doc_templates')"
                icon-plus
                :label="__('organization:add_new_doc_template')"
                variant="primary"
                @click="emit('newTemplate')"
            />
        </div>
    </div>
</template>

<style scoped>
    .DocTemplatesManagerHeader {
        @apply tw-flex tw-flex-wrap tw-items-end tw-justify-between tw-gap-2;
    }

    .DocTemplatesManagerHeader__actions {
        @apply tw-flex tw-flex-wrap tw-items-center tw-gap-2;
    }
</style>
