import type {HasMany, QueryBuilder} from '@meekohq/lumos';
import {collect, Epoch} from '@meekohq/lumos';
import {ref} from 'vue';

import CallModel from '@/modules/activity/models/CallModel';
import CallDirectionValue from '@/modules/activity/utils/CallDirectionValue';
import CallOutcomeValue from '@/modules/activity/utils/CallOutcomeValue';

export default function (pivotConstraint: (query: QueryBuilder<any>, relation: HasMany<any, any>) => void) {
    const calls = ref(collect<CallModel>());

    const newCall = function () {
        const call = new CallModel();
        call.attributes.direction = CallDirectionValue.outcoming;
        call.attributes.outcome = CallOutcomeValue.connected;
        call.extra.draftDate = Epoch.now();

        calls.value.push(call);
    };

    const getCalls = async function () {
        calls.value = await CallModel.query(query => pivotConstraint(query, new CallModel().callPivots()))
            .with(new CallModel().callPivots())
            .with(new CallModel().staff())
            .get();
    };

    const removeCall = function (deletedCall: CallModel) {
        calls.value = calls.value.reject(call => call.id === deletedCall.id);
    };

    return {
        calls,
        getCalls,
        newCall,
        removeCall,
    };
}
