<template>
    <MTooltip class="ParticipantItem">
        <CBadge
            size="sm"
            :variant="badgeVariant"
            @click.stop
        >
            <FontAwesomeIcon
                class="tw-mr-1"
                :icon="icon"
            />
            {{ participantLabel }}
            <FontAwesomeIcon
                class="tw-ml-1"
                icon="fa-regular fa-times"
                @click.stop="removeParticipant(participant)"
            />
        </CBadge>
        <template #content>
            {{ participantEmail }}
        </template>
    </MTooltip>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import type MeetingParticipantModel from '@/modules/activity/models/MeetingParticipantModel';
    import __ from '@/modules/app/utils/i18n-facade';
    import StaffModel from '@/modules/human-resources/models/StaffModel';

    export default defineComponent({
        props: {
            participant: {
                type: Object as PropType<MeetingParticipantModel>,
                required: true,
            },
        },
        emits: ['remove'],
        setup(props, {emit}) {
            const icon = computed(() => getParticipantIcon(props.participant));
            const badgeVariant = computed(() => getParticipantColor(props.participant));

            const participantLabel = computed(() => {
                let label = props.participant.attributes.first_name;

                if (props.participant.attributes.last_name) {
                    label += label
                        ? ` ${props.participant.attributes.last_name}`
                        : props.participant.attributes.last_name;
                }

                if (!label) {
                    label = props.participant.attributes.email;
                }

                return label;
            });

            const participantEmail = computed(() =>
                props.participant.attributes.email ? props.participant.attributes.email : __('activity:no_email')
            );

            function getParticipantIcon(participant: MeetingParticipantModel) {
                switch (participant.attributes.resource_type) {
                    case new StaffModel().type:
                        return 'fa-regular fa-id-badge';
                    case 'registration/registrations':
                        return 'fa-regular fa-user-tie';
                    default:
                        return 'fa-regular fa-user';
                }
            }

            function getParticipantColor(participant: MeetingParticipantModel) {
                switch (participant.attributes.resource_type) {
                    case new StaffModel().type:
                        return 'pink';
                    case 'registration/registrations':
                        return 'purple';
                    default:
                        return 'primary';
                }
            }

            function removeParticipant(participant: MeetingParticipantModel) {
                emit('remove', participant);
            }

            return {
                badgeVariant,
                icon,
                participantEmail,
                participantLabel,
                removeParticipant,
            };
        },
    });
</script>
