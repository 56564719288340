import {Model} from '@meekohq/lumos';

export default class FamilyMemberPivot extends Model {
    public type = 'family/families_members_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        member_id: string | undefined;
        family_id: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        member_id: undefined,
        family_id: undefined,
    };
}
