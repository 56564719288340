<template>
    <CModal
        ref="modal"
        :header-title="__('registration:estimate_of_quote')"
        size="6xl"
        @shown="getBillingConfig()"
    >
        <CSkeleton
            v-if="configLoading"
            :count="1"
            layout="custom"
        >
            <CHStack gap="5">
                <div class="tw-h-7 tw-w-1/4 tw-rounded tw-bg-gray-200" />
                <CHStack
                    class="tw-w-3/4"
                    distribute="between"
                >
                    <div class="tw-h-7 tw-w-1/3 tw-rounded tw-bg-gray-200" />
                    <div class="tw-h-7 tw-w-1/3 tw-rounded tw-bg-gray-200" />
                </CHStack>
            </CHStack>
            <CHStack
                class="tw-mt-5"
                gap="5"
            >
                <div class="tw-h-80 tw-w-1/4 tw-rounded tw-bg-gray-200" />
                <div class="tw-h-80 tw-w-3/4 tw-rounded tw-bg-gray-200" />
            </CHStack>
        </CSkeleton>
        <estimate-invoices
            v-else
            :contract="contract"
            :contract-exists="false"
            :kid="kid"
            :nursery="nursery"
            :user="user"
        />
        <template #footer>
            <template v-if="!configLoading">
                <DownloadTemplate
                    class="tw-mr-auto"
                    doc-type="registration"
                    :item="contract"
                    :loading="loading"
                    :nursery="nursery"
                    :user="user"
                    wait-for-callback
                    @save-before-download-template="saveBeforeDownloadTemplate"
                />
                <MButton
                    class="tw-mr-2"
                    :label="__('common:actions.close')"
                    variant="light"
                    @click="$refs.modal.hide()"
                />
                <MButton
                    :label="__('common:actions.save')"
                    :loading="loading"
                    variant="primary"
                    @click="saveBillingConfig"
                />
            </template>
        </template>
    </CModal>
</template>

<script>
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import EstimateInvoices from '@/modules/cashier/components/billing/invoice/legacy/EstimateInvoices/Estimate.vue';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {
            EstimateInvoices,
            DownloadTemplate,
        },
        props: {
            registration: {},
            user: {},
            nursery: {},
            contract: {},
            kid: {},
        },
        emits: ['registrationUpdated'],
        data: () => ({
            loading: false,
            configLoading: true,
            shouldCreateNewConfig: false,
        }),
        methods: {
            async getBillingConfig() {
                if (this.registration) {
                    this.configLoading = true;

                    const nurseriesConfigsPivots = this.registration.nurseries
                        .map(item => item.billing_config_id)
                        .filter(item => item !== null);
                    const nurseryPivot = this.registration.nurseries.find(item => item.id === this.nursery.id);
                    const isConfigUsedInAnyNurseryPivot = nurseriesConfigsPivots.includes(
                        this.contract.billing_config_id
                    );

                    // La config est set dans la registraton mais n'est pas set dans le pivot (de la crèche sélectionnée) et dans aucune autre
                    const condition1 =
                        this.contract.billing_config_id &&
                        nurseryPivot.billing_config_id === null &&
                        isConfigUsedInAnyNurseryPivot;

                    // La config n'est ni set dans la registration ni dans le pivot de la crèche sélectionnée
                    const condition2 =
                        nurseryPivot.billing_config_id === null && this.contract.billing_config_id === null;

                    // On doit post la config si condition2 ou que le pivot n'a pas de config mais que la registration a une config utilisée par un autre pivot
                    const isConfigUsedInOtherNurseryPivot = this.registration.nurseries.find(
                        item =>
                            item.id !== this.nursery.id && item.billing_config_id === this.contract.billing_config_id
                    );
                    this.shouldCreateNewConfig =
                        condition2 || (isConfigUsedInOtherNurseryPivot && nurseryPivot.billing_config_id === null);

                    if (condition1 || condition2) {
                        // On set à null pour qu'il aille récupérer le default config
                        set(this.contract, 'config', null);
                    }
                    // Si le pivot a une config et qu'elle est différente de celle de la registration, on la récupère
                    else if (nurseryPivot.billing_config_id) {
                        const config = (
                            await useApi().legacy.get(
                                route('billingConfigs.show', {billingConfig: nurseryPivot.billing_config_id})
                            )
                        ).data;
                        if (config) {
                            set(this.contract, 'config', config);
                        }
                    }
                    // Sinon on ne fait rien car ça prendra la config de la registration tout seul

                    this.configLoading = false;
                } else {
                    this.configLoading = false;
                }
            },

            async saveBillingConfig(callback) {
                this.loading = true;

                if (!this.shouldCreateNewConfig) {
                    useApi()
                        .legacy.put(
                            route('billingConfigs.update', {
                                billingConfig: this.contract.config.id,
                            }),
                            this.contract.config
                        )
                        .then(response => {
                            this.contract.config = response.data;
                            if (typeof callback === 'function') {
                                this.saveRegistrationAndPivot(() => {
                                    callback();
                                });
                            } else {
                                this.saveRegistrationAndPivot();
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                } else {
                    const data = _cloneDeep(this.contract.config);
                    useApi()
                        .legacy.post(route('billingConfigs.store'), data)
                        .then(response => {
                            set(this.contract, 'config', response.data);
                            if (typeof callback === 'function') {
                                this.saveRegistrationAndPivot(() => {
                                    callback();
                                });
                            } else {
                                this.saveRegistrationAndPivot();
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error && error.response && error.response.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },

            async saveRegistrationAndPivot(callback) {
                this.loading = true;

                await this.saveConfigPivot();

                const data = {
                    kid_hygiene: this.kid.hygiene,
                    kid_meal_type: this.kid.meal_type,
                    contract_dependent_children: this.contract.dependent_children,
                    contract_handicapped_children: this.contract.handicapped_children,
                    contract_vacation_days: this.contract.vacation_days,
                };

                useApi()
                    .legacy.put(
                        route('registrations.update', {
                            registration: this.contract.id,
                        }),
                        data
                    )
                    .then(response => {
                        const contract = response.data;
                        contract.config = this.contract.config;
                        this.$emit('registrationUpdated', contract);
                        useNotification().success(__('registration:configuration_saved'));
                        this.loading = false;

                        if (typeof callback === 'function') {
                            callback();
                        } else {
                            this.$refs.modal.hide();
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            saveConfigPivot() {
                return useApi()
                    .legacy.put(
                        route('nurseries.registrations.update', {
                            nurseries: this.nursery.id,
                            registration: this.registration.id,
                        }),
                        {billing_config_id: this.contract.config.id}
                    )
                    .then(() => {
                        const nurseryPivot = this.registration.nurseries.find(item => item.id === this.nursery.id);
                        if (nurseryPivot) {
                            nurseryPivot.billing_config_id = this.contract.config.id;
                            this.shouldCreateNewConfig = false;
                        }
                    });
            },

            saveBeforeDownloadTemplate(callback) {
                this.loading = true;
                useNotification().info(__('family_contract:saving_contract_before_download_dots'));
                this.saveBillingConfig(() => {
                    this.loading = false;

                    return callback.done(true);
                });
            },
        },
    };
</script>

<style scoped>
    @media screen(sm) {
        .modal-lg {
            max-width: 80% !important;
        }
    }
</style>
