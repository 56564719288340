<template>
    <svg
        viewBox="0 0 48 48"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 9v28s1 3 11 3 11-3 11-3V9H20z"
            fill="#FFC107"
        />
        <ellipse
            cx="31"
            cy="7"
            fill="#FFC107"
            rx="11"
            ry="3"
        />
        <path
            d="M20 13s1 3 11 3 11-3 11-3v3s-1 3-11 3-11-3-11-3v-3zm0 9s1 3 11 3 11-3 11-3v-3s-1 3-11 3-11-3-11-3v3zm0 6s1 3 11 3 11-3 11-3v-3s-1 3-11 3-11-3-11-3v3zm0 6s1 3 11 3 11-3 11-3v-3s-1 3-11 3-11-3-11-3v3zm0-24s1 3 11 3 11-3 11-3V7s-1 3-11 3-11-3-11-3v3z"
            fill="#FF9800"
        />
        <path
            d="M28 31.9v8c.9.06 1.89.1 3 .1l1-.02V31.9c0-5.15-6.6-6.15-11.94-6.78l-.06 3.9c4.61.35 8 1.5 8 2.88z"
            fill="#F57C00"
        />
        <path
            d="m31 31 .93-.02a4.77 4.77 0 0 0-1.87-3c-8-.16-9.7-2.34-10-2.86l-.03 1.9c4.6.36 7.97 1.5 7.97 2.88v1c.9.06 1.89.1 3 .1zm-3 2.9v3c.9.06 1.89.1 3 .1l1-.02v-3L31 34c-1.11 0-2.1-.04-3-.1z"
            fill="#D66805"
        />
        <path
            d="M6 29h22v9H6z"
            fill="#CFD8DC"
        />
        <path
            d="M6 36s1 3 11 3 11-3 11-3v4s-1 3-11 3-11-3-11-3v-4z"
            fill="#CFD8DC"
        />
        <ellipse
            cx="17"
            cy="28"
            fill="#CFD8DC"
            rx="11"
            ry="3"
        />
        <path
            d="M6 28s1 3 11 3 11-3 11-3v3s-1 3-11 3-11-3-11-3v-3zm0 6s1 3 11 3 11-3 11-3v3s-1 3-11 3-11-3-11-3v-3z"
            fill="#90A4AE"
        />
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
