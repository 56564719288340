<template>
    <MForm @submit.prevent>
        <MFieldset>
            <MLabel>{{ __('hr_balance:balance_name') }}*</MLabel>
            <CInput
                v-model="model.attributes.name"
                :has-error="errorForm.has('name')"
                :placeholder="__('hr_balance:balance_name')"
            />
            <CFormErrorMessageList
                v-if="errorForm.has('name')"
                :errors="errorForm.get('name')"
            />
        </MFieldset>
        <MFieldset class="tw-my-3">
            <MLabel>{{ __('hr_balance:select_balance_unit') }}*</MLabel>
            <MSelectMenu
                v-model="model.attributes.unit"
                :fallback-text="__('hr_balance:select_balance_unit')"
                :options="balanceUnitOptions"
                :unselect-value="true"
            />
        </MFieldset>
        <MFieldset class="tw-my-3">
            <CCheckbox v-model="model.attributes.show_balance">
                {{ __('hr_balance:display_counter_balance') }}
            </CCheckbox>
        </MFieldset>
        <slot
            name="importItemAlert"
            :value="model.attributes.name"
        />
        <CDisclosure :title="__('hr_balance:add_balance_to_events_types')">
            <CVStack gap="2">
                <CList v-if="eventTypesRelation.isLoaded() && eventTypesRelation.value().isNotEmpty()">
                    <BalanceTypeFormEventListItem
                        v-for="eventType in eventTypesRelation
                            .value()
                            .filter(event => !event.pivot().markedForDeletion)"
                        :key="eventType.pivot().id"
                        :event-type="eventType"
                        @delete="removeEventType(eventType)"
                    />
                </CList>
                <SelectEventType
                    :inject-query="excludeAlreadyLinkedEventTypeBuilder"
                    @update:model-value="addEventType"
                >
                    <template #trigger="{toggle}">
                        <CVStack
                            align="center"
                            class="dashed-button tw-group tw-bg-white"
                            distribute="center"
                            @click="toggle"
                        >
                            <CText
                                class="tw-text-gray-700 group-hover:tw-text-black"
                                font-weight="semibold"
                            >
                                <FontAwesomeIcon
                                    class="tw-mr-1 tw-text-blue-500"
                                    icon="fa-duotone fa-plus-circle"
                                />
                                {{ __('hr:add_event_type') }}
                            </CText>
                        </CVStack>
                    </template>
                </SelectEventType>
            </CVStack>
        </CDisclosure>
    </MForm>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import {catcher, collect, ValidationError} from '@meekohq/lumos';
    import {debounce, isUndefined} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import type useError from '@/modules/app/composables/useError';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import BalanceTypeFormEventListItem from '@/modules/human-resources/components/balance-type/BalanceTypeFormEventListItem.vue';
    import SelectEventType from '@/modules/human-resources/components/event-type/SelectEventType.vue';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import BalancePeriodModel from '@/modules/human-resources/models/BalancePeriodModel';
    import BalanceTypeEventTypePivot from '@/modules/human-resources/models/BalanceTypeEventTypePivot';
    import BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import BalanceTypeOrganizationPivot from '@/modules/human-resources/models/BalanceTypeOrganizationPivot';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import {balanceUnitValue} from '@/modules/human-resources/utils/balance/BalanceUnitValue';

    export function eventTypesRelationConstraint(query: QueryBuilder<any>) {
        query.whereHas('organizations', q1 => q1.scope('active'));
    }

    export default defineComponent({
        components: {
            BalanceTypeFormEventListItem,
            SelectEventType,
        },
        props: {
            model: {type: Object as PropType<BalanceTypeModel>, required: true},
            errorForm: {type: Object as PropType<ReturnType<typeof useError>>, required: true},
        },
        emits: ['loading', 'saved', 'checkExisting'],
        setup(props, {emit}) {
            const balancePeriodModel = ref(new BalancePeriodModel());
            const {activeOrganization, legacyAccount} = useManager();

            const balanceUnitOptions = Object.keys(balanceUnitValue).map(key => {
                if (key === 'day') {
                    return {value: key, text: __('common:day_other')};
                } else {
                    return {value: key, text: __('common:hour_other')};
                }
            });

            // Use computed to react to props.model change
            // Export relation to use const instead of fully qualified relation
            const balancePeriodsRelation = computed(() => props.model.balancePeriods());

            // Use computed to react to props.model change
            // Export relation with more scoped query
            const eventTypesRelation = computed(() => props.model.eventTypes().setQuery(eventTypesRelationConstraint));

            const organizationsPivotRelation = computed(() => props.model.organizationsPivots());

            watch(
                () => props.model,
                () => {
                    // If props.model is updated, resolve relations
                    balancePeriodsRelation.value.load();
                    eventTypesRelation.value.load();
                },
                {immediate: true}
            );

            const excludeAlreadyLinkedEventTypeBuilder = computed(() => {
                const ids = eventTypesRelation.value
                    .value()
                    .filter(item => {
                        return !item.pivot().markedForDeletion;
                    })
                    .keyBy(m => m.getKey())
                    .keys()
                    .all();

                return EventTypeModel.query().whereNotIn('id', ids);
            });

            const save = async function () {
                try {
                    emit('loading', true);
                    await props.model.save();

                    // Use Promise.all() to save models simulataneously
                    const promises: Promise<any>[] = [];

                    if (
                        organizationsPivotRelation.value
                            .value()
                            .filter(item => item.attributes.organization_id === activeOrganization.value.id)
                            .isEmpty()
                    ) {
                        // BalanceType is not attached to organization
                        const pivot = new BalanceTypeOrganizationPivot();
                        pivot.attributes.account_id = props.model.attributes.account_id;
                        pivot.balanceType().associate(props.model, false);
                        pivot.organization().associate(activeOrganization.value, false);
                        promises.push(pivot.save());
                        organizationsPivotRelation.value.value().push(pivot);
                    }

                    // Save all EventType relations
                    for (const model of eventTypesRelation.value.value()) {
                        promises.push(model.pivot<BalanceTypeEventTypePivot>().save());
                    }

                    // Save all BalancePeriod relations
                    for (const model of balancePeriodsRelation.value.value()) {
                        promises.push(model.save());
                    }

                    // Wait for all models save
                    await Promise.all(promises);

                    emit('saved', props.model);
                } catch (e) {
                    catcher()
                        .on(ValidationError, value => {
                            props.errorForm.reset();
                            props.errorForm.addValidationError(value);
                        })
                        .catch(e);
                } finally {
                    emit('loading', false);
                }
            };

            const getIconStyle = function (color) {
                return color ? useEventStyle(ref(color), ref(false), 'md', true).badgeStyle.value : null;
            };

            const addEventType = function (model: EventTypeModel) {
                // Search for existing model in collection, in case of remove followed by re-add
                const existingModel = eventTypesRelation.value.value().first(m => m.id === model.id);
                if (existingModel) {
                    // EventType is already in collection, reset state to make it reappear
                    existingModel.pivot<BalanceTypeEventTypePivot>().markedForDeletion = false;
                    existingModel.pivot<BalanceTypeEventTypePivot>().listeners.delete = [];
                } else {
                    const pivot = new BalanceTypeEventTypePivot();
                    pivot.attributes.account_id = props.model.attributes.account_id;
                    pivot.balanceType().associate(props.model, false);
                    pivot.eventType().associate(model, false);

                    // Push pivot to collection
                    eventTypesRelation.value.value().push(model);
                    model.setRelation('pivot', pivot);

                    // Mutate collection to prevent pivot duplication
                    eventTypesRelation.value.mutate(value => value.unique(item => item.id));
                }
            };

            const removeEventType = function (model: EventTypeModel) {
                const removeItemFromList = function (modelToRemove: EventTypeModel) {
                    eventTypesRelation.value.mutate(value => value.reject(item => item.id === modelToRemove.id));
                };

                if (model.pivot().exist) {
                    // Model exist in database
                    model.pivot().markForDeletion();
                    // On pivot delete, remove it from collection
                    model.pivot().on('delete', () => removeItemFromList(model));
                } else {
                    // Pivot does not exist in database, it can be removed from collection
                    removeItemFromList(model);
                }
            };

            const addBalancePeriod = function (model: BalancePeriodModel) {
                model.attributes.account_id = legacyAccount.value.id.toString();
                model.balanceType().associate(props.model, false);
                balancePeriodsRelation.value.value().push(model);

                // Reset BalancePeriodModel for next push
                balancePeriodModel.value = new BalancePeriodModel();
            };

            const removeBalancePeriod = function (model: BalancePeriodModel) {
                const removeItemFromList = function (modelToRemove: BalancePeriodModel) {
                    balancePeriodsRelation.value.mutate(value => value.reject(item => item.id === modelToRemove.id));
                };

                if (model.exist) {
                    // Model exist in database
                    model.markForDeletion();
                    // On model delete, remove it from collection
                    model.on('delete', () => removeItemFromList(model));
                } else {
                    // Model does not exist in database, it can be removed from collection
                    removeItemFromList(model);
                }
            };

            const matches = ref(collect<typeof props.model>());

            const fetchResults = async function (value: string) {
                const query = BalanceTypeModel.query()
                    .where('name', value)
                    .whereHas('organizations', q => q.scope('active'))
                    .with('organizations')
                    .with('organizationsPivots');

                if (props.model.exists) {
                    query.where('id', '<>', props.model.getKey());
                }

                matches.value = await query.get();
                if (matches.value.count() > 0) {
                    props.errorForm.add('name', '0000', __('hr_balance:balance_type_name_already_exists'));
                }
                emit('checkExisting', false);
            };

            const debounceQuery = debounce(fetchResults, 300);

            watch(
                () => props.model.attributes.name,
                async value => {
                    props.errorForm.reset();
                    if (!isUndefined(value)) {
                        emit('checkExisting', true);
                        await debounceQuery(value);
                    }
                }
            );

            return {
                balanceUnitOptions,
                balancePeriodModel,
                save,
                getIconStyle,
                removeEventType,
                addEventType,
                removeBalancePeriod,
                addBalancePeriod,
                eventTypesRelation,
                balancePeriodsRelation,
                excludeAlreadyLinkedEventTypeBuilder,
            };
        },
    });
</script>

<style scoped>
    .dashed-button {
        @apply tw-rounded-xl tw-border-2 tw-border-dashed tw-py-3 hover:tw-border-blue-500;
        @apply tw-cursor-pointer tw-border-gray-300 tw-transition-all tw-duration-300 hover:tw-border-blue-500 hover:tw-bg-blue-100;
    }
</style>
