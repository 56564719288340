<template>
    <CVStack>
        <!-- Default config select -->
        <Config
            v-if="!hasRules"
            v-model="draftRule"
            :configs="configs"
            @update:model-value="addNewRule"
        />
        <!-- List of existing rules -->
        <template v-if="hasExistingRules">
            <Rule
                v-for="(rule, i) in rulesetCopy.rules"
                :key="'rule-' + i"
                :class="{'tw-mt-4': i > 0}"
                :disabled="disabled"
                :rule="rule"
                @remove="removeRule"
                @validate="updateRuleByIndex(i, $event)"
            />
        </template>
        <!-- Pending new rule -->
        <Rule
            v-if="draftRule"
            ref="draftRuleCompo"
            :class="{'tw-mt-4': rulesetCopy.rules.length > 0}"
            :disabled="disabled"
            :rule="draftRule"
            @cancel="resetDraftRule"
            @remove="resetDraftRule"
            @validate="addNewRule"
        />
        <!-- Add new rule action if multi -->
        <div
            v-if="hasExistingRules && multi && !disabled"
            class="tw-mt-4"
        >
            <MButton
                :icon-plus="true"
                @click="initDraftRule({interval: 1, freq: FrequencyValue.day})"
            >
                {{ __('common:add_reminder') }}
            </MButton>
        </div>
    </CVStack>
</template>

<script lang="ts">
    import _cloneDeep from 'lodash-es/cloneDeep';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import type {ConfigsType} from '@/modules/calendar/components/Reminder/Config.vue';
    import Config from '@/modules/calendar/components/Reminder/Config.vue';
    import RuleCompo from '@/modules/calendar/components/Reminder/Rule.vue';
    import useDraftRule from '@/modules/calendar/composables/RuleSet/useDraftRule';
    import useRuleSetRef from '@/modules/calendar/composables/RuleSet/useRuleSetRef';
    import type Rule from '@/modules/calendar/utils/RuleSet/Rule';
    import RuleSet from '@/modules/calendar/utils/RuleSet/RuleSet';
    import FrequencyValue from '@/modules/calendar/utils/RuleSet/values/FrequencyValue';
    import OptionValue from '@/modules/calendar/utils/RuleSet/values/OptionValue';

    export default defineComponent({
        components: {Rule: RuleCompo, Config},
        props: {
            modelValue: {
                type: Object as PropType<RuleSet>,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            multi: {
                type: Boolean,
                default: false,
            },
            shouldSetDefaultRule: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const rulesetCopy = ref<RuleSet>(props.modelValue ? RuleSet.fromJSON(props.modelValue) : new RuleSet());

            const {addRule, updateRuleByIndex, removeRule} = useRuleSetRef(rulesetCopy);
            const {
                rule: draftRule,
                ruleCompo: draftRuleCompo,
                init: initDraftRule,
                reset: resetDraftRule,
            } = useDraftRule();

            onMounted(() => {
                if (props.shouldSetDefaultRule && !hasRules.value) {
                    initDraftRule(configs[0].value);
                    if (draftRule.value) {
                        addNewRule(draftRule.value);
                    }
                }
            });

            const hasRules = computed(() => {
                if (props.multi) {
                    return hasExistingRules.value;
                } else {
                    return draftRule.value || hasExistingRules.value;
                }
            });

            const hasExistingRules = computed(
                () => rulesetCopy.value.rules.length && rulesetCopy.value.rules.filter(rule => rule.interval).length
            );

            // Init selector of pre config rules
            const configs: ConfigsType = [
                {value: {interval: 1, freq: FrequencyValue.once}, text: __('common:at_end')},
                {
                    value: {interval: -5, freq: FrequencyValue.minute, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:minute_before', {count: 5}),
                },
                {
                    value: {interval: -10, freq: FrequencyValue.minute, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:minute_before', {count: 10}),
                },
                {
                    value: {interval: -15, freq: FrequencyValue.minute, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:minute_before', {count: 15}),
                },
                {
                    value: {interval: -30, freq: FrequencyValue.minute, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:minute_before', {count: 30}),
                },
                {
                    value: {interval: -1, freq: FrequencyValue.hour, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:hour_before', {count: 1}),
                },
                {
                    value: {interval: -2, freq: FrequencyValue.hour, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:hour_before', {count: 2}),
                },
                {
                    value: {interval: -1, freq: FrequencyValue.day, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:day_before', {count: 1}),
                },
                {
                    value: {interval: -2, freq: FrequencyValue.day, options: [OptionValue.excludeStartDate]},
                    text: __('calendar:day_before', {count: 2}),
                },
                {value: 'custom', text: __('common:customize')},
            ];

            function addNewRule(rule: Rule) {
                addRule(rule);
                resetDraftRule();
            }

            // Keep v-model updated
            watch(
                () => _cloneDeep(rulesetCopy.value),
                value => {
                    if (value.rules?.length > 0) {
                        emit('update:modelValue', value);
                    } else {
                        emit('update:modelValue', null);
                    }
                }
            );

            return {
                FrequencyValue,
                addNewRule,
                configs,
                hasRules,
                hasExistingRules,
                initDraftRule,
                draftRule,
                draftRuleCompo,
                removeRule,
                rulesetCopy,
                resetDraftRule,
                updateRuleByIndex,
            };
        },
    });
</script>
