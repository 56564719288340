import _includes from 'lodash-es/includes';
import _maxBy from 'lodash-es/maxBy';
import _minBy from 'lodash-es/minBy';
import moment from 'moment';

export const nursery = {
    methods: {
        $getOpening(hours, shouldRemoveHour, exact, events) {
            hours = hours.map(item => item.started_at);

            if (events && events.length) {
                const startPlannings = events.map(item => moment.unix(item.started_at).format('HH:mm:ss'));
                hours = hours.concat(startPlannings);
            }

            const min = _minBy(hours);
            const hour = min ? min : '07:00:00';

            const momentHour = moment(hour, 'HH:mm:ss');
            momentHour.hour() === 0 ? (exact = true) : false;

            if (!exact) {
                if (momentHour.minutes() >= 30) {
                    momentHour.minutes(0);
                } else {
                    momentHour.minutes(0);
                    shouldRemoveHour ? momentHour.subtract(1, 'hour') : null;
                }
            }

            return momentHour.format('HH:mm:ss');
        },
        $getClosing(hours, shouldAddHour, exact, events) {
            hours = hours.map(item => item.ended_at);

            if (events && events.length) {
                const endPlannings = events.map(item => moment.unix(item.ended_at).format('HH:mm:ss'));
                hours = hours.concat(endPlannings);
            }

            const max = _maxBy(hours);

            const hour = max ? max : '18:00:00';

            const momentHour = moment(hour, 'HH:mm:ss');
            momentHour.hour() >= 23 ? (exact = true) : false;

            if (!exact) {
                shouldAddHour ? momentHour.add(1, 'hour') : null;
            }

            return momentHour.format('HH:mm:ss');
        },
        $openingHoursPerWeek(hours) {
            let total = 0;
            hours.forEach(day => {
                total += moment(day.ended_at, 'HH:mm:ss').diff(moment(day.started_at, 'HH:mm:ss'), 'minutes');
            });

            return Math.round((total / 60) * 100) / 100;
        },

        $showCAF(country) {
            const countries = ['FR', 'RE', 'GP', 'MQ', 'GF', 'MF']; // DOM TOM

            return _includes(countries, country);
        },
    },
};
