import {app} from '@meekohq/lumos';
import type {Moment} from 'moment';
import {ref, watch} from 'vue';

import useManager from '@/modules/app/composables/useManager';
import type {LegacyNurseryInterface} from '@/modules/organization/utils/LegacyNurseryOpening';
import {OrganizationOpeningContractBinding} from '@/modules/organization/utils/OrganizationOpeningContract';

export default function (organization?: LegacyNurseryInterface) {
    const {legacyNursery} = useManager();
    const opening = ref(app(OrganizationOpeningContractBinding));

    // Instanciate Opening and refresh it if organization change
    watch(
        legacyNursery,
        () => {
            // If orga is not loaded we don't try to init Opening
            if (legacyNursery.value) {
                opening.value = app(OrganizationOpeningContractBinding);
                opening.value.forOrganization(organization ?? legacyNursery.value);
            }
        },
        {immediate: true}
    );

    return {
        isOpenedOn: (date: Moment) => opening.value.isOpenedOn(date),
        isClosedOn: (date: Moment) => opening.value.isClosedOn(date),
        getOpeningHoursOn: (date: Moment) => opening.value.getOpeningHoursOn(date),
    };
}
