export default {
    /*
    |--------------------------------------------------------------------------
    | Application Environment
    |--------------------------------------------------------------------------
    |
    | This value determines the "environment" your application is currently
    | running in. This may determine how you prefer to configure various
    | services the application utilizes.
    |
    */
    env: import.meta.env.VITE_APP_ENV,

    /*
    |--------------------------------------------------------------------------
    | API Base URL
    |--------------------------------------------------------------------------
    */
    api_base_url: window.localStorage.getItem('cto_api_base_url') ?? import.meta.env.VITE_API_URL,
    production_api_base_url: 'https://api.meeko.app',
    staging_api_base_url: 'https://staging-api.meeko.app',
    local_api_base_url: 'http://localhost:8080',

    /*
    |--------------------------------------------------------------------------
    | App Version
    |--------------------------------------------------------------------------
    */
    version: import.meta.env.VITE_APP_VERSION,

    /*
    |--------------------------------------------------------------------------
    | Application Locale Configuration
    |--------------------------------------------------------------------------
    |
    | The application locale determines the default locale that will be used
    | by the translation service provider. You are free to set this value
    | to any of the locales which will be supported by the application.
    |
    */

    locale: 'fr',

    /*
    |--------------------------------------------------------------------------
    | Application Fallback Locale
    |--------------------------------------------------------------------------
    |
    | The fallback locale determines the locale to use when the current one
    | is not available.
    |
    */

    fallback_locale: 'fr',
};
