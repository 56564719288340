<script lang="ts" setup>
    import {Epoch} from '@meekohq/lumos';
    import {computed, ref, toRef, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';
    import {
        invoiceStatuses,
        useInvoiceStatusState,
    } from '@/modules/cashier/utils/billing/invoice/useInvoiceStatusState';

    const props = withDefaults(
        defineProps<{
            invoice: InvoiceModel | LegacyInvoiceType;
            size?: string;
        }>(),
        {
            size: 'sm',
        }
    );

    const text = ref();
    const color = ref();

    const {invoiceStatus} = useInvoiceStatusState(toRef(props, 'invoice'));

    const invoiceType = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.computed.invoice_type;
        }

        return props.invoice.invoice_type;
    });

    const invoiceUncollectibleDate = computed(() => {
        let uncollectibleDate: string | null = null;

        if (props.invoice instanceof InvoiceModel) {
            uncollectibleDate = props.invoice.attributes.uncollectible_date ?? null;
        } else {
            uncollectibleDate = props.invoice.uncollectible_date;
        }

        return uncollectibleDate
            ? Epoch.parse(uncollectibleDate, 'yyyy-MM-dd').toLocaleString(Epoch.presets.DATE_SHORT)
            : null;
    });

    const invoiceUncollectibleDescription = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.uncollectible_description ?? null;
        } else {
            return props.invoice.uncollectible_description;
        }
    });

    watch(invoiceStatus, getInvoiceState, {immediate: true});

    function getInvoiceState() {
        switch (invoiceStatus.value) {
            case invoiceStatuses.paid:
                text.value = __('billing_invoice:status.paid');
                color.value = 'green';
                break;
            case invoiceStatuses.unpaid:
                text.value = __('billing_invoice:status.unpaid', {context: invoiceType.value});
                color.value = 'red';
                break;
            case invoiceStatuses.uncollectible:
                text.value = __('common:uncollectible');
                color.value = 'red';
                break;
            case invoiceStatuses.pending:
                text.value = __('common:pending');
                color.value = 'orange';
                break;
            case invoiceStatuses.incomplete:
                text.value = __('billing_invoice:status.incomplete');
                color.value = 'orange';
                break;
            case invoiceStatuses.editing:
                text.value = __('billing_invoice:status.editing');
                color.value = 'black';
                break;
            case invoiceStatuses.draft:
            default:
                text.value = __('billing_invoice:status.proforma');
                color.value = 'gray';
        }
    }

    watch(invoiceStatus, getInvoiceState, {immediate: true});
</script>

<template>
    <CBadge
        v-if="invoiceStatus !== invoiceStatuses.uncollectible"
        :size="size"
        :variant="color"
    >
        {{ text }}
    </CBadge>
    <MTooltip v-else>
        <CBadge
            :size="size"
            :variant="color"
        >
            {{ text }}
        </CBadge>
        <template #content>
            {{ __('date_format.since_date', {date: invoiceUncollectibleDate}) }}
            <br />
            {{ invoiceUncollectibleDescription }}
        </template>
    </MTooltip>
</template>
