<template>
    <div class="tw-block tw-w-full tw-overflow-x-auto">
        <table class="tw-mb-4 tw-w-full">
            <thead>
                <tr>
                    <th scope="col" />
                    <th
                        v-for="i in 7"
                        :key="i"
                        scope="col"
                    >
                        {{ i }} {{ i === 1 ? __('common:day_one') : __('common:day_other') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(bracket, index) in prices.brackets"
                    :key="'bracket ' + index"
                >
                    <th
                        v-if="prices.brackets.length > 1"
                        scope="row"
                    >
                        {{ __('billing_setting:t_value', {value: index + 1}) }}
                    </th>
                    <th
                        v-else
                        scope="row"
                    />
                    <th
                        v-for="(price, i) in bracket"
                        :key="'price ' + i"
                    >
                        <MCurrencyInput
                            v-model.number="price.value"
                            class="tw-mt-1 tw-font-normal"
                            :currency-symbol="nursery.currency"
                            :parser-options="parserOptionsCurrencyPerHour"
                            placeholder="9"
                        />
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import i18next from 'i18next';

    import {getNumericParserContractPresetCurrencyPerHour} from '@/modules/core/infrastructure/NumericParserContractPresets';

    export default {
        props: ['prices', 'brackets', 'nursery'],
        emits: ['updatePrice'],
        data() {
            return {
                unit: 'day',
                parserOptionsCurrencyPerHour: getNumericParserContractPresetCurrencyPerHour(i18next.language),
            };
        },
        computed: {},
        watch: {
            brackets() {
                this.reset();
            },
        },
        created() {
            this.parserOptionsCurrencyPerHour.allowNegative = true;
        },
        mounted() {
            if (this.prices.unit !== this.unit) {
                this.reset();
            }
        },
        methods: {
            reset() {
                const brackets = [];

                for (let i = 0; i < this.brackets; i++) {
                    brackets.push([{value: 9}, {value: 9}, {value: 9}, {value: 9}, {value: 9}, {value: 9}, {value: 9}]);
                }
                this.$emit('updatePrice', {
                    unit: this.unit,
                    brackets,
                });
            },
        },
    };
</script>

<style scoped>
    table th,
    table td {
        @apply tw-p-1;
        @apply tw-border-t tw-border-gray-200;
    }

    table thead th {
        @apply tw-border-b-2 tw-border-gray-200;
    }

    table tbody + tbody {
        @apply tw-border-t-2 tw-border-gray-200;
    }
</style>
