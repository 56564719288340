<script setup lang="ts"></script>

<template>
    <div class="MMenuDivider" />
</template>

<style scoped>
    .MMenuDivider {
        @apply tw-my-1.5 tw-h-px tw-bg-gray-100;
    }
</style>
