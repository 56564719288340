<script setup lang="ts">
    import {computed, inject} from 'vue';

    import useModal from '@/modules/app/composables/useModal';
    import type PaymentModel from '@/modules/cashier/models/PaymentModel';
    import InvoicePaymentSuggestionsModal from '@/modules/cashier/payment/infrastructure/components/InvoicePaymentSuggestionsModal.vue';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

    const props = defineProps<{
        invoice: LegacyInvoiceType;
        payment: PaymentModel;
        editingAllocations: boolean;
    }>();

    const emit = defineEmits(['created']);

    const isButtonAddAllocationDisabled = computed(() => {
        return Number(props.invoice.computed.remaining_amount) === 0 || props.editingAllocations;
    });

    const customer = inject('customer');

    function showInvoicePaymentSuggestionsModal() {
        useModal({
            component: InvoicePaymentSuggestionsModal,
            props: {
                invoice: props.invoice,
                payment: props.payment,
                customer,
            },
            listeners: () => ({
                created: () => {
                    emit('created', true);
                },
            }),
        }).show();
    }
</script>

<template>
    <MTooltip
        v-if="isButtonAddAllocationDisabled"
        class="tw-w-full"
    >
        <MButton
            class="tw-w-full"
            disabled
            :label="__('billing_transaction:actions.create_credit')"
            size="lg"
            variant="primary"
        >
            <template v-if="invoice.invoice_type === 'credit_note'">
                {{ __('billing_invoice:refund_the_credit_note') }}
            </template>
            <template v-else>
                {{ __('billing_invoice:settle_the_bill') }}
            </template>
        </MButton>
        <template #content>
            <template v-if="invoice.invoice_type === 'credit_note'">
                {{
                    editingAllocations
                        ? __('billing_invoice:cant_create_debit')
                        : __('billing_invoice:credit_note_paid')
                }}
            </template>
            <template v-else>
                {{ editingAllocations ? __('billing_invoice:cant_create_credit') : __('billing_invoice:invoice_paid') }}
            </template>
        </template>
    </MTooltip>
    <MButton
        v-else
        class="tw-w-full"
        size="lg"
        variant="primary"
        @click="showInvoicePaymentSuggestionsModal"
    >
        <template v-if="invoice.invoice_type === 'credit_note'">
            {{ __('billing_invoice:refund_the_credit_note') }}
        </template>
        <template v-else>
            {{ __('billing_invoice:settle_the_bill') }}
        </template>
    </MButton>
</template>
