<script setup lang="ts">
    import {ref} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import type JobModel from '@/modules/human-resources/models/JobModel';
    import Manager from '@/modules/legacy/store/manager.store';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    defineExpose({
        save,
    });

    const {jobModel} = defineProps<{
        jobModel: JobModel;
    }>();

    const emit = defineEmits<{
        (e: 'loading', value: boolean): void;
        (e: 'saved', value: JobModel): void;
    }>();

    const focus = ref(true);
    const organizationModel = Manager.activeOrganization;

    async function save() {
        emit('loading', true);
        try {
            if (!jobModel.exists) {
                await jobModel.attachToOrga(organizationModel);
            }
            await jobModel.save();
            emit('saved', jobModel);
        } catch (error) {
            useNotification().error(__('common:errors.generic'));
            throw error;
        } finally {
            emit('loading', false);
        }
    }
</script>

<template>
    <MForm @submit.prevent="save">
        <MFieldset>
            <MLabel>
                {{ __('common:name') }}*
                <MHelpTooltip class="tw-ml-2">
                    {{ __('hr:job_name_appear_on_contract') }}
                </MHelpTooltip>
            </MLabel>
            <CInput
                v-model="jobModel.attributes.name"
                :autofocus="focus"
            />
        </MFieldset>
        <CFormSubmitTrigger />
    </MForm>
</template>
