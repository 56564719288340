import {ValidationError} from '@meekohq/lumos';

import type {ApiResponseOutputPort} from '@/modules/core/domain/ports/ApiResponseOutputPort';
import type {ReportingSettingEntity} from '@/modules/meeko-family/domain/entities/ReportingSettingEntity';
import type {ReportingSettingRepositoryPort} from '@/modules/meeko-family/domain/ports/ReportingSettingRepositoryPort';
import type {StoreReportingSettingsInputPort} from '@/modules/meeko-family/domain/use-cases/StoreReportingSettingsInputPort';

export default class StoreReportingSettingsInteractor implements StoreReportingSettingsInputPort {
    constructor(
        private readonly repository: ReportingSettingRepositoryPort,
        private readonly presenter: ApiResponseOutputPort
    ) {}

    async execute(organizationId: string, settings: ReportingSettingEntity[]): Promise<unknown> {
        try {
            await this.repository.storeReportingSettings(organizationId, settings);

            return this.presenter.success();
        } catch (error: Error) {
            if (error instanceof ValidationError) {
                return this.presenter.validationError(error as ValidationError);
            }

            return this.presenter.unexpectedError(error);
        }
    }
}
