<template>
    <div class="MSelectMenu">
        <MPopover2>
            <MPopover2ClickableReference>
                <MButton
                    align="left"
                    class="MSelectMenu__button"
                    :class="buttonClass"
                    :disabled="disabled"
                    icon-sort
                    :size="size"
                    :truncate="truncate"
                    :variant="hasError ? 'outline-danger' : 'light'"
                >
                    <template v-if="selectedOptionText && !forceFallback">
                        {{ selectedOptionText }}
                    </template>
                    <template v-else>
                        <slot name="fallback">
                            {{ fallbackText ?? __('common:actions.select') }}
                        </slot>
                    </template>
                </MButton>
            </MPopover2ClickableReference>
            <MPopover2Panel
                v-slot="{close}"
                no-padding
                panel-class="MSelectMenu__panel"
            >
                <CFinder
                    ref="finder"
                    :funnel="funnel"
                    :has-unselect="hasUnselect"
                    :list-class="listClass"
                    :max-height="maxHeight"
                    :multi="multi"
                    :options="options"
                    :search-bar="searchBar"
                    :search-on="[valuePath, textPath]"
                    :unselect-value="unselectValue"
                    :model-value="selectedOption"
                    @update:model-value="onSelectedOption($event, close)"
                    @keydown.tab="close()"
                >
                    <template
                        v-if="$slots['unselect-item']"
                        #unselect-item
                    >
                        <slot
                            v-if="hasUnselect"
                            name="unselect-item"
                        >
                            {{ __('common:actions.unselect') }}
                        </slot>
                    </template>
                    <template #default="{option}">
                        {{ get(option, textPath) }}
                        <div
                            v-if="get(option, subTextPath)"
                            class="MSelectMenu__item-sub-title"
                            v-html="get(option, subTextPath)"
                        />
                    </template>
                </CFinder>
            </MPopover2Panel>
        </MPopover2>
    </div>
</template>

<script lang="ts">
    import {find, get, head, isArray, isEqual} from 'lodash-es';
    import {computed, defineComponent, reactive, ref, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {},
        props: {
            options: {type: Array, required: true, default: () => reactive([])},
            modelValue: {},
            forceFallback: {type: Boolean, default: false},
            fallbackText: {type: String, default: undefined},
            hasError: {type: Boolean, default: false},
            hasUnselect: {type: Boolean, default: false},
            unselectValue: {default: undefined},
            truncate: {type: Boolean, default: true},
            valuePath: {type: String, default: 'value'},
            textPath: {type: String, default: 'text'},
            subTextPath: {type: String, default: undefined},
            listClass: {type: String, default: ''},
            buttonClass: {type: String, default: ''},
            searchBar: {type: Boolean, default: false},
            funnel: {type: Boolean, default: false},
            maxHeight: {type: String},
            multi: {type: Boolean, default: false},
            size: {default: 'base'},
            disabled: {type: Boolean, default: false},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const selectedOption = ref<unknown>(props.unselectValue);

            const selectedOptionText = computed(() => {
                if (props.multi && isMultiValue.value) {
                    const values = selectedOption.value as any[];

                    if (values.length) {
                        return __('components:count_item', {
                            count: values.length - 1,
                            value: get(head(values), props.textPath),
                        });
                    }

                    return null;
                }

                return get(selectedOption.value, props.textPath);
            });

            const isMultiValue = computed(() => isArray(selectedOption.value) && props.multi);

            const resetSelectionOption = function (value, options) {
                if (props.multi) {
                    if (isArray(value)) {
                        const values: any[] = [];

                        for (const item of value) {
                            const match = find(options, option => isEqual(get(option, props.valuePath), item));
                            if (match) {
                                values.push(match);
                            }
                        }
                        selectedOption.value = values;
                    }
                } else {
                    selectedOption.value =
                        find(options, option => isEqual(get(option, props.valuePath), value)) ?? props.unselectValue;
                }
            };

            watch(
                () => props.modelValue,
                value => {
                    resetSelectionOption(value, props.options);
                },
                {immediate: true}
            );

            watch(
                () => props.options,
                options => {
                    resetSelectionOption(props.modelValue, options);
                },
                {deep: true}
            );

            const dropdownRef = ref();

            const onSelectedOption = function (value, closeFn) {
                if (props.multi) {
                    if (isArray(value)) {
                        emit(
                            'update:modelValue',
                            value.map(item => get(item, props.valuePath))
                        );
                    } else {
                        emit('update:modelValue', value);
                    }
                } else {
                    closeFn();
                    emit('update:modelValue', get(value, props.valuePath));
                }
            };

            return {
                selectedOption,
                selectedOptionText,
                dropdownRef,
                onSelectedOption,
                isArray,
                get,
            };
        },
    });
</script>
