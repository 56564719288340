import _maxBy from 'lodash-es/maxBy';
import _minBy from 'lodash-es/minBy';
import moment from 'moment';

interface OpeningPeriod {
    started_at: string;
    ended_at: string;
}
interface EventPeriod {
    started_time: string;
    ended_time: string;
}

export default function () {
    function getOpening(
        organizationOpeningHours: OpeningPeriod[],
        events: EventPeriod[],
        removeOneHour = false,
        exact = false
    ) {
        let openings = organizationOpeningHours.map(period => period.started_at);

        if (events && events.length) {
            const startPlannings = events.map(event => event.started_time);
            openings = openings.concat(startPlannings);
        }

        const min = _minBy(openings);
        const opening = min ? min : '07:00:00';

        const momentTime = moment(opening, 'HH:mm:ss');
        const fullTime = momentTime.hour() === 0 ? true : exact;

        if (!fullTime) {
            if (momentTime.minutes() >= 30) {
                momentTime.minutes(0);
            } else {
                momentTime.minutes(0);
                if (removeOneHour) {
                    momentTime.subtract(1, 'hour');
                }
            }
        }

        return momentTime.format('HH:mm:ss');
    }

    function getClosing(
        organizationOpeningHours: OpeningPeriod[],
        events: EventPeriod[],
        addOneHour = false,
        exact = false
    ) {
        let closings = organizationOpeningHours.map(period => period.ended_at);

        if (events && events.length) {
            const startPlannings = events.map(event => event.ended_time);
            closings = closings.concat(startPlannings);
        }

        const max = _maxBy(closings);
        const closing = max ? max : '18:00:00';

        const momentTime = moment(closing, 'HH:mm:ss');
        const fullTime = momentTime.hour() >= 23 ? true : exact;

        if (!fullTime && addOneHour) {
            momentTime.add(1, 'hour');
        }

        return momentTime.format('HH:mm:ss');
    }

    return {
        getOpening,
        getClosing,
    };
}
