import {Epoch} from '@meekohq/lumos';

import __ from '@/modules/app/utils/i18n-facade';

export default function () {
    const allocationPeriodLabel = (startedAt: string, endedAt: string | null = null, isFullDay = false) => {
        const startedAtDateTime = Epoch.fromISOString(startedAt);

        if (!endedAt) {
            return formatDate(startedAtDateTime);
        }

        const endedAtDateTime = Epoch.fromISOString(endedAt);

        if (startedAtDateTime.differenceInDays(endedAtDateTime) === 0 && !isFullDay) {
            return __('common:date_format.same_day_period', {
                date: formatDate(startedAtDateTime, endedAtDateTime),
                startTime: startedAtDateTime.toLocaleString(Epoch.presets.TIME_SIMPLE),
                endTime: endedAtDateTime.toLocaleString(Epoch.presets.TIME_SIMPLE),
            });
        } else if (startedAtDateTime.differenceInDays(endedAtDateTime) === 0 && isFullDay) {
            return formatDate(startedAtDateTime);
        } else if (isFullDay) {
            return __('common:date_format.date_period', {
                startDate: formatDate(startedAtDateTime, endedAtDateTime),
                endDate: formatDate(endedAtDateTime),
            });
        } else {
            return __('common:date_format.datetime_period', {
                startDate: formatDate(startedAtDateTime, endedAtDateTime),
                startTime: startedAtDateTime.toLocaleString(Epoch.presets.TIME_WITH_LONG_OFFSET),
                endDate: formatDate(endedAtDateTime),
                endTime: endedAtDateTime.toLocaleString(Epoch.presets.TIME_WITH_LONG_OFFSET),
            });
        }
    };

    const formatDate = (date: Epoch, nextDate: Epoch | null = null) => {
        if (date.differenceInYears(Epoch.now()) === 0 && (!nextDate || date.differenceInYears(nextDate) === 0)) {
            return date.toFormat('d MMMM');
        }

        return date.toLocaleString(Epoch.presets.DATE_FULL);
    };

    return {allocationPeriodLabel};
}
