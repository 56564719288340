<template>
    <CVStack class="tw-w-64">
        <CHStack
            align="center"
            class="tw-mb-4"
        >
            <CText>{{ __('common:each') }}</CText>
            <CInput
                v-model="rule.interval"
                class="tw-mx-2 tw-w-16"
                min="1"
                type="number"
            />
            <CText>{{ __('calendar:yearly_one') }}</CText>
        </CHStack>
        <CButtonGroup
            v-for="(chunkOfMonths, i) in chunk(months, 6)"
            :key="'row-' + i"
            class="tw-mb-2 tw-w-full"
        >
            <MButton
                v-for="month in chunkOfMonths"
                :key="'day-' + month.value"
                :class="{'tw-w-full': chunkOfMonths.length === 6}"
                :truncate="false"
                :variant="rule.bymonth?.includes(month.value) ? 'gray' : 'light'"
                @click="toggleMonth(month.value)"
            >
                {{ month.text }}
            </MButton>
        </CButtonGroup>
        <CHStack
            align="center"
            class="tw-mt-2"
        >
            <CCheckbox v-model="isSpecify">
                {{ __('common:the') }}
            </CCheckbox>
        </CHStack>
        <CHStack
            align="center"
            class="tw-mt-2"
        >
            <BySetPos
                v-model="position"
                :disabled="!isSpecify"
            />
            <ByWeekDay
                v-model="weekDay"
                class="tw-ml-2"
                :disabled="!isSpecify"
            />
        </CHStack>
    </CVStack>
</template>

<script lang="ts">
    import {EpochInfo} from '@meekohq/lumos';
    import chunk from 'lodash-es/chunk';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onUnmounted, ref, watch} from 'vue';

    import BySetPos from '@/modules/calendar/components/RuleSet/Rule/BySetPos.vue';
    import ByWeekDay from '@/modules/calendar/components/RuleSet/Rule/ByWeekDay.vue';
    import type {Range} from '@/modules/calendar/utils/RuleSet/Range';
    import type Rule from '@/modules/calendar/utils/RuleSet/Rule';
    import MonthValue from '@/modules/calendar/utils/RuleSet/values/MonthValue';

    export default defineComponent({
        components: {ByWeekDay, BySetPos},
        props: {
            rule: {
                type: Object as PropType<Rule>,
                required: true,
            },
        },
        setup(props) {
            const isSpecify = ref(false);
            const position = ref(props.rule.bysetpos ?? []);
            const weekDay = ref(props.rule.byweekday ?? []);
            const shortMonth = computed(() => EpochInfo.months('short'));

            // @ts-ignore
            const months: {
                text: string;
                value: number[];
            }[] = Object.keys(MonthValue)
                .filter(x => isNaN(parseInt(x)))
                .map(month => {
                    switch (month) {
                        case 'january':
                            return {value: MonthValue[month], text: shortMonth.value[0]};
                        case 'february':
                            return {value: MonthValue[month], text: shortMonth.value[1]};
                        case 'march':
                            return {value: MonthValue[month], text: shortMonth.value[2]};
                        case 'april':
                            return {value: MonthValue[month], text: shortMonth.value[3]};
                        case 'may':
                            return {value: MonthValue[month], text: shortMonth.value[4]};
                        case 'june':
                            return {value: MonthValue[month], text: shortMonth.value[5]};
                        case 'july':
                            return {value: MonthValue[month], text: shortMonth.value[6]};
                        case 'august':
                            return {value: MonthValue[month], text: shortMonth.value[7]};
                        case 'september':
                            return {value: MonthValue[month], text: shortMonth.value[8]};
                        case 'october':
                            return {value: MonthValue[month], text: shortMonth.value[9]};
                        case 'november':
                            return {value: MonthValue[month], text: shortMonth.value[10]};
                        case 'december':
                            return {value: MonthValue[month], text: shortMonth.value[11]};
                        default: {
                            throw new Error('Not implemented yet');
                        }
                    }
                });

            function toggleMonth(month: Range<1, 12>) {
                if (props.rule.bymonth?.includes(month)) {
                    props.rule.bymonth = props.rule.bymonth.filter(value => value !== month);
                } else {
                    props.rule.bymonth?.push(month);
                }
            }

            watch(isSpecify, () => {
                if (isSpecify.value) {
                    props.rule.byweekday = weekDay.value;
                    props.rule.bysetpos = position.value;
                } else {
                    props.rule.byweekday = [];
                    props.rule.bysetpos = [];
                }
            });

            watch(position, () => {
                props.rule.bysetpos = position.value;
            });

            watch(weekDay, () => {
                props.rule.byweekday = weekDay.value;
            });

            function initData() {
                if (!props.rule.interval) {
                    props.rule.interval = 1;
                }

                if (!props.rule.bymonth) {
                    props.rule.bymonth = [];
                }

                if (!props.rule.bysetpos) {
                    props.rule.bysetpos = [];
                }

                if (!props.rule.byweekday) {
                    props.rule.byweekday = [];
                }
            }

            initData();

            onUnmounted(() => {
                if (props.rule.bysetpos?.length === 0) {
                    props.rule.bysetpos = undefined;
                }

                if (props.rule.byweekday?.length === 0) {
                    props.rule.byweekday = undefined;
                }

                if (props.rule.bymonth?.length === 0) {
                    props.rule.bymonth = undefined;
                }
            });

            return {
                chunk,
                isSpecify,
                months,
                position,
                toggleMonth,
                weekDay,
            };
        },
    });
</script>
