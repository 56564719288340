<template>
    <CCard
        :border="false"
        :shadow="true"
    >
        <StaffAllocationsReport />
    </CCard>
</template>
<script>
    import StaffAllocationsReport from '@/modules/human-resources/components/balance/StaffAllocationsReport.vue';

    export default {
        components: {StaffAllocationsReport},
    };
</script>
