import {useIntervalFn} from '@vueuse/core';
import _cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';
import {computed, onMounted, onUnmounted, ref} from 'vue';

import {EventBus} from '@/eventBus';
import type EventModel from '@/modules/human-resources/models/EventModel';
import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
import {unixToPercent} from '@/modules/legacy/libs/planning/planning';

export default function (day, opening, closing) {
    const latestEvent = ref<EventModel | TemplateEventModel | null>(null);

    function handleCopyEvent(clickedEvent: EventModel | TemplateEventModel) {
        latestEvent.value = _cloneDeep(clickedEvent);
    }

    function handleClearCopy() {
        latestEvent.value = null;
    }

    onMounted(() => {
        EventBus.$on('calendar:staff:event:copy', handleCopyEvent);
        EventBus.$on('calendar:staff:event:clearCopy', handleClearCopy);
    });

    onUnmounted(() => {
        EventBus.$off('calendar:staff:event:copy', handleCopyEvent);
        EventBus.$off('calendar:staff:event:clearCopy', handleClearCopy);
    });

    const now = ref(moment().unix());
    useIntervalFn(() => {
        now.value = moment().unix();
    }, 1000 * 60);

    const clockEnabled = computed(() => {
        return (
            unixToPercent(now.value, day, opening, closing) >= 0 &&
            unixToPercent(now.value, day, opening, closing) <= 100
        );
    });

    function outOfDay(startedAt: number, endedAt: number) {
        const percentStartedAt = unixToPercent(startedAt, day, opening, closing, true);
        const percentEndedAt = unixToPercent(endedAt, day, opening, closing, true);

        return percentStartedAt > 100 || percentEndedAt < 0;
    }

    return {
        latestEvent,
        clockEnabled,
        outOfDay,
    };
}
