<template>
    <div class="row">
        <div class="col-7 col-sm-6">
            {{ __('planning:required_staffs') }}
        </div>
        <div class="col-5 col-sm-6">
            <div
                v-for="(staff, i) in rule.staffs"
                :key="'staff' + i"
                class="tw-mb-2"
            >
                <CBadge
                    class="tw-w-full tw-p-2 tw-text-center"
                    variant="cyan"
                >
                    <div class="tw-truncate">
                        {{ jobTitleName(staff.job_id, staff.staffs) }}
                    </div>
                </CBadge>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            jobModels: {
                type: Object,
                required: true,
            },
            rule: {
                type: Object,
                required: true,
            },
        },

        setup(props) {
            function jobTitleName(jobId, staffsNumber) {
                const jobModel = props.jobModels.first(item => item.id === jobId);
                let jobTitle;

                if (jobModel) {
                    jobTitle = jobModel.attributes?.name;
                } else {
                    jobTitle = __('common:employee', {count: staffsNumber});
                }

                return `${staffsNumber} ${jobTitle}`;
            }

            return {
                jobTitleName,
            };
        },
    });
</script>
