<template>
    <div>
        <h6
            class="h6 tw-mb-1"
            v-html="__('request:absence_of_with_name', {name: fullName})"
        />
        <div class="content tw-text-danger-500">
            <template v-if="sameDate">
                {{
                    __('common:date_format.human_from_date_from_time_to_time', {
                        date: Epoch.fromTimestamp(message.started_at).toLocaleString(Epoch.presets.DATE_SHORT),
                        fromTime: Epoch.fromTimestamp(message.started_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                        toTime: Epoch.fromTimestamp(message.ended_at).toLocaleString(Epoch.presets.TIME_SIMPLE),
                    })
                }}
                <br />
                {{ message.content }}
            </template>
            <template v-else>
                {{
                    __('common:date_format.human_from', {
                        date: Epoch.fromTimestamp(message.started_at).toLocaleString(Epoch.presets.DATETIME_SHORT),
                    })
                }}
                <br />
                {{
                    __('common:date_format.human_to', {
                        date: Epoch.fromTimestamp(message.ended_at).toLocaleString(Epoch.presets.DATETIME_SHORT),
                    })
                }}
                <br />
                {{ message.content }}
            </template>
        </div>
        <MMenu class="tw-mt-1">
            <MMenuButton>
                <MButton
                    icon-menu
                    :variant="getVariantBtn(message.state)"
                >
                    <template #left-icons>
                        <FontAwesomeIcon
                            v-if="message.state === 'accepted'"
                            :fixed-width="true"
                            icon="fa-solid fa-check-circle"
                        />
                        <FontAwesomeIcon
                            v-else-if="message.state === 'rejected'"
                            :fixed-width="true"
                            icon="fa-solid fa-times-circle"
                        />
                        <FontAwesomeIcon
                            v-else
                            :fixed-width="true"
                            icon="fa-solid fa-question-circle"
                        />
                    </template>
                    <template v-if="message.state === 'accepted'">
                        {{ __('request:validated_absence') }}
                    </template>
                    <template v-else>
                        {{ __('request:pended_absence') }}
                    </template>
                </MButton>
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('request:validate_absence')"
                    @click="accept"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-check-circle"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('common:put_on_hold')"
                    @click="updateState('pending')"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-question-circle"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import useEditEventModal from '@/modules/planning/composables/core/useEditEventModal';

    export default {
        props: ['message'],
        computed: {
            Epoch() {
                return Epoch;
            },
            sameDate() {
                return Epoch.fromTimestamp(this.message.started_at).hasSame(
                    Epoch.fromTimestamp(this.message.ended_at),
                    'day'
                );
            },
            fullName() {
                return `${this.message.kid_first_name} ${this.message.kid_last_name}`;
            },
        },
        methods: {
            accept() {
                const segment = {};
                segment.started_at = Epoch.fromTimestamp(this.message.started_at).toISOString();
                segment.ended_at = Epoch.fromTimestamp(this.message.ended_at).toISOString();
                segment.kid_id = this.message.kid_id;
                segment.nursery_id = this.message.nursery_id;
                segment.type = 'absence';
                segment.note = this.message.content;
                segment.contract_hourly_rate = this.message.hourly_rate;
                segment.hourly_rate = this.message.hourly_rate;
                segment.isNew = true;
                segment.rangeAbsence = true;
                segment.unit = 'day';

                const myNotif = _cloneDeep(this.message);
                myNotif.data = {
                    message_id: this.message.id,
                };
                myNotif.referenceable = {
                    id: this.message.id,
                };

                segment.notification = myNotif;
                segment.notification.state = 'accepted';

                useEditEventModal().show(segment);
            },

            updateState(state) {
                useApi()
                    .legacy.put(
                        route('nurseries.messages.update', {
                            nurseries: this.message.nursery_id,
                            message: this.message.id,
                        }),
                        {
                            state,
                        }
                    )
                    .then(response => {
                        this.message.state = response.data.state;
                        useNotification().success(__('request:absence_updated_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },

            getVariantBtn(state) {
                if (state === 'accepted') {
                    return 'success';
                } else if (state === 'rejected') {
                    return 'danger';
                }

                return 'primary';
            },
        },
    };
</script>

<style scoped>
    .content {
        font-size: 14px;
    }
</style>
