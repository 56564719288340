<script setup lang="ts">
    import {collect, type Model} from '@meekohq/lumos';
    import {isNil} from 'lodash-es';
    import {computed, onMounted, onUnmounted, ref, watch} from 'vue';

    import {EventBus} from '@/eventBus';
    import useModal from '@/modules/app/composables/useModal';
    import CreateContactModal from '@/modules/cashier/components/core/contact/organisms/CreateContactModal.vue';
    import NotificationsOfflineSubscriptionToggle from '@/modules/cashier/components/core/notification/atoms/NotificationsOfflineSubscriptionToggle.vue';
    import NotificationsListItem from '@/modules/cashier/components/core/notification/molecules/NotificationsListItem.vue';
    import ContactModel from '@/modules/cashier/models/ContactModel';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import EndpointModel from '@/modules/cashier/models/EndpointModel';
    import type OrderModel from '@/modules/cashier/models/OrderModel';
    import SubscriptionModel from '@/modules/cashier/models/SubscriptionModel';
    import type TenantModel from '@/modules/cashier/models/TenantModel';
    import OfflineEndpointSubscriberService from '@/modules/cashier/utils/core/notification/OfflineEndpointSubscriberService';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import MemberModel from '@/modules/family/models/MemberModel';

    const props = withDefaults(
        defineProps<{
            defaultEmail?: string;
            customerModel: CustomerModel;
            endpointModels: Model[];
            orderModel: OrderModel;
            tenantModel: TenantModel;
            autoSubcribeMember?: boolean;
            contract: any;
            previousOrderId?: string;
        }>(),
        {
            defaultEmail: undefined,
            autoSubcribeMember: false,
            previousOrderId: undefined,
        }
    );

    const emit = defineEmits(['endpointsChanged', 'loadingMember']);

    const topics = computed(() => {
        return collect(Object.values(SubscriptionModel.TOPICS));
    });

    const contactModels = ref<ContactModel[]>([]);
    const memberModels = ref<MemberModel[]>([]);

    const isModelsLoading = ref<boolean>(false);

    const isCreationWithoutPreviousOrder = computed(() => {
        return props.autoSubcribeMember && isNil(props.previousOrderId);
    });

    const isCreationWithPreviousOrder = computed(() => {
        return props.autoSubcribeMember && !isNil(props.previousOrderId);
    });

    const endpointCollection = computed(() => {
        return [...contactModels.value, ...memberModels.value];
    });

    const createContactModal = useModal({
        component: CreateContactModal,
        props: {
            customerModel: props.customerModel,
        },
        listeners: modal => ({
            created(event: ContactModel) {
                onContactCreated(event);
                modal.hide();
            },
        }),
    });

    watch(
        endpointCollection,
        models => {
            emit('endpointsChanged', models);
        },
        {deep: true}
    );

    onUnmounted(() => {
        EventBus.$off('contract:parent:change', subscribeForParentSetInContract);
    });

    onMounted(async () => {
        EventBus.$on('contract:parent:change', subscribeForParentSetInContract);

        isModelsLoading.value = true;

        await Promise.all([fetchContact(), fetchMembers()]);

        if (isCreationWithPreviousOrder.value) {
            await subscribeFromPreviousContract();
        }

        if (isCreationWithoutPreviousOrder.value) {
            subscribeAllMembers();
        }

        isModelsLoading.value = false;
    });

    watch(
        () => props.customerModel,
        async () => {
            memberModels.value = [];
            contactModels.value = [];

            isModelsLoading.value = true;

            await Promise.all([fetchContact(), fetchMembers()]);
            if (props.contract.first_family_member_id || props.contract.second_family_member_id) {
                await subscribeForParentSetInContract();
            }

            isModelsLoading.value = false;
        }
    );

    async function fetchContact() {
        await ContactModel.query()
            .with(new ContactModel().notificationEndpoint(), query => {
                query.with(new EndpointModel().subscriptions());
            })
            .where('customer_id', props.customerModel.getKey())
            .get()
            .then(response => {
                const models = response.all();
                models.forEach(contactModel => {
                    if (!contactModel.notificationEndpoint().value()) {
                        const endpointModel = new EndpointModel();
                        endpointModel.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                        endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                        endpointModel.attributes.resource_type = contactModel.type;
                        endpointModel.attributes.resource_id = contactModel.getKey();
                        contactModel.notificationEndpoint().set(endpointModel);
                    }

                    contactModels.value.push(contactModel);
                });
            });
    }

    async function fetchMembers() {
        await MemberModel.query()
            .with(new MemberModel().notificationEndpoint(), query => {
                query.with(new EndpointModel().subscriptions());
            })
            .whereHas(new MemberModel().families(), query => {
                query.whereHas(new FamilyModel().customers(), query1 => {
                    query1.where('id', props.customerModel.getKey());
                });
            })
            .get()
            .then(response => {
                const models = response.all();
                models.forEach(memberModel => {
                    if (!memberModel.notificationEndpoint().value()) {
                        const endpointModel = new EndpointModel();
                        endpointModel.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                        endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                        endpointModel.attributes.resource_type = memberModel.type;
                        endpointModel.attributes.resource_id = memberModel.getKey();
                        memberModel.notificationEndpoint().set(endpointModel);
                    }

                    memberModels.value.push(memberModel);
                });
            });
    }

    async function subscribeForParentSetInContract() {
        const familyMemberIds = collect([props.contract.first_family_member_id, props.contract.second_family_member_id])
            .unique()
            .filter(id => !isNil(id))
            .toArray();

        memberModels.value.forEach(member => {
            if (familyMemberIds.includes(member.getKey())) {
                subscribeToNotification(member, true);
            }
        });
    }

    function subscribeAllMembers() {
        memberModels.value.forEach(member => {
            subscribeToNotification(member, true);
        });
    }

    function subscribeFromPreviousContract() {
        memberModels.value.forEach(async memberModel => {
            const previousSubscribed = await SubscriptionModel.query()
                .where('endpoint_id', memberModel.notificationEndpoint().value().getKey())
                .where(query => {
                    query.where(query2 => {
                        query2.where('resource_type', props.customerModel.type);
                        query2.where('resource_id', props.customerModel.getKey());
                    });
                    query.orWhere(query2 => {
                        query2.where('resource_type', props.orderModel.type);
                        query2.where('resource_id', props.previousOrderId);
                    });
                })
                .get();

            if (previousSubscribed.length) {
                const subscribeToCustomer = previousSubscribed.some((subscription: SubscriptionModel) => {
                    return subscription.attributes.resource_type === props.customerModel.type;
                });

                subscribeToNotification(memberModel as MemberModel, subscribeToCustomer);
            }
        });

        contactModels.value.forEach(async contactModel => {
            const previousSubscribed = await SubscriptionModel.query()
                .where('endpoint_id', contactModel.notificationEndpoint().value().getKey())
                .where(query => {
                    query.where(query2 => {
                        query2.where('resource_type', props.customerModel.type);
                        query2.where('resource_id', props.customerModel.getKey());
                    });
                    query.orWhere(query2 => {
                        query2.where('resource_type', props.orderModel.type);
                        query2.where('resource_id', props.previousOrderId);
                    });
                })
                .get();

            if (previousSubscribed.length) {
                const subscribeToCustomer = previousSubscribed.some((subscription: SubscriptionModel) => {
                    return subscription.attributes.resource_type === props.customerModel.type;
                });

                subscribeToNotification(contactModel as MemberModel, subscribeToCustomer);
            }
        });
    }

    function onContactCreated(contactModel: ContactModel) {
        contactModel.fresh().then(response => {
            if (!response.notificationEndpoint().value()) {
                const endpointModel = new EndpointModel();
                endpointModel.attributes.tenant_id = props.customerModel.attributes.tenant_id;
                endpointModel.attributes.type = EndpointModel.TYPE.RESOURCE;
                endpointModel.attributes.resource_type = response.type;
                endpointModel.attributes.resource_id = response.getKey();
                contactModel.notificationEndpoint().set(endpointModel);
            }
            contactModels.value.unshift(contactModel);
        });
    }

    function subscribeToNotification(source: MemberModel | ContactModel, subscribeToCustomer: boolean): void {
        if (source.attributes.email) {
            const endpointModel = source.notificationEndpoint().value();

            if (subscribeToCustomer) {
                const subscriberService = new OfflineEndpointSubscriberService(endpointModel, props.customerModel);

                topics.value.each(topic => {
                    if (!subscriberService.isSubscribedToTopic(topic)) {
                        subscriberService.subscribeToTopic(endpointModel, topic);
                    }
                });
            } else {
                const subscriberService = new OfflineEndpointSubscriberService(endpointModel, props.orderModel);

                topics.value
                    .filter(topic => topic !== SubscriptionModel.TOPICS.TAX_CERTIFICATE_SENT)
                    .each(topic => {
                        if (!subscriberService.isSubscribedToTopic(topic)) {
                            subscriberService.subscribeToTopic(endpointModel, topic);
                        }
                    });
            }
        }
    }
</script>

<template>
    <div>
        <div
            class="tw-flex tw-flex-col tw-items-start tw-justify-between tw-gap-1 sm:tw-flex-row sm:tw-items-center lg:tw-gap-3"
        >
            <div class="tw-font-semibold">
                {{ __('billing_core:send_invoices_to_colon') }}
            </div>
            <div>
                <MButton
                    class="tw-whitespace-nowrap"
                    size="sm"
                    @click="createContactModal.show()"
                >
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                    </template>
                    {{ __('billing_customer:add_contact') }}
                </MButton>
            </div>
        </div>
        <loader
            v-if="isModelsLoading"
            class="col tw-text-center"
        />
        <CList
            v-else
            class="tw-mt-2 tw-space-y-4"
        >
            <template v-if="contactModels.length || memberModels.length">
                <CListSection
                    v-if="contactModels.length"
                    variant="orange"
                >
                    <template #title>
                        {{ __('billing_customer:customer_account_contacts') }}
                    </template>
                    <CListRow
                        v-for="contactModel in contactModels"
                        :key="contactModel.getKey()"
                        :hover="false"
                    >
                        <NotificationsListItem :model="contactModel">
                            <NotificationsOfflineSubscriptionToggle
                                :customer-model="customerModel"
                                :resource-model="orderModel"
                                :source-model="contactModel"
                                @subscribe="subscribeToNotification($event, false)"
                            />
                        </NotificationsListItem>
                    </CListRow>
                </CListSection>
                <CListSection
                    v-if="memberModels.length"
                    variant="purple"
                >
                    <template #title>
                        {{ __('billing_core:members_family') }}
                    </template>
                    <CListRow
                        v-for="memberModel in memberModels"
                        :key="memberModel.getKey()"
                        :hover="false"
                    >
                        <NotificationsListItem :model="memberModel">
                            <NotificationsOfflineSubscriptionToggle
                                :customer-model="customerModel"
                                :resource-model="orderModel"
                                :source-model="memberModel"
                                @subscribe="subscribeToNotification($event, false)"
                            />
                        </NotificationsListItem>
                    </CListRow>
                </CListSection>
            </template>
            <div v-else>
                <div class="tw-p-4 tw-text-center tw-text-disabled">
                    {{ __('billing_customer:no_contact_available') }}
                </div>
            </div>
        </CList>
    </div>
</template>
