<template>
    <MSelectMenu
        v-model="selectedWeekDay"
        :disabled="disabled"
        :fallback-text="days[0].text"
        :options="days"
    />
</template>

<script lang="ts">
    import {EpochInfo} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import type {Range} from '@/modules/calendar/utils/RuleSet/Range';
    import DayValue from '@/modules/calendar/utils/RuleSet/values/DayValue';

    export default defineComponent({
        props: {
            modelValue: {
                type: Array as PropType<Range<1, 7>[]>,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const selectedWeekDay = ref(props.modelValue ?? [1]);
            const weekDays = computed(() => EpochInfo.weekdays());

            const days: {
                text: string;
                value: number[];
            }[] = Object.keys(DayValue)
                .filter(x => isNaN(parseInt(x)))
                .map(day => {
                    switch (day) {
                        case 'monday':
                            return {value: [DayValue[day]], text: weekDays.value[0]};
                        case 'tuesday':
                            return {value: [DayValue[day]], text: weekDays.value[1]};
                        case 'wednesday':
                            return {value: [DayValue[day]], text: weekDays.value[2]};
                        case 'thursday':
                            return {value: [DayValue[day]], text: weekDays.value[3]};
                        case 'friday':
                            return {value: [DayValue[day]], text: weekDays.value[4]};
                        case 'saturday':
                            return {value: [DayValue[day]], text: weekDays.value[5]};
                        case 'sunday':
                            return {value: [DayValue[day]], text: weekDays.value[6]};
                        default: {
                            throw new Error('Not implemented yet');
                        }
                    }
                });

            // Add week and weekend choices
            days.push({
                value: [DayValue.monday, DayValue.tuesday, DayValue.wednesday, DayValue.thursday, DayValue.friday],
                text: __('common:day_of_week').toLowerCase(),
            });
            days.push({value: [DayValue.saturday, DayValue.sunday], text: __('common:day_of_weekend')});

            watch(
                () => props.disabled,
                disabled => {
                    if (!disabled && (!selectedWeekDay.value || !selectedWeekDay.value?.length)) {
                        selectedWeekDay.value = [1];
                    }
                }
            );

            watch(selectedWeekDay, () => {
                emit('update:modelValue', selectedWeekDay.value);
            });

            return {
                days,
                selectedWeekDay,
            };
        },
    });
</script>
