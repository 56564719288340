<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {reactive} from 'vue';

    import useError from '@/modules/app/composables/useError';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';

    const props = defineProps<{
        planning: object;
        planningModel: object;
        modal: ModalType;
    }>();

    const {startOfMinute} = useDateTimeModifiersPresets();
    const errorHandler = useError();

    const newPlanning = reactive({
        start_time: Epoch.parse(props.planning.start_time, 'HH:mm:ss'),
        end_time: Epoch.parse(props.planning.end_time, 'HH:mm:ss'),
    });

    function save() {
        if (!newPlanning.start_time || !newPlanning.end_time) {
            return;
        }

        if (newPlanning.end_time.lessThanOrEqualTo(newPlanning.start_time as Epoch)) {
            errorHandler.addErrorCode('startBeforeEnd', '0x2EDA212753');

            return;
        }

        props.planning.start_time = newPlanning.start_time.toFormat('HH:mm:ss');
        props.planning.end_time = newPlanning.end_time.toFormat('HH:mm:ss');

        props.modal.hide();
    }

    function deletePlanning() {
        props.planningModel.plannings = props.planningModel.plannings.filter(item => item.id !== props.planning.id);
        props.modal.hide();
    }
</script>

<template>
    <MModal
        :header-title="__('common:changing_range')"
        :modal="modal"
    >
        <MForm>
            <CFormTwoColumns>
                <MFieldset>
                    <MLabel>
                        {{ __('common:date_from') }}
                    </MLabel>
                    <MTimePicker
                        v-model="newPlanning.start_time"
                        class="tw-w-full"
                        :modifier="startOfMinute"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>
                        {{ __('common:date_to') }}
                    </MLabel>
                    <MTimePicker
                        v-model="newPlanning.end_time"
                        class="tw-w-full"
                        :modifier="startOfMinute"
                    />
                </MFieldset>
            </CFormTwoColumns>
            <CFormErrorMessageList :errors="errorHandler.get('startBeforeEnd')" />
        </MForm>
        <template #footer-start>
            <MButton
                :label="__('common:actions.delete')"
                variant="danger"
                @click="deletePlanning"
            />
        </template>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>
