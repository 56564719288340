<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="family">
                {{ $fullName(familyMember) }}
            </MPageTitle>
        </template>
        <template #actions>
            <TagsSelector
                v-if="model"
                :editable="$can('update', 'family_members_details')"
                :taggable-model="model"
                taggable-type="family_member"
                :tags="model.tags().value().toArray()"
                @attached="model.tags().value().push($event)"
                @detached="model.tags().mutate(v => v.filter(c => c.getKey() !== $event.getKey()))"
            />
            <DownloadTemplate
                doc-type="family_member"
                :item="familyMember"
                :nursery="nursery"
                sm
                :user="user"
                variant="light"
            />
        </template>
        <template #content>
            <CTabMenu
                class="tw-mb-4"
                variant="family"
            >
                <CTabMenuItem
                    :active="selectedTab === 'summary'"
                    variant="family"
                    @click="changeSelectedTab('summary')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-user"
                    />
                    {{ __('common:general') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="$can('read', 'family_members_documents')"
                    :active="selectedTab === 'documents'"
                    variant="family"
                    @click="changeSelectedTab('documents')"
                >
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-file-alt"
                    />
                    {{ __('common:document_other') }}
                </CTabMenuItem>
            </CTabMenu>
            <template v-if="selectedTab === 'summary'">
                <div
                    v-if="familyMember.id"
                    class="tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row"
                >
                    <MBox
                        v-if="$can('read', 'family_members_chat')"
                        class="tw-w-full tw-overflow-hidden lg:tw-w-2/5"
                    >
                        <ModuleChat
                            :family-member="familyMember"
                            :nursery="nursery"
                            :user="user"
                        />
                    </MBox>
                    <MBox class="tw-w-full tw-p-4 lg:tw-w-3/5">
                        <ModuleSummary
                            v-model:family-member="familyMember"
                            :nursery="nursery"
                        />
                    </MBox>
                </div>
                <div
                    v-else
                    key="loader"
                >
                    <loader custom-class="la-2x" />
                </div>
            </template>
            <template v-if="selectedTab === 'documents'">
                <SimpleSubPageTemplate v-if="model">
                    <template #title>
                        <MHeading>{{ __('common:documents_of', {name: model.fullname}) }}</MHeading>
                    </template>
                    <template #actions>
                        <RouterLink
                            v-if="canCreateDocument"
                            :to="{name: 'familyMembers.show.documents.create', query: {nav: 'documents'}}"
                        >
                            <MButton
                                :icon-plus="true"
                                :label="__('common:add_document')"
                                variant="primary"
                            />
                        </RouterLink>
                    </template>
                    <template #content>
                        <DocumentList
                            :bus="bus"
                            :edit-item-fn="editItemFn"
                            permission-key="family_members_documents"
                            :related-resource="model"
                            :show-visibilities="{
                                staffs_access: false,
                                parents_access: true,
                                web_access: false,
                            }"
                        />
                        <router-view
                            :member-model="model"
                            v-on="documentListeners"
                        />
                    </template>
                </SimpleSubPageTemplate>
            </template>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import mitt from 'mitt';

    import {EventBus} from '@/eventBus';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import SimpleSubPageTemplate from '@/modules/app/components/templates/SimpleSubPageTemplate.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useApi from '@/modules/app/composables/useApi';
    import DocumentList from '@/modules/document/components/organisms/DocumentList.vue';
    import FamilyMemberChat from '@/modules/family/components/member/Chat/FamilyMemberChat.vue';
    import Summary from '@/modules/family/components/member/Summary.vue';
    import MemberModel from '@/modules/family/models/MemberModel';
    import DownloadTemplate from '@/modules/legacy/components/Modules/DownloadTemplate.vue';
    import route from '@/modules/legacy/libs/ziggy';
    import TagsSelector from '@/modules/tag/components/TagsSelector.vue';

    export default {
        components: {
            SimpleSubPageTemplate,
            SimplePageTemplate,
            TagsSelector,
            DocumentList,
            ModuleSummary: Summary,
            ModuleChat: FamilyMemberChat,
            DownloadTemplate,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            familyMember: {},
            selectedTab: 'summary',
            selectedTags: [],
            model: null,
            bus: mitt(),
            canCreateDocument: useAbility().can('create', 'family_members_documents'),
        }),
        computed: {
            MemberModel() {
                return MemberModel;
            },
            documentListeners() {
                if (this.$route.name === 'familyMembers.show.documents.create') {
                    return {
                        created: $event => this.bus.emit('documentCreated', $event),
                    };
                } else {
                    return {
                        updated: $event => this.bus.emit('documentUpdated', $event),
                        deleted: $event => this.bus.emit('documentDeleted', $event),
                    };
                }
            },
        },
        watch: {
            '$route.params.familyMember': function () {
                this.init();
            },

            '$route.query.nav': function (value) {
                this.changeSelectedTab(value);
            },
        },
        mounted() {
            this.init();

            this.selectedTab = this.$route.query.nav || 'summary';

            EventBus.$on('update:familyMember', this.handleUpdateFamilyMemberEvent);
            EventBus.$on('refresh:familyMember', this.handleRefreshFamilyMemberEvent);
        },
        beforeUnmount() {
            EventBus.$off('update:familyMember', this.handleUpdateFamilyMemberEvent);
            EventBus.$off('refresh:familyMember', this.handleRefreshFamilyMemberEvent);
        },
        methods: {
            handleUpdateFamilyMemberEvent(familyMember) {
                this.familyMember = familyMember;
            },
            handleRefreshFamilyMemberEvent() {
                this.getFamilyMember();
            },
            init() {
                this.getFamilyMember();
            },

            changeSelectedTab(tab) {
                if (this.selectedTab === tab) {
                    return;
                }

                this.selectedTab = tab;
                this.$router.push({name: 'familyMembers.show', query: {nav: tab}});
            },

            redirectUser(familyMemberOrganizationIds = null) {
                // If familyMember attached to an organization, redirect user on show page with the first organization attached.
                if (familyMemberOrganizationIds && familyMemberOrganizationIds.length) {
                    this.$router.push({
                        name: 'familyMembers.show',
                        params: {
                            nursery: familyMemberOrganizationIds[0],
                        },
                    });
                } else {
                    // If familyMember is not attached redirect on the familyMember index page.
                    this.$router.push({
                        name: 'familyMembers.index',
                        params: {
                            nursery: this.nursery.id,
                        },
                    });
                }
            },

            getFamilyMember() {
                useApi()
                    .legacy.get(
                        route('nurseries.familyMembers.show', {
                            nurseries: this.nursery.id,
                            familyMember: this.$route.params.familyMember,
                        })
                    )
                    .then(response => {
                        const isFamilyMembersAttachedToOrganization = response.data.organization_ids.includes(
                            this.nursery.id
                        );

                        if (isFamilyMembersAttachedToOrganization) {
                            this.familyMember = response.data;
                            this.selectedTags = this.familyMember.tags;
                        } else {
                            this.redirectUser(response.data.organization_ids);
                        }
                    })
                    .catch(() => {
                        this.redirectUser();
                    });

                MemberModel.query()
                    .with(new MemberModel().tags())
                    .find(this.$route.params.familyMember)
                    .then(res => (this.model = res));
            },

            editItemFn(document) {
                this.$router.replace({
                    name: 'familyMembers.show.documents.edit',
                    query: {nav: 'documents'},
                    params: {document: document.getKey()},
                });
            },
        },
    };
</script>
