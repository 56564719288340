import {ReportingType} from '@/modules/meeko-family/domain/values/ReportingType';

export class ReportingSettingEntity {
    constructor(
        public id: string,
        public accountId: string,
        public organizationId: string,
        public type: ReportingType,
        public markNextReportingAsVisible: boolean = type !== ReportingType.observations,
        public markNextReportingObservationAsVisible = false,
        public realTime = true
    ) {}
}
