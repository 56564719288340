<template>
    <div
        v-if="allocation"
        class="tw-flex tw-overflow-hidden tw-rounded-lg tw-shadow-md tw-ring-1 tw-ring-black/20"
    >
        <CVStack class="tw-flex-1">
            <CHStack
                align="center"
                class="tw-border-b tw-border-gray-200 tw-px-2 tw-py-1"
                distribute="between"
            >
                <CText>
                    {{ balanceType.attributes.name }}
                </CText>
            </CHStack>
            <CVStack
                align="center"
                class="tw-flex-1 tw-py-2"
                distribute="center"
            >
                <CText
                    class="tw-w-full"
                    font-size="4xl"
                >
                    <input
                        v-model.number="allocation.attributes.value"
                        class="preventBrowserStyling tw-w-full tw-bg-white tw-text-center tw-font-bold"
                        :disabled="isAllocationConfirmed || !$can('update', 'staffs_balances')"
                        type="number"
                    />
                </CText>
                <CHStack gap="1">
                    <template v-if="balanceType.attributes.show_balance">
                        <CBadge
                            size="sm"
                            variant="blue"
                        >
                            {{ __('common:before') }}
                            <template v-if="balanceType.attributes.unit === 'hour'">
                                {{ __('common:hour_with_count_short', {count: initialSum ?? 0}) }}
                            </template>
                            <template v-else>
                                {{ __('common:day_with_count_short', {count: initialSum ?? 0}) }}
                            </template>
                        </CBadge>
                        <CBadge
                            size="sm"
                            variant="blue"
                        >
                            {{ __('common:after') }}
                            <template v-if="balanceType.attributes.unit === 'hour'">
                                {{ __('common:hour_with_count_short', {count: sum ?? 0}) }}
                            </template>
                            <template v-else>
                                {{ __('common:day_with_count_short', {count: sum ?? 0}) }}
                            </template>
                        </CBadge>
                    </template>
                    <CBadge
                        v-else
                        size="sm"
                        variant="blue"
                    >
                        <template v-if="balanceType.attributes.unit === 'hour'">
                            {{ __('common:hour_with_count', {count: allocation.attributes.value ?? 0}) }}
                        </template>
                        <template v-else>
                            {{ __('common:day_with_count', {count: allocation.attributes.value ?? 0}) }}
                        </template>
                    </CBadge>
                </CHStack>
            </CVStack>
            <CHStack
                v-if="$can('update', 'staffs_balances')"
                class="tw-border-t tw-border-gray-200"
            >
                <template v-if="isAllocationConfirmed">
                    <div class="tw-flex tw-w-full tw-items-baseline tw-justify-center tw-gap-2 tw-py-2">
                        <FontAwesomeIcon
                            class="tw-text-success-500"
                            icon="fa-duotone fa-shield-check"
                        />
                        <div class="tw-text-gray-500">
                            {{ __('hr_balance:account_locked') }}
                        </div>
                        <MHelpTooltip>
                            <div v-html="__('hr_balance:this_balance_has_been_locked_colon')" />
                            <div v-html="__('hr_balance:team_reports_counters_colon')" />
                        </MHelpTooltip>
                    </div>
                </template>
                <template v-else>
                    <CCenter
                        class="tw-min-h-8 tw-flex-1 tw-cursor-pointer tw-border-r tw-border-gray-200 hover:tw-bg-primary-100"
                        @click="decrementValue"
                    >
                        <CText
                            font-weight="semibold"
                            variant="primary"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-minus" />
                        </CText>
                    </CCenter>
                    <CCenter
                        class="tw-min-h-8 tw-flex-1 tw-cursor-pointer hover:tw-bg-primary-100"
                        @click="incrementValue"
                    >
                        <CText
                            font-weight="semibold"
                            variant="primary"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </CText>
                    </CCenter>
                </template>
            </CHStack>
        </CVStack>
    </div>
</template>

<script lang="ts">
    import {round} from 'lodash-es';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref, toRef} from 'vue';

    import configFile from '@/modules/human-resources/config/allocations';
    import BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
    import type BalanceTypeModel from '@/modules/human-resources/models/BalanceTypeModel';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import Calculator from '@/modules/human-resources/utils/balance/Calculator';

    export default defineComponent({
        props: {
            balanceType: {type: Object as PropType<BalanceTypeModel>, required: true},
            staff: {type: Object as PropType<StaffModel>, required: true},
            source: {type: Object as PropType<EventModel>, required: true},
            sumWithSourceAllocation: {type: Boolean, default: false},
        },
        setup(props) {
            const allocation = ref<BalanceAllocationModel>();
            const valueEditedByUser = ref(false);

            const initialSum = sumAllocations();

            const calculator = new Calculator(
                toRef(props, 'source'),
                toRef(props, 'staff'),
                toRef(props, 'balanceType')
            );

            const initAllocation = function () {
                const match = props.balanceType
                    .balanceAllocations()
                    .value()
                    .filter(model => {
                        return (
                            model.attributes.source_id === props.source.getKey() &&
                            model.attributes.source_type === props.source.getType()
                        );
                    })
                    .first();

                if (match) {
                    allocation.value = match;
                } else {
                    allocation.value = new BalanceAllocationModel();
                    allocation.value.attributes.account_id = props.balanceType.attributes.account_id;
                    allocation.value.balanceType().associate(props.balanceType);
                    allocation.value.staff().associate(props.staff);
                    allocation.value.source().associate(props.source);

                    props.balanceType.balanceAllocations().value().push(allocation.value);
                }
            };

            onMounted(() => {
                initAllocation();
            });

            const isAllocationConfirmed = computed(() => allocation.value?.attributes.confirmed_at !== null);

            const sum = computed(() => {
                return sumAllocations();
            });

            const incrementValue = function () {
                if (allocation.value) {
                    ++allocation.value.attributes.value;
                    valueEditedByUser.value = true;
                }
            };

            const decrementValue = function () {
                if (allocation.value) {
                    --allocation.value.attributes.value;
                    valueEditedByUser.value = true;
                }
            };

            const calculate = function () {
                if (allocation.value) {
                    allocation.value.attributes.value = calculator.value.value;
                }
            };

            function sumAllocations() {
                const value = props.balanceType
                    .balanceAllocations()
                    .value()
                    .filter(item => {
                        if (
                            (props.sumWithSourceAllocation && item.getKey() === allocation.value?.getKey()) ||
                            item.getKey() !== allocation.value?.getKey() ||
                            valueEditedByUser
                        ) {
                            return true;
                        }

                        return false;
                    })
                    .sum(item => item.attributes.value) as number;

                return round(value, configFile.roundValuePrecision);
            }

            return {
                allocation,
                incrementValue,
                decrementValue,
                initialSum,
                sum,
                isAllocationConfirmed,
                calculate,
            };
        },
    });
</script>

<style scoped>
    .preventBrowserStyling {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
</style>
