import {Model} from '@meekohq/lumos';

import TemplateModel from '@/modules/human-resources/models/TemplateModel';
import OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default class TemplateOrganizationPivotModel extends Model {
    public type = 'hr/calendar/template/templates_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        template_id: string | undefined;
        organization_id: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        template_id: undefined,
        organization_id: undefined,
    };

    template() {
        return this.belongsTo('template', TemplateModel, 'template_id');
    }

    organization() {
        return this.belongsTo('organization', OrganizationModel, 'organization_id');
    }
}
