<script setup lang="ts">
    import {ref} from 'vue';

    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import Properties from '@/modules/cashier/components/core/customer/CustomerHero/molecules/CustomerHeroProperties.vue';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';

    defineProps<{
        modal: ModalType;
        customerWithSameAccountNumber: CustomerModel;
    }>();

    const emit = defineEmits<{
        (e: 'confirmed' | 'dismissed'): void;
    }>();

    const loading = ref(false);

    function onConfirm() {
        loading.value = true;
        emit('confirmed');
    }
</script>

<template>
    <MModal
        hide-header-close
        :modal="modal"
        size="md"
    >
        <FontAwesomeIcon
            class="ConfirmationModal__icon tw-bg-info-100 tw-text-3xl tw-text-info-500"
            icon="fal fa-question"
        />
        <div class="tw-mt-3 tw-text-center sm:tw-mt-5">
            <h3 class="tw-font-display tw-text-xl tw-leading-6">
                {{ __('common:please_confirm') }}
            </h3>
            <div class="tw-mt-2">
                <p class="tw-text-gray-500">
                    {{ __('billing_customer:do_you_really_want_to_duplicate_account_number_ask') }}
                </p>
            </div>
        </div>
        <div class="tw-mt-4 tw-flex tw-justify-center">
            <Properties :customer-model="customerWithSameAccountNumber" />
        </div>

        <template #footer>
            <div class="tw-grid tw-w-full tw-gap-3 sm:tw-grid-cols-2">
                <MButton
                    class="tw-w-full"
                    size="lg"
                    @click="emit('dismissed')"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    class="tw-w-full"
                    :loading="loading"
                    size="lg"
                    variant="primary"
                    @click="onConfirm"
                >
                    {{ __('common:actions.confirm') }}
                </MButton>
            </div>
        </template>
    </MModal>
</template>

<style scoped>
    .ConfirmationModal__icon {
        @apply tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full;
    }
</style>
