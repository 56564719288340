import type {Ref} from 'vue';
import {computed, ref, watch} from 'vue';

import useManager from '@/modules/app/composables/useManager';
import TeamModel from '@/modules/human-resources/models/TeamModel';
import TeamStaffPivot from '@/modules/human-resources/models/TeamStaffPivot';
import type Mirror from '@/modules/legacy/helpers/mirror.helper';

export default function (staff: Ref<Mirror | null>) {
    const {organizationIds} = useManager();
    const selectedTeams = ref([]);

    // Create computed of each relation needed of staff.
    const staffTeamsRelation = computed(() => {
        return staff.value?.value.teams().setQuery(query => {
            query.with(new TeamModel().organizations(), query1 => {
                query1.whereIn('id', organizationIds.value);
            });
        });
    });

    const getExistingTeam = function (team: TeamModel) {
        return staffTeamsRelation.value.value().first(value => value.id === team.id);
    };

    const addTeam = function (team: TeamModel) {
        // Get the matching team in staff's teams relation.
        const matchingModel: TeamModel = getExistingTeam(team);

        // If already exist, reset it's pivot to not delete it on save.
        if (matchingModel) {
            matchingModel.pivot<TeamStaffPivot>().markedForDeletion = false;
            matchingModel.pivot<TeamStaffPivot>().listeners.delete = [];
        } else {
            // If don't exist, create, attach and push the team in staff's team relation.
            const pivot = new TeamStaffPivot();
            pivot.attributes.account_id = team.attributes.account_id;
            pivot.team().associate(team, false);
            pivot.staff().associate(staff.value?.value, false);

            staffTeamsRelation.value.value().push(team);
            team.setRelation('pivot', pivot);
        }
    };

    const deleteTeam = function (team: TeamModel) {
        // Get the matching team in staff's team relation.
        const matchingModel = getExistingTeam(team);

        const deleteTeamInCollection = function (teamToDelete: TeamModel) {
            // Mutate staff's team relations by deleting the team passed in parameters.
            staffTeamsRelation.value.mutate(value => value.reject(item => item.id === teamToDelete.id));
        };

        // If the pivot of the matchingModel exist, we set the pivot which will be deleted when saving.
        if (matchingModel.pivot().exist) {
            matchingModel.pivot().markForDeletion();
            // Push the callback in delete listener to execute it when the pivot will be deleted.
            matchingModel.pivot().on('delete', () => deleteTeamInCollection(matchingModel));
        } else {
            deleteTeamInCollection(matchingModel);
        }
    };

    watch(
        () => staff.value?.value,
        async value => {
            if (value && value.exist) {
                // Get staff's team relation values if staff exist in database.
                await staffTeamsRelation.value.load();
                selectedTeams.value = staffTeamsRelation.value.value().all();
            }
        },
        {immediate: true}
    );

    watch(
        selectedTeams,
        (values: TeamModel[]) => {
            // Get every teams wich are not attach to staff OR every teams wich are attached with its pivot key "markedForDeletion" set to true
            const teamsToAdd = values.filter((item: TeamModel) => {
                const matchingModel = getExistingTeam(item);

                return (
                    !staffTeamsRelation.value
                        .value()
                        .all()
                        .find(team => team.id === item.id) || matchingModel.pivot().markedForDeletion
                );
            });

            // Get every teams which not selected but still attached on staff
            const teamsToDelete = staffTeamsRelation.value
                .value()
                .all()
                .filter((item: TeamModel) => {
                    return !values.find(value => value.id === item.id);
                });

            teamsToAdd.forEach(teamToAdd => addTeam(teamToAdd));
            teamsToDelete.forEach(teamToDelete => deleteTeam(teamToDelete));
        },
        {deep: true}
    );

    return {
        selectedTeams,
        staffTeamsRelation,
        addTeam,
        deleteTeam,
    };
}
