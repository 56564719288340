<template>
    <CVStack gap="4">
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CSkeletonItem class="tw-mr-5 tw-h-5 tw-w-1/6" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CSkeletonItem class="tw-mr-5 tw-h-5 tw-w-1/6" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CSkeletonItem class="tw-mr-5 tw-h-5 tw-w-1/6" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CSkeletonItem class="tw-mr-5 tw-h-5 tw-w-1/6" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
        </CHStack>
        <CHStack
            align="center"
            class="tw-w-full"
            gap="2"
        >
            <CSkeletonItem class="tw-mr-5 tw-h-5 tw-w-1/6" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
            <CSkeletonItem class="tw-h-5 tw-flex-1 tw-rounded-full" />
        </CHStack>
    </CVStack>
</template>
