<script setup lang="ts">
    import type {Placement} from '@floating-ui/vue';
    import {onClickOutside, useElementHover} from '@vueuse/core';
    import {computed, inject, type PropType, ref, watch} from 'vue';

    import MFloating from '@/modules/meeko-ui/components/MFloating.vue';
    import type {defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    const context = inject<ReturnType<typeof defineFloatingContext>>('MFloatingContext');

    if (!context) {
        throw new Error('MPopoverPanel must be used inside a MPopover');
    }

    const props = defineProps({
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start',
            validator: (value: string) =>
                [
                    'top',
                    'top-start',
                    'top-end',
                    'right',
                    'right-start',
                    'right-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'left',
                    'left-start',
                    'left-end',
                ].includes(value),
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
        noArrow: {
            type: Boolean,
            default: false,
        },
        // Remove on Vue 3
        panelClass: {},
    });

    const {
        panelId,
        isVisible,
        trigger,
        setHovered,
        setPanelElement,
        setArrowElement,
        closeOnEscape,
        closePopover,
        middlewareData,
        buttonEl,
        finalPlacement,
        isReady,
        placement: contextPlacement,
        childrens,
    } = context;

    const myPanel = ref();
    const myArrow = ref();

    const emit = defineEmits(['clickOutside', 'opened', 'closed']);

    contextPlacement.value = props.placement;
    setPanelElement(myPanel);
    setArrowElement(myArrow);

    if (trigger.value === 'hover') {
        const isHovered = useElementHover(myPanel);
        watch(isHovered, setHovered);
    }

    function onEsc() {
        if (closeOnEscape.value) {
            closePopover(true);
        }
    }

    const calculatedAttrs = computed(() => {
        if (trigger.value === 'click') {
            return {
                'role': 'dialog',
                'aria-modal': 'false',
            };
        }

        if (trigger.value === 'hover') {
            return {
                role: 'tooltip',
            };
        }

        return {};
    });

    watch(isVisible, (newIsVisible, oldIsVisible) => {
        if (!newIsVisible && oldIsVisible) {
            emit('closed');
        } else {
            emit('opened');
        }
    });

    const ignored = computed(() => [buttonEl, ...childrens.value]);

    onClickOutside(
        myPanel,
        () => {
            closePopover();
            emit('clickOutside');
        },
        {ignore: ignored}
    );
</script>

<template>
    <MFloating>
        <section
            v-bind="calculatedAttrs"
            :id="panelId"
            ref="myPanel"
            :aria-hidden="!isVisible"
            class="MPopoverPanel"
            :class="panelClass"
            tabindex="-1"
            @keydown.esc.stop="onEsc"
        >
            <div
                v-show="!noArrow"
                ref="myArrow"
                class="MPopoverPanel__arrow"
                :class="['MPopoverPanel__arrow--' + finalPlacement, {'MPopoverPanel__arrow--animated': isReady}]"
                :style="{
                    left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
                    top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                }"
            >
                <svg
                    height="9"
                    role="presentation"
                    width="21"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <path
                            d="M1 9.092h19l-6.402-6.74c-1.717-1.806-4.485-1.8-6.196 0L1 9.093zM20.342 8l-6.02-6.336c-2.108-2.22-5.538-2.218-7.645 0L.658 8h19.684z"
                            fill="#000000"
                            fill-opacity="0.1"
                        />
                        <path
                            d="M7.402 2.353c1.711-1.801 4.48-1.807 6.196 0L20 9.093H1l6.402-6.74z"
                            fill="currentcolor"
                        />
                    </g>
                </svg>
            </div>
            <div
                :class="{
                    'MPopoverPanel__content': !noPadding,
                    'MPopoverPanel__content--no-padding': noPadding,
                }"
            >
                <slot :close="closePopover" />
            </div>
        </section>
    </MFloating>
</template>

<style scoped>
    .MPopoverPanel {
        @apply focus-visible:tw-outline-0;
        @apply tw-rounded-xl tw-bg-white tw-shadow-md tw-ring-1 tw-ring-black/10;
    }

    .MPopoverPanel__content {
        @apply tw-p-2;
    }

    .MPopoverPanel__content--no-padding {
        @apply tw-p-0;
    }

    .MPopoverPanel__arrow {
        width: 21px;
        height: 21px;
        @apply tw-absolute tw-text-white;
    }

    .MPopoverPanel__arrow--animated {
        transition: all 0.65s cubic-bezier(0.43, 0.33, 0.14, 1.01) 0s;
        transition-property: top, right, bottom, left;
    }

    .MPopoverPanel__arrow--top,
    .MPopoverPanel__arrow--top-start,
    .MPopoverPanel__arrow--top-end {
        bottom: -8px;
        @apply tw--rotate-180;
    }

    .MPopoverPanel__arrow--right,
    .MPopoverPanel__arrow--right-start,
    .MPopoverPanel__arrow--right-end {
        left: -8px;
        @apply tw--rotate-90;
    }

    .MPopoverPanel__arrow--bottom,
    .MPopoverPanel__arrow--bottom-start,
    .MPopoverPanel__arrow--bottom-end {
        top: -8px;
    }

    .MPopoverPanel__arrow--left,
    .MPopoverPanel__arrow--left-start,
    .MPopoverPanel__arrow--left-end {
        right: -8px;
        @apply tw-rotate-90;
    }
</style>
