<template>
    <CModal
        :header-title="__('family_family:merge_family')"
        :modal="modal"
        size="3xl"
    >
        <div class="tw-flex">
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:family_to_be_deleted_colon')"
                />
                <div
                    v-if="familyModelToDelete"
                    class="tw-flex tw-h-20 tw-flex-wrap tw-items-center tw-rounded tw-border tw-border-gray-300 tw-bg-gray-50 tw-p-3"
                >
                    <div class="tw-w-full tw-truncate tw-text-2xl">
                        {{ familyModelToDelete.attributes.name }}
                    </div>
                </div>
            </div>
            <div class="tw-mt-5 tw-flex tw-w-2/12 tw-flex-wrap tw-justify-center">
                <FontAwesomeIcon
                    class="tw-self-center tw-text-5xl tw-text-gray-600"
                    icon="fa-duotone fa-angle-double-right"
                />
                <a
                    v-if="familyModelToKeep"
                    class="tw-block tw-w-full tw-cursor-pointer tw-self-end tw-text-center tw-text-sm !tw-text-blue-500"
                    @click="switchFamilies"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-solid fa-retweet"
                    />
                    {{ __('common:actions.reverse') }}
                </a>
            </div>
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:family_to_be_retained_colon')"
                />
                <div
                    class="tw-flex tw-h-20 tw-flex-wrap tw-items-center tw-rounded tw-border tw-border-gray-300 tw-p-3 tw-text-orange-600"
                >
                    <SelectFamily
                        v-model="familyModelToKeep"
                        :extend-builder="familyBuilder"
                        :organization-model="activeOrganization"
                    />
                </div>
            </div>
        </div>

        <hr
            v-if="customersModelToDelete.length"
            class="hr tw-mb-3 tw-mt-6"
        />

        <div
            v-if="!shouldNotMergeCustomers && customersModelToDelete.length && customersModelToKeep.length > 1"
            class="tw-flex"
        >
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:customer_accounts_to_be_merged_colon')"
                />
                <div
                    class="tw-flex tw-min-h-20 tw-flex-wrap tw-items-center tw-rounded tw-border tw-border-gray-300 tw-bg-gray-50 tw-p-3"
                >
                    <div
                        v-for="(customerModelToDelete, i) in customersModelToDelete"
                        :key="'customerDelete' + i"
                        class="tw-w-full"
                        :class="{'tw-mt-2 tw-border-t tw-border-gray-200 tw-pt-2': i > 0}"
                    >
                        <div class="tw-w-full tw-text-lg">
                            {{ customerModelToDelete.attributes.name }}
                        </div>
                        <div
                            v-if="customerModelToDelete.attributes.account_number"
                            class="tw-text-sm tw-text-gray-600"
                        >
                            {{
                                __('family_family:number_short_with_number', {
                                    number: customerModelToDelete.attributes.account_number,
                                })
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-mt-5 tw-flex tw-w-2/12 tw-flex-wrap tw-justify-center">
                <FontAwesomeIcon
                    class="tw-self-center tw-text-5xl tw-text-gray-600"
                    icon="fa-duotone fa-angle-double-right"
                />
            </div>
            <div class="tw-w-5/12">
                <div
                    class="tw-mb-2"
                    v-html="__('family_family:customer_accountto_retrieve_data_colon')"
                />
                <div
                    class="tw-flex tw-h-20 tw-flex-wrap tw-items-center tw-rounded tw-border tw-border-gray-300 tw-p-3 tw-text-orange-600"
                >
                    <SelectCustomer
                        v-model="customerModelToKeep"
                        :create-option-enabled="false"
                        :extend-builder="customerBuilder"
                        :tenant-model="activeTenant"
                        :with-legal="false"
                    />
                </div>
            </div>
        </div>

        <MLabel class="tw-mt-2">
            <CCheckbox v-model="shouldNotMergeCustomers">
                {{ __('family_family:do_not_merge_billing_accounts_checkbox') }}
                <MHelpTooltip class="tw-ml-2">
                    {{ __('family_family:accounts_receivable_will_still_be_sent_to_the_family_to_be_kept') }}
                </MHelpTooltip>
            </CCheckbox>
        </MLabel>

        <div class="tw-mt-8 tw-rounded tw-bg-gray-50 tw-p-2 tw-text-sm">
            <div>{{ __('family_family:the_following_data_will_be_sent_to_the_retained_family_colon') }}</div>
            <ul
                class="tw-mb-0 tw-mt-2"
                v-html="__('family_family:children_records_parents_records_associated_customer_accounts')"
            />
        </div>

        <div
            class="tw-mt-3"
            v-html="__('common:irreversible_action_warning')"
        />

        <template #footer>
            <MButton
                class="tw-ml-auto"
                :loading="isLoading"
                @click="modal.hide"
            >
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :disabled="!familyModelToKeep"
                :loading="isLoading"
                variant="primary"
                @click="mergeFamilies()"
            >
                {{ __('common:actions.merge') }}
            </MButton>
        </template>
    </CModal>
</template>

<script setup lang="ts">
    import {JsonAPISchema} from '@meekohq/lumos';
    import {computed, type PropType, ref, watch} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import useManager from '@/modules/app/composables/useManager';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import SelectCustomer from '@/modules/cashier/components/core/customer/organisms/SelectCustomer.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import SelectFamily from '@/modules/family/components/family/SelectFamily.vue';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import route from '@/modules/legacy/libs/ziggy';

    const {familyModel, modal} = defineProps({
        modal: {type: Object as PropType<ModalType>, required: true},
        familyModel: {
            type: Object as PropType<FamilyModel>,
            required: true,
        },
    });
    const emit = defineEmits(['merged']);

    const {activeOrganization, activeTenant} = useManager();
    const isLoading = ref(false);

    const familyModelToKeep = ref<FamilyModel | null>(null);
    const familyModelToDelete = ref<FamilyModel | null>(familyModel);
    const shouldNotMergeCustomers = ref(false);
    const customersModelToKeep = ref<CustomerModel[]>([]);
    const customersModelToDelete = ref<CustomerModel[]>([]);
    const customerModelToKeep = ref<CustomerModel | null>(null);

    const familyBuilder = computed(() => {
        if (familyModelToDelete.value) {
            return FamilyModel.query().where('id', '<>', familyModelToDelete.value.getKey());
        }

        return FamilyModel.query();
    });

    const customerBuilder = computed(() => {
        if (familyModelToKeep.value) {
            return CustomerModel.query().whereHas(new CustomerModel().families(), query => {
                query.where('id', familyModelToKeep.value?.getKey());
            });
        }

        return CustomerModel.query();
    });

    watch(familyModelToDelete, value => {
        isLoading.value = true;

        CustomerModel.query()
            .whereHas(new CustomerModel().families(), query => {
                query.where('id', value?.getKey());
            })
            .get()
            .then(response => {
                customersModelToDelete.value = response.all();
            })
            .finally(() => {
                isLoading.value = false;
            });
    });

    watch(familyModelToKeep, value => {
        customersModelToKeep.value = [];
        if (value) {
            isLoading.value = true;

            CustomerModel.query()
                .whereHas(new CustomerModel().families(), query => {
                    query.where('id', value.getKey());
                })
                .get()
                .then(response => {
                    customersModelToKeep.value = response.all();
                    customerModelToKeep.value = customersModelToKeep.value[0];
                })
                .finally(() => {
                    isLoading.value = false;
                });
        }
    });

    function mergeFamilies() {
        let customerDestinationId = customerModelToKeep.value ? customerModelToKeep.value.getKey() : null;

        if (shouldNotMergeCustomers.value) {
            customerDestinationId = null;
        }

        isLoading.value = true;
        useApi()
            .legacy.post(
                route('family/families.merge'),
                new JsonAPISchema.JsonApiDocument(
                    new JsonAPISchema.JsonApiData({
                        family_source_id: familyModelToDelete.value?.getKey(),
                        family_destination_id: familyModelToKeep.value?.getKey(),
                        customer_destination_id: customerDestinationId,
                    })
                )
            )
            .then(() => {
                emit('merged', familyModelToKeep.value);
                modal?.hide();
            })
            .finally(() => {
                isLoading.value = false;
            });
    }

    function switchFamilies() {
        const familyModelToKeepClone = familyModelToKeep.value?.clone() as FamilyModel;
        const familyModelToDeleteClone = familyModelToDelete.value?.clone() as FamilyModel;
        familyModelToDelete.value = familyModelToKeepClone;
        familyModelToKeep.value = familyModelToDeleteClone;
    }
</script>
