import {app} from '@meekohq/lumos';
import {computed, ref} from 'vue';

import {JobListContractBinding} from '@/modules/human-resources/utils/job/JobListContract';

export default function useJobList() {
    const jobList = ref(app(JobListContractBinding));

    return {
        jobs: computed(() => jobList.value.jobs),
        loadJobs: () => jobList.value.loadJobs(),
        clearJobs: () => jobList.value.clear(),
    };
}
