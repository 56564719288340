<template>
    <div
        class="card-chart"
        :class="{settings: showSettings}"
    >
        <div class="chart-title">
            <div class="tw-mb-4 tw-ml-1 tw-flex tw-flex-row">
                <div class="tw-flex tw-items-center tw-text-base tw-font-semibold tw-uppercase">
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-child-reaching"
                    />
                    {{ __('common:kid_other') }}
                </div>
                <div
                    class="tw-ml-auto tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-text-sm tw-font-semibold tw-uppercase"
                    @click="showSettings = !showSettings"
                >
                    {{ humanizedSelectedDate }}
                    <FontAwesomeIcon
                        class="tw-ml-2"
                        icon="fa-solid fa-pencil"
                    />
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                    <MMenu class="tw-w-full">
                        <MMenuButton class="tw-w-full">
                            <MButton
                                class="tw-w-full"
                                icon-menu
                                :label="selectedGroup.name"
                            />
                        </MMenuButton>
                        <MMenuItems>
                            <MMenuItem @click="selectedGroup = {id: null, name: __('common:all_groups')}">
                                {{ __('common:all_groups') }}
                            </MMenuItem>
                            <MMenuDivider />
                            <MMenuItem
                                v-for="(group, i) in selectedNursery.groups"
                                :key="'nursery' + i"
                                @click="selectedGroup = group"
                            >
                                {{ group.name }}
                            </MMenuItem>
                        </MMenuItems>
                    </MMenu>
                    <MDatePicker
                        ref="datePickerRef"
                        v-model="selectedDate"
                        class="tw-w-full"
                        format="yyyy-MM-dd"
                    />
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container tw-px-1">
                <loader
                    v-if="loading"
                    light="true"
                    shadow="false"
                    size="sm"
                />
                <div
                    v-else-if="filteredKids.length"
                    class="KidPresences__list"
                >
                    <div
                        v-for="(kid, index) in filteredKids"
                        :key="'kid' + index"
                        class="KidPresences__item"
                    >
                        <div class="KidPresences__header">
                            <LegacyKidAvatar
                                class="KidPresences__avatar"
                                :kid="kid"
                            />
                            <div
                                class="KidPresences__name"
                                :class="kid.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                            >
                                <router-link
                                    class="tw-text-inherit"
                                    :to="{name: 'kids.show', params: {nursery: nursery.id, kid: kid.id}}"
                                >
                                    {{ $fullName(kid) }}
                                </router-link>
                            </div>
                        </div>
                        <div class="KidPresences__container">
                            <div class="KidPresences__sub-container">
                                <MTooltip
                                    v-for="(occasional, i) in kid.occasionals"
                                    :key="'occasional' + i"
                                    class="tw-shrink-0"
                                    :label="occasional.note ? occasional.note : __('common:occasional')"
                                >
                                    <CBadge variant="primary">
                                        {{ $formatDate(occasional.started_at, 'HH:mm') }} -
                                        {{ $formatDate(occasional.ended_at, 'HH:mm') }}
                                    </CBadge>
                                </MTooltip>
                                <MTooltip
                                    v-for="(adaptation, i) in kid.adaptations"
                                    :key="'adaptation' + i"
                                    class="tw-shrink-0"
                                    :label="adaptation.note ? adaptation.note : __('common:adaptation')"
                                >
                                    <CBadge variant="warning">
                                        {{ $formatDate(adaptation.started_at, 'HH:mm') }} -
                                        {{ $formatDate(adaptation.ended_at, 'HH:mm') }}
                                    </CBadge>
                                </MTooltip>
                                <MTooltip
                                    v-for="(absence, i) in kid.absences"
                                    :key="'absence' + i"
                                    class="tw-shrink-0"
                                    :label="absence.note ? absence.note : __('common:absence')"
                                >
                                    <CBadge variant="danger">
                                        {{ $formatDate(absence.started_at, 'HH:mm') }} -
                                        {{ $formatDate(absence.ended_at, 'HH:mm') }}
                                    </CBadge>
                                </MTooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="tw-p-2 tw-text-center"
                >
                    {{ __('dashboard:kids_no_warning') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import {isNil, set} from 'lodash-es';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';

    import LegacyKidAvatar from '@/modules/app/components/atoms/avatars/LegacyKidAvatar.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import __ from '@/modules/app/utils/i18n-facade';
    import {getPlanningEventsFromKidContracts} from '@/modules/legacy/libs/Planning.ts';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {LegacyKidAvatar},
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            kids: [],
            date: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedDate: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedNursery: {},
            selectedGroup: {},
            loading: false,
            showSettings: false,
        }),
        computed: {
            epochSelectedDate() {
                return this.selectedDate ? Epoch.parse(this.selectedDate, 'yyyy-MM-dd') : Epoch.now();
            },
            humanizedSelectedDate() {
                return this.epochSelectedDate.toLocaleString(Epoch.presets.WEEKDAY_WITH_DAY_AND_MONTH);
            },
            from() {
                return this.epochSelectedDate.startOfDay();
            },
            to() {
                return this.epochSelectedDate.endOfDay();
            },
            filteredKids() {
                return this.kids.filter(item => item.hasAnomalies);
            },
        },
        watch: {
            'selectedDate'(val) {
                if (isNil(val)) {
                    return;
                }
                this.showSettings = false;
                this.$refs.datePickerRef.hide();
                this.date = val;
                this.getPresences();
            },
            'selectedNursery.id': {
                handler() {
                    if (this.selectedNursery && this.selectedNursery.id) {
                        this.getPresences();
                    }
                },
                deep: true,
            },
            'nursery.id': {
                handler() {
                    this.selectedNursery = this.nursery;
                },
                deep: true,
            },
            'selectedGroup.id': {
                handler() {
                    this.getPresences();
                },
            },
        },
        mounted() {
            useBroadcast().sessionChannel.bind('kid_plannings', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    this.kids = response.data;

                    this.kids.forEach(kid => {
                        set(kid, 'adaptations', []);
                        set(kid, 'occasionals', []);
                        const plannings = getPlanningEventsFromKidContracts(
                            kid.contracts,
                            moment(this.from.toJSDate())
                        );
                        plannings.forEach(item => {
                            if (item.type === 'planning-adaptation') {
                                kid.adaptations.push(item);
                            } else if (item.type === 'planning-occasional') {
                                kid.occasionals.push(item);
                            }
                        });
                        const hasAnomalies =
                            kid.occasionals.length > 0 || kid.adaptations.length > 0 || kid.absences.length > 0;
                        set(kid, 'hasAnomalies', hasAnomalies);
                    });
                    this.loading = false;
                });
            });

            this.selectedNursery = this.nursery;
            this.switchGroup();
        },
        beforeUnmount() {
            useBroadcast().sessionChannel.unbind('kid_plannings');
        },
        methods: {
            getPresences() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                useApi()
                    .legacy.get(route('nurseries.planning.kids', {nurseries: this.nursery.id}), {
                        params: {
                            from: this.from.toTimestamp(),
                            to: this.to.toTimestamp(),
                            group_id: this.selectedGroup.id ? this.selectedGroup.id : null,
                        },
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            switchGroup(group) {
                if (!group) {
                    this.selectedGroup = {
                        id: null,
                        name: __('common:all_groups'),
                    };
                } else {
                    this.selectedGroup = group;
                }

                if (this.selectedNursery && this.selectedNursery.id) {
                    this.getPresences();
                }
            },
        },
    };
</script>

<style scoped>
    .KidPresences__list {
        @apply tw-my-4 tw-flex tw-flex-col tw-gap-4;
    }

    .KidPresences__item {
        @apply tw-grid tw-grid-cols-2 tw-gap-4;
    }

    .KidPresences__header {
        @apply tw-flex tw-items-center tw-gap-2;
    }

    .KidPresences__avatar {
        @apply tw-aspect-square tw-w-8 tw-shrink-0;
    }

    .KidPresences__name {
        @apply tw-truncate tw-font-grandhotel tw-text-2xl tw-capitalize;
    }

    .KidPresences__container {
        @apply tw-flex tw-items-center;
    }

    .KidPresences__sub-container {
        @apply tw-ml-auto tw-flex tw-w-fit tw-items-center tw-gap-2 tw-overflow-x-auto;
    }
</style>
