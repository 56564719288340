<template>
    <svg
        viewBox="0 0 48 48"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M30 10 18 6 6 10v32l12-4 12 4 12-4V6z"
            fill="#FFECB3"
        />
        <path
            d="M18 6v32l12 4V10z"
            fill="#FFE082"
        />
        <path
            d="M30 15c-3.87 0-7 3.3-7 7.35S30 36 30 36s7-9.6 7-13.65S33.87 15 30 15z"
            fill="#F44336"
        />
        <circle
            cx="30"
            cy="22"
            fill="#FFEBEE"
            r="3"
        />
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
