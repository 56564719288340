<template>
    <MBox class="tw-p-4">
        <apexcharts
            v-if="chartOptions && series"
            ref="barslim"
            :options="chartOptions"
            :series="series"
            type="bar"
        />
    </MBox>
</template>

<script lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import _round from 'lodash-es/round';
    import type {PropType} from 'vue';
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import VueApexCharts from 'vue3-apexcharts';

    import __ from '@/modules/app/utils/i18n-facade';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import CalculateEventTime from '@/modules/human-resources/utils/statistics/CalculateEventTime';
    import {minutesToHours} from '@/modules/legacy/libs/planning/planning';

    export default defineComponent({
        components: {
            apexcharts: VueApexCharts,
        },
        props: {
            events: {
                type: Object as PropType<Collection<EventModel>>,
                required: true,
            },
        },
        setup(props) {
            const barslim = ref();
            const chartOptions = ref();
            onMounted(() => {
                updateChart();
            });

            const eventTypes = computed(() => {
                return props.events.map(event => event.eventType().value()).unique(item => item.getKey());
            });
            watch(
                () => eventTypes.value,
                () => {
                    updateChart();
                }
            );

            const series = computed(() => {
                const arr: any[] = [];

                eventTypes.value.all().forEach(eventType => {
                    const {real, forecast} = new CalculateEventTime(props.events.all() || []).getEventsTimeByType(
                        eventType.id
                    );

                    arr.push({
                        name: eventType.attributes.name,
                        data: [forecast / 60, real / 60],
                        hidden: false,
                    });
                });

                return arr;
            });

            function updateChart() {
                chartOptions.value = {
                    chart: {
                        stacked: true,
                        defaultLocale: 'fr',
                        locales: [
                            {
                                name: 'fr',
                                options: {
                                    toolbar: {
                                        exportToSVG: __('common:exports.export_to_svg'),
                                        exportToPNG: __('common:exports.export_to_png'),
                                        exportToCSV: __('common:exports.export_to_csv'),
                                        download: __('common:actions.download'),
                                        selection: __('common:selection'),
                                        selectionZoom: __('common:chart.zoom.selection'),
                                        zoomIn: __('common:chart.zoom.in'),
                                        zoomOut: __('common:chart.zoom.out'),
                                        pan: __('common:chart.pan'),
                                        reset: __('common:chart.zoom.reset'),
                                    },
                                },
                            },
                        ],
                        toolbar: {
                            show: true,
                            tools: {
                                download: true,
                            },
                            export: {
                                csv: {
                                    filename: __('hr:staff_statistics_filename'),
                                    columnDelimiter: ',',
                                    headerCategory: __('hr:type_other'),
                                    headerValue: __('hr:type_other'),
                                },
                                svg: {
                                    filename: __('hr:staff_statistics_filename'),
                                },
                                png: {
                                    filename: __('hr:staff_statistics_filename'),
                                },
                            },
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            borderRadius: 8,
                            barHeight: '60%',
                        },
                    },
                    colors: eventTypes.value.all().map(item => item.attributes.color),
                    xaxis: {
                        categories: [__('common:forecasted_male_one'), __('common:real_one')],
                        crosshairs: {
                            show: false,
                            wdith: 'tickWidth',
                        },
                        labels: {
                            formatter(value) {
                                return formatValue(value);
                            },
                            style: {
                                colors: '#777',
                            },
                        },
                    },
                    yaxis: {
                        tickAmount: 4,
                        crosshairs: {
                            show: false,
                            wdith: 'tickWidth',
                        },
                        labels: {
                            style: {
                                color: '#777',
                            },
                        },
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'left',
                        fontSize: '12px',
                        labels: {
                            color: '#777',
                        },
                        offsetY: 5,
                        offsetX: 20,
                    },
                    dataLabels: {
                        enabled: false,
                        formatter(value) {
                            return formatValue(value);
                        },
                        style: {
                            fontSize: '12px',
                            colors: ['#FFF'],
                        },
                    },
                    tooltip: {
                        shared: true,
                        followCursor: true,
                        intersect: false,
                        y: {
                            formatter(value) {
                                return formatValue(value);
                            },
                        },
                    },
                    grid: {
                        borderColor: '#EEE',
                    },
                };
            }

            function formatValue(value: number) {
                if (value === null || typeof value === 'undefined') {
                    return '';
                }

                return minutesToHours(_round(value * 60, 2));
            }

            return {
                barslim,
                chartOptions,
                series,
            };
        },
    });
</script>

<style>
    .apexcharts-legend-text {
        color: #777 !important;
        font-size: 12px !important;
    }
</style>
