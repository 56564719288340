import moment from 'moment';

export function formatDate(timestamp, format?: string) {
    !Number.isInteger(timestamp) ? (timestamp = moment(timestamp).unix()) : null;
    const isValid = moment.unix(timestamp).isValid();

    const date = moment.unix(timestamp);
    format = format ? format : 'DD/MM/YYYY';
    const time = date.format(format);

    const invalidTime = format === 'HH:mm' ? '-:-' : '--/--/----';

    return isValid ? time : invalidTime;
}

export function capitalize(str) {
    const lower = str ? str.toLowerCase() : '';

    return lower.replace(/(^|[\s-])\S/g, match => {
        return match.toUpperCase();
    });
}

export function refToDate(ref) {
    return ref
        ? ref
              .replace(/YYYY/i, moment().format('YYYY'))
              .replace(/YY/i, moment().format('YY'))
              .replace(/MM/i, moment().format('MM'))
        : ref;
}

export function fullName(item) {
    const last_name = item.last_name ? capitalizeString(item.last_name) : '';
    const first_name = item.first_name ? capitalizeString(item.first_name) : '';

    if (window.localStorage.getItem('display:orderBy') === 'last_name') {
        return last_name + ' ' + first_name.charAt(0).toUpperCase() + first_name.slice(1);
    }

    return first_name + ' ' + last_name.charAt(0).toUpperCase() + last_name.slice(1);
}

export function valueFromOptions(key, options) {
    const obj = options.find(item => item.value === key);

    return obj ? obj.text : null;
}

function capitalizeString(str) {
    str = str.toLowerCase();
    str = str.split(' ');
    for (let i = 0, x = str.length; i < x; i++) {
        if (str[i] && str[i][0]) {
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }
    }

    return str.join(' ');
}
