import {computed, ref} from 'vue';

export function useLegacyModalBus() {
    const modal = ref();

    const show = function () {
        modal.value?.show();
    };

    const hide = function (args: any = undefined) {
        modal.value?.hide(args);
    };

    const isVisible = computed(() => modal.value?.isModalVisible);

    return {
        modal,
        show,
        hide,
        isVisible,
    };
}
