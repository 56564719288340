import {Model} from '@meekohq/lumos';

export default class KidOrganizationPivot extends Model {
    public type = 'family/kids_organizations_pivot';

    public attributes: {
        id: string;
        account_id: string | undefined;
        kid_id: string | undefined;
        organization_id: string | undefined;
    } = {
        id: this.uuid(),
        account_id: undefined,
        kid_id: undefined,
        organization_id: undefined,
    };
}
