import type {ModelCollection} from '@meekohq/lumos';

import type PaymentModel from '@/modules/cashier/models/PaymentModel';
import type {GetAllocationsForCreditNoteUseCase} from '@/modules/cashier/payment/application/GetAllocationsForCreditNoteUseCase';
import type {CreditNoteAllocationAggregatePort} from '@/modules/cashier/payment/application/ports/CreditNoteAllocationAggregatePort';
import type {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';
import type {TransactionDebitAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';

export class GetAllocationsForCreditNoteInteractor implements GetAllocationsForCreditNoteUseCase {
    private readonly _creditNoteAllocationsAggregateRepository: CreditNoteAllocationAggregatePort;

    constructor(creditNoteAllocationsAggregateRepository: CreditNoteAllocationAggregatePort) {
        this._creditNoteAllocationsAggregateRepository = creditNoteAllocationsAggregateRepository;
    }

    public async fetch(
        creditNoteId: string,
        payments: ModelCollection<PaymentModel>
    ): Promise<(InvoiceAllocationAggregate | TransactionDebitAllocationAggregate)[]> {
        return this._creditNoteAllocationsAggregateRepository.getAllocations(creditNoteId, payments);
    }
}
