import {Epoch} from '@meekohq/lumos';

import type {SerializerType} from '@/modules/app/composables/useMagicFilter';
import type {PeriodFilterType} from '@/modules/legacy/components/Filter/PeriodFilterType';

function toNative(value: any) {
    if (value === 'null') {
        return null;
    }

    return value;
}

export default {
    read: async (value: string) => {
        const [from, to] = value.split(',');

        return {
            from: toNative(from) ? Epoch.parse(toNative(from), 'yyMMdd').startOfDay().toISOString() : toNative(from),
            to: toNative(to) ? Epoch.parse(toNative(to), 'yyMMdd').endOfDay().toISOString() : toNative(to),
        };
    },
    write: (value: PeriodFilterType) => {
        const fromValue = value.from ? Epoch.fromISOString(value.from).toFormat('yyMMdd') : null;
        const toValue = value.to ? Epoch.fromISOString(value.to).toFormat('yyMMdd') : null;

        return fromValue + ',' + toValue;
    },
} as SerializerType;
