<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="settings">
                {{ __('user:my_profile_settings') }}
            </MPageTitle>
        </template>
        <template #content>
            <CTabMenu
                class="tw-mb-4"
                variant="settings"
            >
                <CTabMenuItem
                    :active="selectedTab === 'profile'"
                    variant="settings"
                    @click="selectTab('profile')"
                >
                    {{ __('user:my_profile') }}
                </CTabMenuItem>
                <CTabMenuItem
                    :active="selectedTab === 'notifications'"
                    variant="settings"
                    @click="selectTab('notifications')"
                >
                    {{ __('app:manage_notifications') }}
                </CTabMenuItem>
                <CTabMenuItem
                    v-if="user.attributes.is_owner"
                    :active="selectedTab === 'pat'"
                    variant="settings"
                    @click="selectTab('pat')"
                >
                    {{ __('user:login_keys') }}
                </CTabMenuItem>
            </CTabMenu>
            <ProfileTab v-if="selectedTab === 'profile'" />
            <NotificationsTab v-else-if="selectedTab === 'notifications'" />
            <PersonalAccessTokens v-else-if="selectedTab === 'pat'" />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';
    import {useRoute} from 'vue-router';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useAuth from '@/modules/app/composables/useAuth';
    import useUrl from '@/modules/app/composables/useUrl';
    import PersonalAccessTokens from '@/modules/auth/personal-access-token/infrastructure/components/PersonalAccessTokens.vue';
    import NotificationsTab from '@/modules/user/components/NotificationsTab.vue';
    import ProfileTab from '@/modules/user/components/ProfileTab.vue';

    export default defineComponent({
        components: {
            PersonalAccessTokens,
            ProfileTab,
            SimplePageTemplate,
            NotificationsTab,
        },
        setup() {
            const user = useAuth().user;
            const route = useRoute();
            const selectedTab = ref(route.query.nav ?? 'profile');
            const {pushQueryString} = useUrl();

            function selectTab(tab: string) {
                selectedTab.value = tab;
                pushQueryString('nav', tab);
            }

            return {
                user,
                selectedTab,
                selectTab,
            };
        },
    });
</script>
