<template>
    <MBox class="tw-p-4">
        <MHeading
            class="tw-mb-2"
            level="h4"
        >
            <FontAwesomeIcon
                class="tw-mr-2"
                icon="fa-duotone fa-calendar-day"
            />
            {{ __('hr:details_by_day') }}
        </MHeading>
        <MScrollLoading
            :distance="0.5"
            :end-of-search="endOfSearch"
            :loading="loading"
            @load-more="loadMoreDays"
        >
            <div
                v-for="(day, i) in displayedDays"
                :key="'day' + i"
                class="tw-mb-3"
            >
                <div class="tw-flex tw-items-center tw-rounded-lg tw-bg-gray-50 tw-p-2">
                    <div>
                        <div
                            class="tw-flex tw-h-14 tw-w-14 tw-flex-wrap tw-items-center tw-rounded-lg tw-bg-white tw-py-2 tw-text-center tw-shadow-md"
                        >
                            <div class="tw-w-full tw-text-sm tw-uppercase tw-tracking-wider tw-text-gray-400">
                                {{ day.toFormat('EEE') }}
                            </div>
                            <div class="tw-w-full tw-text-sm tw-font-semibold tw-text-gray-500">
                                {{ day.toLocaleString(Epoch.presets.DATE_2_DIGIT) }}
                            </div>
                        </div>
                    </div>
                    <div class="tw-pl-5">
                        <div
                            v-for="(event, e) in eventsForDay(day)"
                            :key="'event' + e"
                        >
                            <EventBadge
                                class="tw-my-2"
                                editable
                                :event="event"
                                size="sm"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MScrollLoading>
    </MBox>
</template>

<script setup lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {Epoch} from '@meekohq/lumos';
    import {computed, onMounted, ref} from 'vue';

    import EventBadge from '@/modules/human-resources/components/calendar/EventBadge.vue';
    import type EventModel from '@/modules/human-resources/models/EventModel';
    import {epochDaysBetweenDates} from '@/modules/legacy/libs/planning/planning';
    import MScrollLoading from '@/modules/meeko-ui/components/MScrollLoading.vue';

    const props = defineProps<{events: Collection<EventModel>; from?: string; to?: string}>();

    const displayedDays = ref<Epoch[]>([]);
    const days = epochDaysBetweenDates(props.from, props.to);
    const loading = ref(false);

    const endOfSearch = computed(() => displayedDays.value.length === days.length);

    const loadMoreDays = () => {
        loading.value = true;
        const newDays = days.slice(displayedDays.value.length, displayedDays.value.length + 50);
        displayedDays.value = [...displayedDays.value, ...newDays];
        loading.value = false;
    };

    onMounted(() => {
        loadMoreDays();
    });

    // Preprocess the events to group them by date
    const preprocessEvents = (events: Collection<EventModel>) => {
        const eventsByDate: Record<string, EventModel[]> = {};
        events.each(event => {
            let start = Epoch.fromMoment(event.startedAt);
            const end = Epoch.fromMoment(event.endedAt);

            for (start; end.difference(start, 'days').days <= 0; start = start.addDays(1)) {
                const dateStr = start.toFormat('yyyy-MM-dd');
                if (!eventsByDate[dateStr]) {
                    eventsByDate[dateStr] = [];
                }
                eventsByDate[dateStr].push(event);
            }
        });

        return eventsByDate;
    };

    const preprocessedEvents = preprocessEvents(props.events);

    const eventsForDay = (day: Epoch) => preprocessedEvents[day.toFormat('yyyy-MM-dd')] || [];
</script>
