<template>
    <span>
        <MButton
            class="tw-w-full"
            :disabled="!can('update', 'registrations')"
            icon-plus
            variant="primary"
            :zoom="false"
            @click="showModal = true"
        >
            {{ __('planning:add_list') }}
        </MButton>
        <CreateSimulationListModal
            v-if="showModal"
            @created="$emit('created', $event)"
            @hide="showModal = !showModal"
        />
    </span>
</template>

<script lang="ts">
    import {defineComponent, ref} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import CreateSimulationListModal from '@/modules/planning/components/simulation/List/CreateSimulationListModal.vue';

    export default defineComponent({
        components: {CreateSimulationListModal},
        emits: ['created'],
        setup() {
            const showModal = ref(false);
            const loading = ref(false);

            const {can} = useAbility();

            return {
                can,
                loading,
                showModal,
            };
        },
    });
</script>
