<template>
    <div class="TaxCertificates__select-buttons-container">
        <MButton
            class="TaxCertificates__select-all-button"
            variant="light"
            @click="$emit('selectAll')"
        >
            <FontAwesomeIcon
                class="TaxCertificates__select-all-icon"
                icon="fa-solid fa-check-circle"
            />
            {{ __('common:actions.select_all') }}
        </MButton>
        <MButton
            class="TaxCertificates__unselect-all-button"
            variant="light"
            @click="$emit('unselectAll')"
        >
            <FontAwesomeIcon
                class="TaxCertificates__unselect-all-icon"
                icon="fa-solid fa-times-circle"
            />
            {{ __('common:actions.unselect_all') }}
        </MButton>
    </div>
</template>

<style scoped>
    .TaxCertificates__select-buttons-container {
        @apply tw-flex;
    }

    .TaxCertificates__select-all-button {
        @apply tw-mr-2;
    }

    .TaxCertificates__select-all-icon {
        @apply tw-mr-1 tw-text-success-500;
    }

    .TaxCertificates__unselect-all-icon {
        @apply tw-mr-1 tw-text-danger-600;
    }
</style>
