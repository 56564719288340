import {Model} from '@meekohq/lumos';

import PaymentSchedulerRuleModel from '@/modules/cashier/models/PaymentSchedulerRuleModel';

export default class PaymentSchedulerModel extends Model {
    public type = 'cashier/payment_schedulers';

    public attributes: {
        id: string;
        tenant_id: string | undefined;
        name: string | undefined;
        description: string | null;
        archived_at: string | null;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
        id: this.uuid(),
        tenant_id: undefined,
        name: undefined,
        description: null,
        archived_at: null,
        created_at: undefined,
        updated_at: undefined,
    };

    rules() {
        return this.hasMany('rules', PaymentSchedulerRuleModel, 'payment_scheduler_id');
    }
}
