<template>
    <CModal
        ref="modal"
        :header-title="__('organization:create_my_organization')"
    >
        <MHeading
            class="tw-mb-4"
            level="h3"
        >
            {{ __('organization:start_by_creating_your_organization') }}
        </MHeading>

        <MForm>
            <MFieldset>
                <MLabel>{{ __('common:name_of_organization') }}</MLabel>
                <MInput
                    v-model="attributes.name"
                    :has-error="errors.has('name')"
                    @keyup.enter="create"
                />
                <CFormErrorMessageList :errors="errors.get('name')" />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('common:opening_date') }}</MLabel>
                <MDatePicker
                    v-model="attributes.opening_date"
                    class="tw-w-full"
                    format="yyyy-MM-dd"
                />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('common:email') }}</MLabel>
                <MInput
                    v-model="attributes.email"
                    :has-error="errors.has('email')"
                    @keyup.enter="create"
                />
                <CFormErrorMessageList :errors="errors.get('email')" />
            </MFieldset>
        </MForm>

        <template #footer>
            <MButton
                class="tw-ml-auto"
                :label="__('organization:create_my_organization')"
                :loading="isLoading"
                variant="primary"
                @click="create"
            />
        </template>
    </CModal>
</template>

<script>
    import {collect} from '@meekohq/lumos';
    import moment from 'moment';

    import useApi from '@/modules/app/composables/useApi';
    import useError from '@/modules/app/composables/useError';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        name: 'NurseryWizard',
        props: {
            user: {
                type: Object,
            },
        },
        emits: ['store-nursery'],
        data() {
            return {
                currentStep: 1,
                tabIndex: 0,
                isLoading: false,
                errors: useError(),
                attributes: {
                    name: undefined,
                    email: this.user.email,
                    timezone: 'Europe/Paris',
                    locale: this.user.locale,
                },
            };
        },
        watch: {
            currentStep(val) {
                this.tabIndex = val - 1;
            },
        },
        methods: {
            show() {
                this.$refs.modal.show();
            },

            create() {
                this.isLoading = true;
                this.errors.reset();

                moment.tz.guess() ? (this.attributes.timezone = moment.tz.guess()) : null;
                useApi()
                    .legacy.post(route('v2.facility.store'), this.attributes)
                    .then(response => {
                        useApi()
                            .legacy.get(route('nurseries.show', {nursery_id: response.data.data.id}))
                            .then(old => {
                                this.$emit('store-nursery', old.data);
                                this.$refs.modal.hide();
                                this.$router.push({name: 'settings', params: {nursery: response.data.data.id}});
                            });
                    })
                    .catch(error => {
                        if (error?.response?.status === 422) {
                            const errors = error.response.data.errors;
                            collect(errors).each((sourceErrors, source) => {
                                collect(sourceErrors).each((message, key) => {
                                    this.errors.add(source, key, message);
                                });
                            });
                        } else {
                            useNotification().error(error);
                            throw error;
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            hideModal() {
                this.$refs.modal.hide();
            },
        },
    };
</script>
