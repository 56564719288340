<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {computed} from 'vue';

    import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
    import type {LegacyInvoiceType} from '@/modules/cashier/utils/billing/invoice/LegacyInvoiceType';

    const props = defineProps<{
        invoice: InvoiceModel | LegacyInvoiceType;
    }>();

    const kidName = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.kid_name;
        }

        return props.invoice.kid_name;
    });

    const contractNo = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.contract_no;
        }

        return props.invoice.contract_no;
    });

    const startPeriodDate = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.start_period_date;
        }

        return props.invoice.start_period_date;
    });

    const endPeriodDate = computed(() => {
        if (props.invoice instanceof InvoiceModel) {
            return props.invoice.attributes.end_period_date;
        }

        return props.invoice.end_period_date;
    });

    const displayPeriod = computed(() => {
        return startPeriodDate.value && endPeriodDate.value;
    });
</script>

<template>
    <div class="InvoiceMiniatureDescription">
        <div class="InvoiceMiniatureDescription__header">
            <MHeading level="h4">
                {{ __('common:description') }}
            </MHeading>
        </div>
        <div class="InvoiceMiniatureDescription__item">
            <span class="InvoiceMiniatureDescription__item-label">{{ __('billing_transaction:kid_name_colon') }}</span>
            <div class="tw-font-semibold">
                {{ kidName }}
            </div>
        </div>
        <div class="InvoiceMiniatureDescription__item">
            <span class="InvoiceMiniatureDescription__item-label">{{ __('billing_core:contract_number_colon') }}</span>
            <div class="tw-font-semibold">
                {{ contractNo }}
            </div>
        </div>
        <div
            v-if="displayPeriod"
            class="InvoiceMiniatureDescription__item"
        >
            <span class="InvoiceMiniatureDescription__item-label">{{ __('billing_invoice:period') }}</span>
            <div class="tw-font-semibold">
                {{
                    __('common:date_format.human_from_to', {
                        from: Epoch.fromISOString(startPeriodDate).toLocaleString(Epoch.presets.DATE_SHORT),
                        to: Epoch.fromISOString(endPeriodDate).toLocaleString(Epoch.presets.DATE_SHORT),
                    })
                }}
            </div>
        </div>
    </div>
</template>

<style scoped>
    .InvoiceMiniatureDescription__header {
        @apply tw-mb-1 tw-rounded-lg tw-bg-gray-100 tw-px-1.5 tw-py-1;
    }

    .InvoiceMiniatureDescription__item {
        @apply tw-flex tw-flex-row tw-items-baseline tw-gap-1;
    }

    .InvoiceMiniatureDescription__item-label {
        @apply tw-whitespace-nowrap;
    }
</style>
