export enum EventStatus {
    validated = 'validated',
    canceled = 'canceled',
    pendingReplace = 'pending-replace',
    pendingCreate = 'pending-create',
    pendingUpdate = 'pending-update',
    pendingDelete = 'pending-delete',
}

export const eventStatusPending = [
    EventStatus.pendingReplace,
    EventStatus.pendingCreate,
    EventStatus.pendingUpdate,
    EventStatus.pendingDelete,
];
