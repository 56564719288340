enum DocTemplateTypeValues {
    contract = 'contract',
    kid = 'kid',
    familyMember = 'family_member',
    transaction = 'transaction',
    staff = 'staff',
    taxCertificate = 'tax-certificate',
    sepaMandate = 'sepa_mandate',
    staffContract = 'staff_contract',
}

export default DocTemplateTypeValues;
