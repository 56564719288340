<template>
    <AbstractItem>
        <template #icon>
            <CTooltip placement="bottom-start">
                <font-awesome-icon
                    class="tw-text-pink-600"
                    fixed-width
                    :icon="getMeetingStateIcon(meeting)"
                    size="lg"
                />
                <template #content>
                    {{ __('activity:meeting_state', {context: meeting.attributes.state}) }}
                </template>
            </CTooltip>
        </template>
        <template #title>
            <CVStack
                class="tw-flex-1 sm:tw-rounded-lg sm:tw-p-2"
                :class="{
                    'tw-cursor-pointer sm:tw-transition-colors sm:tw-ease-in-out sm:hover:tw-bg-gray-50': !isEditing,
                }"
                @click="isEditing ? undefined : setIsEditing(true)"
            >
                <CText
                    class="tw-break-all"
                    font-size="lg"
                >
                    <template v-if="!meeting.exists">
                        {{ __('activity:meeting_one') }}
                    </template>
                    <template v-else>
                        <CLongText :model-value="meeting.attributes.name" />
                        <CText
                            class="tw-capitalize"
                            font-weight="semibold"
                        >
                            {{ startedAtFormatted }} ({{ duration }})
                        </CText>
                        <CTooltip
                            v-if="meeting.rescheduledMeeting().value()"
                            class="tw-ml-2 tw-text-sm"
                            placement="bottom"
                        >
                            <FontAwesomeIcon
                                class="tw-cursor-pointer tw-text-primary-800"
                                icon="fa-regular fa-history"
                                @click="$emit('meeting:scroll', meeting.rescheduledMeeting().value().getKey())"
                            />
                            <template #content>
                                <CText>
                                    {{ __('activity:rescheduled_from_colon') }}
                                    {{ meeting.rescheduledMeeting().value().attributes.name }}
                                </CText>
                            </template>
                        </CTooltip>
                    </template>
                </CText>
                <CText
                    v-if="!meeting.participants().value().count()"
                    variant="disabled"
                >
                    {{ __('activity:meeting_total', {count: meeting.participants().value().count()}) }}
                </CText>
                <MPopover
                    v-else
                    trigger="hover"
                >
                    <CText variant="disabled">
                        {{ __('activity:meeting_total', {count: meeting.participants().value().count()}) }}
                    </CText>
                    <template #content>
                        <div class="tw-flex tw-flex-col tw-gap-2">
                            <div class="tw-flex tw-items-center tw-gap-4 tw-pb-1">
                                <MHeading
                                    level="h4"
                                    class="tw-w-32"
                                >
                                    {{ __('common:last_name') }} :
                                </MHeading>
                                <MHeading
                                    level="h4"
                                    class="tw-w-32"
                                >
                                    {{ __('common:email') }} :
                                </MHeading>
                                <MHeading level="h4">{{ __('common:status') }} :</MHeading>
                            </div>
                            <div
                                v-for="participant in meeting.participants().value()"
                                :key="participant.getKey()"
                                class="tw-flex tw-items-center tw-gap-4"
                            >
                                <div class="tw-w-32 tw-truncate">
                                    {{ participant.attributes.first_name }} {{ participant.attributes.last_name }}
                                </div>
                                <div class="tw-w-32 tw-truncate">
                                    <MClamp v-if="participant.attributes.email">
                                        {{ participant.attributes.email }}
                                    </MClamp>
                                    <template v-else>
                                        {{ __('common:not_filled') }}
                                    </template>
                                </div>
                                <CBadge
                                    size="sm"
                                    class="tw-shrink-0"
                                    :variant="getAvailabilityVariant(participant)"
                                >
                                    {{
                                        __('activity:participant_state', {context: participant.attributes.availability})
                                    }}
                                </CBadge>
                            </div>
                        </div>
                    </template>
                </MPopover>
                <CHStack
                    v-if="meeting.attributes.location"
                    align="center"
                    class="tw-text-sm"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1 tw-text-disabled"
                        icon="fa-regular fa-map-marker-alt"
                    />
                    <CText variant="disabled">
                        {{ meeting.attributes.location }}
                    </CText>
                </CHStack>
            </CVStack>
        </template>
        <template #date>
            <CVStack class="tw-whitespace-nowrap">
                <CText
                    v-if="createdAtFormatted"
                    variant="disabled"
                >
                    {{ createdAtFormatted }}
                </CText>
                <CText
                    v-if="reporter"
                    font-size="sm"
                    variant="disabled"
                >
                    {{ __('common:by') }}
                    {{ reporter.fullname }}
                </CText>
            </CVStack>
        </template>
        <template #actions>
            <MMenu v-if="can('update', 'registrations')">
                <MMenuButton>
                    <MButton icon-ellipsis />
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        :disabled="isEditing || meeting.attributes.state === MeetingStateValue.rescheduled"
                        :label="__('activity:reschedule_the_meeting')"
                        @click="rescheduleMeeting"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-regular fa-history"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-if="meeting.attributes.state === MeetingStateValue.canceled"
                        :disabled="isEditing"
                        :label="__('common:actions.restore')"
                        @click="toggleState"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-calendar-plus"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        v-else
                        :disabled="isEditing"
                        :label="__('activity:cancel_meeting')"
                        @click="toggleState"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-calendar-xmark"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuDivider />
                    <MMenuItem
                        :disabled="isEditing"
                        :label="__('common:actions.update')"
                        @click="setIsEditing(true)"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-pen"
                            />
                        </template>
                    </MMenuItem>
                    <MMenuItem
                        :label="__('common:actions.delete')"
                        variant="danger"
                        @click="remove"
                    >
                        <template #icon>
                            <FontAwesomeIcon
                                fixed-width
                                icon="fa-solid fa-trash"
                            />
                        </template>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </template>
        <template
            v-if="isEditing || meetingHasExtraInfos"
            #content
        >
            <template v-if="isEditing">
                <MeetingForm
                    :disable-save-button="saving"
                    :meeting="editedMeeting"
                    :participants-custom-select="meetingParticipantsCustomSelect"
                    :registration="registration"
                    @saved="save"
                    @undo="cancel"
                />
            </template>
            <template v-else>
                <CVStack gap="4">
                    <CText v-if="meeting.attributes.description">
                        {{ meeting.attributes.description }}
                    </CText>
                    <template v-if="meeting.attributes.internal_note">
                        <CHStack
                            align="center"
                            class="tw-border-t tw-border-gray-200 tw-pt-3 tw-text-yellow-700"
                        >
                            <FontAwesomeIcon
                                class="tw-mr-2"
                                icon="fa-duotone fa-note"
                            />
                            <CText>
                                {{ meeting.attributes.internal_note }}
                            </CText>
                        </CHStack>
                    </template>
                </CVStack>
            </template>
        </template>
    </AbstractItem>
</template>
<script lang="ts">
    import {MqlOperation} from '@meekohq/lumos';
    import {useToggle} from '@vueuse/core';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref, toRef, watch} from 'vue';

    import MeetingForm from '@/modules/activity/components/MeetingForm.vue';
    import useAttachMeeting from '@/modules/activity/composables/useAttachMeeting';
    import type MeetingModel from '@/modules/activity/models/MeetingModel';
    import MeetingStateValue from '@/modules/activity/utils/MeetingStateValue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import AbstractItem from '@/modules/registration/components/molecules/Timeline/AbstractItem.vue';
    import useMeetingParticipantsCustomSelect from '@/modules/registration/composables/useMeetingParticipantsCustomSelect';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export enum AvailibilityVariant {
        pending = 'gray',
        accepted = 'success',
        rejected = 'danger',
    }

    export enum StatusIcon {
        scheduled = 'fa-duotone fa-calendar-alt',
        rescheduled = 'fa-duotone fa-calendar-clock',
        canceled = 'fa-regular fa-calendar-xmark',
    }

    export default defineComponent({
        components: {MeetingForm, AbstractItem},
        props: {
            meeting: {
                type: Object as PropType<MeetingModel>,
                required: true,
            },
            registration: {
                type: RegistrationModel,
                required: true,
            },
        },
        emits: ['meeting:scroll', 'deleted', 'reschedule'],
        setup(props, {emit}) {
            const [isEditing, setIsEditing] = useToggle(!props.meeting.exists);
            const reporter = computed(() => props.meeting.reporter().value());
            const {can} = useAbility();
            const saving = ref(false);

            const {fromISOString, presets} = useEpoch();

            const createdAtFormatted = computed(() => {
                return props.meeting.computed.created_at
                    ? fromISOString(props.meeting.computed.created_at).toLocaleString(presets.DATETIME_SHORT)
                    : null;
            });

            const startedAtFormatted = computed(() => {
                return props.meeting.attributes.datetime_period.started_at
                    ? fromISOString(props.meeting.attributes.datetime_period.started_at).toLocaleString(
                          presets.DATE_HUGE
                      )
                    : null;
            });

            const duration = computed(() => {
                const startHour = fromISOString(
                    props.meeting.attributes.datetime_period.started_at as string
                ).toLocaleString(presets.TIME_SIMPLE);
                const endHour = fromISOString(
                    props.meeting.attributes.datetime_period.ended_at as string
                ).toLocaleString(presets.TIME_SIMPLE);

                return `${startHour} - ${endHour}`;
            });

            const {attach} = useAttachMeeting(
                toRef(props, 'meeting'),
                props.registration.getKey().toString(),
                'registration/registrations'
            );

            const meetingHasExtraInfos = computed(() => {
                return props.meeting.attributes.description || !!props.meeting.attributes.internal_note;
            });

            const getAvailabilityVariant = function (participant) {
                return AvailibilityVariant[participant.attributes.availability];
            };

            const getMeetingStateIcon = function (meeting) {
                return StatusIcon[meeting.attributes.state];
            };

            const {meetingParticipantsCustomSelect} = useMeetingParticipantsCustomSelect(props.registration);

            const editedMeeting = ref();

            const save = async function (meeting: MeetingModel) {
                saving.value = true;
                await meeting.participants().fresh();

                const isNew = !props.meeting.exists;
                props.meeting.pullFromCache();
                meeting.copyTo(props.meeting);
                await attach();

                const result = await notifyModal();

                if (result.value) {
                    await new MqlOperation('activity/meeting/notify', {
                        meeting_id: props.meeting.getKey(),
                        event_type: isNew ? 'created' : 'updated',
                    }).run();
                }
                setIsEditing(false);
                saving.value = false;
            };

            const cancel = function () {
                editedMeeting.value.rollback();
                setIsEditing(false);
                if (!props.meeting.exists) {
                    emit('deleted', props.meeting);
                }
            };

            const toggleState = async function () {
                await useMagicModal().confirmationModal({
                    title: __('activity:change_meeting_state'),
                    text: __('activity:participants_will_be_notified_warning'),
                    type: 'warning',
                    onConfirm: async () => {
                        if (props.meeting.attributes.state === MeetingStateValue.canceled) {
                            props.meeting.attributes.state = MeetingStateValue.scheduled;
                        } else {
                            props.meeting.attributes.state = MeetingStateValue.canceled;
                        }

                        try {
                            await props.meeting.save({params: {notify: true}});
                            useNotification().success(__('activity:meeting_updated_successfully'));
                        } catch (e) {
                            props.meeting.rollback();
                        }
                    },
                });
            };

            async function notifyModal() {
                const result = ref(false);

                await useMagicModal().confirmationModal({
                    title: __('activity:notification_one'),
                    text: __('activity:do_you_want_to_notify_participants_ask'),
                    confirmButtonText: __('common:yes'),
                    dismissButtonText: __('common:no'),
                    onConfirm: async () => {
                        result.value = true;
                    },
                    onDismiss: async () => {
                        result.value = false;
                    },
                });

                return result;
            }

            async function remove() {
                await useMagicModal().deleteConfirmationModal({
                    title: __('activity:delete_meeting'),
                    text: __('activity:participants_will_be_notified_warning'),
                    onConfirm: async () => {
                        const notifyModalResult = await notifyModal();

                        if (props.meeting.exists) {
                            const rescheduledMeeting = props.meeting.rescheduledMeeting().value();

                            const params = {notify: false};

                            if (notifyModalResult.value) {
                                params.notify = true;
                            }

                            await props.meeting.delete({params});

                            if (rescheduledMeeting) {
                                rescheduledMeeting.attributes.state = MeetingStateValue.scheduled;
                                await rescheduledMeeting.save();
                            }
                        }

                        emit('deleted', props.meeting);
                    },
                });
            }

            watch(
                isEditing,
                value => {
                    if (value) {
                        editedMeeting.value = props.meeting.clone();
                    }
                },
                {immediate: true}
            );

            const rescheduleMeeting = function () {
                emit('reschedule', props.meeting);
            };

            return {
                saving,
                MeetingStateValue,
                isEditing,
                setIsEditing,
                editedMeeting,
                remove,
                getAvailabilityVariant,
                getMeetingStateIcon,
                can,
                save,
                cancel,
                toggleState,
                rescheduleMeeting,
                meetingHasExtraInfos,
                duration,
                reporter,
                meetingParticipantsCustomSelect,
                createdAtFormatted,
                startedAtFormatted,
            };
        },
    });
</script>
