<script setup lang="ts">
    import {useElementSize, useWindowSize} from '@vueuse/core';
    import {ref, watch} from 'vue';

    const isTruncated = ref(false);
    const valueEl = ref();
    const {width: windowWidth, height: windowHeight} = useWindowSize();
    const {width: valueElWidth} = useElementSize(valueEl);

    watch(
        [valueEl, windowWidth, windowHeight, valueElWidth],
        () => {
            if (valueEl.value) {
                isTruncated.value = valueEl.value.offsetWidth < valueEl.value.scrollWidth;
            }
        },
        {immediate: true}
    );
</script>

<template>
    <MTooltip
        class="MClamp"
        :hoverable="isTruncated"
    >
        <div
            ref="valueEl"
            class="MClamp__value"
        >
            <slot />
        </div>
        <template #content>
            <slot />
        </template>
    </MTooltip>
</template>

<style scoped>
    .MClamp {
        @apply tw-w-full tw-min-w-0;
    }

    .MClamp__value {
        @apply tw-truncate;
    }
</style>
