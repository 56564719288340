<script setup lang="ts">
    import {onMounted, onUnmounted, ref} from 'vue';

    const emit = defineEmits(['load-more']);
    const props = defineProps({
        distance: {
            type: Number,
            default: 1,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        endOfSearch: {
            type: Boolean,
            default: false,
        },
    });

    const scroller = ref<HTMLDivElement>();

    const onScroll = () => {
        if (!props.endOfSearch && scroller.value) {
            const distanceFromTop = scroller.value.scrollTop + scroller.value.clientHeight;
            const triggerPoint = (scroller.value?.scrollHeight || 0) * props.distance;

            if (distanceFromTop! >= triggerPoint && !props.loading) {
                emit('load-more');
            }
        }
    };

    onMounted(() => {
        scroller.value?.addEventListener('scroll', onScroll, {passive: true});
    });
    onUnmounted(() => {
        scroller.value?.removeEventListener('scroll', onScroll);
    });
</script>

<template>
    <div
        ref="scroller"
        class="ScrollLoading"
    >
        <slot />
        <Loader
            v-if="!endOfSearch"
            :light="true"
            :shadow="false"
            size="sm"
        />
    </div>
</template>

<style scoped>
    .ScrollLoading {
        @apply tw-overflow-y-scroll;
        @apply tw-pr-2;
        @apply tw-max-h-96;
    }
</style>
