import {datadogRum} from '@datadog/browser-rum';

import services from '@/config/services';

export default function () {
    function addAction(name: string, context?: object | undefined) {
        if (services.datadog.enabled) {
            datadogRum.addAction(name, context);
        }
    }

    return {
        addAction,
    };
}
