<template>
    <div class="TaxCertificates__alert">
        <MagicIndexEmptyList>
            <template #empty-list>
                {{ __('billing_core:no_template_for_tax_certificates') }}
            </template>
            <template #empty-list-action>
                <CText><span v-html="__('billing_core:first_create_a_document_template_with_instructions')" /></CText>
            </template>
        </MagicIndexEmptyList>
        <RouterLink
            :to="{name: 'settings'}"
            @click="showArticle(2988211)"
        >
            <MButton :truncate="false">
                <FontAwesomeIcon
                    class="tw-mr-1"
                    icon="fa-duotone fa-external-link"
                />
                {{ __('billing_core:show_tax_certificate_guide') }}
            </MButton>
        </RouterLink>
    </div>
</template>

<script>
    import useIntercom from '@/modules/app/composables/useIntercom';
    import MagicIndexEmptyList from '@/modules/magic-index/components/atoms/MagicIndexEmptyList.vue';

    export default {
        components: {MagicIndexEmptyList},
        methods: {
            showArticle: useIntercom().showArticle,
        },
    };
</script>
<style scoped>
    .TaxCertificates__alert {
        @apply tw-flex tw-flex-col tw-items-center;
    }
</style>
