<template>
    <div>
        <p
            class="tw-mb-0 tw-text-base tw-text-gray-600"
            v-html="
                __('family_member:family_late_message_with_message', {
                    name: `${notification.data.family_first_name} ${notification.data.family_last_name}`,
                    message: getLateContent(notification.data.late),
                })
            "
        />
        <p
            v-show="notification.data.content"
            class="tw-mb-0 tw-mt-1 tw-text-sm tw-text-gray-800"
        >
            {{ notification.data.content }}
        </p>
    </div>
</template>

<script>
    import moment from 'moment';

    import __ from '@/modules/app/utils/i18n-facade';

    export default {
        props: {
            notification: {type: Object},
        },
        computed: {
            moment() {
                return moment;
            },
        },
        mounted() {
            //
        },
        methods: {
            getLateContent(late) {
                if (late >= 60) {
                    return __('family_member:one_hour_or_more');
                }

                return __('family_member:from_minutes_with_minute', {minute: late});
            },
        },
    };
</script>
