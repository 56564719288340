<template>
    <div>
        <CVStack gap="10">
            <support-meeko :ressource="nursery" />

            <CCard
                body-size="none"
                :border="false"
                class="tw-border-t tw-border-blue-400"
                :header-border="false"
                :shadow="true"
            >
                <template #header>
                    <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
                        <div class="tw-flex tw-items-baseline">
                            <div
                                class="tw-mr-2 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-200"
                            >
                                <FontAwesomeIcon
                                    class="tw-text-xl tw-text-blue-600"
                                    icon="fa-duotone fa-shopping-cart"
                                />
                            </div>
                            <CCardHeroTitle class="tw-mr-2 tw-text-blue-500">
                                {{ __('subscription:manage_subscription') }}
                            </CCardHeroTitle>
                        </div>
                    </div>
                </template>
            </CCard>

            <CVStack gap="4">
                <CAlert
                    v-if="!customer.subscribed && !customer.on_trial"
                    class="tw-shadow-md"
                    :close-button="false"
                    variant="warning"
                >
                    {{ __('subscription:you_need_a_meeko_subscription_to_continue_using_the_application') }}
                </CAlert>

                <CAlert
                    v-if="!customer.subscribed && customer.on_trial"
                    class="tw-shadow-md"
                    :close-button="false"
                    variant="warning"
                >
                    {{ __('subscription:trial_period_warning') }}
                </CAlert>

                <CTabMenu v-if="legacyUser.is_owner">
                    <CTabMenuItem :to="{name: 'subscriptions.plan'}">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-sync-alt"
                        />
                        {{ __('common:subscription') }}
                    </CTabMenuItem>
                    <CTabMenuItem :to="{name: 'subscriptions.paymentsMethods'}">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-credit-card"
                        />
                        {{ __('subscription:payment_method') }}
                    </CTabMenuItem>
                    <CTabMenuItem :to="{name: 'subscriptions.address'}">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-address-card"
                        />
                        {{ __('common:billing_address_one') }}
                    </CTabMenuItem>
                    <CTabMenuItem :to="{name: 'subscriptions.invoices'}">
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-file-alt"
                        />
                        {{ __('common:invoice_other') }}
                    </CTabMenuItem>
                    <CTabMenuItem
                        v-if="customer.has_stripe && (customer.subscribed || false) && !customer.on_grace_period"
                        :to="{name: 'subscriptions.cancel'}"
                    >
                        <FontAwesomeIcon
                            class="tw-mr-2"
                            icon="fa-solid fa-ban"
                        />
                        {{ __('subscription:unsubscribe') }}
                    </CTabMenuItem>
                </CTabMenu>

                <div class="tw-rounded-lg tw-bg-white tw-p-4 tw-shadow-md">
                    <router-view
                        :customer="customer"
                        :nursery="nursery"
                        :user="user"
                        @customer-updated="updateCustomer"
                    />
                </div>
            </CVStack>
        </CVStack>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import {EventBus} from '@/eventBus';
    import useAuth from '@/modules/app/composables/useAuth';

    export default defineComponent({
        props: {
            nursery: {required: true},
            customer: {required: true},
            user: {required: true},
        },
        setup() {
            const updateCustomer = function (customer) {
                EventBus.$emit('update:customer', customer);
            };
            const {legacyUser} = useAuth();

            return {
                updateCustomer,
                legacyUser,
            };
        },
    });
</script>
