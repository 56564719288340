import {Str} from '@meekohq/lumos';
import {computed, ref} from 'vue';

export default function useHumanName(initialName: string) {
    const nameRef = ref(initialName);

    const capitalizedName = computed(() => {
        return Str.capitalizeWords(nameRef.value);
    });

    return {
        nameRef,
        capitalizedName,
    };
}
