<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-variant="buttonVariant"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :model-value="modelValue"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @update:model-value="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            <slot name="button-text-empty" />
        </template>

        <template #button-text="{value}">
            <CHStack gap="2">
                <div
                    class="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full"
                    :style="getIconStyle(value.attributes.color)"
                >
                    <CIcon
                        v-if="getIcon(value.attributes.icon_id)"
                        class="tw-text-sm"
                        :path="getIcon(value.attributes.icon_id).attributes.path"
                        :provider="getIcon(value.attributes.icon_id).attributes.provider"
                    />
                </div>
                {{ value.attributes.name }}
            </CHStack>
        </template>

        <template #button-text-multi="{values}">
            <slot
                name="button-text-multi"
                :values="values"
            >
                {{
                    __('components:count_item', {
                        value: values[0].attributes.name,
                        count: values.length - 1,
                    })
                }}
            </slot>
        </template>

        <!--<template v-slot:unselect-item/>-->
        <!--<template v-slot:unselect-text/>-->

        <!--<template v-slot:fallback="{searchValue}"/>-->

        <template #default="{option, searchValue}">
            <CHStack
                align="center"
                gap="2"
            >
                <div
                    class="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full"
                    :style="getIconStyle(option.attributes.color)"
                >
                    <CIcon
                        v-if="getIcon(option.attributes.icon_id)"
                        class="tw-text-sm"
                        :path="getIcon(option.attributes.icon_id).attributes.path"
                        :provider="getIcon(option.attributes.icon_id).attributes.provider"
                    />
                </div>
                <div
                    class="tw-truncate"
                    v-html="highlight(searchValue, option.attributes.name)"
                />
            </CHStack>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import {collect, type Model} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useEventStyle from '@/modules/human-resources/composables/calendar/useEventStyle';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import DataStore from '@/modules/legacy/store/data.store';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            modelValue: {type: [Object, Array] as PropType<Model | Model[]>},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            // ADD CUSTOM PROPS BELOW
            organizations: {type: [Array] as PropType<OrganizationModel[]>},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function (value) {
                emit('update:modelValue', value);
            };
            // !!! END: DO NOT EDIT !!

            const {activeOrganization} = useManager();

            const queryBuilder = computed(() => {
                let ids: string[] = [];

                if (props.organizations) {
                    ids = collect(props.organizations)
                        .keyBy(m => m.getKey())
                        .keys()
                        .all();
                } else {
                    ids = [activeOrganization.value.getKey()];
                }

                const builder = EventTypeModel.query()
                    .orderBy('name')
                    .whereLike('name', `*${search.value}*`)
                    .whereNull('archived_at')
                    .whereHas(new EventTypeModel().organizationsPivots(), q => {
                        q.whereIn('organization_id', ids).whereNull('archived_at');
                    });

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });

            const onFallback = function () {
                //
            };

            const getIcon = function (iconId) {
                return DataStore.iconPath(iconId);
            };

            const getIconStyle = function (color) {
                if (color) {
                    return useEventStyle(ref(color), ref(false), 'md', true).badgeStyle.value;
                }

                return null;
            };

            return {
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
                getIcon,
                getIconStyle,
            };
        },
    });
</script>
