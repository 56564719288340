<template>
    <ResourceFinder
        :builder="queryBuilder"
        :button-class="buttonClass"
        :button-variant="buttonVariant"
        :fall-back-text="fallBackText"
        :has-unselect="hasUnselect"
        :multi="multi"
        :multi-minimum="multiMinimum"
        :search-bar="searchBar"
        :model-value="modelValue"
        :wrapper-class="wrapperClass"
        @fallback="onFallback"
        @update:model-value="onChange"
        @search="search = $event"
    >
        <template #button-text-empty>
            <slot name="button-text-empty" />
        </template>

        <template #button-text="{value}">
            {{ value.attributes.name }}
        </template>

        <template #button-text-multi="{values, langVar}">
            <slot
                :lang-var="langVar"
                name="button-text-multi"
                :values="values"
            >
                {{ __('components:count_item', {count: values.length - 1, value: values[0].attributes.name}) }}
            </slot>
        </template>

        <template #default="{option, searchValue}">
            <CHStack
                align="center"
                gap="2"
            >
                <div
                    class="tw-mr-1 tw-truncate"
                    v-html="highlight(searchValue, option.attributes.name)"
                />
                <CBadge
                    v-if="showOrganization && option.organization().value()"
                    size="sm"
                    variant="primary"
                >
                    {{ option.organization().value().attributes.name }}
                </CBadge>
            </CHStack>
        </template>
    </ResourceFinder>
</template>

<script lang="ts">
    import type {QueryBuilder} from '@meekohq/lumos';
    import {type Model} from '@meekohq/lumos';
    import type {PropType} from 'vue';
    import {computed, defineComponent, ref} from 'vue';

    import ResourceFinder from '@/modules/app/components/resource/organisms/ResourceFinder.vue';
    import __ from '@/modules/app/utils/i18n-facade';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';

    export default defineComponent({
        components: {ResourceFinder},
        props: {
            // !!! DO NOT EDIT !!!
            modelValue: {type: [Object, Array] as PropType<Model | Model[]>},
            fallBackText: {type: String, default: () => __('common:actions.select')},
            injectQuery: {type: Object as PropType<QueryBuilder<any>>},
            showOrganization: {type: Boolean, default: false},
            hasUnselect: {type: Boolean, default: false},
            multi: {type: Boolean, default: false},
            multiMinimum: {type: [Number, String], default: 0},
            searchBar: {type: Boolean, default: true},
            wrapperClass: {},
            buttonClass: {},
            buttonVariant: {},
            onlyActiveOrganization: {type: Boolean, default: false},
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            // !!! START: DO NOT EDIT !!
            const search = ref('');
            const onChange = function (value) {
                emit('update:modelValue', value);
            };
            // !!! END: DO NOT EDIT !!

            const queryBuilder = computed(() => {
                const builder = KidsGroupModel.query().orderBy('name').whereLike('name', `*${search.value}*`);

                if (props.onlyActiveOrganization) {
                    builder.whereHas(new KidsGroupModel().organization(), query => {
                        query.scope('active');
                    });
                }

                if (props.showOrganization) {
                    builder.with(new KidsGroupModel().organization());
                }

                if (props.injectQuery) {
                    builder.inject(props.injectQuery);
                }

                return builder;
            });
            const onFallback = function () {
                //
            };

            return {
                search,
                onChange,
                onFallback,
                queryBuilder,
                ...useHighlighter(),
            };
        },
    });
</script>
