import {find, get, set} from 'lodash-es';

import type IconModel from '@/modules/app/models/IconModel';
import Store from '@/modules/legacy/store/store';

export class DataStore {
    public store = new Store('data');

    get icons(): IconModel[] {
        return get(this.store.data, 'icons');
    }

    set icons(value: IconModel[]) {
        set(this.store.data, 'icons', value);
    }

    flush() {
        this.store.flush();
    }

    public iconPath(value: string): IconModel {
        return find(this.store.data.icons, icon => icon.id === value);
    }
}

export default new DataStore();
