<template>
    <div class="tw-pt-1">
        <div
            v-if="message.manager_only"
            class="tw--mt-1 tw-mb-2 tw-mr-auto tw-pb-1 tw-text-sm"
            :class="[message.is_note ? 'tw-text-gray-700' : 'tw-text-white']"
        >
            <FontAwesomeIcon
                class="tw-mr-1"
                icon="fa-regular fa-lock-alt"
            />
            {{ __('request:manager_only') }}
        </div>
        <h6
            class="h6"
            :class="message.is_note ? 'tw-text-gray-800' : 'tw-text-white'"
        >
            <FontAwesomeIcon
                v-if="message.loading"
                icon="fa-solid fa-spinner"
                spin
            />
            {{ message.content }}
        </h6>
        <AttachedFiles
            v-if="message.documents && message.documents.length"
            class="tw-mt-2 tw-border-t tw-border-gray-200 tw-pt-1"
            :files="message.documents"
            :light-content="true"
            :member-id="message.family_member_id"
            :small="true"
        />
    </div>
</template>

<script>
    import moment from 'moment';

    import AttachedFiles from '@/modules/document/components/organisms/AttachedFiles.vue';

    export default {
        components: {
            AttachedFiles,
        },
        props: ['message'],
        computed: {
            moment() {
                return moment;
            },
        },
    };
</script>

<style scoped>
    .content {
        color: #40638a;
    }
</style>
