<template>
    <CModal
        ref="modal"
        :header-title="__('billing_customer:add_customer_account')"
        size="2xl"
        visible
        @hidden="$emit('hide')"
    >
        <div class="tw--mt-4 tw-mb-2 tw-flex">
            <MMenu
                v-if="memberModels.length"
                arrow
                class="tw-mx-auto"
                placement="bottom"
            >
                <MMenuButton>
                    <MButton variant="link">
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-duotone fa-copy" />
                        </template>
                        {{ __('billing_customer:copy_from_parent') }}
                    </MButton>
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        v-for="memberModel in memberModels"
                        :key="memberModel.getKey()"
                        @click="selectAddressForCopy(memberModel)"
                    >
                        <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
                            <div class="tw-text-base tw-font-medium">
                                {{ memberModel.fullname }}
                            </div>
                            <div class="tw-text-sm tw-font-normal">
                                {{ memberModel.fullAddress }}
                            </div>
                        </div>
                    </MMenuItem>
                </MMenuItems>
            </MMenu>
        </div>
        <div
            class="tw-flex tw-space-x-5"
            @keypress.enter.prevent="$refs.CustomerForm.save()"
        >
            <div class="tw-w-1/2">
                <MHeading
                    class="tw-mb-2"
                    level="h3"
                >
                    {{ __('common:customer_one') }}
                </MHeading>
                <CustomerForm
                    ref="CustomerForm"
                    :customer-model="customerModel"
                    :family-model="familyModel"
                    :name="name"
                    :tenant-model="tenantModel"
                    @loading="loader.set($event, 'customer')"
                    @saved="onCustomerSaved($event)"
                />
            </div>
            <div class="tw-border-l tw-border-gray-200" />
            <div class="tw-w-1/2">
                <MHeading
                    class="tw-mb-2"
                    level="h3"
                >
                    {{ __('billing_customer:add_customer_account') }}
                </MHeading>
                <AddressForm
                    ref="addressForm"
                    :address-model="addressModel"
                    :company="isCompany"
                    :focus="false"
                    @loading="loader.set($event, 'address')"
                    @saved="onAddressSaved($event)"
                />
            </div>
        </div>
        <template #footer>
            <MButton
                class="tw-ml-auto"
                @click="$emit('hide')"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="loader.isLoading('customer') || loader.isLoading('address')"
                variant="primary"
                @click="$refs.CustomerForm.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </CModal>
</template>

<script lang="ts">
    import {ModelCollection} from '@meekohq/lumos';
    import {cloneDeep, forEach, head, join, orderBy, trim} from 'lodash-es';
    import {computed, defineComponent, onMounted, type PropType, ref, toRef, watch} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import AddressForm from '@/modules/cashier/components/core/address/organisms/AddressForm.vue';
    import CustomerForm from '@/modules/cashier/components/core/customer/organisms/CustomerForm.vue';
    import AddressModel from '@/modules/cashier/models/AddressModel';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type TenantModel from '@/modules/cashier/models/TenantModel';
    import type FamilyModel from '@/modules/family/models/FamilyModel';
    import type MemberModel from '@/modules/family/models/MemberModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';

    export default defineComponent({
        components: {
            AddressForm,
            CustomerForm,
        },
        props: {
            tenantModel: {
                type: Object as PropType<TenantModel>,
                required: true,
            },
            memberModels: {
                type: Array as PropType<MemberModel[]>,
                default: () => [],
            },
            familyModel: {
                type: (Object as PropType<FamilyModel>) || null,
                default: null,
            },
            name: {
                type: String,
                default: '',
            },
            company: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['hide', 'created'],
        setup(props, {emit}) {
            const addressForm = ref();

            const {activeOrganization} = useManager();

            const {modal, show} = useLegacyModalBus();
            const loader = ref(new Loader());

            const customerModel = ref(new CustomerModel());
            const addressModel = ref(new AddressModel());

            const isCompany = computed(() => {
                return customerModel.value.attributes.person === 'legal';
            });

            onMounted(() => {
                reset();
            });

            function reset() {
                customerModel.value.attributes.tenant_id = props.tenantModel.id;
                if (props.company) {
                    customerModel.value.attributes.person = 'legal';
                } else {
                    customerModel.value.attributes.person = 'natural';
                }

                addressModel.value.attributes.tenant_id = props.tenantModel.getKey();
                addressModel.value.attributes.customer_id = customerModel.value.getKey();

                addressModel.value.attributes.type = 'billing';

                if (props.memberModels.length) {
                    setNameFromMembers(props.memberModels);
                    setAddressFromMember(head(props.memberModels) as MemberModel);
                } else {
                    customerModel.value.attributes.name = props.name;
                    addressModel.value.attributes.name = props.name;
                    addressModel.value.attributes.address.country_code =
                        activeOrganization.value?.attributes.address.country_code ?? 'FR';
                }
            }

            watch(
                () => props.memberModels,
                (members: MemberModel[]) => {
                    if (members.length) {
                        setNameFromMembers(members);
                        setAddressFromMember(head(members) as MemberModel);
                    }
                }
            );

            watch(toRef(customerModel.value.attributes, 'person'), person => {
                if (person === 'legal') {
                    addressModel.value.attributes.label = __('billing_customer:head_office');
                } else {
                    addressModel.value.attributes.label = __('billing_customer:home_address');
                }
            });

            watch(toRef(customerModel.value.attributes, 'name'), name => {
                addressModel.value.attributes.name = name;
            });

            function onCustomerSaved(model: CustomerModel) {
                customerModel.value = model;
                addressForm.value.save();

                return customerModel;
            }

            function onAddressSaved(addressModel: AddressModel) {
                emit('hide');
                customerModel.value.addresses().set(new ModelCollection([addressModel]));

                emit('created', customerModel.value);
            }

            function setNameFromMembers(memberModels: MemberModel[]) {
                const names = [] as string[];

                const sortedMembers = orderBy(
                    memberModels,
                    [
                        m => {
                            return m.attributes.gender;
                        },
                    ],
                    ['asc']
                );

                forEach(sortedMembers, memberModel => {
                    if (memberModel.attributes.gender === 'male') {
                        names.push(trim(__('common:mister_with_name_short', {name: memberModel.attributes.last_name})));
                    } else {
                        names.push(trim(__('common:miss_with_name_short', {name: memberModel.attributes.last_name})));
                    }
                });

                const nameJoined = join(names, ' & ');
                customerModel.value.attributes.name = nameJoined;
                addressModel.value.attributes.name = nameJoined;
            }

            function setAddressFromMember(memberModel: MemberModel) {
                addressModel.value.attributes.address = cloneDeep(memberModel.attributes.address);
            }

            function selectAddressForCopy(memberModel: MemberModel) {
                setAddressFromMember(memberModel);
            }

            return {
                modal,
                addressForm,
                loader,
                customerModel,
                addressModel,
                isCompany,
                onCustomerSaved,
                setNameFromMembers,
                onAddressSaved,
                setAddressFromMember,
                selectAddressForCopy,
                show,
            };
        },
    });
</script>
