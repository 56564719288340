<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {computed, toRefs} from 'vue';

    import Calculator from '@/modules/app/utils/Calculator';
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
    import {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
    import PaymentSuggestionsListItemHeaderCreditNote from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsListItemHeaderCreditNote.vue';
    import PaymentSuggestionsListItemHeaderTransaction from '@/modules/cashier/payment/infrastructure/components/PaymentSuggestionsListItemHeaderTransaction.vue';
    import MProgressBar from '@/modules/meeko-ui/components/MProgressBar.vue';

    const props = defineProps<{
        allocation: AbstractAllocationAggregate;
        saving: boolean;
    }>();

    const {allocation} = toRefs(props);

    const availableAmount = computed(() =>
        Calculator.minus(allocation.value.maxAllocatableAmount, allocation.value.allocationAmount)
    );

    const emit = defineEmits<{
        (e: 'add-allocation', value: AbstractAllocationAggregate): void;
    }>();

    const {format} = useFormatCurrency(allocation.value.currencyIsoCode);

    function addAllocation() {
        allocation.value.allocationAmount = allocation.value.maxAllocatableAmount;

        emit('add-allocation', allocation.value);
    }
</script>

<template>
    <div class="PaymentSuggestionsListItem">
        <div class="PaymentSuggestionsListItem__column-header">
            <PaymentSuggestionsListItemHeaderCreditNote
                v-if="allocation instanceof CreditNoteAllocationAggregate"
                :allocation="allocation"
            />
            <PaymentSuggestionsListItemHeaderTransaction
                v-else
                :allocation="allocation"
            />
        </div>
        <div class="PaymentSuggestionsListItem__column-progress-bar">
            <div class="PaymentSuggestionListItem__progress-bar-label">
                <template v-if="allocation instanceof CreditNoteAllocationAggregate">
                    {{
                        __('billing_transaction:remaining_amount_to_refund', {
                            value: format(allocation.allocatableRemainingAmount),
                        })
                    }}
                </template>
                <template v-else>
                    {{
                        __('billing_transaction:remaining_amount_to_allocate', {
                            value: format(allocation.allocatableRemainingAmount),
                        })
                    }}
                </template>
            </div>
            <MProgressBar
                :remaining="allocation.allocatableRemainingAmount"
                :total="allocation.allocatableAmount"
            />
        </div>
        <div class="PaymentSuggestionsListItem__column-informations">
            <div class="PaymentSuggestionsListItem__information">
                <FontAwesomeIcon
                    fixed-width
                    icon="fa-duotone fa-calendar"
                />
                {{ allocation.allocatableDate.toLocaleString(Epoch.presets.DATE_SHORT) }}
            </div>
            <div
                v-if="allocation.allocatableReference"
                class="PaymentSuggestionsListItem__information"
            >
                <FontAwesomeIcon
                    fixed-width
                    icon="fa-duotone fa-receipt"
                />
                {{ allocation.allocatableReference }}
            </div>
        </div>
        <div class="PaymentSuggestionsListItem__action">
            <MButton
                class="tw-w-full"
                :loading="saving"
                variant="primary"
                @click="addAllocation"
            >
                {{ __('billing_transaction:actions.use_amount', {amount: format(availableAmount)}) }}
            </MButton>
        </div>
    </div>
</template>

<style scoped>
    .PaymentSuggestionsListItem {
        @apply tw-grid tw-grid-cols-2 tw-items-center tw-gap-4 md:tw-grid-cols-4;
    }

    .PaymentSuggestionsListItem__column-header {
        @apply tw-col-span-2 md:tw-col-span-1;
    }

    .PaymentSuggestionsListItem__column-progress-bar {
        @apply tw-col-span-1;
    }

    .PaymentSuggestionsListItem__column-informations {
        @apply tw-col-span-1;
    }

    .PaymentSuggestionsListItem__information {
        @apply tw-truncate tw-text-sm tw-text-gray-500;
    }

    .PaymentSuggestionsListItem__action {
        @apply tw-col-span-2 md:tw-col-span-1;
        @apply tw-order-last;
    }
</style>
