<template>
    <MModal
        :header-title="isEditing ? __('user:update_user_account') : __('user:new_user_account')"
        :modal="modal"
    >
        <MForm>
            <CFormThreeColumns>
                <MFieldset>
                    <MLabel>{{ __('common:first_name') }}*</MLabel>
                    <MInput
                        v-model="user.first_name"
                        :placeholder="__('common:first_name')"
                        @keyup.enter="save"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('common:last_name') }}*</MLabel>
                    <MInput
                        v-model="user.last_name"
                        :placeholder="__('common:last_name')"
                        @keyup.enter="save"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('common:language') }}*</MLabel>
                    <MSelectMenu
                        v-model="user.locale"
                        :options="availableLanguages"
                        @keyup.enter="save"
                    />
                </MFieldset>
            </CFormThreeColumns>
            <CFormTwoColumns v-if="!user.is_owner">
                <MFieldset>
                    <MLabel>{{ __('common:email') }}*</MLabel>
                    <MInput
                        v-model="user.email"
                        :placeholder="__('common:email')"
                        @keyup.enter="save"
                    />
                </MFieldset>
                <MFieldset>
                    <MLabel>{{ __('common:password') }}*</MLabel>
                    <CButtonGroup
                        v-if="user.id"
                        class="tw-w-full"
                    >
                        <MInput
                            v-model="user.password"
                            :disabled="!editPassword"
                            :placeholder="editPassword ? __('common:password') : '******'"
                            type="password"
                            @keyup.enter="save"
                        />
                        <MButton
                            class="tw-shrink-0"
                            :class="editPassword ? 'tw-text-red-500' : 'tw-text-primary-500'"
                            :label="editPassword ? __('common:actions.cancel') : __('common:actions.change')"
                            @click="switchPassword(!editPassword)"
                        />
                    </CButtonGroup>
                    <MInput
                        v-else
                        v-model="user.password"
                        :placeholder="__('common:password')"
                        type="password"
                        @keyup.enter="save"
                    />
                </MFieldset>
            </CFormTwoColumns>
        </MForm>
        <template #footer-start>
            <MButton
                v-if="!user.is_owner && isEditing && $can('delete', 'users')"
                :label="__('common:actions.delete_dots')"
                variant="danger"
                @click="deleteUser"
            >
                {{ __('common:actions.delete_dots') }}
            </MButton>
        </template>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="isEditing ? __('common:actions.save') : __('common:actions.add')"
                variant="primary"
                @click="save"
            />
        </template>
    </MModal>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import useLocale from '@/modules/app/composables/useLocale.ts';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        props: {
            nursery: {
                type: Object,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            modal: {
                type: Object,
                required: true,
            },
            isEditing: {
                type: Boolean,
                required: true,
            },
        },
        emits: ['edit', 'add', 'delete'],
        data: () => ({
            editPassword: false,
        }),
        computed: {
            availableLanguages() {
                return useLocale().availableLanguages;
            },
        },
        watch: {
            isEditing: {
                handler(editing) {
                    this.editPassword = !editing;
                },
                immediate: true,
            },
        },
        methods: {
            save() {
                if (this.user.id) {
                    const data = {...this.user};
                    if (!data.password) {
                        delete data.password;
                    }
                    // EDIT USER
                    useApi()
                        .legacy.put(
                            route('nurseries.users.update', {
                                nurseries: this.nursery.id,
                                user: this.user.id,
                            }),
                            this.user
                        )
                        .then(() => {
                            this.$emit('edit', this.user);
                            useNotification().success(__('user:user_updated_successfully'));
                            this.modal.hide();
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                } else {
                    // NEW USER
                    useApi()
                        .legacy.post(route('nurseries.users.store', {nurseries: this.nursery.id}), this.user)
                        .then(response => {
                            this.$emit('add', response.data);
                            useNotification().success(__('user:user_added_successfully'));
                            this.modal.hide();
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },
            deleteUser() {
                this.$emit('delete', this.user);
            },
            switchPassword(edit) {
                this.editPassword = edit;
            },
        },
    };
</script>
