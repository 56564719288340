import {app} from '@meekohq/lumos';

import {AxiosApiBinding} from '@/modules/app/utils/AxiosApiBinding';
import {AxiosLegacyApiBinding} from '@/modules/app/utils/AxiosLegacyApiBinding';

export default function () {
    return {
        api: app(AxiosApiBinding),
        legacy: app(AxiosLegacyApiBinding),
    };
}
