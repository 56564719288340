<script setup lang="ts">
    import {onMounted, ref} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import type {LegacyKidType} from '@/modules/family/utils/kid/LegacyKidType';

    const props = defineProps<{
        kid: LegacyKidType;
    }>();

    const {can} = useAbility();
    const savedMealType = ref(null);

    onMounted(() => {
        savedMealType.value = props.kid.meal_type;
    });

    function switchMeal() {
        useMagicModal().confirmationModal({
            title: __('family_kid:change_meal_types_ask'),
            text: __('family_kid:changing_the_meal_type_may_affect_the_price_of_future_invoices'),
            type: 'warning',
            onDismiss: async () => {
                props.kid.meal_type = savedMealType.value;
                savedMealType.value = props.kid.meal_type;
            },
        });
    }
</script>

<template>
    <MForm
        class="KidFoodEatingHabitsMealTypeForm"
        @submit.prevent
    >
        <MFieldset>
            <MLabel>{{ __('family_kid:meal_type_colon') }}</MLabel>
            <MFieldset class="KidFoodEatingHabitsMealTypeForm__row">
                <div class="KidFoodEatingHabitsMealTypeForm__item">
                    <CRadio
                        v-model="kid.meal_type"
                        option="milk"
                        :disabled="!can('update', 'kids_food')"
                        @click="switchMeal"
                    />
                    <MLabel>
                        {{ __('common:meal_types.milk') }}
                    </MLabel>
                </div>
                <div class="KidFoodEatingHabitsMealTypeForm__item">
                    <CRadio
                        v-model="kid.meal_type"
                        :disabled="!can('update', 'kids_food')"
                        option="mixed"
                        @click="switchMeal"
                    />
                    <MLabel>
                        {{ __('common:meal_types.mixed') }}
                    </MLabel>
                </div>
            </MFieldset>
            <MFieldset class="KidFoodEatingHabitsMealTypeForm__row">
                <div class="KidFoodEatingHabitsMealTypeForm__item">
                    <CRadio
                        v-model="kid.meal_type"
                        option="crushed"
                        :disabled="!can('update', 'kids_food')"
                        @click="switchMeal"
                    />
                    <MLabel>
                        {{ __('common:meal_types.crushed') }}
                    </MLabel>
                </div>
                <div class="KidFoodEatingHabitsMealTypeForm__item">
                    <CRadio
                        v-model="kid.meal_type"
                        option="mix"
                        :disabled="!can('update', 'kids_food')"
                        @click="switchMeal"
                    />
                    <MLabel>
                        {{ __('common:meal_types.mix') }}
                    </MLabel>
                </div>
            </MFieldset>
            <MFieldset class="KidFoodEatingHabitsMealTypeForm__item">
                <CRadio
                    v-model="kid.meal_type"
                    option="normal"
                    :disabled="!can('update', 'kids_food')"
                    @click="switchMeal"
                />
                <MLabel>
                    {{ __('common:meal_types.normal') }}
                </MLabel>
            </MFieldset>
        </MFieldset>
    </MForm>
</template>

<style scoped>
    .KidFoodEatingHabitsMealTypeForm__row {
        @apply tw-flex tw-items-center tw-gap-4;
    }

    .KidFoodEatingHabitsMealTypeForm__item {
        @apply tw-flex tw-items-center tw-gap-2;
    }
</style>
