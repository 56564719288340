<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="staff">
                {{ __('app:menu.staffs') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MButton
                v-if="can('create', 'staffs_details')"
                icon-plus
                variant="primary"
                @click="$refs.staffModal.$refs.modal.show()"
            >
                {{ __('hr_staff:add_staff') }}
            </MButton>
            <StaffModal
                ref="staffModal"
                :error="staffError"
                is-new
                :loading="saveLoading"
                :staff="selectedStaff"
                @attach="attachStaff"
                @save="saveNewStaff"
            />
            <MSettingsButton
                v-if="can('settings', 'staffs_details')"
                :to="{name: 'humanresources/settings'}"
                :tooltip="__('hr_staff:staff_parameter')"
            />
        </template>
        <template #content>
            <MagicIndex
                :loading="loading"
                :paginator="responsePaginator"
                :key-function="item => item.getKey()"
                :router-link-fn="staffRouterLinkCallback"
                variant="staff"
                @change="getStaffs($event)"
            >
                <template #filters>
                    <TagsResourceFinder
                        button-size="sm"
                        has-unselect
                        :inject-query="tagFinderQuery"
                        multi
                        only-active-organization
                        :search-bar="false"
                        :model-value="selectedTagModels"
                        :without-tags-option-is-selected="withoutTags"
                        @update:model-value="updateSelectedTags"
                        @without-tags-option-is-selected="updateWithoutTags"
                    />
                    <FilterContractStates
                        v-model="selectedContracts"
                        size="sm"
                    />
                    <TeamFinder
                        v-model="selectedTeams"
                        button-size="sm"
                        multi
                    />
                </template>
                <template #header>
                    <StaffsListHeader />
                </template>
                <template #item="{item: staff}">
                    <StaffsListItem
                        :staff="staff"
                        :tasks-by-staff-id="tasksByStaffId"
                    />
                </template>
                <template #empty-list>
                    {{ __('common:empty_result') }}
                </template>
                <template #empty-list-action>
                    {{ __('common:try_modifying_filters_or_adding_data') }}
                </template>
            </MagicIndex>
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {defineComponent, onBeforeMount, type Ref, ref, watch} from 'vue';

    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import FilterContractStates from '@/modules/human-resources/components/staff/FilterContractStates.vue';
    import StaffModal from '@/modules/human-resources/components/staff/StaffModal.vue';
    import TeamFinder from '@/modules/human-resources/components/team/TeamFinder.vue';
    import useStaff from '@/modules/human-resources/composables/staff/useStaff';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import TagsResourceFinder from '@/modules/tag/components/TagsResourceFinder.vue';
    import TagModel from '@/modules/tag/models/TagModel';
    import StaffsListHeader from '@/pages/nurseries/[id]/hr/staffs/StaffsListHeader.vue';
    import StaffsListItem from '@/pages/nurseries/[id]/hr/staffs/StaffsListItem.vue';

    export default defineComponent({
        components: {
            StaffsListItem,
            StaffsListHeader,
            TagsResourceFinder,
            SimplePageTemplate,
            MagicIndex,
            TeamFinder,
            StaffModal,
            FilterContractStates,
        },
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        setup(props) {
            const {can} = useAbility();
            const staffModal = ref(null);

            const {selectedOptions: selectedTeams} = useUserFilters('staffs:index:teams', OutputType.array, []);

            const {
                filteredStaffs,
                selectedStaff,
                selectedTags,
                withoutTags,
                selectedContracts,
                loading,
                saveLoading,
                responsePaginator,
                getStaffs,
                saveNewStaff,
                staffError,
                attachStaff,
            } = useStaff(null, null, staffModal, null, false, ref({teams: selectedTeams as Ref<TeamModel[]>}));

            onBeforeMount(() => {
                getStaffs();
                getSelectedTagsModels();
            });

            watch(selectedTeams, () => {
                getStaffs(1);
            });

            watch(filteredStaffs, staffs => {
                getTasksRelatedToStaffs(staffs.map(staff => staff.getKey())).then(
                    tasksRelatedToStaffs => (tasks.value = tasksRelatedToStaffs)
                );
            });

            const tagFinderQuery = TagModel.query().where('type', 'staff');

            /*
             * <TagResourceFinder /> is using TagModel, useStaff is using tag ids, so we need to make a reactive conversions between them
             * `selectedTagModels` is the reactive array of TagModel instances used by <TagResourceFinder />
             * `getSelectedTagsModels` is used in onMounted to load the selected tags models after selectedTags has been load from localStorage
             */
            const selectedTagModels = ref<TagModel[]>([]);

            async function getSelectedTagsModels() {
                if (!selectedTags.value?.length) {
                    selectedTagModels.value = [];

                    return;
                }

                // If tag does not exists anymore, reset the selectedTags
                const models = (await TagModel.query().whereIn('id', selectedTags.value).get()).all();
                if (!models.length) {
                    selectedTags.value = undefined;
                }

                selectedTagModels.value = models;
            }

            function updateSelectedTags(newTagModels: TagModel[]) {
                selectedTagModels.value = newTagModels;
                const tagIds = newTagModels.map(tag => tag.getKey());

                if (!tagIds.length) {
                    selectedTags.value = undefined;

                    return;
                }

                selectedTags.value = tagIds;
                withoutTags.value = false;
            }

            function updateWithoutTags(newWithoutTags: boolean) {
                if (newWithoutTags) {
                    selectedTags.value = undefined;
                }

                withoutTags.value = newWithoutTags;
            }

            function staffRouterLinkCallback(staff: StaffModel) {
                return {
                    name: 'staffs.show',
                    params: {
                        nursery: props.nursery.id,
                        staff: staff.attributes.id,
                    },
                };
            }

            const {
                tasks,
                tasksGroupByModelId: tasksByStaffId,
                getTasks: getTasksRelatedToStaffs,
            } = useGetTasks({
                constrainToResourceModel: StaffModel,
            });

            return {
                can,
                staffModal,
                staffError,
                getStaffs,

                tagFinderQuery,
                selectedStaff,
                selectedTagModels,
                updateSelectedTags,
                updateWithoutTags,
                withoutTags,
                selectedContracts,
                selectedTeams,
                loading,
                saveLoading,
                responsePaginator,
                saveNewStaff,
                attachStaff,
                staffRouterLinkCallback,
                tasksByStaffId,
            };
        },
    });
</script>
