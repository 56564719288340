<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle variant="family">
                {{ __('app:menu.families') }}
            </MPageTitle>
        </template>
        <template #actions>
            <MButton
                :label="__('family_family:filoue_export')"
                @click="openExportFiloue"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-duotone fa-file-download" />
                </template>
            </MButton>
            <MButton
                icon-plus
                variant="primary"
                @click="showCreateModal = true"
            >
                {{ __('family_family:add_family') }}
            </MButton>
            <CreateFamilyModal
                v-if="showCreateModal"
                :organization-model="activeOrganization"
                @cancel="showCreateModal = false"
                @close="showCreateModal = false"
                @created="$router.push({name: 'families.show', params: {family: $event.id}})"
            />
        </template>
        <template #content>
            <MagicIndex
                :loading="loading"
                :paginator="paginator"
                :router-link-fn="familyRouterLinkCallback"
                variant="family"
                @change="navigate($event)"
            >
                <template #header>
                    <FamiliesListHeader />
                </template>
                <template #item="{item}">
                    <FamiliesListItem :family-model="item" />
                </template>
                <template #empty-list>
                    {{ __('common:empty_result') }}
                </template>
                <template #empty-list-action>
                    {{ __('common:try_modifying_filters_or_adding_data') }}
                </template>
            </MagicIndex>
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import type {LengthAwarePaginator} from '@meekohq/lumos';
    import {defineComponent, onBeforeMount, ref} from 'vue';
    import {useRoute, useRouter} from 'vue-router';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useManager from '@/modules/app/composables/useManager';
    import useModal from '@/modules/app/composables/useModal';
    import CreateFamilyModal from '@/modules/family/components/family/CreateFamilyModal.vue';
    import ExportFiloueModal from '@/modules/family/components/family/ExportFiloueModal.vue';
    import FamiliesListItem from '@/modules/family/components/family/FamiliesListItem.vue';
    import FamilyModel from '@/modules/family/models/FamilyModel';
    import {showCAF} from '@/modules/legacy/libs/organization';
    import MagicIndex from '@/modules/magic-index/components/organisms/MagicIndex.vue';
    import FamiliesListHeader from '@/pages/nurseries/[id]/family/families/FamiliesListHeader.vue';

    export default defineComponent({
        components: {
            FamiliesListHeader,
            MagicIndex,
            SimplePageTemplate,
            FamiliesListItem,
            CreateFamilyModal,
        },
        setup() {
            const paginator = ref<LengthAwarePaginator<FamilyModel> | null>(null);
            const route = useRoute();
            const router = useRouter();
            const {activeOrganization} = useManager();
            const showCreateModal = ref(false);
            const loading = ref(false);

            onBeforeMount(() => {
                fetchResults(Number(route.query.page) || 1);
            });

            function fetchResults(page = 1, perPage = 20): void {
                loading.value = true;

                FamilyModel.query()
                    .orderBy('name')
                    .whereHas(new FamilyModel().organizations(), query => {
                        query.where('id', activeOrganization.value.getKey());
                    })
                    .with(new FamilyModel().kids())
                    .with(new FamilyModel().members())
                    .with(new FamilyModel().customers())
                    .paginate(perPage, page)
                    .then(response => {
                        loading.value = false;
                        paginator.value = response;
                    });
            }

            function navigate(page: number) {
                router.push({query: {page: Number(page).toString()}});

                fetchResults(page);
            }

            function familyRouterLinkCallback(familyModel: FamilyModel) {
                return {name: 'families.show', params: {family: familyModel.attributes.id}};
            }

            function openExportFiloue() {
                useModal({
                    component: ExportFiloueModal,
                }).show();
            }

            return {
                activeOrganization,
                showCAF,
                showCreateModal,
                loading,
                paginator,
                navigate,
                familyRouterLinkCallback,
                openExportFiloue,
            };
        },
    });
</script>
