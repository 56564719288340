<template>
    <MSelectMenu
        has-unselect
        :options="options"
        text-path="name"
        :model-value="modelValue"
        value-path="value"
        @update:model-value="$emit('update:modelValue', $event)"
    />
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent} from 'vue';

    import paymentMethodsOptions from '@/modules/cashier/utils/payment-method/paymentMethodsOptions';

    export default defineComponent({
        props: {
            modelValue: {
                type: String as PropType<string | null>,
                default: null,
            },
        },
        emits: ['update:modelValue'],
        setup() {
            const options = paymentMethodsOptions();

            return {
                options,
            };
        },
    });
</script>
