<template>
    <router-link
        class="suggestion"
        :to="to"
    >
        <CHStack
            v-if="userHasManyOrganization"
            align="center"
            class="tw-mb-3 tw-flex-1 tw-text-gray-400"
            gap="1"
        >
            <CText font-size="sm">
                {{ suggestion.bindings.organization_name }}
            </CText>
            <CText font-size="sm">-</CText>
            <CText font-size="sm">
                {{ suggestion.bindings.organization_city }}
            </CText>
        </CHStack>
        <div class="header tw-flex tw-items-center">
            <LegacyMemberAvatar
                class="avatar"
                :member="{first_name: suggestion.title, avatar_url: suggestion.avatar_url}"
            />
            <div
                class="title"
                v-html="highlighted(suggestion.title)"
            />
        </div>
        <div class="additionals">
            <dl class="additional tw-mb-0">
                <template
                    v-for="(value, i) in suggestion.additionals"
                    :key="i"
                >
                    <dt>{{ translateLabel(value.label) }}</dt>
                    <dd v-html="highlighted(value.value)" />
                </template>
            </dl>
        </div>
    </router-link>
</template>

<script>
    import {exists} from 'i18next';

    import LegacyMemberAvatar from '@/modules/app/components/atoms/avatars/LegacyMemberAvatar.vue';
    import useManager from '@/modules/app/composables/useManager';
    import __ from '@/modules/app/utils/i18n-facade';
    import useHighlighter from '@/modules/legacy/helpers/useHighlighter';

    export default {
        components: {LegacyMemberAvatar},
        props: ['suggestion', 'query'],
        data() {
            return {
                userHasManyOrganization: useManager().hasManyOrganizations.value,
            };
        },
        computed: {
            to() {
                return {
                    name: 'familyMembers.show',
                    params: {
                        nursery: this.suggestion.bindings.nursery_id,
                        familyMember: this.suggestion.bindings.familyMember_id,
                    },
                };
            },
        },
        methods: {
            highlighted(value) {
                return useHighlighter().highlight(this.query, value);
            },
            translateLabel(label) {
                switch (label) {
                    case 'legal_custodian_female':
                        return __('common:relationship.legal_custodian_female');
                    case 'legal_custodian_male':
                        return __('common:relationship.legal_custodian_male');
                    case 'mother':
                        return __('common:relationship.parent_female');
                    case 'father':
                        return __('common:relationship.parent_male');
                    case 'sister':
                        return __('common:relationship.sibling_female');
                    case 'brother':
                        return __('common:relationship.sibling_male');
                    case 'aunt':
                        return __('common:relationship.pibling_female');
                    case 'uncle':
                        return __('common:relationship.pibling_male');
                    case 'grandparent':
                        return __('common:relationship.grandparent');
                    case 'nanny':
                        return __('common:relationship.nanny');
                    case 'stepmother':
                        return __('common:relationship.parent_in_law_female');
                    case 'stepfather':
                        return __('common:relationship.parent_in_law_male');
                    case 'cousin':
                        return __('common:relationship.cousin_male');
                    case 'landline':
                        return __('common:phone.landline');
                    case 'cellular':
                        return __('common:phone.cellular');
                    default:
                        return exists(`common:${label}`) ? __(`common:${label}`) : label;
                }
            },
        },
    };
</script>
