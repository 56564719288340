import __ from '@/modules/app/utils/i18n-facade';

export interface DeviceViaBroadcastType {
    value: string;
    label: string;
    secondValue?: string;
}

export function parentDeviceViaBroadcast(): DeviceViaBroadcastType[] {
    return [
        {
            value: 'new_message',
            label: __('common:message_other'),
        },
        {
            value: 'new_late',
            label: __('common:delay_other'),
        },
        {
            value: 'new_absence',
            label: __('common:absence_other'),
        },
        {
            value: 'new_occasional',
            label: __('common:booking_other'),
        },
    ];
}

export function organizationDeviceViaBroadcast(): DeviceViaBroadcastType[] {
    return [
        {
            value: 'new_document',
            label: __('common:document_other'),
            secondValue: 'updated_document',
        },
        {
            value: 'new_post',
            label: __('common:news'),
            secondValue: 'updated_post',
        },
        {
            value: 'new_registration',
            label: __('common:registration_other'),
            secondValue: 'updated_registration',
        },
        {
            value: 'kid_presence_unclocked',
            label: __('organization:missing_clocking_in'),
            secondValue: 'staff_presence_unclocked',
        },
    ];
}
