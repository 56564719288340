import {Model} from '@meekohq/lumos';

export default class IconModel extends Model {
    public type = 'common/icons';

    public attributes: {
        id: string;
        description: string | undefined;
        category: string | undefined;
        path: string | undefined;
        provider: string | undefined;
        archived_at: string | undefined | null;
        created_at: string | undefined;
        updated_at: string | undefined;
    } = {
        id: this.uuid(),
        description: undefined,
        category: undefined,
        path: undefined,
        provider: undefined,
        archived_at: undefined,
        created_at: undefined,
        updated_at: undefined,
    };
}
