import {watchOnce} from '@vueuse/core';
import {cloneDeep, isEqual, merge} from 'lodash-es';
import type {ComputedRef, Ref, UnwrapRef} from 'vue';
import {computed, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';

export interface FilterType<T> {
    data: Ref<UnwrapRef<T>>;
    isReady: Ref<boolean>;
    isActive: ComputedRef<boolean>;
    reset: () => void;
    option: FilterOptions;
}

export interface FilterOptions {
    defaultValue?: any;
    storagePath?: string | false;
    queryString?: string | false;
    isArray?: boolean;
    serializer?: SerializerType;
    debounceDelay?: number;
    storage?: Storage;
}

export interface SerializerType {
    write: (value: any, defaultValue?: any) => string | undefined;
    read: (value: any) => Promise<any>;
}

/**
 * Read a value from the storage
 * @param key
 * @param storage
 */
function readStorage(key: string, storage: Storage = localStorage): string | undefined {
    const value = storage.getItem(key);

    return value ?? undefined;
}

/**
 * Write a value to the storage
 * @param key
 * @param value
 * @param storage
 */
function writeStorage(key: string, value: any, storage: Storage = localStorage): void {
    if (value === undefined) {
        return storage.removeItem(key);
    }

    return storage.setItem(key, value);
}

/**
 * Read a value from the query string
 * @param route
 * @param key
 */
function readQueryString(route: any, key: string): string | undefined {
    return route.query[key];
}

/**
 * Write a value to the query string
 * @param route
 * @param router
 * @param key
 * @param value
 */
async function writeQueryString(route: any, router: any, key: string, value: any): Promise<void> {
    const query = Object.assign({}, route.query);

    if ((value === undefined || value === null) && query[key]) {
        delete query[key];
    }

    if (value !== undefined && value !== null) {
        query[key] = value;
    }

    if (JSON.stringify(route.query) !== JSON.stringify(query)) {
        await router.push({query});
    }
}

/**
 * Serialize a value
 * @param value
 * @param serializer
 */
function serialize(value: any, serializer: SerializerType): string | undefined {
    return serializer.write(value);
}

/**
 * Unserialize a value
 * @param value
 * @param serializer
 */
function unserialize(value: any, serializer: SerializerType): Promise<any> {
    return serializer.read(value);
}

export function setupFilter<T>(option: FilterOptions): FilterType<T> {
    const defaultOption: FilterOptions = {
        defaultValue: undefined,
        storage: localStorage,
        storagePath: false,
        queryString: false,
        debounceDelay: 1000,
    };

    const optionWithDefault = merge({}, defaultOption, option);

    const router = useRouter();
    const route = useRoute();

    const data = ref();
    const isReady = ref(false);

    /**
     * Get the default value
     */
    function getDefaultValue() {
        return cloneDeep(optionWithDefault.defaultValue);
    }

    /**
     * Restore the value from storage or query string
     */
    async function restoreValue() {
        let storageStoredValue: string | undefined;
        let queryStringStoredValue: string | undefined;

        // Try to get value from storage
        if (option.storagePath) {
            storageStoredValue = readStorage(option.storagePath, optionWithDefault.storage);
        }

        // Try to get value from query string
        if (optionWithDefault.queryString) {
            queryStringStoredValue = readQueryString(route, optionWithDefault.queryString);
        }

        if (storageStoredValue === undefined && queryStringStoredValue === undefined) {
            return;
        }

        const value = queryStringStoredValue ?? storageStoredValue;

        if ((optionWithDefault.storagePath || optionWithDefault.queryString) && optionWithDefault.serializer) {
            return unserialize(value, optionWithDefault.serializer);
        }

        return value;
    }

    /**
     * Initialize the value
     */
    async function initValue() {
        try {
            const storedValue = await restoreValue();
            data.value = storedValue === undefined ? getDefaultValue() : storedValue;
        } catch (e) {
            console.error(e);
            // We can't unserialize the value, so we delete the key from the storage to reset the filter
            if (optionWithDefault.storagePath) {
                writeStorage(optionWithDefault.storagePath, undefined, optionWithDefault.storage);
            }

            if (optionWithDefault.queryString) {
                await writeQueryString(route, router, optionWithDefault.queryString, undefined);
            }

            // We can't restore the value, so we reset the filter to avoid further errors
            reset();
        }

        isReady.value = true;
    }

    /**
     * Return true if the filter is active, meaning that the value is different from the default value
     */
    const isActive = computed(() => {
        if (optionWithDefault.isArray) {
            return Array.isArray(data.value) && data.value.length > 0;
        }

        if (optionWithDefault.serializer) {
            return (
                serialize(cloneDeep(data.value), optionWithDefault.serializer) !==
                serialize(getDefaultValue(), optionWithDefault.serializer)
            );
        }

        return !isEqual(data.value, getDefaultValue());
    });

    /**
     * Reset the filter to the default value
     */
    function reset() {
        data.value = getDefaultValue();
    }

    // If the default value is a function, we call it to get the default value
    if (typeof optionWithDefault.defaultValue === 'function') {
        const fnResult = optionWithDefault.defaultValue();
        // If the function return a promise, we wait for the result
        if (fnResult instanceof Promise) {
            fnResult.then(promiseResult => {
                if (promiseResult !== undefined) {
                    optionWithDefault.defaultValue = promiseResult;
                }
                initValue();
            });
        } else {
            if (fnResult !== undefined) {
                optionWithDefault.defaultValue = fnResult;
            }
            initValue();
        }
    } else {
        initValue();
    }

    return {
        data,
        isActive,
        isReady,
        reset,
        option: optionWithDefault,
    };
}

export default function (filters: FilterType<any>[], pageFilter?: FilterType<any>) {
    const activeCount = computed(() => {
        if (!isReady.value) {
            return 0;
        }

        return filters.filter(f => f.isActive.value).length;
    });

    const isReady = computed(() => {
        return filters.filter(f => f.isReady.value).length === filters.length;
    });

    const allFilters = computed(() => {
        return filters.map(f => f.data.value);
    });

    const filtersAndPageReady = computed(() => {
        if (pageFilter) {
            return isReady.value && pageFilter.isReady.value;
        }

        return isReady.value;
    });

    watchOnce(filtersAndPageReady, async () => {
        watch(
            allFilters,
            async () => {
                for (const f of filters) {
                    await writeValue(f);
                }

                pageFilter?.reset();
            },
            {deep: true}
        );

        if (pageFilter) {
            watch(
                () => pageFilter.data.value,
                async () => {
                    await writeValue(pageFilter);
                }
            );
        }
    });

    function reset() {
        for (const f of filters) {
            f.reset();
        }

        if (pageFilter) {
            pageFilter.reset();
        }
    }

    const router = useRouter();
    const route = useRoute();

    /**
     * Write the value to storage and query string
     */
    async function writeValue(filter: FilterType<any>) {
        let value = filter.data.value;

        // If the value is equal to the default value, we don't store it by setting it to undefined
        if (equalsTo(value, filter.option.defaultValue, filter)) {
            value = undefined;
        }

        if (value !== undefined && filter.option.serializer) {
            value = serialize(value, filter.option.serializer);
        }

        if (filter.option.storagePath) {
            writeStorage(filter.option.storagePath, value, filter.option.storage);
        }

        if (filter.option.queryString) {
            await writeQueryString(route, router, filter.option.queryString, value);
        }
    }

    /**
     * Compare two values
     * @param valueA
     * @param valueB
     */
    function equalsTo(valueA: unknown, valueB: unknown, filter: FilterType<any>) {
        if (isEqual(valueA, valueB)) {
            return true;
        }

        if (filter.option.serializer) {
            // We compare value by serializing them
            const serializedValueA = serialize(valueA, filter.option.serializer);
            const serializedValueB = serialize(valueB, filter.option.serializer);

            if (serializedValueA === serializedValueB) {
                return true;
            }
        }

        return false;
    }

    return {
        activeCount,
        isReady,
        reset,
        allFilters,
    };
}
