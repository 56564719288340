<template>
    <div>
        <CButtonGroup
            v-for="(chunkOfDays, i) in chunk(monthDays, 7)"
            :key="'row-' + i"
            class="tw-mb-2 tw-w-full"
        >
            <MButton
                v-for="(day, index) in chunkOfDays"
                :key="'day-' + index"
                :class="{'tw-w-full': chunkOfDays.length === 7, 'tw-w-10': chunkOfDays.length !== 7}"
                :disabled="disabled"
                :variant="selectedMonthDays?.includes(day.value) ? 'gray' : 'light'"
                @click="toggleMonthDay(day.value)"
            >
                {{ day.text }}
            </MButton>
        </CButtonGroup>
    </div>
</template>

<script lang="ts">
    import chunk from 'lodash-es/chunk';
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';

    import type {Range} from '@/modules/calendar/utils/RuleSet/Range';

    export default defineComponent({
        props: {
            modelValue: {
                type: Array as PropType<Range<1, 31>[]>,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const selectedMonthDays = ref<number[]>(props.modelValue.length ? props.modelValue : []);

            // Create range of 31 days (max by month)
            const monthDays: {
                text: number;
                value: number;
            }[] = Array.from({length: 31}, (v, k) => k + 1).map(month => {
                return {value: month, text: month};
            });

            function toggleMonthDay(day: Range<1, 31>) {
                if (selectedMonthDays.value?.includes(day)) {
                    selectedMonthDays.value = selectedMonthDays.value.filter(value => value !== day);
                } else {
                    selectedMonthDays.value.push(day);
                }
            }

            watch(
                selectedMonthDays,
                () => {
                    emit('update:modelValue', selectedMonthDays.value);
                },
                {deep: true}
            );

            return {
                chunk,
                monthDays,
                selectedMonthDays,
                toggleMonthDay,
            };
        },
    });
</script>
