import useManager from '@/modules/app/composables/useManager';

export default function () {
    const {activeOrganization} = useManager();

    function getOccupationColor(rate: number | string | null) {
        rate = Number(rate);
        const minRate = activeOrganization.value.attributes.occ_min_rate ?? 80;
        const maxRate = activeOrganization.value.attributes.occ_max_rate ?? 115;
        if (rate > maxRate) {
            return 'danger';
        } else if (rate >= minRate) {
            return 'success';
        } else {
            return 'primary';
        }
    }

    function getDayKidsColor(kidsNumber: number | null) {
        if (kidsNumber) {
            const places = activeOrganization.value.attributes.places ?? 0;
            const maxRate = activeOrganization.value.attributes.occ_max_rate ?? 115;
            const nbPercent = ((places * maxRate) / 100).toFixed();

            if (kidsNumber > Number(nbPercent)) {
                return 'danger';
            } else if (kidsNumber < places) {
                return 'primary';
            } else {
                return 'success';
            }
        }
    }

    return {
        getOccupationColor,
        getDayKidsColor,
    };
}
