<script setup lang="ts">
    import {ValidationError} from '@meekohq/lumos';
    import {isNil} from 'lodash-es';
    import {computed, toRef, watch} from 'vue';

    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type {SaveAllocationResultFailed} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
    import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
    import {AmountToAllocateIsGreaterThanDestinationRemainingError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanDestinationRemainingError';
    import {AmountToAllocateIsGreaterThanSourceRemainingError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsGreaterThanSourceRemainingError';
    import {AmountToAllocateIsRequired} from '@/modules/cashier/payment/domain/errors/AmountToAllocateIsRequired';
    import {AmountToAllocateMustBePositiveError} from '@/modules/cashier/payment/domain/errors/AmountToAllocateMustBePositiveError';
    import {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';
    import {TransactionCreditAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
    import {TransactionDebitAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';
    import usePaymentSuggestionsListItem from '@/modules/cashier/payment/infrastructure/components/usePaymentSuggestionsListItem';

    const props = defineProps<{
        allocation: AbstractAllocationAggregate;
        currency: string;
        editable: boolean;
        savingError: SaveAllocationResultFailed[] | null;
    }>();

    const {format} = useFormatCurrency(props.currency);

    const invoiceType = computed(() => {
        if (
            props.allocation instanceof TransactionDebitAllocationAggregate ||
            props.allocation instanceof InvoiceAllocationAggregate
        ) {
            return 'credit_note';
        } else {
            return 'invoice';
        }
    });

    const isCanceled = computed(() => {
        if (props.allocation instanceof TransactionCreditAllocationAggregate) {
            return props.allocation.source.isFailed;
        } else if (props.allocation instanceof TransactionDebitAllocationAggregate) {
            return props.allocation.destination.isFailed;
        } else {
            return false;
        }
    });

    const {errors, maxAllocatableAmount, setAllocationAmount} = usePaymentSuggestionsListItem(
        toRef(props, 'allocation')
    );

    watch(
        () => props.savingError,
        newValue => {
            if (isNil(newValue)) {
                return;
            }

            newValue.forEach((error: SaveAllocationResultFailed) => {
                if (error.reason instanceof AmountToAllocateIsRequired) {
                    errors.addErrorCode('amount', AmountToAllocateIsRequired.code);
                } else if (error.reason instanceof AmountToAllocateMustBePositiveError) {
                    errors.addErrorCode('amount', AmountToAllocateMustBePositiveError.code);
                } else if (error.reason instanceof AmountToAllocateIsGreaterThanSourceRemainingError) {
                    errors.addErrorCode('amount', AmountToAllocateIsGreaterThanSourceRemainingError.code);
                } else if (error.reason instanceof AmountToAllocateIsGreaterThanDestinationRemainingError) {
                    errors.addErrorCode('amount', AmountToAllocateIsGreaterThanDestinationRemainingError.code);
                } else if (error.reason instanceof ValidationError) {
                    errors.addValidationError(error.reason);
                } else {
                    throw new AggregateError(
                        newValue.map(r => r.reason),
                        'Failed to save allocations.'
                    );
                }
            });
        }
    );

    watch(
        () => props.editable,
        () => {
            errors.reset();
        }
    );

    watch(
        () => props.allocation.sharedRemainingAmount,
        () => {
            if (props.allocation.sharedRemainingAmount >= 0 && errors.has('amount')) {
                setAllocationAmount(props.allocation.amount);
            }
        }
    );
</script>
<template>
    <div
        class="PaymentAllocationsListAbstractItem"
        :class="{'PaymentAllocationsListAbstractItem--has-error': errors.hasErrors.value}"
    >
        <slot name="item" />
        <div
            v-if="!editable || allocation.allocationMustBeIgnored"
            class="PaymentAllocationsListAbstractItem__allocation"
        >
            <MTooltip :hoverable="editable && allocation.allocationMustBeIgnored">
                <div
                    class="PaymentAllocationsListAbstractItem__allocation-amount"
                    :class="{'PaymentAllocationsListAbstractItem__allocation-amount--rejected': isCanceled}"
                >
                    {{ format(allocation.allocationAmount) }}
                </div>
                <template #content>
                    {{ __('billing_invoice:for_editing_allocation_disable_reject') }}
                </template>
            </MTooltip>
            <div class="PaymentAllocationsListAbstractItem__allocation-label">
                {{ __('billing_invoice:on_this', {context: invoiceType}) }}
            </div>
        </div>
        <div
            v-else
            class="PaymentAllocationsListAbstractItem__allocation-edition"
        >
            <MCurrencyInput
                :allow-undefined="true"
                class="tw-text-lg tw-font-semibold"
                :currency-code="currency"
                size="lg"
                :model-value="allocation.amount"
                @update:model-value="setAllocationAmount($event)"
            />
            <MButton
                :disabled="allocation.sharedRemainingAmount <= 0 || allocation.amount === maxAllocatableAmount"
                :label="__('billing_transaction:actions.use_maximum_amount')"
                size="sm"
                @click="setAllocationAmount(maxAllocatableAmount)"
            />
            <MErrorMessageList
                v-if="errors.has('amount')"
                class="PaymentAllocationsListAbstractItem__allocation-error-message"
                :errors="errors.get('amount')"
                font-size="xs"
                :show-icon="false"
            />
        </div>
    </div>
</template>

<style scoped>
    .PaymentAllocationsListAbstractItem {
        @apply tw-flex tw-flex-col tw-items-center tw-gap-4 sm:tw-flex-row;
    }

    .PaymentAllocationsListAbstractItem--has-error {
        @apply tw-items-start;
    }

    .PaymentAllocationsListAbstractItem__allocation {
        @apply tw-flex tw-w-full tw-items-baseline tw-justify-end tw-gap-2 sm:tw-w-48 sm:tw-flex-col sm:tw-items-end sm:tw-gap-0;
    }

    .PaymentAllocationsListAbstractItem__allocation-amount {
        @apply tw-text-xl tw-font-semibold;

        &--rejected {
            @apply tw-line-through;
        }
    }

    .PaymentAllocationsListAbstractItem__allocation-label {
        @apply tw-text-sm tw-text-gray-500;
    }

    .PaymentAllocationsListAbstractItem__allocation-edition {
        @apply tw-w-full sm:tw-w-48;
        @apply tw-grid tw-grid-cols-2 tw-gap-2 sm:tw-grid-cols-1;
        @apply tw-text-start;
    }

    .PaymentAllocationsListAbstractItem__allocation-error-message {
        @apply tw-col-span-full;
    }
</style>
