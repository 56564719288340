export default [
    /*
     * App Provider
     */
    async () => (await import('@/modules/app/providers/MonitoringServiceProvider')).default,
    async () => (await import('@/modules/app/providers/AppServiceProvider')).default,
    async () => (await import('@/modules/app/providers/ModuleAbilitiesServiceProvider')).default,
    async () => (await import('@/modules/app/providers/BroadcastServiceProvider')).default,
    async () => (await import('@/modules/app/providers/HttpServiceProvider')).default,

    /*
     * Modules Providers
     */
    async () => (await import('@/modules/activity/ActivityServiceProvider')).default,
    async () => (await import('@/modules/calendar/CalendarServiceProvider')).default,
    async () => (await import('@/modules/cashier/CashierServiceProvider')).default,
    async () => (await import('@/modules/core/CoreServiceProvider')).default,
    async () => (await import('@/modules/family/FamilyServiceProvider')).default,
    async () => (await import('@/modules/human-resources/HumanResourcesServiceProvider')).default,
    async () => (await import('@/modules/meeko-family/providers/MeekoFamilyServiceProvider')).default,
    async () => (await import('@/modules/organization/OrganizationServiceProvider')).default,
    async () => (await import('@/modules/registration/RegistrationServiceProvider')).default,
    async () => (await import('@/modules/request/RequestServiceProvider')).default,
    async () => (await import('@/modules/tag/TagServiceProvider')).default,
    async () => (await import('@/modules/user/UserServiceProvider')).default,
    async () => (await import('@/modules/document/DocumentsServiceProvider')).default,
    async () => (await import('@/modules/photo/PhotosServiceProvider')).default,
    async () => (await import('@/modules/health/application/HealthServiceProvider')).default,
    async () => (await import('@/modules/planning/utils/core/PlanningServiceProvider')).default,
    async () => (await import('@/modules/auth/AuthServiceProvider')).default,
    async () => (await import('@/modules/app/providers/TranslationServiceProvider')).default,
    async () => (await import('@/modules/app/providers/VueServiceProvider')).default,
];
