import {includes, isArray} from 'lodash-es';

export type ChannelType = 'stable' | 'beta' | 'dev';

/**
 * Scopes:
 * - Stable: stable
 * - Beta: beta | stable
 * - Dev: dev | beta | stable
 */
export default class ReleaseChannel {
    private readonly _channels: ChannelType | ChannelType[];

    constructor(channels: ChannelType | ChannelType[]) {
        this._channels = channels;
    }

    public get onStable(): boolean {
        return this.on('stable') || this.onBeta || this.onDev;
    }

    public get onBeta(): boolean {
        return this.on('beta') || this.onDev;
    }

    public get onDev(): boolean {
        return this.on('dev');
    }

    protected on(channels: ChannelType[] | ChannelType): boolean {
        if (isArray(channels)) {
            for (const channel of channels) {
                if (includes(this._channels, channel)) {
                    return true;
                }
            }

            return false;
        }

        return includes(this._channels, channels);
    }
}
