<template>
    <MForm
        v-if="loader.isDone('FetchData')"
        @submit.prevent="save"
    >
        <CInline
            align-x="between"
            space="xl"
        >
            <MFieldset>
                <MLabel>
                    {{ __('common:name') }}*
                    <MHelpTooltip class="tw-ml-2">
                        {{ __('hr:the_name_of_the_event_type_as_it_will_appear_on_calendars') }}
                    </MHelpTooltip>
                </MLabel>
                <CInput
                    v-model="model.attributes.name"
                    v-focus="true"
                    :has-error="eventTypeError.hasError('name')"
                />
                <CFormErrorMessageList :errors="eventTypeError.getErrors('name')" />
            </MFieldset>
            <MFieldset :class="{'tw-opacity-50': model.attributes.internal_id}">
                <MLabel>
                    {{ __('common:icon') }}
                </MLabel>
                <SelectIcon
                    :disabled="!!model.attributes.internal_id"
                    :icon-id="model.attributes.icon_id"
                    @update-icon="model.attributes.icon_id = $event"
                />
            </MFieldset>
            <MFieldset :class="{'tw-opacity-50': model.attributes.internal_id}">
                <MLabel>
                    {{ __('common:color') }}
                </MLabel>
                <ColorSwatches
                    v-model="model.attributes.color"
                    :colors="swatchColor"
                    :disabled="!!model.attributes.internal_id"
                />
                <CFormErrorMessageList
                    v-if="eventTypeError.hasError('color')"
                    :errors="eventTypeError.getErrors('color')"
                />
            </MFieldset>
        </CInline>
        <CInline>
            <MFieldset :class="{'tw-opacity-50': model.attributes.internal_id}">
                <MLabel>{{ __('hr:spervision_children_colon') }}</MLabel>
                <MLabel>
                    <CCheckbox
                        v-model="model.attributes.enable_supervision_for_forecast"
                        :disabled="!!model.attributes.internal_id"
                    >
                        {{ __('hr:assign_to_forecast') }}
                    </CCheckbox>
                </MLabel>
                <MLabel>
                    <CCheckbox
                        v-model="model.attributes.enable_supervision_for_actual"
                        :disabled="!!model.attributes.internal_id"
                    >
                        {{ __('hr:assign_to_real') }}
                    </CCheckbox>
                </MLabel>
            </MFieldset>
        </CInline>
        <slot
            name="importItemAlert"
            :value="model.attributes.name"
        />
    </MForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent, reactive} from 'vue';

    import ColorSwatches from '@/modules/app/components/ColorSwatches.vue';
    import SelectIcon from '@/modules/app/components/Icon/SelectIcon.vue';
    import useManager from '@/modules/app/composables/useManager';
    import type EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import EventTypeOrganizationModel from '@/modules/human-resources/models/EventTypeOrganizationModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';

    export default defineComponent({
        components: {ColorSwatches, SelectIcon},
        props: {
            model: {
                required: true,
                type: Object as PropType<EventTypeModel>,
            },
        },
        emits: ['loading', 'saved'],
        setup(props, {emit}) {
            const {activeOrganization} = useManager();
            const loader = reactive(new Loader());
            const eventTypeError = reactive(new ErrorHandler());
            const swatchColor = [
                '#f43f5e',
                '#ec4899',
                '#d946ef',
                '#a855f7',
                '#8b5cf6',
                '#6366f1',
                '#3b82f6',
                '#0ea5e9',
                '#06b6d4',
                '#14b8a6',
                '#10b981',
                '#22c55e',
                '#84cc16',
                '#eab308',
                '#f59e0b',
                '#f97316',
                '#1f2937',
                '#6b7280',
                '#d1d5db',
            ];

            // computed to react on props.model
            // return the relation for using it when i want the value
            const organizationsPivotRelations = computed(() => props.model.organizationsPivots());

            async function save() {
                try {
                    emit('loading', true);

                    await props.model.save();

                    // create a boolean which represents if there is a pivot for the active organization or not
                    const activeOrganizationPivotExist = organizationsPivotRelations.value
                        .value()
                        .filter(pivot => pivot.attributes.organization_id === activeOrganization.value.id)
                        .isNotEmpty();
                    if (!activeOrganizationPivotExist) {
                        // if pivot dont exist we create, associate and save the new pivot
                        const pivot = await savePivot();
                        organizationsPivotRelations.value.value().push(pivot);
                    }
                    eventTypeError.reset();
                    emit('loading', false);
                    emit('saved', props.model);
                } catch (error) {
                    emit('loading', false);
                    eventTypeError.reset(error);
                }
            }

            async function savePivot() {
                const pivot = new EventTypeOrganizationModel();
                pivot.attributes.account_id = props.model.attributes.account_id;
                pivot.eventType().associate(props.model, false);
                pivot.organization().associate(activeOrganization.value, false);

                return pivot.save();
            }

            return {
                loader,
                eventTypeError,
                swatchColor,
                save,
            };
        },
    });
</script>
