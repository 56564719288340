<template>
    <CVStack
        class="tw-cursor-pointer tw-transition-all tw-ease-in-out"
        gap="3"
    >
        <CCenter
            class="tw-rounded-lg tw-bg-gray-100"
            @click="isOpen = !isOpen"
        >
            <CText
                font-size="xl"
                font-weight="semibold"
                variant="gray"
            >
                {{ year }}
            </CText>
        </CCenter>
        <template v-if="isOpen">
            <Week
                v-for="(weekSummaries, week) in weeks"
                :key="'week-' + week + '-' + year"
                class="tw-rounded-lg tw-shadow tw-ring-1 tw-ring-gray-500/10"
                :day-separator="daySeparator"
                :halfday-time="halfdayTime"
                :kids-group-id="kidsGroupId"
                :kids-planning-types="kidsPlanningTypes"
                :kids-tag-ids="kidsTagIds"
                :rate="weekSummaries.rate"
                :registrations="registrations"
                :same-weeks="weekSummaries.sameWeeks"
                :show-supervision="showSupervision"
                :summaries="weekSummaries.summaries"
                :week="Number(week)"
                :year="Number(year)"
            />
        </template>
    </CVStack>
</template>

<script lang="ts">
    import type {ModelCollection} from '@meekohq/lumos';
    import {defineComponent, type PropType, ref} from 'vue';

    import Week from '@/modules/planning/components/simulation/Week/Week.vue';
    import type {WeeksInterface} from '@/modules/planning/utils/simulation/SimulationTypes';
    import type RegistrationModel from '@/modules/registration/models/RegistrationModel';

    export default defineComponent({
        components: {Week},
        props: {
            year: {
                type: String,
                required: true,
            },
            weeks: {
                type: Object as PropType<WeeksInterface>,
                required: true,
            },
            registrations: {
                type: Object as PropType<ModelCollection<RegistrationModel>>,
                required: true,
            },
            kidsGroupId: {
                type: String,
                default: () => null,
            },
            kidsTagIds: {
                type: Array as PropType<number[]>,
                default: () => [],
            },
            kidsPlanningTypes: {
                type: Array as PropType<number[]>,
                default: () => [],
            },
            showSupervision: {
                type: Boolean,
                default: () => false,
            },
            daySeparator: {
                type: String,
                default: 'day',
            },
            halfdayTime: {
                type: String,
                default: '12:30',
            },
        },
        setup() {
            const isOpen = ref(true);

            return {
                isOpen,
            };
        },
    });
</script>
