<template>
    <CTabMenu>
        <div
            v-if="can('read', 'tasks')"
            class="TodoWidgetTabMenu__item-container"
        >
            <CTabMenuItem
                :active="selected === 'tasks'"
                @click="$emit('select', 'tasks')"
            >
                {{ __('common:task_other') }}
            </CTabMenuItem>
            <div
                v-if="tasksCount"
                class="TodoWidgetTabMenu__badge-container"
            >
                <MNotificationBadge :count="tasksCount" />
            </div>
        </div>
        <div
            v-if="can('read', 'hr_request')"
            class="TodoWidgetTabMenu__item-container"
        >
            <CTabMenuItem
                :active="selected === 'requests'"
                @click="$emit('select', 'requests')"
            >
                {{ __('common:request_other') }}
            </CTabMenuItem>
            <div
                v-if="requestsCount"
                class="TodoWidgetTabMenu__badge-container"
            >
                <MNotificationBadge :count="requestsCount" />
            </div>
        </div>
    </CTabMenu>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    import useAbility from '@/modules/app/composables/useAbility';
    import MNotificationBadge from '@/modules/meeko-ui/components/MNotificationBadge.vue';

    export default defineComponent({
        components: {MNotificationBadge},
        props: {
            tasksCount: {
                type: Number,
                required: true,
            },
            requestsCount: {
                type: Number,
                required: true,
            },
            selected: {
                type: String,
                required: true,
            },
        },
        emits: ['select'],
        setup() {
            const {can} = useAbility();

            return {can};
        },
    });
</script>

<style scoped>
    .TodoWidgetTabMenu__item-container {
        @apply tw-relative;
    }

    .TodoWidgetTabMenu__badge-container {
        @apply tw-absolute tw--right-1.5 tw--top-1.5;
    }
</style>
