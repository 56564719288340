export default {
    mql: {
        endpoint: 'mql/main',
        aggregator: {
            enabled: true,
            debounce_delay: 10,
            max_delay: 0, // was 100
            timeout: 10000,
            debug: false,
        },
    },
};
