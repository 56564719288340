<template>
    <router-link
        v-if="to"
        :class="baseClass"
        :to="to"
    >
        <slot />
    </router-link>
    <div
        v-else
        :class="baseClass"
        @click="click"
        @mousemove.self="mousemoveThrottled"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import _throttle from 'lodash-es/throttle';
    import {computed, defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            clickable: {type: Boolean, default: false, required: false},
            hover: {type: Boolean, default: true, required: false},
            forceHover: {type: Boolean, default: false, required: false},
            active: {type: Boolean, default: false, required: false},
            size: {type: String, default: 'default', required: false},
            variant: {type: String, default: 'default', required: false},
            weight: {type: String, default: 'normal', required: false},
            customClass: {},
            to: {},
        },
        emits: ['click', 'mousemove'],
        setup(props, {emit}) {
            const baseClass = computed(() => {
                const finalClass = [] as any[];

                finalClass.push(props.customClass);

                const sizes = {
                    default: 'tw-px-3 tw-py-2 tw-text-base',
                    sm: 'tw-px-2 tw-py-1 tw-text-sm',
                };
                finalClass.push(sizes[props.size]);

                finalClass.push('tw-block tw-no-underline tw-rounded-lg');

                const weights = {
                    normal: 'tw-font-medium',
                    semibold: 'tw-font-semibold',
                    bold: 'tw-font-bold',
                };

                finalClass.push(weights[props.weight]);

                const variants = {
                    default: {
                        default: 'tw-text-black even:tw-bg-gray-50',
                        hover: 'even:tw-bg-gray-50 hover:tw-bg-primary-200',
                        active: 'tw-text-white tw-bg-primary-400',
                        forceHover: 'tw-text-primary-950 tw-bg-primary-200',
                    },
                    danger: {
                        default: 'tw-text-red-500 even:tw-bg-gray-50',
                        hover: 'tw-text-red-500 even:tw-bg-gray-50 hover:tw-bg-red-100',
                        forceHover: 'tw-text-red-500 tw-bg-red-100',
                    },
                    disabled: {
                        default: 'tw-text-gray-500 tw-bg-gray-200',
                        hover: 'tw-text-gray-500 tw-bg-gray-200 hover:tw-bg-gray-300 hover:tw-cursor-not-allowed',
                    },
                    archived: {
                        default: 'tw-text-black/75 tw-bg-orange-100/75 archived-striped',
                        hover: 'tw-text-black/75 tw-bg-orange-100 hover:tw-bg-orange-200 archived-striped',
                    },
                };
                finalClass.push(
                    variants[props.variant][
                        props.active ? 'active' : props.forceHover ? 'forceHover' : props.hover ? 'hover' : 'default'
                    ]
                );

                if (props.clickable) {
                    finalClass.push('tw-cursor-pointer');
                }

                return finalClass;
            });

            const click = function (e) {
                emit('click', e);
            };

            const mousemove = function (e) {
                emit('mousemove', e);
            };

            const mousemoveThrottled = _throttle(mousemove, 100, {trailing: false});

            return {
                baseClass,
                click,
                mousemove,
                mousemoveThrottled,
            };
        },
    });
</script>

<style scope>
    .archived-striped {
        --stripe-color: rgba(255, 96, 0, 0.05);
        background-image: linear-gradient(
            45deg,
            var(--stripe-color) 25%,
            transparent 25%,
            transparent 50%,
            var(--stripe-color) 50%,
            var(--stripe-color) 75%,
            transparent 75%,
            transparent
        );
        background-size: 1rem 1rem;
    }
</style>
