import {Binding} from '@meekohq/lumos';

export interface NumericFormatterContractOptions {
    locale?: string;
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
}

export const NumericFormatterContractBinding = new Binding<NumericFormatterContract, NumericFormatterContractOptions>();

export interface NumericFormatterContract {
    getCurrencySymbol(currencyCode: string): string;

    formatNumber(value: number): string;

    formatCurrency(value: number, currency: string): string;
}
