import {Binding} from '@meekohq/lumos';

import type ContractModel from '@/modules/human-resources/models/ContractModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export const GetOverlappingContractsUseCaseBinding = new Binding<GetOverlappingContractsUseCase>();

export interface GetOverlappingContractsUseCase {
    getFromContract(contract: ContractModel, staff: StaffModel): Promise<ContractModel[]>;
}
