const timeRegex = /^(0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$/;

interface Period {
    startedAt: string;
    endedAt: string;
}

export default class TimePeriodValue {
    protected startedAt: string;
    protected endedAt: string;

    public constructor(period: Period) {
        if (!TimePeriodValue.validate(period)) {
            throw new Error('Bad time period format');
        }
        this.startedAt = period.startedAt;
        this.endedAt = period.endedAt;
    }

    public get value(): Period {
        return {
            startedAt: this.startedAt,
            endedAt: this.endedAt,
        };
    }

    public static validate(period: Period): boolean {
        let valid = true;

        if (!timeRegex.test(period.startedAt) || !timeRegex.test(period.endedAt)) {
            valid = false;
        }

        return valid;
    }
}
