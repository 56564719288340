<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('app:menu.kids_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <TopActions
                :current-display="__('common:yearly')"
                :date="date"
                :nursery="nursery"
                :print="false"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                :current-display="__('common:yearly')"
                :date="date"
                :nursery="nursery"
            />
            <MBox class="tw-mt-4 tw-p-5">
                <div>
                    <div class="row tw-mb-2">
                        <div class="col-6 col-sm-4 tw-order-2 tw-text-left sm:tw-order-1">
                            <MButton
                                variant="success"
                                @click="date = date.clone().subtract(1, 'years')"
                            >
                                <template #left-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                                </template>
                                {{ fromMoment(date).subYears(1).year }}
                            </MButton>
                        </div>
                        <div class="col-12 col-sm-4 tw-order-1 tw-mb-4 tw-text-center sm:tw-order-2">
                            <MHeading>
                                {{ fromMoment(date).year }}
                            </MHeading>
                            <CCenter>
                                <CText>
                                    {{ __('planning:yearly_rate_colon') }}
                                </CText>
                                <OccupationBadge
                                    class="tw-ml-1"
                                    :enable-count="false"
                                    :loading="occupationLoading"
                                    :occupations="rules"
                                />
                            </CCenter>
                        </div>
                        <div class="col-6 col-sm-4 tw-order-3 tw-text-right sm:tw-order-3">
                            <MButton
                                variant="success"
                                @click="date = date.clone().add(1, 'years')"
                            >
                                {{ fromMoment(date).addYears(1).year }}
                                <template #right-icons>
                                    <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                                </template>
                            </MButton>
                        </div>
                    </div>
                    <div v-if="!occupationLoading">
                        <div class="tw-flex tw-flex-wrap tw-justify-around md:tw-justify-between">
                            <template
                                v-for="(month, i) in months"
                                :key="'month' + i"
                            >
                                <Month
                                    :enable-click="true"
                                    :equal-months="true"
                                    :month="month"
                                    :rules="rules"
                                    :with-week-number="true"
                                    @day-clicked="goToDay"
                                />
                            </template>
                        </div>
                    </div>
                    <div
                        v-else
                        key="loader"
                    >
                        <loader
                            light="true"
                            shadow="false"
                            :title="__('common:loading_in_progress_dots')"
                        />
                    </div>
                </div>
            </MBox>
        </template>
    </SimplePageTemplate>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import _debounce from 'lodash-es/debounce';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import _isArray from 'lodash-es/isArray';
    import _isEmpty from 'lodash-es/isEmpty';
    import moment from 'moment';
    import {extendMoment} from 'moment-range';

    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useApi from '@/modules/app/composables/useApi';
    import useBroadcast from '@/modules/app/composables/useBroadcast';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import __ from '@/modules/app/utils/i18n-facade';
    import Month from '@/modules/legacy/components/Modules/Calendar/Month.vue';
    import * as ProcessedData from '@/modules/legacy/libs/ProcessedData';
    import route from '@/modules/legacy/libs/ziggy';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import OccupationBadge from '@/modules/planning/components/core/OccupationBadge.vue';
    import TopActions from '@/modules/planning/components/kid/TopActions.vue';
    import TopActionsSwitcher from '@/modules/planning/components/kid/TopActionsSwitcher.vue';

    export default {
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            TopActions,
            Month,
            OccupationBadge,
        },
        mixins: [nursery],
        props: ['nursery', 'user'],
        data: () => ({
            loading: {},
            rules: [],
            date: moment(),
            occupationLoading: false,
            currentGroup: {},
            planningDisplayPreference: window.localStorage.getItem('planningDisplayPreference'),
            Epoch,
        }),
        computed: {
            moment() {
                return moment;
            },
            window() {
                return window;
            },
            fromYear() {
                return this.date.clone().startOf('year').startOf('day');
            },
            toYear() {
                return this.date.clone().endOf('year').endOf('day');
            },
            months() {
                const range = extendMoment(moment).range(moment(this.fromYear), moment(this.toYear));

                return Array.from(range.by('month'));
            },
        },
        watch: {
            fromYear(val, oldVal) {
                if (val.format('YYYY-MM-DD') !== oldVal.format('YYYY-MM-DD')) {
                    this.debounceDate();
                }
            },
        },
        mounted() {
            if (this.$route.params.date) {
                this.date = moment(this.$route.params.date, 'DD/MM/Y');
            }

            useBroadcast().sessionChannel.bind('occupation', response => {
                ProcessedData.retrieve(response.processedDataUrl, response => {
                    const rules = [];

                    _forEach(response.data, day => {
                        const tooltip = [];
                        tooltip.push(
                            '<span class="tw-mr-1" style="font-size:12px;">' +
                                __('common:kid_with_count', {count: day.kids}) +
                                ' </span><span style="font-size:12px;">' +
                                day.percent +
                                '%' +
                                ' </span>'
                        );
                        if (!_isEmpty(day.anomalies)) {
                            tooltip.push('');
                            _forEach(day.anomalies, anomaly => {
                                tooltip.push(
                                    this.fromTimestamp(anomaly.started_at).toLocaleString(
                                        Epoch.presets.TIME_24_SIMPLE
                                    ) +
                                        '<i class="fa-solid fa-caret-right tw-ml-1 tw-mr-1"></i>' +
                                        this.fromTimestamp(anomaly.ended_at).toLocaleString(
                                            Epoch.presets.TIME_24_SIMPLE
                                        ) +
                                        ' ' +
                                        ' <span class="tw-ml-1 tw-my-0.5 tw-inline-flex tw-rounded-md tw-font-display tw-font-medium tw-bg-pink-200 tw-text-pink-800 tw-px-1.5 tw-py-0.5 tw-text-sm">' +
                                        __('common:kid_with_count', {count: anomaly.kids}) +
                                        '</span>'
                                );
                            });
                            const imploded = this.implode('<br>', tooltip);
                            rules.push({
                                day: moment(day.date),
                                class: 'bg-secondary-day tw-text-white',
                                tooltip: imploded ? imploded : undefined,
                                percent: day.percent,
                            });
                        } else {
                            const nbPercent = (this.nursery.places * this.nursery.occ_max_rate) / 100;
                            let bg = '';

                            if (day.kids > nbPercent) {
                                bg = 'bg-secondary-day';
                            } else if (day.kids < this.nursery.places) {
                                bg = '';
                            } else {
                                bg = 'bg-success-day';
                            }

                            const imploded = this.implode('<br>', tooltip);
                            rules.push({
                                day: moment(day.date),
                                class: bg,
                                tooltip: imploded ? imploded : undefined,
                                percent: day.percent,
                            });
                        }
                    });
                    this.rules = rules;
                    this.occupationLoading = false;
                });
            });

            this.getOccupation();
        },
        beforeUnmount() {
            useBroadcast().sessionChannel.unbind('occupation');
        },
        methods: {
            fromMoment: useEpoch().fromMoment,
            fromTimestamp: useEpoch().fromTimestamp,
            debounceDate: _debounce(function () {
                this.nursery.places && this.nursery.openingHours.length ? this.getOccupation() : null;
            }, 1000),
            getOccupation() {
                this.occupationLoading = true;
                useApi()
                    .legacy.get(route('nurseries.occupation', {nurseries: this.nursery.id}), {
                        params: {
                            from: this.fromYear.unix(),
                            to: this.toYear.unix(),
                            group_id: null,
                            interval: 60,
                        },
                    })
                    .catch(error => {
                        this.occupationLoading = false;
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, function (value) {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    });
            },
            ucfirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            implode(delimiter, collection) {
                const glue = delimiter || '';

                if (!_isArray(collection)) {
                    return false;
                }

                if (collection.length === 1) {
                    return collection[0];
                }

                let returnString = '';
                let i = 0;
                const len = collection.length;
                for (; i < len; i++) {
                    if (i !== 0) {
                        returnString += glue;
                    }
                    returnString += collection[i];
                }

                return returnString;
            },
            goToDay(day) {
                this.$router.push({
                    name: 'planning.kids',
                    params: {
                        nursery: this.nursery.id,
                    },
                    query: {
                        date: day.clone().unix(),
                    },
                });
            },
        },
    };
</script>
