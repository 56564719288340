<template>
    <CCard
        :border="false"
        :shadow="true"
    >
        <StaffRegister />
    </CCard>
</template>
<script>
    import StaffRegister from '@/modules/human-resources/components/registry/StaffRegister.vue';

    export default {
        components: {StaffRegister},
    };
</script>
