<template>
    <CVStack gap="4">
        <CInline
            align-x="between"
            align-y="baseline"
        >
            <MHeading level="h3">
                {{ __('hr:job_other') }}
            </MHeading>
            <CInline space="sm">
                <MTooltip
                    v-if="user.nurseries.length > 1"
                    :label="__('common:import_from_another_organization')"
                >
                    <MButton @click="importJobs">
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-download" />
                        </template>
                        {{ __('common:actions.import') }}
                    </MButton>
                </MTooltip>
                <MButton
                    :icon-plus="true"
                    variant="primary"
                    @click="$refs.createModal.show()"
                >
                    {{ __('hr:add_job') }}
                </MButton>
            </CInline>
            <CreateJobModal
                ref="createModal"
                @attach-job-to-orga="onAttachJobToOrga"
                @created="onJobCreated"
            />
        </CInline>

        <JobsList ref="JobsList" />
    </CVStack>
</template>

<script>
    import {collect} from '@meekohq/lumos';

    import useAuth from '@/modules/app/composables/useAuth';
    import JobsList from '@/modules/human-resources/components/job/List/JobsList.vue';
    import CreateJobModal from '@/modules/human-resources/components/job/Modals/CreateJobModal.vue';

    export default {
        components: {JobsList, CreateJobModal},
        data() {
            return {
                organizationJobs: collect(),
                everyJobs: collect(),
            };
        },
        computed: {
            user() {
                return useAuth().legacyUser.value;
            },
        },
        methods: {
            onJobCreated(jobModel) {
                this.$refs.JobsList.addToResult(jobModel);
            },

            onAttachJobToOrga(jobModel) {
                this.$refs.JobsList.attachJobToOrga(jobModel);
            },

            importJobs() {
                this.$refs.JobsList.importJobs();
            },
        },
    };
</script>
