<template>
    <MSelectMenu
        v-model="selectedPos"
        :disabled="disabled"
        :fallback-text="positions[0].text"
        :options="positions"
    />
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            modelValue: {
                type: Array as PropType<number[]>,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        setup(props, {emit}) {
            const selectedPos = ref(props.modelValue ?? [1]);

            const positions = [
                {value: [1], text: __('common:position.first')},
                {value: [2], text: __('common:position.second')},
                {value: [3], text: __('common:position.third')},
                {value: [4], text: __('common:position.fourth')},
                {value: [5], text: __('common:position.fifth')},
                {value: [-1], text: __('common:position.last')},
            ];

            watch(
                () => props.disabled,
                disabled => {
                    if (!disabled && (!selectedPos.value || !selectedPos.value?.length)) {
                        selectedPos.value = [1];
                    }
                }
            );

            watch(selectedPos, () => {
                emit('update:modelValue', selectedPos.value);
            });

            return {
                positions,
                selectedPos,
            };
        },
    });
</script>
