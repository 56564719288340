<template>
    <div class="TeamRequests">
        <div class="TeamRequests__header">
            <CText
                font-size="xl"
                font-weight="semibold"
            >
                {{ __('common:teams_request') }}
            </CText>
            <div class="TeamRequests__filters-container">
                <TeamsRequestsFilter
                    @selected-kids-groups="setKidsGroups"
                    @selected-organizations="setOrganizations"
                    @selected-periods="setPeriods"
                    @selected-request-types="setRequestTypes"
                    @selected-staffs="setStaffs"
                    @selected-type-status="setTypeStatus"
                />
                <MButton
                    class="tw-ml-auto"
                    variant="link"
                    @click="$emit('markAsRead')"
                >
                    {{ __('common:mark_all_as_read') }}
                </MButton>
            </div>
        </div>
        <div class="TeamRequests__list">
            <TeamsRequestsList
                ref="teamRequestListComponent"
                @prevent-click-outside="$emit('preventClickOutside', $event)"
            />
        </div>
    </div>
</template>

<script>
    import {defineComponent, ref} from 'vue';

    import TeamsRequestsFilter from '@/modules/request/components/Teams/TeamsRequestsFilter.vue';
    import TeamsRequestsList from '@/modules/request/components/Teams/TeamsRequestsList.vue';

    export default defineComponent({
        components: {TeamsRequestsFilter, TeamsRequestsList},
        emits: ['markAsRead', 'preventClickOutside'],
        setup() {
            const teamRequestListComponent = ref();

            const setStaffs = function (staffs) {
                teamRequestListComponent.value.setSelectedStaffs(staffs);
            };

            const setRequestTypes = function (requestTypes) {
                teamRequestListComponent.value.setSelectedRequestTypes(requestTypes);
            };

            const setPeriods = function (periods) {
                teamRequestListComponent.value.setSelectedPeriods(periods);
            };

            const setOrganizations = function (organizations) {
                teamRequestListComponent.value.setSelectedOrganizations(organizations);
            };

            const setTypeStatus = function (typeStatus) {
                teamRequestListComponent.value.setSelectedTypeStatus(typeStatus);
            };

            const setKidsGroups = function (kidsGroups) {
                teamRequestListComponent.value.setSelectedKidsGroups(kidsGroups);
            };

            return {
                teamRequestListComponent,
                setTypeStatus,
                setOrganizations,
                setStaffs,
                setRequestTypes,
                setPeriods,
                setKidsGroups,
            };
        },
    });
</script>

<style scoped>
    .TeamRequests {
    }

    .TeamRequests__header {
        @apply tw-flex tw-w-full tw-flex-col tw-gap-3 tw-p-3;
    }

    .TeamRequests__filters-container {
        @apply tw-flex tw-w-full tw-flex-wrap tw-items-center tw-gap-4 tw-rounded-xl tw-bg-blue-50 tw-p-2;
    }

    .TeamRequests__list {
        @apply tw-flex tw-flex-col;
        @apply tw-overflow-auto;
    }
</style>
