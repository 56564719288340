import {Epoch} from '@meekohq/lumos';

import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';

export default function useRegistrationFilters(storageKeyPrefix) {
    const {selectedOptions: registrationStatusFilter} = useUserFilters(
        `${storageKeyPrefix}-state`,
        OutputType.value,
        'pending'
    );

    const {selectedOptions: nameFilter} = useUserFilters(`${storageKeyPrefix}-name`, OutputType.value, '');

    const {selectedOptions: tagsFilters} = useUserFilters(`${storageKeyPrefix}-tags`, OutputType.array, []);

    const {selectedOptions: withoutTagsOptionIsSelected} = useUserFilters(
        `${storageKeyPrefix}-without-tags`,
        OutputType.value,
        false
    );

    const {selectedOptions: contractStartDate} = useUserFilters(
        `${storageKeyPrefix}-contract-start-date`,
        OutputType.value,
        Epoch.now().startOfMonth().toISOString()
    );

    const {selectedOptions: contractEndDate} = useUserFilters(
        `${storageKeyPrefix}-contract-end-date`,
        OutputType.value,
        Epoch.now().startOfMonth().toISOString()
    );

    return {
        registrationStatusFilter,
        nameFilter,
        tagsFilters,
        withoutTagsOptionIsSelected,
        contractStartDate,
        contractEndDate,
    };
}
