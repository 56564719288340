<script setup lang="ts">
    import {computed, type PropType} from 'vue';

    const props = defineProps({
        label: {type: String, default: ''},
        variant: {
            type: String as PropType<string>,
            default: 'light',
            required: false,
        },
        size: {
            type: String as PropType<string>,
            default: 'base',
            required: false,
        },
        align: {type: String, default: 'center'},
        truncate: {type: Boolean, default: true},
        loading: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        iconExternalLink: {type: Boolean, default: false},
        iconTrash: {type: Boolean, default: false},
        iconMenu: {type: Boolean, default: false},
        iconSort: {type: Boolean, default: false},
        iconPlus: {type: Boolean, default: false},
        iconEdit: {type: Boolean, default: false},
        iconEllipsis: {type: Boolean, default: false},
        round: {type: Boolean, default: false},
        zoom: {type: Boolean, default: false},
    });

    const disabledState = computed(() => {
        return props.disabled || props.loading;
    });
    const hasBeforeIcons = computed(() => {
        return props.loading || props.iconTrash || props.iconPlus || props.iconEdit || props.iconEllipsis;
    });
    const hasAfterIcons = computed(() => {
        return props.iconMenu || props.iconSort || props.iconExternalLink;
    });

    const styling = computed(() => {
        const output = [] as any[];

        output.push(sizeValues[(props.size as string) || 'base']);
        output.push(roundValues[props.round.toString()]);

        output.push(variantValues[(props.variant as string) || 'light']);
        output.push(zoomValues[props.zoom.toString()]);

        return output;
    });

    const contentClass = computed(() => {
        const output = [] as any[];

        if (props.truncate) {
            output.push('CButton__content--truncate');
        }

        output.push(alignValues[props.align]);

        return output;
    });

    const variantValues = {
        'none': '',
        // Theme
        'light': 'CButton--variant-light',
        'primary': 'CButton--variant-primary',
        'success': 'CButton--variant-success',
        'warning': 'CButton--variant-warning',
        'danger': 'CButton--variant-danger',
        'info': 'CButton--variant-info',
        // Colors
        'cyan': 'CButton--variant-cyan',
        'blue': 'CButton--variant-blue',
        'green': 'CButton--variant-green',
        'red': 'CButton--variant-red',
        'orange': 'CButton--variant-orange',
        'yellow': 'CButton--variant-yellow',
        'purple': 'CButton--variant-purple',
        'pink': 'CButton--variant-pink',
        'gray': 'CButton--variant-gray',
        'black': 'CButton--variant-black',
        // Others
        'ghost': 'CButton--variant-ghost',
        // Links
        'link': 'CButton--variant-link-primary',
        'link-danger': 'CButton--variant-link-danger',
        'link-orange': 'CButton--variant-link-orange',
        'link-purple': 'CButton--variant-link-purple',
        'primary-alt': 'CButton--variant-primary-alt',
        'light-alt': 'CButton--variant-light-alt',
        // dasheds
        'dashed-light': 'CButton--variant-dashed-light',
    };

    const sizeValues = {
        xs: 'CButton--size-xs',
        sm: 'CButton--size-sm',
        base: 'CButton--size-base',
        lg: 'CButton--size-lg',
    };

    const roundValues = {
        true: 'CButton--round',
        false: '',
    };

    const zoomValues = {
        true: 'CButton--zoom',
        false: '',
    };

    const alignValues = {
        left: 'CButton__content--align-left',
        center: 'CButton__content--align-center',
        right: 'CButton__content--align-right',
    };
</script>

<template>
    <button
        :class="['CButton', styling, 'tw-group']"
        :disabled="disabledState"
        type="button"
    >
        <div
            v-if="hasBeforeIcons || $slots['left-icons']"
            class="CButton__icons"
        >
            <font-awesome-icon
                v-if="loading"
                icon="fa-duotone fa-sync-alt"
                spin
            />
            <template v-else>
                <slot name="left-icons" />
                <font-awesome-icon
                    v-if="iconTrash"
                    icon="fa-solid fa-trash"
                />
                <font-awesome-icon
                    v-if="iconPlus"
                    icon="fa-solid fa-plus"
                />
                <font-awesome-icon
                    v-if="iconEdit"
                    icon="fa-duotone fa-pen"
                />
                <font-awesome-icon
                    v-if="iconEllipsis"
                    class="CButton__ellipsis-icon"
                    icon="fa-solid fa-ellipsis-h"
                />
            </template>
        </div>
        <template v-if="loading && round" />
        <div
            v-else-if="label || $slots['default']"
            class="CButton__content"
            :class="contentClass"
        >
            <slot>
                {{ label }}
            </slot>
        </div>
        <div
            v-if="$slots['right-icons']"
            class="CButton__icons"
        >
            <slot name="right-icons" />
        </div>
        <div
            v-if="hasAfterIcons || $slots['action-icons']"
            class="CButton__action-icons"
        >
            <slot name="action-icons" />
            <font-awesome-icon
                v-if="iconMenu"
                icon="fa-solid fa-caret-down"
            />
            <font-awesome-icon
                v-if="iconSort"
                icon="fa-solid fa-sort"
            />
            <font-awesome-icon
                v-if="iconExternalLink"
                icon="fa-solid fa-external-link-alt"
            />
        </div>
    </button>
</template>
