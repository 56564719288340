<script setup lang="ts">
    import {app, catcher, ValidationError} from '@meekohq/lumos';
    import {ref} from 'vue';

    import useError from '@/modules/app/composables/useError';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import {UpdatePersonalAccessTokenNameRequest} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenRequest';
    import {UpdatePersonalAccessTokenUseCaseBinding} from '@/modules/auth/personal-access-token/application/UpdatePersonalAccessTokenUseCase';
    import type PersonalAccessTokenModel from '@/modules/auth/personal-access-token/domain/PersonalAccessTokenModel';
    import {useSudoMode} from '@/modules/auth/sudo-mode/infrastructure/components/useSudoMode';

    const props = defineProps<{
        modal: ModalType;
        item: PersonalAccessTokenModel;
    }>();

    const emit = defineEmits<{
        (e: 'updated', model: PersonalAccessTokenModel): void;
    }>();

    const errors = useError();

    const isUpdating = ref(false);
    const name = ref(props.item.attributes.name!);

    async function updatePat() {
        const sudoToken = await useSudoMode().acquireSudoMode();

        if (sudoToken.isDismissed) {
            return;
        }

        isUpdating.value = true;
        errors.reset();

        try {
            const newItem = await app(UpdatePersonalAccessTokenUseCaseBinding).updateName(
                UpdatePersonalAccessTokenNameRequest.make({
                    // Clone the model to avoid modifying the list item before the request is successful
                    model: props.item.clone(),
                    name: name.value,
                    sudoToken: sudoToken.token,
                })
            );
            emit('updated', newItem);
        } catch (err) {
            catcher()
                .on(ValidationError, validationError => {
                    errors.addValidationError(validationError);
                })
                .catch(err);
        } finally {
            isUpdating.value = false;
        }
    }
</script>

<template>
    <MModal
        :header-title="__('user:edit_key')"
        :modal="modal"
    >
        <MForm @submit.prevent>
            <MInputGroup
                v-model="name"
                :errors="errors.get('name')"
                :label="__('common:name')"
                :placeholder="__('common:name')"
            />
        </MForm>
        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :label="__('common:actions.save')"
                :loading="isUpdating"
                variant="primary"
                @click="updatePat"
            />
        </template>
    </MModal>
</template>
