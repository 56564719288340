<template>
    <MModal
        :header-title="__('family_contract:new_contract')"
        :modal="modal"
        size="7xl"
    >
        <MTabs
            default-tab="terms"
            :tabs="tabsArray"
        >
            <template #tab-content="{label, meta}">
                <div class="tw-truncate">
                    {{ label }}
                </div>
                <MAlertBadge :visible="!!meta.hasError" />
            </template>
            <template #default="{activeTab}">
                <div class="tw-mt-4">
                    <ModuleTerms
                        v-if="activeTab === 'terms'"
                        :contract="contract"
                        :kid="kid"
                        :organization="nursery"
                    />
                    <ModulePlanning
                        v-if="activeTab === 'planning'"
                        :contract="contract"
                        :nursery="nursery"
                    />
                    <ModuleRessources
                        v-if="activeTab === 'ressources'"
                        :contract="contract"
                        :kid="kid"
                        :organization="nursery"
                    />
                    <ModuleBilling
                        v-show="activeTab === 'billing'"
                        :cashier-payload="cashierPayload"
                        :contract="contract"
                        :kid="kid"
                        :nursery="nursery"
                        :is-active="activeTab === 'billing'"
                        :user="user"
                    />
                    <ModuleCompany
                        v-if="activeTab === 'company'"
                        :cashier-payload="cashierPayload"
                        :contract="contract"
                        :kid="kid"
                        :nursery="nursery"
                        @has-errors="errors.company = $event"
                    />
                    <ModuleSepa
                        v-if="activeTab === 'sepa'"
                        :contract="contract"
                        :kid="kid"
                        :nursery="nursery"
                        :user="user"
                    />
                </div>
            </template>
        </MTabs>
        <template #footer-end="{hide}">
            <MButton
                variant="light"
                @click="hide"
            >
                {{ __('common:actions.cancel') }}
            </MButton>

            <MButton
                :disabled="hasErrors"
                :loading="loading"
                @click="save(1)"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-file-pen" />
                </template>
                {{ __('family_contract:save_in_draft') }}
            </MButton>
            <MButton
                :disabled="hasErrors"
                :loading="loading"
                variant="primary"
                @click="warnSave"
            >
                <template #left-icons>
                    <FontAwesomeIcon icon="fa-solid fa-check" />
                </template>
                {{ __('family_contract:confirm_contract_dots') }}
            </MButton>
        </template>
    </MModal>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import {set} from 'lodash-es';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';

    import useApi from '@/modules/app/composables/useApi';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import LegacyContractIntegrationService from '@/modules/family/utils/kid/LegacyContractIntegrationService';
    import route from '@/modules/legacy/libs/ziggy';
    import MAlertBadge from '@/modules/meeko-ui/components/MAlertBadge.vue';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    import Company from './Modal/Company.vue';
    import Tarifs from './Modal/Facturation.vue';
    import Modalites from './Modal/Modalites.vue';
    import Planning from './Modal/Planning.vue';
    import Ressources from './Modal/Ressources.vue';
    import Sepa from './Modal/Sepa.vue';

    export default {
        components: {
            MAlertBadge,
            ModuleTerms: Modalites,
            ModulePlanning: Planning,
            ModuleRessources: Ressources,
            ModuleBilling: Tarifs,
            ModuleSepa: Sepa,
            ModuleCompany: Company,
        },
        props: ['modal', 'kid', 'nursery', 'currentContract', 'user'],
        data: () => ({
            contract: {},
            loading: false,
            cashierPayload: {
                companyOrderModel: undefined,
                familyOrderModel: undefined,
                familyEndpoints: [],
                companyEndpoints: [],
                familyModel: undefined,
            },
            errors: {
                company: false,
            },
        }),
        computed: {
            tabsArray() {
                const tabs = {
                    terms: {
                        label: __('common:terms'),
                        meta: {
                            icon: 'fa-solid fa-pencil',
                        },
                    },
                };

                if (this.contract.type === 'recurrent') {
                    tabs.planning = {
                        label: __('common:planning_one'),
                        meta: {
                            icon: 'fa-solid fa-calendar',
                        },
                    };
                }

                if (this.$can('update', 'invoices')) {
                    tabs.ressources = {
                        label: __('common:resources'),
                        meta: {
                            icon: 'fa-solid fa-suitcase',
                        },
                    };
                    tabs.billing = {
                        label: __('common:billing'),
                        meta: {
                            icon: 'fa-solid fa-wallet',
                        },
                    };

                    if (this.contract.company) {
                        tabs.company = {
                            label: __('common:company_one'),
                            meta: {
                                icon: 'fa-solid fa-city',
                                hasError: this.errors.company,
                            },
                        };
                    }

                    tabs.sepa = {
                        label: __('common:sepa_direct_debit'),
                        meta: {
                            icon: 'fa-solid fa-university',
                        },
                    };
                }

                return tabs;
            },
            hasErrors() {
                return this.errors.company;
            },
        },
        mounted() {
            if (this.currentContract) {
                this.contract = this.currentContract;
            } else {
                this.contract = {
                    id: null,
                    started_at: Epoch.now().toFormat('yyyy-MM-dd'),
                    ended_at: Epoch.now().addMonths(1).toFormat('yyyy-MM-dd'),
                    recurrent_weeks: 1,
                    plannings: [],
                    exceptions: [],
                    type: 'recurrent',
                    draft: false,
                    first_family_member_id: null,
                    second_family_member_id: null,
                };
            }

            this.cashierPayload.companyOrderModel = undefined;
            this.cashierPayload.familyOrderModel = undefined;
            this.cashierPayload.familyModel = undefined;
            this.cashierPayload.familyEndpoints = [];
            this.cashierPayload.companyEndpoints = [];
            this.errors = {
                company: false,
            };
        },
        methods: {
            warnSave() {
                useMagicModal().confirmationModal({
                    title: __('family_contract:validate_contract_ask'),
                    text: __('family_contract:validate_contract_warning'),
                    type: 'warning',
                    onConfirm: async () => {
                        this.save(0);
                    },
                });
            },

            save(draft) {
                this.loading = true;
                if (
                    this.contract.temporaryFamilySepa &&
                    this.contract.temporaryFamilySepa.id === null &&
                    this.contract.temporaryCompanySepa &&
                    this.contract.temporaryCompanySepa.id === null
                ) {
                    this.saveSepa(error => {
                        if (error) {
                            this.loading = false;
                        } else {
                            this.saveSepa(
                                error => {
                                    if (error) {
                                        this.loading = false;
                                    } else {
                                        this.save(draft);
                                    }
                                },
                                this.contract.temporaryCompanySepa,
                                true
                            );
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryFamilySepa && this.contract.temporaryFamilySepa.id === null) {
                    this.saveSepa(error => {
                        if (error) {
                            this.loading = false;
                        } else {
                            this.save(draft);
                        }
                    }, this.contract.temporaryFamilySepa);
                } else if (this.contract.temporaryCompanySepa && this.contract.temporaryCompanySepa.id === null) {
                    this.saveSepa(
                        error => {
                            if (error) {
                                this.loading = false;
                            } else {
                                this.save(draft);
                            }
                        },
                        this.contract.temporaryCompanySepa,
                        true
                    );
                } else {
                    const data = _cloneDeep(this.contract);
                    data.draft = draft;

                    if (data.company_cycle === null || data.company_cycle === 0) {
                        data.company_cycle = 1;
                    }

                    if (this.contract.type === 'recurrent') {
                        data.exceptions.forEach(ex => {
                            // Get all dates between start and end
                            const diffDates = this.getDatesBetween(ex.started_at, ex.ended_at);

                            // Loop in those dates and add to plannings
                            diffDates.forEach(date => {
                                const exception = {
                                    day: null,
                                    type: 'tailored',
                                    week: null,
                                    note: ex.note,
                                    skip: 0,
                                };

                                const weekDiff = moment(date).week() - moment(this.contract.started_at).week() + 1;
                                const yearDiff = moment(date).year() - moment(this.contract.started_at).year();
                                exception.week = weekDiff + yearDiff * 52;
                                exception.day = moment(date).locale('en').format('ddd').toLowerCase();
                                if (exception.week >= 1) {
                                    data.plannings.push(exception);
                                }
                            });
                        });
                    } else {
                        data.plannings = [];
                    }

                    if (data.mode === 'manuel') {
                        data.cafpro_resources = null;
                    }

                    data.nursery_id = this.nursery.id;
                    data.kid_id = this.kid.id;

                    useApi()
                        .legacy.post(route('kid.contracts.store'), data)
                        .then(async response => {
                            const contract = response.data;

                            const contractExtension = new LegacyContractIntegrationService(
                                contract,
                                this.cashierPayload
                            );
                            await contractExtension.postCreate(response);

                            if (data.draft) {
                                useNotification().success(__('family_contract:contract_save_in_draft_successfully'));
                            } else {
                                useNotification().success(__('family_contract:contract_validated'));
                            }
                            await this.saveBillingConfig(data, contract);
                            this.loading = false;
                            this.kid.contracts.push(contract);

                            this.modal.hide();
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                        });
                }
            },

            saveBillingConfig(contract, responseContract) {
                return new Promise((resolve, reject) => {
                    const data = _cloneDeep(contract.config);

                    if (responseContract.id) {
                        data.contract_id = responseContract.id;
                    }

                    useApi()
                        .legacy.post(route('billingConfigs.store'), data)
                        .then(response => {
                            set(this.contract, 'config', response.data);
                            set(responseContract, 'config', response.data);
                            const index = this.kid.contracts.indexOf(responseContract);
                            set(this.kid.contracts, index, responseContract);
                            resolve();
                        })
                        .catch(error => {
                            if (error?.response?.status === 422) {
                                _forEach(error.response.data.errors, value => {
                                    useNotification().error(_head(value));
                                });
                            } else {
                                useNotification().error(error);
                            }
                            reject();
                        });
                });
            },

            saveSepa(callback, sepa, company = false) {
                useApi()
                    .legacy.post(route('sepas.store'), sepa)
                    .then(response => {
                        if (company) {
                            this.contract.company_sepa_mandate_id = response.data.id;
                            this.contract.temporaryCompanySepa = null;
                        } else {
                            this.contract.sepa_mandate_id = response.data.id;
                            this.contract.temporaryFamilySepa = null;
                        }
                        useApi()
                            .legacy.post(
                                route('nurseries.sepas.attach', {
                                    nurseries: this.nursery.id,
                                    sepa: response.data.id,
                                })
                            )
                            .then(() => {
                                callback();
                            })
                            .catch(error => {
                                this.loading = false;
                                if (error?.response?.status === 422) {
                                    _forEach(error.response.data.errors, value => {
                                        useNotification().error(_head(value));
                                    });
                                } else {
                                    useNotification().error(error);
                                }
                                callback(error);
                            });
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error?.response?.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                        callback(error);
                    });
            },

            getDatesBetween(startDate, endDate) {
                const dates = [];

                const currDate = moment(startDate).startOf('day');
                const lastDate = moment(endDate).startOf('day');

                dates.push(currDate.clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    dates.push(currDate.clone().toDate());
                }

                return dates;
            },
        },
    };
</script>
