<template>
    <MSelectMenu
        v-model="selectedFrequency"
        :options="frequencies"
        :placeholder="__('common:none')"
        @change="$emit('change', $event.target.value)"
    />
</template>

<script lang="ts">
    import {defineComponent, type PropType, ref, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import FrequencyValue from '@/modules/calendar/utils/RuleSet/values/FrequencyValue';

    export default defineComponent({
        props: {
            modelValue: {
                type: String as PropType<FrequencyValue | 'custom'>,
                default: null,
            },
            customizable: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            excludeFrequencies: {
                type: Array as PropType<FrequencyValue[]>,
                default: () => [FrequencyValue.second, FrequencyValue.minute, FrequencyValue.hour, FrequencyValue.once],
            },
        },
        emits: ['update:modelValue', 'change'],
        setup(props, {emit}) {
            const selectedFrequency = ref(props.modelValue);

            const frequencies: {text: string; value: string}[] = Object.keys(FrequencyValue)
                .filter(freq => !props.excludeFrequencies?.includes(freq as FrequencyValue))
                .map(freq => {
                    switch (freq) {
                        case 'year':
                            return {
                                value: freq,
                                text: __('calendar:yearly_one'),
                            };
                        case 'month':
                            return {
                                value: freq,
                                text: __('calendar:monthly_one'),
                            };
                        case 'week':
                            return {
                                value: freq,
                                text: __('calendar:weekly_one'),
                            };
                        case 'day':
                            return {
                                value: freq,
                                text: __('calendar:daily_one'),
                            };
                        case 'hour':
                            return {
                                value: freq,
                                text: __('calendar:every_hour'),
                            };
                        case 'minute':
                            return {
                                value: freq,
                                text: __('calendar:every_minute'),
                            };
                        case 'second':
                            return {
                                value: freq,
                                text: __('calendar:every_second'),
                            };
                        case 'once':
                            return {
                                value: freq,
                                text: __('calendar:once'),
                            };
                        default: {
                            throw new Error('Not implemented yet');
                        }
                    }
                });

            if (props.customizable) {
                frequencies.push({text: __('common:customize'), value: 'custom'});
            }

            watch(
                () => props.modelValue,
                freq => {
                    if (selectedFrequency.value !== props.modelValue) {
                        selectedFrequency.value = freq;
                    }
                }
            );

            watch(selectedFrequency, () => {
                if (selectedFrequency.value !== props.modelValue) {
                    emit('update:modelValue', selectedFrequency.value);
                }
            });

            return {
                frequencies,
                selectedFrequency,
            };
        },
    });
</script>
