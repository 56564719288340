<script setup lang="ts">
    import type {Epoch} from '@meekohq/lumos';
    import type {PropType} from 'vue';

    defineProps({
        month: {
            type: Object as PropType<Epoch>,
            required: true,
        },
        hideArrows: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits<{
        (e: 'previous', value: undefined): void;
        (e: 'next', value: undefined): void;
        (e: 'toggle-month', value: undefined): void;
        (e: 'toggle-year', value: undefined): void;
    }>();
</script>

<template>
    <div class="MDatePickerControl">
        <MButton
            v-show="!hideArrows"
            class="MDatePickerControl__arrow-button"
            variant="light-alt"
            @click="emit('previous', undefined)"
        >
            <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
        </MButton>
        <MButton
            class="MDatePickerControl__button"
            :truncate="false"
            variant="light-alt"
            @click="emit('toggle-month', undefined)"
        >
            {{ month.toLocaleString({month: 'long'}) }}
        </MButton>
        <MButton
            class="MDatePickerControl__button"
            variant="light-alt"
            @click="emit('toggle-year', undefined)"
        >
            {{ month.toLocaleString({year: 'numeric'}) }}
        </MButton>
        <MButton
            v-show="!hideArrows"
            class="MDatePickerControl__arrow-button"
            variant="light-alt"
            @click="emit('next', undefined)"
        >
            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
        </MButton>
    </div>
</template>

<style scoped>
    .MDatePickerControl {
        @apply tw-flex tw-w-full tw-items-baseline tw-gap-1;
    }

    .MDatePickerControl__button {
        @apply tw-flex-1 tw-bg-transparent tw-px-3 tw-py-1.5 tw-capitalize hover:tw-bg-primary-200;
    }

    .MDatePickerControl__arrow-button {
        @apply tw-bg-transparent tw-px-3 tw-py-1.5 hover:tw-bg-primary-200;
    }
</style>
