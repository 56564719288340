import {collect} from '@meekohq/lumos';
import type {Ref} from 'vue';
import {computed, toRef} from 'vue';

import useAuth from '@/modules/app/composables/useAuth';
import Manager from '@/modules/legacy/store/manager.store';
import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

export default function () {
    const activeOrganization = toRef(Manager, '_activeOrganization') as Ref<OrganizationModel>;
    const activeTenant = computed(() => activeOrganization.value.tenants().value().first());
    const activeTags = computed(() => activeOrganization.value.tags().value());
    const legacyCustomer = toRef(Manager, 'legacyCustomer');
    const legacyNursery = toRef(Manager, 'legacyNursery');
    const legacyAccount = toRef(Manager, 'legacyAccount');
    const language = toRef(Manager, 'language') as Ref<string>;
    const onTrial = toRef(Manager, 'onTrial') as Ref<boolean>;
    const subscribed = toRef(Manager, 'subscribed') as Ref<boolean>;

    const hasManyOrganizations = computed(() => {
        const {legacyUser} = useAuth();

        return legacyUser.value.nurseries.length > 1;
    });

    const organizationIds = computed(() => {
        const {legacyUser} = useAuth();

        return collect(legacyUser.value.nurseries).pluck<string>('id').toArray();
    });

    return {
        activeOrganization,
        activeTenant,
        activeTags,
        legacyCustomer,
        legacyNursery,
        legacyAccount,
        language,
        onTrial,
        subscribed,
        hasManyOrganizations,
        organizationIds,
    };
}
