<template>
    <div class="tw-flex tw-flex-col tw-gap-8">
        <div>
            <MHeading class="tw-mb-4">
                <FontAwesomeIcon
                    class="tw-mr-1 tw-text-primary-500"
                    icon="fa-solid fa-file"
                />
                {{ __('billing_setting:preset_format_proforma') }}
            </MHeading>
            <MForm>
                <CFormThreeColumns>
                    <MFieldset>
                        <MLabel>{{ __('billing_setting:proforma_default_name') }}</MLabel>
                        <MInput
                            id="proforma_name"
                            v-model="editedNursery.proforma_name"
                            :placeholder="__('billing_setting:proformat_default_name_example')"
                            @keyup.enter="save"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MLabel>
                            {{ __('billing_setting:proformat_reference') }}
                            <MHelpTooltip class="tw-ml-2">
                                {{ __('billing_setting:yyyy_and_mm_will_be_replaced_by_current_month_and_year') }}
                            </MHelpTooltip>
                        </MLabel>
                        <MInput
                            id="proforma_ref"
                            v-model="editedNursery.proforma_ref"
                            class="sm:tw-text-right"
                            :placeholder="__('billing_setting:proformat_reference_example')"
                            @keyup.enter="save"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('common:increment') }}</MLabel>
                        <MInput
                            id="proforma_num"
                            v-model="editedNursery.proforma_num"
                            placeholder="1"
                            @keyup.enter="save"
                        />
                    </MFieldset>
                </CFormThreeColumns>
            </MForm>
            <MHeading class="tw-mb-4 tw-mt-5">
                <FontAwesomeIcon
                    class="tw-mr-1 tw-text-primary-500"
                    icon="fa-solid fa-file-invoice"
                />
                {{ __('billing_setting:preset_format_invoice') }}
            </MHeading>
            <MForm>
                <CFormThreeColumns>
                    <MFieldset>
                        <MLabel>{{ __('billing_setting:default_invoice_name') }}</MLabel>
                        <MInput
                            id="name"
                            v-model="editedNursery.invoice_name"
                            :placeholder="__('billing_setting:monthly_invoice')"
                            @keyup.enter="save"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MLabel>
                            {{ __('billing_setting:invoice_reference') }}
                            <MHelpTooltip class="tw-ml-2">
                                {{ __('billing_setting:yyyy_and_mm_will_be_replaced_by_current_month_and_year') }}
                            </MHelpTooltip>
                        </MLabel>
                        <MInput
                            id="invoice_ref"
                            v-model="editedNursery.invoice_ref"
                            class="sm:tw-text-right"
                            :placeholder="__('billing_setting:invoice_reference_example')"
                            @keyup.enter="save"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('common:increment') }}</MLabel>
                        <MInput
                            id="invoice_num"
                            v-model="editedNursery.invoice_num"
                            placeholder="1"
                        />
                    </MFieldset>
                </CFormThreeColumns>
                <CFormThreeColumns>
                    <MFieldset>
                        <MLabel>{{ __('billing_setting:invoice_payable_within') }}</MLabel>
                        <CInputGroup class="tw-w-full">
                            <CInput
                                v-model.number="editedNursery.invoice_due_at"
                                placeholder="30"
                                type="number"
                            />
                            <CInputAddon class="tw-lowercase">
                                <template v-if="editedNursery.invoice_due_at && editedNursery.invoice_due_at > 1">
                                    {{ __('common:day_other') }}
                                </template>
                                <template v-else>
                                    {{ __('common:day_one') }}
                                </template>
                            </CInputAddon>
                        </CInputGroup>
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('billing_setting:default_vat') }}</MLabel>
                        <CInputGroup class="tw-w-full">
                            <CInput
                                v-model.number="editedNursery.invoice_tva"
                                placeholder="20"
                                type="number"
                            />
                            <CInputAddon>%</CInputAddon>
                        </CInputGroup>
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('common:curency_one') }}</MLabel>
                        <MSelectMenu
                            v-model="editedNursery.currency"
                            :options="currencies"
                            search-bar
                            text-path="text"
                            value-path="value"
                        />
                    </MFieldset>
                </CFormThreeColumns>
                <MFieldset>
                    <CCheckbox v-model="editedNursery.invoice_hours">
                        {{ __('billing_setting:display_hourly_rate_in_pdf_invoice') }}
                    </CCheckbox>
                </MFieldset>
                <CFormTwoColumns>
                    <MFieldset>
                        <MLabel>{{ __('billing_invoice:terms_and_conditions') }}</MLabel>
                        <MTextarea
                            v-model="editedNursery.invoice_conditions"
                            :placeholder="__('billing_setting:cash_cheque_payable_to_dots')"
                            rows="4"
                        />
                    </MFieldset>
                    <MFieldset>
                        <MLabel>{{ __('billing_setting:footer') }}</MLabel>
                        <MTextarea
                            v-model="editedNursery.invoice_footer"
                            :placeholder="`${editedNursery.name}..`"
                            rows="4"
                        />
                    </MFieldset>
                </CFormTwoColumns>
            </MForm>
        </div>
        <CDisclosure
            :open="false"
            :title="__('common:additional_information')"
        >
            <MFieldset class="md:tw-w-1/3">
                <MLabel>
                    {{ __('billing_setting:pdf_invoice_name') }}
                    <MHelpTooltip class="tw-ml-2">
                        {{ __('billing_setting:number_will_be_replaced_by_invoice_number') }}
                    </MHelpTooltip>
                </MLabel>
                <CInputGroup class="tw-w-full sm:tw-pr-4">
                    <CInput
                        v-model="editedNursery.invoice_filename"
                        :placeholder="__('billing_setting:pdf_invoice_name')"
                    />
                    <CInputAddon class="tw-lowercase">
                        {{ __('common:pdf_extension') }}
                    </CInputAddon>
                </CInputGroup>
            </MFieldset>
        </CDisclosure>
        <MButton
            class="tw-self-end"
            :loading="saving"
            variant="primary"
            @click="save"
        >
            {{ __('common:actions.save') }}
        </MButton>
    </div>
</template>

<script>
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        props: {
            nursery: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                saving: false,
                editedNursery: {...this.nursery},
                currencies: [
                    {
                        value: null,
                        text: __('billing_setting:choose_devise'),
                    },
                    {
                        value: '€',
                        text: __('billing_setting:devise.euro'),
                    },
                    {
                        value: '$',
                        text: __('billing_setting:devise.united_states_dollars'),
                    },
                    {
                        value: '£',
                        text: __('billing_setting:devise.pound_sterling'),
                    },
                    {
                        value: '₪',
                        text: __('billing_setting:devise.shekel'),
                    },
                    {
                        value: 'CHF',
                        text: __('billing_setting:devise.swiss_franc'),
                    },
                    {
                        value: 'DJF',
                        text: __('billing_setting:devise.djiboutian_franc'),
                    },
                    {
                        value: 'XAF',
                        text: __('billing_setting:devise.west_afrikan_cfa_franc'),
                    },
                    {
                        value: 'XOF',
                        text: __('billing_setting:devise.central_africa_cfa_franc'),
                    },
                    {
                        value: 'KMF',
                        text: __('billing_setting:devise.comorian_franc'),
                    },
                    {
                        value: 'XPF',
                        text: __('billing_setting:devise.pacific_franc'),
                    },
                    {
                        value: 'MUR',
                        text: __('billing_setting:devise.mauritian_rupee'),
                    },
                    {
                        value: 'MAD',
                        text: __('billing_setting:devise.moroccan_dirham'),
                    },
                ],
            };
        },
        methods: {
            save() {
                this.saving = true;
                useApi()
                    .legacy.put(
                        route('nurseries.update', {
                            nurseries: this.editedNursery.id,
                        }),
                        {
                            proforma_name: this.editedNursery.proforma_name,
                            proforma_ref: this.editedNursery.proforma_ref,
                            proforma_num: this.editedNursery.proforma_num,
                            invoice_name: this.editedNursery.invoice_name,
                            invoice_ref: this.editedNursery.invoice_ref,
                            invoice_num: this.editedNursery.invoice_num,
                            invoice_due_at: this.editedNursery.invoice_due_at,
                            invoice_tva: this.editedNursery.invoice_tva,
                            currency: this.editedNursery.currency,
                            invoice_conditions: this.editedNursery.invoice_conditions,
                            invoice_footer: this.editedNursery.invoice_footer,
                            invoice_hours: this.editedNursery.invoice_hours,
                            invoice_filename: this.editedNursery.invoice_filename,
                        }
                    )
                    .then(response => {
                        // Sync global nursery with edited nursery changes
                        this.onNurseryUpdated(response.data);

                        useNotification().success(__('common:parameters_updated_successfully'));
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 422) {
                            _forEach(error.response.data.errors, value => {
                                useNotification().error(_head(value));
                            });
                        } else {
                            useNotification().error(error);
                        }
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },
            onNurseryUpdated(editedNursery) {
                this.nursery.proforma_name = editedNursery.proforma_name;
                this.nursery.proforma_ref = editedNursery.proforma_ref;
                this.nursery.proforma_num = editedNursery.proforma_num;
                this.nursery.invoice_name = editedNursery.invoice_name;
                this.nursery.invoice_ref = editedNursery.invoice_ref;
                this.nursery.invoice_num = editedNursery.invoice_num;
                this.nursery.invoice_due_at = editedNursery.invoice_due_at;
                this.nursery.invoice_tva = editedNursery.invoice_tva;
                this.nursery.currency = editedNursery.currency;
                this.nursery.invoice_conditions = editedNursery.invoice_conditions;
                this.nursery.invoice_footer = editedNursery.invoice_footer;
                this.nursery.invoice_hours = editedNursery.invoice_hours;
                this.nursery.invoice_filename = editedNursery.invoice_filename;
            },
        },
    };
</script>
