<script setup lang="ts">
    import {computed} from 'vue';

    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useModal from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import UpdateContactModal from '@/modules/cashier/components/core/contact/organisms/UpdateContactModal.vue';
    import type ContactModel from '@/modules/cashier/models/ContactModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    const props = defineProps<{contactModel: ContactModel}>();

    const emit = defineEmits<{
        (event: 'deleted' | 'updated', contactModel: ContactModel): void;
    }>();

    async function deleteContact() {
        await useMagicModal().deleteConfirmationModal({
            title: __('billing_customer:delete_contact'),
            text: __('billing_customer:action_will_permanently_delete_contact_action_is_irreversible'),
            onConfirm: async () => {
                await props.contactModel.delete().then(() => {
                    useNotification().success(__('billing_customer:contact_deleted'));
                    emit('deleted', props.contactModel);
                });
            },
        });
    }

    const editModal = useModal({
        component: UpdateContactModal,
        props: {
            contactModel: computed(() => props.contactModel.clone()),
        },
        listeners: () => ({
            updated: (contactModel: ContactModel) => {
                emit('updated', contactModel);
            },
        }),
    });
</script>

<template>
    <div>
        <MMenu>
            <MMenuButton>
                <MButton
                    icon-ellipsis
                    size="sm"
                    variant="ghost"
                />
            </MMenuButton>
            <MMenuItems>
                <MMenuItem
                    :label="__('billing_core:modify_contact')"
                    @click="editModal.show()"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-pen"
                        />
                    </template>
                </MMenuItem>
                <MMenuItem
                    :label="__('billing_core:delete_contact_dots')"
                    variant="danger"
                    @click="deleteContact"
                >
                    <template #icon>
                        <FontAwesomeIcon
                            fixed-width
                            icon="fa-solid fa-trash"
                        />
                    </template>
                </MMenuItem>
            </MMenuItems>
        </MMenu>
    </div>
</template>
