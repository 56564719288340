<script setup lang="ts">
    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';

    const props = defineProps<{
        customer: CustomerModel;
    }>();

    const {format} = useFormatCurrency(props.customer.computed.currency_iso_code);
</script>

<template>
    <div class="AvailableCreditAmount">
        <FontAwesomeIcon
            class="tw-text-green-500"
            fixed-width
            icon="fa-solid fa-circle"
        />
        {{
            __('billing_customer:available_credit_amount', {amount: format(props.customer.availableTotalCreditAmount)})
        }}
    </div>
</template>

<style scoped>
    .AvailableCreditAmount {
        @apply tw-flex tw-items-center tw-gap-1.5 tw-text-sm tw-text-gray-500;
    }
</style>
