import Bugsnag from '@bugsnag/js';
import {datadogRum} from '@datadog/browser-rum';
import {computed, watch} from 'vue';

import services from '@/config/services';
import {useLaunchDarkly} from '@/modules/app/composables/useLaunchDarkly';

export function useFeatureFlag(key: string, defaultValue = false) {
    const {allFlags} = useLaunchDarkly();

    const value = computed(() => {
        return allFlags.value[key] ?? defaultValue;
    });

    watch(
        value,
        flagValue => {
            datadogRum.addFeatureFlagEvaluation(key, flagValue);

            if (services.bugsnag.enabled) {
                if (flagValue) {
                    Bugsnag.addFeatureFlag(key);
                } else {
                    Bugsnag.clearFeatureFlag(key);
                }
            }
        },
        {immediate: true}
    );

    return value;
}
