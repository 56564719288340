import type ISplittable from '@/modules/legacy/libs/periodSplitter/ISplittable';

export default class SplittableEvent implements ISplittable {
    public startedAt: number;
    public endedAt: number;
    public sources: any[];
    public isFullDay: boolean;

    public constructor(startedAt: number, endedAt: number, sources: any[] = [], isFullDay = false) {
        this.startedAt = startedAt;
        this.endedAt = endedAt;
        this.sources = sources;
        this.isFullDay = isFullDay;
    }
}
