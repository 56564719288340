import {Epoch} from '@meekohq/lumos';

import KidModel from '@/modules/family/models/KidModel';

/**
 * Composable to use the KidModel's age calculation fron everywhere in the app.
 */
export default function useKidAge() {
    /**
     * Get the age of a kid by its id. Fetches the model and call model's method.
     */
    async function getById(id: string, compareDate?: Epoch) {
        const model = await KidModel.find(id);

        return getFromModel(model, compareDate);
    }

    /**
     * Get the age of a kid by its birthdate. Create a fake model and call model's method.
     */
    function getByBirthdate(birthdate?: string, compareDate?: string) {
        const model = new KidModel();

        const epochCompareDate = compareDate ? Epoch.fromISOString(compareDate) : undefined;
        model.attributes.birthdate = birthdate;

        return getFromModel(model, epochCompareDate);
    }

    function getFromModel(model: KidModel, compareDate?: Epoch) {
        if (compareDate) {
            return model.getHumanAgeAtDate(compareDate);
        }

        return model.humanAge;
    }

    return {
        getById,
        getByBirthdate,
        getFromModel,
    };
}
