<template>
    <MTooltip
        class="Segment"
        :hoverable="!hideTooltip && tooltipTextFn && tooltipTextFn(segment).length > 0"
        :style="stylingSegmentFn(segment)"
    >
        <div class="Segment__label">
            {{ segment.count }}
        </div>
        <template #content>
            <div v-html="tooltipTextFn?.(segment)" />
        </template>
    </MTooltip>
</template>

<script lang="ts">
    import {defineComponent, type PropType} from 'vue';

    export default defineComponent({
        props: {
            segment: {
                type: Object as PropType<{count: number}>,
                required: true,
            },
            stylingSegmentFn: {
                type: Function as PropType<(segment: any) => object>,
                default: () => {},
            },
            tooltipTextFn: {
                type: Function as PropType<(segment: any) => string>,
                default: undefined,
            },
            hideTooltip: {
                type: Boolean,
                default: false,
            },
        },
    });
</script>

<style scoped>
    .Segment {
        @apply tw-absolute tw-flex tw-h-full tw-items-center tw-justify-center;
        @apply tw-text-ellipsis tw-text-sm tw-font-semibold tw-text-white;
        @apply first:tw-rounded-l-inherit last:tw-rounded-r-inherit;
    }

    .Segment__label {
        @apply tw-text-sm tw-font-semibold tw-text-white;
    }
</style>
