<template>
    <div>
        <MSelectMenu
            v-model="status"
            size="sm"
            :fallback-text="__('billing_customer:all_entities')"
            multi
            :options="options"
        />
    </div>
</template>

<script lang="ts">
    import {defineComponent, type PropType, reactive, ref, watch} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        components: {},
        props: {
            modelValue: {type: Array as PropType<string[]>, default: () => reactive([])},
        },
        emits: ['value:updated'],
        setup(props, {emit}) {
            const status = ref(props.modelValue);
            const options = [
                {
                    text: __('billing_customer:entity_legal'),
                    value: 'legal',
                },
                {
                    text: __('billing_customer:entity_natural'),
                    value: 'natural',
                },
            ];

            watch(
                () => props.modelValue,
                v => {
                    status.value = v;
                }
            );

            watch(status, val => {
                emit('value:updated', val);
            });

            return {
                status,
                options,
            };
        },
    });
</script>
