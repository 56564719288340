<template>
    <MPopover
        class="CustomersListItemDetailsPopover"
        trigger="hover"
    >
        <MHoverable class="tw-min-w-0">
            <div
                class="CustomersListItemDetailsPopover__price"
                :class="{
                    'tw-text-gray-500': customerStateAmount === 0,
                    'tw-text-red-600': customer.computed.unpaid_payment_amount > 0,
                    'tw-font-semibold': customerStateAmount !== 0,
                }"
            >
                {{ format(customerStateAmount) }}
            </div>
        </MHoverable>
        <template #content>
            <div class="CustomersListItemDetailsPopover__container">
                <MBillingDetailItem
                    color="green"
                    :subtitle="
                        __('billing_core:invoice_count', {
                            count: customer.computed.invoice_count - customer.computed.draft_invoice_count,
                        })
                    "
                    :title="__('common:billed_other')"
                    :model-value="format(customer.computed.sales_amount)"
                />
                <MBillingDetailItem
                    color="green"
                    :subtitle="__('billing_customer:balance_states.cashed')"
                    :title="__('common:transactions')"
                    :model-value="format(customer.computed.succeeded_transaction_amount)"
                />
                <MBillingDetailItem
                    color="blue"
                    :subtitle="__('billing_core:invoice_count', {count: customer.computed.draft_invoice_count})"
                    :title="__('billing_customer:balance_states.in_coming')"
                    :model-value="format(customer.computed.draft_payment_amount)"
                />
                <MBillingDetailItem
                    color="orange"
                    :title="__('common:pending')"
                    :model-value="
                        format(customer.computed.pending_payment_amount + customer.computed.upcoming_payment_amount)
                    "
                />
                <MBillingDetailItem
                    color="red"
                    :title="__('common:unpaid_one')"
                    :model-value="format(customer.computed.unpaid_payment_amount)"
                />
                <MBillingDetailItem
                    v-if="customer.computed.uncollectible_payment_amount > 0"
                    color="red"
                    :title="__('common:uncollectible_one')"
                    :model-value="format(customer.computed.uncollectible_payment_amount)"
                />
                <MBillingDetailItem
                    color="gray"
                    :title="__('billing_customer:balance_states.balance')"
                    :model-value="format(customer.computed.balance_amount)"
                />
            </div>
        </template>
    </MPopover>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {computed, defineComponent} from 'vue';

    import useFormatCurrency from '@/modules/cashier/composables/useFormatCurrency';
    import type CustomerModel from '@/modules/cashier/models/CustomerModel';
    import MBillingDetailItem from '@/modules/meeko-ui/components/MBillingDetailItem.vue';

    export default defineComponent({
        components: {MBillingDetailItem},
        props: {
            customer: {type: Object as PropType<CustomerModel>, required: true},
        },
        setup(props) {
            // Get currency formatter from country code
            const {format} = useFormatCurrency(props.customer.computed.currency_iso_code);

            /**
             * Customer state amount
             */
            const customerStateAmount = computed(() => {
                if (props.customer.isUnpaid) {
                    return props.customer.computed.unpaid_payment_amount;
                } else if (props.customer.isPending) {
                    return (
                        props.customer.computed.pending_payment_amount + props.customer.computed.upcoming_payment_amount
                    );
                } else {
                    return props.customer.computed.balance_amount;
                }
            });

            return {
                customerStateAmount,
                format,
            };
        },
    });
</script>

<style scoped>
    .CustomersListItemDetailsPopover__price {
        @apply tw-truncate;
    }

    .CustomersListItemDetailsPopover__container {
        @apply tw-flex tw-flex-col tw-gap-2 tw-p-2;
    }
</style>
