import {datadogRum} from '@datadog/browser-rum';
import type {Collection} from '@meekohq/lumos';
import {collect, Epoch} from '@meekohq/lumos';
import mitt, {type Emitter} from 'mitt';
import type {ComputedRef, Ref} from 'vue';
import {ref} from 'vue';

import AllocationModel from '@/modules/cashier/models/AllocationModel';
import CurrencyModel from '@/modules/cashier/models/CurrencyModel';
import CustomerModel from '@/modules/cashier/models/CustomerModel';
import InvoiceModel from '@/modules/cashier/models/InvoiceModel';
import PaymentMethodModel from '@/modules/cashier/models/PaymentMethodModel';
import PaymentModel from '@/modules/cashier/models/PaymentModel';
import TenantModel from '@/modules/cashier/models/TenantModel';
import TransactionModel from '@/modules/cashier/transaction/domain/TransactionModel';
import TransactionStatusValue from '@/modules/cashier/transaction/domain/TransactionStatusValue';
import type {TransactionErrorReturnType} from '@/modules/cashier/transaction/infrastructure/components/useTransactionError';
import useTransactionError from '@/modules/cashier/transaction/infrastructure/components/useTransactionError';

export interface TransactionEmitter {
    error: Error;
    reset_error: void;
    saved: TransactionModel;
    deleted: TransactionModel;
    hide: void;
}

export interface TransactionStateReturnType {
    transaction: Ref<TransactionModel>;
    tenant: Ref<TenantModel | undefined>;
    customer: Ref<CustomerModel | undefined>;
    paymentMethod: Ref<PaymentMethodModel | undefined>;
    currency: Ref<CurrencyModel | undefined>;
    allocations: Ref<Collection<AllocationModel>>;
    invoiceAllocation: Ref<AllocationModel | undefined>;
    bus: Emitter<TransactionEmitter>;
    transactionError: TransactionErrorReturnType;
    sumOtherAllocations: ComputedRef<number>;
}

export default function useTransactionState(): TransactionStateReturnType {
    const transaction = ref(new TransactionModel()) as Ref<TransactionModel>;
    const tenant = ref<TenantModel | undefined>();
    const customer = ref<CustomerModel | undefined>();
    const paymentMethod = ref<PaymentMethodModel | undefined>();
    const currency = ref<CurrencyModel | undefined>();
    const allocations = ref(collect()) as Ref<Collection<AllocationModel>>;
    const invoiceAllocation = ref<AllocationModel | undefined>();

    const bus = mitt<TransactionEmitter>();
    const transactionError = useTransactionError(transaction, allocations, invoiceAllocation);

    return {
        transaction,
        tenant,
        customer,
        paymentMethod,
        currency,
        allocations,
        invoiceAllocation,
        bus,
        transactionError,
        sumOtherAllocations: transactionError.sumOtherAllocations,
    };
}

export type TransactionAttributesParamType = Required<Pick<TransactionModel['attributes'], 'tenant_id'>> &
    Partial<TransactionModel['attributes']>;

export async function makeNewTransactionState(
    state: TransactionStateReturnType,
    transactionAttributes: TransactionAttributesParamType
): Promise<void> {
    // Init a new transaction
    state.transaction.value = new TransactionModel();
    state.transaction.value.attributes.amount = 0;
    state.transaction.value.attributes.status = TransactionStatusValue.succeeded;
    state.transaction.value.attributes.date = Epoch.now().toISOString();
    state.transaction.value.fill(transactionAttributes);

    // Associate it with the tenant, tenant currency, customer and payment method if they are provided
    if (transactionAttributes.customer_id) {
        state.customer.value = await CustomerModel.find(transactionAttributes.customer_id);
    }

    if (!transactionAttributes.tenant_id) {
        throw new Error('Tenant ID is required');
    }

    state.tenant.value = await TenantModel.find(transactionAttributes.tenant_id);

    state.currency.value = await CurrencyModel.query().where('tenant_id', transactionAttributes.tenant_id).first();

    // We associate the 'transfer' payment method by default
    state.paymentMethod.value = await PaymentMethodModel.query()
        .where('tenant_id', transactionAttributes.tenant_id)
        .where('internal_id', PaymentMethodModel.TRANSFER_INTERNAL_ID)
        .first();

    datadogRum.addAction('Après création de la transaction et le set du customer', {
        transaction: state.transaction.value.attributes,
        customer: state.customer,
    });
}

export async function makeEditionTransactionState(
    state: TransactionStateReturnType,
    transactionId: string,
    paymentId?: string
): Promise<void> {
    state.transaction.value = await TransactionModel.query()
        .with(new TransactionModel().tenant())
        .with(new TransactionModel().customer())
        .with(new TransactionModel().paymentMethod())
        .with(new TransactionModel().currency())
        .with(new TransactionModel().allocationsAsSource(), query2 => {
            query2.with(new AllocationModel().destination(), query3 => {
                query3.with(new PaymentModel().invoices(), query4 => {
                    query4.with(new InvoiceModel().payments());
                });
            });
        })
        .with(new TransactionModel().allocationsAsDestination(), query2 => {
            query2.with(new AllocationModel().source(), query3 => {
                query3.with(new PaymentModel().creditNotes(), query4 => {
                    query4.with(new InvoiceModel().refunds());
                });
            });
        })
        .find(transactionId);

    // Clone the transaction and its relations for update
    state.transaction.value = state.transaction.value.clone();
    state.tenant.value = state.transaction.value.tenant().value().clone();
    state.customer.value = state.transaction.value.customer().value().clone();
    state.paymentMethod.value = state.transaction.value.paymentMethod().value()?.clone();
    state.currency.value = state.transaction.value.currency().value().clone();

    if (state.transaction.value.isDebit) {
        state.allocations.value = state.transaction.value
            ?.allocationsAsDestination()
            .value()
            .map(allocation => {
                mapPaymentToAllocation(allocation);
                allocation.setRelation('source', allocation.extra.payment);

                return allocation;
            }) as Collection<AllocationModel>;
    } else {
        state.allocations.value = state.transaction.value
            ?.allocationsAsSource()
            .value()
            .map(allocation => {
                mapPaymentToAllocation(allocation);
                allocation.setRelation('destination', allocation.extra.payment);

                return allocation;
            }) as Collection<AllocationModel>;
    }

    // If paymentId is set, we filter allocations to find the allocation related to the payment
    if (paymentId) {
        state.allocations.value = state.allocations.value.filter(allocation => {
            if (allocation.extra.payment.getKey() === paymentId) {
                state.invoiceAllocation.value = allocation;
            }

            return allocation.extra.payment.getKey() !== paymentId;
        });
    }

    state.transaction.value.extra.originalAllocationsCount = state.allocations.value.count();
}

// Map payment to allocation data needed for the UI pruposes
export function mapPaymentToAllocation(allocation: AllocationModel) {
    const payment = allocation.source().value() ? allocation.source().value() : allocation.destination().value();

    // A payment can only have one destination or source invoice
    const invoice = payment.invoicesRelation.first();
    const hasInvoiceMultiPayments = invoice.paymentsRelation.count() > 1;

    allocation.extra.payment = payment.clone();
    allocation.extra.invoice = invoice.clone();
    allocation.extra.hasInvoiceMultiPayments = hasInvoiceMultiPayments;
    allocation.extra.invoicePayments = invoice.paymentsRelation;

    return allocation;
}
