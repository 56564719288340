<template>
    <div class="MagicIndexEmptyList">
        <h1 class="MagicIndexEmptyList__title">
            <slot name="empty-list" />
        </h1>
        <h2 class="MagicIndexEmptyList__action">
            <slot name="empty-list-action" />
        </h2>
    </div>
</template>

<style scoped>
    .MagicIndexEmptyList {
        @apply tw-flex tw-flex-col tw-items-start tw-p-10 sm:tw-items-center;
    }

    .MagicIndexEmptyList__title {
        @apply tw-text-center tw-text-2xl tw-font-semibold tw-text-black;
    }

    .MagicIndexEmptyList__action {
        @apply tw-text-center tw-font-medium tw-text-gray-400;
    }
</style>
