<template>
    <MForm>
        <MFieldset>
            <MLabel>{{ __('activity:meeting_name') }}</MLabel>
            <CInput
                v-model="meeting.attributes.name"
                autofocus
            />
            <CFormErrorMessageList :errors="meetingErrors.getErrors('name')" />
        </MFieldset>
        <MFieldset>
            <MLabel>{{ __('common:location') }}</MLabel>
            <CInputSuggestions
                v-model="meeting.attributes.location"
                :suggestions="suggestedLocations"
            />
            <CFormErrorMessageList :errors="meetingErrors.getErrors('location')" />
        </MFieldset>
        <CFormTwoColumns>
            <MFieldset>
                <MLabel>{{ __('activity:start_of_meeting') }}*</MLabel>
                <CFormDatepicker
                    v-model="meeting.attributes.datetime_period.started_at"
                    :time="true"
                />
                <CFormErrorMessageList :errors="meetingErrors.getErrors('datetime_period.started_at')" />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('activity:end_of_meeting') }}*</MLabel>
                <CFormDatepicker
                    v-model="meeting.attributes.datetime_period.ended_at"
                    :time="true"
                />
                <CFormErrorMessageList :errors="meetingErrors.getErrors('datetime_period.ended_at')" />
            </MFieldset>
            <MFieldset>
                <MLabel>{{ __('activity:organized_by_female') }}</MLabel>
                <StaffFinder
                    button-class="tw-w-full"
                    has-unselect
                    :registration="registration"
                    :model-value="meeting.reporter().value()"
                    @update:model-value="meeting.reporter().associate($event)"
                />
                <CFormErrorMessageList :errors="meetingErrors.getErrors('reporter_id')" />
            </MFieldset>
            <MFieldset>
                <MLabel>
                    {{ __('activity:participant_state_other') }}
                    <MHelpTooltip class="tw-ml-2">
                        {{ __('activity:participants_will_be_notified') }}
                    </MHelpTooltip>
                </MLabel>
                <ParticipantsInput
                    v-model="participants"
                    :custom-select="participantsCustomSelect"
                    :meeting="meeting"
                    :registration="registration"
                />
                <CFormErrorMessageList :errors="meetingErrors.getErrors('participants')" />
            </MFieldset>
        </CFormTwoColumns>
        <MFieldset>
            <MLabel>{{ __('common:comment') }}</MLabel>
            <MTextarea v-model="meeting.attributes.description" />
            <CFormErrorMessageList :errors="meetingErrors.getErrors('description')" />
        </MFieldset>
        <MFieldset>
            <MLabel class="tw-border-t tw-border-gray-200 tw-pt-3">
                {{ __('activity:internal_note') }}
            </MLabel>
            <MTextarea
                v-model="meeting.attributes.internal_note"
                class="!tw-bg-yellow-200"
                :placeholder="__('activity:private_note_that_will_not_be_visible')"
            />
            <CFormErrorMessageList :errors="meetingErrors.getErrors('internal_note')" />
        </MFieldset>
        <CHStack
            distribute="end"
            gap="2"
        >
            <MButton
                :label="__('common:actions.cancel')"
                @click="undo"
            />
            <MButton
                :disabled="disableSaveButton"
                :label="__('common:actions.save')"
                :loading="loading"
                variant="primary"
                @click="save"
            />
        </CHStack>
    </MForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref} from 'vue';

    import ParticipantsInput from '@/modules/activity/components/ParticipantsInput.vue';
    import usePreFilledMeetingForm from '@/modules/activity/composables/usePreFilledMeetingForm';
    import type MeetingModel from '@/modules/activity/models/MeetingModel';
    import type {CustomSelectType} from '@/modules/activity/utils/CustomSelectType';
    import MeetingStateValue from '@/modules/activity/utils/MeetingStateValue';
    import useManager from '@/modules/app/composables/useManager';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export default defineComponent({
        components: {ParticipantsInput, StaffFinder},
        props: {
            meeting: {
                type: Object as PropType<MeetingModel>,
                required: true,
            },
            participantsCustomSelect: {
                type: Object as PropType<CustomSelectType>,
                default: null,
            },
            registration: {type: RegistrationModel, required: true},
            disableSaveButton: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['saved', 'undo'],
        setup(props, {emit}) {
            const {activeOrganization} = useManager();
            const loading = ref(false);

            const participants = ref(props.meeting.participants().value());

            const meetingErrors = ref(new ErrorHandler());

            const {suggestedLocations} = usePreFilledMeetingForm(props.meeting);

            async function save() {
                const meeting = props.meeting;
                loading.value = true;
                meetingErrors.value.reset();

                try {
                    props.meeting.attributes.account_id = activeOrganization.value.attributes.account_id;

                    await meeting.save();

                    if (
                        meeting.rescheduledMeeting().value() &&
                        meeting.rescheduledMeeting().value().attributes.state === MeetingStateValue.scheduled
                    ) {
                        meeting.rescheduledMeeting().value().attributes.state = MeetingStateValue.rescheduled;
                        await meeting.rescheduledMeeting().value().save();
                    }

                    const participantsPromise = meeting
                        .participants()
                        .value()
                        .map(participant => participant.save());

                    await Promise.all(participantsPromise);

                    emit('saved', meeting);
                } catch (e) {
                    meetingErrors.value.reset(e);
                } finally {
                    loading.value = false;
                }
            }

            const undo = function () {
                emit('undo');
            };

            return {
                participants,
                meetingErrors,
                loading,
                save,
                undo,
                suggestedLocations,
            };
        },
    });
</script>
