import {Epoch} from '@meekohq/lumos';

export default function useEpoch() {
    return {
        fromISOString: Epoch.fromISOString,
        fromJSDate: Epoch.fromJSDate,
        fromTimestamp: Epoch.fromTimestamp,
        fromMoment: Epoch.fromMoment,
        parse: Epoch.parse,
        now: Epoch.now,
        presets: Epoch.presets,
    };
}
