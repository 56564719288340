export default function () {
    function download(url, fileName, isBlank = false) {
        const anchorElement = document.createElement('a');
        anchorElement.href = url;
        anchorElement.download = fileName;
        if (isBlank) {
            anchorElement.target = '_blank';
        }

        anchorElement.click();
        anchorElement.remove();
    }

    function downloadFromBase64(base64, fileName, contentType) {
        const blob = base64toBlob(base64, contentType);
        const url = URL.createObjectURL(blob);

        download(url, fileName);
        URL.revokeObjectURL(url);
    }

    function base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    return {download, downloadFromBase64};
}
