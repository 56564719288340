<template>
    <svg
        viewBox="0 0 26 26"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.39 18.03c-1.55-1.33-3.12-2.13-4.65-.8l-.92.79c-.66.58-1.9 3.3-6.7-2.23-4.8-5.52-1.95-6.38-1.28-6.95l.92-.8c1.52-1.33.94-3-.15-4.72L8.95 2.3C7.85.57 6.65-.55 5.12.77l-.83.72a7.72 7.72 0 0 0-3.01 5.12c-.55 3.64 1.18 7.8 5.16 12.37 3.97 4.58 7.86 6.88 11.54 6.84a7.77 7.77 0 0 0 5.48-2.28l.83-.72c1.52-1.32.58-2.67-.97-4Z"
        />
    </svg>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({});
</script>
