<script lang="ts" setup>
    import {QueryBuilder} from '@meekohq/lumos';
    import {computed, type PropType, ref} from 'vue';

    import SelectResource from '@/modules/app/components/SelectResource.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import type TenantModel from '@/modules/cashier/models/TenantModel';
    import TextHighlighter from '@/modules/legacy/helpers/text-highlighter.helper';

    const props = defineProps({
        modelValue: {
            type: Object as PropType<CustomerModel>,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tenantModel: {
            type: Object as PropType<TenantModel>,
            required: true,
        },
        withLegal: {
            type: Boolean,
            default: true,
        },
        withNatural: {
            type: Boolean,
            default: true,
        },
        extendBuilder: {
            type: Object as PropType<QueryBuilder<any>>,
            default: () => new QueryBuilder(),
        },
        createOptionEnabled: {
            type: Boolean,
            default: true,
        },
    });

    const emits = defineEmits<{
        (e: 'hideModal'): void;
        (e: 'showModal'): void;
        (e: 'update:modelValue', model: CustomerModel | undefined): void;
    }>();

    const textHighlighter = new TextHighlighter();

    const selectResource = ref();
    const searchValue = ref('');

    const personFilter = computed(() => {
        const filter = [] as string[];
        if (props.withLegal) {
            filter.push('legal');
        }
        if (props.withNatural) {
            filter.push('natural');
        }

        return filter;
    });

    const builder = computed(() => {
        return CustomerModel.query()
            .tap(query => {
                if (searchValue.value) {
                    query.whereLike('name', `*${searchValue.value}*`);
                } else {
                    query.whereNull('archived_at');
                }
            })
            .whereIn('person', personFilter.value)
            .where('tenant_id', props.tenantModel.getKey())
            .orderBy('name')
            .inject(props.extendBuilder)
            .setEagerLoads(props.extendBuilder.getEagerLoads());
    });

    function onSearchValue(event: string) {
        searchValue.value = event;
    }

    function onModelSelected(model: CustomerModel | undefined) {
        emits('update:modelValue', model);
    }

    function showModal() {
        emits('showModal');
        selectResource.value.hide();
    }
</script>

<template>
    <div>
        <SelectResource
            ref="selectResource"
            :builder="builder"
            :disabled="disabled"
            :has-unselect="true"
            :model="CustomerModel"
            :model-value="modelValue"
            @fallback="showModal"
            @update:model-value="onModelSelected"
            @search="onSearchValue"
        >
            <template #button>
                <template v-if="modelValue">
                    <FontAwesomeIcon
                        v-if="modelValue.attributes.person === 'legal'"
                        class="tw-text-blue-600"
                        fixed-width
                        icon="fa-duotone fa-city"
                    />
                    <FontAwesomeIcon
                        v-else
                        class="tw-text-blue-500"
                        fixed-width
                        icon="fa-solid fa-user"
                    />
                    {{ modelValue.attributes.name }}
                </template>
                <template v-else-if="createOptionEnabled">
                    {{ __('billing_customer:find_or_add_an_account') }}
                </template>
                <template v-else>
                    {{ __('billing_customer:find_an_account') }}
                </template>
            </template>
            <template
                v-if="createOptionEnabled"
                #fallback="{searchValue}"
            >
                <template v-if="searchValue">
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />
                    {{ __('common:actions.add') }} {{ searchValue }}
                </template>
                <template v-else>
                    <FontAwesomeIcon
                        class="tw--ml-1 tw-mr-1"
                        fixed-width
                        icon="fa-solid fa-plus"
                    />
                    {{ __('common:add_account') }}
                </template>
            </template>

            <template
                v-if="modelValue"
                #unselect-item
            >
                <FontAwesomeIcon
                    class="tw--ml-1 tw-mr-1"
                    fixed-width
                    icon="fa-duotone fa-unlink"
                />
                {{ __('billing_customer:deselect_account') }} {{ modelValue.attributes.name }}
            </template>

            <template #default="{option, searchValue}">
                <div class="tw-flex tw-items-center tw-pr-4">
                    <div class="tw--ml-1 tw-mr-2">
                        <FontAwesomeIcon
                            v-if="option.attributes.person === 'legal'"
                            class="tw-text-xl tw-text-blue-600"
                            fixed-width
                            icon="fa-duotone fa-city"
                        />
                        <FontAwesomeIcon
                            v-else
                            class="tw-text-xl tw-text-blue-500"
                            fixed-width
                            icon="fa-solid fa-user"
                        />
                    </div>
                    <div
                        class="tw-truncate"
                        v-html="textHighlighter.highlight(searchValue, option.attributes.name)"
                    />
                </div>
            </template>
        </SelectResource>
        <slot
            :modal="{searchValue}"
            name="modal"
        />
    </div>
</template>
