<script setup lang="ts">
    import {Epoch} from '@meekohq/lumos';

    defineProps({
        month: {
            type: Number,
            required: true,
        },
    });

    const emit = defineEmits<{
        (e: 'selected', value: number): void;
    }>();
</script>

<template>
    <div class="MDatePickerMonthPicker">
        <button
            v-for="m in 12"
            :key="m"
            class="MDatePickerMonthPicker__button"
            :class="{
                'MDatePickerMonthPicker__button--active': m === month,
            }"
            @click="emit('selected', m)"
        >
            {{ Epoch.now().setMonth(m).toLocaleString({month: 'long'}) }}
        </button>
    </div>
</template>

<style scoped>
    .MDatePicker {
        @apply tw-w-80;
    }

    .MDatePickerMonthPicker {
        @apply tw-grid tw-grid-flow-row tw-grid-cols-2 tw-gap-1;
    }

    .MDatePickerMonthPicker__button {
        @apply tw-cursor-pointer tw-select-none tw-rounded tw-p-2 tw-font-display tw-text-primary-500;
        @apply tw-transition-all hover:tw-bg-primary-100 hover:tw-text-primary-600;
        @apply tw-text-center tw-capitalize;
    }

    .MDatePickerMonthPicker__button--active {
        @apply tw-bg-primary-100 tw-text-primary-600;
    }
</style>
