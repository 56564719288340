import {MqlOperation, MqlTransaction} from '@meekohq/lumos';

import type {AllocationAggregatePort} from '@/modules/cashier/payment/application/ports/AllocationAggregatePort';
import type {SaveAllocationResult} from '@/modules/cashier/payment/application/SaveAllocationAggregateResponseModel';
import type {AbstractAllocationAggregate} from '@/modules/cashier/payment/domain/AbstractAllocationAggregate';
import {CreditNoteAllocationAggregate} from '@/modules/cashier/payment/domain/CreditNoteAllocationAggregate';
import {InvoiceAllocationAggregate} from '@/modules/cashier/payment/domain/InvoiceAllocationAggregate';
import {TransactionCreditAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionCreditAllocationAggregate';
import {TransactionDebitAllocationAggregate} from '@/modules/cashier/payment/domain/TransactionDebitAllocationAggregate';
import {CreditNoteAllocationErrorInterceptor} from '@/modules/cashier/payment/infrastructure/CreditNoteAllocationErrorInterceptor';
import {TransactionCreditAllocationErrorInterceptor} from '@/modules/cashier/payment/infrastructure/TransactionCreditAllocationErrorInterceptor';

export class MQLAllocationAggregateRepository implements AllocationAggregatePort {
    constructor(private readonly mqlTransaction = new MqlTransaction()) {}

    public async saveAll(allocationAggregates: AbstractAllocationAggregate[]): Promise<SaveAllocationResult[]> {
        const savingReturns: Promise<SaveAllocationResult>[] = [];

        // Sort pour éviter les erreurs de surallocation
        // Le sort prend en compte la différence entre l'amount alloué et l'amount original
        // Car il est possible qu'une désallocation soit plus grande (allocatingAmount) qu'une allocation
        allocationAggregates.sort((a, b) => {
            return a.allocationAmount - a.originalAllocationAmount - (b.allocationAmount - b.originalAllocationAmount);
        });

        for (const allocationAggregate of allocationAggregates) {
            if (
                allocationAggregate instanceof CreditNoteAllocationAggregate ||
                allocationAggregate instanceof InvoiceAllocationAggregate
            ) {
                savingReturns.push(this.saveCreditNoteAllocation(allocationAggregate));
            }

            if (
                allocationAggregate instanceof TransactionCreditAllocationAggregate ||
                allocationAggregate instanceof TransactionDebitAllocationAggregate
            ) {
                savingReturns.push(this.saveTransactionAllocation(allocationAggregate));
            }
        }

        await this.mqlTransaction.run();

        return Promise.all(savingReturns.map(savingReturn => savingReturn));
    }

    public async save(allocationAggregate: AbstractAllocationAggregate): Promise<SaveAllocationResult> {
        let promise: Promise<SaveAllocationResult> | undefined;

        if (
            allocationAggregate instanceof CreditNoteAllocationAggregate ||
            allocationAggregate instanceof InvoiceAllocationAggregate
        ) {
            promise = this.saveCreditNoteAllocation(allocationAggregate);
        }

        if (
            allocationAggregate instanceof TransactionCreditAllocationAggregate ||
            allocationAggregate instanceof TransactionDebitAllocationAggregate
        ) {
            promise = this.saveTransactionAllocation(allocationAggregate);
        }

        if (!promise) {
            throw new Error('Allocation type not supported');
        }

        await this.mqlTransaction.run();

        return promise;
    }

    private async saveCreditNoteAllocation(
        creditNoteAllocation: CreditNoteAllocationAggregate | InvoiceAllocationAggregate
    ): Promise<SaveAllocationResult> {
        const operation = new MqlOperation('cashier/allocations/adjust-credit-note-amount', {
            allocation_id: creditNoteAllocation.allocation!.getKey(),
            credit_note_id: creditNoteAllocation.source.getKey(),
            payment_id: creditNoteAllocation.destination.getKey(),
            amount: creditNoteAllocation.allocation!.attributes.amount,
        });

        this.mqlTransaction.add(operation);
        await this.mqlTransaction.waitForRun();

        try {
            await operation.run();

            return {
                status: 'success',
                sourceKey: creditNoteAllocation.source.getKey(),
            };
        } catch (err) {
            return {
                status: 'failed',
                sourceKey: creditNoteAllocation.source.getKey(),
                reason: CreditNoteAllocationErrorInterceptor.intercept(err as Error),
            };
        }
    }

    private saveTransactionAllocation(
        transactionAllocation: TransactionCreditAllocationAggregate | TransactionDebitAllocationAggregate
    ): Promise<SaveAllocationResult> {
        if (transactionAllocation.allocation!.attributes.amount === 0 && transactionAllocation.allocation!.exists) {
            transactionAllocation.setAllocationForDeletion();
        }

        return transactionAllocation
            .allocation!.save({mqlRunner: this.mqlTransaction})
            .then(() => {
                return {
                    status: 'success',
                    sourceKey: transactionAllocation.source.getKey(),
                };
            })
            .catch(err => {
                return {
                    status: 'failed',
                    sourceKey: transactionAllocation.source.getKey(),
                    reason: TransactionCreditAllocationErrorInterceptor.intercept(err),
                };
            }) as Promise<SaveAllocationResult>;
    }
}
