<template>
    <div
        class="SelectResource"
        :class="buttonClass"
    >
        <MPopover2 :bus="bus">
            <MPopover2ClickableReference manual>
                <slot
                    name="trigger"
                    :toggle="toggle"
                    :value="modelValue"
                >
                    <MButton
                        align="left"
                        :class="buttonClass"
                        :disabled="disabled"
                        class="tw-w-full"
                        :icon-sort="true"
                        :variant="buttonVariant"
                        @click="toggle"
                    >
                        <slot
                            name="button"
                            :value="modelValue"
                        />
                    </MButton>
                </slot>
            </MPopover2ClickableReference>
            <MPopover2Panel
                panel-class="tw-min-w-56"
                no-padding
                @opened="onShown"
            >
                <CFinder
                    ref="finder"
                    :has-more-result="hasMore && !loading"
                    :has-unselect="hasUnselect"
                    :is-loading="loading"
                    :multi="multi"
                    :options="models"
                    ref-path="id"
                    :search-bar="true"
                    :unselect-value="unselectValue"
                    :model-value="modelValue"
                    @fallback="$emit('fallback')"
                    @update:model-value="onModelSelected"
                    @keydown.tab="hide"
                    @search="updateSearchValue"
                    @want-more="fetchNextModels"
                >
                    <template
                        v-if="$slots.fallback"
                        #fallback="{searchValue}"
                    >
                        <slot
                            name="fallback"
                            :search-value="searchValue"
                        />
                    </template>

                    <template
                        v-if="$slots['unselect-item']"
                        #unselect-item
                    >
                        <slot name="unselect-item" />
                    </template>

                    <template #default="{option, searchValue}">
                        <slot
                            :option="option"
                            :search-value="searchValue"
                        />
                    </template>
                </CFinder>
            </MPopover2Panel>
        </MPopover2>
    </div>
</template>

<script lang="ts">
    import type {Model, QueryBuilder} from '@meekohq/lumos';
    import {debounce, isArray} from 'lodash-es';
    import type {PropType, Ref} from 'vue';
    import {defineComponent, ref} from 'vue';

    import {defineFloatingContextBus} from '@/modules/meeko-ui/components/MFloatingContext';

    export default defineComponent({
        props: {
            modelValue: {},
            model: {type: Function as PropType<Model>, required: true},
            builder: {type: Object as PropType<QueryBuilder<any>>, required: true},
            disabled: {type: Boolean, default: false},
            hideOnClick: {type: Boolean, default: true},
            multi: {type: Boolean, default: false},
            hasUnselect: {type: Boolean, default: false},
            unselectValue: {},
            dropdownPosition: {},
            dropdownWrapperClass: {},
            dropdownClass: {},
            buttonClass: {},
            popoverClass: {},
            buttonVariant: {},
        },
        emits: ['update:modelValue', 'search', 'fallback'],
        setup(props, {emit}) {
            const finderRef = ref();

            const models: Ref<Model[]> = ref([]);
            const currentPage = ref(1);
            const hasMore = ref(true);
            const loading = ref(false);

            const updateSearchValue = function (value) {
                emit('search', value);
                fetchModelsDebounced();
            };

            const fetchModels = function (page = 1): void {
                loading.value = true;
                props.builder.paginate(100, page).then(paginator => {
                    loading.value = false;

                    currentPage.value = paginator.currentPage();
                    hasMore.value = paginator.hasMorePages();

                    if (paginator.onFirstPage()) {
                        models.value = paginator.items().all();
                    } else {
                        models.value = models.value.concat(paginator.items().all());
                    }
                });
            };

            const fetchModelsDebounced = debounce(() => fetchModels(), 300);

            const fetchNextModels = function () {
                // This method is triggered by the @wantMore event on first page if models count is < 100
                // The return statement avoid unecessary API call
                if (models.value.length < 100) {
                    return;
                }

                fetchModels(currentPage.value + 1);
            };

            const onModelSelected = function (values: any | any[]) {
                if (!props.multi) {
                    bus.emit('close');
                }

                emit('update:modelValue', values);
            };

            const bus = defineFloatingContextBus();

            const hide = function () {
                bus.emit('close');
            };

            const show = function () {
                bus.emit('open');
            };

            const toggle = function () {
                bus.emit('toggle');
            };

            const onShown = function () {
                emit('search', '');
                fetchModelsDebounced();
            };

            return {
                bus,
                finderRef,
                toggle,
                hide,
                show,
                hasMore,
                models,
                onModelSelected,
                updateSearchValue,
                fetchNextModels,
                loading,
                isArray,
                onShown,
            };
        },
    });
</script>

<style scoped>
    .SelectResource {
        display: inline-block;
    }
</style>
