import {Binding} from '@meekohq/lumos';
import type {Moment} from 'moment';

import type TimePeriodValue from '@/modules/app/utils/Values/TimePeriodValue';

export const OrganizationOpeningContractBinding = new Binding<OrganizationOpeningContract>();

export default interface OrganizationOpeningContract {
    /**
     * Get an organization opening instance for the given organization.
     *
     * @param organization
     */
    forOrganization(organization): this;

    /**
     * Determines if the organization is opened on this day.
     *
     * @param date
     */
    isOpenedOn(date: Moment): boolean;

    /**
     * Determines if the organization is closed on this day.
     *
     * @param date
     */
    isClosedOn(date: Moment): boolean;

    /**
     * Get of the opening hours of the day the given date is.
     *
     * @param date
     */
    getOpeningHoursOn(date: Moment): TimePeriodValue | null;
}
