import {Str} from '@meekohq/lumos';
import {ref} from 'vue';

export class QueryOverlapError extends Error {
    //
}

export default function useConcurrentCallback() {
    const lastId = ref();

    async function resolveLastCallback(callback: () => any) {
        // We generate a unique request ID to prevent the paginator from being updated with the wrong data
        const currentId = Str.uuid();
        // We store the last request ID, so we can compare it with the current request ID
        lastId.value = currentId;

        const result = await callback();

        // If the last request is not the current request, we don't update the paginator
        if (currentId !== lastId.value) {
            return Promise.reject(new QueryOverlapError());
        }

        return result;
    }

    return {
        resolveLastCallback,
    };
}
