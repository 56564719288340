import {Binding} from '@meekohq/lumos';

import type {SaveContractResult} from '@/modules/human-resources/contract/application/SaveContractResult';
import type BalanceAllocationModel from '@/modules/human-resources/models/BalanceAllocationModel';
import type ContractModel from '@/modules/human-resources/models/ContractModel';
import type ContractTrialPeriodModel from '@/modules/human-resources/models/ContractTrialPeriodModel';
import type StaffModel from '@/modules/human-resources/models/StaffModel';

export const SaveContractUseCaseBinding = new Binding<SaveContractUseCase>();

export interface SaveContractUseCase {
    save(
        staff: StaffModel,
        contract: ContractModel,
        trialPeriods?: ContractTrialPeriodModel[],
        balanceAllocations?: BalanceAllocationModel[]
    ): Promise<SaveContractResult>;
}
