<template>
    <MMenu>
        <MMenuButton>
            <MButton icon-menu>
                <template #left-icons>
                    <FontAwesomeIcon
                        v-if="forecastOnly"
                        icon="fa-duotone fa-calendar"
                    />
                    <FontAwesomeIcon
                        v-else-if="realOnly"
                        icon="fa-duotone fa-calendar-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        icon="fa-duotone fa-calendar-alt"
                    />
                </template>
                {{ filterText }}
            </MButton>
        </MMenuButton>
        <MMenuItems>
            <div class="tw-px-1.5">
                <div class="tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
                    {{ __('common:display') }}
                </div>
            </div>
            <MMenuItem
                :auto-close-popover="false"
                @click="updateFilter('forecast')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="filters.includes('forecast')"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                    <FontAwesomeIcon
                        class="tw-ml-2"
                        fixed-width
                        icon="fa-duotone fa-calendar"
                    />
                </template>
                {{ __('common:forecasted_male_one') }}
            </MMenuItem>
            <MMenuItem
                :auto-close-popover="false"
                @click="updateFilter('real')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="filters.includes('real')"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                    <FontAwesomeIcon
                        class="tw-ml-2"
                        fixed-width
                        icon="fa-duotone fa-calendar-check"
                    />
                </template>
                {{ __('common:real_one') }}
            </MMenuItem>

            <div class="tw-px-1.5">
                <div class="tw-px-3 tw-py-2 tw-text-sm tw-text-gray-500">
                    {{ __('planning:planning_type') }}
                </div>
            </div>
            <MMenuItem
                :auto-close-popover="false"
                @click="updateFilter('recurrent')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="filters.includes('recurrent')"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ __('common:recurrent') }}
            </MMenuItem>
            <MMenuItem
                :auto-close-popover="false"
                @click="updateFilter('occasional')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="filters.includes('occasional')"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ __('common:occasional') }}
            </MMenuItem>
            <MMenuItem
                :auto-close-popover="false"
                @click="updateFilter('adaptation')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="filters.includes('adaptation')"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ __('common:adaptation') }}
            </MMenuItem>
            <MMenuItem
                :auto-close-popover="false"
                @click="updateFilter('absence')"
            >
                <template #icon>
                    <FontAwesomeIcon
                        v-if="filters.includes('absence')"
                        fixed-width
                        icon="fa-solid fa-square-check"
                    />
                    <FontAwesomeIcon
                        v-else
                        fixed-width
                        icon="fa-regular fa-square"
                    />
                </template>
                {{ __('common:absence') }}
            </MMenuItem>
        </MMenuItems>
    </MMenu>
</template>

<script lang="ts">
    import {computed, defineComponent, onBeforeMount, reactive, ref} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';

    export default defineComponent({
        props: {
            eventFilters: {
                type: Array,
                default: () => reactive([]),
            },
            defaultText: {
                type: String,
                default: () => __('common:actions.filter'),
            },
            localStorageKey: {
                type: String,
                default: 'events',
            },
        },
        emits: ['update:eventFilters'],
        setup(props, {emit}) {
            const prefix = 'calendar:kids:filter:';
            const filters = ref<string[]>(
                JSON.parse(localStorage.getItem(prefix + props.localStorageKey) as string) || []
            );

            onBeforeMount(() => {
                if (!localStorage.getItem(prefix + props.localStorageKey)) {
                    filters.value = ['forecast', 'real', 'recurrent', 'occasional', 'adaptation', 'absence'];
                    localStorage.setItem(prefix + props.localStorageKey, JSON.stringify(filters.value));
                }
                emit('update:eventFilters', filters.value);
            });

            const forecastOnly = computed(() => {
                return filters.value.includes('forecast') && !filters.value.includes('real');
            });
            const realOnly = computed(() => {
                return !filters.value.includes('forecast') && filters.value.includes('real');
            });

            const filterText = computed(() => {
                let txt = props.defaultText;
                if (forecastOnly.value) {
                    txt = __('common:forecasted_male_one');
                } else if (realOnly.value) {
                    txt = __('common:real_one');
                }

                return txt;
            });

            function updateFilter(val: string) {
                if (filters.value.includes(val)) {
                    filters.value = filters.value.filter(item => item !== val);
                } else {
                    filters.value.push(val);
                }
                localStorage.setItem(prefix + props.localStorageKey, JSON.stringify(filters.value));
                emit('update:eventFilters', filters.value);
            }

            return {
                filters,
                forecastOnly,
                realOnly,
                filterText,
                updateFilter,
            };
        },
    });
</script>
