import {computed, ref, type Ref, watch} from 'vue';

import injectableKey from '@/modules/app/utils/injectableKey';

export interface MStepPanelStepType {
    step: number;
    title: string;
    isCompleted?: boolean;
    hasError?: boolean;
}

export const keyMStepPanelState = injectableKey<ReturnType<typeof useMStepPanelState>>();

export default function useMStepPanelState(step: Ref<number | undefined>) {
    const activeStep = ref(step.value);

    const steps = ref<MStepPanelStepType[]>([]);
    const stepsCount = computed(() => steps.value.length);
    const firstStep = computed(() => steps.value.at(0));
    const lastStep = computed(() => steps.value.at(steps.value.length - 1));

    function registerStep(newStep: MStepPanelStepType) {
        steps.value.push(newStep);
    }

    function setActiveStep(index: number) {
        activeStep.value = index;
    }

    function nextStep() {
        if (!lastStep.value || activeStep.value === lastStep.value.step) {
            return;
        }

        const currentIndex = steps.value.findIndex(item => item.step === activeStep.value);
        setActiveStep(steps.value[currentIndex + 1].step);
    }

    function previousStep() {
        if (!firstStep.value || activeStep.value === firstStep.value.step) {
            return;
        }

        const currentIndex = steps.value.findIndex(item => item.step === activeStep.value);
        setActiveStep(steps.value[currentIndex - 1].step);
    }

    watch(step, value => {
        if (value !== activeStep.value) {
            activeStep.value = value;
        }
    });

    return {
        registerStep,
        activeStep,
        setActiveStep,

        nextStep,
        previousStep,

        stepsCount,
        steps,
        firstStep,
        lastStep,
    };
}
