import NumberFormatter from '@/modules/app/utils/NumberFormatter';

export class SharedRemaingAmount {
    private _remainingAmount: number;
    private readonly _numberFormatter: NumberFormatter;

    public constructor(remainingAmount: number, currencyIsoCode: string) {
        this._remainingAmount = remainingAmount;
        this._numberFormatter = new NumberFormatter({
            currencyCode: currencyIsoCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    get remainingAmount(): number {
        return this._remainingAmount;
    }

    public adjustRemainingAmount(amount: number): void {
        this._remainingAmount = this._numberFormatter.parse(this._remainingAmount - amount).asNumber;
    }
}
