<template>
    <MForm @submit.prevent="save">
        <MFieldset>
            <MLabel>
                {{ __('hr:team_name') }}
            </MLabel>
            <CInput
                v-model="teamModel.attributes.name"
                v-focus="focus"
            />
            <CFormErrorMessageList :errors="teamError.getErrors('name')" />
        </MFieldset>
    </MForm>
</template>

<script lang="ts">
    import type {PropType} from 'vue';
    import {defineComponent, ref, toRef} from 'vue';

    import __ from '@/modules/app/utils/i18n-facade';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import TeamOrganizationPivot from '@/modules/human-resources/models/TeamOrganizationPivot';
    import ErrorHandler from '@/modules/legacy/libs/errors/errorHandler';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import type OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {},
        props: {
            teamModel: {
                type: Object as PropType<TeamModel>,
                required: true,
            },
            organizationModel: {
                type: Object as PropType<OrganizationModel>,
            },
        },
        emits: ['loading', 'saved', 'teamAttached'],

        setup(props, {emit}) {
            const teamModel = toRef(props, 'teamModel');
            const organizationModel = toRef(props, 'organizationModel');
            const focus = ref(true);
            const {teamError} = useForErrors();
            const save = async function () {
                emit('loading', true);
                if (!teamModel.value.exist) {
                    teamModel.value
                        .save()
                        .then(async response => {
                            emit('loading', false);
                            const pivot = getPivot(response);
                            await pivot.save();

                            response.organizationsPivots().value().push(pivot);
                            teamError.value.reset();
                            emit('saved', response);
                        })
                        .catch(error => {
                            emit('loading', false);
                            teamError.value.reset(error);
                        });
                } else {
                    teamModel.value
                        .save()
                        .then(response => {
                            emit('loading', false);
                            teamError.value.reset();
                            emit('saved', response);
                        })
                        .catch(error => {
                            emit('loading', false);
                            teamError.value.reset(error);
                        });
                }
            };

            async function attach(model: TeamModel) {
                emit('loading', true);
                try {
                    const pivot = getPivot(model);
                    await pivot.save();

                    model.organizationsPivots().value().push(pivot);
                    emit('teamAttached', model);
                    emit('loading', false);
                } catch (error) {
                    emit('loading', false);
                    useNotification().error(__('hr:import_team_error'));
                }
            }

            const getPivot = function (model: TeamModel) {
                const pivot = new TeamOrganizationPivot();
                pivot.attributes.account_id = model.attributes.account_id;
                pivot.organization().associate(organizationModel.value);
                pivot.team().associate(model);

                return pivot;
            };

            return {
                focus,
                save,
                teamError,
                attach,
            };
        },
    });

    export function useForErrors() {
        const teamError = ref(new ErrorHandler());

        return {teamError};
    }
</script>
