<script setup lang="ts">
    import {app, catcher, ValidationError} from '@meekohq/lumos';
    import {type PropType, ref} from 'vue';

    import useError from '@/modules/app/composables/useError';
    import type {ModalType} from '@/modules/app/composables/useModal';
    import __ from '@/modules/app/utils/i18n-facade';
    import {SudoModeUseCaseBinding} from '@/modules/auth/sudo-mode/application/SudoModeUseCase';

    defineProps({
        modal: {type: Object as PropType<ModalType>, required: true},
    });

    const emit = defineEmits<{
        (e: 'confirmed', token: string): void;
        (e: 'dismissed'): void;
    }>();

    const errors = useError();

    const isLoading = ref(false);
    const password = ref('');

    async function onConfirm() {
        errors.reset();
        isLoading.value = true;

        try {
            const token = await app(SudoModeUseCaseBinding).enterSudoMode(password.value);
            emit('confirmed', token);
        } catch (err) {
            catcher()
                .on(ValidationError, validationError => {
                    errors.addValidationError(validationError);
                })
                .catch(err);
        } finally {
            isLoading.value = false;
        }
    }
</script>

<template>
    <CModal
        :modal="modal"
        size="md"
    >
        <div class="SudoModeModal">
            <FontAwesomeIcon
                class="SudoModeModal__icon"
                icon="fal fa-lock"
            />

            <MHeading level="h3">
                {{ __('auth:confirm_password_to_continue') }}
            </MHeading>

            <MForm
                class="tw-w-full"
                @submit.prevent
            >
                <MInputGroup
                    v-model="password"
                    :errors="errors.get('password')"
                    :label="__('common:password') + '*'"
                    :placeholder="__('common:password')"
                    type="password"
                />
            </MForm>
        </div>

        <template #footer>
            <div class="SudoModeModal__footer">
                <MButton
                    class="tw-w-full"
                    size="lg"
                    variant="light"
                    @click="emit('dismissed')"
                >
                    {{ __('common:actions.cancel') }}
                </MButton>
                <MButton
                    class="tw-w-full"
                    :loading="isLoading"
                    size="lg"
                    variant="primary"
                    @click="onConfirm"
                >
                    {{ __('common:actions.confirm') }}
                </MButton>
            </div>
        </template>
    </CModal>
</template>

<style scoped>
    .SudoModeModal {
        @apply tw-flex tw-flex-col tw-items-center tw-gap-4;
    }

    .SudoModeModal__icon {
        @apply tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full;
        @apply tw-bg-primary-100 tw-text-3xl tw-text-primary-500;
    }

    .SudoModeModal__footer {
        @apply tw-grid tw-w-full tw-gap-3 sm:tw-grid-cols-2;
    }
</style>
